/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./force-change-password-left.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./force-change-password-left.component";
import * as i5 from "@angular/router";
import * as i6 from "@ngrx/store";
var styles_ForceChangePasswordLeftComponent = [i0.styles];
var RenderType_ForceChangePasswordLeftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForceChangePasswordLeftComponent, data: {} });
export { RenderType_ForceChangePasswordLeftComponent as RenderType_ForceChangePasswordLeftComponent };
export function View_ForceChangePasswordLeftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "left-section left-section-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "forgot-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 5, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(11, { firstName: 0, lastName: 1 }), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(13, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(16, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "btn btn-standard"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("TD_NEST")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("USER.RESET_PASSWORD")); _ck(_v, 5, 0, currVal_1); var tmp_2_0 = null; var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 12).transform("FORCE_CHANGE_PASSWORD.HELLO_FULL_NAME", _ck(_v, 11, 0, (((tmp_2_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.userInfoResult$))) == null) ? null : tmp_2_0.firstName), (((tmp_2_0 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 10).transform(_co.userInfoResult$))) == null) ? null : tmp_2_0.lastName)))); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, i1.ɵnov(_v, 15).transform("FORCE_CHANGE_PASSWORD.WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD")); _ck(_v, 14, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 18, 0, i1.ɵnov(_v, 19).transform("CANCEL")); _ck(_v, 18, 0, currVal_4); }); }
export function View_ForceChangePasswordLeftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-force-change-password-left", [], null, null, null, View_ForceChangePasswordLeftComponent_0, RenderType_ForceChangePasswordLeftComponent)), i1.ɵdid(1, 245760, null, 0, i4.ForceChangePasswordLeftComponent, [i5.Router, i6.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForceChangePasswordLeftComponentNgFactory = i1.ɵccf("app-force-change-password-left", i4.ForceChangePasswordLeftComponent, View_ForceChangePasswordLeftComponent_Host_0, {}, {}, []);
export { ForceChangePasswordLeftComponentNgFactory as ForceChangePasswordLeftComponentNgFactory };
