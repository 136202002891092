<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-user"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close" aria-hidden="true"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="tab-menu d-flex d-block-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: isPermissionTab }"
      (click)="onSelectTabs(rolePageTabs.PERMISSION)"
    >
      Permission
    </a>
  </div>

  <div class="d-block-flex mb-5" [formGroup]="roleForm">
    <div id="PermissionTab" [ngClass]="{ 'd-none': !isPermissionTab }">
      <div class="d-flex flex-row">
        <div class="col-12 col-md-6 mb-3 px-0">
          <label for="name">Role Name<span class="text-danger">*</span></label>
          <input
            id="name"
            formControlName="name"
            type="text"
            placeholder="Role Name"
            autocomplete="off"
            class="form-control"
            maxlength="50"
            [ngClass]="{ 'is-invalid': submitted && roleForm.controls.name.errors }"
          />
          <div *ngIf="submitted && !!roleForm.controls.name.errors" class="invalid-feedback">
            <div *ngIf="!!roleForm.controls.name.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="!!roleForm.controls.name.errors.isDuplicated">
              {{ 'ERRORS.DUPLICATED_ROLE_NAME' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div *ngFor="let role of roleMasters">
          <div class="row section-header">
            <span>{{ role.name }}</span>

            <div class="section-header-right px-2 mr-3" *ngIf="isEditMode">
              <a (click)="selectedAllApp(role)" class=" my-1 btn-text-link">Select All</a><span class="px-1">/</span>
              <a (click)="clearAllApp(role)" class=" my-1 btn-text-link">Clear All</a>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 mb-3" *ngFor="let feature of role.features">
              <div class="d-flex justify-content-between bd-highlight">
                <span class="col pl-1 ">{{ feature.name }}</span>
                <div class="px-2 " *ngIf="isEditMode">
                  <a (click)="selectedAll(feature)" class="my-1 btn-text-link">Select All</a><span class="px-1">/</span
                  ><a (click)="clearAll(feature)" class="my-1 btn-text-link">Clear All</a>
                </div>
              </div>
              <hr class="my-1" />
              <div class="d-flex flex-column bd-highlight">
                <label class="p-2 bd-highlight " *ngFor="let permission of feature.permissions">
                  <input
                    [disabled]="!isEditMode"
                    type="checkbox"
                    name="{{ permission.code }}"
                    [checked]="permission.selected"
                    (change)="onCheckboxChange($event, permission, feature.coApplications)"
                  />
                  {{ permission.name }}
                </label>
              </div>
            </div>
          </div>

          <hr class="section-divider" />
        </div>
      </div>
    </div>

    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right">
        <button
          *ngIf="isViewMode && hasManagePermission"
          type="button"
          id="btn-request-edit"
          class="btn btn-primary"
          (click)="toggleToEditMode()"
        >
          Edit
        </button>
        <button
          *ngIf="isEditMode && hasManagePermission"
          type="button"
          id="btn-cancel"
          class="btn btn-standard mr-2"
          (click)="onExit()"
        >
          Cancel
        </button>
        <button
          *ngIf="isEditMode && hasManagePermission"
          type="button"
          id="btn-submit"
          class="btn btn-primary"
          (click)="onSubmit()"
          appPreventDoubleClick
        >
          Submit
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>
</div>
