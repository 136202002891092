import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { find, orderBy, round } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, pairwise, startWith, take, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { duplicatedArticleValidator } from '../../../shared/custom-validators/duplicated-article-validator';
import { duplicatedBarcodeValidator } from '../../../shared/custom-validators/duplicated-barcode-validator';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { ProductMasterData } from '../../../shared/gql/product.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  Assortment,
  AssortmentAllType,
  AssortmentDeliveryMethodEnum,
  AssortmentFreshLiteTypeEnum,
  AssortmentOrderingMethodEnum,
  AssortmentPageModes,
  AssortmentPageTabsEnum,
  AssortmentProductDetail,
  AssortmentRequestStatusEnum,
  AssortmentRequestTypeEnum,
  Barcode,
  ChangeValue,
  CJBarcode,
  CJProduct,
  CJProductLinkArticleDetail,
  ProductStatusEnum,
  SupplierPrice,
  TaskModuleUrl,
  TDProduct,
  WholesalePrice
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import {
  applyLOV,
  articleTypeLOV,
  cbgProductLOV,
  deliveryMethod,
  freshLiteTypeLOV,
  orderingMethodLOV,
  productStandardLOV,
  productStatusLOV,
  productTierLOV,
  productTypeLOV,
  yesNoLOV
} from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { ProductMaster, PruductMasterSelectStatusEnum } from '../../../shared/models/product-master.model';
import { SupplierPriceContent } from '../../../shared/models/supplier-price.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService, BarcodeService, RequestAssortmentService } from '../../../shared/services';
import { ManualSupplierPriceDataService } from '../../../shared/services/manual-supplier-price-data.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import { FreshLiteListRequest } from '../../../shared/store/actions/fresh-lite.actions';
import {
  ProductAssortmentLoadRequested,
  ProductGetLinkArticleDetailRequestAction,
  ProductGetLinkArticleDetailResetAction,
  RequestAssortmentApproveRejectSuccess,
  RequestAssortmentApproveRequested,
  RequestAssortmentCancelRequested,
  RequestAssortmentCheckCJDuplicateBarcodeRequest,
  RequestAssortmentCheckCJDuplicateBarcodeReset,
  RequestAssortmentCreateRequested,
  RequestAssortmentDeleteRequested,
  RequestAssortmentDuplicateBarcodeReset,
  RequestAssortmentRejectRequested,
  RequestAssortmentReset,
  RequestAssortmentSubmitErrorReset,
  RequestAssortmentSubmitRequested,
  RequestAssortmentUpdateRequested,
  RequestAssortmentUpdateSuccess
} from '../../../shared/store/actions/request-assortment.actions';
import {
  SupplierPriceIsPriceUpdated,
  SupplierPriceReset
} from '../../../shared/store/actions/supplier-price.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectFreshLiteList } from '../../../shared/store/selectors/fresh-lite.selectors';
import {
  selectDuplicateBarcode,
  selectIsCJBarcodeDuplicate,
  selectLinkProductCJDetail,
  selectRequestAssortmentApproveRejectStatus,
  selectRequestAssortmentCJProduct,
  selectRequestAssortmentEdit,
  selectRequestAssortmentSaveStatus,
  selectRequestAssortmentSubmitError
} from '../../../shared/store/selectors/request-assortment.selectors';
import { selectPriceSetting } from '../../../shared/store/selectors/setting.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  convertBkkToUtc,
  convertUtcToBkk,
  dateToStringCriteria,
  formatDate,
  formatDateEndOfDay,
  formatDateStartOfDay,
  getDateFromString
} from '../../../shared/utils/date-util';
import { AssortmentUnlinkCjProductModalComponent } from '../assortment-unlink-cj-product-modal/assortment-unlink-cj-product-modal.component';

@Component({
  selector: 'app-assortment-request',
  templateUrl: './assortment-request.component.html',
  styleUrls: ['./assortment-request.component.scss']
})
export class AssortmentRequestComponent extends BaseComponent {
  assortment: Assortment;
  assortmentPageTabs = AssortmentPageTabsEnum;
  assortmentAllType = AssortmentAllType;
  assortmentPageModes = AssortmentPageModes;
  assortmentRequestStatusEnum = AssortmentRequestStatusEnum;
  currentTab: AssortmentPageTabsEnum;
  supplierDetailsForm: FormGroup;
  productDetailsForm: FormGroup;
  wholesalePriceForm: FormGroup;
  barcodeForm: FormGroup;
  productTypeEnum = ProductTypeEnum;
  assortmentDeliveryMethodEnum = AssortmentDeliveryMethodEnum;

  mockIncVatForm = { includeVat: true };

  isSubmitted: boolean;
  isSaveDraft: boolean;
  isNonMerchandise: boolean;
  isApprove: boolean;
  isRequestViewMode: boolean;
  useCJProduct: boolean;

  listOfValue: {};
  listOfChange: {};

  currentTolerance: number;
  systemVat: number;

  assortmentRequestStatus: AssortmentRequestStatusEnum;
  currentProductStatus: ProductStatusEnum;
  baseStorageUrl: string;
  typeOfFreshLite: AssortmentFreshLiteTypeEnum;

  productsMasterQuery: ProductMasterData;
  saveStatusSubscription: Subscription;
  approveRejectStatusSubscription: Subscription;

  errorSupplierPriceDisplay: {
    [key: number]: {
      orderBarcode: boolean;
      unitFactor: number;
      tdPickingUnit: string;
      supplierUnit: string;
      orderBarcodeInactive: boolean;
    };
  } = {};

  isOriginalCjProduct = false;
  isDeleteLinkArticle = false;

  private productMaster$: Observable<ProductMaster>;
  private localStore: Observable<any>;
  public allWarehouses: WarehouseListContent[] = [];
  public linkedProductArticle: CJProductLinkArticleDetail = null;

  public isManualSupplierPrice = false;
  public manualSupplierPriceDatas: Array<SupplierPriceContent> = [];

  hasViewMerchantPermission = false;
  hasViewNonMerchantPermission = false;
  hasEditPermission = false;
  hasEditNonMerchantPermission = false;
  hasApproveMerchantPermission = false;
  hasApproveNonMerchantPermission = false;
  hasManageAssetPermission = false;
  hasManageStoreUsePermission = false;

  @Output() data: {
    id?: string;
    articleNo?: string;
    title: string;
    mode: AssortmentPageModes;
    articleType: ArticleTypeEnum;
    originPage?: string;
    productType: ProductTypeEnum;
  };

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('supplierDetailsComponent', { static: false }) supplierDetailsReference: any;

  constructor(
    protected router: Router,
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected barcodeService: BarcodeService,
    protected readonly modalService: BsModalService,
    private requestAssortmentService: RequestAssortmentService,
    protected authGuardService: AuthGuardService,
    protected manualSupplierPriceDataService: ManualSupplierPriceDataService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.currentTab = AssortmentPageTabsEnum.PRODUCT_TAB;

    this.baseStorageUrl = `${env.storageUrl}${env.services.productAssortment.bucketName}`;
    this.setInitialData();
    this.loadMasterData();
    this.initialSubscription();

    this.store.dispatch(new FreshLiteListRequest());
    this.store.dispatch(new WarehouseListRequestAction());

    this.hasViewMerchantPermission = this.authGuardService.checkPermission(['tdassort_inv_v']);
    this.hasViewNonMerchantPermission = this.authGuardService.checkPermission(['tdassort_asset_v', 'tdassort_sto_v']);
    this.hasEditPermission = this.authGuardService.checkPermission(['tdassort_inv_m']);
    this.hasEditNonMerchantPermission = this.authGuardService.checkPermission(['tdassort_asset_m', 'tdassort_sto_m']);
    this.hasApproveMerchantPermission = this.authGuardService.checkPermission(['tdassort_inv_app']);
    this.hasApproveNonMerchantPermission = this.authGuardService.checkPermission([
      'tdassort_asset_app',
      'tdassort_sto_app'
    ]);
    this.hasManageAssetPermission = this.authGuardService.checkPermission(['tdassort_asset_m']);
    this.hasManageStoreUsePermission = this.authGuardService.checkPermission(['tdassort_sto_m']);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.store.dispatch(new RequestAssortmentUpdateSuccess({ status: false }));
    this.store.dispatch(new RequestAssortmentApproveRejectSuccess({ isSuccess: null }));
    this.store.dispatch(new RequestAssortmentReset());
    this.store.dispatch(new RequestAssortmentCheckCJDuplicateBarcodeReset());
    this.store.dispatch(new RequestAssortmentDuplicateBarcodeReset());
    this.store.dispatch(new SupplierPriceReset());
    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    }
    this.store.dispatch(new ProductGetLinkArticleDetailResetAction());
    this.manualSupplierPriceDataService.resetData();
  }

  initialSubscription() {
    this.localStore
      .pipe(
        select(selectFreshLiteList),
        tap(result => {
          this.listOfValue['freshLiteList'] = orderBy(result, ['name'], ['asc']);
          if (this.typeOfFreshLite) {
            this.filterFreshLiteList(this.typeOfFreshLite);
          }
        })
      )
      .subscribe();

    this.localStore
      .pipe(
        select(selectPriceSetting),
        take(1),
        tap(result => {
          this.currentTolerance = result && result.tolerance ? result.tolerance : null;
          this.systemVat = result && result.vat ? result.vat : null;
        }),
        untilComponentDestroyed(this)
      )
      .subscribe();

    this.saveStatusSubscription = this.localStore
      .pipe(
        select(selectRequestAssortmentSaveStatus),
        tap(isSuccess => {
          if (isSuccess) {
            this.alertSuccessModal({ result: { response: 'success', errorResponse: null } });
          }
        })
      )
      .subscribe();

    this.approveRejectStatusSubscription = this.localStore
      .pipe(
        select(selectRequestAssortmentApproveRejectStatus),
        tap(isSuccess => {
          if (isSuccess) {
            this.alertApproveRejectModalSuccess({ result: { response: 'success', errorResponse: null } });
          }
        })
      )
      .subscribe();

    this.localStore
      .pipe(
        select(selectIsCJBarcodeDuplicate),
        filter(cjBarcodeDuplicate => cjBarcodeDuplicate !== null)
      )
      .subscribe(cjBarcodeDuplicate => {
        if (cjBarcodeDuplicate && cjBarcodeDuplicate.cjBarcodeList && cjBarcodeDuplicate.cjBarcodeList.length > 0) {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Alert',
              message: 'Barcode is exist in CJ Product.'
            }
          });

          alertModal.content.action
            .pipe(untilComponentDestroyed(this))
            .subscribe((result: ModalButtonResponseEnum) => {
              if (result === ModalButtonResponseEnum.OK) {
                cjBarcodeDuplicate.cjBarcodeList.forEach(cjBarcode => {
                  const barcodeForm = this.findBarcodeFormByBarcode(cjBarcode.barcode);
                  barcodeForm.patchValue({ cjBarcode });
                });
              }
              if (alertModal.content.actions) {
                alertModal.content.actions.unsubscribe();
              }
            });
          this.store.dispatch(new RequestAssortmentCheckCJDuplicateBarcodeReset());
        } else {
          if (!this.isSaveDraft) {
            this.doSubmitAction();
          } else {
            this.doSaveAction();
          }
          this.store.dispatch(new RequestAssortmentCheckCJDuplicateBarcodeReset());
        }
      });

    this.localStore
      .pipe(
        select(selectDuplicateBarcode),
        filter(duplicateBarcode => duplicateBarcode !== null)
      )
      .subscribe(duplicateBarcode => {
        if (duplicateBarcode) {
          let duplicateBarcodeTxt = duplicateBarcode.substring(
            duplicateBarcode.indexOf(':') + 1,
            duplicateBarcode.length
          );
          duplicateBarcodeTxt = `- ${duplicateBarcodeTxt}`;
          duplicateBarcodeTxt = duplicateBarcodeTxt.replace(/,/g, ',- ');
          duplicateBarcodeTxt = duplicateBarcodeTxt.replace(/,/g, '<br/>');
          this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Alert',
              message: `Barcode(s) is already exist:<br/><br/>${duplicateBarcodeTxt}<br/>`
            }
          });
        }
        this.store.dispatch(new RequestAssortmentDuplicateBarcodeReset());
      });

    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(warehouse => {
      if (warehouse) {
        this.allWarehouses = warehouse;
      }
    });

    this.localStore.pipe(select(selectRequestAssortmentSubmitError)).subscribe(submitError => {
      if (submitError) {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: submitError.message
          }
        });

        if (submitError.code === '05015') {
          this.changeTab(this.assortmentPageTabs.SUPPLIER_TAB);
        } else {
          this.changeTab(this.assortmentPageTabs.PRODUCT_TAB);
        }
      }
      this.deleteStatusNodeForAssortmentEdit();

      this.store.dispatch(new RequestAssortmentSubmitErrorReset());
    });

    this.localStore
      .pipe(select(selectLinkProductCJDetail), filter(Boolean))
      .subscribe((cjProduct: CJProductLinkArticleDetail) => {
        this.linkedProductArticle = cjProduct;
        this.copyLinkedArticleToRequestAssortment(cjProduct);
      });
  }

  deleteStatusNodeForAssortmentEdit() {
    this.store.pipe(select(selectRequestAssortmentEdit), untilComponentDestroyed(this)).subscribe(assortment => {
      if (assortment && !assortment.status && this.assortment && typeof this.assortment.status !== 'undefined') {
        delete this.assortment.status;
      }
    });
  }

  loadMasterData() {
    this.requestAssortmentService
      .getProductsMasterData(this.data.articleType)
      .pipe(untilComponentDestroyed(this))
      .subscribe(data => {
        this.productsMasterQuery = data.data;
        this.listOfValue[this.assortmentAllType.SEGMENT] = data.data.segments;

        this.listOfValue[this.assortmentAllType.UOM] = data.data.uoms;
        this.listOfValue[this.assortmentAllType.UNIT] = data.data.units;
        this.listOfValue['productGrading'] = data.data.productGradings;

        if ([AssortmentPageModes.REQUEST_CREATE].includes(this.data.mode)) {
          this.initialDataForCreate();
        } else {
          this.initialDataForViewEdit();
        }
      });
  }

  initialDataForCreate() {
    this.assortment = {} as Assortment;
    this.assortment.status = AssortmentRequestStatusEnum.DRAFT;
    this.assortment.type = AssortmentRequestTypeEnum.NEW;
    this.assortment.importId = null;
    this.assortment.articleType = this.data.articleType;
    this.assortment.version = 0;
    this.assortment.useCJProduct = false;
    this.assortment.productDetail = {} as AssortmentProductDetail;
    this.assortment.productDetail.tdProduct = {} as TDProduct;
    this.assortment.productDetail.tdProduct.version = 0;
    this.assortment.productDetail.tdProduct.useCJProduct = false;
    this.assortment.productDetail.cjProduct = {} as CJProduct;
    this.assortment.productDetail.barcodes = [] as Barcode[];
    this.assortment.productDetail.effectiveWholesalePrice = {} as WholesalePrice;
    this.assortment.productDetail.scheduleWholesalePrice = {} as WholesalePrice;

    this.createForm(null);
  }

  initialDataForViewEdit() {
    if ([AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new ProductAssortmentLoadRequested({ data: this.data.articleNo, isProduct: true }));
    } else {
      this.store.dispatch(new ProductAssortmentLoadRequested({ data: this.data.id, isProduct: false }));
    }
    this.store.pipe(select(selectRequestAssortmentEdit), untilComponentDestroyed(this)).subscribe(assortment => {
      if (assortment) {
        this.assortment = assortment;
        this.assortmentRequestStatus = this.assortment.status;
        this.useCJProduct = assortment.useCJProduct;

        if (
          assortment.productDetail &&
          assortment.productDetail.tdProduct &&
          assortment.productDetail.tdProduct.freshLiteCategory &&
          (assortment.type === AssortmentRequestTypeEnum.EDIT ||
            [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode))
        ) {
          this.typeOfFreshLite = assortment.productDetail.tdProduct.freshLiteCategory.type;
          this.filterFreshLiteList(this.typeOfFreshLite);
        }

        if (this.useCJProduct) {
          this.productMaster$ = this.store.select(selectRequestAssortmentCJProduct);
        }
        this.createForm(this.assortment);
        if (
          [AssortmentPageModes.REQUEST_VIEW].includes(this.data.mode) &&
          assortment.productDetail &&
          assortment.productDetail.changeProducts &&
          assortment.productDetail.changeProducts.length > 0
        ) {
          this.checkCurrentRequestViewMode();
          assortment.productDetail.changeProducts.forEach(data => {
            if (data.field === 'newWarehouse') {
              this.listOfChange[data.after] = data;
            } else {
              this.listOfChange[data.field] = data;
            }
          });
        }

        if (
          [AssortmentRequestStatusEnum.AWAITING_APPROVAL].includes(this.assortmentRequestStatus) &&
          this.listOfChange['useCJProduct']
        ) {
          this.isOriginalCjProduct = this.listOfChange['useCJProduct']['before'];
        } else {
          this.isOriginalCjProduct = this.useCJProduct;
        }

        if (
          [AssortmentRequestStatusEnum.AWAITING_APPROVAL].includes(this.assortmentRequestStatus) &&
          this.useCJProduct
        ) {
          this.linkedProductArticle = {
            ...this.assortment.productDetail.cjProduct,
            barcodes: this.assortment.productDetail.cjProduct.barcodes as CJBarcode[],
            cjSupplierPrices: null,
            selectStatus: this.assortment.productDetail.cjProduct.selectStatus as PruductMasterSelectStatusEnum
          };
        }
      }
    });
  }

  filterFreshLiteList(type: AssortmentFreshLiteTypeEnum) {
    if (type) {
      this.listOfValue['freshLiteList'] = this.listOfValue['freshLiteList'].filter(result => result.type === type);
    }
  }

  setInitialData() {
    this.isNonMerchandise =
      this.data.articleType === ArticleTypeEnum.NON_MERCHANDISE ||
      this.data.productType === ProductTypeEnum.FIX_ASSET ||
      this.data.productType === ProductTypeEnum.STORE_USE;

    this.listOfValue = {};
    this.listOfChange = {};
    this.listOfValue['productTier'] = productTierLOV;
    this.listOfValue['productStandard'] = productStandardLOV;
    this.listOfValue['cbgProduct'] = cbgProductLOV;
    this.listOfValue['restrictedAlcohol'] = applyLOV;
    this.listOfValue['trueFalse'] = yesNoLOV;
    this.listOfValue['productStatus'] = productStatusLOV;
    this.listOfValue['orderingMethods'] = orderingMethodLOV;
    this.listOfValue['deliveryMethod'] = deliveryMethod;
    this.listOfValue['articleType'] = articleTypeLOV;
    this.listOfValue['freshLiteType'] = freshLiteTypeLOV;
    this.listOfValue['productType'] = productTypeLOV;

    this.productMaster$ = of({} as ProductMaster);
  }

  checkCurrentRequestViewMode() {
    this.isRequestViewMode =
      [AssortmentPageModes.REQUEST_VIEW].includes(this.data.mode) &&
      this.assortment.type === AssortmentRequestTypeEnum.EDIT;
  }

  createForm(assortment: Assortment) {
    this.createProductDetailsForm(assortment);
    this.createBarcodeForm(assortment);
    this.createWholesalePriceForm(assortment);
    this.createSupplierDetailsForm(assortment);
  }

  createProductDetailsForm(assortment: Assortment) {
    const isDisabled = [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(
      this.data.mode
    );
    const initialNull = [{ value: null, disabled: isDisabled }];
    const initialFalse = [{ value: false, disabled: isDisabled }];
    const initialNullRequired = [{ value: null, disabled: isDisabled }, Validators.required];
    const initialNullDisabled = [{ value: null, disabled: true }];
    if (assortment && assortment.productDetail) {
      const productDetails = assortment.productDetail.tdProduct;

      let productImages = [];
      if (productDetails.productImages && productDetails.productImages.length > 0) {
        productImages = productDetails.productImages.map(image => {
          return {
            id: image.id,
            filePath: image.original,
            imgSrc: `${this.baseStorageUrl}/${image.original}`,
            errors: {}
          };
        });
      }

      let gradingGroup = null;
      if (productDetails.segment) {
        const segments = this.listOfValue[this.assortmentAllType.SEGMENT];
        const segment = find(segments, seg => seg.code === productDetails.segment);
        if (segment) {
          this.listOfValue[this.assortmentAllType.FAMILY] = segment.families;

          if (productDetails.family) {
            const families = this.listOfValue[this.assortmentAllType.FAMILY];
            const family = find(families, fam => fam.code === productDetails.family);

            if (family) {
              this.listOfValue[this.assortmentAllType.CLASS] = family.classCodes;

              if (productDetails.classCode) {
                const classCodes = this.listOfValue[this.assortmentAllType.CLASS];
                const classCode = find(classCodes, code => code.code === productDetails.classCode);

                if (classCode) {
                  this.listOfValue[this.assortmentAllType.SUBCLASS] = classCode.subClasses;
                  this.listOfValue[this.assortmentAllType.GRADINGGROUP] = [{ ...classCode.gradingGroup }];

                  // Lookup for grading group
                  const lovGradingGroup = this.listOfValue[this.assortmentAllType.GRADINGGROUP];
                  if (lovGradingGroup && lovGradingGroup.length === 1) {
                    gradingGroup = lovGradingGroup[0].code;
                  }
                }
              }
            }
          }
        }
      }

      // Prepare for ordering method
      let orderingMethods = null;
      let cjProductDeliveryMethod = null;
      if (productDetails.orderingMethods && productDetails.orderingMethods.length > 0) {
        orderingMethods = productDetails.orderingMethods.map(ordering => {
          return AssortmentOrderingMethodEnum[ordering];
        });
      }

      if (this.assortment.useCJProduct) {
        cjProductDeliveryMethod = productDetails.productType ? AssortmentDeliveryMethodEnum.TD : null;
        if ([ProductTypeEnum.INVENTORY, ProductTypeEnum.STORE_USE].includes(productDetails.productType)) {
          orderingMethods = '["FIRST_LOT_ORDER","SPECIAL_REQUEST","STORE_REPLENISHMENT"]';
        } else if (productDetails.productType === ProductTypeEnum.FIX_ASSET) {
          orderingMethods = '["FIRST_LOT_ORDER","SPECIAL_REQUEST"]';
        }
      }

      if (productDetails.productStatus) {
        this.currentProductStatus = productDetails.productStatus;
        const defaultFromValue = [
          ProductStatusEnum.HOLD_BUY,
          ProductStatusEnum.RECALL,
          ProductStatusEnum.CLEAR,
          ProductStatusEnum.CLEAR_PROMOTION,
          ProductStatusEnum.HOLD_BUY_SELL,
          ProductStatusEnum.DELETE,
          ProductStatusEnum.OUT_OF_STOCK,
          productDetails.productStatus
        ];

        if (productDetails.productStatus === ProductStatusEnum.OUT_OF_STOCK) {
          if (productDetails.classCode === 'D0200') {
            defaultFromValue.push(ProductStatusEnum.SEASONAL);
          } else {
            defaultFromValue.push(ProductStatusEnum.ACTIVE);
          }
        }

        let productStatusWithPreviousValue = [];
        if (
          [
            ProductStatusEnum.HOLD_BUY,
            ProductStatusEnum.HOLD_BUY_SELL,
            ProductStatusEnum.CLEAR,
            ProductStatusEnum.CLEAR_PROMOTION,
            ProductStatusEnum.RECALL,
            ProductStatusEnum.DELETE,
            ProductStatusEnum.SELECT,
            ProductStatusEnum.NEW,
            ProductStatusEnum.ACTIVE,
            ProductStatusEnum.SEASONAL,
            ProductStatusEnum.OUT_OF_STOCK
          ].includes(productDetails.previousProductStatus)
        ) {
          productStatusWithPreviousValue = [
            ProductStatusEnum.HOLD_BUY,
            ProductStatusEnum.HOLD_BUY_SELL,
            ProductStatusEnum.CLEAR,
            ProductStatusEnum.CLEAR_PROMOTION,
            ProductStatusEnum.RECALL,
            ProductStatusEnum.DELETE,
            ProductStatusEnum.OUT_OF_STOCK,
            productDetails.previousProductStatus
          ];
        }

        switch (productDetails.productStatus) {
          case ProductStatusEnum.SELECT: {
            this.listOfValue['productStatus'] = this.listOfValue['productStatus'].filter(productStatus =>
              [ProductStatusEnum.DELETE, ProductStatusEnum.SELECT, ProductStatusEnum.OUT_OF_STOCK].includes(
                productStatus.value
              )
            );
            break;
          }
          case ProductStatusEnum.NEW:
          case ProductStatusEnum.ACTIVE:
          case ProductStatusEnum.SEASONAL:
          case ProductStatusEnum.OUT_OF_STOCK: {
            this.listOfValue['productStatus'] = this.listOfValue['productStatus'].filter(productStatus =>
              defaultFromValue.includes(productStatus.value)
            );
            break;
          }
          case ProductStatusEnum.HOLD_BUY:
          case ProductStatusEnum.HOLD_BUY_SELL:
          case ProductStatusEnum.CLEAR:
          case ProductStatusEnum.CLEAR_PROMOTION:
          case ProductStatusEnum.RECALL:
          case ProductStatusEnum.DELETE: {
            this.listOfValue['productStatus'] = this.listOfValue['productStatus'].filter(productStatus =>
              productStatusWithPreviousValue.includes(productStatus.value)
            );
            break;
          }
          default: {
          }
        }
      }

      this.productDetailsForm = this.fb.group({
        articleNo: [
          {
            value: productDetails.articleNo,
            disabled:
              !(
                [AssortmentPageModes.ASSORTMENT_EDIT, AssortmentPageModes.REQUEST_EDIT].includes(this.data.mode) &&
                this.assortment.type === 'NEW'
              ) || isDisabled
          }
        ],
        importId: [
          {
            value: assortment.importId,
            disabled: false
          }
        ],
        supplierRefNumber: [{ value: productDetails.supplierRefNumber, disabled: isDisabled }],
        productStatus: [
          {
            value: productDetails.productStatus ? productDetails.productStatus : '',
            disabled:
              isDisabled ||
              (this.assortment.type && this.assortment.type !== AssortmentRequestTypeEnum.EDIT) ||
              this.data.mode !== AssortmentPageModes.ASSORTMENT_EDIT
          }
        ],
        previousProductStatus: [
          { value: productDetails.previousProductStatus ? productDetails.previousProductStatus : '', disabled: true }
        ],
        productImages: [{ value: productImages, disabled: isDisabled }, Validators.required],
        productName: [{ value: productDetails.productName, disabled: isDisabled }, Validators.required],
        productDisplayName: [
          {
            value: productDetails.productDisplayName ? productDetails.productDisplayName.substring(0, 25) : null,
            disabled: isDisabled
          },
          Validators.required
        ],
        brand: this.isNonMerchandise
          ? [{ value: productDetails.brand, disabled: isDisabled }]
          : [{ value: productDetails.brand, disabled: isDisabled }, Validators.required],
        vat: [{ value: productDetails.vat, disabled: isDisabled }, Validators.required],

        // Display on type merchandise only
        shelfLife: this.isNonMerchandise
          ? [{ value: productDetails.shelfLife, disabled: isDisabled }]
          : [{ value: productDetails.shelfLife, disabled: isDisabled }, Validators.required],
        minimumShelfLife: this.isNonMerchandise
          ? [{ value: productDetails.minimumShelfLife, disabled: isDisabled }]
          : [{ value: productDetails.minimumShelfLife, disabled: isDisabled }, Validators.required],
        standardGp: this.isNonMerchandise
          ? [{ value: productDetails.standardGp, disabled: isDisabled }]
          : [{ value: productDetails.standardGp, disabled: isDisabled }, Validators.required],
        productTier: this.isNonMerchandise
          ? [{ value: productDetails.productTier, disabled: isDisabled }]
          : [{ value: productDetails.productTier, disabled: isDisabled }, Validators.required],
        productStandard: this.isNonMerchandise
          ? [{ value: productDetails.productStandard, disabled: isDisabled }]
          : [{ value: productDetails.productStandard, disabled: isDisabled }, Validators.required],
        cbgProduct: this.isNonMerchandise
          ? [{ value: productDetails.cbgProduct, disabled: isDisabled }]
          : [{ value: productDetails.cbgProduct, disabled: isDisabled }, Validators.required],
        exclusiveProduct: [{ value: productDetails.exclusiveProduct, disabled: isDisabled }],
        productGrading: this.isNonMerchandise
          ? [{ value: productDetails.productGrading, disabled: isDisabled }]
          : [{ value: productDetails.productGrading, disabled: isDisabled }, Validators.required],
        productRecommend: this.isNonMerchandise
          ? [
              {
                value: productDetails.productRecommend ? productDetails.productRecommend : false,
                disabled: isDisabled
              }
            ]
          : [
              {
                value: productDetails.productRecommend ? productDetails.productRecommend : false,
                disabled: isDisabled
              },
              Validators.required
            ],
        estSalesQty: [{ value: productDetails.estSalesQty, disabled: isDisabled }],
        estSalesValue: [{ value: productDetails.estSalesValue, disabled: isDisabled }],
        sellingChannel: this.isNonMerchandise
          ? [{ value: productDetails.sellingChannel, disabled: isDisabled }]
          : [{ value: productDetails.sellingChannel, disabled: isDisabled }, Validators.required],
        restrictedItem: [{ value: productDetails.restrictedItem, disabled: isDisabled }],
        restrictedAlcohol: this.isNonMerchandise
          ? [{ value: productDetails.restrictedAlcoholSaleTime, disabled: isDisabled }]
          : [{ value: productDetails.restrictedAlcoholSaleTime, disabled: isDisabled }, Validators.required],
        size: [{ value: productDetails.size, disabled: isDisabled }],
        uom: [{ value: productDetails.uom, disabled: isDisabled }],
        flavor: [{ value: productDetails.flavor, disabled: isDisabled }],
        gradingGroup: [
          { value: productDetails.gradingGroup ? productDetails.gradingGroup : gradingGroup, disabled: true }
        ],
        productLocationGuideline: this.isNonMerchandise
          ? [{ value: productDetails.productLocationGuideline, disabled: isDisabled }]
          : [{ value: productDetails.productLocationGuideline, disabled: isDisabled }, Validators.required],

        // Category & Attribute
        segment: [
          { value: productDetails.segment ? productDetails.segment : null, disabled: isDisabled },
          Validators.required
        ],
        family: [
          { value: productDetails.family ? productDetails.family : null, disabled: isDisabled },
          Validators.required
        ],
        classCode: [
          { value: productDetails.classCode ? productDetails.classCode : null, disabled: isDisabled },
          Validators.required
        ],
        subClass: [
          { value: productDetails.subClass ? productDetails.subClass : null, disabled: isDisabled },
          Validators.required
        ],
        // Ordering & Delivery
        articleType: [{ value: productDetails.articleType, disabled: true }, Validators.required],
        productType: [
          {
            value: productDetails.productType
              ? productDetails.productType
              : productDetails.articleType === ArticleTypeEnum.MERCHANDISE
              ? ProductTypeEnum.INVENTORY
              : null,
            disabled: !this.useCJProduct || productDetails.articleType === ArticleTypeEnum.MERCHANDISE || isDisabled
          },
          Validators.required
        ],
        allowToRent: [{ value: productDetails.allowToRent, disabled: isDisabled || !this.isNonMerchandise }],
        rentalPerMonth: [{ value: productDetails.rentalPerMonth, disabled: true }],
        orderingMethods: [
          {
            value: productDetails.orderingMethods
              ? JSON.stringify(productDetails.orderingMethods)
              : this.assortment.useCJProduct
              ? orderingMethods
              : null,
            disabled: isDisabled || this.assortment.type === AssortmentRequestTypeEnum.EDIT || this.useCJProduct
          },
          Validators.required
        ],
        freshLiteCategory: [
          {
            value:
              productDetails.freshLiteCategory && productDetails.freshLiteCategory.id
                ? productDetails.freshLiteCategory
                : null,
            disabled:
              isDisabled ||
              this.useCJProduct ||
              (productDetails.orderingMethods &&
                !productDetails.orderingMethods.includes(AssortmentOrderingMethodEnum.FRESH_LITE))
          },
          Validators.required
        ],
        freshLiteType: [
          {
            value:
              productDetails.freshLiteCategory && productDetails.freshLiteCategory.id
                ? this.translate.instant('FRESH_LITE.TYPE.' + productDetails.freshLiteCategory.type)
                : null,
            disabled: true
          }
        ],
        deliveryMethod: [
          {
            value: productDetails.deliveryMethod
              ? productDetails.deliveryMethod
              : this.assortment.useCJProduct
              ? cjProductDeliveryMethod
              : null,
            disabled:
              isDisabled ||
              this.useCJProduct ||
              (productDetails.orderingMethods &&
                productDetails.orderingMethods.includes(AssortmentOrderingMethodEnum.FRESH_LITE))
          },
          Validators.required
        ],
        warehouses: [productDetails.warehouses ? productDetails.warehouses : []],
        allowToDestroy: this.isNonMerchandise
          ? [{ value: productDetails.allowToDestroy, disabled: isDisabled }]
          : [{ value: productDetails.allowToDestroy, disabled: isDisabled }, Validators.required]
      });
    } else {
      this.productDetailsForm = this.fb.group({
        articleNo: [
          { value: null, disabled: false },
          {
            validators: [Validators.required],
            asyncValidators: [duplicatedArticleValidator(this.requestAssortmentService)]
          }
        ],
        importId: initialNull,
        supplierRefNumber: initialNull,
        productStatus: [{ value: '', disabled: true }],
        productImages: initialNullRequired,
        productName: initialNullRequired,
        productDisplayName: initialNullRequired,
        brand: this.isNonMerchandise ? initialNull : initialNullRequired,
        vat: initialNullRequired,

        // Display on type merchandise only
        shelfLife: this.isNonMerchandise ? initialNull : initialNullRequired,
        minimumShelfLife: this.isNonMerchandise ? initialNull : initialNullRequired,
        standardGp: this.isNonMerchandise ? initialNull : initialNullRequired,
        productTier: this.isNonMerchandise ? initialNull : initialNullRequired,
        productStandard: this.isNonMerchandise ? initialNull : initialNullRequired,
        cbgProduct: this.isNonMerchandise ? initialNull : initialNullRequired,
        exclusiveProduct: initialNull,
        productGrading: this.isNonMerchandise ? initialNull : initialNullRequired,
        productRecommend: this.isNonMerchandise ? initialNull : initialFalse,
        estSalesQty: initialNull,
        estSalesValue: initialNull,
        sellingChannel: this.isNonMerchandise ? initialNull : initialNullRequired,
        restrictedItem: initialNull,
        restrictedAlcohol: this.isNonMerchandise ? initialNull : initialNullRequired,
        size: initialNull,
        uom: initialNull,
        flavor: initialNull,
        gradingGroup: initialNullDisabled,
        productLocationGuideline: this.isNonMerchandise ? initialNull : initialNullRequired,

        // Category & Attribute
        segment: initialNullRequired,
        family: initialNullRequired,
        classCode: initialNullRequired,
        subClass: initialNullRequired,

        // Ordering & Delivery
        articleType: [{ value: this.data.articleType, disabled: true }, Validators.required],
        productType: [
          {
            value: this.useCJProduct ? null : this.data.productType,
            disabled: !this.useCJProduct || !this.isNonMerchandise || isDisabled
          },
          Validators.required
        ],
        allowToRent: [{ value: false, disabled: !this.isNonMerchandise }],
        rentalPerMonth: [{ value: null, disabled: true }],
        orderingMethods: [{ value: null, disabled: isDisabled }, Validators.required],
        freshLiteCategory: initialNullDisabled,
        freshLiteType: initialNullDisabled,
        deliveryMethod: initialNullRequired,
        warehouses: initialNull,
        allowToDestroy: this.isNonMerchandise ? initialNull : initialNullRequired
      });
    }
    this.filterOrderingMethod(this.productDetailsForm.controls['productType'].value);
    if (
      [
        AssortmentPageModes.REQUEST_EDIT,
        AssortmentPageModes.ASSORTMENT_EDIT,
        AssortmentPageModes.REQUEST_CREATE
      ].includes(this.data.mode)
    ) {
      this.updateValueChangesEventForProductDetailsForm();
      this.checkBarcodeDuplicateForCJProduct();
    }

    if (
      [AssortmentPageModes.ASSORTMENT_EDIT, AssortmentPageModes.REQUEST_EDIT].includes(this.data.mode) &&
      this.assortment.type === 'NEW'
    ) {
      this.productDetailsForm.controls.articleNo.setValidators([Validators.required]);
      this.productDetailsForm.controls.articleNo.setAsyncValidators([
        duplicatedArticleValidator(this.requestAssortmentService, assortment.articleNo)
      ]);
    }
  }

  updateValueChangesEventForProductDetailsForm() {
    if ((this.productDetailsForm.controls.articleType.value as ArticleTypeEnum) === ArticleTypeEnum.NON_MERCHANDISE) {
      this.listOfValue['productType'] = this.listOfValue['productType'].filter(
        productType => productType.value !== 'INVENTORY'
      );
    }

    this.productDetailsForm.controls['allowToRent'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(selected => {
        const rentalPerMonthForm = this.productDetailsForm.controls['rentalPerMonth'];
        if (selected) {
          rentalPerMonthForm.enable();
          rentalPerMonthForm.setValidators([Validators.required]);
          rentalPerMonthForm.updateValueAndValidity({ onlySelf: true });
          return;
        }
        rentalPerMonthForm.setValue(null);
        rentalPerMonthForm.clearValidators();
        rentalPerMonthForm.updateValueAndValidity({ onlySelf: true });
        rentalPerMonthForm.disable();
      });

    this.productDetailsForm.controls['orderingMethods'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(orderingMethods => {
        const freshLiteCategoryForm = this.productDetailsForm.controls['freshLiteCategory'];
        const deliveryMethodForm = this.productDetailsForm.controls['deliveryMethod'];
        if (!this.useCJProduct) {
          if (
            [AssortmentOrderingMethodEnum.FRESH_LITE].some(orderingMethod =>
              orderingMethod.includes(JSON.parse(orderingMethods))
            )
          ) {
            freshLiteCategoryForm.enable();
            freshLiteCategoryForm.setValidators([Validators.required]);
            freshLiteCategoryForm.updateValueAndValidity({ onlySelf: true });

            deliveryMethodForm.disable();
            deliveryMethodForm.setValue(AssortmentDeliveryMethodEnum.SUPPLIER);
            deliveryMethodForm.clearValidators();
            deliveryMethodForm.updateValueAndValidity({ onlySelf: true });
            return;
          }
          freshLiteCategoryForm.setValue(null);
          freshLiteCategoryForm.clearValidators();
          freshLiteCategoryForm.updateValueAndValidity({ onlySelf: true });
          freshLiteCategoryForm.disable();

          deliveryMethodForm.enable();
          deliveryMethodForm.setValue(null);
          deliveryMethodForm.setValidators([Validators.required]);
          deliveryMethodForm.updateValueAndValidity({ onlySelf: true });
        } else {
          deliveryMethodForm.setValue(AssortmentDeliveryMethodEnum.TD);
        }
      });

    this.productDetailsForm.controls['deliveryMethod'].valueChanges
      .pipe(
        untilComponentDestroyed(this),
        startWith(this.productDetailsForm.controls['deliveryMethod'].value as AssortmentDeliveryMethodEnum),
        pairwise()
      )
      .subscribe(
        ([_prevDeliveryMethod, nextDeliveryMethod]: [AssortmentDeliveryMethodEnum, AssortmentDeliveryMethodEnum]) => {
          const orderingMethodValue = JSON.parse(this.productDetailsForm.controls['orderingMethods'].value);
          if (_prevDeliveryMethod !== nextDeliveryMethod) {
            this.store.dispatch(new SupplierPriceReset());
            this.store.dispatch(new SupplierPriceIsPriceUpdated(true));
            this.productDetailsForm.controls.warehouses.setValue([]);
            this.productDetailsForm.controls.importId.setValue(null);
          }
          if (
            nextDeliveryMethod &&
            ![AssortmentDeliveryMethodEnum.TD].includes(nextDeliveryMethod) &&
            ![AssortmentOrderingMethodEnum.FRESH_LITE].some(orderingMethod =>
              orderingMethod.includes(orderingMethodValue)
            )
          ) {
            return;
          }
        }
      );

    this.productDetailsForm.controls['articleNo'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(articleNo => {
        this.assortment = {
          ...this.assortment,
          productDetail: {
            ...this.assortment.productDetail,
            tdProduct: {
              ...this.assortment.productDetail.tdProduct,
              articleNo
            }
          },
          articleNo
        };
      });

    this.productDetailsForm.controls['productName'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(productName => {
        this.assortment = {
          ...this.assortment,
          productDetail: {
            ...this.assortment.productDetail,
            tdProduct: {
              ...this.assortment.productDetail.tdProduct,
              productName
            }
          },
          productName
        };
      });

    this.productDetailsForm.controls['vat'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(vat => {
      const vatRate = vat ? this.getVatRate() : 0;
      (this.wholesalePricesForm as FormArray).controls.forEach((wholesalePrice, index) => {
        const wholesalePriceExVat = (wholesalePrice as FormGroup).controls.wholesalePriceExVat.value;
        const retailPriceFormDTO = new RetailPriceFormDTO();
        retailPriceFormDTO.vatRate = vatRate;
        retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
        if (index === 0) {
          this.recalculateRetailPriceBarcode(retailPriceFormDTO);
        } else if (index === 1) {
          this.recalculateScheduleRetailPriceBarcode(retailPriceFormDTO);
        }
        this.recalculateWholesalePrice();
      });
    });

    this.productDetailsForm.controls['freshLiteCategory'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(freshLite => {
        if (freshLite) {
          this.productDetailsForm.patchValue({
            freshLiteType: this.translate.instant('FRESH_LITE.TYPE.' + freshLite.type)
          });
        } else {
          this.productDetailsForm.patchValue({
            freshLiteType: null
          });
        }
      });

    this.productDetailsForm.controls['productType'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(productType => {
        if (this.assortment.useCJProduct) {
          if ([ProductTypeEnum.INVENTORY, ProductTypeEnum.STORE_USE].includes(productType)) {
            this.productDetailsForm.controls.orderingMethods.setValue(
              '["FIRST_LOT_ORDER","SPECIAL_REQUEST","STORE_REPLENISHMENT"]'
            );
          } else if (productType === ProductTypeEnum.FIX_ASSET) {
            this.productDetailsForm.controls.orderingMethods.setValue('["FIRST_LOT_ORDER","SPECIAL_REQUEST"]');
          }
        } else if (productType) {
          this.productDetailsForm.controls.orderingMethods.reset();
          this.productDetailsForm.controls.deliveryMethod.reset();
          this.productDetailsForm.controls.orderingMethods.enable();
        }
        this.filterOrderingMethod(productType);
      });
  }

  filterOrderingMethod(productType: ProductTypeEnum) {
    switch (productType) {
      case ProductTypeEnum.INVENTORY: {
        this.listOfValue['orderingMethods'] = orderingMethodLOV.filter(orderingMethod => {
          const orderingJson = JSON.parse(orderingMethod.value);
          return orderingJson.some(value => {
            return [
              AssortmentOrderingMethodEnum.FRESH_LITE,
              AssortmentOrderingMethodEnum.STORE_REPLENISHMENT
            ].includes(value);
          });
        });
        break;
      }
      case ProductTypeEnum.FIX_ASSET: {
        this.listOfValue['orderingMethods'] = orderingMethodLOV.filter(orderingMethod => {
          const orderingJson = JSON.parse(orderingMethod.value);
          return orderingJson.every(value => {
            return ![
              AssortmentOrderingMethodEnum.FRESH_LITE,
              AssortmentOrderingMethodEnum.STORE_REPLENISHMENT
            ].includes(value);
          });
        });
        break;
      }
      case ProductTypeEnum.STORE_USE: {
        this.listOfValue['orderingMethods'] = orderingMethodLOV.filter(orderingMethod => {
          const orderingJson = JSON.parse(orderingMethod.value);
          return orderingJson.some(value => {
            return [AssortmentOrderingMethodEnum.STORE_REPLENISHMENT].includes(value);
          });
        });
        break;
      }
      default: {
        return;
      }
    }
  }

  createBarcodeForm(assortment: Assortment) {
    this.barcodeForm = this.fb.group({
      barcodes: this.fb.array([])
    });

    if (
      assortment &&
      assortment.productDetail &&
      assortment.productDetail.barcodes &&
      assortment.productDetail.barcodes.length > 0
    ) {
      assortment.productDetail.barcodes.forEach(barcode => {
        this.barcodesForm.push(this.createBarcodeFormItem(barcode));
      });
    }
  }

  createBarcodeFormItem(barcode: Barcode = null): FormGroup {
    const isDisabled = [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(
      this.data.mode
    );
    const initialNull = [{ value: null, disabled: isDisabled }];
    const initialNullDisable = [{ value: null, disabled: true }];
    const initialNullRequired = [{ value: null, disabled: isDisabled }, Validators.required];
    const initialNullRequiredNoZero = [
      { value: null, disabled: isDisabled },
      [Validators.required, this.notAllowZeroValidator()]
    ];

    if (barcode) {
      const tdBarcode = barcode.tdBarcode;
      const currentBarcodeIndex = this.barcodesForm.length;

      let tdPickingUnit = null;
      if (tdBarcode.barcode === this.assortment.productDetail.tdProduct.pickingUnitBarcode) {
        tdPickingUnit = true;
      }

      const barcodeForm = this.fb.group({
        id: [{ value: tdBarcode.id, disabled: isDisabled }],
        version: [{ value: tdBarcode.version, disabled: isDisabled }],
        index: [{ value: currentBarcodeIndex, disabled: false }],
        barcode: [
          {
            value: tdBarcode.barcode,
            disabled: isDisabled || !!tdBarcode.barcodeStatus || (barcode.cjBarcode && barcode.cjBarcode.id)
          },
          {
            validators: [Validators.required, this.duplicatedBarcodeValidator(currentBarcodeIndex)],
            asyncValidators: [duplicatedBarcodeValidator(this.barcodeService, tdBarcode.barcode)],
            updateOn: 'change'
          }
        ],
        barcodeStatus: [{ value: tdBarcode.barcodeStatus ? tdBarcode.barcodeStatus : '', disabled: isDisabled }],
        tdPickingUnit: [{ value: tdPickingUnit, disabled: isDisabled }],
        barSize: [{ value: tdBarcode.barSize, disabled: isDisabled }, Validators.required],
        unit: [{ value: tdBarcode.unit, disabled: isDisabled }, Validators.required],
        unitFactor: [
          { value: tdBarcode.unitFactor, disabled: isDisabled || tdBarcode.barSize === 'S' },
          [Validators.required, this.notAllowZeroValidator(), this.notAllowOneSizeMOrLValidator()]
        ],
        weight: [{ value: tdBarcode.weight, disabled: isDisabled }, Validators.required],
        height: [{ value: tdBarcode.height, disabled: isDisabled }, Validators.required],
        width: [{ value: tdBarcode.width, disabled: isDisabled }, Validators.required],
        length: [{ value: tdBarcode.length, disabled: isDisabled }, Validators.required],
        suggestedRetailPriceIncVat: [
          this.assortment.articleType,
          this.assortment.productDetail.tdProduct.articleType
        ].includes(ArticleTypeEnum.MERCHANDISE)
          ? [{ value: tdBarcode.suggestedRetailPrice, disabled: isDisabled }, Validators.required]
          : [{ value: tdBarcode.suggestedRetailPrice, disabled: isDisabled }],
        retailPriceIncVat: [
          this.assortment.articleType,
          this.assortment.productDetail.tdProduct.articleType
        ].includes(ArticleTypeEnum.MERCHANDISE)
          ? [
              {
                value: tdBarcode.effectiveRetailPrice ? tdBarcode.effectiveRetailPrice.priceIncVat : null,
                disabled:
                  isDisabled ||
                  this.assortment.type === AssortmentRequestTypeEnum.EDIT ||
                  this.data.mode === AssortmentPageModes.ASSORTMENT_EDIT
              },
              [this.notAllowZeroValidator(), Validators.required]
            ]
          : [
              {
                value: tdBarcode.effectiveRetailPrice ? tdBarcode.effectiveRetailPrice.priceIncVat : null,
                disabled: isDisabled
              }
            ],
        retailPriceVat: [
          { value: tdBarcode.effectiveRetailPrice ? tdBarcode.effectiveRetailPrice.vat : null, disabled: true }
        ],
        retailPriceExVat: [
          {
            value: tdBarcode.effectiveRetailPrice ? tdBarcode.effectiveRetailPrice.priceExcVat : null,
            disabled: true
          }
        ],
        retailPriceEffectiveDate: [
          {
            value:
              tdBarcode.effectiveRetailPrice && tdBarcode.effectiveRetailPrice.effectiveDate
                ? getDateFromString(convertUtcToBkk(tdBarcode.effectiveRetailPrice.effectiveDate))
                : null,
            disabled: true
          }
        ],
        estimatePartnerGp: [
          {
            value:
              tdBarcode.effectiveRetailPrice && tdBarcode.effectiveRetailPrice.estimatedPartnerGpPct !== null
                ? tdBarcode.effectiveRetailPrice.estimatedPartnerGpPct
                : null,
            disabled: true
          }
        ],
        scheduleRetailPriceIncVat: [
          {
            value: tdBarcode.scheduleRetailPrice ? tdBarcode.scheduleRetailPrice.priceIncVat : null,
            disabled: isDisabled
          }
        ],
        scheduleRetailPriceVat: [
          { value: tdBarcode.scheduleRetailPrice ? tdBarcode.scheduleRetailPrice.vat : null, disabled: true }
        ],
        scheduleRetailPriceExVat: [
          { value: tdBarcode.scheduleRetailPrice ? tdBarcode.scheduleRetailPrice.priceExcVat : null, disabled: true }
        ],
        scheduleRetailPriceEffectiveDate: [
          {
            value:
              tdBarcode.scheduleRetailPrice && tdBarcode.scheduleRetailPrice.effectiveDate
                ? getDateFromString(convertUtcToBkk(tdBarcode.scheduleRetailPrice.effectiveDate))
                : null,
            disabled: isDisabled
          },
          [this.notAllowBackDateValidator()]
        ],
        scheduleEstimatePartnerGp: [
          {
            value:
              tdBarcode.scheduleRetailPrice && tdBarcode.scheduleRetailPrice.estimatedPartnerGpPct !== null
                ? tdBarcode.scheduleRetailPrice.estimatedPartnerGpPct
                : null,
            disabled: true
          }
        ],
        tdBarcodeStatus: [
          {
            value: tdBarcode.barcodeStatus ? tdBarcode.barcodeStatus : '',
            disabled: isDisabled || !tdBarcode.barcodeStatus
          }
        ],
        vatPct: [
          {
            value: tdBarcode.effectiveRetailPrice ? tdBarcode.effectiveRetailPrice.vatPct : null,
            disabled: isDisabled
          }
        ],
        cjBarcode: barcode.cjBarcode && barcode.cjBarcode.id ? barcode.cjBarcode : null,
        changes:
          barcode.changeBarcodes && barcode.changeBarcodes.length > 0
            ? this.createChangesForm(barcode.changeBarcodes)
            : null
      });

      this.updateValueChangesEventForBarcodeFormItem(barcodeForm);
      return barcodeForm;
    } else {
      const isMerchandise = this.data.articleType === ArticleTypeEnum.MERCHANDISE;
      const currentBarcodeIndex = this.barcodesForm.length;

      const barcodeForm = this.fb.group({
        id: initialNull,
        version: initialNull,
        index: [{ value: currentBarcodeIndex, disabled: false }],
        barcode: [
          { value: null, disabled: false },
          {
            validators: [Validators.required, this.duplicatedBarcodeValidator(currentBarcodeIndex)],
            asyncValidators: [duplicatedBarcodeValidator(this.barcodeService, null)],
            updateOn: 'change'
          }
        ],
        barcodeStatus: initialNull,
        tdPickingUnit: initialNull,
        barSize: initialNullRequired,
        unit: initialNullRequired,
        unitFactor: [
          { value: null, disabled: isDisabled },
          [Validators.required, this.notAllowZeroValidator(), this.notAllowOneSizeMOrLValidator()]
        ],
        weight: initialNullRequired,
        height: initialNullRequired,
        width: initialNullRequired,
        length: initialNullRequired,
        suggestedRetailPriceIncVat: isMerchandise ? initialNullRequired : initialNull,
        retailPriceIncVat: isMerchandise ? initialNullRequiredNoZero : initialNull,
        retailPriceVat: initialNullDisable,
        retailPriceExVat: initialNullDisable,
        retailPriceEffectiveDate: initialNullDisable,
        scheduleRetailPriceIncVat: initialNull,
        scheduleRetailPriceVat: initialNull,
        scheduleRetailPriceExVat: initialNull,
        scheduleRetailPriceEffectiveDate: initialNull,
        scheduleEstimatePartnerGp: initialNull,
        estimatePartnerGp: initialNullDisable,
        tdBarcodeStatus: [{ value: '', disabled: true }],
        vatPct: initialNull,
        cjBarcode: null,
        changes: null
      });

      this.updateValueChangesEventForBarcodeFormItem(barcodeForm);

      return barcodeForm;
    }
  }

  duplicatedBarcodeValidator = (index): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value) {
        let isDuplicated = false;
        this.barcodesForm.controls.forEach(barcodeForm => {
          if (barcodeForm.get('index').value !== index) {
            const barcode = barcodeForm.get('barcode').value;
            if (control.value === barcode) {
              isDuplicated = true;
              return { duplicatedBarcode: true };
            }
          }
        });
        return isDuplicated ? { duplicatedBarcode: true } : null;
      }
      return null;
    };
  };

  notAllowZeroValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return Number(control.value) === 0 ? { isZero: true } : null;
    };
  };

  notAllowOneSizeMOrLValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!(control.parent as FormGroup)) {
        return null;
      }
      const barSize = (control.parent as FormGroup).controls.barSize.value;
      return ['L', 'M'].includes(barSize) && control.value === 1 ? { isSizeMOrL: true } : null;
    };
  };

  notAllowBackDateValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return moment(control.value).isSameOrBefore(new Date()) ? { invalidDate: true } : null;
    };
  };

  updateValueChangesEventForBarcodeFormItem(barcodeForm: FormGroup) {
    barcodeForm.controls['unitFactor'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(unitFactor => {
      if (unitFactor !== null) {
        const isVat = this.productDetailsForm.get('vat').value;
        const vatRate = isVat ? this.getVatRate() : 0;
        (this.wholesalePricesForm as FormArray).controls.forEach((wholesalePrice, index) => {
          const retailPriceFormDTO = new RetailPriceFormDTO();
          const wholesalePriceExVat = (wholesalePrice as FormGroup).controls.wholesalePriceExVat.value;
          if (index === 0) {
            retailPriceFormDTO.unitFactor = unitFactor;
            retailPriceFormDTO.vatRate = vatRate;
            retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
            retailPriceFormDTO.retailPriceIncVat = barcodeForm.controls['retailPriceIncVat'].value;
            this.calculateRetailPrice(barcodeForm, retailPriceFormDTO);
            this.updateBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);
          } else if (index === 1) {
            retailPriceFormDTO.unitFactor = unitFactor;
            retailPriceFormDTO.vatRate = vatRate;
            retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
            retailPriceFormDTO.retailPriceIncVat = barcodeForm.controls['scheduleRetailPriceIncVat'].value;
            this.calculateScheduleRetailPrice(barcodeForm, retailPriceFormDTO);
            this.updateScheduleBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);
          }
        });
      } else {
        barcodeForm.patchValue({
          estimatePartnerGp: null
        });
      }
    });

    barcodeForm.controls['retailPriceIncVat'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(retailPriceIncVat => {
        const retailPriceFormDTO = new RetailPriceFormDTO();
        if (retailPriceIncVat !== null) {
          const isVat = this.productDetailsForm.get('vat').value;
          const vatRate = isVat ? this.getVatRate() : 0;
          const wholesalePriceExVat = this.getWholesalePriceExVat();
          retailPriceFormDTO.retailPriceIncVat = retailPriceIncVat;
          retailPriceFormDTO.unitFactor = barcodeForm.controls['unitFactor'].value;
          retailPriceFormDTO.vatRate = vatRate;
          retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
          this.calculateRetailPrice(barcodeForm, retailPriceFormDTO);
        }
        this.updateBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);
      });

    barcodeForm.controls['scheduleRetailPriceIncVat'].valueChanges
      .pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(scheduleRetailPriceIncVat => {
        const retailPriceFormDTO = new RetailPriceFormDTO();
        if (scheduleRetailPriceIncVat !== null) {
          const isVat = this.productDetailsForm.get('vat').value;
          const vatRate = isVat ? this.getVatRate() : 0;
          const wholesalePriceExVat = this.getWholesalePriceExVat(1);
          retailPriceFormDTO.retailPriceIncVat = scheduleRetailPriceIncVat;
          retailPriceFormDTO.unitFactor = barcodeForm.controls['unitFactor'].value;
          retailPriceFormDTO.vatRate = vatRate;
          retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
          this.calculateScheduleRetailPrice(barcodeForm, retailPriceFormDTO);
        }
        this.updateScheduleBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);

        if (
          scheduleRetailPriceIncVat ||
          scheduleRetailPriceIncVat === 0 ||
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.value
        ) {
          barcodeForm.controls.scheduleRetailPriceIncVat.setValidators([
            Validators.required,
            this.notAllowZeroValidator()
          ]);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setValidators([
            Validators.required,
            this.notAllowBackDateValidator()
          ]);
          barcodeForm.controls.scheduleRetailPriceIncVat.updateValueAndValidity({ onlySelf: true });
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.updateValueAndValidity({ onlySelf: true });
        } else if (!scheduleRetailPriceIncVat && !barcodeForm.controls.scheduleRetailPriceEffectiveDate.value) {
          barcodeForm.controls.scheduleRetailPriceIncVat.setValidators(null);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setValidators(null);
          barcodeForm.controls.scheduleRetailPriceIncVat.setErrors(null);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setErrors(null);
        }
      });

    barcodeForm.controls['scheduleRetailPriceEffectiveDate'].valueChanges
      .pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(scheduleRetailPriceEffectiveDate => {
        if (scheduleRetailPriceEffectiveDate || barcodeForm.controls.scheduleRetailPriceIncVat.value) {
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setValidators([
            Validators.required,
            this.notAllowBackDateValidator()
          ]);
          barcodeForm.controls.scheduleRetailPriceIncVat.setValidators([
            Validators.required,
            this.notAllowZeroValidator()
          ]);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.updateValueAndValidity({ onlySelf: true });
          barcodeForm.controls.scheduleRetailPriceIncVat.updateValueAndValidity({ onlySelf: true });
        } else if (!scheduleRetailPriceEffectiveDate && !barcodeForm.controls.scheduleRetailPriceIncVat.value) {
          barcodeForm.controls.scheduleRetailPriceIncVat.setValidators(null);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setValidators(null);
          barcodeForm.controls.scheduleRetailPriceIncVat.setErrors(null);
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.setErrors(null);
        }
      });

    barcodeForm.controls['tdBarcodeStatus'].valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(tdBarcodeStatus => {
        if (['INACTIVE', 'MARK_DELETE'].includes(tdBarcodeStatus)) {
          barcodeForm.controls.barcodeStatus.disable();
          barcodeForm.controls.tdPickingUnit.disable();
          barcodeForm.controls.tdPickingUnit.setValue(null);
          barcodeForm.controls.barSize.disable();
          barcodeForm.controls.unit.disable();
          barcodeForm.controls.unitFactor.disable();
          barcodeForm.controls.weight.disable();
          barcodeForm.controls.height.disable();
          barcodeForm.controls.width.disable();
          barcodeForm.controls.length.disable();
          barcodeForm.controls.suggestedRetailPriceIncVat.disable();
          barcodeForm.controls.scheduleRetailPriceIncVat.disable();
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.disable();
          // barcodeForm.controls.retailPriceIncVat.disable();
        } else if (['ACTIVE'].includes(tdBarcodeStatus)) {
          barcodeForm.controls.barcodeStatus.enable();
          barcodeForm.controls.tdPickingUnit.enable();
          barcodeForm.controls.barSize.enable();
          barcodeForm.controls.unit.enable();
          barcodeForm.controls.barSize.value === 'S'
            ? barcodeForm.controls.unitFactor.disable()
            : barcodeForm.controls.unitFactor.enable();
          barcodeForm.controls.weight.enable();
          barcodeForm.controls.height.enable();
          barcodeForm.controls.width.enable();
          barcodeForm.controls.length.enable();
          barcodeForm.controls.suggestedRetailPriceIncVat.enable();
          barcodeForm.controls.scheduleRetailPriceIncVat.enable();
          barcodeForm.controls.scheduleRetailPriceEffectiveDate.enable();
          barcodeForm.controls.retailPriceIncVat.disable();
        }
      });

    barcodeForm.controls['barSize'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(barSize => {
      if (barSize === 'S') {
        barcodeForm.controls.unitFactor.setValue(1);
        barcodeForm.controls.unitFactor.disable();
      } else {
        barcodeForm.controls.unitFactor.enable();
      }
    });
  }

  updateBarcodeRetailByDTO(retailPriceFormDTO: RetailPriceFormDTO, barcodeForm: FormGroup) {
    barcodeForm.patchValue({
      retailPriceVat: retailPriceFormDTO.retailPriceVat,
      retailPriceExVat: retailPriceFormDTO.retailPriceExVat,
      estimatePartnerGp: retailPriceFormDTO.estimatePartnerGp,
      vatPct: retailPriceFormDTO.vatPct
    });
  }

  updateScheduleBarcodeRetailByDTO(retailPriceFormDTO: RetailPriceFormDTO, barcodeForm: FormGroup) {
    barcodeForm.patchValue({
      scheduleRetailPriceVat: retailPriceFormDTO.retailPriceVat,
      scheduleRetailPriceExVat: retailPriceFormDTO.retailPriceExVat,
      scheduleEstimatePartnerGp: retailPriceFormDTO.estimatePartnerGp,
      vatPct: retailPriceFormDTO.vatPct
    });
  }

  getWholesalePriceExVat(index = 0) {
    return this.wholesalePricesForm.controls[index].controls.wholesalePriceExVat.value
      ? this.wholesalePricesForm.controls[index].controls.wholesalePriceExVat.value
      : null;
  }

  createWholesalePriceForm(assortment: Assortment) {
    this.wholesalePriceForm = this.fb.group({
      wholesalePrices: this.fb.array([])
    });

    const wholesalePrices = [];
    if (
      assortment &&
      assortment.productDetail &&
      assortment.productDetail.effectiveWholesalePrice &&
      assortment.productDetail.effectiveWholesalePrice.articleNo
    ) {
      wholesalePrices.push(assortment.productDetail.effectiveWholesalePrice);
    }

    if (
      assortment &&
      assortment.productDetail &&
      assortment.productDetail.scheduleWholesalePrice &&
      assortment.productDetail.scheduleWholesalePrice.articleNo
    ) {
      wholesalePrices.push(assortment.productDetail.scheduleWholesalePrice);
    }

    if (wholesalePrices.length > 0) {
      this.addWholesalePriceForm(wholesalePrices);
      if (
        ((AssortmentPageModes.REQUEST_EDIT === this.data.mode &&
          this.assortment.type === AssortmentRequestTypeEnum.EDIT) ||
          AssortmentPageModes.ASSORTMENT_EDIT === this.data.mode) &&
        wholesalePrices.length === 1 &&
        wholesalePrices.length < 2
      ) {
        this.addWholesalePriceForm(null);
      }
    } else {
      this.addWholesalePriceForm(null);
    }

    if (
      [AssortmentPageModes.ASSORTMENT_EDIT, AssortmentPageModes.REQUEST_EDIT].includes(this.data.mode) &&
      this.wholesalePricesForm.at(1)
    ) {
      this.wholesalePricesForm.at(0).controls.wholesalePriceIncVat.disable();
      this.wholesalePricesForm.at(1).controls.effectiveDate.enable();
    }
  }

  addWholesalePriceForm(wholesalePrices = null) {
    if (wholesalePrices) {
      wholesalePrices.forEach(wholesalePrice => {
        this.wholesalePricesForm.push(this.createWholesalePriceFormItem(wholesalePrice));
      });
    } else {
      this.wholesalePricesForm.push(this.createWholesalePriceFormItem(null));
    }
    (this.wholesalePricesForm as FormArray).controls.forEach((wholesalePrice, index) => {
      const effectiveDate = (wholesalePrice as FormGroup).controls.effectiveDate.value;
      (wholesalePrice as FormGroup).patchValue({ index: index });
      if (effectiveDate) {
        if (index === 0) {
          (wholesalePrice as FormGroup).patchValue({
            effectiveDate: getDateFromString(effectiveDate, env.dateISO8601)
          });
        } else if (index === 1) {
          (wholesalePrice as FormGroup).patchValue({
            effectiveDate: formatDate(getDateFromString(effectiveDate), env.dateISO8601)
          });
        }
      }
    });
  }

  createWholesalePriceFormItem(wholesalePrice: WholesalePrice = null): FormGroup {
    const isDisabled = [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(
      this.data.mode
    );

    const initialNull = [{ value: null, disabled: isDisabled }];
    const initialNullDisabled = [{ value: null, disabled: true }];
    const initialNullRequired = [{ value: null, disabled: isDisabled }, Validators.required];
    const initialNullRequiredNoZero = [
      { value: null, disabled: isDisabled },
      [Validators.required, this.notAllowZeroValidator()]
    ];
    let wholesale = null;
    if (wholesalePrice) {
      if (wholesalePrice.effectiveDate) {
        wholesale = convertUtcToBkk(wholesalePrice.effectiveDate, env.dateTimeNanoSec);
      }
      const wholesalePriceForm = this.fb.group({
        id: [{ value: wholesalePrice.id, disabled: isDisabled }],
        wholesalePriceIncVat: [
          { value: wholesalePrice.priceIncVat, disabled: isDisabled },
          [Validators.required, this.notAllowZeroValidator()]
        ],
        wholesalePriceVat: [{ value: wholesalePrice.vat, disabled: isDisabled }],
        wholesalePriceExVat: [{ value: wholesalePrice.priceExcVat, disabled: isDisabled }],
        tdGp: [{ value: wholesalePrice.gpPct, disabled: isDisabled }],
        vatPct: [{ value: wholesalePrice.vatPct, disabled: isDisabled }],
        effectiveDate: [
          {
            value: wholesale,
            disabled: true
          }
        ],
        index: initialNull,
        changes: wholesalePrice.changes ? this.createChangesForm(wholesalePrice.changes) : null
      }) as FormGroup;

      this.updateValueChangesEventForWholesalePriceFormItem(wholesalePriceForm);
      return wholesalePriceForm;
    } else {
      const wholesalePriceForm = this.fb.group({
        id: initialNull,
        wholesalePriceIncVat: initialNullRequiredNoZero,
        wholesalePriceVat: initialNull,
        wholesalePriceExVat: initialNull,
        tdGp: initialNull,
        vatPct: initialNull,
        effectiveDate: ![AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.ASSORTMENT_EDIT].includes(
          this.data.mode
        )
          ? initialNullDisabled
          : initialNullRequired,
        index: initialNull,
        changes: initialNull
      }) as FormGroup;

      this.updateValueChangesEventForWholesalePriceFormItem(wholesalePriceForm);

      return wholesalePriceForm;
    }
  }

  updateValueChangesEventForWholesalePriceFormItem(wholesalePriceForm: FormGroup) {
    wholesalePriceForm.controls['wholesalePriceIncVat'].valueChanges
      .pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(wholesalePriceIncVat => {
        if (wholesalePriceForm.controls['index'].value === 0) {
          const wholesalePriceFormDTO = new WholesalePriceFormDTO();
          if (wholesalePriceIncVat !== null) {
            wholesalePriceFormDTO.wholesalePriceIncVat = wholesalePriceIncVat;
            this.calculateWholesalePrice(wholesalePriceFormDTO);
          }

          wholesalePriceForm.patchValue({
            wholesalePriceVat: wholesalePriceFormDTO.wholesalePriceVat,
            wholesalePriceExVat: wholesalePriceFormDTO.wholesalePriceExVat,
            tdGp: wholesalePriceFormDTO.tdGp,
            vatPct: wholesalePriceFormDTO.vatPct
          });
        } else if (wholesalePriceForm.controls['index'].value === 1) {
          // TODO new Wholesale price form
          if (wholesalePriceIncVat || wholesalePriceIncVat === 0 || wholesalePriceForm.controls.effectiveDate.value) {
            wholesalePriceForm.controls.effectiveDate.setValidators(Validators.required);
            wholesalePriceForm.controls.effectiveDate.updateValueAndValidity({ onlySelf: true });
            wholesalePriceForm.controls.wholesalePriceIncVat.setValidators([
              Validators.required,
              this.notAllowZeroValidator()
            ]);
            wholesalePriceForm.controls.wholesalePriceIncVat.updateValueAndValidity({ onlySelf: true });
            if (wholesalePriceIncVat === 0) {
              wholesalePriceForm.controls.wholesalePriceIncVat.setErrors({ isZero: true });
            }
          } else if (!wholesalePriceIncVat && !wholesalePriceForm.controls.effectiveDate.value) {
            wholesalePriceForm.controls.effectiveDate.setValidators(null);
            wholesalePriceForm.controls.wholesalePriceIncVat.setValidators(null);
            wholesalePriceForm.controls.effectiveDate.setErrors(null);
            wholesalePriceForm.controls.wholesalePriceIncVat.setErrors(null);
          }
          const newWholesalePriceFormDTO = new WholesalePriceFormDTO();
          if (wholesalePriceIncVat !== null) {
            newWholesalePriceFormDTO.wholesalePriceIncVat = wholesalePriceIncVat;
            this.calculateWholesalePrice(newWholesalePriceFormDTO);
          }

          wholesalePriceForm.patchValue({
            wholesalePriceVat: newWholesalePriceFormDTO.wholesalePriceVat,
            wholesalePriceExVat: newWholesalePriceFormDTO.wholesalePriceExVat,
            tdGp: newWholesalePriceFormDTO.tdGp,
            vatPct: newWholesalePriceFormDTO.vatPct
          });
        }
      });

    wholesalePriceForm.controls['effectiveDate'].valueChanges
      .pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(effectiveDate => {
        if (wholesalePriceForm.controls['index'].value === 1) {
          if (effectiveDate || wholesalePriceForm.controls.wholesalePriceIncVat.value) {
            wholesalePriceForm.controls.wholesalePriceIncVat.setValidators([
              Validators.required,
              this.notAllowZeroValidator()
            ]);
            wholesalePriceForm.controls.wholesalePriceIncVat.updateValueAndValidity({ onlySelf: true });
            wholesalePriceForm.controls.effectiveDate.setValidators(Validators.required);
            wholesalePriceForm.controls.effectiveDate.updateValueAndValidity({ onlySelf: true });
          } else if (!effectiveDate && !wholesalePriceForm.controls.wholesalePriceIncVat.value) {
            wholesalePriceForm.controls.effectiveDate.setValidators(null);
            wholesalePriceForm.controls.wholesalePriceIncVat.setValidators(null);
            wholesalePriceForm.controls.effectiveDate.setErrors(null);
            wholesalePriceForm.controls.wholesalePriceIncVat.setErrors(null);
          }
        }
      });

    wholesalePriceForm.controls['wholesalePriceExVat'].valueChanges
      .pipe(untilComponentDestroyed(this), distinctUntilChanged())
      .subscribe(wholesalePriceExVat => {
        if (wholesalePriceExVat) {
          // update estimate partner in each barcode
          const retailPriceFormDTO = new RetailPriceFormDTO();
          const isVat = this.productDetailsForm.get('vat').value;
          const vatRate = isVat ? this.getVatRate() : 0;

          retailPriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
          retailPriceFormDTO.vatRate = vatRate;
          if (wholesalePriceForm.controls['index'].value === 0) {
            this.recalculateRetailPriceBarcode(retailPriceFormDTO);
          } else if (wholesalePriceForm.controls['index'].value === 1) {
            this.recalculateScheduleRetailPriceBarcode(retailPriceFormDTO);
          }
        }
      });
  }

  recalculateRetailPriceBarcode(retailPriceFormDTO: RetailPriceFormDTO) {
    this.barcodesForm.controls.forEach(barcodeForm => {
      this.calculateRetailPrice(barcodeForm, retailPriceFormDTO);
      this.updateBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);
    });
  }

  recalculateScheduleRetailPriceBarcode(retailPriceFormDTO: RetailPriceFormDTO) {
    this.barcodesForm.controls.forEach(barcodeForm => {
      this.calculateScheduleRetailPrice(barcodeForm, retailPriceFormDTO);
      this.updateScheduleBarcodeRetailByDTO(retailPriceFormDTO, barcodeForm);
    });
  }

  calculateRetailPrice(barcodeForm: FormGroup, retailPriceFormDTO: RetailPriceFormDTO) {
    const retailPriceIncVat = barcodeForm.get('retailPriceIncVat').value;
    const vatRate = retailPriceFormDTO.vatRate;
    const wholesalePriceExVat = retailPriceFormDTO.wholesalePriceExVat;
    if (retailPriceIncVat) {
      const retailPriceVat = round((vatRate * retailPriceIncVat) / (100 + vatRate), 6);
      const retailPriceExVat = round(retailPriceIncVat - retailPriceVat, 6);
      const unitFactor = barcodeForm.get('unitFactor').value;

      retailPriceFormDTO.retailPriceExVat = retailPriceExVat;
      retailPriceFormDTO.retailPriceVat = retailPriceVat;
      retailPriceFormDTO.estimatePartnerGp = this.calculateEstimatedPartnerGP(
        retailPriceExVat,
        wholesalePriceExVat,
        unitFactor
      );
      retailPriceFormDTO.vatPct = vatRate;
    }
  }

  calculateScheduleRetailPrice(barcodeForm: FormGroup, retailPriceFormDTO: RetailPriceFormDTO) {
    const retailPriceIncVat = barcodeForm.get('scheduleRetailPriceIncVat').value;
    const vatRate = retailPriceFormDTO.vatRate;
    /** use current wholesale price instead of new wholesale price for calculate GP **/
    const wholesalePriceExVat =
      ((this.wholesalePricesForm as FormArray).at(0) as FormGroup).controls.wholesalePriceExVat.value || 0;
    if (retailPriceIncVat) {
      const retailPriceVat = round((vatRate * retailPriceIncVat) / (100 + vatRate), 6);
      const retailPriceExVat = round(retailPriceIncVat - retailPriceVat, 6);
      const unitFactor = barcodeForm.get('unitFactor').value;

      retailPriceFormDTO.retailPriceExVat = retailPriceExVat;
      retailPriceFormDTO.retailPriceVat = retailPriceVat;
      retailPriceFormDTO.estimatePartnerGp = this.calculateEstimatedPartnerGP(
        retailPriceExVat,
        wholesalePriceExVat,
        unitFactor
      );
      retailPriceFormDTO.vatPct = vatRate;
    }
  }

  calculateWholesalePrice(wholesalePriceFormDTO: WholesalePriceFormDTO) {
    const isVat = this.productDetailsForm.get('vat').value;
    const vatRate = isVat ? this.getVatRate() : 0;
    const wholesalePriceIncVat = wholesalePriceFormDTO.wholesalePriceIncVat;

    const wholesalePriceVat = round((vatRate * wholesalePriceIncVat) / (100 + vatRate), 6);
    const wholesalePriceExVat = round(wholesalePriceIncVat - wholesalePriceVat, 6);

    let tdGp = null;
    const maximumNormalPrice = this.assortment.productDetail.tdProduct.maximumNormalPrice;
    if (maximumNormalPrice !== null) {
      tdGp = round(((wholesalePriceExVat - maximumNormalPrice) * 100) / maximumNormalPrice, 6);
    }

    wholesalePriceFormDTO.wholesalePriceVat = wholesalePriceVat;
    wholesalePriceFormDTO.wholesalePriceExVat = wholesalePriceExVat;
    wholesalePriceFormDTO.tdGp = tdGp;
    wholesalePriceFormDTO.vatPct = vatRate;
  }

  addBarcode() {
    this.barcodesForm.push(this.createBarcodeFormItem(null));
  }

  getVatRate() {
    return this.mockIncVatForm.includeVat ? this.systemVat : 0;
  }

  get wholesalePricesForm(): any {
    return this.wholesalePriceForm.get('wholesalePrices') as FormArray;
  }

  get barcodesForm(): any {
    return this.barcodeForm.get('barcodes') as FormArray;
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  subscribeForVersionError() {}

  onSelectTabs(tab: AssortmentPageTabsEnum) {
    this.currentTab = tab;
  }

  createSupplierDetailsForm(assortment: Assortment) {
    const isDisabled = [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(
      this.data.mode
    );
    const initialNullRequired = [{ value: null, disabled: isDisabled }, Validators.required];
    const initialNullDisabled = [{ value: null, disabled: true }];

    if (assortment && assortment.productDetail) {
      this.supplierDetailsForm = this.fb.group({
        maximumNormalPrice: [
          { value: assortment.productDetail.tdProduct.maximumNormalPrice, disabled: isDisabled },
          Validators.required
        ],
        tdMovingAvg: {
          value:
            assortment.productDetail.tdProduct.tdMovingAvg !== null
              ? round(assortment.productDetail.tdProduct.tdMovingAvg, 2)
              : null,
          disabled: true
        }
      });
    } else {
      this.supplierDetailsForm = this.fb.group({
        maximumNormalPrice: initialNullRequired,
        tdMovingAvg: initialNullDisabled
      });
    }

    this.updateValueChangesEventForSupplierDetailsForm(this.supplierDetailsForm);
  }

  createChangesForm(change: ChangeValue[]) {
    if (change && change.length > 0) {
      const changes = {};
      change.forEach(data => {
        changes[data.field] = true;
      });
      return changes;
    }
  }

  updateValueChangesEventForSupplierDetailsForm(supplierDetailsForm: FormGroup) {
    supplierDetailsForm.controls['maximumNormalPrice'].valueChanges.pipe().subscribe(maximumNormalPrice => {
      this.assortment = {
        ...this.assortment,
        productDetail: {
          ...this.assortment.productDetail,
          tdProduct: {
            ...this.assortment.productDetail.tdProduct,
            maximumNormalPrice
          }
        }
      };
      this.recalculateWholesalePrice();
    });
  }

  recalculateWholesalePrice() {
    this.wholesalePricesForm.controls.forEach(wholesaleForm => {
      const wholesalePriceFormDTO = new WholesalePriceFormDTO();
      const wholesalePriceIncVat = wholesaleForm.get('wholesalePriceIncVat').value;

      if (wholesalePriceIncVat !== null) {
        wholesalePriceFormDTO.wholesalePriceIncVat = wholesalePriceIncVat;
        this.calculateWholesalePrice(wholesalePriceFormDTO);
      }

      wholesaleForm.patchValue({
        wholesalePriceVat: wholesalePriceFormDTO.wholesalePriceVat,
        wholesalePriceExVat: wholesalePriceFormDTO.wholesalePriceExVat,
        tdGp: wholesalePriceFormDTO.tdGp,
        vatPct: wholesalePriceFormDTO.vatPct
      });
    });
  }

  onCloseFullModal() {
    if (
      (this.wholesalePriceForm && (this.wholesalePriceForm.touched || this.wholesalePriceForm.dirty)) ||
      (this.barcodeForm && (this.barcodeForm.touched || this.barcodeForm.dirty)) ||
      (this.productDetailsForm && (this.productDetailsForm.touched || this.productDetailsForm.dirty)) ||
      (this.supplierDetailsForm && (this.supplierDetailsForm.touched || this.supplierDetailsForm.dirty))
    ) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  toggleToEdit() {
    if ([AssortmentPageModes.ASSORTMENT_VIEW].includes(this.data.mode)) {
      this.requestAssortmentService
        .checkProductRequestExists(this.assortment.articleNo)
        .pipe(
          untilComponentDestroyed(this),
          map(() => {
            this.modalService.show(AlertModalComponent, {
              initialState: {
                title: 'Failed',
                message: 'This product has been edited and waiting for approval.'
              }
            });
            return false;
          }),
          catchError(() => {
            return of(true);
          })
        )
        .subscribe(isAllowToggleEdit => {
          if (isAllowToggleEdit) {
            this.data.mode = AssortmentPageModes.ASSORTMENT_EDIT;
            this.toggleEnableForm();
          }
        });
    } else {
      /** consider as request edit **/
      this.data.mode = AssortmentPageModes.REQUEST_EDIT;
      this.checkCurrentRequestViewMode();
      this.toggleEnableForm();
      this.checkBarcodeDuplicateForCJProduct();
    }
  }

  toggleEnableForm() {
    this.toggleTitle();
    this.toggleEditForProductDetails();
    this.toggleEditForSupplierDetails();
    this.toggleEditWholesalePriceForm();
    this.toggleEditBarcodeForm();
  }

  toggleEditForProductDetails() {
    const freshLiteCategoryForm = this.productDetailsForm.controls.freshLiteCategory;
    const freshLiteTypeForm = this.productDetailsForm.controls.freshLiteType;
    const deliveryMethodForm = this.productDetailsForm.controls.deliveryMethod;
    const orderingMethods = JSON.parse(this.productDetailsForm.controls.orderingMethods.value);
    this.productDetailsForm.enable();

    if (!this.productDetailsForm.controls.productStatus.value) {
      this.productDetailsForm.controls.productStatus.disable();
    }

    if (![AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode)) {
      this.productDetailsForm.controls.articleType.disable();
    }
    this.productDetailsForm.controls.gradingGroup.disable();
    if (
      AssortmentRequestTypeEnum.EDIT === this.assortment.type ||
      [AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode)
    ) {
      this.productDetailsForm.controls.articleNo.disable();
    }
    if (!this.isNonMerchandise) {
      this.productDetailsForm.controls.allowToRent.disable();
      this.productDetailsForm.controls.rentalPerMonth.disable();
      this.productDetailsForm.controls.productType.disable();
    } else if (this.isNonMerchandise) {
      if (this.useCJProduct && this.hasManageAssetPermission && this.hasManageStoreUsePermission) {
        this.productDetailsForm.controls.productType.enable();
      } else {
        this.productDetailsForm.controls.productType.disable();
      }
    }
    if (!this.productDetailsForm.controls.productType.value) {
      this.productDetailsForm.controls.orderingMethods.disable();
    }

    freshLiteTypeForm.disable();
    if (AssortmentOrderingMethodEnum.FRESH_LITE.includes(orderingMethods)) {
      freshLiteCategoryForm.enable();
      freshLiteCategoryForm.setValidators([Validators.required]);

      deliveryMethodForm.disable();
      deliveryMethodForm.clearValidators();
    } else {
      freshLiteCategoryForm.disable();
      freshLiteCategoryForm.clearValidators();

      deliveryMethodForm.enable();
      deliveryMethodForm.setValidators([Validators.required]);
    }

    /** in case of useCJProduct disable delivery method and supplier group for all case **/
    if (this.assortment.productDetail.tdProduct.useCJProduct) {
      deliveryMethodForm.disable();
      this.productDetailsForm.controls.orderingMethods.disable();
    }

    if ([AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode)) {
      this.productDetailsForm.controls.productType.disable();
      this.productDetailsForm.controls.productStatus.setValidators([Validators.required]);
    }

    if (
      [AssortmentPageModes.ASSORTMENT_EDIT, AssortmentPageModes.REQUEST_EDIT].includes(this.data.mode) &&
      this.assortment.type === 'NEW'
    ) {
      this.productDetailsForm.controls.articleNo.setValidators([Validators.required]);
      this.productDetailsForm.controls.articleNo.setAsyncValidators([
        duplicatedArticleValidator(this.requestAssortmentService, this.assortment.articleNo)
      ]);
    }

    this.updateValueChangesEventForProductDetailsForm();
  }

  toggleEditForSupplierDetails() {
    this.supplierDetailsForm.enable();
  }

  toggleEditWholesalePriceForm() {
    if (
      [AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode) &&
      !this.assortment.productDetail.scheduleWholesalePrice &&
      this.wholesalePricesForm.length === 1
    ) {
      this.addWholesalePriceForm(null);
    }

    if (
      AssortmentPageModes.REQUEST_EDIT === this.data.mode &&
      this.assortment.type === AssortmentRequestTypeEnum.EDIT &&
      this.wholesalePricesForm.length === 1 &&
      this.wholesalePricesForm.length < 2
    ) {
      this.addWholesalePriceForm(null);
    }

    this.wholesalePricesForm.controls.forEach((wholesalePrice, i) => {
      wholesalePrice.controls.wholesalePriceIncVat.enable();
      if (this.wholesalePricesForm.length > 1 && i === 0) {
        wholesalePrice.controls.wholesalePriceIncVat.disable();
      }
      if (i === 1) {
        wholesalePrice.controls.effectiveDate.enable();
      }
      this.updateValueChangesEventForWholesalePriceFormItem(wholesalePrice);
    });
  }

  toggleEditBarcodeForm() {
    this.barcodesForm.controls.forEach(barcode => {
      barcode.controls.id.enable();
      !barcode.controls.tdBarcodeStatus.value &&
      (!barcode.controls.cjBarcode || !barcode.controls.cjBarcode.value || !barcode.controls.cjBarcode.value.id)
        ? barcode.controls.barcode.enable()
        : barcode.controls.barcode.disable();
      barcode.controls.barcodeStatus.enable();
      barcode.controls.tdPickingUnit.enable();
      barcode.controls.barSize.enable();
      barcode.controls.unit.enable();
      barcode.controls.barSize.value === 'S'
        ? barcode.controls.unitFactor.disable()
        : barcode.controls.unitFactor.enable();
      barcode.controls.weight.enable();
      barcode.controls.height.enable();
      barcode.controls.width.enable();
      barcode.controls.length.enable();
      barcode.controls.suggestedRetailPriceIncVat.enable();
      barcode.controls.retailPriceIncVat.enable();
      if (barcode.controls.barcodeStatus.value || barcode.controls.scheduleRetailPriceIncVat.value > 0) {
        barcode.controls.tdBarcodeStatus.enable();
        barcode.controls.retailPriceIncVat.disable();
        barcode.controls.scheduleRetailPriceIncVat.enable();
        barcode.controls.scheduleRetailPriceEffectiveDate.enable();
      }
      if (!barcode.controls.barcodeStatus.value) {
        barcode.controls.tdBarcodeStatus.disable();
      }

      if (['INACTIVE', 'MARK_DELETE'].includes(barcode.controls.barcodeStatus.value)) {
        barcode.controls.barcodeStatus.disable();
        barcode.controls.barcode.disable();
        barcode.controls.tdPickingUnit.disable();
        barcode.controls.tdPickingUnit.setValue(null);
        barcode.controls.barSize.disable();
        barcode.controls.unit.disable();
        barcode.controls.unitFactor.disable();
        barcode.controls.weight.disable();
        barcode.controls.height.disable();
        barcode.controls.width.disable();
        barcode.controls.length.disable();
        barcode.controls.suggestedRetailPriceIncVat.disable();
        barcode.controls.scheduleRetailPriceIncVat.disable();
        barcode.controls.scheduleRetailPriceEffectiveDate.disable();
      }

      this.updateValueChangesEventForBarcodeFormItem(barcode);
    });
  }

  toggleTitle() {
    switch (this.data.mode) {
      case AssortmentPageModes.ASSORTMENT_VIEW:
        this.data.title = 'View Product';
        break;
      case AssortmentPageModes.ASSORTMENT_EDIT:
        this.data.title = 'Edit Product';
        break;
      case AssortmentPageModes.REQUEST_VIEW:
        this.data.title = 'View Product Request';
        break;
      case AssortmentPageModes.REQUEST_EDIT:
        this.data.title = 'Edit Product Request';
        break;
      default:
        this.data.title = 'Create Product Request';
        break;
    }
  }

  prepareData() {
    this.prepareDataForProductDetails();
    this.prepareDataForSupplierDetails();
    this.prepareDataForWholesalePrice();
    this.prepareDataForBarcode();
  }

  prepareDataForSupplierDetails() {
    const supplierDetail = this.supplierDetailsForm.getRawValue();
    const supplierPrices = [];
    const supplierPriceArray = [] as SupplierPrice[];
    if (supplierPrices) {
      supplierPrices.forEach(supplierPrice => {
        const targetSupplierPrice = this.findSupplierPriceById(supplierPrice.id);
        let slicedSupplierPrice: SupplierPrice = {} as SupplierPrice;
        slicedSupplierPrice.supplierCode = supplierPrice.supplier.supplierCode;
        slicedSupplierPrice.supplierName = supplierPrice.supplier.supplierName;
        slicedSupplierPrice.vendorCode = supplierPrice.supplier.supplierCode;
        slicedSupplierPrice.branchNo = supplierPrice.supplier.branchNo;
        slicedSupplierPrice.supplierPrice = supplierPrice.supplierPrice;
        slicedSupplierPrice.supplierUnit = supplierPrice.supplierUnit;
        slicedSupplierPrice.orderBarcode = supplierPrice.orderBarcode;
        slicedSupplierPrice.supplierUnitFactor = supplierPrice.supplierUnitFactor;
        slicedSupplierPrice.effectiveDate = supplierPrice.effectiveDate
          ? convertBkkToUtc(formatDateStartOfDay(supplierPrice.effectiveDate, env.dateTimeNanoSec))
          : null;
        slicedSupplierPrice.expireDate = supplierPrice.expireDate
          ? convertBkkToUtc(formatDateEndOfDay(supplierPrice.expireDate, env.dateTimeNanoSec))
          : null;
        slicedSupplierPrice.warehouses = supplierPrice.warehouses;
        slicedSupplierPrice.isDelete = supplierPrice.isDelete;
        slicedSupplierPrice = {
          ...targetSupplierPrice,
          ...slicedSupplierPrice
        };
        supplierPriceArray.push(slicedSupplierPrice);
      });
      // supplierDetail.specialPrice.changes = null;
      supplierDetail.supplierPrices = supplierPriceArray;
    }
    this.assortment = {
      ...this.assortment,
      productDetail: {
        ...this.assortment.productDetail,
        ...supplierDetail,
        tdProduct: {
          ...this.assortment.productDetail.tdProduct,
          maximumNormalPrice: supplierDetail.maximumNormalPrice
        }
      }
    };
  }

  prepareDataForProductDetails() {
    const productDetailRaw = this.productDetailsForm.getRawValue();

    let previousProductStatus = null;
    if (this.currentProductStatus !== productDetailRaw.productStatus) {
      previousProductStatus = this.currentProductStatus;
    }
    // Images
    let productImages = [];
    if (productDetailRaw.productImages && productDetailRaw.productImages.length > 0) {
      productImages = productDetailRaw.productImages.map(image => {
        return {
          id: image.id,
          filePath: image.filePath
        };
      });
    }

    this.assortment = {
      ...this.assortment,
      articleNo: productDetailRaw.articleNo,
      productName: productDetailRaw.productName,
      productType: productDetailRaw.productType,
      importId: this.manualSupplierPriceDataService.getManualSupplierPrice() ? null : productDetailRaw.importId,
      isSubmitted: this.isSubmitted && !this.isSaveDraft,
      manualAddSupplierPrices: this.manualSupplierPriceDataService.getSupplierPricesData(),
      isUpdatedSupplierPrice:
        this.supplierDetailsReference && this.supplierDetailsReference.isUpdatedSupplierPrice
          ? this.supplierDetailsReference.isUpdatedSupplierPrice
          : false,
      productDetail: {
        ...this.assortment.productDetail,
        tdProduct: {
          ...this.assortment.productDetail.tdProduct,
          articleNo: productDetailRaw.articleNo,
          supplierRefNumber: productDetailRaw.supplierRefNumber,
          productStatus: productDetailRaw.productStatus,
          previousProductStatus: previousProductStatus
            ? previousProductStatus
            : productDetailRaw.previousProductStatus,
          productImages,
          productName: productDetailRaw.productName,
          productDisplayName: productDetailRaw.productDisplayName,
          brand: productDetailRaw.brand,
          vat: productDetailRaw.vat,

          shelfLife: productDetailRaw.shelfLife,
          minimumShelfLife: productDetailRaw.minimumShelfLife,
          standardGp: productDetailRaw.standardGp,
          productTier: productDetailRaw.productTier,
          productType: productDetailRaw.productType,
          productStandard: productDetailRaw.productStandard,
          cbgProduct: productDetailRaw.cbgProduct,
          exclusiveProduct: productDetailRaw.exclusiveProduct,
          productGrading: productDetailRaw.productGrading,
          productRecommend: productDetailRaw.productRecommend,
          estSalesQty: productDetailRaw.estSalesQty,
          estSalesValue: productDetailRaw.estSalesValue,
          sellingChannel: productDetailRaw.sellingChannel,
          restrictedItem: productDetailRaw.restrictedItem,
          restrictedAlcoholSaleTime: productDetailRaw.restrictedAlcohol,
          size: productDetailRaw.size,
          uom: productDetailRaw.uom,
          flavor: productDetailRaw.flavor,
          gradingGroup: productDetailRaw.gradingGroup,
          productLocationGuideline: productDetailRaw.productLocationGuideline,

          segment: productDetailRaw.segment,
          family: productDetailRaw.family,
          classCode: productDetailRaw.classCode,
          subClass: productDetailRaw.subClass,
          articleType: productDetailRaw.articleType,
          allowToRent: productDetailRaw.allowToRent,
          rentalPerMonth: productDetailRaw.rentalPerMonth,
          orderingMethods: JSON.parse(productDetailRaw.orderingMethods),
          freshLiteCategory: productDetailRaw.freshLiteCategory,
          deliveryMethod: productDetailRaw.deliveryMethod,
          supplierArticleNo:
            this.linkedProductArticle && this.linkedProductArticle.articleNo
              ? this.linkedProductArticle.articleNo
              : !this.isDeleteLinkArticle
              ? this.assortment.productDetail.tdProduct.supplierArticleNo
              : null,
          supplierProductName:
            this.linkedProductArticle && this.linkedProductArticle.productName
              ? this.linkedProductArticle.productName
              : !this.isDeleteLinkArticle
              ? this.assortment.productDetail.tdProduct.supplierProductName
              : null,
          warehouses: productDetailRaw.warehouses,
          allowToDestroy: productDetailRaw.allowToDestroy
        }
      }
    };

    if (this.linkedProductArticle) {
      this.assortment = {
        ...this.assortment,
        productDetail: {
          ...this.assortment.productDetail,
          cjProduct: { ...this.linkedProductArticle }
        }
      };
    }

    if ([AssortmentPageModes.ASSORTMENT_EDIT].includes(this.data.mode)) {
      this.assortment = {
        ...this.assortment,
        type: AssortmentRequestTypeEnum.EDIT,
        version: 0,
        id: null,
        articleType: this.data.articleType
      };
    }
  }

  prepareDataForWholesalePrice() {
    const wholesaleRaw = this.wholesalePriceForm.getRawValue();
    if (!this.assortment.productDetail) {
      this.assortment.productDetail = {} as AssortmentProductDetail;
    }
    const effectiveWholesale = wholesaleRaw.wholesalePrices[0];
    const scheduleWholesale = wholesaleRaw.wholesalePrices[1];
    if (effectiveWholesale) {
      const effectiveWholesalePrice = {
        id: effectiveWholesale.id,
        articleNo: this.assortment.articleNo,
        priceIncVat: effectiveWholesale.wholesalePriceIncVat,
        vat: effectiveWholesale.wholesalePriceVat,
        priceExcVat: effectiveWholesale.wholesalePriceExVat,
        tdGp: effectiveWholesale.tdGp,
        gpPct: effectiveWholesale.tdGp,
        vatPct: effectiveWholesale.vatPct
      } as WholesalePrice;

      if (effectiveWholesale.effectiveDate) {
        effectiveWholesalePrice.effectiveDate = moment(effectiveWholesale.effectiveDate)
          .utc()
          .toISOString();
      }
      this.assortment.productDetail.effectiveWholesalePrice = {
        ...this.assortment.productDetail.effectiveWholesalePrice,
        ...effectiveWholesalePrice,
        version:
          this.assortment.productDetail.effectiveWholesalePrice &&
          this.assortment.productDetail.effectiveWholesalePrice.id
            ? this.assortment.productDetail.effectiveWholesalePrice.version
            : null
      };
    }

    if (scheduleWholesale) {
      const scheduleWholesalePrice = {
        id: scheduleWholesale.id,
        articleNo: this.assortment.articleNo,
        priceIncVat: scheduleWholesale.wholesalePriceIncVat,
        vat: scheduleWholesale.wholesalePriceVat,
        priceExcVat: scheduleWholesale.wholesalePriceExVat,
        tdGp: scheduleWholesale.tdGp,
        gpPct: scheduleWholesale.tdGp,
        effectiveDate: dateToStringCriteria(scheduleWholesale.effectiveDate),
        vatPct: scheduleWholesale.vatPct
      } as WholesalePrice;

      this.assortment.productDetail.scheduleWholesalePrice = scheduleWholesalePrice.priceIncVat
        ? {
            ...this.assortment.productDetail.scheduleWholesalePrice,
            ...scheduleWholesalePrice,
            version:
              this.assortment.productDetail.scheduleWholesalePrice &&
              this.assortment.productDetail.scheduleWholesalePrice.id
                ? this.assortment.productDetail.scheduleWholesalePrice.version
                : null
          }
        : null;
    }
  }

  prepareDataForBarcode() {
    const barcodeRaw = this.barcodeForm.getRawValue();
    this.assortment.productDetail.barcodes = barcodeRaw.barcodes.map(barcode => {
      const targetBarcode = this.findBarcodeByTDBarcodeId(barcode.id);
      if (targetBarcode) {
        return {
          tdBarcode: {
            ...targetBarcode.tdBarcode,
            articleNo: this.assortment.articleNo,
            barcode: barcode.barcode,
            barcodeStatus: barcode.tdBarcodeStatus,
            barSize: barcode.barSize,
            unit: barcode.unit,
            unitFactor: barcode.unitFactor,
            weight: barcode.weight,
            height: barcode.height,
            width: barcode.width,
            length: barcode.length,
            suggestedRetailPrice: barcode.suggestedRetailPriceIncVat,
            effectiveRetailPrice: barcode.retailPriceIncVat
              ? {
                  ...targetBarcode.tdBarcode.effectiveRetailPrice,
                  articleNo: this.assortment.articleNo,
                  barcode: barcode.barcode,
                  priceIncVat: barcode.retailPriceIncVat,
                  effectiveDate: barcode.retailPriceEffectiveDate
                    ? `${formatDateStartOfDay(barcode.retailPriceEffectiveDate)}Z`
                    : null,
                  vat: barcode.retailPriceVat,
                  vatPct: barcode.vatPct,
                  priceExcVat: barcode.retailPriceExVat,
                  estimatedPartnerGpPct: barcode.estimatedPartnerGpPct
                }
              : null,
            scheduleRetailPrice: barcode.scheduleRetailPriceIncVat
              ? {
                  ...targetBarcode.tdBarcode.scheduleRetailPrice,
                  articleNo: this.assortment.articleNo,
                  barcode: barcode.barcode,
                  priceIncVat: barcode.scheduleRetailPriceIncVat,
                  effectiveDate: barcode.scheduleRetailPriceEffectiveDate
                    ? `${formatDateStartOfDay(barcode.scheduleRetailPriceEffectiveDate)}Z`
                    : null,
                  vat: barcode.scheduleRetailPriceVat,
                  vatPct: barcode.vatPct,
                  priceExcVat: barcode.scheduleRetailPriceExVat,
                  estimatedPartnerGpPct: barcode.estimatedPartnerGpPct
                }
              : null
          },
          cjBarcode: barcode.cjBarcode
        } as Barcode;
      } else {
        return {
          tdBarcode: {
            articleNo: this.assortment.articleNo,
            barcode: barcode.barcode,
            barcodeStatus: barcode.tdBarcodeStatus,
            barSize: barcode.barSize,
            unit: barcode.unit,
            unitFactor: barcode.unitFactor,
            weight: barcode.weight,
            height: barcode.height,
            width: barcode.width,
            length: barcode.length,
            suggestedRetailPrice: barcode.suggestedRetailPriceIncVat,
            effectiveRetailPrice: barcode.retailPriceIncVat
              ? {
                  articleNo: this.assortment.articleNo,
                  barcode: barcode.barcode,
                  priceIncVat: barcode.retailPriceIncVat,
                  effectiveDate: barcode.retailPriceEffectiveDate
                    ? `${formatDateStartOfDay(barcode.retailPriceEffectiveDate)}Z`
                    : null,
                  vat: barcode.retailPriceVat,
                  vatPct: barcode.vatPct,
                  priceExcVat: barcode.retailPriceExVat,
                  estimatedPartnerGpPct: barcode.estimatedPartnerGpPct
                }
              : null,
            scheduleRetailPrice: barcode.scheduleRetailPriceIncVat
              ? {
                  articleNo: this.assortment.articleNo,
                  barcode: barcode.barcode,
                  priceIncVat: barcode.scheduleRetailPriceIncVat,
                  effectiveDate: barcode.scheduleRetailPriceEffectiveDate
                    ? `${formatDateStartOfDay(barcode.scheduleRetailPriceEffectiveDate)}Z`
                    : null,
                  vat: barcode.scheduleRetailPriceVat,
                  vatPct: barcode.vatPct,
                  priceExcVat: barcode.scheduleRetailPriceExVat,
                  estimatedPartnerGpPct: barcode.estimatedPartnerGpPct
                }
              : null
          },
          cjBarcode: barcode.cjBarcode
        } as Barcode;
      }
    });

    // Assign PickingUnitBarcode
    const pickingUnitBarcode = this.findFinalPickingUnitBarcode();
    if (pickingUnitBarcode) {
      this.assortment.productDetail.tdProduct.pickingUnitBarcode = pickingUnitBarcode.barcode;
    }
  }

  findSupplierPriceById(id: string) {
    let targetSupplierPrice = null;
    if (!this.assortment.productDetail.supplierPrices || !this.assortment.productDetail.supplierPrices.length) {
      return null;
    }
    this.assortment.productDetail.supplierPrices.forEach(supplierPrice => {
      if (id === supplierPrice.id) {
        targetSupplierPrice = supplierPrice;
      }
    });
    return targetSupplierPrice;
  }

  findBarcodeByTDBarcodeId(id: string): Barcode {
    let targetBarcode = null;
    this.assortment.productDetail.barcodes.forEach(barcode => {
      if (id === barcode.tdBarcode.id) {
        targetBarcode = barcode;
      }
    });
    return targetBarcode;
  }

  findFinalPickingUnitBarcode() {
    const barcodeRaw = this.barcodeForm.getRawValue();
    let targetPickingUnitBarcode = null;
    barcodeRaw.barcodes.forEach(barcode => {
      if (barcode.tdPickingUnit) {
        targetPickingUnitBarcode = barcode;
      }
    });
    return targetPickingUnitBarcode;
  }

  findBarcodeFormByBarcode(barcode: string): FormGroup {
    let barcodeFormGroup = null;
    this.barcodesForm.controls.forEach(barcodeForm => {
      const barcodeValue = barcodeForm.get('barcode').value;
      if (barcodeValue === barcode) {
        barcodeFormGroup = barcodeForm;
      }
    });
    return barcodeFormGroup;
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.prepareData();

          if (this.assortment.useCJProduct || this.assortment.productDetail.tdProduct.useCJProduct) {
            // Check CJ barcode duplication
            const barcodeList = [];
            this.assortment.productDetail.barcodes.forEach(barcode => {
              if (!barcode.tdBarcode.id && !barcode.cjBarcode) {
                barcodeList.push(barcode.tdBarcode.barcode);
              }
            });
            if (barcodeList && barcodeList.length > 0) {
              this.store.dispatch(
                new RequestAssortmentCheckCJDuplicateBarcodeRequest({
                  articleNo: this.assortment.articleNo,
                  barcode: barcodeList
                })
              );
            } else {
              this.doSubmitAction();
            }
          } else {
            this.doSubmitAction();
          }
        }
      });
  }

  doSubmitAction() {
    if ([AssortmentRequestStatusEnum.AWAITING_APPROVAL].includes(this.assortmentRequestStatus)) {
      this.store.dispatch(new RequestAssortmentUpdateRequested({ assortment: this.assortment }));
    } else {
      this.store.dispatch(new RequestAssortmentSubmitRequested({ assortment: this.assortment }));
    }
  }

  onSave() {
    this.isSaveDraft = true;
    this.isSubmitted = false;
    this.prepareData();

    if (
      !this.assortment.articleNo ||
      !this.assortment.productDetail.tdProduct.productName ||
      this.productDetailsForm.controls.articleNo.errors
    ) {
      return;
    }
    if (this.assortment.useCJProduct || this.assortment.productDetail.tdProduct.useCJProduct) {
      // Check CJ barcode duplication
      const barcodeList = [];
      this.assortment.productDetail.barcodes.forEach(barcode => {
        if (!barcode.tdBarcode.id && !barcode.cjBarcode) {
          barcodeList.push(barcode.tdBarcode.barcode);
        }
      });
      if (barcodeList && barcodeList.length > 0) {
        this.store.dispatch(
          new RequestAssortmentCheckCJDuplicateBarcodeRequest({
            articleNo: this.assortment.articleNo,
            barcode: barcodeList
          })
        );
      } else {
        this.doSaveAction();
      }
    } else {
      this.doSaveAction();
    }
  }

  doSaveAction() {
    if (this.assortment.id) {
      this.store.dispatch(new RequestAssortmentUpdateRequested({ assortment: this.assortment }));
    } else {
      this.store.dispatch(new RequestAssortmentCreateRequested({ assortment: this.assortment }));
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    this.isSaveDraft = false;
    const formValue = this.productDetailsForm.getRawValue();
    if (formValue.productImages === null || formValue.productImages.length === 0) {
      const msg = 'The image is required field.';
      this.currentTab = this.assortmentPageTabs.PRODUCT_TAB;
      this.showAlertModal(msg);
      return;
    }

    if (this.productDetailsForm.invalid) {
      this.currentTab = this.assortmentPageTabs.PRODUCT_TAB;
      return;
    } else if (this.supplierDetailsForm.invalid) {
      this.currentTab = this.assortmentPageTabs.SUPPLIER_TAB;
      return;
    } else if (this.wholesalePriceForm.invalid || this.barcodeForm.invalid) {
      this.currentTab = this.assortmentPageTabs.PRICE_TAB;
      return;
    } else {
      const pickingUnitBarcode = this.findFinalPickingUnitBarcode();
      const isBarcodeError = this.barcodesForm.controls.some(
        barcode => barcode.controls.barcode.errors && barcode.controls.barcode.errors.duplicatedBarcode === true
      );
      if (isBarcodeError) {
        this.currentTab = this.assortmentPageTabs.PRICE_TAB;
        return;
      }

      if (this.barcodesForm.controls.length === 0) {
        this.currentTab = this.assortmentPageTabs.PRICE_TAB;
        this.showAlertModal('Barcode is required.');
        return;
      } else if (!pickingUnitBarcode) {
        this.currentTab = this.assortmentPageTabs.PRICE_TAB;
        this.showAlertModal('Please select one barcode for TD Picking Unit.');
        return;
      } else if (!this.barcodeSizeSValidator()) {
        this.currentTab = this.assortmentPageTabs.PRICE_TAB;
        this.showAlertModal('Active barcode size S is required.');
        return;
      } else {
        this.assortment.productDetail.tdProduct.pickingUnitBarcode = pickingUnitBarcode.barcode;
        this.handleConfirm();
      }
    }
  }

  resetErrorSupplierPriceDisplay(i: number = null) {
    if (i !== null) {
      Object.keys(this.errorSupplierPriceDisplay).forEach((key, index) => {
        if (index === i) {
          delete this.errorSupplierPriceDisplay[key];
        }
      });
    } else {
      // Clear all errors
      Object.keys(this.errorSupplierPriceDisplay).forEach(key => delete this.errorSupplierPriceDisplay[key]);
    }
  }

  barcodeSizeSValidator() {
    return this.barcodesForm.controls.some(barcode => {
      if (barcode.controls.barcodeStatus.value) {
        return barcode.controls.tdBarcodeStatus.value === 'ACTIVE' && barcode.controls.barSize.value === 'S';
      } else {
        return barcode.controls.barSize.value === 'S';
      }
    });
  }

  getColorStatus(status: string): string {
    return AssortmentRequestStatusEnum[status];
  }

  showAlertModal(message) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  alertSuccessModal(resp: any) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isSaveDraft ? 'The request has been saved.' : 'The request has been sent to approver.',
        routerLink: '/products/request-list'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE, result: resp });
      }
    });
  }

  alertApproveRejectModalSuccess(resp) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: this.isApprove ? 'The request has been approved.' : 'The request has been rejected.',
        routerLink:
          this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS
            ? '/dashboard/my-task'
            : '/products/request-list'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
      }
    });
  }

  approve() {
    this.isApprove = true;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        label: 'Comment',
        isRequiredConfirmMessage: false,
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentApproveRequested({
              id: this.data.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  reject() {
    this.isApprove = false;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentRejectRequested({
              id: this.data.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onDeleteRequest(requestId): void {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentDeleteRequested({
              requestId
            })
          );
        }
      });
  }

  checkBarcodeDuplicateForCJProduct() {
    if (!this.assortment) {
      return;
    }

    this.assortment.productDetail.barcodes.forEach(barcode => {
      if (
        barcode.tdBarcode.barcode &&
        barcode.tdBarcode.barcodeStatus === null &&
        barcode.cjBarcode &&
        barcode.cjBarcode.barcode !== null
      ) {
        this.barcodeService.checkDuplicatedBarcode(barcode.tdBarcode.barcode).subscribe(
          () => {
            const barcodeForm = this.findBarcodeFormByBarcode(barcode.tdBarcode.barcode);

            barcodeForm.get('barcode').setErrors({ duplicatedBarcode: true });
          },
          () => {}
        );
      }
    });
  }

  onCancelRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Product Request Number "${this.assortment.requestNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentCancelRequested({
              id: this.assortment.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  calculateEstimatedPartnerGP(
    retailPriceExVAT: number,
    wholesalePriceExVAT: number,
    barcodeUnitFactor: number,
    precision = 6
  ): number {
    let gp = null;

    if (retailPriceExVAT && retailPriceExVAT > 0 && wholesalePriceExVAT && barcodeUnitFactor) {
      retailPriceExVAT = round(retailPriceExVAT, 2);
      wholesalePriceExVAT = round(wholesalePriceExVAT, 2);

      const percents = ((retailPriceExVAT - wholesalePriceExVAT * barcodeUnitFactor) / retailPriceExVAT) * 100;

      gp = round(percents, precision);
    }
    return gp;
  }

  showLinkCJProductModal(articleNo: string, productName: string) {
    if (!articleNo && productName) {
      return;
    }

    const unlinkCJProductModal = this.modalService.show(AssortmentUnlinkCjProductModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        articleNo: articleNo,
        productName: productName,
        linkedArticleNo: this.linkedProductArticle ? this.linkedProductArticle.articleNo : null,
        linkedProductName: this.linkedProductArticle ? this.linkedProductArticle.productName : null,
        useCJProduct: this.isOriginalCjProduct
      }
    });

    unlinkCJProductModal.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const newArticleNo = unlinkCJProductModal.content.newCJArticle;
          if (newArticleNo) {
            this.useCJProduct = true;
            this.isDeleteLinkArticle = false;
            this.store.dispatch(
              new ProductGetLinkArticleDetailRequestAction(unlinkCJProductModal.content.newCJArticle)
            );
          } else {
            /** consider as delete link **/
            this.copyLinkedArticleToRequestAssortment(null);
            this.useCJProduct = false;
            this.isDeleteLinkArticle = true;
            this.assortment.productDetail.cjProduct = null;
            this.linkedProductArticle = null;
            if (this.currentTab === AssortmentPageTabsEnum.CJ_PRODUCT_DETAILS_TAB) {
              this.changeTab(AssortmentPageTabsEnum.PRODUCT_TAB);
            }
          }
          this.assortment.useCJProduct = this.useCJProduct;
          this.assortment.productDetail.tdProduct.useCJProduct = this.useCJProduct;

          unlinkCJProductModal.hide();
        }
      });
  }

  copyLinkedArticleToRequestAssortment(cjProduct: CJProductLinkArticleDetail) {
    this.productMaster$.subscribe(productMaster => {
      if (cjProduct && productMaster) {
        productMaster.articleNo = cjProduct.articleNo;
        productMaster.productName = cjProduct.productName;
        productMaster.statusCJ = cjProduct.statusCJ;
        productMaster.brand = cjProduct.brand;
        productMaster.supplierName = cjProduct.supplierName;
        productMaster.pickingUnit = cjProduct.pickingUnit;
        productMaster.vat = cjProduct.vat;
        productMaster.restrictedItem = cjProduct.restrictedItem;
        productMaster.shelfLife = cjProduct.shelfLife;
        productMaster.minimumShelfLife = cjProduct.minimumShelfLife;
        productMaster.segmentName = cjProduct.segmentName;
        productMaster.familyName = cjProduct.familyName;
        productMaster.className = cjProduct.className;
        productMaster.subClassName = cjProduct.supplierName;
      }
    });
    this.assortment = {
      ...this.assortment,
      productDetail: {
        ...this.assortment.productDetail,
        cjProduct
      }
    };
    this.matchLinkedArticleBarcode(cjProduct);
  }

  matchLinkedArticleBarcode(cjProduct: CJProductLinkArticleDetail) {
    this.barcodesForm.controls.forEach((barcode, index) => {
      const matchedBarcode =
        cjProduct &&
        cjProduct.barcodes &&
        cjProduct.barcodes.filter(linkedBarcode => barcode.controls.barcode.value === linkedBarcode.barcode);
      if (matchedBarcode && matchedBarcode.length > 0) {
        this.barcodesForm.at(index).controls.cjBarcode.patchValue(matchedBarcode[0]);
      } else {
        this.barcodesForm.at(index).controls.cjBarcode.patchValue(null);
      }
    });
  }

  changeTab(tabName) {
    this.currentTab = tabName;
  }
}

class WholesalePriceFormDTO {
  wholesalePriceIncVat?: number;
  wholesalePriceVat?: number;
  wholesalePriceExVat?: number;
  tdGp?: number;
  vatPct?: number;
  effectiveDate?: Date;
}

class RetailPriceFormDTO {
  wholesalePriceExVat?: number;
  retailPriceIncVat?: number;
  retailPriceVat?: number;
  retailPriceExVat?: number;
  estimatePartnerGp?: number;
  unitFactor?: number;
  vatPct?: number;
  vatRate?: number;
}
