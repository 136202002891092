import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantRequestService } from '../../services/merchant-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import { AddShelfTypeErrorAction, AddShelfTypeResponseAction, FirstLotOrderActionType, ShelfDetailsResponseAction, ShelfItemsResponseAction } from '../actions/first-lot-order.action';
import { LayoutActionLoadError } from '../actions/layout.action';
export class FirstLotOrderEffects {
    constructor(actions$, shelfTypesService, merchantRequestService, logger) {
        this.actions$ = actions$;
        this.shelfTypesService = shelfTypesService;
        this.merchantRequestService = merchantRequestService;
        this.logger = logger;
        this.shelfListRequest$ = this.actions$.pipe(ofType(FirstLotOrderActionType.SHELF_ITEMS_REQUEST), map(action => {
            this.logger.debug(`@Effect ${FirstLotOrderActionType.SHELF_ITEMS_REQUEST}: ` + this.stringify(action));
            return action;
        }), switchMap(() => {
            return this.shelfTypesService.getShelfList().pipe(map(shelfListResponse => {
                return new ShelfItemsResponseAction(shelfListResponse);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.addShelfTypes$ = this.actions$.pipe(ofType(FirstLotOrderActionType.ADD_SHELF_TYPE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${FirstLotOrderActionType.ADD_SHELF_TYPE_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.shelfTypesService.addShelfTypeList(payload).pipe(map(() => new AddShelfTypeResponseAction(payload)), catchError(error => of(new AddShelfTypeErrorAction(error.error))))));
        this.shelfDetailsRequest$ = this.actions$.pipe(ofType(FirstLotOrderActionType.SHELF_DETAILS_REQUEST), map(action => {
            this.logger.debug(`@Effect ${FirstLotOrderActionType.SHELF_DETAILS_REQUEST}: ` + this.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantRequestService.getRequestShelfDetails(payload).pipe(map(shelfDetailResponse => {
                return new ShelfDetailsResponseAction(shelfDetailResponse.shelfTypes);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FirstLotOrderEffects.prototype, "shelfListRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FirstLotOrderEffects.prototype, "addShelfTypes$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FirstLotOrderEffects.prototype, "shelfDetailsRequest$", void 0);
