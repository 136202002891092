import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  Z8ParameterRequestListResponse,
  Z8ParameterRequestListSearchCriteria
} from '../models/z8-parameter-request.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class Z8ParameterRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(
    private readonly http: HttpClient,
    private readonly masterService: MasterService,
    protected readonly logger: NGXLogger
  ) {
    super();
    this.envService = this.env.services.z8ParameterRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(
    criteria: Z8ParameterRequestListSearchCriteria
  ): Observable<Z8ParameterRequestListResponse> {
    const params = this.getParams(criteria);

    return this.http.get<Z8ParameterRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
