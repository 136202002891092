import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';

import * as fromMerchantListResponseState from '../reducers/merchant.reducers';

const selectMerchantListState = (state: AppStates) => state.merchant;

export const selectAllMerchantList = createSelector(selectMerchantListState, fromMerchantListResponseState.selectAll);

export const selectMerchantList = createSelector(
  selectMerchantListState,
  (state: fromMerchantListResponseState.MerchantState) => state
);

export const selectMerchantItem = createSelector(
  selectMerchantListState,
  (state: fromMerchantListResponseState.MerchantState) => state.selected
);

export const selectMerchantListCriteria = createSelector(selectMerchantListState, state => {
  return state.criteriaObject;
});

export const selectMerchantListHistory = createSelector(
  selectMerchantListState,
  (state: fromMerchantListResponseState.MerchantState) => state.auditLogs
);

export const selectMerchantById = createSelector(
  selectMerchantListState,
  (state: fromMerchantListResponseState.MerchantState) => state.selected
);
