import { Component, Input, OnInit } from '@angular/core';
import { GroupingBarcode, SupplierPrice } from '../../../shared/models';

@Component({
  selector: 'app-assortment-cj-product-details',
  templateUrl: './assortment-cj-product-details.component.html',
  styleUrls: ['./assortment-cj-product-details.component.scss']
})
export class AssortmentCjProductDetailsComponent implements OnInit {
  @Input() pickingUnit: string;
  @Input() supplierPrices: SupplierPrice[];
  @Input() groupingBarcodes: GroupingBarcode[];
  constructor() {}

  ngOnInit() {}

  trackCjSupplierPrices(index) {
    return index;
  }
}
