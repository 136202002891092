<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-product"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form">
    <div class="tab-menu d-flex d-block-flex">
      <a class="list-tab-menu active px-5 py-2">
        Product Details
      </a>
    </div>

    <div class="info-header-container">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Article Number:</span>
        </div>
        <div class="info-header-value">
          <span>{{
            ((productMaster$ | async).articleNo ? (productMaster$ | async).articleNo : null) | dashDisplay
          }}</span>
        </div>
      </div>
    </div>

    <app-product-view-header [productMaster$]="productMaster$"></app-product-view-header>

    <hr class="section-divider" />

    <app-assortment-cj-product-details
      [groupingBarcodes]="(productMaster$ | async).groupingBarcodes"
      [supplierPrices]="(productMaster$ | async).cjSupplierPrices"
      [pickingUnit]="(productMaster$ | async).pickingUnit"
    ></app-assortment-cj-product-details>
  </div>
</div>
