import { Pipe, PipeTransform } from '@angular/core';
import { StockMovementTypeEnum } from '../enum/stock-movement-type.enum';

@Pipe({ name: 'stockQuantity' })
export class StockQuantityPipe implements PipeTransform {
  transform(num: any, stockMovementType: StockMovementTypeEnum) {
    if (!num) {
      return 0;
    }

    if (
      [
        StockMovementTypeEnum.DELIVERY_DO,
        StockMovementTypeEnum.CANCELLED_GR,
        StockMovementTypeEnum.GOOD_CLAIMED_CR,
        StockMovementTypeEnum.GOOD_SOLD_RCPT
      ].some(v => v === stockMovementType)
    ) {
      return -Math.abs(num);
    }

    return num;
  }
}
