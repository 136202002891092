import { BaseReport } from './base-report.model';
import { Warehouse } from './request-assortment.model';

export class Z8ResultSearchCriteria {
  page: number;
  size: number;
}

export class Z8ResultResponse {
  content: Z8Result[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class Z8Result {
  id: string;
  warehouse: Warehouse;
  orderDate: string;
  createdDate: string;
  createdBy?: string;
  status: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  reportPath?: string;
}

export class Z8ResultImportCriteria extends BaseReport {
  orderDate: string;
  warehouseCode: string;

  constructor(init?: Partial<Z8ResultImportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
