import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequestStatusEnum } from '../../enum/request-status.enum';
import { RequestStepEnum } from '../../enum/request-step.enum';
import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import { MerchantRequestService } from '../../services/merchant-request.service';
import { MerchantService } from '../../services/merchant.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { FirstLotOrderValidateSubmitRequestAction, MerchantActionType, MerchantApproveErrorAction, MerchantApproveResponseAction, MerchantByIdResponseAction, MerchantCreateSaveErrorAction, MerchantCreateSaveResponseAction, MerchantCreateSubmitErrorAction, MerchantCreateSubmitResponseAction, MerchantDeleteResponseAction, MerchantGetVersionResponseAction, MerchantListHistoryResponseAction, MerchantListResponseAction, MerchantRequestByIdResponseAction, MerchantRequestHistoryResponseAction, MerchantRequestListResponseAction } from '../actions/merchant.actions';
export class MerchantEffects {
    constructor(actions$, merchantRequestService, merchantService, store$, logger) {
        this.actions$ = actions$;
        this.merchantRequestService = merchantRequestService;
        this.merchantService = merchantService;
        this.store$ = store$;
        this.logger = logger;
        this.merchantCreateSave$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug('@Effect Merchant Create Save: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.merchantRequestService.saveRequest(payload).pipe(map(res => new MerchantCreateSaveResponseAction(res)), catchError(err => of(new MerchantCreateSaveErrorAction(err.error))))));
        this.merchantCreateSubmit$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug('@Effect Merchant Create Submit: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.merchantRequestService.submitRequest(payload).pipe(map(res => new MerchantCreateSubmitResponseAction(res)), catchError(err => of(new MerchantCreateSubmitErrorAction(err.error))))));
        this.merchantCreateSaveResponse$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE), tap((resp) => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE}: ` + this.stringify(resp.payload));
        }));
        this.merchantCreateSaveError$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SAVE_ERROR), tap((resp) => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_CREATE_SAVE_ERROR}: ` + this.stringify(resp.payload));
        }));
        this.merchantCreateSubmitResponse$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE), tap((resp) => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE}: ` + this.stringify(resp.payload));
        }));
        this.merchantCreateSubmitError$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR), tap((resp) => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR}: ` + this.stringify(resp.payload));
        }));
        this.searchMerchant$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantService.searchMerchantByCriteria(payload).pipe(map(response => {
                return new MerchantListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchMerchantRequest$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantRequestService.searchByCriteria(payload).pipe(map(merchantRequests => {
                return new MerchantRequestListResponseAction({ merchantRequests });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getMerchantRequestById$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantRequestByIdRequestAction: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantRequestService.getMerchantRequest(payload).pipe(tap(response => {
                this.logger.debug('@Effect MerchantRequestByIdResponseAction: ' + this.stringify(response));
            }), map(merchantRequest => {
                if (merchantRequest.step === RequestStepEnum.FIRST_LOT_ORDER &&
                    merchantRequest.status !== RequestStatusEnum.AWAITING_RESERVATION) {
                    return new FirstLotOrderValidateSubmitRequestAction({
                        merchantRequestView: merchantRequest,
                        validateStore: ValidateStoreEnum.LOAD
                    });
                }
                return new MerchantRequestByIdResponseAction({ merchantRequestView: merchantRequest });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.merchantApprove$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.merchantRequestService.approveRequest(payload).pipe(map(() => new MerchantApproveResponseAction({ isSuccess: true })), catchError(err => of(new MerchantApproveErrorAction(err.error))))));
        this.merchantDelete$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_REQUEST_DELETE_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantDeleteRequestAction: ' + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.merchantRequestService.deleteByRequestId(payload).pipe(map(() => new MerchantDeleteResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.merchantHistory$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantListHistoryRequestAction: ' + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantService.getHistoryLogs(payload).pipe(map(response => new MerchantListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.merchantRequestHistory$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_REQUEST_HISTORY_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantHistoryRequestAction: ' + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantRequestService.getHistoryLogs(payload).pipe(map(response => new MerchantRequestHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getMerchantById$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantByIdRequestAction: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantService.getMerchantById(payload).pipe(tap(response => {
                this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_GET_BY_ID_REQUEST}: ` + this.stringify(response));
            }), map(merchantView => {
                return new MerchantByIdResponseAction({ merchantView });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getMerchantVersionById$ = this.actions$.pipe(ofType(MerchantActionType.MERCHANT_GET_VERSION_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantVersionByIdRequestAction: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantRequestService.getMerchantRequestVersionById(payload).pipe(tap(response => {
                this.logger.debug('@Effect MerchantVersionByIdResponseAction: ' + this.stringify(response));
            }), map(version => {
                return new MerchantGetVersionResponseAction(version);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSaveResponse$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSaveError$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSubmitResponse$", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantCreateSubmitError$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "searchMerchant$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "searchMerchantRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "getMerchantRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MerchantEffects.prototype, "merchantApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "merchantDelete$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "merchantHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "merchantRequestHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "getMerchantById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MerchantEffects.prototype, "getMerchantVersionById$", void 0);
