import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appHexadecimalOnly]' })
export class HexadecimalOnlyDirective {
  private readonly navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  private readonly numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.ctrlKey === true) ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.metaKey === true)
    ) {
      return;
    }

    // Ensure that it is a number and stop the keypress
    //   if (
    //   (e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) &&
    //   (e.keyCode < 65 || e.keyCode > 70) &&
    //   (e.keyCode < 96 || e.keyCode > 105)
    // ) {
    if ((e.shiftKey || e.key < '0' || e.key > '9') && (e.key < 'a' || e.key > 'f') && (e.key < 'A' || e.key > 'f')) {
      e.preventDefault();
    } else if (!this.numbers.includes(e.key.toLocaleLowerCase())) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/[^0-9a-fA-F]/g, ''); // get a hexadecimal-only string
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/[^0-9a-fA-F]/g, ''); // get a hexadecimal-only string
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}
