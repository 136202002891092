import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrderRequestService } from '../../services/order-request.service';
import { ShelfTypesService } from '../../services/shelf-types.service';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { OrderApproveResponseAction, OrderCreateSaveResponseAction, OrderCreateSubmitResponseAction, OrderCreateSubmitResponseErrorAction, OrderRequestActionType, OrderRequestByIdResponseAction, OrderRequestHistoryResponseAction, OrderRequestListResponseAction, OrderRequestShelfSelectionResponseAction } from '../actions/order-request.actions';
import { OrderSelectItemOrderAddAllItem } from '../actions/order-select-item-order.actions';
import { selectAllOrderSelectItemOrder } from '../selectors/order-select-item-order.selector';
export class OrderRequestEffects {
    constructor(actions$, orderRequestService, shelfService, shelfTypesService, logger, store) {
        this.actions$ = actions$;
        this.orderRequestService = orderRequestService;
        this.shelfService = shelfService;
        this.shelfTypesService = shelfTypesService;
        this.logger = logger;
        this.store = store;
        this.searchOrderRequest$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderRequestService.searchByCriteria(payload).pipe(map(response => {
                return new OrderRequestListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderCreateSave$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderRequestService.saveRequest(payload).pipe(map(res => new OrderCreateSaveResponseAction(res)), catchError(error => of(new OrderCreateSubmitResponseErrorAction(error.error))));
        }));
        this.OrderCreateSubmit$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderRequestService.submitRequest(payload).pipe(map(res => new OrderCreateSubmitResponseAction(res)), catchError(error => of(new OrderCreateSubmitResponseErrorAction(error.error))));
        }));
        this.getOrderRequestById$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderRequestService.getOrderById(payload).pipe(map(res => {
                return new OrderRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderApprove$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.orderRequestService.approveRequest(payload).pipe(map(() => new OrderApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.OrderRequestHistory$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.orderRequestService.getHistoryLogs(payload).pipe(map(response => new OrderRequestHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderRequestShelfListRequest$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_SHELF_SELECTION_REQUEST}: ` + this.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfTypesService.getShelfTypeList(payload).pipe(map(shelfListResponse => {
                return new OrderRequestShelfSelectionResponseAction(shelfListResponse.content);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.orderRequestGenerateShelfItemsRequest$ = this.actions$.pipe(ofType(OrderRequestActionType.ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST), map(action => {
            this.logger.debug(`@Effect ${OrderRequestActionType.ORDER_REQUEST_GENERATE_SHELF_ITEM_REQUEST}: ` + this.stringify(action));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfService.generateShelfItems(payload.shelfNo, payload.storeNo).pipe(withLatestFrom(this.store.select(selectAllOrderSelectItemOrder)), map(([response, tdAssortmentOrder]) => {
                tdAssortmentOrder.forEach(order => {
                    const item = response.find(value => value.articleNo === order.articleNo);
                    if (!item) {
                        return;
                    }
                    item.qty += order.qty;
                    item.isAddItem = true;
                    item.allowToDelete = item.allowToDelete || false;
                });
                return new OrderSelectItemOrderAddAllItem({ itemList: this.mappingShelfItemOrder(response) });
            }), catchError(err => of(new LayoutActionLoadError(err))));
        }), catchError(error => of(new LayoutActionLoadError(error))));
    }
    mappingShelfItemOrder(shelfItems) {
        shelfItems.forEach(item => {
            item.totalVat = {
                amount: (item.vatAmount && round(item.vatAmount.amount * item.qty, 2)) || 0,
                currency: (item.vatAmount && item.vatAmount.currency) || 'THB'
            };
            item.amountExcVat = {
                amount: (item.wholesalePriceExcVat && round(item.wholesalePriceExcVat.amount * item.qty, 2)) || 0,
                currency: (item.wholesalePriceExcVat && item.wholesalePriceExcVat.currency) || 'THB'
            };
            item.amount = {
                amount: round(item.amountExcVat.amount + item.totalVat.amount, 2) || 0,
                currency: (item.amountExcVat && item.amountExcVat.currency) || 'THB'
            };
            item.isAddItem = true;
        });
        return shelfItems;
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "searchOrderRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "orderCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "OrderCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "getOrderRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "orderApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "OrderRequestHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "orderRequestShelfListRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderRequestEffects.prototype, "orderRequestGenerateShelfItemsRequest$", void 0);
