import { Action } from '@ngrx/store';
import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import {
  ApproveMerchantRequest,
  ErrorResponse,
  Merchant,
  MerchantCreateRequest,
  MerchantListResponse,
  MerchantListSearchCriteria,
  MerchantRequestId,
  MerchantRequestList,
  MerchantRequestListResponse,
  MerchantRequestListSearchCriteria,
  MerchantRequestResponse,
  MerchantRequestTemplate,
  MerchantRequestViewResponse,
  MerchantViewResponse
} from '../../models';

export enum MerchantActionType {
  MERCHANT_CREATE_SAVE_REQUEST = '[Merchant] Create Merchant Save Request',
  MERCHANT_CREATE_SAVE_RESPONSE = '[Merchant] Create Merchant Save Response',
  MERCHANT_CREATE_SAVE_ERROR = '[Merchant] Create Merchant Save Error',
  MERCHANT_CREATE_SUBMIT_REQUEST = '[Merchant] Create Merchant Submit Request',
  MERCHANT_CREATE_SUBMIT_RESPONSE = '[Merchant] Create Merchant Submit Response',
  MERCHANT_CREATE_SUBMIT_ERROR = '[Merchant] Create Merchant Submit Error',
  MERCHANT_CREATE_RESET = '[Merchant] Create Merchant Reset',
  MERCHANT_REQUEST_LIST_REQUEST = '[Merchant] Request List Request',
  MERCHANT_REQUEST_LIST_RESPONSE = '[Merchant] Request List Response',
  MERCHANT_REQUEST_LIST_ERROR = '[Merchant] Request List Error',
  MERCHANT_REQUEST_GET_BY_ID_REQUEST = '[Merchant] Get Merchant Request by Id Request',
  MERCHANT_REQUEST_GET_BY_ID_RESPONSE = '[Merchant] Get Merchant Request by Id Response',
  MERCHANT_REQUEST_GET_BY_ID_ERROR = '[Merchant] Get Merchant Request by Id Error',
  MERCHANT_REQUEST_GET_BY_ID_RESET = '[Merchant] Get Merchant Request by Id Reset',
  MERCHANT_REQUEST_APPROVE_REQUEST = '[Merchant] Request Approve Merchant request',
  MERCHANT_REQUEST_APPROVE_RESPONSE = '[Merchant] Request Approve Merchant Response',
  MERCHANT_REQUEST_APPROVE_ERROR = '[Merchant] Request Approve Merchant Error',
  MERCHANT_REQUEST_DELETE_REQUEST = '[Merchant] Request Delete Merchant request',
  MERCHANT_REQUEST_DELETE_RESPONSE = '[Merchant] Request Delete Merchant Response',
  MERCHANT_REQUEST_DELETE_RESET = '[Merchant] Request Delete Merchant Reset',
  MERCHANT_REQUEST_RESET = '[Merchant] Create Merchant Request Reset',
  MERCHANT_REQUEST_HISTORY_REQUEST = '[Merchant] Request History Request',
  MERCHANT_REQUEST_HISTORY_RESPONSE = '[Merchant] Request History Response',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST = '[Merchant] Submit First Lot Order Request',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE = '[Merchant] Submit First Lot Order Respoonse',
  VALIDATE_SUBMIT_FIRST_LOT_ORDER_ERROR = '[Merchant] Submit First Lot Order Error',
  MERCHANT_LIST_REQUEST = '[Merchant] Merchant List Request',
  MERCHANT_LIST_RESPONSE = '[Merchant] Merchant List Response',
  MERCHANT_LIST_ERROR = '[Merchant] Merchant List Error',
  MERCHANT_GET_BY_ID_REQUEST = '[Merchant] Get Merchant by Id Request',
  MERCHANT_GET_BY_ID_RESPONSE = '[Merchant] Get Merchant by Id Response',
  MERCHANT_GET_BY_ID_ERROR = '[Merchant] Get Merchant by Id Error',
  MERCHANT_GET_BY_ID_RESET = '[Merchant] Get Merchant by Id Reset',
  MERCHANT_LIST_HISTORY_REQUEST = '[Merchant] List History Request',
  MERCHANT_LIST_HISTORY_RESPONSE = '[Merchant] List History Response',
  MERCHANT_GET_VERSION_REQUEST = '[Merchant] Get Merchant Version Request',
  MERCHANT_GET_VERSION_RESPONSE = '[Merchant] Get Merchant Version Response'
}

export class MerchantCreateSaveRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SAVE_REQUEST;

  constructor(public payload: MerchantCreateRequest) {}
}

export class MerchantCreateSaveResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE;

  constructor(public payload: MerchantRequestResponse) {}
}

export class MerchantCreateSaveErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SAVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class MerchantCreateSubmitRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SUBMIT_REQUEST;

  constructor(public payload: MerchantCreateRequest) {}
}

export class MerchantCreateSubmitResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: MerchantRequestResponse) {}
}

export class MerchantCreateSubmitErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class MerchantCreateResetAction implements Action {
  readonly type = MerchantActionType.MERCHANT_CREATE_RESET;

  constructor() {}
}

export class MerchantRequestListRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST;

  constructor(public payload: MerchantRequestListSearchCriteria) {}
}

export class MerchantRequestListResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_LIST_RESPONSE;

  constructor(public payload: { merchantRequests: MerchantRequestListResponse }) {}
}

export class MerchantRequestListErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class MerchantRequestByIdRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: MerchantRequestId) {}
}

export class MerchantRequestByIdResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: { merchantRequestView: MerchantRequestViewResponse }) {}
}

export class MerchantRequestByIdErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetMerchantRequestSelected implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_RESET;
}

export class MerchantApproveRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveMerchantRequest) {}
}

export class MerchantApproveResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class MerchantApproveErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_APPROVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class MerchantDeleteRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_DELETE_REQUEST;

  constructor(public payload: MerchantRequestId) {}
}

export class MerchantDeleteResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class MerchantDeleteResetAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_DELETE_RESET;

  constructor() {}
}

export class MerchantRequestResetAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_RESET;

  constructor() {}
}

export class MerchantRequestHistoryRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_HISTORY_REQUEST;

  constructor(public payload: MerchantRequestId) {}
}

export class MerchantRequestHistoryResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_REQUEST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class MerchantListRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_LIST_REQUEST;

  constructor(public payload: MerchantListSearchCriteria) {}
}

export class MerchantListResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_LIST_RESPONSE;

  constructor(public payload: MerchantListResponse) {}
}

export class MerchantListErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class MerchantByIdRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_BY_ID_REQUEST;

  constructor(public payload: Merchant) {}
}

export class MerchantByIdResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_BY_ID_RESPONSE;

  constructor(public payload: { merchantView: MerchantViewResponse }) {}
}

export class MerchantByIdErrorAction implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetMerchantSelected implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_BY_ID_RESET;
}

export class MerchantListHistoryRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_LIST_HISTORY_REQUEST;

  constructor(public payload: Merchant) {}
}

export class MerchantListHistoryResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class FirstLotOrderValidateSubmitRequestAction implements Action {
  readonly type = MerchantActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST;

  constructor(
    public payload: {
      merchantRequestView: MerchantRequestTemplate; // MerchantRequestViewResponse | MerchantRequestTemplate;
      validateStore: ValidateStoreEnum;
    }
  ) {}
}

export class FirstLotOrderValidateSubmitResponseAction implements Action {
  readonly type = MerchantActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE;

  constructor(public payload: { merchantRequest: MerchantRequestList }) {}
}

export class FirstLotOrderValidateSubmitErrorAction implements Action {
  readonly type = MerchantActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_ERROR;

  constructor() {}
}

export class MerchantGetVersionRequestAction implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_VERSION_REQUEST;

  constructor(public payload: MerchantRequestId) {}
}

export class MerchantGetVersionResponseAction implements Action {
  readonly type = MerchantActionType.MERCHANT_GET_VERSION_RESPONSE;

  constructor(public payload: any) {}
}

export type MerchantActions =
  | MerchantCreateSaveRequestAction
  | MerchantCreateSaveResponseAction
  | MerchantCreateSaveErrorAction
  | MerchantCreateSubmitRequestAction
  | MerchantCreateSubmitResponseAction
  | MerchantCreateSubmitErrorAction
  | MerchantCreateResetAction
  | MerchantRequestListRequestAction
  | MerchantRequestListResponseAction
  | MerchantRequestListErrorAction
  | MerchantRequestByIdRequestAction
  | MerchantRequestByIdResponseAction
  | MerchantRequestByIdErrorAction
  | ResetMerchantRequestSelected
  | MerchantApproveRequestAction
  | MerchantApproveResponseAction
  | MerchantApproveErrorAction
  | MerchantDeleteRequestAction
  | MerchantDeleteResponseAction
  | MerchantDeleteResetAction
  | MerchantRequestResetAction
  | MerchantRequestHistoryRequestAction
  | MerchantRequestHistoryResponseAction
  | FirstLotOrderValidateSubmitRequestAction
  | FirstLotOrderValidateSubmitResponseAction
  | FirstLotOrderValidateSubmitErrorAction
  | MerchantRequestHistoryResponseAction
  | MerchantListRequestAction
  | MerchantListResponseAction
  | MerchantListErrorAction
  | MerchantListHistoryRequestAction
  | MerchantListHistoryResponseAction
  | MerchantByIdRequestAction
  | MerchantByIdResponseAction
  | MerchantByIdErrorAction
  | ResetMerchantSelected
  | MerchantGetVersionRequestAction
  | MerchantGetVersionResponseAction;
