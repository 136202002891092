import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StockInformationService } from '../../services/stock-information.service';
import {
  AdjustStockByIdRequestAction,
  AdjustStockByIdResponseAction,
  AdjustStockImportSubmitRequestAction,
  AdjustStockImportSubmitResponseAction,
  AdjustStockItemListRequestAction,
  AdjustStockItemListResponseAction,
  AdjustStockListRequestAction,
  AdjustStockListResponseAction,
  AdjustStockRequestAction,
  AdjustStockRequestValidateAction,
  AdjustStockResponseErrorAction,
  AdjustStockResponseValidateAction,
  AdjustStockTypeActions
} from '../actions/adjust-stock.actions';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';

@Injectable()
export class AdjustStockEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly stockInformationService: StockInformationService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchAdjustList$ = this.actions$.pipe(
    ofType<AdjustStockListRequestAction>(AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService.searchAdjustByCriteria(payload).pipe(
        map(requests => {
          return new AdjustStockListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getAdjustStockById$ = this.actions$.pipe(
    ofType<AdjustStockByIdRequestAction>(AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Get AdjustStock By Id: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.stockInformationService.getAdjustStockByDocNo(action.payload).pipe(
        map(response => {
          return new AdjustStockByIdResponseAction(response);
        }),
        catchError(err => of(new LayoutActionLoadError(err.error)))
      );
    })
  );

  @Effect()
  getAdjustStockItemById$ = this.actions$.pipe(
    ofType<AdjustStockItemListRequestAction>(AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService
        .getAdjustStockItemCriteria(payload.docNo, payload.adjustStockItemCriteria)
        .pipe(
          map(requests => {
            return new AdjustStockItemListResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
    })
  );

  @Effect()
  validate$ = this.actions$.pipe(
    ofType<AdjustStockRequestValidateAction>(AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_REQUEST),
    tap(action => this.logger.debug('@Effect AdjustStock validate: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.stockInformationService.validateAdjustData(action.payload).pipe(
        map(() => {
          return new AdjustStockResponseValidateAction({ validatePass: true });
        }),
        catchError(err => {
          return this.errorHandling(err);
        })
      );
    })
  );

  @Effect()
  submit$ = this.actions$.pipe(
    ofType<AdjustStockRequestAction>(AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_REQUEST),
    tap(action => this.logger.debug('@Effect AdjustStock submit: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.stockInformationService.submitAdjustStock(action.payload).pipe(
        map(() => {
          return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The data have been saved.'
          });
        }),
        catchError(err => {
          return this.errorHandling(err);
        })
      );
    })
  );

  @Effect()
  submitImport$ = this.actions$.pipe(
    ofType<AdjustStockImportSubmitRequestAction>(AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_REQUEST),
    tap(action => this.logger.debug('@Effect AdjustStock import submit: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.stockInformationService.submitDataImportAdjustsStock(action.payload).pipe(
        map(result => new AdjustStockImportSubmitResponseAction(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      );
    })
  );

  errorHandling(err: any) {
    return err && err.error && this.checkErrorCode(err.error.code)
      ? of(
          new AdjustStockResponseErrorAction({
            ...err.error
          })
        )
      : of(new LayoutActionLoadError(err));
  }

  checkErrorCode(errorCode: string) {
    return ['00004', '00001'].includes(errorCode);
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
