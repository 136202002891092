<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
  </div>
  <div class="modal-body text-center" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="confirm()" id="alert-modal-okBtn">
      {{ okText }}
    </button>
  </div>
</div>
