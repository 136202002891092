<div id="order-details" *ngIf="viewOrder$ | async as order">
  <div class="mt-3 table-responsive">
    <table id="datatable" class="table table-striped table-bordered table-hover border-none w-100">
      <thead>
        <tr>
          <th class="text-center align-top">No.</th>
          <th class="text-center align-top">Product Name</th>
          <th class="text-center align-top">Article No.</th>
          <th class="text-center align-top">Barcode</th>
          <th class="text-center align-top">Product Type</th>
          <th class="text-center align-top">Unit</th>
          <th class="text-center align-top">Unit Price</th>
          <th class="text-center align-top">Quantity</th>
          <th class="text-center align-top total-amount-bg">Amount (THB)</th>
          <th class="text-center align-top">Item Status</th>
          <th class="text-center align-top w-10">Remark</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paging.currentPageData; index as i">
          <td class="text-center">{{ paging.getItemNo(i) }}</td>
          <td>{{ item.productName }}</td>
          <td class="text-left">{{ item.articleNo }}</td>
          <td class="text-left">{{ item.barcode }}</td>
          <td class="text-left">{{ 'PRODUCT_ASSORTMENT.PRODUCT_TYPE.' + item.productType | translate }}</td>
          <td class="text-center">{{ item.unit }}</td>
          <td class="text-right">{{ item.wholesalePrice | numberFormatDisplay }}</td>
          <td class="text-center">{{ item.qty | numberFormatDisplay: 0 }}</td>
          <td class="text-right total-amount-bg">{{ item.amount | numberFormatDisplay }}</td>
          <td class="text-center">{{ getItemStatus(item.itemStatus) }}</td>
          <td class="text-left">{{ item.reason }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <app-ui-pagination
    #paging
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [items]="(viewOrder$ | async).items"
    [valueChangeDetector]="viewOrder$ | async"
  >
  </app-ui-pagination>
</div>
