import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { MerchantList, MerchantListSearchCriteria, RouteLinkTab } from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { MerchantRequestService } from '../../../shared/services/merchant-request.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import {
  MerchantListHistoryRequestAction,
  MerchantListHistoryResponseAction,
  MerchantListRequestAction
} from '../../../shared/store/actions/merchant.actions';
import { MerchantState } from '../../../shared/store/reducers/merchant.reducers';
import {
  selectAllMerchantList,
  selectMerchantList,
  selectMerchantListCriteria,
  selectMerchantListHistory
} from '../../../shared/store/selectors/merchant.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ViewMerchantComponent } from '../view-merchant/view-merchant.component';
@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent extends BaseSearchComponent<
  MerchantListSearchCriteria,
  MerchantList,
  MerchantState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public dateFormat = environment.dateFormat;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public merchantStatusList = filterDropdown.merchantStatusFilter;

  public auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected merchantRequestService: MerchantRequestService
  ) {
    super(store, modalService, selectAllMerchantList, selectMerchantList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectMerchantListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.auditLogs$ = this.localStore.pipe(select(selectMerchantListHistory));
  }

  doDestroy() {}

  get pageMode() {
    return RequestPageModesEnum;
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.merchantStatusList[0].value],
      startCreatedDate: [null],
      endCreatedDate: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startCreatedDate: null,
      endCreatedDate: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: null,
      endCreatedDate: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.startCreatedDate && !formValue.endCreatedDate) {
      return;
    }

    let dateFrom = this.searchForm.value.startCreatedDate;
    let dateTo = this.searchForm.value.endCreatedDate;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: dateFrom,
      endCreatedDate: dateTo,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;

    const startCreatedDate = dateStringToTagCriteria(this.criteriaObject.startCreatedDate);
    const endCreatedDate = dateStringToTagCriteria(this.criteriaObject.endCreatedDate);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: startCreatedDate,
      dateTo: endCreatedDate
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new MerchantListRequestAction(criteriaObj));
  }

  handleEdit(merchantNo: string) {
    this.merchantRequestService
      .getMerchantValidate(TDStoreValidatorTypeEnum.REQUESTED, merchantNo)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        res => {
          if (res.body.allowToEdit) {
            this.goToView(RequestPageModesEnum.REQUEST_EDIT, merchantNo);
          } else {
            const initialState = {
              title: 'Alert',
              message: 'Another request is awaiting approval.'
            };

            this.modalService.show(AlertModalComponent, {
              initialState
            });
          }
        },
        error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      );
  }

  goToView(mode: RequestPageModesEnum, id?: string) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewMerchantComponent,
        {
          title: mode === RequestPageModesEnum.REQUEST_EDIT ? 'Edit Merchant' : 'View Merchant',
          mode,
          merchant: id
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  showHistory(merchantNo: string) {
    this.store.dispatch(new MerchantListHistoryRequestAction({ merchant: merchantNo }));
    const initialState = {
      title: 'History',
      historyHeader: `Merchant ID: ${merchantNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.MERCHANT,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new MerchantListHistoryResponseAction({ auditLogs: null }));
    });
  }

  hasMerchantEditPermission() {
    return this.authGuardService.checkPermission(['merchant_edit_m']);
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission([
      'merchant_v',
      'merchant_vs',
      'merchant_key',
      'merchant_edit_m'
    ]);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'merchant_new_m',
      'merchant_edit_m',
      'merchant_order_m',
      'merchant_app'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Merchant List', url: '/merchant-store/merchant-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Merchant Request', url: '/merchant-store/merchant-request-list' });
    }
  }
}
