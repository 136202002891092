import { createSelector } from '@ngrx/store';
import * as fromFreshLite from '../reducers/fresh-lite.reducers';
import { AppStates } from '../state/app.states';

const selectFreshLiteState = (state: AppStates) => state.freshLite;

export const selectFreshLiteList = createSelector(selectFreshLiteState, fromFreshLite.selectAll);

export const selectFreshLiteDuplicateName = createSelector(selectFreshLiteState, state => {
  return state.duplicate;
});
