import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LayoutActionErrorUpdate } from '../store/actions/layout.action';
import { AppStates } from '../store/state/app.states';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly store: Store<AppStates>, protected readonly logger: NGXLogger) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError(err => this.handleError(err, request))
    );
  }

  handleError(err: any, request): Observable<any> {
    if (err.error) {
      let obs: Observable<any>;

      if (err.error instanceof Blob && (err.status === 400 || err.status === 404)) {
        // Parser Blob error case
        const reader: FileReader = new FileReader();

        obs = new Observable((observer: any) => {
          reader.onloadend = e => {
            if (typeof e.target['result'] === 'string') {
              err.error = JSON.parse(e.target['result']); // srcElement is deprecated then we use target
            }

            observer.error(err);
            observer.complete();
          };
        });

        reader.readAsText(err.error);
      } else {
        // Parser general error case
        const isApiError =
          err.status === 503 &&
          !request.url.includes(environment.services.deliveryOrder.importPicking) &&
          !request.url.includes(environment.services.auth.url);

        if (isApiError) {
          this.store.dispatch(new LayoutActionErrorUpdate(true));
        }

        if (typeof err.error === 'string') {
          err.error = JSON.parse(err.error);
        }

        err.error.translateKey = `ERROR_CODE.${err.error.code}`;

        obs = new Observable((observer: any) => {
          observer.error(err);
          observer.complete();
        });
      }

      return obs;
    }

    return throwError(err);
  }
}
