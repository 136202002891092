import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ConditionsService } from '../../services/z8-conditions.service';
import { Z8ResultService } from '../../services/z8-result.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { Z8ResultActionType, Z8ResultHistoryResponseAction, Z8ResultResponseAction } from '../actions/z8-result.actions';
export class Z8ResultEffects {
    constructor(actions$, z8ConditionsService, z8ResultService, logger) {
        this.actions$ = actions$;
        this.z8ConditionsService = z8ConditionsService;
        this.z8ResultService = z8ResultService;
        this.logger = logger;
        this.searchZ8Result$ = this.actions$.pipe(ofType(Z8ResultActionType.Z8_RESULT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${Z8ResultActionType.Z8_RESULT_REQUEST}: ` + Z8ResultEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.z8ConditionsService.searchResultByCriteria(payload).pipe(map(response => {
                return new Z8ResultResponseAction(response);
            }), catchError(error => {
                return of(new LayoutActionLoadError(error));
            }));
        }));
        this.z8ResultHistory$ = this.actions$.pipe(ofType(Z8ResultActionType.Z8_RESULT_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${Z8ResultActionType.Z8_RESULT_HISTORY_REQUEST}: ` + Z8ResultEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.z8ResultService.getHistoryLogs(payload).pipe(map(response => new Z8ResultHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    static stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], Z8ResultEffects.prototype, "searchZ8Result$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], Z8ResultEffects.prototype, "z8ResultHistory$", void 0);
