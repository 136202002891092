/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./product-change-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./product-change-modal.component";
import * as i4 from "ngx-bootstrap/modal";
var styles_ProductChangeModalComponent = [i0.styles];
var RenderType_ProductChangeModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductChangeModalComponent, data: {} });
export { RenderType_ProductChangeModalComponent as RenderType_ProductChangeModalComponent };
function View_ProductChangeModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Data Changed."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This data has been updated. Please verify data before submit."]))], null, null); }
function View_ProductChangeModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalid Data."])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Some items has invalid data. Please remove item from list."]))], null, null); }
export function View_ProductChangeModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h6", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alert"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["id", "confirm-modal-closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "table", [["class", "table table-striped table-bordered table-hover border-none"], ["id", "prItemErrorDetails"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Subject"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Action"])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductChangeModalComponent_1)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProductChangeModalComponent_2)), i1.ɵdid(19, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["id", "okBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isChange; _ck(_v, 17, 0, currVal_0); var currVal_1 = _co.isInvalidData; _ck(_v, 19, 0, currVal_1); }, null); }
export function View_ProductChangeModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-product-change-modal", [], null, null, null, View_ProductChangeModalComponent_0, RenderType_ProductChangeModalComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProductChangeModalComponent, [i4.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductChangeModalComponentNgFactory = i1.ɵccf("app-product-change-modal", i3.ProductChangeModalComponent, View_ProductChangeModalComponent_Host_0, {}, {}, []);
export { ProductChangeModalComponentNgFactory as ProductChangeModalComponentNgFactory };
