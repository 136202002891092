/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./boxs-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../pipes/number-display.pipe";
import * as i4 from "../../pipes/dash-display.pipe";
import * as i5 from "./boxs-display.component";
var styles_BoxsDisplayComponent = [i0.styles];
var RenderType_BoxsDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BoxsDisplayComponent, data: {} });
export { RenderType_BoxsDisplayComponent as RenderType_BoxsDisplayComponent };
function View_BoxsDisplayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "amount"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2)], function (_ck, _v) { var currVal_0 = "amount"; var currVal_1 = (_v.parent.context.$implicit.className ? _v.parent.context.$implicit.className : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.value, 0)); _ck(_v, 3, 0, currVal_2); }); }
function View_BoxsDisplayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "amount"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1)], function (_ck, _v) { var currVal_0 = "amount"; var currVal_1 = (_v.parent.context.$implicit.className ? _v.parent.context.$implicit.className : ""); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.value)); _ck(_v, 3, 0, currVal_2); }); }
function View_BoxsDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "information-box d-flex flex-row flex-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "infor-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "d-flex flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxsDisplayComponent_3)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxsDisplayComponent_4)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isNumber(_v.context.$implicit.value); _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.isNumber(_v.context.$implicit.value); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.icon, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.title; _ck(_v, 5, 0, currVal_1); }); }
function View_BoxsDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxsDisplayComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boxs; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BoxsDisplayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.NumberFormatDisplayPipe, []), i1.ɵpid(0, i4.DashDisplayPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BoxsDisplayComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.boxs && (_co.boxs.length > 0)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BoxsDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-boxs-display", [], null, null, null, View_BoxsDisplayComponent_0, RenderType_BoxsDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i5.BoxsDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BoxsDisplayComponentNgFactory = i1.ɵccf("app-boxs-display", i5.BoxsDisplayComponent, View_BoxsDisplayComponent_Host_0, { boxs: "boxs" }, {}, []);
export { BoxsDisplayComponentNgFactory as BoxsDisplayComponentNgFactory };
