import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MasterData } from '../../models';
import { MerchantSelectValueActions, MerchantSelectValueActionType } from '../actions/merchant-select-value.actions';

export interface MerchantSelectValueState extends EntityState<MasterData> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<MasterData> = createEntityAdapter<MasterData>();

export const initialMerchantSelectValueState: MerchantSelectValueState = adapter.getInitialState({
  isLoading: false
});

export function merchantSelectValueReducers(
  state = initialMerchantSelectValueState,
  action: MerchantSelectValueActions
): MerchantSelectValueState {
  if (action.type === MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_RESPONSE) {
    return adapter.addAll(action.payload, {
      ...state,
      isLoading: true
    });
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
