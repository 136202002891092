import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { VoucherService } from '../../services/voucher.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  VoucherActionTypes,
  VoucherByVoucherCodeRequestAction,
  VoucherByVoucherCodeResponseAction,
  VoucherCancelAction,
  VoucherListRequestAction,
  VoucherListResponseAction
} from '../actions/voucher.actions';

@Injectable()
export class VoucherEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly voucherService: VoucherService
  ) {}

  @Effect()
  searchVoucherRequest$ = this.actions$.pipe(
    ofType<VoucherListRequestAction>(VoucherActionTypes.VOUCHER_LIST_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${VoucherActionTypes.VOUCHER_LIST_REQUEST}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.voucherService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          return new VoucherListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  cancelVoucher$ = this.actions$.pipe(
    ofType<VoucherCancelAction>(VoucherActionTypes.VOUCHER_CANCEL),
    tap(action => this.logger.debug('@Effect Voucher Cancel: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Your voucher has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getVoucherByVoucherCode$ = this.actions$.pipe(
    ofType<VoucherByVoucherCodeRequestAction>(VoucherActionTypes.VOUCHER_GET_BY_CODE_REQUEST),
    tap(action => this.logger.debug('@Effect Voucher By VoucherCode Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherService.getVoucherByVoucherCode(action.payload.voucherCode).pipe(
        map(VoucherView => {
          return new VoucherByVoucherCodeResponseAction({ VoucherView });
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
