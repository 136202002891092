import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierStorePagination } from '../models/supplier-group-request.model';
import { BaseService } from './base.service';

@Injectable()
export class SupplierStoreService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.supplierStore;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: any): Observable<SupplierStorePagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<SupplierStorePagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
