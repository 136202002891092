import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ProductActionTypes,
  ProductListRequestAction,
  ProductListSelectStatusRequestAction,
  ProductListSelectStatusSuccessAction,
  ProductListSuccessAction
} from '../actions/product.action';
import {
  ProductGetLinkArticleDetailRequestAction,
  ProductGetLinkArticleDetailResponseAction,
  RequestAssortmentActionTypes
} from '../actions/request-assortment.actions';
import { selectProductsCheckList } from '../selectors/product-selector';
import { AppStates } from '../state/app.states';

@Injectable()
export class ProductEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly productService: ProductService,
    private readonly logger: NGXLogger,
    private readonly store$: Store<AppStates>
  ) {}

  @Effect()
  products$: Observable<Action> = this.actions$.pipe(
    ofType<ProductListRequestAction>(ProductActionTypes.PRODUCTS_REQUEST),
    tap(action => this.logger.debug('@Effect ProductListRequestAction: ' + this.stringify(action.payload))),
    withLatestFrom(this.store$.select(selectProductsCheckList)),
    switchMap(([action, selected]) => {
      return this.productService.searchByCriteria(action.payload).pipe(
        map(res => {
          res.content.map(item => {
            if (selected && selected.hasOwnProperty(item.articleNo) && selected[item.articleNo]) {
              item.checked = true;
            }
            return item;
          });
          return new ProductListSuccessAction(res);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      );
    })
  );

  @Effect()
  productsSelectStatus$ = this.actions$.pipe(
    ofType<ProductListSelectStatusRequestAction>(ProductActionTypes.PRODUCTS_SELECT_REQUEST),
    tap(action => this.logger.debug('@Effect Product List Change Status Request: ' + this.stringify(action.payload))),
    switchMap(action => {
      return this.productService.updateSelectStatus(action.payload).pipe(
        // Waiting for sync data to elastic search
        delay(800),
        map(response => new ProductListSelectStatusSuccessAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getArticleDetailForLink$ = this.actions$.pipe(
    ofType<ProductGetLinkArticleDetailRequestAction>(RequestAssortmentActionTypes.LinkArticleDetailRequest),
    tap(action => this.logger.debug('@Effect Get CJ Article Detail Request: ' + this.stringify(action.payload))),
    switchMap(action => {
      return this.productService.getCJArticleData(action.payload).pipe(
        map(response => new ProductGetLinkArticleDetailResponseAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
