import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileModuleEnum } from '../../../enum/file-url.enum';
import { RequestSectionEnum } from '../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse } from '../../../models';
import { AuthGuardService } from '../../../services';
import { MasterService } from '../../../services/master.service';
import { AppStates } from '../../../store/state/app.states';
import { getFileUploadObj } from '../../../utils/get-file-name-from-ref-id-util';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../utils/td-store-workflow-util';

@Component({
  selector: 'app-legal-document',
  templateUrl: './legal-document.component.html',
  styleUrls: ['./legal-document.component.scss']
})
export class LegalDocumentComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;
  private requestedBy: string;

  private localStore: Observable<any>;
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  public banks: any[];

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected masterService: MasterService,
    private readonly authGuardService: AuthGuardService
  ) {}

  get legalDocument() {
    return this.parentForm.get('legalDocument') as FormGroup;
  }

  get fileModule() {
    return FileModuleEnum;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.createForm();

    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.masterService.getMasterDataByNames(['banks']).subscribe(result => {
      if (result.data) {
        this.banks = result.data.banks;
      }
    });

    this.parentForm.addControl('legalDocument', this.createForm());

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setLegalDocumentValue();
    }
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNull = { value: null, disabled: false };

    return this.fb.group({
      idCardFront: initialNullRequired,
      idCardBack: initialNullRequired,
      jcNumber: initialNullRequired,
      dipChip: initialNullRequired,
      houseRegistration: initialNullRequired,
      letterOfIntent: initialNullRequired,
      contractSignOff: initialNullRequired,
      creditBureauSignOff: initialNull,
      criminalCheck: initialNullRequired,
      accountNo: initialNullRequired,
      accountName: initialNullRequired,
      bankName: initialNullRequired,
      bookBank: initialNullRequired
    });
  }

  /** set value from selector (view, edit) mode **/
  setLegalDocumentValue() {
    this.legalDocument.disable();

    this.merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));
    this.merchantRequestView$.pipe(map(response => response)).subscribe(value => {
      if (value) {
        this.type = value.type;
        this.step = value.step;
        this.status = value.status;
        this.requestedBy = value.requestedBy;

        if (value.legalDocument) {
          this.legalDocument.patchValue({
            ...value.legalDocument,
            idCardFront: getFileUploadObj(value.legalDocument.idCardFront),
            idCardBack: getFileUploadObj(value.legalDocument.idCardBack),
            houseRegistration: getFileUploadObj(value.legalDocument.houseRegistration),
            letterOfIntent: getFileUploadObj(value.legalDocument.letterOfIntent),
            contractSignOff: getFileUploadObj(value.legalDocument.contractSignOff),
            creditBureauSignOff: getFileUploadObj(value.legalDocument.creditBureauSignOff),
            criminalCheck: getFileUploadObj(value.legalDocument.criminalCheck)
          });
        }

        if (value.wallet) {
          this.legalDocument.patchValue({
            ...value.wallet,
            dipChip: getFileUploadObj(value.wallet.dipChip),
            bookBank: getFileUploadObj(value.wallet.bookBank),
            jcNumber: value.wallet.jcNumber,
            accountNo: value.wallet.accountNo,
            accountName: value.wallet.accountName
          });
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditLegalDocument();
        } else {
          this.setLegalDocumentCtrl(this.type, this.page, this.step, this.mode);
        }
      }
    });
  }

  setLegalDocumentCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(
      localType,
      localPage,
      localStep,
      RequestSectionEnum.PROFILE
    );
    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.legalDocument.enable();
    } else {
      this.legalDocument.disable();
    }
  }

  setHiddenInfo() {
    return 'Completed';
  }

  toggleEditLegalDocument() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;

    if ([TDStorePage.MERCHANT_EDIT, TDStorePage.STORE_EDIT].includes(this.page)) {
      this.step = RequestStepEnum.EDIT_PROFILE;
      this.type = RequestTypeEnum.EDIT;
      this.status = RequestStatusEnum.DRAFT;
    }

    this.setLegalDocumentCtrl(this.type, this.page, this.step, this.mode);
  }

  hasViewDocumentPermission() {
    if (
      this.type === RequestTypeEnum.NEW &&
      [TDStorePage.MERCHANT_REQUEST, TDStorePage.STORE_REQUEST].includes(this.page) &&
      this.tdStoreWorkflowUtil.hasViewLegalDocument(this.status, this.requestedBy)
    ) {
      return true;
    }

    if (
      this.type === RequestTypeEnum.EDIT &&
      [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_EDIT].includes(this.page) &&
      this.tdStoreWorkflowUtil.hasViewLegalDocument(this.status, this.requestedBy)
    ) {
      return true;
    }

    if (
      this.type === RequestTypeEnum.EDIT &&
      [TDStorePage.MERCHANT_REQUEST, TDStorePage.STORE_REQUEST].includes(this.page) &&
      this.tdStoreWorkflowUtil.hasViewEditLegalDocument(this.status, this.mode)
    ) {
      return true;
    }

    return (
      [TDStorePage.MERCHANT_EDIT, TDStorePage.STORE_EDIT].includes(this.page) &&
      this.authGuardService.checkPermission(['merchant_vs'])
    );
  }
}
