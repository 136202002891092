import { createSelector } from '@ngrx/store';
import * as fromMyTaskState from '../reducers/my-task.reducers';
import { AppStates } from '../state/app.states';

const selectMyTaskState = (state: AppStates) => state.myTask;

export const selectAllMyTaskList = createSelector(selectMyTaskState, fromMyTaskState.selectAll);

export const selectMyTaskList = createSelector(selectMyTaskState, (state: fromMyTaskState.MyTaskState) => state);

export const selectMyTaskListCriteria = createSelector(selectMyTaskState, state => {
  return state.criteriaObject;
});
