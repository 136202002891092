import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantService } from '../../services/merchant.service';
import { StoreService } from '../../services/store.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StoreActionType,
  StoreByIdRequestAction,
  StoreByIdResponseAction,
  StoreListHistoryRequestAction,
  StoreListHistoryResponseAction,
  StoreListRequestAction,
  StoreListResponseAction
} from '../actions/store.actions';

@Injectable()
export class StoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storeService: StoreService,
    private readonly merchantService: MerchantService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchStore$ = this.actions$.pipe(
    ofType<StoreListRequestAction>(StoreActionType.STORE_LIST_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${StoreActionType.STORE_LIST_REQUEST}: ` + StoreEffects.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantService.searchStoreByCriteria(payload).pipe(
        map(response => {
          return new StoreListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  storeHistory$ = this.actions$.pipe(
    ofType<StoreListHistoryRequestAction>(StoreActionType.STORE_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreActionType.STORE_LIST_HISTORY_REQUEST}: ` + StoreEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeService.getHistoryLogs(payload).pipe(
        map(response => new StoreListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getStoreById$ = this.actions$.pipe(
    ofType<StoreByIdRequestAction>(StoreActionType.STORE_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreActionType.STORE_GET_BY_ID_REQUEST}: ` + StoreEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return combineLatest([
        this.merchantService.getMerchantById(payload.merchant),
        this.storeService.getStoreById(payload.storeNo)
      ]).pipe(
        map(([merchant, store]) => {
          const storeView = {
            id: store.id,
            no: store.no,
            version: store.version,
            status: store.status,
            merchantId: merchant.no,
            taxId: merchant.merchantInfo.merchantProfile.taxId,
            merchantName: merchant.merchantInfo.merchantProfile.merchantName,
            merchantType: merchant.merchantInfo.merchantProfile.merchantType,
            ...(merchant.merchantInfo.ownerProfile && {
              contactName: `${merchant.merchantInfo.ownerProfile.contactFirstName} ${merchant.merchantInfo.ownerProfile.contactLastName}`,
              mobilePhone: `${merchant.merchantInfo.ownerProfile.countryCode} ${merchant.merchantInfo.ownerProfile.mobilePhone}`
            }),
            merchantInfo: {
              storeProfile: [{ ...store.storeProfile }]
            },
            ...(store.orderSchedule && {
              orderSchedule: {
                orderScheduleList: [{ ...store.orderSchedule }]
              }
            }),
            devices: store.devices
          };
          return new StoreByIdResponseAction({ storeView });
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private static stringify(data: any) {
    return JSON.stringify(data);
  }
}
