import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LayoutActionErrorUpdate, LayoutActionTypes, LayoutActionVersionError } from '../actions/layout.action';
export class LayoutEffects {
    constructor(actions$, spinner) {
        this.actions$ = actions$;
        this.spinner = spinner;
        // The Error handler ...
        this.onLoadError$ = this.actions$.pipe(ofType(LayoutActionTypes.LOAD_ERROR), switchMap((response) => {
            // Hide loading spinner for every error
            this.spinner.hide();
            if (response.payload &&
                response.payload.error &&
                response.payload.error.code &&
                ['00001', '00003'].includes(response.payload.error.code)) {
                return of(new LayoutActionVersionError(true));
            }
            // TODO: handle for 401 and forward to login
            // ... you can check the payload here to show different messages
            // like if error.statusCode === 501 etc.
            // remap to noop Action if no state needs to be updated.
            // or for example on 401 Errors dispatch a re-login action etc.
            if (response.payload.status !== 401) {
                return of(new LayoutActionErrorUpdate(true));
            }
            return of(new LayoutActionErrorUpdate(false));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], LayoutEffects.prototype, "onLoadError$", void 0);
