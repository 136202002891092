import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PromotionTransFrom } from '../../models';
import { PromotionRequestService } from '../../services/promotion-request.service';
import { PromotionService } from '../../services/promotion.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  PromotionActionTypes,
  PromotionApproveRequested,
  PromotionCancelRequest,
  PromotionCreateSaveErrorAction,
  PromotionCreateSaveRequestAction,
  PromotionCreateSaveResponseAction,
  PromotionCreateSubmitRequestAction,
  PromotionCreateSubmitResponseAction,
  PromotionEditSaveRequestAction,
  PromotionGetByCodeRequestAction,
  PromotionListRequestAction,
  PromotionListResponseAction,
  PromotionRejectRequested,
  PromotionRequestCancelRequested,
  PromotionRequestDeleteRequest,
  PromotionRequestGetByIdRequestAction,
  PromotionRequestGetByIdResponseAction,
  PromotionRequestListRequestAction,
  PromotionRequestListResponseAction,
  PromotionThemeActiveResponseAction,
  PromotionThemeAddError,
  PromotionThemeAddRequestAction,
  PromotionThemeAddSuccess,
  PromotionThemeComingResponseAction,
  PromotionThemeRequestAction
} from '../actions/promotion.action';

@Injectable()
export class PromotionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly promotionService: PromotionService,
    private readonly promotionRequestService: PromotionRequestService
  ) {}

  @Effect()
  listOfPromotionTheme$ = this.actions$.pipe(
    ofType<PromotionThemeRequestAction>(PromotionActionTypes.PROMOTION_THEME_LIST_REQUEST),
    tap(action => this.logger.debug('@Effect Promotion Theme: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.promotionService.searchTheme(action.payload).pipe(
        map(themes => {
          const themesBkk = new Array<any>();

          themes.forEach(item => {
            themesBkk.push(PromotionTransFrom.mappingPrmotionThemeTimeUtcToBkk(item));
          });

          if (action.payload.type === 'effectiveDate') {
            return new PromotionThemeComingResponseAction(themesBkk);
          } else {
            return new PromotionThemeActiveResponseAction(themesBkk);
          }
        }),
        // Handling Errors in NgRx Effects : https://medium.com/city-pantry/handling-errors-in-ngrx-effects-a95d918490d9
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  addTheme$ = this.actions$.pipe(
    ofType<PromotionThemeAddRequestAction>(PromotionActionTypes.PROMOTION_THEME_ADD_REQUEST),
    tap(action => this.logger.debug('@Effect Add Theme: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      const theme = PromotionTransFrom.mappingPromotionThemeTimeBkktoUtc(action.payload);
      return this.promotionService.addTheme(theme).pipe(
        map(res => new PromotionThemeAddSuccess(res)),
        catchError(err => {
          return err.error && err.error.code === '00001'
            ? of(new PromotionThemeAddError(err.error))
            : of(new LayoutActionLoadError(err));
        })
      );
    })
  );

  @Effect()
  createRequestPromotion$ = this.actions$.pipe(
    ofType<PromotionCreateSaveRequestAction>(PromotionActionTypes.PROMOTION_CREATE_SAVE_REQUEST),
    tap(action => this.logger.debug('@Effect Request Promtion Create Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      const promotion = {
        ...action.payload.promotionCreatRequest,
        details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details)
      };

      return this.promotionRequestService.createRequest(promotion).pipe(
        map(res => new PromotionCreateSaveResponseAction(res)),
        catchError(err => {
          return this.errorHandling(err);
        })
      );
    })
  );

  @Effect()
  editRequestPromotion$ = this.actions$.pipe(
    ofType<PromotionEditSaveRequestAction>(PromotionActionTypes.PROMOTION_EDIT_SAVE_REQUEST),
    tap(action => this.logger.debug('@Effect Request Promtion Edit Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      const promotion = {
        ...action.payload.promotionCreatRequest,
        details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details)
      };

      return this.promotionRequestService.updateByRequestId(promotion).pipe(
        map(res => new PromotionCreateSaveResponseAction(res)),
        catchError(err => {
          return this.errorHandling(err);
        })
      );
    })
  );

  @Effect()
  deleteRequestPromotion$ = this.actions$.pipe(
    ofType<PromotionRequestDeleteRequest>(PromotionActionTypes.PROMOTION_REQUEST_DELETE_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Delete: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.promotionRequestService.delete(action.payload.requestId).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  cancelPromotionRequest$ = this.actions$.pipe(
    ofType<PromotionRequestCancelRequested>(PromotionActionTypes.PROMOTION_REQUEST_CANCEL_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.promotionRequestService.requestCancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  cancelPromotion$ = this.actions$.pipe(
    ofType<PromotionCancelRequest>(PromotionActionTypes.PROMOTION_CANCEL_REQUESTED),
    tap(action => this.logger.debug('@Effect Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.promotionService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This promotion has been cancelled.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  searchPromotion$ = this.actions$.pipe(
    ofType<PromotionListRequestAction>(PromotionActionTypes.PROMOTION_LIST_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${PromotionActionTypes.PROMOTION_LIST_REQUEST}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.promotionService.searchByCriteria(payload).pipe(
        map(requests => {
          const content = new Array<any>();
          requests.content.forEach(item => {
            content.push(PromotionTransFrom.mappingPromotionContentTimeUtcToBkk(item));
          });
          const promotion = {
            ...requests,
            content
          };

          return new PromotionListResponseAction(promotion);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  searchPromotionRequest$ = this.actions$.pipe(
    ofType<PromotionRequestListRequestAction>(PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.promotionRequestService.searchByCriteria(payload).pipe(
        map(requests => {
          const content = new Array<any>();
          requests.content.forEach(item => {
            content.push(PromotionTransFrom.mappingPromotionRequestTimeUtcToBkk(item));
          });
          const promotion = {
            ...requests,
            content
          };

          return new PromotionRequestListResponseAction(promotion);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  createSubmitPromotion$ = this.actions$.pipe(
    ofType<PromotionCreateSubmitRequestAction>(PromotionActionTypes.PROMOTION_CREATE_SUBMIT_REQUEST),
    tap(action =>
      this.logger.debug('@Effect Request Promtion Create Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action => {
      const promotion = {
        ...action.payload.promotionCreatRequest,
        details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details)
      };

      return this.promotionRequestService.submit(promotion).pipe(
        map(res => new PromotionCreateSubmitResponseAction(res)),
        catchError(error => of(new PromotionCreateSaveErrorAction(error)))
      );
    })
  );

  @Effect()
  loadRequestPromotion$ = this.actions$.pipe(
    ofType<PromotionRequestGetByIdRequestAction>(PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Promotion Request By ID Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.promotionRequestService.getByRequestId(action.payload.requestId).pipe(
        map(request => this.transformRequestDetails(request)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  rejectRequestPromotions$ = this.actions$.pipe(
    ofType<PromotionRejectRequested>(PromotionActionTypes.PROMOTION_REQUEST_REJECT_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Reject: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.promotionRequestService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  approveRequestPromotions$ = this.actions$.pipe(
    ofType<PromotionApproveRequested>(PromotionActionTypes.PROMOTION_REQUEST_APPROVE_REQUESTED),
    tap(action => this.logger.debug('@Effect Request Approve: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.promotionRequestService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  getPromotionById$ = this.actions$.pipe(
    ofType<PromotionGetByCodeRequestAction>(PromotionActionTypes.PROMOTION_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Promotion By code Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.promotionService.getPromotionById(action.payload.promotionCode).pipe(
        map(request => this.transformRequestDetails(request)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  transformRequestDetails(request: any) {
    const promotion = {
      ...request,
      details: PromotionTransFrom.mappingPromotionTimeUtcToBkk(request.details)
    };

    return new PromotionRequestGetByIdResponseAction(promotion);
  }

  errorHandling(err: any) {
    return err.error && err.error.code !== '00001'
      ? of(new LayoutActionLoadError(err))
      : of(new PromotionCreateSaveErrorAction(err.error));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
