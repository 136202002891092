import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StoreAssortmentRequestService } from '../../services/store-assortment-request.service';
import { StoreAssortmentService } from '../../services/store-assortment.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { StoreAssortmentRequestStoreActionTypes, StoreAssortmentRequestStoreListResponseAction } from '../actions/store-assortment-request-store.actions';
import { StoreAssortmentRequestActionType, StoreAssortmentRequestByIdResponseAction, StoreAssortmentRequestListResponseAction } from '../actions/store-assortment-request.actions';
export class StoreAssortmentRequestEffects {
    constructor(actions$, storeAssortmentService, storeAssortmentRequestService, logger) {
        this.actions$ = actions$;
        this.storeAssortmentService = storeAssortmentService;
        this.storeAssortmentRequestService = storeAssortmentRequestService;
        this.logger = logger;
        this.getStoreAssortmentRequestById$ = this.actions$.pipe(ofType(StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentRequestService.getStoreAssortmentRequestById(payload).pipe(map(res => {
                return new StoreAssortmentRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchStoreAssortmentRequest$ = this.actions$.pipe(ofType(StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentRequestService.searchByCriteria(payload).pipe(map(response => {
                return new StoreAssortmentRequestListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchStoreAssortmentRequestStore$ = this.actions$.pipe(ofType(StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.searchStoreAssortmentStoreListByCriteria(payload).pipe(map(response => {
                return new StoreAssortmentRequestStoreListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.submitRequest$ = this.actions$.pipe(ofType(StoreAssortmentRequestActionType.STORE_ASSORTMENT_CREATE_SUBMIT_REQUEST), tap(action => this.logger.debug(`${StoreAssortmentRequestActionType.STORE_ASSORTMENT_CREATE_SUBMIT_REQUEST}` + JSON.stringify(action.payload))), switchMap(action => this.storeAssortmentRequestService.submitRequest(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been created.'
        })), catchError(err => {
            return err && err.error && err.error.code === '00001'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentRequestEffects.prototype, "getStoreAssortmentRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentRequestEffects.prototype, "searchStoreAssortmentRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentRequestEffects.prototype, "searchStoreAssortmentRequestStore$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentRequestEffects.prototype, "submitRequest$", void 0);
