import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import {
  ApproveMerchantRequest,
  MerchantCreateRequest,
  MerchantRequestId,
  MerchantRequestListResponse,
  MerchantRequestListSearchCriteria,
  MerchantRequestResponse,
  MerchantRequestViewResponse,
  SignedUrlObject
} from '../models';
import { VersionModel } from '../models/version.model';
import { BaseService } from './base.service';

@Injectable()
export class MerchantRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.merchantsRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getMerchantValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }

  public searchByCriteria(criteria: MerchantRequestListSearchCriteria): Observable<MerchantRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public approveRequest(request: ApproveMerchantRequest): Observable<MerchantRequestResponse> {
    const url = this.getFullUrl(this.envService.approveRequest);

    return this.http.put<MerchantRequestResponse>(url, request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public getMerchantRequest(merchantRequestId: MerchantRequestId): Observable<MerchantRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      merchantRequestId: merchantRequestId.merchantRequestId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getMerchantRequestVersionById(merchantRequestId: MerchantRequestId): Observable<VersionModel> {
    const url = this.getFullUrl(this.env.services.merchantsRequest.getMerchantVersion, {
      merchantRequestId: merchantRequestId.merchantRequestId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.fileUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }

  public saveRequest(merchantCreateRequest: MerchantCreateRequest): Observable<MerchantRequestResponse> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<any>(url, merchantCreateRequest.merchantCreatRequest, { headers: this.loaderHeaders() });
  }

  public submitRequest(merchantCreateRequest: MerchantCreateRequest): Observable<MerchantRequestResponse> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, merchantCreateRequest.merchantCreatRequest, { headers: this.loaderHeaders() });
  }

  public deleteByRequestId(merchantRequestId: MerchantRequestId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      merchantRequestId: merchantRequestId.merchantRequestId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(), observe: 'body' });
  }

  public getHistoryLogs(merchantRequestId: MerchantRequestId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      merchantRequestId: merchantRequestId.merchantRequestId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getRequestShelfDetails(storeCode: string): Observable<any> {
    const url = this.getFullUrl(this.env.services.merchantsRequest.getShelfDetails, { storeCode });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
