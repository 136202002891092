import { createSelector } from '@ngrx/store';
import * as fromStoreGroupState from '../reducers/store-group.reducers';
import { AppStates } from '../state/app.states';

const selectStoreGroupCreateState = (state: AppStates) => state.storeGroupCreate;
const selectStoreGroupState = (state: AppStates) => state.storeGroup;

export const selectAllStoreGroupList = createSelector(selectStoreGroupState, fromStoreGroupState.selectAll);

export const selectStoreGroupList = createSelector(
  selectStoreGroupState,
  (state: fromStoreGroupState.StoreGroupState) => state
);

export const selectStoreGroupErrorResponse = createSelector(selectStoreGroupCreateState, state => {
  return state.error;
});

export const selectStoreGroupGetResponse = createSelector(selectStoreGroupCreateState, state => {
  return state.selectdGroup;
});

export const selectStoreGroupListCriteria = createSelector(selectStoreGroupState, state => {
  return state.criteriaObject;
});
