export class MemberCardSearchCriteria {
  searchCriteria?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class MemberCardPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: MemberCardContent[];
}

export class MemberCardContent {
  id: string;
  docNo: string;
  memberCardAmount: string;
  cardNosDisplay: string;
  requestedDate: string;
  createdByName: string;
  createdDate: string;
}

export class GenerateMemberCard {
  memberCardAmount: number;

  public constructor(init?: Partial<GenerateMemberCard>) {
    Object.assign(this, init);
  }
}
