import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  public toggled = false;
  public toggled$ = new BehaviorSubject<boolean>(this.toggled);

  constructor() {}

  toggle() {
    this.toggled = !this.toggled;
    this.toggled$.next(this.toggled);
  }

  get sidebarState$(): BehaviorSubject<boolean> {
    return this.toggled$;
  }

  get sidebarState() {
    return this.toggled;
  }

  set sidebarState(state: boolean) {
    this.toggled = state;
    this.toggled$.next(this.toggled);
  }
}
