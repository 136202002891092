import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  StoreAssortmentRequestList,
  StoreAssortmentRequestListSearchCriteria
} from '../../../shared/models/store-assortment-request.model';
import { AuthGuardService } from '../../../shared/services';
import { StoreAssortmentRequestListRequestAction } from '../../../shared/store/actions/store-assortment-request.actions';
import { StoreAssortmentState } from '../../../shared/store/reducers/store-assortment.reducers';
import {
  selectAllStoreAssortmentRequestList,
  selectStoreAssortmentRequestList
} from '../../../shared/store/selectors/store-assortment-request.selectors';
import { selectStoreListCriteria } from '../../../shared/store/selectors/store.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { StoreAssortmentRequestViewComponent } from '../store-assortment-request-view/store-assortment-request-view.component';
import { StoreAssortmentRequestComponent } from '../store-assortment-request/store-assortment-request.component';

@Component({
  selector: 'app-store-assortment-request-list',
  templateUrl: './store-assortment-request-list.component.html',
  styleUrls: ['./store-assortment-request-list.component.scss']
})
export class StoreAssortmentRequestListComponent extends BaseSearchComponent<
  StoreAssortmentRequestListSearchCriteria,
  StoreAssortmentRequestList,
  StoreAssortmentState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public searchTag: string;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public dateFormat = environment.dateFormat;
  public statusList: Array<{ value: string; label: string }> = filterDropdown.storeAssortmentRequestStatusFilter;

  public responseExportError: string;

  constructor(
    protected authGuardService: AuthGuardService,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllStoreAssortmentRequestList, selectStoreAssortmentRequestList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStoreListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      createdDateFrom: [null],
      createdDateTo: [null]
    });
  }

  clearFilterDate() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilter(controlName) {
    this.searchForm.controls[controlName].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      [controlName]: this.searchForm.controls[controlName].value
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: null,
      createdDateFrom: null,
      createdDateTo: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.createdDateFrom && !formValue.createdDateTo) {
      return;
    }

    let dateFrom = formValue.createdDateFrom;
    let dateTo = formValue.createdDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: dateFrom,
      createdDateTo: dateTo,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new StoreAssortmentRequestListRequestAction(criteriaObj));
  }

  goToCreate() {
    const mode = RequestPageModesEnum.REQUEST_CREATE;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreAssortmentRequestComponent,
        {
          title: 'Create Store Assortment Request',
          mode,
          requestId: null
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToView(id: string) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreAssortmentRequestViewComponent,
        {
          title: 'View Store Assortment Request',
          requestId: id
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['store_assortment_v', 'store_assortment_m']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['store_assortment_m']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Store Assortment List', url: '/store-assortment/store-assortment-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({
        tabName: 'Store Assortment Request',
        url: '/store-assortment/store-assortment-request-list'
      });
    }
  }

  getColorStatus(status: string): string {
    if (status === 'COMPLETED') {
      return 'completed';
    }

    return '';
  }
}
