import { createSelector } from '@ngrx/store';
import * as fromShelfCreateResponseState from '../reducers/shelf-create.reducers';
import { AppStates } from '../state/app.states';

const selectShelfCreateState = (state: AppStates) => state.shelfCreate;

export const selectShelf = createSelector(
  selectShelfCreateState,
  (state: fromShelfCreateResponseState.ShelfCreateResponseState | null) => state
);

export const selectShelfApproveRejectStatus = createSelector(
  selectShelfCreateState,
  (state: fromShelfCreateResponseState.ShelfCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
