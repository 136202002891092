import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { AuthService } from '../../services';
import { CookiesUtil } from '../../utils/cookies-util';
import { AuthActionTypes, LogInFailureAction, LogInSuccessAction, LogOutAction } from '../actions/auth.actions';
import { MenuRequestedAction } from '../actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction, UserInformationResetAction } from '../actions/user-info.action';
export class AuthEffects {
    constructor(actions, authService, router, cookieService, logger, store) {
        this.actions = actions;
        this.authService = authService;
        this.router = router;
        this.cookieService = cookieService;
        this.logger = logger;
        this.store = store;
        this.LogIn = this.actions.pipe(ofType(AuthActionTypes.LOGIN), map(action => action.payload), switchMap(payload => {
            return this.authService.logIn(payload).pipe(switchMap((resp) => {
                return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
            }), catchError(err => {
                return of(new LogInFailureAction(err.error));
            }));
        }));
        this.Refresh = this.actions.pipe(ofType(AuthActionTypes.REFRESH), map(action => action.payload), switchMap(payload => {
            return this.authService.refreshToken(payload).pipe(switchMap((resp) => of(new LogInSuccessAction(resp))), catchError(err => of(new LogInFailureAction(err.error))));
        }));
        this.LogInSuccess = this.actions.pipe(ofType(AuthActionTypes.LOGIN_SUCCESS), map(action => action.payload), tap((payload) => {
            try {
                const userInfo = JwtDecode(payload.access_token);
                const mSec = payload.expires_in * 1000;
                const currentDate = new Date();
                const expiryDate = new Date(currentDate.getTime() + mSec + env.cookies.bufferTimePeriod);
                const tokenExpiryDate = new Date(moment(expiryDate)
                    .add(1, 'week')
                    .toDate());
                const cookieOptions = {
                    expires: expiryDate,
                    path: env.cookies.path,
                    domain: env.cookies.domain,
                    secure: window.location.protocol === 'https:'
                };
                if (payload.access_token) {
                    const accessToken = this.cookiesUtil.splitByLength(payload.access_token, env.cookies.accessToken, cookieOptions);
                    const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
                    accessTokenCookiesName.forEach((value, index) => {
                        this.cookieService.set(value, accessToken[index], tokenExpiryDate, cookieOptions.path, cookieOptions.domain, cookieOptions.secure);
                    });
                }
                if (payload.refresh_token) {
                    const refreshToken = this.cookiesUtil.splitByLength(payload.refresh_token, env.cookies.refreshToken, cookieOptions);
                    const refreshTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.refreshToken);
                    refreshTokenCookiesName.forEach((value, index) => {
                        this.cookieService.set(value, refreshToken[index], tokenExpiryDate, cookieOptions.path, cookieOptions.domain, cookieOptions.secure);
                    });
                }
                this.dispatchInfo(userInfo);
                if (userInfo.user_info.forceChangePassword) {
                    this.router.navigate(['/force-change-password']);
                }
                else if (this.router.url === '/' || this.router.url.toLocaleLowerCase() === '/') {
                    this.router.navigateByUrl('/dashboard/my-task');
                }
            }
            catch (err) {
                this.logger.error(err);
                this.store.dispatch(new LogOutAction());
                window.location.href = window.location.origin;
            }
        }));
        this.LogInFailure = this.actions.pipe(ofType(AuthActionTypes.LOGIN_FAILURE), tap(() => {
            this.clearAuth();
            this.router.navigateByUrl('/');
        }));
        this.LogOut = this.actions.pipe(ofType(AuthActionTypes.LOGOUT), tap(() => {
            this.clearAuth();
            this.router.navigateByUrl('/');
        }));
        this.cookiesUtil = new CookiesUtil(cookieService);
    }
    dispatchInfo(userInfo) {
        userInfo.user_info.authorities = userInfo.authorities;
        this.store.dispatch(new UserInformationAction(userInfo.user_info));
        this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));
        this.store.dispatch(new ClientIdAction(userInfo.client_id));
        this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
    }
    clearAuth() {
        this.cookieService.deleteAll(env.cookies.path, env.cookies.domain);
        this.logger.info('delete all ', env.cookies.domain, 'cookies ', this.cookieService.getAll());
        this.store.dispatch(new ClientIdAction(env.defaultClientId));
        this.store.dispatch(new UserInformationResetAction());
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], AuthEffects.prototype, "LogIn", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], AuthEffects.prototype, "Refresh", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], AuthEffects.prototype, "LogInSuccess", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], AuthEffects.prototype, "LogInFailure", void 0);
tslib_1.__decorate([
    Effect({ dispatch: false }),
    tslib_1.__metadata("design:type", Observable)
], AuthEffects.prototype, "LogOut", void 0);
