import { BaseApiResponse } from '../../models/base-api-response';
import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';

export interface ResetPasswordPinState {
  result: {
    resetPasswordResult?: BaseApiResponse | null;
    resetPinCodeResult?: BaseApiResponse | null;
  };
}

export const initialResetPinPasswordState: ResetPasswordPinState = {
  result: {
    resetPasswordResult: null,
    resetPinCodeResult: null
  }
};

export function resetPasswordPinReducers(
  state = initialResetPinPasswordState,
  action: UserInfoActions
): ResetPasswordPinState {
  switch (action.type) {
    case UserActionTypes.USER_PASSWORD_RESET_RESPONSE: {
      return {
        ...state,
        result: {
          resetPasswordResult: action.payload
        }
      };
    }
    case UserActionTypes.USERS_RESET_PIN_CODE_RESPONSE: {
      return {
        ...state,
        result: {
          resetPinCodeResult: action.payload
        }
      };
    }
    case UserActionTypes.USERS_RESET_PASSWORD_PIN_CODE_RESET: {
      return {
        ...state,
        ...initialResetPinPasswordState
      };
    }
    default:
      return state;
  }
}
