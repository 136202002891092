import { createSelector } from '@ngrx/store';
import * as fromSupplierStore from '../reducers/supplier-store.reducers';
import { AppStates } from '../state/app.states';

const selectSupplierStoreState = (state: AppStates) => state.supplierStore;

export const selectSupplierStoreList = createSelector(selectSupplierStoreState, fromSupplierStore.selectAll);

export const selectSupplierStore = createSelector(
  selectSupplierStoreState,
  (state: fromSupplierStore.SupplierStoreState) => state
);
