import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StoreGroup, StoreGroupDto, StoreGroupError } from '../../models';
import { StoreGroupAction, StoreGroupActionTypes } from '../actions/store-group.actions';

export interface StoreGroupCreateState extends EntityState<StoreGroupDto> {
  isLoading: boolean;
  isSubmitSuccess: boolean;
  selectdGroup: StoreGroup;
  submitData: StoreGroupDto;
  error: StoreGroupError;
}

export const adapter: EntityAdapter<StoreGroupDto> = createEntityAdapter<StoreGroupDto>();

export const initialStoreGroupCreateState: StoreGroupCreateState = adapter.getInitialState({
  isLoading: false,
  isSubmitSuccess: false,
  submitData: null,
  error: null,
  selectdGroup: null
});

export function StoreGroupCreateReducers(
  state = initialStoreGroupCreateState,
  action: StoreGroupAction
): StoreGroupCreateState {
  switch (action.type) {
    case StoreGroupActionTypes.STORE_GROUP_SUBMIT_REQUEST:
      return {
        ...state,
        submitData: action.payload
      };
    case StoreGroupActionTypes.STORE_GROUP_SUBMIT_RESET:
      return initialStoreGroupCreateState;
    case StoreGroupActionTypes.STORE_GROUP_SUBMIT_ERROR:
      return {
        ...state,
        error: action.payload
      };
    case StoreGroupActionTypes.STORE_GROUP_GET_RESPONSE:
      return {
        ...state,
        selectdGroup: action.payload
      };
    case StoreGroupActionTypes.STORE_GROUP_GET_RESET:
      return initialStoreGroupCreateState;
    default: {
      return state;
    }
  }
}
