<!-- Modal Product Section -->
<div
  id="searchMultipleArticleModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #searchMultipleArticleModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog" [ngStyle]="{ 'min-width': '550px' }">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Add Article
          </h6>
          <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="closeSelectArticleModal()"
            id="selectBarcode-modal-closeBtn"
          >
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="articleForm">
            <div class="row">
              <div class="col-12">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label> Enter each article on a separate line.(maximum of {{ maxLength }} articles) </label>
                        <textarea
                          formControlName="article"
                          id="articleInput"
                          class=" custom-textarea form-control"
                          rows="10"
                          [ngClass]="{ 'is-invalid': submittedSearchArticle && form.article?.errors }"
                        ></textarea>
                        <div
                          *ngIf="submittedSearchArticle && form.article.errors?.required"
                          class="invalid-feedback mt-1"
                        >
                          {{ 'ERRORS.REQUIRED' | translate }}
                        </div>
                        <div
                          *ngIf="submittedSearchArticle && form.article.errors?.maxlength"
                          class="invalid-feedback mt-1"
                        >
                          {{ 'ERRORS.MAX_IS' | translate: { value: maxLength } }}
                        </div>
                        <div *ngIf="hasDuplicatedArticle$ | async" class="invalid-feedback text-warning d-block mt-1">
                          {{ duplicateError$ | async }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-standard"
            (click)="closeSelectArticleModal()"
            id="product-modal-cancelBtn"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="btn btn-primary"
            id="product-modal-addBtn"
            (click)="submit()"
            appPreventDoubleClick
          >
            OK
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
