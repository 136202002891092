import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { SegmentList, segmentListByArticleTypeQuery } from '../gql/segmentList.gql';
import {
  ExportAssortmentSearchCriteria,
  ProductAssortmentResponse,
  ProductAssortmentSearchCriteria,
  ProductStatusEnum
} from '../models';
import { OrderSelectItemSearchCriteria } from '../models/order-request.model';
import { TdAssortmentListPagination } from '../models/purchase-request.model';
import { DcAssortmentExportCriteria } from '../models/report.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class ProductAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly masterService: MasterService) {
    super();
    this.envService = this.env.services.assortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ProductAssortmentSearchCriteria): Observable<ProductAssortmentResponse> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.get);
    const headers: HttpHeaders = new HttpHeaders(this.env.services.assortment.headers);
    return this.http.get<ProductAssortmentResponse>(url, {
      headers,
      observe: 'body',
      params
    });
  }

  public getSegmentListData(articleType: string = null): Observable<ApolloQueryResult<SegmentList>> {
    return this.masterService.watchQuery<SegmentList>({
      query: segmentListByArticleTypeQuery,
      variables: {
        articleType: articleType
      }
    });
  }

  public searchCatalogByCriteria(criteria: OrderSelectItemSearchCriteria): Observable<any> {
    const params = this.getParams({
      ...criteria,
      productType: criteria.productType.toString(),
      excStatus: [
        ProductStatusEnum.SELECT,
        ProductStatusEnum.RECALL,
        ProductStatusEnum.HOLD_BUY_SELL,
        ProductStatusEnum.DELETE
      ].toString()
    });
    const url = this.getFullUrl(this.envService.catalogs);

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportAssortment(criteria: ExportAssortmentSearchCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.exportAssortment);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportDcAssortment(criteria: DcAssortmentExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportDcAssortment, { code: criteria.code });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
