import { createSelector } from '@ngrx/store';
import * as fromRoleListState from '../reducers/role-list.reducers';
import { RoleState } from '../reducers/role.reducers';
import { AppStates } from '../state/app.states';

const selectRoleState = (state: AppStates) => state.roles;
const selectRoleListState = (state: AppStates) => state.rolesList;

export const selectAllRoleList = createSelector(selectRoleListState, fromRoleListState.selectAll);

export const selectRoleList = createSelector(selectRoleListState, (state: fromRoleListState.RoleListState) => state);

export const selectRoleListCriteria = createSelector(selectRoleListState, state => {
  return state.criteriaObject;
});

export const selectRoleMasterList = createSelector(selectRoleState, (state: RoleState) => state.permissions);

export const selectRoleErrorResponse = createSelector(selectRoleState, state => {
  return state.roleErrorResponse;
});

export const selectRoleAllList = createSelector(selectRoleState, (state: RoleState) => state.roleList);

export const selectRoleById = createSelector(selectRoleState, (state: RoleState) => state.selected);
