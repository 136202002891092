import { Action } from '@ngrx/store';
import {
  Z8ParameterList,
  Z8ParameterListResponse,
  Z8ParameterListSearchCriteria
} from '../../models/z8-parameter.model';

export enum Z8ParameterActionType {
  Z8_PARAMETER_LIST_REQUEST = '[Z8 Parameter] Z8 Parameter Request',
  Z8_PARAMETER_LIST_RESPONSE = '[Z8 Parameter] Z8 Parameter Response',
  Z8_PARAMETER_LIST_HISTORY_REQUEST = '[Z8 Parameter] List History Request',
  Z8_PARAMETER_LIST_HISTORY_RESPONSE = '[Z8 Parameter] List History Response',
  Z8_PARAMETER_GET_BY_ID_RESPONSE = '[Z8 Parameter] Get Z8 Parameter by Id Response',
  Z8_PARAMETER_GET_BY_ID_RESET = '[Z8 Parameter] Get Z8 Parameter by Id Reset'
}

export class Z8ParameterListRequestAction implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST;

  constructor(public payload: Z8ParameterListSearchCriteria) {}
}

export class Z8ParameterListResponseAction implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_LIST_RESPONSE;

  constructor(public payload: Z8ParameterListResponse) {}
}

export class Z8ParameterByIdResponseAction implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_GET_BY_ID_RESPONSE;

  constructor(public payload: Z8ParameterList) {}
}

export class ResetZ8ParameterSelected implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_GET_BY_ID_RESET;
}

export class Z8ParameterListHistoryRequestAction implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_REQUEST;
  constructor(public payload: string) {}
}

export class Z8ParameterListHistoryResponseAction implements Action {
  readonly type = Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export type Z8ParameterActions =
  | Z8ParameterListRequestAction
  | Z8ParameterListResponseAction
  | Z8ParameterByIdResponseAction
  | ResetZ8ParameterSelected
  | Z8ParameterListHistoryRequestAction
  | Z8ParameterListHistoryResponseAction;
