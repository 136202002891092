import { Action } from '@ngrx/store';
import {
  ErrorResponse,
  StoreGroup,
  StoreGroupDto,
  StoreGroupError,
  StoreGroupListResponse,
  StoreGroupSearchCriteria
} from '../../models';

export enum StoreGroupActionTypes {
  STORE_GROUP_SUBMIT_REQUEST = '[Store Group] Submit Request',
  STORE_GROUP_SUBMIT_ERROR = '[Store Group] Submit Error',
  STORE_GROUP_SUBMIT_RESET = '[Store Group] Submit Seset',
  STORE_GROUP_UPDATE_REQEUST = '[Store Group] Update Seset',

  STORE_GROUP_LIST_REQUEST = '[Store Group] List Request',
  STORE_GROUP_LIST_RESPONSE = '[Store Group] List Response',
  STORE_GROUP_LIST_ERROR = '[Store Group] List Error',

  STORE_GROUP_GET_REQUEST = '[Store Group] Get group  Request',
  STORE_GROUP_GET_RESPONSE = '[Store Group] Get group Response',
  STORE_GROUP_GET_RESET = '[Store Group] Get group Reset'
}

export class StoreGroupListRequestAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST;

  constructor(public payload: StoreGroupSearchCriteria) {}
}

export class StoreGroupListResponseAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_LIST_RESPONSE;
  constructor(public payload: StoreGroupListResponse) {}
}

export class StoreGroupListErrorAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class StoreGroupSubmitRequestAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_SUBMIT_REQUEST;

  constructor(public payload: StoreGroupDto) {}
}

export class StoreGroupUpdateRequestAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_UPDATE_REQEUST;

  constructor(public payload: { merchantNo: string; groupNo: string; data: StoreGroupDto }) {}
}

export class StoreGroupSubmitErrorAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_SUBMIT_ERROR;

  constructor(public payload: StoreGroupError) {}
}

export class StoreGroupSubmitResetAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_SUBMIT_RESET;
}

export class StoreGroupGetRequestAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_GET_REQUEST;

  constructor(public payload: { merchantNo: string; groupNo: string }) {}
}

export class StoreGroupGetResponseAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_GET_RESPONSE;

  constructor(public payload: StoreGroup) {}
}

export class StoreGroupGetResetAction implements Action {
  readonly type = StoreGroupActionTypes.STORE_GROUP_GET_RESET;
}

export type StoreGroupAction =
  | StoreGroupListRequestAction
  | StoreGroupListResponseAction
  | StoreGroupListErrorAction
  | StoreGroupSubmitRequestAction
  | StoreGroupSubmitErrorAction
  | StoreGroupSubmitResetAction
  | StoreGroupGetRequestAction
  | StoreGroupGetResponseAction
  | StoreGroupGetResetAction;
