import { NewRequestStatusEnum } from '../enum/request-status.enum';
import { NewRequestTypeEnum } from '../enum/request-step.enum';
import { AuditLog } from './audit-log.model';
import { Sort } from './pagination.model';
import { MerchandiseItem, NonMerchandiseItem, ShelfInfo } from './shelf-request.model';

export class ShelfListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  lastUpdatedDateFrom?: string;
  lastUpdatedDateTo?: string;
  shelfType?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class ShelfListResponse {
  id: number;
  content: ShelfList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface ShelfList {
  id: string;
  no: string;
  shelfCode: string;
  shelfName: string;
  shelfType: string;
  noOfStore: number;
  lastUpdatedDate: string;
  status: NewRequestStatusEnum;
  auditLogs: AuditLog[];
}

export interface ShelfNo {
  shelfNo: string;
}

export class ShelfViewResponse {
  id: string;
  no: string;
  version = 0;
  shelfCode: string;
  type?: NewRequestTypeEnum;
  status: NewRequestStatusEnum;
  shelfInfo: ShelfInfo;
  nonMerchandiseItem: NonMerchandiseItem[];
  merchandiseItem: MerchandiseItem[];
  noOfStore: number;
  createdBy: string;
  createdByName: string;
  createdDate: string;
  lastModifiedDate: string;
}
