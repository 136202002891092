import { Action } from '@ngrx/store';
import {
  Device,
  Merchant,
  StoreListResponse,
  StoreListSearchCriteria,
  StoreNo,
  StoreViewResponse,
  UpdateOrderScheduleTemplate
} from '../../models';

export enum StoreActionType {
  STORE_LIST_REQUEST = '[Store] Store List Request',
  STORE_LIST_RESPONSE = '[Store] Store List Response',
  STORE_LIST_HISTORY_REQUEST = '[Store] List History Request',
  STORE_LIST_HISTORY_RESPONSE = '[Store] List History Response',
  STORE_GET_BY_ID_REQUEST = '[Store] Get Store by Id Request',
  STORE_GET_BY_ID_RESPONSE = '[Store] Get Store by Id Response',
  STORE_GET_BY_ID_RESET = '[Store] Get Store by Id Reset',
  STORE_POS_SUBMIT_REQUEST = '[Store] POS Terminal submit Request',
  UPDATE_ORDER_SCHEDULE_SUBMIT_REQUEST = '[Store] Update Order Schedule Submit Request',
  UPDATE_ORDER_SCHEDULE_SUBMIT_RESPONSE = '[Store] Update Order Schedule Submit Response',
  UPDATE_ORDER_SCHEDULE_SUBMIT_RESET = '[Store] Update Order Schedule Submit Reset'
}

export class StoreListRequestAction implements Action {
  readonly type = StoreActionType.STORE_LIST_REQUEST;

  constructor(public payload: StoreListSearchCriteria) {}
}

export class StoreListResponseAction implements Action {
  readonly type = StoreActionType.STORE_LIST_RESPONSE;

  constructor(public payload: StoreListResponse) {}
}

export class StoreListHistoryRequestAction implements Action {
  readonly type = StoreActionType.STORE_LIST_HISTORY_REQUEST;
  constructor(public payload: StoreNo) {}
}

export class StoreListHistoryResponseAction implements Action {
  readonly type = StoreActionType.STORE_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export class StoreByIdRequestAction implements Action {
  readonly type = StoreActionType.STORE_GET_BY_ID_REQUEST;

  constructor(public payload: { merchant: Merchant; storeNo: StoreNo }) {}
}

export class StoreByIdResponseAction implements Action {
  readonly type = StoreActionType.STORE_GET_BY_ID_RESPONSE;

  constructor(public payload: { storeView: StoreViewResponse }) {}
}

export class ResetStoreSelected implements Action {
  readonly type = StoreActionType.STORE_GET_BY_ID_RESET;
}

export class StorePOSSubmitRequestAction implements Action {
  readonly type = StoreActionType.STORE_POS_SUBMIT_REQUEST;

  constructor(public payload: Device) {}
}

export class UpdateOrderScheduleSubmitRequestAction implements Action {
  readonly type = StoreActionType.UPDATE_ORDER_SCHEDULE_SUBMIT_REQUEST;

  constructor(public payload: UpdateOrderScheduleTemplate) {}
}

export class UpdateOrderScheduleSubmitResponseAction implements Action {
  readonly type = StoreActionType.UPDATE_ORDER_SCHEDULE_SUBMIT_RESPONSE;
  constructor(public payload: UpdateOrderScheduleTemplate) {}
}

export class UpdateOrderScheduleSubmitReset implements Action {
  readonly type = StoreActionType.UPDATE_ORDER_SCHEDULE_SUBMIT_RESET;
  constructor() {}
}

export type StoreActions =
  | StoreListRequestAction
  | StoreListResponseAction
  | StoreListHistoryRequestAction
  | StoreListHistoryResponseAction
  | StoreByIdRequestAction
  | StoreByIdResponseAction
  | ResetStoreSelected
  | StorePOSSubmitRequestAction
  | UpdateOrderScheduleSubmitRequestAction
  | UpdateOrderScheduleSubmitResponseAction
  | UpdateOrderScheduleSubmitReset;
