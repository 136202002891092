import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ChildComponent } from '../../../shared/layouts/modals/full-modal/child-component';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { ProductMaster } from '../../../shared/models/product-master.model';
import { ProductMasterViewRequested } from '../../../shared/store/actions/product-master.actions';
import {
  selectProductMasterLoading,
  selectProductMasterView
} from '../../../shared/store/selectors/product-master.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: ['./product-view.component.scss']
})
export class ProductViewComponent implements OnInit, ChildComponent {
  productMaster$: Observable<ProductMaster>;
  loading$: Observable<boolean>;

  barcodeIndex: number;
  headerRow: string[];
  cjBarcodeModalRef: BsModalRef;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    protected readonly modalService: BsModalService,
    private readonly store: Store<AppStates>
  ) {}

  ngOnInit() {
    this.headerRow = [
      'Barcode Size',
      'Barcode',
      'Unit',
      'Unit Factor',
      'Wholesale Price',
      'Retail Price',
      'Effective Date'
    ];

    this.barcodeIndex = 0;

    this.loading$ = this.store.pipe(select(selectProductMasterLoading));

    if (this.data.id) {
      this.store.dispatch(new ProductMasterViewRequested({ articleNo: this.data.articleNo }));
    } else {
      this.router.navigateByUrl('/products/list');
    }

    this.productMaster$ = this.store.pipe(select(selectProductMasterView(this.data.id)));
  }

  openCJBarcodeModal(template: TemplateRef<any>, index: number) {
    this.cjBarcodeModalRef = this.modalService.show(template);
    this.barcodeIndex = index;
  }

  onExit() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }
}
