import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class SupplierService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.supplier;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchSupplierByName(term) {
    const criteria = { ...term, size: 100 };
    const params = this.getParams(criteria);

    return this.http
      .get<any>(this.getUrl(), {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        tap(suppliers => {
          if (suppliers) {
            suppliers.forEach(supplier => {
              supplier.supplierNameDisplay = `${supplier.supplierCode} ${supplier.supplierName} (${supplier.branchNo})`;
            });
          }
        })
      );
  }
}
