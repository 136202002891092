import { createSelector } from '@ngrx/store';
import * as fromProducts from '../reducers/product.reducers';
import { AppStates } from '../state/app.states';

const selectProductsState = (state: AppStates) => state.products;

export const selectProductsResult = createSelector(selectProductsState, fromProducts.selectAll);

export const selectProducts = createSelector(selectProductsState, (state: fromProducts.ProductState) => state);

export const selectProductsCheckList = createSelector(selectProductsState, state => {
  return state.selected;
});

export const selectProductListCriteria = createSelector(selectProductsState, state => {
  return state.criteriaObject;
});

export const selectStatusUpdate = createSelector(selectProductsState, state => {
  return state.productSelectStatusResponse;
});
