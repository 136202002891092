<div id="product-assortment" class="admin-form mt-2">
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="flex-item d-md-flex justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.value.searchCriteria }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="mt-3 table-responsive">
      <table id="transactions" class="table table-striped table-bordered table-hover border-none">
        <thead>
          <tr>
            <th class="text-center w-5">No.</th>
            <th class="text-center w-10">Barcode</th>
            <th class="text-center w-25">Product</th>
            <th class="text-center w-5">Unit</th>
            <th class="text-center w-10">Unit Factor</th>
            <th class="text-center w-5">PLG</th>
            <th class="text-center w-10">Tier</th>
            <th class="text-center w-10">Grading</th>
            <th class="text-center w-10">Stock</th>
            <th class="text-center w-10">Z8 Factor</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-center">{{ getItemNo(i) }}</td>
            <td class="text-center">{{ result.barcode }}</td>
            <td>
              {{ result.productName }}
              <span class="d-block">{{ result.articleNo }}</span>
            </td>
            <td class="text-center">{{ result.unit }}</td>
            <td class="text-center">{{ result.unitFactor }}</td>
            <td class="text-center">{{ result.productLocation }}</td>
            <td class="text-center">{{ result.productTier }}</td>
            <td class="text-center">{{ result.productGrading }}</td>
            <td class="text-center">{{ result.stock | numberFormatDisplay: 0 }}</td>
            <td class="text-center">
              <a class="btn-text-link d-inline-block" (click)="showZ8Factor(result)">View</a>
            </td>
          </tr>
        </tbody>
      </table>

      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </ng-container>
</div>
