<ng-container *ngIf="boxs && boxs.length > 0">
  <div class="information-box d-flex flex-row flex-item" *ngFor="let box of boxs; index as i">
    <div class="infor-icon">
      <em class="{{ box.icon }}"></em>
    </div>
    <div class="d-flex flex-column">
      <div class="title">
        {{ box.title }}
      </div>
      <div class="amount" [ngClass]="box.className ? box.className : ''" *ngIf="isNumber(box.value)">
        {{ box.value | numberFormatDisplay: 0 }}
      </div>
      <div class="amount" [ngClass]="box.className ? box.className : ''" *ngIf="!isNumber(box.value)">
        {{ box.value | dashDisplay }}
      </div>
    </div>
  </div>
</ng-container>
