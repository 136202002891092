import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment as env } from '../../../../environments/environment';
import { BaseUploadButtonComponent } from '../../../base/base-upload-button.component';
import { SupplierGroupModeEnum } from '../../../shared/enum/supplier-group.enum';
import { CommonUploadService } from '../../../shared/services/common.upload.service';
import { SupplierGroupRequestService } from '../../../shared/services/supplier-group-request.service';
import { SupplierGroupService } from '../../../shared/services/supplier-group.service';

@Component({
  selector: 'app-supplier-group-import',
  templateUrl: './supplier-group-import.component.html',
  styleUrls: ['./supplier-group-import.component.scss']
})
export class SupplierGroupImportComponent extends BaseUploadButtonComponent implements OnInit, OnDestroy {
  @ViewChild('uploadSupplierGroupInput', { static: false }) uploadInput: ElementRef;
  @Input() mode: SupplierGroupModeEnum;
  @Input() supplierGroupNo: string;
  constructor(
    protected readonly modalService: BsModalService,
    protected uploadService: CommonUploadService,
    protected readonly translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected readonly logger: NGXLogger,
    protected supplierGroupRequestService: SupplierGroupRequestService,
    protected supplierGroupService: SupplierGroupService
  ) {
    super(modalService, uploadService, translate, spinner, logger);
  }

  ngOnInit() {
    if ([SupplierGroupModeEnum.REQUEST_EDIT, SupplierGroupModeEnum.REQUEST_CREATE].includes(this.mode)) {
      this.uploadHeaders = env.services.supplierGroupRequest.headers;
      this.uploadUrl = this.supplierGroupRequestService.getUrlImport();
    } else if ([SupplierGroupModeEnum.VIEW, SupplierGroupModeEnum.EDIT].includes(this.mode)) {
      this.uploadHeaders = env.services.supplierGroup.headers;
      this.uploadUrl = this.supplierGroupService.getUrlImport(this.supplierGroupNo);
    }
  }

  onHandleUploadError({ value }: { value: HttpErrorResponse }) {
    if ([400, 404, 500].includes(value.status)) {
      if (value.error.code !== '00001') {
        this.alertFailValidation(this.translate.instant(value.error.translateKey));
      } else {
        this.alertFailValidation(value.error.message);
      }
    }
    this.uploadInput.nativeElement.value = '';
    this.spinner.hide();
  }

  onHandleUploadSuccess(resp) {
    this.itemDetail.emit(resp);
    this.uploadInput.nativeElement.value = '';
    this.spinner.hide();
  }

  OnClickImport() {
    this.uploadInput.nativeElement.click();
  }
}
