<div id="home-page" class="page-wrapper">
  <div class="page-content flex-item">
    <div class="flex-item d-none d-md-block">
      <router-outlet name="leftsection"></router-outlet>
    </div>
    <div class="right-wrapper flex-item">
      <img id="red-line" class="d-md-none" src="/assets/images/8-degree.svg" alt="TD Tawandang" />
      <div class="form-section flex-item">
        <div class="home-logo mt-lg-4">
          <img src="/assets/images/vertical-logo-border.png" alt="TD Tawandang" />
          <app-tier-tag class="mt-2 d-block"></app-tier-tag>
        </div>
        <router-outlet></router-outlet>
      </div>
      <div class="footer-section">
        <p class="footer">
          &copy; 2019 TD Tawandang Co.,Ltd. All rightS reserved. www.tdshop.io
          <app-version-tag></app-version-tag>
        </p>
      </div>
    </div>
  </div>
</div>
