import { Action } from '@ngrx/store';
import { OfficeWarehouse } from '../../models/warehouse.model';

export enum WarehouseMasterActionTypes {
  WAREHOUSE_MASTER_LIST_REQUEST = '[Warehouse Master List] Warehouse Master List Request',
  WAREHOUSE_MASTER_LIST_RESPONSE = '[Warehouse Master List] Warehouse Master List Response'
}

export class WarehouseMasterListRequestAction implements Action {
  readonly type = WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_REQUEST;

  constructor() {}
}

export class WarehouseMasterListResponseAction implements Action {
  readonly type = WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_RESPONSE;
  constructor(public payload: OfficeWarehouse[]) {}
}

export type WarehouseMasterAction = WarehouseMasterListRequestAction | WarehouseMasterListResponseAction;
