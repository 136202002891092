import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { Z8ResultImportCriteria } from '../../../../shared/models/z8-result.model';
import { Z8ResultService } from '../../../../shared/services/z8-result.service';

@Component({
  selector: 'app-target-stock-import-modal',
  templateUrl: './target-stock-import-modal.component.html',
  styleUrls: ['./target-stock-import-modal.component.scss']
})
export class TargetStockImportModalComponent implements OnInit {
  public orderDate: string;
  public warehouseCode: string;
  public form: FormGroup;
  public loading: boolean;
  public errors = [];
  public errorMessage: string;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected modalService: BsModalService,
    protected readonly z8ResultService: Z8ResultService,
    protected readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {}

  createForm() {
    this.form = this.fb.group({
      targetStockImport: [null, Validators.required]
    });
  }

  onSubmit() {
    if (!this.form.value && this.form.value.targetStockImport.length === 0) {
      return;
    }

    this.loading = true;

    const z8ResultImportCriteria = new Z8ResultImportCriteria({
      orderDate: moment(new Date(this.orderDate)).format('DD/MM/YYYY'),
      warehouseCode: this.warehouseCode
    });

    this.z8ResultService.importZ8Result(this.form.value.targetStockImport[0], z8ResultImportCriteria).subscribe(
      () => {
        this.closeModal();

        this.alertSuccessModal('The data have been imported. System will take a while to regenerate report.');
      },
      errorResponse => {
        if (errorResponse.error.code === '400') {
          const importError = errorResponse.error.items || [];

          if (importError.length) {
            this.setErrorMessage(`${importError.length} Errors Found.`);

            importError.forEach(item => {
              this.addErrorData(item);
            });
          }
        } else {
          this.setErrorMessage(
            errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey)
          );
        }
      }
    );
  }

  addErrorData(error: any) {
    return this.errors.push(error);
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
    this.loading = false;
  }

  reset() {
    this.errorMessage = null;
    this.errors = [];
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
}
