import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { GoodsReceiveModeEnum, GoodsReceiveStatusEnum } from '../../../shared/enum/goods-receive.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ShipToType } from '../../../shared/enum/purchase-order.enum';
import { PurchaseRequestTypeEnum } from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  GoodsReceiveContent,
  GoodsReceiveExportCriteria,
  GoodsReceiveSearchCriteria
} from '../../../shared/models/goods-receive.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import {
  grManagePermissions,
  grViewPermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prManagePermissions,
  prViewPermissions
} from '../../../shared/permissions/permissions';
import { AuthGuardService } from '../../../shared/services';
import { GoodsReceiveService } from '../../../shared/services/goods-receive.service';
import { SupplierService } from '../../../shared/services/supplier.service';
import {
  GoodsReceiveCancelRequest,
  GoodsReceiveListRequest
} from '../../../shared/store/actions/goods-receive.actions';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { GoodsReceiveState } from '../../../shared/store/reducers/goods-receive.reducers';
import {
  selectGoodsReceive,
  selectGoodsReceiveErrorResponse,
  selectGoodsReceiveList
} from '../../../shared/store/selectors/goods-receive.selectors';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { GoodsReceiveViewComponent } from '../goods-receive-view/goods-receive-view.component';

@Component({
  selector: 'app-goods-receive-list',
  templateUrl: './goods-receive-list.component.html',
  styleUrls: ['./goods-receive-list.component.scss']
})
export class GoodsReceiveListComponent extends BaseSearchComponent<
  GoodsReceiveSearchCriteria,
  GoodsReceiveContent,
  GoodsReceiveState
> {
  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];
  public bsModalRef: BsModalRef;
  public localStore: Observable<any>;

  public dateTag: string;
  public dateStringTag: string;
  public prTypeTag: string;
  public prTypeStringTag: string;

  public grStatusFilter = filterDropdown.grStatusFilter;
  public prTypeFilter = filterDropdown.prTypeFilter;
  public grStatusExportFilter: NgOption[];
  public purchaseRequestTypeEnum = PurchaseRequestTypeEnum;
  public goodsReceiveStatusEnum = GoodsReceiveStatusEnum;
  public shipToType = ShipToType;
  public hasViewGrPermission = false;
  public hasManageGrPermission = false;
  public viewGrPermission = grViewPermissions;
  public manageGrPermission = grManagePermissions;
  public allowExportGr: boolean;
  public permissionAction = PermissionAction;

  public supplierList: Observable<SupplierSearch[]>;
  public supplierSearchInput$ = new Subject<string>();
  public supplierSearchLoading = false;
  public createdMaxDate: Date;
  public createdMinDate: Date;
  public exportForm: FormGroup;
  public submittedExport: boolean;
  errorExport: string;
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store.select(selectWarehouseList);
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected goodsReceiveService: GoodsReceiveService,
    protected supplierService: SupplierService,
    protected permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, selectGoodsReceiveList, selectGoodsReceive);
    super.subscribeForSaveSuccess();
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
  }

  doInit() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: false, module: 'gr' }));
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectGoodsReceiveList))
      .subscribe(() => (this.currentPage = this.criteriaObject.page + 1));
    this.hasViewGrPermission = this.authGuardService.checkPermission(this.viewGrPermission, true);
    this.hasManageGrPermission = this.authGuardService.checkPermission(this.manageGrPermission, true);

    this.allowExportGr = this.authGuardService.checkPermission([...grViewPermissions, ...grManagePermissions], true);

    if (this.hasViewGrPermission && !this.hasManageGrPermission) {
      this.grStatusFilter = filterDropdown.grStatusFilter.filter(status => {
        return status.value !== GoodsReceiveStatusEnum.DRAFT;
      });

      this.grStatusExportFilter = filterDropdown.grStatusFilter.filter(
        status => status.value !== '' && status.value && status.value !== GoodsReceiveStatusEnum.DRAFT
      );
    } else {
      this.grStatusExportFilter = filterDropdown.grStatusFilter.filter(status => status.value !== '');
    }

    this.localStore.pipe(select(selectGoodsReceiveErrorResponse)).subscribe(error => {
      if (error) {
        if (error.code === '08028') {
          this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: `${error.message}.`
            }
          });
        }
      }
    });

    this.createdMinDate = new Date(2019, 0, 1);
    this.createdMaxDate = new Date();
    this.createdMaxDate.setDate(this.maxDate.getDate() + 365);

    this.createExportForm();

    this.loadSupplier('');
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.grStatusFilter[0].value],
      purchaseType: [null],
      createdDateFrom: [null],
      createdDateTo: [null]
    });
  }

  setInitialValue() {
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  onchangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: GoodsReceiveStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  getPurchaseType(goodsReceive: GoodsReceiveContent) {
    if (goodsReceive.type === this.purchaseRequestTypeEnum.Z9) {
      return `Z9(${this.translate.instant('DAY_INDEX.' + goodsReceive.orderSchedule)})`;
    } else if (goodsReceive.type === this.purchaseRequestTypeEnum.Z9_EDIT) {
      return `Z9(${this.translate.instant('DAY_INDEX.' + goodsReceive.orderSchedule)}) - Edit`;
    } else if (goodsReceive.type === this.purchaseRequestTypeEnum.HOT_PRICE) {
      return 'Hot Price';
    } else if (goodsReceive.type === this.purchaseRequestTypeEnum.SUPPLIER) {
      return 'Supplier';
    } else if (goodsReceive.type === this.purchaseRequestTypeEnum.WAREHOUSE) {
      return 'Warehouse';
    }
  }

  goToView(gr) {
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          GoodsReceiveViewComponent,
          {
            id: gr.id,
            title: 'View GR',
            mode: GoodsReceiveModeEnum.VIEW
          },
          true
        )
      }
    });
  }

  goToEdit(gr) {
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          GoodsReceiveViewComponent,
          {
            id: gr.id,
            title: 'Edit GR',
            mode: GoodsReceiveModeEnum.CREATE
          },
          true
        )
      }
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.createdDateFrom && !formValue.createdDateTo && !formValue.purchaseType) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      purchaseType: formValue.purchaseType,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearDateFilter() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearTypeFilter() {
    this.setFirstPage();
    this.searchForm.controls['purchaseType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      purchaseType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['purchaseType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      purchaseType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.prTypeTag = null;
    this.prTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'GR Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.purchaseType && this.criteriaObject.purchaseType.length > 0) {
      this.prTypeStringTag = 'Purchase Type';
      const prType = this.prTypeFilter
        .filter(data => {
          return this.criteriaObject.purchaseType.indexOf(data.value) > -1;
        })
        .map(status => status.label);

      const poTypes = prType.join(', ');
      this.prTypeTag = `"${poTypes}"`;
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new GoodsReceiveListRequest(criteriaObj));
  }

  setRouteTab() {
    const hasPrPermission = this.authGuardService.checkPermission(
      [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions],
      true
    );
    const hasPoPermission = this.authGuardService.checkPermission(
      [...poViewPermissions, ...poManagePermissions, ...grManagePermissions],
      true
    );
    const hasGrPermission = this.authGuardService.checkPermission(
      [...grViewPermissions, ...grManagePermissions],
      true
    );
    if (hasPrPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_REQUEST.PURCHASE_REQUEST',
        url: '/purchase/purchase-request-list'
      });
    }

    if (hasPoPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_ORDER.PURCHASE_ORDER',
        url: '/purchase/purchase-order-list'
      });
    }

    if (hasGrPermission) {
      this.listRoute.push({
        tabName: 'GOODS_RECEIVE.GOODS_RECEIVE',
        url: '/purchase/goods-receive-list'
      });
    }
  }

  onCancelGr(id: string, docNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel GR Number "${docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new GoodsReceiveCancelRequest({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showHistory(goodsReceive: GoodsReceiveContent) {
    const initialState = {
      title: 'History',
      historyHeader: `GR Number.: ${goodsReceive.docNo}`,
      // historyType: HistoryType.CLAIM_REQUEST,
      auditLogs: goodsReceive.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      grCreatedDateFrom: [null],
      grCreatedDateTo: [null],
      status: [null],
      supplier: [null],
      poNumber: [null],
      code: [null]
    });
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();
    this.goodsReceiveService.exportGr(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
        this.doSearch(this.criteriaObject);
        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  prepareExportRequestData(formData): GoodsReceiveExportCriteria {
    const createdDateFrom = dateToStringCriteria(formData.grCreatedDateFrom);
    const createdDateTo = dateToStringCriteria(formData.grCreatedDateTo, false);
    const statuses = this.getValue(formData.status);
    const supplierCode = this.getValue(formData.supplier) ? formData.supplier.supplierCode : null;
    const search = this.getValue(formData.poNumber);
    const locations = this.getValue(formData.code);

    return {
      createdDateFrom,
      createdDateTo,
      statuses,
      supplierCode,
      search,
      locations
    };
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportGr.timeFormat);

    return `${environment.fileName.exportGr.prefix}${formattedDate}.xlsx`;
  }

  resetExportForm() {
    this.exportForm.controls['grCreatedDateFrom'].reset();
    this.exportForm.controls['grCreatedDateTo'].reset();
    this.exportForm.controls['status'].reset();
    this.exportForm.controls['supplier'].reset();
    this.exportForm.controls['poNumber'].reset();
    this.exportForm.controls['code'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }

  clearError() {
    this.exportForm.controls['grCreatedDateFrom'].setErrors(null);
    this.exportForm.controls['grCreatedDateTo'].setErrors(null);
    this.errorExport = null;
  }

  alertErrorModal(errorResponse) {
    if (errorResponse && errorResponse.error) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.error.messsage
      };

      if (errorResponse.status === 404) {
        this.errorExport = this.translate.instant('ERRORS.NO_TRANSACTION');
      } else if (['00008', '08023'].includes(errorResponse.error.code)) {
        this.errorExport = errorResponse.error.message;
      } else {
        initialState.message = errorResponse.error.message;

        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  onChangeGRCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeGRCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
    }
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  onBlurSupplierName() {
    if (!this.exportForm.controls.supplier.value) {
      this.loadSupplier('');
    }
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !(formValues.grCreatedDateFrom || formValues.supplier || formValues.poNumber);
  }
}
