<!-- Modal Section -->
<div
  id="salesSummaryReportModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #salesSummaryReportModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export Sales Summary Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group col-md-12">
                    <div class="form-row">
                      <div class="col-12">Create Date<span class="text-danger">*</span></div>
                      <div class="col-md-6">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="salesSummaryCreatedDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeExportCreatedDateFrom($event)"
                          readonly
                        />
                        <label for="salesSummaryCreatedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                      <div class="col-md-6">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="salesSummaryCreatedDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [minDate]="minDate"
                          [bsConfig]="bsDateConfig"
                          (bsValueChange)="onChangeExportCreatedDateTo($event)"
                          readonly
                        />
                        <label for="salesSummaryCreatedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group  col-md-12">
                    <div class="mb-2">
                      Store Type
                    </div>
                    <ng-select
                      [items]="storeTypeList"
                      placeholder="Please select..."
                      [searchable]="false"
                      [clearable]="false"
                      [multiple]="true"
                      bindLabel="nameTh"
                      bindValue="code"
                      formControlName="storeType"
                    >
                    </ng-select>
                  </div>

                  <div *ngIf="responseExportError" class="alert-danger form-group mb-0">
                    {{ responseExportError }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_delete_bill_report_btn"
            [disabled]="exportFormInValid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Section -->
