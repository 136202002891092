import { Action } from '@ngrx/store';
import { OrderSelectItemSearchCriteria } from '../../models/order-request.model';

export enum OrderSelectItemActionTypes {
  ORDER_SELECT_ITEM_LIST_REQUEST = '[Order Select Item] Order Select Item List Request',
  ORDER_SELECT_ITEM_LIST_RESPONSE = '[Order Select Item] Order Select Item List Response',
  ORDER_SELECT_ITEM_LIST_RESET = '[Order Select Item] Order Select Item List Reset'
}

export class OrderSelectItemListRequest implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST;

  constructor(public payload: OrderSelectItemSearchCriteria) {}
}

export class OrderSelectItemListResponse implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESPONSE;

  constructor(public payload: any) {}
}

export class OrderSelectItemListReset implements Action {
  readonly type = OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_RESET;

  constructor() {}
}

export type OrderSelectItemActions =
  | OrderSelectItemListRequest
  | OrderSelectItemListResponse
  | OrderSelectItemListReset;
