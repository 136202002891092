import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { VoucherService } from '../../services/voucher.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { VoucherActionTypes, VoucherByVoucherCodeResponseAction, VoucherListResponseAction } from '../actions/voucher.actions';
export class VoucherEffects {
    constructor(actions$, logger, voucherService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.voucherService = voucherService;
        this.searchVoucherRequest$ = this.actions$.pipe(ofType(VoucherActionTypes.VOUCHER_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${VoucherActionTypes.VOUCHER_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.voucherService.searchByCriteriaRequest(payload).pipe(map(requests => {
                return new VoucherListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.cancelVoucher$ = this.actions$.pipe(ofType(VoucherActionTypes.VOUCHER_CANCEL), tap(action => this.logger.debug('@Effect Voucher Cancel: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Your voucher has been cancelled.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getVoucherByVoucherCode$ = this.actions$.pipe(ofType(VoucherActionTypes.VOUCHER_GET_BY_CODE_REQUEST), tap(action => this.logger.debug('@Effect Voucher By VoucherCode Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherService.getVoucherByVoucherCode(action.payload.voucherCode).pipe(map(VoucherView => {
            return new VoucherByVoucherCodeResponseAction({ VoucherView });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherEffects.prototype, "searchVoucherRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherEffects.prototype, "cancelVoucher$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherEffects.prototype, "getVoucherByVoucherCode$", void 0);
