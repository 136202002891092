<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store"></em>
    {{ data.title }}
  </div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelStoreRequest()">
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteStoreRequest()">
    <em class="icon-delete"></em>
    Delete
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form" [ngClass]="{ 'is-footer': hasAtLeastOnePermission() }">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="(storeView$ | async)?.requestNo">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (storeView$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span
          *ngIf="storePageMode.REQUEST_CREATE === data.mode; else getStatus"
          [ngClass]="getColorStatus('D')"
          class="request-status"
        >
          {{ 'STORE_REQUEST.STATUS.D' | translate }}</span
        >
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((storeView$ | async)?.status)" class="request-status">
            {{ 'STORE_REQUEST.STATUS.' + (storeView$ | async)?.status | translate }}</span
          >
        </ng-template>
      </div>
    </div>
  </div>

  <div [formGroup]="storeForm">
    <!-- Store Profile Section -->
    <div class="information-container">
      <div class="row merchant-info">
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant ID</span>
            <span class="info-header-value">{{ (storeView$ | async)?.no | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Tax ID/ID card</span>
            <span class="info-header-value">{{ (storeView$ | async)?.taxId | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant Name</span>
            <span class="info-header-value">{{ (storeView$ | async)?.merchantName | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Merchant Type</span>
            <span class="info-header-value">{{ 'STORE_TYPE.' + (storeView$ | async)?.merchantType | translate }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Contact Name</span>
            <span class="info-header-value">{{ (storeView$ | async)?.contactName | dashDisplay }}</span>
          </div>
        </div>
        <div class="col-lg-2 col-md-4">
          <div class="d-flex flex-md-column">
            <span class="info-header-label">Mobile Phone</span>
            <span class="info-header-value">{{ (storeView$ | async)?.mobilePhone | dashDisplay }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Store Profile -->
    <div class="row section-header justify-content-between">
      <span>Store Profile</span>
    </div>

    <app-store-profile
      #storeProfile
      [parentForm]="storeForm"
      [mode]="data.mode"
      [page]="TDStorePage"
      [saved]="saved"
      [submitted]="submitted"
      [requestId]="data.storeId"
    ></app-store-profile>
    <!-- Store Profile -->

    <hr class="section-divider" />

    <!-- Order Policy -->
    <div class="row section-header">
      <span id="ORDER_POLICY">Order Schedule(for supply chain)</span>
    </div>

    <app-order-policy
      #orderPolicy
      [parentForm]="storeForm"
      [mode]="data.mode"
      [page]="TDStorePage"
      [submitted]="submitted"
    ></app-order-policy>
    <!-- Order Policy -->
    <!-- Store Profile Section -->
  </div>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container
      *ngIf="[storePageMode.REQUEST_CREATE, storePageMode.REQUEST_EDIT].includes(data.mode); else otherMode"
    >
      <div class="fixed-row-bottom form-row">
        <div class="float-left" *ngIf="hasSavePermission()">
          <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()">
            Save
          </button>
        </div>
        <div class="float-right" *ngIf="hasSubmitStorePermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditStorePermission()" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
            <span *ngIf="hasApproveStorePermission()">
              <button
                type="button"
                id="btn-reject"
                class="btn btn-special-reject mr-2"
                (click)="doApproveRejectRequest(false)"
              >
                Reject
              </button>
              <button
                type="button"
                id="btn-approve"
                class="btn btn-special-approve"
                (click)="doApproveRejectRequest(true)"
              >
                Approve
              </button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
