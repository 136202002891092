import { Action } from '@ngrx/store';
import { SupplierPricePagination, SupplierPriceSearchCriteria } from '../../models/supplier-price.model';

export enum SupplierPriceActionTypes {
  SUPPLIER_PRICE_LIST_REQUEST = '[Supplier Price List Page] Supplier Price List Request',
  SUPPLIER_PRICE_LIST_RESPONSE = '[Supplier Price List API] Supplier Price List Response',
  SUPPLIER_PRICE_RESET = '[Supplier Price View Page] Supplier Price Reset',
  SUPPLIER_PRICE_SHOW_SUCCESS = '[Supplier Price View Page] Supplier Price Show Success',
  SUPPLIER_PRICE_IS_PRICE_UPDATED = '[Supplier Price View Page] Supplier Price is price updated'
}

export class SupplierPriceListRequest implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST;
  constructor(public payload: SupplierPriceSearchCriteria) {}
}

export class SupplierPriceListResponse implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_RESPONSE;

  constructor(public payload: SupplierPricePagination) {}
}

export class SupplierPriceReset implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_RESET;
  constructor() {}
}

export class SupplierPriceShowSuccess implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_SHOW_SUCCESS;
  constructor(public payload: boolean) {}
}

export class SupplierPriceIsPriceUpdated implements Action {
  readonly type = SupplierPriceActionTypes.SUPPLIER_PRICE_IS_PRICE_UPDATED;
  constructor(public payload: boolean) {}
}

export type SupplierPriceActions =
  | SupplierPriceListRequest
  | SupplierPriceListResponse
  | SupplierPriceReset
  | SupplierPriceShowSuccess
  | SupplierPriceIsPriceUpdated;
