import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AdjustStockItemContent, AdjustStockItemCriteria } from '../../models/adjust-stock.model';
import { AdjustStockAction, AdjustStockTypeActions } from '../actions/adjust-stock.actions';

export interface AdjustStockItemState extends EntityState<AdjustStockItemContent> {
  isLoading: boolean;
  criteriaObject: AdjustStockItemCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<AdjustStockItemContent> = createEntityAdapter<AdjustStockItemContent>({
  selectId: (model: AdjustStockItemContent) => model.articleNo
});

export const initialAdjustStockItemState: AdjustStockItemState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function AdjustStockItemReducers(
  state = initialAdjustStockItemState,
  action: AdjustStockAction
): AdjustStockItemState {
  switch (action.type) {
    case AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.adjustStockItemCriteria
      };
    case AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_RESET:
      return initialAdjustStockItemState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
