import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { beforeTodayValidator } from '../../../../shared/custom-validators/before-today-validator';
import { OrderingMethodEnum } from '../../../../shared/enum/ordering-method.enum';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { DeliveryDetails } from '../../../../shared/models/order-request.model';
import { OrderRequestSaveDeliveryDetailsAction } from '../../../../shared/store/actions/order-request.actions';
import { selectDeliveryDetails } from '../../../../shared/store/selectors/order-request.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../../shared/utils/date-util';

@Component({
  selector: 'app-delivery-details-special',
  templateUrl: './delivery-details-special.component.html',
  styleUrls: ['./delivery-details-special.component.scss']
})
export class DeliveryDetailsSpecialComponent implements OnInit, OnDestroy {
  @Input() orderType: OrderingMethodEnum;
  @Input() mode: RequestPageModesEnum;
  @Output() updateDeliveryDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

  public bsConfig: BsDatepickerConfig;
  public form: FormGroup;
  public submitted: boolean;
  public dateFormat = env.dateFormat;

  private localStore: Observable<any>;
  public deliveryDetails$: Observable<DeliveryDetails>;

  constructor(public fb: FormBuilder, public store: Store<AppStates>, public bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue'
    } as BsDatepickerConfig;

    this.createForm();

    this.deliveryDetails$ = this.localStore.pipe(select(selectDeliveryDetails));
    this.deliveryDetails$.pipe(filter(data => Boolean(data && data.deliveryDetailsByType))).subscribe(value => {
      if (value) {
        this.specialForm.patchValue({
          ...(value.deliveryDetailsByType[this.orderType] && {
            deliveryDate: moment(value.deliveryDetailsByType[this.orderType].deliveryDate, [
              env.dateFormat,
              moment.ISO_8601
            ]).toDate(),
            deliveryNote: value.deliveryDetailsByType[this.orderType].deliveryNote
          })
        });
      }
    });

    this.setDeliveryDetailsCtrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode && !changes.mode.firstChange) {
      this.setDeliveryDetailsCtrl();
    }
  }

  ngOnDestroy() {}

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, [Validators.required, beforeTodayValidator()]];
    this.form = this.fb.group({
      deliveryDetailsByType: this.fb.group({
        [this.orderType]: this.fb.group({
          deliveryDate: initialNullRequired,
          deliveryNote: [{ value: null, disabled: false }]
        })
      })
    });
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value.deliveryDetailsByType) {
      for (const key of Object.keys(this.form.value.deliveryDetailsByType)) {
        this.form.value.deliveryDetailsByType[key].deliveryDate = formatDateStartOfDay(
          this.form.value.deliveryDetailsByType[key].deliveryDate,
          env.dateISO8601
        );
      }
    }

    this.store.dispatch(new OrderRequestSaveDeliveryDetailsAction(this.form.value));
    this.updateDeliveryDetails.emit(true);

    this.decline();
  }

  setDeliveryDetailsCtrl() {
    if (this.mode === RequestPageModesEnum.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  get specialForm() {
    return this.form.get('deliveryDetailsByType').get(this.orderType) as FormGroup;
  }

  get pageMode() {
    return RequestPageModesEnum;
  }
}
