import { ApproveStatusEnum } from '../enum/approve-status.enum';
import { NewRequestStatusEnum, RequestStatusEnum } from '../enum/request-status.enum';
import { RequestStepEnum, RequestTypeEnum } from '../enum/request-step.enum';
import { StoreType } from '../enum/store.enum';
import { AuditLog } from './audit-log.model';
import { FirstLotInfo } from './first-lot-order.model';
import { Sort } from './pagination.model';
import { Price } from './price.model';

export class MerchantValidateTaxIdRequest {
  taxId: number;

  constructor(taxId: number) {
    this.taxId = taxId;
  }
}

export class MerchantValidateTaxIdResponse {
  httpStatusCode: number;
  statusCode?: string;
  message?: string;
}

export class MerchantCreateRequest {
  merchantCreatRequest: MerchantRequestTemplate;
}

export class MerchantRequestResponse {
  id: string;
  version: number;
  requestNo?: string;
  type: RequestTypeEnum;
  status: RequestStatusEnum;
  step: RequestStepEnum;
  merchantInfo: MerchantInfo;
  legalDocument: LegalDocument;
  orderSchedule?: OrderSchedule;
  wallet: Wallet;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  lastModifiedDate: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
}

export class MerchantRequestTemplate {
  id?: string;
  version?: number;
  requestNo?: string;
  type: RequestTypeEnum;
  status: RequestStatusEnum;
  step: RequestStepEnum;
  merchantInfo: MerchantInfo;
  legalDocument: LegalDocument;
  orderSchedule?: OrderSchedule;
  wallet: Wallet;
  createdDate?: string;
  createdBy?: string;
  createdByName?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
}

export class MerchantRequestViewResponse {
  id?: string;
  version?: number;
  requestNo?: string;
  type: RequestTypeEnum;
  status: RequestStatusEnum;
  step: RequestStepEnum;
  merchantInfo: MerchantInfo;
  legalDocument: LegalDocument;
  wallet: Wallet;
  orderSchedule: OrderSchedule;
  firstLotPo?: FirstLotInfo;
  createdDate?: string;
  createdBy?: string;
  createdByName?: string;
  lastModifiedDate?: string;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
  requestedBy?: string;
  requestedByName?: string;

  public static mappingByMerchantRequestTemplate(
    merchantRequestViewResponse: MerchantRequestViewResponse,
    original: MerchantRequestTemplate = null
  ): MerchantRequestTemplate {
    return {
      ...(original || new MerchantRequestTemplate()),
      ...{
        ...(merchantRequestViewResponse.id && { id: merchantRequestViewResponse.id }),
        version: merchantRequestViewResponse.version,
        requestNo: merchantRequestViewResponse.requestNo,
        type: merchantRequestViewResponse.type,
        status: merchantRequestViewResponse.status,
        step: merchantRequestViewResponse.step,
        merchantInfo: merchantRequestViewResponse.merchantInfo,
        legalDocument: merchantRequestViewResponse.legalDocument,
        wallet: merchantRequestViewResponse.wallet,
        orderSchedule: merchantRequestViewResponse.orderSchedule,
        firstLotPo: merchantRequestViewResponse.firstLotPo,
        createdDate: merchantRequestViewResponse.createdDate,
        createdBy: merchantRequestViewResponse.createdBy,
        createdByName: merchantRequestViewResponse.createdByName,
        lastModifiedDate: merchantRequestViewResponse.lastModifiedDate,
        lastModifiedBy: merchantRequestViewResponse.lastModifiedBy,
        lastModifiedByName: merchantRequestViewResponse.lastModifiedByName,
        requestedBy: merchantRequestViewResponse.requestedBy,
        requestedByName: merchantRequestViewResponse.requestedByName
      }
    } as MerchantRequestTemplate;
  }
}

export class MerchantInfo {
  merchantNo?: string;
  merchantProfile?: MerchantProfile;
  ownerProfile?: OwnerProfile;
  storeProfile?: StoreProfile[];
}

export class LegalDocument {
  idCardFront: string;
  idCardBack: string;
  houseRegistration: string;
  letterOfIntent: string;
  contractSignOff: string;
  creditBureauSignOff?: string;
  criminalCheck: string;
}

export class Wallet {
  bankName: string;
  accountNo: string;
  accountName: string;
  bookBank: string;
  dipChip: string;
  jcNumber: string;
}

export class OrderSchedule {
  orderScheduleList: OrderScheduleList[];
}

export class OrderScheduleList {
  warehouseCode: string;
  minOrder: number;
  schedules: Schedules[];
}

export class UpdateOrderScheduleTemplate extends OrderScheduleList {
  no: string;
  version: string;
}

export class Schedules {
  orderScheduleDate: string;
  orderScheduleTime: string;
  deliverySchedule: string;
  pickScheduleDate: string;
}

export enum WeekEnum {
  SUN = 0,
  MON = 1,
  TUE = 2,
  WED = 3,
  THU = 4,
  FRI = 5,
  SAT = 6
}

export class MerchantProfile {
  customerType: string;
  taxId: string;
  merchantType: string;
  merchantName: string;
  address: string;
  state: string;
  postCode: string;
  country: string;
  currency: string;
}

export class StoreProfile {
  storeNo?: string;
  storeCode: string;
  storeName: string;
  taxNo: string;
  branchNo: string;
  contactName: string;
  email: string;
  address: string;
  state: string;
  region: string;
  postCode: string;
  country: string;
  timezone: string; // Asia/Bangkok
  countryCode: string;
  phone: string;
  buildingType: string;
  unitSize: string;
  unitWLH: number;
  propertyOwnership: string;
  rentalFee: Price;
  saleSpace: number;
  stockSpace: number;
  latitude: number;
  longitude: number;
  parking: string;
  deliveryByFourWheelsTruck: boolean;
  deliveryTimes?: RestrictedDeliveryTime[];
  titleDeed: string;
  houseRegistrationPicture: string;
  idCardPicture: string;
  consentLetterPicture: string;
  storeFrontPicture: string[];
  storeFrontVideo: string;
  attachmentPicture: string[];
  nearByPois: NearByPoi[];
  nearByCompetitors?: NearByCompetitor[];
}

export class OwnerProfile {
  cardId: string;
  contactFirstName: string;
  contactLastName: string;
  contactLocalFirstName: string;
  contactLocalLastName: string;
  countryCode: string;
  mobilePhone: string;
  email: string;
  gender: string;
  birthDate: string;
  testScore: number;
  attitudeTest: string;
  ownerPicture: string;
  userName?: string;
}

export class MerchantListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  startCreatedDate?: string;
  endCreatedDate?: string;
  page: number;
  size: number;
}

export class MerchantRequestListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  startRequestDate?: string;
  endRequestDate?: string;
  type?: string;
  page: number;
  size: number;
}

export interface MerchantList {
  id: number;
  taxId: string;
  merchant: string;
  merchantName: string;
  merchantType: string;
  createdByName: string;
  createdDate: string;
  status: RequestStepEnum;
  requestType: RequestTypeEnum;
  auditLogs: AuditLog[];
}

export interface MerchantRequestList {
  id: string;
  requestNo: string;
  merchantId: string;
  merchantName: string;
  requestBy: string;
  requestDate: string;
  step: RequestStepEnum;
  requestStatus: RequestStatusEnum;
  auditLogs: AuditLog[];
  type: RequestTypeEnum;
}

export class MerchantListResponse {
  id: number;
  content: MerchantList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class MerchantRequestListResponse {
  id: number;
  content: MerchantRequestList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class MerchantRequestId {
  merchantRequestId: string;
}

export class SignedUrlObject {
  refId: string;
  signedUrl: string;
}

export class ApproveMerchantRequest {
  requestNo: string;
  status: ApproveStatusEnum;
  step: RequestStepEnum;
  comment: string;
}

export enum MerchantPageTabs {
  MERCHANT_INFO = 'MERCHANT_INFO',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER'
}

export class Merchant {
  merchant: string;
}

export class MerchantViewResponse {
  id: string;
  version: number;
  no: string;
  type: RequestTypeEnum;
  status: string;
  deleted: boolean;
  merchantInfo: MerchantInfo;
  legalDocument: LegalDocument;
  wallet: Wallet;
  createdDate: string;
  createdBy: string;
  createdByName: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
}

export class RestrictedDeliveryTime {
  from: string;
  to: string;
}

export class NearByPoi {
  poiType: string;
  poiName: string;
  distance: string;
}

export class NearByCompetitor {
  competitorName: string;
  unitSize: string;
  distance: string;
}

export class MerchantStore {
  code: string;
  createdByName: string;
  createdDate: string;
  defaultGroup: boolean;
  deleted: boolean;
  deviceId: string;
  deviceStatus: string;
  group: string;
  id: string;
  lastModifiedDate: string;
  merchant: string;
  merchantStatus: NewRequestStatusEnum;
  merchantType: StoreType;
  name: string;
  no: string;
  region: string;
  site: string;
  state: string;
  status: NewRequestStatusEnum;
}
