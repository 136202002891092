import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/base.environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { StoreList } from '../../../shared/models';
import { InventoryCountTransactionTempReportExportCriteria } from '../../../shared/models/report.model';
import { MasterService } from '../../../shared/services/master.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { StoreService } from '../../../shared/services/store.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-inventory-count-transaction-temp-modal',
  templateUrl: './inventory-count-transaction-temp-modal.component.html',
  styleUrls: ['./inventory-count-transaction-temp-modal.component.scss']
})
export class InventoryCountTransactionTempModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('inventoryCountTransactionTempModal', { static: false })
  inventoryCountTransactionTempModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  public storeTypeList: Array<NewMasterData> | null;
  public storeList: Observable<StoreList[]>;
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly merchantService: MerchantService,
    private readonly masterService: MasterService,
    private readonly storeService: StoreService
  ) {
    super();
  }

  ngOnInit() {
    this.loadStore('');
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      createdDateFrom: [null],
      createdDateTo: [null],
      storeType: [null],
      store: [null]
    });

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });
  }

  get form() {
    return this.exportForm.controls;
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.inventoryCountTransactionTempModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.inventoryCountTransactionTempModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid && this.exportForm.valid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();

    const exportCriteria = new InventoryCountTransactionTempReportExportCriteria({
      createdDateFrom: dateToStringCriteria(rawData.createdDateFrom, true),
      createdDateTo: dateToStringCriteria(rawData.createdDateTo, false),
      storeType: rawData.storeType && rawData.storeType.length > 0 ? rawData.storeType.toString() : rawData.storeType,
      storeNo: rawData.store,
      exportBy: 'count'
    });

    this.storeService
      .exportInventoryCountStore<InventoryCountTransactionTempReportExportCriteria>(exportCriteria)
      .subscribe(
        response => {
          const blob = new Blob([response]);
          saveAs(
            blob,
            `${environment.fileName.exportInventoryCountTransactionTemp.prefix} ${this.timeToExport}.xlsx`
          );
        },
        error => {
          this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
        },
        () => {
          this.submitted = false;
          this.closeExportModal();
        }
      );
  }

  onChangeExportCreatedDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportCreatedDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportInventoryCountTransactionTemp.timeFormat);
  }

  loadStore(initialTerm: string) {
    this.storeList = concat(
      of(null),
      this.storeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term =>
          this.merchantService
            .searchStoreByCriteria({
              searchCriteria: term,
              sortBy: 'name',
              sortOrder: 'asc',
              page: 0,
              size: 20
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              map(data => data.content),
              tap(() => {
                this.storeSearchLoading = false;
              })
            )
        )
      )
    );
  }
}
