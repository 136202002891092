<nav id="navbar" class="navbar navbar-default navbar-expand-lg justify-content-between">
  <div>
    <button class="nav-toggler burger-pr" type="button" (click)="toggleSidebar()">
      <span class="navbar-toggler-icon"></span>
    </button>
    <a class="navbar-brand">
      <img class="logo-image-small" src="./assets/images/logo.png" alt="TD Tawandang" />
    </a>
    <app-tier-tag class="ml-4 app-tier-tag"></app-tier-tag>
  </div>

  <div class="form-inline d-sm-inline pr-md-3">
    <ul class="list-inline align-middle d-sm-inline d-md-inline">
      <!-- <li class="list-inline-item mx-2 mx-md-3 btn-rotate">
        <em class="icon-bell pr-md-2 align-middle"></em>
      </li> -->
      <li class="list-inline-item mx-2">
        <div class="menu-profile btn-group align-middle" dropdown>
          <a id="button-menu-dividers" dropdownToggle aria-controls="dropdown-menu-dividers">
            <em class="icon-profile align-middle"></em>
            <span class="align-middle d-none d-sm-inline pl-1">
              {{ (userInfoResult$ | async)?.userName }}
            </span>
          </a>

          <ul
            id="dropdown-menu-dividers"
            *dropdownMenu
            class="dropdown-menu profile"
            role="menu"
            aria-labelledby="button-menu-dividers"
          >
            <li role="menuitem">
              <a class="info dropdown-item">{{ (userInfoResult$ | async)?.displayName }}</a>
            </li>
            <!-- <li role="menuitem">
              <a class="menu-item dropdown-item">
                <em class="icon-profile pr-md-2" aria-hidden="true"></em>
                Profile
              </a>
            </li> -->
            <li class="divider dropdown-divider my-0"></li>
            <li role="menuitem">
              <a class="menu-item menu-bottom dropdown-item" (click)="onLogOut()">
                <em class="icon-logout pr-md-1" aria-hidden="true"></em>
                Logout
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>
