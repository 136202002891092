import { createSelector } from '@ngrx/store';
import * as fromOrderCreateResponseState from '../reducers/order-create.reducers';
import { AppStates } from '../state/app.states';

const selectOrderCreateState = (state: AppStates) => state.orderCreate;

export const selectOrderRequest = createSelector(
  selectOrderCreateState,
  (state: fromOrderCreateResponseState.OrderCreateResponseState | null) => state
);

export const selectOrderApproveRejectStatus = createSelector(
  selectOrderCreateState,
  (state: fromOrderCreateResponseState.OrderCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
