import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from '../../../../environments/base.environment';
import { UploadStatusEnum } from '../../../shared/enum/upload-status.enum';
import { AssortmentDeliveryMethodEnum } from '../../../shared/models';
import { SupplierPriceSearchCriteria } from '../../../shared/models/supplier-price.model';
import { AppStates } from '../../../shared/store/state/app.states';
import { b64toBlob } from '../../../shared/utils/b64toBlob-util';
import { AssortmentSupplierPriceFileUploadComponent } from '../assortment-supplier-price-file-upload/assortment-supplier-price-file-upload.component';

@Component({
  selector: 'app-assortment-supplier-price-modal-upload',
  templateUrl: './assortment-supplier-price-modal-upload.component.html',
  styleUrls: ['./assortment-supplier-price-modal-upload.component.scss']
})
export class AssortmentSupplierPriceModalUploadComponent implements OnInit, OnDestroy {
  @ViewChild(AssortmentSupplierPriceFileUploadComponent, { static: false })
  fileUpload: AssortmentSupplierPriceFileUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  public fileObject: any;
  public form: FormGroup;
  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;
  public uploadStatus = UploadStatusEnum;
  public searchCriteria: SupplierPriceSearchCriteria;
  public productName: string;
  public deliveryMethod: AssortmentDeliveryMethodEnum;

  private localStore: Observable<any>;

  constructor(
    public fb: FormBuilder,
    public bsModalRef: BsModalRef,
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.form.controls['uploadSupplierPrice'].valueChanges
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(value => {
        this.fileObject = value[0];
      });
  }

  downloadErrorExcel() {
    const blob = b64toBlob(this.form.controls.uploadSupplierPrice.value[0].fileBase64String);
    saveAs(blob, `${this.productName} Error ${this.timeToExport}.xlsx`);
  }

  ngOnDestroy() {
    this.form.reset();
  }

  createForm() {
    this.form = this.fb.group({
      uploadSupplierPrice: [null]
    });
  }

  onSubmit() {
    if (this.fileObject && !this.fileObject.importId && !this.fileObject.manualSupplierPriceData) {
      return;
    }

    this.submitUpload.emit({ file: this.fileObject });
    this.bsModalRef.hide();
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportSupplierPriceConfigs.timeFormat);
  }
}
