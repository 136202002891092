import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ProfitSharingModeEnum } from '../../../shared/enum/profit-sharing.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  ProfitSharingContent,
  ProfitSharingExportCriteria,
  ProfitSharingPrintCriteria,
  ProfitSharingSearchCriteria
} from '../../../shared/models/profit-sharing.model';
import { AuthGuardService } from '../../../shared/services';
import { ProfitSharingService } from '../../../shared/services/profit-sharing.service';
import { ProfitSharingListRequestAction } from '../../../shared/store/actions/profit-sharing.actions';
import { ProfitSharingState } from '../../../shared/store/reducers/profit-sharing.reducers';
import {
  selectAllProfitSharingList,
  selectProfitSharingList
} from '../../../shared/store/selectors/profit-sharing.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToMonthTagCriteria,
  dateStringToTagCriteria,
  dateToStringCriteria,
  dateToStringMonthCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { ProfitSharingViewComponent } from '../profit-sharing-view/profit-sharing-view.component';

@Component({
  selector: 'app-profit-sharing-list',
  templateUrl: './profit-sharing-list.component.html',
  styleUrls: ['./profit-sharing-list.component.scss']
})
export class ProfitSharingListComponent extends BaseSearchComponent<
  ProfitSharingSearchCriteria,
  ProfitSharingContent,
  ProfitSharingState
> {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  listRoute: Array<RouteLinkTab>;
  searchForm: FormGroup;
  submittedExport: boolean;
  profitSharingStatus: NgOption[];
  bsMonthConfig: BsDatepickerConfig;
  minMode: BsDatepickerViewMode = 'month';
  minMonth: Date;
  maxMonth: Date;
  dateTag: string;
  dateStringTag: string;
  scheduleTag: string;
  scheduleStringTag: string;
  bsModalRef: BsModalRef;
  exportForm: FormGroup;
  exportFormInValid: boolean;
  exportMaxMonth: Date;
  exportMinMonth: Date;
  responseExportError: string;
  hasPermissionManage: boolean;
  hasPermissionView: boolean;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected router: Router,
    protected fb: FormBuilder,
    protected profitSharingService: ProfitSharingService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllProfitSharingList, selectProfitSharingList);
    this.hasPermissionManage = this.authGuardService.checkPermission(['profitsharing_m']);
    this.hasPermissionView = this.authGuardService.checkPermission(['profitsharing_v']);
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    this.bsMonthConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'MM/YYYY',
      showWeekNumbers: false,
      adaptivePosition: true,
      minMode: this.minMode
    } as BsDatepickerConfig;

    this.createExportForm();
    this.setDropdownDefault();
  }

  setInitialValue() {
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);
    this.minMonth = new Date(2019, 0, 1);
    this.maxMonth = new Date();
    this.maxMonth.setDate(this.maxMonth.getDate() + 365);
    this.exportMinMonth = new Date(2019, 0, 1);
    this.exportMaxMonth = new Date();
    this.exportMaxMonth.setDate(this.maxMonth.getDate() + 365);
  }

  createForm() {
    this.submittedExport = false;
    this.searchForm = this.fb.group({
      searchCriteria: [null, Validators.required],
      status: [''],
      lastModifiedDateFrom: [null],
      lastModifiedDateTo: [null],
      scheduleFrom: [null],
      scheduleTo: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      scheduleFrom: [],
      scheduleTo: []
    });
  }

  setDropdownDefault() {
    this.profitSharingStatus = filterDropdown.profitSharingStatus;
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeMonthFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minMonth = new Date(value);
    } else {
      this.minMonth = new Date(2019, 0, 1);
    }
  }

  onChangeMonthTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxMonth = new Date(value);
    } else {
      this.maxMonth = new Date();
      this.maxMonth.setDate(this.maxMonth.getDate() + 365);
    }
  }

  goToView(viewParams: ProfitSharingContent) {
    const title = 'View Profit Sharing';
    const isRequiredLeaveForm = true;
    const initialState = {
      childItem: new ChildItem(
        ProfitSharingViewComponent,
        {
          title,
          id: viewParams.id,
          mode: ProfitSharingModeEnum.VIEW
        },
        isRequiredLeaveForm
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });
  }

  goToEdit(viewParams: ProfitSharingContent) {
    const isEndOfPeriod = moment(viewParams.lastAllowEditedDate).isBefore(moment().utc());
    if (isEndOfPeriod) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to edit. This period has been closed.'
        }
      });
      return;
    }

    const title = 'Edit Profit Sharing';
    const isRequiredLeaveForm = true;
    const initialState = {
      childItem: new ChildItem(
        ProfitSharingViewComponent,
        {
          title,
          id: viewParams.id,
          mode: ProfitSharingModeEnum.EDIT
        },
        isRequiredLeaveForm
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.scheduleFrom &&
      !formValue.scheduleTo &&
      !formValue.lastModifiedDateFrom &&
      !formValue.lastModifiedDateTo
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
    this.setFirstPage();
    let beginLastUpdate = formValue.lastModifiedDateFrom;
    let endLastUpdate = formValue.lastModifiedDateTo;
    if (beginLastUpdate && !isNaN(beginLastUpdate.getTime())) {
      beginLastUpdate = dateToStringCriteria(beginLastUpdate, true);
    } else {
      beginLastUpdate = null;
    }

    if (endLastUpdate && !isNaN(endLastUpdate.getTime())) {
      endLastUpdate = dateToStringCriteria(endLastUpdate, false);
    } else {
      endLastUpdate = null;
    }

    let beginMonth = formValue.scheduleFrom;
    let endMonth = formValue.scheduleTo;

    if (beginMonth && !isNaN(beginMonth.getTime())) {
      beginMonth = dateToStringMonthCriteria(beginMonth, true);
    } else {
      beginMonth = null;
    }

    if (endMonth && !isNaN(endMonth.getTime())) {
      endMonth = dateToStringMonthCriteria(endMonth, false);
    } else {
      endMonth = null;
    }

    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastModifiedDateFrom: beginLastUpdate,
      lastModifiedDateTo: endLastUpdate,
      scheduleFrom: beginMonth,
      scheduleTo: endMonth
    };

    this.doSearch(this.criteriaObject);
  }
  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.scheduleTag = null;
    this.scheduleStringTag = null;

    const lastUpdatedDateFrom = dateStringToTagCriteria(this.criteriaObject.lastModifiedDateFrom);
    const lastUpdatedDateTo = dateStringToTagCriteria(this.criteriaObject.lastModifiedDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Last Updated',
      dateFrom: lastUpdatedDateFrom,
      dateTo: lastUpdatedDateTo
    });
    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    const scheduleDateFrom = dateStringToMonthTagCriteria(this.criteriaObject.scheduleFrom);
    const scheduleDateTo = dateStringToMonthTagCriteria(this.criteriaObject.scheduleTo);

    const schedule = generateDateStringTag({
      dateName: 'As of Month',
      dateFrom: scheduleDateFrom,
      dateTo: scheduleDateTo,
      periodTag: 'Month'
    });
    this.scheduleStringTag = schedule.dateStringTag;
    this.scheduleTag = schedule.dateTag;
  }

  search(criteriaObj) {
    this.store.dispatch(new ProfitSharingListRequestAction(criteriaObj));
  }

  setRouteTab() {
    this.listRoute = [];

    if (this.hasPermissionView || this.hasPermissionManage) {
      this.listRoute.push({ tabName: 'Profit Sharing List', url: '/billing/profit-sharing-list' });
    }

    if (this.hasPermissionManage) {
      this.listRoute.push({ tabName: 'Monthly Profit Sharing', url: '/billing/monthly-partner-profit-sharing-list' });
    }
  }
  getColorStatus(status): string {
    return status.toLowerCase();
  }

  print(profitSharing: ProfitSharingContent) {
    if (!profitSharing) {
      return;
    }
    const param: ProfitSharingPrintCriteria = {
      docNo: profitSharing.docNo,
      runDate: profitSharing.schedule
        .split('/')
        .reverse()
        .join('')
    };

    this.profitSharingService.printProfitSharingPdf(param).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
      saveAs(blob, generatedFilenamePdf(profitSharing.docNo));
    });
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        (e.target.parentElement &&
          e.target.parentElement.classList &&
          e.target.parentElement.classList.contains('is-highlighted')) ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  clearDateFilter() {
    this.setFirstPage();
    this.searchForm.controls['lastModifiedDateFrom'].reset();
    this.searchForm.controls['lastModifiedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastModifiedDateFrom: null,
      lastModifiedDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearScheduleFilter() {
    this.setFirstPage();
    this.searchForm.controls['scheduleFrom'].reset();
    this.searchForm.controls['scheduleTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      scheduleFrom: null,
      scheduleTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['scheduleFrom'].reset();
    this.searchForm.controls['scheduleTo'].reset();
    this.searchForm.controls['lastModifiedDateFrom'].reset();
    this.searchForm.controls['lastModifiedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastModifiedDateFrom: null,
      lastModifiedDateTo: null,
      scheduleFrom: null,
      scheduleTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  openExportModal() {
    this.responseExportError = null;
    this.exportForm.controls['scheduleFrom'].reset();
    this.exportForm.controls['scheduleTo'].reset();
    this.exportFormInValid = true;

    this.exportModal.show();
  }

  onChangeExportMonthFrom(value: Date) {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.exportMinMonth = new Date(value);
    } else {
      this.exportMinMonth = new Date(2019, 0, 1);
    }
  }

  onChangeExportMonthTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.exportMaxMonth = new Date(value);
    } else {
      this.exportMaxMonth = new Date();
      this.exportMaxMonth.setDate(this.exportMaxMonth.getDate() + 365);
    }
  }

  closeExportModal() {
    this.exportForm.reset();
    this.exportModal.hide();
  }

  onExport() {
    if (this.exportFormInValid) {
      return;
    }

    const monthExport = moment().format(environment.fileName.exportProfitSharing.timeFormat);
    let scheduleFrom = this.exportForm.getRawValue().scheduleFrom;
    let scheduleTo = this.exportForm.getRawValue().scheduleTo;

    if (scheduleFrom && !isNaN(scheduleFrom.getTime())) {
      scheduleFrom = dateToStringMonthCriteria(scheduleFrom);
    } else {
      scheduleFrom = null;
    }

    if (scheduleTo && !isNaN(scheduleTo.getTime())) {
      scheduleTo = dateToStringMonthCriteria(scheduleTo, false);
    } else {
      scheduleTo = null;
    }

    const profitSharingExportCriteria = new ProfitSharingExportCriteria({
      scheduleFrom,
      scheduleTo
    });

    this.profitSharingService.export(profitSharingExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportProfitSharing.prefix}${monthExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message;
      },
      () => this.closeExportModal()
    );
  }

  showHistory(profitSharing: ProfitSharingContent) {
    const initialState = {
      title: 'History',
      historyHeader: profitSharing.docNo,
      action: HistoryType.REQUEST,
      historyType: HistoryType.PROFIT_SHARING,
      auditLogs: profitSharing.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }
}
