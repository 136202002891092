import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StoreGroupService } from '../../services/store-group.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  StoreGroupActionTypes,
  StoreGroupGetRequestAction,
  StoreGroupGetResponseAction,
  StoreGroupListRequestAction,
  StoreGroupListResponseAction,
  StoreGroupSubmitErrorAction,
  StoreGroupSubmitRequestAction,
  StoreGroupUpdateRequestAction
} from '../actions/store-group.actions';

@Injectable()
export class StoreGroupEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly storeGroupService: StoreGroupService
  ) {}

  @Effect()
  searchStoreGroup$ = this.actions$.pipe(
    ofType<StoreGroupListRequestAction>(StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeGroupService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          return new StoreGroupListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  createSubmitStoreGroup$ = this.actions$.pipe(
    ofType<StoreGroupSubmitRequestAction>(StoreGroupActionTypes.STORE_GROUP_SUBMIT_REQUEST),
    tap(action => this.logger.debug('@Effect Request Store Group Submit Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.storeGroupService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Store group has been created.'
            })
        ),
        catchError(err => this.errorHandling(err))
      );
    })
  );

  @Effect()
  updateStoreGroup$ = this.actions$.pipe(
    ofType<StoreGroupUpdateRequestAction>(StoreGroupActionTypes.STORE_GROUP_UPDATE_REQEUST),
    tap(action => this.logger.debug('@Effect Request Store Group update Request: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.storeGroupService
        .update(action.payload.merchantNo, action.payload.groupNo, action.payload.data)
        .pipe(
          map(
            () =>
              new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been saved.'
              })
          ),
          catchError(err => this.errorHandling(err))
        );
    })
  );

  @Effect()
  getStoreGroup$ = this.actions$.pipe(
    ofType<StoreGroupGetRequestAction>(StoreGroupActionTypes.STORE_GROUP_GET_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreGroupActionTypes.STORE_GROUP_GET_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeGroupService.getGroup(payload.merchantNo, payload.groupNo).pipe(
        map(requests => {
          return new StoreGroupGetResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  errorHandling(err: any) {
    return err &&
      err.error &&
      (err.error.code === '00000' || err.error.code === '04015' || err.error.code === '04016')
      ? of(new StoreGroupSubmitErrorAction(err.error))
      : of(new LayoutActionLoadError(err));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
