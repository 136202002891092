<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-purchase"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    (click)="onCancelPo()"
    *ngIf="
      purchaseOrder &&
      [purchaseOrderStatusEnum.AWAITING_GR].includes(purchaseOrder.status) &&
      [
        purchaseRequestTypeEnum.WAREHOUSE,
        purchaseRequestTypeEnum.HOT_PRICE,
        purchaseRequestTypeEnum.SUPPLIER
      ].includes(purchaseOrder?.type) &&
      permissionsUtil.determinePoPermission(
        purchaseOrder.shipToType,
        purchaseOrder.shipToCode,
        purchaseOrder.productType,
        [permissionAction.MANAGE]
      )
    "
  >
    <em class="icon-forbidden"></em>
    Cancel PO
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    (click)="onClosePo()"
    *ngIf="
      purchaseOrder &&
      [purchaseOrderStatusEnum.AWAITING_GR, purchaseOrderStatusEnum.GR_COMPLETED].includes(purchaseOrder?.status) &&
      permissionsUtil.determinePoPermission(
        purchaseOrder.shipToType,
        purchaseOrder.shipToCode,
        purchaseOrder.productType,
        [permissionAction.MANAGE]
      ) &&
      ![shipToType.STORE].includes(purchaseOrder.shipToType)
    "
  >
    <em class="icon-close-po"></em>
    Close PO
  </button>
  <button
    type="button"
    class="btn btn-header-button d-none d-md-block"
    *ngIf="
      isCanPrintPDF(purchaseOrder?.status) &&
      (permissionsUtil.determinePoPermission(
        purchaseOrder.shipToType,
        purchaseOrder.shipToCode,
        purchaseOrder.productType,
        [permissionAction.VIEW, permissionAction.MANAGE]
      ) ||
        permissionsUtil.determineGrPermission(
          purchaseOrder.shipToType,
          purchaseOrder.shipToCode,
          purchaseOrder.productType,
          [permissionAction.MANAGE]
        ))
    "
    (click)="onPrintPdf(purchaseOrder)"
  >
    <em class="icon-print"></em>
    Print
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>

  <div class="clearfix"></div>
</div>

<div id="purchase-order" class="admin-form">
  <div class="tab-menu d-flex d-inline-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: selectedTab == purchaseOrderPageTabs.PO_DETAILS }"
      (click)="onSelectTabs(purchaseOrderPageTabs.PO_DETAILS)"
    >
      PO Details
    </a>
    <!-- TODO Gr summary can active from ALL PO Status -->
    <!--    <a-->
    <!--      class="list-tab-menu px-3 px-md-5 py-2"-->
    <!--      [ngClass]="{-->
    <!--        active: selectedTab === purchaseOrderPageTabs.GR_SUMMARY,-->
    <!--        disable: [-->
    <!--          purchaseOrderStatusEnum.AWAITING_SEND_PO,-->
    <!--          purchaseOrderStatusEnum.AWAITING_SUPPLIER_RESPONSE,-->
    <!--          purchaseOrderStatusEnum.CANCELLED-->
    <!--        ].includes(purchaseOrder?.status)-->
    <!--      }"-->
    <!--      (click)="onSelectTabs(purchaseOrderPageTabs.GR_SUMMARY)"-->
    <!--    >-->
    <!--      GR Summary-->
    <!--    </a>-->
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{
        active: selectedTab === purchaseOrderPageTabs.GR_SUMMARY
      }"
      (click)="onSelectTabs(purchaseOrderPageTabs.GR_SUMMARY)"
    >
      GR Summary
    </a>
  </div>

  <div *ngIf="purchaseOrder && purchaseOrder?.status">
    <div class="info-header-container">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>PO Number:</span>
        </div>
        <div class="info-header-value">
          <span>{{ purchaseOrder?.docNo }}</span>
        </div>
      </div>
      <div *ngIf="purchaseOrder && purchaseOrder?.shipToType === shipToType.STORE" class="info-header-item">
        <div class="info-header-label">
          <span>Order No.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ purchaseOrder?.orderRefNo }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>PR Number:</span>
        </div>
        <div class="info-header-value">
          <span>{{ purchaseOrder?.prNos.join(', ') }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>PO Status:</span>
        </div>
        <div class="info-header-value">
          <span class="po-status" [ngClass]="getColorStatus(purchaseOrder?.status)">
            {{ 'PURCHASE_ORDER.PURCHASE_ORDER_STATUS.' + purchaseOrder?.status | translate }}
          </span>
        </div>
      </div>
    </div>

    <div>
      <!-- Information Details -->
      <div class="information-container">
        <div class="container-fluid px-0">
          <div class="row">
            <div class="col-lg col-md-4 flex-column">
              <div class="info-header-label">
                <span>Supplier Name</span>
              </div>
              <div class="info-header-value">
                {{ purchaseOrder?.supplierName }}
              </div>
              <span
                class="sub-header-text"
                *ngIf="
                  [purchaseRequestTypeEnum.WAREHOUSE, purchaseRequestTypeEnum.HOT_PRICE].includes(purchaseOrder?.type)
                "
              >
                {{ purchaseOrder?.vendorDcCodeName | dashDisplay }}</span
              >
            </div>
            <div class="col-lg col-md-4 flex-column">
              <div class="info-header-label">
                <span>Purchase Type</span>
              </div>
              <div class="info-header-value">
                {{ 'PURCHASE_REQUEST.PURCHASE_REQUEST_TYPE.' + purchaseOrder?.type | translate }}
                <span
                  *ngIf="[purchaseRequestTypeEnum.Z9, purchaseRequestTypeEnum.Z9_EDIT].includes(purchaseOrder.type)"
                >
                  ({{ purchaseOrder?.orderScheduleDayOfWeekDisplay }})
                </span>
              </div>
            </div>
            <div class="col-lg col-md-4 flex-column">
              <div class="info-header-label">
                <span>Location</span>
              </div>
              <div class="info-header-value">
                <span>
                  {{ purchaseOrder?.shipToCodeName | dashDisplay }}
                </span>
              </div>
            </div>
            <div class="col-lg col-md-4 flex-column">
              <div class="info-header-label">
                <span>Requester</span>
              </div>
              <div class="info-header-value">
                {{ purchaseOrder?.requester }}
              </div>
            </div>
            <div class="col-lg col-md-4 flex-column">
              <div class="info-header-label">
                <span>PO Created Date</span>
              </div>
              <div class="info-header-value">
                {{ purchaseOrder?.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
              </div>
            </div>

            <div class="col-lg-4" [ngClass]="{ 'd-none': selectedTab !== purchaseOrderPageTabs.PO_DETAILS }">
              <div class="total-amount">
                <div class="text-right">
                  <span class="info-total">
                    {{ purchaseOrder?.totalAmount | numberFormatDisplay }}
                  </span>
                  <span class="info-total-currency">
                    THB
                  </span>
                </div>
                <div
                  class="info-total-detail"
                  [ngClass]="{ 'd-none': selectedTab !== purchaseOrderPageTabs.PO_DETAILS }"
                >
                  <span>(Total Amount before VAT: {{ purchaseOrder?.subTotalAmount | numberFormatDisplay }}| </span
                  ><span (click)="showVatDetails()" class="is-link"
                    >VAT: {{ purchaseOrder?.vatAmount | numberFormatDisplay }})</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- PO Items -->
      <div class="mt-3 table-responsive" *ngIf="purchaseOrder?.items">
        <table
          *ngIf="purchaseOrder && purchaseOrder.items?.length"
          id="poItemsTable"
          class="table table-striped table-bordered table-hover border-none table-with-child"
        >
          <thead>
            <tr>
              <th class="text-center align-top">No.</th>
              <th class="text-center align-top">Product Name</th>
              <th class="text-center align-top">Article No.</th>
              <th class="text-center align-top">Barcode</th>
              <th class="text-center align-top">Order Unit</th>
              <th class="text-center align-top">Unit Price</th>
              <th class="text-center align-top max-w-150px" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                Quantity
              </th>
              <th class="text-center align-top max-w-150px" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                Order<br />Quantity
              </th>
              <th class="text-center align-top max-w-150px" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                Remaining<br />Quantity
              </th>
              <th class="text-center align-top max-w-150px" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                Receive<br />Quantity
              </th>
              <th class="text-center align-top min-w-160px" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                Amount (THB)
              </th>
              <th class="text-center align-top" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                Item Status
              </th>
              <th class="text-center align-top" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                Item<br />Status
              </th>
              <th class="text-center align-top" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">Remark</th>
              <th class="text-center align-top" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let poItem of paging.currentPageData; index as itemIndex">
              <tr
                [ngClass]="{
                  even: itemIndex % 2 === 0 && purchaseOrder.items.length === 0,
                  'even-with-child': itemIndex % 2 === 0 && purchaseOrder.items.length !== 0,
                  odd: itemIndex % 2 !== 0 && purchaseOrder.items.length === 0,
                  'odd-with-child': itemIndex % 2 !== 0 && purchaseOrder.items.length !== 0
                }"
              >
                <td class="text-center">
                  <span>{{ poItem.itemNo }}</span>
                </td>
                <td>
                  <span>{{ poItem.productName }}</span>
                </td>
                <td>
                  <span>{{ poItem.articleNo }}</span>
                </td>
                <td>
                  <span>{{ poItem.barcode }}</span>
                </td>
                <td class="text-center">
                  <span>{{ poItem.orderUnit }}</span>
                </td>
                <td class="text-right">
                  <span>{{ poItem.unitPrice | numberFormatDisplay }}</span>
                </td>
                <td class="text-center td-input-column-100" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                  <app-numeric-textbox
                    id="quantity"
                    [(ngModel)]="poItem.quantity"
                    [format]="'0,0'"
                    [decimals]="0"
                    [disabled]="true"
                    maxlength="10"
                    class="td-numeric-textbox-center"
                  >
                  </app-numeric-textbox>
                  <!-- <input class="form-control" [(ngModel)]="poItem.quantity" /> -->
                  <span
                    class="text-green"
                    *ngIf="
                      poItem.estimatedQty &&
                      ![purchaseRequestTypeEnum.WAREHOUSE, purchaseRequestTypeEnum.HOT_PRICE].includes(
                        purchaseOrder?.type
                      )
                    "
                    >{{ poItem.estimatedQty | numberFormatDisplay: 0 }}</span
                  >
                </td>
                <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                  <span>{{ poItem.quantity | numberFormatDisplay: 0 }}</span>
                </td>
                <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                  <span>
                    {{
                      [
                        purchaseOrderItemStatusEnum.CANCELLED_BY_CJ,
                        purchaseOrderItemStatusEnum.CANCELLED_BY_TD
                      ].includes(poItem.status)
                        ? '-'
                        : (poItem.remainingQuantity | numberFormatDisplay: 0)
                    }}
                  </span>
                </td>
                <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                  <span>
                    {{
                      [
                        purchaseOrderItemStatusEnum.CANCELLED_BY_CJ,
                        purchaseOrderItemStatusEnum.CANCELLED_BY_TD
                      ].includes(poItem.status)
                        ? '-'
                        : (poItem.receivedQuantity | numberFormatDisplay: 0)
                    }}
                  </span>
                </td>
                <td class="text-right" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                  <span>{{ poItem.subTotalAmount | numberFormatDisplay }}</span>
                </td>
                <td class="text-center">
                  <span>{{ 'PURCHASE_ORDER.PURCHASE_ORDER_ITEM_STATUS.' + poItem.status | translate }}</span>
                </td>
                <td *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                  <span *ngIf="poItem.vendorPoRefNo && poItem.vendorPoLineItem"
                    >{{ 'PO: ' + poItem.vendorPoRefNo + ',' }} <br />{{
                      'Item No.: ' + poItem.vendorPoLineItem
                    }}</span
                  >
                </td>
                <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                  <em
                    class="fa nicon icon-truck"
                    title="Delivery Details"
                    appTippy
                    [tippyOptions]="{ arrow: true }"
                    [ngClass]="{ success: true }"
                    (click)="showDeliveryDetail(itemIndex)"
                  ></em>
                </td>
              </tr>
              <ng-container *ngFor="let freeItem of poItem.freeItems; index as freeItemIndex">
                <tr
                  [ngClass]="{
                    'child-row-even': itemIndex % 2 === 0,
                    'child-row-odd': itemIndex % 2 !== 0
                  }"
                >
                  <td class="text-center"></td>
                  <td>
                    <span>{{ freeItem.productName }} <em class="icon-gift"></em></span>
                  </td>
                  <td>
                    <span>{{ freeItem.articleNo }}</span>
                  </td>
                  <td>
                    <span>{{ freeItem.barcode }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ freeItem.orderUnit }}</span>
                  </td>
                  <td class="text-right">
                    <span>{{ 0 | numberFormatDisplay }}</span>
                  </td>
                  <td class="text-center">
                    <span>{{ freeItem.quantity | numberFormatDisplay: 0 }}</span>
                  </td>
                  <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                    <span>
                      {{
                        [
                          purchaseOrderItemStatusEnum.CANCELLED_BY_CJ,
                          purchaseOrderItemStatusEnum.CANCELLED_BY_TD
                        ].includes(poItem.status)
                          ? '-'
                          : (freeItem.remainingQuantity | numberFormatDisplay: 0)
                      }}
                    </span>
                  </td>
                  <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                    <span>
                      {{
                        [
                          purchaseOrderItemStatusEnum.CANCELLED_BY_CJ,
                          purchaseOrderItemStatusEnum.CANCELLED_BY_TD
                        ].includes(poItem.status)
                          ? '-'
                          : (freeItem.receivedQuantity | numberFormatDisplay: 0)
                      }}
                    </span>
                  </td>
                  <td class="text-center" *ngIf="selectedTab === purchaseOrderPageTabs.GR_SUMMARY">
                    <span>{{ 'PURCHASE_ORDER.PURCHASE_ORDER_ITEM_STATUS.' + poItem.status | translate }}</span>
                  </td>
                  <td class="text-right" *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">
                    <span>{{
                      [
                        purchaseOrderItemStatusEnum.CANCELLED_BY_CJ,
                        purchaseOrderItemStatusEnum.CANCELLED_BY_TD
                      ].includes(poItem.status)
                        ? '-'
                        : 'Free'
                    }}</span>
                  </td>
                  <td *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS"></td>
                  <td *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS"></td>
                  <td *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS"></td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
          <!--          <tfoot *ngIf="selectedTab === purchaseOrderPageTabs.PO_DETAILS">-->
          <!--            <tr>-->
          <!--              <td class="text-right border-none align-middle" [colSpan]="7">-->
          <!--                <strong id="subTotalAmount" class="d-block mt-2">Subtotal</strong>-->
          <!--              </td>-->
          <!--              <td class="text-right total-amount-bg align-middle">-->
          <!--                <span>{{ purchaseOrder?.subTotalAmount | numberFormatDisplay }}</span>-->
          <!--              </td>-->
          <!--              <td class="border-none" [colSpan]="3"></td>-->
          <!--            </tr>-->
          <!--            <tr>-->
          <!--              <td [colSpan]="7" class="text-right border-none align-middle">-->
          <!--                <a class="pointer" (click)="showVatDetails()">-->
          <!--                  <strong id="vatSummary" class="d-block mt-2 blue_color">-->
          <!--                    <u>VAT</u>-->
          <!--                  </strong>-->
          <!--                </a>-->
          <!--              </td>-->
          <!--              <td class="text-right total-amount-bg align-middle">-->
          <!--                <span> {{ purchaseOrder?.vatAmount | numberFormatDisplay }} </span>-->
          <!--              </td>-->
          <!--              <td class="border-none" [colSpan]="3"></td>-->
          <!--            </tr>-->
          <!--            <tr>-->
          <!--              <td [colSpan]="7" class="text-right border-none align-middle">-->
          <!--                <strong id="totalAmount" class="d-block total-amount">Total Amount (THB)</strong>-->
          <!--              </td>-->
          <!--              <td class="text-right total-amount-bg total-amount align-middle">-->
          <!--                <strong> {{ purchaseOrder?.totalAmount | numberFormatDisplay }} </strong>-->
          <!--              </td>-->
          <!--              <td class="border-none" [colSpan]="3"></td>-->
          <!--            </tr>-->
          <!--          </tfoot>-->
        </table>
        <app-ui-pagination #paging [currentPage]="currentPage" [pageSize]="pageSize" [items]="purchaseOrder?.items">
        </app-ui-pagination>
      </div>
    </div>
  </div>

  <div
    class="fixed-row-bottom form-row"
    *ngIf="
      [purchaseOrderStatusEnum.AWAITING_GR].includes(purchaseOrder?.status) &&
      ![shipToType.STORE].includes(purchaseOrder.shipToType) &&
      permissionsUtil.determineGrPermission(
        purchaseOrder.shipToType,
        purchaseOrder.shipToCode,
        purchaseOrder.productType,
        [permissionAction.MANAGE]
      )
    "
  >
    <div class="float-right">
      <button type="button" id="btn-gr" class="btn btn-primary" appPreventDoubleClick (click)="goToGr()">
        Goods Receive
      </button>
    </div>
  </div>
</div>
