import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ParameterService } from '../../services/z8-parameter.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  Z8ParameterActionType,
  Z8ParameterListHistoryRequestAction,
  Z8ParameterListHistoryResponseAction,
  Z8ParameterListRequestAction,
  Z8ParameterListResponseAction
} from '../actions/z8-parameter.actions';

@Injectable()
export class Z8ParameterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly z8ParameterService: Z8ParameterService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchZ8Parameter$ = this.actions$.pipe(
    ofType<Z8ParameterListRequestAction>(Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST}: ` + Z8ParameterEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ParameterService.searchByCriteria(payload).pipe(
        map(response => {
          return new Z8ParameterListResponseAction(response);
        }),
        catchError(error => {
          return of(new LayoutActionLoadError(error));
        })
      );
    })
  );

  @Effect()
  z8ParameterHistory$ = this.actions$.pipe(
    ofType<Z8ParameterListHistoryRequestAction>(Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_REQUEST}: ` +
          Z8ParameterEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ParameterService.getHistoryLogs(payload).pipe(
        map(response => new Z8ParameterListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private static stringify(data: any) {
    return JSON.stringify(data);
  }
}
