import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantService } from '../../services/merchant.service';
import { StoreService } from '../../services/store.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StoreActionType, StoreByIdResponseAction, StoreListHistoryResponseAction, StoreListResponseAction } from '../actions/store.actions';
export class StoreEffects {
    constructor(actions$, storeService, merchantService, logger) {
        this.actions$ = actions$;
        this.storeService = storeService;
        this.merchantService = merchantService;
        this.logger = logger;
        this.searchStore$ = this.actions$.pipe(ofType(StoreActionType.STORE_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreActionType.STORE_LIST_REQUEST}: ` + StoreEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantService.searchStoreByCriteria(payload).pipe(map(response => {
                return new StoreListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.storeHistory$ = this.actions$.pipe(ofType(StoreActionType.STORE_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreActionType.STORE_LIST_HISTORY_REQUEST}: ` + StoreEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeService.getHistoryLogs(payload).pipe(map(response => new StoreListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreById$ = this.actions$.pipe(ofType(StoreActionType.STORE_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreActionType.STORE_GET_BY_ID_REQUEST}: ` + StoreEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return combineLatest([
                this.merchantService.getMerchantById(payload.merchant),
                this.storeService.getStoreById(payload.storeNo)
            ]).pipe(map(([merchant, store]) => {
                const storeView = Object.assign({ id: store.id, no: store.no, version: store.version, status: store.status, merchantId: merchant.no, taxId: merchant.merchantInfo.merchantProfile.taxId, merchantName: merchant.merchantInfo.merchantProfile.merchantName, merchantType: merchant.merchantInfo.merchantProfile.merchantType }, (merchant.merchantInfo.ownerProfile && {
                    contactName: `${merchant.merchantInfo.ownerProfile.contactFirstName} ${merchant.merchantInfo.ownerProfile.contactLastName}`,
                    mobilePhone: `${merchant.merchantInfo.ownerProfile.countryCode} ${merchant.merchantInfo.ownerProfile.mobilePhone}`
                }), { merchantInfo: {
                        storeProfile: [Object.assign({}, store.storeProfile)]
                    } }, (store.orderSchedule && {
                    orderSchedule: {
                        orderScheduleList: [Object.assign({}, store.orderSchedule)]
                    }
                }), { devices: store.devices });
                return new StoreByIdResponseAction({ storeView });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    static stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreEffects.prototype, "searchStore$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreEffects.prototype, "storeHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreEffects.prototype, "getStoreById$", void 0);
