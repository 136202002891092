import { Action } from '@ngrx/store';
import {
  ErrorResponse,
  VoucherRequest,
  VoucherRequestListResponse,
  VoucherRequestResponse,
  VoucherRequestSearchCriteria,
  VoucherRequestViewResponse,
  VoucherRequestWithComment
} from '../../models';

export enum VoucherRequestActionTypes {
  VOUCHER_REQUEST_LIST_REQUEST = '[Voucher Request] List Request',
  VOUCHER_REQUEST_LIST_RESPONSE = '[Voucher Request] List Response',
  VOUCHER_REQUEST_LIST_ERROR = '[Voucher Request] List Error',
  VOUCHER_REQUEST_SUBMIT_REQUEST = '[Voucher Request] Voucher Submit Request',
  VOUCHER_REQUEST_SUBMIT_RESPONSE = '[Voucher Request] Voucher Submit Response',
  VOUCHER_REQUEST_SUBMIT_RESET = '[Voucher Request] Voucher Submit Reset',
  VOUCHER_REQUEST_GET_BY_ID_REQUEST = '[Voucher Request] Get Voucher Request by Id Request',
  VOUCHER_REQUEST_GET_BY_ID_RESPONSE = '[Voucher Request] Get Voucher Request by Id Response',
  VOUCHER_REQUEST_GET_BY_ID_ERROR = '[Voucher Request] Get Voucher Request by Id Error',
  VOUCHER_REQUEST_GET_BY_ID_RESET = '[Voucher Request] Get Voucher Request by Id Reset',
  VOUCHER_REQUEST_APPROVE_REQUESTED = '[Voucher Request] Voucher Request Approve Requested',
  VOUCHER_REQUEST_REJECT_REQUESTED = '[Voucher Request] Voucher Request Reject Requested',
  VOUCHER_REQUEST_CANCEL_REQUESTED = '[Voucher Request] Voucher Request Cancel Requested'
}

export class VoucherRequestListRequestAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST;

  constructor(public payload: VoucherRequestSearchCriteria) {}
}

export class VoucherRequestListResponseAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_RESPONSE;

  constructor(public payload: VoucherRequestListResponse) {}
}

export class VoucherRequestListErrorAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class VoucherRequestSubmitRequestAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_REQUEST;

  constructor(public payload: VoucherRequest) {}
}

export class VoucherRequestSubmitResponseAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_RESPONSE;

  constructor(public payload: VoucherRequestResponse) {}
}

export class VoucherRequestSubmitResetAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_RESET;
}

export class VoucherRequestByIdRequestAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: { requestId: string }) {}
}

export class VoucherRequestByIdResponseAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: { VoucherRequestView: VoucherRequestViewResponse }) {}
}

export class VoucherRequestByIdErrorAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ResetVoucherByIdRequestSelected implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_RESET;
}

export class VoucherApproveRequested implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_APPROVE_REQUESTED;

  constructor(public payload: VoucherRequestWithComment) {}
}

export class VoucherRejectRequested implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_REJECT_REQUESTED;

  constructor(public payload: VoucherRequestWithComment) {}
}

export class VoucherCancelRequestedAction implements Action {
  readonly type = VoucherRequestActionTypes.VOUCHER_REQUEST_CANCEL_REQUESTED;

  constructor(public payload: VoucherRequestWithComment) {}
}

export type VoucherRequestAction =
  | VoucherRequestListRequestAction
  | VoucherRequestListResponseAction
  | VoucherRequestListErrorAction
  | VoucherRequestSubmitRequestAction
  | VoucherRequestSubmitResponseAction
  | VoucherRequestSubmitResetAction
  | VoucherRequestByIdRequestAction
  | VoucherRequestByIdResponseAction
  | VoucherRequestByIdErrorAction
  | ResetVoucherByIdRequestSelected
  | VoucherApproveRequested
  | VoucherRejectRequested
  | VoucherCancelRequestedAction;
