<div class="search-result">
  <ng-container *ngIf="formControls.length === 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="No item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="formControls.length > 0">
    <div class="mt-2 table-responsive table-scroll max-height-table">
      <table
        id="selectedTable"
        class="table table-striped table-bordered table-hover w-100"
        [formGroup]="storeSelected"
      >
        <thead>
          <tr>
            <th class="text-center w-5">
              <input id="checkAllSelecctedId" type="checkbox" (click)="onClickCheckAllBox($event)" [checked]="true" />
            </th>
            <th class="text-center w-10">Store Code</th>
            <th class="text-center w-20">Store Name</th>
            <th class="text-center w-10">Store Type</th>
            <th class="text-center w-20">Region</th>
            <th class="text-center w-15">Province</th>
            <th class="text-center poi-col">POI Type</th>
          </tr>
        </thead>
        <tbody formArrayName="selectedItems">
          <tr
            *ngFor="let item of paging.currentPageData; index as i"
            [formGroupName]="i + (currentPage - 1) * pageSize"
          >
            <td class="text-center">
              <input
                type="checkbox"
                (change)="onClickCheckBox($event, i, item)"
                [(ngModel)]="selectedList[item.get('storeNo').value]"
                [ngModelOptions]="{ standalone: true }"
              />
            </td>
            <td class="text-center">
              {{ item.get('storeCode').value }}
            </td>
            <td>{{ item.get('storeName').value }}</td>
            <td class="text-center">{{ 'STORE_TYPE.' + item.get('storeType').value | translate }}</td>
            <td class="text-center">
              {{ item.get('region').value }}
            </td>
            <td class="text-center">{{ item.get('province').value }}</td>
            <td>
              <ng-container *ngIf="item.get('poiType').value">
                <span>{{ wordLimiter(item.get('poiType').value) }} </span>
                <span
                  popover="{{ item.get('poiType').value }}"
                  [adaptivePosition]="false"
                  [outsideClick]="true"
                  containerClass="customPopover"
                  placement="top end"
                  triggers="mouseenter:mouseleave"
                  *ngIf="
                    wordLimiter(item.get('poiType').value).length !==
                    wordSpaceCompare(item.get('poiType').value).length
                  "
                  >, ...</span
                >
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="result-pagination">
      <app-ui-pagination
        #paging
        [(currentPage)]="currentPage"
        [(pageSize)]="pageSize"
        [items]="form.controls"
        [valueChangeDetector]="form.valueChanges | async"
      >
      </app-ui-pagination>
    </div>
  </ng-container>
</div>
