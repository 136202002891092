import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, pipe } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MasterDataEnum } from '../../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { SelectedPromotionStoreEnum } from '../../../../shared/enum/promotion.enum';
import { NewMasterData } from '../../../../shared/gql/common.gql';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { PromotionRequestPageModes, PromotionRequestResponse } from '../../../../shared/models';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { MasterService } from '../../../../shared/services/master.service';
import { StoreService } from '../../../../shared/services/store.service';
import { selectRequestPromotionData } from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { SelectByStoreComponent } from '../select-by-store/select-by-store.component';

export const FORM_PARAMS = {
  promotionStores: 'promotionStores',
  selectStore: 'selectStore',
  stores: 'stores'
};

@Component({
  selector: 'app-promotion-store',
  templateUrl: './promotion-store.component.html',
  styleUrls: ['./promotion-store.component.scss']
})
export class PromotionStoreComponent implements OnInit {
  private localStore: Observable<any>;
  @Input() saveDraft: boolean;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: PromotionRequestPageModes;

  public promotionRequestView$: Observable<PromotionRequestResponse>;
  public headerRow: string[];
  public storeList: any[];

  public isSelectedByStores: boolean;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;

  public masterDataEnum = MasterDataEnum;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected storeService: StoreService,
    private readonly masterService: MasterService
  ) {}

  ngOnInit() {
    this.headerRow = ['No.', 'Store Code', 'Store Name', 'Store Type', 'Region', 'Province'];

    this.storeList = filterDropdown.selectedPromotionStore;
    this.createForm();
    this.initialData();
    this.initState();
  }

  initialData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });
  }

  initState() {
    if (this.canEditView) {
      this.setPromotionStoreValue();
    }
  }

  setPromotionStoreValue() {
    this.promotionRequestView$ = this.store.pipe(select(selectRequestPromotionData));
    this.promotionRequestView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      if (data.stores && data.stores.length) {
        data.stores.forEach(store => {
          const itemPatched = { ...store };
          this.formStores.push(this.createFormArray());
          const controlItem = this.formControlStore[this.formControlStore.length - 1];
          controlItem.patchValue(itemPatched);
        });
        this.formStores.controls.sort(this.sortList);
      }

      const casePageView =
        !Boolean(data.selectStore) && !Boolean(data.stores) && this.mode === PromotionRequestPageModes.VIEW;
      const casePageRqView =
        !Boolean(data.selectStore) && !Boolean(data.stores) && this.mode === PromotionRequestPageModes.REQUEST_VIEW;
      const isStatus = ['W', 'A', 'R', 'C'].includes(data.status);

      if (casePageView || (casePageRqView && isStatus)) {
        this.form.get(FORM_PARAMS.selectStore).setValue(SelectedPromotionStoreEnum.ALL_STORE);
      } else {
        this.form.get(FORM_PARAMS.selectStore).setValue(data.selectStore);
      }
    });

    if (this.mode === PromotionRequestPageModes.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.toggleEditPromotionStore();
    }
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    const aStoreCode = a.value.code.toLowerCase();
    const bStoreCode = b.value.code.toLowerCase();
    if (aStoreCode < bStoreCode) {
      return -1;
    }
    if (aStoreCode > bStoreCode) {
      return 1;
    }
    return 0;
  }

  toggleEditPromotionStore() {
    this.form.enable();
    if (this.form.controls[FORM_PARAMS.selectStore].value === SelectedPromotionStoreEnum.SELECT_BY_STORE) {
      this.isSelectedByStores = true;
    }
  }

  createForm() {
    this.form.addControl(
      FORM_PARAMS.selectStore,
      new FormControl(
        {
          value: null,
          disabled: false
        },
        { validators: [Validators.required] }
      )
    );
    this.form.addControl(FORM_PARAMS.stores, this.fb.array([]));
  }

  get form(): FormGroup {
    return this.parentForm.get(FORM_PARAMS.promotionStores) as FormGroup;
  }

  get formStores(): FormArray {
    return this.form.get(FORM_PARAMS.stores) as FormArray;
  }

  get formControlStore(): AbstractControl[] {
    return (this.form.get(FORM_PARAMS.stores) as FormArray).controls;
  }

  createFormArray() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    return this.fb.group({
      code: initialNull,
      no: initialNull,
      name: initialNull,
      merchant: initialNull,
      warehouse: initialNull,
      merchantType: initialNull,
      region: initialNull,
      state: initialNull
    });
  }

  onChangeStoreList($event) {
    this.isSelectedByStores = $event.value === SelectedPromotionStoreEnum.SELECT_BY_STORE;

    if ($event.value !== SelectedPromotionStoreEnum.SELECT_BY_STORE && this.formStores.length > 0) {
      this.form.get(FORM_PARAMS.selectStore).setValue($event.value);

      const initialState = {
        title: 'Confirm',
        message: `Are you sure you want to change to select all stores? <br/>Your selected store will be deleted.`
      };
      const alertModal = this.modalService.show(ConfirmModalComponent, { initialState, backdrop: 'static' });

      alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        switch (result) {
          case ModalButtonResponseEnum.OK:
            this.formStores.clear();
            break;
          case ModalButtonResponseEnum.CANCEL:
            this.isSelectedByStores = true;
            this.form.get(FORM_PARAMS.selectStore).setValue(SelectedPromotionStoreEnum.SELECT_BY_STORE);
            break;
          default:
            break;
        }
        alertModal.hide();
      });
    }
  }

  openSelectByStore() {
    const initialState = {
      parentForm: this.form,
      controlName: FORM_PARAMS.stores
    };
    this.modalService.show(SelectByStoreComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode);
  }

  get canEditView(): boolean {
    return [
      PromotionRequestPageModes.REQUEST_VIEW,
      PromotionRequestPageModes.REQUEST_EDIT,
      PromotionRequestPageModes.VIEW
    ].includes(this.mode);
  }

  get getFormParams() {
    return FORM_PARAMS;
  }

  ngOnDestroy(): void {}
}
