<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-purchase"></em>
    {{ data.title }}
  </div>

  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      [purchaseRequestStatusEnum.DRAFT].includes(purchaseRequest?.status) &&
      [purchaseRequestModeEnum.VIEW].includes(data.mode) &&
      permissionsUtil.determinePrPermission(
        purchaseRequest?.shipToType,
        purchaseRequest?.shipToCode,
        purchaseRequest?.productType,
        [permissionAction.MANAGE]
      )
    "
    (click)="onDeletePr()"
  >
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      [purchaseRequestStatusEnum.AWAITING_APPROVAL].includes(purchaseRequest?.status) &&
      [purchaseRequestModeEnum.VIEW].includes(data.mode) &&
      permissionsUtil.determinePrPermission(
        purchaseRequest?.shipToType,
        purchaseRequest?.shipToCode,
        purchaseRequest?.productType,
        [permissionAction.MANAGE]
      )
    "
    (click)="onCancelPr()"
  >
    <em class="icon-forbidden"></em>
    Cancel PR
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="purchase-request" class="admin-form" *ngIf="purchaseRequest">
  <div class="info-header-container">
    <div class="info-header-item" *ngIf="purchaseRequest?.docNo">
      <div class="info-header-label">
        <span>PR Number:</span>
      </div>
      <div class="info-header-value">
        <span>{{ purchaseRequest?.docNo }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="purchaseRequest?.orderRefNo">
      <div class="info-header-label">
        <span>Order No:</span>
      </div>
      <div class="info-header-value">
        <span>{{ purchaseRequest?.orderRefNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>PR Status:</span>
      </div>
      <div class="info-header-value">
        <span class="pr-status" [ngClass]="getColorStatus(purchaseRequest?.status)">
          {{ 'PURCHASE_REQUEST.PURCHASE_REQUEST_STATUS.' + purchaseRequest?.status | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Purchase Type</span>
          </div>
          <div class="info-header-value" [ngStyle]="{ color: purchaseRequest?.advance ? '#dc3545' : 'inherit' }">
            {{ 'PURCHASE_REQUEST.PURCHASE_REQUEST_TYPE.' + purchaseRequest?.type | translate }}
            <span *ngIf="[purchaseRequestTypeEnum.Z9, purchaseRequestTypeEnum.Z9_EDIT].includes(data.type)"
              >({{ purchaseRequest?.orderScheduleDayOfWeekDisplay }})</span
            >
            <span *ngIf="purchaseRequest?.advance">- Advance</span>
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Location</span>
          </div>
          <div class="info-header-value">
            {{
              purchaseRequest.shipToCodeName
                ? purchaseRequest.shipToCodeName
                : purchaseRequest?.wmsCode + '-' + purchaseRequest?.shipToName
            }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Total Buy Items</span>
          </div>
          <div class="info-header-value">
            {{ purchaseRequest?.requestBuyItems }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Total Free Items</span>
          </div>
          <div class="info-header-value">
            {{ purchaseRequest?.requestFreeItems }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Requester</span>
          </div>
          <div class="info-header-value">
            {{ purchaseRequest?.requesterName }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created Date</span>
          </div>
          <div class="info-header-value">
            {{ purchaseRequest?.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
        </div>
        <div class="col-lg-4">
          <div class="total-amount">
            <div class="text-right">
              <span class="info-total">
                {{ purchaseRequest?.totalAmount | numberFormatDisplay }}
              </span>
              <span class="info-total-currency">
                THB
              </span>
            </div>
            <div class="info-total-detail">
              <span>(Total Amount before VAT: {{ purchaseRequest?.subTotalAmount | numberFormatDisplay }}| </span
              ><span (click)="showVatDetails()" class="is-link"
                >VAT: {{ purchaseRequest?.vatAmount | numberFormatDisplay }})</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-md-flex flex-row">
    <button
      type="button"
      class="btn btn-secondary mr-2 my-3"
      *ngIf="
        data.mode !== purchaseRequestModeEnum.VIEW &&
        purchaseRequest?.status !== purchaseRequestStatusEnum.AWAITING_APPROVAL &&
        purchaseRequestForm
      "
      (click)="checkPermissionForSelectTdAssortmentList()"
    >
      <em class="icon-plus"></em>
      Select item
    </button>

    <div
      class="d-md-flex flex-fill justify-content-end"
      *ngIf="[purchaseRequestModeEnum.VIEW, purchaseRequestModeEnum.PARTIAL_APPROVE].includes(data.mode)"
    >
      <div class="filter-search-box flex-item form-group">
        <div class="search-box position-relative">
          <input
            type="text"
            class="form-control searchCriteria"
            [(ngModel)]="searchTerm"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
            (keyup.enter)="filterFormItem()"
          />
          <em class="icon-close-mini" [ngClass]="{ 'd-none': !searchTerm }" (click)="clearSearchText()"></em>
          <button class="btn-search" type="submit">
            <em class="icon-search" (click)="filterFormItem()"></em>
          </button>
        </div>
      </div>
    </div>

    <app-import-export-button
      class="my-3"
      [buttons]="buttons"
      (import)="checkExistingItem()"
    ></app-import-export-button>

    <div class="ml-auto">
      <button
        type="button"
        class="btn btn-standard ml-2 my-3"
        *ngIf="
          [purchaseRequestTypeEnum.Z9].includes(data.type) &&
          ![purchaseRequestModeEnum.VIEW].includes(data.mode) &&
          ![purchaseRequestStatusEnum.AWAITING_APPROVAL].includes(purchaseRequest?.status)
        "
        (click)="showModalConfirmAdvanceSchedule()"
      >
        <em class="icon-calendar"></em>
        <span class="ml-1">Advance Order Schedule</span>
      </button>
    </div>
  </div>

  <ng-container *ngIf="form?.get('items').controls.length === 0 && !purchaseRequest?.id">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center no-data">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="form?.get('items').controls.length === 0 && purchaseRequest?.id">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <div class="purchase-wrapper">
    <div class="row">
      <div class="col-md">
        <div class="table-responsive" *ngIf="purchaseRequestForm" [formGroup]="purchaseRequestForm">
          <table
            *ngIf="form && getItemForm && getItemForm.controls?.length"
            id="prItemsTable"
            class="table table-striped table-bordered table-hover border-none table-with-child"
          >
            <thead>
              <tr>
                <th class="text-center align-top padding-x-0" *ngIf="data.mode !== purchaseRequestModeEnum.VIEW"></th>
                <th class="text-center align-top">No.</th>
                <th class="text-center align-top">Product Name</th>
                <th class="text-center align-top">Article No.</th>
                <th class="text-center align-top">Barcode</th>
                <th class="text-center align-top">
                  {{
                    [
                      purchaseRequestTypeEnum.SUPPLIER,
                      purchaseRequestTypeEnum.HOT_PRICE,
                      purchaseRequestTypeEnum.WAREHOUSE
                    ].includes(data.type)
                      ? 'Supplier'
                      : 'Source Supplier'
                  }}
                </th>
                <th class="text-center align-top">Order Unit</th>
                <th class="text-center align-top min-w-160px">Unit Price</th>
                <th class="text-center align-top max-w-150px">Quantity</th>
                <th class="text-center align-top min-w-160px">Amount (THB)</th>
                <th class="text-center align-top min-w-70px">Action</th>
              </tr>
            </thead>

            <tbody formArrayName="items">
              <ng-container *ngFor="let prItem of paging.currentPageData; index as itemIndex">
                <ng-container [formGroupName]="itemIndex + (currentPage - 1) * pageSize">
                  <tr
                    [ngClass]="{
                      even: itemIndex % 2 === 0 && getFreeItem(prItem).length === 0,
                      'even-with-child': itemIndex % 2 === 0 && getFreeItem(prItem).length !== 0,
                      odd: itemIndex % 2 !== 0 && getFreeItem(prItem).length === 0,
                      'odd-with-child': itemIndex % 2 !== 0 && getFreeItem(prItem).length !== 0,
                      'line-through': prItem?.get('isReject').value,
                      'error-background': prItem?.controls.submitError.value
                    }"
                  >
                    <td class="text-center padding-x-0 " *ngIf="data.mode !== purchaseRequestModeEnum.VIEW">
                      <a
                        *ngIf="
                          data.mode !== purchaseRequestModeEnum.PARTIAL_APPROVE || !prItem?.get('isReject').value
                        "
                        (click)="
                          deleteItem(
                            prItem?.get('supplierCodeAndBarcode').value,
                            itemIndex + (currentPage - 1) * pageSize
                          )
                        "
                      >
                        <em class="icon-delete" title="Delete" appTippy [tippyOptions]="{ arrow: true }"></em>
                      </a>
                    </td>
                    <td class="text-center">
                      {{ prItem?.get('itemNo').value }}
                    </td>
                    <td>
                      <div>{{ prItem?.get('productName').value }}</div>
                      <div
                        class="minimum"
                        *ngIf="
                          prItem?.get('productChanges').value && (prItem?.get('productChanges').value)['productName']
                        "
                      >
                        Product Name has been updated.
                      </div>
                      <div *ngIf="prItem?.get('productName').errors" class="invalid-display text-left">
                        <div *ngIf="isSubmit && prItem?.get('productName').errors.productName">
                          {{ prItem?.get('productName').errors.productName.errorMessage }}
                        </div>
                        <div *ngIf="isSubmit && prItem?.get('productName').errors.productName">
                          {{ prItem?.get('productName').errors.productName }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ prItem?.get('articleNo').value }}</div>
                      <div *ngIf="prItem?.get('articleNo').errors" class="invalid-display text-left">
                        <div *ngIf="isSubmit && prItem?.get('articleNo').errors.articleNo">
                          {{ prItem?.get('articleNo').errors.articleNo }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ prItem?.get('barcode').value }}</div>
                      <div
                        class="minimum"
                        *ngIf="
                          prItem?.get('productChanges').value && (prItem?.get('productChanges').value)['barcode']
                        "
                      >
                        Barcode has been updated.
                      </div>
                      <div *ngIf="prItem?.get('barcode').errors" class="invalid-display text-left">
                        <div *ngIf="isSubmit && prItem?.get('barcode').errors.barcode">
                          {{ prItem?.get('barcode').errors.barcode }}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>{{ prItem?.get('supplierName').value }}</div>
                      <span
                        class="sub-header-text"
                        *ngIf="
                          [purchaseRequestTypeEnum.HOT_PRICE, purchaseRequestTypeEnum.WAREHOUSE].includes(
                            purchaseRequest?.type
                          )
                        "
                      >
                        {{ prItem?.controls.vendorDcCodeName.value }}
                      </span>
                      <div *ngIf="prItem?.get('supplierName').errors" class="invalid-display text-left">
                        <div *ngIf="isSubmit && prItem?.get('supplierName').errors.supplierName">
                          {{ prItem?.get('supplierName').errors.supplierName }}
                        </div>
                        <div *ngIf="isSubmit && prItem?.get('supplierName').errors.supplier">
                          {{ prItem?.get('supplierName').errors.supplier }}
                        </div>
                      </div>
                      <div
                        class="minimum"
                        *ngIf="
                          prItem?.get('productChanges').value && (prItem?.get('productChanges').value)['supplierName']
                        "
                      >
                        Supplier has been updated.
                      </div>
                    </td>
                    <td class="text-center">
                      <div>{{ prItem?.get('orderUnit').value }}</div>
                      <div class="minimum">
                        <span *ngIf="prItem?.get('minimumOrder').value"
                          >Min = {{ prItem?.get('minimumOrder').value }}</span
                        ><span *ngIf="prItem?.get('lotSize').value"> | Lot = {{ prItem?.get('lotSize').value }}</span>
                      </div>
                      <div
                        class="minimum"
                        *ngIf="
                          prItem?.get('productChanges').value && (prItem?.get('productChanges').value)['orderUnit']
                        "
                      >
                        Order Unit has been updated.
                      </div>
                    </td>
                    <td
                      class="text-right td-input-column-150"
                      *ngIf="
                        [purchaseRequestTypeEnum.HOT_PRICE, purchaseRequestTypeEnum.Z9_EDIT].includes(data.type);
                        else displayPrice
                      "
                    >
                      <app-numeric-textbox
                        formControlName="unitPrice"
                        [format]="'0,0.00'"
                        [negativeSign]="false"
                        [maxValue]="9999999999.99"
                        maxlength="15"
                        placeholder="Unit Price"
                        class="td-numeric-textbox"
                        [ngClass]="{
                          'is-invalid': isSubmit && prItem?.get('unitPrice').errors
                        }"
                      >
                      </app-numeric-textbox>
                      <div *ngIf="prItem?.get('unitPrice').errors !== null" class="invalid-display text-left">
                        <div *ngIf="prItem?.get('unitPrice').errors?.isZero">
                          {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                        </div>
                        <div *ngIf="isSubmit && prItem?.get('unitPrice').errors?.required">
                          {{ 'ERRORS.REQUIRED' | translate }}
                        </div>
                        <div *ngIf="isSubmit && prItem?.get('unitPrice').errors?.unitPrice">
                          {{ prItem?.get('unitPrice').errors.unitPrice }}
                        </div>
                      </div>
                    </td>
                    <ng-template #displayPrice>
                      <td class="text-right">
                        <div>{{ prItem?.get('unitPrice').value | numberFormatDisplay }}</div>
                        <div
                          class="minimum"
                          *ngIf="
                            prItem?.get('productChanges').value && (prItem?.get('productChanges').value)['unitPrice']
                          "
                        >
                          Unit Price has been updated.
                        </div>
                        <div *ngIf="prItem?.get('unitPrice').errors" class="invalid-display">
                          <div *ngIf="isSubmit && prItem?.get('unitPrice').errors.unitPrice">
                            {{ prItem?.get('unitPrice').errors.unitPrice }}
                          </div>
                          <div *ngIf="isSubmit && prItem?.get('unitPrice').errors?.isZero">
                            {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                          </div>
                        </div>
                      </td>
                    </ng-template>
                    <td class="text-center td-input-column-150">
                      <app-numeric-textbox
                        id="itemQuantity"
                        formControlName="quantity"
                        [negativeSign]="false"
                        placeholder="Quantity"
                        [format]="'0,0'"
                        [decimals]="0"
                        maxlength="10"
                        ngClass="numeric-format quantity"
                        class="td-numeric-textbox"
                        [ngClass]="{
                          'is-invalid': isSubmit && prItem?.get('quantity').errors
                        }"
                      >
                      </app-numeric-textbox>
                      <div *ngIf="prItem?.get('quantity').errors" class="invalid-display text-left">
                        <div *ngIf="prItem?.get('quantity').errors?.isZero">
                          {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                        </div>
                        <div *ngIf="isSubmit && prItem?.get('quantity').errors.required">
                          {{ 'ERRORS.REQUIRED' | translate }}
                        </div>
                      </div>
                    </td>
                    <td class="text-right">
                      {{ prItem?.get('subTotalAmount').value | numberFormatDisplay }}
                    </td>
                    <td class="text-center">
                      <em
                        class="fa nicon icon-truck"
                        title="Delivery Details"
                        appTippy
                        [tippyOptions]="{ arrow: true }"
                        [ngClass]="{
                          success: !prItem.get('deliveryDetail').invalid,
                          error: prItem.get('deliveryDetail').invalid
                        }"
                        (click)="showDeliveryDetail(itemIndex + (currentPage - 1) * pageSize)"
                      ></em>

                      <a
                        *ngIf="
                          [
                            purchaseRequestTypeEnum.SUPPLIER,
                            purchaseRequestTypeEnum.Z9,
                            purchaseRequestTypeEnum.Z9_EDIT
                          ].includes(data.type) &&
                          data.mode !== purchaseRequestModeEnum.VIEW &&
                          purchaseRequest?.status !== purchaseRequestStatusEnum.AWAITING_APPROVAL
                        "
                        title="Add Free Item"
                        appTippy
                        [tippyOptions]="{ arrow: true }"
                        (click)="expandAddFreeItem(prItem)"
                      >
                        <em class="icon-gift ml-2"></em>
                      </a>
                    </td>
                  </tr>
                  <ng-container *ngIf="getFreeItem(prItem)">
                    <ng-container
                      formArrayName="freeItems"
                      *ngFor="
                        let freeItem of getFreeItem(prItem);
                        index as freeItemIndex;
                        trackBy: trackFreeItemIndex
                      "
                    >
                      <ng-container [formGroupName]="freeItemIndex">
                        <tr
                          [ngClass]="{
                            'child-row-even': itemIndex % 2 === 0,
                            'child-row-odd': itemIndex % 2 !== 0,
                            'line-through': prItem?.get('isReject').value
                          }"
                        >
                          <!--                    {{freeItem?.controls.articlceNo | json}}-->
                          <td class="text-center"></td>
                          <td *ngIf="data.mode === purchaseRequestModeEnum.PARTIAL_APPROVE" class="text-center"></td>
                          <td
                            class="text-center"
                            *ngIf="
                              data.mode !== purchaseRequestModeEnum.VIEW &&
                              purchaseRequest?.status !== purchaseRequestStatusEnum.AWAITING_APPROVAL
                            "
                          >
                            <a (click)="deleteFreeItem(prItem, freeItemIndex)">
                              <div>
                                <em
                                  class="icon-close-mini"
                                  title="Delete"
                                  appTippy
                                  [tippyOptions]="{ arrow: true }"
                                ></em>
                              </div>
                            </a>
                          </td>

                          <td>
                            <div>{{ freeItem.value.productName }} <em class="icon-gift"></em></div>
                          </td>
                          <td>
                            <div>{{ freeItem.value.articleNo }}</div>
                          </td>
                          <td>
                            <div>{{ freeItem.value.barcode }}</div>
                          </td>
                          <td>
                            <div>{{ freeItem.value.supplierName }}</div>
                          </td>
                          <td class="text-center">
                            <div>{{ freeItem.value.orderUnit }}</div>
                          </td>
                          <td class="text-right">
                            <div>{{ '0.00' }}</div>
                          </td>
                          <td class="text-center td-input-column-150">
                            <app-numeric-textbox
                              id="_freeItemQuantity"
                              formControlName="quantity"
                              [negativeSign]="false"
                              [format]="'0,0'"
                              [decimals]="0"
                              [minValue]="1"
                              maxlength="10"
                              ngClass="numeric-format quantity"
                              class="td-numeric-textbox"
                              [ngClass]="{
                                'is-invalid': isSubmit && freeItem?.get('quantity').errors
                              }"
                              value="{{ freeItem.value.quantity }}"
                            >
                            </app-numeric-textbox>
                            <div
                              *ngIf="isSubmit && freeItem?.get('quantity').errors"
                              class="invalid-display text-left"
                            >
                              <div *ngIf="freeItem?.get('quantity').errors?.isZero">
                                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                              </div>
                              <div *ngIf="isSubmit && freeItem?.get('quantity').errors.required">
                                {{ 'ERRORS.REQUIRED' | translate }}
                              </div>
                            </div>
                          </td>
                          <td class="text-right">
                            {{ 'Free' }}
                          </td>
                          <td></td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <tr
                    *ngIf="expandFreeItemArticleNo === prItem?.get('supplierCodeAndBarcode').value"
                    [formGroup]="addFreeItemForm"
                  >
                    <td colspan="11" class="bg-9bbad68f">
                      <div class="row">
                        <div class="col-md-1">
                          <em id="deleteAddFreeItem" class="icon-close-mini" (click)="deleteAddFreeItemForm()"></em>
                        </div>
                        <div class="col-md-5">
                          <ng-select
                            [items]="freeItemProductList$ | async"
                            bindLabel="productName"
                            dropdownPosition="auto"
                            labelForId="freeItem"
                            placeholder="Please select..."
                            notFoundText="No Data"
                            [loading]="productSearchLoading"
                            [typeahead]="productSearchInput$"
                            [virtualScroll]="true"
                            (change)="
                              onChangeProductName(
                                $event,
                                prItem?.get('articleNo').value,
                                prItem?.get('barcode').value
                              )
                            "
                            (blur)="onBlurProductName()"
                            formControlName="productName"
                            appendTo=".purchase-wrapper"
                          >
                            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                              <div>
                                <strong>Product Name:</strong>
                                <span [ngOptionHighlight]="search" class="ml-1">
                                  {{ item.productName }}
                                </span>
                              </div>
                              <small class="d-flex flex-row">
                                <span class="mr-2 min-w-150px">
                                  <strong>Barcode :</strong>
                                  <span [ngOptionHighlight]="search" class="ml-1 float-right">{{
                                    item.barcode
                                  }}</span>
                                </span>
                                {{ '  |  ' }}
                                <span class="mx-2">
                                  <strong>Unit :</strong>
                                  <span [ngOptionHighlight]="search" class="ml-1">{{ item.orderUnit }}</span>
                                </span>

                                {{ '  |  ' }}
                                <span class="mx-2">
                                  <strong>Article No.:</strong>
                                  <span [ngOptionHighlight]="search" class="ml-1">{{ item.articleNo }}</span>
                                </span>
                              </small>
                            </ng-template>
                          </ng-select>
                        </div>
                        <div class="col-md-2">
                          <app-numeric-textbox
                            id="freeItemQuantity"
                            formControlName="quantity"
                            [format]="'0,0'"
                            [minValue]="1"
                            [negativeSign]="false"
                            [decimals]="0"
                            maxlength="10"
                            ngClass="numeric-format quantity"
                            class="td-numeric-textbox"
                            placeholder="Quantity"
                          >
                          </app-numeric-textbox>
                        </div>
                        <div class="col-md-2">
                          <button
                            type="button"
                            id="btn-add-free-item"
                            class="btn btn-special-approve"
                            appPreventDoubleClick
                            (click)="addFreeItem(prItem)"
                            [disabled]="
                              !addFreeItemForm.controls.quantity.value || !addFreeItemForm.controls.productName.value
                            "
                          >
                            Add Free Item
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </tbody>
          </table>

          <app-ui-pagination
            #paging
            [(currentPage)]="currentPage"
            [(pageSize)]="pageSize"
            [items]="getItemForm.controls"
            [valueChangeDetector]="getItemForm.valueChanges | async"
          >
          </app-ui-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="fixed-row-bottom form-row">
    <div
      class="float-left"
      *ngIf="
        ![purchaseRequestModeEnum.VIEW].includes(data.mode) &&
        [purchaseRequestStatusEnum.DRAFT].includes(purchaseRequest?.status) &&
        permissionsUtil.determinePrPermission(
          purchaseRequest.shipToType,
          purchaseRequest.shipToCode,
          purchaseRequest.productType,
          [permissionAction.MANAGE]
        )
      "
    >
      <button
        type="button"
        id="btn-save"
        class="btn btn-secondary"
        appPreventDoubleClick
        (click)="onSave()"
        [disabled]="form?.get('items').controls.length === 0"
      >
        Save
      </button>
    </div>

    <div
      class="float-left"
      *ngIf="
        [purchaseRequestStatusEnum.AWAITING_APPROVAL].includes(purchaseRequest?.status) &&
        permissionsUtil.determinePrPermission(
          purchaseRequest.shipToType,
          purchaseRequest.shipToCode,
          purchaseRequest.productType,
          [permissionAction.APPROVE]
        )
      "
    >
      <button
        type="button"
        id="btn-partial-approve"
        class="btn btn-secondary"
        appPreventDoubleClick
        (click)="toggleToPartialApprove()"
      >
        Partial Approve
      </button>
    </div>

    <div
      class="float-right"
      *ngIf="
        [purchaseRequestModeEnum.EDIT, purchaseRequestModeEnum.CREATE].includes(data.mode) &&
        purchaseRequest?.status === purchaseRequestStatusEnum.DRAFT &&
        permissionsUtil.determinePrPermission(
          purchaseRequest.shipToType,
          purchaseRequest.shipToCode,
          purchaseRequest.productType,
          [permissionAction.MANAGE]
        )
      "
    >
      <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCloseFullModal()">
        Cancel
      </button>
      <button
        type="button"
        id="btn-submit"
        class="btn btn-primary"
        appPreventDoubleClick
        (click)="onSubmit()"
        [disabled]="form?.get('items').controls.length === 0"
      >
        Submit
      </button>
    </div>
    <div
      class="float-right"
      *ngIf="
        [purchaseRequestStatusEnum.DRAFT].includes(purchaseRequest?.status) &&
        [purchaseRequestModeEnum.VIEW].includes(data.mode) &&
        ![shipToType.STORE].includes(purchaseRequest.shipToType) &&
        permissionsUtil.determinePrPermission(
          purchaseRequest.shipToType,
          purchaseRequest.shipToCode,
          purchaseRequest.productType,
          [permissionAction.MANAGE]
        )
      "
    >
      <button type="button" id="btn-edit" class="btn btn-primary" appPreventDoubleClick (click)="toggleToEdit()">
        Edit
      </button>
    </div>
    <div
      class="float-right"
      *ngIf="
        [purchaseRequestStatusEnum.AWAITING_APPROVAL].includes(purchaseRequest?.status) &&
        permissionsUtil.determinePrPermission(
          purchaseRequest.shipToType,
          purchaseRequest.shipToCode,
          purchaseRequest.productType,
          [permissionAction.APPROVE]
        )
      "
    >
      <button
        *ngIf="[purchaseRequestModeEnum.VIEW].includes(data.mode)"
        type="button"
        id="btn-reject"
        class="btn btn-special-reject mr-2"
        appPreventDoubleClick
        (click)="onReject()"
      >
        Reject
      </button>
      <button
        *ngIf="[purchaseRequestModeEnum.PARTIAL_APPROVE].includes(data.mode)"
        type="button"
        id="btn-partial-approve-cancel"
        class="btn btn-standard mr-2"
        appPreventDoubleClick
        (click)="onPartialApproveCancel()"
      >
        Cancel
      </button>
      <button
        [disabled]="isAllItemRejected()"
        type="button"
        id="btn-approve"
        class="btn btn-special-approve"
        appPreventDoubleClick
        (click)="onApprove()"
      >
        Approve
      </button>
    </div>
  </div>

  <div class="modal fade" bsModal #modalAdvanceSchedule="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Advance Order Schedule</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalAdvanceSchedule()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Order schedule:</div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="advanceScheduleDayList"
                placeholder="Please select..."
                [(ngModel)]="advanceScheduleDayIndex"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-standard" (click)="hideModalAdvanceSchedule()" appPreventDoubleClick>
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!advanceScheduleDayIndex"
            (click)="onApplyAdvanceSchedule()"
            appPreventDoubleClick
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" bsModal #modalProductType="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Product Type</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalProductType()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Product Type:</div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="productTypeList"
                placeholder="Please select..."
                [(ngModel)]="selectedProductType"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-standard" (click)="hideModalProductType()" appPreventDoubleClick>
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!selectedProductType"
            (click)="onApplyProductType()"
            appPreventDoubleClick
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #importResultModel="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">{{ importResult?.title }}</h6>
      </div>
      <div class="modal-body">
        <div
          *ngIf="importResult && importResult.table && importResult.table.length > 0"
          class="max-height-table table-responsive"
        >
          <table class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr class="error-background text-center">
                <th scope="col">Row</th>
                <th scope="col">Column</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of importResult.table; index as i">
                <td class="text-center">{{ item.row }}</td>
                <td>{{ item.column }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="importResult && importResult.message">
          {{ importResult.message }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importResultModel.hide()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
