import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  StoreAssortmentRequestList,
  StoreAssortmentRequestViewResponse
} from '../../models/store-assortment-request.model';
import { StoreAssortmentListSearchCriteria } from '../../models/store-assortment.model';
import {
  StoreAssortmentRequestActions,
  StoreAssortmentRequestActionType
} from '../actions/store-assortment-request.actions';
import { BaseState } from '../state/base.state';

export interface StoreAssortmentRequestState extends EntityState<StoreAssortmentRequestList>, BaseState {
  selected: StoreAssortmentRequestViewResponse;
  criteriaObject: StoreAssortmentListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StoreAssortmentRequestList> = createEntityAdapter<StoreAssortmentRequestList>();

export const initialStoreAssortmentRequestResponseState: StoreAssortmentRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20
});

export function storeAssortmentRequestReducers(
  state = initialStoreAssortmentRequestResponseState,
  action: StoreAssortmentRequestActions
): StoreAssortmentRequestState {
  switch (action.type) {
    case StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
