import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ProductActionTypes, ProductListSelectStatusSuccessAction, ProductListSuccessAction } from '../actions/product.action';
import { ProductGetLinkArticleDetailResponseAction, RequestAssortmentActionTypes } from '../actions/request-assortment.actions';
import { selectProductsCheckList } from '../selectors/product-selector';
export class ProductEffects {
    constructor(actions$, productService, logger, store$) {
        this.actions$ = actions$;
        this.productService = productService;
        this.logger = logger;
        this.store$ = store$;
        this.products$ = this.actions$.pipe(ofType(ProductActionTypes.PRODUCTS_REQUEST), tap(action => this.logger.debug('@Effect ProductListRequestAction: ' + this.stringify(action.payload))), withLatestFrom(this.store$.select(selectProductsCheckList)), switchMap(([action, selected]) => {
            return this.productService.searchByCriteria(action.payload).pipe(map(res => {
                res.content.map(item => {
                    if (selected && selected.hasOwnProperty(item.articleNo) && selected[item.articleNo]) {
                        item.checked = true;
                    }
                    return item;
                });
                return new ProductListSuccessAction(res);
            }), catchError(err => of(new LayoutActionLoadError(err))));
        }));
        this.productsSelectStatus$ = this.actions$.pipe(ofType(ProductActionTypes.PRODUCTS_SELECT_REQUEST), tap(action => this.logger.debug('@Effect Product List Change Status Request: ' + this.stringify(action.payload))), switchMap(action => {
            return this.productService.updateSelectStatus(action.payload).pipe(
            // Waiting for sync data to elastic search
            delay(800), map(response => new ProductListSelectStatusSuccessAction(response)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getArticleDetailForLink$ = this.actions$.pipe(ofType(RequestAssortmentActionTypes.LinkArticleDetailRequest), tap(action => this.logger.debug('@Effect Get CJ Article Detail Request: ' + this.stringify(action.payload))), switchMap(action => {
            return this.productService.getCJArticleData(action.payload).pipe(map(response => new ProductGetLinkArticleDetailResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ProductEffects.prototype, "products$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProductEffects.prototype, "productsSelectStatus$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProductEffects.prototype, "getArticleDetailForLink$", void 0);
