<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left" (click)="logForm()">
      <!--      {{ editingSupplierPriceIndex !== null ? 'Edit Supplier Price' : 'Add Supplier Price' }}-->
      {{
        [assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode)
          ? 'View Supplier Price'
          : editingSupplierPriceIndex !== null
          ? 'Edit Supplier Price'
          : 'Add Supplier Price'
      }}
    </h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalAddSupplierPrice()">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body" [formGroup]="addSupplierPriceForm">
    <div class="container-fluid">
      <div class="d-inline-flex mr-auto pl-0 col-md-12">
        <h6 class="pr-2">
          {{ productDetailsForm?.controls.articleNo.value }}: {{ productDetailsForm?.controls.productName.value }}
        </h6>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="locationLevelType">Location Level<span class="text-danger">*</span></label>
            <ng-select
              id="locationLevelType"
              [items]="listOfValue['locationLevel']"
              [searchable]="false"
              [clearable]="false"
              dropdownPosition="auto"
              labelForId="locationLevelType"
              placeholder="Please select..."
              formControlName="locationLevelType"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.locationLevelType.errors
              }"
            >
            </ng-select>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.locationLevelType.errors"
              class="invalid-display"
            >
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="location">Location<span class="text-danger">*</span></label>
            <ng-select
              *ngIf="
                addSupplierPriceForm.controls.locationLevelType.value &&
                  addSupplierPriceForm.controls.locationLevelType.value.value === 'BY_STORE';
                else defaultSelect
              "
              id="storeLocation"
              [items]="locationItems$ | async"
              [typeahead]="storeInput$"
              dropdownPosition="auto"
              labelForId="location"
              placeholder="Please select..."
              formControlName="location"
              (change)="clearError()"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.location.errors
              }"
            >
              <ng-template ng-option-tmp let-item="item">
                {{ item.value + '-' + item.label }}
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                {{ item.value + '-' + item.label }}
              </ng-template>
            </ng-select>
            <ng-template #defaultSelect>
              <ng-select
                id="location"
                [items]="locationItems$ | async"
                [searchable]="false"
                [clearable]="false"
                dropdownPosition="auto"
                labelForId="location"
                placeholder="Please select..."
                formControlName="location"
                (change)="clearError()"
                [ngClass]="{
                  'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.location.errors
                }"
              >
                <ng-template
                  *ngIf="
                    addSupplierPriceForm.controls.locationLevelType &&
                    addSupplierPriceForm.controls.locationLevelType.value &&
                    addSupplierPriceForm.controls.locationLevelType.value.value === 'BY_DC'
                  "
                  ng-option-tmp
                  let-item="item"
                >
                  {{ item.value + '-' + item.label }}
                </ng-template>
                <ng-template
                  *ngIf="
                    addSupplierPriceForm.controls.locationLevelType &&
                    addSupplierPriceForm.controls.locationLevelType.value &&
                    addSupplierPriceForm.controls.locationLevelType.value.value === 'BY_DC'
                  "
                  ng-label-tmp
                  let-item="item"
                >
                  {{ item.value + '-' + item.label }}
                </ng-template>
              </ng-select>
            </ng-template>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.location.errors"
              class="invalid-display"
            >
              <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.location.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div
                *ngIf="
                  isSubmitAddSupplierPrice &&
                  addSupplierPriceForm.controls.location.errors?.duplicateLocationSamePeriod
                "
              >
                Duplicate location within same period.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="supplier">Supplier Name<span class="text-danger">*</span></label>
            <ng-select
              id="supplier"
              [items]="supplierList | async"
              bindLabel="supplier"
              dropdownPosition="auto"
              labelForId="supplier"
              placeholder="Please select..."
              notFoundText="No Data"
              [loading]="supplierSearchLoading"
              [typeahead]="supplierSearchInput$"
              [virtualScroll]="true"
              (change)="onChangeSupplierName($event)"
              formControlName="supplier"
              (blur)="onBlurSupplierName()"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors
              }"
            >
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <strong>Supplier Name:</strong>
                  <span [ngOptionHighlight]="search" class="ml-1">
                    {{ item.supplierName }}
                  </span>
                </div>
                <small>
                  <strong>Vendor No:</strong>
                  <span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span>
                  {{ ' | ' }}
                  <strong>Branch No:</strong>
                  <span class="ml-1">{{ item.branchNo }}</span>
                </small>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                {{ item.supplierCode + ' ' + item.supplierName + ' (' + item.branchNo + ')' }}
              </ng-template>
            </ng-select>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors"
              class="invalid-feedback"
            >
              <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplier.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div
                *ngIf="
                  isSubmitAddSupplierPrice &&
                  addSupplierPriceForm.controls.supplier.errors?.duplicateSupplierSamePeriod
                "
              >
                Duplicate supplier in same location within same period.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="barcode">Order Barcode<span class="text-danger">*</span></label>
            <input
              id="barcode"
              type="text"
              class="form-control"
              placeholder="Barcode Number"
              formControlName="barcode"
              maxlength="25"
              (keyup)="clearError()"
              appDigitOnly
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.barcode.errors
              }"
            />
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.barcode.errors"
              class="invalid-display"
            >
              <div *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.barcode.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label for="orderUnit">Order Unit<span class="text-danger">*</span></label>
            <ng-select
              id="orderUnit"
              [items]="listOfValue[assortmentAllType.UNIT]"
              [searchable]="false"
              [clearable]="false"
              bindLabel="nameEn"
              bindValue="code"
              dropdownPosition="auto"
              labelForId="orderUnit"
              placeholder="Please select..."
              formControlName="orderUnit"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.orderUnit.errors
              }"
            >
            </ng-select>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.orderUnit.errors"
              class="invalid-display"
            >
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-3 pl-0">
          <div class="form-group">
            <label for="unitFactor">Unit Factor<span class="text-danger">*</span></label>
            <app-numeric-textbox
              id="unitFactor"
              formControlName="unitFactor"
              [negativeSign]="false"
              placeholder="No. of piece"
              [format]="'0,0'"
              [decimals]="0"
              maxlength="10"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.unitFactor.errors
              }"
            ></app-numeric-textbox>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.unitFactor?.errors"
              class="invalid-display"
            >
              <span *ngIf="addSupplierPriceForm.controls.unitFactor?.errors.required; else zero">
                {{ 'ERRORS.REQUIRED' | translate }}
              </span>
              <ng-template #zero>
                <span *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.unitFactor?.errors.isZero">
                  {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                </span>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="supplierPriceExVatAmount">Supplier Price (ex VAT)<span class="text-danger">*</span></label>
            <app-numeric-textbox
              id="supplierPriceExVatAmount"
              formControlName="supplierPriceExVatAmount"
              placeholder="0.00"
              [format]="'0,0.00'"
              [maxValue]="9999999999.99"
              [negativeSign]="false"
              maxlength="15"
              [ngClass]="{
                'is-invalid':
                  isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.supplierPriceExVatAmount.errors
              }"
            ></app-numeric-textbox>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm?.controls.supplierPriceExVatAmount.errors"
              class="invalid-display"
            >
              <div
                *ngIf="
                  isSubmitAddSupplierPrice && addSupplierPriceForm.controls.supplierPriceExVatAmount.errors?.required
                "
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div
                *ngIf="
                  isSubmitAddSupplierPrice &&
                  addSupplierPriceForm.controls.supplierPriceExVatAmount.errors?.differentPriceSamePeriod
                "
              >
                Difference Supplier Price is not allow within same period.
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="avgSupplierPriceExVatAmount">Avg. Supplier Price (ex VAT)/Base Unit</label>
            <app-numeric-textbox
              id="avgSupplierPriceExVatAmount"
              formControlName="avgSupplierPriceExVatAmount"
              [negativeSign]="false"
              placeholder="0.00"
              [format]="'0,0.00'"
              [maxValue]="9999999999.99"
              maxlength="10"
            ></app-numeric-textbox>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="effectiveDate">Effective Date<span class="text-danger">*</span></label>
            <input
              id="effectiveDate"
              type="text"
              placeholder="{{ dateFormat }}"
              class="form-control calendar"
              placement="bottom"
              autocomplete="off"
              bsDatepicker
              [bsConfig]="bsConfig"
              formControlName="effectiveDate"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.effectiveDate.errors
              }"
              (bsValueChange)="clearDateError($event)"
              readonly
            />
            <label for="effectiveDate" class="icon-calendar btn-calendar"></label>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.effectiveDate.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>

              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.duplicated">
                Not allow Overlapped period.
              </div>
              <div *ngIf="addSupplierPriceForm.controls.effectiveDate.errors?.invalidDate">
                Invalid Date.
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="form-group">
            <label for="expireDate">Expire Date<span class="text-danger">*</span></label>
            <input
              id="expireDate"
              type="text"
              placeholder="{{ dateFormat }}"
              class="form-control calendar"
              placement="bottom"
              bsDatepicker
              [bsConfig]="bsConfig"
              [minDate]="expireDateMinDate"
              formControlName="expireDate"
              [ngClass]="{
                'is-invalid': isSubmitAddSupplierPrice && addSupplierPriceForm.controls.expireDate.errors
              }"
              (bsValueChange)="clearDateError($event)"
              readonly
            />
            <label for="expireDate" class="icon-calendar btn-calendar"></label>
            <div
              *ngIf="isSubmitAddSupplierPrice && addSupplierPriceForm.controls.expireDate.errors"
              class="invalid-feedback"
            >
              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>

              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.duplicated">
                Not allow Overlapped period.
              </div>
              <div *ngIf="addSupplierPriceForm.controls.expireDate.errors?.invalidDate">
                Invalid Date.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode)"
      type="button"
      class="btn btn-standard mr-2"
      (click)="hideModalAddSupplierPrice()"
      id="supplierPrice-modal-cancelBtn"
    >
      Cancel
    </button>
    <button
      *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode)"
      type="submit"
      class="btn btn-primary mr-3"
      id="supplierPrice-modal-addBtn"
      (click)="saveSupplierPrice()"
      appPreventDoubleClick
    >
      {{ editingSupplierPriceIndex !== null ? 'Update' : 'Add' }}
    </button>
  </div>
</div>
