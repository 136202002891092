import { Component, HostBinding, OnDestroy, OnInit, Optional } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NGXLogger } from 'ngx-logger';
import { environment as env } from '../environments/environment';
import { isDevEnv } from './shared/utils/is-dev-util';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @HostBinding('class.tier-alpha') public isAlpha = env.tier === 'alpha';
  @HostBinding('class.tier-beta') public isBeta = env.tier === 'beta';
  @HostBinding('class.tier-staging') public isStaging = env.tier === 'staging';
  @HostBinding('class.tier-develop') public isDevelop = isDevEnv(env.tier);

  public environment: { [key: string]: any } = env;
  public lang: string;

  constructor(
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService,
    private readonly titleService: Title,
    @Optional() private updates: SwUpdate
  ) {
    if (this.updates && this.updates.isEnabled) {
      this.updates.available.subscribe(event => {
        this.logger.info('Available event', event);
        if (event.available) {
          document.location.reload();
        }
      });
      this.updates.activated.subscribe(event => {
        this.logger.info('Activated event', event);
      });
    }
  }

  ngOnInit(): void {
    const title = `TD Nest${env.tier !== 'prod' ? ' - ' + env.tier.toUpperCase() : ''}`;
    this.titleService.setTitle(title);

    this.lang = this.environment.defaultLanguage;
    moment.locale(this.lang);

    this.translate.addLangs(this.environment.supportedLanguages);
    this.translate.setDefaultLang(this.lang);
    this.translate.use(this.lang);

    this.logger.trace('Production value is ' + this.environment.production);
    this.logger.debug('Your tier is ' + this.environment.tier);
    this.logger.debug('Cookies of domain is ' + this.environment.cookies.domain);
    this.logger.info('Your appVersion is ' + this.environment.version);
  }

  ngOnDestroy(): void {}
}
