import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SelectWarehouseList, WarehouseListContent } from '../../models/warehouse.model';
import { WarehouseAction, WarehouseActionTypes } from '../actions/warehouse.actions';

export interface WarehouseState extends EntityState<WarehouseListContent> {
  isLoading: boolean;
  selectWarehouseList: SelectWarehouseList[];
}

export const adapter: EntityAdapter<WarehouseListContent> = createEntityAdapter<WarehouseListContent>();

export const initialWarehouseState: WarehouseState = adapter.getInitialState({
  isLoading: false,
  selectWarehouseList: []
});

export function warehouseReducers(state = initialWarehouseState, action: WarehouseAction): WarehouseState {
  switch (action.type) {
    case WarehouseActionTypes.WAREHOUSE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, { ...state });
    case WarehouseActionTypes.SELECT_WAREHOUSES_LIST_RESPONSE:
      return {
        ...state,
        selectWarehouseList: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
