import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  ErrorResponse,
  MerchantSearch,
  PortalModule,
  RouteLinkTab,
  StoreRequestList,
  StoreRequestListSearchCriteria,
  TaskModuleUrl
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import {
  StoreDeleteRequestAction,
  StoreDeleteResetAction,
  StoreRequestHistoryRequestAction,
  StoreRequestHistoryResponseAction,
  StoreRequestListRequestAction
} from '../../../shared/store/actions/store-request.actions';
import { StoreRequestState } from '../../../shared/store/reducers/store-request.reducers';
import {
  selectAllStoreRequestList,
  selectStoreDeleteStatus,
  selectStoreRequestHistory,
  selectStoreRequestList,
  selectStoreRequestListCriteria
} from '../../../shared/store/selectors/store-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { merchantColorStatus } from '../../../shared/utils/merchant-color-status-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';
import { StoreRequestComponent } from '../store-request/store-request.component';

@Component({
  selector: 'app-store-request-list',
  templateUrl: './store-request-list.component.html',
  styleUrls: ['./store-request-list.component.scss']
})
export class StoreRequestListComponent
  extends BaseSearchComponent<StoreRequestListSearchCriteria, StoreRequestList, StoreRequestState>
  implements OnInit {
  @ViewChild('modalCreateNewStore', { static: false }) modalCreateNewStore: ModalDirective;
  @ViewChild('selectMerchant', { static: false }) selectMerchant: NgSelectComponent;

  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public storeRequestStatusFilter = filterDropdown.storeRequestStatusFilter;
  public storeRequestTypeFilter = filterDropdown.storeRequestTypeFilter;

  public listRoute: Array<RouteLinkTab>;

  public dateFormat = environment.dateFormat;
  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public typeTag: string;
  public typeStringTag: string;

  public merchantList: Observable<MerchantSearch>;
  public merchantSearchLoading: boolean;
  public merchantSearchInput$ = new Subject<string>();
  public merchantForm: FormGroup;

  public page: TDStorePage;
  public type: RequestTypeEnum;

  public auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected storeRequestService: StoreRequestService,
    protected readonly translate: TranslateService,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {
    super(store, modalService, selectAllStoreRequestList, selectStoreRequestList);
  }

  doInit() {
    this.page = TDStorePage.STORE_REQUEST;

    this.merchantSearchLoading = false;
    this.createMerchantForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStoreRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.localStore.pipe(select(selectStoreDeleteStatus)).subscribe(isDeleteSuccess => {
      if (isDeleteSuccess) {
        this.alertSuccessModal('The request has been deleted.');
        this.store.dispatch(new StoreDeleteResetAction());
      }
    });
    this.auditLogs$ = this.localStore.pipe(select(selectStoreRequestHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.storeRequestStatusFilter[0].value],
      startRequestDate: [null],
      endRequestDate: [null],
      type: [null]
    });
  }

  createMerchantForm() {
    this.merchantForm = this.fb.group({
      merchant: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['startRequestDate'].reset();
    this.searchForm.controls['endRequestDate'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startRequestDate: null,
      endRequestDate: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterType() {
    this.setFirstPage();
    this.searchForm.controls['type'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      type: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startRequestDate'].reset();
    this.searchForm.controls['endRequestDate'].reset();
    this.searchForm.controls['type'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startRequestDate: null,
      endRequestDate: null,
      type: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.typeTag = null;
    this.typeStringTag = null;

    const startRequestDate = dateStringToTagCriteria(this.criteriaObject.startRequestDate);
    const endRequestDate = dateStringToTagCriteria(this.criteriaObject.endRequestDate);
    const requestDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: startRequestDate,
      dateTo: endRequestDate
    });

    this.dateStringTag = requestDate.dateStringTag;
    this.dateTag = requestDate.dateTag;

    if (this.criteriaObject.type && this.criteriaObject.type.length > 0) {
      this.typeStringTag = 'Request Type';

      const types = this.storeRequestTypeFilter
        .filter(data => {
          return this.criteriaObject.type.indexOf(data.value) > -1;
        })
        .map(status => status.label)
        .join(', ');

      this.typeTag = `"${types}"`;
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.startRequestDate && !formValue.endRequestDate && !formValue.type) {
      return;
    }

    let dateFrom = this.searchForm.value.startRequestDate;
    let dateTo = this.searchForm.value.endRequestDate;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      startRequestDate: dateFrom,
      endRequestDate: dateTo,
      type: formValue.type && formValue.type.length > 0 ? formValue.type.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new StoreRequestListRequestAction(criteriaObj));
  }

  goToCreate() {
    const mode = StoreRequestListComponent.pageMode.REQUEST_CREATE;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreRequestComponent,
        {
          title: StoreRequestListComponent.getStoreRequestTitle(mode),
          mode,
          storeId: null,
          merchant: this.merchantForm.controls.merchant.value
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    this.hideModalCreateNewStore();

    return;
  }

  goToView(item) {
    const mode = RequestPageModesEnum.REQUEST_VIEW;
    const initialState = ModuleUtil.InitialState(
      item,
      StoreRequestComponent,
      mode,
      StoreRequestListComponent.getStoreRequestTitle(mode),
      TaskModuleUrl.STORE_REQUEST,
      PortalModule.STORE
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  goToEdit(item) {
    const mode = RequestPageModesEnum.REQUEST_EDIT;
    const initialState = ModuleUtil.InitialState(
      item,
      StoreRequestComponent,
      mode,
      StoreRequestListComponent.getStoreRequestTitle(mode),
      TaskModuleUrl.STORE_REQUEST,
      PortalModule.STORE
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  deleteStoreRequest(id) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new StoreDeleteRequestAction({ storeId: id }));
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  cancelStoreRequest(step: RequestStepEnum, requestNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeRequestService
            .approveRequest({
              requestNo,
              status: ApproveStatusEnum.CANCELLED,
              step,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
                this.doSearch(this.criteriaObject);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.store.dispatch(new StoreRequestListRequestAction(this.criteriaObject));
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  showHistory(storeId: string, requestNo: string) {
    this.store.dispatch(new StoreRequestHistoryRequestAction({ storeId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE_REQUEST,
      auditLogs$: this.auditLogs$
    };

    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  onSelectMerchant() {
    this.loadMerchant('');
    this.modalCreateNewStore.show();
  }

  hideModalCreateNewStore() {
    this.merchantForm.controls.merchant.patchValue([]);
    this.selectMerchant.itemsList.unmarkItem();
    this.modalCreateNewStore.hide();
  }

  getRequestNo(storeRequest: StoreRequestList) {
    return !storeRequest.requestNo || storeRequest.requestStatus === RequestStatusEnum.DRAFT
      ? '(None)'
      : storeRequest.requestNo;
  }

  getColorStatus(status: string): string {
    return merchantColorStatus(status);
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission([
      'store_v',
      'store_eq',
      'merchant_edit_m',
      'merchant_order_m'
    ]);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'merchant_new_m',
      'merchant_edit_m',
      'merchant_order_m',
      'merchant_app'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Store List', url: '/merchant-store/store-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Store Request', url: '/merchant-store/store-request-list' });
    }
  }

  loadMerchant(initialTerm: string) {
    this.merchantList = concat(
      of([]),
      this.merchantSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.merchantSearchLoading = true)),
        switchMap(term =>
          this.storeRequestService.searchMerchantByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.merchantSearchLoading = false;
            })
          )
        )
      )
    );
  }

  hasEditPermission(type: RequestTypeEnum, step: RequestStepEnum, status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(type, this.page, step, status);
  }

  hasDeletePermission(type: RequestTypeEnum, step: RequestStepEnum, status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasDeletePermission(type, this.page, step, status);
  }

  hasCancelPermission(status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasCancelPermission(status);
  }

  hasViewHistoryPermission(status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasViewHistoryPermission(status);
  }

  static get pageMode() {
    return RequestPageModesEnum;
  }

  static getStoreRequestTitle(mode: RequestPageModesEnum) {
    let title: string;
    switch (mode) {
      case RequestPageModesEnum.REQUEST_CREATE:
        title = 'Create Store Request';
        break;
      case RequestPageModesEnum.REQUEST_VIEW:
        title = 'View Store Request';
        break;
      case RequestPageModesEnum.REQUEST_EDIT:
        title = 'Edit Store Request';
        break;
      default:
      // return null;
    }
    return title;
  }
}
