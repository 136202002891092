import { Action } from '@ngrx/store';
import { StoreNo } from '../../models';
import { MasterAssortmentList, MasterAssortmentListSearchCriteria } from '../../models/store-assortment.model';

export enum MasterAssortmentActionType {
  MASTER_ASSORTMENT_LIST_REQUEST = '[MasterAssortment] MasterAssortment List Request',
  MASTER_ASSORTMENT_LIST_RESPONSE = '[MasterAssortment] MasterAssortment List Response',
  MASTER_ASSORTMENT_LIST_RESET = '[MasterAssortment] MasterAssortment List Reset'
}

export class MasterAssortmentListRequestAction implements Action {
  readonly type = MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_REQUEST;

  constructor(public payload: { storeNo: StoreNo; criteria: MasterAssortmentListSearchCriteria }) {}
}

export class MasterAssortmentListResponseAction implements Action {
  readonly type = MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESPONSE;

  constructor(public payload: MasterAssortmentList) {}
}

export class ResetMasterAssortmentListAction implements Action {
  readonly type = MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESET;
}

export type MasterAssortmentActions =
  | MasterAssortmentListRequestAction
  | MasterAssortmentListResponseAction
  | ResetMasterAssortmentListAction;
