import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DropdownMasterRequest } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class MasterDataService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.masterData;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getDropdownMasterData(masterDataRequest: DropdownMasterRequest): Observable<any> {
    const params = this.getParams(masterDataRequest, true);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
