import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ShipmentStatusEnum } from '../../../shared/enum/shipment-status.enum';
import { ShipmentTypeEnum } from '../../../shared/enum/shipment-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { Shipment, ShipmentExportCriteria, ShipmentSearchCriteria } from '../../../shared/models/shipment.model';
import { AuthGuardService } from '../../../shared/services';
import { ShipmentService } from '../../../shared/services/shipment.service';
import { ShipmentListRequestAction } from '../../../shared/store/actions/shipment.actions';
import { WarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { ShipmentState } from '../../../shared/store/reducers/shipment.reducers';
import { selectShipment, selectShipmentList } from '../../../shared/store/selectors/shipment.selectors';
import { selectAllWarehouse } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  formatDate,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { ShipmentRequestComponent } from '../shipment-request/shipment-request.component';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent extends BaseSearchComponent<ShipmentSearchCriteria, Shipment, ShipmentState>
  implements OnInit, OnDestroy {
  public shipmentStatusList: Array<{ value: string; label: string }>;
  public modalShipment: BsModalRef;
  public pickMinDate: Date;
  public pickMaxDate: Date;
  public createdMinDate: Date;
  public createdMaxDate: Date;
  public createdDateTag: string;
  public createdDateStringTag: string;
  public pickupDateTag: string;
  public pickupDateStringTag: string;
  public deliveryDateTag: string;
  public deliveryDateStringTag: string;
  public warehouseTypeTag: string;
  public warehouseTypeStringTag: string;
  public shipmentStatusEnum = ShipmentStatusEnum;
  public shipmentTypeEnum = ShipmentTypeEnum;
  public showHistoryButton: boolean;
  private localStore: Observable<any>;

  public createdShipmentMaxDate: Date;
  public createdShipmentMinDate: Date;
  public createdDeliveryMaxDate: Date;
  public createdDeliveryMinDate: Date;
  public shipmentStatusExportFilter: NgOption[];
  public exportForm: FormGroup;
  public submittedExport: boolean;
  public errorExport: string;

  public hasManageDeliveryOrder: boolean;
  public hasManageLogistic: boolean;
  public hasViewLogistic: boolean;

  public warehouseList: NgOption[];
  public buttons: Array<ImportExportButton>;
  public permissionAction = PermissionAction;

  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    private readonly translate: TranslateService,
    private authGuardService: AuthGuardService,
    private readonly logger: NGXLogger,
    private shipmentService: ShipmentService,
    public permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, selectShipmentList, selectShipment);
    this.subscribeForSaveSuccess();
    this.shipmentStatusList = filterDropdown.shipmentStatusList;
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  getColorStatus(status: ShipmentStatusEnum): string {
    return status ? `${ShipmentStatusEnum[status].toLowerCase()}` : '';
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.shipmentStatusList[0].value],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      pickupDateFrom: [null],
      pickupDateTo: [null],
      createdDateFrom: [null],
      createdDateTo: [null],
      warehouseCode: [null]
    });
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.modalShipment) {
      this.modalShipment.hide();
    }
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doDestroy() {}

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createExportForm();
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new WarehouseListRequestAction('-'));

    this.createdShipmentMaxDate = new Date();
    this.createdMaxDate.setDate(this.maxDate.getDate() + 365);
    this.createdShipmentMinDate = new Date(2019, 0, 1);
    this.createdDeliveryMaxDate = new Date();
    this.createdMaxDate.setDate(this.maxDate.getDate() + 365);
    this.createdDeliveryMinDate = new Date(2019, 0, 1);

    // Clone without all and draft status
    this.shipmentStatusExportFilter = filterDropdown.shipmentStatusList.filter(status => status.value !== '');

    this.localStore.pipe(select(selectAllWarehouse('Warehouse'))).subscribe(warehouseResult => {
      this.warehouseList = warehouseResult;
    });

    // Check permission
    this.determinePermissions();
    this.generateWarehouse();
    this.getButton();
  }

  determinePermissions() {
    this.hasManageDeliveryOrder = this.authGuardService.checkPermission('^do_m_[a-zA-Z]{2}[0-9]{1,3}$', true);
    this.hasManageLogistic = this.authGuardService.checkPermission('^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$', true);
    this.hasViewLogistic = this.authGuardService.checkPermission('^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$', true);
  }

  generateWarehouse() {
    this.localStore
      .pipe(select(selectAllWarehouse('Warehouse')))
      .subscribe(
        whs =>
          (this.warehouseList = whs.filter(wh =>
            this.permissionsUtil.checkPermissionByLocation(['do_logis_m_$1', 'do_logis_v_$1', 'do_m_$1'], wh.code)
          ))
      );
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: !this.hasManageLogistic && !this.hasViewLogistic && !this.hasManageDeliveryOrder
      }
    ];
  }

  goToView(viewParams?: any) {
    const title = 'View Shipment Order';
    // TODO:: goToView;
    this.logger.debug(title + '-goToView', viewParams);
  }

  onChangePickDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.pickMinDate = new Date(value);
    } else {
      this.pickMinDate = new Date(2019, 0, 1);
    }
  }

  onChangePickDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.pickMaxDate = new Date(value);
    } else {
      this.pickMaxDate = new Date();
      this.pickMaxDate.setDate(this.pickMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.pickMaxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.deliveryDateFrom &&
      !formValue.deliveryDateTo &&
      !formValue.pickupDateFrom &&
      !formValue.pickupDateTo &&
      !formValue.createdDateFrom &&
      !formValue.createdDateTo &&
      !formValue.warehouseCode
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      deliveryDateFrom: formatDate(formValue.deliveryDateFrom, environment.dateFormat),
      deliveryDateTo: formatDate(formValue.deliveryDateTo, environment.dateFormat),
      pickupDateFrom: formatDate(formValue.pickupDateFrom, environment.dateFormat),
      pickupDateTo: formatDate(formValue.pickupDateTo, environment.dateFormat),
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      warehouseCode: formValue.warehouseCode,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCreatedDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterPickUpDate() {
    this.setFirstPage();
    this.searchForm.controls['pickupDateFrom'].reset();
    this.searchForm.controls['pickupDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      pickupDateFrom: null,
      pickupDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterDeliveryDate() {
    this.setFirstPage();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryDateFrom: null,
      deliveryDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterWarehouse() {
    this.setFirstPage();
    this.searchForm.controls['warehouseCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      warehouseCode: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['pickupDateFrom'].reset();
    this.searchForm.controls['pickupDateTo'].reset();
    this.searchForm.controls['deliveryDateFrom'].reset();
    this.searchForm.controls['deliveryDateTo'].reset();
    this.searchForm.controls['warehouseCode'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      pickupDateFrom: null,
      pickupDateTo: null,
      deliveryDateFrom: null,
      deliveryDateTo: null,
      warehouseCode: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.deliveryDateTag = null;
    this.deliveryDateStringTag = null;
    this.pickupDateTag = null;
    this.pickupDateStringTag = null;
    this.createdDateTag = null;
    this.createdDateStringTag = null;
    this.warehouseTypeTag = null;
    this.warehouseTypeStringTag = null;

    const deliveryDateFrom = dateStringToTagCriteria(this.criteriaObject.deliveryDateFrom);
    const deliveryDateTo = dateStringToTagCriteria(this.criteriaObject.deliveryDateTo);
    const deliveryDate = generateDateStringTag({
      dateName: 'Delivery Date',
      dateFrom: deliveryDateFrom,
      dateTo: deliveryDateTo
    });

    this.deliveryDateStringTag = deliveryDate.dateStringTag;
    this.deliveryDateTag = deliveryDate.dateTag;

    const pickupDateFrom = dateStringToTagCriteria(this.criteriaObject.pickupDateFrom);
    const pickupDateTo = dateStringToTagCriteria(this.criteriaObject.pickupDateTo);
    const pickupDate = generateDateStringTag({
      dateName: 'Pick Up Date',
      dateFrom: pickupDateFrom,
      dateTo: pickupDateTo
    });

    this.pickupDateStringTag = pickupDate.dateStringTag;
    this.pickupDateTag = pickupDate.dateTag;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createdDateStringTag = createdDate.dateStringTag;
    this.createdDateTag = createdDate.dateTag;

    if (this.criteriaObject.warehouseCode && this.criteriaObject.warehouseCode.length > 0) {
      this.warehouseTypeStringTag = 'Warehouse';
      const warehouseType = this.warehouseList
        .filter(data => {
          return this.criteriaObject.warehouseCode.indexOf(data.code.toString()) > -1;
        })
        .map(status => status.warehouseNameDisplay);
      const warehouseTypes = warehouseType.join(', ');
      this.warehouseTypeTag = `"${warehouseTypes}"`;
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new ShipmentListRequestAction(criteriaObj));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.pageSize = 20;
    this.currentPage = 1;

    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.pickMaxDate = new Date();
    this.pickMaxDate.setDate(this.pickMaxDate.getDate() + 365);

    this.createdMaxDate = new Date();
    this.createdMaxDate.setDate(this.pickMaxDate.getDate() + 365);
  }

  setRouteTab() {
    const hasDeliveryOrderListPagePermission = this.authGuardService.checkPermission(
      ['^do_m_[a-zA-Z]{2}[0-9]{1,3}$', '^do_v_[a-zA-Z]{2}[0-9]{1,3}$', '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );
    const hasShipmentListPagePermission = this.authGuardService.checkPermission(
      ['^do_logis_v_[a-zA-Z]{2}[0-9]{1,3}$', '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$', '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );

    this.listRoute = [];

    if (hasDeliveryOrderListPagePermission) {
      this.listRoute.push({ tabName: 'DELIVERY_ORDER.DELIVERY_ORDER_LIST', url: '/order/delivery-order-list' });
    }

    if (hasShipmentListPagePermission) {
      this.listRoute.push({ tabName: 'SHIPMENT.SHIPMENT_LIST', url: '/order/shipment-list' });
    }
  }

  canDispatch(currentStatus: ShipmentStatusEnum) {
    return ShipmentStatusEnum.AWAITING === currentStatus;
  }

  canDelete(currentStatus: ShipmentStatusEnum) {
    return ShipmentStatusEnum.AWAITING === currentStatus;
  }

  canEdit(currentStatus: ShipmentStatusEnum) {
    return [ShipmentStatusEnum.AWAITING, ShipmentStatusEnum.DISPATCHED].includes(currentStatus);
  }

  showModalShipment(shipmentNo: string = null, mode: string, doNo: string = null) {
    let title = '';
    switch (mode) {
      case this.shipmentTypeEnum.DISPATCH:
        title = 'Confirm Dispatch';
        break;
      case this.shipmentTypeEnum.EDIT_DISPATCH:
      case this.shipmentTypeEnum.EDIT_AWAITING:
        title = 'Edit Shipment';
        break;
      case this.shipmentTypeEnum.DELETE:
        title = 'Confirm Delete';
        break;
      default:
        title = 'View Shipment';
        break;
    }
    this.showShipment(shipmentNo, title, mode, doNo);
  }

  showShipment(shipmentNo: string, title: string, mode: string, doNo: string) {
    this.modalShipment = this.modalService.show(ShipmentRequestComponent, {
      class: 'delivery-details',
      backdrop: 'static',
      keyboard: false,
      initialState: {
        title,
        shipmentNo,
        mode,
        doNo
      }
    });
  }

  showHistory(shipment: Shipment) {
    this.modalService.show(HistoryComponent, {
      initialState: {
        title: 'History',
        historyHeader: `Shipment No.: ${shipment.shipmentNo}`,
        auditLogs: shipment.shipmentHistories
      }
    });
  }

  /** export modal related **/

  createExportForm() {
    this.exportForm = this.fb.group({
      createdDateFrom: [null],
      createdDateTo: [null],
      deliveryDateFrom: [null],
      deliveryDateTo: [null],
      status: [null],
      code: [null]
    });
  }

  onExport() {
    this.submittedExport = true;
    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();
    this.shipmentService.exportShipment(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
        this.doSearch(this.criteriaObject);
        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  prepareExportRequestData(formData): ShipmentExportCriteria {
    const createdDateFrom = dateToStringCriteria(formData.createdDateFrom);
    const createdDateTo = dateToStringCriteria(formData.createdDateTo, false);
    const deliveryDateFrom = dateToStringCriteria(formData.deliveryDateFrom);
    const deliveryDateTo = dateToStringCriteria(formData.deliveryDateTo, false);
    const statuses = this.getValue(formData.status);
    const warehouseCode = formData.code;

    return {
      createdDateFrom,
      createdDateTo,
      deliveryDateFrom,
      deliveryDateTo,
      statuses,
      warehouseCode
    };
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportShipment.timeFormat);

    return `${environment.fileName.exportShipment.prefix}${formattedDate}.xlsx`;
  }

  resetExportForm() {
    this.exportForm.controls['createdDateFrom'].reset();
    this.exportForm.controls['createdDateTo'].reset();
    this.exportForm.controls['deliveryDateFrom'].reset();
    this.exportForm.controls['deliveryDateTo'].reset();
    this.exportForm.controls['status'].reset();
    this.exportForm.controls['code'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }

  clearError() {
    this.exportForm.controls['createdDateFrom'].setErrors(null);
    this.exportForm.controls['createdDateTo'].setErrors(null);
    this.exportForm.controls['deliveryDateFrom'].setErrors(null);
    this.exportForm.controls['deliveryDateTo'].setErrors(null);
    this.errorExport = null;
  }

  alertErrorModal(errorResponse) {
    if (errorResponse.code && errorResponse.message) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
      if (['00006', '08023'].includes(errorResponse.code)) {
        this.errorExport = initialState.message;
      } else {
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });

        const modal = alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
          if (modal) {
            modal.unsubscribe();
          }
        });
      }
    }
  }

  onChangeCreatedShipmentDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdShipmentMinDate = new Date(value);
    } else {
      this.createdShipmentMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeCreatedShipmentDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdShipmentMaxDate = new Date(value);
    } else {
      this.createdShipmentMaxDate = new Date();
      this.createdShipmentMaxDate.setDate(this.createdShipmentMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDeliveryDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdDeliveryMinDate = new Date(value);
    } else {
      this.createdDeliveryMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeCreatedDeliveryDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdDeliveryMaxDate = new Date(value);
    } else {
      this.createdDeliveryMaxDate = new Date();
      this.createdDeliveryMaxDate.setDate(this.createdDeliveryMaxDate.getDate() + 365);
    }
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !(
      formValues.createdDateFrom ||
      formValues.createdDateTo ||
      formValues.deliveryDateFrom ||
      formValues.deliveryDateTo
    );
  }
}
