import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RouteLinkTab } from '../../models';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input() routeLinkTabs: Array<RouteLinkTab>;

  @Input() refresh: boolean;
  currentUrl: string;
  currentTab: string;
  constructor(private readonly router: Router, private readonly translate: TranslateService) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.routeLinkTabs.forEach(routeLinkTab => {
      if (this.currentUrl.startsWith(routeLinkTab.url) && !this.currentTab) {
        this.currentTab = routeLinkTab.tabName;
      }
      this.translate.get(routeLinkTab.tabName).subscribe(value => (routeLinkTab.tabName = value));
    });
  }

  onClickTab(obj: any) {
    this.currentTab = obj.tabName;
  }

  handleActiveUrl(url: string, tabName: string): boolean {
    if (!this.refresh) {
      return this.currentUrl === url;
    }

    if (this.currentTab === tabName) {
      return true;
    }
    return false;
  }
}
