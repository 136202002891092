import { enableProdMode } from '@angular/core';
// tslint:disable-next-line:no-implicit-dependencies
import { bootloader } from '@angularclass/hmr';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
const bootstrap = () => __NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, { preserveWhitespaces: !environment.production })
    .then(() => {
    if (window.navigator && navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (const registration of registrations) {
                registration.unregister().then(v => v && console.log('unregister is successful'));
            }
        });
    }
});
const ɵ0 = bootstrap;
bootloader(bootstrap);
export { ɵ0 };
