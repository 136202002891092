import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateShipmentRequest,
  Shipment,
  ShipmentExportCriteria,
  ShipmentPagination,
  ShipmentSearchCriteria
} from '../models/shipment.model';
import { BaseService } from './base.service';

@Injectable()
export class ShipmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.shipment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public createShipment(request: CreateShipmentRequest): Observable<Shipment> {
    return this.http.post<Shipment>(this.getUrl(), request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchShipment(criteria: ShipmentSearchCriteria): Observable<ShipmentPagination> {
    const params = this.getParams(criteria, true);

    return this.http.get<ShipmentPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public updateShipment(request: Shipment): Observable<Shipment> {
    return this.http.put<Shipment>(this.getUrl(), request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getShipmentByShipmentNumber(shipmentNo: string): Observable<Shipment> {
    const url = this.getFullUrl(this.envService.getByShipmentNumber, {
      shipmentNo
    });

    return this.http.get<Shipment>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public deleteByShipmentNumber(shipmentNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.deleteByShipmentNumber, { shipmentNo });

    return this.http.delete<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public confirmLoad(request: Shipment): Observable<Shipment> {
    const url = this.getFullUrl(this.envService.confirmLoad);

    return this.http.post<Shipment>(url, request, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public dispatchShipmentByShipmentNumber(shipmentNo: string): Observable<Shipment> {
    const url = this.getFullUrl(this.envService.dispatch, {
      shipmentNo
    });

    return this.http.post<Shipment>(url, {}, { headers: this.headers });
  }

  public exportShipment(criteria: ShipmentExportCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.exportShipment);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
