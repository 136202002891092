<div [formGroup]="parentForm" class="store-profile-wrapper">
  <div formArrayName="storeProfile" *ngFor="let tdStore of storeProfile.controls; let i = index; first as firstIndex">
    <div [formGroupName]="i">
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeCode">Store Code<span class="text-danger">*</span></label>
              <input
                id="storeCode"
                name="storeCode"
                type="text"
                class="form-control"
                maxlength="8"
                formControlName="storeCode"
                placeholder="Store Code"
                appAlphanumericOnly
                toUpperCase="true"
                [ngClass]="{
                  'is-invalid': isShowDuplicatedError(tdStore.controls.storeCode)
                }"
              />
              <div *ngIf="tdStore.controls.storeCode.errors?.duplicated" class="invalid-feedback">
                <div *ngIf="tdStore.controls.storeCode.errors?.duplicated">
                  {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Code' } }}
                </div>
              </div>
              <div *ngIf="(saved || submitted) && tdStore.controls.storeCode.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.storeCode.errors.required; else invalidStoreCode">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #invalidStoreCode>
                  <div *ngIf="tdStore.controls.storeCode.errors.invalidStoreCode">
                    {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Code' } }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeName">Store Name<span class="text-danger">*</span></label>
              <input
                id="storeName"
                name="storeName"
                type="text"
                class="form-control"
                maxlength="100"
                formControlName="storeName"
                placeholder="Store Name"
                [ngClass]="{
                  'is-invalid': isShowDuplicatedError(tdStore.controls.storeName)
                }"
              />
              <div *ngIf="tdStore.controls.storeName.errors?.duplicated" class="invalid-feedback">
                <div *ngIf="tdStore.controls.storeName.errors?.duplicated">
                  {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Name' } }}
                </div>
              </div>
              <div *ngIf="(saved || submitted) && tdStore.controls.storeName.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.storeName.errors.required; else invalidStoreName">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #invalidStoreName>
                  <div *ngIf="tdStore.controls.storeName.errors.invalidStoreName">
                    {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Store Name' } }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeContactName">Contact Name<span class="text-danger">*</span></label>
              <input
                id="storeContactName"
                name="storeContactName"
                type="text"
                class="form-control"
                maxlength="250"
                formControlName="contactName"
                placeholder="Contact Name"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.contactName.errors }"
              />
              <div *ngIf="submitted && tdStore.controls.contactName.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.contactName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="email">Email<span class="text-danger">*</span></label>
              <input
                name="email"
                type="email"
                class="form-control"
                maxlength="100"
                formControlName="email"
                placeholder="Email"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.email.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.email.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.email.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="tdStore.controls.email.errors.email">
                  {{ 'ERRORS.INVALID_EMAIL' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeStateProvince">State / Province<span class="text-danger">*</span></label>
              <ng-select
                id="storeStateProvince"
                name="storeStateProvince"
                placeholder="Please select..."
                [items]="stateSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="state"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.state.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.state.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.state.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeRegion">Region<span class="text-danger">*</span></label>
              <ng-select
                id="storeRegion"
                name="storeRegion"
                placeholder="Please select..."
                [items]="regionSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="region"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.region.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.region.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.region.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeTimezone">Timezone<span class="text-danger">*</span></label>
              <ng-select
                id="storeTimezone"
                name="storeTimezone"
                placeholder="Please select..."
                [items]="timeZoneSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="timezone"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.timezone.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.timezone.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.timezone.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storePhone">Phone Number<span class="text-danger">*</span></label>
              <ngx-intl-tel-input
                [cssClass]="'form-control d-block' + isPhoneError(tdStore.controls.phone)"
                [preferredCountries]="['th']"
                [enableAutoCountrySelect]="true"
                [maxlength]="'25'"
                id="storePhone"
                formControlName="phone"
                [ngClass]="{ disabled: tdStore.controls.phone.disabled }"
              ></ngx-intl-tel-input>
              <div *ngIf="submitted && tdStore.controls.phone.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.phone.errors.required; else validatePhoneNumber">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #validatePhoneNumber>
                  <div *ngIf="tdStore.controls.phone.errors.validatePhoneNumber">
                    {{ 'ERRORS.INVALID_PHONE' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="unitWLH">Unit W x L x H (in metre)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [negativeSign]="false"
                [format]="'0.00'"
                [maxValue]="999.99"
                id="unitWLH"
                formControlName="unitWLH"
                class="form-control"
                maxlength="6"
                placeholder="Cubic metre"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.unitWLH.errors }"
              ></app-numeric-textbox>
              <div *ngIf="submitted && tdStore.controls.unitWLH.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.unitWLH.errors.required; else validateUnitWLH">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #validateUnitWLH>
                  <div *ngIf="tdStore.controls.unitWLH.errors.isZero">
                    {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <div class="form-row">
                <div class="col-8">
                  <label for="storePropertyOwnership">Property Ownership<span class="text-danger">*</span></label>
                  <ng-select
                    id="storePropertyOwnership"
                    name="storePropertyOwnership"
                    placeholder="Please select..."
                    [items]="propertyOwnerhipSelectValue"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="disableRentalField(tdStore)"
                    bindLabel="nameTh"
                    bindValue="code"
                    formControlName="propertyOwnership"
                    [ngClass]="{ 'is-invalid': submitted && tdStore.controls.propertyOwnership.errors }"
                  >
                  </ng-select>
                  <div *ngIf="submitted && tdStore.controls.propertyOwnership.errors" class="invalid-feedback">
                    <div *ngIf="tdStore.controls.propertyOwnership.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label for="storeRental">Rental/Month<span class="text-danger">*</span></label>
                  <app-numeric-textbox
                    [negativeSign]="false"
                    [format]="'0,0.00'"
                    [maxValue]="9999999.99"
                    id="storeRental"
                    formControlName="rentalFee"
                    class="form-control"
                    placeholder="THB"
                    maxlength="10"
                    [ngClass]="{ 'is-invalid': submitted && tdStore.controls.rentalFee.errors }"
                  ></app-numeric-textbox>
                  <div *ngIf="submitted && tdStore.controls.rentalFee.errors" class="invalid-feedback">
                    <div *ngIf="tdStore.controls.rentalFee.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeLatitude">Latitude<span class="text-danger">*</span></label>
              <input
                id="storeLatitude"
                name="storeLatitude"
                type="text"
                class="form-control"
                formControlName="latitude"
                placeholder="Latitude"
                appDecimalPlaces="6"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.latitude.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.latitude.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.latitude.errors.required; else invalidStoreLatitude">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #invalidStoreLatitude>
                  <div *ngIf="tdStore.controls.latitude.errors.pattern">
                    {{ 'ERRORS.INVALID_LATITUDE' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeLongitude">Longitude<span class="text-danger">*</span></label>
              <input
                id="storeLongitude"
                name="storeLongitude"
                type="text"
                class="form-control"
                formControlName="longitude"
                placeholder="Longitude"
                appDecimalPlaces="6"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.longitude.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.longitude.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.longitude.errors.required; else invalidStoreLongitude">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #invalidStoreLongitude>
                  <div *ngIf="tdStore.controls.longitude.errors.pattern">
                    {{ 'ERRORS.INVALID_LONGITUDE' | translate }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeTaxNo">Tax No.<span class="text-danger">*</span></label>
              <input
                id="storeTaxNo"
                name="storeTaxNo"
                type="text"
                class="form-control"
                maxlength="13"
                formControlName="taxNo"
                placeholder="Tax No."
                appDigitOnly
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.taxNo.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.taxNo.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.taxNo.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="tdStore.controls.taxNo.errors.minlength">
                  {{ 'ERRORS.INVALID_FORMAT' | translate: { value: 'Tax No' } }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeBranchNo">Branch No.</label>
              <input
                id="storeBranchNo"
                name="storeBranchNo"
                type="text"
                class="form-control"
                maxlength="5"
                formControlName="branchNo"
                placeholder="Branch No."
                appDigitOnly
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.branchNo.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.branchNo.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.branchNo.errors.pattern">
                  {{ 'ERRORS.REQUIRED_5_DIGITS' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="storeAddress">Address<span class="text-danger">*</span></label>
              <input
                id="storeAddress"
                name="storeAddress"
                type="text"
                class="form-control"
                maxlength="250"
                formControlName="address"
                placeholder="Address"
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.address.errors }"
              />
              <div *ngIf="submitted && tdStore.controls.address.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.address.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storePostCode">PostCode<span class="text-danger">*</span></label>
              <input
                id="storePostCode"
                name="storePostCode"
                type="text"
                class="form-control"
                maxlength="15"
                formControlName="postCode"
                placeholder="PostCode"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.postCode.errors
                }"
              />
              <div *ngIf="submitted && tdStore.controls.postCode.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.postCode.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeCountry">Country<span class="text-danger">*</span></label>
              <ng-select
                id="storeCountry"
                placeholder="Please select..."
                [items]="countrySelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="country"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.country.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.country.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.country.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeBuildingType">Building Type<span class="text-danger">*</span></label>
              <ng-select
                id="storeBuildingType"
                name="storeBuildingType"
                placeholder="Please select..."
                [items]="buildingSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="buildingType"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.buildingType.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.buildingType.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.buildingType.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="unitSize">Unit Size<span class="text-danger">*</span></label>
              <ng-select
                id="unitSize"
                name="unitSize"
                placeholder="Please select..."
                [items]="buildingSizeSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="unitSize"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.unitSize.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.unitSize.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.unitSize.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeSaleSpace">Sale Space (sqm.)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [negativeSign]="false"
                [format]="'0.00'"
                [maxValue]="999.99"
                id="storeSaleSpace"
                formControlName="saleSpace"
                class="form-control"
                maxlength="6"
                placeholder="Sqm."
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.saleSpace.errors }"
              ></app-numeric-textbox>
              <div *ngIf="submitted && tdStore.controls.saleSpace.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.saleSpace.errors.required; else validateStoreSaleSpace">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #validateStoreSaleSpace>
                  <div *ngIf="tdStore.controls.saleSpace.errors.isZero">
                    {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeStockSpace">Stock Space (sqm.)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [negativeSign]="false"
                [format]="'0.00'"
                [maxValue]="999.99"
                id="storeStockSpace"
                formControlName="stockSpace"
                class="form-control"
                maxlength="6"
                placeholder="Sqm."
                [ngClass]="{ 'is-invalid': submitted && tdStore.controls.stockSpace.errors }"
              ></app-numeric-textbox>
              <div *ngIf="submitted && tdStore.controls.stockSpace.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.stockSpace.errors.required; else validateStoreStockSpace">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #validateStoreStockSpace>
                  <div *ngIf="tdStore.controls.stockSpace.errors.isZero">
                    {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="storeParking">Parking Condition<span class="text-danger">*</span></label>
              <ng-select
                id="storeParking"
                name="storeParking"
                placeholder="Please select..."
                [items]="parkingSelectValue"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                bindValue="code"
                formControlName="parking"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.parking.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.parking.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.parking.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="storeDeliveryByFourWheelsTruck"
                >Delivery By 4 Wheels Truck Only<span class="text-danger">*</span></label
              >
              <ng-select
                id="storeDeliveryByFourWheelsTruck"
                name="storeDeliveryByFourWheelsTruck"
                placeholder="Please select..."
                [items]="listOfValue['deliveryBy4Wheels']"
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                formControlName="deliveryByFourWheelsTruck"
                [ngClass]="{
                  'is-invalid': submitted && tdStore.controls.deliveryByFourWheelsTruck.errors
                }"
              >
              </ng-select>
              <div *ngIf="submitted && tdStore.controls.deliveryByFourWheelsTruck.errors" class="invalid-feedback">
                <div *ngIf="tdStore.controls.deliveryByFourWheelsTruck.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="storeDeliveryTimes">Restricted Delivery Time</label>
          <app-delivery-times
            #deliveryTimes
            [parentForm]="tdStore"
            [submitted]="submitted"
            [page]="page"
            [index]="i"
            [mode]="mode"
            [restrictDeliveryTimeSelectValue]="restrictDeliveryTimeSelectValue"
          ></app-delivery-times>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="titleDeed">Title Deed<span class="text-danger">*</span></label>
          <app-files-upload
            id="titleDeed"
            formControlName="titleDeed"
            class="form-control"
            [hasError]="submitted && tdStore.controls.titleDeed.errors"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [withMeta]="true"
            [size]="500000"
            [controlName]="'titleDeed'"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-files-upload>
        </div>
        <div class="col-md-6 mb-3">
          <label for="houseRegistrationPicture">Landlord House Registration<span class="text-danger">*</span></label>
          <app-files-upload
            id="houseRegistrationPicture"
            formControlName="houseRegistrationPicture"
            class="form-control"
            [hasError]="submitted && tdStore.controls.houseRegistrationPicture.errors"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [withMeta]="true"
            [size]="500000"
            [controlName]="'houseRegistrationPicture'"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-files-upload>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="idCardPicture">Landlord ID Card<span class="text-danger">*</span></label>
          <app-files-upload
            id="idCardPicture"
            formControlName="idCardPicture"
            class="form-control"
            [hasError]="submitted && tdStore.controls.idCardPicture.errors"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [withMeta]="true"
            [size]="500000"
            [controlName]="'idCardPicture'"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-files-upload>
        </div>
        <div class="col-md-6 mb-3">
          <label for="consentLetterPicture">Landlord Consent Letter<span class="text-danger">*</span></label>
          <app-files-upload
            id="consentLetterPicture"
            formControlName="consentLetterPicture"
            class="form-control"
            [hasError]="submitted && tdStore.controls.consentLetterPicture.errors"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [withMeta]="true"
            [size]="500000"
            [controlName]="'consentLetterPicture'"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-files-upload>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="storePicFront">Picture (Store Front)<span class="text-danger">*</span></label>
          <app-multiple-files-upload
            id="storePicFront"
            [form]="tdStore"
            [arrayName]="'storePicFront'"
            [submitted]="submitted"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [size]="500000"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, .jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [maxImages]="10"
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-multiple-files-upload>
        </div>
        <div class="col-md-6 mb-3">
          <label for="storeVDOFront">Video (Store Front)</label>
          <app-files-upload
            id="storeVDOFront"
            formControlName="storeVDOFront"
            class="form-control"
            [hasError]="submitted && tdStore.controls.storeVDOFront.errors"
            [allowedTypes]="'(mp4)'"
            [withMeta]="true"
            [size]="5000000"
            [controlName]="'storeVDOFront'"
            [allowedExt]="'(.mp4)'"
            descriptionTxt="Format file .mp4 (Size up to 5 MB)"
            fileSizeErrorTxt="Size up to 5 MB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .mp4)."
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-files-upload>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="attachmentPicture">Attachment</label>
          <app-multiple-files-upload
            id="attachmentPicture"
            [form]="tdStore"
            [arrayName]="'attachmentPicture'"
            [submitted]="submitted"
            [allowedTypes]="'(pdf|png|jpe?g)'"
            [size]="500000"
            [allowedExt]="'(.pdf|.png|.jpe?g)'"
            descriptionTxt="Format file .pdf, .jpg, .jpeg, .png (Size up to 500KB)"
            fileSizeErrorTxt="Size up to 500 KB."
            fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
            [maxImages]="10"
            [fileModule]="fileModule.MERCHANT_REQUEST"
          ></app-multiple-files-upload>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="nearByPois">Nearby POIs<span class="text-danger">*</span></label>
          <app-near-by-poi
            [parentForm]="tdStore"
            [submitted]="submitted"
            [page]="page"
            [index]="i"
            [mode]="mode"
            [poiSelectValue]="poiSelectValue"
            [poiDistanceSelectValue]="poiDistanceSelectValue"
          ></app-near-by-poi>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mb-3">
          <label for="nearByCompetitors">Nearby Competitors</label>
          <app-near-by-competitor
            [parentForm]="tdStore"
            [submitted]="submitted"
            [page]="page"
            [index]="i"
            [mode]="mode"
            [competitorSelectValue]="competitorSelectValue"
            [buildingSizeSelectValue]="buildingSizeSelectValue"
            [poiDistanceSelectValue]="poiDistanceSelectValue"
          ></app-near-by-competitor>
        </div>
      </div>
    </div>
  </div>
</div>
