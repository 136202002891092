import { createEntityAdapter } from '@ngrx/entity';
import { BillToMasterActionTypes } from '../actions/bill-to-master.actions';
const ɵ0 = (billToBase) => billToBase.code;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialBillToMasterState = adapter.getInitialState();
export function billToMasterReducers(state = initialBillToMasterState, action) {
    if (action.type === BillToMasterActionTypes.BILL_TO__MASTER_LIST_RESPONSE) {
        return adapter.addAll(action.payload, Object.assign({}, state));
    }
    else {
        return state;
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
