import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { LogOutAction } from '../../../shared/store/actions/auth.actions';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { AuthUserInfoState } from '../../../shared/store/state/auth-user-info.state';

@Component({
  selector: 'app-force-change-password-left',
  templateUrl: './force-change-password-left.component.html',
  styleUrls: ['./force-change-password-left.component.scss']
})
export class ForceChangePasswordLeftComponent implements OnInit, OnDestroy {
  public userInfoResult$: Observable<AuthUserInfoState>;
  private localStore: Observable<any>;

  constructor(private readonly router: Router, private readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
  }

  ngOnDestroy(): void {}

  onCancel() {
    this.store.dispatch(new LogOutAction());
  }
}
