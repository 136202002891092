import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SignedUrlObject } from '../models';
import {
  ClaimApproveRequest,
  ClaimRejectRequest,
  ClaimRequest,
  ClaimRequestExportCriteria,
  ClaimRequestPagination,
  ClaimRequestSearchCriteria,
  ClaimRequestSubmit
} from '../models/claim-request.model';
import { BaseService } from './base.service';

@Injectable()
export class ClaimService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.claim;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getClaimRequestByClaimNumber(claimRequestNo: string): Observable<ClaimRequest> {
    const url = this.getFullUrl(this.envService.get, {
      claimRequestNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getClaimDraftByReceiveOrderNumber(receiveOrderNo: string): Observable<ClaimRequest> {
    const url = this.getFullUrl(this.envService.draft, {
      receiveOrderNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  submit(data: ClaimRequestSubmit): Observable<any> {
    return this.http.post<any>(`${this.getUrl()}`, data, {
      headers: this.loaderHeaders()
    });
  }

  public searchByCriteria(criteria: ClaimRequestSearchCriteria): Observable<ClaimRequestPagination> {
    const params = this.getParams(criteria);
    return this.http.get<ClaimRequestPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  approve(claimApproveRequest: ClaimApproveRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.put<any>(url, claimApproveRequest, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  reject(claimRejectRequest: ClaimRejectRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.reject, {
      claimRequestNo: claimRejectRequest.claimRequestNo
    });
    return this.http.put<any>(
      url,
      { version: claimRejectRequest.version, comment: claimRejectRequest.comment },
      { headers: this.loaderHeaders() }
    );
  }

  releaseCN(claimRequestNo: string): Observable<any> {
    const url = this.getFullUrl(this.env.services.claim.releaseCn, {
      claimRequestNo
    });
    return this.http.put<any>(
      url,
      { claimRequestNo },
      {
        headers: this.loaderHeaders()
      }
    );
  }

  public exportClaimRequest(criteria: ClaimRequestExportCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.exportCrs);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.fileUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }
}
