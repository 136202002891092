import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap';
import { environment as env } from '../../../../environments/environment';
import { ImageModalComponent } from '../../../shared/layouts/modals/image-modal/image-modal.component';
import {
  AssortmentAllType,
  AssortmentDeliveryMethodEnum,
  AssortmentGroup,
  AssortmentPageModes
} from '../../../shared/models';
import { AssortmentRequestFreshLiteComponent } from '../assortment-request-fresh-lite/assortment-request-fresh-lite.component';
import { AssortmentSelectWarehouseModalComponent } from '../assortment-select-warehouse-modal/assortment-select-warehouse-modal.component';

@Component({
  selector: 'app-assortment-product-details',
  templateUrl: './assortment-product-details.component.html',
  styleUrls: ['./assortment-product-details.component.scss']
})
export class AssortmentProductDetailsComponent implements OnInit, OnDestroy {
  @Input() productDetailsForm: FormGroup;
  @Input() mode: AssortmentPageModes;
  @Input() isRequestViewMode: boolean;
  @Input() isSubmitted: boolean;
  @Input() isSaveDraft: boolean;
  @Input() isNonMerchandise: boolean;
  @Input() listOfValue: {};
  @Input() listOfChange: {};

  @ViewChild('freshLiteCategory', { static: false }) freshLiteCategory: AssortmentRequestFreshLiteComponent;

  uploadUrl: string;
  uploadHeaders: any;
  baseStorageUrl: string;
  assortmentAllType = AssortmentAllType;
  assortmentGroup = AssortmentGroup;
  assortmentPageModes = AssortmentPageModes;
  assortmentDeliveryMethodEnum = AssortmentDeliveryMethodEnum;

  private readonly types = [
    this.assortmentAllType.SEGMENT,
    this.assortmentAllType.FAMILY,
    this.assortmentAllType.CLASS,
    this.assortmentAllType.GRADINGGROUP,
    this.assortmentAllType.SUBCLASS
  ];
  // private readonly catalogs = [this.assortmentAllType.CATLV1, this.assortmentAllType.CATLV2];

  constructor(protected readonly modalService: BsModalService) {}

  ngOnInit() {
    this.uploadUrl = `${env.serverUrl}${env.services.productAssortment.uploadUrl}`;
    this.uploadHeaders = env.services.productAssortment.headers;
    this.baseStorageUrl = `${env.storageUrl}${env.services.productAssortment.bucketName}`;
  }

  isShowDuplicatedError(control: AbstractControl): string {
    const isDuplicated = control.errors ? control.errors.duplicated : false;
    return (this.isSaveDraft && control.errors) || (this.isSubmitted && control.errors) || isDuplicated
      ? 'is-invalid'
      : '';
  }

  onChangeSelectDropdown($event, assortmentType: AssortmentAllType, associateGroup: AssortmentGroup) {
    if (assortmentType) {
      this.clearFormSelectDropdown(assortmentType, associateGroup);

      if (this.assortmentAllType.SEGMENT === assortmentType) {
        this.listOfValue[this.assortmentAllType.FAMILY] = $event.families;
      } else if (this.assortmentAllType.FAMILY === assortmentType) {
        this.listOfValue[this.assortmentAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentAllType.CLASS === assortmentType) {
        this.listOfValue[this.assortmentAllType.SUBCLASS] = $event.subClasses;
        if (!this.isNonMerchandise && $event.gradingGroup) {
          this.listOfValue[this.assortmentAllType.GRADINGGROUP].push($event.gradingGroup);
          this.productDetailsForm.controls[this.assortmentAllType.GRADINGGROUP].patchValue($event.gradingGroup.code);
        } else {
          this.productDetailsForm.controls[this.assortmentAllType.GRADINGGROUP].reset();
        }
      }
    }
  }

  clearFormSelectDropdown(selectedType: AssortmentAllType, _associateGroup: AssortmentGroup) {
    // const typeArr = associateGroup === AssortmentGroup.CATALOG ? this.catalogs : this.types;
    const typeArr = this.types;
    const associateLv = typeArr.indexOf(selectedType) + 1;
    typeArr
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        this.productDetailsForm.controls[typeName].reset();
        this.listOfValue[typeName] = [];
      });
  }

  showModalFreshLiteCategory() {
    const initialState = {
      listOfValue: this.listOfValue
    };
    this.modalService.show(AssortmentRequestFreshLiteComponent, {
      class: 'modal-lg',
      backdrop: 'static',
      initialState
    });
  }

  showImage(value) {
    if (value) {
      this.modalService.show(ImageModalComponent, {
        initialState: {
          url: value[0].imgSrc
        },
        class: 'modal-image'
      });
    }
  }

  addSelectWarehouse() {
    this.modalService.show(AssortmentSelectWarehouseModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        productDetailsForm: this.productDetailsForm
      }
    });
  }

  isWarehouseChanged(wmsCode: string) {
    return Object.keys(this.listOfChange).find(changed => changed.includes(wmsCode));
  }

  ngOnDestroy(): void {}
}
