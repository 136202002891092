import { Action } from '@ngrx/store';
import {
  StoreAssortmentRequestStoreContent,
  StoreAssortmentRequestStoreListResponse,
  StoreAssortmentRequestStoreListSearchCriteria
} from '../../models/store-assortment-request-store.model';

export enum StoreAssortmentRequestStoreActionTypes {
  STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST = '[Store Assortment Request Store] List Request',
  STORE_ASSORTMENT_REQUEST_STORE_LIST_RESPONSE = '[Store Assortment Request Store] List Response',
  STORE_ASSORTMENT_REQUEST_STORE_SELECT = '[Store Assortment Request Store] Store Select',
  STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL = '[Store Assortment Request Store] Store Select All',
  STORE_ASSORTMENT_REQUEST_STORE_UNSELECT = '[Store Assortment Request Store] Store Unelect',
  STORE_ASSORTMENT_REQUEST_STORE_UNSELECT_ALL = '[Store Assortment Request Store] Store Unelect All',
  STORE_ASSORTMENT_REQUEST_STORE_SELECT_RESET = '[Store Assortment Request Store] Store select reset',
  STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL_RESET = '[Store Assortment Request Store] Store select all reset'
}

export class StoreAssortmentRequestStoreListRequestAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST;
  constructor(public payload: StoreAssortmentRequestStoreListSearchCriteria) {}
}

export class StoreAssortmentRequestStoreListResponseAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_RESPONSE;
  constructor(public payload: StoreAssortmentRequestStoreListResponse) {}
}

export class StoreAssortmentRequestStoreSelectAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT;
  constructor(public payload: StoreAssortmentRequestStoreContent) {}
}

export class StoreAssortmentRequestStoreSelectAllAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL;
  constructor() {}
}

export class StoreAssortmentRequestStoreUnselectAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT;
  constructor(public payload: StoreAssortmentRequestStoreContent) {}
}

export class StoreAssortmentRequestStoreUnselectAllAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT_ALL;
  constructor() {}
}

export class StoreAssortmentRequestStoreSelectResetAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_RESET;
  constructor() {}
}

export class StoreAssortmentRequestStoreSelectAllResetAction implements Action {
  readonly type = StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL_RESET;
  constructor() {}
}

export type StoreAssortmentRequestStoreAction =
  | StoreAssortmentRequestStoreListRequestAction
  | StoreAssortmentRequestStoreListResponseAction
  | StoreAssortmentRequestStoreSelectAction
  | StoreAssortmentRequestStoreSelectAllAction
  | StoreAssortmentRequestStoreUnselectAction
  | StoreAssortmentRequestStoreUnselectAllAction
  | StoreAssortmentRequestStoreSelectResetAction
  | StoreAssortmentRequestStoreSelectAllResetAction;
