import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class SidebarService {
    constructor() {
        this.toggled = false;
        this.toggled$ = new BehaviorSubject(this.toggled);
    }
    toggle() {
        this.toggled = !this.toggled;
        this.toggled$.next(this.toggled);
    }
    get sidebarState$() {
        return this.toggled$;
    }
    get sidebarState() {
        return this.toggled;
    }
    set sidebarState(state) {
        this.toggled = state;
        this.toggled$.next(this.toggled);
    }
}
SidebarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SidebarService_Factory() { return new SidebarService(); }, token: SidebarService, providedIn: "root" });
