import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/base.environment';
import { ToteTransactionFromStoreReportCriteria } from '../../../shared/models/report.model';
import { ToteTransactionService } from '../../../shared/services/tote-transaction.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-tote-transaction-from-store-modal',
  templateUrl: './tote-transaction-from-store-modal.component.html',
  styleUrls: ['./tote-transaction-from-store-modal.component.scss']
})
export class ToteTransactionFromStoreModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('toteTransactionFromStoreModal', { static: false })
  toteTransactionFromStoreModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly toteTransactionService: ToteTransactionService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      receiveOrderDateFrom: [null],
      receiveOrderDateTo: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.toteTransactionFromStoreModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.toteTransactionFromStoreModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const toteTransactionReportCriteria = new ToteTransactionFromStoreReportCriteria({
      receiveOrderDateFrom: dateToStringCriteria(rawData.receiveOrderDateFrom, true),
      receiveOrderDateTo: dateToStringCriteria(rawData.receiveOrderDateTo, false)
    });

    this.toteTransactionService.exportStore(toteTransactionReportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.toteTransactionFromStoreReport.prefixReport} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeExportReceiveOrderDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportReceiveOrderDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.toteTransactionFromStoreReport.timeFormat);
  }
}
