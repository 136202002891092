import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestSectionEnum } from '../../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import { TDStoreWorkflowUtil } from '../../../../shared/utils/td-store-workflow-util';
import { merchantDuplicatedValidator } from '../../../custom-validators/merchant-duplicated-validator';
import { TDStoreValidatorTypeEnum } from '../../../enum/merchant-validator-type.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MerchantProfile, MerchantRequestViewResponse } from '../../../models';
import { baseCurrency } from '../../../models/list-value/list-key-value.model';
import { MasterService } from '../../../services/master.service';
import { MerchantRequestService } from '../../../services/merchant-request.service';
import { ResetMerchantRequestSelected } from '../../../store/actions/merchant.actions';
import { AppStates } from '../../../store/state/app.states';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';

@Component({
  selector: 'app-merchant-profile',
  templateUrl: './merchant-profile.component.html',
  styleUrls: ['./merchant-profile.component.scss']
})
export class MerchantProfileComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() saved: boolean;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() requestId: string;
  @Input() merchantRequestId: string;
  @Input() page: TDStorePage;
  @Output() changeOwnerIdCard: EventEmitter<boolean> = new EventEmitter<boolean>();

  private localStore: Observable<any>;

  public listOfValue: {};
  public ownerIdCardReadOnly: boolean;
  // public triggeredMode: RequestPageModesEnum;

  public merchantSelectValue: { [key: string]: Array<any> };
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;

  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;
  private currencyCode: string;
  private defaultCountryCode: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly merchantRequestService: MerchantRequestService,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly masterService: MasterService
  ) {}

  get merchantProfile() {
    return this.parentForm.get('merchantProfile') as FormGroup;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetMerchantRequestSelected());
  }

  ngOnInit() {
    this.currencyCode = 'THB';

    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.parentForm.addControl('merchantProfile', this.createForm());

    this.initiateState();

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setMerchantProfile();
    }
  }

  initiateState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.masterService.getMasterDataByNames(['merchants', 'countries', 'customers']).subscribe(result => {
      if (result.data) {
        this.merchantSelectValue = result.data;
        this.merchantSelectValue.merchants = this.merchantSelectValue.merchants.filter(
          data => data.code !== 'STORE_MODEL'
        ); // Not allowed to use STORE_MODEL
        this.defaultCountryCode = this.merchantSelectValue.countries.find(data => data.nameTh === 'Thailand').code;

        this.merchantProfile.controls.country.setValue(this.defaultCountryCode);
        this.merchantProfile.controls.merchantType.setValue('PARTNER_MODEL');
      }
    });

    this.listOfValue = { baseCurrency };
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    return this.fb.group({
      customerType: initialNullRequired,
      taxId: [
        { value: null, disabled: false },
        { validators: [Validators.required, Validators.minLength(13)], updateOn: 'blur' }
      ],
      address: initialNullRequired,
      postCode: initialNullRequired,
      country: initialNullRequired,
      merchantType: initialNullRequired,
      merchantName: initialNullRequired,
      state: initialNullRequired,
      currency: [{ value: this.currencyCode, disabled: false }, Validators.required]
    });
  }

  onChangeMerchantCustomerType(el: SimpleChanges | any): void {
    this.ownerIdCardReadOnly = el.nameTh === 'Personal';
    this.changeOwnerIdCard.emit(this.ownerIdCardReadOnly);
    this.merchantProfile.controls.taxId.clearAsyncValidators();

    if (this.ownerIdCardReadOnly) {
      this.onChangeMerchantTaxId(this.merchantProfile.controls.taxId.value);
      this.merchantProfile.controls.taxId.setAsyncValidators([
        merchantDuplicatedValidator(
          TDStoreValidatorTypeEnum.ID_CARD,
          this.merchantRequestService,
          this.requestId,
          this.page
        )
      ]);
    } else {
      this.merchantProfile.controls.taxId.setAsyncValidators([
        merchantDuplicatedValidator(
          TDStoreValidatorTypeEnum.TAX_ID,
          this.merchantRequestService,
          this.requestId,
          this.page
        )
      ]);
    }

    this.merchantProfile.controls.taxId.updateValueAndValidity({ onlySelf: true });
  }

  onChangeMerchantTaxId(value: string): void {
    if (this.ownerIdCardReadOnly) {
      this.parentForm.controls.ownerProfile.get('cardId').setValue(value);
    }
  }

  isShowDuplicatedError(control: AbstractControl): string | undefined {
    if (control) {
      const isDuplicated = control.errors ? control.errors.duplicated : false;
      return (this.submitted && control.errors) || isDuplicated ? 'is-invalid' : '';
    }
  }

  setMerchantProfile() {
    this.merchantProfile.disable();

    this.merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));
    this.merchantRequestView$.pipe(map(response => response)).subscribe(value => {
      if (value) {
        this.setMerchantProfileValue(value.merchantInfo.merchantProfile);
        this.requestId = value.id;

        this.type = value.type;
        this.step = value.step;
        this.status = value.status;

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditMerchantProfile();
        } else {
          this.setMerchantProfileCtrl(this.type, this.page, this.step, this.mode);
        }
      }
    });
  }

  setMerchantProfileValue(merchantProfile: MerchantProfile) {
    this.merchantProfile.controls.taxId.clearAsyncValidators();
    this.merchantProfile.controls.taxId.updateValueAndValidity({ onlySelf: true });
    this.merchantProfile.patchValue({
      customerType: merchantProfile.customerType || null,
      taxId: merchantProfile.taxId || null,
      address: merchantProfile.address || null,
      postCode: merchantProfile.postCode || null,
      country: merchantProfile.country || null,
      merchantType: merchantProfile.merchantType || null,
      merchantName: merchantProfile.merchantName || null,
      state: merchantProfile.state || null,
      currency: merchantProfile.currency || null
    });
  }

  public toggleEditMerchantProfile() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (!this.step && this.page === TDStorePage.MERCHANT_EDIT) {
      this.step = RequestStepEnum.EDIT_PROFILE;
      this.type = RequestTypeEnum.EDIT; /* remove after getting type */
    }

    this.setMerchantProfileCtrl(this.type, this.page, this.step, this.mode);
  }

  setMerchantProfileCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(
      localType,
      localPage,
      localStep,
      RequestSectionEnum.PROFILE
    );
    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.merchantProfile.enable();

      this.merchantProfile.controls.taxId.setAsyncValidators([
        merchantDuplicatedValidator(
          TDStoreValidatorTypeEnum.ID_CARD,
          this.merchantRequestService,
          this.requestId,
          this.page
        )
      ]);

      this.disableFields();
    }
  }

  disableFields() {
    const merchantProfileCtrl = this.merchantProfile.controls;
    if (this.step === RequestStepEnum.PROFILE && this.status === RequestStatusEnum.AWAITING_APPROVAL) {
      merchantProfileCtrl.customerType.disable();

      // Not allow edit in case of customer type is Personal
      if (merchantProfileCtrl.customerType.value === 'PERSONAL') {
        merchantProfileCtrl.taxId.disable();
      }
    } else if (this.step === RequestStepEnum.EDIT_PROFILE) {
      merchantProfileCtrl.merchantType.disable();
      merchantProfileCtrl.customerType.disable();
      merchantProfileCtrl.taxId.disable();
    }
  }
}
