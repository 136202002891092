export class MyTaskSearchCriteria {
  searchCriteria?: string;
  module?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class MyTaskListResponse {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: MyTaskContent[];
}

export class MyTaskContent {
  id: string;
  requestId: string;
  requestNo: string;
  docType: string;
  module: string;
  requestStatus: string;
  subject: string;
  requester: string;
  requesterName: string;
  requestedDate: string;
  requestInfo: any;
  status: string;
  createdDate: string;
  lastModifiedDate: string;
}

export enum PortalModule {
  PRODUCT = 'PRODUCT',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  CLAIM = 'CLAIM',
  MERCHANT = 'MERCHANT',
  STORE = 'STORE',
  PROMOTION = 'PROMOTION',
  VOUCHER = 'VOUCHER',
  SUPPLIER_GROUP = 'SUPPLIER_GROUP',
  SHELF_REQUEST = 'SHELF_REQUEST',
  ORDER = 'ORDER'
}

export enum TasksDocType {
  PRODUCT_MERCHANDISE = 'PRODUCT_MERCHANDISE',
  PRODUCT_NON_MERCHANDISE = 'PRODUCT_NON_MERCHANDISE',
  PURCHASE_REQUEST_MERCHANDISE = 'PURCHASE_REQUEST_MERCHANDISE',
  PURCHASE_REQUEST_NON_MERCHANDISE = 'PURCHASE_REQUEST_NON_MERCHANDISE',
  PROMOTION = 'PROMOTION',
  VOUCHER = 'VOUCHER'
}

export class TaskModuleUrl {
  public static readonly MY_TASKS = '/dashboard/my-task';
  public static readonly PROMOTION_REQUEST = '/campaign/promotion-request-list';
  public static readonly PURCHASE_REQUEST = '/purchase/purchase-request-list';
  public static readonly CLAIM_REQUEST = '/claim-request-list';
  public static readonly VOUCHER_REQUEST = '/voucher-request-list';
  public static readonly MERCHANT_REQUEST = '/td-store/merchant-request-list';
  public static readonly STORE_REQUEST = '/td-store/store-request-list';
  public static readonly SUPPLIER_GROUP_REQUEST = '/supplier/supplier-group-request-list';
  public static readonly SHELF_REQUEST = '/shelf-request-list';
  public static readonly ORDER_REQUEST = '/order-request-list';
  public static readonly RECEIVE_ORDER_REQUEST = '/receive-order-list';
}
