import { createSelector } from '@ngrx/store';
import * as fromPurchaseRequest from '../reducers/purchase-request.reducers';
import { AppStates } from '../state/app.states';

const selectPurchaseRequestState = (state: AppStates) => state.purchaseRequest;

export const selectEditedPurchaseRequest = createSelector(selectPurchaseRequestState, state => {
  return state.purchaseRequest;
});

export const selectPurchaseRequest = createSelector(
  selectPurchaseRequestState,
  (state: fromPurchaseRequest.PurchaseRequestState) => state
);

export const selectClaimRequestSaveStatus = createSelector(
  selectPurchaseRequestState,
  (state: fromPurchaseRequest.PurchaseRequestState) => {
    return state.saveSuccess;
  }
);

export const selectPurchaseRequestList = createSelector(selectPurchaseRequestState, fromPurchaseRequest.selectAll);

export const selectPurchaseRequestCriteria = createSelector(selectPurchaseRequestState, state => {
  return state.criteriaObject;
});

export const selectPurchaseRequestProductList = createSelector(selectPurchaseRequestState, state => {
  return state.productList;
});

export const selectPurchaseRequestErrorResponse = createSelector(selectPurchaseRequestState, state => {
  return state.purchaseRequestErrorResponse;
});

export const selectDefaultDeliveryDetail = createSelector(selectPurchaseRequestState, state => {
  return state.defaultDeliveryDetail;
});
