import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { PermissionCreated, RoleCreated } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { AuthGuardService } from '../../../shared/services';
import { RoleCreatedRequestAction, RoleMasterListRequest } from '../../../shared/store/actions/role.actions';
import { selectRoleListCriteria, selectRoleMasterList } from '../../../shared/store/selectors/role.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { RoleEditComponent } from '../role-edit/role-edit.component';

@Component({
  selector: 'app-role-create',
  templateUrl: '../role-edit/role-edit.component.html',
  styleUrls: ['../role-edit/role-edit.component.scss']
})
export class RoleCreateComponent extends RoleEditComponent implements OnInit, OnDestroy {
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, translate, fb, modalService, authGuardService);
    this.isPermissionTab = true;
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    this.roleForm = this.fb.group({
      name: initialNullRequired
    });
  }

  initData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new RoleMasterListRequest());

    this.localStore
      .pipe(select(selectRoleListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));

    this.roleMasterList$ = this.store.pipe(select(selectRoleMasterList));
    this.roleMasterList$.subscribe(roleMaster => {
      if (roleMaster) {
        this.roleMasters = roleMaster;
      }
    });
  }

  onExit() {
    this.isFormDirty = this.roleForm.dirty || this.selectedPermission.length > 0;
    this.closeModal = true;
    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareCreatedData();
          this.store.dispatch(new RoleCreatedRequestAction(reqData));
        }
      });
  }

  prepareCreatedData(): RoleCreated {
    const value = this.roleForm.getRawValue();
    const permissions: PermissionCreated[] = [];
    const applications: string[] = [];
    this.selectedPermission.forEach(item => {
      const permission = {
        code: item.code,
        name: item.name,
        enable: item.enable
      } as PermissionCreated;

      const appData = this.prepareApplicationData(applications, item.applications);
      applications.push(...appData);
      permissions.push(permission);
    });

    return {
      ...value,
      name: value.name.trim(),
      isPredefined: false,
      applications,
      permissions
    };
  }
}
