import { createSelector } from '@ngrx/store';
import * as fromStoreAssortmentRequestStoreListResponseState from '../reducers/store-assortment-request-store.reducers';
import * as fromStoreAssortmentRequestListResponseState from '../reducers/store-assortment-request.reducer';
import { AppStates } from '../state/app.states';

const selectStoreAssortmentRequestListState = (state: AppStates) => state.storeAssortmentRequest;
const selectStoreAssortmentRequestStoreListState = (state: AppStates) => state.storeAssortmentRequestStore;

export const selectAllStoreAssortmentRequestList = createSelector(
  selectStoreAssortmentRequestListState,
  fromStoreAssortmentRequestListResponseState.selectAll
);

export const selectStoreAssortmentRequestList = createSelector(
  selectStoreAssortmentRequestListState,
  (state: fromStoreAssortmentRequestListResponseState.StoreAssortmentRequestState) => state
);

export const selectStoreAssortmentRequestListCriteria = createSelector(
  selectStoreAssortmentRequestListState,
  state => {
    return state.criteriaObject;
  }
);

export const selectAllStoreAssortmentRequestStoreList = createSelector(
  selectStoreAssortmentRequestStoreListState,
  fromStoreAssortmentRequestStoreListResponseState.selectAll
);

export const selectStoreAssortmentRequestStoreList = createSelector(
  selectStoreAssortmentRequestStoreListState,
  (state: fromStoreAssortmentRequestStoreListResponseState.StoreAssortmentRequestStoreState) => state
);

export const selectStoreAssortmentRequestStoreListCriteria = createSelector(
  selectStoreAssortmentRequestStoreListState,
  state => {
    return state.criteriaObject;
  }
);

export const selectStoreAssortmentRequestStoreSelectedList = createSelector(
  selectStoreAssortmentRequestStoreListState,
  state => {
    return state.selected;
  }
);

export const selectStoreAssortmentRequestStoreSelectedAllPage = createSelector(
  selectStoreAssortmentRequestStoreListState,
  state => {
    return state.selectedAllPage || { 1: false };
  }
);

export const selectStoreAssortmentRequestById = createSelector(
  selectStoreAssortmentRequestListState,
  (state: fromStoreAssortmentRequestListResponseState.StoreAssortmentRequestState) => state.selected
);
