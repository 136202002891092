import { createEntityAdapter } from '@ngrx/entity';
import { StoreAssortmentRequestStoreActionTypes } from '../actions/store-assortment-request-store.actions';
const ɵ0 = list => list.storeNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialStoreAssortmentRequestStoreState = adapter.getInitialState({
    selected: {},
    selectedAllPage: {},
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 0
});
export function storeAssortmentRequestStoreReducers(state = initialStoreAssortmentRequestStoreState, action) {
    switch (action.type) {
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL: {
            const { entities } = state;
            const selectedNew = {};
            Object.keys(entities).forEach(key => {
                selectedNew[entities[key].storeNo] = entities[key];
            });
            return Object.assign({}, state, { selected: Object.assign({}, state.selected, selectedNew), selectedAllPage: Object.assign({}, state.selectedAllPage) });
        }
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT_ALL: {
            const { selected, entities } = state;
            Object.keys(entities).forEach(key => {
                delete selected[entities[key].storeNo];
            });
            return Object.assign({}, state, { selected: Object.assign({}, selected), selectedAllPage: Object.assign({}, state.selectedAllPage) });
        }
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT: {
            return Object.assign({}, state, { selected: Object.assign({}, state.selected, { [action.payload.storeNo]: action.payload }) });
        }
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT: {
            const { selected } = state;
            delete selected[action.payload.storeNo];
            return Object.assign({}, state, { selected: Object.assign({}, selected) });
        }
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_RESET: {
            return Object.assign({}, state, { selected: {}, selectedAllPage: {} });
        }
        case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL_RESET: {
            return Object.assign({}, state, { selectedAllPage: { 1: false } });
        }
        default: {
            return Object.assign({}, state);
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
