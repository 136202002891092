import { Action } from '@ngrx/store';
import {
  StoreSupplierRequestSearchCriteria,
  SupplierStorePagination
} from '../../models/supplier-group-request.model';
import { StoreSupplierSearchCriteria } from '../../models/supplier-group.model';

export enum SupplierStoreActionTypes {
  SUPPLIER_STORE_RESET = '[Supplier Group View Page] Supplier Store Reset',
  SUPPLIER_STORE_REQUEST_LIST_REQUEST = '[Supplier Group View Page] Supplier Store List Request',
  SUPPLIER_STORE_REQUEST_LIST_RESPONSE = '[Supplier Store API] Supplier Store List Response',
  REQUEST_STORE_SUPPLIER_LIST_REQUEST = '[Supplier Group View Page] Store Supplier Request List Request',
  REQUEST_STORE_SUPPLIER_LIST_RESPONSE = '[Supplier Group View API] Store Supplier Request List Response',
  STORE_SUPPLIER_LIST_REQUEST = '[Supplier Group View Page] Store Supplier List Request',
  STORE_SUPPLIER_LIST_RESPONSE = '[Supplier Group View API] Store Supplier List Response'
}

export class SupplierStoreRequestListRequest implements Action {
  readonly type = SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_REQUEST;
  constructor(public payload: any) {}
}

export class SupplierStoreRequestListResponse implements Action {
  readonly type = SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_RESPONSE;
  constructor(public payload: SupplierStorePagination) {}
}

export class SupplierStoreReset implements Action {
  readonly type = SupplierStoreActionTypes.SUPPLIER_STORE_RESET;
  constructor() {}
}

export class StoreSupplierRequestListRequest implements Action {
  readonly type = SupplierStoreActionTypes.REQUEST_STORE_SUPPLIER_LIST_REQUEST;
  constructor(public payload: StoreSupplierRequestSearchCriteria) {}
}

export class StoreSupplierRequestListResponse implements Action {
  readonly type = SupplierStoreActionTypes.REQUEST_STORE_SUPPLIER_LIST_RESPONSE;
  constructor(public payload: SupplierStorePagination) {}
}

export class StoreSupplierListRequest implements Action {
  readonly type = SupplierStoreActionTypes.STORE_SUPPLIER_LIST_REQUEST;
  constructor(public payload: StoreSupplierSearchCriteria) {}
}

export class StoreSupplierListResponse implements Action {
  readonly type = SupplierStoreActionTypes.STORE_SUPPLIER_LIST_RESPONSE;
  constructor(public payload: SupplierStorePagination) {}
}

export type SupplierStoreActions =
  | SupplierStoreRequestListRequest
  | SupplierStoreRequestListResponse
  | StoreSupplierRequestListRequest
  | StoreSupplierRequestListResponse
  | StoreSupplierListRequest
  | StoreSupplierListResponse
  | SupplierStoreReset;
