<div id="purchase-request-list" class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-purchase"></em>
    <h4>{{ 'MENU.PURCHASE' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between">
      <div class="form-group text-nowrap d-none d-md-block">
        <button class="btn btn-primary mr-2" type="button" *ngIf="allowCreateEditPr" (click)="showModalCreatePr()">
          <em class="icon-plus"></em>
          Create PR
        </button>

        <app-import-export-button [buttons]="buttons" (export)="exportModal.show()"></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="prStatusFilter"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-width"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onchangeStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          <!--{{ 'CJ_PRODUCT.STEP' | translate }}-->
                          Purchase Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="prTypeFilter"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="purchaseType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        PR Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="startRequestDate"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="startRequestDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="endRequestDate"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="endRequestDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="prTypeTag || dateStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="prTypeStringTag">
      <a class="remove-criteria" (click)="clearTypeFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ prTypeStringTag }}: {{ prTypeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ result.docNo ? result.docNo : '(None)' }}
              </label>
              <span [ngClass]="getColorStatus(result.status)" class="card-status pr-status ml-auto">
                {{ 'PURCHASE_REQUEST.PURCHASE_REQUEST_STATUS.' + result.status | translate }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Purchase Type:</span>
              <span class="result-value">{{ getPurchaseType(result) }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Location:</span>
              <span class="result-value">{{ result.shipToCodeName | dashDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Total(THB):</span>
              <span class="result-value">{{ result.totalAmount | numberFormatDisplay }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Requester:</span>
              <span class="result-value">{{ result.requesterName | dashDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ result.requestedDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
            *ngIf="
              ![purchaseRequestStatusEnum.DRAFT].includes(result.status) ||
              permissionsUtil.determinePrPermission(result.shipToType, result.shipToCode, result.productType, [
                permissionAction.MANAGE
              ])
            "
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="
                [purchaseRequestStatusEnum.DRAFT].includes(result.status) &&
                ![shipToType.STORE].includes(result.shipToType) &&
                permissionsUtil.determinePrPermission(result.shipToType, result.shipToCode, result.productType, [
                  permissionAction.MANAGE
                ])
              "
              (click)="goToEdit(result)"
            >
              <em class="icon-edit"></em>Edit
            </button>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="
                [purchaseRequestStatusEnum.DRAFT].includes(result.status) &&
                permissionsUtil.determinePrPermission(result.shipToType, result.shipToCode, result.productType, [
                  permissionAction.MANAGE
                ])
              "
              (click)="onDeletePr(result.id)"
            >
              <em class="icon-delete"></em>Delete
            </button>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="
                [purchaseRequestStatusEnum.AWAITING_APPROVAL].includes(result.status) &&
                permissionsUtil.determinePrPermission(result.shipToType, result.shipToCode, result.productType, [
                  permissionAction.MANAGE
                ])
              "
              (click)="onCancelPr(result.id, result.docNo)"
            >
              <em class="icon-forbidden"></em>Cancel PR
            </button>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="result.status !== 'DRAFT'"
              (click)="showHistory(result)"
            >
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <!--modal create pr-->
  <div class="modal fade" bsModal #modalCreatePr="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content" [formGroup]="createPurchaseForm">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Warehouse and Purchase Type</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCreateNewPr()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Warehouse</div>
              <ng-select
                bindLabel="nameEn"
                [searchable]="false"
                [clearable]="false"
                [items]="warehouses"
                formControlName="warehouse"
                placeholder="Please select..."
                (change)="onChangeWarehouse($event)"
                [ngClass]="{
                  'is-invalid': isCreated && createPurchaseForm.controls.warehouse.errors
                }"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <span>{{ item.wmsCode + '-' + item.nameEn }}</span>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{ item.wmsCode + '-' + item.nameEn }}
                </ng-template>
              </ng-select>
              <div *ngIf="isCreated && createPurchaseForm.controls.warehouse.errors" class="invalid-display">
                <span *ngIf="createPurchaseForm.controls.warehouse.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </span>
              </div>
            </div>

            <div class="form-group">
              <div class="mb-2">Purchase Type</div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="purchaseRequestTypes"
                placeholder="Please select..."
                formControlName="purchaseType"
                [ngClass]="{
                  'is-invalid': isCreated && createPurchaseForm.controls.purchaseType.errors
                }"
              >
              </ng-select>
              <div *ngIf="isCreated && createPurchaseForm.controls.purchaseType.errors" class="invalid-display">
                <span *ngIf="createPurchaseForm.controls.purchaseType.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="goToCreate()" appPreventDoubleClick>
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Export -->
  <div class="modal fade" bsModal #exportModal="bs-modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Export Data</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
            <em class="icon-close"></em>
          </button>
        </div>

        <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
          <div class="form-row">
            <div class="col-12 px-0">PR Created Date<span class="text-danger">*</span></div>
            <div class="form-group col-md-6 pl-0">
              <label>{{ 'FROM' | translate }}:</label>
              <input
                id="export_prCreatedDateFrom"
                formControlName="prCreatedDateFrom"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.prCreatedDateFrom.errors
                }"
                [bsConfig]="bsDateConfig"
                [maxDate]="createdMaxDate"
                (bsValueChange)="onChangePRCreatedDateFrom($event)"
                readonly
              />
              <label for="export_prCreatedDateFrom" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.prCreatedDateFrom.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.prCreatedDateFrom.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
            <div class="form-group col-md-6 pr-0">
              <label>{{ 'TO' | translate }}:</label>
              <input
                id="export_prCreatedDateTo"
                formControlName="prCreatedDateTo"
                type="text"
                placeholder="{{ environment.dateFormat }}"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [ngClass]="{
                  'is-invalid': submittedExport && exportForm.controls.prCreatedDateTo.errors
                }"
                [bsConfig]="bsDateConfig"
                [minDate]="createdMinDate"
                (bsValueChange)="onChangePRCreatedDateTo($event)"
                readonly
              />
              <label for="export_prCreatedDateTo" class="icon-calendar btn-calendar"></label>
              <div *ngIf="submittedExport && exportForm.controls.prCreatedDateTo.errors" class="invalid-feedback">
                <div *ngIf="exportForm.controls.prCreatedDateTo.errors.required">
                  {{ 'ERRORS.REQUIRED_AT_LEAST_DATE_FROM_OR_TO' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Purchase Type
            </div>
            <div class="form-group">
              <ng-select
                id="export_purchaseType"
                placeholder="Please select..."
                [items]="prTypeFilter"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="purchaseType"
              >
              </ng-select>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Location
            </div>
            <div class="form-group">
              <app-dropdown-multiple-select
                [parentForm]="exportForm"
                controlName="code"
                [items]="warehouseList$ | async"
                bindLabel="locationDisplay"
                bindValue="code"
              >
              </app-dropdown-multiple-select>
            </div>
          </div>
          <div class="form-group">
            <div class="mb-2">
              Status
            </div>
            <div class="form-group">
              <ng-select
                id="export_status"
                placeholder="Please select..."
                [items]="prStatusExportFilter"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
              >
              </ng-select>
            </div>
          </div>
          <div *ngIf="errorExport" class="alert-danger form-group mb-0" role="alert">
            {{ errorExport }}
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onExport()"
            [disabled]="exportFormInValid"
            id="btnExport"
          >
            Export
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
