import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { environment } from '../../../../../environments/environment';
import { ImportError } from '../../../../shared/models/import.model';
import {
  ImportOrderRequest,
  OrderRequestDataSheet,
  ProductOrderItem
} from '../../../../shared/models/order-request.model';
import { ExcelUtil } from '../../../../shared/utils/excel-util';

@Component({
  selector: 'app-import-order-request',
  templateUrl: './import-order-request.component.html',
  styleUrls: ['./import-order-request.component.scss']
})
export class ImportOrderRequestComponent implements OnInit, OnDestroy {
  @Output() setData: EventEmitter<Array<ImportOrderRequest>> = new EventEmitter<Array<ImportOrderRequest>>();
  @Input() currentData: Array<ProductOrderItem>;

  public form: FormGroup;
  public loading: boolean;
  public errors = [];
  public errorMessage: string;

  private uniques = [];

  constructor(private readonly fb: FormBuilder, private readonly bsModalRef: BsModalRef) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy() {}

  createForm() {
    this.form = this.fb.group({
      importData: [null, Validators.required]
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.loading = true;
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* create workbook */
      const result = new Uint8Array(e.target.result);
      const v = XLSX.read(result, {
        type: 'array'
      });

      const wsname: string = v.SheetNames[0];
      const ws: XLSX.WorkSheet = v.Sheets[wsname];

      if (this.validateHeaders(ws)) {
        this.setErrorMessage('Incorrect Format (File template has been changed).');
        return;
      }

      const data = this.readImportData(ws);

      if (this.isDiffRows(data)) {
        this.setErrorMessage('Row count mismatch.');
        return;
      }

      this.handleData(data);
    };
    reader.readAsArrayBuffer(this.form.value.importData[0]);
  }

  readImportData(ws: XLSX.WorkSheet): Array<ImportOrderRequest> {
    const range = XLSX.utils.decode_range(ws['!ref']);
    range.s.r = 2; // 0 == XLSX.utils.decode_row("1")
    const newRange = XLSX.utils.encode_range(range);

    const jsonData: { [key: string]: any }[] = XLSX.utils.sheet_to_json(ws, {
      range: newRange,
      header: 'A'
    });

    return jsonData.map((item: ProductOrderItem) => {
      return {
        articleNo: item[OrderRequestDataSheet.articleNo] && item[OrderRequestDataSheet.articleNo].toString(),
        qty: item[OrderRequestDataSheet.qty]
      };
    });
  }

  validateHeaders(ws: XLSX.WorkSheet) {
    const startColumn = 0;
    const endColumn = XLSX.utils.decode_col(OrderRequestDataSheet.amount);
    const headers = ExcelUtil.getHeaders(ws, 1, startColumn, endColumn);
    return headers.length !== Object.keys(OrderRequestDataSheet).length;
  }

  isDiffRows(data: Array<ImportOrderRequest>) {
    const currentRows = this.currentData.length;
    const newRows = data.length;

    return currentRows !== newRows;
  }

  handleData(data: Array<ImportOrderRequest>) {
    this.validateData(data);

    if (!this.errors.length) {
      this.setData.emit(data);
      this.loading = false;
      this.closeModal();
    }
  }

  validateData(data: Array<ImportOrderRequest>) {
    data.forEach((v, i) => {
      this.validateDuplicateArticleNo(v, i);
      this.validateAvailableArticleNo(v, i);
      this.validateQuantity(v, i);
    });

    if (this.errors.length) {
      this.setErrorMessage(`${this.errors.length} Errors Found.`);
    }
  }

  validateDuplicateArticleNo(data: ImportOrderRequest, index: number) {
    if (this.uniques.includes(data.articleNo)) {
      this.addErrorData({ row: index, column: 'Article No.', description: 'Article No. is duplicate.' });
    } else {
      this.uniques.push(data.articleNo);
    }
  }

  validateAvailableArticleNo(data: ImportOrderRequest, index: number) {
    const item = this.currentData.find(v => v.articleNo === data.articleNo);

    if (!item) {
      this.addErrorData({
        row: index,
        column: 'Article No.',
        description: 'Article No. could not found in order request.'
      });
    }
  }

  validateQuantity(data: ImportOrderRequest, index: number) {
    const regexFullNum = /^((\+?)(([0-9]{1,3}(,[0-9]{3})+)|([1-9]?[1-9]?[1-9]{1})|([0-9]+))(\.[0]+|\.)?|\.[0]+)$/;
    const strQty: string =
      data.qty && data.qty.toLocaleString(environment.defaultLanguage, { minimumFractionDigits: 2 });
    data.qty = regexFullNum.test(strQty) ? Number(data.qty) : data.qty;

    if (!data.qty || data.qty === 0) {
      this.addErrorData({ row: index, column: 'Quantity', description: 'Not allow 0 or blank.' });
    } else if (!regexFullNum.test(strQty)) {
      this.addErrorData({ row: index, column: 'Quantity', description: 'Allow number only.' });
    } else if (data.qty > 99999) {
      this.addErrorData({ row: index, column: 'Quantity', description: 'Maximum is 99,999.' });
    }
  }

  addErrorData(error: ImportError) {
    return this.errors.push(error);
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
    this.loading = false;
  }

  reset() {
    this.errorMessage = null;
    this.errors = [];
    this.uniques = [];
  }
}
