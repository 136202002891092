<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store"></em>
    <span>{{ data.title }}</span>
  </div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelDeliveryOrder()">
    <em class="icon-forbidden"></em>
    Cancel Delivery Order
  </button>
  <button
    type="button"
    class="btn btn-header-button d-none d-md-block"
    *ngIf="isCanPrintPDF(deliveryOrder?.doStatus)"
    (click)="onPrintPdf(deliveryOrder)"
  >
    <em class="icon-print"></em>
    Print
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onClose()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="deliveryOrder" class="pb-5 admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Delivery Order No:</span>
      </div>
      <div class="info-header-value">
        <span>{{ deliveryOrder?.doNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order No:</span>
      </div>
      <div class="info-header-value">
        <span>{{ deliveryOrder?.poNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Shipment No:</span>
      </div>
      <div class="info-header-value">
        <span>{{ deliveryOrder?.shipmentNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Delivery Order Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(deliveryOrder?.doStatus)" class="do-status">{{
          'DELIVERY_ORDER.DELIVERY_ORDER_STATUS.' + deliveryOrder?.doStatus | translate
        }}</span>
      </div>
    </div>
  </div>

  <!-- Information Details -->
  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Warehouse</span>
          </div>
          <div class="info-header-value">
            {{ deliveryOrder?.warehouseDisplayName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ storeFullName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store Type</span>
          </div>
          <div class="info-header-value">
            {{ 'STORE_TYPE.' + deliveryOrder?.storeType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_TYPE.' + deliveryOrder?.orderType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Pick Date</span>
          </div>
          <div class="info-header-value">
            {{ deliveryOrder?.pickDate | amFromUtc | amLocal | dateDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Request Delivery Date</span>
          </div>
          <div class="info-header-value">
            {{ deliveryOrder?.requestedDeliveryDate | amFromUtc | amLocal | dateDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="information-container container-fluid mt-3">
    <div class="row">
      <div class="col-md-3">
        <span class="section-header mt-2">Customer</span>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg col-md-6 flex-column">
        <div class="info-header-label">
          <span>Store ID / Store Name:</span>
        </div>
        <div class="info-header-value">
          <strong>
            {{ deliveryOrder?.storeCode | dashDisplay }} / {{ deliveryOrder?.storeName | dashDisplay }}
          </strong>
          <div>
            {{ deliveryOrder?.shipTo.address | dashDisplay: '' }} {{ deliveryOrder?.shipTo.state | dashDisplay: '' }}
            {{ deliveryOrder?.shipTo.postCode | dashDisplay: '' }}
          </div>
        </div>
      </div>

      <div class="col-lg col-md-3 flex-column">
        <div class="info-header-label">
          <span>Contact Name:</span>
        </div>
        <div class="info-header-value">
          {{ deliveryOrder?.contactName | dashDisplay }}
        </div>
      </div>

      <div class="col-lg col-md-3 flex-column">
        <div class="info-header-label">
          <span>Contact Number:</span>
        </div>
        <div class="info-header-value">
          {{ deliveryOrder?.contactNumber | dashDisplay }}
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg col-md-6 flex-column">
        <div class="info-header-label">
          <span>Delivery Note:</span>
        </div>
        <div class="info-header-value">
          <ul
            class="hyphen"
            *ngIf="
              deliveryOrder?.deliveryByFourWheelsTruck || deliveryOrder?.noEntry || deliveryOrder?.deliveryNote;
              else none
            "
          >
            <li *ngIf="deliveryOrder?.deliveryByFourWheelsTruck || deliveryOrder?.noEntry">
              <span *ngIf="deliveryOrder?.deliveryByFourWheelsTruck">{{
                deliveryOrder?.deliveryByFourWheelsTruck
              }}</span>
              <span *ngIf="deliveryOrder?.noEntry">{{ deliveryOrder?.noEntry }}</span>
            </li>
            <li *ngIf="deliveryOrder?.deliveryNote">
              <div [innerHTML]="deliveryOrder?.deliveryNote"></div>
            </li>
          </ul>
          <ng-template #none>
            <span>{{ '-' }}</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-3" *ngIf="allowSplitDeliveryOrder">
    <button type="button" class="btn btn-secondary mr-2" (click)="showModalSplitDo()" [disabled]="isDisableSplitDo">
      <em class="icon-plus"></em>
      Split Delivery Order
    </button>
  </div>

  <ng-container *ngIf="deliveryOrder$ | async">
    <div class="mt-3 table-responsive" *ngIf="deliveryOrder?.items.length">
      <table id="datatable" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center align-top padding-x-0" *ngIf="allowSplitDeliveryOrder">
              Select
            </th>
            <th class="text-center align-top" *ngIf="deliveryOrder.doStatus === 'DRAFT'">Split DO</th>
            <th class="text-center align-top">No.</th>
            <th class="text-center align-top">Product Name</th>
            <th class="text-center align-top">Article No.</th>
            <th class="text-center align-top">Barcode</th>
            <th class="text-center align-top">Unit</th>
            <th class="text-center align-top">Quantity</th>
            <th class="text-center align-top">Assign Quantity</th>
            <th class="text-center align-top">Picked</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deliveryOrderItem of paging.currentPageData; index as i">
            <td class="text-center padding-x-0" *ngIf="allowSplitDeliveryOrder">
              <input
                id="select"
                type="checkbox"
                class="cursor-pointer"
                *ngIf="deliveryOrderItem.splitNo === 1"
                (change)="onClickCheckBox($event, deliveryOrderItem)"
                [(ngModel)]="selectedItemNoList[deliveryOrderItem.itemNo]"
              />
              <a *ngIf="deliveryOrderItem.splitNo !== 1" (click)="deleteDoItem(i, deliveryOrderItem)">
                <em
                  class="icon-delete"
                  id="full-modal-deleteIcon"
                  title="Delete"
                  appTippy
                  [tippyOptions]="{ arrow: true }"
                ></em>
              </a>
            </td>
            <td class="text-center" *ngIf="deliveryOrder.doStatus === 'DRAFT'">
              <div class="d-flex justify-content-center">
                <div>Draft {{ deliveryOrderItem.splitNo }}</div>
                <div class="circle ml-2" [ngClass]="getDraftColor(deliveryOrderItem.splitNo)"></div>
              </div>
            </td>
            <td class="text-center">{{ deliveryOrderItem.itemNo }}</td>
            <td>{{ deliveryOrderItem.productName }}</td>
            <td class="text-center">{{ deliveryOrderItem.articleNo }}</td>
            <td class="text-center">{{ deliveryOrderItem.barcode }}</td>
            <td class="text-center">{{ deliveryOrderItem.unit }}</td>
            <td class="text-center">{{ deliveryOrderItem.qty | numberFormatDisplay: 0 }}</td>
            <td class="text-center">{{ deliveryOrderItem.assignedQty | numberFormatDisplay: 0 }}</td>
            <td class="p-0 align-middle w-max-80">
              <app-numeric-textbox
                id="pickedQty"
                [negativeSign]="false"
                [format]="'0,0'"
                [decimals]="0"
                maxlength="6"
                placeholder="Picked"
                [cssClass]="'text-center'"
                [(ngModel)]="deliveryOrderItem.pickedQty"
                [ngClass]="{
                  'is-invalid': isSubmit && deliveryOrderItem.pickedQty > deliveryOrderItem.assignedQty
                }"
                [disabled]="!isEditMode || deliveryOrder.doStatus === 'DRAFT'"
                (blur)="onBlurPickedQty(deliveryOrderItem)"
              >
              </app-numeric-textbox>
              <div
                *ngIf="
                  (isSubmit || deliveryOrderItem.blur) && deliveryOrderItem.pickedQty > deliveryOrderItem.assignedQty
                "
                class="invalid-display"
              >
                Picked not more than Assign Quantity
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <app-ui-pagination
      #paging
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [items]="(deliveryOrder$ | async).items"
      [valueChangeDetector]="deliveryOrder$ | async"
    >
    </app-ui-pagination>
  </ng-container>

  <div
    class="fixed-row-bottom form-row"
    *ngIf="showSaveButton(deliveryOrder?.doStatus) || showEditButton || showSubmitButton"
  >
    <div class="float-left">
      <button
        *ngIf="showSaveButton(deliveryOrder?.doStatus)"
        type="button"
        id="btn-save"
        class="btn btn-secondary"
        appPreventDoubleClick
        (click)="onSave()"
      >
        Save
      </button>
    </div>

    <div class="float-right">
      <button
        *ngIf="
          showEditButton &&
          permissionUtil.determineDoPermission(deliveryOrder?.warehouseCode, [permissionAction.MANAGE])
        "
        type="button"
        id="btn-edit"
        class="btn btn-primary"
        (click)="toggleToEdit()"
      >
        Edit
      </button>
      <button
        *ngIf="showCancelButton"
        type="button"
        id="btn-cancel"
        class="btn btn-standard mr-2"
        (click)="onCancel()"
      >
        Cancel
      </button>
      <button
        *ngIf="showSubmitButton"
        type="button"
        id="btn-submit"
        class="btn btn-primary"
        appPreventDoubleClick
        (click)="onSubmit()"
      >
        Submit
      </button>
    </div>
  </div>

  <!-- Modal Split DO -->
  <div class="modal fade" bsModal #modalSplitDo="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Split Delivery Order</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalSplitDo()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <ng-select
                [searchable]="false"
                [clearable]="false"
                [items]="draftList"
                placeholder="Please select..."
                [(ngModel)]="selectedDraftNo"
                [ngClass]="{
                  'is-invalid': noSelectedDraft
                }"
                (change)="onChangeDraftNo($event)"
              >
              </ng-select>
              <div *ngIf="noSelectedDraft" class="invalid-display">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-standard" (click)="cancelSplit()" appPreventDoubleClick>
            Cancel
          </button>
          <button type="button" class="btn btn-primary" (click)="confirmSplit()" appPreventDoubleClick>
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
