import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsDatepickerDirective, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as uuid from 'uuid';
import { environment } from '../../../../../environments/base.environment';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { Z8ParameterContent, Z8ParameterList } from '../../../../shared/models/z8-parameter.model';
import { Z8ParameterDataService } from '../../../../shared/services/z8-parameter-data.service';
import { selectZ8ParameterById } from '../../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { formatDate } from '../../../../shared/utils/date-util';

@Component({
  selector: 'app-add-condition',
  templateUrl: './add-condition.component.html',
  styleUrls: ['./add-condition.component.scss']
})
export class AddConditionComponent implements OnInit, OnDestroy {
  @ViewChild('dpStartDate', { static: false }) dpStartDate: BsDatepickerDirective;

  @Output() validateObjChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() validateObj: any;
  @Input() listOfValue;
  @Input() storeList$: Observable<any>;
  @Input() articleList$: Observable<any>;
  @Input() listOfValue$: Observable<any>;
  @Input() mode: RequestPageModesEnum;
  @Input() editItem: Z8ParameterContent;

  public form: FormGroup;
  public errorData: any;
  public noOfErrors: number;
  public parameterInfo: Z8ParameterList;
  public storeList;
  public articleList;
  public families;
  public classCodes;
  public subclasses;
  public selectedProductValue;
  public selectedStore;

  public newValidateObj = {};
  public currentValidateObj;
  public originalEditItem;

  private localStore: Observable<any>;

  public productLevelList = filterDropdown.productLevelList;
  public productValueList: any = [];
  public bsDateConfig: BsDatepickerConfig;
  public dateFormat = environment.dateFormat;
  public minDateFrom: Date;
  public minDateTo: Date;
  public maxDate: Date;
  public isExpired: boolean;
  public nextDay: Date;
  public responseError: string;
  public parameterFactor = filterDropdown.z8ParameterFactor;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly modalService: BsModalService,
    private readonly store: Store<AppStates>,
    private readonly z8ParameterDataService: Z8ParameterDataService,
    private readonly translate: TranslateService
  ) {
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectZ8ParameterById)).subscribe(data => {
      this.parameterInfo = data;
    });

    this.nextDay = new Date();
    this.nextDay.setDate(this.nextDay.getDate() + 1);
    this.minDateFrom = this.nextDay;
    this.minDateTo = this.nextDay;

    this.createForm();
    this.initData();
  }

  ngOnDestroy() {}

  initData() {
    const allStore = {
      storeCode: 'ALL',
      storeName: 'All Stores',
      storeCodeName: 'All Stores'
    };

    combineLatest([
      this.listOfValue$,
      this.storeList$.pipe(
        filter(v => Boolean(v)),
        map(v => {
          return v.content.map(item => {
            return {
              storeNo: item.no,
              storeCode: item.code,
              storeCodeName: item.storeCodeName
            };
          });
        })
      ),
      this.articleList$.pipe(
        filter(v => Boolean(v)),
        map(v => {
          return v.map(item => {
            return {
              code: item.articleNo,
              name: item.articleNoProductName
            };
          });
        })
      )
    ]).subscribe(([segment, storeList, articleList]) => {
      const families = this.flatArray(segment.data.segments.map(v => v.families));
      this.families = this.getObjectListValue(families);

      const classCodes = this.flatArray(families.map(v => v.classCodes));
      this.classCodes = this.getObjectListValue(classCodes);

      const subclasses = this.flatArray(classCodes.map(v => v.subClasses));
      this.subclasses = this.getObjectListValue(subclasses);

      storeList = storeList.sort((a, b) => a.storeCode.localeCompare(b.storeCode));
      storeList.splice(0, 0, allStore);

      this.storeList = storeList;
      this.articleList = articleList;

      if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
        this.setEditItemValue(this.editItem);
      }
    });
  }

  createForm() {
    this.form = this.fb.group({
      store: [null, Validators.required],
      productLevel: [null, Validators.required],
      productValue: [null, Validators.required],
      parameterValue: [null, [Validators.required, this.notAllowZeroValidator()]],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    });
  }

  setEditItemValue(editItem: Z8ParameterContent) {
    this.form.get('store').patchValue(editItem.storeCode);
    this.form
      .get('productLevel')
      .patchValue(this.translate.instant('Z8_PARAMETER_REQUEST.PRODUCT_LEVEL.' + editItem.productLevel));
    this.form.get('productValue').patchValue(editItem.productValue.code);
    this.form.get('parameterValue').patchValue(editItem.parameterValue);
    this.form
      .get('startDate')
      .patchValue(this.getAvialableDate(moment(editItem.startDate, environment.dateFormat).toDate()));
    this.form
      .get('endDate')
      .patchValue(this.getAvialableDate(moment(editItem.endDate, environment.dateFormat).toDate()));
    this.form.updateValueAndValidity();

    this.originalEditItem = JSON.parse(JSON.stringify(this.editItem));

    if (editItem.productLevel === 'FAMILY') {
      this.productValueList = this.families;
    } else if (editItem.productLevel === 'CLASS') {
      this.productValueList = this.classCodes;
    } else if (editItem.productLevel === 'SUBCLASS') {
      this.productValueList = this.subclasses;
    } else if (editItem.productLevel === 'ARTICLE') {
      this.productValueList = this.articleList;
    }

    this.selectedProductValue = editItem.productValue;
    this.selectedStore = this.storeList && this.storeList.find(item => item.storeCode === editItem.storeCode);

    if (!editItem.endDate) {
      this.isExpired = true;
      this.onToggleNoExpire();
    }
  }

  getAvialableDate(date: Date) {
    return date < this.nextDay ? this.nextDay : date;
  }

  setEditData() {}

  closeModal() {
    this.bsModalRef.hide();
  }

  getParameterFactor() {
    const defaultParameter = {
      parameterName: 'Default',
      format: '0,0',
      minValue: 0,
      maxValue: 99999,
      decimals: 0,
      maxLength: 5,
      allowNegative: false
    };

    const paramFactor = this.parameterFactor
      ? this.parameterFactor.find(item => item.parameterName === this.parameterInfo.name)
      : null;

    return paramFactor ? paramFactor : defaultParameter;
  }

  onAddCondition() {
    const z8Condition = this.prepareZ8ConditionData();
    const isValidData = this.validateDuplicate(z8Condition);

    if (isValidData.length === 0) {
      this.validateObjChange.emit(this.validateObj);
      this.z8ParameterDataService.addData([z8Condition]);
      this.closeModal();
    } else {
      this.responseError = isValidData[0];
    }
  }

  onUpdateCondition() {
    const z8ConditionItem = this.prepareZ8ConditionData();

    if (this.originalEditItem.store) {
      delete this.originalEditItem.store;
    }

    // If update object is not the same as original object
    if (!this.isEqual(this.originalEditItem, z8ConditionItem)) {
      const originalItemKey = this.getItemKey(this.originalEditItem);

      if (this.validateObj[originalItemKey]) {
        if (this.validateObj[originalItemKey].length > 1) {
          const keyItemIndex = this.validateObj[originalItemKey].findIndex(
            x => x.startDate === this.originalEditItem.startDate && x.endDate === this.originalEditItem.endDate
          );

          if (keyItemIndex > -1) {
            this.validateObj[originalItemKey].splice(keyItemIndex, 1);
          }
        } else {
          delete this.validateObj[originalItemKey];
        }
      }

      const isValidData = this.validateDuplicate(z8ConditionItem);

      if (isValidData.length === 0) {
        this.validateObjChange.emit(this.validateObj);

        this.z8ParameterDataService.updateItem(z8ConditionItem);

        this.closeModal();
      } else {
        this.validateObj[originalItemKey] = [
          ...(this.validateObj[originalItemKey] || []),
          {
            startDate: this.originalEditItem.startDate,
            endDate: this.originalEditItem.endDate
          }
        ];

        this.validateObjChange.emit(this.validateObj);

        this.responseError = isValidData[0];
      }
    } else {
      this.closeModal();
    }
  }

  updateValidateItem() {}

  prepareZ8ConditionData() {
    const dateFormat = environment.dateFormat;
    const data = this.form.getRawValue();

    const z8Parameter: Z8ParameterContent = new Z8ParameterContent();
    z8Parameter.id = (this.editItem && this.editItem.id) || uuid.v4();
    z8Parameter.storeNo = this.selectedStore.storeNo ? this.selectedStore.storeNo : null;
    z8Parameter.storeCode = this.selectedStore.storeCode;
    z8Parameter.storeCodeName = this.selectedStore.storeCodeName;
    z8Parameter.startDate = data.startDate ? formatDate(data.startDate, dateFormat) : null;
    z8Parameter.endDate = data.endDate ? formatDate(data.endDate, dateFormat) : null;
    z8Parameter.productLevel = data.productLevel.toUpperCase().replace(' ', '');
    z8Parameter.parameterValue = data.parameterValue;
    z8Parameter.productValue = {
      code: this.selectedProductValue.code,
      name: this.selectedProductValue.name
    };

    return z8Parameter;
  }

  onChangeProductLevel(event) {
    const productLevel = event.value;

    this.form.get('productValue').patchValue(null);
    this.form.get('parameterValue').patchValue(null);
    this.form.updateValueAndValidity();

    if (productLevel === 'Family') {
      this.productValueList = this.families;
    } else if (productLevel === 'Class') {
      this.productValueList = this.classCodes;
    } else if (productLevel === 'Sub Class') {
      this.productValueList = this.subclasses;
    } else if (productLevel === 'Article') {
      this.productValueList = this.articleList;
    }
  }

  onChangeDateFrom(value: Date): void {
    this.minDateTo = new Date(value);
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = null;
    }
  }

  onSelectProductValue(event) {
    this.selectedProductValue = event;
  }

  onSelectStore(event) {
    this.selectedStore = event;
  }

  validateDuplicate(item) {
    const key = this.getItemKey(item);
    const startDate = moment(item.startDate, environment.dateFormat);
    const endDate = moment(item.endDate, environment.dateFormat);
    if (!startDate.isValid() || (item.endDate && !endDate.isValid())) {
      return [];
    }

    const existObj = this.validateObj[key];

    if (existObj) {
      let isCollapse;
      for (const obj of existObj) {
        isCollapse = this.checkCollapse(obj, item);
        if (isCollapse) {
          break;
        } else {
          this.validateObj[key] = [
            ...(this.validateObj[key] || []),
            {
              startDate: item.startDate,
              endDate: item.endDate
            }
          ];
        }
      }

      return isCollapse || [];
    } else {
      this.validateObj[key] = [
        {
          startDate: item.startDate,
          endDate: item.endDate
        }
      ];
    }
    return [];
  }

  notAllowZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  checkCollapse(obj, item) {
    const expireDate = moment('31/12/9999', environment.dateFormat);
    const itemStartDate = moment(item.startDate, environment.dateFormat);
    const objStartDate = moment(obj.startDate, environment.dateFormat);
    const itemEndDate = (item.endDate && moment(item.endDate, environment.dateFormat)) || expireDate;
    const objEndDate = (obj.endDate && moment(obj.endDate, environment.dateFormat)) || expireDate;

    return (itemStartDate === objStartDate && itemEndDate === objEndDate) ||
      (itemStartDate <= objStartDate && objStartDate <= itemEndDate) ||
      (itemStartDate <= objEndDate && objEndDate <= itemEndDate) ||
      (objStartDate < itemStartDate && itemStartDate < objEndDate)
      ? ['Duplicated condition']
      : null;
  }

  getItemKey(item: Z8ParameterContent) {
    return `${item.storeCode === 'ALL' ? 'All Stores' : item.storeCode}_${this.translate.instant(
      'Z8_PARAMETER_REQUEST.PRODUCT_LEVEL.' + item.productLevel
    )}_${item.productValue.code}`;
  }

  flatArray(array) {
    return array.reduce((a, b) => a.concat(b), []).sort((a, b) => a.nameEn.localeCompare(b.nameEn));
  }

  getObjectListValue(array) {
    const initialValue = {};
    return array.map(item => {
      return {
        code: item.code,
        name: `${item.code}-${item.nameEn}`
      };
    }, initialValue);
  }

  onToggleNoExpire() {
    const endDate = this.form.get('endDate');
    if (this.isExpired) {
      endDate.disable();
      endDate.patchValue(null);
      endDate.setValidators(null);
    } else {
      endDate.enable();
      endDate.setValidators(Validators.required);
    }

    endDate.updateValueAndValidity();
  }

  getPageTitle() {
    const parameter = this.parameterInfo.name || '';
    const pageMode = this.mode === RequestPageModesEnum.REQUEST_CREATE ? 'Add' : 'Edit';

    return `${pageMode} ${parameter} Condition`;
  }

  alertModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  isEqual(arg1, arg2) {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)) {
      if (
        Object.prototype.toString.call(arg1) === '[object Object]' ||
        Object.prototype.toString.call(arg1) === '[object Array]'
      ) {
        if (Object.keys(arg1).length !== Object.keys(arg2).length) {
          return false;
        }
        return Object.keys(arg1).every(key => {
          return this.isEqual(arg1[key], arg2[key]);
        });
      }
      return arg1 === arg2;
    }
    return false;
  }
}
