import { createEntityAdapter } from '@ngrx/entity';
import { ShelfDetailsActionType } from '../actions/shelf-details.actions';
const ɵ0 = (shelfDetail) => shelfDetail.shelfNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialShelfDetailsResponseState = adapter.getInitialState({
    isLoading: false
});
export function ShelfDetailsReducers(state = initialShelfDetailsResponseState, action) {
    switch (action.type) {
        case ShelfDetailsActionType.SHELF_DETAILS_RESPONSE:
            return adapter.upsertMany(action.payload, Object.assign({}, state));
        case ShelfDetailsActionType.SHELF_DETAILS_RESET:
            return adapter.removeAll(Object.assign({}, state));
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
