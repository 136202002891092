import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { MonthlyPartnerProfitSharingUploadStatusEnum } from '../../../shared/enum/monthly-partner-profit-sharing.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { MonthlyPartnerProfitSharingSubmitResponse } from '../../../shared/models/monthly-partner-profit-sharing.model';
import { MonthlyPartnerProfitSharingService } from '../../../shared/services/monthly-partner-profit-sharing.service';
import { LayoutActionVersionError } from '../../../shared/store/actions/layout.action';
import { MonthlyPartnerProfitSharingSubmitRequestAction } from '../../../shared/store/actions/monthly-partner-profit-sharing.actions';
import { selectMonthlyPartnerProfitSharingSubmitResult } from '../../../shared/store/selectors/monthly-partner-profit-sharing.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-monthly-partner-profit-sharing-download-modal',
  templateUrl: './monthly-partner-profit-sharing-download-modal.component.html',
  styleUrls: ['./monthly-partner-profit-sharing-download-modal.component.scss']
})
export class MonthlyPartnerProfitSharingDownloadModalComponent implements OnInit {
  public id: string;
  public refId: string;
  public runDate: string;
  public form: FormGroup;
  public monthlyPartnerProfitSharingUploadStatusEnum = MonthlyPartnerProfitSharingUploadStatusEnum;

  private monthlyPartnerProfitSharingObject;
  private localStore: Observable<any>;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly monthlyPartnerProfitSharingService: MonthlyPartnerProfitSharingService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.form.controls.uploadProfitSharing.valueChanges
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(value => {
        const response = value[0];
        this.monthlyPartnerProfitSharingObject = response;
      });

    this.localStore
      .pipe(select(selectMonthlyPartnerProfitSharingSubmitResult), filter(Boolean))
      .subscribe((result: MonthlyPartnerProfitSharingSubmitResponse) => {
        if (result.status === 'COMPLETED') {
          this.alertSuccessModal('Profit Sharing has been created.');
        }
      });
  }

  ngOnDestroy() {}

  createForm() {
    this.form = this.fb.group({
      uploadProfitSharing: [null]
    });
  }

  downloadMonthlyProfitSharing() {
    if (!this.refId) {
      return;
    }

    this.monthlyPartnerProfitSharingService.downloadMonthlyProfitSharingReport(this.refId, this.id).subscribe(
      result => {
        window.open(result.signedUrl, '_parent');
      },
      error => {
        this.store.dispatch(new LayoutActionVersionError(error));
      }
    );
  }

  alertErrorModal(errorResponse) {
    if (errorResponse && errorResponse.error) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.error.message
      };

      const alertModal = this.modalService.show(AlertModalComponent, {
        backdrop: 'static',
        initialState
      });

      alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          alertModal.hide();
          this.bsModalRef.hide();
        }
      });
    }
  }

  onSubmit() {
    if (
      !this.monthlyPartnerProfitSharingObject.monthlyPartnerProfitSharing ||
      !this.monthlyPartnerProfitSharingObject.partnerProfitSharings
    ) {
      return;
    }
    const submitObject = {
      monthlyPartnerProfitSharing: this.monthlyPartnerProfitSharingObject.monthlyPartnerProfitSharing,
      partnerProfitSharings: this.monthlyPartnerProfitSharingObject.partnerProfitSharings
    };

    this.store.dispatch(new MonthlyPartnerProfitSharingSubmitRequestAction(submitObject));
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }
}
