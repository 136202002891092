import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DeliveryOrderService } from '../../services/delivery-order.service';
import {
  DeliveryOrderActionTypes,
  DeliveryOrderListRequestAction,
  DeliveryOrderListResponseAction,
  DeliveryOrderSaveRequested,
  DeliveryOrderSaveSuccess,
  DeliveryOrderSubmitRequested,
  DeliveryOrderViewLoaded,
  DeliveryOrderViewRequested
} from '../actions/delivery-order.actions';
import { LayoutActionLoadError } from '../actions/layout.action';

@Injectable()
export class DeliveryOrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly deliveryOrderService: DeliveryOrderService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  loadDeliveryOrder$ = this.actions$.pipe(
    ofType<DeliveryOrderViewRequested>(DeliveryOrderActionTypes.DELIVERY_ORDER_VIEW_REQUESTED),
    tap(action => this.logger.debug('@Effect Delivery Order Requested: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.deliveryOrderService.getDeliveryOrderById(action.payload).pipe(
        map(result => new DeliveryOrderViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  saveDeliveryOrder$ = this.actions$.pipe(
    ofType<DeliveryOrderSaveRequested>(DeliveryOrderActionTypes.DELIVERY_ORDER_SAVE_REQUESTED),
    tap(action => this.logger.debug('@Effect Delivery Order Save: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.deliveryOrderService.updateDeliveryOrder(action.payload).pipe(
        map(() => new DeliveryOrderSaveSuccess(true)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  submitDeliveryOrder$ = this.actions$.pipe(
    ofType<DeliveryOrderSubmitRequested>(DeliveryOrderActionTypes.DELIVERY_ORDER_SUBMIT_REQUESTED),
    tap(action => this.logger.debug('@Effect Delivery Order Submit: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.deliveryOrderService.submitDeliveryOrder(action.payload).pipe(
        map(() => new DeliveryOrderSaveSuccess(true)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  deliveryOrderListRequestAction$ = this.actions$.pipe(
    ofType<DeliveryOrderListRequestAction>(DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST}:` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.deliveryOrderService.searchByCriteria(action.payload).pipe(
        map(response => new DeliveryOrderListResponseAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );
}
