import { Action } from '@ngrx/store';
import { ProductAssortmentResponse, ProductAssortmentSearchCriteria } from '../../models';

export enum ProductAssortmentActionTypes {
  ProductAssortmentSearchRequested = '[Product Assortment List Page] Product Assortment Search Requested',
  ProductAssortmentSearchLoaded = '[Product Assortment List API] Product Assortment Search Loaded'
}

export class ProductAssortmentSearchRequested implements Action {
  readonly type = ProductAssortmentActionTypes.ProductAssortmentSearchRequested;
  constructor(public payload: ProductAssortmentSearchCriteria) {}
}

export class ProductAssortmentSearchLoaded implements Action {
  readonly type = ProductAssortmentActionTypes.ProductAssortmentSearchLoaded;
  constructor(public payload: { productAssortments: ProductAssortmentResponse }) {}
}

export type ProductAssortmentActions = ProductAssortmentSearchRequested | ProductAssortmentSearchLoaded;
