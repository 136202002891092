import { createSelector } from '@ngrx/store';
import * as fromTdAssortmentState from '../reducers/td-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectTdAssortmentState = (state: AppStates) => state.tdAssortment;

export const selectAllTdAssortmentList = createSelector(selectTdAssortmentState, fromTdAssortmentState.selectAll);

export const selectTdAssortmentList = createSelector(
  selectTdAssortmentState,
  (state: fromTdAssortmentState.TdAssortmentState) => state
);
