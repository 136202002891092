<div id="import-export-button" *ngIf="isShowing()">
  <button
    type="button"
    class="btn btn-standard min-width-auto"
    placement="bottom"
    containerClass="bottomLeft"
    [popover]="imExTemplate"
    [outsideClick]="true"
    [disabled]="disabled"
  >
    <em class="icon-excel"></em>
  </button>

  <ng-template #imExTemplate>
    <div *ngFor="let button of buttons">
      <div class="form-row" *ngIf="!button.hidden">
        <span class="btn btn-link" (click)="onSubmit(button)">
          <em class="icon-{{ button.type }}"></em>{{ button.name }}
        </span>
      </div>
    </div>
  </ng-template>
</div>
