import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { RequestImportAdjustStock, StockAdjustSearchCriteria } from '../../../shared/models/adjust-stock.model';
import { StockInformationService } from '../../../shared/services/stock-information.service';
import {
  AdjustStockImportSubmitRequestAction,
  AdjustStockImportSubmitResetAction,
  AdjustStockListRequestAction
} from '../../../shared/store/actions/adjust-stock.actions';
import {
  selectAdjustListCriteria,
  selectImportAdjustStockResponse
} from '../../../shared/store/selectors/adjust-stock.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { ColumnType, ColumnTypeEnum, ExcelUtil, HeaderSheet } from '../../../shared/utils/excel-util';

@Component({
  selector: 'app-stock-adjustment-download-modal',
  templateUrl: './stock-adjustment-download-modal.component.html',
  styleUrls: ['./stock-adjustment-download-modal.component.scss']
})
export class StockAdjustmentDownloadModalComponent implements OnInit {
  public form: FormGroup;
  private localStore: Observable<any>;

  public runDate: string;
  public stockAdjustmentObject;
  public criteriaObject: StockAdjustSearchCriteria;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService,
    protected stockInformationService: StockInformationService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.createForm();
    this.form.controls.uploadStockAdjustment.valueChanges
      .pipe(filter(Boolean), distinctUntilChanged())
      .subscribe(value => {
        this.stockAdjustmentObject = value[0];
      });

    this.localStore
      .pipe(select(selectAdjustListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));

    this.localStore
      .pipe(select(selectImportAdjustStockResponse), filter(Boolean))
      .subscribe(() => this.alertSuccessModal('The data have been imported.'));
  }

  ngOnDestroy() {}

  refreshList() {
    this.criteriaObject.page = 0;
    this.store.dispatch(new AdjustStockListRequestAction(this.criteriaObject));
  }

  createForm() {
    this.form = this.fb.group({
      uploadStockAdjustment: [null]
    });
  }

  downloadStockAdjustmentTemplate() {
    const fileName = `${env.fileName.exportStock.titleStockAdjustmentTemplate}.xlsx`;
    const titleSheet = `${env.fileName.exportStock.titleStockAdjustmentTemplate}`;
    const headers = [
      'Reference No',
      'Location Type',
      'Location',
      'Adjust Type',
      'Article No',
      'Adjust Stock',
      'Reason'
    ];
    const header = {
      title: '',
      headers
    } as HeaderSheet;

    const columnType: ColumnType = {
      A: ColumnTypeEnum.TEXT,
      B: ColumnTypeEnum.TEXT,
      C: ColumnTypeEnum.TEXT,
      D: ColumnTypeEnum.TEXT,
      E: ColumnTypeEnum.TEXT,
      F: ColumnTypeEnum.TEXT_CENTER,
      G: ColumnTypeEnum.TEXT
    };

    const workSheet = ExcelUtil.GeneratedWorkSheet(header, [], columnType);
    const workBook = ExcelUtil.GeneratedWorkBook(workSheet, titleSheet);

    ExcelUtil.WriteFile(workBook, fileName);
  }

  onSubmit() {
    if (!this.stockAdjustmentObject && !this.stockAdjustmentObject.stockAdjustItems) {
      return;
    }

    const submitObject: RequestImportAdjustStock[] = this.stockAdjustmentObject.stockAdjustItems;

    this.store.dispatch(new AdjustStockImportSubmitRequestAction(submitObject));
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.refreshList();
        this.store.dispatch(new AdjustStockImportSubmitResetAction());
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }
}
