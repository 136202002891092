import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ClassMarkup, ClassMarkupSearchCriteria, RouteLinkTab } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import {
  ClassMarkupActionTypes,
  ClassMarkupSearchRequested,
  ClassMarkupUpdateRequested,
  ClassMarkupUpdateSuccess
} from '../../../shared/store/actions/class-markup.actions';
import { ClassMarkupEffects } from '../../../shared/store/effects/class-markup.effects';
import { ClassMarkupState } from '../../../shared/store/reducers/class-markup.reducers';
import {
  selectClassMarkupList,
  selectClassMarkupTotal
} from '../../../shared/store/selectors/class-markup.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-class-markup',
  templateUrl: './class-markup.component.html',
  styleUrls: ['./class-markup.component.scss']
})
export class ClassMarkupComponent implements OnInit, OnDestroy {
  currentPage: number;
  pageSize: number;
  maxValue: number;

  headerRow: string[];

  searchForm: FormGroup;

  listRoute: Array<RouteLinkTab>;

  priceForm = {};

  destroyed$ = new Subject();

  criteriaObject: ClassMarkupSearchCriteria;

  classMarkupFilter = filterDropdown.classMarkupFilter;

  classMarkups$: Observable<ClassMarkup[]>;
  totalElements$: Observable<ClassMarkupState>;
  hasEditPermission = false;

  constructor(
    private readonly store: Store<AppStates>,
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly classMarkupEffect: ClassMarkupEffects,
    private readonly dispatcher: ActionsSubject,
    private readonly authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    // Check permission
    this.hasEditPermission = this.authGuardService.checkPermission(['price_m']);

    this.headerRow = ['Class Code', 'Class Name', 'Price Markup %', 'Edit Date', 'Edit By', 'Action'];
    this.createForm();
    this.setInitialCriteriaObject();
    this.setInitialElementValue();
    this.setFirstPage();
    this.setRouteTab();
    // api
    this.searchClassMarkup(this.criteriaObject);

    this.classMarkups$ = this.store.select(selectClassMarkupList);
    this.totalElements$ = this.store.select(selectClassMarkupTotal);

    this.dispatcher
      .pipe(
        filter((action: Action) => action.type === ClassMarkupActionTypes.ClassMarkupUpdateSuccess),
        takeUntil(this.destroyed$)
      )
      .subscribe((res: ClassMarkupUpdateSuccess) => {
        delete this.priceForm[res.payload.id];
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null, Validators.required],
      status: ['']
    });
  }

  setInitialElementValue() {
    this.maxValue = 999.99;
    this.pageSize = 20;
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      priceMarkup: null,
      page: 0,
      size: 20
    };
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      priceMarkup: formValue.productStatus,
      page: 0
    };
    this.searchClassMarkup(this.criteriaObject);
  }

  clearSearchText() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.searchClassMarkup(this.criteriaObject);
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.searchClassMarkup(this.criteriaObject);
    }
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      priceMarkup: event.value,
      page: 0
    };
    this.searchClassMarkup(this.criteriaObject);
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.searchClassMarkup(this.criteriaObject);
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.searchClassMarkup(this.criteriaObject);
  }

  searchClassMarkup(criteriaObj) {
    this.store.dispatch(new ClassMarkupSearchRequested(criteriaObj));
  }

  editClassMarkup(classMarkup: ClassMarkup) {
    this.priceForm[classMarkup.id] = {
      ...classMarkup,
      isEdit: true
    };
  }

  updateClassMarkup(id: string) {
    if (this.priceForm[id].classMarkup === '' || this.priceForm[id].classMarkup === null) {
      this.priceForm[id] = {
        ...this.priceForm[id],
        isError: true
      };
      return;
    }
    this.store.dispatch(new ClassMarkupUpdateRequested({ classMarkup: this.priceForm[id] }));
  }

  cancelEditClassMarkup(id: string) {
    delete this.priceForm[id];
  }

  clearError(id: string) {
    if (this.priceForm[id] && this.priceForm[id].isError) {
      this.priceForm[id].isError = false;
    }
  }

  showHistory(classMarkup: ClassMarkup) {
    const initialState = {
      title: 'History',
      historyHeader: `Class: ${classMarkup.classCode} - ${classMarkup.className}`,
      historyType: HistoryType.PRICE_SETTING_CLASS,
      auditLogs: classMarkup.historySettings
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setRouteTab() {
    this.listRoute = [];

    this.listRoute.push({ tabName: 'Stack Pricing', url: '/products/stack-pricing' });
    this.listRoute.push({ tabName: 'Class Markup', url: '/products/class-markup' });

    // Incase it has permission to edit
    if (this.hasEditPermission) {
      this.listRoute.push({ tabName: 'Location Markup', url: '' });
      this.listRoute.push({ tabName: 'Setting', url: '' });
    }
  }
}
