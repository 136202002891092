<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Add Shelf Type</h6>
    <button type="button" class="full-popup-close close pull-right" aria-label="Close" (click)="closeModal()">
      <em class="icon-close"></em>
    </button>
  </div>

  <div class="modal-body admin-form">
    <div class="container-fluid" [formGroup]="shelfTypeForm">
      <div class="row">
        <div class="col-md-12 mb-3">
          <label>Shelf Type Name<span class="text-danger">*</span></label>
          <div class="d-flex">
            <div class="flex-item">
              <input
                id="shelfTypeName"
                name="shelfTypeName"
                class="form-control"
                maxlength="100"
                formControlName="shelfTypeName"
                placeholder="Shelf Type Name"
                type="text"
                trim="blur"
                [ngClass]="{
                  'is-invalid': submitted && shelfTypeForm.controls.shelfTypeName.errors
                }"
              />
              <div *ngIf="submitted && shelfTypeForm.controls.shelfTypeName.errors" class="invalid-feedback">
                <div *ngIf="shelfTypeForm.controls.shelfTypeName.errors?.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="shelfTypeForm.controls.shelfTypeName.errors?.duplicated">
                  {{ 'ERRORS.DUPLICATED_SHELF_TYPE_NAME' | translate }}
                </div>
              </div>
            </div>
            <button class="btn btn-standard btn-inline ml-2" type="button" (click)="addShelfTypeName()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="shelfTypeListForm.controls.length">
        <div class="col-12">
          <label>Shelf Type List</label>
          <div class="table-responsive table-scroll max-height-table">
            <table class="table table-striped table-bordered table-hover w-100">
              <thead>
                <tr>
                  <th class="text-center w-10">Shelf Type Code</th>
                  <th class="text-center w-30">Shelf Type Name</th>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  formArrayName="shelfTypeList"
                  *ngFor="let item of shelfTypeListForm.controls; index as itemIndex"
                >
                  <tr [formGroupName]="itemIndex">
                    <td class="text-center">{{ item.value.code | dashDisplay: '(None)' }}</td>
                    <td class="d-flex flex-column">
                      <app-edit-in-place
                        [control]="item"
                        [viewData]="item.value.name"
                        [mode]="form.get('editInPlaceMode').value"
                        (modeChange)="onEditInPlaceModeChange($event)"
                      >
                        <input
                          type="text"
                          formControlName="name"
                          class="form-control"
                          required
                          maxlength="100"
                          trim="blur"
                          [ngClass]="{ 'is-invalid': item.get('name').errors }"
                        />
                      </app-edit-in-place>
                      <span *ngIf="item.get('name').errors?.required" class="invalid-message">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </span>
                      <span
                        *ngIf="!item.get('name').errors?.required && item.get('name').errors?.duplicated"
                        class="invalid-message"
                      >
                        {{ 'ERRORS.DUPLICATED_SHELF_TYPE_NAME' | translate }}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard mr-2" (click)="closeModal()" id="product-modal-cancelBtn">
      Cancel
    </button>
    <button
      type="submit"
      class="btn btn-primary mr-3"
      id="product-modal-addBtn"
      (click)="onSubmit()"
      [disabled]="isLoading"
    >
      Update
    </button>
  </div>
</div>
