import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingSubmitResponse
} from '../../models/monthly-partner-profit-sharing.model';
import {
  MonthlyPartnerProfitSharingActions,
  MonthlyPartnerProfitSharingActionTypes
} from '../actions/monthly-partner-profit-sharing.actions';

export interface MonthlyPartnerProfitSharingState extends EntityState<MonthlyPartnerProfitSharingContent> {
  criteriaObject: MonthlyPartnerProfitSharingSearchCriteria;
  isLoading: boolean;
  monthlyPartnerProfitSharing: MonthlyPartnerProfitSharingSubmitResponse;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<MonthlyPartnerProfitSharingContent> = createEntityAdapter<
  MonthlyPartnerProfitSharingContent
>();

export const initialMonthlyPartnerProfitSharingState: MonthlyPartnerProfitSharingState = adapter.getInitialState({
  isLoading: false,
  monthlyPartnerProfitSharing: null,
  criteriaObject: {
    sortBy: 'lastModifiedDate',
    sortOrder: 'desc',
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function monthlyPartnerProfitSharingReducers(
  state = initialMonthlyPartnerProfitSharingState,
  action: MonthlyPartnerProfitSharingActions
): MonthlyPartnerProfitSharingState {
  switch (action.type) {
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_RESPONSE:
      return { ...state, monthlyPartnerProfitSharing: action.payload };
    case MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_RESET_RESPONSE:
      return { ...state, ...initialMonthlyPartnerProfitSharingState };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
