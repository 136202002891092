import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequestStatusEnum } from '../../enum/request-status.enum';
import { RequestStepEnum } from '../../enum/request-step.enum';
import { SubmitSuccessEnum } from '../../enum/submit-success.enum';
import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import { StoreRequestTemplate, StoreRequestViewResponse } from '../../models';
import { MerchantService } from '../../services/merchant.service';
import { StoreRequestService } from '../../services/store-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StoreApproveResponseAction, StoreCreateSaveResponseAction, StoreCreateSubmitResponseAction, StoreCreateSubmitResponseError, StoreDeleteResponseAction, StoreFirstLotOrderValidateSubmitRequestAction, StoreGetVersionResponseAction, StoreRequestActionType, StoreRequestByIdResponseAction, StoreRequestHistoryResponseAction, StoreRequestListResponseAction } from '../actions/store-request.actions';
export class StoreRequestEffects {
    constructor(actions$, storeRequestService, merchantService, logger) {
        this.actions$ = actions$;
        this.storeRequestService = storeRequestService;
        this.merchantService = merchantService;
        this.logger = logger;
        this.searchStoreRequest$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeRequestService.searchStoreRequestByCriteria(payload).pipe(map(response => {
                return new StoreRequestListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreRequestMerchantById$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_MERCHANT_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_MERCHANT_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantService.getMerchantById(payload).pipe(map(merchantView => {
                const storeRequestViewResponse = Object.assign({ merchantId: merchantView.id }, this.parseStoreRequestResponse(merchantView));
                return new StoreRequestByIdResponseAction(storeRequestViewResponse);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreRequestById$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_GET_BY_ID_REQUEST), tap(action => this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload))), map(action => action.payload), switchMap(payload => {
            return this.storeRequestService.getStoreById(payload).pipe(switchMap(store => combineLatest([
                of(store).pipe(tap(action => this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_GET_BY_ID_RESPONSE}: ` + this.stringify(action)))),
                this.merchantService.getMerchantById({ merchant: store.merchantNo })
            ])), map(([store, merchant]) => {
                const storeRequestViewResponse = Object.assign({}, this.parseStoreCreateResponse(store), this.parseStoreRequestResponse(merchant));
                if (storeRequestViewResponse.step === RequestStepEnum.FIRST_LOT_ORDER &&
                    storeRequestViewResponse.status !== RequestStatusEnum.AWAITING_RESERVATION) {
                    return new StoreFirstLotOrderValidateSubmitRequestAction({
                        storeRequestTemplate: StoreRequestTemplate.mappingByStoreRequestViewResponse(storeRequestViewResponse),
                        validateStore: ValidateStoreEnum.LOAD
                    });
                }
                return new StoreRequestByIdResponseAction(storeRequestViewResponse);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreRequestVersionById$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_GET_VERSION_REQUEST), tap(action => this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_GET_VERSION_REQUEST}: ` + this.stringify(action.payload))), map(action => action.payload), switchMap(payload => {
            return this.storeRequestService.getStoreRequestVersionById(payload).pipe(tap(response => {
                this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_GET_VERSION_RESPONSE}: ` + this.stringify(response));
            }), map(version => {
                return new StoreGetVersionResponseAction(version);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.storeCreateSave$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeRequestService.saveRequest(payload).pipe(map(res => new StoreCreateSaveResponseAction(this.parseStoreCreateResponse(res))), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.storeCreateSubmit$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeRequestService.submitRequest(payload).pipe(map(res => new StoreCreateSubmitResponseAction(this.parseStoreCreateResponse(res))), catchError(error => of(new StoreCreateSubmitResponseError(error.error))));
        }));
        this.storeApprove$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.storeRequestService.approveRequest(payload).pipe(map(() => new StoreApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.firstLotOrderSubmit$ = this.actions$.pipe(ofType(StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_REQUEST}:` + this.stringify(action.payload));
            const validateOrderRequest = this.parseValidateOrderRequest(action.payload.storeRequestTemplate, action.payload.validateStore);
            return {
                validateOrderRequest,
                storeRequestTemplate: action.payload.storeRequestTemplate
            };
        }), switchMap(data => combineLatest([
            of(data),
            this.merchantService.getMerchantById({ merchant: data.storeRequestTemplate.merchantNo })
        ])), switchMap(([data, merchantViewResponse]) => this.storeRequestService.validateSubmitFirstLotOrder(data.validateOrderRequest).pipe(tap(response => {
            this.logger.debug(`@Effect ${StoreRequestActionType.VALIDATE_SUBMIT_FIRST_LOT_ORDER_RESPONSE}:` + this.stringify(response));
        }), map(response => {
            const storeRequestTemplate = data.storeRequestTemplate;
            const indexStoreFirstLot = 0; // We have only one firstLotOrder in store request.
            storeRequestTemplate.firstLotPo.storeFirstLot[indexStoreFirstLot] = response.storeFirstLot;
            if (response.action === ValidateStoreEnum.SUBMIT) {
                storeRequestTemplate.firstLotPo.submitSuccess = response.pass
                    ? SubmitSuccessEnum.SUCCESS
                    : SubmitSuccessEnum.FAILED;
            }
            return new StoreRequestByIdResponseAction(StoreRequestViewResponse.mappingByStoreRequestTemplate(storeRequestTemplate, {
                merchantInfo: merchantViewResponse.merchantInfo
            }));
        }), catchError(error => of(new LayoutActionLoadError(error))))));
        this.storeRequestHistory$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeRequestService.getHistoryLogs(payload).pipe(map(response => new StoreRequestHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.storeRequestDelete$ = this.actions$.pipe(ofType(StoreRequestActionType.STORE_REQUEST_DELETE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreRequestActionType.STORE_REQUEST_DELETE_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.storeRequestService.deleteByRequestId(payload).pipe(map(() => new StoreDeleteResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    parseValidateOrderRequest(merchantRequestView, validateStore) {
        return {
            id: merchantRequestView.id,
            action: validateStore,
            step: merchantRequestView.step,
            version: merchantRequestView.version,
            storeFirstLot: merchantRequestView.firstLotPo.storeFirstLot[0]
        };
    }
    parseStoreCreateResponse(store) {
        return {
            id: store.id,
            version: store.version,
            requestNo: store.requestNo,
            type: store.type,
            status: store.status,
            step: store.step,
            merchantNo: store.merchantNo,
            merchantInfo: {
                storeProfile: [Object.assign({}, store.storeProfile)]
            },
            orderSchedule: store.orderSchedule && {
                orderScheduleList: [Object.assign({}, store.orderSchedule)]
            },
            firstLotPo: store.firstLotPo
        };
    }
    parseStoreRequestResponse(merchant) {
        return {
            no: merchant.no,
            taxId: merchant.merchantInfo.merchantProfile.taxId,
            merchantName: merchant.merchantInfo.merchantProfile.merchantName,
            merchantType: merchant.merchantInfo.merchantProfile.merchantType,
            contactName: `${merchant.merchantInfo.ownerProfile.contactFirstName} ${merchant.merchantInfo.ownerProfile.contactLastName}`,
            mobilePhone: `${merchant.merchantInfo.ownerProfile.countryCode} ${merchant.merchantInfo.ownerProfile.mobilePhone}`
        };
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "searchStoreRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "getStoreRequestMerchantById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "getStoreRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "getStoreRequestVersionById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "storeCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "storeCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "storeApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "firstLotOrderSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "storeRequestHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreRequestEffects.prototype, "storeRequestDelete$", void 0);
