import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StoreList } from '../../../../shared/models';
import { MerchantService } from '../../../../shared/services/merchant.service';

@Component({
  selector: 'app-select-by-store',
  templateUrl: './select-by-store.component.html',
  styleUrls: ['./select-by-store.component.scss']
})
export class SelectByStoreComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;

  public submitted: boolean;
  public storeList: StoreList[];
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();
  public errorExport: string;
  public selectedStore = [];
  private readonly MAX_STORE = 100;
  public form: FormGroup;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly merchantService: MerchantService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      stores: []
    });

    this.initData();
  }

  initData() {
    const criteriaObj = {
      status: 'ACTIVE',
      sortBy: 'code',
      sortOrder: 'ASC',
      page: 0,
      size: 5000
    };

    this.merchantService
      .searchStoreByCriteria(criteriaObj)
      .pipe(
        filter(data => Boolean(data.content)),
        map(response => response.content)
      )
      .subscribe(data => {
        this.storeList = data.map(item => {
          item.storeName = `${item.code}-${item.name}`;
          return item;
        });

        if (this.formControlStore && this.formControlStore.length) {
          this.selectedStore = this.formControlStore.map(item => item.value.code);
          const selected = this.storeList.filter(item => this.selectedStore.includes(item.code));
          this.form.get('stores').patchValue(selected);
        }
      });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  createFormArray() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    return this.fb.group({
      code: initialNull,
      no: initialNull,
      name: initialNull,
      merchant: initialNull,
      warehouse: initialNull,
      merchantType: initialNull,
      region: initialNull,
      state: initialNull
    });
  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const formData = this.form.getRawValue();
    if (formData.stores && formData.stores.length > 0) {
      this.formStore.clear();
      formData.stores.forEach(store => {
        const itemPatched = { ...store };
        this.formStore.push(this.createFormArray());
        const controlItem = this.formControlStore[this.formControlStore.length - 1];
        controlItem.patchValue(itemPatched);
        this.formStore.controls.sort(this.sortList);
      });
    } else {
      this.formStore.clear();
    }
    this.closeModal();
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    const aStoreCode = a.value.code.toLowerCase();
    const bStoreCode = b.value.code.toLowerCase();
    if (aStoreCode < bStoreCode) {
      return -1;
    }
    if (aStoreCode > bStoreCode) {
      return 1;
    }
    return 0;
  }

  get formStore(): FormArray {
    return this.parentForm.get(this.controlName) as FormArray;
  }

  get formControlStore(): AbstractControl[] {
    return (this.parentForm.get(this.controlName) as FormArray).controls;
  }
}
