import { BaseReport } from './base-report.model';

export interface ReportItem {
  title: string;
  section: ReportSection;
  action: ActionReportEnum;
  permission: string[];
}

export enum ActionReportEnum {
  VOUCHER_REPORT = 'voucher',
  STOCK_CARD = 'stockcard',
  SALES_TRANSACTION = 'sales_transaction',
  PROMOTION_COMPENSATE = 'promotion_compensate',
  DELETED_BILL = 'deleted_bill',
  SALES_SUMMARY = 'sales_summary',
  TOTE_TRANSACTION = 'tote_transaction',
  TOTE_TRANSACTION_DC = 'tote_transaction_dc',
  TOTE_TRANSACTION_FROM_STORE = 'tote_transaction_from_store',
  TOTE_ASSET_LOCATION = 'tote_asset_location',
  INVENTORY_COUNT_TRANSACTION_TEMP = 'inventory_count_transaction_temp',
  MOVING_AVERAGE = 'moving_average',
  INVENTORY_COUNT_TRANSACTION = 'inventory_count_transaction',
  MONEY_IN = 'money_in',
  INVENTORY_ADJUSTMENT_REQUEST = 'inventory_adjustment_request',
  INVENTORY_VARIANCE = 'inventory_variance',
  DC_ASSORTMENT = 'dc_assortment',
  BILL_PAYMENT = 'bill_payment'
}

export enum ReportSection {
  TD_NEST = 'TD_NEST',
  TD_POS = 'TD_POS',
  TD_EYES = 'TD_EYES',
  PERFORMANCE = 'PERFORMANCE'
}

export class StockCardExportCriteria extends BaseReport {
  location: string;
  documentDateFrom?: string;
  documentDateTo?: string;
  segment: string;
  family?: string;
  classCode?: string;
  subClass?: string;

  constructor(init?: Partial<StockCardExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class VoucherExportCriteria extends BaseReport {
  receiptDateFrom?: string;
  receiptDateTo?: string;

  constructor(init?: Partial<VoucherExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SalesTransactionExportCriteria extends BaseReport {
  transactionDateFrom?: string;
  transactionDateTo?: string;
  store?: string;
  salesType?: string;

  constructor(init?: Partial<SalesTransactionExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DeletedBillReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;

  constructor(init?: Partial<DeletedBillReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class SalesSummaryReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;

  constructor(init?: Partial<SalesSummaryReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteTransactionReportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;

  constructor(init?: Partial<ToteTransactionReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteTransactionDCReportCriteria extends BaseReport {
  dispatchedDateFrom?: string;
  dispatchedDateTo?: string;

  constructor(init?: Partial<ToteTransactionDCReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteTransactionFromStoreReportCriteria extends BaseReport {
  receiveOrderDateFrom?: string;
  receiveOrderDateTo?: string;

  constructor(init?: Partial<ToteTransactionFromStoreReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class ToteAssetLocationReportCriteria extends BaseReport {
  toteCode?: string;

  constructor(init?: Partial<ToteAssetLocationReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryCountTransactionTempReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeNo?: string;

  constructor(init?: Partial<InventoryCountTransactionTempReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class PromotionCompensateReportExportCriteria extends BaseReport {
  receiptDateFrom?: string;
  receiptDateTo?: string;
  storeType?: string;
  supplierCode?: string;
  classes?: string;

  constructor(init?: Partial<PromotionCompensateReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class MovingAverageReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;

  constructor(init?: Partial<MovingAverageReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryCountTransactionReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeNo?: string;

  constructor(init?: Partial<InventoryCountTransactionReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryAdjustmentRequestReportExportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  storeType?: string;
  storeNo?: string;

  constructor(init?: Partial<InventoryAdjustmentRequestReportExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class MoneyInReportCriteria extends BaseReport {
  createdDateFrom?: string;
  createdDateTo?: string;
  store?: string[];

  constructor(init?: Partial<MoneyInReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class InventoryVarianceCriteria extends BaseReport {
  createdDate?: string;
  storeNo?: string[];

  constructor(init?: Partial<InventoryVarianceCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class DcAssortmentExportCriteria extends BaseReport {
  code?: string;

  constructor(init?: Partial<DcAssortmentExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export class BillPaymentReportCriteria extends BaseReport {
  saleDateFrom: string;
  saleDateTo: string;
  storeNo?: string;
  status?: string;

  constructor(init?: Partial<BillPaymentReportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
