import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GraphqlQueryObject, GraphqlQuerySortOptions, OrderByEnum } from '../../../shared/gql/common.gql';
import { AssortmentAllType, SearchLocationCriteria } from '../../../shared/models';
import { StockCardExportCriteria } from '../../../shared/models/report.model';
import { AuthGuardService, ProductAssortmentService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { StockInformationService } from '../../../shared/services/stock-information.service';
import { StockLocationsService } from '../../../shared/services/stock-locations.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateToStringCriteria } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-stock-card-modal',
  templateUrl: './stock-card-modal.component.html',
  styleUrls: ['./stock-card-modal.component.scss']
})
export class StockCardModalComponent extends BaseReportModalComponent implements OnInit, OnDestroy {
  @ViewChild('stockCardModal', { static: false }) stockCardModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public assortmentAllType = AssortmentAllType;
  public exportFormInValid: boolean;
  public listOfValue = {};
  public locationList$: Observable<NgOption[]>;
  public locationSearchInput$ = new Subject<string>();
  public locationSearchLoading: boolean;
  public responseExportError: string;

  public documentMaxDate: Date;
  public documentMinDate: Date;
  public listWarehouses: string[];

  private readonly types = [
    this.assortmentAllType.SEGMENT,
    this.assortmentAllType.FAMILY,
    this.assortmentAllType.CLASS,
    this.assortmentAllType.SUBCLASS
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly productAssortmentService: ProductAssortmentService,
    protected readonly translate: TranslateService,
    protected readonly stockLocationService: StockLocationsService,
    protected readonly stockInformationService: StockInformationService,
    protected readonly masterService: MasterService,
    protected readonly authGuardService: AuthGuardService
  ) {
    super();
  }

  ngOnInit() {
    this.loadSegmentList();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.documentMinDate = new Date(2019, 0, 1);
    this.documentMaxDate = new Date();
    this.documentMaxDate.setDate(this.documentMaxDate.getDate() + 365);
    this.initControl();
    this.getWareHouseList();
  }

  getWareHouseList() {
    const wareHouseQuery = new GraphqlQueryObject();

    wareHouseQuery.name = 'warehouses';
    wareHouseQuery.sort = { orderBy: OrderByEnum.CODE } as GraphqlQuerySortOptions;

    this.masterService
      .getMasterDataByNames([wareHouseQuery])
      .pipe(filter(res => Boolean(res.data.warehouses) && res.data.warehouses.length))
      .subscribe(res => {
        this.listWarehouses = res.data.warehouses.map(wh => wh.code);
        this.loadLocation('');
      });
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      location: [null, [Validators.required]],
      documentDateFrom: [null],
      documentDateTo: [null],
      segment: [null, [Validators.required]],
      family: [null],
      classCode: [null],
      subClass: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  loadSegmentList() {
    const productType = null;

    this.productAssortmentService
      .getSegmentListData(productType)
      .pipe(untilComponentDestroyed(this))
      .subscribe(data => {
        this.listOfValue[this.assortmentAllType.SEGMENT] = data.data.segments;
      });
  }

  onChangeSelectDropdown($event, assortmentType: AssortmentAllType) {
    if (assortmentType) {
      this.clearFormSelectDropdown(assortmentType);
      if (this.assortmentAllType.SEGMENT === assortmentType) {
        this.listOfValue[this.assortmentAllType.FAMILY] = $event.families;
      } else if (this.assortmentAllType.FAMILY === assortmentType) {
        this.listOfValue[this.assortmentAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentAllType.CLASS === assortmentType) {
        this.listOfValue[this.assortmentAllType.SUBCLASS] = $event.subClasses;
      }
    }
  }

  clearFormSelectDropdown(selectedType) {
    const associateLv = this.types.indexOf(selectedType) + 1;
    this.types
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        this.exportForm.controls[typeName].reset();
        this.listOfValue[typeName] = [];
      });
  }

  openExportModal() {
    this.clearFormSelectDropdown(this.assortmentAllType.SEGMENT);
    this.exportForm.reset();
    this.responseExportError = null;
    this.stockCardModal.show();
  }

  closeExportModal() {
    this.submitted = false;
    this.clearFormSelectDropdown(this.assortmentAllType.SEGMENT);
    this.exportForm.reset();
    this.stockCardModal.hide();
  }

  loadLocation(initialTerm: string) {
    this.locationList$ = concat(
      of([]),
      this.locationSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.locationSearchLoading = true)),
        switchMap(term => {
          const param = {
            searchCriteria: term,
            locationType: ['WAREHOUSE', 'STORE'],
            warehouseCode: [...this.listWarehouses]
          } as SearchLocationCriteria;

          return this.stockLocationService.location(param).pipe(
            map(res => (res ? res.content.map(item => ({ value: item.code, label: item.codeName })) : [])),
            catchError(() => of([])), // empty list on error
            tap(() => (this.locationSearchLoading = false))
          );
        })
      )
    );
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid && this.exportForm.valid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();

    const stockCardExportCriteria = new StockCardExportCriteria({
      location: rawData.location,
      segment: rawData.segment,
      family: rawData.family,
      classCode: rawData.classCode,
      subClass: rawData.subClass,
      documentDateFrom: dateToStringCriteria(rawData.documentDateFrom, true),
      documentDateTo: dateToStringCriteria(rawData.documentDateTo, false),
      exportBy: 'card'
    });

    this.stockInformationService.exportStock<StockCardExportCriteria>(stockCardExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportStock.prefixCard} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.clearFormSelectDropdown(this.assortmentAllType.SEGMENT);
        this.closeExportModal();
      }
    );
  }

  onChangeExportPeriodDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.exportFormInValid = false;
      this.documentMinDate = new Date(value);
    } else {
      this.documentMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportPeriodDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.exportFormInValid = false;
      this.documentMaxDate = new Date(value);
    } else {
      this.documentMaxDate = new Date();
      this.documentMaxDate.setDate(this.documentMaxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportStock.timeFormat);
  }

  ngOnDestroy(): void {}
}
