import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators';
import { SearchBarcodeModalComponent } from '../../../../shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchProductModalComponent } from '../../../../shared/components/search-product-modal/search-product-modal.component';
import { MasterDataEnum } from '../../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { PromotionItemTypeEnum } from '../../../../shared/enum/promotion.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  PromotionByEnum,
  PromotionItem,
  PromotionItemErrorEnum,
  PromotionRequestPageModes,
  PromotionRequestResponse,
  PromotionTransFrom,
  PromotionTypeEnum
} from '../../../../shared/models';
import { MasterService } from '../../../../shared/services/master.service';
import {
  selectCoPromotionItems,
  selectPromotionItems,
  selectRequestPromotionData
} from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-promotion-item',
  templateUrl: './promotion-item.component.html',
  styleUrls: ['./promotion-item.component.scss']
})
export class PromotionItemComponent implements OnInit, OnDestroy {
  @Input() saveDraft: boolean;
  @Input() parentForm: FormGroup;
  @Input() promotionItemType: PromotionItemTypeEnum;
  @Input() submitted: boolean;
  @Input() mode: PromotionRequestPageModes;
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @ViewChild('searchBarcodeModal', { static: false }) searchBarcodeModal: SearchBarcodeModalComponent;

  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  promotionItems$: Observable<PromotionItem[]>;
  headerRow: string[];
  promotionConRetailPrice: string;
  promotionType: PromotionTypeEnum;
  promotionBySubscription: Subscription;
  promotionConRetailPriceSubscription: Subscription;
  promotionTypeSubscription: Subscription;
  promotionRequestView$: Observable<PromotionRequestResponse>;
  discountCode: any[];
  showApplyAll: boolean;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    private readonly masterService: MasterService
  ) {
    this.showApplyAll = true;
  }

  ngOnInit() {
    this.headerRow = [
      'No.',
      'Barcode',
      'Product Name',
      'Unit',
      'Unit Factor',
      'Moving Avg',
      'Retail Price',
      'Discount Code',
      'Compensate (Supplier)',
      'Compensate (Partner)',
      'Action'
    ];
    this.initState();
    this.initialData();
  }

  initState() {
    this.store.pipe(untilComponentDestroyed(this));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.DISCOUNTCODE])
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data && result.data.discountcodes) {
          this.discountCode = result.data.discountcodes;
        }
      });

    const setSelectPromotionItems =
      this.promotionItemType !== PromotionItemTypeEnum.PROMOTION ? selectCoPromotionItems : selectPromotionItems;
    this.promotionItems$ = this.store.pipe(select(setSelectPromotionItems));
  }

  get details(): FormGroup {
    return this.parentForm.get('promotionDetails') as FormGroup;
  }

  get condition(): FormGroup {
    return this.parentForm.get('promotionCondition') as FormGroup;
  }

  initialData() {
    this.promotionType = this.details.controls['promotionType'].value;
    this.promotionConRetailPrice = this.condition.controls['selectedRetailPrice'].value;
    this.promotionBySubscription = this.details.controls['promotionBy'].valueChanges
      .pipe(
        filter(v => v !== null),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.onPromotionByChange();
      });
    this.promotionConRetailPriceSubscription = this.condition.controls['selectedRetailPrice'].valueChanges
      .pipe(debounceTime(300), filter(Boolean))
      .subscribe(() => {
        this.onPromotionRetailPriceChange();
      });

    this.promotionTypeSubscription = this.details.controls['promotionType'].valueChanges
      .pipe(
        filter(value => value !== null),
        distinctUntilChanged()
      )
      .subscribe(value => {
        if (value) {
          this.onPromotionTypeChange(value);
        }
      });

    if (this.canEditView) {
      this.setPromotionItemValue();
    }
  }

  onPromotionByChange() {
    this.formPromotionItem.controls.forEach(fieldValue => {
      fieldValue.get('supplierCompensate').setValue(null);
    });
    this.canEditPromotionItem(this.canEditWithPromotionBy);
  }

  onPromotionRetailPriceChange() {
    for (let i = 0; i < this.formPromotionItem.controls.length; i++) {
      this.formControlPromotionItem[i].get('retailPrice').updateValueAndValidity({ onlySelf: false });
    }
  }

  onPromotionTypeChange(promotionType) {
    const countForm = this.formPromotionItem.controls.length;
    if (
      promotionType !== PromotionTypeEnum.BUY_TWO_SAVE_MORE &&
      this.promotionItemType === PromotionItemTypeEnum.CO_PROMOTION &&
      countForm &&
      this.canEdit
    ) {
      const initialState = {
        title: 'Confirm',
        message: 'Are you sure you want to change "Promotion Type" because all data will be lost?'
      };
      const alertModal = this.modalService.show(ConfirmModalComponent, { initialState, backdrop: 'static' });

      alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
        switch (result) {
          case ModalButtonResponseEnum.OK:
            this.purgeForm(this.formPromotionItem);
            this.details.controls['promotionType'].setValue(promotionType);
            break;
          case ModalButtonResponseEnum.CANCEL:
            this.details.controls['promotionType'].patchValue(PromotionTypeEnum.BUY_TWO_SAVE_MORE);
            break;
          default:
            break;
        }
        alertModal.hide();
      });
    }

    if (countForm && this.canEdit) {
      this.onPromotionRetailPriceChange();
    }
  }

  purgeForm(form: FormArray) {
    while (0 !== form.length) {
      form.removeAt(0);
    }
  }

  createForm() {
    const initialNull = [{ initialValue: { value: null, disabled: false } }];
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];

    return this.fb.group({
      barcode: initialNull,
      articleNo: initialNull,
      productName: initialNull,
      unit: initialNull,
      unitFactor: initialNull,
      movingAverage: initialNull,
      retailPrice: [
        { value: null, disabled: false },
        {
          validators: [this.isDifferentPriceValidator()],
          updateOn: 'blur'
        }
      ],
      discountCode: initialNullRequired,
      supplierCompensate: initialNullRequired,
      partnerCompensate: initialNullRequired
    });
  }

  get formPromotionItem(): FormArray {
    return this.parentForm.get(this.promotionItemType) as FormArray;
  }

  get formControlPromotionItem(): AbstractControl[] {
    return (this.parentForm.get(this.promotionItemType) as FormArray).controls;
  }

  onAddPromotionItem(promotionItems: any[]) {
    if (!promotionItems) {
      return;
    }

    promotionItems.forEach(promotionItem => {
      if (this.formControlPromotionItem.some(x => x.get('barcode').value === promotionItem.barcode)) {
        this.showAlert('Failed', 'Not allow to add duplicated barcode.');
        return;
      }
      const itemPatched = this.createItemValue({ ...promotionItem });
      this.formPromotionItem.push(this.createForm());
      const controlItem = this.formControlPromotionItem[this.formControlPromotionItem.length - 1];
      controlItem.patchValue(itemPatched);

      if (promotionItem.errorMessage) {
        this.ApplyPromotionItemError(controlItem, promotionItem.errorMessage);
        controlItem.get('barcode').updateValueAndValidity();
        controlItem.get('productName').updateValueAndValidity();
      }

      this.formPromotionItem.updateValueAndValidity({ onlySelf: false });
    });

    this.canEditPromotionItem(this.canEditWithPromotionBy);
  }

  onAddPromotionBarCode(promotionItems: any[]) {
    if (!promotionItems) {
      return;
    }

    promotionItems.forEach(promotionItem => {
      if (promotionItem.barcode && promotionItem.barcode !== '') {
        const itemPatched = this.createItemValue({ ...promotionItem });
        this.formPromotionItem.push(this.createForm());
        const controlItem = this.formControlPromotionItem[this.formControlPromotionItem.length - 1];
        controlItem.patchValue(itemPatched);

        if (promotionItem.errorMessage) {
          this.ApplyPromotionItemError(controlItem, promotionItem.errorMessage);
          controlItem.get('barcode').updateValueAndValidity();
          controlItem.get('productName').updateValueAndValidity();
        }

        this.formPromotionItem.updateValueAndValidity({ onlySelf: false });
      }
    });

    this.canEditPromotionItem(this.canEditWithPromotionBy);
  }

  canEditPromotionItem(canEdit: boolean) {
    this.showApplyAll = canEdit;

    if (canEdit) {
      this.formControlPromotionItem.forEach(fieldValue => {
        fieldValue.get('supplierCompensate').disable();
      });
    } else {
      this.formPromotionItem.controls.forEach(fieldValue => {
        fieldValue.get('supplierCompensate').enable();
      });
    }
  }

  createItemValue(promotionItems: PromotionItem): any {
    const discountCodeValue = promotionItems.discountCode || null;
    return {
      ...promotionItems,
      discountCode: discountCodeValue,
      movingAverage: PromotionTransFrom.getAmount(promotionItems.movingAverage),
      retailPrice: PromotionTransFrom.getAmount(promotionItems.retailPrice),
      partnerCompensate: PromotionTransFrom.getAmount(promotionItems.partnerCompensate),
      supplierCompensate: PromotionTransFrom.getAmount(promotionItems.supplierCompensate)
    };
  }

  setPromotionItemValue() {
    this.formPromotionItem.disable();
    this.promotionRequestView$ = this.store.pipe(
      select(selectRequestPromotionData),
      filter(data => data !== null)
    );

    this.promotionRequestView$
      .pipe(
        filter(value => Boolean(value)),
        take(1)
      )
      .subscribe(value => {
        let getPromotionItems = [];

        if (this.promotionItemType === PromotionItemTypeEnum.PROMOTION) {
          getPromotionItems = value.promotionItems;
        } else {
          getPromotionItems = value.coPromotionItems;
        }
        if (getPromotionItems && getPromotionItems.length) {
          getPromotionItems.forEach(promotionItem => {
            this.formPromotionItem.push(this.createForm());
            const itemPatched = this.createItemValue(promotionItem);

            const controlItem = this.formControlPromotionItem[this.formControlPromotionItem.length - 1];
            controlItem.patchValue(itemPatched);

            if (promotionItem.errorMessage) {
              this.ApplyPromotionItemError(controlItem, promotionItem.errorMessage);
              this.formPromotionItem.updateValueAndValidity();
            }
            if (this.canEdit) {
              this.toggleEditPromotionItems();
            } else {
              controlItem.disable();
            }
          });
        }

        this.formPromotionItem.updateValueAndValidity({ onlySelf: false });
      });
  }

  ApplyPromotionItemError(controlItem: AbstractControl, errorMessage) {
    switch (errorMessage) {
      case PromotionItemErrorEnum.INVALID_BARCODE:
      case PromotionItemErrorEnum.INACTIVE_BARCODE:
        controlItem.setValidators(this.setValidator(errorMessage));
        controlItem.get('barcode').setValidators(this.setValidator(errorMessage));
        break;
      case PromotionItemErrorEnum.RESTRICT_ITEM:
      case PromotionItemErrorEnum.STATUS_IS_DELISTED:
      case PromotionItemErrorEnum.NOT_ALLOW_FIX_ASSET:
      case PromotionItemErrorEnum.NOT_ALLOW_INVENTORY:
      case PromotionItemErrorEnum.NOT_ALLOW_STORE_USE:
        controlItem.setValidators(this.setValidator(errorMessage));
        controlItem.get('productName').setValidators(this.setValidator(errorMessage));
        break;
      default:
        break;
    }
  }

  ApplyToAll(field: string, i: number) {
    let value: any;
    switch (field) {
      case 'discountCode':
        value = this.formControlPromotionItem[i].get(field).value;
        break;
      case 'supplierCompensate':
      case 'partnerCompensate':
        value = this.formControlPromotionItem[i].get(field).value;
        break;
      default:
        value = '';
    }

    this.formPromotionItem.controls.forEach(fieldValue => {
      fieldValue.get(field).setValue(value);
    });
  }

  addNewProductItem() {
    this.searchProductModal.openSelectProductModal();
  }

  addNewBarcode() {
    this.searchBarcodeModal.selectBarcodeModal.show();
  }

  deletePromotionItem(id: any) {
    this.formPromotionItem.removeAt(id);
    for (let i = 0; i < this.formPromotionItem.controls.length; i++) {
      this.formControlPromotionItem[i].get('barcode').updateValueAndValidity({ onlySelf: true });
    }
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onChangeDiscountCode() {}

  validatorFormControls(field: string, i: number) {
    return this.formControlPromotionItem[i].get(field).errors;
  }

  toggleEditPromotionItems() {
    const promotionBy = this.details.controls['promotionBy'].value;
    this.formPromotionItem.enable();
    for (let i = 0; i < this.formPromotionItem.controls.length; i++) {
      if (promotionBy === PromotionByEnum.TD) {
        this.showApplyAll = true;
        this.formControlPromotionItem[i].get('supplierCompensate').disable();
      } else {
        this.showApplyAll = false;
        this.formControlPromotionItem[i].get('supplierCompensate').enable();
      }
    }
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode);
  }

  get canEditView(): boolean {
    return [
      PromotionRequestPageModes.REQUEST_VIEW,
      PromotionRequestPageModes.REQUEST_EDIT,
      PromotionRequestPageModes.VIEW
    ].includes(this.mode);
  }

  get canEditWithPromotionBy(): boolean {
    return (
      [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(this.mode) &&
      this.details.controls['promotionBy'].value === PromotionByEnum.TD
    );
  }

  get promotionRequestPageMode() {
    return PromotionRequestPageModes;
  }

  isDifferentPriceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const selectedRetailPrice = this.condition.controls['selectedRetailPrice'].value;

      const promotionBy = this.details.controls['promotionType'].value;
      if (
        control.value !== null &&
        (promotionBy === PromotionTypeEnum.BUY_GET_ONE_FREE ||
          promotionBy === PromotionTypeEnum.BUY_MORE_GET_ONE_FREE)
      ) {
        return control.value !== selectedRetailPrice ? { isDifferentPrice: true } : null;
      }
      return null;
    };
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case PromotionItemErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case PromotionItemErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case PromotionItemErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case PromotionItemErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case PromotionItemErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case PromotionItemErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case PromotionItemErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }
          return null;
        default:
          return null;
      }
    };
  }

  get isInactiveValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isInactive: true };
    };
  }

  get isInvalidValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { invalidBarcode: true };
    };
  }

  get isRestrictItemValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isRestrictItem: true };
    };
  }

  get isStatusDelistedValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isStatusDelisted: true };
    };
  }

  ngOnDestroy(): void {
    if (this.promotionTypeSubscription) {
      this.promotionTypeSubscription.unsubscribe();
    }
    if (this.promotionConRetailPriceSubscription) {
      this.promotionConRetailPriceSubscription.unsubscribe();
    }
    if (this.promotionBySubscription) {
      this.promotionBySubscription.unsubscribe();
    }
  }
}
