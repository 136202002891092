import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { GenerateMemberCard, MemberCardPagination, MemberCardSearchCriteria } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class MembershipService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, protected readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.membership;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public generateMemberCard(data: GenerateMemberCard): Observable<any> {
    const url = this.getFullUrl(this.envService.memberCard);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public searchMemberCardCriteria(criteria: MemberCardSearchCriteria): Observable<MemberCardPagination> {
    const url = this.getFullUrl(this.envService.memberCard);
    const params = this.getParams(criteria, true);

    return this.http.get<MemberCardPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportMemberCard(documentNo: string) {
    const params = this.getParams({ docNo: documentNo });
    const url = this.getFullUrl(this.envService.memberCardExport);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
