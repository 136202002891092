import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfService } from '../../services/shelf.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfActionType, ShelfByIdResponseAction, ShelfListHistoryResponseAction, ShelfListResponseAction, SubmitShelfFirstLotErrorAction, SubmitShelfFirstLotResponseAction } from '../actions/shelf.actions';
export class ShelfEffects {
    constructor(actions$, shelfService, store$, logger) {
        this.actions$ = actions$;
        this.shelfService = shelfService;
        this.store$ = store$;
        this.logger = logger;
        this.searchShelf$ = this.actions$.pipe(ofType(ShelfActionType.SHELF_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfActionType.SHELF_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfService.searchByCriteria(payload).pipe(map(response => {
                return new ShelfListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfHistory$ = this.actions$.pipe(ofType(ShelfActionType.SHELF_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfActionType.SHELF_LIST_HISTORY_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfService.getHistoryLogs(payload).pipe(map(response => new ShelfListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getMerchantById$ = this.actions$.pipe(ofType(ShelfActionType.SHELF_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfActionType.SHELF_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfService.getShelfById(payload).pipe(map(res => {
                return new ShelfByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.submitFirstLot$ = this.actions$.pipe(ofType(ShelfActionType.SUBMIT_SHELF_FIRST_LOT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfActionType.SUBMIT_SHELF_FIRST_LOT_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfService.submitFirstLot(payload).pipe(map(res => new SubmitShelfFirstLotResponseAction(res)), catchError(error => of(new SubmitShelfFirstLotErrorAction(error.error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfEffects.prototype, "searchShelf$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfEffects.prototype, "shelfHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfEffects.prototype, "getMerchantById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfEffects.prototype, "submitFirstLot$", void 0);
