import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SupplierGroupRequestService } from '../../services/supplier-group-request.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  SupplierGroupRequestActionTypes,
  SupplierGroupRequestApproveRequest,
  SupplierGroupRequestCancelRequest,
  SupplierGroupRequestCheckExistingRequest,
  SupplierGroupRequestCheckExistingResponse,
  SupplierGroupRequestDeleteRequest,
  SupplierGroupRequestListCheckExistingRequest,
  SupplierGroupRequestListCheckExistingResponse,
  SupplierGroupRequestListRequest,
  SupplierGroupRequestListResponse,
  SupplierGroupRequestRejectRequest,
  SupplierGroupRequestSaveRequest,
  SupplierGroupRequestSubmitErrorResponse,
  SupplierGroupRequestSubmitRequest,
  SupplierGroupRequestViewLoaded,
  SupplierGroupRequestViewRequest
} from '../actions/supplier-group-request.actions';

@Injectable()
export class SupplierGroupRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supplierGroupRequestService: SupplierGroupRequestService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchSupplierGroup$ = this.actions$.pipe(
    ofType<SupplierGroupRequestListRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_REQUEST),
    tap(action =>
      this.logger.debug(`@Effect Supplier Group Request List Requested:` + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.searchByCriteria(action.payload).pipe(
        map(response => new SupplierGroupRequestListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  loadSupplierGroupRequest$ = this.actions$.pipe(
    ofType<SupplierGroupRequestViewRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_VIEW_REQUESTED),
    tap(action =>
      this.logger.debug('@Effect Supplier Group Request Get Requested: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.getSupplierGroupRequestById(action.payload).pipe(
        map(result => new SupplierGroupRequestViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  saveSupplierGroupRequest$ = this.actions$.pipe(
    ofType<SupplierGroupRequestSaveRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SAVE_REQUEST),
    tap(action => this.logger.debug('@Effect Supplier Group Save Request: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.save(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(err => this.errorHandling(err))
      )
    )
  );

  @Effect()
  updateSupplierGroupRequest$ = this.actions$.pipe(
    ofType<SupplierGroupRequestSaveRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_UPDATE_REQUEST),
    tap(action => this.logger.debug('@Effect Supplier Group Update Request: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.update(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(err => this.errorHandling(err))
      )
    )
  );

  @Effect()
  submitSupplierGroupRequest$ = this.actions$.pipe(
    ofType<SupplierGroupRequestSubmitRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SUBMIT_REQUEST),
    tap(action =>
      this.logger.debug('@Effect Supplier Group Request Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been sent to approver.'
            })
        ),
        catchError(err => this.submitErrorHandling(err))
      )
    )
  );

  @Effect()
  submitSupplierGroupEditRequest$ = this.actions$.pipe(
    ofType<SupplierGroupRequestSubmitRequest>(
      SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_EDIT_SUBMIT_REQUEST
    ),
    tap(action =>
      this.logger.debug('@Effect Supplier Group Request Edit Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been sent to approver.',
              routerLink: '/supplier/supplier-group-request-list'
            })
        ),
        catchError(err => this.submitErrorHandling(err))
      )
    )
  );

  @Effect()
  approve$ = this.actions$.pipe(
    ofType<SupplierGroupRequestApproveRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_APPROVE),
    tap(action => this.logger.debug('@Effect Supplier Group Request Approve: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(err => this.errorHandling(err))
      )
    )
  );

  @Effect()
  reject$ = this.actions$.pipe(
    ofType<SupplierGroupRequestRejectRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_REJECT),
    tap(action => this.logger.debug('@Effect Supplier Group Request Reject: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(err => this.errorHandling(err))
      )
    )
  );

  @Effect()
  cancel$ = this.actions$.pipe(
    ofType<SupplierGroupRequestCancelRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CANCEL),
    tap(action => this.logger.debug('@Effect Supplier Group Request Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(err => this.errorHandling(err))
      )
    )
  );

  @Effect()
  delete$ = this.actions$.pipe(
    ofType<SupplierGroupRequestDeleteRequest>(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_DELETE),
    tap(action => this.logger.debug('@Effect Supplier Group Request Delete: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupRequestService.delete(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00001'
            ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
            : err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  checkExisting$ = this.actions$.pipe(
    ofType<SupplierGroupRequestCheckExistingRequest>(
      SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_REQUEST
    ),
    tap(action =>
      this.logger.debug('@Effect Supplier Group Request Check Existing: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.checkExisting(action.payload).pipe(
        map(() => new SupplierGroupRequestCheckExistingResponse(true)),
        catchError(() => {
          return of(new SupplierGroupRequestCheckExistingResponse(false));
        })
      )
    )
  );

  @Effect()
  checkExistingForList$ = this.actions$.pipe(
    ofType<SupplierGroupRequestListCheckExistingRequest>(
      SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_REQUEST
    ),
    tap(action =>
      this.logger.debug('@Effect Supplier Group Request List Check Existing: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierGroupRequestService.checkExisting(action.payload).pipe(
        map(() => new SupplierGroupRequestListCheckExistingResponse(true)),
        catchError(_err => {
          return of(new SupplierGroupRequestListCheckExistingResponse(false));
        })
      )
    )
  );

  errorHandling(err: any) {
    return err.error && err.error.code === '00001'
      ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
      : of(new LayoutActionLoadError(err));
  }

  submitErrorHandling(err: any) {
    return err.error && ['00001', '08002', '08003'].includes(err.error.code)
      ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
      : of(new LayoutActionLoadError(err));
  }
}
