import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClassMarkupService } from '../../services/class-markup.service';
import { ClassMarkupActionTypes, ClassMarkupLoaded, ClassMarkupSearchLoaded, ClassMarkupUpdateSuccess } from '../actions/class-markup.actions';
import { LayoutActionLoadError } from '../actions/layout.action';
export class ClassMarkupEffects {
    constructor(actions$, classMarkupService, logger) {
        this.actions$ = actions$;
        this.classMarkupService = classMarkupService;
        this.logger = logger;
        this.searchClassMarkup$ = this.actions$.pipe(ofType(ClassMarkupActionTypes.ClassMarkupSearchRequested), tap(action => this.logger.debug('@Effect Class Markup Search: ' + this.stringify(action.payload))), switchMap(action => {
            return this.classMarkupService.searchByCriteria(action.payload).pipe(map(classMarkup => new ClassMarkupSearchLoaded({ classMarkup })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.updateClassMarkup$ = this.actions$.pipe(ofType(ClassMarkupActionTypes.ClassMarkupUpdateRequested), tap(action => this.logger.debug('@Effect Class Markup Update Request: ' + this.stringify(action.payload.classMarkup))), switchMap(action => {
            return this.classMarkupService.updateById(action.payload.classMarkup).pipe(map(response => new ClassMarkupUpdateSuccess({
                id: response.id,
                classMarkup: response
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getClassMarkup$ = this.actions$.pipe(ofType(ClassMarkupActionTypes.ClassMarkupRequested), tap(action => this.logger.debug('@Effect Class Markup Request By ID: ' + this.stringify(action.payload))), switchMap(action => {
            return this.classMarkupService.getById(action.payload).pipe(map(response => new ClassMarkupLoaded({ classMarkup: response })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ClassMarkupEffects.prototype, "searchClassMarkup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ClassMarkupEffects.prototype, "updateClassMarkup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ClassMarkupEffects.prototype, "getClassMarkup$", void 0);
