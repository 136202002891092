import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OrderContent, OrderSearchCriteria } from '../../models/order.model';
import { OrderActions, OrderActionTypes } from '../actions/order.actions';

export interface OrderState extends EntityState<OrderContent> {
  isLoading: boolean;
  viewOrder: OrderContent;
  criteriaObject: OrderSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<OrderContent> = createEntityAdapter<OrderContent>();

export const initialOrderState: OrderState = adapter.getInitialState({
  isLoading: false,
  viewOrder: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20
});

export function orderReducers(state = initialOrderState, action: OrderActions): OrderState {
  switch (action.type) {
    case OrderActionTypes.OrderListSearchLoaded:
      return adapter.addAll(action.payload.orders.content, {
        ...state,
        page: action.payload.orders.page,
        size: action.payload.orders.size,
        totalElements: action.payload.orders.totalElements,
        totalPages: action.payload.orders.totalPages
      });
    case OrderActionTypes.OrderViewLoaded:
      return {
        ...state,
        viewOrder: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
