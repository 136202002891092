import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { AssortmentPageModes } from '../../../shared/models';
import { convertUtcToBkkWithZ } from '../../../shared/utils/date-util';

@Component({
  selector: '' + 'app-assortment-wholesale-price',
  templateUrl: './assortment-wholesale-price.component.html',
  styleUrls: ['./assortment-wholesale-price.component.scss']
})
export class AssortmentWholesalePriceComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() mode: AssortmentPageModes;
  @Input() isRequestViewMode: boolean;

  bsDateConfig: BsDatepickerConfig;
  minDate: Date;
  listOfEffectiveDateValue = [];
  dateFormat = environment.dateFormat;
  constructor() {}

  ngOnInit() {
    this.minDate = new Date(2019, 0, 1);
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: this.dateFormat,
      showWeekNumbers: false,
      adaptivePosition: true,
      isAnimated: true
    } as BsDatepickerConfig;
    this.generateEffectiveDate();
  }

  generateEffectiveDate() {
    const selectedEffectiveDate =
      (this.getWholesalePriceForm as FormArray).at(1) &&
      (this.getWholesalePriceForm as FormArray).at(1).get('effectiveDate')
        ? (this.getWholesalePriceForm as FormArray).at(1).get('effectiveDate').value
        : null;

    for (let i = 1; i <= 12; i++) {
      const effectiveDateObject = { value: null, label: null };
      effectiveDateObject.value = convertUtcToBkkWithZ(
        moment(new Date())
          .add(i, 'month')
          .startOf('month')
          .toISOString()
      );
      effectiveDateObject.label = moment(new Date())
        .add(i, 'month')
        .startOf('month')
        .format('MMMM DD, YYYY');
      this.listOfEffectiveDateValue.push(effectiveDateObject);
    }

    if (selectedEffectiveDate) {
      const effectiveDate = { value: null, label: null };
      effectiveDate.value = selectedEffectiveDate;
      effectiveDate.label = moment(new Date(selectedEffectiveDate)).format('MMMM DD, YYYY');
      const isSelectedDateInList = this.listOfEffectiveDateValue.some(value => {
        return value.value === effectiveDate.value;
      });

      if (!isSelectedDateInList) {
        this.listOfEffectiveDateValue.unshift(effectiveDate);
        this.listOfEffectiveDateValue.splice(this.listOfEffectiveDateValue.length - 1, 1);
      }
    }
  }

  get getWholesalePriceForm(): any {
    return this.parentForm.get('wholesalePrices') as FormArray;
  }
}
