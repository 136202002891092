import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  SupplierGroupRequest,
  SupplierGroupRequestApproveRejectRequest,
  SupplierGroupRequestObject,
  SupplierGroupRequestPagination,
  SupplierGroupRequestSearchCriteria
} from '../../models/supplier-group-request.model';

export enum SupplierGroupRequestActionTypes {
  SUPPLIER_GROUP_REQUEST_VIEW_REQUESTED = '[Supplier Group Request View Page] Supplier Group Request View Request',
  SUPPLIER_GROUP_REQUEST_VIEW_LOADED = '[Supplier Group Request View Page] Supplier Group Request View Loaded',
  SUPPLIER_GROUP_REQUEST_LIST_REQUEST = '[Supplier Group Request List Page] Supplier Group Request List Request',
  SUPPLIER_GROUP_REQUEST_LIST_RESPONSE = '[Supplier Group Request List API] Supplier Group Request List Response',
  SUPPLIER_GROUP_REQUEST_SAVE_REQUEST = '[Supplier Group Request View Page] Supplier Group Request Save Request',
  SUPPLIER_GROUP_REQUEST_UPDATE_REQUEST = '[Supplier Group Request View Page] Supplier Group Request Update Request',
  SUPPLIER_GROUP_REQUEST_SUBMIT_REQUEST = '[Supplier Group Request View Page] Supplier Group Request Submit Request',
  SUPPLIER_GROUP_REQUEST_EDIT_SUBMIT_REQUEST = '[Supplier Group Request View Page] Supplier Group Request Edit Submit Request',
  SUPPLIER_GROUP_REQUEST_SUBMIT_ERROR_RESPONSE = '[Supplier Group Request View Page] Supplier Group Request Submit Error Response',
  SUPPLIER_GROUP_REQUEST_RESET = '[Supplier Group Request View Page] Supplier Group Request Reset',
  SUPPLIER_GROUP_REQUEST_APPROVE = '[Supplier Group Request View Page] Supplier Group Request Approve',
  SUPPLIER_GROUP_REQUEST_REJECT = '[Supplier Group Request View Page] Supplier Group Request Reject',
  SUPPLIER_GROUP_REQUEST_DELETE = '[Supplier Group Request View Page] Supplier Group Request Delete',
  SUPPLIER_GROUP_REQUEST_CANCEL = '[Supplier Group Request View Page] Supplier Group Request Cancel',
  SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_REQUEST = '[Supplier Group Request View Page] Supplier Group Request Check Existing Request',
  SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_RESPONSE = '[Supplier Group Request View Page] Supplier Group Request Check Existing Response',
  SUPPLIER_GROUP_REQUEST_EXISTING_RESET = '[Supplier Group Request View Page] Supplier Group Request Existing Reset',
  SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_REQUEST = '[Supplier Group Request List Page] Supplier Group Request Check Existing Request',
  SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_RESPONSE = '[Supplier Group Request List Page] Supplier Group Request Check Existing Response',
  SUPPLIER_GROUP_REQUEST_LIST_EXISTING_RESET = '[Supplier Group Request List Page] Supplier Group Request Existing Reset'
}

export class SupplierGroupRequestViewRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class SupplierGroupRequestViewLoaded implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_VIEW_LOADED;

  constructor(public payload: SupplierGroupRequest) {}
}

export class SupplierGroupRequestListRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_REQUEST;
  constructor(public payload: SupplierGroupRequestSearchCriteria) {}
}

export class SupplierGroupRequestListResponse implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_RESPONSE;

  constructor(public payload: SupplierGroupRequestPagination) {}
}

export class SupplierGroupRequestSaveRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SAVE_REQUEST;
  constructor(public payload: SupplierGroupRequestObject) {}
}

export class SupplierGroupRequestApproveRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_APPROVE;
  constructor(public payload: SupplierGroupRequestApproveRejectRequest) {}
}

export class SupplierGroupRequestRejectRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_REJECT;
  constructor(public payload: SupplierGroupRequestApproveRejectRequest) {}
}

export class SupplierGroupRequestDeleteRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_DELETE;
  constructor(public payload: string) {}
}

export class SupplierGroupRequestCancelRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CANCEL;
  constructor(public payload: SupplierGroupRequestApproveRejectRequest) {}
}

export class SupplierGroupRequestUpdateRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_UPDATE_REQUEST;
  constructor(public payload: SupplierGroupRequestObject) {}
}

export class SupplierGroupRequestSubmitRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SUBMIT_REQUEST;
  constructor(public payload: SupplierGroupRequestObject) {}
}

export class SupplierGroupRequestEditSubmitRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_EDIT_SUBMIT_REQUEST;
  constructor(public payload: SupplierGroupRequestObject) {}
}

export class SupplierGroupRequestSubmitErrorResponse implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SUBMIT_ERROR_RESPONSE;

  constructor(public payload: ErrorResponse) {}
}

export class SupplierGroupRequestReset implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_RESET;
  constructor() {}
}

export class SupplierGroupRequestCheckExistingRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_REQUEST;
  constructor(public payload: string) {}
}

export class SupplierGroupRequestCheckExistingResponse implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_RESPONSE;

  constructor(public payload: boolean) {}
}

export class SupplierGroupRequestExistingReset implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_EXISTING_RESET;
  constructor() {}
}

export class SupplierGroupRequestListCheckExistingRequest implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_REQUEST;
  constructor(public payload: string) {}
}

export class SupplierGroupRequestListCheckExistingResponse implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_RESPONSE;

  constructor(public payload: boolean) {}
}

export class SupplierGroupRequestListExistingReset implements Action {
  readonly type = SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_EXISTING_RESET;
  constructor() {}
}

export type SupplierGroupRequestActions =
  | SupplierGroupRequestViewRequest
  | SupplierGroupRequestViewLoaded
  | SupplierGroupRequestListRequest
  | SupplierGroupRequestSaveRequest
  | SupplierGroupRequestUpdateRequest
  | SupplierGroupRequestSubmitRequest
  | SupplierGroupRequestEditSubmitRequest
  | SupplierGroupRequestSubmitErrorResponse
  | SupplierGroupRequestReset
  | SupplierGroupRequestExistingReset
  | SupplierGroupRequestCheckExistingRequest
  | SupplierGroupRequestCheckExistingResponse
  | SupplierGroupRequestListCheckExistingRequest
  | SupplierGroupRequestListCheckExistingResponse
  | SupplierGroupRequestListExistingReset
  | SupplierGroupRequestDeleteRequest
  | SupplierGroupRequestCancelRequest
  | SupplierGroupRequestListResponse;
