import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/base.environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { BaseReport } from '../../../shared/models';
import { ToteAssetLocationReportCriteria } from '../../../shared/models/report.model';
import { MasterService } from '../../../shared/services/master.service';
import { ToteAssetLocationService } from '../../../shared/services/tote-asset-location.service';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-tote-asset-location-modal',
  templateUrl: './tote-asset-location-modal.component.html',
  styleUrls: ['./tote-asset-location-modal.component.scss']
})
export class ToteAssetLocationModalComponent extends BaseReport implements OnInit {
  @ViewChild('toteAssetLocationModal', { static: false })
  toteAssetLocationModal: ModalDirective;
  public exportForm: FormGroup;
  public responseExportError: string;
  public listOfValue = {};
  public masterDataEnum = MasterDataEnum;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly masterService: MasterService,
    protected readonly translate: TranslateService,
    protected readonly toteAssetLocationService: ToteAssetLocationService
  ) {
    super();
  }

  ngOnInit() {
    this.getListOfValue();
    this.initControl();
  }

  getListOfValue() {
    const toteQuery = new GraphqlQueryObject();
    toteQuery.name = MasterDataEnum.TOTE;
    toteQuery.fields = ['code', 'nameTh', 'nameEn', 'rentalFee', 'group'];
    this.masterService.getMasterDataByNames([toteQuery]).subscribe(result => {
      if (result.data) {
        this.listOfValue = result.data[MasterDataEnum.TOTE].filter(v => {
          return v.group && v.rentalFee > 0;
        });
      }
    });
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      toteCode: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.toteAssetLocationModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.toteAssetLocationModal.hide();
  }

  onExport() {
    this.responseExportError = null;

    const rawData = this.exportForm.getRawValue();
    const toteAssetLocation = new ToteAssetLocationReportCriteria({
      toteCode: rawData.toteCode && rawData.toteCode.length > 0 ? rawData.toteCode.toString() : rawData.toteCode
    });

    this.toteAssetLocationService.export(toteAssetLocation).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.toteAssetLocationReport.prefixReport} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.closeExportModal();
      }
    );
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.toteAssetLocationReport.timeFormat);
  }
}
