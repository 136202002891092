<!-- Modal Product Section -->
<div
  id="stockCardModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #dcAssortmentModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export DC Assortment Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <div class="mb-2">Location Name<span class="text-danger">*</span></div>
                    <ng-select
                      bindLabel="locationDisplay"
                      bindValue="code"
                      [searchable]="true"
                      [clearable]="false"
                      [items]="warehouseList$ | async"
                      placeholder="Please select..."
                      formControlName="code"
                      [ngClass]="{ 'is-invalid': submitted && exportForm.controls.code.errors }"
                    >
                    </ng-select>
                    <div *ngIf="submitted && exportForm.controls.code.errors?.required" class="invalid-feedback">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                  <div *ngIf="responseExportError" class="alert-danger form-group mb-0 w-100">
                    {{ responseExportError }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_stock_card_report_btn"
            [disabled]="!isExportFormValid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
