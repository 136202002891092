import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MonthlyPartnerProfitSharingService } from '../../services/monthly-partner-profit-sharing.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { MonthlyPartnerProfitSharingActionTypes, MonthlyPartnerProfitSharingListResponseAction, MonthlyPartnerProfitSharingSubmitResponseAction } from '../actions/monthly-partner-profit-sharing.actions';
export class MonthlyPartnerProfitSharingEffects {
    constructor(actions$, logger, monthlyPartnerProfitSharingService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.monthlyPartnerProfitSharingService = monthlyPartnerProfitSharingService;
        this.searchMonthlyPartnerProfitSharing$ = this.actions$.pipe(ofType(MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.monthlyPartnerProfitSharingService.searchByCriteria(payload).pipe(map(response => {
                return new MonthlyPartnerProfitSharingListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.submitMonthlyPartnerProfitSharing$ = this.actions$.pipe(ofType(MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.monthlyPartnerProfitSharingService.submitMonthlyPartnerProfitSharing(payload).pipe(map(response => {
                return new MonthlyPartnerProfitSharingSubmitResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MonthlyPartnerProfitSharingEffects.prototype, "searchMonthlyPartnerProfitSharing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MonthlyPartnerProfitSharingEffects.prototype, "submitMonthlyPartnerProfitSharing$", void 0);
