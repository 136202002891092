import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TdAssortmentContent, TdAssortmentSearchCriteria } from '../../models/purchase-request.model';
import { TdAssortmentActions, TdAssortmentActionTypes } from '../actions/td-assortment.actions';

export interface TdAssortmentState extends EntityState<TdAssortmentContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  criteriaObject: TdAssortmentSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<TdAssortmentContent> = createEntityAdapter<TdAssortmentContent>({
  selectId: (tdAssortmentContent: TdAssortmentContent) =>
    `${tdAssortmentContent.supplierCode}_${tdAssortmentContent.barcode}`
});

export const initialTdAssortmentState: TdAssortmentState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function tdAssortmentReducers(
  state = initialTdAssortmentState,
  action: TdAssortmentActions
): TdAssortmentState {
  switch (action.type) {
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESET:
      return adapter.removeAll({
        ...state,
        totalElements: 0,
        totalPages: 0
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
