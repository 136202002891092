import { createSelector } from '@ngrx/store';
import * as fromSupplierPrice from '../reducers/supplier-price.reducers';
import { AppStates } from '../state/app.states';

const selectSupplierPriceState = (state: AppStates) => state.supplierPrice;

export const selectSupplierPriceList = createSelector(selectSupplierPriceState, fromSupplierPrice.selectAll);

export const selectSupplierPrice = createSelector(
  selectSupplierPriceState,
  (state: fromSupplierPrice.SupplierPriceState) => state
);

export const selectSupplierPriceShowSuccess = createSelector(
  selectSupplierPriceState,
  (state: fromSupplierPrice.SupplierPriceState) => {
    return state.showSuccess;
  }
);

export const selectSupplierPriceIsUpdated = createSelector(
  selectSupplierPriceState,
  (state: fromSupplierPrice.SupplierPriceState) => {
    return state.isPriceUpdated;
  }
);
