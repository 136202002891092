import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RolePageModes, RolePageTabs } from '../../../shared/enum/role.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  Feature,
  Permission,
  PermissionCreated,
  RoleEdit,
  RoleErrorResponse,
  RoleMasterResponse,
  RoleSearchCriteria,
  RoleViewResponse
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import {
  ResetRoleSelected,
  RoleByIdRequestAction,
  RoleEditRequestAction,
  RoleListRequestAction,
  RoleMasterListRequest,
  RoleMasterListReset,
  RoleResponseErrorResetAction
} from '../../../shared/store/actions/role.actions';
import {
  selectRoleById,
  selectRoleErrorResponse,
  selectRoleListCriteria,
  selectRoleMasterList
} from '../../../shared/store/selectors/role.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RolePageModes;
    roleNo: string;
  };
  isFormDirty: boolean;
  public rolePageTabs = RolePageTabs;
  protected localStore: Observable<any>;
  public roleMasterList$: Observable<RoleMasterResponse[]>;
  public roleMasters: RoleMasterResponse[];
  public role: RoleViewResponse;
  public roleSelectedSubscription: Subscription;

  public roleForm: FormGroup;
  public environment: any;
  public isPermissionTab: boolean;
  public submitted: boolean;
  public selectedPermission: Permission[] = [];
  public duplicateName: string;
  public rolePermission: any;
  public criteriaObject: RoleSearchCriteria;
  public closeModal: boolean;
  public hasViewPermission = false;
  public hasManagePermission = false;
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, false);
    this.isPermissionTab = true;
    this.hasViewPermission = this.authGuardService.checkPermission(['user_bo_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['user_bo_m']);
    this.environment = environment;
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    this.roleForm = this.fb.group({
      name: initialNullRequired
    });
  }

  initData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new RoleMasterListRequest());
    if (this.data.roleNo) {
      this.store.dispatch(new RoleByIdRequestAction(this.data.roleNo));
    }
    this.localStore
      .pipe(select(selectRoleListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));

    this.roleSelectedSubscription = combineLatest([
      this.store.select(selectRoleMasterList),
      this.store.select(selectRoleById)
    ]).subscribe(([roleMaster, selectedRole]) => {
      this.roleMasters = roleMaster;
      this.role = selectedRole;

      if (this.roleMasters && this.role) {
        this.setInitialValue();
      }
    });
  }

  initState() {
    this.localStore
      .pipe(
        select(selectRoleErrorResponse),
        filter(value => value !== null)
      )
      .subscribe((error: RoleErrorResponse) => {
        if (error.code === '02011') {
          this.duplicateName = error.duplicateName;
          this.roleForm.get('name').setValidators(this.isDuplicatedValidator);
          this.roleForm.get('name').updateValueAndValidity({ onlySelf: true });
        }

        const message = this.translate.instant(`ERROR_CODE.${error.code}`);
        this.alertErrorModal(message);
      });
  }

  setInitialValue() {
    if (this.isViewMode || this.role.isPredefined) {
      this.roleForm.disable();
    }
    this.roleForm.get('name').setValue(this.role.name);
    this.rolePermission = this.role.permissions.map(r => r.code);

    this.roleMasters.forEach(element => {
      element.features.forEach(feature => {
        feature.permissions.forEach(permission => {
          this.checkMappingPermission(permission, feature.coApplications);
        });
      });
    });
  }

  ngAfterViewInit() {}

  checkMappingPermission(permission: Permission, applications: string[]) {
    if (!this.rolePermission) {
      return;
    }
    if (this.rolePermission.includes(permission.code)) {
      permission.selected = true;
      permission.applications = applications;
      this.selectedPermission.push(permission);
    }
  }

  onExit() {
    this.isFormDirty = this.roleForm.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  selectedAll(featuresList: Feature) {
    featuresList.permissions.forEach(item => this.selectedItem(item, featuresList.coApplications));
  }

  clearAll(featuresList: Feature) {
    featuresList.permissions.forEach(item => this.deselectedItem(item));
  }

  selectedAllApp(applicationList: RoleMasterResponse) {
    applicationList.features.forEach(feature => this.selectedAll(feature));
  }

  clearAllApp(applicationList: RoleMasterResponse) {
    applicationList.features.forEach(feature => this.clearAll(feature));
  }

  selectedItem(item, applications: string[]) {
    if (!item.selected) {
      item.selected = true;
      item.applications = applications;
      this.selectedPermission.push(item);
    }
  }

  deselectedItem(item) {
    if (item.selected) {
      this.removeSelected(item.code);
      item.selected = false;
    }
  }

  removeSelected(code: string) {
    this.selectedPermission.splice(
      this.selectedPermission.findIndex(itemSelected => itemSelected.code === code),
      1
    );
  }

  onCheckboxChange(event: any, permissionSelected, applications: string[]) {
    if (event.target.checked) {
      this.selectedItem(permissionSelected, applications);
    } else {
      this.deselectedItem(permissionSelected);
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.selectedPermission.length === 0) {
      const message = 'Please select at least 1 permission.';
      this.alertErrorModal(message);
      return;
    }

    if (this.roleForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareData();
          this.store.dispatch(new RoleEditRequestAction(reqData));
        }
      });
  }

  alertErrorModal(message) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  prepareData(): RoleEdit {
    const value = this.roleForm.getRawValue();
    const permissions: PermissionCreated[] = [];
    const applications: string[] = [];
    this.selectedPermission.forEach(item => {
      const permission = {
        code: item.code,
        name: item.name,
        enable: item.enable
      } as PermissionCreated;

      const appData = this.prepareApplicationData(applications, item.applications);
      applications.push(...appData);
      permissions.push(permission);
    });

    return {
      ...this.role,
      ...value,
      name: value.name.trim(),
      applications,
      permissions
    };
  }

  prepareApplicationData(appList, app: string[]) {
    if (!app) {
      return;
    }
    const applications: string[] = [];
    app.forEach(item => {
      if (!appList.some(x => x === item)) {
        applications.push(item);
      }
    });

    return applications;
  }

  get isDuplicatedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === this.duplicateName ? { isDuplicated: true } : null;
      }
      return null;
    };
  }

  onSelectTabs(tab: RolePageTabs) {
    if (tab === RolePageTabs.PERMISSION) {
      this.isPermissionTab = true;
    }
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  ngOnDestroy() {
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
    if (this.roleSelectedSubscription) {
      this.roleSelectedSubscription.unsubscribe();
    }
    super.unsubscribeBase();
    this.store.dispatch(new ResetRoleSelected());
    this.store.dispatch(new RoleMasterListReset());
    this.store.dispatch(new RoleResponseErrorResetAction());
    this.refreshVoucherRequestList();
  }

  refreshVoucherRequestList() {
    if (this.data.mode === RolePageModes.CREATE && !this.closeModal) {
      this.criteriaObject.page = 0;
    }
    this.store.dispatch(new RoleListRequestAction(this.criteriaObject));
  }

  toggleToEditMode() {
    this.data.mode = RolePageModes.EDIT;
    this.data.title = 'Edit Role';
    if (!this.role.isPredefined) {
      this.roleForm.enable();
    }
  }

  get isViewMode(): boolean {
    return [RolePageModes.VIEW].includes(this.data.mode);
  }

  get isEditMode(): boolean {
    return [RolePageModes.EDIT, RolePageModes.CREATE].includes(this.data.mode);
  }
}
