const merchantColorStatus = (status: string): string => {
  switch (status) {
    case 'D':
      return 'draft';
    case 'WOS':
      return 'awaiting-order-schedule';
    case 'WA':
      return 'awaiting-approval';
    case 'A':
      return 'approved';
    case 'R':
      return 'rejected';
    case 'C':
      return 'cancelled';
    default:
      return '';
  }
};

export { merchantColorStatus };
