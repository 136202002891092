<input
  id="uploadFile"
  #uploadSupplierGroupInput
  type="file"
  class="d-none"
  (change)="handleFileInput()"
  [disabled]="disabled"
  [multiple]="multiple"
/>

<!--  Upload File-->
<button type="button" class="btn btn-standard" (click)="OnClickImport()">
  <em class="icon-import"></em>Import Supplier
</button>
