import { round } from 'lodash';

export const calculateVatAmount = (
  unitPrice: number,
  quantity: number,
  vatPct: number,
  isProductVat: boolean,
  isSupplierVat: boolean
) => {
  if ((isProductVat && isSupplierVat) || (!isProductVat && !isSupplierVat && vatPct)) {
    return round(unitPrice * quantity * (vatPct / 100), 4);
  }
  return 0;
};
