import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { RequestSectionEnum } from '../../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import { TDStoreWorkflowUtil } from '../../../../shared/utils/td-store-workflow-util';
import { emailValidator } from '../../../custom-validators/email-validator';
import { merchantDuplicatedValidator } from '../../../custom-validators/merchant-duplicated-validator';
import { FileModuleEnum } from '../../../enum/file-url.enum';
import { TDStoreValidatorTypeEnum } from '../../../enum/merchant-validator-type.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../enum/request-step.enum';
import { TDStorePage } from '../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse } from '../../../models';
import { gender } from '../../../models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../models/notification-emit.model';
import { MerchantRequestService } from '../../../services/merchant-request.service';
import { AppStates } from '../../../store/state/app.states';
import { getFileUploadObj } from '../../../utils/get-file-name-from-ref-id-util';
import { getSelectByPage } from '../../../utils/get-select-by-page-util';

@Component({
  selector: 'app-owner-profile',
  templateUrl: './owner-profile.component.html',
  styleUrls: ['./owner-profile.component.scss']
})
export class OwnerProfileComponent implements OnInit, OnDestroy {
  @Input() requestId: string;
  @Input() mode: RequestPageModesEnum;
  @Input() ownerIdCardReadOnly: boolean;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() page: TDStorePage;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public listOfValue: {};
  public bsConfig: BsDatepickerConfig;
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  public dateFormat = environment.dateFormat;
  private localStore: Observable<any>;

  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;

  constructor(
    private readonly fb: FormBuilder,
    private readonly merchantRequestService: MerchantRequestService,
    protected readonly modalService: BsModalService,
    private readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {}

  get ownerProfile() {
    return this.parentForm.get('ownerProfile') as FormGroup;
  }

  get fileModule() {
    return FileModuleEnum;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.parentForm.addControl('ownerProfile', this.createForm());

    this.bsConfig = ({
      dateInputFormat: this.dateFormat,
      maxDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      useUtc: true
    } as unknown) as BsDatepickerConfig;

    this.listOfValue = {
      gender
    };

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setOwnerProfileValue();
    }
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    const initialNull = [{ value: null, disabled: false }];

    return this.fb.group({
      contactFirstName: initialNullRequired,
      contactLastName: initialNullRequired,
      contactLocalFirstName: initialNullRequired,
      contactLocalLastName: initialNullRequired,
      cardId: [
        { value: null, disabled: false },
        {
          validators: [Validators.required, Validators.minLength(13)],
          asyncValidators: [
            merchantDuplicatedValidator(TDStoreValidatorTypeEnum.ID_CARD, this.merchantRequestService)
          ],
          updateOn: 'blur'
        }
      ],
      mobilePhone: [
        { value: null, disabled: false },
        {
          validators: [Validators.required],
          asyncValidators: [
            merchantDuplicatedValidator(TDStoreValidatorTypeEnum.MOBILE_PHONE, this.merchantRequestService)
          ],
          updateOn: 'blur'
        }
      ],
      gender: initialNull,
      birthDate: initialNullRequired,
      email: [{ value: null, disabled: false }, [Validators.maxLength(100), emailValidator()]],
      testScore: [{ value: null, disabled: false }, [Validators.required, Validators.min(0), Validators.max(100)]],
      attitudeTest: initialNullRequired,
      ownerPicture: initialNullRequired
    });
  }

  isShowDuplicatedError(control: AbstractControl): string {
    const isDuplicated = control.errors ? control.errors.duplicated : false;
    return (this.submitted && control.errors) || isDuplicated ? 'is-invalid' : '';
  }

  /** set value from selector (view, edit) mode **/
  setOwnerProfileValue() {
    this.merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));
    this.merchantRequestView$
      .pipe(
        filter(value => Boolean(value)),
        map(response => response)
      )
      .subscribe(value => {
        const ownerProfile = value.merchantInfo.ownerProfile;

        this.ownerProfile.controls.cardId.clearAsyncValidators();
        this.ownerProfile.controls.cardId.updateValueAndValidity({ onlySelf: true });

        this.ownerProfile.controls.mobilePhone.clearAsyncValidators();
        this.ownerProfile.controls.mobilePhone.updateValueAndValidity({ onlySelf: true });
        this.ownerProfile.patchValue({
          ...ownerProfile,
          mobilePhone:
            ownerProfile.countryCode && ownerProfile.mobilePhone
              ? ownerProfile.countryCode + ownerProfile.mobilePhone
              : null,
          countryCode: ownerProfile.countryCode || null,
          attitudeTest: getFileUploadObj(ownerProfile.attitudeTest),
          ownerPicture: getFileUploadObj(ownerProfile.ownerPicture)
        });

        document
          .getElementById('mobilePhone')
          .getElementsByTagName('input')[0]
          .focus();
        setTimeout(() => {
          document
            .getElementById('mobilePhone')
            .getElementsByTagName('input')[0]
            .blur();
          this.ownerProfile.controls.mobilePhone.markAsUntouched();
          if (
            this.mode !== RequestPageModesEnum.REQUEST_EDIT ||
            !this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.PROFILE)
          ) {
            this.ownerProfile.disable();
          }
        }, 0);

        this.type = value.type;
        this.step = value.step;
        this.status = value.status;

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditOwnerProfile();
        } else {
          this.setOwnerProfileCtrl(this.type, this.page, this.step, this.mode);
        }
      });
  }

  public toggleEditOwnerProfile() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (this.page === TDStorePage.MERCHANT_EDIT) {
      this.step = RequestStepEnum.EDIT_PROFILE;
      this.type = RequestTypeEnum.EDIT; /* remove after getting type */
    }

    this.setOwnerProfileCtrl(this.type, this.page, this.step, this.mode);
  }

  setOwnerProfileCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(
      localType,
      localPage,
      localStep,
      RequestSectionEnum.PROFILE
    );
    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.ownerProfile.enable();

      if (this.type !== RequestTypeEnum.EDIT) {
        this.ownerProfile.controls.cardId.setAsyncValidators([
          merchantDuplicatedValidator(
            TDStoreValidatorTypeEnum.ID_CARD,
            this.merchantRequestService,
            this.requestId,
            this.page
          )
        ]);
        this.ownerProfile.controls.mobilePhone.setAsyncValidators([
          merchantDuplicatedValidator(
            TDStoreValidatorTypeEnum.MOBILE_PHONE,
            this.merchantRequestService,
            this.requestId,
            this.page
          )
        ]);
      }

      this.disableFields();
    }
  }

  disableFields() {
    const ownerProfileCtrl = this.ownerProfile.controls;
    if (this.step === RequestStepEnum.PROFILE && this.status === RequestStatusEnum.AWAITING_APPROVAL) {
      ownerProfileCtrl.cardId.disable();
      ownerProfileCtrl.mobilePhone.disable();
    } else if (this.step === RequestStepEnum.EDIT_PROFILE) {
      ownerProfileCtrl.cardId.disable();
      ownerProfileCtrl.mobilePhone.disable();
      ownerProfileCtrl.gender.disable();
      ownerProfileCtrl.birthDate.disable();
      ownerProfileCtrl.testScore.disable();
      ownerProfileCtrl.attitudeTest.disable();
    }
  }
}
