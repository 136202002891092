<form [formGroup]="searchForm" (ngSubmit)="findStore()">
  <div class="row mb-1">
    <div class="col-12">
      <label for="searchCriteria">Keyword Search</label>
      <div class="d-flex">
        <ng-select
          placeholder="Please select..."
          [items]="keywordList"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="keyword"
          class="flex-item mr-1"
          (change)="onChangeKeyword()"
        >
        </ng-select>
        <div class="search-box position-relative flex-item ml-1">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="Enter Keyword"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-1">
    <div class="col-12">
      <label for="storeType">Store Type</label>
      <ng-select
        placeholder="Please select..."
        [items]="storeTypeList"
        [searchable]="false"
        [clearable]="true"
        [multiple]="true"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="storeType"
      >
      </ng-select>
    </div>
  </div>

  <div class="row mb-1">
    <div class="col-12">
      <label for="region">Region</label>
      <ng-select
        placeholder="Please select..."
        [items]="regionList"
        [searchable]="false"
        [clearable]="true"
        [multiple]="true"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="region"
      >
      </ng-select>
    </div>
  </div>

  <div class="row mb-1">
    <div class="col-12">
      <label for="province">Province</label>
      <ng-select
        placeholder="Please select..."
        [items]="stateList"
        [searchable]="true"
        [clearable]="true"
        [multiple]="true"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="province"
      >
      </ng-select>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-12">
      <label for="poiType">POI Type</label>
      <ng-select
        placeholder="Please select..."
        [items]="poiList"
        [searchable]="false"
        [clearable]="true"
        [multiple]="true"
        bindLabel="nameEn"
        bindValue="code"
        formControlName="poiType"
      >
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="col-12 footer-button">
      <button class="btn btn-standard" type="button" (click)="clearAllFilter()">
        Clear All
      </button>
      <button class="btn btn-primary" type="button" (click)="findStore()">
        Search
      </button>
    </div>
  </div>
</form>
