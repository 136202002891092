import { createSelector } from '@ngrx/store';
import * as fromMonthlyPartnerProfitSharing from '../reducers/monthly-partner-profit-sharing-reducers';
import { AppStates } from '../state/app.states';

const selectMonthlyProfitSharingState = (state: AppStates) => state.monthlyProfitSharing;

export const selectAllMonthlyPartnerProfitSharingList = createSelector(
  selectMonthlyProfitSharingState,
  fromMonthlyPartnerProfitSharing.selectAll
);

export const selectMonthlyPartnerProfitSharingList = createSelector(
  selectMonthlyProfitSharingState,
  (state: fromMonthlyPartnerProfitSharing.MonthlyPartnerProfitSharingState) => state
);

export const selectMonthlyPartnerProfitSharingSubmitResult = createSelector(
  selectMonthlyProfitSharingState,
  (state: fromMonthlyPartnerProfitSharing.MonthlyPartnerProfitSharingState) => state.monthlyPartnerProfitSharing
);
