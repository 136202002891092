import { createEntityAdapter } from '@ngrx/entity';
import { SpecialAssortmentActionType } from '../actions/special-assortment.actions';
const ɵ0 = (specialAssortment) => specialAssortment.barcode;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialSpecialAssortmentState = adapter.getInitialState({
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 20
});
export function specialAssortmentReducers(state = initialSpecialAssortmentState, action) {
    switch (action.type) {
        case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload.criteria });
        case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESET:
            return initialSpecialAssortmentState;
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
