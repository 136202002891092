import { Action } from '@ngrx/store';
import {
  ReceiveOrderListResponse,
  ReceiveOrderListSearchCriteria,
  ReceiveOrderViewResponse
} from '../../models/receive-order.model';

export enum ReceiveOrderActionType {
  RECEIVE_ORDER_LIST_REQUEST = '[ReceiveOrder] Receive Order List Request',
  RECEIVE_ORDER_LIST_RESPONSE = '[ReceiveOrder] Receive Order List Response',
  RECEIVE_ORDER_GET_BY_NO_REQUEST = '[ReceiveOrder] Get Receive Order by No Request',
  RECEIVE_ORDER_GET_BY_NO_RESPONSE = '[ReceiveOrder] Get Receive Order by No Response',
  RECEIVE_ORDER_GET_BY_NO_RESET = '[ReceiveOrder] Get Receive Order by No Reset'
}

export class ReceiveOrderListRequestAction implements Action {
  readonly type = ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST;

  constructor(public payload: ReceiveOrderListSearchCriteria) {}
}

export class ReceiveOrderListResponseAction implements Action {
  readonly type = ReceiveOrderActionType.RECEIVE_ORDER_LIST_RESPONSE;

  constructor(public payload: ReceiveOrderListResponse) {}
}

export class ReceiveOrderByIdRequestAction implements Action {
  readonly type = ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_REQUEST;

  constructor(public payload: { receiveOrderNo: string }) {}
}

export class ReceiveOrderByIdResponseAction implements Action {
  readonly type = ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESPONSE;

  constructor(public payload: ReceiveOrderViewResponse) {}
}

export class ResetReceiveOrderSelected implements Action {
  readonly type = ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESET;
}

export type ReceiveOrderActions =
  | ReceiveOrderListRequestAction
  | ReceiveOrderListResponseAction
  | ReceiveOrderByIdRequestAction
  | ReceiveOrderByIdResponseAction
  | ResetReceiveOrderSelected;
