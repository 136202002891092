import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { SearchArticleModalComponent } from '../../../shared/components/search-article-modal/search-article-modal.component';
import { SearchMultipleArticleModalComponent } from '../../../shared/components/search-multiple-article-modal/search-multiple-article-modal.component';
import {
  LocationTypeEnum,
  ManageStockPageModes,
  MovementType,
  RequestAdjustItemErrorEnum
} from '../../../shared/enum/manage-stock.emun';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import {
  GraphqlQueryObject,
  GraphqlQuerySortOptions,
  OrderByEnum,
  OrderDirectionEnum
} from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  AdjustItem,
  AdjustStockRequest,
  ItemsErrorList,
  StockAdjustSearchCriteria
} from '../../../shared/models/adjust-stock.model';
import { ArticlesContent } from '../../../shared/models/articles.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { adjustTypeList } from '../../../shared/models/list-value/list-key-value.model';
import { LocationDetail } from '../../../shared/models/manage-stock.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import {
  AdjustStockListRequestAction,
  AdjustStockRequestAction,
  AdjustStockRequestValidateAction,
  AdjustStockResetValidateAction
} from '../../../shared/store/actions/adjust-stock.actions';
import {
  selectAdjustListCriteria,
  selectAdjustStockResponseError,
  selectAdjustStockValidate
} from '../../../shared/store/selectors/adjust-stock.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-adjust-stock',
  templateUrl: './adjust-stock.component.html',
  styleUrls: ['./adjust-stock.component.scss']
})
export class AdjustStockComponent extends BaseComponent implements OnInit {
  protected localStore: Observable<any>;
  public submitted: boolean;
  public isSendToSubmit: boolean;
  private isDataUpdated: boolean;
  public maxDate: Date;
  public headerRow: string[];
  public reasonList: NgOption[];
  public adjustTypeList: NgOption[];
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: ManageStockPageModes;
    docNo?: string;
    movementType: MovementType;
    locationType: LocationTypeEnum;
    location: LocationDetail;
  };

  @ViewChild('searchArticleModal', { static: false }) searchArticleModal: SearchArticleModalComponent;
  @ViewChild('searchMultipleArticleModal', { static: false })
  searchMultipleArticleModal: SearchMultipleArticleModalComponent;
  public adjustStockForm: FormGroup;
  public dateFormat = environment.dateFormat;
  public criteriaObject: StockAdjustSearchCriteria;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    private readonly router: Router,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    private readonly masterService: MasterService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    const todayDate = new Date();
    this.maxDate = todayDate;

    this.initSelectList();
    this.headerRow = ['', 'No.', 'Article No.', 'Product Name', 'Adjust Stock', 'Reason'];
    this.initControl();
    this.initState();
    this.initData();

    this.localStore
      .pipe(select(selectAdjustListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  initSelectList() {
    this.adjustTypeList = adjustTypeList.filter(v =>
      this.data.mode === ManageStockPageModes.ADJUST_STOCK
        ? v.allowedLocationType.includes(this.data.locationType)
        : v
    );
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.localStore.pipe(select(selectAdjustStockValidate)).subscribe(validate => {
      if (validate) {
        this.handleConfirm();
      }
    });
  }

  initData() {
    this.localStore
      .pipe(select(selectAdjustStockResponseError))
      .pipe(filter(data => Boolean(data)))
      .subscribe(error => {
        if (error && error.code === '00001' && error.items) {
          this.handleErrorItemList(error.items);
        } else {
          this.showModalError(error.message);
        }
      });
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];

    const formArray = this.fb.array([], [this.articleValidator()]);
    this.adjustStockForm = this.fb.group({
      adjustType: initialNullRequired,
      refNo: initialNullRequired,
      items: formArray
    });
  }

  get form(): FormGroup {
    return this.adjustStockForm;
  }

  get formAdjustItemControls(): AbstractControl[] {
    return (this.adjustStockForm.get('items') as FormArray).controls;
  }

  get formAdjustItem(): FormArray {
    return this.adjustStockForm.get('items') as FormArray;
  }

  onCancel() {
    this.isDataUpdated = this.form.touched;

    if (this.isDataUpdated) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  addSelectItem() {
    this.searchArticleModal.openSelectModal();
  }

  addArticleItem() {
    this.searchMultipleArticleModal.searchMultipleArticleModal.show();
  }

  deleteItem(i: number) {
    if (
      !this.formAdjustItem.at(i).getError('invalidArticle') &&
      !this.formAdjustItem.at(i).getError('isNotAllowStoreUse') &&
      !this.formAdjustItem.at(i).getError('isNotAllowFixAsset') &&
      !this.formAdjustItem.at(i).getError('duplicated')
    ) {
      const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: 'Confirm',
          message: 'Are you sure you want to delete this item?',
          okText: 'Yes, delete'
        }
      });

      confirmModalRef.content.action
        .pipe(untilComponentDestroyed(this))
        .subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            this.confirmDeleteItem(i);
          }
        });
      return;
    }

    this.confirmDeleteItem(i);
  }

  confirmDeleteItem(i: number) {
    this.formAdjustItem.removeAt(i);
    this.formAdjustItem.markAsTouched();
  }

  createForm(item: AdjustItem): FormGroup {
    return this.fb.group({
      ...item,
      adjustStock: [
        {
          value: item.adjustStock ? item.adjustStock : null,
          disabled: false
        },
        [Validators.required, this.isZeroValidator]
      ],
      reason: [{ value: item.reason, disabled: false }, [Validators.required]]
    });
  }

  ApplyProductItemError(controlItem: AbstractControl, errorMessage: RequestAdjustItemErrorEnum) {
    switch (errorMessage) {
      case RequestAdjustItemErrorEnum.INVALID_ARTICLE:
        controlItem.setValidators(this.isInvalidValidator);
        controlItem.get('articleNo').setValidators(this.isInvalidValidator);
        break;
      case RequestAdjustItemErrorEnum.NOT_INSUFFICIENT_STOCK:
        controlItem.get('adjustStock').setErrors({ notInsufficientStock: true });
        break;
      case RequestAdjustItemErrorEnum.NOT_ALLOW_STORE_USE:
        controlItem.setValidators(this.isNotAllowStoreUseValidator);
        controlItem.get('articleNo').setValidators(this.isNotAllowStoreUseValidator);
        break;
      case RequestAdjustItemErrorEnum.NOT_ALLOW_FIX_ASSET:
        controlItem.setValidators(this.isNotAllowFixAssetValidator);
        controlItem.get('articleNo').setValidators(this.isNotAllowFixAssetValidator);
        break;
      case RequestAdjustItemErrorEnum.FRESH_LITE_ITEM:
        controlItem.setValidators(this.isNotAllowFreshLiteItemValidator);
        controlItem.get('articleNo').setValidators(this.isNotAllowFreshLiteItemValidator);
        break;
      case RequestAdjustItemErrorEnum.FRESH_LITE_EXPENSE_ITEM:
        controlItem.setValidators(this.isNotAllowFreshLiteExpenseItemValidator);
        controlItem.get('articleNo').setValidators(this.isNotAllowFreshLiteExpenseItemValidator);
        break;
      default:
        break;
    }
  }

  onAddSelectedItem(response: ArticlesContent[]) {
    response.forEach(item => {
      if (!this.checkDuplicatedBarcode(item.articleNo)) {
        this.formAdjustItem.push(this.createForm(AdjustItem.mappingArticleResponseToProductItem(item)));

        this.formAdjustItem.markAsTouched();
      } else {
        this.alertErrorModal('Not allow to add duplicated article.');
        return;
      }
    });
  }

  checkDuplicatedBarcode(barcode: string): boolean {
    return !!(
      this.formAdjustItemControls && this.formAdjustItemControls.find(x => x.get('articleNo').value === barcode)
    );
  }

  onAddArticleItem(response: ArticlesContent[]) {
    response.forEach(item => {
      const fromItem = this.createForm(AdjustItem.mappingArticleResponseToProductItem(item));
      this.ApplyProductItemError(fromItem, item.errorMessage);
      this.formAdjustItem.push(fromItem);
    });

    this.formAdjustItem.markAsTouched();
  }

  ApplyToAllReason(i: number) {
    const value = this.formAdjustItemControls[i].get('reason').value;
    this.formAdjustItem.controls.forEach(fieldValue => {
      let adjustStock =
        value && value.value.adjustType === 'NEGATIVE'
          ? -Math.abs(fieldValue.value.adjustStock)
          : Math.abs(fieldValue.value.adjustStock);
      adjustStock = adjustStock || fieldValue.value.adjustStock === 0 ? adjustStock : null;

      fieldValue.get('reason').patchValue(value);
      fieldValue.get('adjustStock').patchValue(adjustStock);
    });
  }

  onChangeReason(event, i: number) {
    const valueReason = event.value;
    const valueAdjust = this.formAdjustItemControls[i].get('adjustStock').value;
    if (valueAdjust && valueReason) {
      const adjustStock = valueReason.adjustType === 'NEGATIVE' ? -Math.abs(valueAdjust) : Math.abs(valueAdjust);
      this.formAdjustItemControls[i].get('adjustStock').patchValue(adjustStock);
    }
  }

  onChangeAdjustStock(event, i: number) {
    const valueAdjust = event.target.value;
    const valueReason = this.formAdjustItemControls[i].get('reason').value;
    if (valueAdjust && valueReason) {
      const adjustStock =
        valueReason.value.adjustType === 'NEGATIVE' ? -Math.abs(valueAdjust) : Math.abs(valueAdjust);
      this.formAdjustItemControls[i].get('adjustStock').patchValue(adjustStock);
    }
  }

  onChangeAdjustType(event) {
    const value = event.value;
    const stateQuery = new GraphqlQueryObject();
    stateQuery.name = MasterDataEnum.STOCK_ADJUST_REASONS_BY_TYPE;
    stateQuery.fields = ['code', 'nameEn', 'nameTh', 'adjustType', 'type'];
    stateQuery.sort = {
      orderBy: OrderByEnum.CODE,
      orderDirection: OrderDirectionEnum.ASC
    } as GraphqlQuerySortOptions;
    stateQuery.type = value;
    this.masterService.getMasterDataByNames([stateQuery]).subscribe(result => {
      if (result.data && result.data[MasterDataEnum.STOCK_ADJUST_REASONS_BY_TYPE]) {
        this.reasonList = result.data[MasterDataEnum.STOCK_ADJUST_REASONS_BY_TYPE].map(v => ({
          value: { ...v },
          label: v.nameTh
        }));
      }
    });

    // reset reason from each row
    for (const row of this.formAdjustItemControls) {
      row.get('reason').setValue(null);
    }
  }

  onSubmit() {
    this.submitted = true;
    const errorMessage = this.getErrorMessage();

    if (errorMessage) {
      this.showModalError(errorMessage);
    } else if (this.form.valid) {
      this.validateData();
    }
  }

  getErrorMessage(): string {
    const formGroup = this.formAdjustItem;
    if (!formGroup.controls.length) {
      this.form.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    } else {
      return formGroup.controls.some(value => value.errors) ? 'Please delete invalid data before submit.' : '';
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  validateData() {
    const reqData = this.prepareRequestData();
    this.store.dispatch(new AdjustStockRequestValidateAction(reqData));
  }

  handleConfirm() {
    this.store.dispatch(new AdjustStockResetValidateAction());

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isSendToSubmit = true;
          const reqData = this.prepareRequestData();
          this.store.dispatch(new AdjustStockRequestAction(reqData));
        }
      });
  }

  prepareRequestData(): AdjustStockRequest {
    const rawData = this.form.getRawValue();

    rawData.items.forEach(item => {
      item.reason = item.reason.value ? item.reason.value.nameTh : '';
    });

    return {
      ...rawData,
      locationType: this.data.locationType,
      location: this.data.location.code
    };
  }

  get isInvalidValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { invalidArticle: true };
    };
  }

  get isNotAllowStoreUseValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isNotAllowStoreUse: true };
    };
  }

  get isNotAllowFixAssetValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isNotAllowFixAsset: true };
    };
  }

  get isNotAllowFreshLiteItemValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isNotAllowFreshLiteItem: true };
    };
  }

  get isNotAllowFreshLiteExpenseItemValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isNotAllowFreshLiteExpenseItem: true };
    };
  }

  get isNotInsufficientStockValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { notInsufficientStock: true };
    };
  }

  get isZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  validatorFormControls(field: string, i: number) {
    return this.formAdjustItemControls[i].get(field).errors;
  }

  handleErrorItemList(items: ItemsErrorList[]) {
    if (items.length > 0) {
      const itemList = this.formAdjustItemControls.map(value => value.get('articleNo').value);
      items.forEach(item => {
        const index = itemList.indexOf(item.articleNo);
        this.ApplyProductItemError(this.formAdjustItemControls[index], item.errorMessage);
        this.formAdjustItemControls[index].updateValueAndValidity();
      });
    }
  }

  articleValidator(): ValidatorFn {
    return (formArray: FormArray) => {
      const array = formArray.getRawValue();
      const availableArticle = [];
      for (let i = 0; i < array.length; i++) {
        const isDuplicated = availableArticle.indexOf(array[i].articleNo) > -1;
        if (!isDuplicated) {
          availableArticle.push(array[i].articleNo);
        }

        if (!formArray.controls[i].errors || formArray.controls[i].getError('duplicated')) {
          formArray.controls[i].setErrors(isDuplicated && { duplicated: true });
        }
      }
      return null;
    };
  }

  isInvalidArticle(i: number): boolean {
    return !this.formAdjustItemControls[i].get('articleNo').hasError('invalidArticle');
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getProductType() {
    return this.data.locationType === LocationTypeEnum.STORE ? [ProductTypeEnum.INVENTORY] : null;
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  ngOnDestroy(): void {
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
    if (this.data.mode === ManageStockPageModes.ADJUST_STOCK && this.isSendToSubmit) {
      this.criteriaObject.page = 0;
      this.store.dispatch(new AdjustStockListRequestAction(this.criteriaObject));
    }
  }

  get isViewMode(): boolean {
    return this.data.mode === ManageStockPageModes.ADJUST_STOCK_VIEW;
  }
}
