<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="info-header-container">
  <div class="info-header-item">
    <div class="info-header-label">
      <span>Request No.:</span>
    </div>
    <div class="info-header-value">
      <span>{{ (storeAssortmentRequestView$ | async)?.requestNo }}</span>
    </div>
  </div>
  <div class="info-header-item">
    <div class="info-header-label">
      <span>Status:</span>
    </div>
    <div class="info-header-value">
      <span [ngClass]="getColorStatus((storeAssortmentRequestView$ | async)?.status)" class="request-status">
        {{ 'STORE_ASSORTMENT_REQUEST.STATUS.' + (storeAssortmentRequestView$ | async)?.status | translate }}
      </span>
    </div>
  </div>
</div>

<div class="information-container">
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-lg col-md-4 flex-column">
        <div class="info-header-label">
          <span>No. of Store</span>
        </div>
        <div class="info-header-value">
          {{ (storeAssortmentRequestView$ | async)?.noOfStore | numberFormatDisplay: 0:0 }}
        </div>
      </div>
      <div class="col-lg col-md-4 flex-column">
        <div class="info-header-label">
          <span>No. of Added</span>
        </div>
        <div class="info-header-value">
          {{ (storeAssortmentRequestView$ | async)?.noOfAdd | numberFormatDisplay: 0:0 }}
        </div>
      </div>
      <div class="col-lg col-md-4 flex-column">
        <div class="info-header-label">
          <span>No. of Deleted</span>
        </div>
        <div class="info-header-value">
          {{ (storeAssortmentRequestView$ | async)?.noOfDelete | numberFormatDisplay: 0:0 }}
        </div>
      </div>
      <div class="col-lg col-md-4 flex-column">
        <div class="info-header-label">
          <span>Created By</span>
        </div>
        <div class="info-header-value">
          {{ (storeAssortmentRequestView$ | async)?.createdBy }}
        </div>
      </div>
      <div class="col-lg col-md-4 flex-column">
        <div class="info-header-label">
          <span>Created Date</span>
        </div>
        <div class="info-header-value">
          {{ (storeAssortmentRequestView$ | async)?.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeFormat }}
        </div>
      </div>
    </div>
  </div>
</div>

<app-store-assortment-request-table class="admin-form" [mode]="mode"></app-store-assortment-request-table>
