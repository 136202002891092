import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantService } from '../../services/merchant.service';
import { UsersService } from '../../services/users.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { ActivateUserResponseAction, ForceChangePasswordFailed, ForceChangePasswordSuccess, UserActionTypes, UserByUserNoResponseAction, UserCreateResponseErrorAction, UserListResponseAction, UserResetPasswordResponseAction, UsersResetPinCodeResponseAction, UserUpdateResponseErrorAction } from '../actions/user-info.action';
export class UserEffects {
    constructor(actions$, logger, usersService, merchantService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.usersService = usersService;
        this.merchantService = merchantService;
        this.searchUser$ = this.actions$.pipe(ofType(UserActionTypes.USER_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${UserActionTypes.USER_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.searchByCriteriaRequest(payload).pipe(map(requests => {
                return new UserListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.UserPasswordReset = this.actions$.pipe(ofType(UserActionTypes.USER_PASSWORD_RESET), map(action => {
            this.logger.debug('@Effect UserPasswordResetAction: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.resetPassword(payload.userName).pipe(switchMap(() => {
                return this.usersService.resetPinCode(payload.userName);
            }), switchMap(() => {
                return this.merchantService.addResetOwnerLog({ merchant: payload.merchant });
            }), map(() => {
                return new LayoutActionSaveSuccess({
                    isSuccess: true,
                    title: 'Success',
                    message: 'Username & Password has been sent to the owner.'
                });
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.UserCreateRequest = this.actions$.pipe(ofType(UserActionTypes.USER_CREATE_REQUEST), map(action => {
            this.logger.debug('@Effect UserCreateRequestAction: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.crateUser(payload).pipe(map(() => this.layoutActionSaveSuccess('The user has been created.')), catchError(err => {
                return err && err.error && this.checkErrorCode(err.error.code)
                    ? of(new UserCreateResponseErrorAction(Object.assign({}, err.error)))
                    : err && err.error && err.error.code === '00001'
                        ? of(new LayoutActionVersionError(true))
                        : of(new LayoutActionLoadError(err));
            }));
        }));
        this.UserUpdateRequest = this.actions$.pipe(ofType(UserActionTypes.USER_UPDATE_REQUEST), map(action => {
            this.logger.debug('@Effect UserUpdateRequest: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.updateUser(payload).pipe(map(() => this.layoutActionSaveSuccess('The user has been updated.')), catchError(err => {
                return err && err.error && this.checkErrorCode(err.error.code)
                    ? of(new UserUpdateResponseErrorAction(Object.assign({}, err.error)))
                    : err && err.error && err.error.code === '00001'
                        ? of(new LayoutActionVersionError(true))
                        : of(new LayoutActionLoadError(err));
            }));
        }));
        this.getUserByNo$ = this.actions$.pipe(ofType(UserActionTypes.USER_GET_BY_NO_REQUEST), map(action => {
            this.logger.debug(`@Effect ${UserActionTypes.USER_GET_BY_NO_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.getUserByNo(payload).pipe(map(requests => {
                return new UserByUserNoResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.changeStatusUserByUserName$ = this.actions$.pipe(ofType(UserActionTypes.USER_ACTIVATE_BY_USER_NAME_REQUEST), map(action => {
            this.logger.debug(`@Effect ${UserActionTypes.USER_ACTIVATE_BY_USER_NAME_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.usersService.changeStatusUserByUserName(payload).pipe(map(resp => {
                return new ActivateUserResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
            }), catchError(err => of(new ActivateUserResponseAction({ statusCode: err.status.toString(), message: 'Change status Error' }))));
        }));
        this.resetPassword$ = this.actions$.pipe(ofType(UserActionTypes.USER_PASSWORD_RESET_REQUEST), map(action => action.payload), switchMap(payload => {
            this.logger.debug('@Effect Reset Password Request: ' + this.stringify(payload));
            return this.usersService.resetPassword(payload).pipe(map((resp) => {
                this.logger.debug('@Effect Reset Password Response: ' + this.stringify(resp));
                return new UserResetPasswordResponseAction({
                    statusCode: resp.status.toString(),
                    message: resp.statusText
                });
            }), catchError(err => of(new UserResetPasswordResponseAction({
                statusCode: err.status.toString(),
                message: 'Reset Password Error'
            }))));
        }));
        this.resetPinCode$ = this.actions$.pipe(ofType(UserActionTypes.USERS_RESET_PIN_CODE_REQUEST), map(action => action.payload), switchMap(payload => {
            this.logger.debug('@Effect Reset Pin code Request: ' + this.stringify(payload));
            return this.usersService.resetPinCode(payload.userName).pipe(map((resp) => {
                this.logger.debug('@Effect Reset Pin code Response: ' + this.stringify(resp));
                return new UsersResetPinCodeResponseAction({
                    statusCode: resp.status.toString(),
                    message: resp.statusText
                });
            }), catchError(err => of(new UsersResetPinCodeResponseAction({
                statusCode: err.status.toString(),
                message: 'Reset Pin Code Error'
            }))));
        }));
        this.forceChangePassword$ = this.actions$.pipe(ofType(UserActionTypes.CHANGE_PASSWORD_REQUEST), map((action) => action.payload), switchMap(changePasswordRequest => {
            return this.usersService.changePassword(changePasswordRequest).pipe(map((changedPassword) => {
                return new ForceChangePasswordSuccess({
                    statusCode: changedPassword.status.toString(),
                    message: changedPassword.statusText
                });
            }), catchError(err => {
                return of(new ForceChangePasswordFailed(err.error));
            }));
        }));
    }
    checkErrorCode(errorCode) {
        return ['02008', '02010', '02006', '02003'].includes(errorCode);
    }
    layoutActionSaveSuccess(message) {
        return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message
        });
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UserEffects.prototype, "searchUser$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "UserPasswordReset", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "UserCreateRequest", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "UserUpdateRequest", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UserEffects.prototype, "getUserByNo$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], UserEffects.prototype, "changeStatusUserByUserName$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "resetPassword$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "resetPinCode$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], UserEffects.prototype, "forceChangePassword$", void 0);
