import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { VoucherRequestService } from '../../services';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import {
  VoucherApproveRequested,
  VoucherCancelRequestedAction,
  VoucherRejectRequested,
  VoucherRequestActionTypes,
  VoucherRequestByIdRequestAction,
  VoucherRequestByIdResponseAction,
  VoucherRequestListRequestAction,
  VoucherRequestListResponseAction,
  VoucherRequestSubmitRequestAction
} from '../actions/voucher-request.actions';

@Injectable()
export class VoucherRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly voucherRequestService: VoucherRequestService
  ) {}

  @Effect()
  searchVoucherRequest$ = this.actions$.pipe(
    ofType<VoucherRequestListRequestAction>(VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.voucherRequestService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          return new VoucherRequestListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  createSubmitVoucher$ = this.actions$.pipe(
    ofType<VoucherRequestSubmitRequestAction>(VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_REQUEST),
    tap(action =>
      this.logger.debug('@Effect Request Voucher Create Submit Request: ' + JSON.stringify(action.payload))
    ),
    switchMap(action => {
      return this.voucherRequestService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been sent to approver.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getVoucherRequestById$ = this.actions$.pipe(
    ofType<VoucherRequestByIdRequestAction>(VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Voucher Request By ID Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherRequestService.getVoucherRequestById(action.payload.requestId).pipe(
        map(VoucherRequestView => {
          return new VoucherRequestByIdResponseAction({ VoucherRequestView });
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  rejectRequestPromotions$ = this.actions$.pipe(
    ofType<VoucherRejectRequested>(VoucherRequestActionTypes.VOUCHER_REQUEST_REJECT_REQUESTED),
    tap(action => this.logger.debug('@Effect Voucher Request Reject: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherRequestService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  cancelRequestPromotions$ = this.actions$.pipe(
    ofType<VoucherCancelRequestedAction>(VoucherRequestActionTypes.VOUCHER_REQUEST_CANCEL_REQUESTED),
    tap(action => this.logger.debug('@Effect Voucher Request Cancel: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherRequestService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  approveRequestPromotions$ = this.actions$.pipe(
    ofType<VoucherApproveRequested>(VoucherRequestActionTypes.VOUCHER_REQUEST_APPROVE_REQUESTED),
    tap(action => this.logger.debug('@Effect Voucher Request Approve: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.voucherRequestService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
