/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../version-tag/version-tag.component.ngfactory";
import * as i4 from "../version-tag/version-tag.component";
import * as i5 from "./login-layout.component";
var styles_LoginLayoutComponent = [i0.styles];
var RenderType_LoginLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginLayoutComponent, data: {} });
export { RenderType_LoginLayoutComponent as RenderType_LoginLayoutComponent };
export function View_LoginLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container page-wrapper"], ["id", "home-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "page-content flex-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "flex-item d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "router-outlet", [["name", "leftsection"]], null, null, null, null, null)), i1.ɵdid(4, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, "leftsection"], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "form-section flex-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "home-logo mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "p", [["class", "footer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" \u00A9 2019 TD Tawandang Co.,Ltd. All rightS reserved. www.tdshop.io "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-version-tag", [], null, null, null, i3.View_VersionTagComponent_0, i3.RenderType_VersionTagComponent)), i1.ɵdid(13, 114688, null, 0, i4.VersionTagComponent, [], null, null)], function (_ck, _v) { _ck(_v, 4, 0); _ck(_v, 8, 0); _ck(_v, 13, 0); }, null); }
export function View_LoginLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-layout", [], null, null, null, View_LoginLayoutComponent_0, RenderType_LoginLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoginLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginLayoutComponentNgFactory = i1.ɵccf("app-login-layout", i5.LoginLayoutComponent, View_LoginLayoutComponent_Host_0, {}, {}, []);
export { LoginLayoutComponentNgFactory as LoginLayoutComponentNgFactory };
