import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RoleCreated, RoleEdit, RoleListResponse, RoleSearchCriteria, RoleViewResponse } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class RoleService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.role;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: RoleSearchCriteria): Observable<RoleListResponse> {
    const url = this.getFullUrl(this.envService.list);

    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  save(roleCreate: RoleCreated): Observable<any> {
    return this.http.post<any>(this.getUrl(), roleCreate, { headers: this.loaderHeaders() });
  }

  edit(roleEdit: RoleEdit): Observable<any> {
    return this.http.put<any>(this.getUrl(), roleEdit, { headers: this.loaderHeaders() });
  }

  getRoleById(roleNo: string): Observable<RoleViewResponse> {
    const url = this.getFullUrl(this.envService.get, {
      roleNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  getRoleAll(): Observable<RoleViewResponse[]> {
    return this.http.get<RoleViewResponse[]>(this.getUrl(), {
      headers: this.headers,
      observe: 'body'
    });
  }
}
