/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./update-order-policy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../order-policy.component.ngfactory";
import * as i3 from "../order-policy.component";
import * as i4 from "@angular/forms";
import * as i5 from "@ngrx/store";
import * as i6 from "../../../../utils/td-store-workflow-util";
import * as i7 from "../../../../directives/disable-button.directive";
import * as i8 from "../../../../services/disable-button.service";
import * as i9 from "./update-order-policy.component";
import * as i10 from "ngx-bootstrap/modal";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../services/store.service";
var styles_UpdateOrderPolicyComponent = [i0.styles];
var RenderType_UpdateOrderPolicyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UpdateOrderPolicyComponent, data: {} });
export { RenderType_UpdateOrderPolicyComponent as RenderType_UpdateOrderPolicyComponent };
export function View_UpdateOrderPolicyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { orderPolicy: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 15, "div", [["class", "modal-content admin-form"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h6", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Update Order Schedule"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["id", "closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-order-policy", [], null, null, null, i2.View_OrderPolicyComponent_0, i2.RenderType_OrderPolicyComponent)), i1.ɵdid(10, 245760, [[1, 4], ["orderPolicy", 4]], 0, i3.OrderPolicyComponent, [i4.FormBuilder, i5.Store, i6.TDStoreWorkflowUtil], { parentForm: [0, "parentForm"], submitted: [1, "submitted"], mode: [2, "mode"], page: [3, "page"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-standard"], ["id", "cancelBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["appDisableButton", ""], ["class", "btn btn-primary"], ["id", "okBtn"], ["type", "button"]], null, [[null, "click"], [null, "dblclick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("dblclick" === en)) {
        var pd_1 = (i1.ɵnov(_v, 15).onDoubleClicked() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 81920, null, 0, i7.DisableButtonDirective, [i1.ElementRef, i1.Renderer2, i8.DisableButtonService], { isFormInvalid: [0, "isFormInvalid"] }, { click: "click" }), (_l()(), i1.ɵted(-1, null, [" Submit "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.submitted; var currVal_2 = _co.mode; var currVal_3 = _co.page; _ck(_v, 10, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.form.invalid; _ck(_v, 15, 0, currVal_4); }, null); }
export function View_UpdateOrderPolicyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-update-order-policy", [], null, null, null, View_UpdateOrderPolicyComponent_0, RenderType_UpdateOrderPolicyComponent)), i1.ɵdid(1, 4440064, null, 0, i9.UpdateOrderPolicyComponent, [i4.FormBuilder, i10.BsModalRef, i5.Store, i10.BsModalService, i11.TranslateService, i12.StoreService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UpdateOrderPolicyComponentNgFactory = i1.ɵccf("app-update-order-policy", i9.UpdateOrderPolicyComponent, View_UpdateOrderPolicyComponent_Host_0, { parentForm: "parentForm", submitted: "submitted", mode: "mode", page: "page", storeNo: "storeNo", merchant: "merchant", version: "version" }, {}, []);
export { UpdateOrderPolicyComponentNgFactory as UpdateOrderPolicyComponentNgFactory };
