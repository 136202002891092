const formatNumber = (numb, precision = 2) => {
  if (!numb && numb !== 0) {
    return null;
  }
  if (typeof numb === 'string' || numb instanceof String) {
    return numb;
  }
  if (precision === 0) {
    const realNumber = String(Number(numb).toLocaleString()).match(/^([0-9,]+)/);
    return realNumber[1];
  }

  const floatNumber = Number(numb).toFixed(precision);
  const groupNumber = String(floatNumber).match(/^(\d+).(\d+)$/);
  const leadingNumber = String(Number(groupNumber[1]).toLocaleString()).match(/^([0-9,]+)/);
  return `${leadingNumber[0]}.${groupNumber[2]}`;
};

const alwaysRoundUp = (num, precision) => {
  /**
   * @param num The number to round
   * @param precision The number of decimal places to preserve
   */
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};
export { formatNumber, alwaysRoundUp };
