import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DisableButtonService {
  loaded = new Subject<boolean>();

  constructor() {}

  enable() {
    this.loaded.next(true);
  }
}
