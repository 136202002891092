import { createSelector } from '@ngrx/store';
import * as fromReceiveOrderResponseState from '../reducers/receive-order.reducers';
import { AppStates } from '../state/app.states';

const selectReceiveOrderState = (state: AppStates) => state.receiveOrder;

export const selectAllReceiveOrderList = createSelector(
  selectReceiveOrderState,
  fromReceiveOrderResponseState.selectAll
);

export const selectReceiveOrderList = createSelector(
  selectReceiveOrderState,
  (state: fromReceiveOrderResponseState.ReceiveOrderState) => state
);

export const selectReceiveOrderListCriteria = createSelector(selectReceiveOrderState, state => {
  return state.criteriaObject;
});

export const selectReceiveOrderByNo = createSelector(
  selectReceiveOrderState,
  (state: fromReceiveOrderResponseState.ReceiveOrderState) => state.selected
);
