import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dropdown-multiple-select',
  templateUrl: './dropdown-multiple-select.component.html',
  styleUrls: ['./dropdown-multiple-select.component.scss']
})
export class DropdownMultipleSelectComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() controlName: string;
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() items: any[];
  @Input() showSelectAll = true;
  @Input() showClearAll = true;
  @Input() showSearch = true;
  @Input() showTotalItem = true;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  onSelectAll() {
    if (!this.items) {
      return;
    }
    this.parentForm.get(this.controlName).patchValue([]);

    const selected = this.items.map(item => (item[this.controlName] ? item[this.controlName] : item));
    this.parentForm.get(this.controlName).patchValue(selected);
  }

  onClearAll() {
    this.parentForm.get(this.controlName).patchValue([]);
  }

  getItemsLength() {
    return this.items && this.items.length;
  }
}
