import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StoreNo } from '../models';
import {
  ExportStoreAssortmentByProductCriteria,
  ExportStoreAssortmentByStoreCriteria,
  StoreAssortmentRequestStoreListResponse,
  StoreAssortmentRequestStoreListSearchCriteria
} from '../models/store-assortment-request-store.model';
import {
  MasterAssortmentList,
  MasterAssortmentListSearchCriteria,
  StoreAssortmentListResponse,
  StoreAssortmentListSearchCriteria,
  StoreAssortmentViewResponse
} from '../models/store-assortment.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeAssortment;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: StoreAssortmentListSearchCriteria): Observable<StoreAssortmentListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(storeNo: StoreNo): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStoreAssortmentById(storeNo: StoreNo): Observable<StoreAssortmentViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      storeNo: storeNo.storeNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchAssortmentByCriteria(
    storeNo: StoreNo,
    criteria: MasterAssortmentListSearchCriteria
  ): Observable<MasterAssortmentList> {
    const url = this.getFullUrl(this.envService.getAssortment, {
      storeNo: storeNo.storeNo
    });
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public searchStoreAssortmentStoreListByCriteria(
    criteria: StoreAssortmentRequestStoreListSearchCriteria
  ): Observable<StoreAssortmentRequestStoreListResponse> {
    const url = this.getFullUrl(this.envService.list, {});
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportByProduct(criteria: ExportStoreAssortmentByProductCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportByProduct);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public exportByStore(criteria: ExportStoreAssortmentByStoreCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportByStore);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
