import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { SupplierPriceContent, SupplierPriceSearchCriteria } from '../../models/supplier-price.model';
import { SupplierPriceActions, SupplierPriceActionTypes } from '../actions/supplier-price.actions';

export interface SupplierPriceState extends EntityState<SupplierPriceContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  supplierPrice: SupplierPriceContent;
  criteriaObject: SupplierPriceSearchCriteria;
  totalElements: number;
  totalPages: number;
  errorResponse: ErrorResponse;
  showSuccess: boolean;
  isPriceUpdated: boolean;
  allowLoad: boolean;
}

export const adapter: EntityAdapter<SupplierPriceContent> = createEntityAdapter<SupplierPriceContent>();

export const initialSupplierPriceState: SupplierPriceState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  supplierPrice: null,
  criteriaObject: {
    importId: null,
    page: 0,
    size: 20,
    showSuccess: false
  },
  totalElements: 0,
  totalPages: 0,
  errorResponse: null,
  showSuccess: false,
  isPriceUpdated: false,
  allowLoad: false
});

export function supplierPriceReducers(
  state = initialSupplierPriceState,
  action: SupplierPriceActions
): SupplierPriceState {
  switch (action.type) {
    case SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierPriceActionTypes.SUPPLIER_PRICE_RESET: {
      return {
        ...state,
        ...initialSupplierPriceState
      };
    }
    case SupplierPriceActionTypes.SUPPLIER_PRICE_SHOW_SUCCESS:
      return {
        ...state,
        showSuccess: action.payload
      };
    case SupplierPriceActionTypes.SUPPLIER_PRICE_IS_PRICE_UPDATED:
      return {
        ...state,
        isPriceUpdated: action.payload
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
