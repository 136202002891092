import { Action } from '@ngrx/store';
import {
  ProductsContent,
  ProductSelectStatusResponse,
  ProductsPagination,
  ProductsSearchCriteria,
  ProductsSelectStatusRequest
} from '../../models';

export enum ProductActionTypes {
  PRODUCTS_REQUEST = '[Product List Page] Product List Request',
  PRODUCTS_SUCCESS = '[Product List API] Product List Response',
  PRODUCTS_SELECT_REQUEST = '[Product List Page] Product List Select Status Request',
  PRODUCTS_SELECT_SUCCESS = '[Product List API] Product List Select Status Response',
  PRODUCTS_SELECT_RESET = '[Product List Page] Product List Select Status Reset',
  PRODUCTS_CHECK = '[Product List Page] Product List Check',
  PRODUCTS_CHECK_ALL = '[Product List Page] Product List Check All',
  PRODUCTS_UNCHECK = '[Product List Page] Product List UnCheck',
  PRODUCTS_UNCHECK_ALL = '[Product List Page] Product List UnCheck All',
  PRODUCTS_CHECK_RESET = '[Product List Page] Product List Reset Checked'
}

export class ProductListRequestAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_REQUEST;
  constructor(public payload: ProductsSearchCriteria) {}
}

export class ProductListSuccessAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_SUCCESS;
  constructor(public payload: ProductsPagination) {}
}

export class ProductListSelectStatusRequestAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_SELECT_REQUEST;
  constructor(public payload: ProductsSelectStatusRequest) {}
}

export class ProductListSelectStatusSuccessAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_SELECT_SUCCESS;
  constructor(public payload: ProductSelectStatusResponse) {}
}

export class ProductListSelectStatusResetDelete implements Action {
  readonly type = ProductActionTypes.PRODUCTS_SELECT_RESET;
  constructor() {}
}

export class ProductListCheckAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_CHECK;
  constructor(public payload: ProductsContent) {}
}

export class ProductListCheckAllAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_CHECK_ALL;
  constructor() {}
}

export class ProductListUnCheckAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_UNCHECK;
  constructor(public payload: ProductsContent) {}
}

export class ProductListUnCheckAllAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_UNCHECK_ALL;
  constructor() {}
}

export class ProductListCheckResetAction implements Action {
  readonly type = ProductActionTypes.PRODUCTS_CHECK_RESET;
  constructor() {}
}

export type ProductActions =
  | ProductListRequestAction
  | ProductListSuccessAction
  | ProductListSelectStatusRequestAction
  | ProductListSelectStatusSuccessAction
  | ProductListSelectStatusResetDelete
  | ProductListCheckAction
  | ProductListCheckAllAction
  | ProductListUnCheckAction
  | ProductListUnCheckAllAction
  | ProductListCheckResetAction;
