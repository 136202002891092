import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StoreAssortmentService } from '../../services/store-assortment.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { SpecialAssortmentActionType, SpecialAssortmentListResponseAction } from '../actions/special-assortment.actions';
export class SpecialAssortmentEffects {
    constructor(actions$, storeAssortmentService, logger) {
        this.actions$ = actions$;
        this.storeAssortmentService = storeAssortmentService;
        this.logger = logger;
        this.searchSpecialAssortment$ = this.actions$.pipe(ofType(SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.searchAssortmentByCriteria(payload.storeNo, payload.criteria).pipe(map(response => {
                return new SpecialAssortmentListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SpecialAssortmentEffects.prototype, "searchSpecialAssortment$", void 0);
