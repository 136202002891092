import { createSelector } from '@ngrx/store';
import * as fromOrder from '../reducers/order.reducers';
import { AppStates } from '../state/app.states';

const selectOrderState = (state: AppStates) => state.order;

export const selectOrderList = createSelector(selectOrderState, fromOrder.selectAll);

export const selectOrder = createSelector(selectOrderState, (state: fromOrder.OrderState) => state);

export const selectViewOrder = createSelector(selectOrderState, (state: fromOrder.OrderState) => state.viewOrder);
