import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplierStorePagination } from '../models/supplier-group-request.model';
import {
  StoreSupplierSearchCriteria,
  SupplierGroup,
  SupplierGroupPagination,
  SupplierGroupSearchCriteria
} from '../models/supplier-group.model';
import { BaseService } from './base.service';

@Injectable()
export class SupplierGroupService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.supplierGroup;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: SupplierGroupSearchCriteria): Observable<SupplierGroupPagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<SupplierGroupPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getSupplierGroupById(id: string): Observable<SupplierGroup> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getStoresSupplierBySupplierGroupId(
    storeSupplierSearchCriteria: StoreSupplierSearchCriteria
  ): Observable<SupplierStorePagination> {
    const url = this.getFullUrl(this.envService.getStoreSupplier, {
      id: storeSupplierSearchCriteria.id
    });

    const params = this.getParams(storeSupplierSearchCriteria.searchCriteria, true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  export(supplierGroupNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.export, { supplierGroupNo });

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  getUrlImport(supplierGroupNo) {
    return this.getFullUrl(this.envService.import, { supplierGroupNo });
  }
}
