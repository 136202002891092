import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MasterAssortmentContent, MasterAssortmentListSearchCriteria } from '../../models/store-assortment.model';
import { MasterAssortmentActions, MasterAssortmentActionType } from '../actions/master-assortment.actions';
import { BaseState } from '../state/base.state';

export interface MasterAssortmentState extends EntityState<MasterAssortmentContent>, BaseState {
  criteriaObject: MasterAssortmentListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<MasterAssortmentContent> = createEntityAdapter<MasterAssortmentContent>({
  selectId: (masterAssortment: MasterAssortmentContent) => `${masterAssortment.shelfNo}-${masterAssortment.barcode}`
});

export const initialMasterAssortmentState: MasterAssortmentState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20
});

export function masterAssortmentReducers(
  state = initialMasterAssortmentState,
  action: MasterAssortmentActions
): MasterAssortmentState {
  switch (action.type) {
    case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESET:
      return initialMasterAssortmentState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
