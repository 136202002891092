import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { environment, environment as env } from '../../../../../environments/environment';
import { AssortmentAllType, AssortmentPageModes } from '../../../../shared/models';
import { pickingUnitLOV } from '../../../../shared/models/list-value/list-key-value.model';
import { SupplierSearch } from '../../../../shared/models/supplier.model';
import { WarehouseListContent } from '../../../../shared/models/warehouse.model';
import { SupplierService } from '../../../../shared/services/supplier.service';
import { selectAllWarehouse } from '../../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { alwaysRoundUp } from '../../../../shared/utils/number-util';

const moment = extendMoment(Moment);

@Component({
  selector: 'app-add-supplier-price',
  templateUrl: './add-supplier-price.component.html',
  styleUrls: ['./add-supplier-price.component.scss']
})
export class AddSupplierPriceComponent implements OnInit, OnDestroy {
  @Input() isSubmit: boolean;
  @Input() supplierDetailsForm: FormGroup;
  @Input() productDetailsForm: FormGroup;
  @Input() listOfValue: {};
  @Input() isRequestViewMode: boolean;
  @Input() supplierPriceForm: any;
  @Input() editingSupplierPriceIndex: number;
  @Input() mode: AssortmentPageModes;
  @Input() useCJProduct: boolean;
  @Input() errorSupplierPriceDisplay: {
    [key: number]: {
      orderBarcode: boolean;
      unitFactor: number;
      tdPickingUnit: string;
    };
  } = {};

  public assortmentAllType = AssortmentAllType;
  public supplierList: Observable<SupplierSearch[]>;
  public addSupplierPriceForm: FormGroup;
  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();
  public dateFormat = environment.dateFormat;
  public listOfOrderUnit: {};
  public target: WarehouseListContent[] = [];
  public source: WarehouseListContent[] = [];
  public isSubmitAddSupplierPrice = false;
  public bsConfig: BsDatepickerConfig;
  public listOfChange = {};
  /** Duel list box related initial value **/
  public key: string;
  public display: any;
  public assortmentPageMode = AssortmentPageModes;
  public expireDateMinDate = new Date();
  public additionalCharacters: RegExp = new RegExp('[-]', 'gi');
  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected supplierService: SupplierService,
    protected bsModalRef: BsModalRef,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.listOfOrderUnit = pickingUnitLOV;
    this.setInitialElementValue();
    this.createSupplierPriceForm();

    let type = null;
    if (this.useCJProduct) {
      type = 'Warehouse';
    }
    /** Duel list box related initial value **/
    this.store
      .select(selectAllWarehouse(type))
      .pipe(untilComponentDestroyed(this))
      .subscribe(warehouse => {
        if (warehouse) {
          this.source = warehouse;
        }
      });

    this.key = 'id';
    this.display = this.warehouseLabel;
    this.loadSupplier('');
  }

  ngOnDestroy() {
    this.isSubmitAddSupplierPrice = false;
    this.editingSupplierPriceIndex = null;
    this.addSupplierPriceForm.reset();
    this.target = [];
  }

  setInitialElementValue() {
    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  logForm() {
    this.logger.debug('this.addSupplierPriceForm ', this.addSupplierPriceForm);
    this.logger.debug('this.supplierDetailsForm ', this.supplierDetailsForm);
    this.logger.debug('productDetailsForm ', this.productDetailsForm);
    this.logger.debug('this.target ', this.target);
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  onChangeSupplierName(value) {
    this.addSupplierPriceForm.patchValue({
      supplierCode: value ? value.supplierCode : null
    });
  }

  onBlurSupplierName() {
    if (!this.addSupplierPriceForm.controls.supplier.value) {
      this.loadSupplier('');
    }
  }

  hideModalAddSupplierPrice() {
    this.bsModalRef.hide();
  }

  saveSupplierPrice() {
    this.isSubmitAddSupplierPrice = true;
    const addSupplierFormRaw = this.fb.group(this.addSupplierPriceForm.getRawValue());
    addSupplierFormRaw.patchValue({ warehouses: this.target, tdPickingUnit: null });
    this.validateNameAndPrice(addSupplierFormRaw);
    this.validateEffectiveDateAdnExpireDate();
    if (this.target.length === 0 && this.productDetailsForm.controls.deliveryMethod.value !== 'SUPPLIER') {
      return;
    }
    if (this.addSupplierPriceForm.valid) {
      if (this.editingSupplierPriceIndex !== null) {
        this.supplierPrices.at(this.editingSupplierPriceIndex).patchValue(addSupplierFormRaw.getRawValue());
        this.resetErrorSupplierPriceDisplay(this.editingSupplierPriceIndex);
      } else {
        this.supplierPrices.push(addSupplierFormRaw);
      }
      this.hideModalAddSupplierPrice();
    } else {
      return;
    }
  }

  resetErrorSupplierPriceDisplay(i: number = null) {
    if (i !== null) {
      Object.keys(this.errorSupplierPriceDisplay).forEach(key => {
        if (key === String(i)) {
          delete this.errorSupplierPriceDisplay[i];
        }
      });
    } else {
      // Clear all errors
      Object.keys(this.errorSupplierPriceDisplay).forEach(key => delete this.errorSupplierPriceDisplay[key]);
    }
  }

  createSupplierPriceForm() {
    const isDisable =
      [AssortmentPageModes.ASSORTMENT_VIEW, AssortmentPageModes.REQUEST_VIEW].includes(this.mode) ||
      this.useCJProduct;
    const isDirectToStore = this.productDetailsForm.controls.deliveryMethod.value === 'SUPPLIER';
    const isAfterEffectiveDateAndAssortmentEdit =
      [AssortmentPageModes.ASSORTMENT_EDIT].includes(this.mode) &&
      (this.supplierPriceForm
        ? moment(this.supplierPriceForm.effectiveDate)
            .startOf('day')
            .isSameOrBefore(moment().startOf('day'), 'day')
        : false);
    let supplierObject = null;
    if (isDirectToStore && this.productDetailsForm.controls.supplierGroup.value !== null) {
      const supplierGroup = this.productDetailsForm.controls.supplierGroup.value;

      supplierObject = {
        supplierName: supplierGroup.supplierGroupName,
        supplierCode: supplierGroup.supplierGroupNo,
        branchNo: supplierGroup.supplierGroupNo
      };
    }
    this.addSupplierPriceForm = this.fb.group({
      supplier: [
        {
          value: this.supplierPriceForm ? this.supplierPriceForm.supplier : isDirectToStore ? supplierObject : null,
          disabled: isDirectToStore || isDisable
        },
        Validators.required
      ],
      orderBarcode: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.orderBarcode
              ? this.supplierPriceForm.orderBarcode
              : null,
          disabled: isDisable
        },
        Validators.required
      ],
      supplierPrice: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.supplierPrice !== null
              ? this.supplierPriceForm.supplierPrice
              : null,
          disabled: isDisable
        },
        Validators.required
      ],
      supplierUnit: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.supplierUnit
              ? this.supplierPriceForm.supplierUnit
              : null,
          disabled: isDisable
        },
        Validators.required
      ],
      sourceSupplierUnit: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.sourceSupplierUnit
              ? this.supplierPriceForm.sourceSupplierUnit
              : null,
          disabled: true
        }
      ],
      supplierUnitFactor: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.supplierUnitFactor
              ? this.supplierPriceForm.supplierUnitFactor
              : null,
          disabled: isDisable
        },
        [Validators.required, this.notAllowZeroValidator()]
      ],
      avgSupplierPrice: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.avgSupplierPrice
              ? this.supplierPriceForm.avgSupplierPrice
              : null,
          disabled: true
        }
      ],
      effectiveDate: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.effectiveDate
              ? new Date(this.supplierPriceForm.effectiveDate)
              : null,
          disabled: isDisable || isAfterEffectiveDateAndAssortmentEdit
        },
        Validators.required
      ],
      expireDate: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.expireDate
              ? new Date(this.supplierPriceForm.expireDate)
              : null,
          disabled: isDisable
        },
        Validators.required
      ],
      warehouses: [
        {
          value:
            this.supplierPriceForm && this.supplierPriceForm.warehouses ? this.supplierPriceForm.warehouses : null,
          disabled: isDisable
        }
      ],
      isDelete: null,
      tdPickingUnit: null
    });

    this.addSupplierPriceForm.controls.supplierPrice.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(supplierPrice => {
        const unitFactor = this.addSupplierPriceForm.controls.supplierUnitFactor.value;
        let averageSupplierPrice;
        if (supplierPrice && unitFactor) {
          averageSupplierPrice = supplierPrice / unitFactor;
        } else {
          averageSupplierPrice = 0;
        }
        averageSupplierPrice
          ? this.addSupplierPriceForm.controls.avgSupplierPrice.patchValue(
              round(alwaysRoundUp(averageSupplierPrice, 3), 2)
            )
          : this.addSupplierPriceForm.controls.avgSupplierPrice.patchValue(averageSupplierPrice);
      });

    this.addSupplierPriceForm.controls.supplierUnitFactor.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(unitFactor => {
        const supplierPrice = this.addSupplierPriceForm.controls.supplierPrice.value;
        let averageSupplierPrice;
        if (supplierPrice && unitFactor) {
          averageSupplierPrice = supplierPrice / unitFactor;
        } else {
          averageSupplierPrice = 0;
        }
        averageSupplierPrice
          ? this.addSupplierPriceForm.controls.avgSupplierPrice.patchValue(
              round(alwaysRoundUp(averageSupplierPrice, 3), 2)
            )
          : this.addSupplierPriceForm.controls.avgSupplierPrice.patchValue(averageSupplierPrice);
      });

    this.addSupplierPriceForm.controls.effectiveDate.valueChanges
      .pipe(untilComponentDestroyed(this))
      .subscribe(() => {
        this.expireDateMinDate = this.addSupplierPriceForm.controls.effectiveDate.value;
      });

    if (
      this.addSupplierPriceForm.get('warehouses').value &&
      (this.addSupplierPriceForm.get('warehouses').value as Array<WarehouseListContent>).length > 0
    ) {
      (this.addSupplierPriceForm.get('warehouses').value as Array<WarehouseListContent>).forEach(target => {
        this.target.push(target);
      });
    }
  }

  validateNameAndPrice(addSupplierPrice) {
    const currentSupplierPrice = addSupplierPrice.getRawValue();
    const supplierPriceList = [];
    this.supplierPrices.getRawValue().forEach(e => {
      supplierPriceList.push(e);
    });

    if (this.productDetailsForm.controls.deliveryMethod.value !== 'SUPPLIER') {
      const sameWareHouseSupplierPriceList = supplierPriceList.filter((supplierWarehouse, index) => {
        if (supplierWarehouse.warehouses && !supplierWarehouse.isDelete) {
          return this.editingSupplierPriceIndex !== null
            ? this.editingSupplierPriceIndex !== index &&
                supplierWarehouse.warehouses.some(warehouse =>
                  currentSupplierPrice.warehouses.some(currentWarehouse => currentWarehouse.id === warehouse.id)
                )
            : supplierWarehouse.warehouses.some(warehouse =>
                currentSupplierPrice.warehouses.some(currentWarehouse => currentWarehouse.id === warehouse.id)
              );
        }
      });

      const isDuplicateWarehouseSamePeriod = sameWareHouseSupplierPriceList.some(supplierPrice => {
        return (
          supplierPrice.supplier.supplierCode === currentSupplierPrice.supplier.supplierCode &&
          moment
            .range(moment(currentSupplierPrice.effectiveDate), moment(currentSupplierPrice.expireDate))
            .overlaps(moment.range(moment(supplierPrice.effectiveDate), moment(supplierPrice.expireDate)), {
              adjacent: true
            })
        );
      });

      if (isDuplicateWarehouseSamePeriod) {
        this.addSupplierPriceForm.controls.supplier.setErrors({ duplicateWarehouseSamePeriod: true });
      }

      const samePeriodSupplierPrice = supplierPriceList.filter((supplierPrice, index) => {
        if (!supplierPrice.isDelete) {
          return this.editingSupplierPriceIndex !== null
            ? this.editingSupplierPriceIndex !== index &&
                moment
                  .range(moment(currentSupplierPrice.effectiveDate), moment(currentSupplierPrice.expireDate))
                  .overlaps(moment.range(moment(supplierPrice.effectiveDate), moment(supplierPrice.expireDate)), {
                    adjacent: true
                  })
            : moment
                .range(moment(currentSupplierPrice.effectiveDate), moment(currentSupplierPrice.expireDate))
                .overlaps(moment.range(moment(supplierPrice.effectiveDate), moment(supplierPrice.expireDate)), {
                  adjacent: true
                });
        }
      });

      const differentPriceSamePeriod = samePeriodSupplierPrice.some(
        sameDate => sameDate.supplierPrice !== currentSupplierPrice.supplierPrice
      );
      if (differentPriceSamePeriod) {
        this.addSupplierPriceForm.controls.supplierPrice.setErrors({ differentPriceSamePeriod: true });
      }
    } else if (this.productDetailsForm.controls.deliveryMethod.value === 'SUPPLIER') {
      const samePeriodSupplierPrice = supplierPriceList.filter((supplierPrice, index) => {
        if (!supplierPrice.isDelete) {
          return this.editingSupplierPriceIndex !== null
            ? this.editingSupplierPriceIndex !== index &&
                moment
                  .range(moment(currentSupplierPrice.effectiveDate), moment(currentSupplierPrice.expireDate))
                  .overlaps(moment.range(moment(supplierPrice.effectiveDate), moment(supplierPrice.expireDate)), {
                    adjacent: true
                  })
            : moment
                .range(moment(currentSupplierPrice.effectiveDate), moment(currentSupplierPrice.expireDate))
                .overlaps(moment.range(moment(supplierPrice.effectiveDate), moment(supplierPrice.expireDate)), {
                  adjacent: true
                });
        }
      });

      if (samePeriodSupplierPrice.length > 0) {
        this.addSupplierPriceForm.controls.effectiveDate.setErrors({ duplicated: true });
        this.addSupplierPriceForm.controls.expireDate.setErrors({ duplicated: true });
        return;
      }
    }
  }

  validateEffectiveDateAdnExpireDate() {
    const effectiveDate = this.addSupplierPriceForm.controls.effectiveDate.value;
    const expireDate = this.addSupplierPriceForm.controls.expireDate.value;

    if (moment(expireDate).isBefore(effectiveDate)) {
      ///////// set error here
      this.addSupplierPriceForm.controls.effectiveDate.setErrors({ invalidDate: true });
      this.addSupplierPriceForm.controls.expireDate.setErrors({ invalidDate: true });
      return;
    }
  }

  clearDateError(value: Date) {
    if (value) {
      if (this.addSupplierPriceForm.controls.effectiveDate.value) {
        this.addSupplierPriceForm.controls.effectiveDate.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.expireDate.value) {
        this.addSupplierPriceForm.controls.expireDate.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.supplier.value) {
        this.addSupplierPriceForm.controls.supplier.setErrors(null);
      }
      if (this.addSupplierPriceForm.controls.supplierPrice.value) {
        this.addSupplierPriceForm.controls.supplierPrice.setErrors(null);
      }
    }
  }

  notAllowZeroValidator = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return Number(control.value) === 0 ? { isZero: true } : null;
    };
  };

  get supplierPrices() {
    return this.supplierDetailsForm.get('supplierPrices') as FormArray;
  }

  private warehouseLabel(item: any) {
    return item.code + ': ' + item.name;
  }
}
