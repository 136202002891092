import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DisableButtonService } from '../services/disable-button.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private readonly disableButtonService: DisableButtonService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      request = request.clone({
        setHeaders: {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
        }
      });
    }

    return next.handle(request).pipe(
      tap(() => {
        this.disableButtonService.enable();
      })
    );
  }
}
