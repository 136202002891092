<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-product"></em>
    <h4>TD Assortment</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form
    class="flex-item d-md-flex justify-content-between align-items-center"
    [formGroup]="searchForm"
    (ngSubmit)="doSearchNewCriteria()"
  >
    <div class="form-group text-nowrap" *ngIf="hasEditPermission || hasEditNonMerchantPermission">
      <button class="btn btn-primary col-sm-12" type="button" (click)="checkCreateNewProductPermission()">
        <em class="icon-plus"></em>
        Create New Product
      </button>
    </div>

    <div class="d-md-flex flex-fill justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            class="searchCriteria form-control"
            placeholder="Enter keyword search"
            formControlName="searchCriteria"
            (keyup.backspace)="handleBackspace()"
            maxlength="50"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="filter-dropdown flex-item form-group ml-md-2">
        <ng-select
          [searchable]="false"
          [clearable]="false"
          [items]="statusList"
          bindLabel="label"
          bindValue="value"
          formControlName="status"
          (change)="onChangeStatus($event)"
        >
        </ng-select>
      </div>
      <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
        <button
          class="btn advance-filter-button btn-advance-filter"
          [ngClass]="{ active: isShowAdvanceSearch }"
          type="button"
          (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
        >
          <em class="icon-filter"></em>
          Advanced Filters
        </button>
        <div
          class="box-dropdown"
          [ngClass]="{ open: isShowAdvanceSearch }"
          (appClickOutside)="onClickedOutside($event)"
          [exclude]="['.btn-advance-filter, .is-highlighted']"
        >
          <div class="box-dropdown-list advance-filter-dropdown">
            <div class="box-dropdown-body px-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">Requested Date</div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>From</label>
                      <input
                        id="dateFrom"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        class="form-control calendar"
                        autocomplete="off"
                        bsDatepicker
                        [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: dateFormat,
                          showWeekNumbers: false
                        }"
                        [maxDate]="maxDate"
                        (bsValueChange)="onChangeDateFrom($event)"
                        formControlName="dateFrom"
                        readonly
                      />
                      <label for="dateFrom" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>To</label>
                      <input
                        id="dateTo"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        class="form-control calendar"
                        autocomplete="off"
                        bsDatepicker
                        [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: dateFormat,
                          showWeekNumbers: false
                        }"
                        [minDate]="minDate"
                        (bsValueChange)="onChangeDateTo($event)"
                        formControlName="dateTo"
                        readonly
                      />
                      <label for="dateTo" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="mb-2">Request Type</div>
                      <ng-select
                        bindLabel="label"
                        bindValue="value"
                        [searchable]="false"
                        [multiple]="true"
                        [items]="requestTypeList"
                        placeholder="Please select..."
                        formControlName="requestType"
                      >
                      </ng-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="advance-filter-footer text-center">
              <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                Cancel
              </button>
              <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateStringTag || requestStringTypeTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="requestStringTypeTag">
      <a class="remove-criteria" (click)="clearRequestType()">
        <em class="icon-close"></em>
      </a>
      <span>{{ requestStringTypeTag }}: {{ requestTypeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <div>
    <div class="no-result-container text-center" *ngIf="(listState$ | async).totalElements == 0">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
    <div *ngIf="(listState$ | async).totalElements">
      <div class="table-responsive">
        <table id="datatable" class="table table-striped table-bordered table-hover w-100">
          <thead>
            <tr>
              <th class="text-center w-10">{{ headerRow[0] }}</th>
              <th class="text-center w-10">{{ headerRow[1] }}</th>
              <th class="text-center w-25">{{ headerRow[2] }}</th>
              <th class="text-center w-10">{{ headerRow[3] }}</th>
              <th class="text-center w-15">{{ headerRow[4] }}</th>
              <th class="text-center w-10">{{ headerRow[5] }}</th>
              <th class="text-center w-15">{{ headerRow[6] }}</th>
              <th class="text-center w-5">{{ headerRow[7] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let assortment of resultList$ | async; index as i">
              <td (click)="goToView(assortment)">
                {{ assortment.requestNo | dashDisplay: '(None)' }}
              </td>
              <td (click)="goToView(assortment)">
                {{ assortment.articleNo | dashDisplay }}
              </td>
              <td (click)="goToView(assortment)">
                {{ assortment.productName | dashDisplay }}
              </td>
              <td (click)="goToView(assortment)">
                {{ 'PRODUCT_ASSORTMENT.REQUEST_TYPE.' + assortment.type | translate }}
              </td>
              <td (click)="goToView(assortment)">
                {{ assortment.requestedByName | dashDisplay }}
              </td>
              <td (click)="goToView(assortment)">
                {{ assortment.requestedDate | amFromUtc | amLocal | dateDisplay }}
              </td>
              <td
                (click)="goToView(assortment)"
                [ngClass]="getColorStatus(assortment.status)"
                class="assortment-status"
              >
                {{ 'PRODUCT_ASSORTMENT.STATUS.' + assortment.status | translate }}
              </td>
              <td class="text-center position-relative">
                <a
                  href="javascript:void(0)"
                  class="btn-more mx-auto"
                  [popover]="moreTemplate"
                  placement="bottom"
                  containerClass="fl"
                  container=".popover-container"
                  [outsideClick]="true"
                >
                  <em class="icon-more"></em>
                </a>
                <ng-template #moreTemplate>
                  <div class="form-row">
                    <button
                      type="button"
                      class="btn btn-link"
                      *ngIf="
                        (assortment.status === assortmentRequestStatusEnum.DRAFT ||
                          assortment.status === assortmentRequestStatusEnum.AWAITING_APPROVAL) &&
                        canEditRequest(assortment.articleType)
                      "
                      (click)="goToEdit(assortment)"
                    >
                      <em class="icon-edit"></em>Edit
                    </button>
                    <button
                      type="button"
                      class="btn btn-link"
                      *ngIf="
                        assortment.status === assortmentRequestStatusEnum.DRAFT &&
                        canEditRequest(assortment.articleType)
                      "
                      (click)="showConfirmDeleteModal(assortment.id)"
                    >
                      <em class="icon-delete"></em>Delete
                    </button>
                    <button
                      type="button"
                      class="btn btn-link"
                      *ngIf="
                        assortment.status === assortmentRequestStatusEnum.AWAITING_APPROVAL &&
                        canEditRequest(assortment.articleType)
                      "
                      (click)="showConfirmCancelModal(assortment.id, assortment.requestNo)"
                    >
                      <em class="icon-forbidden"></em>Cancel Request
                    </button>
                    <button
                      type="button"
                      class="btn btn-link"
                      *ngIf="
                        [
                          assortmentRequestStatusEnum.APPROVED,
                          assortmentRequestStatusEnum.AWAITING_APPROVAL,
                          assortmentRequestStatusEnum.REJECTED,
                          assortmentRequestStatusEnum.CANCELLED
                        ].includes(assortment.status) &&
                        (canEditRequest(assortment.articleType) || canApproveOrRejectRequest(assortment.articleType))
                      "
                      (click)="showHistory(assortment.requestNo, assortment.auditLogs)"
                    >
                      <em class="icon-history"></em>History
                    </button>
                  </div>
                </ng-template>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </div>

  <div class="modal fade" bsModal #modalCreateNewProduct="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Product Type</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalCreateNewProduct()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Product Type:</div>
              <ng-select
                bindLabel="label"
                bindValue="value"
                [searchable]="false"
                [clearable]="false"
                [items]="productTypeList"
                placeholder="Please select..."
                [(ngModel)]="productType"
                (change)="onChangeProductType()"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            [disabled]="!articleType"
            (click)="goToCreate()"
            appPreventDoubleClick
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
