import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StoreSupplierRequestSearchCriteria,
  SupplierGroupRequest,
  SupplierGroupRequestApproveRejectRequest,
  SupplierGroupRequestObject,
  SupplierGroupRequestPagination,
  SupplierGroupRequestSearchCriteria,
  SupplierStorePagination
} from '../models/supplier-group-request.model';
import { BaseService } from './base.service';

@Injectable()
export class SupplierGroupRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.supplierGroupRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: SupplierGroupRequestSearchCriteria): Observable<SupplierGroupRequestPagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<SupplierGroupRequestPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getSupplierGroupRequestById(id: string): Observable<SupplierGroupRequest> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getStoresSupplierBySupplierGroupId(
    storeSupplierRequestSearchCriteria: StoreSupplierRequestSearchCriteria
  ): Observable<SupplierStorePagination> {
    const url = this.getFullUrl(this.envService.getStoreSupplier, {
      id: storeSupplierRequestSearchCriteria.id
    });

    const params = this.getParams(storeSupplierRequestSearchCriteria.searchCriteria, true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  save(supplierGroup: SupplierGroupRequestObject): Observable<SupplierGroupRequest> {
    const url = this.getUrl();
    return this.http.post<SupplierGroupRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  update(supplierGroup: SupplierGroupRequestObject): Observable<SupplierGroupRequest> {
    const url = this.getUrl();
    return this.http.put<SupplierGroupRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  submit(supplierGroup: SupplierGroupRequestObject): Observable<SupplierGroupRequest> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<SupplierGroupRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  exportTemplate(): Observable<any> {
    const url = this.getFullUrl(this.envService.exportTemplate);
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  approve(
    supplierGroup: SupplierGroupRequestApproveRejectRequest
  ): Observable<SupplierGroupRequestApproveRejectRequest> {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<SupplierGroupRequestApproveRejectRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  reject(
    supplierGroup: SupplierGroupRequestApproveRejectRequest
  ): Observable<SupplierGroupRequestApproveRejectRequest> {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<SupplierGroupRequestApproveRejectRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  cancel(
    supplierGroup: SupplierGroupRequestApproveRejectRequest
  ): Observable<SupplierGroupRequestApproveRejectRequest> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<SupplierGroupRequestApproveRejectRequest>(url, supplierGroup, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  delete(id: string): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });
    return this.http.delete<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  checkExisting(supplierGroupNo: string): Observable<SupplierGroupRequest> {
    const url = this.getFullUrl(this.envService.checkExisting, {
      supplierGroupNo
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  getUrlImport() {
    return this.getFullUrl(this.envService.import);
  }
}
