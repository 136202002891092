import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse, StoreCreateRequest } from '../../models';
import { StoreRequestActions, StoreRequestActionType } from '../actions/store-request.actions';
import { BaseState } from '../state/base.state';

export interface StoreCreateResponseState extends EntityState<StoreCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: StoreCreateRequest | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<StoreCreateRequest> = createEntityAdapter<StoreCreateRequest>();

export const initialStoreCreateResponseState: StoreCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function storeCreateReducers(
  state = initialStoreCreateResponseState,
  action: StoreRequestActions
): StoreCreateResponseState {
  switch (action.type) {
    case StoreRequestActionType.STORE_CREATE_SAVE_RESPONSE:
    case StoreRequestActionType.STORE_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: StoreRequestActionType.STORE_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case StoreRequestActionType.STORE_CREATE_SUBMIT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case StoreRequestActionType.STORE_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case StoreRequestActionType.STORE_CREATE_RESET:
      return initialStoreCreateResponseState;
    default: {
      return state;
    }
  }
}
