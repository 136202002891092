import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { ModeEnum } from '../../../shared/components/edit-in-place/edit-in-place.component';
import { AssortmentFreshLiteTypeEnum, FreshLiteCategory } from '../../../shared/models';
import { FreshLiteService } from '../../../shared/services/fresh-lite.service';
import { FreshLiteListRequest, UpdateFreshLiteRequest } from '../../../shared/store/actions/fresh-lite.actions';
import {
  selectFreshLiteDuplicateName,
  selectFreshLiteList
} from '../../../shared/store/selectors/fresh-lite.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-assortment-request-fresh-lite',
  templateUrl: './assortment-request-fresh-lite.component.html',
  styleUrls: ['./assortment-request-fresh-lite.component.scss']
})
export class AssortmentRequestFreshLiteComponent implements OnInit, OnDestroy {
  @Input() listOfValue: [];
  public freshLiteForm: FormGroup;
  public resultList$: Observable<FreshLiteCategory[]>;
  public isAdded: boolean;
  public isSubmitted: boolean;

  private localStore: Observable<any>;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private freshLiteService: FreshLiteService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createForm();
    this.initialSubscription();

    this.loadFreshLiteList();
  }

  ngOnDestroy(): void {
    this.loadFreshLiteList();
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    this.freshLiteForm = this.fb.group({
      editInPlaceMode: ModeEnum.VIEW,
      name: initialNullRequired,
      type: initialNullRequired,
      freshLiteItem: this.fb.array([])
    });
  }

  createItemForm(freshLite: FreshLiteCategory) {
    return this.fb.group({
      id: freshLite.id,
      code: freshLite.code,
      name: [
        { value: freshLite.name, disabled: false },
        {
          validators: [Validators.required, this.isDuplicateValidator('name')],
          updateOn: 'change'
        }
      ],
      type: freshLite.type,
      previousType: freshLite.type
    });
  }

  isDuplicateValidator(controlName): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value.trim() && this.formFreshLiteItem.controls.length > 1) {
        const isDuplicated = this.formFreshLiteItem.controls
          .map((formGroup: AbstractControl | any) => formGroup.controls[controlName])
          .filter(formControl => formControl !== control)
          .some((formControl: FormControl) => formControl.value === control.value);

        return isDuplicated ? { duplicated: isDuplicated } : null;
      }
      return null;
    };
  }

  initialSubscription() {
    this.localStore.pipe(select(selectFreshLiteList), take(1)).subscribe(resultList => {
      resultList.forEach(freshLite => {
        this.formFreshLiteItem.push(this.createItemForm(freshLite));
      });
    });

    this.localStore
      .pipe(
        select(selectFreshLiteDuplicateName),
        filter(response => Boolean(response))
      )
      .subscribe(response => {
        if (response && response.duplicateName.length > 0) {
          this.formFreshLiteItem.controls.forEach((item, indexItem) => {
            response.duplicateName.forEach(name => {
              if (item.get('name').value === name) {
                this.formFreshLiteItem
                  .at(indexItem)
                  .get('name')
                  .setErrors({ duplicated: true });
              }
            });
          });
        } else {
          this.decline();
        }
      });

    this.formFreshLiteItem.controls.forEach(freshForm => {
      const code = freshForm.get('code').value;
      freshForm
        .get('type')
        .valueChanges.pipe(untilComponentDestroyed(this))
        .subscribe(type => {
          const previousType = freshForm.get('previousType').value;
          if (previousType !== type) {
            this.allowChangeFreshLite(code, 'type', freshForm);
          } else {
            freshForm.get('type').setErrors(null);
          }
        });
    });
  }

  allowChangeFreshLite(freshLiteCode: string, forName: string, control: AbstractControl) {
    this.freshLiteService
      .allowEditFreshLite(freshLiteCode)
      .pipe(
        untilComponentDestroyed(this),
        switchMap(() => {
          control.get(forName).setErrors({ duplicated: true });
          return of(null);
        }),
        catchError(() => of(null))
      )
      .subscribe();
  }

  loadFreshLiteList(): void {
    this.store.dispatch(new FreshLiteListRequest());
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  addFreshLiteCategory(): void {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }

    let isValid = true;
    const name = this.freshLiteForm.value.name.trim();
    const nameForm = this.freshLiteForm.get('name');
    if (name && name !== '') {
      this.formFreshLiteItem.controls.forEach(item => {
        if (item.get('name').value === name.trim()) {
          nameForm.setErrors({ duplicated: true });
          isValid = false;
          return;
        }
      });
    }
    if (!isValid) {
      return;
    }

    nameForm.setErrors(null);

    const rawValue = this.freshLiteForm.getRawValue();
    const freshLiteRequest = {
      id: null,
      code: null,
      name,
      type: AssortmentFreshLiteTypeEnum[rawValue.type]
    } as FreshLiteCategory;

    this.isSubmitted = false;
    this.isAdded = true;
    this.formFreshLiteItem.controls.unshift(this.createItemForm(freshLiteRequest));
    this.form.get('name').reset();
    this.form.get('type').reset();
  }

  onSubmit() {
    if (this.formFreshLiteItem.invalid) {
      return;
    }
    if (this.isAdded || this.form.dirty) {
      this.store.dispatch(new UpdateFreshLiteRequest(this.formFreshLiteItem.getRawValue()));
    } else {
      this.decline();
    }
  }

  get form(): FormGroup {
    return this.freshLiteForm;
  }

  get formFreshLiteItem() {
    return this.form.get('freshLiteItem') as FormArray;
  }

  getDropdownTypeLabel(selected: string) {
    return this.listOfValue['freshLiteType'].find(data => data.value === selected).label;
  }

  onEditInPlaceModeChange(editInPlaceMode) {
    this.form.get('editInPlaceMode').setValue(editInPlaceMode);

    if (editInPlaceMode === ModeEnum.FORCE_VIEW) {
      setTimeout(() => {
        this.form.get('editInPlaceMode').setValue(ModeEnum.VIEW);
      }, 0);
    }
  }
}
