import { HttpErrorResponse } from '@angular/common/http';
import { ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { tap } from 'rxjs/operators';
import { AlertModalComponent } from '../shared/layouts';
import { CommonUploadRequest, CommonUploadService } from '../shared/services/common.upload.service';

type AllowedExtType = RegExp | string | string[];

export class BaseUploadButtonComponent implements OnInit, OnDestroy {
  public files: File[];

  @Input()
  set allowedExt(value: AllowedExtType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  @Output()
  public itemDetail: EventEmitter<any> = new EventEmitter();

  constructor(
    protected readonly modalService: BsModalService,
    protected uploadService: CommonUploadService,
    protected readonly translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected readonly logger: NGXLogger
  ) {
    this.disabled = false;
  }

  get allowedExt(): AllowedExtType {
    return this._allowedExt;
  }

  protected _allowedExt: AllowedExtType;
  url = '';
  value;
  validator: AsyncValidatorFn;
  progress: Array<CommonUploadRequest>;

  protected uploadInput: ElementRef;

  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: AllowedExtType;
  @Input() size: number;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() uploadUrl: string;
  @Input() uploadHeaders: any;
  @Input() baseStorageUrl: string;
  @Input() controlName: string;
  @Input() isNew: boolean;
  @Input() fileSizeExceedMessage: string;

  ngOnDestroy(): void {}

  ngOnInit(): void {}

  handleFileInput() {
    this.files = [];
    const files: { [key: string]: File } = this.uploadInput.nativeElement.files;
    for (const key in files) {
      if (!isNaN(Number(key))) {
        this.files.push(files[key]);
      }
    }
    this.validate(this.files);
  }

  validate(files: File[]): ValidationErrors | null {
    if (!files || !files.length) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const f of files) {
      if (this.size && this.size < f.size) {
        errors['fileSize'] = true;
      }

      if (!this.allowedExt && !this.allowedTypes) {
        continue;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      errors = {
        ...errors,
        ...(extP &&
          !extP.test(f.name) && {
            fileExt: true
          }),
        ...(typeP &&
          f.type &&
          !typeP.test(f.type) && {
            fileType: true
          })
      };
    }

    if (Object.keys(errors).length) {
      this.uploadInput.nativeElement.value = '';
      const isFileTypeError = errors['fileType'] || errors['fileExt'];
      const isFileSizeError = errors['fileSize'];
      this.alertFailModal(isFileTypeError, isFileSizeError);

      return errors;
    } else if (this.uploadUrl) {
      this.spinner.show();
      this.progress = this.uploadService.upload(files, this.uploadUrl, this.uploadHeaders);
      this.progress.forEach((request: CommonUploadRequest) => {
        request.response
          .pipe(
            untilComponentDestroyed(this),
            tap(val => this.logger.debug(val))
          )
          .subscribe(
            resp => this.onHandleUploadSuccess(resp),
            err => this.onHandleUploadError({ value: err })
          );
      });
    } else {
      this.logger.error('UploadUrl should not be ', this.uploadUrl);
    }
    return null;
  }

  onHandleUploadSuccess(resp) {
    this.logger.debug('onHandleUploadSuccess', resp);
    this.itemDetail.emit(resp);
    this.alertSuccessUploadModal();
    this.spinner.hide();
  }

  onHandleUploadError({ value }: { value: HttpErrorResponse }) {
    this.logger.debug('onHandleUploadError', value);
    if (value.status === 400) {
      this.alertFailValidation(this.translate.instant(value.error.translateKey));
    }
    this.spinner.hide();
  }

  alertFailModal(
    isFileTypeError,
    isFileSizeError,
    initialState = {
      title: 'Failed',
      message: `${
        isFileTypeError
          ? 'Incorrect Format (allow only format file .xlsx)'
          : this.fileSizeExceedMessage
          ? this.fileSizeExceedMessage
          : 'File size limit exceeded. <br/><br/> Size up to 500 KB (Recommended upload size of 500 * 500 pixels.)'
      }`
    }
  ) {
    this.logger.debug('alertFailModal->isFileSizeError', isFileSizeError);
    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertFailValidation(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  alertSuccessUploadModal() {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Upload Completed',
        message: 'All data has been uploaded.'
      }
    });
  }

  generateRegExp(pattern: RegExp | string | string[]): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }
    return null;
  }
}
