import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';

const selectProductMasterState = (state: AppStates) => state.productMaster;

export const selectProductMasterView = (articleNo: string) =>
  createSelector(selectProductMasterState, productMasterState => {
    return productMasterState.entities[articleNo]
      ? productMasterState.entities[articleNo]
      : productMasterState.productMaster;
  });

export const selectProductMasterLoading = createSelector(
  selectProductMasterState,
  productMasterState => productMasterState.isLoading
);
