/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./Z8-factor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../shared/pipes/number-display.pipe";
import * as i3 from "@angular/common";
import * as i4 from "./Z8-factor.component";
import * as i5 from "ngx-bootstrap/modal";
var styles_Z8FactorComponent = [i0.styles];
var RenderType_Z8FactorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_Z8FactorComponent, data: {} });
export { RenderType_Z8FactorComponent as RenderType_Z8FactorComponent };
function View_Z8FactorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "row mt-2 td-info-modal-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "col-3 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.label; _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _co.data.z8Factor[_v.context.$implicit.fieldValue], 0, "N/A")); _ck(_v, 6, 0, currVal_1); }); }
export function View_Z8FactorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.NumberFormatDisplayPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "modal-header pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "td-modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h6", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Z8 Factor"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "sub-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ": ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["id", "confirm-modal-closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "em", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "row mt-2 td-info-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "col-9"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Factor "])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "col-3 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Value "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Z8FactorComponent_1)), i1.ɵdid(18, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.z8FactorDetails; _ck(_v, 18, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.articleNo; var currVal_1 = _co.data.productName; _ck(_v, 7, 0, currVal_0, currVal_1); }); }
export function View_Z8FactorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-z8-factor", [], null, null, null, View_Z8FactorComponent_0, RenderType_Z8FactorComponent)), i1.ɵdid(1, 114688, null, 0, i4.Z8FactorComponent, [i5.BsModalRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Z8FactorComponentNgFactory = i1.ɵccf("app-z8-factor", i4.Z8FactorComponent, View_Z8FactorComponent_Host_0, { data: "data" }, {}, []);
export { Z8FactorComponentNgFactory as Z8FactorComponentNgFactory };
