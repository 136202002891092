import gql from 'graphql-tag';

export const warehouseMasterQuery = gql`
  query {
    warehouses {
      code
      nameEn
      nameTh
      contactName
      contactNumber
      address
      fullNameTh
      fullNameEn
      wmsCode
      billTo {
        code
        nameEn
        nameTh
        contactName
        contactNumber
        address
      }
    }
  }
`;

export const warehouseQuery = gql`
  query {
    offices {
      id
      code
      nameTh
    }
    warehouses {
      id
      code
      nameTh
      wmsCode
    }
  }
`;
