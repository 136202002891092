import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { TdAssortmentService } from '../../services/td-assortment-service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { TdAssortmentActionTypes, TdAssortmentListResponse } from '../actions/td-assortment.actions';
import { selectAllTdAssortmentOrder } from '../selectors/td-assortment-order.selectors';
export class TdAssortmentEffects {
    constructor(actions$, tdAssortmentService, store, logger) {
        this.actions$ = actions$;
        this.tdAssortmentService = tdAssortmentService;
        this.store = store;
        this.logger = logger;
        this.tdAssortmentListRequest$ = this.actions$.pipe(ofType(TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST), tap(action => this.logger.debug('@Effect Purchase Request Get TdAssortment: ' + JSON.stringify(action.payload))), switchMap(action => this.tdAssortmentService.searchByCriteria(action.payload).pipe(tap(resp => this.logger.debug('@Effect Purchase Response Get TdAssortment: ' + JSON.stringify(resp))), withLatestFrom(this.store.select(selectAllTdAssortmentOrder)), map(([response, tdAssortmentOrder]) => {
            tdAssortmentOrder.forEach(order => {
                const foundObjectIndex = response.content.findIndex(content => {
                    return `${content.supplierCode}_${content.barcode}` === `${order.supplierCode}_${order.barcode}`;
                });
                if (response.content[foundObjectIndex]) {
                    response.content[foundObjectIndex].quantity = order.quantity;
                    if ('HOT_PRICE' === action.payload.purchaseType) {
                        response.content[foundObjectIndex].newUnitPrice = order.newUnitPrice
                            ? order.newUnitPrice
                            : order.unitPrice;
                    }
                }
            });
            return new TdAssortmentListResponse(response);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], TdAssortmentEffects.prototype, "tdAssortmentListRequest$", void 0);
