import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ChangePasswordResponse } from '../../models/force-change-password.model';
import { MerchantService } from '../../services/merchant.service';
import { UsersService } from '../../services/users.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  ActivateUserRequestAction,
  ActivateUserResponseAction,
  ForceChangePasswordFailed,
  ForceChangePasswordRequestAction,
  ForceChangePasswordSuccess,
  UserActionTypes,
  UserByUserNoRequestAction,
  UserByUserNoResponseAction,
  UserCreateRequestAction,
  UserCreateResponseErrorAction,
  UserListRequestAction,
  UserListResponseAction,
  UserPasswordResetAction,
  UserResetPasswordRequestAction,
  UserResetPasswordResponseAction,
  UsersResetPinCodeRequestAction,
  UsersResetPinCodeResponseAction,
  UserUpdateRequestAction,
  UserUpdateResponseErrorAction
} from '../actions/user-info.action';

@Injectable()
export class UserEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly usersService: UsersService,
    private readonly merchantService: MerchantService
  ) {}

  @Effect()
  searchUser$ = this.actions$.pipe(
    ofType<UserListRequestAction>(UserActionTypes.USER_LIST_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${UserActionTypes.USER_LIST_REQUEST}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          return new UserListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  UserPasswordReset: Observable<Action> = this.actions$.pipe(
    ofType<UserPasswordResetAction>(UserActionTypes.USER_PASSWORD_RESET),
    map(action => {
      this.logger.debug('@Effect UserPasswordResetAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.resetPassword(payload.userName).pipe(
        switchMap(() => {
          return this.usersService.resetPinCode(payload.userName);
        }),
        switchMap(() => {
          return this.merchantService.addResetOwnerLog({ merchant: payload.merchant });
        }),
        map(() => {
          return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Username & Password has been sent to the owner.'
          });
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  UserCreateRequest: Observable<Action> = this.actions$.pipe(
    ofType<UserCreateRequestAction>(UserActionTypes.USER_CREATE_REQUEST),
    map(action => {
      this.logger.debug('@Effect UserCreateRequestAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.crateUser(payload).pipe(
        map(() => this.layoutActionSaveSuccess('The user has been created.')),
        catchError(err => {
          return err && err.error && this.checkErrorCode(err.error.code)
            ? of(
                new UserCreateResponseErrorAction({
                  ...err.error
                })
              )
            : err && err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      );
    })
  );

  @Effect()
  UserUpdateRequest: Observable<Action> = this.actions$.pipe(
    ofType<UserUpdateRequestAction>(UserActionTypes.USER_UPDATE_REQUEST),
    map(action => {
      this.logger.debug('@Effect UserUpdateRequest: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.updateUser(payload).pipe(
        map(() => this.layoutActionSaveSuccess('The user has been updated.')),
        catchError(err => {
          return err && err.error && this.checkErrorCode(err.error.code)
            ? of(
                new UserUpdateResponseErrorAction({
                  ...err.error
                })
              )
            : err && err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      );
    })
  );

  @Effect()
  getUserByNo$ = this.actions$.pipe(
    ofType<UserByUserNoRequestAction>(UserActionTypes.USER_GET_BY_NO_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${UserActionTypes.USER_GET_BY_NO_REQUEST}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.getUserByNo(payload).pipe(
        map(requests => {
          return new UserByUserNoResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  changeStatusUserByUserName$ = this.actions$.pipe(
    ofType<ActivateUserRequestAction>(UserActionTypes.USER_ACTIVATE_BY_USER_NAME_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${UserActionTypes.USER_ACTIVATE_BY_USER_NAME_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.usersService.changeStatusUserByUserName(payload).pipe(
        map(resp => {
          return new ActivateUserResponseAction({ statusCode: resp.status.toString(), message: resp.statusText });
        }),
        catchError(err =>
          of(new ActivateUserResponseAction({ statusCode: err.status.toString(), message: 'Change status Error' }))
        )
      );
    })
  );

  @Effect()
  resetPassword$: Observable<Action> = this.actions$.pipe(
    ofType<UserResetPasswordRequestAction>(UserActionTypes.USER_PASSWORD_RESET_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Reset Password Request: ' + this.stringify(payload));
      return this.usersService.resetPassword(payload).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Reset Password Response: ' + this.stringify(resp));
          return new UserResetPasswordResponseAction({
            statusCode: resp.status.toString(),
            message: resp.statusText
          });
        }),
        catchError(err =>
          of(
            new UserResetPasswordResponseAction({
              statusCode: err.status.toString(),
              message: 'Reset Password Error'
            })
          )
        )
      );
    })
  );

  @Effect()
  resetPinCode$: Observable<Action> = this.actions$.pipe(
    ofType<UsersResetPinCodeRequestAction>(UserActionTypes.USERS_RESET_PIN_CODE_REQUEST),
    map(action => action.payload),
    switchMap(payload => {
      this.logger.debug('@Effect Reset Pin code Request: ' + this.stringify(payload));
      return this.usersService.resetPinCode(payload.userName).pipe(
        map((resp: any) => {
          this.logger.debug('@Effect Reset Pin code Response: ' + this.stringify(resp));
          return new UsersResetPinCodeResponseAction({
            statusCode: resp.status.toString(),
            message: resp.statusText
          });
        }),
        catchError(err =>
          of(
            new UsersResetPinCodeResponseAction({
              statusCode: err.status.toString(),
              message: 'Reset Pin Code Error'
            })
          )
        )
      );
    })
  );

  @Effect()
  forceChangePassword$: Observable<Action> = this.actions$.pipe(
    ofType<ForceChangePasswordRequestAction>(UserActionTypes.CHANGE_PASSWORD_REQUEST),
    map((action: ForceChangePasswordRequestAction) => action.payload),
    switchMap(changePasswordRequest => {
      return this.usersService.changePassword(changePasswordRequest).pipe(
        map((changedPassword: HttpResponse<ChangePasswordResponse>) => {
          return new ForceChangePasswordSuccess({
            statusCode: changedPassword.status.toString(),
            message: changedPassword.statusText
          });
        }),
        catchError(err => {
          return of(new ForceChangePasswordFailed(err.error));
        })
      );
    })
  );

  checkErrorCode(errorCode: string) {
    return ['02008', '02010', '02006', '02003'].includes(errorCode);
  }

  layoutActionSaveSuccess(message: string) {
    return new LayoutActionSaveSuccess({
      isSuccess: true,
      title: 'Success',
      message
    });
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
