import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductAssortmentService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import { OrderSelectItemActionTypes, OrderSelectItemListResponse } from '../actions/order-select-item.actions';
import { selectAllOrderSelectItemOrder } from '../selectors/order-select-item-order.selector';
export class OrderSelectItemEffects {
    constructor(actions$, productAssortmentService, store, logger) {
        this.actions$ = actions$;
        this.productAssortmentService = productAssortmentService;
        this.store = store;
        this.logger = logger;
        this.orderSelectItemListRequest$ = this.actions$.pipe(ofType(OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST}:` + JSON.stringify(action.payload))), switchMap(action => this.productAssortmentService.searchCatalogByCriteria(action.payload).pipe(withLatestFrom(this.store.select(selectAllOrderSelectItemOrder)), map(([response, tdAssortmentOrder]) => {
            tdAssortmentOrder.forEach(order => {
                const foundObjectIndex = response.content.findIndex(content => {
                    return content.articleNo === order.articleNo;
                });
                if (response.content[foundObjectIndex]) {
                    response.content[foundObjectIndex].qty = order.qty;
                    response.content[foundObjectIndex].allowToDelete = order.allowToDelete;
                }
            });
            return new OrderSelectItemListResponse(response);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderSelectItemEffects.prototype, "orderSelectItemListRequest$", void 0);
