<div id="stackPricingView">
  <div class="admin-form">
    <!-- Modal Body -->
    <div>
      <div class="d-flex flex-row">
        <div class="d-inline-flex mr-auto">
          <h5 id="articleNo" class="pr-2">
            Article No. : {{ productAssortment?.articleNo }} - {{ productAssortment?.productName }}
          </h5>
        </div>
        <div>
          <div class="d-inline-block picking-label">
            <label class="mr-2">TD Picking Unit : {{ currentPickingUnit }}</label>
          </div>
        </div>
      </div>

      <div class="row section-header mt-3 mb-3 mx-0">
        <span>Current Price</span>
      </div>

      <div class="row">
        <div class="col-md-12">
          <ng-container *ngIf="(pricings$ | async)?.wholesalePrices.length > 0">
            <table id="datatable" class="table table-striped table-bordered table-hover w-100">
              <thead>
                <tr>
                  <th class="text-center w-5">{{ headerRow[0] }}</th>
                  <th class="text-center w-20">{{ headerRow[1] }}</th>
                  <th class="text-center w-20">{{ headerRow[2] }}</th>
                  <th class="text-center w-20">{{ headerRow[3] }}</th>
                  <th class="text-center w-20">{{ headerRow[4] }}</th>
                  <th class="text-center w-15">{{ headerRow[5] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let price of (pricings$ | async)?.wholesalePrices; index as i">
                  <td class="text-center">
                    {{ i + 1 }}
                  </td>
                  <td class="text-right">
                    {{
                      (price.lowerBoundPrice | numberFormatDisplay) +
                        ' - ' +
                        (price.upperBoundPrice | numberFormatDisplay)
                    }}
                  </td>
                  <td class="text-center">
                    <span *ngIf="price.totalStock; else outOfStock">
                      {{ price.totalStock | numberFormatDisplay }}
                    </span>

                    <ng-template #outOfStock>
                      <span class="text-danger">
                        Out of Stock
                      </span>
                    </ng-template>
                  </td>
                  <td class="text-center">
                    {{ price.safetyStock | dashDisplay }}
                  </td>
                  <td class="text-right">
                    {{ price.wholesalePrice | numberFormatDisplay }}
                  </td>
                  <td class="text-right">
                    {{ price.gpPct | numberFormatDisplay }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <ng-container *ngIf="(pricings$ | async)?.manageStackPricings.length > 0 && !isManageMode">
            <div class="row section-header mt-3 mb-3 mx-0">
              <span>New Price will be effective tomorrow.</span>
            </div>

            <table id="new-price" class="table table-striped table-bordered table-hover w-100">
              <thead>
                <tr>
                  <th class="text-center w-5">{{ headerRow[0] }}</th>
                  <th class="text-center w-20">{{ headerRow[1] }}</th>
                  <th class="text-center w-20">{{ headerRow[2] }}</th>
                  <th class="text-center w-20">{{ headerRow[3] }}</th>
                  <th class="text-center w-20">{{ headerRow[4] }}</th>
                  <th class="text-center w-15">{{ headerRow[5] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let price of (pricings$ | async)?.manageStackPricings; index as i">
                  <td class="text-center">
                    {{ i + 1 }}
                  </td>
                  <td class="text-right">
                    {{
                      (price.lowerBoundPrice | numberFormatDisplay) +
                        ' - ' +
                        (price.upperBoundPrice | numberFormatDisplay)
                    }}
                  </td>
                  <td class="text-center">
                    <span *ngIf="price.totalStock; else outOfStock">
                      {{ price.totalStock | numberFormatDisplay }}
                    </span>

                    <ng-template #outOfStock>
                      <span class="text-danger">
                        Out of Stock
                      </span>
                    </ng-template>
                  </td>
                  <td class="text-center">
                    {{ price.safetyStock | dashDisplay }}
                  </td>
                  <td class="text-right">
                    {{ price.wholesalePrice | numberFormatDisplay }}
                  </td>
                  <td class="text-right">
                    {{ price.gpPct | numberFormatDisplay }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>

          <ng-container *ngIf="(pricings$ | async) && (pricings$ | async).wholesalePrices.length <= 0">
            <div>
              <div class="no-result-container text-center">
                <div class="d-flex justify-content-center">
                  <img width="200" height="200" src="/assets/images/no-item.svg" alt="no item" />
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Modal Body -->

    <div *ngIf="isManageMode" class="container-fluid">
      <div class="row section-header mt-3 mb-3">
        <span>​Manage Lot</span>
      </div>

      <div class="row">
        <div class="col-md">
          <div class="row">
            <div class="w-5 text-center p-2 inner-div sort-font font-weight-bold">
              Lot
            </div>
            <div class="w-20 text-center p-2 inner-div sort-font font-weight-bold">
              Cost Range
            </div>
            <div class="w-20 text-center p-2 inner-div sort-font font-weight-bold">
              Total Stock
            </div>
            <div class="w-20 text-center p-2 inner-div sort-font font-weight-bold">
              Safety Stock
            </div>
            <div class="w-20 text-center p-2 inner-div sort-font font-weight-bold">
              Wholesale Price
            </div>
            <div class="w-15 text-center p-2 inner-div sort-font font-weight-bold">
              GP%
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <bs-sortable
            [(ngModel)]="manageStackPricings"
            [itemTemplate]="itemTemplate"
            itemActiveClass="sortable-item-active"
            placeholderItem="Drag here"
          ></bs-sortable>
        </div>
      </div>
      <ng-template #itemTemplate let-item="item" let-index="index">
        <div
          class="row drag-container"
          [ngClass]="{
            'drag-container': true
          }"
        >
          <div
            class="w-5 text-center p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
          >
            {{ index + 1 }}
          </div>
          <div
            class="w-20 text-right p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
          >
            {{ item.value.lowerBoundPrice | numberFormatDisplay }}
            - {{ item.value.upperBoundPrice | numberFormatDisplay }}
          </div>
          <div
            class="w-20 text-center p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
            [ngClass]="{ 'text-danger': !item.value.totalStock }"
          >
            <span *ngIf="item.value.totalStock; else outOfStock">
              {{ item.value.totalStock | numberFormatDisplay }}
            </span>

            <ng-template #outOfStock>
              <span class="text-danger">
                Out of Stock
              </span>
            </ng-template>
          </div>
          <div
            class="w-20 text-center p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
          >
            {{ item.value.safetyStock | numberFormatDisplay }}
          </div>
          <div
            class="w-20 text-right p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
          >
            {{ item.value.wholesalePrice | numberFormatDisplay }}
          </div>
          <div
            class="w-15 text-right p-2 inner-div sort-font"
            [ngStyle]="{ 'background-color': index % 2 === 0 ? '#f4f5f8' : '' }"
          >
            {{ item.value.gpPct }}% <em class="icon-select-arrow ml-2 float-right"></em>
          </div>
        </div>
      </ng-template>
      <div class="row mt-3 mb-3">
        <span class="blue-color">This Stack price will be effective tomorrow.</span>
      </div>
    </div>

    <!-- Footer -->
    <div class="fixed-row-bottom form-row" *appHasPermission="['price_m_m', 'price_nm_m']">
      <div class="float-right" *appHasPermission="['price_m_m', 'price_nm_m']">
        <button
          *ngIf="!isManageMode && (pricings$ | async)?.manageStackPricings.length > 1"
          type="button"
          class="btn btn-standard mr-2"
          (click)="deleteStackPricing()"
          appPreventDoubleClick
        >
          Delete Stack Pricing
        </button>
        <button *ngIf="isManageMode" type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">
          Cancel
        </button>
        <button *ngIf="isManageMode" type="button" class="btn btn-primary" (click)="onSubmit()" appPreventDoubleClick>
          Submit
        </button>
        <button
          (click)="toggleToManageMode()"
          *ngIf="!isManageMode && (pricings$ | async)?.wholesalePrices.length > 1"
          type="button"
          id="btn-edit"
          class="btn btn-primary"
        >
          Manage Stack Pricing
        </button>
      </div>
    </div>
    <!-- Footer -->
  </div>
</div>
