import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WarehouseService } from '../../services/warehouse.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  WarehouseMasterActionTypes,
  WarehouseMasterListRequestAction,
  WarehouseMasterListResponseAction
} from '../actions/warehouse-master.actions';

@Injectable()
export class WarehouseMasterEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly warehouseService: WarehouseService
  ) {}

  @Effect()
  warehouseMasterList$ = this.actions$.pipe(
    ofType<WarehouseMasterListRequestAction>(WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_REQUEST),
    map(() => {
      this.logger.debug(`@Effect ${WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_REQUEST}`);
    }),
    switchMap(() => {
      return this.warehouseService.getWarehouseMasterData().pipe(
        map(response => {
          return new WarehouseMasterListResponseAction(response.data.warehouses);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );
}
