<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Export Shelf</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onExport()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Shelf<span class="text-danger">*</span></label>
            <app-dropdown-multiple-select
              [parentForm]="form"
              controlName="no"
              [items]="shelfList"
              bindValue="no"
              bindLabel="shelfName"
            >
            </app-dropdown-multiple-select>
          </div>
        </div>
      </div>
      <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
        {{ errorExport }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="onExport()"
      [disabled]="!form.value.no.length"
      id="btnExport"
    >
      Export
    </button>
  </div>
</div>
