import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LayoutActionErrorUpdate } from '../store/actions/layout.action';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "ngx-logger";
export class ErrorInterceptor {
    constructor(store, logger) {
        this.store = store;
        this.logger = logger;
    }
    intercept(request, next) {
        return next.handle(request).pipe(
        // retry(1),
        catchError(err => this.handleError(err, request)));
    }
    handleError(err, request) {
        if (err.error) {
            let obs;
            if (err.error instanceof Blob && (err.status === 400 || err.status === 404)) {
                // Parser Blob error case
                const reader = new FileReader();
                obs = new Observable((observer) => {
                    reader.onloadend = e => {
                        if (typeof e.target['result'] === 'string') {
                            err.error = JSON.parse(e.target['result']); // srcElement is deprecated then we use target
                        }
                        observer.error(err);
                        observer.complete();
                    };
                });
                reader.readAsText(err.error);
            }
            else {
                // Parser general error case
                const isApiError = err.status === 503 &&
                    !request.url.includes(environment.services.deliveryOrder.importPicking) &&
                    !request.url.includes(environment.services.auth.url);
                if (isApiError) {
                    this.store.dispatch(new LayoutActionErrorUpdate(true));
                }
                if (typeof err.error === 'string') {
                    err.error = JSON.parse(err.error);
                }
                err.error.translateKey = `ERROR_CODE.${err.error.code}`;
                obs = new Observable((observer) => {
                    observer.error(err);
                    observer.complete();
                });
            }
            return obs;
        }
        return throwError(err);
    }
}
ErrorInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorInterceptor_Factory() { return new ErrorInterceptor(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NGXLogger)); }, token: ErrorInterceptor, providedIn: "root" });
