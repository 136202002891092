import { Action } from '@ngrx/store';
import {
  RoleCreated,
  RoleCreatedSuccessResponse,
  RoleEdit,
  RoleErrorResponse,
  RoleListResponse,
  RoleMasterResponse,
  RoleSearchCriteria,
  RoleViewResponse
} from '../../models';

export enum RoleActionTypes {
  ROLE_LIST_REQUEST_ACTION = '[Role List] Get Role RequestLiis',
  ROLE_LIST_RESPONSE_ACTION = '[Role List] Get Role Response Liis',
  ROLE_MASTER_LIST_REQUEST = '[Role] Get Role List Request',
  ROLE_MASTER_LIST_RESPONSE = '[Role] Get Role List Response',
  ROLE_MASTER_LIST_RESET = '[Role] Get Role List Reset',

  ROLE_GET_BY_ID_REQUEST = '[Role] Get Role by Id Request',
  ROLE_GET_BY_ID_RESPONSE = '[Role] Get Role by Id Response',
  ROLE_GET_BY_ID_RESET = '[Role] Get Role by Id Reset',

  ROLE_CREATED_REQUEST = '[Role] Role Created Request',
  ROLE_CREATED_RESPONSE = '[Role] Role Created Response',

  ROLE_EDIT_REQUEST = '[Role] Role Edit Request',
  ROLE_EDIT_RESPONSE = '[Role] Role Edit Response',

  ROLE_RESPONSE_ERROR = '[Role] Role Response ERROR',
  ROLE_RESPONSE_ERROR_RESET = '[Role] Role Response ERROR Reset',

  ROLE_ALL_REQUEST_ACTION = '[Role] Get Role all Request',
  ROLE_ALL_RESPONSE_ACTION = '[Role] Get Role all Response',
  ROLE_ALL_RESET_ACTION = '[Role] Get Role all Reset'
}

export class RoleListRequestAction implements Action {
  readonly type = RoleActionTypes.ROLE_LIST_REQUEST_ACTION;

  constructor(public payload: RoleSearchCriteria) {}
}

export class RoleListResponseAction implements Action {
  readonly type = RoleActionTypes.ROLE_LIST_RESPONSE_ACTION;
  constructor(public payload: RoleListResponse) {}
}

export class RoleMasterListRequest implements Action {
  readonly type = RoleActionTypes.ROLE_MASTER_LIST_REQUEST;
}

export class RoleMasterListResponse implements Action {
  readonly type = RoleActionTypes.ROLE_MASTER_LIST_RESPONSE;
  constructor(public payload: { permissions: RoleMasterResponse[] }) {}
}
export class RoleMasterListReset implements Action {
  readonly type = RoleActionTypes.ROLE_MASTER_LIST_RESET;
}

export class RoleCreatedRequestAction implements Action {
  readonly type = RoleActionTypes.ROLE_CREATED_REQUEST;
  constructor(public payload: RoleCreated) {}
}

export class RoleCreatedSuccessResponseAction implements Action {
  readonly type = RoleActionTypes.ROLE_CREATED_RESPONSE;
  constructor(public payload: RoleCreatedSuccessResponse) {}
}

export class RoleByIdRequestAction implements Action {
  readonly type = RoleActionTypes.ROLE_GET_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class RoleByIdResponseAction implements Action {
  readonly type = RoleActionTypes.ROLE_GET_BY_ID_RESPONSE;

  constructor(public payload: RoleViewResponse) {}
}

export class ResetRoleSelected implements Action {
  readonly type = RoleActionTypes.ROLE_GET_BY_ID_RESET;
}

export class RoleEditRequestAction implements Action {
  readonly type = RoleActionTypes.ROLE_EDIT_REQUEST;
  constructor(public payload: RoleEdit) {}
}

export class RoleEditSuccessResponseAction implements Action {
  readonly type = RoleActionTypes.ROLE_EDIT_RESPONSE;
}

export class RoleResponseErrorAction implements Action {
  readonly type = RoleActionTypes.ROLE_RESPONSE_ERROR;
  constructor(public payload: RoleErrorResponse) {}
}

export class RoleResponseErrorResetAction implements Action {
  readonly type = RoleActionTypes.ROLE_RESPONSE_ERROR_RESET;
}

export class RoleAllRequestAction implements Action {
  readonly type = RoleActionTypes.ROLE_ALL_REQUEST_ACTION;
}

export class RoleAllResponseAction implements Action {
  readonly type = RoleActionTypes.ROLE_ALL_RESPONSE_ACTION;
  constructor(public payload: RoleViewResponse[]) {}
}
export class RoleAllResetAction implements Action {
  readonly type = RoleActionTypes.ROLE_ALL_RESET_ACTION;
}

export type RoleActions =
  | RoleAllRequestAction
  | RoleAllResponseAction
  | ResetRoleSelected
  | RoleResponseErrorAction
  | RoleByIdRequestAction
  | RoleByIdResponseAction
  | RoleListRequestAction
  | RoleListResponseAction
  | RoleMasterListRequest
  | RoleMasterListResponse
  | RoleMasterListReset
  | RoleCreatedRequestAction
  | RoleCreatedSuccessResponseAction
  | RoleEditRequestAction
  | RoleEditSuccessResponseAction
  | RoleResponseErrorResetAction
  | RoleAllResetAction;
