import gql from 'graphql-tag';

export const billToMasterQuery = gql`
  query {
    billings {
      code
      nameEn
      nameTh
      contactName
      contactNumber
      address
    }
  }
`;
