import { Component, ComponentFactoryResolver, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertModalComponent } from '../..';
import { ModalButtonResponseEnum } from '../../../enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../enum/notification-type.enum';
import { AlertModal } from '../../../models/alert-modal.mode';
import { BaseModalComponent } from '../../../models/base-modal.component.model';
import { ConfirmModal } from '../../../models/confirm-modal.mode';
import { NotificationEmit } from '../../../models/notification-emit.model';
import { FullModalActionLeaveFormUpdate } from '../../../store/actions/full-modal.action';
import { AppStates } from '../../../store/state/app.states';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { ChildComponent } from './child-component';
import { ChildDirective } from './child-directive';
import { ChildItem } from './child-item';

@Component({
  selector: 'app-full-modal',
  templateUrl: './full-modal.component.html',
  styleUrls: ['./full-modal.component.scss']
})
export class FullModalComponent implements OnInit, OnDestroy, BaseModalComponent {
  @ViewChild(ChildDirective, { static: true }) childHost: ChildDirective;

  public title: string;
  public routerLink?: string;
  public childItem: ChildItem;
  public action: Subject<ModalButtonResponseEnum>;
  isRequiredLeaveForm: boolean;
  routerEventRouterSubs: Subscription;
  isLeaveFormDirty: boolean;

  canPrint? = false;

  constructor(
    public bsModalRef: BsModalRef,
    protected readonly modalService: BsModalService,
    private readonly router: Router,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly translate: TranslateService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {
    this.routerEventRouterSubs = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.bsModalRef.hide();
        this.isLeaveFormDirty = false;
      });
  }

  ngOnInit() {
    this.action = new EventEmitter();
    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new FullModalActionLeaveFormUpdate(false));

    if (this.routerEventRouterSubs) {
      this.routerEventRouterSubs.unsubscribe();
    }
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.childItem.component);
    const viewContainerRef = this.childHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const instance = componentRef.instance as ChildComponent;

    instance.data = this.childItem.data;
    instance.notifyParent = this.childItem.notifyParent;

    this.isRequiredLeaveForm = this.childItem.isRequiredLeaveForm;

    instance.notifyParent.pipe(untilComponentDestroyed(this)).subscribe((emit: NotificationEmit) => {
      // Reference from onSubmit method at add.component.ts
      switch (emit.notificationType) {
        case NotificationTypeEnum.NEXT:
          if (emit && emit.result) {
            this.bsModalRef.hide();
          }
          break;
        case NotificationTypeEnum.CANCEL:
          this.close();
          break;
        case NotificationTypeEnum.FORCE_CLOSE:
          this.bsModalRef.hide();
          break;
        case NotificationTypeEnum.CONFIRM: // New version
          this.confirmModal(emit.initialState);
          break;
        case NotificationTypeEnum.ALERT: // New version
          this.alertModal(emit.initialState);
          break;
        case NotificationTypeEnum.LOGGING: // New version
          this.logger.debug(emit.result);
          break;
        case NotificationTypeEnum.CLOSE:
        default:
          break;
      }
    });
    if (instance.titleOutput) {
      instance.titleOutput.pipe(untilComponentDestroyed(this)).subscribe(title => {
        this.title = title;
      });
    }
    if (instance.notifyLeaveFormOutput) {
      instance.notifyLeaveFormOutput
        .pipe(untilComponentDestroyed(this))
        .subscribe(isDirty => (this.isLeaveFormDirty = isDirty));
    }

    // document.querySelector('#page-content').scrollTop = 0;
    // document.querySelector('#page-content').scrollLeft = 0;
  }

  onPrint() {
    this.action.next(ModalButtonResponseEnum.PRINT);
  }

  close() {
    const initialState = {
      title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
      okText: this.translate.instant('STAY_ON_PAGE'),
      cancelText: this.translate.instant('LEAVE'),
      message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING'),
      backdrop: true
    };
    if (this.isRequiredLeaveForm && this.isLeaveFormDirty) {
      const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
        animated: true,
        backdrop: true,
        initialState
      });

      confirmModalRef.content.action
        .pipe(untilComponentDestroyed(this))
        .subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.CANCEL) {
            this.bsModalRef.hide();
          }
        });
    } else {
      this.bsModalRef.hide();
    }
  }

  alertModal(initialState: AlertModal) {
    this.modalService.show(AlertModalComponent, { initialState });
  }

  confirmModal(initialState?: ConfirmModal) {
    let confirmModalRef: BsModalRef;
    let closeMode: ModalButtonResponseEnum = ModalButtonResponseEnum.CANCEL;

    if (initialState && initialState.closeMode) {
      closeMode = initialState.closeMode;
    }

    confirmModalRef = this.modalService.show(ConfirmModalComponent, { initialState });
    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === closeMode) {
          this.bsModalRef.hide();
        }
      });
  }
}
