import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  startWith,
  switchMap,
  take,
  tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { DeliveryOrderStatusEnum } from '../../../shared/enum/delivery-order-status.enum';
import { ShipmentStatusEnum } from '../../../shared/enum/shipment-status.enum';
import { ShipmentTypeEnum } from '../../../shared/enum/shipment-type.enum';
import { CreateShipmentRequest, DeliveryOrderShipment, Shipment } from '../../../shared/models/shipment.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { SupplierService } from '../../../shared/services/supplier.service';
import {
  ShipmentConfirmDispatchRequest,
  ShipmentConfirmLoadRequest,
  ShipmentCreateRequest,
  ShipmentDeleteRequest,
  ShipmentGetByIdRequest,
  ShipmentReset,
  ShipmentUpdateRequest
} from '../../../shared/store/actions/shipment.actions';
import {
  selectShipmentContent,
  selectShipmentDeliveryOrdersCreate,
  selectShipmentOrderSelect
} from '../../../shared/store/selectors/shipment.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDateStartOfDay, getDateFromString } from '../../../shared/utils/date-util';

export const shipmentStepConfig = {
  create: {
    required: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate'],
    disabled: [],
    showConfirm: false,
    showNo: false,
    showFlag: false
  },
  load: {
    required: ['plateNo', 'driverName', 'confirmLoad'],
    disabled: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate'],
    showConfirm: true,
    showNo: false,
    showFlag: true
  },
  editAwaiting: {
    required: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate', 'plateNo', 'driverName'],
    disabled: [],
    showConfirm: false,
    showNo: true,
    showFlag: false
  },
  editDispatch: {
    required: ['shipCharge'],
    disabled: ['deliveryBy', 'deliveryDate', 'pickUpDate', 'plateNo', 'driverName'],
    showConfirm: false,
    showNo: true,
    showFlag: false
  },
  dispatch: {
    required: [],
    disabled: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate', 'plateNo', 'driverName'],
    showConfirm: false,
    showNo: true,
    showFlag: false
  },
  delete: {
    required: [],
    disabled: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate', 'plateNo', 'driverName'],
    showConfirm: false,
    showNo: true,
    showFlag: false
  },
  view: {
    required: [],
    disabled: ['deliveryBy', 'shipCharge', 'deliveryDate', 'pickUpDate', 'plateNo', 'driverName'],
    showConfirm: false,
    showNo: true,
    showFlag: false
  }
};

@Component({
  selector: 'app-shipment-request',
  templateUrl: './shipment-request.component.html',
  styleUrls: ['./shipment-request.component.scss']
})
export class ShipmentRequestComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() shipmentNo: string;
  @Input() mode: string;
  @Input() doNo: string;

  public dateFormat = environment.dateFormat;
  public submitted: boolean;
  public itemIsLoading: boolean;
  public shipmentContent: Observable<Shipment>;
  public supplierList: Observable<SupplierSearch[]>;
  public bsConfig: BsDatepickerConfig;

  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();

  public deliveryOrderShipment: Observable<DeliveryOrderShipment[]>;
  public shipmentForm: FormGroup;
  public doList: DeliveryOrderShipment[];
  public shipmentTypeEnum = ShipmentTypeEnum;

  private localStore: Observable<any>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected bsModalRef: BsModalRef,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected supplierService: SupplierService
  ) {}

  ngOnInit() {
    this.createForm();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.setInitialElementValue();
    this.loadSupplier('');

    if (this.mode === ShipmentTypeEnum.CREATE) {
      // Display DO list
      this.generateDOListDisplay(selectShipmentDeliveryOrdersCreate);
    } else {
      // Not create need to load data from backend
      this.setInitialShipmentData();
      this.initialFormData();
      // Display DO list
      this.generateDOListDisplay(selectShipmentOrderSelect);
    }
  }

  ngOnDestroy() {
    this.store.dispatch(new ShipmentReset());
  }

  generateDOListDisplay(selector) {
    this.localStore
      .pipe(
        select(selector),
        filter(aObj => Boolean(aObj) && aObj.length > 0)
      )
      .subscribe(list => {
        this.doList = list.map(data => {
          return {
            ...data,
            isCurrentDO: this.doNo ? data.doNo === this.doNo : false
          };
        });
      });
  }

  initialFormData() {
    this.localStore
      .pipe(
        select(selectShipmentContent),
        filter(shipment => shipment !== null && shipment.shipmentNo !== null),
        take(1)
      )
      .subscribe(shipment => {
        this.createForm(shipment);
      });
  }

  createForm(shipment: Shipment = null) {
    if (shipment) {
      this.shipmentForm = this.fb.group({
        id: this.createControlName('id', shipment.id),
        version: this.createControlName('version', shipment.version),
        deliveryBy: this.createControlName('deliveryBy', shipment.courierName),
        shipCharge: this.createControlName('shipCharge', shipment.shipCharge),
        plateNo: this.createControlName('plateNo', shipment.plateNo),
        driverName: this.createControlName('driverName', shipment.driverName),
        deliveryDate: this.createControlName(
          'deliveryDate',
          shipment.deliveryDate ? getDateFromString(shipment.deliveryDate) : null
        ),
        pickUpDate: this.createControlName(
          'pickUpDate',
          shipment.pickupDate ? getDateFromString(shipment.pickupDate) : null
        ),
        // create for display and request param
        supplierCode: this.createControlName('supplierCode', shipment.courierCode),
        supplierName: this.createControlName('supplierName', shipment.courierName),
        status: this.createControlName('status', shipment.status),
        isWarningAfterDeliveryDate: this.createControlName('isWarningAfterDeliveryDate', false),
        confirmLoad: this.createControlName('confirmLoad')
      });
    } else {
      this.shipmentForm = this.fb.group({
        deliveryBy: this.createControlName('deliveryBy'),
        shipCharge: this.createControlName('shipCharge'),
        plateNo: this.createControlName('plateNo'),
        driverName: this.createControlName('driverName'),
        deliveryDate: this.createControlName('deliveryDate'),
        pickUpDate: this.createControlName('pickUpDate'),
        isWarningAfterDeliveryDate: this.createControlName('isWarningAfterDeliveryDate'),
        confirmLoad: this.createControlName('confirmLoad')
      });
    }
  }

  createControlName(fieldName: string, value: any = null) {
    const requireList = shipmentStepConfig[this.mode].required;
    const disabledList = shipmentStepConfig[this.mode].disabled;
    return [
      {
        value: value !== null ? value : null,
        disabled: disabledList.includes(fieldName)
      },
      requireList.includes(fieldName) ? Validators.required : null
    ];
  }

  setInitialShipmentData() {
    this.store.dispatch(new ShipmentGetByIdRequest(this.shipmentNo));
  }

  setInitialElementValue() {
    this.doList = [];
    this.itemIsLoading = false;
    this.bsConfig = {
      dateInputFormat: this.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  onSelectConfirm($event: any) {
    const form = this.form.get('confirmLoad') as FormGroup;
    form.setValue($event.target.checked);
    form.setValidators([Validators.required, this.checkConfirmValidator()]);
    form.updateValueAndValidity();
  }

  checkConfirmValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value ? null : { invalid: true };
    };
  }

  onChangeDate(value: Date, element: HTMLElement, checkWarning = false) {
    const formName = element.getAttribute('formControlName');
    this.form.patchValue({
      [formName]: value
    });

    if (value && !isNaN(value.getTime())) {
      this.setMinDateValidator('pickUpDate');
      if (checkWarning) {
        this.warningValidate(value);
      }
    }
  }

  minDateValidator(): ValidatorFn {
    return (control: AbstractControl) => {
      const minDate = this.form.get('deliveryDate').value;
      return control.value > minDate ? { afterDate: true } : null;
    };
  }

  warningValidate(deliveryDate: Date) {
    this.form.controls['isWarningAfterDeliveryDate'].setValue(null);
    this.doList.forEach(data => {
      const reqDate = getDateFromString(data.requestedDeliveryDate);
      const currentDeliveryDate = moment(new Date(deliveryDate))
        .startOf('day')
        .toDate();
      if (
        deliveryDate &&
        currentDeliveryDate > reqDate &&
        [ShipmentTypeEnum.CREATE.toString(), ShipmentTypeEnum.EDIT_AWAITING.toString()].includes(this.mode)
      ) {
        this.form.controls['isWarningAfterDeliveryDate'].setValue(true);
      }
    });
  }

  setMinDateValidator(fieldName: string) {
    const form = this.form.get(fieldName) as FormGroup;
    form.setValidators([Validators.required, this.minDateValidator()]);
    form.updateValueAndValidity();
  }

  validateSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.submit();
  }

  submit() {
    const value = this.form.getRawValue();
    if (this.mode === ShipmentTypeEnum.CREATE) {
      const body: CreateShipmentRequest = {
        courierCode: value.deliveryBy.supplierCode,
        courierName: value.deliveryBy.supplierName,
        shipCharge: value.shipCharge,
        plateNo: value.plateNo,
        driverName: value.driverName,
        deliveryDate: formatDateStartOfDay(value.deliveryDate),
        pickupDate: formatDateStartOfDay(value.pickUpDate),
        deliveryOrders: this.doList.map(data => data.doNo)
      };

      this.store.dispatch(new ShipmentCreateRequest({ shipment: body }));
    } else if (this.mode === ShipmentTypeEnum.LOAD) {
      const body: Shipment = {
        id: value.id,
        version: value.version,
        shipmentNo: this.shipmentNo,
        plateNo: value.plateNo,
        driverName: value.driverName,
        deliveryOrder: this.doList.find(data => data.isCurrentDO)
      };
      this.store.dispatch(new ShipmentConfirmLoadRequest({ shipment: body }));
    } else if (this.mode === ShipmentTypeEnum.DISPATCH) {
      if (this.doList.filter(data => data.doStatus !== DeliveryOrderStatusEnum.LOADED).length > 0) {
        this.itemIsLoading = true;
        return;
      }
      this.store.dispatch(new ShipmentConfirmDispatchRequest(this.shipmentNo));
    } else if (this.mode === ShipmentTypeEnum.EDIT_AWAITING || this.mode === ShipmentTypeEnum.EDIT_DISPATCH) {
      const body: Shipment = {
        id: value.id,
        version: value.version,
        courierCode:
          value.deliveryBy && value.deliveryBy.supplierCode ? value.deliveryBy.supplierCode : value.supplierCode,
        courierName:
          value.deliveryBy && value.deliveryBy.supplierName ? value.deliveryBy.supplierName : value.supplierName,
        shipCharge: value.shipCharge,
        plateNo: value.plateNo,
        driverName: value.driverName,
        deliveryDate: formatDateStartOfDay(value.deliveryDate),
        pickupDate: formatDateStartOfDay(value.pickUpDate)
      };
      this.store.dispatch(new ShipmentUpdateRequest({ shipment: body }));
    } else if (this.mode === ShipmentTypeEnum.DELETE) {
      this.store.dispatch(new ShipmentDeleteRequest(this.shipmentNo));
    }
  }

  onBlurSupplierName() {
    if (!this.form.controls.deliveryBy.value) {
      this.loadSupplier('');
    }
  }

  close(): void {
    this.bsModalRef.hide();
  }

  get form(): FormGroup {
    return this.shipmentForm;
  }

  get showNo() {
    return shipmentStepConfig[this.mode].showNo;
  }

  getColorStatusDeliveryOrder(status: DeliveryOrderStatusEnum): string {
    return status ? `${DeliveryOrderStatusEnum[status].toLowerCase()}` : '';
  }

  getColorStatusShipment(status: ShipmentStatusEnum): string {
    return status ? `${ShipmentStatusEnum[status].toLowerCase()}` : '';
  }
}
