<div [formGroup]="parentForm">
  <div
    class="d-flex align-items-center"
    formArrayName="deliveryTimes"
    *ngFor="let tdStore of deliveryTimes.controls; let i = index"
  >
    <div class="row flex-item" [formGroupName]="i">
      <div class="col-md-3 mb-1">
        <label for="deliveryTimeFrom">From</label>
        <ng-select
          id="deliveryTimeFrom"
          name="deliveryTimeFrom"
          placeholder="Please select..."
          [items]="restrictedDeliveryTimeFrom"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="from"
          (change)="onSelectedDeliveryTime(i)"
          [ngClass]="{
            'is-invalid': submitted && (tdStore.controls.from.errors || tdStore.errors)
          }"
        >
        </ng-select>
        <div *ngIf="submitted" class="invalid-feedback">
          <div *ngIf="tdStore.controls.from.errors && tdStore.controls.from.errors.required; else validate">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <ng-template #validate>
            <div *ngIf="tdStore.errors && tdStore.errors.invalid">
              From must be less than To.
            </div>
            <div *ngIf="tdStore.errors && tdStore.errors.overlapped && !tdStore.errors.invalid">
              {{ 'ERRORS.TIME_OVERLAPPED' | translate }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-md-3 mb-1">
        <label for="deliveryTimeTo">To</label>
        <ng-select
          id="deliveryTimeTo"
          name="deliveryTimeTo"
          placeholder="Please select..."
          [items]="restrictedDeliveryTimeTo"
          [searchable]="false"
          [clearable]="false"
          bindLabel="nameTh"
          bindValue="code"
          formControlName="to"
          (change)="onSelectedDeliveryTime(i)"
          [ngClass]="{
            'is-invalid': submitted && (tdStore.controls.to.errors || tdStore.errors)
          }"
        >
        </ng-select>
        <div *ngIf="submitted" class="invalid-feedback">
          <div *ngIf="tdStore.controls.to.errors && tdStore.controls.to.errors.required; else validate">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <ng-template #validate>
            <div *ngIf="tdStore.errors && tdStore.errors.invalid">
              From must be less than To.
            </div>
            <div *ngIf="tdStore.errors && tdStore.errors.overlapped && !tdStore.errors.invalid">
              {{ 'ERRORS.TIME_OVERLAPPED' | translate }}
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-md-1 mb-1">
        <button
          type="button"
          id="delete"
          class="btn btn-special-reject"
          (click)="deleteDeliveryTime(i)"
          title="Delete"
          *ngIf="!checkEditPermission()"
        >
          <em class="icon-delete"></em>
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <button type="button" id="add" *ngIf="!checkEditPermission()" class="btn btn-secondary mt-2" (click)="addForm()">
        <em class="icon-plus"></em>
        Add more
      </button>
    </div>
  </div>
</div>
