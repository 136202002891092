import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StockStoreContent, StockStoreSearchCriteria } from '../../models';
import { StockInformationAction, StockInformationActionTypes } from '../actions/stock-information.actions';

export interface StockInformationStoreState extends EntityState<StockStoreContent> {
  isLoading: boolean;
  criteriaObject: StockStoreSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StockStoreContent> = createEntityAdapter<StockStoreContent>();

export const initialStockInformationStoreState: StockInformationStoreState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20,
    locationType: 'STORE'
  },
  totalElements: 0,
  totalPages: 0
});

export function stockInformationStoreReducers(
  state = initialStockInformationStoreState,
  action: StockInformationAction
): StockInformationStoreState {
  switch (action.type) {
    case StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case StockInformationActionTypes.STOCK_INFORMATION_STORE_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StockInformationActionTypes.STOCK_INFORMATION_STORE_RESET:
      return initialStockInformationStoreState;
    default: {
      return { ...state };
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
