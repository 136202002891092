import { createSelector } from '@ngrx/store';
import * as fromShelfRequestResponseState from '../reducers/shelf-request.reducers';
import { AppStates } from '../state/app.states';

const selectShelfRequestState = (state: AppStates) => state.shelfRequest;

export const selectAllShelfRequestList = createSelector(
  selectShelfRequestState,
  fromShelfRequestResponseState.selectAll
);

export const selectShelfRequestList = createSelector(
  selectShelfRequestState,
  (state: fromShelfRequestResponseState.ShelfRequestState) => state
);

export const selectShelfRequestListCriteria = createSelector(selectShelfRequestState, state => {
  return state.criteriaObject;
});

export const selectShelfRequestById = createSelector(
  selectShelfRequestState,
  (state: fromShelfRequestResponseState.ShelfRequestState) => state.selected
);

export const selectShelfRequestHistory = createSelector(
  selectShelfRequestState,
  (state: fromShelfRequestResponseState.ShelfRequestState) => state.auditLogs
);

export const selectShelfDeleteStatus = createSelector(
  selectShelfRequestState,
  (state: fromShelfRequestResponseState.ShelfRequestState) => {
    return state.isDeleteSuccess;
  }
);
