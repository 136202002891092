import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import {
  SupplierGroupRequest,
  SupplierGroupRequestContent,
  SupplierGroupRequestSearchCriteria
} from '../../models/supplier-group-request.model';
import {
  SupplierGroupRequestActions,
  SupplierGroupRequestActionTypes
} from '../actions/supplier-group-request.actions';

export interface SupplierGroupRequestState extends EntityState<SupplierGroupRequestContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  supplierGroupRequest: SupplierGroupRequest;
  criteriaObject: SupplierGroupRequestSearchCriteria;
  totalElements: number;
  totalPages: number;
  isExisting: boolean;
  isExistingForList: boolean;
  errorResponse: ErrorResponse;
}

export const adapter: EntityAdapter<SupplierGroupRequestContent> = createEntityAdapter<SupplierGroupRequestContent>();

export const initialSupplierGroupRequestState: SupplierGroupRequestState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  supplierGroupRequest: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  isExisting: null,
  isExistingForList: null,
  errorResponse: null
});

export function supplierGroupRequestReducers(
  state = initialSupplierGroupRequestState,
  action: SupplierGroupRequestActions
): SupplierGroupRequestState {
  switch (action.type) {
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SUBMIT_ERROR_RESPONSE: {
      return {
        ...state,
        errorResponse: action.payload
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_VIEW_LOADED: {
      return {
        ...state,
        supplierGroupRequest: action.payload
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_RESET: {
      return {
        ...state,
        supplierGroupRequest: null,
        errorResponse: null
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_RESPONSE: {
      return {
        ...state,
        isExisting: action.payload
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_EXISTING_RESET: {
      return {
        ...state,
        isExisting: null
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_RESPONSE: {
      return {
        ...state,
        isExistingForList: action.payload
      };
    }
    case SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_EXISTING_RESET: {
      return {
        ...state,
        isExistingForList: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
