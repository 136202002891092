import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StockInformationService } from '../../services/stock-information.service';
import { AdjustStockByIdResponseAction, AdjustStockImportSubmitResponseAction, AdjustStockItemListResponseAction, AdjustStockListResponseAction, AdjustStockResponseErrorAction, AdjustStockResponseValidateAction, AdjustStockTypeActions } from '../actions/adjust-stock.actions';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
export class AdjustStockEffects {
    constructor(actions$, stockInformationService, logger) {
        this.actions$ = actions$;
        this.stockInformationService = stockInformationService;
        this.logger = logger;
        this.searchAdjustList$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService.searchAdjustByCriteria(payload).pipe(map(requests => {
                return new AdjustStockListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getAdjustStockById$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Get AdjustStock By Id: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stockInformationService.getAdjustStockByDocNo(action.payload).pipe(map(response => {
                return new AdjustStockByIdResponseAction(response);
            }), catchError(err => of(new LayoutActionLoadError(err.error))));
        }));
        this.getAdjustStockItemById$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService
                .getAdjustStockItemCriteria(payload.docNo, payload.adjustStockItemCriteria)
                .pipe(map(requests => {
                return new AdjustStockItemListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.validate$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_REQUEST), tap(action => this.logger.debug('@Effect AdjustStock validate: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stockInformationService.validateAdjustData(action.payload).pipe(map(() => {
                return new AdjustStockResponseValidateAction({ validatePass: true });
            }), catchError(err => {
                return this.errorHandling(err);
            }));
        }));
        this.submit$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect AdjustStock submit: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stockInformationService.submitAdjustStock(action.payload).pipe(map(() => {
                return new LayoutActionSaveSuccess({
                    isSuccess: true,
                    title: 'Success',
                    message: 'The data have been saved.'
                });
            }), catchError(err => {
                return this.errorHandling(err);
            }));
        }));
        this.submitImport$ = this.actions$.pipe(ofType(AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect AdjustStock import submit: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stockInformationService.submitDataImportAdjustsStock(action.payload).pipe(map(result => new AdjustStockImportSubmitResponseAction(result)), catchError(err => of(new LayoutActionLoadError(err))));
        }));
    }
    errorHandling(err) {
        return err && err.error && this.checkErrorCode(err.error.code)
            ? of(new AdjustStockResponseErrorAction(Object.assign({}, err.error)))
            : of(new LayoutActionLoadError(err));
    }
    checkErrorCode(errorCode) {
        return ['00004', '00001'].includes(errorCode);
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "searchAdjustList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "getAdjustStockById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "getAdjustStockItemById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "validate$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "submit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], AdjustStockEffects.prototype, "submitImport$", void 0);
