<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Export Store Assortment By Product</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onExport()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Search By : Product Name, Barcode, Article No.<span class="text-danger">*</span></label>
            <ng-select
              #productSelect
              [items]="productList | async"
              bindLabel="productName"
              bindValue="barcode"
              dropdownPosition="auto"
              labelForId="album"
              placeholder="Please select..."
              notFoundText="No Data"
              [loading]="productSearchLoading"
              [typeahead]="productSearchInput$"
              [virtualScroll]="true"
              (blur)="onBlurProduct()"
              formControlName="product"
            >
              <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                <div>
                  <strong>Product Name:</strong>
                  <span [ngOptionHighlight]="search" class="ml-1">
                    {{ item.productName }}
                  </span>
                </div>
                <small>
                  <strong>Barcode :</strong><span [ngOptionHighlight]="search" class="ml-1">{{ item.barcode }}</span
                  >{{ ' | ' }} <strong>Unit :</strong
                  ><span [ngOptionHighlight]="search" class="ml-1">{{ item.unit }}</span> {{ ' | ' }}
                  <strong>Article No.:</strong
                  ><span [ngOptionHighlight]="search" class="ml-1">{{ item.articleNo }}</span>
                </small>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
        {{ errorExport }}
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onExport()" [disabled]="!form.value.product" id="btnExport">
      Export
    </button>
  </div>
</div>
