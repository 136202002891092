<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-history-header">
    <span>{{ historyHeader }}</span>
  </div>
  <div class="modal-body history-modal-body">
    <div class="row mt-3">
      <div class="col-4 history-list-header">
        Edit Date
      </div>
      <div class="col-4 history-list-header pl-0 pr-0">
        Activity
      </div>
      <div class="col-4 history-list-header">
        Edit By
      </div>
    </div>
    <div
      class="row history-list"
      *ngFor="let auditLog of auditLogs$ | async; index as i"
      [ngClass]="{ 'pt-0': i === 0 }"
    >
      <div class="col-4 history-list-text">
        {{ auditLog.editDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
      </div>

      <!--Template for list activity-->
      <div class="col-4 history-list-text pl-0 pr-0">
        <div *ngIf="!auditLog.activityList; else collapse">
          {{ historyType + '.HISTORY_ACTIVITY.' + auditLog.activity | translate }}
          <span *ngIf="!auditLog.description; else desc">({{ auditLog.noOfItem | numberFormatDisplay: 0 }})</span>
          <ng-template #desc>
            <div class="history-list-desc">- {{ auditLog.description }}</div>
          </ng-template>
        </div>
        <ng-template #collapse>
          <ul class="activity-title">
            <li
              *ngFor="let activity of auditLog.activityList | keyvalue; last as isLast"
              [ngClass]="{ active: listState[activity.key], 'mb-2': !isLast }"
            >
              <a (click)="toggle(auditLog.refNo, activity.key)"
                >{{ historyType + '.HISTORY_ACTIVITY.' + activityList[activity.key] | translate }} ({{
                  activity.value.length | numberFormatDisplay: 0
                }})</a
              >
              <ul *ngIf="activity.value.length > 0" class="data-list" [@slide]="getState(auditLog.refNo, activity.key)">
                <li *ngFor="let value of activity.value">
                  {{ value }}
                </li>
              </ul>
            </li>
          </ul>
        </ng-template>
      </div>
      <!--End Template for list activity-->

      <div class="col-4 history-list-text">
        <span>{{ auditLog.editByName }}</span>
      </div>
    </div>
  </div>
</div>
