import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { shelfTypeStatusEnum } from '../enum/request-status.enum';
import { OrderTemplateRequest, ShelfItems, ShelfTypeRequestCriteria, SignedUrlObject } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class ShelfTypesService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.shelfType;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getShelfList(term?): Observable<any> {
    const params = this.getParams({
      sortBy: 'name',
      sortOrder: 'asc',
      ...term
    });

    const url = this.getFullUrl(this.envService.getShelfTypes);
    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(map(data => data));
  }

  public getShelfTypeList(status?: shelfTypeStatusEnum): Observable<any> {
    const criteria: ShelfTypeRequestCriteria = {
      page: '0',
      size: '100',
      sortBy: 'name',
      sortOrder: 'asc',
      ...(status && { status })
    };

    const params = this.getParams(criteria);

    const url = this.getUrl();
    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(map(data => data));
  }

  public addShelfTypeList(shelfType: ShelfItems[]) {
    return this.http.post<any>(this.getUrl(), shelfType, { headers: this.headers });
  }

  public generateOrder(payload: OrderTemplateRequest): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders(this.env.services.shelfType.headers);
    const url = `${this.env.serverUrl}${this.env.services.shelfType.url}${this.env.services.shelfType.generateOrder}`;

    return this.http.post<any>(url, payload, { headers, responseType: 'blob' as 'json' });
  }

  public getFileUrl(refId: string): Observable<SignedUrlObject> {
    const url = this.getFullUrl(this.envService.fileUrl);
    return this.http.post<SignedUrlObject>(url, { refId }, { headers: this.headers });
  }
}
