import { createSelector } from '@ngrx/store';
import * as fromGoodsReceive from '../reducers/goods-receive.reducers';
import { AppStates } from '../state/app.states';

const selectGoodsReceiveState = (state: AppStates) => state.goodsReceive;

export const selectGoodsReceive = createSelector(
  selectGoodsReceiveState,
  (state: fromGoodsReceive.GoodsReceiveState) => state
);

export const selectGoodsReceiveList = createSelector(selectGoodsReceiveState, fromGoodsReceive.selectAll);

export const selectCreateGoodsReceive = createSelector(selectGoodsReceiveState, state => {
  return state.goodsReceive;
});

export const selectGoodsReceiveSaveStatus = createSelector(selectGoodsReceiveState, state => {
  return state.saveSuccess;
});

export const selectGoodsReceiveCriteriaObject = createSelector(selectGoodsReceiveState, state => {
  return state.criteriaObject;
});

export const selectGoodsReceiveErrorResponse = createSelector(selectGoodsReceiveState, state => {
  return state.goodsReceiveErrorResponse;
});
