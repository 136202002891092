import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { PasswordValidation } from '../../shared/custom-validators/password-validation';
import { ForceChangePasswordRequestAction } from '../../shared/store/actions/user-info.action';
import { selectUserInfoResult } from '../../shared/store/selectors/auth-user-info.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/user-selector';
import { AppStates } from '../../shared/store/state/app.states';

@Component({
  selector: 'app-force-change-password',
  templateUrl: './force-change-password.component.html',
  styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  public submitted;
  public forceChangePasswordResult$: Observable<any>;
  public userInfoResult$: Observable<any>;
  public userName: string;
  public isShowPassword: boolean;
  public isShowReEnterPassword: boolean;
  private localStore: Observable<any>;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly router: Router
  ) {
    this.changePasswordForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, PasswordValidation]],
        reEnterPassword: ['', [Validators.required]]
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));
    this.submitted = false;

    this.forceChangePasswordResult$.subscribe(data => {
      if (!data.result.response && data.result.errorResponse.code === '02002') {
        this.changePasswordFormGetter.newPassword.setErrors({ passwordSamePrevious: true });
      } else if (data.result.response && data.result.response.statusCode === '200') {
        this.router.navigateByUrl('/');
      }
    });
    this.userInfoResult$ = this.localStore.pipe(select(selectUserInfoResult));
    this.userInfoResult$.subscribe(userInfo => {
      this.userName = userInfo.userName;
    });
  }

  ngOnDestroy(): void {}

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.valid) {
      this.store.dispatch(
        new ForceChangePasswordRequestAction({
          userName: this.userName,
          newPassword: this.changePasswordFormGetter.newPassword.value
        })
      );
    }
  }

  get changePasswordFormGetter(): {
    [key: string]: AbstractControl;
  } {
    return this.changePasswordForm.controls;
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    const pass = group.controls.newPassword.value;
    const confirmPass = group.controls.reEnterPassword.value;
    const error = pass === confirmPass ? null : { passwordNotEquivalent: true };

    return group.controls.reEnterPassword.setErrors(error);
  }

  toggleInputPasswordType() {
    this.isShowPassword = !this.isShowPassword;
  }

  toggleInputReEnterPasswordType() {
    this.isShowReEnterPassword = !this.isShowReEnterPassword;
  }

  clearPasswordSamePreviousError() {
    this.changePasswordFormGetter.newPassword.setErrors(null);
  }
}
