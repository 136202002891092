<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Add/Delete CJ Product</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeUnLinkModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="searchForm">
    <div class="form-row" *ngIf="useCJProduct">
      <div class="form-group col-md">
        <label>Delete CJ Article No.</label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control"
            id="deleteCJArticleNo"
            placeholder="Delete CJ Article No."
            value="{{ articleNo + ': ' + productName }}"
            disabled
          />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md">
        <label for="article"
          >Add CJ Article No.<span class="text-danger" *ngIf="useCJProduct === false">*</span></label
        >
        <div class="d-flex">
          <ng-select
            id="article"
            class="w-100"
            [items]="articlesList | async"
            labelForId="article"
            placeholder="Please select..."
            [loading]="articlesSearchLoading"
            [typeahead]="articlesSearchInput$"
            [searchable]="true"
            [clearable]="true"
            [multiple]="false"
            formControlName="article"
            (blur)="onBlurArticle()"
            (change)="onChangeArticle($event)"
            [ngClass]="{
              'is-invalid': isSubmitted && searchForm.controls.article.errors
            }"
          >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                {{ item.articleNo + ': ' + item.productName }}
              </div>
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              {{ item.articleNo + ': ' + item.productName }}
            </ng-template>
          </ng-select>
        </div>
        <div *ngIf="isSubmitted && searchForm.controls.article.errors?.required" class="invalid-display">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="closeUnLinkModal()" id="btCancel">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" id="btnSubmit" (click)="onAddNewCJArticle()">
      OK
    </button>
  </div>
</div>
