import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StockStoreContent } from '../../../../shared/models';
import { AuthGuardService } from '../../../../shared/services';
import {
  StockInformationStoreRequestAction,
  StockInformationStoreResetAction
} from '../../../../shared/store/actions/stock-information.actions';
import { StockInformationStoreState } from '../../../../shared/store/reducers/stock-information-store.reducers';
import {
  selectAllStockStoreList,
  selectStockStoreList,
  selectStockStoreListCriteria
} from '../../../../shared/store/selectors/stock-information.selector';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-stock-store',
  templateUrl: './stock-store.component.html',
  styleUrls: ['./stock-store.component.scss']
})
export class StockStoreComponent implements OnInit, OnDestroy {
  @Input() warehouse: string;
  @Input() articleNo: string;
  public dateFormat = environment.dateFormat;
  public dateTimeDisplay = environment.dateTimeDisplay;
  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;
  public searchForm: FormGroup;
  resultStockList$: Observable<StockStoreContent[]>;
  listStockState$: Observable<StockInformationStoreState>;
  private localStore: Observable<any>;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.setInitialCriteriaObject();
    this.initialData();
    this.createSearchForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStockStoreListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.search();
  }

  initialData() {
    this.resultStockList$ = this.store.select(selectAllStockStoreList);
    this.listStockState$ = this.store.select(selectStockStoreList);
  }
  setInitialCriteriaObject() {
    this.pageSize = 20;

    this.criteriaObject = {
      page: 0,
      size: 20
    };
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  search() {
    this.store.dispatch(
      new StockInformationStoreRequestAction({
        criteria: this.criteriaObject,
        warehouse: 'store',
        articleNo: this.articleNo
      })
    );
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.search();
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.search();
    }
  }

  clearSearchText() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.search();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StockInformationStoreResetAction());
  }
}
