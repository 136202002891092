import { DeliveryOrderStatusEnum } from '../enum/delivery-order-status.enum';
import { ShipmentStatusEnum } from '../enum/shipment-status.enum';
import { AuditLog } from './audit-log.model';

export interface ShipmentList {
  id: string;
  deliveryOrderNo: string;
  orderNo: string;
}

export interface CreateShipmentRequest {
  courierCode: string;
  courierName: string;
  shipCharge: number;
  plateNo: string;
  driverName: string;
  deliveryDate: string;
  pickupDate: string;
  deliveryOrders?: string[];
}

export class ShipmentSearchCriteria {
  searchCriteria?: string;
  status?: ShipmentStatusEnum;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  pickupDateFrom?: string;
  pickupDateTo?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  warehouseCode?: string[];
  page: number;
  size: number;
}

export class ShipmentExportCriteria {
  createdDateFrom?: string;
  createdDateTo?: string;
  deliveryDateFrom?: string;
  deliveryDateTo?: string;
  statuses?: ShipmentStatusEnum;
  warehouseCode?: string[];
}
export class ShipmentPagination {
  content: Shipment[];
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
}

export class Shipment {
  id?: string;
  version?: number;
  shipmentNo?: string;
  status?: ShipmentStatusEnum;
  courierCode?: string;
  courierName?: string;
  shipCharge?: number;
  plateNo?: string;
  driverName?: string;
  deliveryDate?: string;
  pickupDate?: string;
  createdDate?: string;
  createdByName?: string;
  createdBy?: string;
  deliveryOrders?: DeliveryOrderShipment[];
  deliveryOrder?: DeliveryOrderShipment;
  shipmentHistories?: AuditLog[];
  platNoDriverNameDisplay?: string;
  warehouseCode?: string;
  warehouseName?: string;
  warehouseDisplayName?: string;

  constructor() {
    this.id = null;
    this.shipmentNo = null;
    this.status = null;
    this.courierCode = null;
    this.shipCharge = 0;
    this.plateNo = null;
    this.driverName = null;
    this.deliveryDate = null;
    this.pickupDate = null;
    this.deliveryOrders = [];
    this.createdDate = null;
    this.createdBy = null;
  }
}

export class DeliveryOrderShipment {
  id?: string;
  version?: number;
  doNo: string;
  poNo: string;
  storeCode: string;
  storeName: string;
  requestedDeliveryDate: string;
  createdDate?: string;
  doStatus: DeliveryOrderStatusEnum;
  isCurrentDO?: boolean;
  shipmentNo?: string;
  warehouseCode?: string;
}
