import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProfitSharingService } from '../../services/profit-sharing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { ProfitSharingActionTypes, ProfitSharingListResponseAction, ProfitSharingSubmitErrorAction, ProfitSharingViewResponseAction } from '../actions/profit-sharing.actions';
export class ProfitSharingEffects {
    constructor(actions$, logger, profitSharingService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.profitSharingService = profitSharingService;
        this.searchProfitSharing$ = this.actions$.pipe(ofType(ProfitSharingActionTypes.PROFIT_SHARING_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ProfitSharingActionTypes.PROFIT_SHARING_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.profitSharingService.searchByCriteria(payload).pipe(map(response => {
                return new ProfitSharingListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.loadProfitSharing$ = this.actions$.pipe(ofType(ProfitSharingActionTypes.PROFIT_SHARING_VIEW_REQUEST), tap(action => this.logger.debug(`@Effect ${ProfitSharingActionTypes.PROFIT_SHARING_VIEW_REQUEST}: ` + JSON.stringify(action.payload))), switchMap(action => this.profitSharingService.getProfitSharingById(action.payload).pipe(map(result => new ProfitSharingViewResponseAction(result)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.submitProfitSharing$ = this.actions$.pipe(ofType(ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_REQUEST), tap(action => this.logger.debug(`@Effect ${ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_REQUEST}: ` + JSON.stringify(action.payload))), switchMap(action => this.profitSharingService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Profit Sharing has been updated.'
        })), catchError(error => {
            if (error.error && error.error.code === '11006') {
                return of(new ProfitSharingSubmitErrorAction(error.error.message));
            }
            else {
                return of(new LayoutActionLoadError(error));
            }
        }))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfitSharingEffects.prototype, "searchProfitSharing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfitSharingEffects.prototype, "loadProfitSharing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProfitSharingEffects.prototype, "submitProfitSharing$", void 0);
