import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PromotionTransFrom } from '../../models';
import { PromotionRequestService } from '../../services/promotion-request.service';
import { PromotionService } from '../../services/promotion.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { PromotionActionTypes, PromotionCreateSaveErrorAction, PromotionCreateSaveResponseAction, PromotionCreateSubmitResponseAction, PromotionListResponseAction, PromotionRequestGetByIdResponseAction, PromotionRequestListResponseAction, PromotionThemeActiveResponseAction, PromotionThemeAddError, PromotionThemeAddSuccess, PromotionThemeComingResponseAction } from '../actions/promotion.action';
export class PromotionEffects {
    constructor(actions$, logger, promotionService, promotionRequestService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.promotionService = promotionService;
        this.promotionRequestService = promotionRequestService;
        this.listOfPromotionTheme$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_THEME_LIST_REQUEST), tap(action => this.logger.debug('@Effect Promotion Theme: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.promotionService.searchTheme(action.payload).pipe(map(themes => {
                const themesBkk = new Array();
                themes.forEach(item => {
                    themesBkk.push(PromotionTransFrom.mappingPrmotionThemeTimeUtcToBkk(item));
                });
                if (action.payload.type === 'effectiveDate') {
                    return new PromotionThemeComingResponseAction(themesBkk);
                }
                else {
                    return new PromotionThemeActiveResponseAction(themesBkk);
                }
            }), 
            // Handling Errors in NgRx Effects : https://medium.com/city-pantry/handling-errors-in-ngrx-effects-a95d918490d9
            catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.addTheme$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_THEME_ADD_REQUEST), tap(action => this.logger.debug('@Effect Add Theme: ' + JSON.stringify(action.payload))), switchMap(action => {
            const theme = PromotionTransFrom.mappingPromotionThemeTimeBkktoUtc(action.payload);
            return this.promotionService.addTheme(theme).pipe(map(res => new PromotionThemeAddSuccess(res)), catchError(err => {
                return err.error && err.error.code === '00001'
                    ? of(new PromotionThemeAddError(err.error))
                    : of(new LayoutActionLoadError(err));
            }));
        }));
        this.createRequestPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_CREATE_SAVE_REQUEST), tap(action => this.logger.debug('@Effect Request Promtion Create Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            const promotion = Object.assign({}, action.payload.promotionCreatRequest, { details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details) });
            return this.promotionRequestService.createRequest(promotion).pipe(map(res => new PromotionCreateSaveResponseAction(res)), catchError(err => {
                return this.errorHandling(err);
            }));
        }));
        this.editRequestPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_EDIT_SAVE_REQUEST), tap(action => this.logger.debug('@Effect Request Promtion Edit Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            const promotion = Object.assign({}, action.payload.promotionCreatRequest, { details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details) });
            return this.promotionRequestService.updateByRequestId(promotion).pipe(map(res => new PromotionCreateSaveResponseAction(res)), catchError(err => {
                return this.errorHandling(err);
            }));
        }));
        this.deleteRequestPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_DELETE_REQUESTED), tap(action => this.logger.debug('@Effect Request Delete: ' + JSON.stringify(action.payload))), switchMap(action => this.promotionRequestService.delete(action.payload.requestId).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been deleted.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.cancelPromotionRequest$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_CANCEL_REQUESTED), tap(action => this.logger.debug('@Effect Request Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.promotionRequestService.requestCancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been cancelled.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.cancelPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_CANCEL_REQUESTED), tap(action => this.logger.debug('@Effect Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.promotionService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This promotion has been cancelled.'
        })), catchError(err => {
            return err.error && err.error.code === '00004'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
        this.searchPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${PromotionActionTypes.PROMOTION_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.promotionService.searchByCriteria(payload).pipe(map(requests => {
                const content = new Array();
                requests.content.forEach(item => {
                    content.push(PromotionTransFrom.mappingPromotionContentTimeUtcToBkk(item));
                });
                const promotion = Object.assign({}, requests, { content });
                return new PromotionListResponseAction(promotion);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchPromotionRequest$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${PromotionActionTypes.PROMOTION_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.promotionRequestService.searchByCriteria(payload).pipe(map(requests => {
                const content = new Array();
                requests.content.forEach(item => {
                    content.push(PromotionTransFrom.mappingPromotionRequestTimeUtcToBkk(item));
                });
                const promotion = Object.assign({}, requests, { content });
                return new PromotionRequestListResponseAction(promotion);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.createSubmitPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_CREATE_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect Request Promtion Create Submit Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            const promotion = Object.assign({}, action.payload.promotionCreatRequest, { details: PromotionTransFrom.mappingPromotionTimeBkktoUtc(action.payload.promotionCreatRequest.details) });
            return this.promotionRequestService.submit(promotion).pipe(map(res => new PromotionCreateSubmitResponseAction(res)), catchError(error => of(new PromotionCreateSaveErrorAction(error))));
        }));
        this.loadRequestPromotion$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Promotion Request By ID Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.promotionRequestService.getByRequestId(action.payload.requestId).pipe(map(request => this.transformRequestDetails(request)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.rejectRequestPromotions$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_REJECT_REQUESTED), tap(action => this.logger.debug('@Effect Request Reject: ' + JSON.stringify(action.payload))), mergeMap(action => this.promotionRequestService.reject(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been rejected.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.approveRequestPromotions$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_REQUEST_APPROVE_REQUESTED), tap(action => this.logger.debug('@Effect Request Approve: ' + JSON.stringify(action.payload))), mergeMap(action => this.promotionRequestService.approve(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been approved.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.getPromotionById$ = this.actions$.pipe(ofType(PromotionActionTypes.PROMOTION_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Promotion By code Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.promotionService.getPromotionById(action.payload.promotionCode).pipe(map(request => this.transformRequestDetails(request)), catchError(err => of(new LayoutActionLoadError(err))))));
    }
    transformRequestDetails(request) {
        const promotion = Object.assign({}, request, { details: PromotionTransFrom.mappingPromotionTimeUtcToBkk(request.details) });
        return new PromotionRequestGetByIdResponseAction(promotion);
    }
    errorHandling(err) {
        return err.error && err.error.code !== '00001'
            ? of(new LayoutActionLoadError(err))
            : of(new PromotionCreateSaveErrorAction(err.error));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "listOfPromotionTheme$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "addTheme$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "createRequestPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "editRequestPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "deleteRequestPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "cancelPromotionRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "cancelPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "searchPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "searchPromotionRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "createSubmitPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "loadRequestPromotion$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "rejectRequestPromotions$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "approveRequestPromotions$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PromotionEffects.prototype, "getPromotionById$", void 0);
