import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StoreAssortmentService } from '../../services/store-assortment.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StoreAssortmentActionType, StoreAssortmentByIdResponseAction, StoreAssortmentListHistoryResponseAction, StoreAssortmentListResponseAction } from '../actions/store-assortment.actions';
export class StoreAssortmentEffects {
    constructor(actions$, storeAssortmentService, store$, logger) {
        this.actions$ = actions$;
        this.storeAssortmentService = storeAssortmentService;
        this.store$ = store$;
        this.logger = logger;
        this.searchStoreAssortment$ = this.actions$.pipe(ofType(StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.searchByCriteria(payload).pipe(map(response => {
                return new StoreAssortmentListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfHistory$ = this.actions$.pipe(ofType(StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.getHistoryLogs(payload).pipe(map(response => new StoreAssortmentListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getStoreAssortmentById$ = this.actions$.pipe(ofType(StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.getStoreAssortmentById(payload).pipe(map(res => {
                return new StoreAssortmentByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentEffects.prototype, "searchStoreAssortment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentEffects.prototype, "shelfHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreAssortmentEffects.prototype, "getStoreAssortmentById$", void 0);
