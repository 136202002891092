import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import {
  PurchaseRequestModeEnum,
  PurchaseRequestStatusEnum,
  PurchaseRequestTypeEnum
} from '../../../shared/enum/purchase-request.enum';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { BillToMasterListRequestAction } from '../../../shared/store/actions/bill-to-master.actions';
import { selectBillToMaster } from '../../../shared/store/selectors/bill-to-master-selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-purchase-request-delivery-details',
  templateUrl: './purchase-request-delivery-details.component.html',
  styleUrls: ['./purchase-request-delivery-details.component.scss']
})
export class PurchaseRequestDeliveryDetailsComponent implements OnInit, OnDestroy {
  @Input() parentFormItemIndex: FormGroup;
  @Input() data: any;
  @Input() isSubmit: boolean;

  @Output() parentFormItem: FormArray;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public shipToList: Observable<any[]>;
  public shipToSearchLoading = false;
  public shipToSearchInput$ = new Subject<string>();

  public billToList: NgOption[];

  public tmpForm: FormGroup;
  public bsConfig: BsDatepickerConfig;

  public applyAll: boolean;
  public isViewMode: boolean;
  public updated: boolean;
  public dateFormat = env.dateFormat;

  private localStore: Observable<any>;

  constructor(
    protected bsModalRef: BsModalRef,
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new BillToMasterListRequestAction());
    this.loadMasterData();
    this.createForm();
    this.setInitialElementValue();

    this.isViewMode = [PurchaseRequestModeEnum.VIEW, PurchaseRequestModeEnum.PARTIAL_APPROVE].includes(
      this.data.mode
    );

    if (this.isViewMode) {
      this.tmpForm.disable();
    }
  }

  ngOnDestroy(): void {
    const currentRequestDeliveryDate = this.parentFormItemIndex.get('requestDeliveryDate').value;
    const currentDate = new Date();
    const currentDateStart = moment(currentDate)
      .startOf('day')
      .toDate();
    if (
      currentRequestDeliveryDate &&
      currentRequestDeliveryDate < currentDateStart &&
      !this.isTypeZ9 &&
      this.data.status === PurchaseRequestStatusEnum.DRAFT
    ) {
      this.parentFormItemIndex.get('requestDeliveryDate').setErrors({ invalidDate: true });
    }
  }

  createForm() {
    const parentFormItemIndex = this.parentFormItemIndex.controls;
    this.tmpForm = this.fb.group({
      requestDeliveryDate: [
        {
          value: parentFormItemIndex['requestDeliveryDate'].value,
          disabled: parentFormItemIndex['requestDeliveryDate'].disabled
        },
        parentFormItemIndex['requestDeliveryDate'].validator ? Validators.required : null
      ],
      deliveryNote: [
        {
          value: parentFormItemIndex['deliveryNote'].value,
          disabled: parentFormItemIndex['deliveryNote'].disabled
        }
      ],
      shipToId: parentFormItemIndex['shipToId'].value,
      shipToName: [
        {
          value: parentFormItemIndex['shipToName'].value,
          // disabled: parentFormItemIndex['shipToName'].disabled
          disabled: true
        },
        parentFormItemIndex['shipToName'].validator ? Validators.required : null
      ],
      shipToDetail: parentFormItemIndex['shipToDetail'].value,
      shipToContactName: [parentFormItemIndex['shipToContactName'].value, Validators.required],
      shipToContactNumber: [parentFormItemIndex['shipToContactNumber'].value, Validators.required],
      billToId: parentFormItemIndex['billToId'].value,
      billToName: [
        {
          value: parentFormItemIndex['billToName'].value,
          disabled: parentFormItemIndex['billToName'].disabled
        },
        parentFormItemIndex['billToName'].validator ? Validators.required : null
      ],
      billToDetail: parentFormItemIndex['billToDetail'].value,
      billToContactName: [parentFormItemIndex['billToContactName'].value, Validators.required],
      billToContactNumber: [parentFormItemIndex['billToContactNumber'].value, Validators.required]
    });
  }

  setInitialElementValue() {
    this.updated = false;
    this.applyAll = this.isTypeZ9;
    this.bsConfig = {
      dateInputFormat: this.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue',
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  loadMasterData() {
    this.localStore
      .pipe(
        select(selectBillToMaster),
        filter(billToMaster => billToMaster != null)
      )
      .subscribe(billToMaster => {
        this.billToList = billToMaster;
      });
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm(): void {
    this.updated = true;
    const value = this.tmpForm.getRawValue();
    const currentDate = new Date();
    const currentDateStart = moment(currentDate)
      .startOf('day')
      .toDate();
    const currentRequestDeliveryDate = value.requestDeliveryDate;

    if (currentRequestDeliveryDate && currentRequestDeliveryDate < currentDateStart && !this.isTypeZ9) {
      this.tmpForm.get('requestDeliveryDate').setErrors({ invalidDate: true });
    }

    if (this.tmpForm.invalid) {
      return;
    }

    if (this.applyAll) {
      this.parentFormItem.controls.forEach(itemForm => {
        itemForm.get('deliveryDetail').patchValue(value);
      });
    } else {
      this.parentFormItemIndex.patchValue(value);
    }
    this.bsModalRef.hide();
  }

  onChangeShipToName($event) {
    if ($event) {
      this.tmpForm.patchValue({
        shipToId: $event.id,
        shipToName: $event.name,
        shipToDetail: $event.address
      });
    }
  }

  onChangeBillToName($event) {
    if ($event) {
      this.tmpForm.patchValue({
        billToId: $event.code,
        billToName: $event.nameEn,
        billToDetail: $event.address
      });
    }
  }

  onChangeDate($event) {
    if ($event && !isNaN($event.getTime())) {
      const currentDate = new Date();
      const currentDateStart = moment(currentDate)
        .startOf('day')
        .toDate();
      const requestDeliveryDate = this.tmpForm.get('requestDeliveryDate').value;
      if (
        requestDeliveryDate > currentDateStart &&
        this.parentFormItemIndex.get('requestDeliveryDate').errors &&
        this.parentFormItemIndex.get('requestDeliveryDate').errors.invalidDate
      ) {
        this.parentFormItemIndex.get('requestDeliveryDate').setErrors(null);
      }
    }
  }

  onChangeApplyAll() {
    this.applyAll = !this.applyAll;
  }

  get isTypeZ9() {
    return [PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.type);
  }
}
