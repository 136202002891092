/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./tier-tag.component";
var styles_TierTagComponent = [];
var RenderType_TierTagComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TierTagComponent, data: {} });
export { RenderType_TierTagComponent as RenderType_TierTagComponent };
function View_TierTagComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "tier-color"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tier; _ck(_v, 1, 0, currVal_0); }); }
export function View_TierTagComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TierTagComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tier; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TierTagComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-tier-tag", [], null, null, null, View_TierTagComponent_0, RenderType_TierTagComponent)), i0.ɵdid(1, 114688, null, 0, i2.TierTagComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TierTagComponentNgFactory = i0.ɵccf("app-tier-tag", i2.TierTagComponent, View_TierTagComponent_Host_0, {}, {}, []);
export { TierTagComponentNgFactory as TierTagComponentNgFactory };
