import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Z8ParameterList, Z8ParameterListSearchCriteria } from '../../models/z8-parameter.model';
import { Z8ParameterActions, Z8ParameterActionType } from '../actions/z8-parameter.actions';
import { BaseState } from '../state/base.state';

export interface Z8ParameterState extends EntityState<Z8ParameterList>, BaseState {
  selected: Z8ParameterList;
  criteriaObject: Z8ParameterListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<Z8ParameterList> = createEntityAdapter<Z8ParameterList>();

export const initialZ8ParameterResponseState: Z8ParameterState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function z8ParameterReducers(
  state = initialZ8ParameterResponseState,
  action: Z8ParameterActions
): Z8ParameterState {
  switch (action.type) {
    case Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case Z8ParameterActionType.Z8_PARAMETER_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case Z8ParameterActionType.Z8_PARAMETER_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case Z8ParameterActionType.Z8_PARAMETER_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
