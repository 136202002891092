import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SupplierGroupService } from '../../services/supplier-group.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { SupplierGroupActionTypes, SupplierGroupListResponse, SupplierGroupViewLoaded } from '../actions/supplier-group.actions';
export class SupplierGroupEffects {
    constructor(actions$, supplierGroupService, logger) {
        this.actions$ = actions$;
        this.supplierGroupService = supplierGroupService;
        this.logger = logger;
        this.searchSupplierGroup$ = this.actions$.pipe(ofType(SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Group List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupService.searchByCriteria(action.payload).pipe(map(response => new SupplierGroupListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.loadSupplierGroup$ = this.actions$.pipe(ofType(SupplierGroupActionTypes.SUPPLIER_GROUP_VIEW_REQUESTED), tap(action => this.logger.debug('@Effect Supplier Group Get Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupService.getSupplierGroupById(action.payload).pipe(map(result => new SupplierGroupViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupEffects.prototype, "searchSupplierGroup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupEffects.prototype, "loadSupplierGroup$", void 0);
