import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { round } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { PurchaseRequestTypeEnum } from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { PurchaseRequestItem, TdAssortmentSearchCriteria } from '../../../shared/models/purchase-request.model';
import { AuthGuardService } from '../../../shared/services';
import { TdAssortmentService } from '../../../shared/services/td-assortment-service';
import { TdAssortmentOrderAddItem } from '../../../shared/store/actions/td-assortment-order.actions';
import { TdAssortmentListRequest } from '../../../shared/store/actions/td-assortment.actions';
import { TdAssortmentState } from '../../../shared/store/reducers/td-assortment.reducers';
import { selectMerchantRequestListCriteria } from '../../../shared/store/selectors/merchant-request.selector';
import {
  getCountTdAssortmentOrder,
  getTdAssortmentOrderBySupplierCodeAndBarcode
} from '../../../shared/store/selectors/td-assortment-order.selectors';
import {
  selectAllTdAssortmentList,
  selectTdAssortmentList
} from '../../../shared/store/selectors/td-assortment.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-td-assortment-list',
  templateUrl: './td-assortment-list.component.html',
  styleUrls: ['./td-assortment-list.component.scss']
})
export class TdAssortmentListComponent extends BaseSearchComponent<
  TdAssortmentSearchCriteria,
  PurchaseRequestItem,
  TdAssortmentState
> {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() data: {
    prType: PurchaseRequestTypeEnum;
    dayIndex: number;
    productType: ProductTypeEnum;
    shipToCode: string;
  };

  public totalOrder$ = this.store.pipe(select(getCountTdAssortmentOrder));
  private localStore: Observable<any>;
  public purchaseRequestTypeEnum = PurchaseRequestTypeEnum;
  errorExport: string;

  public buttons: Array<ImportExportButton>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly logger: NGXLogger,
    protected tdAssortmentService: TdAssortmentService,
    protected spinner: NgxSpinnerService
  ) {
    super(store, modalService, selectAllTdAssortmentList, selectTdAssortmentList);
  }

  onBack() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      productType: [this.data.productType],
      shipToCode: [this.data.shipToCode]
    });
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectMerchantRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.getButton();
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export',
        hidden: ![
          PurchaseRequestTypeEnum.Z9,
          PurchaseRequestTypeEnum.Z9_EDIT,
          PurchaseRequestTypeEnum.WAREHOUSE,
          PurchaseRequestTypeEnum.SUPPLIER
        ].includes(this.data.prType)
      }
    ];
  }
  goToView(viewParams?: any) {
    this.logger.debug('goToView', viewParams);
  }

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj) {
    if ([PurchaseRequestTypeEnum.Z9, PurchaseRequestTypeEnum.Z9_EDIT].includes(this.data.prType)) {
      criteriaObj.orderingCycle = this.data.dayIndex;
    }
    criteriaObj.productType = this.data.productType;
    this.store.dispatch(new TdAssortmentListRequest(criteriaObj));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      purchaseType: this.data.prType,
      shipToCode: this.data.shipToCode,
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setRouteTab() {}

  addItem(purchaseRequestItem: PurchaseRequestItem, qty: number) {
    purchaseRequestItem.isAddItem = true;
    if (qty) {
      let previousQty = 0;
      let previousVatAmount = 0;
      let needUpdatePreviousValue = false;
      const selectPreviousQty$ = this.localStore
        .pipe(
          select(
            getTdAssortmentOrderBySupplierCodeAndBarcode(
              purchaseRequestItem.supplierCode,
              purchaseRequestItem.barcode
            )
          )
        )
        .subscribe(value => {
          if (value) {
            needUpdatePreviousValue = true;
            previousQty = value.quantity;
            previousVatAmount = value.vatAmount;
          }
        });

      purchaseRequestItem.selected = true;
      purchaseRequestItem.quantity = Number(previousQty) + Number(qty);
      purchaseRequestItem.subTotalAmount = round(
        purchaseRequestItem.quantity *
          (purchaseRequestItem.newUnitPrice ? purchaseRequestItem.newUnitPrice : purchaseRequestItem.unitPrice),
        2
      );

      if (needUpdatePreviousValue) {
        purchaseRequestItem.vatAmount = previousVatAmount;
        purchaseRequestItem.totalVatAmount = round(purchaseRequestItem.quantity * previousVatAmount, 2);
      } else {
        purchaseRequestItem.totalVatAmount = round(purchaseRequestItem.quantity * purchaseRequestItem.vatAmount, 2);
      }

      this.store.dispatch(new TdAssortmentOrderAddItem(purchaseRequestItem));
      selectPreviousQty$.unsubscribe();
      this.notifyLeaveFormOutput.emit(true);
    }
  }

  changeQuantityValue(purchaseRequestItem: PurchaseRequestItem) {
    purchaseRequestItem.isAddItem = false;
    this.notifyLeaveFormOutput.emit(true);
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  exportProductPrice() {
    const request = this.prepareExportRequestData();

    this.tdAssortmentService.exportTdAssortment(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
        this.doSearch(this.criteriaObject);
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  prepareExportRequestData(): TdAssortmentSearchCriteria {
    return {
      purchaseType: this.data.prType,
      orderingCycle: this.data.dayIndex,
      productType: this.data.productType,
      shipToCode: this.data.shipToCode
    };
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportPr.timeFormat);

    return [PurchaseRequestTypeEnum.WAREHOUSE, PurchaseRequestTypeEnum.SUPPLIER].includes(this.data.prType)
      ? `${this.translate.instant(
          'PURCHASE_REQUEST.PURCHASE_REQUEST_TYPE.' + this.data.prType
        )}_${formattedDate}.xlsx`
      : `${this.translate.instant(
          'PURCHASE_REQUEST.PURCHASE_REQUEST_TYPE.' + this.data.prType
        )}_${this.translate.instant('DAY_INDEX.' + this.data.dayIndex)}_${formattedDate}.xlsx`;
  }

  alertErrorModal(errorResponse) {
    const initialState = {
      title: 'Failed',
      message: errorResponse.error.message
    };
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }
}
