import { ShelfDetailRequest } from './first-lot-order.model';
import { Pageable, Sort } from './pagination.model';

export class StoreAssortmentListSearchCriteria {
  searchCriteria?: string;
  storeType?: string;
  lastUpdatedDateFrom?: string;
  lastUpdatedDateTo?: string;
  page: number;
  size: number;
}

export interface StoreAssortmentList {
  storeNo: string;
  storeCodeName: string;
  storeType: string;
  noOfShelf: number;
  noOfAssortment: number;
  lastUpdatedDate: string;
  auditLogs?: AuditLog[];
}

export interface AuditLog {
  activity: string;
  activityList?: { [key: string]: Array<string> };
  editBy: string;
  editByName: string;
  editDate: string;
  noOfItem?: number;
  description?: string;
  refNo?: string;
}

export class StoreAssortmentListResponse {
  id: number;
  content: StoreAssortmentList[];
  pageable: Pageable;
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class StoreAssortmentViewResponse {
  storeNo: string;
  storeCodeName: string;
  storeType: string;
  noOfShelf: number;
  noOfAssortment: number;
  noOfMaster: number;
  noOfSpecial: number;
  lastUpdatedDate: string;
  shelfDetails: ShelfDetailRequest[];
}

export class MasterAssortmentListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class MasterAssortmentList {
  content: MasterAssortmentContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class MasterAssortmentContent {
  id: string;
  shelfName: string;
  shelfNo: string;
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
  productLocation: string;
  productTier: string;
  productGrading: string;
  stock: number;
  status: string;
  articleNo: string;
  z8Factor: { [key: string]: number };
}

export class SpecialAssortmentListSearchCriteria {
  searchCriteria?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class SpecialAssortmentList {
  content: SpecialAssortmentContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class SpecialAssortmentContent {
  id: string;
  barcode: string;
  productName: string;
  unit: string;
  unitFactor: number;
  productLocation: string;
  productTier: string;
  productGrading: string;
  stock: number;
  articleNo: string;
  z8Factor: { [key: string]: number };
}

export enum StoreAssortmentTypeEnum {
  MASTER_ASSORTMENT = 'MASTER_ASSORTMENT',
  SPECIAL_ASSORTMENT = 'SPECIAL_ASSORTMENT'
}

export class StoreAssortmentPrintCriteria {
  storeNo: string;
  format: string;
  locale: string;
}
