import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductAssortmentService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  OrderSelectItemActionTypes,
  OrderSelectItemListRequest,
  OrderSelectItemListResponse
} from '../actions/order-select-item.actions';
import { selectAllOrderSelectItemOrder } from '../selectors/order-select-item-order.selector';
import { AppStates } from '../state/app.states';

@Injectable()
export class OrderSelectItemEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly productAssortmentService: ProductAssortmentService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  orderSelectItemListRequest$ = this.actions$.pipe(
    ofType<OrderSelectItemListRequest>(OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${OrderSelectItemActionTypes.ORDER_SELECT_ITEM_LIST_REQUEST}:` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.productAssortmentService.searchCatalogByCriteria(action.payload).pipe(
        withLatestFrom(this.store.select(selectAllOrderSelectItemOrder)),
        map(([response, tdAssortmentOrder]) => {
          tdAssortmentOrder.forEach(order => {
            const foundObjectIndex = response.content.findIndex(content => {
              return content.articleNo === order.articleNo;
            });

            if (response.content[foundObjectIndex]) {
              response.content[foundObjectIndex].qty = order.qty;
              response.content[foundObjectIndex].allowToDelete = order.allowToDelete;
            }
          });
          return new OrderSelectItemListResponse(response);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );
}
