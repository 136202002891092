import { createSelector } from '@ngrx/store';
import * as fromAdjustState from '../reducers/adjust-stock.reducers';
import { AppStates } from '../state/app.states';

import * as fromAdjustItemState from '../reducers/adjust-stock-item.reducers';

const adjustStockState = (state: AppStates) => state.AdjustStock;

const adjustStockItemState = (state: AppStates) => state.AdjustStockItem;

export const selectAllAdjustList = createSelector(adjustStockState, fromAdjustState.selectAll);

export const selectAdjustList = createSelector(adjustStockState, (state: fromAdjustState.AdjustStockState) => state);

export const selectAdjustListCriteria = createSelector(adjustStockState, state => {
  return state.criteriaObject;
});

export const selectAdjustItemListCriteria = createSelector(adjustStockItemState, state => {
  return state.criteriaObject;
});

export const selectAdjustItemList = createSelector(
  adjustStockItemState,
  (state: fromAdjustItemState.AdjustStockItemState) => state
);

export const selectAdjustItemListAll = createSelector(adjustStockItemState, fromAdjustItemState.selectAll);

export const selectAdjustStockResponseError = createSelector(
  adjustStockState,
  (state: fromAdjustState.AdjustStockState) => state.adjustStockResponseError
);

export const selectViewAdjustStockResponse = createSelector(
  adjustStockState,
  (state: fromAdjustState.AdjustStockState) => state.selected
);

export const selectAdjustStockValidate = createSelector(
  adjustStockState,
  (state: fromAdjustState.AdjustStockState) => state.validatePass
);

export const selectImportAdjustStockResponse = createSelector(
  adjustStockState,
  (state: fromAdjustState.AdjustStockState) => state.importSuccess
);
