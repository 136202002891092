import { createSelector } from '@ngrx/store';
import * as fromSpecialAssortmentListState from '../reducers/special-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectSpecialAssortmentListState = (state: AppStates) => state.specialAssortment;

export const selectAllSpecialAssortmentList = createSelector(
  selectSpecialAssortmentListState,
  fromSpecialAssortmentListState.selectAll
);

export const selectSpecialAssortmentList = createSelector(
  selectSpecialAssortmentListState,
  (state: fromSpecialAssortmentListState.SpecialAssortmentState) => state
);

export const selectSpecialAssortmentListCriteria = createSelector(selectSpecialAssortmentListState, state => {
  return state.criteriaObject;
});
