import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MembershipService } from '../../services/membership.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { GenerateMemberCardListResponseAction, GenerateMemberCardResponseAction, MemberCardTypeActions } from '../actions/member-card.actions';
export class MemberCardEffects {
    constructor(actions$, memberCardService, logger) {
        this.actions$ = actions$;
        this.memberCardService = memberCardService;
        this.logger = logger;
        this.generateMemberCard = this.actions$.pipe(ofType(MemberCardTypeActions.GENERATE_MEMBER_CARD_REQUEST), tap(action => this.logger.debug('@Effect GENERATE_MEMBER_CARD_REQUEST: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.memberCardService.generateMemberCard(action.payload).pipe(map(() => new GenerateMemberCardResponseAction()), catchError(err => of(new LayoutActionLoadError(err))));
        }));
        this.searchMemberCardList$ = this.actions$.pipe(ofType(MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.memberCardService.searchMemberCardCriteria(payload).pipe(map(requests => {
                return new GenerateMemberCardListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MemberCardEffects.prototype, "generateMemberCard", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MemberCardEffects.prototype, "searchMemberCardList$", void 0);
