<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Select Warehouse</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeSelectWarehouseModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label>Warehouse</label>
        <app-dropdown-multiple-select
          [parentForm]="form"
          controlName="warehouses"
          [items]="warehouseList$ | async"
          bindLabel="locationDisplay"
        >
        </app-dropdown-multiple-select>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="closeSelectWarehouseModal()" id="btCancel">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" id="btnSubmit" (click)="onAddSelectWarehouse()">
      OK
    </button>
  </div>
</div>
