import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Z8ConditionsContinueCriteria,
  Z8ConditionsExistingResponse,
  Z8ConditionsListResponse,
  Z8ConditionsListSearchCriteria
} from '../models/z8-conditions.model';
import { Z8ResultResponse, Z8ResultSearchCriteria } from '../models/z8-result.model';
import { BaseService } from './base.service';

@Injectable()
export class Z8ConditionsService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.z8conditions;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: Z8ConditionsListSearchCriteria): Observable<Z8ConditionsListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public searchResultByCriteria(criteria: Z8ResultSearchCriteria): Observable<Z8ResultResponse> {
    const url = this.getFullUrl(this.envService.result);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public loadExisting(criteria: Z8ConditionsContinueCriteria): Observable<Z8ConditionsExistingResponse> {
    const url = this.getFullUrl(this.envService.continue);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
