import { createSelector } from '@ngrx/store';
import { UserCreateState } from '../reducers/user-create.reducers';
import * as fromUsersState from '../reducers/users.reducers';
import { AppStates } from '../state/app.states';

const selectUserState = (state: AppStates) => state.userCreate;
const selectUsersListState = (state: AppStates) => state.usersList;
const selectForceChangePassword = (state: AppStates) => state.forceChangePassword;

export const selectUserCreateResponseError = createSelector(
  selectUserState,
  (state: UserCreateState) => state.userResponseError
);

export const selectUserUpdateResponseError = createSelector(
  selectUserState,
  (state: UserCreateState) => state.userResponseError
);

export const selectAllUsersList = createSelector(selectUsersListState, fromUsersState.selectAll);

export const selectUsersList = createSelector(selectUsersListState, (state: fromUsersState.UsersState) => state);

export const selectUsersListCriteria = createSelector(selectUsersListState, state => {
  return state.criteriaObject;
});

export const selectUsersByNo = createSelector(selectUsersListState, state => {
  return state.selected;
});

export const selectChangeStatusUsersByUsernameResult = createSelector(selectUsersListState, state => {
  return state.changeStatusResponse;
});

export const selectForceChangePasswordResult = createSelector(selectForceChangePassword, state => state);
