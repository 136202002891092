<div *ngIf="fileList && fileList.length > 0; else noFiles">
  <div class="position-relative image-container" *ngFor="let file of fileList">
    <input
      class="form-control cursor-default"
      [ngClass]="{ enabled: !disabled, disabled: disabled, 'bg-h-change': hasChange }"
      readonly
      value="{{ file.name }}"
    />
    <em *ngIf="file.name && !disabled" class="icon-close-mini" (click)="onClickDelete()"></em>
  </div>
</div>

<ng-template #noFiles>
  <div class="upload-container text-center form-control" [ngClass]="{ disabled: disabled, 'is-invalid': hasError }">
    <input
      id="uploadFile"
      class="d-none"
      #uploadInput
      type="file"
      [disabled]="disabled"
      [multiple]="multiple || false"
    />
    <div class="input-group position-relative">
      <input
        placeholder="No File Chosen"
        class="form-control upload-input cursor-default"
        [disabled]="disabled"
        (click)="uploadInput.click()"
        readonly
        [ngClass]="{
          'is-invalid': hasError,
          'bg-h-change': hasChange,
          enabled: !disabled,
          disabled: disabled
        }"
      />
      <div class="input-group-append" *ngIf="!disabled">
        <button
          class="btn upload-btn"
          [disabled]="disabled"
          (click)="uploadInput.click()"
          [ngClass]="{
            'is-invalid': hasError
          }"
        >
          <em class="icon-upload"></em>
        </button>
      </div>
    </div>
  </div>
</ng-template>
