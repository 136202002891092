import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { environment } from '../environments/environment';

export function InitializeGraphqlFactory(httpLink: HttpLink) {
  return {
    cache: new InMemoryCache({ addTypename: true }),
    link: httpLink.create({
      uri: environment.serverUrl + environment.services.graphql.url,
      headers: new HttpHeaders(environment.services.graphql.headers)
    })
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: InitializeGraphqlFactory,
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
