/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../sidebar/sidebar.component.ngfactory";
import * as i4 from "../sidebar/sidebar.component";
import * as i5 from "../sidebar/sidebar.service";
import * as i6 from "@ngrx/store";
import * as i7 from "@angular/router";
import * as i8 from "../navbar/navbar.component.ngfactory";
import * as i9 from "../navbar/navbar.component";
import * as i10 from "./admin-layout.component";
import * as i11 from "ngx-bootstrap/modal";
var styles_AdminLayoutComponent = [i0.styles];
var RenderType_AdminLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminLayoutComponent, data: {} });
export { RenderType_AdminLayoutComponent as RenderType_AdminLayoutComponent };
export function View_AdminLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "page-wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(4, { toggled: 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-sidebar", [], null, null, null, i3.View_SidebarComponent_0, i3.RenderType_SidebarComponent)), i1.ɵdid(7, 114688, null, 0, i4.SidebarComponent, [i5.SidebarService, i6.Store, i7.Router], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-navbar", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).autoToggleMenu($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_NavbarComponent_0, i8.RenderType_NavbarComponent)), i1.ɵdid(9, 114688, null, 0, i9.NavbarComponent, [i5.SidebarService, i6.Store], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "page-content"], ["id", "page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(13, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "page-wrapper"; var currVal_1 = _ck(_v, 4, 0, i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_co.sideBarState$))); _ck(_v, 2, 0, currVal_0, currVal_1); _ck(_v, 7, 0); _ck(_v, 9, 0); _ck(_v, 13, 0); }, null); }
export function View_AdminLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-layout", [], null, null, null, View_AdminLayoutComponent_0, RenderType_AdminLayoutComponent)), i1.ɵdid(1, 245760, null, 0, i10.AdminLayoutComponent, [i7.Router, i2.Location, i5.SidebarService, i6.Store, i11.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminLayoutComponentNgFactory = i1.ɵccf("app-layout", i10.AdminLayoutComponent, View_AdminLayoutComponent_Host_0, {}, {}, []);
export { AdminLayoutComponentNgFactory as AdminLayoutComponentNgFactory };
