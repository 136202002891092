import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OrderService } from '../../services/order.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  OrderActionTypes,
  OrderListSearchLoaded,
  OrderListSearchRequested,
  OrderViewLoaded,
  OrderViewRequested
} from '../actions/order.actions';

@Injectable()
export class OrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly orderService: OrderService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchOrders$ = this.actions$.pipe(
    ofType<OrderListSearchRequested>(OrderActionTypes.OrderListSearchRequested),
    tap(action => this.logger.debug('@Effect Order List Search: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.orderService.searchByCriteria(action.payload).pipe(
        map(orders => new OrderListSearchLoaded({ orders })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  loadOrder$ = this.actions$.pipe(
    ofType<OrderViewRequested>(OrderActionTypes.OrderViewRequested),
    tap(action => this.logger.debug('@Effect Order View Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.orderService.getOrderRequest(action.payload).pipe(
        map(result => new OrderViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );
}
