import { createSelector } from '@ngrx/store';
import * as fromPurchaseOrder from '../reducers/purchase-order.reducers';
import { AppStates } from '../state/app.states';

const selectPurchaseOrderState = (state: AppStates) => state.purchaseOrder;

export const selectEditedPurchaseOrder = createSelector(selectPurchaseOrderState, state => {
  return state.purchaseOrder;
});

export const selectPurchaseOrder = createSelector(
  selectPurchaseOrderState,
  (state: fromPurchaseOrder.PurchaseOrderState) => state
);

export const selectPurchaseOrderList = createSelector(selectPurchaseOrderState, fromPurchaseOrder.selectAll);

export const selectPurchaseOrderCriteria = createSelector(selectPurchaseOrderState, state => {
  return state.criteriaObject;
});

export const selectPurchaseOrderCancelError = createSelector(
  selectPurchaseOrderState,
  (state: fromPurchaseOrder.PurchaseOrderState) => state.purchaseOrderCancelError
);
