<input
  id="uploadFile"
  #uploadInput
  type="file"
  class="d-none"
  (change)="handleFileInput()"
  [disabled]="disabled"
  [multiple]="multiple"
  onclick="this.value = null"
/>

<!--  Upload File-->
<button type="button" class="btn btn-standard" (click)="checkExistingUpload()" [disabled]="disabled">
  <em class="icon-import"></em>
  {{ text }}
</button>
