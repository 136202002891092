import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GraphqlQueryObject } from '../gql/common.gql';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "ngx-logger";
export class MasterService {
    constructor(apollo, logger) {
        this.apollo = apollo;
        this.logger = logger;
    }
    watchQuery(options) {
        return this.apollo
            .watchQuery(options)
            .valueChanges.pipe(tap(value => this.logger.debug('watchQuery', value)));
    }
    getMasterDataByNames(names) {
        return names.length ? this.watchQuery({ query: this.getGraphQuery(names) }) : of(null);
    }
    /**
     *
     * Sample usage
     * const dayQuery = new GraphqlQueryObject();
     * dayQuery.name = 'days';
     * dayQuery.sort = { orderBy: 'ID'} as GraphqlQuerySortOptions;
     * dayQuery.fields = dayQuery.defaultFields;
     *
     * const countryQuery = new GraphqlQueryObject();
     * countryQuery.name = 'countries';
     * countryQuery.fields = ['id', 'vatPct', 'defaultCurrency'];
     *
     * const stateQuery = new GraphqlQueryObject();
     * stateQuery.name = 'states';
     * stateQuery.sort = { orderBy: 'NAME_EN'} as GraphqlQuerySortOptions;
     * stateQuery.fields = [
     * 'code',
     * 'nameTh',
     * 'nameEn',
     * `region {
     *      nameTh
     *      code
     *    }`
     * ];
     */
    getGraphQuery(names) {
        const nodes = names.map(name => {
            if (typeof name === 'string') {
                return `${name} {
          id
          nameTh
          nameEn
          code
        }`;
            }
            else if (name instanceof GraphqlQueryObject) {
                return this.getGraphqlQueryObject(name);
            }
        });
        const nodesString = nodes.join('\n');
        // query {
        //   days(sort: { orderBy: ID }) {
        //     id
        //     nameTh
        //     nameEn
        //     code
        //   }
        // }
        // query {
        //   days(sort: { orderBy: ID }, type: '') {
        //     id
        //     nameTh
        //     nameEn
        //     code,
        //     type
        //   }
        // }
        return gql `
      query {
        ${nodesString}
      }
    `;
    }
    getGraphqlQueryObject(name) {
        const queryObject = name;
        const sort = queryObject.sort
            ? `sort: { orderBy: ${queryObject.sort.orderBy} ${queryObject.sort.orderDirection ? ', orderDirection: ' + queryObject.sort.orderDirection : ''}}`
            : null;
        const rawType = queryObject.type instanceof Array ? JSON.stringify(queryObject.type) : `"${queryObject.type}"`;
        const type = queryObject.type ? `type: ${rawType}` : null;
        const params = [sort, type].filter(Boolean).join(',');
        const queryParams = params ? `(${params})` : '';
        return `${queryObject.name}${queryParams} {
      ${(queryObject.fields ? queryObject.fields : queryObject.defaultFields).join('\n')}
    }`;
    }
}
MasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MasterService_Factory() { return new MasterService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.NGXLogger)); }, token: MasterService, providedIn: "root" });
