export const getFileId = (data: Array<any>): string | undefined => {
  if (!data) {
    return;
  }

  const res = data.map(v => v.id).filter(Boolean);
  return !res || res.length > 1 ? res : res[0];
};

export const getMultipleFileId = (data: Array<any>) => {
  const res = [...data].map(v => getFileId(v.image)).filter(Boolean);
  return res.length > 0 ? res : null;
};
