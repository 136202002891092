import { ErrorResponse } from '../../models/error.model';
import { ChangePasswordResponse } from './../../models/force-change-password.model';
import { UserActionTypes, UserInfoActions } from './../actions/user-info.action';

export interface BaseForceChangePasswordResponseState {
  result: {
    isSuccess: boolean;
    response: ChangePasswordResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialForceChangePasswordResponseState: BaseForceChangePasswordResponseState = {
  result: {
    isSuccess: false,
    response: null,
    errorResponse: new ErrorResponse()
  }
};

export function forceChangePasswordReducers(
  state = initialForceChangePasswordResponseState,
  action: UserInfoActions
): BaseForceChangePasswordResponseState {
  switch (action.type) {
    case UserActionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        result: {
          isSuccess: true,
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }

    case UserActionTypes.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        result: {
          isSuccess: false,
          response: null,
          errorResponse: {
            code: action.payload.code,
            message: action.payload.message,
            translateKey: action.payload.translateKey
          }
        }
      };
    }

    case UserActionTypes.CHANGE_PASSWORD_RESET: {
      return initialForceChangePasswordResponseState;
    }
    default:
      return state;
  }
}
