import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment as env } from '../../../../../environments/environment';
import { SearchBarcodeModalComponent } from '../../../../shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchProductModalComponent } from '../../../../shared/components/search-product-modal/search-product-modal.component';
import { UploadDocumentButtonComponent } from '../../../../shared/components/upload-document-button/upload-document-button.component';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import {
  NewRequestTypeEnum,
  RequestPageModesEnum,
  RequestProductErrorEnum
} from '../../../../shared/enum/request-step.enum';
import { Classes } from '../../../../shared/gql/classes.gql';
import { ProductGradings } from '../../../../shared/gql/product-grading.gql';
import { AlertModalComponent } from '../../../../shared/layouts';
import { UiPaginationComponent } from '../../../../shared/layouts/ui-pagination/ui-pagination.component';
import { BarcodeResponse } from '../../../../shared/models/barcode.model';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { ShelfRequestViewResponse } from '../../../../shared/models/shelf-request.model';
import {
  StoreAssortmentImportItem,
  StoreAssortmentItem
} from '../../../../shared/models/store-assortment-request.model';
import { ShelfRequestService } from '../../../../shared/services/shelf-request.service';
import { StoreAssortmentRequestService } from '../../../../shared/services/store-assortment-request.service';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.scss']
})
export class StoreAssortmentItemListComponent implements OnInit, OnDestroy {
  @Input() submitted: boolean;
  @Input() formItemList: FormGroup;
  @ViewChild('searchProductModal', { static: false }) searchProductModal: SearchProductModalComponent;
  @ViewChild('searchBarcodeModal', { static: false }) searchBarcodeModal: SearchBarcodeModalComponent;
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;
  @ViewChild('importItem', { static: false }) importItem: UploadDocumentButtonComponent;

  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  public localStore: Observable<any>;
  public shelfRequestView$: Observable<ShelfRequestViewResponse>;
  public requestNo: string;
  public type: NewRequestTypeEnum;
  public status: NewRequestStatusEnum;
  public productGradings: Array<ProductGradings>;
  public classes: Array<Classes>;
  public shelfCode: string;
  public editTypeList: Array<{ value: string; label: string }> = filterDropdown.storeAssortmentEditType;

  public currentPage = 1;
  public pageSize = 20;

  public downloadTemplate = `${env.serverUrl}${env.services.storeAssortment.downloadTemplate}`;
  public importStoreAssortmentUrl = `${env.serverUrl}${env.services.storeAssortment.url}${env.services.storeAssortment.importStoreAssorement}`;
  public importHeaders = env.services.storeAssortment.headers;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.IMPORT,
      name: 'Import Item'
    }
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly storeAssortmentRequestService: StoreAssortmentRequestService,
    private readonly shelfRequestService: ShelfRequestService,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.initData();
    this.initState();
  }

  ngOnDestroy(): void {}

  get requestPageModesEnum() {
    return RequestPageModesEnum;
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    const formArray = this.fb.array([], [this.barcodeValidator()]);
    this.formItemList.addControl('productItems', formArray);
  }

  initData() {
    this.shelfRequestService.getProductGrading().subscribe(result => {
      if (result.data) {
        this.productGradings = result.data.productGradings;
      }
    });

    this.shelfRequestService.getClasses().subscribe(result => {
      if (result.data) {
        this.classes = result.data.classes;
      }
    });
  }

  get form(): FormArray {
    return this.formItemList.get('productItems') as FormArray;
  }

  get formControls(): AbstractControl[] {
    return (this.formItemList.get('productItems') as FormArray).controls;
  }

  createForm(item: StoreAssortmentItem, isCalculateMinQty: boolean, errorMessage?: RequestProductErrorEnum) {
    const isProductError = !!errorMessage;

    const formItem: FormGroup = this.fb.group({
      barcode: item.barcode,
      classCode: item.classCode,
      articleNo: item.articleNo,
      productName: item.productName,
      productDisplayName: item.productDisplayName,
      productLocation: item.productLocation,
      unit: item.unit,
      unitFactor: item.unitFactor,
      productGrading: item.productGrading,
      productTier: item.productTier,
      plg: item.plg,
      editType: [item.editType ? item.editType : null, errorMessage ? null : Validators.required],
      facingQty: [
        { value: isProductError ? null : item.facingQty, disabled: false },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      stackingQty: [
        { value: isProductError ? null : item.stackingQty, disabled: false },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      depthQty: [
        { value: isProductError ? null : item.depthQty, disabled: false },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      minBeautyQty: [
        { value: item.minBeautyQty, disabled: true },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ]
    });

    if (isCalculateMinQty) {
      this.calculateGradingQty(formItem);
    }

    if (item.editType) {
      this.setValidationByEditType(item.editType, formItem);
    }

    if (errorMessage) {
      this.validateForm(formItem, errorMessage);
    }

    return formItem;
  }

  addNewItem() {
    this.searchProductModal.openSelectProductModal();
  }

  addNewBarcode() {
    this.searchBarcodeModal.selectBarcodeModal.show();
  }

  onAddItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      if (!this.checkDuplicatedBarcode(item.barcode)) {
        this.form.push(
          this.createForm(
            StoreAssortmentItem.mappingBarcodeResponseToStoreAssortmentItem(item),
            false,
            item.errorMessage
          )
        );
        this.form.markAsTouched();
      } else {
        this.alertErrorModal('Not allow to add duplicated barcode.');
        return;
      }
    });
  }

  onAddBarcodeItem(response: BarcodeResponse[]) {
    response.forEach(item => {
      this.form.push(
        this.createForm(
          StoreAssortmentItem.mappingBarcodeResponseToStoreAssortmentItem(item),
          true,
          item.errorMessage
        )
      );
    });

    this.form.markAsTouched();
  }

  onLoadItemDetail(value: StoreAssortmentImportItem[]) {
    // Reset form
    this.form.clear();

    const form = new FormArray([]);
    value.forEach(item => {
      form.push(this.createForm(this.prepareItemFromImport(item), true, item.errorMessage));
    });

    this.formItemList.setControl('productItems', form);

    this.validateImportedBarcode();

    this.form.setValidators(this.barcodeValidator());

    this.form.markAsTouched();
  }

  prepareItemFromImport(response: StoreAssortmentImportItem) {
    return {
      barcode: response.barcode,
      classCode: response.classCode,
      articleNo: response.articleNo,
      productName: response.productName,
      productDisplayName: response.productDisplayName,
      productLocation: response.productLocation,
      unit: response.unit,
      unitFactor: response.unitFactor,
      productGrading: response.productGrading,
      productTier: response.productTier,
      plg: response.productLocation,
      editType: response.editType,
      facingQty: response.facingQty,
      stackingQty: response.stackingQty,
      depthQty: response.depthQty,
      minBeautyQty: response.minBeautyQty
    };
  }

  onDownloadTemplate() {
    this.storeAssortmentRequestService.downloadTemplate().subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, 'Store Assortment Template.xlsx');
      },
      error => {
        if (error.error.code === '00004') {
          this.alertErrorModal(error.error.message);
        } else {
          this.alertErrorModal(this.translate.instant(error.error.translateKey));
        }
      }
    );
  }

  validatorFormControls(field: string, i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    return this.formControls[index].get(field).errors;
  }

  deleteItem(i: number) {
    const index = i + (this.currentPage - 1) * this.pageSize;
    this.form.removeAt(index);
    this.form.markAsTouched();
  }

  checkDuplicatedBarcode(barcode: string): boolean {
    return !!(this.formControls && this.formControls.find(x => x.get('barcode').value === barcode));
  }

  validateImportedBarcode() {
    const availableBarcodes = [];

    this.formControls.forEach((fg: FormGroup) => {
      if (fg.errors) {
        return;
      }

      const isDuplicated = availableBarcodes.indexOf(fg.value.barcode) > -1;
      if (!isDuplicated) {
        availableBarcodes.push(fg.value.barcode);
      }

      if (!fg.errors || fg.getError('duplicated')) {
        fg.setErrors(isDuplicated && { duplicated: true });

        if (isDuplicated) {
          fg.get('editType').clearValidators();
        }
      }
    });
  }

  barcodeValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();

      const availableBarcodes = [];
      for (let i = 0; i < array.length; i++) {
        const isDuplicated = availableBarcodes.indexOf(array[i].barcode) > -1;

        if (!isDuplicated) {
          availableBarcodes.push(array[i].barcode);
        }

        if (!fa.controls[i].errors || fa.controls[i].getError('duplicated')) {
          fa.controls[i].setErrors(isDuplicated && { duplicated: true });

          if (isDuplicated) {
            fa.controls[i].get('editType').clearValidators();
          }
        }
      }

      return null;
    };
  }

  calculateGradingQty(formItem: AbstractControl) {
    const facingQty = formItem.get('facingQty').value;
    const stackingQty = formItem.get('stackingQty').value;
    const depthQty = formItem.get('depthQty').value;
    const classCode = formItem.get('classCode').value;

    if (
      (!facingQty && facingQty !== 0) ||
      (!stackingQty && stackingQty !== 0) ||
      (!depthQty && depthQty !== 0) ||
      (!classCode && classCode !== 0)
    ) {
      formItem.get('minBeautyQty').patchValue(null);

      formItem.updateValueAndValidity();

      return;
    }

    const productGrading = formItem.get('productGrading').value;

    const beautyFromGrading = this.productGradings.find(item => item.code === productGrading).beautyPct || 0;
    const beautyFromClass = this.classes.find(item => item.code === classCode).beautyPct || 0;

    // Calculate Beauty Qty
    const beautyDepthQty = round((beautyFromGrading / 100) * depthQty);
    const maxBeautyQty = beautyDepthQty >= beautyFromClass ? beautyDepthQty : beautyFromClass;
    const comparedMinBeauty = maxBeautyQty <= depthQty ? maxBeautyQty : depthQty;
    const minBeautyQty = facingQty * stackingQty * comparedMinBeauty;

    formItem.get('minBeautyQty').patchValue(minBeautyQty);

    formItem.updateValueAndValidity();
  }

  validateForm(formItem: AbstractControl, errorMessage: RequestProductErrorEnum) {
    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('barcode').setValidators(this.setValidator(errorMessage));
        break;
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        formItem.setValidators(this.setValidator(errorMessage));
        formItem.get('productName').setValidators(this.setValidator(errorMessage));
        break;
      default:
        break;
    }
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }

          return null;
        default:
          return null;
      }
    };
  }

  onChangeEditType(event: any, formItem: AbstractControl) {
    this.setValidationByEditType(event.value, formItem);
  }

  setValidationByEditType(editType: string, formItem: AbstractControl) {
    const fields = ['facingQty', 'stackingQty', 'depthQty', 'minBeautyQty'];

    if (editType === 'DELETE') {
      fields.forEach(field => {
        formItem.get(field).patchValue(null);
        formItem.get(field).clearValidators();
        formItem.get(field).updateValueAndValidity();
      });
    } else if (editType === 'ADD') {
      fields.forEach(field => {
        formItem
          .get(field)
          .setValidators([Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]);
        formItem.get(field).updateValueAndValidity();
      });
    }
  }

  isDeleteItem(editType: string) {
    return editType === 'DELETE';
  }

  isDeleteCoreProductItem(tier: string, editType: string) {
    return tier === 'Core' && editType === 'DELETE';
  }

  alertErrorModal(message: string) {
    const initialState = {
      title: 'Failed',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  onClickImport() {
    this.importItem.checkExistingUpload();
  }
}
