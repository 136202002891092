<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
  </div>
  <div class="modal-body history-modal-body">
    <div *ngIf="data.length > 0; else noData" class="max-height-table table-responsive">
      <table id="simple-info-table" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr class="text-center">
            <th scope="col" *ngFor="let column of columns" [ngClass]="[column ? 'w-' + column.width : '']">
              {{ 'INFO_LIST.FIELD.' + column.field | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of data; index as i">
            <td *ngFor="let column of columns" [ngClass]="[column ? 'text-' + column.align : '']">
              {{ row[column.field] }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ng-template #noData>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/no-item.svg" alt="No data" />
        </div>
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="hide()">
      OK
    </button>
  </div>
</div>
