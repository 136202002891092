import { createEntityAdapter } from '@ngrx/entity';
import { WarehouseMasterActionTypes } from '../actions/warehouse-master.actions';
const ɵ0 = (officeWarehouse) => officeWarehouse.code;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialWarehouseMasterState = adapter.getInitialState();
export function warehouseMasterReducers(state = initialWarehouseMasterState, action) {
    if (action.type === WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_RESPONSE) {
        return adapter.addAll(action.payload, Object.assign({}, state));
    }
    else {
        return state;
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
