import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { AppStates } from 'src/app/shared/store/state/app.states';
import { ShelfDetailRequest } from '../../models';
import { selectAllShelfDetails } from '../../store/selectors/shelf-details.selectors';

@Component({
  selector: 'app-shelf-details',
  templateUrl: './shelf-details.component.html',
  styleUrls: ['./shelf-details.component.scss']
})
export class ShelfDetailsComponent implements OnInit, OnDestroy {
  public shelfDetails$: Observable<ShelfDetailRequest[]>;
  private localStore: Observable<any>;

  constructor(public bsModalRef: BsModalRef, private readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.shelfDetails$ = this.localStore.pipe(select(selectAllShelfDetails));
  }

  ngOnDestroy() {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
