<div class="modal fade" bsModal #handleTheme="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog" [ngStyle]="{ 'min-width': '750px' }">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">Add Theme</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModel()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body admin-form">
        <form (ngSubmit)="addTheme()" [formGroup]="themeForm">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12 mb-3">
                <label>Theme<span class="text-danger">*</span></label>
                <input
                  id="theme"
                  name="theme"
                  class="form-control"
                  maxlength="100"
                  formControlName="theme"
                  placeholder="Theme"
                  type="text"
                  trim="blur"
                  [ngClass]="{
                    'is-invalid': isAddTheme && (themeForm.controls.theme.errors || isDuplicateTheme)
                  }"
                />
                <div
                  *ngIf="isAddTheme && (themeForm.controls.theme.errors || isDuplicateTheme)"
                  class="invalid-feedback"
                >
                  <div *ngIf="themeForm.controls.theme.errors?.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="!themeForm.controls.theme.errors && isDuplicateTheme">
                    {{ 'ERRORS.DUPLICATED_THEME' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"><label>Effective Date/Time</label><span class="text-danger">*</span></div>
              <div class="col-md-6"><label>Expire Date/Time</label><span class="text-danger">*</span></div>
            </div>
            <div class="row">
              <div class="col-md-3 mb-3">
                <input
                  id="effectiveDate"
                  type="text"
                  placeholder="{{ dateFormat }}"
                  readonly
                  formControlName="effectiveDate"
                  class="form-control calendar"
                  placement="bottom"
                  bsDatepicker
                  [maxDate]="maxDate"
                  [minDate]="minEffectiveDate"
                  (bsValueChange)="onChangeEffectiveDate($event)"
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false
                  }"
                  [ngClass]="{
                    'is-invalid': isAddTheme && (themeForm.controls.effectiveDate.errors || isDuplicateTheme)
                  }"
                />
                <div
                  *ngIf="isAddTheme && (themeForm.controls.effectiveDate.errors || isDuplicateTheme)"
                  class="invalid-feedback"
                >
                  <div *ngIf="themeForm.controls.effectiveDate.errors?.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="isDuplicateTheme">
                    <small> {{ 'ERRORS.DUPLICATED_THEME' | translate }}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <ng-select
                  id="effectiveTime"
                  formControlName="effectiveTime"
                  [items]="timeRangeEffective"
                  [searchable]="false"
                  [clearable]="false"
                  (change)="onChangeEffectiveTime($event)"
                  bindLabel="label"
                  bindValue="value"
                >
                </ng-select>
              </div>
              <div class="col-md-3 mb-3">
                <input
                  id="expireDate"
                  type="text"
                  formControlName="expireDate"
                  placeholder="{{ dateFormat }}"
                  class="form-control calendar"
                  readonly
                  placement="bottom"
                  bsDatepicker
                  (bsValueChange)="onChangeExpireDate($event)"
                  [minDate]="minDate"
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false
                  }"
                  [ngClass]="{
                    'is-invalid': isAddTheme && (themeForm.controls.expireDate.errors || isDuplicateTheme)
                  }"
                />
                <div
                  *ngIf="isAddTheme && (themeForm.controls.expireDate.errors || isDuplicateTheme)"
                  class="invalid-feedback"
                >
                  <div *ngIf="themeForm.controls.expireDate.errors?.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="isDuplicateTheme">
                    <small> {{ 'ERRORS.DUPLICATED_THEME' | translate }}</small>
                  </div>
                </div>
              </div>
              <div class="col-md-3 mb-3">
                <ng-select
                  id="expireTime"
                  formControlName="expireTime"
                  [items]="timeRangeExpire"
                  [searchable]="false"
                  [clearable]="false"
                  (change)="onChangeExpireTime($event)"
                  bindLabel="label"
                  bindValue="value"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-body" id="listOfTheme" name="listOfTheme" *ngIf="listTheme && listTheme.length > 0">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 mb-3">
              Theme List
            </div>
          </div>
          <div class="table-responsive table-scroll max-height-table">
            <table class="table table-striped table-bordered table-hover w-100">
              <thead>
                <tr>
                  <th class="text-center w-5">No</th>
                  <th class="text-center w-30">Theme</th>
                  <th class="text-center w-15">Effective Date/Time</th>
                  <th class="text-center w-15">Expire Date/Time</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of listTheme; index as i">
                  <td class="text-center">{{ i + 1 }}</td>
                  <td>{{ item.name }}</td>
                  <td class="text-center">
                    {{ item.effectiveDate | dateDisplay: dateTimeDisplay }}
                  </td>
                  <td class="text-center">
                    {{ item.expireDate | dateDisplay: dateTimeDisplay }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" class="btn btn-standard mr-2" (click)="closeModel()" id="product-modal-cancelBtn">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-primary mr-3"
          id="product-modal-addBtn"
          (click)="addTheme()"
          appPreventDoubleClick
        >
          Add
        </button>
      </div>
    </div>
  </div>
</div>
