import { ProfitSharingStatusEnum } from '../enum/profit-sharing.enum';
import { AuditLog } from './audit-log.model';
import { BaseReport } from './base-report.model';

export class ProfitSharingSearchCriteria {
  lastModifiedDateFrom?: string;
  lastModifiedDateTo?: string;
  status?: ProfitSharingStatusEnum;
  searchCriteria?: string;
  scheduleFrom?: string;
  scheduleTo?: string;
  page: number;
  size: number;
}

export class ProfitSharingListPagination {
  content: ProfitSharingContent[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class ProfitSharingContent {
  id: string;
  docNo: string;
  runDate: string;
  schedule: string;
  scheduleDisplay: string;
  period: string;
  profitStatus: ProfitSharingStatusEnum;
  store: StoreDetails;
  totalAmount: number;
  profitSharingAmount: number;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastAllowEditedDate: string;
  remainingBalanceAmount: number;
  auditLogs: AuditLog[];
}

export class StoreDetails {
  no: string;
  code: string;
  name: string;
  storeCodeName: string;
}

export class ProfitSharingData {
  id: string;
  docNo: string;
  version: number;
  runDate: string;
  schedule: string;
  scheduleDisplay: string;
  period: string;
  status: string;
  profitStatus: ProfitSharingStatusEnum;
  store: StoreDetails;
  profitSharingAmount: number;
  remainingBalanceAmount: number;
  grossProfitAfterShareAmount: number;
  shareToTDIncVatAmount: number;
  shareToTDExcVatAmount: number;
  shareToTDVatAmount: number;
  grossProfitIncVatAmount: number;
  grossProfitExcVatAmount: number;
  salesIncVatAmount: number;
  salesExcVatAmount: number;
  cogsIncVatAmount: number;
  cogsExcVatAmount: number;
  compensateAmount: number;
  totalOtherExpense: number;
  withHoldingTaxAmount: number;
  accruedExpenseAmount: number;
  lessCashTransferAmount: number;
  storeUseAmount: number;
  stockLossAdjustmentAmount: number;
  destroyAmount: number;
  netPenaltyAmount: number;
  otherExpenseAmount: number;
  totalOtherIncome: number;
  cigaretteVatAdjustmentAmount: number;
  overCashTransferAmount: number;
  incentiveAmount: number;
  otherIncomeAmount: number;
  totalAmount: number;
  createdDate: string;
  createdBy: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastAllowEditedDate: string;
}

export class ProfitSharingPrintCriteria {
  docNo: string;
  runDate: string;
}

export class ProfitSharingExportCriteria extends BaseReport {
  scheduleFrom?: string;
  scheduleTo?: string;

  constructor(init?: Partial<ProfitSharingExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
