<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    {{ data.title }}
  </div>

  <ng-container *ngIf="viewOrder$ | async as order">
    <button
      type="button"
      class="btn btn-header-button d-none d-md-block"
      id="full-modal-print"
      *ngIf="isCanPrintPDF(order.status)"
      (click)="onPrintPdf(order)"
    >
      <em class="icon-print"></em>
      Print
    </button>
  </ng-container>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onClose()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="tab-menu d-flex d-inline-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: isSelectedTab(orderTabs.ORDER_DETAILS) }"
      (click)="onSelectTabs(orderTabs.ORDER_DETAILS)"
    >
      Order Details
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: isSelectedTab(orderTabs.RECEIVE_SUMMARY) }"
      (click)="onSelectTabs(orderTabs.RECEIVE_SUMMARY)"
    >
      Receive Summary
    </a>
  </div>

  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (viewOrder$ | async)?.orderNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (viewOrder$ | async)?.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order Status:</span>
      </div>
      <div class="info-header-value">
        <span class="order-status" [ngClass]="getColorStatus((viewOrder$ | async)?.status)">
          {{ 'ORDER.STATUS.' + (viewOrder$ | async)?.status | translate }}
        </span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="viewOrder$ | async as order">
    <div class="information-container mb-3">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Store</span>
            </div>
            <div class="info-header-value">
              {{ order?.storeCodeName | dashDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Store Type</span>
            </div>
            <div class="info-header-value">
              {{ 'STORE_TYPE.' + order?.storeType | translate }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Order Type</span>
            </div>
            <div class="info-header-value">
              {{ 'ORDER_TYPE.' + order?.orderType | translate }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Order By</span>
            </div>
            <div class="info-header-value">
              {{ order?.orderBy | dashDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Order Date</span>
            </div>
            <div class="info-header-value">
              {{ order?.orderDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-3 flex-column" *ngIf="isSelectedTab(orderTabs.ORDER_DETAILS)">
            <div class="info-header-label">
              <span>Warehouse</span>
            </div>
            <div class="info-header-value">
              {{ order.warehouseDisplayName ? order.warehouseDisplayName : '-' }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column" *ngIf="isSelectedTab(orderTabs.RECEIVE_SUMMARY)">
            <div class="info-header-label">
              <span>Delivery By</span>
            </div>
            <div class="info-header-value">
              {{ 'ORDER.DELIVERY_BY.' + order?.deliveryBy | translate }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span>Requested Delivery Date</span>
            </div>
            <div class="info-header-value">
              {{ order?.requestedDeliveryDate | amFromUtc | amLocal | dateDisplay }}
            </div>
          </div>

          <div class="col-lg-4" *ngIf="isSelectedTab(orderTabs.ORDER_DETAILS)">
            <div class="total-amount">
              <div class="text-right">
                <span class="info-total">
                  {{ order.total?.amount | numberFormatDisplay }}
                </span>
                <span class="info-total-currency">
                  THB
                </span>
              </div>
              <div class="info-total-detail">
                <span>(Total Amount before VAT: {{ order.itemAmount?.amount | numberFormatDisplay }} | </span>
                <span (click)="showVatDetails()" class="is-link"
                  >VAT: {{ order.vatAmount?.amount | numberFormatDisplay }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="information-container container-fluid">
      <div class="row">
        <div class="col-md-3">
          <span class="section-header">Customer</span>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg col-md-6 flex-column">
          <div class="info-header-label">
            <span>Store ID / Store Name:</span>
          </div>
          <div class="info-header-value">
            <strong>
              {{
                (order?.customerDetail
                  ? order.customerDetail?.storeCode + '/' + order.customerDetail?.storeName
                  : ''
                ) | dashDisplay
              }}
            </strong>

            <div>
              {{ order.customerDetail?.address | dashDisplay: '' }}
              {{ order.customerDetail?.state | dashDisplay: '' }}
              {{ order.customerDetail?.postCode | dashDisplay: '' }}
            </div>
          </div>
        </div>

        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Contact Name:</span>
          </div>
          <div class="info-header-value">
            {{ order.customerDetail?.contactName | dashDisplay }}
          </div>
        </div>

        <div class="col-lg col-md-3 flex-column">
          <div class="info-header-label">
            <span>Contact Number:</span>
          </div>
          <div class="info-header-value">
            {{ order.customerDetail?.contactNumber | dashDisplay }}
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-lg col-md-6 flex-column">
          <div class="info-header-label">
            <span>Delivery Note:</span>
          </div>
          <div class="info-header-value">
            <ul
              class="hyphen"
              *ngIf="
                order.customerDetail?.deliveryByFourWheelsTruck ||
                  order.customerDetail?.noEntry ||
                  order.customerDetail?.deliveryNote;
                else none
              "
            >
              <li *ngIf="order.customerDetail?.deliveryByFourWheelsTruck || order.customerDetail?.noEntry">
                <span *ngIf="order.customerDetail?.deliveryByFourWheelsTruck">{{
                  order.customerDetail?.deliveryByFourWheelsTruck
                }}</span>
                <span *ngIf="order.customerDetail?.noEntry">{{ order.customerDetail?.noEntry }}</span>
              </li>
              <li *ngIf="order.customerDetail?.deliveryNote">
                <div [innerHTML]="order.customerDetail?.deliveryNote"></div>
              </li>
            </ul>
            <ng-template #none>
              <span>{{ '-' }}</span>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <app-order-details *ngIf="isSelectedTab(orderTabs.ORDER_DETAILS)"></app-order-details>
  <app-receive-summary *ngIf="isSelectedTab(orderTabs.RECEIVE_SUMMARY)"></app-receive-summary>
</div>
