import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import { ForgotUsernameActionTypes, ForgotUsernameFailed, ForgotUsernameSuccess } from '../actions/forgot-username.action';
export class ForgotUsernameEffects {
    constructor(actions$, authService, userService, router) {
        this.actions$ = actions$;
        this.authService = authService;
        this.userService = userService;
        this.router = router;
        this.forgotUsername$ = this.actions$.pipe(ofType(ForgotUsernameActionTypes.FORGOT_USERNAME_REQUEST), map((action) => action.payload), switchMap(payload => {
            return this.authService.bypassAuthenToken().pipe(map(result => result['access_token']), switchMap(accessToken => {
                return this.userService.forgotUsername(payload, accessToken);
            }), map((forgotUsername) => {
                this.router.navigateByUrl('/');
                return new ForgotUsernameSuccess({ data: forgotUsername, mobileNumber: payload.mobileNumber });
            }), catchError(err => {
                return of(new ForgotUsernameFailed({ error: err.error, mobileNumber: payload.mobileNumber }));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ForgotUsernameEffects.prototype, "forgotUsername$", void 0);
