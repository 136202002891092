import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ModalButtonResponseEnum } from '../../../enum/modal-button-response.enum';
import { BaseModalComponent } from '../../../models/base-modal.component.model';

@Component({
  selector: 'app-success-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit, BaseModalComponent {
  public title: string;
  public okText: string;
  public message: string;
  public routerLink?: string;
  public isRefresh?: boolean;
  public action: Subject<ModalButtonResponseEnum>;

  constructor(public bsModalRef: BsModalRef, private router: Router, private readonly translate: TranslateService) {}

  ngOnInit() {
    this.action = new Subject();
    this.okText = this.okText || this.translate.instant('OK');
  }

  confirm() {
    this.action.next(ModalButtonResponseEnum.OK);
    this.bsModalRef.hide();
    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    }
    if (this.isRefresh) {
      // window.location.reload();
    }
  }
}
