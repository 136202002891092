import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ReceiveOrderService } from '../../services/receive-order.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ReceiveOrderActionType, ReceiveOrderByIdResponseAction, ReceiveOrderListResponseAction } from '../actions/receive-order.actions';
export class ReceiveOrderEffects {
    constructor(actions$, receiveOrderService, logger) {
        this.actions$ = actions$;
        this.receiveOrderService = receiveOrderService;
        this.logger = logger;
        this.searchReceiveOrder$ = this.actions$.pipe(ofType(ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST}: ` + this.stringify(action.payload))), switchMap(action => {
            return this.receiveOrderService.searchByCriteria(action.payload).pipe(map(response => new ReceiveOrderListResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getReceiveOrderByNo$ = this.actions$.pipe(ofType(ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_REQUEST), tap(action => this.logger.debug(`@Effect ${ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_REQUEST}: ` + this.stringify(action.payload))), switchMap(action => {
            return this.receiveOrderService.getReceiveOrderByNo(action.payload.receiveOrderNo).pipe(map(response => new ReceiveOrderByIdResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ReceiveOrderEffects.prototype, "searchReceiveOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ReceiveOrderEffects.prototype, "getReceiveOrderByNo$", void 0);
