import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { AuthGuardService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AssortmentPageModes,
  AssortmentRequestStatusEnum,
  RequestAssortment,
  RequestAssortmentSearchCriteria,
  RouteLinkTab
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { assortmentRequestStatusLOV, productTypeLOV } from '../../../shared/models/list-value/list-key-value.model';
import {
  RequestAssortmentCancelRequested,
  RequestAssortmentCopyRequested,
  RequestAssortmentCriteriaObjectUpdated,
  RequestAssortmentDeleteRequested,
  RequestAssortmentSearchRequested
} from '../../../shared/store/actions/request-assortment.actions';
import { PriceSettingRequested } from '../../../shared/store/actions/setting.actions';
import { RequestAssortmentState } from '../../../shared/store/reducers/request-assortment.reducers';
import {
  selectRequestAssortment,
  selectRequestAssortmentList
} from '../../../shared/store/selectors/request-assortment.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { AssortmentRequestComponent } from '../assortment-request/assortment-request.component';

@Component({
  selector: 'app-request-assortment-list',
  templateUrl: './assortment-request-list.component.html',
  styleUrls: ['./assortment-request-list.component.scss']
})
export class AssortmentRequestListComponent
  extends BaseSearchComponent<RequestAssortmentSearchCriteria, RequestAssortment, RequestAssortmentState>
  implements OnInit, OnDestroy, AfterViewInit {
  assortmentRequestStatusEnum = AssortmentRequestStatusEnum;
  headerRow: string[];
  statusList: NgOption[];
  requestTypeList: NgOption[];
  productTypeList: NgOption[];
  isShowAdvanceSearch: boolean;
  currentPage: number;
  pageSize: number;
  minDate: Date;
  maxDate: Date;
  dateTag: string;
  dateStringTag: string;
  requestTypeTag: string;
  requestStringTypeTag: string;
  cancelRequestId: string;
  articleType: ArticleTypeEnum;
  productType: ProductTypeEnum;
  dateFormat = environment.dateFormat;

  hasViewMerchantPermission = false;
  hasViewNonMerchantPermission = false;
  hasEditPermission = false;
  hasEditNonMerchantPermission = false;
  hasApproveMerchantPermission = false;
  hasApproveNonMerchantPermission = false;

  @ViewChild('modalCreateNewProduct', { static: false }) modalCreateNewProduct: ModalDirective;

  bsModalRef: BsModalRef;

  listRoute: Array<RouteLinkTab>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectRequestAssortmentList, selectRequestAssortment);
    super.subscribeForSaveSuccess();
    this.statusList = assortmentRequestStatusLOV;

    this.requestTypeList = [
      { value: 'NEW', label: 'New' },
      { value: 'EDIT', label: 'Edit' }
    ];
  }

  ngAfterViewInit(): void {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      requestType: [[]],
      dateFrom: [null],
      dateTo: [null]
    });
  }

  doInit() {
    // Check permission

    this.hasViewMerchantPermission = this.authGuardService.checkPermission(['tdassort_inv_v']);
    this.hasViewNonMerchantPermission = this.authGuardService.checkPermission(['tdassort_asset_v', 'tdassort_sto_v']);
    this.hasEditPermission = this.authGuardService.checkPermission(['tdassort_inv_m']);
    this.hasEditNonMerchantPermission = this.authGuardService.checkPermission(['tdassort_asset_m', 'tdassort_sto_m']);
    this.hasApproveMerchantPermission = this.authGuardService.checkPermission(['tdassort_inv_app']);
    this.hasApproveNonMerchantPermission = this.authGuardService.checkPermission([
      'tdassort_asset_app',
      'tdassort_sto_app'
    ]);

    this.setRouteTab();
    this.isShowAdvanceSearch = false;

    this.productTypeList = productTypeLOV.filter(item => {
      return (
        (item.value === ProductTypeEnum.INVENTORY && this.hasEditPermission) ||
        (item.value === ProductTypeEnum.FIX_ASSET && this.hasEditNonMerchantPermission) ||
        (item.value === ProductTypeEnum.STORE_USE && this.hasEditNonMerchantPermission)
      );
    });

    this.headerRow = [
      'Request No.',
      'Article No.',
      'Product Name',
      'Request Type',
      'Requested By',
      'Requested Date',
      'Status',
      'Action'
    ];

    // Load for child component
    this.store.dispatch(new PriceSettingRequested());
  }

  doDestroy() {}

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doSearchNewCriteria() {
    const searchCriteria = this.searchForm.value.searchCriteria;
    const status = this.searchForm.value.status;

    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      // page,
      searchCriteria,
      page: 0,
      status
    };

    this.doSearch(this.criteriaObject);
  }

  handleBackspace() {
    const searchCriteria = this.searchForm.value.searchCriteria;
    if (searchCriteria === '') {
      this.doSearchNewCriteria();
    }
  }

  prepareSearchCriteriaTags() {
    this.requestStringTypeTag = null;
    this.requestTypeTag = null;
    this.dateTag = null;
    this.dateStringTag = null;

    const beginRequestDate = dateStringToTagCriteria(this.criteriaObject.beginRequestDate);
    const endRequestDate = dateStringToTagCriteria(this.criteriaObject.endRequestDate);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: beginRequestDate,
      dateTo: endRequestDate
    });

    this.dateStringTag = requestedDate.dateStringTag;
    this.dateTag = requestedDate.dateTag;

    if (this.criteriaObject.requestType && this.criteriaObject.requestType.length > 0) {
      this.requestStringTypeTag = 'Request Type';
      const request = [];
      if (this.criteriaObject.requestType.includes('NEW')) {
        request.push('New');
      }

      if (this.criteriaObject.requestType.includes('EDIT')) {
        request.push('Edit');
      }

      const requests = request.join(', ');
      this.requestTypeTag = `"${requests}"`;
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new RequestAssortmentSearchRequested(criteriaObj));
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
  }

  onChangeRowPerPage(value: string) {
    this.pageSize = Number(value);
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.doSearch(this.criteriaObject);
  }

  onAdvanceSubmit() {
    let dateFrom = this.searchForm.value.dateFrom;
    let dateTo = this.searchForm.value.dateTo;
    const requestType = this.searchForm.value.requestType;
    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom, true);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      beginRequestDate: dateFrom,
      endRequestDate: dateTo,
      requestType,
      page: 0
    };

    this.doSearch(this.criteriaObject);
    this.isShowAdvanceSearch = false;
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  clearAdvanceFilter() {
    this.searchForm.controls['dateFrom'].reset();
    this.searchForm.controls['dateTo'].reset();
    this.searchForm.controls['requestType'].reset();
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      beginRequestDate: null,
      endRequestDate: null,
      requestType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearRequestType() {
    this.searchForm.controls['requestType'].reset();
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      requestType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearDate() {
    this.searchForm.controls['dateFrom'].reset();
    this.searchForm.controls['dateTo'].reset();
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      beginRequestDate: null,
      endRequestDate: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  showConfirmDeleteModal(requestId): void {
    this.cancelRequestId = requestId;

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentDeleteRequested({
              requestId: this.cancelRequestId
            })
          );
        } else {
          this.cancelRequestId = null;
        }
      });
  }

  showConfirmCancelModal(id, requestNo): void {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Product Request Number "${requestNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new RequestAssortmentCancelRequested({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  getColorStatus(status: AssortmentRequestStatusEnum): string {
    return AssortmentRequestStatusEnum[status].toLowerCase();
  }

  goToView(assortment) {
    const mode = AssortmentPageModes.REQUEST_VIEW;
    const title = 'View Product Request';
    const isRequiredLeaveForm = true;

    const initialState = {
      childItem: new ChildItem(
        AssortmentRequestComponent,
        {
          mode,
          title,
          id: assortment.id,
          articleType: assortment.articleType,
          productType: assortment.productType,
          originPage: null
        },
        isRequiredLeaveForm
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });

    this.store.dispatch(new RequestAssortmentCriteriaObjectUpdated({ criteriaObject: this.criteriaObject }));
    return;
  }

  goToEdit(assortment) {
    const mode = AssortmentPageModes.REQUEST_EDIT;
    const title = 'Edit Product Request';
    const isRequiredLeaveForm = false;
    const initialState = {
      childItem: new ChildItem(
        AssortmentRequestComponent,
        {
          mode,
          title,
          id: assortment.id,
          articleType: assortment.articleType,
          productType: assortment.productType
        },
        isRequiredLeaveForm
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });

    this.store.dispatch(new RequestAssortmentCriteriaObjectUpdated({ criteriaObject: this.criteriaObject }));
    return;
  }

  goToCreate() {
    const mode = AssortmentPageModes.REQUEST_CREATE;
    const title = 'Create Product Request';
    const isRequiredLeaveForm = true;
    const initialState = {
      childItem: new ChildItem(
        AssortmentRequestComponent,
        {
          id: null,
          mode,
          title,
          articleType: this.articleType,
          productType: this.productType
        },
        isRequiredLeaveForm
      )
    };

    this.modalCreateNewProduct.hide();
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });

    this.store.dispatch(new RequestAssortmentCriteriaObjectUpdated({ criteriaObject: this.criteriaObject }));
  }

  showHistory(requestNo: string, auditLogs: AuditLog[]) {
    const initialState = {
      title: 'History',
      historyHeader: `Request No. ${requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.PRODUCT_ASSORTMENT,
      auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setRouteTab() {
    this.listRoute = [];

    if (this.hasViewMerchantPermission || this.hasEditPermission || this.hasEditNonMerchantPermission) {
      this.listRoute.push({ tabName: 'Product List', url: '/products/product-list' });
    }

    // Add request list tabs in case it has permission to edit
    if (
      this.hasEditPermission ||
      this.hasEditNonMerchantPermission ||
      this.hasApproveMerchantPermission ||
      this.hasApproveNonMerchantPermission
    ) {
      this.listRoute.push({ tabName: 'Product Request', url: '/products/request-list' });
    }
  }

  copyRequest(requestId: string) {
    this.store.dispatch(new RequestAssortmentCriteriaObjectUpdated({ criteriaObject: this.criteriaObject }));
    this.store.dispatch(new RequestAssortmentCopyRequested({ requestId }));
  }

  alertCopySuccessModal() {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: 'The request has been created'
      }
    });
  }

  hideModalCreateNewProduct() {
    this.productType = null;
    this.modalCreateNewProduct.hide();
  }

  checkCreateNewProductPermission() {
    const hasManageAssetPermission = this.authGuardService.checkPermission(['tdassort_asset_m']);
    const hasManageStoreUsePermission = this.authGuardService.checkPermission(['tdassort_sto_m']);
    if (this.hasEditPermission && !this.hasEditNonMerchantPermission) {
      this.articleType = ArticleTypeEnum.MERCHANDISE;
      this.productType = ProductTypeEnum.INVENTORY;
      this.goToCreate();
    } else if (hasManageAssetPermission && !this.hasEditPermission && !hasManageStoreUsePermission) {
      this.articleType = ArticleTypeEnum.NON_MERCHANDISE;
      this.productType = ProductTypeEnum.FIX_ASSET;
      this.goToCreate();
    } else if (!hasManageAssetPermission && !this.hasEditPermission && hasManageStoreUsePermission) {
      this.articleType = ArticleTypeEnum.NON_MERCHANDISE;
      this.productType = ProductTypeEnum.STORE_USE;
      this.goToCreate();
    } else if (!this.hasEditPermission && this.hasEditNonMerchantPermission) {
      this.productTypeList = this.productTypeList.filter(
        productType => productType.value !== ProductTypeEnum.INVENTORY
      );
      this.showModalCreateNewProduct();
    } else if (this.hasEditPermission && this.hasEditNonMerchantPermission) {
      this.showModalCreateNewProduct();
    }
  }
  showModalCreateNewProduct() {
    this.productType = null;
    this.articleType = null;
    this.modalCreateNewProduct.show();
  }

  onChangeProductType() {
    if ([ProductTypeEnum.INVENTORY].includes(this.productType)) {
      this.articleType = ArticleTypeEnum.MERCHANDISE;
    } else if ([ProductTypeEnum.FIX_ASSET, ProductTypeEnum.STORE_USE].includes(this.productType)) {
      this.articleType = ArticleTypeEnum.NON_MERCHANDISE;
    }
  }

  canEditRequest(articleType: string) {
    return (
      (articleType === ArticleTypeEnum.MERCHANDISE && this.hasEditPermission) ||
      (articleType === ArticleTypeEnum.NON_MERCHANDISE && this.hasEditNonMerchantPermission)
    );
  }

  canApproveOrRejectRequest(articleType: string) {
    return (
      (articleType === ArticleTypeEnum.MERCHANDISE && this.hasApproveMerchantPermission) ||
      (articleType === ArticleTypeEnum.NON_MERCHANDISE && this.hasApproveNonMerchantPermission)
    );
  }
}
