import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { ProductsSearchCriteria } from '../../../shared/models';
import { ArticlesContent } from '../../../shared/models/articles.model';
import { ProductService } from '../../../shared/services';

@Component({
  selector: 'app-assortment-unlink-cj-product-modal',
  templateUrl: './assortment-unlink-cj-product-modal.component.html',
  styleUrls: ['./assortment-unlink-cj-product-modal.component.scss']
})
export class AssortmentUnlinkCjProductModalComponent implements OnInit {
  public articleNo: string;
  public productName: string;
  public action: Subject<ModalButtonResponseEnum>;
  public articlesList: Observable<ArticlesContent[]>;
  public articlesSearchLoading = false;
  public articlesSearchInput$ = new Subject<string>();
  public searchForm: FormGroup;
  public newCJArticle: string;
  public linkedArticleNo: string;
  public linkedProductName: string;
  public useCJProduct: boolean;
  public isSubmitted = false;
  constructor(
    public bsModalRef: BsModalRef,
    private readonly productService: ProductService,
    private readonly formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.action = new Subject();
    this.loadArticle('');
    const articleObject = {
      articleNo: this.linkedArticleNo || null,
      productName: this.linkedProductName || null
    };
    this.searchForm = this.formBuilder.group({
      article: [this.linkedArticleNo ? articleObject : null],
      articleNo: [this.linkedArticleNo ? this.linkedArticleNo : null],
      productName: [this.linkedProductName ? this.linkedProductName : null]
    });

    if (this.useCJProduct === false) {
      this.searchForm.controls.article.setValidators([Validators.required]);
    }
  }

  closeUnLinkModal() {
    this.searchForm.get('article').reset();
    this.bsModalRef.hide();
  }

  onAddNewCJArticle() {
    this.isSubmitted = true;
    if (this.searchForm.invalid) {
      return;
    }
    this.newCJArticle = this.searchForm.controls.articleNo.value;
    this.action.next(ModalButtonResponseEnum.OK);
  }

  onBlurArticle() {
    if (!this.searchForm.controls.article.value) {
      this.loadArticle('');
    }
  }

  onChangeArticle(value) {
    this.isSubmitted = false;
    this.searchForm.patchValue({
      articleNo: value ? value.articleNo : null,
      productName: value ? value.productName : null
    });
  }

  loadArticle(initialTerm: string) {
    this.articlesList = concat(
      of([]),
      this.articlesSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.articlesSearchLoading = true)),
        switchMap(term => {
          return this.productService
            .searchByCriteria({
              searchCriteria: term,
              isLinkCJArticle: true,
              page: 0,
              size: 100
            } as ProductsSearchCriteria)
            .pipe(
              map(res => {
                return res.content;
              }),
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.articlesSearchLoading = false;
              })
            );
        })
      )
    );
  }
}
