import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OrderService } from '../../services/order.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { OrderActionTypes, OrderListSearchLoaded, OrderViewLoaded } from '../actions/order.actions';
export class OrderEffects {
    constructor(actions$, orderService, logger) {
        this.actions$ = actions$;
        this.orderService = orderService;
        this.logger = logger;
        this.searchOrders$ = this.actions$.pipe(ofType(OrderActionTypes.OrderListSearchRequested), tap(action => this.logger.debug('@Effect Order List Search: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.orderService.searchByCriteria(action.payload).pipe(map(orders => new OrderListSearchLoaded({ orders })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.loadOrder$ = this.actions$.pipe(ofType(OrderActionTypes.OrderViewRequested), tap(action => this.logger.debug('@Effect Order View Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.orderService.getOrderRequest(action.payload).pipe(map(result => new OrderViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderEffects.prototype, "searchOrders$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], OrderEffects.prototype, "loadOrder$", void 0);
