
    <input
      type="text"
      [class]="classValue"
      [attr.maxlength]="maxlength"
      [attr.placeholder]="placeholder"
      [disabled]="disabled"
      (input)="handleInput()"
      (focus)="handleFocus()"
      (blur)="handleBlur()"
      (keydown)="handleKeyDown($event)"
      #numericInput
    />
  