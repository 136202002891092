export enum RequestStatusEnum {
  A = 'Approved',
  D = 'Draft',
  R = 'Rejected',
  WA = 'Awaiting Approval',
  CR = 'Cancelled',
  WR = 'Awaiting Reservation',
  WO = 'Awaiting Order Schedule',
  WF = 'Awaiting Fist Lot Quantity',
  APPROVED = 'A',
  DRAFT = 'D',
  REJECTED = 'R',
  AWAITING_APPROVAL = 'WA',
  CANCELLED = 'CR',
  AWAITING_RESERVATION = 'WR',
  AWAITING_ORDER_SCHEDULE = 'WOS',
  AWAITING_FIRST_LOT = 'WF'
}

export enum NewRequestStatusEnum {
  APPROVED = 'APPROVED',
  DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  CANCELLED = 'CANCELLED',
  AWAITING_RESERVATION = 'AWAITING_RESERVATION',
  AWAITING_ORDER_SCHEDULE = 'AWAITING_ORDER_SCHEDULE',
  AWAITING_FIRST_LOT = 'AWAITING_FIRST_LOT',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export enum shelfTypeStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
