import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GoodsReceiveService } from '../../services/goods-receive.service';
import {
  GoodsReceiveActionTypes,
  GoodsReceiveCancelRequest,
  GoodsReceiveListRequest,
  GoodsReceiveListResponse,
  GoodsReceiveSubmitRequest,
  GoodsReceiveSubmitResponseError,
  GoodsReceiveSubmitResponseSuccess,
  GoodsReceiveViewByIdRequest,
  GoodsReceiveViewLoaded,
  GoodsReceiveViewRequest
} from '../actions/goods-receive.actions';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';

@Injectable()
export class GoodsReceiveEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly goodsReceiveService: GoodsReceiveService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchGoodsReceive$ = this.actions$.pipe(
    ofType<GoodsReceiveListRequest>(GoodsReceiveActionTypes.GOODS_RECEIVE_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Goods Receive List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.goodsReceiveService.searchByCriteria(action.payload).pipe(
        map(response => new GoodsReceiveListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  submitGrRequest$ = this.actions$.pipe(
    ofType<GoodsReceiveSubmitRequest>(GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_REQUEST),
    tap(action => this.logger.debug('@Effect GR Submit Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.goodsReceiveService.submit(action.payload).pipe(
        map(() => new GoodsReceiveSubmitResponseSuccess()),
        catchError(err => {
          return (err &&
            err.error &&
            err.error.code === '00001' &&
            (err.error.message === 'Receive Quantity is more than Remaining Quantity' ||
              err.error.message === 'Purchase Order Status has been changed')) ||
            err.error.code === '08026'
            ? of(new GoodsReceiveSubmitResponseError(err.error))
            : err && err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  loadGoodsReceive$ = this.actions$.pipe(
    ofType<GoodsReceiveViewRequest>(GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_REQUESTED),
    tap(action => this.logger.debug('@Effect Goods Receive Get Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.goodsReceiveService.getGrByPoNo(action.payload).pipe(
        map(result => new GoodsReceiveViewLoaded(result)),
        catchError(err => {
          return err &&
            err.error &&
            err.error.code === '00001' &&
            err.error.message.includes('has already changed status')
            ? of(new GoodsReceiveSubmitResponseError(err.error))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  loadGoodsReceiveById$ = this.actions$.pipe(
    ofType<GoodsReceiveViewByIdRequest>(GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_BY_ID_REQUESTED),
    tap(action => this.logger.debug('@Effect Goods Receive Get Requested By Id: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.goodsReceiveService.getGrById(action.payload).pipe(
        map(result => new GoodsReceiveViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  cancelGrRequest$ = this.actions$.pipe(
    ofType<GoodsReceiveCancelRequest>(GoodsReceiveActionTypes.GOODS_RECEIVE_CANCEL_REQUEST),
    tap(action => this.logger.debug('@Effect GR Cancel Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.goodsReceiveService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This GR has been cancelled.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : of(new GoodsReceiveSubmitResponseError(err.error));
        })
      )
    )
  );
}
