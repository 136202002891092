/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-document-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./upload-document-button.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../services/common.upload.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "ngx-spinner";
import * as i7 from "ngx-logger";
var styles_UploadDocumentButtonComponent = [i0.styles];
var RenderType_UploadDocumentButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadDocumentButtonComponent, data: {} });
export { RenderType_UploadDocumentButtonComponent as RenderType_UploadDocumentButtonComponent };
export function View_UploadDocumentButtonComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["class", "d-none"], ["id", "uploadFile"], ["onclick", "this.value = null"], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-standard"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkExistingUpload() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "em", [["class", "icon-import"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.multiple; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.disabled; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.text; _ck(_v, 4, 0, currVal_3); }); }
export function View_UploadDocumentButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-document-button", [], null, null, null, View_UploadDocumentButtonComponent_0, RenderType_UploadDocumentButtonComponent)), i1.ɵdid(1, 245760, null, 0, i2.UploadDocumentButtonComponent, [i3.BsModalService, i4.CommonUploadService, i5.TranslateService, i6.NgxSpinnerService, i7.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadDocumentButtonComponentNgFactory = i1.ɵccf("app-upload-document-button", i2.UploadDocumentButtonComponent, View_UploadDocumentButtonComponent_Host_0, { allowedExt: "allowedExt", disabled: "disabled", multiple: "multiple", allowedTypes: "allowedTypes", size: "size", withMeta: "withMeta", maxHeight: "maxHeight", maxWidth: "maxWidth", uploadUrl: "uploadUrl", uploadHeaders: "uploadHeaders", baseStorageUrl: "baseStorageUrl", controlName: "controlName", isNew: "isNew", fileSizeExceedMessage: "fileSizeExceedMessage", isExistingUpload: "isExistingUpload", text: "text" }, { itemDetail: "itemDetail" }, []);
export { UploadDocumentButtonComponentNgFactory as UploadDocumentButtonComponentNgFactory };
