import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MyTaskListResponse, MyTaskSearchCriteria } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class DashboardService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.dashboard;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  /**
   * @description  get tasks by role , return MyTaskListResponse
   */
  tasksByRole(criteria: MyTaskSearchCriteria): Observable<MyTaskListResponse> {
    const url = this.getFullUrl(this.envService.tasksByRole);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
