import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SupplierGroupService } from '../../services/supplier-group.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  SupplierGroupActionTypes,
  SupplierGroupListRequest,
  SupplierGroupListResponse,
  SupplierGroupViewLoaded,
  SupplierGroupViewRequest
} from '../actions/supplier-group.actions';

@Injectable()
export class SupplierGroupEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supplierGroupService: SupplierGroupService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchSupplierGroup$ = this.actions$.pipe(
    ofType<SupplierGroupListRequest>(SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Supplier Group List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupService.searchByCriteria(action.payload).pipe(
        map(response => new SupplierGroupListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  loadSupplierGroup$ = this.actions$.pipe(
    ofType<SupplierGroupViewRequest>(SupplierGroupActionTypes.SUPPLIER_GROUP_VIEW_REQUESTED),
    tap(action => this.logger.debug('@Effect Supplier Group Get Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.supplierGroupService.getSupplierGroupById(action.payload).pipe(
        map(result => new SupplierGroupViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );
}
