import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { Z8ParameterRequestList } from '../../../shared/models/z8-parameter-request.model';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-view-parameter-request',
  templateUrl: './view-parameter-request.component.html',
  styleUrls: ['./view-parameter-request.component.scss']
})
export class ViewParameterRequestComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: { parameterInfo: Z8ParameterRequestList };

  public dateTimeDisplay = environment.dateTimeDisplay;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, true);
  }

  ngOnInit() {}

  ngOnDestroy() {}

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  doAfterVersionAlertModal() {}
}
