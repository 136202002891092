<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store-assortment"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="row mx-0 mt-3">
    <div class="col-lg-3 col-md-5">
      <app-step-progress [items]="progressStepConfigs" [selectedStepValue]="currentStep"></app-step-progress>
    </div>
  </div>
  <div *ngIf="currentStep === storeAssortmentSteps.CREATE">
    <div class="row mx-0">
      <!-- Find Store -->
      <div class="col-lg-3 col-md-5">
        <div class="row">
          <div class="border-box">
            <div class="section-header">
              <span>Find Store</span>
            </div>

            <app-store-assortment-find-store></app-store-assortment-find-store>
          </div>
        </div>
      </div>

      <!-- Search Result -->
      <div class="col-lg-9 col-md-7">
        <div class="tab-menu d-flex d-inline-flex">
          <a
            class="list-tab-menu px-3 px-md-5 py-2"
            [ngClass]="{ active: selectedTab == storeAssortmentTabs.SEARCH_RESULT }"
            (click)="onSelectTabs(storeAssortmentTabs.SEARCH_RESULT)"
          >
            Search Result
          </a>
          <a
            class="list-tab-menu px-3 px-md-5 py-2"
            [ngClass]="{ active: selectedTab == storeAssortmentTabs.SELECTED }"
            (click)="onSelectTabs(storeAssortmentTabs.SELECTED)"
          >
            Selected ({{ totalSelectedCount }})
          </a>
        </div>

        <div class="tab-list">
          <ng-container *ngIf="selectedTab === storeAssortmentTabs.SEARCH_RESULT">
            <app-store-assortment-request-search-result></app-store-assortment-request-search-result>
          </ng-container>

          <ng-container *ngIf="selectedTab === storeAssortmentTabs.SELECTED">
            <app-store-assortment-request-store-selected></app-store-assortment-request-store-selected>
          </ng-container>
        </div>
      </div>
    </div>

    <hr class="section-divider" />

    <div class="row section-header">
      <span>Item List</span>
    </div>
    <div class="mb-2">
      <app-store-assortment-item-list #itemList id="itemList" [submitted]="submitted" [formItemList]="formItemList">
      </app-store-assortment-item-list>
    </div>
  </div>
  <div *ngIf="currentStep === storeAssortmentSteps.CONFIRM">
    <app-store-assortment-request-table
      #storeAssortmentData
      [mode]="data.mode"
      (alert)="alertNoData()"
    ></app-store-assortment-request-table>
  </div>

  <!--footer-->
  <ng-container *ngIf="currentStep === storeAssortmentSteps.CREATE">
    <div class="fixed-row-bottom form-row">
      <div class="float-right">
        <button type="button" id="btn-next" class="btn btn-secondary" (click)="onNextStep()">
          Next
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="currentStep === storeAssortmentSteps.CONFIRM">
    <div class="fixed-row-bottom form-row">
      <div class="float-left">
        <button type="button" id="btn-save" class="btn btn-secondary" (click)="onBackStep()">
          Back
        </button>
      </div>
      <div class="float-right">
        <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
        <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
  <!--End footer-->
</div>
