<div class="left-section">
  <h2 class="forgot-section-header">{{ 'TD_NEST' | translate }}</h2>
  <h4>
    {{ 'FORGOT_USERNAME.FORGOT_USERNAME' | translate }}
  </h4>
  <p>{{ 'FORGOT_USERNAME.PLEASE_ENTER_YOUR_MOBILE_NUMBER' | translate }}</p>
  <div>
    <button type="button" class="btn btn-standard text-capitalize" (click)="onCancel()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>
