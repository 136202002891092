import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { UiPaginationComponent } from '../../../../shared/layouts/ui-pagination/ui-pagination.component';
import { AuthGuardService } from '../../../../shared/services';
import { StoreAssortmentRequestStoreUnselectAction } from '../../../../shared/store/actions/store-assortment-request-store.actions';
import { selectStoreAssortmentRequestStoreSelectedList } from '../../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-request-store-selected',
  templateUrl: './store-selected.component.html',
  styleUrls: ['./store-selected.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreAssortmentRequestStoreSelectedComponent implements OnInit, OnDestroy {
  get form(): FormArray {
    return this.storeSelected.get('selectedItems') as FormArray;
  }

  get formControls(): AbstractControl[] {
    return (this.storeSelected.get('selectedItems') as FormArray).controls;
  }
  public currentPage = 1;
  public pageSize = 20;
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;
  public resultList$: Observable<any>;

  public selectedList: any;

  public storeSelected: FormGroup;

  constructor(
    public readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {}
  private localStore: Observable<any>;

  createForm(item: any) {
    return this.fb.group({
      storeNo: item.storeNo,
      storeCode: item.storeCode,
      storeName: item.storeName,
      storeType: item.storeType,
      region: item.region,
      province: item.province,
      poiType: item.poiType
    });
  }

  initialControl() {
    this.storeSelected = this.fb.group({
      selectedItems: this.fb.array([])
    });
  }

  initialState() {
    this.selectedList = {};

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectStoreAssortmentRequestStoreSelectedList)).subscribe(selectedItems => {
      this.selectedList = {
        ...(Object.keys(selectedItems).length && selectedItems)
      };
    });

    if (this.selectedList) {
      Object.values(this.selectedList).forEach((item: any) => {
        this.form.push(this.createForm(item));
      });
    }
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.initialControl();
    this.initialState();
  }

  onClickCheckAllBox(event) {
    event.preventDefault();

    if (!event.target.checked) {
      const startIndex = this.currentPage > 1 ? (this.currentPage - 1) * this.pageSize : 0;
      let endIndex = this.currentPage * this.pageSize - 1;
      const itemsInPage =
        this.currentPage * this.pageSize <= this.form.length ? this.pageSize : this.form.length % this.pageSize;

      if (itemsInPage < this.pageSize) {
        endIndex = startIndex + itemsInPage - 1;
      }

      for (let i = startIndex; i <= endIndex; i++) {
        this.store.dispatch(new StoreAssortmentRequestStoreUnselectAction(this.form.at(startIndex).value));

        this.form.removeAt(startIndex);
        this.form.markAsTouched();
      }

      if (itemsInPage === endIndex - startIndex + 1) {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      }
    }
  }

  onClickCheckBox(event: any, i: number, store: FormArray) {
    if (!event.target.checked) {
      this.store.dispatch(new StoreAssortmentRequestStoreUnselectAction(store.value));

      const index = i + (this.currentPage - 1) * this.pageSize;

      this.form.removeAt(index);
      this.form.markAsTouched();
    }
  }
  wordLimiter(fullText: string) {
    const MAX_LENGTH = 18;

    const wordList = [];
    const cleanWord = fullText.replace(/[^ก-ฮโใไเแาำA-Za-z0-9',]/g, '');
    const splitWordByLength = cleanWord.slice(0, MAX_LENGTH);
    const numberOfWordAllowed = splitWordByLength.split(',').length;
    const fullTextBreak = fullText.split(',');

    if (numberOfWordAllowed) {
      for (let i = 0; i < numberOfWordAllowed; i++) {
        wordList.push(fullTextBreak[i].trim());
      }
    }

    return wordList.join(', ');
  }

  wordSpaceCompare(text: string) {
    return text.replace(/,/g, ' ,');
  }
}
