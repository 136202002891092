import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StoreAssortmentService } from '../../services/store-assortment.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StoreAssortmentActionType,
  StoreAssortmentByIdRequestAction,
  StoreAssortmentByIdResponseAction,
  StoreAssortmentListHistoryRequestAction,
  StoreAssortmentListHistoryResponseAction,
  StoreAssortmentListRequestAction,
  StoreAssortmentListResponseAction
} from '../actions/store-assortment.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class StoreAssortmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly storeAssortmentService: StoreAssortmentService,
    private readonly store$: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchStoreAssortment$ = this.actions$.pipe(
    ofType<StoreAssortmentListRequestAction>(StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeAssortmentService.searchByCriteria(payload).pipe(
        map(response => {
          return new StoreAssortmentListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfHistory$ = this.actions$.pipe(
    ofType<StoreAssortmentListHistoryRequestAction>(StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeAssortmentService.getHistoryLogs(payload).pipe(
        map(response => new StoreAssortmentListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getStoreAssortmentById$ = this.actions$.pipe(
    ofType<StoreAssortmentByIdRequestAction>(StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.storeAssortmentService.getStoreAssortmentById(payload).pipe(
        map(res => {
          return new StoreAssortmentByIdResponseAction(res);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
