import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import {
  ErrorResponse,
  GenerateMemberCard,
  MemberCardContent,
  MemberCardSearchCriteria,
  RouteLinkTab
} from '../../../shared/models';
import { AuthGuardService } from '../../../shared/services';
import { MembershipService } from '../../../shared/services/membership.service';
import {
  GenerateMemberCardListRequestAction,
  GenerateMemberCardRequestAction,
  GenerateMemberCardResetAction
} from '../../../shared/store/actions/member-card.actions';
import { MemberCardState } from '../../../shared/store/reducers/member-card.reducers';
import {
  selectAllMemberCardList,
  selectMemberCardList,
  selectMemberCardSubmitResponse
} from '../../../shared/store/selectors/member-card.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-member-card-list',
  templateUrl: './member-card-list.component.html',
  styleUrls: ['./member-card-list.component.scss']
})
export class MemberCardListComponent extends BaseSearchComponent<
  MemberCardSearchCriteria,
  MemberCardContent,
  MemberCardState
> {
  listRoute: Array<RouteLinkTab>;
  public generateMemberCardForm: FormGroup;
  public generateMemberCardSubmitted: boolean;
  private localStore: Observable<any>;

  @ViewChild('modalGenerateMemberCard', { static: false }) modalGenerateMemberCard: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly membershipService: MembershipService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, selectAllMemberCardList, selectMemberCardList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.setRouteTab();
    this.createForm();
    this.initSubscribe();
  }

  initSubscribe(): void {
    this.localStore
      .pipe(select(selectMemberCardSubmitResponse), filter(Boolean))
      .subscribe(() => this.alertSuccessModal('The request has been created.'));
  }
  createForm(): void {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });

    this.generateMemberCardSubmitted = false;
    this.generateMemberCardForm = this.fb.group({
      noOfCard: [{ value: null, disabled: false }, Validators.required],
      hasConfirmed: [{ value: null, disabled: false }]
    });
  }

  onGenerateMemberCardSubmit(): void {
    this.generateMemberCardSubmitted = true;
    if (this.generateMemberCardForm.invalid) {
      return;
    }

    const value = this.generateMemberCardForm.value;

    const member = new GenerateMemberCard({
      memberCardAmount: value.noOfCard
    });

    this.store.dispatch(new GenerateMemberCardRequestAction(member));
  }

  setRouteTab() {
    this.listRoute = [];

    this.listRoute.push({ tabName: 'Member Card', url: '/membership/member-card-list' });
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onDownLoadMemberCard(item: MemberCardContent): void {
    if (item === null || item.docNo === null) {
      return;
    }

    const date = new Date();
    const dateExport = moment(date).format(environment.fileName.exportMemberCard.timeFormat);

    this.membershipService.exportMemberCard(item.docNo).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${item.docNo} ${dateExport}.xlsx`);
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    }
  }

  hasPermission(): boolean {
    return this.authGuardService.checkPermission(['mem_card_m']);
  }

  showModalGenerateMemberCard(): void {
    this.modalGenerateMemberCard.show();
  }

  alertSuccessModal(msg: string): void {
    this.hideModal();
    this.onSubmit();
    const initialState = {
      title: 'Success',
      message: msg
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.store.dispatch(new GenerateMemberCardResetAction());
        alertModal.hide();
      }
    });
  }

  hideModal(): void {
    this.generateMemberCardForm.reset();
    this.generateMemberCardSubmitted = false;
    this.modalGenerateMemberCard.hide();
  }

  get disableGenerateMemberCard() {
    const formValue = this.generateMemberCardForm.value;
    return !formValue.hasConfirmed;
  }

  doAfterVersionAlertModal() {}

  doDestroy() {}

  goToView(viewParams: any) {
    this.logger.log('Not Implemented', viewParams);
  }

  onAdvanceSubmit() {
    this.logger.log('Not Implemented');
  }

  prepareSearchCriteriaTags() {
    this.logger.log('Not Implemented');
  }

  search(criteriaObj) {
    this.store.dispatch(new GenerateMemberCardListRequestAction(criteriaObj));
  }
}
