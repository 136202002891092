import { ProductTypeEnum } from '../enum/product-type.enum';
import { PurchaseOrderItemStatusEnum, PurchaseOrderStatusEnum, ShipToType } from '../enum/purchase-order.enum';
import { PurchaseRequestTypeEnum } from '../enum/purchase-request.enum';
import { AuditLog } from './audit-log.model';
import { FreeItems, ProductChange, ShipToBillToDetail } from './purchase-request.model';

export interface PurchaseOrder {
  id: string;
  articleType: string;
  type: PurchaseRequestTypeEnum;
  status: PurchaseOrderStatusEnum;
  advance?: boolean;
  docNo?: string;
  prNos?: string[];
  docDate?: string;
  orderSchedule?: number;
  orderScheduleDate?: string;
  orderScheduleDayOfWeek: number;
  orderScheduleDayOfWeekDisplay?: string;
  requestBuyItems: number;
  requestFreeItems: number;
  createdBy?: string;
  createdByName: string;
  createdDate: Date;
  lastModifiedBy?: string;
  lastModifiedByName?: string;
  totalAmount: number;
  vatAmount?: number;
  subTotalAmount?: number;
  items: PurchaseOrderItem[];
  version: number;
  productChanges: ProductChange[];
  requester?: string;
  requestedDate?: string;
  supplierName: string;
  shipToType: ShipToType;
  shipToCode: string;
  shipToCodeName: string;
  productType: ProductTypeEnum;
  supplierVat?: boolean;
  orderRefNo?: string;
  vendorDcCode?: string;
  vendorDcCodeName?: string;
  vendorDcName?: string;
}

export interface PurchaseOrderItem {
  id: string;
  itemNo: number;
  productName: string;
  articleNo: string;
  barcode: string;
  supplierName: string;
  supplierCode: string;
  orderUnit: string;
  minimumOrder: number;
  unitPrice: number;
  quantity: number;
  subTotalAmount: number;
  remark: string;
  selected?: boolean;
  vatAmount?: number;
  vatTotalAmount?: number;
  vatPct?: number;
  isAddItem?: boolean;
  newUnitPrice?: number;
  productChanges?: ProductChange[];
  isFreeItem?: boolean;
  freeItems?: FreeItems[];
  deliveryDetail?: PurchaseOrderDeliveryDetail;
  isReject?: boolean;
  status?: PurchaseOrderItemStatusEnum;
  segmentCode?: string;
  familyCode?: string;
  classCode?: string;
  subClassCode?: string;
  remainingQuantity?: number;
  receivedQuantity?: number;
  confirmQuantity?: number;
  vendorPoRefNo?: string;
  vendorPoLineItem?: string;
  sumConfirmedQty?: number;
  estimatedQty?: number;
  productVat?: boolean;
  totalVatAmount?: number;
}

export class PurchaseOrderDeliveryDetail {
  requestDeliveryDate: string;
  deliveryNote: string;
  shipTo: ShipToBillToDetail;
  billTo: ShipToBillToDetail;
}

export class PurchaseOrderContent {
  id: string;
  docNo: string;
  prNos: string[];
  type: PurchaseRequestTypeEnum;
  purchaseTypeDisplay?: string;
  supplierName: string;
  requester: string;
  totalAmount: number;
  status: PurchaseOrderStatusEnum;
  shipToCodeName?: string;
  shipToCode?: string;
  shipToName?: string;
  advance?: boolean;
  orderSchedule?: string;
  createdDate: string;
  auditLogs?: AuditLog[];
  shipToType: ShipToType;
  productType: ProductTypeEnum;
}

export class PurchaseOrderSearchCriteria {
  searchCriteria?: string;
  status?: PurchaseOrderStatusEnum;
  purchaseType?: string[];
  createdDateFrom?: string;
  createdDateTo?: string;
  page: number;
  size: number;
}

export class PurchaseOrderPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: PurchaseOrderContent[];
}

export enum PurchaseOrderPageTabs {
  PO_DETAILS = 'PO_DETAILS',
  GR_SUMMARY = 'GR_SUMMARY'
}

export interface PurchaseOrderCancelCloseRequestData {
  id: string;
  comment: string;
}

export class PurchaseOrderPrintCriteria {
  id: string;
  format: string;
  locale: string;
}
