import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseOrderService } from '../../services/purchase-order.service';
import { StackPricingService } from '../../services/stack-pricing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { PurchaseOrderActionTypes, PurchaseOrderCancelErrorResponse, PurchaseOrderListResponse, PurchaseOrderViewLoaded } from '../actions/purchase-order.actions';
export class PurchaseOrderEffects {
    constructor(actions$, purchaseOrderService, stackPricingService, logger) {
        this.actions$ = actions$;
        this.purchaseOrderService = purchaseOrderService;
        this.stackPricingService = stackPricingService;
        this.logger = logger;
        this.searchPurchaseOrder$ = this.actions$.pipe(ofType(PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Purchase Order List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseOrderService.searchByCriteria(action.payload).pipe(map(response => new PurchaseOrderListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.loadPurchaseOrder$ = this.actions$.pipe(ofType(PurchaseOrderActionTypes.PURCHASE_ORDER_VIEW_REQUESTED), tap(action => this.logger.debug('@Effect Purchase Order Get Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseOrderService.getPoById(action.payload).pipe(map(result => new PurchaseOrderViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.cancelPoRequest$ = this.actions$.pipe(ofType(PurchaseOrderActionTypes.PURCHASE_ORDER_CANCEL_REQUEST), tap(action => this.logger.debug('@Effect PO Cancel Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseOrderService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This PO has been cancelled.'
        })), catchError(err => {
            return err.error && err.error.code === '00001'
                ? of(new LayoutActionVersionError(true))
                : err.error && err.error.code === '08019'
                    ? of(new PurchaseOrderCancelErrorResponse(err.error))
                    : of(new LayoutActionLoadError(err));
        }))));
        this.closePoRequest$ = this.actions$.pipe(ofType(PurchaseOrderActionTypes.PURCHASE_ORDER_CLOSE_REQUEST), tap(action => this.logger.debug('@Effect PO Close Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseOrderService.close(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This PO has been closed.'
        })), catchError(err => {
            return err.error && err.error.code === '00001'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseOrderEffects.prototype, "searchPurchaseOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseOrderEffects.prototype, "loadPurchaseOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseOrderEffects.prototype, "cancelPoRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseOrderEffects.prototype, "closePoRequest$", void 0);
