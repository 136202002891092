<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-order"></em>
    <h4>{{ 'MENU.RECEIVE_ORDER' | translate }}</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="openExportModal()"></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
            [ngClass]="{ open: isShowAdvanceSearch }"
            class="box-dropdown"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Order Type
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="orderTypeFilter"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="orderType"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Delivery By
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="deliveryByFilter"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="deliveryBy"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="createdDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="createdDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="dateStringTag || deliveryByStringTag || orderTypeStringTag"
  >
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="deliveryByStringTag">
      <a class="remove-criteria" (click)="clearFilterDeliveryBy()">
        <em class="icon-close"></em>
      </a>
      <span>{{ deliveryByStringTag }}: {{ deliveryByTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="orderTypeStringTag">
      <a class="remove-criteria" (click)="clearFilterOrderType()">
        <em class="icon-close"></em>
      </a>
      <span>{{ orderTypeStringTag }}: {{ orderTypeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ result?.receiveOrderNo | dashDisplay: '(None)' }}
              </label>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order No.:</span>
              <span class="result-value">{{ result?.orderNo | dashDisplay: '(None)' }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Reference No.:</span>
              <span class="result-value">{{ result?.refNo | dashDisplay: '(None)' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Store:</span>
              <span class="result-value">{{ result?.storeCodeName | dashDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Order Type:</span>
              <span class="result-value">{{ 'ORDER_TYPE.' + result?.orderType | translate }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Delivery By:</span>
              <span class="result-value">{{ 'ORDER.DELIVERY_BY.' + result?.deliveryBy | translate }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Receiver:</span>
              <span class="result-value">{{ result?.receiver | dashDisplay: '(None)' }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ result?.createdDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box" *ngIf="hasManageClaimPermission()">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button type="button" class="btn btn-link" (click)="createClaimRO(result)">
              <em class="icon-create-ro"></em>Create Claim
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal fade" bsModal #exportModal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Export Receive Order Report</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
        <div class="form-row">
          <div class="col-12">Receive Order Date<span class="text-danger">*</span></div>
          <div class="form-group col-md-6">
            <label>{{ 'FROM' | translate }}</label>
            <input
              id="exportDateFrom"
              formControlName="rcCreatedDateFrom"
              type="text"
              placeholder="{{ environment.dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsDateConfig"
              [maxDate]="exportMaxDate"
              (bsValueChange)="onChangeExportDateFrom($event)"
              readonly
            />
            <label for="exportDateFrom" class="icon-calendar btn-calendar"></label>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'TO' | translate }}</label>
            <input
              id="exportDateTo"
              formControlName="rcCreatedDateTo"
              type="text"
              placeholder="{{ environment.dateFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [minDate]="exportMinDate"
              [bsConfig]="{
                containerClass: 'theme-dark-blue',
                dateInputFormat: environment.dateFormat,
                showWeekNumbers: false
              }"
              (bsValueChange)="onChangeExportDateTo($event)"
              readonly
            />
            <label for="exportDateTo" class="icon-calendar btn-calendar"></label>
          </div>
        </div>

        <div *ngIf="responseExportError" class="alert-danger form-group mb-0 " role="alert">
          {{ responseExportError }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onExport()"
          [disabled]="exportFormInValid"
          id="btnExport"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</div>
