import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { RequestPageModesEnum, RequestProductErrorEnum } from '../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { StoreListSearchCriteria } from '../../../shared/models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../../shared/models/barcode.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { ProgressStep } from '../../../shared/models/progress-step.model';
import { MerchandiseItem } from '../../../shared/models/shelf-request.model';
import {
  StoreAssortmentRequestItem,
  StoreAssortmentRequestStoreItem,
  StoreAssortmentSteps,
  StoreAssortmentTabs
} from '../../../shared/models/store-assortment-request.model';
import { BarcodeService } from '../../../shared/services';
import { StoreAssortmentRequestService } from '../../../shared/services/store-assortment-request.service';
import { StoreAssortmentRequestStoreSelectResetAction } from '../../../shared/store/actions/store-assortment-request-store.actions';
import {
  StoreAssortmentCreateSubmitRequestAction,
  StoreAssortmentRequestByIdResponseAction,
  StoreAssortmentRequestListRequestAction,
  StoreAssortmentRequestResetAction
} from '../../../shared/store/actions/store-assortment-request.actions';
import {
  selectStoreAssortmentRequestStoreListCriteria,
  selectStoreAssortmentRequestStoreSelectedList
} from '../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { StoreAssortmentRequestTableComponent } from '../components/store-assortment-request-table/store-assortment-request-table.component';
import { StoreAssortmentItemListComponent } from './item-list/item-list.component';

@Component({
  selector: 'app-store-assortment-request',
  templateUrl: './store-assortment-request.component.html',
  styleUrls: ['./store-assortment-request.component.scss']
})
export class StoreAssortmentRequestComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('storeAssortmentData', { static: false }) storeAssortmentData: StoreAssortmentRequestTableComponent;
  @ViewChild('itemList', { static: false }) itemList: StoreAssortmentItemListComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: RequestPageModesEnum;
    requestId?: string;
    originPage?: string;
  };

  private localStore: Observable<any>;
  private selectedList: any;
  public requestForm: FormGroup;
  public formItemList: FormGroup;
  public submitted: boolean;
  public selectedTab: StoreAssortmentTabs;
  public currentStep: StoreAssortmentSteps;
  public progressStepConfigs: ProgressStep[] = [];
  public totalSelectedCount = 0;
  public criteriaObject: StoreListSearchCriteria;
  public storeAssortmentSteps = StoreAssortmentSteps;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    private readonly storeAssortmentRequestService: StoreAssortmentRequestService,
    private readonly barcodeService: BarcodeService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, false);
    super.subscribeForSaveSuccess();
  }

  ngOnInit() {
    this.selectedTab = StoreAssortmentTabs.SEARCH_RESULT;
    this.currentStep = StoreAssortmentSteps.CREATE;

    this.progressStepConfigs = [
      {
        label: 'Create',
        value: StoreAssortmentSteps.CREATE
      },
      {
        label: 'Confirm',
        value: StoreAssortmentSteps.CONFIRM
      }
    ];

    this.criteriaObject = {
      page: 0,
      size: 20
    };

    this.initForm();
    this.initState();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StoreAssortmentRequestResetAction());
    this.store.dispatch(new StoreAssortmentRequestStoreSelectResetAction());
  }

  get requestMode() {
    return RequestPageModesEnum;
  }

  get storeAssortmentTabs() {
    return StoreAssortmentTabs;
  }

  initForm() {
    this.requestForm = this.fb.group({});
    this.formItemList = this.fb.group({});
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectStoreAssortmentRequestStoreSelectedList)).subscribe(selectedState => {
      this.totalSelectedCount = Object.keys(selectedState).length;
      this.selectedList = {
        ...(Object.keys(selectedState).length && selectedState)
      };
    });

    this.localStore.pipe(select(selectStoreAssortmentRequestStoreListCriteria)).subscribe(criteriaObject => {
      if (criteriaObject.page === 0) {
        this.selectedTab = StoreAssortmentTabs.SEARCH_RESULT;
      }
    });
  }

  onSelectTabs(tab: StoreAssortmentTabs) {
    this.selectedTab = tab;
  }

  onCancel() {
    if (Object.keys(this.selectedList).length > 0 || this.formItemList.value.productItems.length > 0) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onNextStep() {
    this.submitted = true;

    this.productItemValidator();
  }

  onBackStep() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to go back? All existing data will be lost.'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.submitted = false;
          this.currentStep = StoreAssortmentSteps.CREATE;
        }
      });
  }

  getDataList(): StoreAssortmentRequestItem[] {
    const items = [];
    for (const item in this.selectedList) {
      if (this.selectedList[item]) {
        const data: StoreAssortmentRequestItem = this.selectedList[item];
        data.storeItems = [];
        for (const formItem of this.formItemList.getRawValue().productItems) {
          data.storeItems.push(formItem);
        }
        items.push(data);
      }
    }
    return items;
  }

  onSubmit() {
    this.submitted = true;

    this.itemValidator();
  }

  prepareRequestData(rawData: Array<any>): Array<StoreAssortmentRequestItem> {
    const data: StoreAssortmentRequestItem[] = [];
    const stores = rawData.map(item => item.storeNo).filter((value, index, self) => self.indexOf(value) === index);
    for (const store of stores) {
      const group = rawData.filter(v => v.storeNo === store);
      data.push({
        ...StoreAssortmentRequestItem.mappingDataToStoreAssortmentRequestItem(group[0]),
        ...{
          storeItems: group.map((v, i) =>
            StoreAssortmentRequestStoreItem.mappingDataToStoreAssortmentRequestStoreItem(v, i)
          )
        }
      });
    }
    return data;
  }

  productItemValidator() {
    const errorMessage = this.getFormGroupErrorMessage();

    if (errorMessage) {
      this.showModalError(errorMessage);
      return;
    } else if (this.itemList.form.invalid) {
      this.validateError(this.itemList);
      return;
    }

    const barcodeList = this.formItemList.value.productItems.map(data => data.barcode);
    const itemList$: Observable<BarcodeResponse[]> = this.barcodeValidator(barcodeList);

    itemList$
      .pipe(
        tap(itemList => {
          this.updateFormGroup(itemList);
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        if (this.itemList.form.valid) {
          this.currentStep = StoreAssortmentSteps.CONFIRM;
          this.store.dispatch(new StoreAssortmentRequestByIdResponseAction({ items: this.getDataList() }));
        }
      });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new StoreAssortmentCreateSubmitRequestAction(this.prepareRequestData(this.storeAssortmentData.data))
          );
        }
      });
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  alertNoData() {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Alert',
        message: 'No data found. You are redirected to previous page.'
      },
      backdrop: 'static'
    });
    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.currentStep = StoreAssortmentSteps.CREATE;
      }
    });
  }

  itemValidator() {
    const articleList$: Observable<Array<
      StoreAssortmentRequestItem
    >> = this.storeAssortmentRequestService.validateStoreAssortment(
      this.prepareRequestData(this.storeAssortmentData.data)
    );

    articleList$
      .pipe(
        tap(data => {
          this.store.dispatch(new StoreAssortmentRequestByIdResponseAction({ items: data }));
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        const hasError = this.storeAssortmentData.data.some(v => v.errorStatus);

        if (hasError) {
          this.validateDuplicateAssortment(this.storeAssortmentData.data);
        } else {
          this.handleConfirm();
        }
      });
  }

  barcodeValidator(barcodes: string[]) {
    const barcodeList = this.barcodeService.searchBarcodeByCriteria(
      barcodes,
      new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: ProductTypeEnum.INVENTORY,
        size: barcodes.length
      })
    );

    return barcodes.length ? barcodeList : of([]);
  }

  updateFormGroup(barcode: BarcodeResponse[]) {
    if (!barcode.length) {
      return;
    }

    let formGroup;
    let mappingBarcodeResponse;

    formGroup = this.formItemList.get('productItems');
    mappingBarcodeResponse = MerchandiseItem.mappingBarcodeResponseToMerchandiseItem;

    barcode.forEach((data, i) => {
      formGroup.controls[i].patchValue({
        ...mappingBarcodeResponse(data),
        ...formGroup.getRawValue()[i]
      });

      if (data.errorMessage) {
        this.validateForm(formGroup.controls[i], data.errorMessage);
      }
    });
  }

  validateForm(formItem: FormGroup, errorMessage: RequestProductErrorEnum) {
    let childItem;

    const newValidator = this.setValidator(errorMessage);

    switch (errorMessage) {
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.INACTIVE_BARCODE:
      case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
        childItem = formItem.get('barcode');
        break;
      case RequestProductErrorEnum.RESTRICT_ITEM:
      case RequestProductErrorEnum.STATUS_IS_DELISTED:
      case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
      case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
      case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
        childItem = formItem.get('productName');
        break;
      default:
        this.logger.error(`ErrorMessage: ${errorMessage} did not supported in barcode and productName.`);
        return;
    }

    childItem.setValidators(newValidator);
    childItem.updateValueAndValidity();

    formItem.setValidators(newValidator);
    formItem.updateValueAndValidity();
  }

  setValidator(errorMessage: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.INACTIVE_BARCODE:
          return { isInactive: true };
        case RequestProductErrorEnum.DUPLICATED_BARCODE_FIELD:
          return { duplicated: true };
        case RequestProductErrorEnum.RESTRICT_ITEM:
          return { isRestrictItem: true };
        case RequestProductErrorEnum.STATUS_IS_DELISTED:
          return { isStatusDelisted: true };
        case RequestProductErrorEnum.NOT_ALLOW_FIX_ASSET:
          return { isFixAssetItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_INVENTORY:
          return { isInventoryItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_STORE_USE:
          return { isStoreUseItem: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          return control.value !== null && control.value === 0 ? { isZero: true } : null;
        default:
          return null;
      }
    };
  }

  validateError(form) {
    const invalidIndex = form.formControls.findIndex(item => item.invalid);
    form.paging.navigateToErrorIndex(invalidIndex);
    for (const control of form.paging.currentPageData) {
      if (control.hasError('duplicated') || control.get('barcode').errors || control.get('productName').errors) {
        this.showModalError('Please delete invalid data before submit.');
        break;
      }
    }
  }

  validateDuplicateAssortment(data) {
    const invalidIndex = data.findIndex(item => item.errorStatus);
    const hasInvalidItem = invalidIndex >= 0;

    this.storeAssortmentData.paging.navigateToErrorIndex(invalidIndex);

    if (hasInvalidItem) {
      this.showModalError('Please delete invalid data before submit.');
    }
  }

  getFormGroupErrorMessage() {
    if (Object.keys(this.selectedList).length === 0) {
      return 'Please select at least one store before submit.';
    } else if (!this.formItemList.value.productItems.length) {
      return 'Please select at least one item before submit.';
    }

    return '';
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    this.store.dispatch(
      new StoreAssortmentRequestListRequestAction({
        page: 0,
        size: 20
      })
    );
  }

  // Override since list page already subscribe
  subscribeForVersionError() {}
}
