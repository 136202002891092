import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  VoucherListResponse,
  VoucherListSearchCriteria,
  VoucherRequestWithComment,
  VoucherViewResponse
} from '../models';
import { BaseService } from './base.service';

@Injectable()
export class VoucherService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.vouchers;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: VoucherListSearchCriteria): Observable<VoucherListResponse> {
    const url = this.getUrl();

    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  cancel(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.headers
    });
  }

  exportVoucher(voucherCode: string) {
    const params = this.getParams({ voucherCode });

    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  getVoucherByVoucherCode(voucherCode: string): Observable<VoucherViewResponse> {
    const url = this.getFullUrl(this.envService.get, { voucherCode });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
