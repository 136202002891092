import gql from 'graphql-tag';

export class Classes {
  public code: string;
  public beautyPct: number;
  public firstLotPct: number;
}

export const productClassesQuery = gql`
  query {
    classes(sort: { orderBy: CODE }) {
      code
      beautyPct
      firstLotPct
    }
  }
`;
