import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Z8ParameterContent } from '../models/z8-parameter.model';

@Injectable()
export class Z8ParameterDataService {
  public dataList: Array<Z8ParameterContent> = [];
  public getData: Subject<Array<Z8ParameterContent>> = new Subject();
  public updateData: Subject<boolean> = new Subject();

  constructor() {}

  addData(data) {
    this.dataList.push(...data);
    this.getData.next(this.dataList);
  }

  deleteItem(index: string) {
    const itemIndex = this.dataList.findIndex(item => item.id === index);

    this.dataList.splice(itemIndex, 1);
    this.getData.next(this.dataList);
  }

  updateItem(updateItem: Z8ParameterContent) {
    const itemIndex = this.dataList.findIndex(v => v.id === updateItem.id);
    this.dataList[itemIndex] = { ...updateItem };
    this.updateData.next(true);
  }

  searchData(term: string, startDate, endDate) {
    const data = this.dataList.filter(v => {
      const termFilter = term
        ? v.productValue.name.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1 ||
          v.storeCodeName.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) !== -1
        : true;

      if (!startDate && !endDate) {
        return termFilter;
      } else if (!startDate && endDate) {
        return false;
      }

      if (startDate && !endDate) {
        return (
          termFilter &&
          moment(v.startDate, environment.dateFormat) <= moment(startDate, environment.dateFormat).startOf('day') &&
          !Boolean(v.endDate)
        );
      } else if (startDate && endDate) {
        return (
          termFilter &&
          moment(v.startDate, environment.dateFormat) <= moment(startDate, environment.dateFormat).startOf('day') &&
          (moment(v.endDate, environment.dateFormat) >= moment(endDate, environment.dateFormat).startOf('day') ||
            !Boolean(v.endDate))
        );
      }
    });

    this.getData.next(data);
  }

  reset() {
    this.dataList = [];
  }
}
