<form id="search-form-store" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
  <div class="flex-item d-md-flex justify-content-between">
    <div class="d-md-flex flex-fill justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            aria-hidden="true"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-container *ngIf="(listStockState$ | async).totalElements == 0">
  <div class="no-result-container text-center">
    <div class="d-flex justify-content-center">
      <img width="240" height="240" src="/assets/images/not-found.svg" alt="not found" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(listStockState$ | async).totalElements > 0">
  <div class="mt-3 table-responsive">
    <table id="transactions" class="table table-striped table-bordered table-hover border-none">
      <thead>
        <tr>
          <th class="text-center w-10">Store Code</th>
          <th class="text-center w-20">Store Name</th>
          <th class="text-center  w-20">Store-Available</th>
          <th class="text-center  w-10">To be adjusted</th>
          <th class="text-center  w-10">Total Stock</th>
          <th class="text-center  w-10">Last Updated</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of resultStockList$ | async; index as i">
          <td class="text-center">
            {{ result.storeCode }}
          </td>
          <td>
            {{ result.storeName }}
          </td>
          <td class="text-center">{{ result.storeAvailable | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.toBeAdjusted | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.totalStock | numberFormatDisplay: 0 }}</td>
          <td class="text-center">
            {{ result.lastModifiedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </td>
        </tr>
      </tbody>
    </table>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listStockState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </div>
</ng-container>
