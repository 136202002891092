import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SupplierPriceConfigService } from '../../services/supplier-price-config.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { SupplierPriceActionTypes, SupplierPriceListResponse, SupplierPriceShowSuccess } from '../actions/supplier-price.actions';
export class SupplierPriceEffects {
    constructor(actions$, supplierPriceConfigService, logger) {
        this.actions$ = actions$;
        this.supplierPriceConfigService = supplierPriceConfigService;
        this.logger = logger;
        this.searchSupplierPrice$ = this.actions$.pipe(ofType(SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Price List Requested:` + JSON.stringify(action.payload))), switchMap(action => {
            return this.supplierPriceConfigService.searchSupplierPrice(action.payload).pipe(switchMap(response => [
                new SupplierPriceShowSuccess(action.payload.showSuccess),
                new SupplierPriceListResponse(response)
            ]), catchError(error => of(new LayoutActionLoadError(error))));
            return of([]);
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierPriceEffects.prototype, "searchSupplierPrice$", void 0);
