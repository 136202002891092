<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Import</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Order Request</label>
            <div class="d-flex justify-content-between">
              <app-get-excel-data
                id="importData"
                formControlName="importData"
                [size]="50000000"
                [controlName]="'importData'"
                [disabled]="loading"
                (clearInput)="reset()"
                (showErrorMessage)="setErrorMessage($event)"
                class="flex-item"
              ></app-get-excel-data>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="errorMessage" class="alert-danger form-group" role="alert">
        {{ errorMessage }}
      </div>

      <ng-container *ngIf="errors.length">
        <table class="table table-striped table-bordered table-hover w-100">
          <thead>
            <tr class="error-background">
              <th class="text-center w-10">Row</th>
              <th class="text-center w-30">Column</th>
              <th class="text-center w-30">Description</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let error of errors; index as i">
              <td class="text-center">{{ error.row + 1 }}</td>
              <td class="text-left">{{ error.column }}</td>
              <td class="text-left">{{ error.description }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-standard mr-2" (click)="closeModal()" id="btnCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary btn-loader"
      (click)="onSubmit()"
      id="btnSubmit"
      [disabled]="!form.value.importData || loading || errorMessage"
    >
      OK
      <div class="loader" *ngIf="loading"></div>
    </button>
  </div>
</div>
