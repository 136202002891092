import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import { TdAssortmentListPagination, TdAssortmentSearchCriteria } from '../models/purchase-request.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class TdAssortmentService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.purchaseRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: TdAssortmentSearchCriteria): Observable<TdAssortmentListPagination> {
    const params = this.getParams(criteria, true);
    const url = `${this.env.serverUrl}${this.envService.purchaseProductPrice}`;

    return this.http.get<TdAssortmentListPagination>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportTdAssortment(criteria: TdAssortmentSearchCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = `${this.env.serverUrl}${this.envService.purchaseProductPrice}${this.envService.exportPr}`;
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public getUrlImport() {
    return this.getFullUrl(this.envService.import);
  }

  public uploadPurchaseRequestFiles(files: Array<File>, url: string): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadPurchaseRequestFile(file, url));
  }

  public uploadPurchaseRequestFile(file: File, url: string): Observable<UploadResultType> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }
}
