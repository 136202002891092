import { AbstractControl, ValidatorFn } from '@angular/forms';

export function beforeTodayValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    const today = new Date().setHours(0, 0, 0, 0);
    const current = new Date(control.value).setHours(0, 0, 0, 0);

    return current < today ? { beforeToday: true } : null;
  };
}
