/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shelf-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./shelf-details.component";
import * as i4 from "ngx-bootstrap/modal";
import * as i5 from "@ngrx/store";
var styles_ShelfDetailsComponent = [i0.styles];
var RenderType_ShelfDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShelfDetailsComponent, data: {} });
export { RenderType_ShelfDetailsComponent as RenderType_ShelfDetailsComponent };
function View_ShelfDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row mt-2 td-info-modal-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.shelfCode; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.shelfType; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.context.$implicit.shelfOption; _ck(_v, 9, 0, currVal_2); }); }
export function View_ShelfDetailsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "modal-header pb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h6", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shelf Details"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["id", "confirm-modal-closeBtn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.decline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "modal-body history-modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "row mt-2 td-info-modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shelf Code "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "col-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shelf Type "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "col-6"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Shelf Option "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ShelfDetailsComponent_1)), i1.ɵdid(16, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_co.shelfDetails$)); _ck(_v, 16, 0, currVal_0); }, null); }
export function View_ShelfDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shelf-details", [], null, null, null, View_ShelfDetailsComponent_0, RenderType_ShelfDetailsComponent)), i1.ɵdid(1, 245760, null, 0, i3.ShelfDetailsComponent, [i4.BsModalRef, i5.Store], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ShelfDetailsComponentNgFactory = i1.ɵccf("app-shelf-details", i3.ShelfDetailsComponent, View_ShelfDetailsComponent_Host_0, {}, {}, []);
export { ShelfDetailsComponentNgFactory as ShelfDetailsComponentNgFactory };
