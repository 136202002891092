import { environment as env } from '../../../environments/environment';

export const rename = (refId: string) => {
  const fileExt = refId ? refId.substring(refId.lastIndexOf('.')) : '';
  const fileName = refId ? refId.substring(refId.lastIndexOf('/') + 1, refId.lastIndexOf('_')) : '';
  return fileName + fileExt;
};

export const getFileUploadObj = (value: string): Array<any> | undefined => {
  const regexpImageType = new RegExp(env.regexp.imageType, 'ig');
  if (!value) {
    return;
  }

  return [
    {
      filePath: value,
      errors: {},
      isImg: regexpImageType.test(value),
      name: rename(value),
      id: value
    }
  ];
};
