import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { GoodsReceiveModeEnum, GoodsReceiveStatusEnum } from '../../../shared/enum/goods-receive.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ShipToType } from '../../../shared/enum/purchase-order.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import {
  GoodsReceive,
  GoodsReceiveFreeItem,
  GoodsReceiveItem,
  GoodsReceiveItemDetails,
  GoodsReceiveSearchCriteria
} from '../../../shared/models/goods-receive.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { PurchaseRequestProductError } from '../../../shared/models/purchase-request.model';
import { grManagePermissions } from '../../../shared/permissions/permissions';
import { AuthGuardService } from '../../../shared/services';
import {
  GoodsReceiveCancelRequest,
  GoodsReceiveReset,
  GoodsReceiveResetSaveSuccessStatus,
  GoodsReceiveSubmitRequest,
  GoodsReceiveViewByIdRequest,
  GoodsReceiveViewRequest
} from '../../../shared/store/actions/goods-receive.actions';
import { selectDisplayNameResult } from '../../../shared/store/selectors/auth-user-info.selector';
import {
  selectCreateGoodsReceive,
  selectGoodsReceiveCriteriaObject,
  selectGoodsReceiveErrorResponse,
  selectGoodsReceiveSaveStatus
} from '../../../shared/store/selectors/goods-receive.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { calculateVatAmount } from '../../../shared/utils/vat-util';
import { VatDetailsModalComponent } from '../vat-details-modal/vat-details-modal.component';

@Component({
  selector: 'app-goods-receive-create',
  templateUrl: './goods-receive-view.component.html',
  styleUrls: ['./goods-receive-view.component.scss']
})
export class GoodsReceiveViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() data: {
    id: string;
    poNo: string;
    mode: GoodsReceiveModeEnum;
    title: string;
    isFromViewPo?: boolean;
  };

  public dateTimeDisplay = environment.dateTimeDisplay;
  private localStore: Observable<any>;
  private goodsReceiveListCriteriaObject: GoodsReceiveSearchCriteria;

  public isSubmit: boolean;
  public isViewMode: boolean;
  public goodsReceiveForm: FormGroup;
  public goodsReceive: GoodsReceive;
  public receiver: string;
  public currentDate: Date;
  public goodsReceiveModeEnum = GoodsReceiveModeEnum;
  public goodsReceiveStatusEnum = GoodsReceiveStatusEnum;
  public shipToType = ShipToType;
  public hasManageGrPermission = false;
  public manageGrPermission = grManagePermissions;
  public permissionAction = PermissionAction;

  currentPage: number;
  pageSize: number;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected cdRef: ChangeDetectorRef,
    public permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, false);
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectGoodsReceiveCriteriaObject)).subscribe(searchCriteria => {
      this.goodsReceiveListCriteriaObject = searchCriteria;
    });

    if (this.data.id) {
      this.store.dispatch(new GoodsReceiveViewByIdRequest(this.data.id));
    } else {
      this.store.dispatch(new GoodsReceiveViewRequest(this.data.poNo));
    }

    this.setInitialValue();
    this.initialGrData();

    this.localStore.pipe(select(selectGoodsReceiveSaveStatus)).subscribe(isSuccess => {
      if (isSuccess) {
        this.alertSuccessModal();
      }
    });
    this.hasManageGrPermission = this.authGuardService.checkPermission(this.manageGrPermission, true);

    if (this.form && this.currentViewMode) {
      this.form.disable();
    }

    this.localStore.pipe(select(selectGoodsReceiveErrorResponse)).subscribe(error => {
      if (error) {
        if (error.code === '00001' && error.message === 'Receive Quantity is more than Remaining Quantity') {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: `${error.message}.`
            }
          });

          alertModal.content.action
            .pipe(untilComponentDestroyed(this))
            .subscribe((result: ModalButtonResponseEnum) => {
              if (result === ModalButtonResponseEnum.OK) {
                alertModal.hide();
                this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
              }
            });
        } else if (
          error.code === '00001' &&
          (error.message === 'Purchase Order Status has been changed' ||
            error.message.includes('has already changed status'))
        ) {
          const alertModal = this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: 'Purchase Order Status has been changed.',
              routerLink: '/purchase/purchase-order-list'
            }
          });

          alertModal.content.action
            .pipe(untilComponentDestroyed(this))
            .subscribe((result: ModalButtonResponseEnum) => {
              if (result === ModalButtonResponseEnum.OK) {
                alertModal.hide();
                this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
                if (document.getElementById('page-content')) {
                  document.getElementById('page-content').scrollTop = 0;
                }
              }
            });
        } else if (error.code === '08026') {
          error.items.forEach((itemErrorDetails: GoodsReceiveItemDetails) => {
            if (itemErrorDetails.errors && itemErrorDetails.errors.length) {
              const grItemFormGroup = this.getCurrentGRItemFromGroup('itemNo', itemErrorDetails.itemNo);
              if (itemErrorDetails.errors && itemErrorDetails.errors.length && grItemFormGroup) {
                itemErrorDetails.errors.forEach((itemError: PurchaseRequestProductError) => {
                  const node = itemError.field;
                  if (node) {
                    setTimeout(() => {
                      grItemFormGroup.controls[node].setErrors({ [node]: itemError.message });
                    });
                  }
                });
              }
            }
          });
        }
        // else if (error.code === '08028') {
        //   this.modalService.show(AlertModalComponent, {
        //     initialState: {
        //       title: 'Failed',
        //       message: `${error.message}.`
        //     }
        //   });
        // }
      }
    });

    this.currentPage = 1;
    this.pageSize = 20;
  }

  getCurrentGRItemFromGroup(fieldName: string, currentItemValue: any): FormGroup {
    let grItemFormGroup = null;
    if (this.getItemForm) {
      this.getItemForm.controls.forEach(itemForm => {
        const fieldValue = itemForm.get(fieldName).value;
        if (fieldValue === currentItemValue) {
          grItemFormGroup = itemForm;
        }
      });
    }
    return grItemFormGroup;
  }

  alertSuccessModal() {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: 'This GR has been created.',
        routerLink: '/purchase/goods-receive-list'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        this.notifyParent.emit({
          notificationType: NotificationTypeEnum.FORCE_CLOSE
        });
        if (document.getElementById('page-content')) {
          document.getElementById('page-content').scrollTop = 0;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new GoodsReceiveReset());
    this.store.dispatch(new GoodsReceiveResetSaveSuccessStatus());
  }

  setInitialValue() {
    this.isSubmit = false;
    this.currentDate = new Date();
    this.localStore.pipe(select(selectDisplayNameResult)).subscribe(displayName => {
      this.receiver = displayName;
    });
  }

  initialGrData() {
    this.localStore.pipe(select(selectCreateGoodsReceive)).subscribe(gr => {
      this.goodsReceive = {
        ...gr
      };

      if (this.goodsReceive && this.goodsReceive.items && this.goodsReceive.items.length > 0) {
        this.createForm(this.goodsReceive);
      }
    });
  }

  createForm(goodsReceive: GoodsReceive) {
    this.goodsReceiveForm = this.fb.group({
      doNo: [
        { value: goodsReceive.doNo ? goodsReceive.doNo : null, disabled: goodsReceive && goodsReceive.id },
        Validators.required
      ],
      docRefNo: [
        {
          value: goodsReceive.docRefNo ? goodsReceive.docRefNo : null,
          disabled: goodsReceive && goodsReceive.id
        }
      ],
      articleType: goodsReceive.articleType,
      items: this.createItemForm(goodsReceive.items)
    });
  }

  createItemForm(grItems: GoodsReceiveItem[]): FormArray {
    const grItemForms = new FormArray([]);
    grItems.forEach(item => {
      const grItemFormGroup = this.fb.group(
        {
          itemNo: item.itemNo,
          productName: item.productName,
          articleNo: item.articleNo,
          barcode: item.barcode,
          orderUnit: item.orderUnit,
          unitPrice: item.unitPrice,
          orderQuantity: item.orderQuantity,
          remainingQuantity: item.remainingQuantity,
          vatPct: item.vatPct,
          vatAmount: item.vatAmount,
          receiveQuantity: [{ value: item.receiveQuantity, disabled: this.currentViewMode }, this.isZeroValidator],
          vatTotalAmount: calculateVatAmount(
            item.unitPrice,
            item.receiveQuantity,
            item.vatPct,
            item.productVat,
            this.goodsReceive && this.goodsReceive.supplierVat
          ),
          productVat: item.productVat,
          receiveAmount: item.receiveAmount,
          unitFactor: item.unitFactor,
          freeItems:
            item.freeItems && item.freeItems.length > 0 ? this.createFreeItemForm(item.freeItems) : new FormArray([])
        },
        { validator: this.validateQty }
      );

      grItemFormGroup.controls['receiveQuantity'].valueChanges
        .pipe(untilComponentDestroyed(this))
        .subscribe(quantity => {
          if (quantity) {
            grItemFormGroup.patchValue({
              receiveAmount: round(quantity * grItemFormGroup.get('unitPrice').value, 2),
              vatTotalAmount: calculateVatAmount(
                grItemFormGroup.get('unitPrice').value,
                quantity,
                grItemFormGroup.get('vatPct').value,
                grItemFormGroup.get('productVat').value,
                this.goodsReceive && this.goodsReceive.supplierVat
              )
            });
          } else {
            grItemFormGroup.patchValue({
              receiveAmount: 0,
              vatTotalAmount: null
            });
            grItemFormGroup.controls['barcode'].setErrors(null);
          }
          this.calculateTotalAmount();
        });

      grItemForms.push(grItemFormGroup);
    });
    return grItemForms;
  }

  createFreeItemForm(freeItems: GoodsReceiveFreeItem[]): FormArray {
    const grFreeItemForms = new FormArray([]);
    freeItems.forEach(item => {
      const grFreeItemFormGroup = this.fb.group(
        {
          refArticleNo: item.refArticleNo,
          refBarcode: item.refBarcode,
          productName: item.productName,
          articleNo: item.articleNo,
          barcode: item.barcode,
          orderUnit: item.orderUnit,
          orderQuantity: item.orderQuantity,
          remainingQuantity: item.remainingQuantity,
          receiveQuantity: [{ value: item.receiveQuantity, disabled: this.currentViewMode }, this.isZeroValidator],
          unitFactor: item.unitFactor
        },
        { validator: this.validateQty }
      );
      grFreeItemFormGroup.controls.receiveQuantity.valueChanges.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.calculateTotalAmount();
      });
      grFreeItemForms.push(grFreeItemFormGroup);
    });
    return grFreeItemForms;
  }

  toggleToEdit() {
    this.data.mode = GoodsReceiveModeEnum.CREATE;
    this.data.title = 'Edit GR';
    this.getItemForm.enable();
  }

  onCloseFullModal() {
    if (this.form && this.form.dirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  calculateTotalAmount() {
    let subTotalAmt = 0;
    let totalVatAmt = 0;

    this.getItemForm.controls.forEach(itemForm => {
      const receiveAmount = itemForm.get('receiveAmount').value || 0;
      const vatAmount = itemForm.get('vatTotalAmount').value || 0;
      subTotalAmt = subTotalAmt + receiveAmount;
      totalVatAmt = totalVatAmt + vatAmount;
    });

    totalVatAmt = round(totalVatAmt, 4);
    const totalAmount = round(subTotalAmt + totalVatAmt, 2);

    subTotalAmt = round(subTotalAmt, 2);
    totalVatAmt = round(totalVatAmt, 2);

    this.form.patchValue({
      subTotalAmount: subTotalAmt,
      vatAmount: totalVatAmt
    });
    this.goodsReceive = {
      ...this.goodsReceive,
      vatAmount: totalVatAmt ? totalVatAmt : 0,
      subTotalAmount: subTotalAmt ? subTotalAmt : 0,
      totalAmount: totalAmount ? totalAmount : 0
    };
  }

  showVatDetails() {
    const vatDetails = [];
    this.getItemForm.controls.forEach(itemForm => {
      const rawValue = (itemForm as FormGroup).getRawValue();
      vatDetails.push({
        itemNo: rawValue.itemNo,
        productName: rawValue.productName,
        vat: rawValue.vatPct,
        vatAmount: rawValue.vatTotalAmount,
        isInvalid: itemForm.get('receiveQuantity').invalid || itemForm.get('receiveQuantity').value === null,
        productVat: rawValue.productVat,
        supplierVat: this.goodsReceive && this.goodsReceive.supplierVat
      });
    });
    const initialState = {
      title: 'VAT',
      vatDetails,
      vatAmountPrecision: 4
    };
    this.modalService.show(VatDetailsModalComponent, {
      initialState
    });
  }

  get isZeroValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === 0 ? { isZero: true } : null;
      }
      return null;
    };
  }

  validateQty: ValidatorFn = (fb: FormGroup) => {
    const receiveQty = fb.get('receiveQuantity').value;
    const remainingQty = fb.get('remainingQuantity').value;
    return receiveQty !== null && remainingQty !== null && receiveQty > remainingQty ? { moreQty: true } : null;
  };

  get form(): FormGroup {
    return this.goodsReceiveForm;
  }

  get getItemForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  getColorStatus(status: GoodsReceiveStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  get currentViewMode() {
    return [this.goodsReceiveModeEnum.VIEW].includes(this.data.mode);
  }

  get currentCreateMode() {
    return [this.goodsReceiveModeEnum.CREATE].includes(this.data.mode);
  }

  confirmReceiveAllItem() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to receive all items?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.receiveAllItem();
        }
      });
  }

  receiveAllItem() {
    this.getItemForm.controls.forEach(itemForm => {
      const remainingQuantity = itemForm.get('remainingQuantity').value;
      const freeItems = itemForm.get('freeItems') as FormArray;

      freeItems.controls.forEach(freeItemForm => {
        const freeItemRemainingQuantity = freeItemForm.get('remainingQuantity').value;
        freeItemForm.get('receiveQuantity').patchValue(freeItemRemainingQuantity);
      });

      itemForm.get('receiveQuantity').patchValue(remainingQuantity);
    });
    this.form.markAsDirty();
  }

  onSubmit() {
    this.isSubmit = true;

    if (this.getItemForm.invalid) {
      const invalidIndex = this.getItemForm.controls.findIndex(item => item.invalid);
      this.paging.navigateToErrorIndex(invalidIndex);
      return;
    }

    if (this.goodsReceiveForm.invalid) {
      return;
    }

    if (this.isAllowSubmitGr()) {
      this.handleConfirm();
    }
  }

  isAllowSubmitGr() {
    const itemReceived = this.getItemForm.controls.some(item => item.get('receiveQuantity').value);
    const freeItemReceived = this.getItemForm.controls.some(item =>
      (item.get('freeItems') as FormArray).controls.some(freeItem => freeItem.get('receiveQuantity').value)
    );

    if (!itemReceived && !freeItemReceived) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Please receive at least 1 item before submit.'
        }
      });
      return false;
    }
    return true;
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          const gr = this.prepareData();
          this.store.dispatch(new GoodsReceiveSubmitRequest(gr));
        }
      });
  }

  prepareData() {
    return (this.goodsReceive = {
      ...this.goodsReceive,
      ...this.goodsReceiveForm.getRawValue()
    } as GoodsReceive);
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  onCancelGr() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel GR Number "${this.goodsReceive.docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new GoodsReceiveCancelRequest({
              id: this.goodsReceive.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  subscribeForVersionError() {}
}
