<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-order"></em>
    {{ data.title }}
  </div>
  <button type="button" class="btn btn-header-button d-none d-md-block" (click)="onPrintPdf()">
    <em class="icon-print"></em>
    Print Price Tag
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="store-assortment" class="admin-form">
  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ (storeAssortmentView$ | async)?.storeCodeName }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store Type</span>
          </div>
          <div class="info-header-value">
            {{ 'STORE_TYPE.' + (storeAssortmentView$ | async)?.storeType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>No. of Shelf</span>
          </div>
          <div class="info-header-value">
            {{ (storeAssortmentView$ | async)?.noOfShelf | numberFormatDisplay: 0 }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>No. of Assortment</span>
          </div>
          <div class="info-header-value">
            {{ (storeAssortmentView$ | async)?.noOfAssortment | numberFormatDisplay: 0 }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Last Updated</span>
          </div>
          <div class="info-header-value">
            {{ (storeAssortmentView$ | async)?.lastUpdatedDate | amFromUtc | amLocal | dateDisplay: dateTimeFormat }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="tab-menu d-flex d-inline-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: selectedTab === storeAssortmentTypeEnum.MASTER_ASSORTMENT }"
      (click)="onSelectTabs(storeAssortmentTypeEnum.MASTER_ASSORTMENT)"
    >
      Master Assortment ({{ (storeAssortmentView$ | async)?.noOfMaster | numberFormatDisplay: 0:0 }})
    </a>

    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: selectedTab === storeAssortmentTypeEnum.SPECIAL_ASSORTMENT }"
      (click)="onSelectTabs(storeAssortmentTypeEnum.SPECIAL_ASSORTMENT)"
    >
      Special Assortment ({{ (storeAssortmentView$ | async)?.noOfSpecial | numberFormatDisplay: 0:0 }})
    </a>
  </div>

  <app-master-assortment
    [storeNo]="data.storeNo"
    *ngIf="selectedTab === storeAssortmentTypeEnum.MASTER_ASSORTMENT"
  ></app-master-assortment>
  <app-special-assortment
    [storeNo]="data.storeNo"
    *ngIf="selectedTab === storeAssortmentTypeEnum.SPECIAL_ASSORTMENT"
  ></app-special-assortment>
</div>
