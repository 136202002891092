import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MonthlyPartnerProfitSharingService } from '../../services/monthly-partner-profit-sharing.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  MonthlyPartnerProfitSharingActionTypes,
  MonthlyPartnerProfitSharingListRequestAction,
  MonthlyPartnerProfitSharingListResponseAction,
  MonthlyPartnerProfitSharingSubmitRequestAction,
  MonthlyPartnerProfitSharingSubmitResponseAction
} from '../actions/monthly-partner-profit-sharing.actions';

@Injectable()
export class MonthlyPartnerProfitSharingEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly monthlyPartnerProfitSharingService: MonthlyPartnerProfitSharingService
  ) {}

  @Effect()
  searchMonthlyPartnerProfitSharing$ = this.actions$.pipe(
    ofType<MonthlyPartnerProfitSharingListRequestAction>(
      MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_LIST_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.monthlyPartnerProfitSharingService.searchByCriteria(payload).pipe(
        map(response => {
          return new MonthlyPartnerProfitSharingListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  submitMonthlyPartnerProfitSharing$ = this.actions$.pipe(
    ofType<MonthlyPartnerProfitSharingSubmitRequestAction>(
      MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST
    ),
    map(action => {
      this.logger.debug(
        `@Effect ${MonthlyPartnerProfitSharingActionTypes.MONTHLY_PARTNER_PROFIT_SHARING_SUBMIT_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.monthlyPartnerProfitSharingService.submitMonthlyPartnerProfitSharing(payload).pipe(
        map(response => {
          return new MonthlyPartnerProfitSharingSubmitResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
