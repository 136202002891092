/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./files-upload.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../directives/app-src.directive";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "./files-upload.component";
import * as i7 from "ngx-bootstrap/modal";
import * as i8 from "../../services/users.service";
import * as i9 from "ngx-logger";
import * as i10 from "../../services/file-url.service";
var styles_FilesUploadComponent = [i0.styles];
var RenderType_FilesUploadComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilesUploadComponent, data: {} });
export { RenderType_FilesUploadComponent as RenderType_FilesUploadComponent };
function View_FilesUploadComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["alt", "upload image"], ["appSrc", ""], ["class", "upload-image-preview"]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.AppSrcDirective, [i1.Renderer2, i1.ElementRef], { appSrc: [0, "appSrc"] }, null)], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.filePath; _ck(_v, 0, 0, currVal_0); }); }
function View_FilesUploadComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "a", [["class", "input-upload-display d-block"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "link-file": 0, "link-image": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_1 = "input-upload-display d-block"; var currVal_2 = _ck(_v, 3, 0, !_v.parent.context.$implicit.isImg, _v.parent.context.$implicit.isImg); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.filePath; _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_3); }); }
function View_FilesUploadComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "input-upload-hidden link-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Attached"]))], null, null); }
function View_FilesUploadComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "icon-close-mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_FilesUploadComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "position-relative image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "input", [["class", "form-control cursor-default"], ["readonly", ""]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { enabled: 0, disabled: 1, "bg-h-change": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["hiddenFile", 2]], null, 0, null, View_FilesUploadComponent_5)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_6)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "form-control cursor-default"; var currVal_1 = _ck(_v, 4, 0, !_co.disabled, _co.disabled, _co.hasChange); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = ((_v.context.$implicit.name && _v.context.$implicit.isImg) && !_co.isHiddenFileName); _ck(_v, 6, 0, currVal_2); var currVal_3 = !_co.isHiddenFileName; var currVal_4 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = ((_v.context.$implicit.name && !_co.disabled) && !_co.isHiddenFileName); _ck(_v, 11, 0, currVal_5); }, null); }
function View_FilesUploadComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FilesUploadComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "btn upload-btn"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v.parent, 4).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "is-invalid": 0 }), (_l()(), i1.ɵeld(5, 0, null, null, 0, "em", [["class", "icon-upload"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn upload-btn"; var currVal_2 = _ck(_v, 4, 0, _co.hasError); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesUploadComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "upload-container text-center form-control"]], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { disabled: 0, "is-invalid": 1 }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["class", "d-none"], ["id", "uploadFile"], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "input-group position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "input", [["class", "form-control upload-input cursor-default"], ["placeholder", "No File Chosen"], ["readonly", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "is-invalid": 0, "bg-h-change": 1, enabled: 2, disabled: 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_8)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "upload-container text-center form-control"; var currVal_1 = _ck(_v, 3, 0, _co.disabled, _co.hasError); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = "form-control upload-input cursor-default"; var currVal_6 = _ck(_v, 9, 0, _co.hasError, _co.hasChange, !_co.disabled, _co.disabled); _ck(_v, 8, 0, currVal_5, currVal_6); var currVal_7 = !_co.disabled; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.disabled; var currVal_3 = (_co.multiple || false); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _co.disabled; _ck(_v, 6, 0, currVal_4); }); }
function View_FilesUploadComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("ERRORS.REQUIRED")); _ck(_v, 1, 0, currVal_0); }); }
function View_FilesUploadComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "invalid-feedback"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_10)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasError.required; _ck(_v, 2, 0, currVal_0); }, null); }
function View_FilesUploadComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "image-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.descriptionTxt; _ck(_v, 1, 0, currVal_0); }); }
function View_FilesUploadComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-secondary mt-2"], ["id", "add-image"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAddImage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "em", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add more "]))], null, null); }
function View_FilesUploadComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "input-label"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_12)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_13)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.descriptionTxt; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_co.disabled && _co.isAddable) && (_co.maxImages > (_co.index + 1))); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_FilesUploadComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noFiles", 2]], null, 0, null, View_FilesUploadComponent_7)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_9)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilesUploadComponent_11)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fileList && (_co.fileList.length > 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.hasError; _ck(_v, 5, 0, currVal_2); var currVal_3 = (!_co.isAddable || _co.lastIndex); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_FilesUploadComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-files-upload", [], [[2, "is-invalid", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FilesUploadComponent_0, RenderType_FilesUploadComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.FilesUploadComponent]), i1.ɵprd(5120, null, i5.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i6.FilesUploadComponent]), i1.ɵdid(3, 770048, null, 0, i6.FilesUploadComponent, [[3, i5.ControlContainer], i7.BsModalService, i8.UsersService, i9.NGXLogger, i10.FileUrlService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).invalid; _ck(_v, 0, 0, currVal_0); }); }
var FilesUploadComponentNgFactory = i1.ɵccf("app-files-upload", i6.FilesUploadComponent, View_FilesUploadComponent_Host_0, { allowedExt: "allowedExt", disabled: "disabled", multiple: "multiple", allowedTypes: "allowedTypes", size: "size", hasError: "hasError", hasChange: "hasChange", withMeta: "withMeta", maxHeight: "maxHeight", maxWidth: "maxWidth", controlName: "controlName", initialFileList: "initialFileList", isNew: "isNew", fileTypeErrorTxt: "fileTypeErrorTxt", fileSizeErrorTxt: "fileSizeErrorTxt", descriptionTxt: "descriptionTxt", isAddable: "isAddable", index: "index", maxImages: "maxImages", lastIndex: "lastIndex", isHiddenFileName: "isHiddenFileName", fileModule: "fileModule" }, { addImage: "addImage" }, []);
export { FilesUploadComponentNgFactory as FilesUploadComponentNgFactory };
