/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./get-excel-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./get-excel-data.component";
import * as i5 from "ngx-logger";
var styles_GetExcelDataComponent = [i0.styles];
var RenderType_GetExcelDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GetExcelDataComponent, data: {} });
export { RenderType_GetExcelDataComponent as RenderType_GetExcelDataComponent };
function View_GetExcelDataComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "em", [["class", "icon-close-mini"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClickDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_GetExcelDataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "position-relative image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "input", [["class", "form-control cursor-default"], ["readonly", ""]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "input-upload-display d-block link-file"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GetExcelDataComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_v.context.$implicit.name && !_co.disabled); _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_GetExcelDataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GetExcelDataComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fileList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GetExcelDataComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "upload-container text-center form-control"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["uploadInput", 1]], null, 0, "input", [["class", "d-none"], ["id", "uploadFile"], ["type", "file"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "input-group position-relative"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "form-control upload-input cursor-default"], ["placeholder", "No File Chosen"], ["readonly", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn upload-btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).click() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "icon-upload"]], null, null, null, null, null))], null, null); }
export function View_GetExcelDataComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GetExcelDataComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noFiles", 2]], null, 0, null, View_GetExcelDataComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.fileList && (_co.fileList.length > 0)); var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_GetExcelDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "app-get-excel-data", [], null, [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onChange($event.target.files) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_GetExcelDataComponent_0, RenderType_GetExcelDataComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.GetExcelDataComponent]), i1.ɵprd(5120, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i4.GetExcelDataComponent]), i1.ɵdid(3, 770048, null, 0, i4.GetExcelDataComponent, [[3, i3.ControlContainer], i5.NGXLogger], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var GetExcelDataComponentNgFactory = i1.ɵccf("app-get-excel-data", i4.GetExcelDataComponent, View_GetExcelDataComponent_Host_0, { size: "size", controlName: "controlName", disabled: "disabled" }, { clearInput: "clearInput", showErrorMessage: "showErrorMessage" }, []);
export { GetExcelDataComponentNgFactory as GetExcelDataComponentNgFactory };
