import { Action } from '@ngrx/store';
import { PriceSetting } from '../../models';

export enum SettingActionTypes {
  PRICE_SETTING_REQUESTED = '[Price Setting] Load Price Setting',
  PRICE_SETTING_SUCCESS = '[Price Setting] Load Price Setting Success',
  xxx = 'xxx'
}

export class PriceSettingRequested implements Action {
  readonly type = SettingActionTypes.PRICE_SETTING_REQUESTED;
  constructor() {}
}

export class PriceSettingSuccess implements Action {
  readonly type = SettingActionTypes.PRICE_SETTING_SUCCESS;
  constructor(public payload: { priceSetting: PriceSetting }) {}
}

export class Xxx implements Action {
  readonly type = SettingActionTypes.xxx;
  constructor() {}
}

export type SettingActions = PriceSettingRequested | PriceSettingSuccess | Xxx;
