import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { AuthRequest } from '../../models';
import { LogInAction, LoginReset } from '../../store/actions/auth.actions';
import { ForgotPasswordReset } from '../../store/actions/forgot-password.action';
import { ForgotUsernameReset } from '../../store/actions/forgot-username.action';
import { BaseForgotUsernameState } from '../../store/reducers/forgot-username.reducer';
import { selectAuthResult } from '../../store/selectors/auth.selector';
import { selectForgotPasswordResult } from '../../store/selectors/forgot-password.selector';
import { selectForgotUsernameResult } from '../../store/selectors/forgot-username.selector';
import { AppStates } from '../../store/state/app.states';
import { BaseAuthState } from '../../store/state/auth.state';
import { BaseForgotPasswordState } from '../../store/state/forgot-password.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isShowPassword: boolean;
  submitted: boolean;
  auth$: Observable<BaseAuthState>;
  forgotUsernameResult$: Observable<BaseForgotUsernameState>;
  forgotPasswordResult$: Observable<BaseForgotPasswordState>;

  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>, private fb: FormBuilder) {}

  ngOnDestroy(): void {
    this.store.dispatch(new LoginReset());
    this.resetAlertMessage();
  }

  ngOnInit() {
    this.isShowPassword = false;
    this.submitted = false;
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.auth$ = this.store.pipe(select(selectAuthResult));

    this.forgotUsernameResult$ = this.localStore.pipe(select(selectForgotUsernameResult));
    this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult));
  }

  get form(): any {
    return this.loginForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    const payload: AuthRequest = {
      ...this.loginForm.value,
      grant_type: 'password'
    };

    this.resetAlertMessage();

    this.store.dispatch(new LogInAction(payload));
  }

  resetAlertMessage() {
    this.store.dispatch(new ForgotUsernameReset());
    this.store.dispatch(new ForgotPasswordReset());
  }

  toggleInputType(): void {
    this.isShowPassword = !this.isShowPassword;
  }
}
