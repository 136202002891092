export class ImportExportButton {
  type: ButtonType;
  name: string;
  hidden?: boolean;
  by?: ExportTypeEnum | ImportTypeEnum;
}

export enum ButtonType {
  IMPORT = 'import',
  EXPORT = 'export'
}

export enum ExportTypeEnum {
  PRODUCT = 'product',
  LOCATION = 'location',
  STORE = 'store'
}

export enum ImportTypeEnum {
  ITEM = 'item',
  SHELF = 'shelf'
}
