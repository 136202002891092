import { Action } from '@ngrx/store';
import { DropdownMasterRequest, MasterData } from '../../models';

export enum DropdownMasterDataActionsType {
  DropdownMasterDataRequested = '[Dropdown Master Data Page] Dropdown Master Data Requested',
  DropdownMasterDataLoaded = '[Dropdown Master Data API] Dropdown Master Data Loaded',
  DropdownMasterDataReset = '[Dropdown Master Data Page] Dropdown Master Data Reset'
}

export class DropdownMasterDataRequested implements Action {
  readonly type = DropdownMasterDataActionsType.DropdownMasterDataRequested;
  constructor(public payload: DropdownMasterRequest) {}
}

export class DropdownMasterDataLoaded implements Action {
  readonly type = DropdownMasterDataActionsType.DropdownMasterDataLoaded;
  constructor(public payload: MasterData[]) {}
}

export class DropdownMasterDataReset implements Action {
  readonly type = DropdownMasterDataActionsType.DropdownMasterDataReset;
  constructor() {}
}

export type MasterDataActions = DropdownMasterDataRequested | DropdownMasterDataLoaded | DropdownMasterDataReset;
