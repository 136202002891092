import { BaseReport } from './base-report.model';
import { MerchantInfo, OrderSchedule, OrderScheduleList, StoreProfile } from './merchant.model';

export class StoreListSearchCriteria {
  storeType?: string;
  state?: string;
  region?: string;
  searchCriteria?: string;
  text?: string;
  status?: string;
  startCreatedDate?: string;
  endCreatedDate?: string;
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
  defaultGroup?: boolean;
  active?: boolean;
}

export class StoreListSearchByName {
  storeType?: string;
  searchCriteria?: string;
  status?: string;
  warehouse?: string;
}

export class StoreListResponse {
  content: StoreList[];
  empty: boolean;
  first: boolean;
  hasContent: boolean;
  last: boolean;
  numberOfElements: number;
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface StoreList {
  no: string;
  code: string;
  createdByName: string;
  createdDate: string;
  deleted: boolean;
  deviceId: string;
  deviceStatus: string;
  group: string;
  id: string;
  lastModifiedDate: string;
  merchant: string;
  merchantType: string;
  merchantStatus: string;
  name: string;
  status: string;
  site: string;
  state: string;
  region: string;
}

export class StoreId {
  storeId: string;
}

export class StoreNo {
  storeNo: string;
}

export class StoreViewResponse {
  id: string;
  version: number;
  merchantId: string;
  taxId: string;
  merchantName: string;
  merchantType: string;
  contactName: string;
  mobilePhone: string;
  merchantInfo?: MerchantInfo;
  orderSchedule?: OrderSchedule;
  devices?: Device[];
}

export class StoreResponse {
  id: string;
  no: string;
  version: number;
  status: string;
  storeProfile: StoreProfile;
  orderSchedule: OrderScheduleList;
  devices: Device[];
}

export class Device {
  deviceId: string;
  registerDate: string;
  effectiveDate: string;
  generatedDate: string;
  serialNo: string;
  registeredNo: string;
  status: string;
}

export class POSRegisteredData {
  deviceId: string;
  registeredNo: string;
  serialNo: string;
}

export enum StorePageTabs {
  STORE_INFO = 'STORE_INFO',
  FIRST_LOT_ORDER = 'FIRST_LOT_ORDER'
}

export enum POSStatus {
  WAITING = 'WAITING',
  ACTIVATED = 'ACTIVATED',
  DEACTIVATED = 'DEACTIVATED'
}

export class StoreExportCriteria extends BaseReport {
  storeType: string;
  status: string;
  firstLotOnly: boolean;

  constructor(init?: Partial<StoreExportCriteria>) {
    super();
    Object.assign(this, init);
  }
}
