import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { environment as env } from '../../../environments/environment';
import { LogOutAction } from '../store/actions/auth.actions';
import { MenuRequestedAction } from '../store/actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction } from '../store/actions/user-info.action';
import { CookiesUtil } from '../utils/cookies-util';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@ngrx/store";
import * as i3 from "ngx-logger";
export class AppInitService {
    constructor(cookieService, store, logger) {
        this.cookieService = cookieService;
        this.store = store;
        this.logger = logger;
        this.cookiesUtil = new CookiesUtil(cookieService);
    }
    load() {
        return () => new Promise(resolve => {
            try {
                const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
                const accessToken = this.cookiesUtil.concatCookie(accessTokenCookiesName);
                if (accessToken) {
                    const userInfo = JwtDecode(accessToken);
                    this.dispatchInfo(userInfo);
                    this.store.dispatch(new ClientIdAction(userInfo.client_id));
                }
                else {
                    this.store.dispatch(new ClientIdAction(env.defaultClientId));
                }
                resolve(true);
            }
            catch (err) {
                this.logger.error(err);
                this.store.dispatch(new LogOutAction());
                window.location.href = window.location.origin;
            }
        });
    }
    dispatchInfo(userInfo) {
        userInfo.user_info.authorities = userInfo.authorities;
        this.store.dispatch(new UserInformationAction(userInfo.user_info));
        this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));
        this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
    }
}
AppInitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInitService_Factory() { return new AppInitService(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.Store), i0.ɵɵinject(i3.NGXLogger)); }, token: AppInitService, providedIn: "root" });
