import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StoreGroup,
  StoreGroupDto,
  StoreGroupListResponse,
  StoreGroupSearchCriteria,
  StoreGroupUser
} from '../models';
import { BaseService } from './base.service';

@Injectable()
export class StoreGroupService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeGroup;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: StoreGroupSearchCriteria): Observable<StoreGroupListResponse> {
    const url = this.getFullUrl(this.envService.groupList);
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  getGroup(merchantNo: string, groupNo: string): Observable<StoreGroup> {
    const url = this.getFullUrl(this.envService.get, {
      merchantId: merchantNo,
      groupId: groupNo
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public submit(data: StoreGroupDto): Observable<any> {
    const url = this.getFullUrl(this.envService.submit, {
      merchantId: data.merchant
    });

    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  public update(merchantNo: string, groupNo: string, data: StoreGroupDto): Observable<any> {
    const url = this.getFullUrl(this.envService.get, {
      merchantId: merchantNo,
      groupId: groupNo
    });

    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public getStoreGroupsByMerchant(merchantNo: string): Observable<StoreGroupUser[]> {
    const url = this.getFullUrl(this.envService.submit, {
      merchantId: merchantNo
    });
    return this.http.get<StoreGroupUser[]>(url, { headers: this.headers, observe: 'body' });
  }
}
