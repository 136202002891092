<div class="modal-content admin-form" [formGroup]="freshLiteForm">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Add Fresh Lite Category</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body pb-5">
    <div class="row mb-3">
      <div class="col-md-12">
        <label for="name">Fresh Lite Category Name<span class="text-danger">*</span></label>
        <div class="d-flex">
          <input
            type="text"
            class="form-control mr-3"
            formControlName="name"
            id="name"
            trim="blur"
            maxlength="100"
            placeholder="Fresh Lite Category Name"
            [ngClass]="{ 'is-invalid': isSubmitted && freshLiteForm.controls.name.errors }"
          />
          <button type="button" class="btn btn-standard w-15" appPreventDoubleClick (click)="addFreshLiteCategory()">
            Add
          </button>
        </div>
        <div *ngIf="isSubmitted && freshLiteForm.controls.name.errors?.required" class="invalid-display">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
        <div *ngIf="isSubmitted && freshLiteForm.controls.name.errors?.duplicated" class="invalid-display">
          Not allow duplicated fresh lite category name.
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-4">
        <label for="type">Type<span class="text-danger">*</span></label>
        <ng-select
          id="type"
          placeholder="Please select..."
          [items]="listOfValue['freshLiteType']"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="type"
          [ngClass]="{ 'is-invalid': isSubmitted && freshLiteForm.controls.type.errors }"
        >
        </ng-select>
        <div *ngIf="isSubmitted && freshLiteForm.controls.type.errors?.required" class="invalid-display">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>

    <div class="row mb-3" *ngIf="form.get('freshLiteItem')?.controls.length > 0">
      <div class="col-12">
        <label>Fresh Lite Category List</label>
        <div class="table-responsive table-scroll max-height-table">
          <table id="data-table" class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr>
                <th class="text-center w-25">Fresh Lite Category Code</th>
                <th class="text-center w-30">Fresh Lite Category Name</th>
                <th class="text-center w-20">Type</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                formArrayName="freshLiteItem"
                *ngFor="let freshLite of form.get('freshLiteItem')?.controls; index as itemIndex"
              >
                <tr [formGroupName]="itemIndex">
                  <td>
                    {{ freshLite.get('code')?.value | dashDisplay: '(None)' }}
                  </td>
                  <td>
                    <app-edit-in-place
                      [control]="freshLite.get('name')"
                      [viewData]="freshLite.get('name').value"
                      [mode]="form.get('editInPlaceMode').value"
                      (modeChange)="onEditInPlaceModeChange($event)"
                    >
                      <input
                        type="text"
                        formControlName="name"
                        class="form-control"
                        required
                        maxlength="100"
                        [ngClass]="{ 'is-invalid': freshLite.get('name').errors }"
                      />
                    </app-edit-in-place>
                    <div *ngIf="freshLite.get('name').errors?.required" class="invalid-display">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                    <div *ngIf="freshLite.get('name').errors?.duplicated" class="invalid-display">
                      Not allow duplicate fresh lite category name.
                    </div>
                  </td>
                  <td>
                    <app-edit-in-place
                      [control]="freshLite.get('type')"
                      [viewData]="getDropdownTypeLabel(freshLite.get('type').value)"
                      [mode]="form.get('editInPlaceMode').value"
                      (modeChange)="onEditInPlaceModeChange($event)"
                    >
                      <ng-select
                        placeholder="Please select..."
                        [items]="listOfValue['freshLiteType']"
                        [searchable]="false"
                        [clearable]="false"
                        bindLabel="label"
                        bindValue="value"
                        formControlName="type"
                        [ngClass]="{ 'is-invalid': freshLite.get('type').errors }"
                      >
                      </ng-select>
                    </app-edit-in-place>
                    <div *ngIf="freshLite.get('type').errors?.duplicated" class="invalid-display">
                      Not allow to change, this category has been used.
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="decline()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
      Update
    </button>
  </div>
</div>
