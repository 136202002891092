import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({ selector: '[appDisallowSpaces]' })
export class DisallowSpacesDirective {
  private readonly navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];

  inputElement: HTMLElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.ctrlKey === true) ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.metaKey === true)
    ) {
      return;
    } else if (e.key === ' ') {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\s/g, '');
    event.preventDefault();
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData('text').replace(/\s/g, '');
    event.preventDefault();
    document.execCommand('insertText', false, textData);
  }
}
