/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supplier-group-import.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./supplier-group-import.component";
import * as i3 from "ngx-bootstrap/modal";
import * as i4 from "../../../shared/services/common.upload.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "ngx-spinner";
import * as i7 from "ngx-logger";
import * as i8 from "../../../shared/services/supplier-group-request.service";
import * as i9 from "../../../shared/services/supplier-group.service";
var styles_SupplierGroupImportComponent = [i0.styles];
var RenderType_SupplierGroupImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupplierGroupImportComponent, data: {} });
export { RenderType_SupplierGroupImportComponent as RenderType_SupplierGroupImportComponent };
export function View_SupplierGroupImportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { uploadInput: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["uploadSupplierGroupInput", 1]], null, 0, "input", [["class", "d-none"], ["id", "uploadFile"], ["type", "file"]], [[8, "disabled", 0], [8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.handleFileInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["class", "btn btn-standard"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.OnClickImport() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "em", [["class", "icon-import"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Import Supplier\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; var currVal_1 = _co.multiple; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_SupplierGroupImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supplier-group-import", [], null, null, null, View_SupplierGroupImportComponent_0, RenderType_SupplierGroupImportComponent)), i1.ɵdid(1, 245760, null, 0, i2.SupplierGroupImportComponent, [i3.BsModalService, i4.CommonUploadService, i5.TranslateService, i6.NgxSpinnerService, i7.NGXLogger, i8.SupplierGroupRequestService, i9.SupplierGroupService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplierGroupImportComponentNgFactory = i1.ɵccf("app-supplier-group-import", i2.SupplierGroupImportComponent, View_SupplierGroupImportComponent_Host_0, { allowedExt: "allowedExt", disabled: "disabled", multiple: "multiple", allowedTypes: "allowedTypes", size: "size", withMeta: "withMeta", maxHeight: "maxHeight", maxWidth: "maxWidth", uploadUrl: "uploadUrl", uploadHeaders: "uploadHeaders", baseStorageUrl: "baseStorageUrl", controlName: "controlName", isNew: "isNew", fileSizeExceedMessage: "fileSizeExceedMessage", mode: "mode", supplierGroupNo: "supplierGroupNo" }, { itemDetail: "itemDetail" }, []);
export { SupplierGroupImportComponentNgFactory as SupplierGroupImportComponentNgFactory };
