import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { environment as env } from '../../../../../environments/environment';
import { beforeTodayValidator } from '../../../../shared/custom-validators/before-today-validator';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { DeliveryDetails, InventoryTypeEnum } from '../../../../shared/models/order-request.model';
import { OrderRequestSaveDeliveryDetailsAction } from '../../../../shared/store/actions/order-request.actions';
import { selectDeliveryDetails } from '../../../../shared/store/selectors/order-request.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { formatDateStartOfDay } from '../../../../shared/utils/date-util';

@Component({
  selector: 'app-delivery-details',
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss']
})
export class DeliveryDetailsComponent implements OnInit, OnDestroy {
  @Input() mode: RequestPageModesEnum;
  @Input() segmentList: Array<string> = [];
  @Output() updateDeliveryDetails: EventEmitter<boolean> = new EventEmitter<boolean>();

  public bsConfig: BsDatepickerConfig;
  public form: FormGroup;
  public submitted: boolean;
  public deliveryDetails$: Observable<DeliveryDetails>;
  public pageMode = RequestPageModesEnum;
  public inventoryType = InventoryTypeEnum;
  public productType = ProductTypeEnum;
  public dateFormat = env.dateFormat;

  private localStore: Observable<any>;

  constructor(public bsModalRef: BsModalRef, public fb: FormBuilder, protected readonly store: Store<AppStates>) {}

  ngOnInit() {
    this.createForm();

    this.bsConfig = {
      dateInputFormat: env.dateFormat,
      minDate: new Date(),
      showWeekNumbers: false,
      containerClass: 'theme-dark-blue'
    } as BsDatepickerConfig;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.deliveryDetails$ = this.localStore.pipe(select(selectDeliveryDetails));
    this.deliveryDetails$
      .pipe(
        take(1),
        filter(data => Boolean(data))
      )
      .subscribe(value => {
        if (value.storeOpenDate) {
          this.form.patchValue({
            storeOpenDate: moment(value.storeOpenDate, [env.dateFormat, moment.ISO_8601]).toDate()
          });
        }
      });

    this.setDeliveryDetailsCtrl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode && !changes.mode.firstChange) {
      this.setDeliveryDetailsCtrl();
    }
  }

  ngOnDestroy() {}

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, [Validators.required, beforeTodayValidator()]];
    this.form = this.fb.group({
      storeOpenDate: initialNullRequired,
      deliveryDetailsByType: this.fb.group({})
    });
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    if (this.form.value.storeOpenDate) {
      this.form.value.storeOpenDate = formatDateStartOfDay(this.form.value.storeOpenDate, env.dateISO8601);
    }

    if (this.form.value.deliveryDetailsByType) {
      for (const key of Object.keys(this.form.value.deliveryDetailsByType)) {
        this.form.value.deliveryDetailsByType[key].deliveryDate = formatDateStartOfDay(
          this.form.value.deliveryDetailsByType[key].deliveryDate,
          env.dateISO8601
        );
      }
    }

    this.store.dispatch(new OrderRequestSaveDeliveryDetailsAction(this.form.value));
    this.updateDeliveryDetails.emit(true);

    this.decline();
  }

  applyToAll(value: string) {
    for (const segment in this.deliveryDetailsByType.controls) {
      if (this.deliveryDetailsByType.controls[segment]) {
        this.deliveryDetailsByType.controls[segment].patchValue({
          deliveryNote: value
        });
      }
    }
  }

  setDeliveryDetailsCtrl() {
    if (this.mode === RequestPageModesEnum.REQUEST_VIEW) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  showSegment(segment) {
    return this.segmentList.includes(segment);
  }

  get deliveryDetailsByType() {
    return this.form.get('deliveryDetailsByType') as FormGroup;
  }
}
