import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { ApolloQueryResult } from 'apollo-client';
import { combineLatest, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MasterDataEnum } from '../../../../enum/master-data.enum';
import { RequestSectionEnum } from '../../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../../enum/request-step.enum';
import { TDStorePage } from '../../../../enum/td-store-page.enum';
import {
  GraphqlQueryObject,
  GraphqlQuerySortOptions,
  NewMasterData,
  OrderByEnum,
  OrderDirectionEnum
} from '../../../../gql/common.gql';
import { MerchantRequestViewResponse, Schedules, StoreRequestViewResponse, WeekEnum } from '../../../../models';
import { MasterService } from '../../../../services/master.service';
import { AppStates } from '../../../../store/state/app.states';
import { getSelectByPage } from '../../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../../utils/td-store-workflow-util';

@Component({
  selector: 'app-order-policy-schedule',
  templateUrl: './order-policy-schedule.component.html',
  styleUrls: ['./order-policy-schedule.component.scss']
})
export class OrderPolicyScheduleComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() index: number;
  @Input() page: TDStorePage;

  private localStore: Observable<any>;
  private type: RequestTypeEnum;
  private status: RequestStatusEnum;
  private step: RequestStepEnum;

  private readonly schedulesLimit = 7;
  private orderPolicySelectValue: any;
  public orderScheduleDateOptions: NewMasterData[];
  public deliveryScheduleDateOptions: NewMasterData[];
  public pickScheduleOptions: NewMasterData[];

  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  public merchantSelectValue$: Observable<ApolloQueryResult<any>>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly masterService: MasterService
  ) {}

  get schedules() {
    return this.parentForm.get('schedules') as FormArray;
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.status = RequestStatusEnum.DRAFT;
    this.step = RequestStepEnum.PROFILE;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(v => v !== null)
    );

    const dayQuery = new GraphqlQueryObject();
    dayQuery.name = 'days';
    dayQuery.sort = { orderBy: OrderByEnum.ID, orderDirection: OrderDirectionEnum.DESC } as GraphqlQuerySortOptions;
    this.merchantSelectValue$ = this.masterService
      .getMasterDataByNames([dayQuery, MasterDataEnum.ORDER_SCHEDULE_TIME])
      .pipe(filter(v => Boolean(v && v.data) && Object.keys(v.data).length > 0));

    this.addSchedule();
    this.schedules.disable();

    combineLatest([this.merchantRequestView$, this.merchantSelectValue$])
      .pipe(untilComponentDestroyed(this))
      .subscribe(results => {
        this.orderPolicySelectValue = results[1].data;
        this.orderScheduleDateOptions = [...results[1].data.days];
        this.deliveryScheduleDateOptions = [...results[1].data.days];
        this.pickScheduleOptions = [...results[1].data.days];

        if (results[0] && results[1]) {
          this.setOrderPolicyScheduleValue(results[0]);
        }
      });
  }

  get createForm() {
    const initialNullRequired = [
      { value: null, disabled: this.mode === RequestPageModesEnum.REQUEST_VIEW || !this.canEditSection() },
      this.schedules.length === 0 ? Validators.required : null
    ];

    return this.fb.group({
      orderScheduleDate: initialNullRequired,
      orderScheduleTime: initialNullRequired,
      deliverySchedule: [{ value: null, disabled: true }, this.schedules.length === 0 ? Validators.required : null],
      pickScheduleDate: [{ value: null, disabled: true }, this.schedules.length === 0 ? Validators.required : null]
    });
  }

  addSchedule() {
    if (this.schedules.length >= this.schedulesLimit) {
      return;
    }

    this.schedules.push(this.createForm);
  }

  deleteSchedule(index) {
    this.schedules.removeAt(index);
  }

  checkEditPermission() {
    return (
      this.page === TDStorePage.STORE_EDIT_ORDER_SCHEDULE ||
      this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, this.status)
    );
  }

  canEditSection() {
    return (
      this.page === TDStorePage.STORE_EDIT_ORDER_SCHEDULE ||
      this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.ORDER_POLICY)
    );
  }

  showBtn() {
    const showMode =
      ![RequestPageModesEnum.REQUEST_CREATE, RequestPageModesEnum.REQUEST_VIEW].includes(this.mode) &&
      this.checkEditPermission();
    const canAddItem = this.schedules.length < this.schedulesLimit;

    return showMode && canAddItem && this.canEditSection();
  }

  onSelectedOrderScheduleDate(rowIndex: number) {
    if (rowIndex !== 0) {
      this.updateValidators(rowIndex);
    }

    const currentValue = this.schedules.at(rowIndex).value;

    if (currentValue.orderScheduleDate) {
      this.schedules
        .at(rowIndex)
        .get('deliverySchedule')
        .enable();
    }

    if (currentValue.deliverySchedule) {
      this.schedules
        .at(rowIndex)
        .get('deliverySchedule')
        .patchValue(null);
    }

    if (currentValue.pickScheduleDate) {
      this.schedules
        .at(rowIndex)
        .get('pickScheduleDate')
        .patchValue(null);
    }
  }

  onSelectedDeliveryScheduleDate(rowIndex: number) {
    const currentValue = this.schedules.at(rowIndex).value;

    if (currentValue.orderScheduleDate && currentValue.deliverySchedule) {
      this.schedules
        .at(rowIndex)
        .get('pickScheduleDate')
        .enable();
    }

    if (currentValue.pickScheduleDate) {
      this.schedules
        .at(rowIndex)
        .get('pickScheduleDate')
        .patchValue(null);
    }
  }

  updateOrderScheduleDateOptions(rowIndex: number) {
    let week: NewMasterData[] = [...this.orderPolicySelectValue.days];
    const orderSchedules = this.schedules.value
      .map((v: Schedules) => {
        return v.orderScheduleDate ? v : null;
      })
      .filter(Boolean);

    const currentSchedule: Schedules = this.schedules.at(rowIndex).value;
    const currentIndex: number = orderSchedules.findIndex(
      v => v.orderScheduleDate === currentSchedule.orderScheduleDate
    );

    for (const i in orderSchedules) {
      if (currentIndex !== Number(i)) {
        const orderSchedule = orderSchedules[i];
        const startDateIndex = week.findIndex(v => v.code === orderSchedule.orderScheduleDate);
        week = week.concat(week.splice(0, startDateIndex));

        const endDateIndex = week.findIndex(v => v.code === orderSchedule.deliverySchedule);
        week.splice(0, endDateIndex > -1 ? endDateIndex + 1 : 1);
      }
    }

    week.sort((a: NewMasterData, b: NewMasterData) => WeekEnum[a.code] - WeekEnum[b.code]);
    this.orderScheduleDateOptions = week;
  }

  updateDeliveryScheduleDateOptions(rowIndex: number) {
    let week: NewMasterData[] = [...this.orderPolicySelectValue.days];
    const orderSchedules = this.schedules.value
      .map((v: Schedules) => {
        return v.orderScheduleDate ? v : null;
      })
      .filter(Boolean)
      .sort((a: Schedules, b: Schedules) => WeekEnum[a.orderScheduleDate] - WeekEnum[b.orderScheduleDate]);

    if (orderSchedules.length > 1) {
      const currentSchedule: Schedules = this.schedules.at(rowIndex).value;
      const currentIndex: number = orderSchedules.findIndex(
        v => v.orderScheduleDate === currentSchedule.orderScheduleDate
      );
      const nextSchedule = orderSchedules[currentIndex + 1] || orderSchedules[0];

      const startDateIndex = week.findIndex(v => v.code === currentSchedule.orderScheduleDate);
      week = week.concat(week.splice(0, startDateIndex));

      const endDateIndex = week.findIndex(v => v.code === nextSchedule.orderScheduleDate);
      week = week.splice(0, endDateIndex);
    }

    week.sort((a: NewMasterData, b: NewMasterData) => WeekEnum[a.code] - WeekEnum[b.code]);
    this.deliveryScheduleDateOptions = week;
  }

  updatePickScheduleOptions(startDate, endDate) {
    let week: NewMasterData[] = [...this.orderPolicySelectValue.days];

    const startDateIndex = week.findIndex(v => v.nameTh === startDate);
    week = week.concat(week.splice(0, startDateIndex));

    const endDateIndex = week.findIndex(v => v.nameTh === endDate);
    this.pickScheduleOptions = week.slice(0, endDateIndex + 1);
  }

  updateValidators(rowIndex) {
    const fieldUpdates = ['orderScheduleDate', 'orderScheduleTime', 'deliverySchedule', 'pickScheduleDate'];
    for (const field of fieldUpdates) {
      this.schedules
        .at(rowIndex)
        .get(field)
        .setValidators([Validators.required]);
      this.schedules
        .at(rowIndex)
        .get(field)
        .updateValueAndValidity();
    }
  }

  setOrderPolicyScheduleValue(merchantRequestView: MerchantRequestViewResponse | StoreRequestViewResponse) {
    this.type = merchantRequestView.type || RequestTypeEnum.EDIT;
    this.step = merchantRequestView.step || RequestStepEnum.EDIT_PROFILE;
    this.status = merchantRequestView.status || RequestStatusEnum.DRAFT;

    const currentData =
      merchantRequestView.orderSchedule && merchantRequestView.orderSchedule.orderScheduleList[this.index];

    if (currentData && currentData.schedules && currentData.schedules.length > 0) {
      this.schedules.clear(); // reset fields after getting new values

      currentData['schedules']
        .sort((a: Schedules, b: Schedules) => WeekEnum[a.orderScheduleDate] - WeekEnum[b.orderScheduleDate])
        .forEach((field, i) => {
          if (!this.schedules.at(i)) {
            this.schedules.push(this.createForm);
          }

          this.schedules.at(i).patchValue({
            orderScheduleDate: field.orderScheduleDate,
            orderScheduleTime: field.orderScheduleTime,
            deliverySchedule: field.deliverySchedule,
            pickScheduleDate: field.pickScheduleDate
          });

          if (this.page === TDStorePage.STORE_EDIT_ORDER_SCHEDULE) {
            this.schedules
              .at(i)
              .get('deliverySchedule')
              .enable();

            this.schedules
              .at(i)
              .get('pickScheduleDate')
              .enable();
          }
        });
    }
  }
}
