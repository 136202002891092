<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-product"></em>
    {{ data.title }}
  </div>

  <button type="button" *ngIf="hasManageClaimPermission()" class="btn btn-header-button" (click)="onCreateClaim()">
    <em class="icon-create-ro"></em>
    Create Claim
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onClose()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="receiveOrder" class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Receive Order No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ receiveOrder?.receiveOrderNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Order No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ receiveOrder?.orderNo | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Reference No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ receiveOrder?.refNo | dashDisplay }}</span>
      </div>
    </div>
  </div>

  <!-- Information Details -->
  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store</span>
          </div>
          <div class="info-header-value">
            {{ receiveOrder?.storeCodeName | dashDisplay }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Store Type</span>
          </div>
          <div class="info-header-value">
            {{ 'STORE_TYPE.' + receiveOrder?.storeType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Order Type</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER_TYPE.' + receiveOrder?.orderType | translate }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Delivery By</span>
          </div>
          <div class="info-header-value">
            {{ 'ORDER.DELIVERY_BY.' + receiveOrder?.deliveryBy | translate }}
          </div>
        </div>

        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Receiver</span>
          </div>
          <div class="info-header-value">
            {{ receiveOrder?.receiver }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created Date</span>
          </div>
          <div class="info-header-value">
            {{ receiveOrder?.createdDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="receiveOrder$ | async">
    <div class="mt-3 table-responsive">
      <table id="datatable" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center align-top">No.</th>
            <th class="text-center align-top">Product Name</th>
            <th class="text-center align-top">Article No.</th>
            <th class="text-center align-top w-15">Delivered Qty</th>
            <th class="text-center align-top w-15">Received Qty</th>
            <th class="text-center align-top w-15" *ngIf="receiveOrder?.deliveryBy === deliveryByTypeEnum.TD">
              Requested Claim Qty
            </th>
            <th class="text-center align-top w-15" *ngIf="receiveOrder?.deliveryBy === deliveryByTypeEnum.SUPPLIER">
              Remaining Qty
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let receiveOrderItem of paging.currentPageData; index as i">
            <td class="text-center">{{ paging.getItemNo(i) }}</td>
            <td>{{ receiveOrderItem.productName }}</td>
            <td class="text-center">{{ receiveOrderItem.articleNo }}</td>
            <td class="text-center">
              {{ receiveOrderItem.deliverQty | dashDisplay }}
              <div class="sub-header">({{ receiveOrderItem.qty + ' ' + receiveOrderItem.unit }})</div>
            </td>
            <td class="text-center">{{ receiveOrderItem.receiveQty | dashDisplay }}</td>
            <td class="text-center" *ngIf="receiveOrder?.deliveryBy === deliveryByTypeEnum.TD">
              {{ receiveOrderItem.claimQty | dashDisplay }}
            </td>
            <td class="text-center" *ngIf="receiveOrder?.deliveryBy === deliveryByTypeEnum.SUPPLIER">
              {{ receiveOrderItem.remainQty | dashDisplay }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-ui-pagination
      #paging
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [items]="(receiveOrder$ | async).items"
      [valueChangeDetector]="receiveOrder$ | async"
    >
    </app-ui-pagination>
  </ng-container>
</div>
