import { createSelector } from '@ngrx/store';
import * as fromZ8ConditionsListResponseState from '../reducers/z8-conditions.reducers';
import { AppStates } from '../state/app.states';

const selectZ8ConditionState = (state: AppStates) => state.z8Conditions;

export const selectZ8ConditionsListCriteria = createSelector(selectZ8ConditionState, state => {
  return state.criteriaObject;
});

export const selectAllZ8ConditionsList = createSelector(
  selectZ8ConditionState,
  fromZ8ConditionsListResponseState.selectAll
);

export const selectZ8ConditionsList = createSelector(
  selectZ8ConditionState,
  (state: fromZ8ConditionsListResponseState.Z8ConditionsState) => state
);
