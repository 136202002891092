import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ShipmentService } from '../../services/shipment.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { ShipmentActionTypes, ShipmentGetByIdResponse, ShipmentListResponseAction, ShipmentResetSelectDeliveryOrder } from '../actions/shipment.actions';
export class ShipmentEffects {
    constructor(actions$, shipmentService, store, logger) {
        this.actions$ = actions$;
        this.shipmentService = shipmentService;
        this.store = store;
        this.logger = logger;
        this.createShipment$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_CREATE_REQUEST), tap(action => this.logger.debug('@Effect Create Shipment Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.createShipment(action.payload.shipment).pipe(map(() => {
            this.store.dispatch(new ShipmentResetSelectDeliveryOrder());
            return new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been created.'
            });
        }), catchError(err => {
            // TODO consider 06004 code as optimistic lock
            if (err.error.code === '06004') {
                return of(new LayoutActionVersionError(true));
            }
            return of(new LayoutActionLoadError(err));
        }))));
        this.confirmLoad$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_CONFIRM_LOAD_REQUEST), tap(action => this.logger.debug('@Effect Confirm Load Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.confirmLoad(action.payload.shipment).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'This Delivery Order has been loaded.'
        })), catchError(err => of(new LayoutActionLoadError(err))))));
        this.confirmDispatch$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_CONFIRM_DISPATCH_REQUEST), tap(action => this.logger.debug('@Effect Confirm Dispatch Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.dispatchShipmentByShipmentNumber(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been saved.'
        })), catchError(err => {
            return err.error && err.error.code === '05005'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
        }))));
        this.editShipment$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_UPDATE_REQUEST), tap(action => this.logger.debug('@Effect Update Shipment Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.updateShipment(action.payload.shipment).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been saved.'
        })), catchError(err => of(new LayoutActionLoadError(err))))));
        this.deleteShipment$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_DELETE_REQUEST), tap(action => this.logger.debug('@Effect Delete Shipment Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.deleteByShipmentNumber(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'Shipment has been deleted.'
        })), catchError(err => of(new LayoutActionLoadError(err))))));
        this.getShipment$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Shipment Get Requested: ' + JSON.stringify(action.payload))), mergeMap(action => this.shipmentService.getShipmentByShipmentNumber(action.payload).pipe(map(result => {
            return new ShipmentGetByIdResponse(result);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.ShipmentListRequestAction$ = this.actions$.pipe(ofType(ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST}:` + JSON.stringify(action.payload))), switchMap(action => this.shipmentService.searchShipment(action.payload).pipe(map(response => new ShipmentListResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "createShipment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "confirmLoad$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "confirmDispatch$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "editShipment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "deleteShipment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "getShipment$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShipmentEffects.prototype, "ShipmentListRequestAction$", void 0);
