<div class="d-flex mb-3" *appHasPermission="['store_eq']">
  <button type="button" class="btn btn-standard" *ngIf="!waitingDevice && !activeDevice" (click)="onRegisterPOS()">
    <em class="icon-gen-user-pass"></em>
    Generate POS Registration Code
  </button>

  <button type="button" class="btn btn-standard" *ngIf="waitingDevice" (click)="onDeletePOS()">
    <em class="icon-delete" title="Delete"></em>
    Delete Device
  </button>

  <button type="button" class="btn btn-standard" *ngIf="activeDevice" (click)="onDeactivatePOS()">
    <em class="icon-deactivate" title="Deactivate"></em>
    Deactivate Device
  </button>
</div>

<div class="information-container container-fluid mb-3" *ngFor="let device of devices$ | async; let i = index">
  <div class="row">
    <div class="col-3">
      <span class="info-text-label">Device ID:</span>
    </div>
    <div class="col-3">
      <span class="info-text-value">{{ device.deviceId | dashDisplay: '(None)' }}</span>
    </div>
    <div class="col-6" *appHasPermission="['store_eq']">
      <button
        type="button"
        class="btn btn-default btn-edit-device"
        *ngIf="canUpdateRegisterPOS(device.status)"
        (click)="onUpdateRegisterPOS(device)"
      >
        <em class="icon-edit"></em>
      </button>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <span class="info-text-label">Registration Date:</span>
    </div>
    <div class="col-3">
      <span class="info-text-value">{{ device?.registerDate | amFromUtc | amLocal | dateDisplay }}</span>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <span class="info-text-label">Effective Date:</span>
    </div>
    <div class="col-3">
      <span class="info-text-value">{{ device?.effectiveDate | amFromUtc | amLocal | dateDisplay }}</span>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <span class="info-text-label">POS Registered No:</span>
    </div>
    <div class="col-3">
      <span class="info-text-value">{{ device?.registeredNo | dashDisplay: '(None)' }}</span>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <span class="info-text-label">Serial No:</span>
    </div>
    <div class="col-3">
      <span class="info-text-value">{{ device?.serialNo | dashDisplay: '(None)' }}</span>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col-3">
      <span class="info-text-label">Status:</span>
    </div>
    <div class="col-3">
      <span
        class="info-text-value pos-status"
        *ngIf="device.status; else none"
        [ngClass]="getColorStatus(device.status)"
      >
        {{ 'POS_STATUS.' + device.status | translate }}
      </span>
      <ng-template #none>
        <span>(None)</span>
      </ng-template>
    </div>
  </div>
</div>
