import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ShipmentService } from '../../services/shipment.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  ShipmentActionTypes,
  ShipmentConfirmDispatchRequest,
  ShipmentConfirmLoadRequest,
  ShipmentCreateRequest,
  ShipmentDeleteRequest,
  ShipmentGetByIdRequest,
  ShipmentGetByIdResponse,
  ShipmentListRequestAction,
  ShipmentListResponseAction,
  ShipmentResetSelectDeliveryOrder,
  ShipmentUpdateRequest
} from '../actions/shipment.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class ShipmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shipmentService: ShipmentService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  createShipment$ = this.actions$.pipe(
    ofType<ShipmentCreateRequest>(ShipmentActionTypes.SHIPMENT_CREATE_REQUEST),
    tap(action => this.logger.debug('@Effect Create Shipment Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.shipmentService.createShipment(action.payload.shipment).pipe(
        map(() => {
          this.store.dispatch(new ShipmentResetSelectDeliveryOrder());
          return new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been created.'
          });
        }),
        catchError(err => {
          // TODO consider 06004 code as optimistic lock
          if (err.error.code === '06004') {
            return of(new LayoutActionVersionError(true));
          }
          return of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  confirmLoad$ = this.actions$.pipe(
    ofType<ShipmentConfirmLoadRequest>(ShipmentActionTypes.SHIPMENT_CONFIRM_LOAD_REQUEST),
    tap(action => this.logger.debug('@Effect Confirm Load Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.shipmentService.confirmLoad(action.payload.shipment).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This Delivery Order has been loaded.'
            })
        ),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  confirmDispatch$ = this.actions$.pipe(
    ofType<ShipmentConfirmDispatchRequest>(ShipmentActionTypes.SHIPMENT_CONFIRM_DISPATCH_REQUEST),
    tap(action => this.logger.debug('@Effect Confirm Dispatch Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.shipmentService.dispatchShipmentByShipmentNumber(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '05005'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  editShipment$ = this.actions$.pipe(
    ofType<ShipmentUpdateRequest>(ShipmentActionTypes.SHIPMENT_UPDATE_REQUEST),
    tap(action => this.logger.debug('@Effect Update Shipment Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.shipmentService.updateShipment(action.payload.shipment).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been saved.'
            })
        ),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  deleteShipment$ = this.actions$.pipe(
    ofType<ShipmentDeleteRequest>(ShipmentActionTypes.SHIPMENT_DELETE_REQUEST),
    tap(action => this.logger.debug('@Effect Delete Shipment Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.shipmentService.deleteByShipmentNumber(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Shipment has been deleted.'
            })
        ),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  getShipment$ = this.actions$.pipe(
    ofType<ShipmentGetByIdRequest>(ShipmentActionTypes.SHIPMENT_GET_BY_ID_REQUEST),
    tap(action => this.logger.debug('@Effect Shipment Get Requested: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.shipmentService.getShipmentByShipmentNumber(action.payload).pipe(
        map(result => {
          return new ShipmentGetByIdResponse(result);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  ShipmentListRequestAction$ = this.actions$.pipe(
    ofType<ShipmentListRequestAction>(ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST}:` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action =>
      this.shipmentService.searchShipment(action.payload).pipe(
        map(response => new ShipmentListResponseAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );
}
