export class GraphqlQueryObject {
  defaultFields: string[] = ['id', 'nameTh', 'nameEn', 'code'];
  name: string;
  fields: string[];
  sort?: GraphqlQuerySortOptions;
  type?: any;
}

export interface GraphqlQuerySortOptions {
  orderBy?: OrderByEnum;
  orderDirection?: OrderDirectionEnum;
}

export enum OrderByEnum {
  CODE = 'CODE',
  NAME_EN = 'NAME_EN',
  NAME_TH = 'NAME_TH',
  ID = 'ID'
}

export enum OrderDirectionEnum {
  ASC = 'ASC',
  DESC = 'DESC'
}

export class NewMasterData {
  id: string;
  code: string;
  nameTh: string;
  nameEn: string;
}
