import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { VoucherRequestService } from '../../services';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { VoucherRequestActionTypes, VoucherRequestByIdResponseAction, VoucherRequestListResponseAction } from '../actions/voucher-request.actions';
export class VoucherRequestEffects {
    constructor(actions$, logger, voucherRequestService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.voucherRequestService = voucherRequestService;
        this.searchVoucherRequest$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${VoucherRequestActionTypes.VOUCHER_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.voucherRequestService.searchByCriteriaRequest(payload).pipe(map(requests => {
                return new VoucherRequestListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.createSubmitVoucher$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect Request Voucher Create Submit Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.voucherRequestService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been sent to approver.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getVoucherRequestById$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_GET_BY_ID_REQUEST), tap(action => this.logger.debug('@Effect Voucher Request By ID Load: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherRequestService.getVoucherRequestById(action.payload.requestId).pipe(map(VoucherRequestView => {
            return new VoucherRequestByIdResponseAction({ VoucherRequestView });
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.rejectRequestPromotions$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_REJECT_REQUESTED), tap(action => this.logger.debug('@Effect Voucher Request Reject: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherRequestService.reject(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been rejected.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.cancelRequestPromotions$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_CANCEL_REQUESTED), tap(action => this.logger.debug('@Effect Voucher Request Cancel: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherRequestService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been cancelled.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.approveRequestPromotions$ = this.actions$.pipe(ofType(VoucherRequestActionTypes.VOUCHER_REQUEST_APPROVE_REQUESTED), tap(action => this.logger.debug('@Effect Voucher Request Approve: ' + JSON.stringify(action.payload))), mergeMap(action => this.voucherRequestService.approve(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been approved.'
        })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "searchVoucherRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "createSubmitVoucher$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "getVoucherRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "rejectRequestPromotions$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "cancelRequestPromotions$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], VoucherRequestEffects.prototype, "approveRequestPromotions$", void 0);
