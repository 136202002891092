import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { LocationTypeEnum } from '../../../shared/enum/manage-stock.emun';
import { GraphqlQueryObject, GraphqlQuerySortOptions, OrderByEnum } from '../../../shared/gql/common.gql';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AssortmentAllType,
  ExportStockByLocationCriteria,
  ExportStockByProductCriteria,
  ProductStatusEnum,
  RouteLinkTab,
  SearchLocationCriteria,
  StockInformationContent,
  StockInformationSearchCriteria,
  StockLocation,
  StockLocationItem
} from '../../../shared/models';
import { ButtonType, ExportTypeEnum, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService, ProductAssortmentService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { StockInformationService } from '../../../shared/services/stock-information.service';
import { StockLocationsService } from '../../../shared/services/stock-locations.service';
import { StockInformationListRequestAction } from '../../../shared/store/actions/stock-information.actions';
import { StockInformationState } from '../../../shared/store/reducers/stock-information.reducers';
import {
  selectAllStockInformationList,
  selectStockInformationList,
  selectStockInformationListCriteria
} from '../../../shared/store/selectors/stock-information.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { StockInformationComponent } from '../stock-information/stock-information.component';

enum AdvanceFilterFormNameEnum {
  SEGMENT = 'segment',
  FAMILY = 'family',
  CLASS_CODE = 'classCode',
  SUB_CLASS = 'subClass',
  PRODUCT_TYPE = 'productType',
  DELIVERY_METHOD = 'deliveryMethod'
}

@Component({
  selector: 'app-stock-information-list',
  templateUrl: './stock-information-list.component.html',
  styleUrls: ['./stock-information-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StockInformationListComponent extends BaseSearchComponent<
  StockInformationSearchCriteria,
  StockInformationContent,
  StockInformationState
> {
  @ViewChild('locationSelect', { static: false }) locationSelect: NgSelectComponent;

  public advanceFilterFormName = AdvanceFilterFormNameEnum;

  public listRoute: Array<RouteLinkTab>;
  private localStore: Observable<any>;
  public locations: Array<StockLocationItem>;
  public selectedLocation: StockLocationItem;

  public warehouses: Array<any>;
  public productAssortmentStatus: NgOption[];
  public productTypeSelect: NgOption[];
  public deliveryMethodSelect: NgOption[];

  public listOfValue = {};
  public assortmentAllType = AssortmentAllType;

  public segmentTag: string;
  public segmentStringTag: string;
  public familyTag: string;
  public familyStringTag: string;
  public classCodeTag: string;
  public classCodeStringTag: string;
  public subClassTag: string;
  public subClassStringTag: string;
  public productTypeTag: string;
  public productTypeStringTag: string;
  public deliveryMethodTag: string;
  public deliveryMethodStringTag: string;
  private bsModalRef: BsModalRef;
  private readonly regexPermission = '^stk_v_$1';
  public exportProductForm: FormGroup;
  public exportLocationForm: FormGroup;
  public responseExportError: string;
  public exportListOfValue = {};
  public assortmentByExportFormAllType = AssortmentAllType;
  public productStatus: NgOption[];
  public locationList$: Observable<NgOption[]>;
  public locationSearchInput$ = new Subject<string>();
  public locationSearchLoading = false;
  public popover: any;
  private readonly MAX_LOCATION = 100;

  @ViewChild('exportProductModal', { static: false }) exportProductModal: ModalDirective;
  @ViewChild('exportLocationModal', { static: false }) exportLocationModal: ModalDirective;

  private readonly types = [
    this.assortmentAllType.SEGMENT,
    this.assortmentAllType.FAMILY,
    this.assortmentAllType.CLASS,
    this.assortmentAllType.SUBCLASS
  ];

  public buttons: Array<ImportExportButton> = [];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    private readonly router: Router,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    private readonly route: ActivatedRoute,
    private readonly productAssortmentService: ProductAssortmentService,
    private readonly stockInformationService: StockInformationService,
    private readonly masterService: MasterService,
    protected readonly stockLocationService: StockLocationsService
  ) {
    super(store, modalService, selectAllStockInformationList, selectStockInformationList);
  }

  doInit() {
    this.listRoute = [];
    this.loadSegmentList();
    this.setDropdownDefault();
    this.loadLocationList();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStockInformationListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      productStatus: [''],
      segment: [null],
      family: [null],
      classCode: [null],
      subClass: [null],
      productType: [null],
      deliveryMethod: [null]
    });

    this.exportProductForm = this.fb.group({
      segment: [null],
      family: [null],
      classCode: [null],
      subClass: [null],
      productType: [null],
      productStatus: [null]
    });

    this.exportLocationForm = this.fb.group({
      productType: [null],
      productStatus: [null],
      location: [null]
    });
  }

  onAdvanceSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      segment: formValue.segment,
      family: formValue.family,
      classCode: formValue.classCode,
      subClass: formValue.subClass,
      productType: formValue.productType,
      deliveryMethod: formValue.deliveryMethod
    };

    this.isShowAdvanceSearch = false;

    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.prepareSearchCriteriaTagsSegment();
    this.prepareSearchCriteriaTagsProductType();
    this.prepareSearchCriteriaTagsDeliveryMethod();
  }

  prepareSearchCriteriaTagsSegment() {
    this.segmentTag = null;
    this.segmentStringTag = null;
    this.familyTag = null;
    this.familyStringTag = null;
    this.classCodeTag = null;
    this.classCodeStringTag = null;
    this.subClassTag = null;
    this.subClassStringTag = null;

    if (this.criteriaObject.segment) {
      this.segmentStringTag = 'Segment';
      this.segmentTag = `"${
        this.listOfValue[this.assortmentAllType.SEGMENT].find(obj => obj.code === this.criteriaObject.segment).nameEn
      }"`;
      if (this.criteriaObject.family) {
        this.familyStringTag = 'Family';
        this.familyTag = `"${
          this.listOfValue[this.assortmentAllType.FAMILY].find(obj => obj.code === this.criteriaObject.family).nameEn
        }"`;
        if (this.criteriaObject.classCode) {
          this.classCodeStringTag = 'Class';
          this.classCodeTag = `"${
            this.listOfValue[this.assortmentAllType.CLASS].find(obj => obj.code === this.criteriaObject.classCode)
              .nameEn
          }"`;
          if (this.criteriaObject.subClass) {
            this.subClassStringTag = 'Sub Class';
            this.subClassTag = `"${
              this.listOfValue[this.assortmentAllType.SUBCLASS].find(obj => obj.code === this.criteriaObject.subClass)
                .nameEn
            }"`;
          }
        }
      }
    }
  }

  prepareSearchCriteriaTagsProductType() {
    this.productTypeTag = null;
    this.productTypeStringTag = null;

    if (this.criteriaObject.productType && this.criteriaObject.productType.length > 0) {
      this.productTypeStringTag = 'Product Type';
      const productTypeList = filterDropdown.productTypeLOV
        .filter(data => this.criteriaObject.productType.indexOf(data.value) > -1)
        .map(data => data.label)
        .join(', ');
      this.productTypeTag = `"${productTypeList}"`;
    }
  }

  prepareSearchCriteriaTagsDeliveryMethod() {
    this.deliveryMethodTag = null;
    this.deliveryMethodStringTag = null;

    if (this.criteriaObject.deliveryMethod && this.criteriaObject.deliveryMethod.length > 0) {
      this.deliveryMethodStringTag = 'Delivery Method';
      const list = filterDropdown.deliveryMethod
        .filter(data => this.criteriaObject.deliveryMethod.indexOf(data.value) > -1)
        .map(data => data.label)
        .join(', ');
      this.deliveryMethodTag = `"${list}"`;
    }
  }

  clearAdvanceSearch(formName: string) {
    this.setFirstPage();
    this.searchForm.controls[formName].reset();

    if (
      formName === AdvanceFilterFormNameEnum.SEGMENT ||
      formName === AdvanceFilterFormNameEnum.FAMILY ||
      formName === AdvanceFilterFormNameEnum.CLASS_CODE ||
      formName === AdvanceFilterFormNameEnum.SUB_CLASS
    ) {
      this.clearFormSelectDropdown(formName);
    }

    const formValue = this.searchForm.getRawValue();
    this.criteriaObject = {
      ...this.criteriaObject,
      [formName]: null,
      segment: formValue.segment,
      family: formValue.family,
      classCode: formValue.classCode,
      subClass: formValue.subClass,
      deliveryMethod: formValue.deliveryMethod,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  clearAdvanceSearchAll() {
    this.setFirstPage();
    this.searchForm.patchValue({
      segment: null,
      family: null,
      classCode: null,
      subClass: null,
      productType: null,
      deliveryMethod: null
    });

    this.clearFormSelectDropdown(this.assortmentAllType.SEGMENT);

    this.criteriaObject = {
      ...this.criteriaObject,
      warehouse: this.selectedLocation.locationCode,
      segment: null,
      family: null,
      classCode: null,
      subClass: null,
      productType: null,
      deliveryMethod: null,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      productStatus: formValue.productStatus,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      childItem: new ChildItem(
        StockInformationComponent,
        {
          warehouseCode: this.selectedLocation.locationCode,
          articleNo: viewParams.articleNo,
          articleName: `${viewParams.articleNo} ${viewParams.productName}`,
          title: `View Stock Information [${this.selectedLocation.displayName}]`,
          productType: viewParams.productType,
          stockLocation: this.selectedLocation.stockLocation
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  doAfterVersionAlertModal() {}

  loadSegmentList() {
    const productType = null;

    this.productAssortmentService
      .getSegmentListData(productType)
      .pipe(untilComponentDestroyed(this))
      .subscribe(data => {
        this.listOfValue[this.assortmentAllType.SEGMENT] = data.data.segments;
        this.exportListOfValue[this.assortmentByExportFormAllType.SEGMENT] = data.data.segments;
      });
  }

  setDropdownDefault() {
    this.productAssortmentStatus = filterDropdown.productAssortmentStatus;
    this.productTypeSelect = filterDropdown.productTypeLOV;
    this.deliveryMethodSelect = filterDropdown.deliveryMethod;
    this.productStatus = filterDropdown.productAssortmentStatus.filter(value => Boolean(value.value));
  }

  openPopover(pop) {
    this.popover = pop;
  }
  closePopover() {
    this.popover.hide();
  }

  onChangeWarehouse() {
    if (this.popover) {
      this.closePopover();
    }

    this.searchForm.patchValue({
      searchCriteria: null,
      productStatus: '',
      segment: null,
      family: null,
      classCode: null,
      subClass: null,
      productType: null,
      deliveryMethod: null
    });

    this.setFirstPage();
    this.clearFormSelectDropdown(this.assortmentAllType.SEGMENT);

    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      warehouse: !['WAREHOUSE', 'STORE'].includes(this.selectedLocation.locationCode)
        ? this.selectedLocation.locationCode
        : null,
      locationType: this.selectedLocation.locationCode === 'STORE' ? 'STORE' : null,
      segment: null,
      family: null,
      classCode: null,
      subClass: null,
      productType: null,
      deliveryMethod: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      productStatus: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearFormSelectDropdown(selectedType) {
    const associateLv = this.types.indexOf(selectedType) + 1;
    this.types
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        this.searchForm.controls[typeName].reset();
        this.listOfValue[typeName] = [];
      });
  }

  onChangeSelectDropdown($event, assortmentType: AssortmentAllType) {
    if (assortmentType) {
      this.clearFormSelectDropdown(assortmentType);
      if (this.assortmentAllType.SEGMENT === assortmentType) {
        this.listOfValue[this.assortmentAllType.FAMILY] = $event.families;
      } else if (this.assortmentAllType.FAMILY === assortmentType) {
        this.listOfValue[this.assortmentAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentAllType.CLASS === assortmentType) {
        this.listOfValue[this.assortmentAllType.SUBCLASS] = $event.subClasses;
      }
    }
  }

  loadLocationList(): void {
    this.locations = [];

    if (this.authGuardService.checkPermission('stk_v_sto')) {
      this.locations.push({
        displayName: 'All Stores',
        locationCode: 'STORE',
        stockLocation: StockLocation.ALL_STORES
      });
    }

    if (this.authGuardService.checkPermission('stk_v_all')) {
      this.locations.push({
        displayName: 'All Locations',
        locationCode: 'WAREHOUSE',
        stockLocation: StockLocation.ALL_LOCATIONS
      });
    }

    const wareHouseQuery = new GraphqlQueryObject();

    wareHouseQuery.name = 'warehouses';
    wareHouseQuery.fields = ['id', 'code', 'nameEn', 'nameTh', 'wmsCode'];
    wareHouseQuery.sort = { orderBy: OrderByEnum.CODE } as GraphqlQuerySortOptions;

    this.masterService.getMasterDataByNames([wareHouseQuery]).subscribe(result => {
      if (result.data && result.data.warehouses) {
        this.warehouses = result.data.warehouses.filter(wh =>
          this.authGuardService.checkPermission(wh.code.replace(/(.*)/, this.regexPermission), true)
        );

        if (this.warehouses && this.warehouses.length > 0) {
          this.warehouses.forEach(item => {
            this.locations.push({
              displayName: `${item.wmsCode}-${item.nameTh}`,
              locationCode: item.code,
              stockLocation: StockLocation.DC
            });
          });
        }
      }

      if (this.locations.length === 1) {
        this.selectLocation(this.locations[0]);
      }
    });
  }

  selectLocation(location: StockLocationItem) {
    if (!location) {
      return;
    }

    this.selectedLocation = location;

    this.buttons = [];
    this.buttons.push({
      type: ButtonType.EXPORT,
      name: 'Export By Product',
      by: ExportTypeEnum.PRODUCT
    });

    if (['WAREHOUSE', 'STORE'].includes(this.selectedLocation.locationCode)) {
      this.buttons.push({
        type: ButtonType.EXPORT,
        name: 'Export By Location',
        by: ExportTypeEnum.LOCATION
      });
    }

    this.onChangeWarehouse();
  }

  setRouteTab(): void {}

  search(criteriaObj) {
    if (this.selectedLocation && this.selectedLocation.locationCode) {
      this.criteriaObject.warehouse = !['WAREHOUSE', 'STORE'].includes(this.selectedLocation.locationCode)
        ? this.selectedLocation.locationCode
        : null;
      this.criteriaObject.locationType = this.selectedLocation.locationCode === 'STORE' ? 'STORE' : null;
      this.store.dispatch(new StockInformationListRequestAction(criteriaObj));
    }
  }

  getColorStatus(status: ProductStatusEnum): string {
    return ProductStatusEnum[status].toLowerCase();
  }

  get exportType() {
    return ExportTypeEnum;
  }

  onChangeSelectDropdownExport($event, assortmentType: AssortmentAllType) {
    if (assortmentType) {
      this.exportFormClearSelectDropdown(assortmentType);
      if (this.assortmentByExportFormAllType.SEGMENT === assortmentType) {
        this.exportListOfValue[this.assortmentByExportFormAllType.FAMILY] = $event.families;
      } else if (this.assortmentByExportFormAllType.FAMILY === assortmentType) {
        this.exportListOfValue[this.assortmentByExportFormAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentByExportFormAllType.CLASS === assortmentType) {
        this.exportListOfValue[this.assortmentByExportFormAllType.SUBCLASS] = $event.subClasses;
      }
    }
  }

  exportFormClearSelectDropdown(selectedType) {
    const associateLv = this.types.indexOf(selectedType) + 1;
    this.types
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        this.exportProductForm.controls[typeName].reset();
        this.exportListOfValue[typeName] = [];
      });
  }

  openExportModal(exportType: ExportTypeEnum) {
    this.responseExportError = null;

    switch (exportType) {
      case ExportTypeEnum.PRODUCT:
        this.exportFormClearSelectDropdown(this.assortmentAllType.SEGMENT);
        this.exportProductForm.reset();
        this.exportProductModal.show();
        break;
      case ExportTypeEnum.LOCATION:
        this.loadLocation('');
        this.exportLocationForm.reset();
        this.exportLocationModal.show();
        break;
      default: {
        break;
      }
    }
  }

  closeExportModal(exportType: ExportTypeEnum) {
    switch (exportType) {
      case ExportTypeEnum.PRODUCT:
        this.exportProductModal.hide();
        break;
      case ExportTypeEnum.LOCATION:
        this.exportLocationModal.hide();
        this.locationSelect.itemsList.unmarkItem();
        break;
      default: {
        break;
      }
    }
  }

  onExportProduct() {
    this.responseExportError = null;
    const rawData = this.exportProductForm.getRawValue();

    const exportCriteria = new ExportStockByProductCriteria({
      ...rawData,
      location: !['WAREHOUSE', 'STORE'].includes(this.selectedLocation.locationCode)
        ? this.selectedLocation.locationCode
        : null,
      locationType: this.selectedLocation.locationCode === 'STORE' ? 'STORE' : null,
      exportBy: ExportTypeEnum.PRODUCT
    });

    this.stockInformationService.exportStock<ExportStockByProductCriteria>(exportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportStock.prefixProduct} ${this.timeToExport}.xlsx`);
      },
      error => (this.responseExportError = error.error.message),
      () => {
        this.exportProductModal.hide();
        this.locationSelect.itemsList.unmarkItem();
      }
    );
  }

  onExportLocation() {
    this.responseExportError = null;

    const rawData = this.exportLocationForm.getRawValue();
    const exportCriteria = new ExportStockByLocationCriteria({
      ...rawData,
      location: rawData.location ? [rawData.location.value] : null,
      searchType: this.selectedLocation.locationCode === 'STORE' ? 'ALL_STORES' : 'ALL_LOCATIONS'
    });

    this.stockInformationService.exportStockByLocation<ExportStockByLocationCriteria>(exportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportStock.prefixLocation} ${this.timeToExport}.xlsx`);
      },
      error => (this.responseExportError = error.error.message),
      () => this.exportLocationModal.hide()
    );
  }

  loadLocation(initialTerm: string) {
    const locations =
      this.selectedLocation.locationCode === 'STORE'
        ? [LocationTypeEnum.STORE]
        : [LocationTypeEnum.WAREHOUSE, LocationTypeEnum.STORE];

    this.locationList$ = concat(
      of([]),
      this.locationSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.locationSearchLoading = true)),
        switchMap(term => {
          const param = {
            searchCriteria: term,
            locationType: locations
          } as SearchLocationCriteria;

          return this.stockLocationService.location(param).pipe(
            map(result => {
              const items = [];
              result.content.forEach(item => {
                items.push({ value: item.code, label: `${item.codeName}` });
              });

              return items;
            }),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.locationSearchLoading = false;
            })
          );
        })
      )
    );
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportStock.timeFormat);
  }

  get exportLocationFormInValid() {
    const formValues = this.exportLocationForm.value;
    return !formValues.location;
  }

  doDestroy() {}
}
