import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequestSectionEnum } from '../../../../enum/request-section.enum';
import { RequestStatusEnum } from '../../../../enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../../enum/request-step.enum';
import { TDStorePage } from '../../../../enum/td-store-page.enum';
import { MerchantRequestViewResponse, StoreRequestViewResponse } from '../../../../models';
import { AppStates } from '../../../../store/state/app.states';
import { getSelectByPage } from '../../../../utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../../utils/td-store-workflow-util';

@Component({
  selector: 'app-near-by-poi',
  templateUrl: './near-by-poi.component.html',
  styleUrls: ['./near-by-poi.component.scss']
})
export class NearByPoiComponent implements OnInit, OnDestroy {
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() page: TDStorePage;
  @Input() index: number;
  @Input() mode: RequestPageModesEnum;
  @Input() poiSelectValue: NgOption;
  @Input() poiDistanceSelectValue: NgOption;

  private localStore: Observable<any>;
  private type: RequestTypeEnum;
  private step: RequestStepEnum;
  private status: RequestStatusEnum;

  constructor(
    public fb: FormBuilder,
    public store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    private readonly element: ElementRef
  ) {}

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.parentForm.addControl('nearByPois', this.fb.array([]));
    this.addForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.mode)) {
      this.setNearbyPoi();
    }
  }

  ngOnDestroy() {}

  get nearByPois() {
    return this.parentForm.get('nearByPois') as FormArray;
  }

  addForm() {
    this.nearByPois.push(this.createForm());
  }

  createForm() {
    const initialNullRequired = [
      { value: null, disabled: false },
      this.nearByPois.length === 0 ? Validators.required : null
    ];

    return this.fb.group({
      poiType: initialNullRequired,
      poiName: initialNullRequired,
      distance: initialNullRequired
    });
  }

  onSelectedPoi(rowIndex) {
    const row = this.nearByPois.at(rowIndex);
    if (row.value.poiType || row.value.poiName || row.value.distance) {
      this.updateValidator(rowIndex, true);
    } else {
      this.updateValidator(rowIndex, false);
    }
  }

  updateValidator(rowIndex, isRequired) {
    const fieldUpdates = ['poiType', 'poiName', 'distance'];
    for (const field of fieldUpdates) {
      this.nearByPois
        .at(rowIndex)
        .get(field)
        .setValidators(isRequired ? Validators.required : null);
      this.nearByPois
        .at(rowIndex)
        .get(field)
        .updateValueAndValidity();
    }
  }

  deleteNearByPoi(index) {
    this.nearByPois.removeAt(index);

    if (this.nearByPois.length === 0) {
      this.addForm();
    } else if (index === 0) {
      this.updateValidator(index, true);
    }
  }

  checkEditPermission() {
    return this.nearByPois.disabled;
  }

  setNearbyPoi() {
    this.nearByPois.disable();

    this.merchantRequestView$ = this.localStore.pipe(
      select(getSelectByPage(this.page)),
      filter(data => data !== null)
    );
    this.merchantRequestView$.subscribe((value: MerchantRequestViewResponse | StoreRequestViewResponse) => {
      this.type = value.type || RequestTypeEnum.EDIT;
      this.step = value.step || RequestStepEnum.EDIT_PROFILE;
      this.status = value.status || RequestStatusEnum.DRAFT;

      const currentData = value.merchantInfo && value.merchantInfo.storeProfile[this.index];

      if (currentData && currentData.nearByPois && currentData.nearByPois.length > 0) {
        currentData.nearByPois.forEach((field, i) => {
          if (!this.nearByPois.at(i)) {
            this.addForm();
          }

          this.nearByPois.at(i).patchValue({
            poiType: field.poiType,
            poiName: field.poiName,
            distance: field.distance
          });

          this.onSelectedPoi(i);
        });
      }

      this.setNearbyPoiCtrl(this.type, this.page, this.step, this.mode);
    });
  }

  setNearbyPoiCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(localPage)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);

    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.nearByPois.enable();
    } else {
      this.nearByPois.disable();
    }
  }

  disableTableScroll(toDisable: boolean) {
    const el: HTMLInputElement = this.element.nativeElement.querySelector('.table-scroll');
    if (toDisable) {
      el.setAttribute('style', 'overflow-y: hidden');
    } else {
      el.removeAttribute('style');
    }
  }
}
