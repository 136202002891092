import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StoreList, StoreListSearchCriteria, StoreViewResponse } from '../../models';
import { StoreActions, StoreActionType } from '../actions/store.actions';
import { BaseState } from '../state/base.state';

export interface StoreState extends EntityState<StoreList>, BaseState {
  selected: StoreViewResponse;
  criteriaObject: StoreListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<StoreList> = createEntityAdapter<StoreList>();

export const initialStoreResponseState: StoreState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function storeReducers(state = initialStoreResponseState, action: StoreActions): StoreState {
  switch (action.type) {
    case StoreActionType.STORE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreActionType.STORE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreActionType.STORE_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload.storeView
      };
    case StoreActionType.STORE_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case StoreActionType.STORE_POS_SUBMIT_REQUEST:
      return {
        ...state,
        selected: {
          ...state.selected,
          devices: [action.payload]
        }
      };
    case StoreActionType.STORE_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
