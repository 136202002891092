import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { RolePageModes } from '../../../shared/enum/role.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RoleContent, RoleSearchCriteria, RouteLinkTab } from '../../../shared/models';
import { AuthGuardService } from '../../../shared/services';
import { RoleListRequestAction } from '../../../shared/store/actions/role.actions';
import { RoleListState } from '../../../shared/store/reducers/role-list.reducers';
import {
  selectAllRoleList,
  selectRoleList,
  selectRoleListCriteria
} from '../../../shared/store/selectors/role.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { RoleCreateComponent } from '../role-create/role-create.component';
import { RoleEditComponent } from '../role-edit/role-edit.component';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent extends BaseSearchComponent<RoleSearchCriteria, RoleContent, RoleListState> {
  private localStore: Observable<any>;
  listRoute: Array<RouteLinkTab>;
  private bsModalRef: BsModalRef;
  public dateFormat = environment.dateFormat;
  public modePage = RolePageModes;
  public hasViewPermission = false;
  public hasManagePermission = false;
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllRoleList, selectRoleList);
    super.subscribeForSaveSuccess();
    this.hasViewPermission = this.authGuardService.checkPermission(['user_bo_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['user_bo_m']);
  }

  doInit() {
    this.setRouteTab();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectRoleListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }
  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onAdvanceSubmit() {}
  prepareSearchCriteriaTags() {}

  goToView(mode: RolePageModes, roleNo?: string) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        RoleEditComponent,
        {
          mode: mode,
          title: this.getRoleTitle(mode),
          roleNo
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  gotoCreateRole() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        RoleCreateComponent,
        {
          mode: RolePageModes.CREATE,
          title: this.getRoleTitle(RolePageModes.CREATE)
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  search(criteriaObj: any) {
    this.store.dispatch(new RoleListRequestAction(criteriaObj));
  }

  setRouteTab(): void {
    this.listRoute = [];
    this.listRoute.push({ tabName: 'Role List', url: '/user-management/role-list' });
  }

  getRoleTitle(mode: RolePageModes): string {
    switch (mode) {
      case RolePageModes.CREATE:
        return 'Create Role';
      case RolePageModes.VIEW:
        return 'View Role';
      case RolePageModes.EDIT:
        return 'Edit Role';
      default:
        return '';
    }
  }

  doDestroy() {}
}
