import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GroupSupplierStoresService } from '../../services/group-supplier-stores.service';
import { SupplierGroupRequestService } from '../../services/supplier-group-request.service';
import { SupplierGroupService } from '../../services/supplier-group.service';
import { SupplierStoreService } from '../../services/supplier-store.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StoreSupplierListRequest,
  StoreSupplierListResponse,
  StoreSupplierRequestListRequest,
  StoreSupplierRequestListResponse,
  SupplierStoreActionTypes,
  SupplierStoreRequestListRequest,
  SupplierStoreRequestListResponse
} from '../actions/supplier-store.actions';

@Injectable()
export class SupplierStoreEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supplierStoreService: SupplierStoreService,
    private readonly supplierGroupRequestService: SupplierGroupRequestService,
    private readonly supplierGroupService: SupplierGroupService,
    private readonly groupSupplierStoresService: GroupSupplierStoresService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchSupplierStore$ = this.actions$.pipe(
    ofType<SupplierStoreRequestListRequest>(SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_REQUEST),
    tap(action =>
      this.logger.debug(`@Effect Supplier Store Request List Requested:` + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.supplierStoreService.searchByCriteria(action.payload).pipe(
        map(response => new SupplierStoreRequestListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  searchStoreSupplierRequest$ = this.actions$.pipe(
    ofType<StoreSupplierRequestListRequest>(SupplierStoreActionTypes.REQUEST_STORE_SUPPLIER_LIST_REQUEST),
    tap(action =>
      this.logger.debug(`@Effect Supplier Store Group Request List Requested:` + JSON.stringify(action.payload))
    ),
    switchMap(action => {
      if (action.payload.isImported) {
        return this.groupSupplierStoresService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
          map(response => new StoreSupplierRequestListResponse(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      } else {
        return this.supplierGroupRequestService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
          map(response => new StoreSupplierRequestListResponse(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      }
    })
  );

  @Effect()
  searchStoreSupplier$ = this.actions$.pipe(
    ofType<StoreSupplierListRequest>(SupplierStoreActionTypes.STORE_SUPPLIER_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Supplier Store Group List Requested:` + JSON.stringify(action.payload))),
    switchMap(action => {
      // if (action.payload.isImported) {
      //   // TODO: implement after import
      //   // return this.groupSupplierStoresService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
      //   //   map(response => new StoreSupplierRequestListResponse(response)),
      //   //   catchError(error => of(new LayoutActionLoadError(error)))
      //   // );
      // } else {
      //   return this.supplierGroupService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
      //     map(response => new StoreSupplierListResponse(response)),
      //     catchError(error => of(new LayoutActionLoadError(error)))
      //   );
      // }

      if (!action.payload.isImported) {
        return this.supplierGroupService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
          map(response => new StoreSupplierListResponse(response)),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
      }

      return of([]);
    })
  );
}
