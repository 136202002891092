import { Action } from '@ngrx/store';
import { StoreNo } from '../../models';
import { SpecialAssortmentList, SpecialAssortmentListSearchCriteria } from '../../models/store-assortment.model';

export enum SpecialAssortmentActionType {
  SPECIAL_ASSORTMENT_LIST_REQUEST = '[SpecialAssortment] SpecialAssortment List Request',
  SPECIAL_ASSORTMENT_LIST_RESPONSE = '[SpecialAssortment] SpecialAssortment List Response',
  SPECIAL_ASSORTMENT_LIST_RESET = '[SpecialAssortment] SpecialAssortment List Reset'
}

export class SpecialAssortmentListRequestAction implements Action {
  readonly type = SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_REQUEST;

  constructor(public payload: { storeNo: StoreNo; criteria: SpecialAssortmentListSearchCriteria }) {}
}

export class SpecialAssortmentListResponseAction implements Action {
  readonly type = SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESPONSE;

  constructor(public payload: SpecialAssortmentList) {}
}

export class ResetSpecialAssortmentListAction implements Action {
  readonly type = SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESET;
}

export type SpecialAssortmentActions =
  | SpecialAssortmentListRequestAction
  | SpecialAssortmentListResponseAction
  | ResetSpecialAssortmentListAction;
