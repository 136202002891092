import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { flatten, merge } from 'lodash';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  tap
} from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { StoreType } from '../../../shared/enum/store.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { StoreList } from '../../../shared/models';
import { PromotionCompensateReportExportCriteria } from '../../../shared/models/report.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { AuthGuardService, ProductService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { PromotionCompensateReportService } from '../../../shared/services/promotion-compensate-report.service';
import { SupplierService } from '../../../shared/services/supplier.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateTimeToDateOnlyString } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-promotion-compensate-modal',
  templateUrl: './promotion-compensate-modal.component.html',
  styleUrls: ['./promotion-compensate-modal.component.scss']
})
export class PromotionCompensateModalComponent extends BaseReportModalComponent implements OnInit, OnDestroy {
  @ViewChild('promotionCompensateModal', { static: false }) promotionCompensateModal: ModalDirective;
  private localStore: Observable<any>;

  public exportForm: FormGroup;
  public submitted: boolean;
  public storeType = StoreType;

  public responseExportError: string;
  public maxDate: Date;
  public minDate: Date;

  public storeList: Observable<StoreList[]>;
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();

  public storeTypeList: Array<NewMasterData> | null;
  public productClassList: Array<NewMasterData> | null;

  public supplierList: Observable<SupplierSearch[]>;
  public supplierSearchLoading = false;
  public supplierSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly authGuardService: AuthGuardService,
    protected masterService: MasterService,
    protected productService: ProductService,
    protected supplierService: SupplierService,
    protected promotionCompensateReportService: PromotionCompensateReportService
  ) {
    super();
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });

    this.productService
      .getClassCodeData(ArticleTypeEnum.MERCHANDISE)
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data) {
          this.productClassList = flatten(
            flatten(
              merge(
                result.data.segments.map(segment =>
                  segment.families.map(family => family.classCodes.map(classCodes => classCodes))
                )
              )
            )
          );
        }
      });

    this.createExportForm();
    this.loadSupplier('');
  }

  ngOnDestroy(): void {}

  closeExportModal() {
    this.exportForm.reset();
    this.promotionCompensateModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid && this.exportForm.valid) {
      return;
    }

    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const promotionCompensateReportExportCriteria = new PromotionCompensateReportExportCriteria({
      receiptDateFrom: dateTimeToDateOnlyString(rawData.effectiveDateFrom),
      receiptDateTo: dateTimeToDateOnlyString(rawData.effectiveDateTo),
      storeType: rawData.storeType,
      supplierCode: rawData.supplier ? rawData.supplier.supplierCode : null,
      classes: rawData.productClass
    });

    this.promotionCompensateReportService.reportExport(promotionCompensateReportExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportPromotionCompensate.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportPromotionCompensate.timeFormat);
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.promotionCompensateModal.show();
  }

  createExportForm() {
    this.exportForm = this.formBuilder.group({
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      storeType: [null],
      supplier: [null],
      productClass: [null]
    });
  }
  onChangeExportEffectiveDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.exportFormInValid = false;
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportEffectiveDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.exportFormInValid = false;
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }
}
