import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SupplierPriceConfigService } from '../../services/supplier-price-config.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  SupplierPriceActionTypes,
  SupplierPriceListRequest,
  SupplierPriceListResponse,
  SupplierPriceShowSuccess
} from '../actions/supplier-price.actions';

@Injectable()
export class SupplierPriceEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly supplierPriceConfigService: SupplierPriceConfigService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchSupplierPrice$ = this.actions$.pipe(
    ofType<SupplierPriceListRequest>(SupplierPriceActionTypes.SUPPLIER_PRICE_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Supplier Price List Requested:` + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.supplierPriceConfigService.searchSupplierPrice(action.payload).pipe(
        switchMap(response => [
          new SupplierPriceShowSuccess(action.payload.showSuccess),
          new SupplierPriceListResponse(response)
        ]),
        catchError(error => of(new LayoutActionLoadError(error)))
      );

      return of([]);
    })
  );
}
