import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  PurchaseRequest,
  PurchaseRequestContent,
  PurchaseRequestDeliveryDetail,
  PurchaseRequestErrorResponse,
  PurchaseRequestSearchCriteria
} from '../../models/purchase-request.model';
import { PurchaseRequestActions, PurchaseRequestActionTypes } from '../actions/purchase-request.actions';

export interface PurchaseRequestState extends EntityState<PurchaseRequestContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  productList: any[];
  purchaseRequest: PurchaseRequest;
  purchaseRequestErrorResponse: PurchaseRequestErrorResponse;
  criteriaObject: PurchaseRequestSearchCriteria;
  defaultDeliveryDetail: PurchaseRequestDeliveryDetail;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<PurchaseRequestContent> = createEntityAdapter<PurchaseRequestContent>();

export const initialPurchaseRequestState: PurchaseRequestState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  productList: [],
  purchaseRequest: null,
  purchaseRequestErrorResponse: null,
  defaultDeliveryDetail: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function purchaseRequestReducers(
  state = initialPurchaseRequestState,
  action: PurchaseRequestActions
): PurchaseRequestState {
  switch (action.type) {
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_VIEW_LOADED: {
      return {
        ...state,
        purchaseRequest: action.payload
      };
    }
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_RESET: {
      return {
        ...state,
        purchaseRequest: null,
        productList: []
      };
    }
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_ERROR_RESPONSE: {
      return {
        ...state,
        purchaseRequestErrorResponse: action.payload
      };
    }
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_RESPONSE: {
      return {
        ...state,
        defaultDeliveryDetail: {
          ...state.defaultDeliveryDetail,
          ...action.payload
        }
      };
    }
    case PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_RESET: {
      return {
        ...state,
        defaultDeliveryDetail: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
