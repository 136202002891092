import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MemberCardContent, MemberCardSearchCriteria } from '../../models';
import { AdjustStockResponse } from '../../models/adjust-stock.model';
import { MemberCardAction, MemberCardTypeActions } from '../actions/member-card.actions';

export interface MemberCardState extends EntityState<MemberCardContent> {
  isLoading: boolean;
  submitSuccess: boolean;
  selected: AdjustStockResponse;
  criteriaObject: MemberCardSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<MemberCardContent> = createEntityAdapter<MemberCardContent>();

export const initialMemberCardState: MemberCardState = adapter.getInitialState({
  isLoading: false,
  submitSuccess: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function MemberCardReducers(state = initialMemberCardState, action: MemberCardAction): MemberCardState {
  switch (action.type) {
    case MemberCardTypeActions.GENERATE_MEMBER_CARD_RESPONSE:
      return {
        ...state,
        submitSuccess: true
      };
    case MemberCardTypeActions.GENERATE_MEMBER_CARD_RESET:
      return {
        ...state,
        submitSuccess: false
      };
    case MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
