import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { PurchaseOrder, PurchaseOrderContent, PurchaseOrderSearchCriteria } from '../../models/purchase-order.model';
import { PurchaseOrderActions, PurchaseOrderActionTypes } from '../actions/purchase-order.actions';

export interface PurchaseOrderState extends EntityState<PurchaseOrderContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  purchaseOrder: PurchaseOrder;
  purchaseOrderCancelError: ErrorResponse;
  criteriaObject: PurchaseOrderSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<PurchaseOrderContent> = createEntityAdapter<PurchaseOrderContent>();

export const initialPurchaseOrderState: PurchaseOrderState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  purchaseOrder: null,
  purchaseOrderCancelError: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function purchaseOrderReducers(
  state = initialPurchaseOrderState,
  action: PurchaseOrderActions
): PurchaseOrderState {
  switch (action.type) {
    case PurchaseOrderActionTypes.PURCHASE_ORDER_VIEW_LOADED: {
      return {
        ...state,
        purchaseOrder: action.payload
      };
    }
    case PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case PurchaseOrderActionTypes.PURCHASE_ORDER_RESET: {
      return {
        ...state,
        ...{
          purchaseOrder: null,
          purchaseOrderCancelError: null
        }
      };
    }
    case PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case PurchaseOrderActionTypes.PURCHASE_ORDER_CANCEL_ERROR_RESPONSE: {
      return {
        ...state,
        purchaseOrderCancelError: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
