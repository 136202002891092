<div class="edit-in-place d-flex">
  <div *ngIf="localMode === ModeEnum.EDIT; then editMode; else viewMode"></div>
  <ng-template #editMode>
    <div class="w-100">
      <ng-content></ng-content>
    </div>
    <div class="flex-shrink-0">
      <button type="button" class="p-1 close" aria-label="Close" (click)="doCancel()">
        <em class="icon-close"></em>
      </button>
      <button type="button" class="p-1 close" aria-label="Close" (click)="doOK()">
        <em class="icon-correct"></em>
      </button>
    </div>
  </ng-template>
  <ng-template #viewMode>
    <div class="w-100">{{ viewData }}</div>
    <a class="flex-shrink-0" (click)="doToggleMode()"><em class="icon-edit"></em></a>
  </ng-template>
</div>
