import { Action } from '@ngrx/store';
import {
  SupplierGroup,
  SupplierGroupPagination,
  SupplierGroupSearchCriteria
} from '../../models/supplier-group.model';

export enum SupplierGroupActionTypes {
  SUPPLIER_GROUP_VIEW_REQUESTED = '[Supplier Group View Page] Supplier Group View Request',
  SUPPLIER_GROUP_VIEW_LOADED = '[Supplier Group View Page] Supplier Group View Loaded',
  SUPPLIER_GROUP_LIST_REQUEST = '[Supplier Group List Page] Supplier Group List Request',
  SUPPLIER_GROUP_LIST_RESPONSE = '[Supplier Group List API] Supplier Group List Response',
  SUPPLIER_GROUP_RESET = '[Supplier Group View Page] Supplier Group Reset'
}

export class SupplierGroupViewRequest implements Action {
  readonly type = SupplierGroupActionTypes.SUPPLIER_GROUP_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class SupplierGroupViewLoaded implements Action {
  readonly type = SupplierGroupActionTypes.SUPPLIER_GROUP_VIEW_LOADED;

  constructor(public payload: SupplierGroup) {}
}

export class SupplierGroupListRequest implements Action {
  readonly type = SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_REQUEST;
  constructor(public payload: SupplierGroupSearchCriteria) {}
}

export class SupplierGroupListResponse implements Action {
  readonly type = SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_RESPONSE;

  constructor(public payload: SupplierGroupPagination) {}
}

export class SupplierGroupReset implements Action {
  readonly type = SupplierGroupActionTypes.SUPPLIER_GROUP_RESET;
  constructor() {}
}

export type SupplierGroupActions =
  | SupplierGroupViewRequest
  | SupplierGroupViewLoaded
  | SupplierGroupListRequest
  | SupplierGroupListResponse
  | SupplierGroupReset;
