import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ParameterService } from '../../services/z8-parameter.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { Z8ParameterActionType, Z8ParameterListHistoryResponseAction, Z8ParameterListResponseAction } from '../actions/z8-parameter.actions';
export class Z8ParameterEffects {
    constructor(actions$, z8ParameterService, logger) {
        this.actions$ = actions$;
        this.z8ParameterService = z8ParameterService;
        this.logger = logger;
        this.searchZ8Parameter$ = this.actions$.pipe(ofType(Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${Z8ParameterActionType.Z8_PARAMETER_LIST_REQUEST}: ` + Z8ParameterEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.z8ParameterService.searchByCriteria(payload).pipe(map(response => {
                return new Z8ParameterListResponseAction(response);
            }), catchError(error => {
                return of(new LayoutActionLoadError(error));
            }));
        }));
        this.z8ParameterHistory$ = this.actions$.pipe(ofType(Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${Z8ParameterActionType.Z8_PARAMETER_LIST_HISTORY_REQUEST}: ` +
                Z8ParameterEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.z8ParameterService.getHistoryLogs(payload).pipe(map(response => new Z8ParameterListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    static stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], Z8ParameterEffects.prototype, "searchZ8Parameter$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], Z8ParameterEffects.prototype, "z8ParameterHistory$", void 0);
