<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="uploadSupplierPrice">Supplier Price</label>
        <div class="d-flex">
          <app-assortment-supplier-price-file-upload
            id="uploadSupplierPrice"
            formControlName="uploadSupplierPrice"
            class="form-control"
            [hasError]="form.controls.uploadSupplierPrice.errors"
            [allowedTypes]="'sheet'"
            [withMeta]="true"
            [controlName]="'uploadSupplierPrice'"
            [allowedExt]="'xlsx'"
            [fileModule]=""
            [deliveryMethod]="deliveryMethod"
            (submitted)="onSubmit()"
          ></app-assortment-supplier-price-file-upload>
        </div>
      </div>
    </div>
    <div
      class="form-row"
      *ngIf="
        form.controls.uploadSupplierPrice.errors ||
        (form.controls.uploadSupplierPrice.value &&
          form.controls.uploadSupplierPrice.value[0] &&
          form.controls.uploadSupplierPrice.value[0].status)
      "
    >
      <div class="form-group col-md">
        <div
          class="alert-danger form-group mb-0"
          *ngIf="
            form.controls.uploadSupplierPrice.errors ||
            (form.controls.uploadSupplierPrice.value &&
              form.controls.uploadSupplierPrice.value[0].status === uploadStatus.FAILED)
          "
        >
          <div *ngIf="form.controls.uploadSupplierPrice.errors?.fileExt">
            Incorrect Format (allow only format file .xlsx).
          </div>
          <div
            *ngIf="
              form.controls.uploadSupplierPrice.value &&
              form.controls.uploadSupplierPrice.value[0].errorMessage &&
              form.controls.uploadSupplierPrice.value[0].status === uploadStatus.FAILED
            "
          >
            {{ form.controls.uploadSupplierPrice.value[0].errorMessage }}
          </div>
          <div
            *ngIf="
              form.controls.uploadSupplierPrice.value &&
              form.controls.uploadSupplierPrice.value[0].fileBase64String &&
              form.controls.uploadSupplierPrice.value[0].status === uploadStatus.FAILED
            "
            class="upload-error"
          >
            <span class="flex-70"
              >{{ form.controls.uploadSupplierPrice.value[0].rowErrorNo }} Error Found. Download this file to edit or
              delete invalid row then re-import.</span
            >
            <a class="btn btn-link upload-error-file" (click)="downloadErrorExcel()">download</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      [disabled]="
        form.controls.uploadSupplierPrice.errors ||
        !form.controls.uploadSupplierPrice.value ||
        form.controls.uploadSupplierPrice.value.length === 0 ||
        form.controls.uploadSupplierPrice.value[0].status === uploadStatus.FAILED
      "
      id="btnSubmit"
      (click)="fileUpload.uploadFile()"
    >
      OK
    </button>
  </div>
</div>
