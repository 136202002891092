import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../services/dashboard.service';
import { DashboardActionTypes, TasksByRoleListResponseAction } from '../actions/dashboard.actions';
import { LayoutActionLoadError } from '../actions/layout.action';
export class DashboardEffects {
    constructor(actions$, dashboardService, logger) {
        this.actions$ = actions$;
        this.dashboardService = dashboardService;
        this.logger = logger;
        this.searchTasksByRole$ = this.actions$.pipe(ofType(DashboardActionTypes.TASKS_BY_ROLE_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${DashboardActionTypes.TASKS_BY_ROLE_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.dashboardService.tasksByRole(payload).pipe(map(reply => {
                return new TasksByRoleListResponseAction(reply);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], DashboardEffects.prototype, "searchTasksByRole$", void 0);
