import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfRequestService } from '../../services/shelf-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ShelfApproveRequestAction,
  ShelfApproveResponseAction,
  ShelfCreateSaveErrorAction,
  ShelfCreateSaveRequestAction,
  ShelfCreateSaveResponseAction,
  ShelfCreateSubmitErrorAction,
  ShelfCreateSubmitRequestAction,
  ShelfCreateSubmitResponseAction,
  ShelfDeleteRequestAction,
  ShelfDeleteResponseAction,
  ShelfRequestActionType,
  ShelfRequestByIdRequestAction,
  ShelfRequestByIdResponseAction,
  ShelfRequestListHistoryRequestAction,
  ShelfRequestListHistoryResponseAction,
  ShelfRequestListRequestAction,
  ShelfRequestListResponseAction
} from '../actions/shelf-request.actions';

@Injectable()
export class ShelfRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchShelfRequest$ = this.actions$.pipe(
    ofType<ShelfRequestListRequestAction>(ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfRequestService.searchByCriteria(payload).pipe(
        map(response => {
          return new ShelfRequestListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfCreateSave$ = this.actions$.pipe(
    ofType<ShelfCreateSaveRequestAction>(ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfRequestService.saveRequest(payload).pipe(
        map(res => new ShelfCreateSaveResponseAction(res)),
        catchError(error => of(new ShelfCreateSaveErrorAction(error)))
      );
    })
  );

  @Effect()
  shelfCreateSubmit$ = this.actions$.pipe(
    ofType<ShelfCreateSubmitRequestAction>(ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfRequestService.submitRequest(payload).pipe(
        map(res => new ShelfCreateSubmitResponseAction(res)),
        catchError(error => of(new ShelfCreateSubmitErrorAction(error.error)))
      );
    })
  );

  @Effect()
  getShelfRequestById$ = this.actions$.pipe(
    ofType<ShelfRequestByIdRequestAction>(ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfRequestService.getShelfRequestById(payload).pipe(
        map(res => {
          return new ShelfRequestByIdResponseAction(res);
        }),

        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfApprove$ = this.actions$.pipe(
    ofType<ShelfApproveRequestAction>(ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.shelfRequestService.approveRequest(payload).pipe(
        map(() => new ShelfApproveResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  shelfRequestHistory$ = this.actions$.pipe(
    ofType<ShelfRequestListHistoryRequestAction>(ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.shelfRequestService.getHistoryLogs(payload).pipe(
        map(response => new ShelfRequestListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  shelfRequestDelete$ = this.actions$.pipe(
    ofType<ShelfDeleteRequestAction>(ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST}: ` + JSON.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.shelfRequestService.deleteByRequestId(payload).pipe(
        map(() => new ShelfDeleteResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
