import env from './.env';
import { environment as defaultEnv } from './base.environment';

export const environment = {
  ...defaultEnv,
  hmr: false,
  version: env.version + '-khaohom',
  tier: 'khaohom',
  serverUrl: 'https://api-hom.tdshop.io',
  cookies: {
    ...defaultEnv.cookies,
    domain: env.forceDomain || 'nest-hom.tdshop.io'
  }
};
