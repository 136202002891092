export enum NotificationTypeEnum {
  NEXT = 'next',
  ERROR = 'error',
  CANCEL = 'cancel',
  CLOSE = 'close',
  FORCE_CLOSE = 'Force Close', // New version
  CONFIRM = 'Confirm', // New version
  ALERT = 'Alert', // New version
  LOGGING = 'Logging' // New version
}
