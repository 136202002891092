import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';
import { AuthUserInfoState } from '../state/auth-user-info.state';

export function authUserInfoReducers(state = null, action: UserInfoActions): AuthUserInfoState {
  if (action.type === UserActionTypes.USER_INFO) {
    return {
      ...state,
      userNo: action.payload.userNo,
      userID: action.payload.userID,
      userName: action.payload.userName,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      mobileNo: action.payload.mobileNo,
      country: action.payload.country,
      currency: action.payload.currency,
      language: action.payload.language,
      timeZone: action.payload.timeZone,
      roles: action.payload.roles,
      authorities: action.payload.authorities,
      forceChangePassword: action.payload.forceChangePassword,
      profileImageName: action.payload.profileImageName,
      displayName: setDisplayName(action.payload.firstName, action.payload.lastName)
    };
  } else {
    return state;
  }
}

function setDisplayName(firstName: string, lastName: string): string {
  firstName = firstName ? firstName : '';
  lastName = lastName ? ' ' + lastName : '';

  return `${firstName + lastName}`;
}
