import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StockInformationService } from '../../services/stock-information.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StockInformationActionTypes, StockInformationListResponseAction, StockInformationMovementResponseAction, StockInformationResponseAction, StockInformationStoreResponseAction, StockInformationWarehouseResponseAction } from '../actions/stock-information.actions';
export class StockInformationEffects {
    constructor(actions$, logger, stockInformationService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.stockInformationService = stockInformationService;
        this.searchStockInformationList$ = this.actions$.pipe(ofType(StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService.searchByCriteria(payload).pipe(map(requests => {
                return new StockInformationListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.stockInformation$ = this.actions$.pipe(ofType(StockInformationActionTypes.STOCK_INFORMATION_GET_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_GET_REQUEST}`);
            return action;
        }), switchMap(action => {
            return this.stockInformationService.warehouseArticleNo(action.payload.warehouse, action.payload.articleNo).pipe(map(requests => {
                return new StockInformationResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchStockMovement$ = this.actions$.pipe(ofType(StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService.movement(payload.warehouse, payload.articleNo, payload.criteria).pipe(map(requests => {
                return new StockInformationMovementResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchStockStore$ = this.actions$.pipe(ofType(StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService
                .stockByLocation(payload.warehouse, payload.articleNo, payload.criteria)
                .pipe(map(requests => {
                return new StockInformationStoreResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.searchStockWarehouse$ = this.actions$.pipe(ofType(StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST}: ` +
                this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.stockInformationService
                .stockByLocation(payload.warehouse, payload.articleNo, payload.criteria)
                .pipe(map(requests => {
                return new StockInformationWarehouseResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockInformationEffects.prototype, "searchStockInformationList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockInformationEffects.prototype, "stockInformation$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockInformationEffects.prototype, "searchStockMovement$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockInformationEffects.prototype, "searchStockStore$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StockInformationEffects.prototype, "searchStockWarehouse$", void 0);
