import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PermissionsService } from '../../services/permissions.service';
import { RoleService } from '../../services/role.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  RoleActionTypes,
  RoleAllRequestAction,
  RoleAllResponseAction,
  RoleByIdRequestAction,
  RoleByIdResponseAction,
  RoleCreatedRequestAction,
  RoleEditRequestAction,
  RoleListRequestAction,
  RoleListResponseAction,
  RoleMasterListRequest,
  RoleMasterListResponse,
  RoleResponseErrorAction
} from '../actions/role.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class RoleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly roleService: RoleService,
    private readonly permissionsService: PermissionsService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>,
    protected spinner: NgxSpinnerService
  ) {}

  @Effect()
  searchRoleList$ = this.actions$.pipe(
    ofType<RoleListRequestAction>(RoleActionTypes.ROLE_LIST_REQUEST_ACTION),
    map(action => {
      this.logger.debug(`@Effect ${RoleActionTypes.ROLE_LIST_REQUEST_ACTION}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.roleService.searchByCriteriaRequest(payload).pipe(
        map(requests => {
          let i = 1;
          requests.content.forEach(e => {
            e.id = i.toString();
            i++;
          });
          return new RoleListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getRoleMasterList$: Observable<Action> = this.actions$.pipe(
    ofType<RoleMasterListRequest>(RoleActionTypes.ROLE_MASTER_LIST_REQUEST),
    tap(action =>
      this.logger.debug(`@Effect ${RoleActionTypes.ROLE_MASTER_LIST_REQUEST}: ` + JSON.stringify(action))
    ),
    switchMap(() =>
      this.permissionsService.getPermission().pipe(
        map(items => new RoleMasterListResponse({ permissions: items })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  createRole$: Observable<Action> = this.actions$.pipe(
    ofType<RoleCreatedRequestAction>(RoleActionTypes.ROLE_CREATED_REQUEST),
    tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_CREATED_REQUEST}: ` + JSON.stringify(action))),
    switchMap(action => {
      return this.roleService.save(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The role has been created.'
            })
        ),
        catchError(err => this.responseError(err, action))
      );
    })
  );

  @Effect()
  editRole$: Observable<Action> = this.actions$.pipe(
    ofType<RoleEditRequestAction>(RoleActionTypes.ROLE_EDIT_REQUEST),
    tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_EDIT_REQUEST}: ` + JSON.stringify(action))),
    switchMap(action => {
      return this.roleService.edit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The role has been updated.'
            })
        ),
        catchError(err => this.responseError(err, action))
      );
    })
  );

  @Effect()
  getRoleById$ = this.actions$.pipe(
    ofType<RoleByIdRequestAction>(RoleActionTypes.ROLE_GET_BY_ID_REQUEST),
    tap(action => {
      this.logger.debug('@Effect Role By Id Load: ' + JSON.stringify(action.payload));
    }),
    mergeMap(action =>
      this.roleService.getRoleById(action.payload).pipe(
        map(roleView => {
          return new RoleByIdResponseAction(roleView);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  getRoleAll$ = this.actions$.pipe(
    ofType<RoleAllRequestAction>(RoleActionTypes.ROLE_ALL_REQUEST_ACTION),
    tap(action => {
      this.logger.debug('@Effect Role All Load: ' + JSON.stringify(action.type));
    }),
    mergeMap(() =>
      this.roleService.getRoleAll().pipe(
        map(roleView => {
          return new RoleAllResponseAction(roleView);
        }),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  private responseError(err, action) {
    return err && err.error && ['02011', '02012'].includes(err.error.code)
      ? of(
          new RoleResponseErrorAction({
            ...err.error,
            duplicateName: action.payload.name
          })
        )
      : err && err.error && err.error.code === '00001'
      ? of(new LayoutActionVersionError(true))
      : of(new LayoutActionLoadError(err));
  }

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
