<div [formGroup]="parentForm">
  <div formGroupName="promotionDetails">
    <div class="row">
      <div class="col-md-6">
        <label for="promotionName">Promotion Name<span class="text-danger">*</span></label>
        <input
          id="promotionName"
          name="promotionName"
          class="form-control"
          maxlength="100"
          formControlName="promotionName"
          placeholder="Promotion Name"
          type="text"
          trim="blur"
          [ngClass]="{
            'is-invalid':
              (saveDraft && form.controls.promotionName.errors) || (submitted && form.controls.promotionName.errors)
          }"
        />
        <div
          *ngIf="(saveDraft && form.controls.promotionName.errors) || (submitted && form.controls.promotionName.errors)"
          class="invalid-feedback"
        >
          <div *ngIf="form.controls.promotionName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="input-label blank" *ngIf="this.canEdit"></div>
      </div>

      <div class="col-md-6">
        <label for="theme">Theme<span class="text-danger">*</span></label>
        <ng-select
          formControlName="theme"
          placeholder="Please select..."
          id="theme"
          [items]="listTheme"
          (change)="onChangeTheme($event)"
          [typeahead]="themeSearchInput$"
          [loading]="themeSearchLoading"
          bindLabel="name"
          [ngClass]="{
            'is-invalid': submitted && form.controls.theme.errors
          }"
        >
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div>
              <strong>Theme:</strong>
              <span [ngOptionHighlight]="search" class="ml-1">
                {{ item.name }}
              </span>
            </div>
            <small>
              <strong>Effective Date/Time:</strong
              ><span [ngOptionHighlight]="search" class="ml-1">{{
                item.effectiveDate | dateDisplay: dateTimeDisplay
              }}</span
              >{{ ' | ' }} <strong>Expire Date/Time:</strong
              ><span [ngOptionHighlight]="search" class="ml-1">{{
                item.expireDate | dateDisplay: dateTimeDisplay
              }}</span>
            </small>
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.controls.theme.errors" class="invalid-feedback">
          <div *ngIf="form.controls.theme.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
          <div *ngIf="form.controls.theme.errors.isEffectiveUnder">
            {{ 'ERRORS.THEME_INVALID' | translate }}
          </div>
        </div>
        <div class="input-label ">
          <div></div>
          <a *ngIf="this.canEdit" class="btn-text-link" (click)="gotoTheme()">+Add Theme</a>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label>Effective Date/Time<span class="text-danger">*</span></label>
            <div class="row">
              <div class="col-md-6 mb-3">
                <input
                  id="effectiveDate"
                  type="text"
                  placeholder="{{ dateFormat }}"
                  class="form-control calendar"
                  formControlName="effectiveDate"
                  readonly
                  placement="bottom"
                  bsDatepicker
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false
                  }"
                  [ngClass]="{
                    'is-invalid': submitted && form.controls.effectiveDate.errors
                  }"
                />
                <label for="effectiveDate" class="icon-calendar btn-calendar"></label>
                <div *ngIf="submitted && form.controls.effectiveDate.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.effectiveDate.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="form.controls.effectiveDate.errors.invalid">
                    {{ 'ERRORS.INVALID_DATATIME' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <ng-select
                  id="effectiveTime"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="effectiveTime"
                  [items]="timeRange"
                  bindLabel="label"
                  bindValue="value"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label>Expire Date/Time<span class="text-danger">*</span></label>
            <div class="row">
              <div class="col-md-6 mb-3">
                <input
                  id="expireDate"
                  type="text"
                  placeholder="{{ dateFormat }}"
                  readonly
                  class="form-control calendar"
                  formControlName="expireDate"
                  placement="bottom"
                  bsDatepicker
                  [bsConfig]="{
                    containerClass: 'theme-dark-blue',
                    dateInputFormat: dateFormat,
                    showWeekNumbers: false
                  }"
                  [ngClass]="{
                    'is-invalid': submitted && form.controls.expireDate.errors
                  }"
                />
                <label for="expireDate" class="icon-calendar btn-calendar"></label>
                <div *ngIf="submitted && form.controls.expireDate.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.expireDate.errors.required">
                    {{ 'ERRORS.REQUIRED' | translate }}
                  </div>
                  <div *ngIf="form.controls.expireDate.errors.invalid">
                    {{ 'ERRORS.INVALID_DATATIME' | translate }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <ng-select
                  id="expireTime"
                  [searchable]="false"
                  [clearable]="false"
                  formControlName="expireTime"
                  [items]="timeRange"
                  bindLabel="label"
                  bindValue="value"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="promotionType">Promotion Type<span class="text-danger">*</span></label>
            <ng-select
              id="promotionType"
              #promotionType
              formControlName="promotionType"
              placeholder="Please select..."
              (change)="onChangePromotionType(promotionType)"
              [items]="promotionTypeList"
              [searchable]="false"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'is-invalid': submitted && form.controls.promotionType.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && form.controls.promotionType.errors" class="invalid-feedback">
              <div *ngIf="form.controls.promotionType.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="forceQuantity">Force Buy Quantity<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0'"
              [decimals]="1"
              [min]="1"
              [negativeSign]="false"
              id="forceQuantity"
              placeholder="Force Buy Quantity"
              formControlName="forceBuyQty"
              maxlength="3"
              [ngClass]="{
                'is-invalid': submitted && form.controls.forceBuyQty.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.forceBuyQty.errors" class="invalid-feedback">
              <div *ngIf="form.controls.forceBuyQty.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="form.controls.forceBuyQty.errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
              </div>
              <div *ngIf="form.controls.forceBuyQty.errors.isLessThan">
                {{ 'ERRORS.MIN_VALUE' | translate: '{label:"quantity", value: 2 }' }}
              </div>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="limitPerBill">Limit Per Bill<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="0"
              [format]="'0,0'"
              [decimals]="0"
              [negativeSign]="false"
              id="limitPerBill"
              placeholder="Limit Per Bill"
              formControlName="limitPerBill"
              maxlength="3"
              [ngClass]="{
                'is-invalid': submitted && form.controls.limitPerBill.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.limitPerBill.errors" class="invalid-feedback">
              <div *ngIf="form.controls.limitPerBill.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="promotionBy">Promotion By<span class="text-danger">*</span></label>
            <ng-select
              id="promotionBy"
              placeholder="Please select..."
              [items]="promotionByList"
              [searchable]="false"
              formControlName="promotionBy"
              [clearable]="false"
              bindLabel="label"
              bindValue="value"
              [ngClass]="{
                'is-invalid': submitted && form.controls.promotionBy.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && form.controls.promotionBy.errors" class="invalid-feedback">
              <div *ngIf="form.controls.promotionBy.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="referenceCode">Reference Promotion Code<span class="text-danger">*</span></label>
            <input
              id="referenceCode"
              name="referenceCode"
              class="form-control"
              maxlength="20"
              formControlName="refPromotionCode"
              placeholder="Reference Promotion Code"
              type="text"
              [ngClass]="{
                'is-invalid': submitted && form.controls.refPromotionCode.errors
              }"
            />
            <div *ngIf="submitted && form.controls.refPromotionCode.errors" class="invalid-feedback">
              <div *ngIf="form.controls.refPromotionCode.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="theme">Supplier Name<span class="text-danger">*</span></label>
        <ng-select
          [items]="supplierList | async"
          placeholder="Please select..."
          notFoundText="No Data"
          [virtualScroll]="true"
          formControlName="supplier"
          bindLabel="supplierName"
          dropdownPosition="auto"
          labelForId="album"
          [loading]="supplierSearchLoading"
          [typeahead]="supplierSearchInput$"
          [ngClass]="{ 'is-invalid': submitted && form.controls.supplier.errors }"
        >
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div>
              <strong>Supplier Name:</strong>
              <span [ngOptionHighlight]="search" class="ml-1">
                {{ item.supplierName }}
              </span>
            </div>
            <small>
              <strong>Vendor No:</strong><span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span
              >{{ ' | ' }} <strong>Branch No:</strong
              ><span [ngOptionHighlight]="search" class="ml-1">{{ item.branchNo }}</span>
            </small>
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.controls.supplier.errors?.required" class="invalid-feedback">
          <div *ngIf="form.controls.supplier.errors?.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-handle-theme #handleTheme></app-handle-theme>
