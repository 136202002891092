import { OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { RouteLinkTab } from '../shared/models';
import { AppStates } from '../shared/store/state/app.states';
import { BaseComponent } from './base.component';

export abstract class BaseSearchComponent<T, T2, T3> extends BaseComponent implements OnInit, OnDestroy {
  criteriaObject: T;
  resultList$: Observable<T2[]>;
  listState$: Observable<T3>;
  listRoute: Array<RouteLinkTab>;

  searchForm: FormGroup;
  currentPage: number;
  pageSize: number;
  minDate: Date;
  maxDate: Date;
  isShowAdvanceSearch: boolean;
  public environment: { [key: string]: any };

  protected constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected listResultSelector,
    protected listStateSelector
  ) {
    super(store, modalService);
    this.environment = environment;
  }

  ngOnInit() {
    this.listRoute = [];
    this.createForm();
    this.setInitialValue();
    this.setInitialCriteriaObject();
    this.setFirstPage();
    this.setRouteTab();
    // api
    this.doSearch(this.criteriaObject);
    this.resultList$ = this.store.select(this.listResultSelector);
    this.listState$ = this.store.select(this.listStateSelector);

    this.doInit();
  }

  ngOnDestroy() {
    this.doDestroy();
  }

  abstract doInit();

  abstract doDestroy();

  abstract createForm();

  abstract onAdvanceSubmit();

  abstract prepareSearchCriteriaTags();

  abstract search(criteriaObj);

  abstract goToView(viewParams?: any);

  abstract setRouteTab();

  onSubmit() {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      ...(this.searchForm.value.searchCriteria && { searchCriteria: this.searchForm.value.searchCriteria }),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialValue() {
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      ...({} as T),
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  clearSearchText() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.doSearch(this.criteriaObject);
    }
  }

  clearDate() {
    this.setFirstPage();
    this.searchForm.controls['dateFrom'].reset();
    this.searchForm.controls['dateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      dateFrom: null,
      dateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['dateFrom'].reset();
    this.searchForm.controls['dateTo'].reset();
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      dateFrom: null,
      dateTo: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  doSearch(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.search(criteriaObj);
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }
}
