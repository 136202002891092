import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Menu, SubmenusEntity } from '../../models/menu.model';
import { selectMenus } from '../../store/selectors/menu.selector';
import { AppStates } from '../../store/state/app.states';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: [
    trigger('slide', [
      state('up', style({ height: 0 })),
      state('down', style({ height: '*' })),
      transition('up <=> down', animate(200))
    ])
  ]
})
export class SidebarComponent implements OnInit {
  menuGroup: MenuGroup[] = [];
  allMenus: Menu[] = [];
  selectedSubMenu: Menu;
  currentPath: string;

  constructor(
    public sidebarService: SidebarService,
    private readonly store: Store<AppStates>,
    private router: Router
  ) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     let url = event.url;
    //     if (event.url.indexOf('/') > -1) {
    //       const urlPath = event.url.split('/');
    //       url = urlPath[urlPath.length - 1];
    //     }
    //     this.currentPath = url;
    //   }
    // });
  }

  ngOnInit() {
    this.menuGroup = [
      { group: 'HOME' },
      { group: 'STORE', title: 'Store Management' },
      { group: 'ADMIN', title: 'Back Office' }
    ];
    this.store.select(selectMenus).subscribe(menus => {
      this.allMenus = menus;
    });
  }

  getMenuByGroup(group: string) {
    return this.allMenus.filter(menu => {
      return menu.group === group;
    });
  }

  toggle(currentMenu) {
    this.allMenus.forEach(element => {
      if (element === currentMenu) {
        currentMenu.active = !currentMenu.active;
      } else {
        element.active = false;
      }
    });
  }

  selectSubMenu(currentSubMenu: Menu) {
    this.selectedSubMenu = currentSubMenu;
  }

  isMenuActive(link: string): boolean {
    return this.router.url.search(link) !== -1;
  }

  isSubmenuActive(submenu: SubmenusEntity): boolean {
    const regex = /([^:\/\?]+)(\?.*|$)/;
    return submenu.sublinks && submenu.sublinks.includes(regex.exec(this.router.url)[1]);
  }

  getState(currentMenu) {
    if (currentMenu.active) {
      return 'down';
    } else {
      return 'up';
    }
  }
}

export class MenuGroup {
  title?: string;
  group: string;
}
