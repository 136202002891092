import { RequestProductErrorEnum } from '../enum/request-step.enum';
import { BarcodeResponse } from './barcode.model';
import { Sort } from './pagination.model';

export class StoreAssortmentRequestListSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  page: number;
  size: number;
}

export interface StoreAssortmentRequestList {
  id: string;
  requestNo: string;
  noOfStore: number;
  noOfAdd: number;
  noOfDelete: number;
  requestedBy: string;
  createdDate: string;
  status: string;
}

export class StoreAssortmentRequestListResponse {
  id: number;
  content: StoreAssortmentRequestList[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export class StoreAssortmentItem {
  articleNo: string;
  barcode: string;
  productName: string;
  productGrading: string;
  productDisplayName: string;
  productLocation: string;
  productTier: string;
  unit: string;
  unitFactor: number;
  plg?: string;
  facingQty: number;
  stackingQty: number;
  depthQty: number;
  minBeautyQty: number;
  classCode: string;
  editType: string;

  public static mappingBarcodeResponseToStoreAssortmentItem(response: BarcodeResponse): StoreAssortmentItem {
    return {
      articleNo: response.articleNo,
      classCode: response.classCode,
      barcode: response.barcode,
      productName: response.productName,
      productDisplayName: response.productDisplayName,
      productGrading: response.productGrading,
      productLocation: response.productLocation,
      productTier: response.productTier,
      unit: response.unit,
      unitFactor: response.unitFactor,
      plg: response.productLocation,
      facingQty: null,
      stackingQty: null,
      depthQty: null,
      minBeautyQty: null,
      editType: null
    };
  }
}

export class StoreAssortmentRequestNo {
  storeAssortmentRequestNo: string;
}

export enum StoreAssortmentTabs {
  SEARCH_RESULT = 'SEARCH_RESULT',
  SELECTED = 'SELECTED'
}

export enum StoreAssortmentSteps {
  CREATE = 'CREATE',
  CONFIRM = 'CONFIRM'
}

export class StoreAssortmentRequestViewResponse {
  id?: string;
  requestNo?: string;
  noOfStore?: number;
  noOfAdd?: number;
  noOfDelete?: number;
  createdBy?: string;
  createdDate?: string;
  status?: string;
  items: StoreAssortmentRequestItem[];
}

export class StoreAssortmentRequestItem {
  storeNo: string;
  storeCode: string;
  storeName: string;
  storeItems: StoreAssortmentRequestStoreItem[];

  public static mappingDataToStoreAssortmentRequestItem(response: any): StoreAssortmentRequestItem {
    return {
      storeNo: response.storeNo,
      storeCode: response.storeCode,
      storeName: response.storeName,
      storeItems: null
    };
  }
}

export class StoreAssortmentImportItem {
  articleNo: string;
  barcode: string;
  depthQty: number;
  editType: string;
  facingQty: number;
  minBeautyQty: number;
  classCode: string;
  productDisplayName: string;
  productGrading: string;
  productLocation: string;
  productName: string;
  productTier: string;
  stackingQty: number;
  unit: string;
  unitFactor: number;
  errorMessage: RequestProductErrorEnum;
}

export class StoreAssortmentRequestStoreItem {
  storeAssortmentType: string;
  itemNo?: number;
  barcode: string;
  articleNo: string;
  productName: string;
  productDisplayName: string;
  unit: string;
  unitFactor: number;
  productLocation: string;
  productTier: string;
  productGrading: string;
  editType: string;
  facingQty: number;
  stackingQty: number;
  depthQty: number;
  minBeautyQty: number;
  note?: string;
  errorStatus?: boolean;
  storeNo?: string;
  storeCode?: string;
  storeName?: string;

  public static mappingDataToStoreAssortmentRequestStoreItem(
    response: any,
    index: number
  ): StoreAssortmentRequestStoreItem {
    return {
      storeAssortmentType: response.storeAssortmentType,
      itemNo: response.itemNo || index + 1,
      barcode: response.barcode,
      articleNo: response.articleNo,
      productName: response.productName,
      productDisplayName: response.productDisplayName,
      unit: response.unit,
      unitFactor: response.unitFactor,
      productLocation: response.productLocation,
      productTier: response.productTier,
      productGrading: response.productGrading,
      editType: response.editType,
      facingQty: response.facingQty,
      stackingQty: response.stackingQty,
      depthQty: response.depthQty,
      minBeautyQty: response.minBeautyQty,
      note: response.note,
      errorStatus: response.errorStatus
    };
  }
}
