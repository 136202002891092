import { createSelector } from '@ngrx/store';
import * as fromOrderSelectItemState from '../reducers/order-select-item.reducers';
import { AppStates } from '../state/app.states';

const selectorderSelectItemState = (state: AppStates) => state.orderSelectItem;

export const selectAllOrderSelectItemList = createSelector(
  selectorderSelectItemState,
  fromOrderSelectItemState.selectAll
);

export const selectOrderSelectItemList = createSelector(
  selectorderSelectItemState,
  (state: fromOrderSelectItemState.OrderSelectItemState) => state
);
