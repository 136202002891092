import { createSelector } from '@ngrx/store';
import * as fromMerchantRequestState from '../reducers/merchant-request.reducers';
import { AppStates } from '../state/app.states';

const selectMerchantRequestState = (state: AppStates) => state.merchantRequest;

export const selectAllMerchantRequestList = createSelector(
  selectMerchantRequestState,
  fromMerchantRequestState.selectAll
);

export const selectMerchantRequestList = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => state
);

export const selectMerchantRequest = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => state.selected
);

export const selectMerchantRequestListCriteria = createSelector(selectMerchantRequestState, state => {
  return state.criteriaObject;
});

export const selectMerchantDeleteStatus = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => {
    return state.isDeleteSuccess;
  }
);

export const selectMerchantStoreProfile = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => {
    return state.selected && state.selected.merchantInfo && state.selected.merchantInfo.storeProfile
      ? state.selected.merchantInfo.storeProfile
      : [];
  }
);

export const selectMerchantRequestHistory = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => state.auditLogs
);

export const selectMerchantFirstLotOrder = createSelector(
  selectMerchantRequestState,
  (state: fromMerchantRequestState.MerchantRequestState) => state.selected.firstLotPo
);
