import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PriceSetting, Setting } from '../../models';
import { SettingActions, SettingActionTypes } from '../actions/setting.actions';

export interface SettingState extends EntityState<Setting> {
  isLoading: boolean;
  priceSetting: PriceSetting;
}

export const adapter: EntityAdapter<Setting> = createEntityAdapter<Setting>();

export const initialSettingState: SettingState = adapter.getInitialState({
  isLoading: false,
  priceSetting: null
});

export function settingReducers(state = initialSettingState, action: SettingActions): SettingState {
  switch (action.type) {
    case SettingActionTypes.PRICE_SETTING_SUCCESS:
      return {
        ...state,
        priceSetting: action.payload.priceSetting
      };
    case SettingActionTypes.xxx:
      return {
        ...state,
        priceSetting: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
