import { createSelector } from '@ngrx/store';
import * as fromZ8ResultResponseState from '../reducers/z8-result.reducers';
import { AppStates } from '../state/app.states';

const selectZ8ResultState = (state: AppStates) => state.z8Result;

export const selectAllZ8Result = createSelector(selectZ8ResultState, fromZ8ResultResponseState.selectAll);

export const selectZ8Result = createSelector(
  selectZ8ResultState,
  (state: fromZ8ResultResponseState.Z8ResultState) => state
);

export const selectZ8ResultHistory = createSelector(
  selectZ8ResultState,
  (state: fromZ8ResultResponseState.Z8ResultState) => {
    return state.auditLogs;
  }
);
