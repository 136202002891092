<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-product"></em>
    <span>{{ data.title }}</span>
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      ![
        assortmentPageModes.REQUEST_CREATE,
        assortmentPageModes.REQUEST_EDIT,
        assortmentPageModes.ASSORTMENT_EDIT
      ].includes(data.mode) &&
      assortment?.status === assortmentRequestStatusEnum.DRAFT &&
      (hasEditPermission || hasEditNonMerchantPermission)
    "
    (click)="onDeleteRequest(assortment.id)"
  >
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      ![
        assortmentPageModes.REQUEST_CREATE,
        assortmentPageModes.REQUEST_EDIT,
        assortmentPageModes.ASSORTMENT_EDIT
      ].includes(data.mode) &&
      assortment?.status === assortmentRequestStatusEnum.AWAITING_APPROVAL &&
      (hasEditPermission || hasEditNonMerchantPermission)
    "
    (click)="onCancelRequest()"
  >
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      [assortmentPageModes.ASSORTMENT_EDIT].includes(data.mode) &&
      assortment?.type !== 'NEW' &&
      hasEditPermission &&
      productDetailsForm?.controls.deliveryMethod.value === assortmentDeliveryMethodEnum.TD &&
      productDetailsForm?.controls.productType.value === productTypeEnum.INVENTORY
    "
    (click)="
      showLinkCJProductModal(
        assortment?.productDetail.tdProduct.supplierArticleNo,
        assortment?.productDetail.tdProduct.supplierProductName
      )
    "
  >
    <em class="icon-link"></em>
    Add/Delete CJ Product
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="tab-menu d-flex d-inline-flex">
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === assortmentPageTabs.PRODUCT_TAB }"
      (click)="onSelectTabs(assortmentPageTabs.PRODUCT_TAB)"
    >
      Product Details
    </a>

    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === assortmentPageTabs.SUPPLIER_TAB }"
      (click)="onSelectTabs(assortmentPageTabs.SUPPLIER_TAB)"
    >
      Supplier Details
    </a>

    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === assortmentPageTabs.PRICE_TAB }"
      (click)="onSelectTabs(assortmentPageTabs.PRICE_TAB)"
    >
      Wholesale/Retails Price
    </a>
    <a
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === assortmentPageTabs.CJ_PRODUCT_DETAILS_TAB }"
      (click)="onSelectTabs(assortmentPageTabs.CJ_PRODUCT_DETAILS_TAB)"
      *ngIf="useCJProduct"
    >
      CJ Product Details
    </a>
  </div>

  <!-- Header -->
  <div class="info-header-container" *ngIf="assortment?.status">
    <div *ngIf="assortment?.requestNo" class="info-header-item">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>
          {{ assortment?.requestNo }}
        </span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(assortment?.status).toLowerCase()" class="assortment-status">
          {{ 'PRODUCT_ASSORTMENT.STATUS.' + assortment?.status | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row">
    <div class="d-inline-flex mr-auto">
      <span class="heading-text" *ngIf="assortment?.articleNo">
        {{ assortment?.articleNo | dashDisplay }}{{ ': ' }}{{ assortment?.productName | dashDisplay }}
      </span>
    </div>
  </div>

  <div
    class="alert-danger mb-3"
    *ngIf="isRequestViewMode && listOfChange['supplierArticleNo'] && listOfChange['supplierArticleNo']['before']"
  >
    Deleted CJ Product Link | Article: {{ listOfChange['supplierArticleNo']['before'] }}
  </div>

  <div id="productDetailsTab" class="pb-5" *ngIf="currentTab === assortmentPageTabs.PRODUCT_TAB">
    <app-assortment-product-details
      [productDetailsForm]="productDetailsForm"
      [mode]="data.mode"
      [isRequestViewMode]="isRequestViewMode"
      [isSaveDraft]="isSaveDraft"
      [isSubmitted]="isSubmitted"
      [isNonMerchandise]="isNonMerchandise"
      [listOfValue]="listOfValue"
      [listOfChange]="listOfChange"
    >
    </app-assortment-product-details>
  </div>

  <div id="supplierDetailsTab" class="pb-5" *ngIf="currentTab === assortmentPageTabs.SUPPLIER_TAB">
    <app-supplier-details
      #supplierDetailsComponent
      [supplierDetailsForm]="supplierDetailsForm"
      [isSubmitted]="isSubmitted"
      [productDetailsForm]="productDetailsForm"
      [listOfChange]="listOfChange"
      [listOfValue]="listOfValue"
      [mode]="data.mode"
      [isRequestViewMode]="isRequestViewMode"
      [useCJProduct]="useCJProduct"
      [errorSupplierPriceDisplay]="errorSupplierPriceDisplay"
      [type]="assortment.type"
      (currentTab)="changeTab($event)"
    ></app-supplier-details>
  </div>

  <div id="priceTab" class="pb-5" *ngIf="currentTab === assortmentPageTabs.PRICE_TAB">
    <div class="row justify-content-between section-header">
      <span>Wholesale Price</span>
    </div>

    <app-assortment-wholesale-price
      [parentForm]="wholesalePriceForm"
      [mode]="data.mode"
      [isSubmitted]="isSubmitted"
      [isRequestViewMode]="isRequestViewMode"
    ></app-assortment-wholesale-price>

    <hr class="section-divider" />

    <div class="row justify-content-between section-header">
      <span>Retail Price</span>
    </div>

    <app-assortment-barcode
      [parentForm]="barcodeForm"
      [mode]="data.mode"
      [isSubmitted]="isSubmitted"
      [articleType]="data.articleType"
      [listOfValue]="listOfValue"
      [listOfChange]="listOfChange"
      [isRequestViewMode]="isRequestViewMode"
      [type]="assortment.type || null"
    >
    </app-assortment-barcode>
    <a
      class="btn-text-link mt-2"
      (click)="addBarcode()"
      *ngIf="
        [
          assortmentPageModes.REQUEST_CREATE,
          assortmentPageModes.REQUEST_EDIT,
          assortmentPageModes.ASSORTMENT_EDIT
        ].includes(data.mode)
      "
    >
      +Add Barcode
    </a>
  </div>
  <div
    id="cjProductDetailsTab"
    class="pb-5"
    *ngIf="currentTab === assortmentPageTabs.CJ_PRODUCT_DETAILS_TAB && useCJProduct"
  >
    <app-product-view-header
      [productMaster$]="productMaster$"
      [listOfChange]="listOfChange"
      [isRequestViewMode]="isRequestViewMode"
    ></app-product-view-header>

    <app-assortment-cj-product-details
      [groupingBarcodes]="assortment?.productDetail.cjProduct.groupingBarcodes"
      [supplierPrices]="assortment?.productDetail.cjProduct.cjSupplierPrices"
      [pickingUnit]="assortment?.productDetail.cjProduct.pickingUnit"
    ></app-assortment-cj-product-details>
  </div>

  <div class="fixed-row-bottom form-row">
    <div
      class="float-left"
      *ngIf="
        [assortmentPageModes.REQUEST_CREATE, assortmentPageModes.REQUEST_EDIT].includes(data.mode) &&
        assortment?.status === assortmentRequestStatusEnum.DRAFT
      "
    >
      <button type="button" id="btn-save" class="btn btn-secondary" appPreventDoubleClick (click)="onSave()">
        Save
      </button>
    </div>

    <div
      class="float-right"
      *ngIf="
        [
          assortmentPageModes.REQUEST_CREATE,
          assortmentPageModes.REQUEST_EDIT,
          assortmentPageModes.ASSORTMENT_EDIT
        ].includes(data.mode)
      "
    >
      <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCloseFullModal()">
        Cancel
      </button>
      <button type="button" id="btn-submit" class="btn btn-primary" appPreventDoubleClick (click)="onSubmit()">
        Submit
      </button>
    </div>

    <div
      class="float-right"
      *ngIf="[assortmentPageModes.REQUEST_VIEW, assortmentPageModes.ASSORTMENT_VIEW].includes(data.mode)"
    >
      <button
        *ngIf="
          (assortment?.status === assortmentRequestStatusEnum.DRAFT ||
            assortment?.status === assortmentRequestStatusEnum.AWAITING_APPROVAL ||
            data.mode === assortmentPageModes.ASSORTMENT_VIEW) &&
          (hasEditPermission || hasEditNonMerchantPermission)
        "
        type="button"
        id="btn-edit"
        class="btn btn-primary mr-3"
        appPreventDoubleClick
        (click)="toggleToEdit()"
      >
        Edit
      </button>

      <button
        *ngIf="
          assortment?.status === assortmentRequestStatusEnum.AWAITING_APPROVAL &&
          (hasApproveMerchantPermission || hasApproveNonMerchantPermission)
        "
        type="button"
        class="btn btn-special-reject mr-3"
        id="btn-reject"
        appPreventDoubleClick
        (click)="reject()"
      >
        Reject
      </button>
      <button
        *ngIf="
          assortment?.status === assortmentRequestStatusEnum.AWAITING_APPROVAL &&
          (hasApproveMerchantPermission || hasApproveNonMerchantPermission)
        "
        type="button"
        class="btn btn-special-approve mr-3"
        id="btn-approve"
        appPreventDoubleClick
        (click)="approve()"
      >
        Approve
      </button>
    </div>
  </div>
</div>
