import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PurchaseRequestItem } from '../../models/purchase-request.model';
import { TdAssortmentOrderActions, TdAssortmentOrderActionTypes } from '../actions/td-assortment-order.actions';

export interface TdAssortmentOrderState extends EntityState<PurchaseRequestItem> {
  isLoading: boolean;
}

export const adapter: EntityAdapter<PurchaseRequestItem> = createEntityAdapter<PurchaseRequestItem>({
  selectId: (tdAssortmentContent: PurchaseRequestItem) =>
    `${tdAssortmentContent.supplierCode}_${tdAssortmentContent.barcode}`
});

export const initialTdAssortmentOrderState: TdAssortmentOrderState = adapter.getInitialState({
  isLoading: false
});

export function tdAssortmentOrderReducers(
  state = initialTdAssortmentOrderState,
  action: TdAssortmentOrderActions
): TdAssortmentOrderState {
  switch (action.type) {
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ITEM:
      return adapter.upsertOne(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_UPDATE_ITEM:
      return adapter.updateOne(
        {
          id: `${action.payload.supplierCode}_${action.payload.barcode}`,
          changes: { ...action.payload }
        },
        {
          ...state
        }
      );
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ALL_ITEM:
    case TdAssortmentOrderActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST:
      return adapter.upsertMany(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_INSERT_ALL_ITEM:
      return adapter.addAll(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_REMOVE_ITEM:
      return adapter.removeOne(action.payload, {
        ...state
      });
    case TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_RESET:
      return adapter.removeAll({
        ...state
      });

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
