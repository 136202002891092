import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  ErrorResponse,
  MerchantRequestList,
  MerchantRequestListSearchCriteria,
  PortalModule,
  RouteLinkTab,
  TaskModuleUrl
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { MerchantRequestService } from '../../../shared/services/merchant-request.service';
import {
  MerchantDeleteRequestAction,
  MerchantDeleteResetAction,
  MerchantRequestHistoryRequestAction,
  MerchantRequestHistoryResponseAction,
  MerchantRequestListRequestAction
} from '../../../shared/store/actions/merchant.actions';
import { MerchantRequestState } from '../../../shared/store/reducers/merchant-request.reducers';
import {
  selectAllMerchantRequestList,
  selectMerchantDeleteStatus,
  selectMerchantRequestHistory,
  selectMerchantRequestList,
  selectMerchantRequestListCriteria
} from '../../../shared/store/selectors/merchant-request.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { merchantColorStatus } from '../../../shared/utils/merchant-color-status-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';
import { MerchantRequestComponent } from '../merchant-request/merchant-request.component';

@Component({
  selector: 'app-merchant-request-list',
  templateUrl: './merchant-request-list.component.html',
  styleUrls: ['./merchant-request-list.component.scss']
})
export class MerchantRequestListComponent extends BaseSearchComponent<
  MerchantRequestListSearchCriteria,
  MerchantRequestList,
  MerchantRequestState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public dateFormat = environment.dateFormat;
  public listRoute: Array<RouteLinkTab>;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public typeTag: string;
  public typeStringTag: string;

  public page: TDStorePage;
  public type: RequestTypeEnum;

  public merchantStatusList = filterDropdown.merchantRequestStatusFilter;
  public merchantRequestTypeList = filterDropdown.requestTypeFilter;

  public auditLogs$: Observable<AuditLog[]>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected readonly translate: TranslateService,
    private readonly merchantRequestService: MerchantRequestService
  ) {
    super(store, modalService, selectAllMerchantRequestList, selectMerchantRequestList);
  }

  doInit() {
    this.page = TDStorePage.MERCHANT_REQUEST;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectMerchantRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
    this.localStore.pipe(select(selectMerchantDeleteStatus)).subscribe(isDeleteSuccess => {
      if (isDeleteSuccess) {
        this.alertSuccessModal('The request has been deleted.');
        this.doSearch(this.criteriaObject);
        this.store.dispatch(new MerchantDeleteResetAction());
      }
    });
    this.auditLogs$ = this.localStore.pipe(select(selectMerchantRequestHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.merchantStatusList[0].value],
      startRequestDate: [null],
      endRequestDate: [null],
      type: [null]
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['startRequestDate'].reset();
    this.searchForm.controls['endRequestDate'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startRequestDate: null,
      endRequestDate: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterType() {
    this.setFirstPage();
    this.searchForm.controls['type'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      type: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startRequestDate'].reset();
    this.searchForm.controls['endRequestDate'].reset();
    this.searchForm.controls['type'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startRequestDate: null,
      endRequestDate: null,
      type: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.startRequestDate && !formValue.endRequestDate && !formValue.type) {
      return;
    }

    let dateFrom = this.searchForm.value.startRequestDate;
    let dateTo = this.searchForm.value.endRequestDate;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      startRequestDate: dateFrom,
      endRequestDate: dateTo,
      type: formValue.type && formValue.type.length > 0 ? formValue.type.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.typeTag = null;
    this.typeStringTag = null;

    const startRequestDate = dateStringToTagCriteria(this.criteriaObject.startRequestDate);
    const endRequestDate = dateStringToTagCriteria(this.criteriaObject.endRequestDate);
    const requestDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: startRequestDate,
      dateTo: endRequestDate
    });

    this.dateStringTag = requestDate.dateStringTag;
    this.dateTag = requestDate.dateTag;

    if (this.criteriaObject.type && this.criteriaObject.type.length > 0) {
      this.typeStringTag = 'Request Type';

      const types = this.merchantRequestTypeList
        .filter(data => {
          return this.criteriaObject.type.indexOf(data.value) > -1;
        })
        .map(status => status.label)
        .join(', ');

      this.typeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new MerchantRequestListRequestAction(criteriaObj));
  }

  goToCreate() {
    const mode = RequestPageModesEnum.REQUEST_CREATE;
    const initialState = {
      title: null,
      childItem: new ChildItem(
        MerchantRequestComponent,
        {
          title: MerchantRequestListComponent.getMerchantRequestTitle(mode),
          mode,
          merchantRequestId: null
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToView(item) {
    const mode = RequestPageModesEnum.REQUEST_VIEW;
    const initialState = ModuleUtil.InitialState(
      item,
      MerchantRequestComponent,
      mode,
      MerchantRequestListComponent.getMerchantRequestTitle(mode),
      TaskModuleUrl.MERCHANT_REQUEST,
      PortalModule.MERCHANT
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  goToEdit(item) {
    const mode = RequestPageModesEnum.REQUEST_EDIT;
    const initialState = ModuleUtil.InitialState(
      item,
      MerchantRequestComponent,
      mode,
      MerchantRequestListComponent.getMerchantRequestTitle(mode),
      TaskModuleUrl.MERCHANT_REQUEST,
      PortalModule.MERCHANT
    );

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission([
      'merchant_v',
      'merchant_vs',
      'merchant_key',
      'merchant_edit_m'
    ]);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'merchant_new_m',
      'merchant_edit_m',
      'merchant_order_m',
      'merchant_app'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Merchant List', url: '/merchant-store/merchant-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Merchant Request', url: '/merchant-store/merchant-request-list' });
    }
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  cancelMerchantRequest(step: RequestStepEnum, requestNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.merchantRequestService
            .approveRequest({
              requestNo,
              status: ApproveStatusEnum.CANCELLED,
              step,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
                this.doSearch(this.criteriaObject);
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  deleteMerchantRequest(id) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new MerchantDeleteRequestAction({ merchantRequestId: id }));
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.store.dispatch(new MerchantRequestListRequestAction(this.criteriaObject));

      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  showHistory(merchantRequestId: string, requestNo: string) {
    this.store.dispatch(new MerchantRequestHistoryRequestAction({ merchantRequestId }));
    const initialState = {
      title: 'History',
      historyHeader: `Request No.: ${requestNo}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.MERCHANT_REQUEST,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new MerchantRequestHistoryResponseAction({ auditLogs: null }));
    });
  }

  getColorStatus(status: string): string {
    return merchantColorStatus(status);
  }

  hasEditPermission(type: RequestTypeEnum, step: RequestStepEnum, status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(type, this.page, step, status);
  }

  hasDeletePermission(type: RequestTypeEnum, step: RequestStepEnum, status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasDeletePermission(type, this.page, step, status);
  }

  hasCancelPermission(status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasCancelPermission(status);
  }

  hasViewHistoryPermission(status: RequestStatusEnum): boolean {
    return this.tdStoreWorkflowUtil.hasViewHistoryPermission(status);
  }

  static getMerchantRequestTitle(mode: RequestPageModesEnum) {
    let title: string;
    switch (mode) {
      case RequestPageModesEnum.REQUEST_CREATE:
        title = 'Create Merchant Request';
        break;
      case RequestPageModesEnum.REQUEST_VIEW:
        title = 'View Merchant Request';
        break;
      case RequestPageModesEnum.REQUEST_EDIT:
        title = 'Edit Merchant Request';
        break;
      default:
      // return null
    }
    return title;
  }
}
