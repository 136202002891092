import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { ICustomFile } from '../../../pages/purchase/purchase-request-file-upload/purchase-request-file-upload.component';

@Component({
  selector: 'app-files-input',
  templateUrl: './files-input.component.html',
  styleUrls: ['./files-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FilesInputComponent),
      multi: true
    }
  ],
  exportAs: 'FilesInputComponent'
})
export class FilesInputComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  constructor(
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly logger: NGXLogger
  ) {
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;
  @Output() clearInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() showErrorMessage: EventEmitter<string> = new EventEmitter<string>();
  @Output() submit: EventEmitter<Array<File>> = new EventEmitter<Array<File>>();
  @Input() size: number;
  @Input() controlName: string;
  @Input() disabled: boolean;
  @Input() fileTypeErrorTxt = 'Incorrect Format (allow only format file .xlsx).';
  @Input() fileSizeErrorTxt = `File size limit exceeded. <br/><br/>`;

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = [];
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    this.ngChange(this.value);
    this.ngTouched();

    return (files: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const f of files) {
        fileArr.push(f);
      }

      fn(fileArr);
    };
  }

  validate(c: AbstractControl) {
    if (!c.value || !c.value.length || c.disabled) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const f of c.value) {
      if (this.size && this.size < f.size) {
        f.errors = { ...f.errors, fileSize: true };
        errors = { ...errors, fileSize: true };
      }

      const extP = new RegExp('.(xlsx)', 'ig');
      const typeP = new RegExp('^application/vnd.openxmlformats-.*$', 'ig');

      const fileErrors = {
        ...(!extP.test(f.name) && {
          fileExt: true
        }),
        ...(f.type &&
          !typeP.test(f.type) && {
            fileType: true
          })
      };

      errors = {
        ...errors,
        ...fileErrors
      };

      f.errors = {
        ...f.errors,
        ...fileErrors
      };
    }

    this.fileList = c.value;

    if (Object.keys(errors).length) {
      const isFileTypeError = errors['fileType'] || errors['fileExt'];
      const isFileSizeError = errors['fileSize'];
      this.onShowErrorMessage(isFileTypeError, isFileSizeError);
      this.controlContainer.control.get(this.controlName).setErrors(errors);
      return errors;
    } else {
      this.submit.emit(c.value);
    }
  }

  onClickDelete() {
    this.ngChange(this.value);
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue(null);
    this.clearInput.emit();
  }

  onShowErrorMessage(isFileTypeError, isFileSizeError) {
    this.showErrorMessage.emit(isFileTypeError ? this.fileTypeErrorTxt : this.fileSizeErrorTxt);
    this.logger.debug('alertFailModal->isFileSizeError', isFileSizeError);
  }
}
