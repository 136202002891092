<div class="admin-form">
  <ng-container *ngIf="data?.length == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data?.length > 0">
    <table id="datatable" class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <th class="w-10 text-center align-top">Store</th>
          <th class="w-5 text-center align-top">No.</th>
          <th class="w-10 text-center align-top">Barcode</th>
          <th class="w-20 text-center align-top">Product Name</th>
          <th class="w-5 text-center align-top">Unit</th>
          <th class="text-center align-top">Unit Factor</th>
          <th class="w-5 text-center align-top">PLG</th>
          <th class="w-5 text-center align-top">Tier</th>
          <th class="w-5 text-center align-top">Grading</th>
          <th class="text-center align-top">Edit Type</th>
          <th class="w-5 text-center align-top">Facing</th>
          <th class="w-5 text-center align-top">Stacking</th>
          <th class="w-5 text-center align-top">Depth</th>
          <th class="text-center align-top">Min Beauty</th>
          <th class="text-center" *ngIf="mode === requestPageModes.REQUEST_CREATE"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let item of paging.currentPageData; index as i"
          [ngClass]="{
            'error-background': item.errorStatus
          }"
        >
          <td
            *ngIf="getStoreData(item.storeNo, paging.currentPageData)[0].barcode === item.barcode"
            [rowSpan]="getStoreData(item.storeNo, paging.currentPageData).length"
          >
            {{ item.storeCode + '-' + item.storeName }}
          </td>

          <td class="text-center">{{ getItemNo(item) }}</td>
          <td class="text-center">{{ item.barcode }}</td>
          <td>
            {{ item.productName }}
            <span class="d-block">{{ item.articleNo }}</span>
            <span
              *ngIf="item.note"
              class="d-block note invalid-feedback"
              [ngClass]="getNoteColor(mode)"
              [class.text-danger]="item.errorStatus"
              >{{ item.note }}</span
            >
          </td>
          <td class="text-center">{{ item.unit }}</td>
          <td class="text-center">{{ item.unitFactor }}</td>
          <td class="text-center">{{ item.productLocation }}</td>
          <td class="text-center">{{ item.productTier }}</td>
          <td class="text-center">{{ item.productGrading }}</td>
          <td class="text-center">{{ 'STORE_ASSORTMENT_REQUEST.EDIT_TYPE.' + item.editType | translate }}</td>
          <td class="text-center">{{ item.facingQty | numberFormatDisplay: 0:'' }}</td>
          <td class="text-center">{{ item.stackingQty | numberFormatDisplay: 0:'' }}</td>
          <td class="text-center">{{ item.depthQty | numberFormatDisplay: 0:'' }}</td>
          <td class="text-center">{{ item.minBeautyQty | numberFormatDisplay: 0:'' }}</td>
          <td class="text-center" *ngIf="mode === requestPageModes.REQUEST_CREATE">
            <a
              class="is-link text-muted"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
              (click)="deleteItem(i)"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <app-ui-pagination
      #paging
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [items]="data"
      [valueChangeDetector]="data.length"
    >
    </app-ui-pagination>
  </ng-container>
</div>
