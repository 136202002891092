import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { RequestStatusEnum } from '../../enum/request-status.enum';
import { RequestStepEnum } from '../../enum/request-step.enum';
import { ValidateStoreEnum } from '../../enum/validate-store.enum';
import { MerchantRequestService } from '../../services/merchant-request.service';
import { MerchantService } from '../../services/merchant.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  FirstLotOrderValidateSubmitRequestAction,
  MerchantActionType,
  MerchantApproveErrorAction,
  MerchantApproveRequestAction,
  MerchantApproveResponseAction,
  MerchantByIdRequestAction,
  MerchantByIdResponseAction,
  MerchantCreateSaveErrorAction,
  MerchantCreateSaveRequestAction,
  MerchantCreateSaveResponseAction,
  MerchantCreateSubmitErrorAction,
  MerchantCreateSubmitRequestAction,
  MerchantCreateSubmitResponseAction,
  MerchantDeleteRequestAction,
  MerchantDeleteResponseAction,
  MerchantGetVersionRequestAction,
  MerchantGetVersionResponseAction,
  MerchantListHistoryRequestAction,
  MerchantListHistoryResponseAction,
  MerchantListRequestAction,
  MerchantListResponseAction,
  MerchantRequestByIdRequestAction,
  MerchantRequestByIdResponseAction,
  MerchantRequestHistoryRequestAction,
  MerchantRequestHistoryResponseAction,
  MerchantRequestListRequestAction,
  MerchantRequestListResponseAction
} from '../actions/merchant.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class MerchantEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly merchantRequestService: MerchantRequestService,
    private readonly merchantService: MerchantService,
    private readonly store$: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  merchantCreateSave$: Observable<Action> = this.actions$.pipe(
    ofType<MerchantCreateSaveRequestAction>(MerchantActionType.MERCHANT_CREATE_SAVE_REQUEST),
    map(action => {
      this.logger.debug('@Effect Merchant Create Save: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload =>
      this.merchantRequestService.saveRequest(payload).pipe(
        map(res => new MerchantCreateSaveResponseAction(res)),
        catchError(err => of(new MerchantCreateSaveErrorAction(err.error)))
      )
    )
  );

  @Effect()
  merchantCreateSubmit$: Observable<Action> = this.actions$.pipe(
    ofType<MerchantCreateSubmitRequestAction>(MerchantActionType.MERCHANT_CREATE_SUBMIT_REQUEST),
    map(action => {
      this.logger.debug('@Effect Merchant Create Submit: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload =>
      this.merchantRequestService.submitRequest(payload).pipe(
        map(res => new MerchantCreateSubmitResponseAction(res)),
        catchError(err => of(new MerchantCreateSubmitErrorAction(err.error)))
      )
    )
  );

  @Effect({ dispatch: false })
  merchantCreateSaveResponse$: Observable<Action> = this.actions$.pipe(
    ofType(MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE),
    tap((resp: any) => {
      this.logger.debug(
        `@Effect ${MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE}: ` + this.stringify(resp.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  merchantCreateSaveError$: Observable<Action> = this.actions$.pipe(
    ofType(MerchantActionType.MERCHANT_CREATE_SAVE_ERROR),
    tap((resp: any) => {
      this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_CREATE_SAVE_ERROR}: ` + this.stringify(resp.payload));
    })
  );

  @Effect({ dispatch: false })
  merchantCreateSubmitResponse$: Observable<Action> = this.actions$.pipe(
    ofType(MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE),
    tap((resp: any) => {
      this.logger.debug(
        `@Effect ${MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE}: ` + this.stringify(resp.payload)
      );
    })
  );

  @Effect({ dispatch: false })
  merchantCreateSubmitError$: Observable<Action> = this.actions$.pipe(
    ofType(MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR),
    tap((resp: any) => {
      this.logger.debug(
        `@Effect ${MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR}: ` + this.stringify(resp.payload)
      );
    })
  );

  @Effect()
  searchMerchant$ = this.actions$.pipe(
    ofType<MerchantListRequestAction>(MerchantActionType.MERCHANT_LIST_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_LIST_REQUEST}: ` + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantService.searchMerchantByCriteria(payload).pipe(
        map(response => {
          return new MerchantListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  searchMerchantRequest$ = this.actions$.pipe(
    ofType<MerchantRequestListRequestAction>(MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantRequestService.searchByCriteria(payload).pipe(
        map(merchantRequests => {
          return new MerchantRequestListResponseAction({ merchantRequests });
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getMerchantRequestById$ = this.actions$.pipe(
    ofType<MerchantRequestByIdRequestAction>(MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantRequestByIdRequestAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantRequestService.getMerchantRequest(payload).pipe(
        tap(response => {
          this.logger.debug('@Effect MerchantRequestByIdResponseAction: ' + this.stringify(response));
        }),
        map(merchantRequest => {
          if (
            merchantRequest.step === RequestStepEnum.FIRST_LOT_ORDER &&
            merchantRequest.status !== RequestStatusEnum.AWAITING_RESERVATION
          ) {
            return new FirstLotOrderValidateSubmitRequestAction({
              merchantRequestView: merchantRequest,
              validateStore: ValidateStoreEnum.LOAD
            });
          }

          return new MerchantRequestByIdResponseAction({ merchantRequestView: merchantRequest });
        }),

        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  merchantApprove$: Observable<Action> = this.actions$.pipe(
    ofType<MerchantApproveRequestAction>(MerchantActionType.MERCHANT_REQUEST_APPROVE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${MerchantActionType.MERCHANT_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload =>
      this.merchantRequestService.approveRequest(payload).pipe(
        map(() => new MerchantApproveResponseAction({ isSuccess: true })),
        catchError(err => of(new MerchantApproveErrorAction(err.error)))
      )
    )
  );

  @Effect()
  merchantDelete$ = this.actions$.pipe(
    ofType<MerchantDeleteRequestAction>(MerchantActionType.MERCHANT_REQUEST_DELETE_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantDeleteRequestAction: ' + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload =>
      this.merchantRequestService.deleteByRequestId(payload).pipe(
        map(() => new MerchantDeleteResponseAction({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  merchantHistory$ = this.actions$.pipe(
    ofType<MerchantListHistoryRequestAction>(MerchantActionType.MERCHANT_LIST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantListHistoryRequestAction: ' + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantService.getHistoryLogs(payload).pipe(
        map(response => new MerchantListHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  merchantRequestHistory$ = this.actions$.pipe(
    ofType<MerchantRequestHistoryRequestAction>(MerchantActionType.MERCHANT_REQUEST_HISTORY_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantHistoryRequestAction: ' + JSON.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantRequestService.getHistoryLogs(payload).pipe(
        map(response => new MerchantRequestHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getMerchantById$ = this.actions$.pipe(
    ofType<MerchantByIdRequestAction>(MerchantActionType.MERCHANT_GET_BY_ID_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantByIdRequestAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantService.getMerchantById(payload).pipe(
        tap(response => {
          this.logger.debug(`@Effect ${MerchantActionType.MERCHANT_GET_BY_ID_REQUEST}: ` + this.stringify(response));
        }),
        map(merchantView => {
          return new MerchantByIdResponseAction({ merchantView });
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getMerchantVersionById$ = this.actions$.pipe(
    ofType<MerchantGetVersionRequestAction>(MerchantActionType.MERCHANT_GET_VERSION_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantVersionByIdRequestAction: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantRequestService.getMerchantRequestVersionById(payload).pipe(
        tap(response => {
          this.logger.debug('@Effect MerchantVersionByIdResponseAction: ' + this.stringify(response));
        }),
        map(version => {
          return new MerchantGetVersionResponseAction(version);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
