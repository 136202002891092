<div id="edit-parameter" class="admin-form mt-2">
  <form [formGroup]="searchForm" (ngSubmit)="onSearchSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button
          type="button"
          class="btn btn-standard ml-2 my-3"
          (click)="openAddConditionModal()"
          [disabled]="isLoading"
        >
          <em class="icon-plus"></em>
          Add Condition
        </button>
        <app-import-export-button
          [buttons]="buttons"
          (import)="openImportModal()"
          [disabled]="isLoading"
          class="ml-2"
        ></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Start Date</label>
                        <input
                          id="startDate"
                          formControlName="startDate"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="startDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>End Date</label>
                        <input
                          id="endDate"
                          formControlName="endDate"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="endDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="data.length === 0 && !isApplySearchFilter()">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/no-item.svg" alt="No item" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.length === 0 && isApplySearchFilter()">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.length > 0">
    <div class="mt-3 table-responsive">
      <table id="transactions" class="table table-striped table-bordered table-hover border-none">
        <thead>
          <tr>
            <th class="text-center">No.</th>
            <th class="text-center">Store</th>
            <th class="text-center">Product Level</th>
            <th class="text-center">Product Value</th>
            <th class="text-center">Parameter Value</th>
            <th class="text-center">Start Date</th>
            <th class="text-center">End Date</th>
            <th class="text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of paging.currentPageData; index as i">
            <td class="text-center">{{ paging.getItemNo(i) }}</td>
            <td class="text-left">{{ result.storeCodeName || result.store }}</td>
            <td class="text-center">
              {{ 'Z8_PARAMETER_REQUEST.PRODUCT_LEVEL.' + result.productLevel.toUpperCase() | translate }}
            </td>
            <td class="text-left">{{ result.productValue.name }}</td>
            <td class="text-center">
              {{ result.parameterValue | numberFormatDisplay: getParameterFactor().decimals }}
            </td>
            <td class="text-center">{{ result.startDate }}</td>
            <td class="text-center">
              <ng-container *ngIf="!result.endDate; else endDate">{{
                result.endDate | dashDisplay: 'No Expire'
              }}</ng-container>
              <ng-template #endDate>{{ result.endDate }}</ng-template>
            </td>
            <td class="text-center position-relative">
              <a
                href="javascript:void(0)"
                class="btn-more mx-auto"
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button type="button" class="btn btn-link" (click)="editCondition(result)">
                  <em class="icon-edit"></em>Edit
                </button>
                <button type="button" class="btn btn-link" (click)="deleteCondition(result.id, result)">
                  <em class="icon-delete"></em>Delete
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>

      <!--pagination-->
      <app-ui-pagination #paging [(currentPage)]="currentPage" [(pageSize)]="pageSize" [items]="data">
      </app-ui-pagination>
    </div>
  </ng-container>
</div>
