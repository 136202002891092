import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SupplierGroupRequestService } from '../../services/supplier-group-request.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { SupplierGroupRequestActionTypes, SupplierGroupRequestCheckExistingResponse, SupplierGroupRequestListCheckExistingResponse, SupplierGroupRequestListResponse, SupplierGroupRequestSubmitErrorResponse, SupplierGroupRequestViewLoaded } from '../actions/supplier-group-request.actions';
export class SupplierGroupRequestEffects {
    constructor(actions$, supplierGroupRequestService, logger) {
        this.actions$ = actions$;
        this.supplierGroupRequestService = supplierGroupRequestService;
        this.logger = logger;
        this.searchSupplierGroup$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Group Request List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.searchByCriteria(action.payload).pipe(map(response => new SupplierGroupRequestListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.loadSupplierGroupRequest$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_VIEW_REQUESTED), tap(action => this.logger.debug('@Effect Supplier Group Request Get Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.getSupplierGroupRequestById(action.payload).pipe(map(result => new SupplierGroupRequestViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.saveSupplierGroupRequest$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SAVE_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Save Request: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.save(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been saved.'
        })), catchError(err => this.errorHandling(err)))));
        this.updateSupplierGroupRequest$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_UPDATE_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Update Request: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.update(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been saved.'
        })), catchError(err => this.errorHandling(err)))));
        this.submitSupplierGroupRequest$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Request Submit Request: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been sent to approver.'
        })), catchError(err => this.submitErrorHandling(err)))));
        this.submitSupplierGroupEditRequest$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_EDIT_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Request Edit Submit Request: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been sent to approver.',
            routerLink: '/supplier/supplier-group-request-list'
        })), catchError(err => this.submitErrorHandling(err)))));
        this.approve$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_APPROVE), tap(action => this.logger.debug('@Effect Supplier Group Request Approve: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.approve(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been approved.'
        })), catchError(err => this.errorHandling(err)))));
        this.reject$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_REJECT), tap(action => this.logger.debug('@Effect Supplier Group Request Reject: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.reject(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been rejected.'
        })), catchError(err => this.errorHandling(err)))));
        this.cancel$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CANCEL), tap(action => this.logger.debug('@Effect Supplier Group Request Cancel: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been cancelled.'
        })), catchError(err => this.errorHandling(err)))));
        this.delete$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_DELETE), tap(action => this.logger.debug('@Effect Supplier Group Request Delete: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.delete(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been deleted.'
        })), catchError(err => {
            return err.error && err.error.code === '00001'
                ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
                : err.error && err.error.code === '00004'
                    ? of(new LayoutActionVersionError(true))
                    : of(new LayoutActionLoadError(err));
        }))));
        this.checkExisting$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_CHECK_EXISTING_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Request Check Existing: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.checkExisting(action.payload).pipe(map(() => new SupplierGroupRequestCheckExistingResponse(true)), catchError(() => {
            return of(new SupplierGroupRequestCheckExistingResponse(false));
        }))));
        this.checkExistingForList$ = this.actions$.pipe(ofType(SupplierGroupRequestActionTypes.SUPPLIER_GROUP_REQUEST_LIST_CHECK_EXISTING_REQUEST), tap(action => this.logger.debug('@Effect Supplier Group Request List Check Existing: ' + JSON.stringify(action.payload))), switchMap(action => this.supplierGroupRequestService.checkExisting(action.payload).pipe(map(() => new SupplierGroupRequestListCheckExistingResponse(true)), catchError(_err => {
            return of(new SupplierGroupRequestListCheckExistingResponse(false));
        }))));
    }
    errorHandling(err) {
        return err.error && err.error.code === '00001'
            ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
            : of(new LayoutActionLoadError(err));
    }
    submitErrorHandling(err) {
        return err.error && ['00001', '08002', '08003'].includes(err.error.code)
            ? of(new SupplierGroupRequestSubmitErrorResponse(err.error))
            : of(new LayoutActionLoadError(err));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "searchSupplierGroup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "loadSupplierGroupRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "saveSupplierGroupRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "updateSupplierGroupRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "submitSupplierGroupRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "submitSupplierGroupEditRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "approve$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "reject$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "cancel$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "delete$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "checkExisting$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierGroupRequestEffects.prototype, "checkExistingForList$", void 0);
