import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StoreGroupService } from '../../services/store-group.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { StoreGroupActionTypes, StoreGroupGetResponseAction, StoreGroupListResponseAction, StoreGroupSubmitErrorAction } from '../actions/store-group.actions';
export class StoreGroupEffects {
    constructor(actions$, logger, storeGroupService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.storeGroupService = storeGroupService;
        this.searchStoreGroup$ = this.actions$.pipe(ofType(StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeGroupService.searchByCriteriaRequest(payload).pipe(map(requests => {
                return new StoreGroupListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.createSubmitStoreGroup$ = this.actions$.pipe(ofType(StoreGroupActionTypes.STORE_GROUP_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect Request Store Group Submit Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.storeGroupService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'Store group has been created.'
            })), catchError(err => this.errorHandling(err)));
        }));
        this.updateStoreGroup$ = this.actions$.pipe(ofType(StoreGroupActionTypes.STORE_GROUP_UPDATE_REQEUST), tap(action => this.logger.debug('@Effect Request Store Group update Request: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.storeGroupService
                .update(action.payload.merchantNo, action.payload.groupNo, action.payload.data)
                .pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been saved.'
            })), catchError(err => this.errorHandling(err)));
        }));
        this.getStoreGroup$ = this.actions$.pipe(ofType(StoreGroupActionTypes.STORE_GROUP_GET_REQUEST), map(action => {
            this.logger.debug(`@Effect ${StoreGroupActionTypes.STORE_GROUP_GET_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeGroupService.getGroup(payload.merchantNo, payload.groupNo).pipe(map(requests => {
                return new StoreGroupGetResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    errorHandling(err) {
        return err &&
            err.error &&
            (err.error.code === '00000' || err.error.code === '04015' || err.error.code === '04016')
            ? of(new StoreGroupSubmitErrorAction(err.error))
            : of(new LayoutActionLoadError(err));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreGroupEffects.prototype, "searchStoreGroup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreGroupEffects.prototype, "createSubmitStoreGroup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreGroupEffects.prototype, "updateStoreGroup$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StoreGroupEffects.prototype, "getStoreGroup$", void 0);
