<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-purchase"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    (click)="onCancelGr()"
    *ngIf="
      goodsReceive &&
      [goodsReceiveStatusEnum.RECEIVED].includes(goodsReceive.status) &&
      ![shipToType.STORE].includes(goodsReceive.shipToType) &&
      permissionsUtil.determineGrPermission(
        goodsReceive.shipToType,
        goodsReceive.shipToCode,
        goodsReceive.productType,
        [permissionAction.MANAGE]
      )
    "
  >
    <em class="icon-forbidden"></em>
    Cancel GR
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="goods-receive" class="admin-form" *ngIf="goodsReceiveForm" [formGroup]="goodsReceiveForm">
  <div *ngIf="goodsReceive && goodsReceive?.status">
    <!-- Header Row -->
    <div class="info-header-container">
      <div class="info-header-item" *ngIf="goodsReceive?.status !== goodsReceiveStatusEnum.DRAFT">
        <div class="info-header-label">
          <span>GR Number:</span>
        </div>
        <div class="info-header-value">
          <span>{{ goodsReceive.docNo ? goodsReceive.docNo : '' }}</span>
        </div>
      </div>
      <div class="info-header-item" *ngIf="goodsReceive?.shipToType === shipToType.STORE">
        <div class="info-header-label">
          <span>Receive Order No.</span>
        </div>
        <div class="info-header-value">
          <span>{{ goodsReceive.roRefNo ? goodsReceive.roRefNo : '' }}</span>
        </div>
      </div>
      <div class="info-header-item" *ngIf="currentViewMode">
        <div class="info-header-label">
          <span>Delivery Order No.</span>
        </div>
        <div class="info-header-value">
          <span>{{ goodsReceive?.doNo | dashDisplay }}</span>
        </div>
      </div>
      <div class="info-header-item" *ngIf="currentViewMode">
        <div class="info-header-label">
          <span>Ref. Document No.</span>
        </div>
        <div class="info-header-value">
          <span>{{ goodsReceive?.docRefNo | dashDisplay }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>GR Status:</span>
        </div>
        <div class="info-header-value">
          <span class="gr-status" [ngClass]="getColorStatus(goodsReceive?.status)">
            {{ 'GOODS_RECEIVE.GOODS_RECEIVE_STATUS.' + goodsReceive?.status | translate }}
          </span>
        </div>
      </div>
    </div>

    <!-- Information Details -->
    <div class="information-container">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span class="info-header-label">PO Number</span>
            </div>
            <div class="info-header-value">
              {{ goodsReceive.poNo }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span class="info-header-label">Supplier Name</span>
            </div>
            <div class="info-header-value">
              {{ goodsReceive.supplierName | dashDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span class="info-header-label">Location</span>
            </div>
            <div class="info-header-value">
              {{ goodsReceive.shipToCodeName | dashDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span class="info-header-label">Receiver</span>
            </div>
            <div class="info-header-value">
              {{ goodsReceive.receiver ? goodsReceive.receiver : receiver }}
            </div>
          </div>
          <div class="col-lg col-md-4 flex-column">
            <div class="info-header-label">
              <span class="info-header-label">Created Date</span>
            </div>
            <div class="info-header-value">
              {{
                (goodsReceive.createdDate ? goodsReceive.createdDate : currentDate)
                  | amFromUtc
                  | amLocal
                  | dateDisplay: dateTimeDisplay
              }}
            </div>
          </div>

          <div class="col-lg-4">
            <div class="total-amount">
              <div class="text-right">
                <span class="info-total">
                  {{ goodsReceive.totalAmount || 0 | numberFormatDisplay }}
                </span>
                <span class="info-total-currency">
                  THB
                </span>
              </div>
              <div class="info-total-detail">
                <span>(Total Amount before VAT: {{ goodsReceive?.subTotalAmount | numberFormatDisplay }}| </span
                ><span (click)="showVatDetails()" class="vat-details-top"
                  >VAT: {{ goodsReceive?.vatAmount | numberFormatDisplay }})</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex flex-column flex-md-row justify-content-between mt-5 mb-3" *ngIf="currentCreateMode">
      <div class="d-md-inline-flex">
        <div class="mr-2">
          <label class="d-block">Delivery Order No.<span class="text-danger">*</span></label>
          <input
            class="form-control w-220px"
            type="text"
            placeholder="Delivery Order Number"
            formControlName="doNo"
            maxlength="25"
            [ngClass]="{
              'is-invalid': isSubmit && form.get('doNo').errors
            }"
          />
          <div *ngIf="isSubmit && form.get('doNo').errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="py-3 py-md-0">
          <label class="d-block">Ref. Document No.</label>
          <input
            class="form-control w-220px"
            type="text"
            placeholder="Ref. Document No."
            formControlName="docRefNo"
            maxlength="25"
            [ngClass]="{ 'is-invalid': isSubmit && form.get('docRefNo').errors }"
          />
        </div>
      </div>
      <div class="py-2 py-md-4">
        <div class="align-self-end" *ngIf="currentCreateMode">
          <button type="button" class="btn btn-standard" (click)="confirmReceiveAllItem()">Receive All Items</button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <table id="grItemsTable" class="table table-striped table-bordered table-hover border-none table-with-child">
        <thead>
          <tr>
            <th class="text-center align-top">No.</th>
            <th class="text-center align-top">Product Name</th>
            <th class="text-center align-top">Article No.</th>
            <th class="text-center align-top">Barcode</th>
            <th class="text-center align-top">Order Unit</th>
            <th class="text-center align-top">Unit Price</th>
            <th class="text-center align-top max-w-150px">Order Quantity</th>
            <th class="text-center align-top max-w-150px" *ngIf="!currentViewMode">Remaining Quantity</th>
            <th class="text-center align-top min-w-160px">Receive Quantity</th>
            <th class="text-center align-top min-w-160px">Receive Amount (THB)</th>
          </tr>
        </thead>
        <tbody>
          <ng-container formArrayName="items" *ngFor="let grItem of paging.currentPageData; index as itemIndex">
            <ng-container [formGroupName]="itemIndex + (currentPage - 1) * pageSize">
              <tr
                [ngClass]="{
                  even: itemIndex % 2 === 0 && goodsReceive.items.length === 0,
                  'even-with-child': itemIndex % 2 === 0 && goodsReceive.items.length !== 0,
                  odd: itemIndex % 2 !== 0 && goodsReceive.items.length === 0,
                  'odd-with-child': itemIndex % 2 !== 0 && goodsReceive.items.length !== 0
                }"
              >
                <td class="text-center">
                  <span>{{ grItem.get('itemNo').value }}</span>
                </td>
                <td>
                  <span>{{ grItem.get('productName').value }}</span>
                </td>
                <td>
                  <span>{{ grItem.get('articleNo').value }}</span>
                </td>
                <td>
                  <span>{{ grItem.get('barcode').value }}</span>
                  <div *ngIf="grItem?.get('barcode').errors" class="invalid-display text-left">
                    <div *ngIf="isSubmit && grItem?.get('barcode').errors.barcode">
                      {{ grItem?.get('barcode').errors.barcode }}
                    </div>
                  </div>
                </td>
                <td class="text-center">
                  <span>{{ grItem.get('orderUnit').value }}</span>
                </td>
                <td class="text-right">
                  <span>{{ grItem.get('unitPrice').value | numberFormatDisplay }}</span>
                </td>
                <td class="text-center">
                  <span>{{ grItem.get('orderQuantity').value }}</span>
                </td>
                <td class="text-center" *ngIf="!currentViewMode">
                  <span>{{ grItem.get('remainingQuantity').value }}</span>
                </td>
                <td class="text-center w-10 td-input-column">
                  <app-numeric-textbox
                    [format]="'0,0'"
                    [decimals]="0"
                    [negativeSign]="false"
                    maxlength="10"
                    ngClass="numeric-format quantity"
                    class="td-numeric-textbox"
                    formControlName="receiveQuantity"
                    placeholder="Quantity"
                    [ngClass]="{
                      'is-invalid':
                        isSubmit &&
                        (grItem?.get('receiveQuantity').errors || getItemForm.controls[itemIndex].errors?.moreQty)
                    }"
                  >
                  </app-numeric-textbox>

                  <div *ngIf="grItem?.get('receiveQuantity').errors" class="invalid-display text-left">
                    <div *ngIf="grItem?.get('receiveQuantity').errors?.isZero && !currentViewMode">
                      {{ 'ERRORS.NOT_ALLOW' | translate: { value: 0 } }}
                    </div>
                  </div>
                  <div
                    *ngIf="isSubmit && getItemForm.controls[itemIndex].errors?.moreQty"
                    class="invalid-display text-left"
                  >
                    Receive QTY not more than Remaining QTY.
                  </div>
                </td>
                <td class="text-right total-amount-bg">
                  <span>{{ grItem.get('receiveAmount').value || 0 | numberFormatDisplay }}</span>
                </td>
              </tr>
              <ng-container
                formArrayName="freeItems"
                *ngFor="let freeItem of grItem?.get('freeItems').controls; index as freeItemIndex"
              >
                <ng-container [formGroupName]="freeItemIndex + (currentPage - 1) * pageSize">
                  <tr
                    [ngClass]="{
                      'child-row-even': itemIndex % 2 === 0,
                      'child-row-odd': itemIndex % 2 !== 0
                    }"
                  >
                    <td class="text-center"></td>
                    <td>
                      <span>{{ freeItem.get('productName').value }} <em class="icon-gift"></em></span>
                    </td>
                    <td>
                      <span>{{ freeItem.get('articleNo').value }}</span>
                    </td>
                    <td>
                      <span>{{ freeItem.get('barcode').value }}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ freeItem.get('orderUnit').value }}</span>
                    </td>
                    <td class="text-right">
                      <span>{{ 0 | numberFormatDisplay }}</span>
                    </td>
                    <td class="text-center">
                      <span>{{ freeItem.get('orderQuantity').value | numberFormatDisplay: 0 }}</span>
                    </td>
                    <td class="text-center" *ngIf="!currentViewMode">
                      <span>{{ freeItem.get('remainingQuantity').value | numberFormatDisplay: 0 }}</span>
                    </td>
                    <td class="text-center td-input-column">
                      <app-numeric-textbox
                        [format]="'0,0'"
                        [decimals]="0"
                        maxlength="10"
                        [negativeSign]="false"
                        ngClass="numeric-format quantity"
                        class="td-numeric-textbox"
                        formControlName="receiveQuantity"
                        placeholder="Quantity"
                        [ngClass]="{
                          'is-invalid':
                            isSubmit &&
                            (freeItem?.get('receiveQuantity').errors ||
                              (grItem?.get('freeItems').controls)[freeItemIndex].errors?.moreQty)
                        }"
                      >
                      </app-numeric-textbox>
                      <div *ngIf="freeItem?.get('receiveQuantity').errors" class="invalid-display text-left">
                        <div *ngIf="freeItem?.get('receiveQuantity').errors?.isZero && !currentViewMode">
                          {{ 'ERRORS.NOT_ALLOW' | translate: { value: 0 } }}
                        </div>
                      </div>
                      <div
                        *ngIf="isSubmit && (grItem?.get('freeItems').controls)[freeItemIndex].errors?.moreQty"
                        class="invalid-display text-left"
                      >
                        Receive QTY not more than Remaining QTY.
                      </div>
                    </td>
                    <td class="text-right total-amount-bg">
                      Free
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </tbody>
      </table>

      <app-ui-pagination
        #paging
        [(currentPage)]="currentPage"
        [(pageSize)]="pageSize"
        [items]="form?.get('items').controls"
        [valueChangeDetector]="form?.get('items').valueChanges | async"
      >
      </app-ui-pagination>
    </div>
  </div>
  <div
    class="fixed-row-bottom form-row"
    *ngIf="currentCreateMode || (currentViewMode && goodsReceive?.status === goodsReceiveStatusEnum.DRAFT)"
  >
    <div class="float-right" *ngIf="currentCreateMode">
      <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCloseFullModal()">
        Cancel
      </button>
      <button type="button" id="btn-submit" class="btn btn-primary" appPreventDoubleClick (click)="onSubmit()">
        Submit
      </button>
    </div>
    <div class="float-right" *ngIf="currentViewMode && goodsReceive?.status === goodsReceiveStatusEnum.DRAFT">
      <button type="button" id="btn-edit" class="btn btn-primary" appPreventDoubleClick (click)="toggleToEdit()">
        Edit
      </button>
    </div>
  </div>
</div>
