import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ModalButtonResponseEnum } from '../../../enum/modal-button-response.enum';
import { BaseModalComponent } from '../../../models/base-modal.component.model';

@Component({
  selector: 'app-confirm-with-option-modal',
  templateUrl: './confirm-with-option-modal.component.html',
  styleUrls: ['./confirm-with-option-modal.component.scss']
})
export class ConfirmWithOptionModalComponent implements OnInit, BaseModalComponent {
  public title: string;
  public cancelText: string;
  public okText: string;
  public okClass: string;
  public message: string;
  public options: Array<any>;
  public routerLink?: string;
  public response: any;
  public selectedValue: string;
  public action: Subject<ModalButtonResponseEnum>;

  constructor(
    public bsModalRef: BsModalRef,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.action = new Subject();
    this.response = new Subject();
    this.okText = this.okText || this.translate.instant('OK');
    this.okClass = this.okClass || 'btn btn-primary';
    this.cancelText = this.cancelText || this.translate.instant('Cancel');
  }

  confirm(): void {
    this.action.next(ModalButtonResponseEnum.OK);
    this.response.next({ action: ModalButtonResponseEnum.OK, value: this.selectedValue });
    this.bsModalRef.hide();

    if (this.routerLink) {
      this.router.navigateByUrl(this.routerLink);
    }
  }

  decline(): void {
    this.action.next(ModalButtonResponseEnum.CANCEL);
    this.response.next({ action: ModalButtonResponseEnum.CANCEL, value: '' });
    this.bsModalRef.hide();
  }
}
