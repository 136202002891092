import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { TippyModule } from 'ng-tippy';
import { InputTrimModule } from 'ng2-trim-directive';
import {
  AccordionModule,
  AlertModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  DatepickerModule,
  ModalModule,
  PaginationModule,
  PopoverModule,
  SortableModule,
  TabsModule
} from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxMaskModule } from 'ngx-mask';
import { MomentModule } from 'ngx-moment';
import { NumericTextboxModule } from 'ngx-numeric-textbox';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HmrModule } from './hmr.module';
import { AssortmentProductListComponent } from './pages/assortment-product/assortment-product-list/assortment-product-list.component';
import { AssortmentBarcodeComponent } from './pages/assortment-request/assortment-barcode/assortment-barcode.component';
import { AssortmentCjProductDetailsComponent } from './pages/assortment-request/assortment-cj-product-details/assortment-cj-product-details.component';
import { AssortmentProductDetailsComponent } from './pages/assortment-request/assortment-product-details/assortment-product-details.component';
import { AssortmentRequestFreshLiteComponent } from './pages/assortment-request/assortment-request-fresh-lite/assortment-request-fresh-lite.component';
import { AssortmentRequestListComponent } from './pages/assortment-request/assortment-request-list/assortment-request-list.component';
import { AssortmentRequestComponent } from './pages/assortment-request/assortment-request/assortment-request.component';
import { AssortmentSelectWarehouseModalComponent } from './pages/assortment-request/assortment-select-warehouse-modal/assortment-select-warehouse-modal.component';
import { AssortmentSupplierPriceFileUploadComponent } from './pages/assortment-request/assortment-supplier-price-file-upload/assortment-supplier-price-file-upload.component';
import { AssortmentSupplierPriceModalUploadComponent } from './pages/assortment-request/assortment-supplier-price-modal-upload/assortment-supplier-price-modal-upload.component';
import { AssortmentUnlinkCjProductModalComponent } from './pages/assortment-request/assortment-unlink-cj-product-modal/assortment-unlink-cj-product-modal.component';
import { AssortmentWholesalePriceComponent } from './pages/assortment-request/assortment-wholesale-price/assortment-wholesale-price.component';
import { AddSupplierPriceComponent } from './pages/assortment-request/supplier-details/add-supplier-price/add-supplier-price.component';
import { CustomListboxComponent } from './pages/assortment-request/supplier-details/custom-listbox/custom-listbox.component';
import { ManualAddEditSupplierPriceComponent } from './pages/assortment-request/supplier-details/manual-add-edit-supplier-price/manual-add-edit-supplier-price.component';
import { SupplierDetailsComponent } from './pages/assortment-request/supplier-details/supplier-details.component';
import { ClaimRequestListComponent } from './pages/claim-request/claim-request-list/claim-request-list.component';
import { ClaimRequestViewComponent } from './pages/claim-request/claim-request-view/claim-request-view.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { MyTaskComponent } from './pages/dashboard/my-task/my-task.component';
import { DeliveryOrderEditComponent } from './pages/delivery-order/delivery-order-edit/delivery-order-edit.component';
import { DeliveryOrderFileUploadComponent } from './pages/delivery-order/delivery-order-file-upload/delivery-order-file-upload.component';
import { DeliveryOrderImportModalComponent } from './pages/delivery-order/delivery-order-import-modal/delivery-order-import-modal.component';
import { DeliveryOrderImportComponent } from './pages/delivery-order/delivery-order-import/delivery-order-import.component';
import { DeliveryOrderListComponent } from './pages/delivery-order/delivery-order-list/delivery-order-list.component';
import { ForceChangePasswordLeftComponent } from './pages/force-change-password/force-change-password-left/force-change-password-left.component';
import { ForceChangePasswordComponent } from './pages/force-change-password/force-change-password.component';
import { ForgotPasswordLeftComponent } from './pages/forgot-password/forgot-password-left/forgot-password-left.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ForgotUsernameLeftComponent } from './pages/forgot-username/forgot-username-left/forgot-username-left.component';
import { ForgotUsernameComponent } from './pages/forgot-username/forgot-username.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginLeftComponent } from './pages/login/login-left/login-left.component';
import { LoginRightComponent } from './pages/login/login-right.component';
import { AdjustStockViewComponent } from './pages/manage-stock/adjust-stock-view/adjust-stock-view.component';
import { AdjustStockComponent } from './pages/manage-stock/adjust-stock/adjust-stock.component';
import { ManageStockListComponent } from './pages/manage-stock/manage-stock-list/manage-stock-list.component';
import { StockAdjustmentDownloadModalComponent } from './pages/manage-stock/stock-adjustment-download-modal/stock-adjustment-download-modal.component';
import { StockAdjustmentFileUploadComponent } from './pages/manage-stock/stock-adjustment-file-upload/stock-adjustment-file-upload.component';
import { MemberCardListComponent } from './pages/member-card/member-card-list/member-card-list.component';
import { MerchantListComponent } from './pages/merchant/merchant-list/merchant-list.component';
import { MerchantRequestListComponent } from './pages/merchant/merchant-request-list/merchant-request-list.component';
import { MerchantRequestComponent } from './pages/merchant/merchant-request/merchant-request.component';
import { ViewMerchantComponent } from './pages/merchant/view-merchant/view-merchant.component';
import { DeliveryDetailsSpecialComponent } from './pages/order/order-components/delivery-details-special/delivery-details-special.component';
import { DeliveryDetailsComponent } from './pages/order/order-components/delivery-details/delivery-details.component';
import { DeliveryScheduleComponent } from './pages/order/order-components/delivery-details/delivery-schedule/delivery-schedule.component';
import { ImportOrderRequestComponent } from './pages/order/order-components/import-order-request/import-order-request.component';
import { SelectItemModalComponent } from './pages/order/order-components/select-item-modal/select-item-modal.component';
import { SelectShelfModalComponent } from './pages/order/order-components/select-shelf-modal/select-shelf-modal.component';
import { OrderListComponent } from './pages/order/order-list/order-list.component';
import { OrderRequestListComponent } from './pages/order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from './pages/order/order-request/order-request.component';
import { OrderDetailsComponent } from './pages/order/order-view/order-details/order-details.component';
import { OrderViewComponent } from './pages/order/order-view/order-view.component';
import { ReceiveSummaryComponent } from './pages/order/order-view/receive-summary/receive-summary.component';
import { ClassMarkupComponent } from './pages/price-setting/class-markup/class-markup.component';
import { StackPricingViewComponent } from './pages/price-setting/stack-pricing/stack-pricing-view/stack-pricing-view.component';
import { ProductCreateComponent } from './pages/products/product-create/product-create.component';
import { ProductEditComponent } from './pages/products/product-edit/product-edit.component';
import { ProductListComponent } from './pages/products/product-list/product-list.component';
import { ProductViewHeaderComponent } from './pages/products/product-view-header/product-view-header.component';
import { ProductViewComponent } from './pages/products/product-view/product-view.component';
import { MonthlyPartnerProfitSharingDownloadModalComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-download-modal/monthly-partner-profit-sharing-download-modal.component';
import { MonthlyPartnerProfitSharingFileUploadComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-file-upload/monthly-partner-profit-sharing-file-upload.component';
import { MonthlyPartnerProfitSharingListComponent } from './pages/profit-sharing/monthly-partner-profit-sharing-list/monthly-partner-profit-sharing-list.component';
import { ProfitSharingListComponent } from './pages/profit-sharing/profit-sharing-list/profit-sharing-list.component';
import { ProfitSharingViewComponent } from './pages/profit-sharing/profit-sharing-view/profit-sharing-view.component';
import { PromotionListComponent } from './pages/promotion/promotion-list/promotion-list.component';
import { PromotionRequestListComponent } from './pages/promotion/promotion-request-list/promotion-request-list.component';
import { HandleThemeComponent } from './pages/promotion/promotion-request/handle-theme/handle-theme.component';
import { PromotionConditionComponent } from './pages/promotion/promotion-request/promotion-condition/promotion-condition.component';
import { PromotionDetailsComponent } from './pages/promotion/promotion-request/promotion-details/promotion-details.component';
import { PromotionItemComponent } from './pages/promotion/promotion-request/promotion-item/promotion-item.component';
import { PromotionRequestComponent } from './pages/promotion/promotion-request/promotion-request.component';
import { PromotionStoreComponent } from './pages/promotion/promotion-request/promotion-store/promotion-store.component';
import { SelectByStoreComponent } from './pages/promotion/promotion-request/select-by-store/select-by-store.component';
import { PromotionViewComponent } from './pages/promotion/promotion-view/promotion-view.component';
import { GoodsReceiveListComponent } from './pages/purchase/goods-receive-list/goods-receive-list.component';
import { GoodsReceiveViewComponent } from './pages/purchase/goods-receive-view/goods-receive-view.component';
import { ProductChangeModalComponent } from './pages/purchase/product-change-modal/product-change-modal.component';
import { PurchaseOrderListComponent } from './pages/purchase/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderViewComponent } from './pages/purchase/purchase-order-view/purchase-order-view.component';
import { PurchaseRequestDeliveryDetailsComponent } from './pages/purchase/purchase-request-delivery-details/purchase-request-delivery-details.component';
import { PurchaseRequestFileUploadComponent } from './pages/purchase/purchase-request-file-upload/purchase-request-file-upload.component';
import { PurchaseRequestListComponent } from './pages/purchase/purchase-request-list/purchase-request-list.component';
import { PurchaseRequestModalUploadComponent } from './pages/purchase/purchase-request-modal-upload/purchase-request-modal-upload.component';
import { PurchaseRequestViewComponent } from './pages/purchase/purchase-request-view/purchase-request-view.component';
import { TdAssortmentImportComponent } from './pages/purchase/td-assortment-import/td-assortment-import.component';
import { TdAssortmentListComponent } from './pages/purchase/td-assortment-list/td-assortment-list.component';
import { VatDetailsModalComponent } from './pages/purchase/vat-details-modal/vat-details-modal.component';
import { ReceiveOrderListComponent } from './pages/receive-order/receive-order-list/receive-order-list.component';
import { ReceiveOrderViewComponent } from './pages/receive-order/receive-order-view/receive-order-view.component';
import { BillPaymentModalComponent } from './pages/reports/bill-payment-modal/bill-payment-modal.component';
import { DcAssortmentModalComponent } from './pages/reports/dc-assortment-modal/dc-assortment-modal.component';
import { DeleteBillModalComponent } from './pages/reports/delete-bill-modal/delete-bill-modal.component';
import { InventoryAdjustmentRequestModalComponent } from './pages/reports/inventory-adjustment-request-modal/inventory-adjustment-request-modal.component';
import { InventoryCountTransactionModalComponent } from './pages/reports/inventory-count-transaction-modal/inventory-count-transaction-modal.component';
import { InventoryCountTransactionTempModalComponent } from './pages/reports/inventory-count-transaction-temp-modal/inventory-count-transaction-temp-modal.component';
import { InventoryVarianceModalComponent } from './pages/reports/inventory-variance-modal/inventory-variance-modal.component';
import { MoneyInModalComponent } from './pages/reports/money-in-modal/money-in-modal.component';
import { MovingAverageModalComponent } from './pages/reports/moving-average-modal/moving-average-modal.component';
import { PromotionCompensateModalComponent } from './pages/reports/promotion-compensate-modal/promotion-compensate-modal.component';
import { ReportListComponent } from './pages/reports/report-list.component';
import { SalesSummaryModalComponent } from './pages/reports/sales-summary-modal/sales-summary-modal.component';
import { SalesTransactionModalComponent } from './pages/reports/sales-transaction-modal/sales-transaction-modal.component';
import { StockCardModalComponent } from './pages/reports/stock-card-modal/stock-card-modal.component';
import { ToteAssetLocationModalComponent } from './pages/reports/tote-asset-location-modal/tote-asset-location-modal.component';
import { ToteTransactionDCModalComponent } from './pages/reports/tote-transaction-dc-modal/tote-transaction-dc-modal.component';
import { ToteTransactionFromStoreModalComponent } from './pages/reports/tote-transaction-from-store-modal/tote-transaction-from-store-modal.component';
import { ToteTransactionModalComponent } from './pages/reports/tote-transaction-modal/tote-transaction-modal.component';
import { VoucherModalComponent } from './pages/reports/voucher-modal/voucher-modal.component';
import { RoleCreateComponent } from './pages/roles/role-create/role-create.component';
import { RoleEditComponent } from './pages/roles/role-edit/role-edit.component';
import { RoleListComponent } from './pages/roles/role-list/role-list.component';
import { AddShelfTypeComponent } from './pages/shelf/shelf-components/add-shelf-type/add-shelf-type.component';
import { ExportShelfComponent } from './pages/shelf/shelf-components/export-shelf/export-shelf.component';
import { ShelfFixAssetComponent } from './pages/shelf/shelf-components/shelf-fix-asset/shelf-fix-asset.component';
import { ShelfInfoComponent } from './pages/shelf/shelf-components/shelf-info/shelf-info.component';
import { ShelfInventoryComponent } from './pages/shelf/shelf-components/shelf-inventory/shelf-inventory.component';
import { ShelfListComponent } from './pages/shelf/shelf-list/shelf-list.component';
import { ShelfRequestListComponent } from './pages/shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from './pages/shelf/shelf-request/shelf-request.component';
import { ViewShelfComponent } from './pages/shelf/view-shelf/view-shelf.component';
import { ShipmentListComponent } from './pages/shipment/shipment-list/shipment-list.component';
import { ShipmentRequestComponent } from './pages/shipment/shipment-request/shipment-request.component';
import { StockInformationListComponent } from './pages/stock/stock-information-list/stock-information-list.component';
import { StockInformationComponent } from './pages/stock/stock-information/stock-information.component';
import { StockMovementComponent } from './pages/stock/stock-information/stock-movement/stock-movement.component';
import { StockStoreComponent } from './pages/stock/stock-information/stock-store/stock-store.component';
import { StockWarehouseComponent } from './pages/stock/stock-information/stock-warehouse/stock-warehouse.component';
import { ExportByProductComponent } from './pages/store-assortment/components/export-by-product/export-by-product.component';
import { ExportByStoreComponent } from './pages/store-assortment/components/export-by-store/export-by-store.component';
import { StoreAssortmentRequestTableComponent } from './pages/store-assortment/components/store-assortment-request-table/store-assortment-request-table.component';
import { Z8FactorComponent } from './pages/store-assortment/components/Z8-factor/Z8-factor.component';
import { StoreAssortmentListComponent } from './pages/store-assortment/store-assortment-list/store-assortment-list.component';
import { StoreAssortmentRequestListComponent } from './pages/store-assortment/store-assortment-request-list/store-assortment-request-list.component';
import { StoreAssortmentRequestViewComponent } from './pages/store-assortment/store-assortment-request-view/store-assortment-request-view.component';
import { StoreAssortmentFindStoreComponent } from './pages/store-assortment/store-assortment-request/find-store/find-store.component';
import { StoreAssortmentItemListComponent } from './pages/store-assortment/store-assortment-request/item-list/item-list.component';
import { StoreAssortmentRequestSearchResultComponent } from './pages/store-assortment/store-assortment-request/search-result/search-result.component';
import { StoreAssortmentRequestComponent } from './pages/store-assortment/store-assortment-request/store-assortment-request.component';
import { StoreAssortmentRequestStoreSelectedComponent } from './pages/store-assortment/store-assortment-request/store-selected/store-selected.component';
import { MasterAssortmentComponent } from './pages/store-assortment/view-store-assortment/master-assortment/master-assortment.component';
import { SpecialAssortmentComponent } from './pages/store-assortment/view-store-assortment/special-assortment/special-assortment.component';
import { ViewStoreAssortmentComponent } from './pages/store-assortment/view-store-assortment/view-store-assortment.component';
import { StoreGroupCreateComponent } from './pages/store-group/store-group-create/store-group-create.component';
import { StoreGroupListComponent } from './pages/store-group/store-group-list/store-group-list.component';
import { AddConditionComponent } from './pages/store-stock-planning/components/add-condition/add-condition.component';
import { ExportStoreStockPlanningComponent } from './pages/store-stock-planning/components/export-store-stock-planning/export-store-stock-planning.component';
import { ImportDataComponent } from './pages/store-stock-planning/components/import-data/import-data.component';
import { TargetStockImportFileUploadComponent } from './pages/store-stock-planning/components/target-stock-import-file-upload/target-stock-import-file-upload.component';
import { TargetStockImportModalComponent } from './pages/store-stock-planning/components/target-stock-import-modal/target-stock-import-modal.component';
import { ViewParameterRequestComponent } from './pages/store-stock-planning/view-parameter-request/view-parameter-request.component';
import { EditParameterComponent } from './pages/store-stock-planning/view-parameter/edit-parameter/edit-parameter.component';
import { ViewParameterListComponent } from './pages/store-stock-planning/view-parameter/view-parameter-list/view-parameter-list.component';
import { ViewParameterComponent } from './pages/store-stock-planning/view-parameter/view-parameter.component';
import { Z8ParameterListComponent } from './pages/store-stock-planning/z8-parameter-list/z8-parameter-list.component';
import { Z8ParameterRequestListComponent } from './pages/store-stock-planning/z8-parameter-request-list/z8-parameter-request-list.component';
import { Z8ResultComponent } from './pages/store-stock-planning/z8-result/z8-result.component';
import { StoreListComponent } from './pages/store/store-list/store-list.component';
import { StoreRequestListComponent } from './pages/store/store-request-list/store-request-list.component';
import { StoreRequestComponent } from './pages/store/store-request/store-request.component';
import { POSTerminalComponent } from './pages/store/view-store/pos-terminal/pos-terminal.component';
import { RegisterPOSComponent } from './pages/store/view-store/register-pos/register-pos.component';
import { ViewStoreComponent } from './pages/store/view-store/view-store.component';
import { SupplierPriceComponent } from './pages/supplier-price/supplier-price.component';
import { SupplierGroupImportComponent } from './pages/supplier/supplier-group-import/supplier-group-import.component';
import { SupplierGroupListComponent } from './pages/supplier/supplier-group-list/supplier-group-list.component';
import { SupplierGroupRequestListComponent } from './pages/supplier/supplier-group-request-list/supplier-group-request-list.component';
import { SupplierGroupRequestViewComponent } from './pages/supplier/supplier-group-request-view/supplier-group-request-view.component';
import { UserCreateComponent } from './pages/user/user-create/user-create.component';
import { UserEditComponent } from './pages/user/user-edit/user-edit.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { VoucherListComponent } from './pages/voucher/voucher-list/voucher-list.component';
import { VoucherRequestListComponent } from './pages/voucher/voucher-request-list/voucher-request-list.component';
import { VoucherRequestComponent } from './pages/voucher/voucher-request/voucher-request.component';
import { VoucherViewComponent } from './pages/voucher/voucher-view/voucher-view.component';
import { BoxsDisplayComponent } from './shared/components/boxs-display/boxs-display.component';
import { CollapsibleHistoryComponent } from './shared/components/collapsible-history/collapsible-history.component';
import { DropdownMultipleSelectComponent } from './shared/components/dropdown-multiple-select/dropdown-multiple-select.component';
import { EditInPlaceComponent } from './shared/components/edit-in-place/edit-in-place.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { FilesInputComponent } from './shared/components/files-input/files-input.component';
import { FilesSubmitComponent } from './shared/components/files-submit/files-submit.component';
import { FilesUploadComponent } from './shared/components/files-upload/files-upload.component';
import { GetExcelDataComponent } from './shared/components/get-excel-data/get-excel-data.component';
import { HistoryComponent } from './shared/components/history/history.component';
import { ImportExportButtonComponent } from './shared/components/import-export-button/import-export-button.component';
import { NumericTextboxComponent } from './shared/components/money-textbox/numeric-textbox.component';
import { MultipleFilesUploadComponent } from './shared/components/multiple-files-upload/multiple-files-upload.component';
import { OtpInputComponent } from './shared/components/otp-input/otp-input.component';
import { SearchArticleModalComponent } from './shared/components/search-article-modal/search-article-modal.component';
import { SearchBarcodeModalComponent } from './shared/components/search-barcode-modal/search-barcode-modal.component';
import { SearchMultipleArticleModalComponent } from './shared/components/search-multiple-article-modal/search-multiple-article-modal.component';
import { SearchProductModalComponent } from './shared/components/search-product-modal/search-product-modal.component';
import { ShelfDetailsComponent } from './shared/components/shelf-details/shelf-details.component';
import { StepProgressComponent } from './shared/components/step-progress/step-progress.component';
import { LegalDocumentComponent } from './shared/components/td-store/legal-document/legal-document.component';
import { MerchantProfileComponent } from './shared/components/td-store/merchant-profile/merchant-profile.component';
import { OrderPolicyScheduleComponent } from './shared/components/td-store/order-policy/order-policy-schedule/order-policy-schedule.component';
import { OrderPolicyComponent } from './shared/components/td-store/order-policy/order-policy.component';
import { UpdateOrderPolicyComponent } from './shared/components/td-store/order-policy/update-order-policy/update-order-policy.component';
import { OwnerProfileComponent } from './shared/components/td-store/owner-profile/owner-profile.component';
import { DeliveryTimesComponent } from './shared/components/td-store/store-profile/delivery-times/delivery-times.component';
import { NearByCompetitorComponent } from './shared/components/td-store/store-profile/near-by-competitor/near-by-competitor.component';
import { NearByPoiComponent } from './shared/components/td-store/store-profile/near-by-poi/near-by-poi.component';
import { StoreProfileComponent } from './shared/components/td-store/store-profile/store-profile.component';
import { TierTagComponent } from './shared/components/tier-tag/tier-tag.component';
import { UploadDocumentButtonComponent } from './shared/components/upload-document-button/upload-document-button.component';
import { AlphabetOnlyDirective } from './shared/directives/alphabet-only.directive';
import { AlphanumericOnlyDirective } from './shared/directives/alphanumeric-only.directive';
import { AppSrcDirective } from './shared/directives/app-src.directive';
import { AssortmentStatusDirective } from './shared/directives/assortment-status.directive';
import { ClickOutsideDirective } from './shared/directives/click-outside.directive';
import { DecimalPlacesDirective } from './shared/directives/decimal-places.directive';
import { DigitOnlyDirective } from './shared/directives/digit-only.directive';
import { DisableButtonDirective } from './shared/directives/disable-button.directive';
import { DisallowSpacesDirective } from './shared/directives/disallow-spaces.directive';
import { HasPermissionDirective } from './shared/directives/has-permission.directive';
import { HexadecimalOnlyDirective } from './shared/directives/hexadecimal-only.directive';
import { OtpNumberDirective } from './shared/directives/otp-number.directive';
import { PascalCaseDirective } from './shared/directives/pascal-case.directive';
import { PreventDoubleClickDirective } from './shared/directives/prevent-double-click.directive';
import {
  CacheInterceptor,
  ErrorInterceptor,
  SpinnerLoaderInterceptor,
  TokenInterceptor
} from './shared/http-interceptors';
import { AlertModalComponent, FooterComponent, HeaderComponent, LoginComponent } from './shared/layouts';
import { AdminLayoutComponent } from './shared/layouts/admin/admin-layout.component';
import { LoginLayoutComponent } from './shared/layouts/login-layout/login-layout.component';
import { ConfirmModalComponent } from './shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from './shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ConfirmWithOptionModalComponent } from './shared/layouts/modals/confirm-with-option-modal/confirm-with-option-modal.component';
import { ChildDirective } from './shared/layouts/modals/full-modal/child-directive';
import { FullModalComponent } from './shared/layouts/modals/full-modal/full-modal.component';
import { ImageModalComponent } from './shared/layouts/modals/image-modal/image-modal.component';
import { SimpleInfoListModalComponent } from './shared/layouts/modals/simple-info-list-modal/simple-info-list-modal.component';
import { NavbarComponent } from './shared/layouts/navbar/navbar.component';
import { SearchPaginationComponent } from './shared/layouts/search-pagination/search-pagination.component';
import { SidebarComponent } from './shared/layouts/sidebar/sidebar.component';
import { TabComponent } from './shared/layouts/tab/tab.component';
import { UiPaginationComponent } from './shared/layouts/ui-pagination/ui-pagination.component';
import { VersionTagComponent } from './shared/layouts/version-tag/version-tag.component';
import { AdditionPipe } from './shared/pipes/addition.pipe';
import { ArraySortPipe } from './shared/pipes/array-sort.pipe';
import { IsDevEnvPipe } from './shared/pipes/is-dev.pipe';
import { MasterDataDisplayPipe } from './shared/pipes/master-data-display.pipe';
import {
  BooleanDisplayPipe,
  DashDisplayPipe,
  DateDisplayPipe,
  KeysPipe,
  NameThDisplayPipe,
  NumberFormatDisplayPipe,
  PeriodDisplayPipe,
  StockQuantityPipe
} from './shared/pipes/Pipes';
import { SetTimeoutPipe } from './shared/pipes/set-timeout.pipe';
import {
  AuthGuardService,
  AuthService,
  BarcodeService,
  MasterDataService,
  ProductAssortmentService,
  ProductMasterService,
  ProductService,
  RequestAssortmentService,
  VoucherRequestService
} from './shared/services';
import { AppComponentService } from './shared/services/app-component.service';
import { AppInitService } from './shared/services/app-init.service';
import { ArticleService } from './shared/services/article.service';
import { ClaimService } from './shared/services/claim.service';
import { ClassMarkupService } from './shared/services/class-markup.service';
import { CommonUploadService } from './shared/services/common.upload.service';
import { DashboardService } from './shared/services/dashboard.service';
import { DeletedBillReportService } from './shared/services/deleted-bill-report.service';
import { DeliveryOrderService } from './shared/services/delivery-order.service';
import { DisableButtonService } from './shared/services/disable-button.service';
import { FileUrlService } from './shared/services/file-url.service';
import { FreshLiteService } from './shared/services/fresh-lite.service';
import { GoodsReceiveService } from './shared/services/goods-receive.service';
import { GroupSupplierStoresService } from './shared/services/group-supplier-stores.service';
import { ManualSupplierPriceDataService } from './shared/services/manual-supplier-price-data.service';
import { MasterService } from './shared/services/master.service';
import { MembershipService } from './shared/services/membership.service';
import { MerchantRequestService } from './shared/services/merchant-request.service';
import { MerchantSelectValueService } from './shared/services/merchant-select-value.service';
import { MerchantService } from './shared/services/merchant.service';
import { MonthlyPartnerProfitSharingService } from './shared/services/monthly-partner-profit-sharing.service';
import { MovingAverageReportService } from './shared/services/moving-average-report.service';
import { OrderRequestService } from './shared/services/order-request.service';
import { OrderService } from './shared/services/order.service';
import { PermissionsService } from './shared/services/permissions.service';
import { ProfitSharingService } from './shared/services/profit-sharing.service';
import { PromotionCompensateReportService } from './shared/services/promotion-compensate-report.service';
import { PromotionRequestService } from './shared/services/promotion-request.service';
import { PromotionService } from './shared/services/promotion.service';
import { PurchaseOrderService } from './shared/services/purchase-order.service';
import { PurchaseRequestService } from './shared/services/purchase-request.service';
import { ReceiveOrderService } from './shared/services/receive-order.service';
import { RoleService } from './shared/services/role.service';
import { SalesSummaryReportService } from './shared/services/sales-summary-report.service';
import { SalesTransactionReportService } from './shared/services/sales-transaction-report.service';
import { SettingService } from './shared/services/setting.service';
import { ShelfRequestService } from './shared/services/shelf-request.service';
import { ShelfTypesService } from './shared/services/shelf-types.service';
import { ShelfService } from './shared/services/shelf.service';
import { ShipmentService } from './shared/services/shipment.service';
import { StackPricingService } from './shared/services/stack-pricing.service';
import { StockInformationService } from './shared/services/stock-information.service';
import { StockLocationsService } from './shared/services/stock-locations.service';
import { StoreAssortmentRequestService } from './shared/services/store-assortment-request.service';
import { StoreAssortmentService } from './shared/services/store-assortment.service';
import { StoreGroupService } from './shared/services/store-group.service';
import { StoreRequestService } from './shared/services/store-request.service';
import { StoreService } from './shared/services/store.service';
import { StoresService } from './shared/services/stores.service';
import { SupplierGroupRequestService } from './shared/services/supplier-group-request.service';
import { SupplierGroupService } from './shared/services/supplier-group.service';
import { SupplierPriceConfigService } from './shared/services/supplier-price-config.service';
import { SupplierStoreService } from './shared/services/supplier-store.service';
import { SupplierService } from './shared/services/supplier.service';
import { TdAssortmentService } from './shared/services/td-assortment-service';
import { ToteAssetLocationService } from './shared/services/tote-asset-location.service';
import { ToteTransactionService } from './shared/services/tote-transaction.service';
import { VoucherReportService } from './shared/services/voucher-report.service';
import { VoucherService } from './shared/services/voucher.service';
import { WarehouseService } from './shared/services/warehouse.service';
import { Z8ConditionsService } from './shared/services/z8-conditions.service';
import { Z8ParameterDataService } from './shared/services/z8-parameter-data.service';
import { Z8ParameterRequestService } from './shared/services/z8-parameter-request-service';
import { Z8ParameterService } from './shared/services/z8-parameter.service';
import { Z8ResultService } from './shared/services/z8-result.service';
import { AdjustStockEffects } from './shared/store/effects/adjust-stock.effects';
import { AuthEffects } from './shared/store/effects/auth.effects';
import { BillToMasterEffects } from './shared/store/effects/bill-to-master.effects';
import { ClaimEffects } from './shared/store/effects/claim-request.effects';
import { ClassMarkupEffects } from './shared/store/effects/class-markup.effects';
import { DashboardEffects } from './shared/store/effects/dashboard.effects';
import { DeliveryOrderEffects } from './shared/store/effects/delivery-order.effects';
import { FirstLotOrderEffects } from './shared/store/effects/first-lot-order.effects';
import { ForgotPasswordEffects } from './shared/store/effects/forgot-password.effects';
import { ForgotUsernameEffects } from './shared/store/effects/forgot-username.effects';
import { FreshLiteEffects } from './shared/store/effects/fresh-lite.effects';
import { GoodsReceiveEffects } from './shared/store/effects/goods-receive.effects';
import { LayoutEffects } from './shared/store/effects/layout.effects';
import { MasterAssortmentEffects } from './shared/store/effects/master-assortment.effects';
import { MasterDataEffects } from './shared/store/effects/master-data.effects';
import { MemberCardEffects } from './shared/store/effects/member-card.effects';
import { MenuEffects } from './shared/store/effects/menu.effects';
import { MerchantSelectValueEffects } from './shared/store/effects/merchant-select-value.effects';
import { MerchantEffects } from './shared/store/effects/merchant.effects';
import { MonthlyPartnerProfitSharingEffects } from './shared/store/effects/monthly-partner-profit-sharing.effects';
import { OrderRequestEffects } from './shared/store/effects/order-request.effects';
import { OrderSelectItemEffects } from './shared/store/effects/order-select-item.effects';
import { OrderEffects } from './shared/store/effects/order.effects';
import { ProductAssortmentEffects } from './shared/store/effects/product-assortment.effects';
import { ProductMasterEffects } from './shared/store/effects/product-master.effects';
import { ProductEffects } from './shared/store/effects/product.effects';
import { ProfitSharingEffects } from './shared/store/effects/profit-sharing.effects';
import { PromotionEffects } from './shared/store/effects/promotion.effects';
import { PurchaseOrderEffects } from './shared/store/effects/purchase-order.effects';
import { PurchaseRequestEffects } from './shared/store/effects/purchase-request.effects';
import { ReceiveOrderEffects } from './shared/store/effects/receive-order.effects';
import { RequestAssortmentEffects } from './shared/store/effects/request-assortment.effects';
import { RoleEffects } from './shared/store/effects/role.effects';
import { SettingEffects } from './shared/store/effects/setting.effects';
import { ShelfRequestEffects } from './shared/store/effects/shelf-request.effects';
import { ShelfEffects } from './shared/store/effects/shelf.effects';
import { ShipmentEffects } from './shared/store/effects/shipment.effects';
import { SpecialAssortmentEffects } from './shared/store/effects/special-assortment.effects';
import { StackPricingEffects } from './shared/store/effects/stack-pricing.effects';
import { StockInformationEffects } from './shared/store/effects/stock-information.effects';
import { StoreAssortmentRequestEffects } from './shared/store/effects/store-assortment-request.effects';
import { StoreAssortmentEffects } from './shared/store/effects/store-assortment.effects';
import { StoreGroupEffects } from './shared/store/effects/store-group.effects';
import { StoreRequestEffects } from './shared/store/effects/store-request.effects';
import { StoreEffects } from './shared/store/effects/store.effects';
import { SupplierGroupRequestEffects } from './shared/store/effects/supplier-group-request.effects';
import { SupplierGroupEffects } from './shared/store/effects/supplier-group.effects';
import { SupplierPriceEffects } from './shared/store/effects/supplier-price.effects';
import { SupplierStoreEffects } from './shared/store/effects/supplier-store.effects';
import { TdAssortmentEffects } from './shared/store/effects/td-assortment.effects';
import { UserEffects } from './shared/store/effects/user-info.effects';
import { VoucherRequestEffects } from './shared/store/effects/voucher-request.effects';
import { VoucherEffects } from './shared/store/effects/voucher.effects';
import { WarehouseMasterEffects } from './shared/store/effects/warehouse-master-effects';
import { WarehouseEffects } from './shared/store/effects/warehouse.effects';
import { Z8ConditionsEffects } from './shared/store/effects/z8-conditions.effects';
import { Z8ParameterRequestEffects } from './shared/store/effects/z8-parameter-request.effects';
import { Z8ParameterEffects } from './shared/store/effects/z8-parameter.effects';
import { Z8ResultEffects } from './shared/store/effects/z8-result.effects';
import { appReducers } from './shared/store/reducers/app.reducers';
import { clearState } from './shared/store/reducers/meta-reducers/clear-state';
import { CookiesUtil } from './shared/utils/cookies-util';
import { ExcelUtil } from './shared/utils/excel-util';
import { PermissionsUtil } from './shared/utils/permissions-util';
import { TDStoreWorkflowUtil } from './shared/utils/td-store-workflow-util';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function InitializeAppFactory(appInitService: AppInitService) {
  return appInitService.load();
}

// TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LoginRightComponent,
    LoginLeftComponent,
    DashboardComponent,
    HomeComponent,
    AdminLayoutComponent,
    NavbarComponent,
    SidebarComponent,
    ProductCreateComponent,
    ProductEditComponent,
    ProductListComponent,
    ProductViewComponent,
    DashDisplayPipe,
    BooleanDisplayPipe,
    NumberFormatDisplayPipe,
    NameThDisplayPipe,
    DateDisplayPipe,
    PeriodDisplayPipe,
    StockQuantityPipe,
    AdditionPipe,
    SetTimeoutPipe,
    ArraySortPipe,
    IsDevEnvPipe,
    AssortmentRequestListComponent,
    ClickOutsideDirective,
    AlertModalComponent,
    FullModalComponent,
    ChildDirective,
    ConfirmModalComponent,
    ConfirmWithMessageModalComponent,
    ConfirmWithOptionModalComponent,
    ProductViewHeaderComponent,
    NumericTextboxComponent,
    FileUploadComponent,
    AssortmentProductListComponent,
    AppSrcDirective,
    PreventDoubleClickDirective,
    TabComponent,
    ClassMarkupComponent,
    HistoryComponent,
    DigitOnlyDirective,
    DecimalPlacesDirective,
    DisallowSpacesDirective,
    HasPermissionDirective,
    StepProgressComponent,
    SearchPaginationComponent,
    StackPricingViewComponent,
    MerchantRequestComponent,
    MerchantRequestListComponent,
    MerchantListComponent,
    FilesUploadComponent,
    AssortmentStatusDirective,
    MultipleFilesUploadComponent,
    StoreProfileComponent,
    MerchantProfileComponent,
    OwnerProfileComponent,
    LegalDocumentComponent,
    SupplierPriceComponent,
    OrderPolicyComponent,
    OrderPolicyScheduleComponent,
    UpdateOrderPolicyComponent,
    OrderListComponent,
    OrderViewComponent,
    OrderDetailsComponent,
    ReceiveSummaryComponent,
    OrderRequestListComponent,
    OrderRequestComponent,
    SelectItemModalComponent,
    DeliveryDetailsComponent,
    DeliveryScheduleComponent,
    DeliveryDetailsSpecialComponent,
    DeliveryOrderListComponent,
    DeliveryOrderEditComponent,
    SelectShelfModalComponent,
    ShelfDetailsComponent,
    ShipmentRequestComponent,
    ViewMerchantComponent,
    DeliveryOrderImportComponent,
    StoreListComponent,
    StoreRequestListComponent,
    StoreRequestComponent,
    ShipmentListComponent,
    ViewStoreComponent,
    RegisterPOSComponent,
    POSTerminalComponent,
    ClaimRequestListComponent,
    ClaimRequestViewComponent,
    PromotionListComponent,
    PromotionRequestComponent,
    PromotionRequestListComponent,
    PromotionItemComponent,
    PromotionViewComponent,
    SearchProductModalComponent,
    SearchBarcodeModalComponent,
    SearchArticleModalComponent,
    SearchMultipleArticleModalComponent,
    PromotionDetailsComponent,
    PromotionConditionComponent,
    PromotionStoreComponent,
    SelectByStoreComponent,
    HexadecimalOnlyDirective,
    ImageModalComponent,
    AlphabetOnlyDirective,
    AlphanumericOnlyDirective,
    PurchaseRequestListComponent,
    PurchaseRequestViewComponent,
    TdAssortmentListComponent,
    VatDetailsModalComponent,
    PurchaseRequestDeliveryDetailsComponent,
    ProductChangeModalComponent,
    HandleThemeComponent,
    PurchaseOrderListComponent,
    PurchaseOrderViewComponent,
    LoginLayoutComponent,
    ForgotUsernameComponent,
    ForgotUsernameLeftComponent,
    PurchaseOrderViewComponent,
    GoodsReceiveViewComponent,
    GoodsReceiveListComponent,
    ForgotPasswordComponent,
    ForgotPasswordLeftComponent,
    KeysPipe,
    OtpNumberDirective,
    OtpInputComponent,
    MyTaskComponent,
    DeliveryTimesComponent,
    NearByPoiComponent,
    NearByCompetitorComponent,
    TdAssortmentImportComponent,
    VoucherRequestListComponent,
    VoucherRequestComponent,
    VoucherListComponent,
    VoucherViewComponent,
    SupplierGroupRequestListComponent,
    SupplierGroupRequestViewComponent,
    RoleCreateComponent,
    RoleEditComponent,
    MasterDataDisplayPipe,
    RoleListComponent,
    UserListComponent,
    UserCreateComponent,
    SupplierGroupImportComponent,
    SupplierGroupListComponent,
    StoreGroupListComponent,
    StoreGroupCreateComponent,
    ShelfRequestListComponent,
    ShelfRequestComponent,
    ShelfListComponent,
    ViewShelfComponent,
    ShelfInfoComponent,
    AddShelfTypeComponent,
    ShelfInventoryComponent,
    ShelfFixAssetComponent,
    UploadDocumentButtonComponent,
    SupplierGroupListComponent,
    AssortmentRequestComponent,
    AssortmentWholesalePriceComponent,
    AssortmentBarcodeComponent,
    SupplierDetailsComponent,
    CustomListboxComponent,
    AssortmentBarcodeComponent,
    AssortmentProductDetailsComponent,
    AddSupplierPriceComponent,
    AssortmentRequestFreshLiteComponent,
    VersionTagComponent,
    UserEditComponent,
    PascalCaseDirective,
    ManageStockListComponent,
    MemberCardListComponent,
    AdjustStockComponent,
    AdjustStockViewComponent,
    ForceChangePasswordComponent,
    ForceChangePasswordLeftComponent,
    SimpleInfoListModalComponent,
    EditInPlaceComponent,
    StockInformationComponent,
    StockInformationListComponent,
    StockAdjustmentDownloadModalComponent,
    StockAdjustmentFileUploadComponent,
    StockMovementComponent,
    BoxsDisplayComponent,
    UiPaginationComponent,
    ReceiveOrderListComponent,
    ReceiveOrderViewComponent,
    StockStoreComponent,
    ReportListComponent,
    VoucherModalComponent,
    StockCardModalComponent,
    SalesTransactionModalComponent,
    DeleteBillModalComponent,
    ToteTransactionModalComponent,
    ToteTransactionDCModalComponent,
    ToteTransactionFromStoreModalComponent,
    ToteAssetLocationModalComponent,
    MoneyInModalComponent,
    SalesSummaryModalComponent,
    InventoryCountTransactionTempModalComponent,
    MovingAverageModalComponent,
    DisableButtonDirective,
    InventoryCountTransactionTempModalComponent,
    MonthlyPartnerProfitSharingListComponent,
    StoreAssortmentListComponent,
    ViewStoreAssortmentComponent,
    MasterAssortmentComponent,
    SpecialAssortmentComponent,
    StoreAssortmentRequestListComponent,
    StoreAssortmentRequestComponent,
    StoreAssortmentRequestViewComponent,
    StoreAssortmentItemListComponent,
    StoreAssortmentFindStoreComponent,
    Z8FactorComponent,
    StoreAssortmentRequestViewComponent,
    StoreAssortmentRequestTableComponent,
    StoreAssortmentRequestSearchResultComponent,
    StoreAssortmentRequestStoreSelectedComponent,
    MonthlyPartnerProfitSharingDownloadModalComponent,
    MonthlyPartnerProfitSharingFileUploadComponent,
    ProfitSharingListComponent,
    CollapsibleHistoryComponent,
    ExportByProductComponent,
    ExportByStoreComponent,
    DropdownMultipleSelectComponent,
    ProfitSharingViewComponent,
    TierTagComponent,
    PromotionCompensateModalComponent,
    InventoryCountTransactionModalComponent,
    Z8ParameterListComponent,
    ViewParameterComponent,
    ImportDataComponent,
    GetExcelDataComponent,
    EditParameterComponent,
    ViewParameterListComponent,
    AddConditionComponent,
    ExportStoreStockPlanningComponent,
    Z8ParameterRequestListComponent,
    ViewParameterRequestComponent,
    Z8ResultComponent,
    TargetStockImportModalComponent,
    TargetStockImportFileUploadComponent,
    InventoryAdjustmentRequestModalComponent,
    InventoryVarianceModalComponent,
    ExportShelfComponent,
    AssortmentUnlinkCjProductModalComponent,
    ImportOrderRequestComponent,
    BillPaymentModalComponent,
    ImportExportButtonComponent,
    StockWarehouseComponent,
    GetExcelDataComponent,
    AssortmentSupplierPriceModalUploadComponent,
    AssortmentSupplierPriceFileUploadComponent,
    AssortmentCjProductDetailsComponent,
    AssortmentSelectWarehouseModalComponent,
    DcAssortmentModalComponent,
    PurchaseRequestModalUploadComponent,
    PurchaseRequestFileUploadComponent,
    DeliveryOrderImportModalComponent,
    DeliveryOrderFileUploadComponent,
    FilesInputComponent,
    FilesSubmitComponent,
    ManualAddEditSupplierPriceComponent
  ],
  imports: [
    AlertModule.forRoot(),
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserModule,
    ModalModule.forRoot(),
    BrowserAnimationsModule,
    NumericTextboxModule,
    NgSelectModule,
    AppRoutingModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
    StoreModule.forRoot(appReducers, { metaReducers: [clearState] }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 15
        })
      : [],
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forRoot([
      AuthEffects,
      ProductEffects,
      ProductMasterEffects,
      RequestAssortmentEffects,
      ProductAssortmentEffects,
      ClassMarkupEffects,
      MasterDataEffects,
      LayoutEffects,
      SettingEffects,
      StackPricingEffects,
      MerchantSelectValueEffects,
      MerchantEffects,
      MenuEffects,
      FirstLotOrderEffects,
      MenuEffects,
      OrderEffects,
      OrderRequestEffects,
      OrderSelectItemEffects,
      DeliveryOrderEffects,
      ShipmentEffects,
      StoreEffects,
      StoreRequestEffects,
      ClaimEffects,
      StockInformationEffects,
      UserEffects,
      PromotionEffects,
      PurchaseRequestEffects,
      TdAssortmentEffects,
      ForgotUsernameEffects,
      PurchaseOrderEffects,
      GoodsReceiveEffects,
      TdAssortmentEffects,
      DashboardEffects,
      ForgotPasswordEffects,
      VoucherRequestEffects,
      StoreGroupEffects,
      VoucherEffects,
      RoleEffects,
      AdjustStockEffects,
      SupplierGroupRequestEffects,
      SupplierGroupEffects,
      SupplierStoreEffects,
      ShelfRequestEffects,
      ShelfEffects,
      VoucherRequestEffects,
      WarehouseEffects,
      WarehouseMasterEffects,
      FreshLiteEffects,
      OrderRequestEffects,
      BillToMasterEffects,
      ReceiveOrderEffects,
      StoreAssortmentEffects,
      StoreAssortmentRequestEffects,
      MasterAssortmentEffects,
      MemberCardEffects,
      MonthlyPartnerProfitSharingEffects,
      SpecialAssortmentEffects,
      ProfitSharingEffects,
      Z8ParameterEffects,
      Z8ParameterRequestEffects,
      Z8ConditionsEffects,
      Z8ResultEffects,
      SupplierPriceEffects
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoggerModule.forRoot({
      level: environment.production
        ? NgxLoggerLevel.OFF
        : ['develop', 'redseries', 'greenapple', 'galaxy', 'khamoo', 'khaohom', 'integration'].includes(
            environment.tier
          )
        ? NgxLoggerLevel.TRACE
        : NgxLoggerLevel.INFO,
      serverLogLevel: NgxLoggerLevel.OFF
    }),
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    PopoverModule.forRoot(),
    MomentModule,
    NgxIntlTelInputModule,
    TippyModule,
    TabsModule.forRoot(),
    SortableModule.forRoot(),
    DatepickerModule,
    NgxSpinnerModule,
    CollapseModule,
    InputTrimModule,
    NgxMaskModule.forRoot(),
    NgOptionHighlightModule,
    ButtonsModule,
    GraphQLModule
  ],
  entryComponents: [
    AlertModalComponent,
    ConfirmModalComponent,
    FullModalComponent,
    ConfirmWithMessageModalComponent,
    ConfirmWithOptionModalComponent,
    HistoryComponent,
    SelectShelfModalComponent,
    SelectItemModalComponent,
    DeliveryDetailsComponent,
    DeliveryDetailsSpecialComponent,
    ShipmentRequestComponent,
    ShelfDetailsComponent,
    RegisterPOSComponent,
    ImageModalComponent,
    TdAssortmentListComponent,
    VatDetailsModalComponent,
    PromotionViewComponent,
    PurchaseRequestDeliveryDetailsComponent,
    ProductChangeModalComponent,
    PurchaseOrderViewComponent,
    VoucherViewComponent,
    RoleEditComponent,
    SupplierGroupRequestViewComponent,
    UpdateOrderPolicyComponent,
    AddShelfTypeComponent,
    UpdateOrderPolicyComponent,
    AssortmentRequestComponent,
    AddSupplierPriceComponent,
    UserEditComponent,
    AdjustStockComponent,
    AdjustStockViewComponent,
    AssortmentRequestFreshLiteComponent,
    SimpleInfoListModalComponent,
    SelectShelfModalComponent,
    ReceiveOrderViewComponent,
    Z8FactorComponent,
    MonthlyPartnerProfitSharingDownloadModalComponent,
    CollapsibleHistoryComponent,
    SelectByStoreComponent,
    StockAdjustmentDownloadModalComponent,
    StockAdjustmentFileUploadComponent,
    ExportByProductComponent,
    ExportByStoreComponent,
    ImportDataComponent,
    EditParameterComponent,
    ViewParameterListComponent,
    AddConditionComponent,
    ExportStoreStockPlanningComponent,
    TargetStockImportModalComponent,
    TargetStockImportFileUploadComponent,
    ExportShelfComponent,
    ExportByStoreComponent,
    AssortmentUnlinkCjProductModalComponent,
    ImportOrderRequestComponent,
    AssortmentSupplierPriceModalUploadComponent,
    AssortmentSelectWarehouseModalComponent,
    PurchaseRequestModalUploadComponent,
    DeliveryOrderImportModalComponent,
    FilesSubmitComponent,
    ManualAddEditSupplierPriceComponent
  ],
  providers: [
    AppInitService,
    AuthService,
    ProductService,
    AuthGuardService,
    ProductMasterService,
    CookieService,
    RequestAssortmentService,
    ProductAssortmentService,
    ClassMarkupService,
    MasterDataService,
    CommonUploadService,
    BarcodeService,
    ArticleService,
    SettingService,
    StackPricingService,
    ShelfRequestService,
    StockInformationService,
    MembershipService,
    StockLocationsService,
    ShelfService,
    MerchantSelectValueService,
    MerchantRequestService,
    MerchantService,
    SupplierService,
    ShelfTypesService,
    OrderService,
    OrderRequestService,
    ReceiveOrderService,
    DeliveryOrderService,
    DashboardService,
    ShipmentService,
    StoreService,
    StoreGroupService,
    StoreRequestService,
    ClaimService,
    TDStoreWorkflowUtil,
    CookiesUtil,
    PromotionService,
    PromotionRequestService,
    PurchaseRequestService,
    TdAssortmentService,
    PurchaseOrderService,
    GoodsReceiveService,
    VoucherRequestService,
    VoucherService,
    RoleService,
    PermissionsService,
    SupplierGroupRequestService,
    SupplierGroupService,
    SupplierStoreService,
    MasterService,
    GroupSupplierStoresService,
    DecimalPipe,
    FileUrlService,
    KeysPipe,
    WarehouseService,
    FreshLiteService,
    VoucherReportService,
    SalesTransactionReportService,
    DeletedBillReportService,
    DisableButtonService,
    SalesSummaryReportService,
    StoreAssortmentService,
    StoreAssortmentRequestService,
    MonthlyPartnerProfitSharingService,
    ProfitSharingService,
    PromotionCompensateReportService,
    MovingAverageReportService,
    Z8ParameterDataService,
    Z8ParameterService,
    Z8ParameterRequestService,
    Z8ConditionsService,
    Z8ResultService,
    ToteTransactionService,
    SupplierPriceConfigService,
    ToteAssetLocationService,
    ExcelUtil,
    DatePipe,
    PermissionsUtil,
    StoresService,
    ManualSupplierPriceDataService,
    { provide: APP_INITIALIZER, useFactory: InitializeAppFactory, deps: [AppInitService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerLoaderInterceptor, multi: true },
    { provide: 'app-component-service', useValue: AppComponentService },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  exports: [OtpInputComponent, CustomListboxComponent],
  bootstrap: [AppComponent]
})
export class AppModule extends HmrModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
