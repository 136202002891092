import { createSelector } from '@ngrx/store';
import * as fromPrmotionState from '../reducers/promotion.reducers';
import { AppStates } from '../state/app.states';

const selectPromotionState = (state: AppStates) => state.promotion;

export const selectAllPromotionList = createSelector(selectPromotionState, fromPrmotionState.selectAll);

export const selectPromotionList = createSelector(
  selectPromotionState,
  (state: fromPrmotionState.PromotionState) => state
);

export const selectPromotionListCriteria = createSelector(selectPromotionState, state => {
  return state.criteriaObject;
});

export const selectPromotionCancelStatus = createSelector(
  selectPromotionState,
  (state: fromPrmotionState.PromotionState) => {
    return state.isCancelSuccess;
  }
);
