import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SupplierStoreContent } from '../../models/supplier-group-request.model';
import { SupplierStoreActions, SupplierStoreActionTypes } from '../actions/supplier-store.actions';
import { BaseState } from '../state/base.state';

export interface SupplierStoreState extends EntityState<SupplierStoreContent>, BaseState {
  isLoading: boolean;
  totalElements: number;
  totalPages: number;
}

// Change code to id
export const adapter: EntityAdapter<SupplierStoreContent> = createEntityAdapter<SupplierStoreContent>({
  selectId: (model: SupplierStoreContent) => (model.id ? model.id : model.store.code)
});

export const initialSupplierStoreState: SupplierStoreState = adapter.getInitialState({
  isLoading: false,
  totalElements: 0,
  totalPages: 0
});

export function supplierStoreReducers(
  state = initialSupplierStoreState,
  action: SupplierStoreActions
): SupplierStoreState {
  switch (action.type) {
    case SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_REQUEST:
      return {
        ...state
      };
    case SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_RESPONSE:
    case SupplierStoreActionTypes.REQUEST_STORE_SUPPLIER_LIST_RESPONSE:
    case SupplierStoreActionTypes.STORE_SUPPLIER_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierStoreActionTypes.SUPPLIER_STORE_RESET:
      return {
        ...state,
        ids: [],
        entities: {}
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
