import { Action } from '@ngrx/store';
import {
  PurchaseRequest,
  PurchaseRequestApproveRequestData,
  PurchaseRequestCancelRequestData,
  PurchaseRequestDeliveryDetail,
  PurchaseRequestDeliveryDetailCriteria,
  PurchaseRequestErrorResponse,
  PurchaseRequestPagination,
  PurchaseRequestRejectRequestData,
  PurchaseRequestSearchCriteria
} from '../../models/purchase-request.model';

export enum PurchaseRequestActionTypes {
  PURCHASE_REQUEST_VIEW_REQUESTED = '[Purchase Request View Page] Purchase Request View Request',
  PURCHASE_REQUEST_VIEW_LOADED = '[Purchase Request API] Purchase Request View Loaded',
  PURCHASE_REQUEST_LIST_REQUEST = '[Purchase Request List Page] Purchase Request List Request',
  PURCHASE_REQUEST_LIST_RESPONSE = '[Purchase Request List API] Purchase Request List Response',
  PURCHASE_REQUEST_RESET = '[Purchase Request View Page] Purchase Request Reset',
  PURCHASE_REQUEST_SAVE = '[Purchase Request View Page] Purchase Request Save Request',
  PURCHASE_REQUEST_SUBMIT_REQUEST = '[Purchase Request Edit Page] Purchase Request Submit Request',
  PURCHASE_REQUEST_ERROR_RESPONSE = '[Purchase Request Edit Page] Purchase Request Submit Error Response',
  PURCHASE_REQUEST_DELIVERY_DETAIL_REQUEST = '[Purchase Request VIEW Page] Purchase Request Delivery Detail Request',
  PURCHASE_REQUEST_DELIVERY_DETAIL_RESPONSE = '[Purchase Request VIEW Page] Purchase Request Delivery Detail Response',
  PURCHASE_REQUEST_DELIVERY_DETAIL_RESET = '[Purchase Request VIEW Page] Purchase Request Delivery Detail Reset',
  PURCHASE_REQUEST_DELETE_REQUEST = '[Purchase Request View Page] Purchase Request Delete Request',
  PURCHASE_REQUEST_CANCEL_REQUEST = '[Purchase Request View Page] Purchase Request Cancel Request',
  PURCHASE_REQUEST_APPROVE_REQUEST = '[Purchase Request View Page] Purchase Request Approve Request',
  PURCHASE_REQUEST_REJECT_REQUEST = '[Purchase Request View Page] Purchase Request Reject Request'
}

export class PurchaseRequestViewRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class PurchaseRequestViewLoaded implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_VIEW_LOADED;

  constructor(public payload: PurchaseRequest) {}
}

export class PurchaseRequestListRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_LIST_REQUEST;

  constructor(public payload: PurchaseRequestSearchCriteria) {}
}

export class PurchaseRequestListResponse implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_LIST_RESPONSE;

  constructor(public payload: PurchaseRequestPagination) {}
}

export class PurchaseRequestReset implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_RESET;

  constructor() {}
}

export class PurchaseRequestSaveRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_SAVE;

  constructor(public payload: PurchaseRequest) {}
}

export class PurchaseRequestSubmitRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_SUBMIT_REQUEST;

  constructor(public payload: PurchaseRequest) {}
}

export class PurchaseRequestSubmitErrorResponse implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_ERROR_RESPONSE;

  constructor(public payload: PurchaseRequestErrorResponse) {}
}

export class PurchaseRequestDeleteRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_DELETE_REQUEST;

  constructor(public payload: string) {}
}

export class PurchaseRequestCancelRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_CANCEL_REQUEST;

  constructor(public payload: PurchaseRequestCancelRequestData) {}
}

export class PurchaseRequestDeliveryDetailRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_REQUEST;

  constructor(public payload: PurchaseRequestDeliveryDetailCriteria) {}
}

export class PurchaseRequestDeliveryDetailResponse implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_RESPONSE;

  constructor(public payload: PurchaseRequestDeliveryDetail) {}
}

export class PurchaseRequestDeliveryDetailReset implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_RESET;

  constructor() {}
}

export class PurchaseRequestApproveRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_APPROVE_REQUEST;

  constructor(public payload: PurchaseRequestApproveRequestData) {}
}

export class PurchaseRequestRejectRequest implements Action {
  readonly type = PurchaseRequestActionTypes.PURCHASE_REQUEST_REJECT_REQUEST;

  constructor(public payload: PurchaseRequestRejectRequestData) {}
}

export type PurchaseRequestActions =
  | PurchaseRequestViewRequest
  | PurchaseRequestViewLoaded
  | PurchaseRequestListRequest
  | PurchaseRequestListResponse
  | PurchaseRequestReset
  | PurchaseRequestSaveRequest
  | PurchaseRequestSubmitRequest
  | PurchaseRequestSubmitErrorResponse
  | PurchaseRequestDeliveryDetailRequest
  | PurchaseRequestDeliveryDetailResponse
  | PurchaseRequestDeliveryDetailReset
  | PurchaseRequestSubmitErrorResponse
  | PurchaseRequestDeleteRequest
  | PurchaseRequestCancelRequest
  | PurchaseRequestApproveRequest
  | PurchaseRequestRejectRequest;
