import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  ErrorResponse,
  RouteLinkTab,
  StoreExportCriteria,
  StoreList,
  StoreListSearchCriteria
} from '../../../shared/models';
import { AuditLog, HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import { StoreService } from '../../../shared/services/store.service';
import { LayoutActionLoadError } from '../../../shared/store/actions/layout.action';
import {
  StoreListHistoryRequestAction,
  StoreListHistoryResponseAction,
  StoreListRequestAction
} from '../../../shared/store/actions/store.actions';
import { StoreState } from '../../../shared/store/reducers/store.reducers';
import {
  selectAllStoreList,
  selectStoreList,
  selectStoreListCriteria,
  selectStoreListHistory
} from '../../../shared/store/selectors/store.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ViewStoreComponent } from '../view-store/view-store.component';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
  styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent extends BaseSearchComponent<StoreListSearchCriteria, StoreList, StoreState> {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public searchTag: string;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public storeTypeTag: string;
  public storeTypeStringTag = 'Store Type';
  public regionTag: string;
  public regionStringTag = 'Region';
  public stateTag: string;
  public stateStringTag = 'Province';

  public storeStatusList = filterDropdown.listStatusFilter;
  public storeTypeList: Array<NewMasterData> | null;
  public regionList: Array<NewMasterData> | null;
  public stateList: Array<NewMasterData> | null;
  public exportStatusList = filterDropdown.listStatusFilter.filter(v => Boolean(v.value));

  public auditLogs$: Observable<AuditLog[]>;
  dateFormat = environment.dateFormat;
  public masterDataEnum = MasterDataEnum;

  public exportForm: FormGroup;
  public errorExport: string;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected storeRequestService: StoreRequestService,
    protected storeService: StoreService,
    protected masterService: MasterService,
    protected bsModalService: BsModalService
  ) {
    super(store, modalService, selectAllStoreList, selectStoreList);
  }

  doInit() {
    this.createExportForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStoreListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT, MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
        this.regionList = result[MasterDataEnum.REGION];
        this.stateList = result[MasterDataEnum.STATE];
      });

    this.auditLogs$ = this.localStore.pipe(select(selectStoreListHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.storeStatusList[0].value],
      startCreatedDate: [null],
      endCreatedDate: [null],
      storeType: [null],
      region: [null],
      state: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      storeType: [null],
      status: [null],
      firstLotOnly: false
    });
  }

  onchangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      startCreatedDate: null,
      endCreatedDate: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterStoreType() {
    this.clearFilter('storeType');
  }

  clearFilterRegion() {
    this.clearFilter('region');
  }

  clearFilterState() {
    this.clearFilter('state');
  }

  clearFilter(controlName) {
    this.searchForm.controls[controlName].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      [controlName]: this.searchForm.controls[controlName].value
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['startCreatedDate'].reset();
    this.searchForm.controls['endCreatedDate'].reset();
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['state'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: null,
      endCreatedDate: null,
      storeType: null,
      region: null,
      state: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (
      !(
        formValue.startCreatedDate ||
        formValue.endCreatedDate ||
        formValue.storeType ||
        formValue.region ||
        formValue.state
      )
    ) {
      return;
    }

    let dateFrom = formValue.startCreatedDate;
    let dateTo = formValue.endCreatedDate;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      startCreatedDate: dateFrom,
      endCreatedDate: dateTo,
      storeType: formValue.storeType && formValue.storeType.length ? formValue.storeType.toString() : null,
      region: formValue.region && formValue.region.length ? formValue.region.toString() : null,
      state: formValue.state && formValue.state.length ? formValue.state.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.storeTypeTag = null;
    this.regionTag = null;
    this.stateTag = null;

    const startCreatedDate = dateStringToTagCriteria(this.criteriaObject.startCreatedDate);
    const endCreatedDate = dateStringToTagCriteria(this.criteriaObject.endCreatedDate);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: startCreatedDate,
      dateTo: endCreatedDate
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.storeType && this.criteriaObject.storeType.length) {
      const storeTypes = this.storeTypeList
        .filter(data => this.criteriaObject.storeType.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.storeTypeTag = `"${storeTypes}"`;
    }

    if (this.criteriaObject.state && this.criteriaObject.state.length) {
      const states = this.stateList
        .filter(data => this.criteriaObject.state.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.stateTag = `"${states}"`;
    }

    if (this.criteriaObject.region && this.criteriaObject.region.length) {
      const regions = this.regionList
        .filter(data => this.criteriaObject.region.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.regionTag = `"${regions}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new StoreListRequestAction(criteriaObj));
  }

  handleEdit(result: StoreList) {
    this.storeRequestService
      .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, result.no)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        res => {
          if (res.body.allowToEdit) {
            this.goToView(RequestPageModesEnum.REQUEST_EDIT, { merchant: result.merchant, no: result.no });
          } else {
            this.alertModal('Alert', 'Another request is awaiting approval.');
          }
        },
        error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      );
  }

  goToView(mode: RequestPageModesEnum, data?: any) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewStoreComponent,
        {
          title: mode === RequestPageModesEnum.REQUEST_EDIT ? 'Edit Store' : 'View Store',
          mode,
          storeNo: data.no,
          merchant: data.merchant
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  showHistory(storeList: StoreList) {
    this.store.dispatch(new StoreListHistoryRequestAction({ storeNo: storeList.no }));
    const initialState = {
      title: 'History',
      historyHeader: `Store: ${storeList.code}-${storeList.name}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreListHistoryResponseAction({ auditLogs: null }));
    });
  }

  getColorStatus(status: string): string {
    return status.toLowerCase();
  }

  get pageMode() {
    return RequestPageModesEnum;
  }

  hasStoreEditPermission() {
    return this.authGuardService.checkPermission(['merchant_edit_m']);
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission([
      'store_v',
      'store_eq',
      'merchant_edit_m',
      'merchant_order_m'
    ]);
    const hasRequestPagePermission = this.authGuardService.checkPermission([
      'merchant_new_m',
      'merchant_edit_m',
      'merchant_order_m',
      'merchant_app'
    ]);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Store List', url: '/merchant-store/store-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Store Request', url: '/merchant-store/store-request-list' });
    }
  }

  onOpenExportModal() {
    this.exportForm.reset();
    this.errorExport = null;
    this.exportModal.show();
  }

  onExport() {
    const fileConfig = environment.fileName.exportStore;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.storeService.exportStore(request).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.exportModal.hide()
    );
  }

  prepareExportRequestData(formData): StoreExportCriteria {
    return new StoreExportCriteria({
      storeType: this.convertArrayFilter(formData.storeType),
      status: this.convertArrayFilter(formData.status),
      firstLotOnly: formData.firstLotOnly
    });
  }

  convertArrayFilter(value) {
    return value && value.length > 0 ? value.toString() : null;
  }

  onActivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to activate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeService
            .activateStore({ storeNo })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The store has been activated.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  onDeactivateStore(storeNo) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to deactivate?',
        okText: 'Submit',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeRequestService
            .getStoreValidate(TDStoreValidatorTypeEnum.REQUESTED, storeNo)
            .pipe(
              untilComponentDestroyed(this),
              filter(v => Boolean(v))
            )
            .subscribe(
              res => {
                if (res.body.allowToEdit) {
                  this.deactivateStore(storeNo);
                } else {
                  this.alertModal('Failed', 'The request is awaiting approval.');
                }
              },
              error => {
                this.store.dispatch(new LayoutActionLoadError(error));
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  deactivateStore(storeNo) {
    this.storeService
      .deactivateStore({ storeNo })
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        () => {
          this.alertSuccessModal('The store has been deactivated.');
        },
        error => {
          this.alertErrorModal(error.error);
        }
      );
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalService.hide(1);
        this.setFirstPage();
        this.search({ ...this.criteriaObject, page: 0 });
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  alertModal(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }
}
