<div class="row section-header">
  <span>CJ Supplier Price</span>
</div>

<table
  id="supplierPriceTable"
  class="table table-striped table-bordered table-hover border-none table-with-child"
  *ngIf="supplierPrices && supplierPrices.length > 0; else dashTable"
>
  <thead>
    <tr>
      <th class="text-center align-top">Supplier Name</th>
      <th class="text-center align-top">Order Barcode</th>
      <th class="text-center align-top">Order Unit</th>
      <th class="text-center align-top">Unit Factor</th>
      <th class="text-center align-top">Supplier Price (ex VAT)</th>
      <th class="text-center align-top">AVG. Supplier Price (ex VAT)/Base Unit</th>
      <th class="text-center align-top">Effective Date</th>
      <th class="text-center align-top">Expire Date</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let cjSupplierPrice of supplierPrices; index as i; trackBy: trackCjSupplierPrices">
      <td class="text-left">
        {{ cjSupplierPrice?.supplierName | dashDisplay }}
        <div>
          <span class="sub-header"
            >Vendor No: {{ cjSupplierPrice.supplierCode }} | Branch No: {{ cjSupplierPrice.branchNo }}</span
          >
        </div>
      </td>
      <td class="text-center">
        {{ cjSupplierPrice?.orderBarcode | dashDisplay }}
      </td>
      <td class="text-center">
        {{ cjSupplierPrice?.supplierUnit | dashDisplay }}
      </td>
      <td class="text-center">
        {{ cjSupplierPrice?.supplierUnitFactor | dashDisplay }}
      </td>
      <td class="text-right">
        {{ cjSupplierPrice?.supplierPrice | numberFormatDisplay | dashDisplay }}
      </td>
      <td class="text-right">
        {{ cjSupplierPrice?.avgSupplierPrice | numberFormatDisplay | dashDisplay }}
      </td>
      <td class="text-center">
        {{ cjSupplierPrice?.effectiveDate | dateDisplay | dashDisplay }}
      </td>
      <td class="text-center">
        {{ cjSupplierPrice?.expireDate | dateDisplay | dashDisplay }}
      </td>
    </tr>
  </tbody>
</table>

<ng-template #dashTable>
  <table
    id="supplierPriceDashTable"
    class="table table-striped table-bordered table-hover border-none table-with-child"
  >
    <thead>
      <tr>
        <th class="text-center align-top">Supplier Name</th>
        <th class="text-center align-top">Order Unit</th>
        <th class="text-center align-top">Unit Factor</th>
        <th class="text-center align-top">Supplier Price (ex VAT)</th>
        <th class="text-center align-top">AVG. Supplier Price (ex VAT)/Base Unit</th>
        <th class="text-center align-top">Effective Date</th>
        <th class="text-center align-top">Expire Date</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left">
          {{ null | dashDisplay }}
        </td>
        <td class="text-center">
          {{ null | dashDisplay }}
        </td>
        <td class="text-center">
          {{ null | dashDisplay }}
        </td>
        <td class="text-right">
          {{ null | dashDisplay }}
        </td>
        <td class="text-right">
          {{ null | dashDisplay }}
        </td>
        <td class="text-center">
          {{ null | dateDisplay }}
        </td>
        <td class="text-center">
          {{ null | dateDisplay }}
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<hr class="section-divider" />

<div class="row section-header">
  <span>Barcode</span>
</div>

<table id="cjBarcodeTable" class="table table-striped table-bordered table-hover">
  <thead>
    <tr>
      <th class="text-center align-top">Warehouse</th>
      <th class="text-center align-top">Barcode Size</th>
      <th class="text-center align-top">Barcode</th>
      <th class="text-center align-top">Unit</th>
      <th class="text-center align-top">Unit Factor</th>
      <th class="text-center align-top">Wholesale Price</th>
      <th class="text-center align-top">Retail Price</th>
      <th class="text-center align-top">Moving AVG.Price</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let groupingBarcodes of groupingBarcodes; index as indexOfItem">
      <tr *ngFor="let barcode of groupingBarcodes.barcodes; index as indexOfBarcode">
        <td *ngIf="indexOfBarcode === 0" [rowSpan]="groupingBarcodes.barcodes.length" class="text-left bg-white">
          {{ groupingBarcodes.warehouseName }}
        </td>
        <td class="text-center">{{ barcode.barSize }}</td>
        <td>{{ barcode.barcode }}</td>
        <td class="text-center">
          {{ barcode.unit }}
          <span class="picking-unit" *ngIf="barcode.pickingUnit">
            Picking Unit
          </span>
        </td>
        <td class="text-center">{{ barcode.unitFactor }}</td>
        <td class="text-right">{{ barcode.wholesalePrice | numberFormatDisplay }}</td>
        <td class="text-right">{{ barcode.retailPrice | numberFormatDisplay }}</td>
        <td class="text-right">{{ barcode.movingPrice | numberFormatDisplay }}</td>
      </tr>
    </ng-container>
  </tbody>
</table>
