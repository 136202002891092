import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import {
  ForgotPasswordActionTypes,
  ForgotPasswordChangePasswordFailed,
  ForgotPasswordChangePasswordRequestAction,
  ForgotPasswordChangePasswordSuccess,
  ForgotPasswordOTPFailed,
  ForgotPasswordOTPRequestAction,
  ForgotPasswordOTPSuccess,
  ForgotPasswordVerifyOTPRequestAction,
  ForgotPasswordVerifyOTPSuccess
} from '../actions/forgot-password.action';

@Injectable()
export class ForgotPasswordEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly userService: UsersService,
    private readonly router: Router,
    protected readonly logger: NGXLogger
  ) {}

  @Effect()
  forgotPasswordCreateOTP$: Observable<Action> = this.actions$.pipe(
    ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_REQUEST),
    map((action: ForgotPasswordOTPRequestAction) => action.payload),
    switchMap(payload => {
      return this.authService.bypassAuthenToken().pipe(
        map(result => result['access_token']),
        switchMap(accessToken => {
          return this.userService.forgotPasswordCreateOTP(payload, accessToken);
        }),
        map(response => {
          return new ForgotPasswordOTPSuccess({ data: response.body });
        }),
        catchError(err => {
          return of(new ForgotPasswordOTPFailed(err));
        })
      );
    })
  );

  @Effect()
  forgotPasswordVerifyOTP$: Observable<Action> = this.actions$.pipe(
    ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_REQUEST),
    tap((action: ForgotPasswordVerifyOTPRequestAction) =>
      this.logger.debug('@Effect Forgot Password Verify OTP Request Action : ' + JSON.stringify(action.payload))
    ),
    map((action: ForgotPasswordVerifyOTPRequestAction) => action.payload),
    switchMap(payload => {
      return this.authService.bypassAuthenToken().pipe(
        map(result => result['access_token']),
        switchMap(accessToken => {
          return this.userService.forgotPasswordVerifyOTP(payload.userName, payload.otp, accessToken);
        }),
        map(response => {
          return new ForgotPasswordVerifyOTPSuccess({ data: response.body });
        }),
        catchError(err => {
          return of(new ForgotPasswordOTPFailed(err));
        })
      );
    })
  );

  @Effect()
  forgotPasswordChangePassword$: Observable<Action> = this.actions$.pipe(
    ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_REQUEST),
    tap((action: ForgotPasswordChangePasswordRequestAction) =>
      this.logger.debug('@Effect Forgot Password Change Password Request Action : ' + JSON.stringify(action.payload))
    ),
    map((action: ForgotPasswordChangePasswordRequestAction) => action.payload),
    switchMap(payload => {
      return this.authService.bypassAuthenToken().pipe(
        map(result => result['access_token']),
        switchMap(accessToken => {
          return this.userService.forgotPassword(payload.userName, payload.newPassword, payload.otp, accessToken);
        }),
        map(response => {
          this.router.navigateByUrl('/');
          return new ForgotPasswordChangePasswordSuccess({ data: response.body });
        }),
        catchError(err => {
          return of(new ForgotPasswordChangePasswordFailed(err));
        })
      );
    })
  );
}
