import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { BarcodeService } from '../services';

export function duplicatedBarcodeValidator(barcodeService: BarcodeService, ignoreValue: any): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const debounceTime = 500; // milliseconds

    return timer(debounceTime).pipe(
      switchMap(() => {
        if (control.value && control.value !== ignoreValue) {
          return barcodeService.checkDuplicatedBarcode(control.value).pipe(
            switchMap(() => of({ duplicatedBarcode: true })),
            catchError(() => of(null))
          );
        } else {
          return of(null);
        }
      })
    );
  };
}
