export enum MerchantSelectValueEnum {
  ALL = 'area,bank,building,parking,poi,region,noOfUnit,orderScheduleDate,orderScheduleTime,deliveryScheduleDate,pickScheduleDate,country,state,currency,merchantType,timezone,propertyOwnership,customerType,restrictedDeliveryTime,poiDistance,competitorsName',
  DELIVERY_SCHEDULE_DATE = 'deliveryScheduleDate',
  ORDER_SCHEDULE_TIME = 'orderScheduleTime',
  ORDER_SCHEDULE_DATE = 'orderScheduleDate',
  PICK_SCHEDULE_DATE = 'pickScheduleDate',
  CUSTOMER_TYPE = 'customerType',
  MERCHANT_TYPE = 'merchantType',
  CURRENCY = 'currency',
  COUNTRY = 'country',
  AREA = 'area',
  BANK = 'bank',
  BUILDING = 'building',
  PARKING = 'parking',
  POI = 'poi',
  REGION = 'region',
  STATE = 'state',
  RESTRICTED_DELIVERY_TIME = 'restrictedDeliveryTime'
}
