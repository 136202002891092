import { Action } from '@ngrx/store';
import { Z8ResultResponse, Z8ResultSearchCriteria } from '../../models/z8-result.model';

export enum Z8ResultActionType {
  Z8_RESULT_REQUEST = '[Z8 Result] Z8 Result Request',
  Z8_RESULT_RESPONSE = '[Z8 Result] Z8 Result Response',
  Z8_RESULT_HISTORY_REQUEST = '[Z8 Result] List History Request',
  Z8_RESULT_HISTORY_RESPONSE = '[Z8 Result] List History Response'
}

export class Z8ResultRequestAction implements Action {
  readonly type = Z8ResultActionType.Z8_RESULT_REQUEST;

  constructor(public payload: Z8ResultSearchCriteria) {}
}

export class Z8ResultResponseAction implements Action {
  readonly type = Z8ResultActionType.Z8_RESULT_RESPONSE;

  constructor(public payload: Z8ResultResponse) {}
}

export class Z8ResultHistoryRequestAction implements Action {
  readonly type = Z8ResultActionType.Z8_RESULT_HISTORY_REQUEST;
  constructor(public payload: string) {}
}

export class Z8ResultHistoryResponseAction implements Action {
  readonly type = Z8ResultActionType.Z8_RESULT_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export type Z8ResultActions =
  | Z8ResultRequestAction
  | Z8ResultResponseAction
  | Z8ResultHistoryRequestAction
  | Z8ResultHistoryResponseAction;
