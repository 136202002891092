import { createSelector } from '@ngrx/store';
import * as fromTdAssortmentOrderState from '../reducers/td-assortment-order.reducers';
import { AppStates } from '../state/app.states';

const selectTdAssortmentOrderState = (state: AppStates) => state.tdAssortmentOrder;

export const getTdAssortmentOrderById = articleNo =>
  createSelector(selectTdAssortmentOrderState, orders => orders.entities[articleNo]);

export const getTdAssortmentOrderBySupplierCodeAndBarcode = (supplierCode, barcode) =>
  createSelector(selectTdAssortmentOrderState, orders => orders.entities[`${supplierCode}_${barcode}`]);

export const getCountTdAssortmentOrder = createSelector(
  selectTdAssortmentOrderState,
  fromTdAssortmentOrderState.selectTotal
);

export const selectAllTdAssortmentOrder = createSelector(
  selectTdAssortmentOrderState,
  fromTdAssortmentOrderState.selectAll
);
