import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Z8Result, Z8ResultSearchCriteria } from '../../models/z8-result.model';
import { Z8ResultActions, Z8ResultActionType } from '../actions/z8-result.actions';
import { BaseState } from '../state/base.state';

export interface Z8ResultState extends EntityState<Z8Result>, BaseState {
  selected: Z8Result;
  criteriaObject: Z8ResultSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<Z8Result> = createEntityAdapter<Z8Result>();

export const initialZ8ResultResponseState: Z8ResultState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function z8ResultReducers(state = initialZ8ResultResponseState, action: Z8ResultActions): Z8ResultState {
  switch (action.type) {
    case Z8ResultActionType.Z8_RESULT_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case Z8ResultActionType.Z8_RESULT_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case Z8ResultActionType.Z8_RESULT_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
