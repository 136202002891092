import { Component, OnInit } from '@angular/core';
import { environment as env } from '../../../../environments/environment';
import { isDevEnv } from '../../utils/is-dev-util';

@Component({
  selector: 'app-version-tag',
  templateUrl: './version-tag.component.html',
  styleUrls: ['./version-tag.component.scss']
})
export class VersionTagComponent implements OnInit {
  private initVersion: string;

  constructor() {}

  ngOnInit() {
    this.initVersion = `${env.version}-${env.commit}`;

    if (isDevEnv(env.tier)) {
      this.initVersion += ` branch:${env.branch}`;
    }
  }

  get version() {
    return this.initVersion;
  }
}
