import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AssortmentDeliveryMethodEnum } from '../models';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import {
  SupplierPriceExportCriteria,
  SupplierPricePagination,
  SupplierPriceSearchCriteria
} from '../models/supplier-price.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class SupplierPriceConfigService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.supplierPrice;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public uploadSupplierPriceFiles(
    files: Array<File>,
    deliveryMethod: AssortmentDeliveryMethodEnum
  ): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadSupplierPriceFile(file, deliveryMethod));
  }

  public uploadSupplierPriceFile(
    file: File,
    deliveryMethod: AssortmentDeliveryMethodEnum
  ): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.import, {
      deliveryMethod
    });
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { ...event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  searchSupplierPrice(supplierPriceSearchCriteria: SupplierPriceSearchCriteria): Observable<SupplierPricePagination> {
    if (supplierPriceSearchCriteria.importId) {
      return this.searchSupplierPriceByImportId(supplierPriceSearchCriteria);
    } else {
      return this.searchSupplierPriceByArticleNo(supplierPriceSearchCriteria);
    }
  }

  searchSupplierPriceByArticleNo(
    supplierPriceSearchCriteria: SupplierPriceSearchCriteria
  ): Observable<SupplierPricePagination> {
    const params = this.getParams(supplierPriceSearchCriteria, true);
    return this.http.get<any>(this.getFullUrl(this.envService.get), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  searchSupplierPriceByImportId(
    supplierPriceSearchCriteria: SupplierPriceSearchCriteria
  ): Observable<SupplierPricePagination> {
    const params = this.getParams(supplierPriceSearchCriteria, true);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  onExport(supplierPriceCriteria: SupplierPriceExportCriteria) {
    const params = this.getParams(supplierPriceCriteria, true);

    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
