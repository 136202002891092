<div class="page-wrapper" [ngClass]="{ toggled: sideBarState$ | async }">
  <div>
    <app-sidebar></app-sidebar>
  </div>
  <app-navbar></app-navbar>
  <div id="page-content" class="page-content">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
