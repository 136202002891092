/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-in-place.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./edit-in-place.component";
var styles_EditInPlaceComponent = [i0.styles];
var RenderType_EditInPlaceComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditInPlaceComponent, data: {} });
export { RenderType_EditInPlaceComponent as RenderType_EditInPlaceComponent };
function View_EditInPlaceComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], null, null, null, null, null))], null, null); }
function View_EditInPlaceComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "w-100"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "flex-shrink-0"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "p-1 close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "em", [["class", "icon-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["aria-label", "Close"], ["class", "p-1 close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doOK() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "em", [["class", "icon-correct"]], null, null, null, null, null))], null, null); }
function View_EditInPlaceComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "w-100"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "flex-shrink-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.doToggleMode() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "em", [["class", "icon-edit"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewData; _ck(_v, 1, 0, currVal_0); }); }
export function View_EditInPlaceComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "edit-in-place d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditInPlaceComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["editMode", 2]], null, 0, null, View_EditInPlaceComponent_2)), (_l()(), i1.ɵand(0, [["viewMode", 2]], null, 0, null, View_EditInPlaceComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.localMode === _co.ModeEnum.EDIT); var currVal_1 = i1.ɵnov(_v, 3); var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_EditInPlaceComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-in-place", [], null, null, null, View_EditInPlaceComponent_0, RenderType_EditInPlaceComponent)), i1.ɵdid(1, 638976, null, 0, i3.EditInPlaceComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditInPlaceComponentNgFactory = i1.ɵccf("app-edit-in-place", i3.EditInPlaceComponent, View_EditInPlaceComponent_Host_0, { control: "control", viewData: "viewData", mode: "mode" }, { modeChange: "modeChange" }, ["*"]);
export { EditInPlaceComponentNgFactory as EditInPlaceComponentNgFactory };
