export const b64toBlob = dataURI => {
  const byteString = atob(dataURI);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    /* tslint:disable:no-bitwise */
    ia[i] = byteString.charCodeAt(i) & 0xff;
  }
  return new Blob([ab], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
};
