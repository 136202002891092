import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import {
  StoreAssortmentRequestStoreContent,
  StoreAssortmentRequestStoreListSearchCriteria
} from '../../../../shared/models/store-assortment-request-store.model';
import { AuthGuardService } from '../../../../shared/services';
import {
  StoreAssortmentRequestStoreListRequestAction,
  StoreAssortmentRequestStoreSelectAction,
  StoreAssortmentRequestStoreSelectAllAction,
  StoreAssortmentRequestStoreSelectAllResetAction,
  StoreAssortmentRequestStoreUnselectAction,
  StoreAssortmentRequestStoreUnselectAllAction
} from '../../../../shared/store/actions/store-assortment-request-store.actions';
import { StoreAssortmentRequestStoreState } from '../../../../shared/store/reducers/store-assortment-request-store.reducers';
import {
  selectAllStoreAssortmentRequestStoreList,
  selectStoreAssortmentRequestStoreList,
  selectStoreAssortmentRequestStoreListCriteria,
  selectStoreAssortmentRequestStoreSelectedAllPage,
  selectStoreAssortmentRequestStoreSelectedList
} from '../../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-request-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreAssortmentRequestSearchResultComponent implements OnInit, OnDestroy {
  public criteriaObject: StoreAssortmentRequestStoreListSearchCriteria;
  public currentPage: number;
  public pageSize: number;
  public searchForm: FormGroup;
  private localStore: Observable<any>;
  selectedAllPage: any;
  selectedList: any;
  listState$: Observable<StoreAssortmentRequestStoreState>;
  resultList$: Observable<StoreAssortmentRequestStoreContent[]>;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {}

  ngOnInit() {
    this.setInitialCriteriaObject();
    this.initialData();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectStoreAssortmentRequestStoreListCriteria)).subscribe(criteriaObject => {
      this.criteriaObject = {
        ...criteriaObject
      };
      this.currentPage = this.criteriaObject.page + 1;
    });

    this.localStore.pipe(select(selectStoreAssortmentRequestStoreSelectedList)).subscribe(selectedState => {
      this.selectedList = {
        ...(Object.keys(selectedState).length && selectedState)
      };

      if (Object.keys(selectedState).length === 0) {
        this.store.dispatch(new StoreAssortmentRequestStoreSelectAllResetAction());
      }
    });

    this.localStore.pipe(select(selectStoreAssortmentRequestStoreSelectedAllPage)).subscribe(selectedAllPage => {
      this.selectedAllPage = selectedAllPage;
    });
  }

  ngOnDestroy(): void {}

  initialData() {
    this.selectedAllPage = { 1: false };
    this.selectedList = {};

    this.resultList$ = this.store.select(selectAllStoreAssortmentRequestStoreList);
    this.listState$ = this.store.select(selectStoreAssortmentRequestStoreList);
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;

    this.criteriaObject = {
      page: 0,
      size: 20
    };
  }

  search() {
    this.store.dispatch(new StoreAssortmentRequestStoreListRequestAction(this.criteriaObject));
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onClickCheckBox(event: any, store) {
    if (event.target.checked) {
      this.store.dispatch(new StoreAssortmentRequestStoreSelectAction(store));
    } else {
      this.store.dispatch(new StoreAssortmentRequestStoreUnselectAction(store));
      this.selectedAllPage[this.currentPage] = false;
    }
  }

  onClickCheckAllBox(event) {
    if (event.target.checked) {
      this.store.dispatch(new StoreAssortmentRequestStoreSelectAllAction());
      this.selectedAllPage[this.currentPage] = true;
    } else {
      this.store.dispatch(new StoreAssortmentRequestStoreUnselectAllAction());
      this.selectedAllPage[this.currentPage] = false;
    }
  }

  wordLimiter(fullText: string) {
    const MAX_LENGTH = 18;

    const wordList = [];
    const cleanWord = fullText.replace(/[^ก-ฮโใไเแาำA-Za-z0-9',]/g, '');
    const splitWordByLength = cleanWord.slice(0, MAX_LENGTH);
    const numberOfWordAllowed = splitWordByLength.split(',').length;
    const fullTextBreak = fullText.split(',');

    if (numberOfWordAllowed) {
      for (let i = 0; i < numberOfWordAllowed; i++) {
        wordList.push(fullTextBreak[i].trim());
      }
    }

    return wordList.join(', ');
  }

  wordSpaceCompare(text: string) {
    return text.replace(/,/g, ' ,');
  }
}
