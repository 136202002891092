import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ProfitSharingContent,
  ProfitSharingData,
  ProfitSharingSearchCriteria
} from '../../models/profit-sharing.model';
import { ProfitSharingActions, ProfitSharingActionTypes } from '../actions/profit-sharing.actions';

export interface ProfitSharingState extends EntityState<ProfitSharingContent> {
  criteriaObject: ProfitSharingSearchCriteria;
  isLoading: boolean;
  profitSharing: ProfitSharingData;
  submitError: string;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProfitSharingContent> = createEntityAdapter<ProfitSharingContent>();

export const initialProfitSharingState: ProfitSharingState = adapter.getInitialState({
  isLoading: false,
  profitSharing: null,
  submitError: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function profitSharingReducers(
  state = initialProfitSharingState,
  action: ProfitSharingActions
): ProfitSharingState {
  switch (action.type) {
    case ProfitSharingActionTypes.PROFIT_SHARING_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ProfitSharingActionTypes.PROFIT_SHARING_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ProfitSharingActionTypes.PROFIT_SHARING_VIEW_RESPONSE:
      return {
        ...state,
        profitSharing: action.payload
      };
    case ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_ERROR:
      return {
        ...state,
        submitError: action.payload
      };
    case ProfitSharingActionTypes.PROFIT_SHARING_SUBMIT_ERROR_RESET:
      return {
        ...state,
        submitError: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
