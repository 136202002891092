import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FreshLiteCategory, FreshLiteUpdateResponse } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class FreshLiteService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.freshLiteCategory;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchFreshLite(): Observable<FreshLiteCategory[]> {
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body'
    });
  }

  addFreshLiteList(freshLite: FreshLiteCategory[]): Observable<FreshLiteUpdateResponse> {
    return this.http.post<any>(this.getUrl(), freshLite, { headers: this.headers });
  }

  allowEditFreshLite(code: string): Observable<any> {
    const url = this.getFullUrl(this.envService.checkFreshLite, {
      code
    });
    return this.http.get(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
