import { createSelector } from '@ngrx/store';
import * as fromMasterAssortmentListState from '../reducers/master-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectMasterAssortmentListState = (state: AppStates) => state.masterAssortment;

export const selectAllMasterAssortmentList = createSelector(
  selectMasterAssortmentListState,
  fromMasterAssortmentListState.selectAll
);

export const selectMasterAssortmentList = createSelector(
  selectMasterAssortmentListState,
  (state: fromMasterAssortmentListState.MasterAssortmentState) => state
);

export const selectMasterAssortmentListCriteria = createSelector(selectMasterAssortmentListState, state => {
  return state.criteriaObject;
});
