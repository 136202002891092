import { createSelector } from '@ngrx/store';
import * as fromDeliveryOrder from '../reducers/delivery-order.reducers';
import { AppStates } from '../state/app.states';

const selectDeliveryOrderState = (state: AppStates) => state.deliveryOrder;

export const selectEditedDeliveryOrder = createSelector(selectDeliveryOrderState, state => {
  return state.deliveryOrder;
});

export const selectDeliveryOrder = createSelector(
  selectDeliveryOrderState,
  (state: fromDeliveryOrder.DeliveryOrderState) => state
);

export const selectDeliveryOrderSaveStatus = createSelector(
  selectDeliveryOrderState,
  (state: fromDeliveryOrder.DeliveryOrderState) => {
    return state.saveSuccess;
  }
);

export const selectDeliveryOrderList = createSelector(selectDeliveryOrderState, fromDeliveryOrder.selectAll);

export const selectDeliveryOrderCriteria = createSelector(selectDeliveryOrderState, state => {
  return state.criteriaObject;
});

export const selectDeliveryOrderCheck = createSelector(selectDeliveryOrderState, state => {
  return state.selected;
});
