import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import { ForgotUsername } from '../../models/forgot-username.model';

export enum ForgotUsernameActionTypes {
  FORGOT_USERNAME_REQUEST = '[Forgot Username] Forgot Username Request',
  FORGOT_USERNAME_SUCCESS = '[Forgot Username] Forgot Username Success',
  FORGOT_USERNAME_FAILED = '[Forgot Username] Forgot Username Failed',
  FORGOT_USERNAME_RESET = '[Forgot Username] Forgot Username Reset'
}

export class ForgotUsernameRequestAction implements Action {
  readonly type = ForgotUsernameActionTypes.FORGOT_USERNAME_REQUEST;

  constructor(public payload: ForgotUsername) {}
}

export class ForgotUsernameSuccess implements Action {
  readonly type = ForgotUsernameActionTypes.FORGOT_USERNAME_SUCCESS;

  constructor(public payload: { data: any; mobileNumber: string }) {}
}

export class ForgotUsernameFailed implements Action {
  readonly type = ForgotUsernameActionTypes.FORGOT_USERNAME_FAILED;

  constructor(public payload: { error: ErrorResponse; mobileNumber: string }) {}
}

export class ForgotUsernameReset implements Action {
  readonly type = ForgotUsernameActionTypes.FORGOT_USERNAME_RESET;

  constructor() {}
}

export type ForgotUsernameActions =
  | ForgotUsernameRequestAction
  | ForgotUsernameSuccess
  | ForgotUsernameFailed
  | ForgotUsernameReset;
