import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ClaimRequestStatusEnum } from '../../../shared/enum/claim-status.enum';
import { DeliveryByTypeEnum } from '../../../shared/enum/delivery-by-type.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { OrderingMethodEnum } from '../../../shared/enum/ordering-method.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { PortalModule, TaskModuleUrl } from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { ReceiveOrderViewResponse } from '../../../shared/models/receive-order.model';
import { AuthGuardService } from '../../../shared/services';
import {
  ReceiveOrderByIdRequestAction,
  ResetReceiveOrderSelected
} from '../../../shared/store/actions/receive-order.actions';
import { selectReceiveOrderByNo } from '../../../shared/store/selectors/receive-order.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { convertUtcToBkk, getDateFromString } from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { ClaimRequestViewComponent } from '../../claim-request/claim-request-view/claim-request-view.component';

@Component({
  selector: 'app-receive-order-view',
  templateUrl: './receive-order-view.component.html',
  styleUrls: ['./receive-order-view.component.scss']
})
export class ReceiveOrderViewComponent extends BaseComponent implements OnInit, OnDestroy, AfterContentChecked {
  public bsModalRef: BsModalRef;
  public receiveOrder: ReceiveOrderViewResponse;
  public currentPage = 1;
  public pageSize = 20;
  public receiveOrder$: Observable<ReceiveOrderViewResponse>;

  public dateTimeDisplay = environment.dateTimeDisplay;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { receiveOrderNo: string; deliveryBy: DeliveryByTypeEnum; title: string; crNo: string };

  private localStore: Observable<any>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected readonly authGuardService: AuthGuardService,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.store.dispatch(new ReceiveOrderByIdRequestAction({ receiveOrderNo: this.data.receiveOrderNo }));

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.receiveOrder$ = this.localStore.pipe(
      select(selectReceiveOrderByNo),
      distinctUntilChanged(),
      filter(receiveOrder => receiveOrder !== null)
    );

    this.receiveOrder$.subscribe(receiveOrder => (this.receiveOrder = receiveOrder));
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.receiveOrder = null;
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }

    // Reset node to free memory
    this.store.dispatch(new ResetReceiveOrderSelected());
  }

  doAfterVersionAlertModal() {}

  subscribeForVersionError() {}

  onClose() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  onCreateClaim() {
    const cutOffDate = moment('2020-09-11').toDate();
    const createdDate = getDateFromString(convertUtcToBkk(this.receiveOrder.createdDate), 'YYYY-MM-DDTHH:mm:ss.SSS');
    if (this.receiveOrder.orderType !== OrderingMethodEnum.FRESH_LITE) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Allow to create claim for receive order type fresh lite only.'
        }
      });
      return;
    } else if (this.data.crNo) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'This receive order already created claim.'
        }
      });
      return;
    } else if (createdDate && cutOffDate && createdDate < cutOffDate) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to claim Receive Order created before 11 September 2020.'
        }
      });
      return;
    }

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: ModuleUtil.InitialState(
        { receiveOrderNo: this.receiveOrder.receiveOrderNo, claimRequestStatus: ClaimRequestStatusEnum.DRAFT },
        ClaimRequestViewComponent,
        '',
        'Create Claim Request',
        TaskModuleUrl.RECEIVE_ORDER_REQUEST,
        PortalModule.CLAIM
      )
    });
  }

  get deliveryByTypeEnum() {
    return DeliveryByTypeEnum;
  }

  hasManageClaimPermission() {
    return this.authGuardService.checkPermission(['cr_m']);
  }
}
