import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { NoSpaceOnlyValidation } from '../../../../shared/custom-validators/no-space-only-validation';
import { FileModuleEnum } from '../../../../shared/enum/file-url.enum';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import { NewRequestTypeEnum, RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { ShelfPages } from '../../../../shared/enum/td-store-page.enum';
import { ShelfItems } from '../../../../shared/models';
import { ShelfRequestViewResponse } from '../../../../shared/models/shelf-request.model';
import { ShelfTypesService } from '../../../../shared/services/shelf-types.service';
import { AppStates } from '../../../../shared/store/state/app.states';
import { getFileUploadObj } from '../../../../shared/utils/get-file-name-from-ref-id-util';
import { getSelectShelf } from '../../../../shared/utils/get-select-by-page-util';
import { AddShelfTypeComponent } from '../add-shelf-type/add-shelf-type.component';

@Component({
  selector: 'app-shelf-info',
  templateUrl: './shelf-info.component.html',
  styleUrls: ['./shelf-info.component.scss']
})
export class ShelfInfoComponent implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() saved: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: ShelfPages;

  public shelfTypeSearchLoading: boolean;
  public shelfTypeSearchInput$ = new Subject<string>();
  public shelfTypeList$: Observable<ShelfItems[]>;
  public status: NewRequestStatusEnum;
  public listOfChange: string[] = [];

  private localStore: Observable<any>;
  private type: NewRequestTypeEnum;
  private shelfRequestView$: Observable<ShelfRequestViewResponse>;

  constructor(
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly shelfTypeService: ShelfTypesService,
    private readonly store: Store<AppStates>
  ) {}

  ngOnDestroy() {}

  ngOnInit() {
    this.type = NewRequestTypeEnum.NEW;
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.parentForm.addControl('shelfInfo', this.createForm());
    this.loadShelfTypeList('');

    if (this.mode !== RequestPageModesEnum.REQUEST_CREATE) {
      this.setShelfInfoValue();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['mode'] &&
      changes['mode'].currentValue &&
      changes['mode'].currentValue === RequestPageModesEnum.REQUEST_EDIT
    ) {
      this.toggleEditShelfInfo();
    }
  }

  get form(): FormGroup {
    return this.parentForm.get('shelfInfo') as FormGroup;
  }

  get fileModule() {
    return FileModuleEnum;
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];

    return this.fb.group({
      shelfName: [{ value: null, disabled: false }, [Validators.required, NoSpaceOnlyValidation]],
      shelfType: initialNullRequired,
      shelfImage: initialNullRequired
    });
  }

  goToShelfType() {
    const modal = this.modalService.show(AddShelfTypeComponent, {
      class: 'modal-lg',
      backdrop: 'static'
    });

    modal.content.addedShelfType.pipe(untilComponentDestroyed(this)).subscribe(() => {
      this.loadShelfTypeList('');
    });
  }

  loadShelfTypeList(initialTerm: string) {
    this.shelfTypeList$ = concat(
      of([]),
      this.shelfTypeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.shelfTypeSearchLoading = true)),
        switchMap(term =>
          this.shelfTypeService
            .getShelfList({
              searchCriteria: term
            })
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.shelfTypeSearchLoading = false;
              })
            )
        )
      )
    );
  }

  setShelfInfoValue() {
    this.form.disable();

    this.shelfRequestView$ = this.localStore.pipe(select(getSelectShelf(this.page)));
    this.shelfRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe(data => {
        this.status = data.status;
        this.type = data.type;

        if (data.shelfInfo) {
          this.form.patchValue({
            ...data.shelfInfo,
            shelfImage: getFileUploadObj(data.shelfInfo.shelfImage)
          });

          if (data.shelfInfo.changes && data.shelfInfo.changes.length > 0) {
            this.listOfChange = data.shelfInfo.changes.map(change => {
              return change.field;
            });
          }
        }

        if (this.mode === RequestPageModesEnum.REQUEST_EDIT) {
          this.toggleEditShelfInfo();
        }
      });
  }

  toggleEditShelfInfo() {
    this.mode = RequestPageModesEnum.REQUEST_EDIT;

    if (this.form && ![NewRequestStatusEnum.AWAITING_FIRST_LOT, NewRequestStatusEnum.ACTIVE].includes(this.status)) {
      this.form.enable();
    }
  }

  hasChanges(field: string) {
    return (
      this.page === ShelfPages.SHELF_REQUEST &&
      this.type === NewRequestTypeEnum.EDIT &&
      this.listOfChange.includes(field)
    );
  }

  get isShowButton() {
    return (
      this.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status !== NewRequestStatusEnum.AWAITING_FIRST_LOT &&
      this.type !== NewRequestTypeEnum.EDIT
    );
  }
}
