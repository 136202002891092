<div [formGroup]="parentForm">
  <div formGroupName="promotionCondition">
    <div class="row" *ngIf="!isSpecialPrice()">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="noOfQuantity">Buy Promotion Item (No. of Quantity)</label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0'"
              [decimals]="0"
              placeholder="Buy Promotion Item (No. of Quantity)"
              formControlName="buyQty"
              maxlength="8"
              [maxValue]="99999.99"
              ngDefaultControl
            >
            </app-numeric-textbox>
          </div>
          <div class="col-md-6 mb-3" *ngIf="!isCoPromotion()">
            <label for="totalNewPrice">Selected Retail Price<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0.00'"
              [decimals]="2"
              [maxValue]="99999.99"
              [negativeSign]="false"
              id="selectedRetailPrice"
              placeholder="Selected Retail Price"
              formControlName="selectedRetailPrice"
              maxlength="8"
              (blur)="onBlurRetailPrice()"
              [ngClass]="{
                'is-invalid': submitted && form.controls.selectedRetailPrice.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.selectedRetailPrice.errors" class="invalid-feedback">
              <div *ngIf="form.controls.selectedRetailPrice.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="form.controls.selectedRetailPrice.errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3" *ngIf="isCoPromotion()">
            <label for="buyCoProItem">Buy Co-Promotion Item (No. of Quantity)<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0'"
              [decimals]="0"
              id="buyCoItemQty"
              placeholder="Buy Co-Promotion Item (No. of Quantity)"
              formControlName="buyCoItemQty"
              [negativeSign]="false"
              maxlength="8"
              [ngClass]="{
                'is-invalid': submitted && form.controls.buyCoItemQty.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.buyCoItemQty.errors" class="invalid-feedback">
              <div *ngIf="form.controls.buyCoItemQty.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="totalNewPrice">Total New Price (THB)<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0.00'"
              [decimals]="2"
              [maxValue]="99999.99"
              maxlength="8"
              [negativeSign]="false"
              placeholder="Total New Price (THB)"
              formControlName="totalPrice"
              [ngClass]="{
                'is-invalid': submitted && form.controls.totalPrice.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.totalPrice.errors" class="invalid-feedback">
              <div *ngIf="form.controls.totalPrice.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="form.controls.totalPrice.errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-3" *ngIf="details.controls.promotionType.value !== proType.BUY_TWO_SAVE_MORE">
            <label for="averageNewPrice">Average New Price/Unit</label>
            <app-numeric-textbox
              [format]="'0,0.00'"
              [decimals]="2"
              [negativeSign]="false"
              placeholder="Average New Price/Unit"
              formControlName="averageUnitPrice"
              maxlength="8"
              ngDefaultControl
            >
            </app-numeric-textbox>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="isSpecialPrice()">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="noOfQuantity">Buy Promotion Item (No. of Quantity)</label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0'"
              [decimals]="0"
              placeholder="Buy Promotion Item (No. of Quantity)"
              formControlName="buyQty"
              maxlength="8"
              [negativeSign]="false"
              [maxValue]="99999.99"
              ngDefaultControl
            >
            </app-numeric-textbox>
          </div>
          <div class="col-md-6 mb-3">
            <label for="totalNewPrice">Total New Price (THB)<span class="text-danger">*</span></label>
            <app-numeric-textbox
              [minValue]="1"
              [format]="'0,0.00'"
              [decimals]="2"
              [negativeSign]="false"
              [maxValue]="99999.99"
              maxlength="8"
              placeholder="Total New Price (THB)"
              formControlName="totalPrice"
              (blur)="onBlurTotalPrice()"
              [ngClass]="{
                'is-invalid': submitted && form.controls.totalPrice.errors
              }"
              ngDefaultControl
            >
            </app-numeric-textbox>
            <div *ngIf="submitted && form.controls.totalPrice.errors" class="invalid-feedback">
              <div *ngIf="form.controls.totalPrice.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="form.controls.totalPrice.errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
              </div>
              <div *ngIf="form.controls.totalPrice.errors.invalidPrice">
                {{ 'ERRORS.INVALID_PRICE' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6 mb-3" *ngIf="details.controls.promotionType.value !== proType.BUY_TWO_SAVE_MORE">
            <label for="averageNewPrice">Average New Price/Unit</label>
            <app-numeric-textbox
              [format]="'0,0.00'"
              [decimals]="2"
              [negativeSign]="false"
              placeholder="Average New Price/Unit"
              formControlName="averageUnitPrice"
              maxlength="8"
              ngDefaultControl
            >
            </app-numeric-textbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
