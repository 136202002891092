<div class="table-responsive" [formGroup]="parentForm" *ngIf="parentForm">
  <table class="table table-striped table-bordered table-hover" id="wholesalePriceTable">
    <thead>
      <tr>
        <th class="text-center align-top">Wholesale Price Type</th>
        <th class="text-center align-top w-20">
          Wholesale Price (inc VAT)<span class="text-danger">*</span><br /><span class="text-black-50"
            >(Unit:Base Unit)</span
          >
        </th>
        <th class="text-center align-top w-20">
          Wholesale Price VAT<br /><span class="text-black-50">(Unit:Base Unit)</span>
        </th>

        <th class="text-center align-top w-20">
          Wholesale Price (ex VAT)<span class="text-danger">*</span><br /><span class="text-black-50"
            >(Unit: Base Unit)</span
          >
        </th>
        <th class="text-center align-top w-15">Effective Date<span class="text-danger">*</span></th>
      </tr>
    </thead>
    <tbody formArrayName="wholesalePrices">
      <tr *ngFor="let wholesalePrice of getWholesalePriceForm.controls; let i = index" [formGroupName]="i">
        <td>{{ i > 0 ? 'New ' : '' }}Wholesale Price</td>
        <td class="td-no-padding align-top">
          <app-numeric-textbox
            id="wholesalePriceIncVat"
            formControlName="wholesalePriceIncVat"
            [format]="'0,0.00'"
            [maxValue]="9999999999.99"
            [negativeSign]="false"
            decimals="2"
            maxlength="13"
            placeholder="0.00"
            [autoCorrect]="true"
            [ngClass]="{
              'td-numeric-textbox': true,
              'is-invalid': isSubmitted && wholesalePrice.controls.wholesalePriceIncVat.errors,
              'bg-h-change':
                isRequestViewMode &&
                (!wholesalePrice.controls.id.value ||
                  (wholesalePrice.controls.changes?.value && wholesalePrice.controls.changes.value['priceIncVat']))
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="isSubmitted && wholesalePrice.controls.wholesalePriceIncVat.errors" class="invalid-display">
            <span *ngIf="wholesalePrice.controls.wholesalePriceIncVat.errors.required; else zero">
              {{ 'ERRORS.REQUIRED' | translate }}
            </span>
            <ng-template #zero>
              <span *ngIf="wholesalePrice.controls.wholesalePriceIncVat.errors.isZero">
                {{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}
              </span>
            </ng-template>
          </div>
        </td>
        <td class="text-right align-top">
          <span id="wholesalePriceVat">
            {{ wholesalePrice.controls.wholesalePriceVat.value | numberFormatDisplay: 2 }}
          </span>
        </td>
        <td class="text-right align-top">
          <span id="wholesalePriceExVat">
            {{ wholesalePrice.controls.wholesalePriceExVat.value | numberFormatDisplay: 2 }}
          </span>
        </td>
        <td class="td-no-padding align-top" *ngIf="i === 0">
          <input
            id="wholesalePriceEffectiveDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            readonly
            [bsConfig]="bsDateConfig"
            placement="bottom"
            bsDatepicker
            formControlName="effectiveDate"
            [minDate]="minDate"
            [ngClass]="{
              'is-invalid': isSubmitted && wholesalePrice.controls.effectiveDate.errors
            }"
          />
          <label for="wholesalePriceEffectiveDate" class="icon-calendar btn-calendar"></label>
        </td>
        <td class="td-no-padding align-top" *ngIf="i !== 0">
          <ng-select
            [searchable]="false"
            [clearable]="true"
            [items]="listOfEffectiveDateValue"
            bindLabel="label"
            bindValue="value"
            formControlName="effectiveDate"
            appendTo=".full-popup-inner"
            placeholder="Please select..."
            [ngClass]="{
              'is-invalid': isSubmitted && wholesalePrice.controls.effectiveDate.errors,
              'bg-h-change':
                isRequestViewMode &&
                (!wholesalePrice.controls.id.value ||
                  (wholesalePrice.controls.changes?.value && wholesalePrice.controls.changes.value['effectiveDate']))
            }"
          >
          </ng-select>
          <div *ngIf="isSubmitted && wholesalePrice.controls.effectiveDate.errors" class="invalid-display">
            <span *ngIf="wholesalePrice.controls.effectiveDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </span>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
