import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProductAssortmentService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ProductAssortmentActionTypes,
  ProductAssortmentSearchLoaded,
  ProductAssortmentSearchRequested
} from '../actions/product-assortment.actions';

@Injectable()
export class ProductAssortmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly productAssortmentService: ProductAssortmentService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchProductAssortments$ = this.actions$.pipe(
    ofType<ProductAssortmentSearchRequested>(ProductAssortmentActionTypes.ProductAssortmentSearchRequested),
    tap(action =>
      this.logger.debug(
        `@Effect ${ProductAssortmentActionTypes.ProductAssortmentSearchRequested}: ` + JSON.stringify(action.payload)
      )
    ),
    switchMap(action => {
      return this.productAssortmentService.searchByCriteria(action.payload).pipe(
        map(productAssortments => new ProductAssortmentSearchLoaded({ productAssortments })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );
}
