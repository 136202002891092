import { ErrorResponse } from '../../models';

export interface BaseState {
  result?: {
    response: any | null;
    errorResponse: ErrorResponse | null;
  };
}

export const initialCriteriaObject = {
  page: 0,
  size: 20
};
