import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantSelectValueService } from '../../services/merchant-select-value.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { MerchantSelectValueActionType, MerchantSelectValueErrorAction, MerchantSelectValueResponseAction } from '../actions/merchant-select-value.actions';
export class MerchantSelectValueEffects {
    constructor(actions$, merchantSelectValueService, store, logger) {
        this.actions$ = actions$;
        this.merchantSelectValueService = merchantSelectValueService;
        this.store = store;
        this.logger = logger;
        this.merchantSelectValue$ = this.actions$.pipe(ofType(MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_REQUEST), map(action => {
            this.logger.debug('@Effect MerchantSelectValue: ' + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.merchantSelectValueService.getMerchantSelectValue(payload).pipe(map(result => new MerchantSelectValueResponseAction(result)), catchError(error => {
                this.store.dispatch(new MerchantSelectValueErrorAction(error));
                return of(new LayoutActionLoadError(error));
            }));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MerchantSelectValueEffects.prototype, "merchantSelectValue$", void 0);
