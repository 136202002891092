import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { SpecialAssortmentContent, StoreAssortmentTypeEnum } from '../../../../shared/models/store-assortment.model';
import {
  ResetSpecialAssortmentListAction,
  SpecialAssortmentListRequestAction
} from '../../../../shared/store/actions/special-assortment.actions';
import { SpecialAssortmentState } from '../../../../shared/store/reducers/special-assortment.reducers';
import {
  selectAllSpecialAssortmentList,
  selectSpecialAssortmentList,
  selectSpecialAssortmentListCriteria
} from '../../../../shared/store/selectors/special-assortment.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { Z8FactorComponent } from '../../components/Z8-factor/Z8-factor.component';

@Component({
  selector: 'app-special-assortment',
  templateUrl: './special-assortment.component.html',
  styleUrls: ['./special-assortment.component.scss']
})
export class SpecialAssortmentComponent implements OnInit, OnDestroy {
  @Input() storeNo: string;

  public criteriaObject: any;
  public currentPage: number;
  public pageSize: number;
  public searchForm: FormGroup;
  resultList$: Observable<SpecialAssortmentContent[]>;
  listState$: Observable<SpecialAssortmentState>;
  private localStore: Observable<any>;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectSpecialAssortmentListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.setInitialCriteriaObject();
    this.initialData();
    this.createSearchForm();

    this.search();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetSpecialAssortmentListAction());
  }

  initialData() {
    this.resultList$ = this.localStore.pipe(select(selectAllSpecialAssortmentList));
    this.listState$ = this.localStore.pipe(select(selectSpecialAssortmentList));
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...(this.criteriaObject as any),
      size: Number(value),
      page: 0
    };
    this.search();
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;

    this.criteriaObject = {
      page: 0,
      size: 20,
      type: StoreAssortmentTypeEnum.SPECIAL_ASSORTMENT
    };
  }

  search() {
    this.store.dispatch(
      new SpecialAssortmentListRequestAction({
        storeNo: { storeNo: this.storeNo },
        criteria: this.criteriaObject
      })
    );
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...(this.criteriaObject as any),
      page: event.page - 1
    };
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.criteriaObject = {
        ...this.criteriaObject,
        searchCriteria: null,
        page: 0
      };
      this.search();
    }
  }

  clearSearchText() {
    this.setFirstPage();
    this.searchForm.controls['searchCriteria'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: null,
      page: 0
    };
    this.search();
  }

  onSubmit() {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      ...this.searchForm.value,
      page: 0
    };
    this.search();
  }

  getItemNo(index: number) {
    return this.pageSize * (this.currentPage - 1) + index + 1;
  }

  showZ8Factor(data: SpecialAssortmentContent) {
    this.modalService.show(Z8FactorComponent, {
      initialState: {
        data: data
      }
    });
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }
}
