<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-store-assortment"></em>
    <h4>Store Assortment</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button type="button" class="btn btn-primary col-sm-12" (click)="goToCreate()">
          <em class="icon-plus"></em>
          Manage Store Assortment
        </button>
      </div>
      <div class="flex-item d-md-flex justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="form-control searchCriteria"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="statusList"
            [searchable]="false"
            [clearable]="false"
            class="ng-select-width"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onChangeStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Created Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="createdDateFrom"
                          formControlName="createdDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: dateFormat,
                            showWeekNumbers: false
                          }"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="createdDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="createdDateTo"
                          formControlName="createdDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="{
                            containerClass: 'theme-dark-blue',
                            dateInputFormat: dateFormat,
                            showWeekNumbers: false
                          }"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="createdDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <table id="data-table" class="table table-striped table-bordered table-hover w-100">
      <thead>
        <tr>
          <th class="text-center w-15">Request No.</th>
          <th class="text-center w-15">No. of Store</th>
          <th class="text-center w-15">No. of Added</th>
          <th class="text-center w-15">No. of Deleted</th>
          <th class="text-center w-15">Created By</th>
          <th class="text-center w-15">Created Date</th>
          <th class="text-center w-10">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result$ of resultList$ | async; index as i" (click)="goToView(result$.id)">
          <td>
            {{ result$.requestNo | dashDisplay: '(None)' }}
          </td>
          <td class="text-center">
            {{ result$.noOfStore | numberFormatDisplay: 0:0 }}
          </td>
          <td class="text-center">
            {{ result$.noOfAdd | numberFormatDisplay: 0:0 }}
          </td>
          <td class="text-center">
            {{ result$.noOfDelete | numberFormatDisplay: 0:0 }}
          </td>
          <td class="text-center">
            {{ result$.createdBy | dashDisplay: '-' }}
          </td>
          <td class="text-center">
            {{ result$.createdDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
          </td>
          <td class="request-status text-center" [ngClass]="getColorStatus(result$.status)">
            {{ 'STORE_ASSORTMENT_REQUEST.STATUS.' + result$.status | translate }}
          </td>
        </tr>
      </tbody>
    </table>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
