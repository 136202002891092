import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tier-tag',
  template: '<span class="tier-color" *ngIf="tier">{{tier}}</span>'
})
export class TierTagComponent implements OnInit {
  public tier: string;

  constructor() {}

  ngOnInit() {
    this.tier = environment.tier === 'prod' ? undefined : environment.tier;
  }
}
