import { Action } from '@ngrx/store';
import { Z8ConditionsListResponse, Z8ConditionsListSearchCriteria } from '../../models/z8-conditions.model';

export enum Z8ConditionsActionType {
  Z8_CONDITIONS_LIST_REQUEST = '[Z8 Conditions] Z8 Conditions Request',
  Z8_CONDITIONS_LIST_RESPONSE = '[Z8 Conditions] Z8 Conditions Response',
  Z8_CONDITIONS_LIST_RESET = '[Z8 Conditions] Z8 Conditions Reset'
}

export class Z8ConditionsListRequestAction implements Action {
  readonly type = Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST;

  constructor(public payload: Z8ConditionsListSearchCriteria) {}
}

export class Z8ConditionsListResponseAction implements Action {
  readonly type = Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESPONSE;

  constructor(public payload: Z8ConditionsListResponse) {}
}

export class Z8ConditionsListResetAction implements Action {
  readonly type = Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESET;
}

export type Z8ConditionsActions =
  | Z8ConditionsListRequestAction
  | Z8ConditionsListResponseAction
  | Z8ConditionsListResetAction;
