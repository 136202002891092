import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProductMasterService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ProductMasterActionTypes, ProductMasterViewLoaded } from '../actions/product-master.actions';
export class ProductMasterEffects {
    constructor(actions$, productMasterService, logger) {
        this.actions$ = actions$;
        this.productMasterService = productMasterService;
        this.logger = logger;
        this.loadProductMaster$ = this.actions$.pipe(ofType(ProductMasterActionTypes.ProductMasterViewRequested), tap(action => this.logger.debug('@Effect Product Master : ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.productMasterService.findProductMasterByArticleNo(action.payload.articleNo).pipe(map(productMaster => new ProductMasterViewLoaded({ productMaster })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProductMasterEffects.prototype, "loadProductMaster$", void 0);
