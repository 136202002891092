import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MembershipService } from '../../services/membership.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  GenerateMemberCardListRequestAction,
  GenerateMemberCardListResponseAction,
  GenerateMemberCardRequestAction,
  GenerateMemberCardResponseAction,
  MemberCardTypeActions
} from '../actions/member-card.actions';

@Injectable()
export class MemberCardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly memberCardService: MembershipService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  generateMemberCard = this.actions$.pipe(
    ofType<GenerateMemberCardRequestAction>(MemberCardTypeActions.GENERATE_MEMBER_CARD_REQUEST),
    tap(action => this.logger.debug('@Effect GENERATE_MEMBER_CARD_REQUEST: ' + JSON.stringify(action.payload))),
    switchMap(action => {
      return this.memberCardService.generateMemberCard(action.payload).pipe(
        map(() => new GenerateMemberCardResponseAction()),
        catchError(err => of(new LayoutActionLoadError(err)))
      );
    })
  );

  @Effect()
  searchMemberCardList$ = this.actions$.pipe(
    ofType<GenerateMemberCardListRequestAction>(MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST}: ` + JSON.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.memberCardService.searchMemberCardCriteria(payload).pipe(
        map(requests => {
          return new GenerateMemberCardListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );
}
