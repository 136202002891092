<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store"></em>
    <span>{{ data.title }}</span>
  </div>
  <button type="button" class="btn btn-header-button" *ngIf="hasCancelPermission()" (click)="cancelMerchantRequest()">
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <button type="button" class="btn btn-header-button" *ngIf="hasDeletePermission()" (click)="deleteMerchantRequest()">
    <em class="icon-delete"></em>
    Delete
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form">
    <div class="info-header-container">
      <div class="info-header-item" *ngIf="(merchantRequestView$ | async)?.requestNo">
        <div class="info-header-label">
          <span>Request No.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ (merchantRequestView$ | async)?.requestNo }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span
            *ngIf="merchantRequestPageMode.REQUEST_CREATE === data.mode; else getStatus"
            [ngClass]="getColorStatus('D')"
            class="request-status"
          >
            {{ 'MERCHANT_REQUEST.STATUS.D' | translate }}</span
          >
        </div>
        <ng-template #getStatus>
          <span [ngClass]="getColorStatus((merchantRequestView$ | async)?.status)" class="request-status">
            {{ 'MERCHANT_REQUEST.STATUS.' + (merchantRequestView$ | async)?.status | translate }}</span
          >
        </ng-template>
      </div>
    </div>

    <div [formGroup]="merchantRequestForm" (ngSubmit)="onSubmit()">
      <!-- Merchant Profile -->
      <div class="row section-header">
        <span id="PROFILE">Merchant Profile</span>
      </div>
      <app-merchant-profile
        #merchantProfile
        [parentForm]="merchantRequestForm"
        [saved]="saved"
        [submitted]="submitted"
        (changeOwnerIdCard)="onChangeOwnerIdCard($event)"
        [mode]="data.mode"
        [requestId]="data.merchantRequestId"
        [page]="page"
      ></app-merchant-profile>

      <hr class="section-divider" />

      <!-- Owner Profile -->
      <div class="row section-header">
        <span>Owner Profile</span>
      </div>
      <app-owner-profile
        #ownerProfile
        [parentForm]="merchantRequestForm"
        [submitted]="submitted"
        [ownerIdCardReadOnly]="ownerIdCardReadOnly"
        [mode]="data.mode"
        [requestId]="data.merchantRequestId"
        [page]="page"
      ></app-owner-profile>

      <hr class="section-divider" />

      <!-- Legal Document -->
      <ng-container *ngIf="isShowSection(merchantSectionEnum.LEGAL_DOCUMENT)">
        <div class="row section-header">
          <span id="LEGAL_DOCUMENT">Legal Document</span>
        </div>
        <app-legal-document
          #legalDocument
          [parentForm]="merchantRequestForm"
          [submitted]="submitted"
          [mode]="data.mode"
          [page]="page"
        ></app-legal-document>

        <hr class="section-divider" />
      </ng-container>
      <!-- Legal Document -->

      <!-- Store Profile -->
      <ng-container *ngIf="isShowSection(merchantSectionEnum.STORE_PROFILE)">
        <div class="row section-header justify-content-between">
          <span>Store Profile</span>
        </div>
        <app-store-profile
          #storeProfile
          [parentForm]="merchantRequestForm"
          [submitted]="submitted"
          [mode]="data.mode"
          [requestId]="data.merchantRequestId"
          [page]="page"
        ></app-store-profile>

        <hr class="section-divider" />
      </ng-container>
      <!-- Store Profile -->

      <!-- Order Policy -->
      <ng-container *ngIf="isShowSection(merchantSectionEnum.ORDER_POLICY)">
        <div class="row section-header">
          <span id="ORDER_POLICY">Order Schedule(for supply chain)</span>
        </div>
        <app-order-policy
          #orderPolicy
          [parentForm]="merchantRequestForm"
          [submitted]="submitted"
          [mode]="data.mode"
          [page]="page"
        ></app-order-policy>
      </ng-container>
      <!-- Order Policy -->
    </div>

    <!--footer-->
    <ng-container *ngIf="hasAtLeastOnePermission()">
      <ng-container
        *ngIf="
          [merchantRequestPageMode.REQUEST_CREATE, merchantRequestPageMode.REQUEST_EDIT].includes(data.mode);
          else otherMode
        "
      >
        <div class="fixed-row-bottom form-row">
          <div class="float-left" *ngIf="hasSavePermission()">
            <button type="button" id="btn-save" class="btn btn-secondary" (click)="onSave()">
              Save
            </button>
          </div>
          <div class="float-right" *ngIf="hasSubmitPermission()">
            <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
            <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
              Submit
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #otherMode>
        <ng-container>
          <div class="fixed-row-bottom form-row">
            <div class="float-left"></div>
            <div class="float-right">
              <span *ngIf="hasEditPermission()" class="mr-2">
                <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
              </span>
              <span *ngIf="hasApprovePermission()">
                <button
                  type="button"
                  id="btn-reject"
                  class="btn btn-special-reject mr-2"
                  (click)="doApproveRejectRequest(false)"
                >
                  Reject
                </button>
                <button
                  type="button"
                  id="btn-approve"
                  class="btn btn-special-approve"
                  (click)="doApproveRejectRequest(true)"
                >
                  Approve
                </button>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <!--End footer-->
  </div>
</div>
