import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MerchantSelectValueService } from '../../services/merchant-select-value.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  MerchantSelectValueActionType,
  MerchantSelectValueErrorAction,
  MerchantSelectValueRequestAction,
  MerchantSelectValueResponseAction
} from '../actions/merchant-select-value.actions';
import { AppStates } from '../state/app.states';

@Injectable()
export class MerchantSelectValueEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly merchantSelectValueService: MerchantSelectValueService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  merchantSelectValue$: Observable<Action> = this.actions$.pipe(
    ofType<MerchantSelectValueRequestAction>(MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_REQUEST),
    map(action => {
      this.logger.debug('@Effect MerchantSelectValue: ' + this.stringify(action.payload));
      return action.payload;
    }),
    switchMap(payload => {
      return this.merchantSelectValueService.getMerchantSelectValue(payload).pipe(
        map(result => new MerchantSelectValueResponseAction(result)),
        catchError(error => {
          this.store.dispatch(new MerchantSelectValueErrorAction(error));
          return of(new LayoutActionLoadError(error));
        })
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
