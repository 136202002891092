import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { CollapsibleHistoryComponent } from '../../../shared/components/collapsible-history/collapsible-history.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { ButtonType, ExportTypeEnum, ImportExportButton } from '../../../shared/models/import-export-button.model';
import {
  AuditLog,
  StoreAssortmentList,
  StoreAssortmentListSearchCriteria
} from '../../../shared/models/store-assortment.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import {
  StoreAssortmentListHistoryRequestAction,
  StoreAssortmentListHistoryResponseAction,
  StoreAssortmentListRequestAction
} from '../../../shared/store/actions/store-assortment.actions';
import { StoreAssortmentState } from '../../../shared/store/reducers/store-assortment.reducers';
import {
  selectAllStoreAssortmentList,
  selectStoreAssortmentList,
  selectStoreAssortmentListHistory
} from '../../../shared/store/selectors/store-assortment.selectors';
import { selectStoreListCriteria } from '../../../shared/store/selectors/store.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ExportByProductComponent } from '../components/export-by-product/export-by-product.component';
import { ExportByStoreComponent } from '../components/export-by-store/export-by-store.component';
import { ViewStoreAssortmentComponent } from '../view-store-assortment/view-store-assortment.component';

@Component({
  selector: 'app-store-assortment-list',
  templateUrl: './store-assortment-list.component.html',
  styleUrls: ['./store-assortment-list.component.scss']
})
export class StoreAssortmentListComponent extends BaseSearchComponent<
  StoreAssortmentListSearchCriteria,
  StoreAssortmentList,
  StoreAssortmentState
> {
  private bsModalRef: BsModalRef;
  private localStore: Observable<any>;

  public listRoute: Array<RouteLinkTab>;
  public searchTag: string;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public dateFormat = environment.dateFormat;
  public storeTypeTag: string;
  public storeTypeStringTag: string;

  public auditLogs$: Observable<AuditLog[]>;
  public activityList = {
    addSpecial: 'ADD_SPECIAL',
    deleteSpecial: 'DELETE_SPECIAL',
    activeMaster: 'ACTIVE_MASTER',
    inactiveMaster: 'INACTIVE_MASTER'
  };
  public responseExportError: string;
  public storeTypeFilter: NgOption;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export by Product',
      by: ExportTypeEnum.PRODUCT
    },
    {
      type: ButtonType.EXPORT,
      name: 'Export by Store',
      by: ExportTypeEnum.STORE
    }
  ];

  constructor(
    protected authGuardService: AuthGuardService,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    private readonly masterService: MasterService
  ) {
    super(store, modalService, selectAllStoreAssortmentList, selectStoreAssortmentList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStoreListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        untilComponentDestroyed(this),
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeFilter = result[MasterDataEnum.MERCHANT];
      });

    this.auditLogs$ = this.localStore.pipe(select(selectStoreAssortmentListHistory));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      storeType: [null],
      lastUpdatedDateFrom: [null],
      lastUpdatedDateTo: [null]
    });
  }

  clearFilterDate() {
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterStoreType() {
    this.setFirstPage();
    this.searchForm.controls['storeType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      storeType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['storeType'].reset();
    this.searchForm.controls['lastUpdatedDateFrom'].reset();
    this.searchForm.controls['lastUpdatedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      storeType: null,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.storeType && !formValue.lastUpdatedDateFrom && !formValue.lastUpdatedDateTo) {
      return;
    }

    let dateFrom = formValue.lastUpdatedDateFrom;
    let dateTo = formValue.lastUpdatedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      storeType: formValue.storeType && formValue.storeType.length > 0 ? formValue.storeType.toString() : null,
      lastUpdatedDateFrom: dateFrom,
      lastUpdatedDateTo: dateTo,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.storeTypeTag = null;
    this.storeTypeStringTag = null;

    const lastUpdatedDateFrom = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateFrom);
    const lastUpdatedDateTo = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateTo);
    const lastUpdatedDate = generateDateStringTag({
      dateName: 'Last Updated Date',
      dateFrom: lastUpdatedDateFrom,
      dateTo: lastUpdatedDateTo
    });

    this.dateStringTag = lastUpdatedDate.dateStringTag;
    this.dateTag = lastUpdatedDate.dateTag;

    if (this.criteriaObject.storeType && this.criteriaObject.storeType.length) {
      this.storeTypeStringTag = 'Store Type';
      const types = this.storeTypeFilter
        .filter(data => this.criteriaObject.storeType.includes(data.code))
        .map(data => data.nameTh)
        .join(', ');
      this.storeTypeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new StoreAssortmentListRequestAction(criteriaObj));
  }

  goToView(storeNo: string) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewStoreAssortmentComponent,
        {
          title: 'View Store Assortment',
          storeNo
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
    return;
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  showHistory(data: StoreAssortmentList) {
    this.store.dispatch(new StoreAssortmentListHistoryRequestAction({ storeNo: data.storeNo }));
    const initialState = {
      title: 'History',
      historyHeader: `${data.storeCodeName}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.STORE_ASSORTMENT,
      auditLogs$: this.auditLogs$,
      activityList: this.activityList
    };

    this.modalService.show(CollapsibleHistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new StoreAssortmentListHistoryResponseAction({ auditLogs: null }));
    });
  }

  setRouteTab() {
    const hasListPagePermission = this.authGuardService.checkPermission(['store_assortment_v', 'store_assortment_m']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['store_assortment_m']);

    this.listRoute = [];

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Store Assortment List', url: '/store-assortment/store-assortment-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({
        tabName: 'Store Assortment Request',
        url: '/store-assortment/store-assortment-request-list'
      });
    }
  }

  onOpenExportModal(exportType: ExportTypeEnum) {
    switch (exportType) {
      case ExportTypeEnum.PRODUCT:
        this.openExportByProduct();
        break;
      case ExportTypeEnum.STORE:
        this.openExportByStore();
        break;
      default: {
        break;
      }
    }
  }

  openExportByStore() {
    this.modalService.show(ExportByStoreComponent, {
      backdrop: 'static'
    });
  }

  openExportByProduct() {
    this.modalService.show(ExportByProductComponent, {
      backdrop: 'static'
    });
  }
}
