import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ClaimRequest, ClaimRequestContent, ClaimRequestSearchCriteria } from '../../models/claim-request.model';
import { ClaimRequestActions, ClaimRequestActionTypes } from '../actions/claim-request.actions';

export interface ClaimRequestState extends EntityState<ClaimRequestContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  selected: ClaimRequest[];
  claimRequest: ClaimRequest;
  criteriaObject: ClaimRequestSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ClaimRequestContent> = createEntityAdapter<ClaimRequestContent>();

export const initialClaimRequestState: ClaimRequestState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  selected: null,
  claimRequest: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function claimRequestReducers(
  state = initialClaimRequestState,
  action: ClaimRequestActions
): ClaimRequestState {
  switch (action.type) {
    case ClaimRequestActionTypes.CLAIM_REQUEST_VIEW_LOADED: {
      return {
        ...state,
        claimRequest: action.payload
      };
    }
    case ClaimRequestActionTypes.CLAIM_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ClaimRequestActionTypes.CLAIM_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ClaimRequestActionTypes.CLAIM_REQUEST_RESET: {
      return {
        ...state,
        claimRequest: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
