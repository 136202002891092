<div class="row">
  <div class="col-md-12">
    <table
      *ngIf="formPromotionItem.controls.length"
      id="Table"
      class="table table-striped table-bordered w-100"
      [formGroup]="parentForm"
    >
      <thead>
        <tr>
          <th class="text-center w-5">{{ headerRow[0] }}</th>
          <th class="text-center w-10">{{ headerRow[1] }}</th>
          <th class="text-center w-20">{{ headerRow[2] }}</th>
          <th class="text-center w-5">{{ headerRow[3] }}</th>
          <th class="text-center w-5">{{ headerRow[4] }}</th>
          <th class="text-center w-10">{{ headerRow[5] }}</th>
          <th class="text-center w-10">{{ headerRow[6] }}</th>
          <th class="text-center w-10">{{ headerRow[7] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[8] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10">{{ headerRow[9] }}<span class="text-danger">*</span></th>
          <th class="text-center w-10" *ngIf="canEdit">{{ headerRow[10] }}</th>
        </tr>
      </thead>
      <tbody [formArrayName]="promotionItemType">
        <tr
          *ngFor="let prdItem of formPromotionItem.controls; let i = index; first as firstIndex"
          [formGroupName]="i"
          [ngClass]="{
            'error-background':
              prdItem.errors ||
              (submitted && prdItem.get('retailPrice').errors?.isDifferentPrice) ||
              prdItem.errors?.duplicated
          }"
        >
          <td class="text-center align-top">{{ i + 1 }}</td>
          <td class="text-left align-top">
            {{ prdItem.get('barcode').value }}
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors && prdItem.get('barcode').errors?.invalidBarcode"
            >
              {{ 'ERRORS.INVALID_BARCODE' | translate }}</small
            >
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors && prdItem.get('barcode').errors?.isInactive"
            >
              {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
            </small>
            <small class="d-block invalid-feedback text-danger" *ngIf="prdItem.errors?.duplicated">
              {{ 'ERRORS.DUPLICATED_BARCODE_FIELD' | translate }}
            </small>
          </td>
          <td class="text-left align-top">
            {{ prdItem.get('productName').value }}
            <span class="d-block">{{ prdItem.get('articleNo').value }}</span>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors?.isRestrictItem || prdItem.get('productName').errors?.isRestrictItem"
            >
              {{ 'ERRORS.RESTRICT_ITEM' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors?.isStatusDelisted || prdItem.get('productName').errors?.isStatusDelisted"
            >
              {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors?.isFixAssetItem || prdItem.get('productName').errors?.isFixAssetItem"
            >
              {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
            </small>
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="prdItem.errors?.isStoreUseItem || prdItem.get('productName').errors?.isStoreUseItem"
            >
              {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
            </small>
          </td>
          <td class="text-center align-top">
            {{ prdItem.get('unit').value }}
          </td>
          <td class="text-center align-top">
            {{ prdItem.get('unitFactor').value }}
          </td>
          <td class="text-right align-top">
            {{ prdItem.get('movingAverage').value | numberFormatDisplay: 2 }}
          </td>
          <td class="text-right align-top">
            {{ prdItem.get('retailPrice').value | numberFormatDisplay: 2 }}
            <small
              class="d-block invalid-feedback text-danger"
              *ngIf="submitted && prdItem.get('retailPrice').errors?.isDifferentPrice"
              >{{ 'ERRORS.DIFFERENT_PRICE' | translate }}</small
            >
          </td>
          <td class="text-center align-top">
            <ng-container *ngIf="!prdItem.errors?.invalidBarcode">
              <ng-select
                class="d-block"
                formControlName="discountCode"
                (change)="onChangeDiscountCode()"
                [items]="discountCode"
                bindLabel="code"
                dropdownPosition="auto"
                placeholder="Please select..."
                notFoundText="No Data"
                [virtualScroll]="true"
                [ngClass]="{ 'is-invalid': submitted && validatorFormControls('discountCode', i) }"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div class="text-left">
                    <span [ngOptionHighlight]="search" class="ml-1 text-left">
                      {{ item.code }}
                    </span>
                  </div>
                </ng-template>
              </ng-select>
              <a
                *ngIf="
                  firstIndex &&
                  (promotionRequestPageMode.REQUEST_CREATE === mode || promotionRequestPageMode.REQUEST_EDIT === mode)
                "
                (click)="ApplyToAll('discountCode', i)"
                class="btn-text-link d-block"
              >
                Apply to All
              </a>
              <div *ngIf="submitted && validatorFormControls('discountCode', i)" class="invalid-feedback">
                <div *ngIf="validatorFormControls('discountCode', i).required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </ng-container>
          </td>
          <td class="text-center  align-top">
            <ng-container *ngIf="!prdItem.errors?.invalidBarcode">
              <app-numeric-textbox
                [value]="prdItem.supplierCompensate"
                formControlName="supplierCompensate"
                [format]="'0,0.00'"
                [negativeSign]="false"
                [minValue]="0"
                [decimals]="2"
                [maxValue]="99999.99"
                class="td-numeric-textbox"
                id="supplierCompensate"
                placeholder="THB"
                maxlength="8"
                [ngClass]="{ 'is-invalid': submitted && validatorFormControls('supplierCompensate', i) }"
              >
              </app-numeric-textbox>
              <a
                *ngIf="
                  firstIndex &&
                  (promotionRequestPageMode.REQUEST_CREATE === mode ||
                    promotionRequestPageMode.REQUEST_EDIT === mode) &&
                  !showApplyAll
                "
                (click)="ApplyToAll('supplierCompensate', i)"
                class="btn-text-link d-block"
              >
                Apply to All
              </a>
              <div *ngIf="submitted && validatorFormControls('supplierCompensate', i)" class="invalid-feedback">
                <div *ngIf="validatorFormControls('supplierCompensate', i).required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </ng-container>
          </td>
          <td class="text-center  align-top">
            <ng-container *ngIf="!prdItem.errors?.invalidBarcode">
              <app-numeric-textbox
                [value]="prdItem.partnerCompensate"
                formControlName="partnerCompensate"
                [format]="'0,0.00'"
                [negativeSign]="false"
                [minValue]="0"
                [decimals]="2"
                [maxValue]="99999.99"
                id="partnerCompensate"
                [ngClass]="{ 'is-invalid': submitted && validatorFormControls('partnerCompensate', i) }"
                placeholder="THB"
                maxlength="8"
                class="td-numeric-textbox"
              >
              </app-numeric-textbox>
              <a
                *ngIf="
                  firstIndex &&
                  (promotionRequestPageMode.REQUEST_CREATE === mode || promotionRequestPageMode.REQUEST_EDIT === mode)
                "
                (click)="ApplyToAll('partnerCompensate', i)"
                class="btn-text-link d-block"
              >
                Apply to All
              </a>
              <div *ngIf="submitted && validatorFormControls('partnerCompensate', i)" class="invalid-feedback">
                <div *ngIf="validatorFormControls('partnerCompensate', i).required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </ng-container>
          </td>
          <td class="text-center align-middle" *ngIf="canEdit">
            <a
              id="delete-store"
              class="is-link text-muted"
              (click)="deletePromotionItem(i)"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div *ngIf="promotionRequestPageMode.REQUEST_CREATE === mode || promotionRequestPageMode.REQUEST_EDIT === mode">
  <a class="btn-text-link d-inline-block mr-1 " (click)="addNewProductItem()">+Select Item </a> or
  <a class="btn-text-link d-inline-block ml-1 " (click)="addNewBarcode()">+Add Barcode </a>
</div>
<app-search-product-modal
  #searchProductModal
  [promotion]="{
    promotionConRetailPrice: promotionConRetailPrice,
    promotionType: promotionType,
    promotionItemType: promotionItemType
  }"
  [productType]="productType"
  (addItem)="onAddPromotionItem($event)"
>
</app-search-product-modal>
<app-search-barcode-modal
  #searchBarcodeModal
  [promotion]="{
    promotionConRetailPrice: promotionConRetailPrice,
    promotionType: promotionType,
    promotionItemType: promotionItemType
  }"
  [allowProductType]="productType"
  (addItem)="onAddPromotionBarCode($event)"
></app-search-barcode-modal>
