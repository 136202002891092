import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { StoreAssortmentRequestStoreContent } from '../../../../shared/models/store-assortment-request-store.model';
import { StoreAssortmentService } from '../../../../shared/services/store-assortment.service';

@Component({
  selector: 'app-export-by-store',
  templateUrl: './export-by-store.component.html',
  styleUrls: ['./export-by-store.component.scss']
})
export class ExportByStoreComponent implements OnInit {
  public submitted: boolean;
  public form: FormGroup;
  public storeList: StoreAssortmentRequestStoreContent[];
  public storeSearchLoading: boolean;
  public storeSearchInput$ = new Subject<string>();
  public errorExport: string;
  public selectedStore = [];
  private readonly MAX_STORE = 100;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly storeAssortmentService: StoreAssortmentService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      storeNo: [[], [Validators.required]]
    });

    this.initData();
  }

  initData() {
    this.storeAssortmentService
      .searchStoreAssortmentStoreListByCriteria({
        page: 0,
        size: 50000
      })
      .subscribe(data => {
        this.storeList = data.content.map(item => {
          item.storeName = `${item.storeCode}-${item.storeName}`;

          return item;
        });
      });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    if (this.form.get('storeNo').value.length > this.MAX_STORE) {
      this.errorExport = `Export limit exceeded (up to ${this.MAX_STORE} stores)`;
      return;
    }

    const fileConfig = environment.fileName.exportStoreAssortment;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.form.getRawValue();

    this.storeAssortmentService.exportByStore({ storeNo: formData.storeNo.toString() }).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.closeModal()
    );
  }
}
