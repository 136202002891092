<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-product"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="status === requestStatus.INACTIVE && hasEditPermission()"
    (click)="onActivateShelf(true)"
  >
    <em class="icon-activate"></em>
    Activate
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="status === requestStatus.ACTIVE && hasEditPermission()"
    (click)="onActivateShelf(false)"
  >
    <em class="icon-deactivate"></em>
    Deactivate
  </button>

  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Shelf Code:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (shelfView$ | async)?.shelfCode | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="status !== requestStatus.DRAFT; else edit">
      <div class="info-header-label">
        <span>Shelf Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((shelfView$ | async)?.status)" class="shelf-status">
          {{ 'SHELF.STATUS.' + (shelfView$ | async)?.status | translate }}
        </span>
      </div>
    </div>
    <ng-template #edit>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="getColorStatus('draft')" class="shelf-status">
            {{ 'SHELF_REQUEST.STATUS.' + status | translate }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="admin-form">
    <div [formGroup]="shelfForm" (ngSubmit)="onSubmit()">
      <!-- Shelf Information -->
      <div class="row section-header">
        <span>Shelf Information</span>
      </div>
      <app-shelf-info
        #shelfInfo
        [parentForm]="shelfForm"
        [submitted]="submitted"
        [mode]="data.mode"
        [page]="page"
      ></app-shelf-info>
    </div>

    <hr class="section-divider" />

    <div class="row section-header">
      <span>Fix Asset</span>
    </div>
    <div class="mb-1">
      <app-shelf-fix-asset
        id="fix-asset"
        class="mb-5"
        #fixAsset
        [mode]="data.mode"
        [submitted]="submitted"
        [parentForm]="shelfForm"
        [page]="page"
      >
      </app-shelf-fix-asset>
    </div>

    <hr class="section-divider" />

    <div class="row section-header">
      <span>Inventory</span>
    </div>
    <div class="mb-5">
      <app-shelf-inventory
        id="inventory"
        class="mb-5"
        #inventory
        [mode]="data.mode"
        [submitted]="submitted"
        [parentForm]="shelfForm"
        [page]="page"
      >
      </app-shelf-inventory>
    </div>

    <!--footer-->
    <ng-container *ngIf="hasAtLeastOnePermission()">
      <ng-container *ngIf="pageModes.REQUEST_EDIT === data.mode; else otherMode">
        <div class="fixed-row-bottom form-row">
          <div class="float-right" *ngIf="hasSubmitPermission()">
            <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
            <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
              Submit
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #otherMode>
        <ng-container>
          <div class="fixed-row-bottom form-row">
            <div class="float-left"></div>
            <div class="float-right">
              <span *ngIf="hasEditFirstLotPermission()" class="mr-2">
                <button
                  type="button"
                  id="btn-update-firstlot"
                  class="btn btn-primary"
                  (click)="onTriggerEditFirstLot()"
                >
                  Update Beauty & First Lot
                </button>
              </span>
              <span *ngIf="hasEditPermission() && status !== requestStatus.INACTIVE" class="mr-2">
                <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <!--End footer-->
  </div>
</div>
