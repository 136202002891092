<!-- Modal Product Section -->
<div
  id="stockCardModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #stockCardModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export Stock Card Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <div class="mb-2">Location Name<span class="text-danger">*</span></div>
                      <ng-select
                        bindLabel="label"
                        bindValue="value"
                        [searchable]="true"
                        [clearable]="false"
                        [items]="locationList$ | async"
                        [loading]="locationSearchLoading"
                        [typeahead]="locationSearchInput$"
                        placeholder="Please select..."
                        formControlName="location"
                        [ngClass]="{ 'is-invalid': submitted && exportForm.controls.location.errors }"
                      >
                      </ng-select>
                      <div *ngIf="submitted && exportForm.controls.location.errors?.required" class="invalid-feedback">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>
                    <div class="form-group col-md-12">
                      <div class="form-row">
                        <div class="col-12">Document Date<span class="text-danger">*</span></div>
                        <div class="col-md-6">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="exportPeriodDateFrom"
                            formControlName="documentDateFrom"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="documentMaxDate"
                            (bsValueChange)="onChangeExportPeriodDateFrom($event)"
                            readonly
                          />
                          <label for="exportPeriodDateFrom" class="icon-calendar btn-calendar"></label>
                        </div>
                        <div class="col-md-6">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="exportPeriodDateTo"
                            formControlName="documentDateTo"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [minDate]="documentMinDate"
                            [bsConfig]="bsDateConfig"
                            (bsValueChange)="onChangeExportPeriodDateTo($event)"
                            readonly
                          />
                          <label for="exportPeriodDateTo" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-12">
                      <div class="mb-2">Segment<span class="text-danger">*</span></div>
                      <ng-select
                        placeholder="Please select..."
                        [items]="listOfValue[this.assortmentAllType.SEGMENT]"
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="false"
                        bindLabel="nameEn"
                        bindValue="code"
                        formControlName="segment"
                        (change)="onChangeSelectDropdown($event, assortmentAllType.SEGMENT)"
                      >
                      </ng-select>

                      <div *ngIf="submitted && exportForm.controls.segment.errors?.required" class="invalid-feedback">
                        {{ 'ERRORS.REQUIRED' | translate }}
                      </div>
                    </div>

                    <div class="form-group col-md-12">
                      <div class="mb-2">
                        Family
                      </div>
                      <ng-select
                        placeholder="Please select..."
                        [items]="listOfValue[this.assortmentAllType.FAMILY]"
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="false"
                        bindLabel="nameEn"
                        bindValue="code"
                        formControlName="family"
                        (change)="onChangeSelectDropdown($event, assortmentAllType.FAMILY)"
                      >
                      </ng-select>
                    </div>

                    <div class="form-group col-md-12">
                      <div class="mb-2">
                        Class
                      </div>
                      <ng-select
                        placeholder="Please select..."
                        [items]="listOfValue[this.assortmentAllType.CLASS]"
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="false"
                        bindLabel="nameEn"
                        bindValue="code"
                        formControlName="classCode"
                        (change)="onChangeSelectDropdown($event, assortmentAllType.CLASS)"
                      >
                      </ng-select>
                    </div>

                    <div class="form-group col-md-12">
                      <div class="mb-2">
                        Sub Class
                      </div>
                      <ng-select
                        placeholder="Please select..."
                        [items]="listOfValue[this.assortmentAllType.SUBCLASS]"
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="false"
                        bindLabel="nameEn"
                        bindValue="code"
                        formControlName="subClass"
                        (change)="onChangeSelectDropdown($event, assortmentAllType.SUBCLASS)"
                      >
                      </ng-select>
                    </div>

                    <div *ngIf="responseExportError" class="alert-danger form-group mb-0 w-100">
                      {{ responseExportError }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_stock_card_report_btn"
            [disabled]="exportFormInValid || this.exportForm.invalid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
