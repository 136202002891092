import { createSelector } from '@ngrx/store';
import * as fromZ8ParameterRequestResponseState from '../reducers/z8-parameter-request.reducers';
import { AppStates } from '../state/app.states';

const selectZ8ParameterRequestState = (state: AppStates) => state.z8ParameterRequest;

export const selectAllZ8ParameterRequestList = createSelector(
  selectZ8ParameterRequestState,
  fromZ8ParameterRequestResponseState.selectAll
);

export const selectZ8ParameterRequestList = createSelector(
  selectZ8ParameterRequestState,
  (state: fromZ8ParameterRequestResponseState.Z8ParameterRequestState) => state
);

export const selectZ8ParameterRequestListCriteria = createSelector(selectZ8ParameterRequestState, state => {
  return state.criteriaObject;
});
