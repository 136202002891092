import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round } from 'lodash';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import * as numeral from 'numeral';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ShelfDetailsComponent } from '../../../shared/components/shelf-details/shelf-details.component';
import { ApproveStatusEnum } from '../../../shared/enum/approve-status.enum';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { OrderingMethodEnum } from '../../../shared/enum/ordering-method.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { NewRequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestProductErrorEnum } from '../../../shared/enum/request-step.enum';
import { GraphqlQueryObject } from '../../../shared/gql/common.gql';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import { ErrorResponse, Money, ShelfDetailRequest, TaskModuleUrl } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  DeliveryDetail,
  DeliveryDetailsByTypeDictionary,
  InventoryTypeEnum,
  OrderRequestListSearchCriteria,
  OrderRequestProductItemsExport,
  OrderRequestValidate,
  OrderRequestViewResponse,
  ProductOrderItem
} from '../../../shared/models/order-request.model';
import { WarehouseListContent } from '../../../shared/models/warehouse.model';
import { AuthGuardService, ProductAssortmentService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { OrderRequestService } from '../../../shared/services/order-request.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import {
  OrderApproveRequestAction,
  OrderCreateResetAction,
  OrderCreateSaveRequestAction,
  OrderCreateSubmitRequestAction,
  OrderRequestByIdRequestAction,
  OrderRequestDeliveryDetailsResetAction,
  OrderRequestListRequestAction,
  OrderRequestShelfSelectionResetAction,
  ResetOrderRequestSelected
} from '../../../shared/store/actions/order-request.actions';
import {
  OrderSelectItemOrderAddAllItem,
  OrderSelectItemOrderRemoveItem,
  OrderSelectItemOrderReset,
  OrderSelectItemOrderUpdateAllItem,
  OrderSelectItemOrderUpdateItem
} from '../../../shared/store/actions/order-select-item-order.actions';
import { OrderSelectItemListReset } from '../../../shared/store/actions/order-select-item.actions';
import {
  ShelfDetailsResetAction,
  ShelfDetailsResponseAction
} from '../../../shared/store/actions/shelf-details.actions';
import { OrderCreateResponseState } from '../../../shared/store/reducers/order-create.reducers';
import {
  selectOrderApproveRejectStatus,
  selectOrderRequest
} from '../../../shared/store/selectors/order-create.selectors';
import {
  selectOrderRequestById,
  selectOrderRequestListCriteria
} from '../../../shared/store/selectors/order-request.selector';
import {
  selectAllOrderSelectItemOrder,
  selectOrderUpdated
} from '../../../shared/store/selectors/order-select-item-order.selector';
import { selectAllShelfDetails } from '../../../shared/store/selectors/shelf-details.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { getPriceFormat } from '../../../shared/utils/calculate-price-util';
import { ColumnType, ColumnTypeEnum, ExcelUtil, HeaderSheet } from '../../../shared/utils/excel-util';
import { VatDetailsModalComponent } from '../../purchase/vat-details-modal/vat-details-modal.component';
import { DeliveryDetailsSpecialComponent } from '../order-components/delivery-details-special/delivery-details-special.component';
import { DeliveryDetailsComponent } from '../order-components/delivery-details/delivery-details.component';
import { ImportOrderRequestComponent } from '../order-components/import-order-request/import-order-request.component';
import { SelectItemModalComponent } from '../order-components/select-item-modal/select-item-modal.component';
import { SelectShelfModalComponent } from '../order-components/select-shelf-modal/select-shelf-modal.component';

@Component({
  selector: 'app-order-request',
  templateUrl: './order-request.component.html',
  styleUrls: ['./order-request.component.scss']
})
export class OrderRequestComponent implements OnInit, OnDestroy {
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    title: string;
    mode: RequestPageModesEnum;
    orderType?: OrderingMethodEnum;
    warehouseCode?: string;
    warehouseDisplayName?: string;
    requestId?: string;
    originPage?: string;
  };

  private localStore: Observable<any>;
  private listSearchCriteria: OrderRequestListSearchCriteria;
  private requestNo: string;
  private status: NewRequestStatusEnum;
  private isDataUpdated: boolean;
  private skipUpdated: boolean;
  private segmentList: Array<string> = [];
  private segments = {};

  public orderRequestView$: Observable<OrderRequestViewResponse>;
  public orderRequestForm: FormGroup;
  public orderRequest: OrderRequestViewResponse;
  public shelfDetails: ShelfDetailRequest[];
  public warehouseList: WarehouseListContent[];

  public searchForm: FormGroup;
  public saved: boolean;
  public submitted: boolean;
  public orderTypeEnum = OrderingMethodEnum;
  public pageModeEnum = RequestPageModesEnum;
  public vatPct$: Observable<number>;
  public storeNo: string;
  public storeCode: string;

  public dateTimeDisplay = environment.dateTimeDisplay;
  public isLoading = false;
  public isSkipSetFilteredForm = false;

  public numberFormat = '0,0.00';
  public buttons: Array<ImportExportButton>;

  public currentPage = 1;
  public pageSize = 20;
  public filteredFormGroup: FormGroup;
  public inventoryType = InventoryTypeEnum;

  constructor(
    public readonly store: Store<AppStates>,
    private readonly fb: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly orderRequestService: OrderRequestService,
    private readonly assortmentService: ProductAssortmentService,
    private readonly authGuardService: AuthGuardService,
    protected masterService: MasterService
  ) {}

  ngOnInit() {
    this.initialControl();
    this.initState();
    this.getButton();
  }

  getButton() {
    this.buttons = [
      {
        type: ButtonType.EXPORT,
        name: 'Export'
      },
      {
        type: ButtonType.IMPORT,
        name: 'Import',
        hidden:
          this.data.mode === this.pageModeEnum.REQUEST_VIEW ||
          this.orderRequest.orderType !== this.orderTypeEnum.FIRST_LOT_ORDER
      }
    ];
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetOrderRequestSelected());
    this.store.dispatch(new OrderCreateResetAction());
    this.store.dispatch(new OrderSelectItemOrderReset());
    this.store.dispatch(new OrderSelectItemListReset());
    this.store.dispatch(new OrderRequestShelfSelectionResetAction());
    this.store.dispatch(new ShelfDetailsResetAction());
    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new OrderRequestListRequestAction(this.listSearchCriteria));
    }
  }

  initialControl() {
    this.orderRequestForm = this.fb.group({
      productOrderItems: this.fb.array([])
    });

    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });

    this.filteredFormGroup = this.fb.group({
      productOrderItems: this.fb.array([])
    });
  }

  initState() {
    const countryQuery = new GraphqlQueryObject();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    countryQuery.name = MasterDataEnum.COUNTRY;
    countryQuery.fields = ['id', 'code', 'nameEn', 'vatPct'];

    this.vatPct$ = this.masterService
      .getMasterDataByNames([countryQuery])
      .pipe(map(result => result.data.countries.find(data => data.nameEn === environment.defaultCountry).vatPct));

    this.orderRequest = new OrderRequestViewResponse();
    this.orderRequest.amount = getPriceFormat();
    this.orderRequest.vatAmount = getPriceFormat();
    this.orderRequest.amountExcVat = getPriceFormat();

    this.orderRequestView$ = this.localStore.pipe(select(selectOrderRequestById));
    this.orderRequestView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      this.storeNo = data.store.storeNo;
      this.storeCode = data.store.storeCode;
    });

    this.orderRequestService
      .getSegmentListData()
      .pipe(
        untilComponentDestroyed(this),
        filter(result => Boolean(result && result.data)),
        map(result => result.data)
      )
      .subscribe(data => {
        const segments = data[MasterDataEnum.SEGMENT];
        const regex = /[^\s]+$/;

        this.segments = segments.reduce((prev, obj) => {
          // Check if segment is 'OTHER' or 'PROMOTION' then set it to the section 'PROMOTION_OTHERS'
          prev[obj.code] = ['SEG00003', 'SEG00004'].includes(obj.code)
            ? this.inventoryType.PROMOTION_OTHERS
            : regex
                .exec(obj.nameEn)[0]
                .replace('-', '_')
                .toUpperCase();

          return prev;
        }, {});
      });

    this.localStore
      .pipe(select(selectOrderRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore
      .pipe(
        select(selectOrderRequest),
        filter(value => Boolean(value && value.result))
      )
      .subscribe((value: OrderCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal(this.getSubmitMessage(value.isSave));
        } else {
          if (value.result.errorResponse.code === '03005') {
            this.orderRequest.deliveryStatus = 'error';
          } else {
            this.alertErrorModal(value.result.errorResponse);
          }
        }
      });

    if ([RequestPageModesEnum.REQUEST_VIEW, RequestPageModesEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.store.dispatch(new OrderRequestByIdRequestAction({ orderId: this.data.requestId }));
    }

    this.localStore.pipe(select(selectAllShelfDetails)).subscribe(shelfDetails => {
      this.shelfDetails = shelfDetails;
    });

    this.orderRequestView$.pipe(filter(value => Boolean(value))).subscribe(value => {
      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
        value.deliveryDetails && value.deliveryDetails.deliveryDetailsByType
      );
      this.orderRequest.deliveryDetails = value.deliveryDetails;
    });

    this.localStore.pipe(select(selectOrderUpdated)).subscribe(skipUpdated => {
      this.skipUpdated = skipUpdated;
    });

    this.setOrderRequestValue();
    this.initialSelectItemOrder();
  }

  createItemForm(item: ProductOrderItem) {
    const errorMessage = item.errorMessage;
    const formItem: FormGroup = this.fb.group({
      productName: item.productName,
      productDisplayName: item.productDisplayName,
      articleNo: item.articleNo,
      barcode: item.barcode,
      productType: item.productType,
      segment: item.segment,
      unit: item.unit,
      unitPrice: item.unitPrice || item.wholesalePrice,
      wholesalePrice: item.wholesalePrice,
      wholesalePricePerUnit: item.wholesalePricePerUnit,
      qty: [
        { value: item.qty, disabled: this.data.mode === RequestPageModesEnum.REQUEST_VIEW },
        [Validators.required, this.setValidator(RequestProductErrorEnum.NOT_ALLOW_ZERO)]
      ],
      vatAmount: item.vatAmount,
      amountExcVat: item.amountExcVat,
      wholesalePriceExcVat: item.wholesalePriceExcVat,
      deliveryMethod: item.deliveryMethod,
      supplierCode: item.supplierCode,
      supplierName: item.supplierName,
      totalVat:
        item.totalVat ||
        getPriceFormat(round(item.vatAmount.amount * item.qty, 2), item.vatAmount && item.vatAmount.currency),
      amount: item.amount,
      allowToDelete: item.allowToDelete,
      vat: item.vat
    });

    if (errorMessage) {
      this.validateForm(formItem, errorMessage, item.errorMessageValue);
    }

    return formItem;
  }

  setOrderRequestValue() {
    this.orderRequestView$
      .pipe(
        filter(value => Boolean(value)),
        tap(value => {
          this.requestNo = value.requestNo;
          this.status = value.status || NewRequestStatusEnum.DRAFT;
          this.orderRequest.orderType = value.orderType || this.data.orderType;
          this.orderRequest.requestedDate = value.requestedDate;
          this.orderRequest.amountExcVat = value.amountExcVat || getPriceFormat();
          this.orderRequest.vatAmount = value.vatAmount || getPriceFormat();
          this.orderRequest.amount = value.amount || getPriceFormat();
          this.orderRequest.store = value.store;

          this.data.warehouseCode = value.warehouseCode || this.data.warehouseCode;
          this.data.warehouseDisplayName = value.warehouseDisplayName || this.data.warehouseDisplayName;

          if (value.productOrderItems && value.productOrderItems.length > 0) {
            this.store.dispatch(
              new OrderSelectItemOrderAddAllItem({ itemList: value.productOrderItems, skipUpdated: true })
            );
          }

          if (value.shelfDetails && value.shelfDetails.length > 0) {
            this.store.dispatch(new ShelfDetailsResponseAction(value.shelfDetails));
          }

          if (RequestPageModesEnum.REQUEST_EDIT === this.data.mode) {
            const itemList = value.productOrderItems.map(item => {
              return { articleNo: item.articleNo, barcode: item.barcode } as OrderRequestValidate;
            });

            this.itemValidator(itemList);
          }
        }),
        take(1)
      )
      .subscribe();
  }

  onSave() {
    this.saved = true;
    this.store.dispatch(new OrderCreateSaveRequestAction(this.prepareRequestData()));
  }

  onSubmit() {
    this.submitted = true;
    this.isLoading = true;

    this.clearSearchText();
    this.baseFormArray.updateValueAndValidity();

    const errorMessage = this.getFormGroupErrorMessage();

    if (errorMessage) {
      this.showModalError(errorMessage);
      this.isLoading = false;
      return;
    } else if (this.baseFormArray.invalid) {
      const itemLists = this.baseFormArray.controls.map(item => {
        return { articleNo: item.value.articleNo, barcode: item.value.barcode } as OrderRequestValidate;
      });

      this.itemValidator(itemLists);
      return;
    } else if (this.orderRequest.deliveryStatus === 'error') {
      this.showAlertModal('Delivery details is required or delivery date is invalid.');
      this.isLoading = false;
      return;
    }

    const itemList = this.baseFormArray.controls.map(item => {
      return { articleNo: item.value.articleNo, barcode: item.value.barcode } as OrderRequestValidate;
    });

    this.itemValidator(itemList);
  }

  itemValidator(itemList: Array<OrderRequestValidate>) {
    const itemList$: Observable<ProductOrderItem[]> = this.orderRequestService.validateOrderList(
      this.storeNo,
      this.data.orderType,
      itemList
    );

    itemList$
      .pipe(
        tap(errorList => {
          this.baseFormArray.controls.forEach(item => {
            const articleNo = item.get('articleNo').value;
            const error = errorList.find(x => x.articleNo === articleNo);

            if (error) {
              this.validateForm(item, error.errorMessage, error.errorMessageValue);
            } else {
              item.clearValidators();
              item.updateValueAndValidity();
            }
            this.isLoading = false;
          });
        }),
        filter(() => this.submitted)
      )
      .subscribe(() => {
        this.baseFormArray.updateValueAndValidity();
        if (this.baseFormArray.valid && this.orderRequest.deliveryStatus !== 'error') {
          this.handleConfirm();
        } else {
          this.validateError();
        }
      });
  }

  validateError() {
    const invalidIndex = this.baseFormArray.controls.findIndex(item => item.invalid);
    this.paging.navigateToErrorIndex(invalidIndex);

    const error = this.baseFormArray.controls.find(item => !!item.errors);
    if (error) {
      this.showModalError('Please delete invalid data before submit.');
    }

    this.isLoading = false;
  }

  getFormGroupErrorMessage(): string | undefined {
    const formGroup = this.orderRequestForm.get('productOrderItems') as FormArray;

    if (this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER && !this.shelfDetails.length) {
      this.orderRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one shelf before submit.';
    } else if (!formGroup.controls.length) {
      this.orderRequestForm.setErrors({ requiredItem: true });
      return 'Please select at least one item before submit.';
    }
  }

  prepareRequestData(): OrderRequestViewResponse {
    const formData = this.orderRequestForm.getRawValue();
    const template = this.orderRequest;
    template.status = this.status;
    template.orderType = this.data.orderType;
    template.shelfDetails = this.shelfDetails.map(item => {
      return {
        shelfNo: item.shelfNo,
        shelfTypeCode: item.shelfTypeCode,
        shelfType: item.shelfType,
        shelfCode: item.shelfCode,
        shelfOption: item.shelfOption
      };
    });
    template.warehouseCode = this.data.warehouseCode;

    this.orderRequestView$
      .pipe(
        untilComponentDestroyed(this),
        filter(value => Boolean(value))
      )
      .subscribe((value: OrderRequestViewResponse) => {
        template.id = value.id;
        template.version = value.version;
        template.requestNo = value.requestNo;
        template.store = value.store;
        template.deliveryDetails = value.deliveryDetails;
      });

    template.productOrderItems = formData.productOrderItems;

    return template;
  }

  openSelectShelf() {
    const initialState = {
      title: null,
      childItem: new ChildItem(SelectShelfModalComponent, { storeNo: this.orderRequest.store.storeNo }, false)
    };

    const modal = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    if (modal && modal.content) {
      const selectedShelf = modal.content.childItem.notifyParent
        .pipe(untilComponentDestroyed(this))
        .subscribe(updated => {
          if (updated && updated.notificationType === NotificationTypeEnum.NEXT) {
            this.isLoading = updated.result.response;
          }

          if (selectedShelf) {
            selectedShelf.unsubscribe();
          }
        });
    }
  }

  showShelfDetails() {
    this.modalService.show(ShelfDetailsComponent);
  }

  showVatDetails() {
    const vatDetails = [];

    this.baseFormArray.controls.forEach((itemForm, i) => {
      const rawValue = (itemForm as FormGroup).getRawValue();
      const data = {
        itemNo: i + 1,
        productName: rawValue.productName,
        vat: 0,
        vatAmount: rawValue.totalVat.amount,
        isInvalid: itemForm.get('qty').invalid || itemForm.get('qty').value === null
      };

      if (rawValue.vat) {
        this.vatPct$.subscribe(vatPct => (data.vat = vatPct));
      }
      vatDetails.push(data);
    });

    const initialState = {
      title: 'VAT',
      vatDetails
    };

    this.modalService.show(VatDetailsModalComponent, {
      initialState
    });
  }

  openSelectItem() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        SelectItemModalComponent,
        {
          title: 'Select Item',
          orderType: OrderingMethodEnum.SPECIAL_REQUEST,
          storeNo: this.storeNo
        },
        false
      )
    };

    this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  onCancel() {
    if (this.isDataUpdated || this.orderRequestForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  deleteRequest() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?',
        okText: 'Yes, delete',
        cancelText: 'Cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestService
            .deleteByRequestId({ orderId: this.data.requestId })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been deleted.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  cancelRequest() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number <strong>&quot;${this.requestNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.orderRequestService
            .approveRequest({
              requestNo: this.requestNo,
              status: ApproveStatusEnum.CANCELLED,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The request has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  doApproveRejectRequest(isApprove: boolean) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: isApprove ? 'Are you sure you want to "Approve"?' : 'Are you sure you want to "Reject"?',
        label: 'Comment',
        okText: isApprove ? 'Approve' : 'Reject',
        okClass: isApprove ? 'btn btn-special-approve' : 'btn btn-special-reject',
        isRequiredConfirmMessage: !isApprove
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.localStore
            .pipe(
              select(selectOrderApproveRejectStatus),
              filter(isApproveRejectSuccess => isApproveRejectSuccess)
            )
            .subscribe(() => {
              this.alertApproveRejectModalSuccess(
                {
                  result: {
                    response: 'success',
                    errorResponse: null
                  }
                },
                isApprove
              );
            });
          this.store.dispatch(
            new OrderApproveRequestAction({
              requestNo: this.requestNo,
              status: isApprove ? ApproveStatusEnum.APPROVED : ApproveStatusEnum.REJECTED,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  alertApproveRejectModalSuccess(resp, isApprove) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message: isApprove ? 'The request has been approved.' : 'The request has been rejected.'
      },
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK || result === ModalButtonResponseEnum.CANCEL) {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: resp });
      }
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState,
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
        if (this.status === NewRequestStatusEnum.DRAFT) {
          this.listSearchCriteria.page = 0;
        }
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    let minOrder: Money;
    let initialState: { title: string; message: any };

    if (errorResponse.code === '03002') {
      const error = errorResponse.message.split(':');

      if (error.length > 1) {
        const minOrderObj = error[1].split(' ');

        minOrder = {
          amount: minOrderObj[1] ? Number(minOrderObj[1]) : 0,
          currency: minOrderObj[0] ? minOrderObj[0] : 'THB'
        };
      }

      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message,
          amount: (minOrder && numeral(minOrder.amount).format(this.numberFormat)) || 0,
          currency: (minOrder && minOrder.currency) || 'THB'
        })
      };
    } else if (errorResponse.code === '05008') {
      initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
    } else {
      initialState = {
        title: 'Failed',
        message: this.translate.instant(errorResponse.translateKey, {
          context: errorResponse.message
        })
      };
    }

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        this.isLoading = false;
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new OrderCreateSubmitRequestAction(this.prepareRequestData()));
        }
      });
  }

  onTriggerEdit() {
    this.data.title = 'Edit Order Request';
    this.data.mode = RequestPageModesEnum.REQUEST_EDIT;
    this.isLoading = true;

    this.clearSearchText();

    const itemList = this.orderRequestForm.getRawValue().productOrderItems.map(item => {
      return { articleNo: item.articleNo, barcode: item.barcode } as OrderRequestValidate;
    });

    this.itemValidator(itemList);

    if ([NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status)) {
      this.orderRequestForm.enable();
      this.getButton();
    }
  }

  getSubmitMessage(isSave): string | undefined {
    if (isSave) {
      return 'The request has been saved.';
    } else if (this.status === NewRequestStatusEnum.DRAFT) {
      return 'The request has been sent to approver.';
    } else if (this.status === NewRequestStatusEnum.AWAITING_APPROVAL) {
      return 'The request has been updated.';
    }
  }

  search() {
    const formValue = this.searchForm.value;
    const searchTerm = formValue && formValue.searchCriteria && formValue.searchCriteria.toLowerCase();
    const result = !searchTerm
      ? this.baseFormArray.controls
      : this.baseFormArray.controls.filter(
          item =>
            item
              .get('articleNo')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('barcode')
              .value.toLowerCase()
              .includes(searchTerm) ||
            item
              .get('productName')
              .value.toLowerCase()
              .includes(searchTerm)
        );

    this.resetFilteredFormArrayTo(result);
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  onSubmitSearch() {
    this.setFirstPage();
    this.search();
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.setFirstPage();
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.setFirstPage();
      this.search();
    }
  }

  hasSavePermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.status === NewRequestStatusEnum.DRAFT &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m'])
    );
  }

  hasSubmitPermission() {
    return (
      this.data.mode !== RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m'])
    );
  }

  hasEditPermission() {
    return (
      [NewRequestStatusEnum.DRAFT, NewRequestStatusEnum.AWAITING_APPROVAL].includes(this.status) &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m'])
    );
  }

  hasCancelPermission() {
    return (
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m'])
    );
  }

  hasDeletePermission() {
    return (
      this.status === NewRequestStatusEnum.DRAFT &&
      this.data.mode === RequestPageModesEnum.REQUEST_VIEW &&
      this.authGuardService.checkPermission(['so_firstlot_m', 'so_special_m'])
    );
  }

  hasApprovePermission() {
    return (
      this.status === NewRequestStatusEnum.AWAITING_APPROVAL && this.authGuardService.checkPermission(['so_app'])
    );
  }

  hasAtLeastOnePermission() {
    return (
      this.hasSavePermission() ||
      this.hasEditPermission() ||
      this.hasApprovePermission() ||
      this.hasSubmitPermission()
    );
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  sortList(a: AbstractControl, b: AbstractControl) {
    const aProductType = a.value.productType;
    const aArticleNo = a.value.articleNo;
    const bProductType = b.value.productType;
    const bArticleNo = b.value.articleNo;

    if (aProductType < bProductType) {
      return -1;
    }
    if (aProductType > bProductType) {
      return 1;
    }
    if (aArticleNo < bArticleNo) {
      return -1;
    }
    if (aArticleNo > bArticleNo) {
      return 1;
    }
    return 0;
  }

  initialSelectItemOrder() {
    this.localStore
      .pipe(select(selectAllOrderSelectItemOrder), untilComponentDestroyed(this))
      .subscribe(productOrderItems => {
        const hasNewItem = productOrderItems.some(item => item.isAddItem);
        const articleNoList = this.baseFormArray.controls.map(control => control.get('articleNo').value);
        const addProductOrderItems = productOrderItems.filter(item => item.isAddItem || item.isAddItem === undefined);

        addProductOrderItems
          .filter(item => articleNoList.length > 0 && articleNoList.indexOf(item.articleNo) >= 0)
          .forEach(item => {
            const ctrlIndex = articleNoList.indexOf(item.articleNo);
            const selectedItem = this.baseFormArray.controls[ctrlIndex];

            item.isAddItem = false;

            item.unitPrice = item.wholesalePrice;
            selectedItem.patchValue(item);
          });

        addProductOrderItems
          .filter(item => articleNoList.indexOf(item.articleNo) === -1)
          .forEach(item => {
            item.isAddItem = false;
            this.baseFormArray.push(this.createItemForm(item));
          });

        this.baseFormArray.controls.sort(this.sortList);

        if (hasNewItem) {
          this.searchForm.controls['searchCriteria'].reset();
        }

        if (this.isSkipSetFilteredForm) {
          this.isSkipSetFilteredForm = false;
        } else if (this.searchForm.controls['searchCriteria'].value) {
          this.search();
        } else {
          this.resetFilteredFormArrayTo(this.baseFormArray.controls);
        }

        if (this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
          this.getSegmentList(productOrderItems);
        }

        if (!this.skipUpdated && productOrderItems.length > 0) {
          this.calculateTotalAmount();
        }

        this.isLoading = false;
      });
  }

  updateValueChangesEvent(data, item) {
    const control = this.baseFormArray.controls.find(ctrl => ctrl.get('articleNo').value === item.articleNo);

    if (!control) {
      return;
    }

    const amountExcVat = round(control.get('wholesalePriceExcVat').value.amount, 2) * data || 0;
    const totalVat = round(control.get('vatAmount').value.amount * data, 2) || 0;
    const amount = round(amountExcVat + totalVat, 2) || 0;

    this.isSkipSetFilteredForm = true;

    control.get('amountExcVat').patchValue({
      amount: amountExcVat,
      currency: control.get('amountExcVat').value.currency
    });
    control.get('totalVat').patchValue({ amount: totalVat, currency: control.get('vatAmount').value.currency });
    control.get('amount').patchValue({ amount, currency: control.get('amount').value.currency });
    control.updateValueAndValidity();

    control.setParent(this.baseFormArray);

    this.store.dispatch(new OrderSelectItemOrderUpdateItem(control.value));
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    let excVatAmt = 0;
    let totalVatAmt = 0;
    let incVatAmt = 0;

    this.baseFormArray.controls.forEach(itemForm => {
      const amountExcVat = itemForm.get('amountExcVat').value.amount || 0;
      const totalVat = itemForm.get('totalVat').value.amount || 0;
      const amount = itemForm.get('amount').value.amount || 0;

      excVatAmt = round(excVatAmt + amountExcVat, 2);
      totalVatAmt = round(totalVatAmt + totalVat, 2);
      incVatAmt = round(incVatAmt + amount, 2);
    });

    this.orderRequest.amountExcVat.amount = excVatAmt;
    this.orderRequest.vatAmount.amount = totalVatAmt;
    this.orderRequest.amount.amount = incVatAmt;
    this.isDataUpdated = true;
  }

  deleteItemWithoutConfirmation(item: ProductOrderItem) {
    this.deleteProductItem(item);
    this.calculateTotalAmount();
  }

  deleteProductItem(item: ProductOrderItem) {
    const itemIndex = this.baseFormArray.controls.findIndex(x => x.value.articleNo === item.articleNo);

    if (itemIndex > -1) {
      this.baseFormArray.removeAt(itemIndex);
      this.store.dispatch(new OrderSelectItemOrderRemoveItem(item.articleNo));
      if (this.baseFormArray.value.length === 0) {
        this.store.dispatch(new OrderRequestDeliveryDetailsResetAction());
      }
    }
  }

  deleteItem(item?: ProductOrderItem) {
    const deleteAllItem = !item;
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: !deleteAllItem
        ? 'Are you sure you want to delete this item?'
        : 'Are you sure you want to delete all items?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          if (!deleteAllItem) {
            this.deleteProductItem(item);
          } else {
            this.setFirstPage();
            this.baseFormArray.clear();

            this.store.dispatch(new OrderSelectItemOrderReset());
            this.store.dispatch(new ShelfDetailsResetAction());
            this.store.dispatch(new OrderRequestDeliveryDetailsResetAction());
          }

          this.calculateTotalAmount();
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  openDeliveryDetails() {
    if (!this.baseFormArray.controls.length) {
      this.showAlertModal('Please select at least one item.');
      return;
    }

    const component =
      this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER
        ? DeliveryDetailsComponent
        : DeliveryDetailsSpecialComponent;

    const initialState = {
      orderType: this.data.orderType,
      mode: this.data.mode,
      ...(this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER && {
        segmentList: this.segmentList
      })
    };

    const modal = this.modalService.show(component, {
      class: this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER ? 'modal-lg' : 'modal-md',
      initialState
    });

    const updateDeliveryDetails = modal.content.updateDeliveryDetails
      .pipe(untilComponentDestroyed(this))
      .subscribe(updated => {
        this.isDataUpdated = updated;

        if (updateDeliveryDetails) {
          updateDeliveryDetails.unsubscribe();
        }
      });
  }

  showAlertModal(message: string) {
    this.showModalMessage('Alert', message);
  }

  showModalError(message: string) {
    this.showModalMessage('Failed', message);
  }

  showModalMessage(title: string, message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });
  }

  validateForm(formItem: AbstractControl, errorMessage: RequestProductErrorEnum, value = '') {
    switch (errorMessage) {
      case RequestProductErrorEnum.RECALL:
      case RequestProductErrorEnum.HOLD_BUY_SELL:
      case RequestProductErrorEnum.DELETE:
      case RequestProductErrorEnum.FRESH_LITE:
      case RequestProductErrorEnum.ASSORTMENT:
      case RequestProductErrorEnum.INVALID_SUPPLIER_PRICE:
      case RequestProductErrorEnum.INVALID_SUPPLIER:
      case RequestProductErrorEnum.INVALID_BARCODE:
      case RequestProductErrorEnum.MAXIMUM_PURCHASING_PRICE:
        formItem.setValidators(this.setValidator(errorMessage, value));
        formItem.get('productName').setValidators(this.setValidator(errorMessage, value));
        formItem.get('qty').disable();
        formItem.setParent(this.baseFormArray);
        formItem.updateValueAndValidity();
        break;
      default:
        break;
    }
  }

  setValidator(errorMessage: string, value = ''): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      switch (errorMessage) {
        case RequestProductErrorEnum.RECALL:
          return { isRecall: true };
        case RequestProductErrorEnum.HOLD_BUY_SELL:
          return { isHoldBuySell: true };
        case RequestProductErrorEnum.DELETE:
          return { isDelete: true };
        case RequestProductErrorEnum.FRESH_LITE:
          return { isFreshLite: true };
        case RequestProductErrorEnum.ASSORTMENT:
          return { isStoreAssortment: true };
        case RequestProductErrorEnum.NOT_ALLOW_ZERO:
          if (control.value !== null) {
            return control.value === 0 ? { isZero: true } : null;
          }
          return null;
        case RequestProductErrorEnum.INVALID_SUPPLIER_PRICE:
          return { invalidSupplierPrice: true };
        case RequestProductErrorEnum.INVALID_SUPPLIER:
          return { invalidSupplier: true };
        case RequestProductErrorEnum.INVALID_BARCODE:
          return { invalidBarcode: true };
        case RequestProductErrorEnum.MAXIMUM_PURCHASING_PRICE:
          if (value && value.length > 0) {
            return { maximumPurchasingPrice: { hasError: true, price: value } };
          }
          return null;
        default:
          return null;
      }
    };
  }

  getSegmentList(items) {
    const list = items.map(item => {
      return item.productType !== ProductTypeEnum.INVENTORY ? item.productType : this.segments[item.segment];
    });

    this.segmentList = Array.from(new Set(list));
    this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(
      this.orderRequest.deliveryDetails && this.orderRequest.deliveryDetails.deliveryDetailsByType
    );

    this.updateDeliveryDetails();
  }

  updateDeliveryDetails() {
    if (this.orderRequest.deliveryDetails && this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
      const regex = /_[^_]+$/;
      const deliveryDetailsByType = this.orderRequest.deliveryDetails.deliveryDetailsByType;

      for (const segment in deliveryDetailsByType) {
        if (deliveryDetailsByType.hasOwnProperty(segment) && !this.segmentList.includes(segment.replace(regex, ''))) {
          delete deliveryDetailsByType[segment];
        }
      }

      this.orderRequest.deliveryStatus = this.getDeliveryDetailsStatus(deliveryDetailsByType);
    }
  }

  getDeliveryDetailsStatus(data: DeliveryDetailsByTypeDictionary<DeliveryDetail>) {
    const dataLength = data && Object.keys(data).length;
    let isDataInvalid = false;

    for (const segment in data) {
      if (data.hasOwnProperty(segment) && !data[segment].deliveryDate) {
        isDataInvalid = true;
        break;
      }
    }

    if (!isDataInvalid && this.data.orderType === OrderingMethodEnum.FIRST_LOT_ORDER) {
      isDataInvalid = dataLength !== this.segmentList.length * 2;
    }

    return dataLength && !isDataInvalid ? 'success' : 'error';
  }

  onExportProductItem() {
    const titleSheet = environment.fileName.exportOrderRequest.prefix;
    const formData = this.orderRequestForm.getRawValue();
    const fileName = this.exportFileName(titleSheet);

    const headers = [
      'No',
      'Article No',
      'Product Name',
      'Barcode',
      'Product Type',
      'Unit',
      'Unit Price',
      'Quantity',
      'Amount'
    ];
    const header = {
      title: titleSheet,
      headers
    } as HeaderSheet;

    const productItems = this.mappingProductItem(formData.productOrderItems);

    if (!productItems) {
      this.showAlertModal('Please select at least one item.');
      return;
    }

    const columnType: ColumnType = {
      A: ColumnTypeEnum.TEXT_CENTER,
      B: ColumnTypeEnum.TEXT,
      C: ColumnTypeEnum.TEXT,
      D: ColumnTypeEnum.TEXT,
      E: ColumnTypeEnum.TEXT,
      F: ColumnTypeEnum.TEXT_CENTER,
      G: ColumnTypeEnum.CURRENCY,
      H: ColumnTypeEnum.NUMBER,
      I: ColumnTypeEnum.CURRENCY
    };

    const workSheet = ExcelUtil.GeneratedWorkSheet(header, productItems, columnType);

    const workBook = ExcelUtil.GeneratedWorkBook(workSheet, titleSheet);

    ExcelUtil.WriteFile(workBook, fileName);
  }

  onImportProductItem() {
    const modal = this.modalService.show(ImportOrderRequestComponent, {
      backdrop: 'static',
      initialState: {
        currentData: this.baseFormArray.value
      }
    });

    const setData = modal.content.setData.pipe(untilComponentDestroyed(this)).subscribe(data => {
      this.showModalMessage('Success', 'The data have been imported.');
      this.store.dispatch(new OrderSelectItemOrderUpdateAllItem(data));
      if (setData) {
        setData.unsubscribe();
      }
    });
  }

  mappingProductItem(productItems: any[]) {
    if (productItems.length === 0) {
      return null;
    }

    return productItems.map((item, i) => {
      return {
        no: i + 1,
        articleNo: item.articleNo,
        productName: item.productName,
        barcode: item.barcode,
        productType: this.translate.instant(`PRODUCT_ASSORTMENT.PRODUCT_TYPE.${item.productType}`),
        unit: item.unit,
        unitPrice: item.unitPrice.amount,
        quantity: item.qty,
        amount: item.amount.amount
      } as OrderRequestProductItemsExport;
    });
  }

  exportFileName(titleSheet: string): string {
    if (this.status !== NewRequestStatusEnum.DRAFT && this.requestNo) {
      return `${this.requestNo} ${this.timeToExport}.xlsx`;
    }
    return `${titleSheet}_${this.storeCode} ${this.timeToExport}.xlsx`;
  }

  resetFilteredFormArrayTo(controls: Array<AbstractControl>) {
    this.filteredFormArray.clear();
    controls.forEach(ctrl => this.filteredFormArray.push(ctrl));
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportOrderRequest.timeFormat);
  }

  get filteredFormArray() {
    return this.filteredFormGroup.get('productOrderItems') as FormArray;
  }

  get baseFormArray() {
    return this.orderRequestForm.get('productOrderItems') as FormArray;
  }
}
