<div class="modal-content admin-form">
  <div class="modal-header">
    <h5 class="modal-title pull-left">Import</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <div class="form-row">
      <div class="form-group col-md">
        <label for="targetStockImport">Upload New Target Stock</label>
        <div class="d-flex">
          <app-target-stock-import-file-upload
            id="targetStockImport"
            formControlName="targetStockImport"
            [size]="50000000"
            [controlName]="'targetStockImport'"
            [disabled]="loading"
            (clearInput)="reset()"
            (showErrorMessage)="setErrorMessage($event)"
            class="flex-item"
          ></app-target-stock-import-file-upload>
        </div>
      </div>
    </div>

    <div *ngIf="errorMessage" class="alert-danger form-group" role="alert">
      {{ errorMessage }}
    </div>

    <ng-container *ngIf="errors.length">
      <table class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr class="error-background">
            <th class="text-center w-10">Row</th>
            <th class="text-center w-30">Column</th>
            <th class="text-center w-30">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let error of errors; index as i">
            <td class="text-center">{{ error.row }}</td>
            <td class="text-left">{{ error.column }}</td>
            <td class="text-left">{{ error.description }}</td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="bsModalRef.hide()" id="btnCancel">
      Cancel
    </button>

    <button
      type="button"
      class="btn btn-primary btn-loader"
      (click)="onSubmit()"
      id="btnSumit"
      [disabled]="form.invalid || loading || errorMessage"
    >
      OK
      <div class="loader" *ngIf="loading"></div>
    </button>
  </div>
</div>
