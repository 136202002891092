import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeliveryOrderStatusEnum } from '../enum/delivery-order-status.enum';
import {
  CancelDeliveryOrder,
  DeliveryOrder,
  DeliveryOrderExportPickingCriteria,
  DeliveryOrderPagination,
  DeliveryOrderPrintCriteria,
  DeliveryOrderSearchCriteria,
  SplitNoItem
} from '../models/delivery-order.model';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class DeliveryOrderService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.deliveryOrder;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getDeliveryOrderById(id: string): Observable<DeliveryOrder> {
    const headers: HttpHeaders = new HttpHeaders(this.env.services.deliveryOrder.headers);
    return this.http.get<any>(`${this.getUrl()}/${id}`, {
      headers,
      observe: 'body'
    });
  }

  public updateDeliveryOrder(request: DeliveryOrder): Observable<DeliveryOrder> {
    const items = this.prepareDeliverySplitNoData(request);
    const url = this.getFullUrl(this.envService.update, {
      id: request.id
    });

    return this.http.post<DeliveryOrder>(
      url,
      { items, version: request.version },
      {
        headers: this.loaderHeaders(),
        observe: 'body'
      }
    );
  }

  public submitDeliveryOrder(request: DeliveryOrder): Observable<DeliveryOrder> {
    if (request.doStatus === DeliveryOrderStatusEnum.DRAFT) {
      return this.submitSplitDO(request);
    } else {
      return this.submitDO(request);
    }
  }

  public searchByCriteria(criteria: DeliveryOrderSearchCriteria): Observable<DeliveryOrderPagination> {
    const params = this.getParams(criteria, true);

    return this.http.get<DeliveryOrderPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public cancelDeliveryOrder(request: CancelDeliveryOrder): Observable<DeliveryOrder> {
    const url = this.getFullUrl(this.envService.cancel);

    return this.http.put<DeliveryOrder>(url, request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public exportPicking(criteria: DeliveryOrderExportPickingCriteria): Observable<any> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.exportPicking);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public printPdfDeliveryOrder(criteria: DeliveryOrderPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.print, { ...criteria });
    const body = new HttpParams();

    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public getUrlImport() {
    return this.getFullUrl(this.envService.importPicking);
  }

  public uploadDeliveryOrderFiles(files: Array<File>, url: string): Array<Observable<UploadResultType>> {
    return files.map(file => this.uploadDeliveryOrderFile(file, url));
  }

  public uploadDeliveryOrderFile(file: File, url: string): Observable<UploadResultType> {
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http
      .post<any>(url, formData, {
        reportProgress: true,
        observe: 'events',
        headers: this.loaderHeaders()
      })
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              const progress = Math.round((100 * event.loaded) / event.total);
              return { status: 'progress', message: progress };
            case HttpEventType.Response:
              return { validations: event.body, status: 'done' };
            default:
              const msg = `Unhandled event: ${HttpEventType[event.type]}`;
              this.logger.info(msg);
              return null;
          }
        }),
        catchError(err => {
          return of(err);
        })
      );
  }

  private submitDO(request: DeliveryOrder): Observable<DeliveryOrder> {
    const url = this.getFullUrl(this.envService.edit, {
      id: request.id
    });

    return this.http.put<DeliveryOrder>(url, request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  private submitSplitDO(request: DeliveryOrder): Observable<DeliveryOrder> {
    const items = this.prepareDeliverySplitNoData(request);
    const url = this.getFullUrl(this.envService.submit, {
      id: request.id
    });

    return this.http.post<DeliveryOrder>(
      url,
      { items, version: request.version },
      {
        headers: this.headers,
        observe: 'body'
      }
    );
  }

  private prepareDeliverySplitNoData(request: DeliveryOrder): SplitNoItem[] {
    return request.items.map(doItem => {
      return {
        itemNo: doItem.itemNo,
        splitNo: doItem.splitNo
      };
    });
  }
}
