import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StoreAssortmentService } from '../../services/store-assortment.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { MasterAssortmentActionType, MasterAssortmentListResponseAction } from '../actions/master-assortment.actions';
export class MasterAssortmentEffects {
    constructor(actions$, storeAssortmentService, logger) {
        this.actions$ = actions$;
        this.storeAssortmentService = storeAssortmentService;
        this.logger = logger;
        this.searchMasterAssortment$ = this.actions$.pipe(ofType(MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.storeAssortmentService.searchAssortmentByCriteria(payload.storeNo, payload.criteria).pipe(map(response => {
                return new MasterAssortmentListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], MasterAssortmentEffects.prototype, "searchMasterAssortment$", void 0);
