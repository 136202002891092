import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  VoucherRequest,
  VoucherRequestListResponse,
  VoucherRequestSearchCriteria,
  VoucherRequestViewResponse,
  VoucherRequestWithComment
} from '../models';
import { BaseService } from './base.service';

@Injectable()
export class VoucherRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.vouchersRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteriaRequest(criteria: VoucherRequestSearchCriteria): Observable<VoucherRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  submit(data: VoucherRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  getVoucherRequestById(requestsId: string): Observable<VoucherRequestViewResponse> {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: requestsId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  approve(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  reject(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  cancel(data: VoucherRequestWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
}
