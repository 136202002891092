import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { RequestAssortmentService } from '../services';

export function duplicatedArticleValidator(
  requestAssortmentService: RequestAssortmentService,
  bypassArticleNoCheck?: string | null
): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    // const debounceTime = 500; // milliseconds

    return timer().pipe(
      switchMap(() => {
        if (control.value && control.value !== bypassArticleNoCheck) {
          return requestAssortmentService.checkDuplicatedArticleNo(control.value).pipe(
            switchMap(() => of({ duplicated: true })),
            catchError(() => of(null))
          );
        } else {
          return of(null);
        }
      })
    );
  };
}
