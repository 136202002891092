import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PromotionRequestListResponse,
  PromotionRequestResponse,
  PromotionSearchCriteria,
  RequestWithComment
} from '../models';
import { BaseService } from './base.service';
@Injectable()
export class PromotionRequestService extends BaseService {
  public headers: HttpHeaders;
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.promotionsRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }
  searchByCriteria(criteria: PromotionSearchCriteria): Observable<PromotionRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  getByRequestId(requestId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: requestId
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  createRequest(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
  updateByRequestId(data: PromotionRequestResponse): Observable<any> {
    const url = this.getUrl();
    return this.http.put<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  submit(data: PromotionRequestResponse): Observable<any> {
    const url = this.getFullUrl(this.envService.requestsSubmit);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
  approve(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  reject(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
  getPromotionById(promotionId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { id: promotionId });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
  /**
   * @description For delete promotion request
   */
  delete(promotionId: string) {
    const url = this.getFullUrl(this.envService.requestsID, {
      requestId: promotionId
    });
    return this.http.delete<any>(url, {
      headers: this.loaderHeaders()
    });
  }

  /**
   * @description For cancel promotion request
   */
  requestCancel(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.requestCancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }
}
