import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { NewMasterData } from '../../../shared/gql/common.gql';
import { SalesSummaryReportExportCriteria } from '../../../shared/models/report.model';
import { MasterService } from '../../../shared/services/master.service';
import { SalesSummaryReportService } from '../../../shared/services/sales-summary-report.service';
import { AppStates } from '../../../shared/store/state/app.states';
import { dateTimeToDateOnlyString } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-sales-summary-modal',
  templateUrl: './sales-summary-modal.component.html',
  styleUrls: ['./sales-summary-modal.component.scss']
})
export class SalesSummaryModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('salesSummaryReportModal', { static: false }) salesSummaryReportModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  public storeTypeList: Array<NewMasterData> | null;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly salesSummaryReportService: SalesSummaryReportService,
    private readonly masterService: MasterService
  ) {
    super();
  }

  ngOnInit() {
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      createdDateFrom: [null],
      createdDateTo: [null],
      storeType: [null]
    });

    this.masterService
      .getMasterDataByNames([MasterDataEnum.MERCHANT])
      .pipe(
        filter(res => Boolean(res && res.data)),
        map(res => res.data)
      )
      .subscribe(result => {
        this.storeTypeList = result[MasterDataEnum.MERCHANT];
      });
  }

  get form() {
    return this.exportForm.controls;
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.salesSummaryReportModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.salesSummaryReportModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.exportFormInValid) {
      return;
    }
    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();
    const salesSummaryReportExportCriteria = new SalesSummaryReportExportCriteria({
      createdDateFrom: dateTimeToDateOnlyString(rawData.createdDateFrom),
      createdDateTo: dateTimeToDateOnlyString(rawData.createdDateTo),
      storeType: rawData.storeType
    });

    this.salesSummaryReportService.reportExport(salesSummaryReportExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportSalesSummary.prefixReport} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeExportCreatedDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportCreatedDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportSalesSummary.timeFormat);
  }
}
