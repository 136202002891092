import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProductMaster } from '../models/product-master.model';

@Injectable()
export class ProductMasterService {
  private readonly env = environment;
  private readonly envService = this.env.services.productMaster;

  constructor(private readonly http: HttpClient) {}

  findProductMasterByArticleNo(articleNo: string): Observable<ProductMaster> {
    const headers: HttpHeaders = new HttpHeaders(this.env.services.productMaster.headers);
    return this.http.get<ProductMaster>(`${this.getUrl()}/${articleNo}`, {
      headers
    });
  }

  private getUrl() {
    return this.env.serverUrl + this.envService.url;
  }
}
