import { Action } from '@ngrx/store';
import { BillToBase } from '../../models/warehouse.model';

export enum BillToMasterActionTypes {
  BILL_TO_MASTER_LIST_REQUEST = '[Bill To Master List] Bill To Master List Request',
  BILL_TO__MASTER_LIST_RESPONSE = '[Bill To Master List] Bill To Master List Response'
}

export class BillToMasterListRequestAction implements Action {
  readonly type = BillToMasterActionTypes.BILL_TO_MASTER_LIST_REQUEST;

  constructor() {}
}

export class BillToMasterListResponseAction implements Action {
  readonly type = BillToMasterActionTypes.BILL_TO__MASTER_LIST_RESPONSE;
  constructor(public payload: BillToBase[]) {}
}

export type BillToMasterAction = BillToMasterListRequestAction | BillToMasterListResponseAction;
