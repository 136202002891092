import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShelfRequestService } from '../../services/shelf-request.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ShelfApproveResponseAction, ShelfCreateSaveErrorAction, ShelfCreateSaveResponseAction, ShelfCreateSubmitErrorAction, ShelfCreateSubmitResponseAction, ShelfDeleteResponseAction, ShelfRequestActionType, ShelfRequestByIdResponseAction, ShelfRequestListHistoryResponseAction, ShelfRequestListResponseAction } from '../actions/shelf-request.actions';
export class ShelfRequestEffects {
    constructor(actions$, shelfRequestService, logger) {
        this.actions$ = actions$;
        this.shelfRequestService = shelfRequestService;
        this.logger = logger;
        this.searchShelfRequest$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfRequestService.searchByCriteria(payload).pipe(map(response => {
                return new ShelfRequestListResponseAction(response);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfCreateSave$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfRequestService.saveRequest(payload).pipe(map(res => new ShelfCreateSaveResponseAction(res)), catchError(error => of(new ShelfCreateSaveErrorAction(error))));
        }));
        this.shelfCreateSubmit$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfRequestService.submitRequest(payload).pipe(map(res => new ShelfCreateSubmitResponseAction(res)), catchError(error => of(new ShelfCreateSubmitErrorAction(error.error))));
        }));
        this.getShelfRequestById$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfRequestService.getShelfRequestById(payload).pipe(map(res => {
                return new ShelfRequestByIdResponseAction(res);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfApprove$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.shelfRequestService.approveRequest(payload).pipe(map(() => new ShelfApproveResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.shelfRequestHistory$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.shelfRequestService.getHistoryLogs(payload).pipe(map(response => new ShelfRequestListHistoryResponseAction({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.shelfRequestDelete$ = this.actions$.pipe(ofType(ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST), map(action => {
            this.logger.debug(`@Effect ${ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST}: ` + JSON.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => this.shelfRequestService.deleteByRequestId(payload).pipe(map(() => new ShelfDeleteResponseAction({ isSuccess: true })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "searchShelfRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "shelfCreateSave$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "shelfCreateSubmit$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "getShelfRequestById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "shelfApprove$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "shelfRequestHistory$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ShelfRequestEffects.prototype, "shelfRequestDelete$", void 0);
