import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-z8-factor',
  templateUrl: './Z8-factor.component.html',
  styleUrls: ['./Z8-factor.component.scss']
})
export class Z8FactorComponent implements OnInit {
  @Input() data: any;

  public results: any;
  public z8FactorDetails = [
    { label: 'Beauty Value', fieldValue: 'beautyValue' },
    { label: 'Safety Day', fieldValue: 'safetyDay' },
    { label: '%Weekend', fieldValue: 'weekend' },
    { label: '%First Day of Month', fieldValue: 'firstDayOfMonth' },
    { label: 'Promotion', fieldValue: 'promotion' },
    { label: '%Round to Picking Unit', fieldValue: 'roundToPickingUnit' }
  ];

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
