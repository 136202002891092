<div class="left-section left-section-wrapper">
  <h2 class="forgot-section-header">{{ 'TD_NEST' | translate }}</h2>
  <div>
    <div *ngIf="step === 'REQUEST_OTP'">
      <h4>
        {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
      </h4>
      <p>{{ 'FORGOT_PASSWORD.PLEASE_ENTER_YOUR_USERNAME' | translate }}</p>
    </div>
    <div *ngIf="step === 'ENTER_OTP'">
      <h4>
        {{ 'FORGOT_PASSWORD.FORGOT_PASSWORD' | translate }}
      </h4>
      <p>
        {{ 'FORGOT_PASSWORD.REQUEST_ENTER_OTP_SMS' | translate }}
      </p>
    </div>
    <div *ngIf="step === 'CHANGE_PASSWORD'">
      <h4>
        {{ 'FORGOT_PASSWORD.RESET_PASSWORD' | translate }}
      </h4>
      <p>{{ 'FORGOT_PASSWORD.WELCOME_TO_THE_SYSTEM_FOR_MORE_SECURITY_PLEASE_SET_YOUR_NEW_PASSWORD' | translate }}</p>
    </div>

    <button type="button" class="btn btn-standard" (click)="onCancel()">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
