import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  GoodsReceive,
  GoodsReceiveContent,
  GoodsReceiveErrorResponse,
  GoodsReceiveSearchCriteria
} from '../../models/goods-receive.model';
import { GoodsReceiveActions, GoodsReceiveActionTypes } from '../actions/goods-receive.actions';

export interface GoodsReceiveState extends EntityState<GoodsReceiveContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  goodsReceive: GoodsReceive;
  goodsReceiveErrorResponse: GoodsReceiveErrorResponse;
  criteriaObject: GoodsReceiveSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<GoodsReceiveContent> = createEntityAdapter<GoodsReceiveContent>();

export const initialGoodsReceiveState: GoodsReceiveState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  goodsReceive: null,
  goodsReceiveErrorResponse: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function goodsReceiveReducers(
  state = initialGoodsReceiveState,
  action: GoodsReceiveActions
): GoodsReceiveState {
  switch (action.type) {
    case GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_LOADED: {
      return {
        ...state,
        goodsReceive: action.payload
      };
    }
    case GoodsReceiveActionTypes.GOODS_RECEIVE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case GoodsReceiveActionTypes.GOODS_RECEIVE_RESET: {
      return {
        ...state,
        goodsReceive: null,
        goodsReceiveErrorResponse: null
      };
    }
    case GoodsReceiveActionTypes.GOODS_RECEIVE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_RESPONSE_SUCCESS: {
      return {
        ...state,
        saveSuccess: true
      };
    }
    case GoodsReceiveActionTypes.GOODS_RECEIVE_RESET_SAVE_SUCCESS_STATUS: {
      return {
        ...state,
        saveSuccess: false
      };
    }
    case GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_RESPONSE_ERROR: {
      return {
        ...state,
        goodsReceiveErrorResponse: action.payload
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
