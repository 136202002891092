import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StackPricingDeleteRequest,
  StackPricingListResponse,
  StackPricingSearchCriteria,
  StackPricingView
} from '../models/stack-pricing.model';
import { BaseService } from './base.service';

@Injectable()
export class StackPricingService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.pricing;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: StackPricingSearchCriteria): Observable<StackPricingListResponse> {
    const url = this.getFullUrl(this.envService.request);
    const params = this.getParams(criteria, true);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  getStackPricingByID(articleNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { articleNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  submit(data: StackPricingView): Observable<any> {
    const url = this.getFullUrl(this.envService.request);
    return this.http.post<any>(url, data, { headers: this.loaderHeaders() });
  }

  deleteStackPricingByID(data: StackPricingDeleteRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.delete);
    return this.http.post<any>(url, data, { headers: this.loaderHeaders() });
  }

  getHistoryByID(articleNo: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, { articleNo });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }
}
