import { environment } from '../../../environments/environment';
import { MerchantRequestService } from './merchant-request.service';
import { ShelfTypesService } from './shelf-types.service';
import * as i0 from "@angular/core";
import * as i1 from "./merchant-request.service";
import * as i2 from "./shelf-types.service";
export class FileUrlService {
    constructor(merchantRequestService, shelfTypeService) {
        this.merchantRequestService = merchantRequestService;
        this.shelfTypeService = shelfTypeService;
        this.env = environment;
        this.merchantRequest = refId => this.merchantRequestService.getFileUrl(refId);
        this.shelfType = refId => this.shelfTypeService.getFileUrl(refId);
    }
}
FileUrlService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileUrlService_Factory() { return new FileUrlService(i0.ɵɵinject(i1.MerchantRequestService), i0.ɵɵinject(i2.ShelfTypesService)); }, token: FileUrlService, providedIn: "root" });
