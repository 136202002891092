<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-bill"></em>
    <h4>Profit Sharing</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between">
      <div class="form-group text-nowrap d-none d-md-block">
        <app-import-export-button [buttons]="buttons" (export)="openExportModal()"></app-import-export-button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="Enter keyword search"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            placeholder="Please select..."
            [items]="profitSharingStatus"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        As of Month
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="scheduleFrom"
                          formControlName="scheduleFrom"
                          type="text"
                          placeholder="{{ environment.monthFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsMonthConfig"
                          [maxDate]="maxMonth"
                          (bsValueChange)="onChangeMonthFrom($event)"
                          readonly
                        />
                        <label for="scheduleFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="scheduleTo"
                          formControlName="scheduleTo"
                          type="text"
                          placeholder="{{ environment.monthFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsMonthConfig"
                          [minDate]="minMonth"
                          (bsValueChange)="onChangeMonthTo($event)"
                          readonly
                        />
                        <label for="scheduleTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Last Updated
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="lastModifiedDateFrom"
                          formControlName="lastModifiedDateFrom"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="lastModifiedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="lastModifiedDateTo"
                          formControlName="lastModifiedDateTo"
                          type="text"
                          placeholder="{{ environment.dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="lastModifiedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateTag || scheduleTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearDateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="scheduleStringTag">
      <a class="remove-criteria" (click)="clearScheduleFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ scheduleStringTag }}: {{ scheduleTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async)?.totalElements > 0">
    <div class="td-card" *ngFor="let profitSharing of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 flex-item d-flex flex-column cursor-pointer" (click)="goToView(profitSharing)">
          <div class="px-3">
            <div class="row">
              <label id="doc-no" class="chk-container">{{ profitSharing.docNo }}</label>
              <span
                [ngClass]="getColorStatus(profitSharing.profitStatus)"
                class="card-status profit-sharing-status ml-auto"
              >
                {{ 'PROFIT_SHARING.STATUS.' + profitSharing.profitStatus | translate }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-store">Store:</span>
              <span class="result-value" id="txt-store"
                >{{ profitSharing.store.code }}-{{ profitSharing.store.name }}</span
              >
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-as-of-month">As of Month:</span>
              <span class="result-value" id="txt-as-of-month">{{ profitSharing.scheduleDisplay }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-amount">Profit Sharing:</span>
              <span class="result-value" id="txt-amount">{{
                profitSharing.profitSharingAmount | numberFormatDisplay: 2
              }}</span>
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-remaining-balance">Remaining Balance:</span>
              <span class="result-value" id="txt-remaining-balance">{{
                profitSharing.remainingBalanceAmount | numberFormatDisplay: 2
              }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-updated-by">Updated By:</span>
              <span class="result-value" id="txt-updated-by">{{ profitSharing.lastModifiedByName }}</span>
            </div>

            <div class="col-md-6 d-inline-flex">
              <span class="result-property" id="lb-update-date">Last Updated:</span>
              <span class="result-value" id="txt-update-date">{{
                profitSharing.lastModifiedDate | dateDisplay
              }}</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button *ngIf="hasPermissionManage" type="button" class="btn btn-link" (click)="goToEdit(profitSharing)">
              <em class="icon-edit"></em>Edit
            </button>
            <button type="button" class="btn btn-link d-none d-md-block" (click)="print(profitSharing)">
              <em class="icon-print"></em>Print
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(profitSharing)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>

  <ng-container *ngIf="(listState$ | async)?.totalElements == 0">
    <div>
      <div class="no-result-container text-center">
        <div class="d-flex justify-content-center">
          <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal fade" bsModal #exportModal="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Export Profit Sharing</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
        <div class="form-row">
          <div class="col-12">As of Month<span class="text-danger">*</span></div>
          <div class="form-group col-md-6">
            <label>{{ 'FROM' | translate }}</label>
            <input
              id="exportDateFrom"
              formControlName="scheduleFrom"
              type="text"
              placeholder="{{ environment.monthFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsMonthConfig"
              [maxDate]="exportMaxMonth"
              (bsValueChange)="onChangeExportMonthFrom($event)"
              readonly
            />
            <label for="exportDateFrom" class="icon-calendar btn-calendar"></label>
          </div>
          <div class="form-group col-md-6">
            <label>{{ 'TO' | translate }}</label>
            <input
              id="exportDateTo"
              formControlName="scheduleTo"
              type="text"
              placeholder="{{ environment.monthFormat }}"
              autocomplete="off"
              class="form-control calendar"
              bsDatepicker
              [bsConfig]="bsMonthConfig"
              [minDate]="exportMinMonth"
              (bsValueChange)="onChangeExportMonthTo($event)"
              readonly
            />
            <label for="exportDateTo" class="icon-calendar btn-calendar"></label>
          </div>
        </div>

        <div *ngIf="responseExportError" class="alert-danger form-group mb-0 " role="alert">
          {{ responseExportError }}
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-primary"
          (click)="onExport()"
          [disabled]="exportFormInValid"
          id="btnExport"
        >
          Export
        </button>
      </div>
    </div>
  </div>
</div>
