import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ConditionsService } from '../../services/z8-conditions.service';
import { Z8ResultService } from '../../services/z8-result.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  Z8ResultActionType,
  Z8ResultHistoryRequestAction,
  Z8ResultHistoryResponseAction,
  Z8ResultRequestAction,
  Z8ResultResponseAction
} from '../actions/z8-result.actions';

@Injectable()
export class Z8ResultEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly z8ConditionsService: Z8ConditionsService,
    private readonly z8ResultService: Z8ResultService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchZ8Result$ = this.actions$.pipe(
    ofType<Z8ResultRequestAction>(Z8ResultActionType.Z8_RESULT_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ResultActionType.Z8_RESULT_REQUEST}: ` + Z8ResultEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ConditionsService.searchResultByCriteria(payload).pipe(
        map(response => {
          return new Z8ResultResponseAction(response);
        }),
        catchError(error => {
          return of(new LayoutActionLoadError(error));
        })
      );
    })
  );

  @Effect()
  z8ResultHistory$ = this.actions$.pipe(
    ofType<Z8ResultHistoryRequestAction>(Z8ResultActionType.Z8_RESULT_HISTORY_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ResultActionType.Z8_RESULT_HISTORY_REQUEST}: ` + Z8ResultEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ResultService.getHistoryLogs(payload).pipe(
        map(response => new Z8ResultHistoryResponseAction({ auditLogs: response.auditLogs })),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private static stringify(data: any) {
    return JSON.stringify(data);
  }
}
