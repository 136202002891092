import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { round, some as someLodash } from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TaskModuleUrl } from 'src/app/shared/models';
import { TasksByRoleListRequestAction } from 'src/app/shared/store/actions/dashboard.actions';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ClaimRequestStatusEnum } from '../../../shared/enum/claim-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ImageModalComponent } from '../../../shared/layouts/modals/image-modal/image-modal.component';
import { UiPaginationComponent } from '../../../shared/layouts/ui-pagination/ui-pagination.component';
import {
  ClaimRequest,
  ClaimRequestItem,
  ClaimRequestSearchCriteria,
  ClaimRequestSubmit,
  Reason
} from '../../../shared/models/claim-request.model';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { ReceiveOrderListSearchCriteria } from '../../../shared/models/receive-order.model';
import { AuthGuardService } from '../../../shared/services';
import { ClaimService } from '../../../shared/services/claim.service';
import {
  ClaimRequestApprove,
  ClaimRequestDraftRequested,
  ClaimRequestListRequest,
  ClaimRequestReject,
  ClaimRequestReleaseCN,
  ClaimRequestReset,
  ClaimRequestSubmitRequested,
  ClaimRequestViewRequested
} from '../../../shared/store/actions/claim-request.actions';
import { selectUserInfoResult } from '../../../shared/store/selectors/auth-user-info.selector';
import {
  selectClaimRequestCriteria,
  selectEditedClaimRequest
} from '../../../shared/store/selectors/claim-request.selectors';
import { selectReceiveOrderListCriteria } from '../../../shared/store/selectors/receive-order.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { AuthUserInfoState } from '../../../shared/store/state/auth-user-info.state';
import { convertBkkToUtc } from '../../../shared/utils/date-util';
import { VatDetailsModalComponent } from '../../purchase/vat-details-modal/vat-details-modal.component';

@Component({
  selector: 'app-claim-request-view',
  templateUrl: './claim-request-view.component.html',
  styleUrls: ['./claim-request-view.component.scss']
})
export class ClaimRequestViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: any;

  public dateTimeDisplay = environment.dateTimeDisplay;
  public canApproveOrRejectRequest = this.initApproveOrRejectRequest();
  public canReleaseCNRequest = this.initCanReleaseCNRequest();

  public claimForm: FormGroup;
  public claimFromStatusList = filterDropdown.claimFromList;
  public claimRequestView$: Observable<ClaimRequest>;
  public submitted = false;
  public claimRequestStatusEnum = ClaimRequestStatusEnum;
  public claimRequestCriteria: ClaimRequestSearchCriteria;
  public receiveOrderSearchCriteria: ReceiveOrderListSearchCriteria;
  public requestBy: string;

  private localStore: Observable<any>;
  private claimRequest: ClaimRequest;

  currentPage: number;
  pageSize: number;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected claimService: ClaimService,
    protected authGuardService: AuthGuardService,
    protected translate: TranslateService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    if (this.data.claimRequestStatus === ClaimRequestStatusEnum.DRAFT) {
      this.store.dispatch(new ClaimRequestDraftRequested(this.data.receiveOrderNo));
    } else {
      this.store.dispatch(new ClaimRequestViewRequested(this.data.claimRequestNo));
    }

    this.claimRequestView$ = this.store.select(selectEditedClaimRequest);
    this.initialClaimRequestData();

    this.localStore.pipe(select(selectClaimRequestCriteria), distinctUntilChanged()).subscribe(criteria => {
      this.claimRequestCriteria = criteria;
    });

    this.localStore
      .pipe(select(selectReceiveOrderListCriteria))
      .subscribe(criteriaObject => (this.receiveOrderSearchCriteria = criteriaObject));

    this.currentPage = 1;
    this.pageSize = 20;

    if (this.data.claimRequestStatus === ClaimRequestStatusEnum.DRAFT) {
      let userInfo: AuthUserInfoState = null;
      this.store
        .select(selectUserInfoResult)
        .pipe(
          map(userInfoResult => {
            userInfo = userInfoResult;
          })
        )
        .subscribe();

      this.claimRequestView$ = this.claimRequestView$.pipe(
        map(claimRequestView => {
          if (claimRequestView) {
            const requestedDate = convertBkkToUtc(new Date().toString());
            claimRequestView = {
              ...claimRequestView,
              requestedBy: userInfo.displayName,
              requestedDate,
              crBy: userInfo.userNo,
              crByName: userInfo.displayName,
              crByLocalName: userInfo.displayName
            };
            this.claimRequest = claimRequestView;
            return claimRequestView;
          }
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClaimRequestReset());
    if (this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else if (
      this.data.originPage === TaskModuleUrl.CLAIM_REQUEST ||
      this.data.originPage === TaskModuleUrl.RECEIVE_ORDER_REQUEST
    ) {
      this.store.dispatch(new ClaimRequestListRequest(this.claimRequestCriteria));
    }
  }

  initialClaimRequestData() {
    this.localStore
      .pipe(
        select(selectEditedClaimRequest),
        filter(result => result !== null)
      )
      .subscribe(claimRequest => {
        if (claimRequest) {
          this.createForm(claimRequest);
          if (this.data.claimRequestStatus === ClaimRequestStatusEnum.DRAFT) {
            this.calculateTotalAmount();
          }
        }
      });
  }

  initApproveOrRejectRequest() {
    return this.authGuardService.checkPermission(['cr_app']);
  }

  initCanReleaseCNRequest() {
    return this.authGuardService.checkPermission(['cn_m']);
  }

  createForm(claimRequest: ClaimRequest) {
    this.claimForm = this.fb.group({
      id: claimRequest.id,
      version: claimRequest.version,
      claimRequestNo: claimRequest.claimRequestNo,
      claimRequestStatus: claimRequest.claimRequestStatus,
      orderNo: claimRequest.orderNo,
      storeName: claimRequest.storeName,
      storeType: claimRequest.storeType,
      orderType: claimRequest.orderType,
      requestedBy: claimRequest.requestedBy,
      requestedDate: claimRequest.requestedDate,
      totalApprovedAmount: claimRequest.totalApprovedAmount,
      totalApprovedExcVatAmount: claimRequest.totalApprovedExcVatAmount || 0,
      totalApprovedVatAmount: claimRequest.totalApprovedVatAmount || 0,
      items: this.createItemForm(claimRequest.items)
    });
  }

  createItemForm(claimItem: ClaimRequestItem[]): FormArray {
    const claimItemForms = new FormArray([]);
    claimItem.forEach(item => {
      claimItemForms.push(
        this.fb.group({
          itemNo: item.itemNo,
          articleNo: item.articleNo,
          productName: item.productName,
          barcode: item.barcode,
          vatAmountPerUnit: item.vatAmountPerUnit ? item.vatAmountPerUnit.amount : 0,
          vatAmountPerItem: this.calculateVatAmountPerItem(item.reasons),
          reasons: this.createReasonForm(item.reasons)
        })
      );
    });
    return claimItemForms;
  }

  calculateVatAmountPerItem(reasonItem: Reason[] = null): number {
    let vatAmountPerItem = 0;
    reasonItem.forEach(item => {
      vatAmountPerItem += item.approvedVatAmount;
    });
    return Number(round(vatAmountPerItem, 2).toFixed(2));
  }

  createReasonForm(reasonItem: Reason[] = null): FormArray {
    const reasonItemForms = new FormArray([]);
    reasonItem.forEach(item => {
      const reasonItemTmp = this.fb.group({
        claimReasonNo: item.claimReasonNo,
        claimQty: item.claimQty,
        claimAmount: item.claimAmount,
        claimReason: item.claimReason,
        claimImage: item.claimImage,
        approvedQty: [
          {
            value: item.approvedQty,
            disabled:
              !this.canApproveOrRejectRequest ||
              this.data.claimRequestStatus !== this.claimRequestStatusEnum.WAITING ||
              this.data.claimRequestStatus === this.claimRequestStatusEnum.DRAFT
          },
          {
            validators: [this.qtyValidator(item.claimQty)],
            updateOn: 'blur'
          }
        ],
        approvedAmount: item.approvedAmount,
        claimFrom: [
          {
            value: item.claimFrom,
            disabled:
              !this.canApproveOrRejectRequest ||
              this.data.claimRequestStatus !== this.claimRequestStatusEnum.WAITING ||
              this.data.claimRequestStatus === this.claimRequestStatusEnum.DRAFT
          }
        ],
        unit: item.unit,
        approvedExcVatAmount: item.approvedExcVatAmount || 0,
        approvedVatAmount: item.approvedVatAmount || 0
      });

      reasonItemTmp.controls['approvedQty'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(value => {
        if (value) {
          reasonItemTmp.controls['claimFrom'].setValidators([Validators.required]);
        } else {
          reasonItemTmp.controls['claimFrom'].clearValidators();
        }
        reasonItemTmp.controls['claimFrom'].updateValueAndValidity();
      });
      reasonItemForms.push(reasonItemTmp);
    });
    return reasonItemForms;
  }

  calculateAmount(value: any, indexOfItem: number, indexOfReason: number) {
    const claimQty = (this.getItemForm.controls[indexOfItem].get('reasons') as FormArray).controls[indexOfReason].get(
      'claimQty'
    ).value;
    const claimAmount = (this.getItemForm.controls[indexOfItem].get('reasons') as FormArray).controls[
      indexOfReason
    ].get('claimAmount').value;
    const getApprovedAmount = (this.getItemForm.controls[indexOfItem].get('reasons') as FormArray).controls[
      indexOfReason
    ];
    const vatAmountPerUnit = this.getItemForm.controls[indexOfItem].get('vatAmountPerUnit').value;

    const approvedExcVatAmount = (claimAmount / claimQty - vatAmountPerUnit) * value;
    const approvedAmount = (claimAmount / claimQty) * value;
    getApprovedAmount.patchValue({
      approvedExcVatAmount: Number(round(approvedExcVatAmount, 2).toFixed(2)),
      approvedAmount: Number(round(approvedAmount, 2).toFixed(2)),
      approvedVatAmount: Number(round(value * vatAmountPerUnit, 2).toFixed(2))
    });
    this.calculateTotalAmount();
  }

  calculateTotalAmount() {
    let total = 0;
    let totalAmount = 0;
    let claimVatAmount = 0;
    this.getItemForm.controls.forEach((item: FormGroup) => {
      const items = item.controls.reasons as FormArray;
      let vatAmountPerItem = 0;
      items.controls.forEach((reason: FormGroup) => {
        total += reason.get('approvedExcVatAmount').value;
        totalAmount += reason.get('approvedAmount').value;
        claimVatAmount += reason.get('approvedVatAmount').value;
        vatAmountPerItem += reason.get('approvedVatAmount').value;
      });
      item.patchValue({ vatAmountPerItem: Number(round(vatAmountPerItem, 2).toFixed(2)) });
    });

    this.form.patchValue({
      totalApprovedExcVatAmount: total,
      totalApprovedAmount: Number(totalAmount.toFixed(2)),
      totalApprovedVatAmount: claimVatAmount
    });
  }

  qtyValidator(claimQty): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value > claimQty ? { over: true } : null;
    };
  }

  showApproveAllItem() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'OK',
        cancelText: 'Cancel',
        message: 'Are you sure you want to Approve all items?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.approveAllItem();
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  approveAllItem() {
    const items = this.getItemForm.getRawValue();
    items.forEach((data, index) => {
      const vatAmountPerUnit = data.vatAmountPerUnit;
      data.reasons.forEach(dataReason => {
        dataReason.approvedQty = dataReason.claimQty;
        dataReason.approvedAmount = dataReason.claimAmount;
        dataReason.approvedVatAmount = Number(round(dataReason.claimQty * vatAmountPerUnit, 2).toFixed(2));
        dataReason.approvedExcVatAmount = dataReason.claimAmount - dataReason.approvedVatAmount;
      });
      this.getItemForm.at(index).setValue(data);
    });
    this.calculateTotalAmount();
  }

  applyAllClaimForm() {
    const selectedClaimForm = (((this.getItemForm.at(0) as FormGroup).controls.reasons as FormArray).at(
      0
    ) as FormGroup).controls.claimFrom.value;
    if (!selectedClaimForm) {
      return;
    }

    const items = this.getItemForm.getRawValue();
    items.forEach((data, index) => {
      data.reasons.forEach(dataReason => {
        dataReason.claimFrom = selectedClaimForm;
      });
      this.getItemForm.at(index).setValue(data);
    });
  }

  showConfirmReject() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true
      }
    });
    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new ClaimRequestReject({
              version: this.form.get('version').value,
              claimRequestNo: this.data.claimRequestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  validateSubmit() {
    this.submitted = true;
    const validateItem = this.getItemForm.getRawValue();

    let everyQtyNull = true;
    validateItem.forEach(data => {
      if (someLodash(data.reasons, reasonItem => reasonItem.approvedQty)) {
        everyQtyNull = false;
      }
    });

    if (everyQtyNull) {
      this.showAlertModal();
      return;
    }

    if (this.getItemForm.invalid) {
      const invalidIndex = this.getItemForm.controls.findIndex(item => item.invalid);
      this.paging.navigateToErrorIndex(invalidIndex);
      return;
    }

    if (this.form.invalid) {
      return;
    }

    this.submit();
  }

  submit() {
    const form: ClaimRequest = this.form.getRawValue();
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        label: 'Comment',
        isRequiredConfirmMessage: false
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new ClaimRequestApprove({
              version: form.version,
              claimRequestNo: form.claimRequestNo,
              comment: confirmModalRef.content.confirmMessage,
              totalApprovedAmount: form.totalApprovedAmount,
              items: form.items.map(item => {
                return {
                  ...item,
                  reasons: item.reasons.map(itemReason => {
                    return {
                      ...itemReason,
                      approvedQty: itemReason.approvedQty === null ? 0 : itemReason.approvedQty,
                      claimFrom: itemReason.approvedQty === null ? null : itemReason.claimFrom
                    };
                  })
                };
              })
            })
          );
        }
      });
  }

  releaseCN() {
    const claimFormValue = this.claimForm.getRawValue();

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(new ClaimRequestReleaseCN(claimFormValue.claimRequestNo));
        }
      });
  }

  showImage(value: string): void {
    if (value) {
      this.modalService.show(ImageModalComponent, {
        initialState: {
          refId: value,
          service: this.claimService
        },
        class: 'modal-image'
      });
    }
  }

  showAlertModal(): void {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message: 'Please select at least 1 item before approve.'
      }
    });
  }

  canApproveReject() {
    return [ClaimRequestStatusEnum.WAITING].includes(this.data.claimRequestStatus) && this.canApproveOrRejectRequest;
  }

  canReleaseCN() {
    return [ClaimRequestStatusEnum.WAITING_CN].includes(this.data.claimRequestStatus) && this.canReleaseCNRequest;
  }

  canCreateClaim() {
    return [ClaimRequestStatusEnum.DRAFT].includes(this.data.claimRequestStatus);
  }

  getColorStatus(status: ClaimRequestStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  get form(): FormGroup {
    return this.claimForm;
  }

  get getItemForm(): FormArray {
    return this.form.get('items') as FormArray;
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  onExit() {
    if (this.data.claimRequestStatus === ClaimRequestStatusEnum.DRAFT) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  showVatDetails() {
    const vatDetails = [];
    this.getItemForm.controls.forEach((itemForm, i) => {
      const rawValue = (itemForm as FormGroup).getRawValue();
      vatDetails.push({
        itemNo: i + 1,
        productName: rawValue.productName,
        vat: rawValue.vatAmountPerUnit ? 7 : 0,
        vatAmount: rawValue.vatAmountPerItem
        // isInvalid: itemForm.get('approvedQty').invalid || itemForm.get('approvedQty').value === null
      });
    });

    const initialState = {
      title: 'VAT',
      vatDetails
    };
    this.modalService.show(VatDetailsModalComponent, {
      initialState
    });
  }

  onCreateClaimSubmit() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareClaimRequestData();
          this.store.dispatch(new ClaimRequestSubmitRequested({ ...reqData }));
        }
      });
  }

  prepareClaimRequestData() {
    const claimRequestSubmit = {
      ...this.claimRequest,
      crDate: convertBkkToUtc(new Date().toString()),
      grNo: this.claimRequest.roNo,
      totalApprovedAmount: {
        amount: this.claimRequest.totalApprovedAmount,
        currency: 'THB'
      },
      totalApprovedExcVatAmount: {
        amount: this.claimRequest.totalApprovedExcVatAmount,
        currency: 'THB'
      },
      totalApprovedVatAmount: {
        amount: this.claimRequest.totalApprovedExcVatAmount || 0,
        currency: 'THB'
      },
      totalClaimAmount: {
        amount: this.claimRequest.totalClaimAmount || 0,
        currency: 'THB'
      },
      amount: {
        amount: this.claimRequest.amount || 0,
        currency: 'THB'
      },
      items: this.prepareClaimRequestItemSubmit(this.claimRequest.items)
    } as ClaimRequestSubmit;
    return claimRequestSubmit;
  }

  prepareClaimRequestItemSubmit(items: ClaimRequestItem[]) {
    const claimRequestItemSubmits = [];
    items.forEach(item => {
      const claimRequestItemSubmit = {
        itemNo: item.itemNo,
        barcode: item.barcode,
        articleNo: item.articleNo,
        productName: item.productName,
        vatAmountPerUnit: item.vatAmountPerUnit,
        receiveQty: item.receiveQty,
        reasons: this.prepareReasonSubmit(item.reasons),
        productType: item.productType,
        receiveAmount: item.receiveAmount,
        unit: item.unit,
        localUnit: item.localUnit,
        unitPrice: item.unitPrice,
        unitFactor: item.unitFactor
      };
      claimRequestItemSubmits.push(claimRequestItemSubmit);
    });
    return claimRequestItemSubmits;
  }

  prepareReasonSubmit(reasons: Reason[]) {
    const reasonsSubmits = [];
    reasons.forEach(reason => {
      const reasonSubmit = {
        claimReasonNo: reason.claimReasonNo,
        claimQty: reason.claimQty,
        claimAmount: {
          amount: reason.claimAmount,
          currency: 'THB'
        },
        reason: reason.claimReason,
        claimImage: null,
        approvedQty: reason.approvedQty,
        approvedAmount: {
          amount: reason.approvedAmount,
          currency: 'THB'
        },
        claimFrom: null,
        unit: reason.unit,
        approvedExcVatAmount: {
          amount: reason.approvedExcVatAmount,
          currency: 'THB'
        },
        approvedVatAmount: {
          amount: reason.approvedVatAmount,
          currency: 'THB'
        }
      };
      reasonsSubmits.push(reasonSubmit);
    });
    return reasonsSubmits;
  }
}
