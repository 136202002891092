import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { FileResponse } from '../models/fileResponse';
import { Progress } from '../models/progress.model';
import { Z8ResultImportCriteria } from '../models/z8-result.model';
import { BaseService } from './base.service';

type UploadResultType = Progress | FileResponse | null | any;

@Injectable()
export class Z8ResultService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {
    super();
    this.envService = this.env.services.z8Result;
    this.headers = new HttpHeaders(this.envService.headers);
  }
  public exportZ8Result(refId: string): Observable<any> {
    const url = this.getFullUrl(this.envService.export);

    return this.http.post<any>(
      url,
      { refId },
      {
        headers: this.loaderHeaders(),
        observe: 'body'
      }
    );
  }

  public importZ8Result(file: File, criteria: Z8ResultImportCriteria): Observable<UploadResultType> {
    const url = this.getFullUrl(this.envService.import);
    const params = this.getParams(criteria);
    const formData = new FormData();

    formData.append('file', file, file.name);

    this.headers.append('Content-Type', 'multipart/form-data');

    return this.http.post<any>(url, formData, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params
    });
  }

  public getHistoryLogs(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, { id });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }
}
