import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { MovingAverageReportExportCriteria } from '../../../shared/models/report.model';
import { MovingAverageReportService } from '../../../shared/services/moving-average-report.service';
import { dateTimeToDateOnlyString } from '../../../shared/utils/date-util';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-moving-average-modal',
  templateUrl: './moving-average-modal.component.html',
  styleUrls: ['./moving-average-modal.component.scss']
})
export class MovingAverageModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('movingAverageReportModal', { static: false }) movingAverageReportModal: ModalDirective;

  public exportForm: FormGroup;
  public submitted: boolean;
  public exportFormInValid: boolean;
  public responseExportError: string;

  public maxDate: Date;
  public minDate: Date;

  constructor(
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly movingAverageReportService: MovingAverageReportService
  ) {
    super();
  }

  ngOnInit() {
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      createdDateFrom: [null],
      createdDateTo: [null]
    });
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.movingAverageReportModal.show();
  }

  closeExportModal() {
    this.exportForm.reset();
    this.movingAverageReportModal.hide();
  }

  onExport() {
    this.submitted = true;

    if (this.exportFormInValid) {
      return;
    }

    this.responseExportError = null;
    const rawData = this.exportForm.getRawValue();

    const movingAverageReportExportCriteria = new MovingAverageReportExportCriteria({
      createdDateFrom: dateTimeToDateOnlyString(rawData.createdDateFrom),
      createdDateTo: dateTimeToDateOnlyString(rawData.createdDateTo)
    });

    this.movingAverageReportService.reportExport(movingAverageReportExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportMovingAverage.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  onChangeExportCreatedDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportCreatedDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportDeletedBill.timeFormat);
  }
}
