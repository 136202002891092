<div class="full-popup-head ">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-user"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close float-right">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div>
  <div class="admin-form" [formGroup]="userForm">
    <div class="info-header-container" *ngIf="!isCreateMode || status">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="status | lowercase" class="user-status">
            {{ 'USER.STATUS.' + status | translate | titlecase }}</span
          >
        </div>
      </div>
    </div>

    <div id="userProfileDetail">
      <div class="row section-header">
        <span>User Profile</span>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="firstName">First Name (EN)<span class="text-danger">*</span></label>
              <input
                id="firstName"
                name="firstName"
                class="form-control"
                formControlName="firstName"
                maxlength="100"
                placeholder="First Name (EN)"
                tabindex="1"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.firstName.errors
                }"
                appPascalCase
                appAlphabetOnly
              />
              <div *ngIf="submitted && userForm.controls.firstName.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.firstName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="lastName">Last Name (EN)<span class="text-danger">*</span></label>
              <input
                id="lastName"
                name="lastName"
                class="form-control"
                formControlName="lastName"
                maxlength="100"
                placeholder="Last Name (EN)"
                tabindex="2"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.lastName.errors
                }"
                appPascalCase
                appAlphabetOnly
              />
              <div *ngIf="submitted && userForm.controls.lastName.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.lastName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="birthdate">Birthdate</label>
              <input
                id="birthdate"
                name="birthdate"
                type="text"
                placeholder="{{ dateFormat }}"
                class="form-control calendar"
                autocomplete="off"
                tabindex="5"
                bsDatepicker
                [bsConfig]="bsConfig"
                formControlName="birthDate"
                readonly
              />
              <label for="birthdate" class="icon-calendar btn-calendar"></label>
            </div>
            <div class="col-md-6 mb-3">
              <label for="gender">Gender</label>
              <ng-select
                id="gender"
                placeholder="Please select..."
                [items]="listOfValue['gender']"
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="value"
                tabindex="6"
                formControlName="gender"
              >
              </ng-select>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="localFirstName">First Name (Local)<span class="text-danger">*</span></label>
              <input
                id="localFirstName"
                name="localFirstName"
                class="form-control"
                formControlName="localFirstName"
                maxlength="100"
                placeholder="First Name (Local)"
                tabindex="3"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.localFirstName.errors
                }"
              />

              <div *ngIf="submitted && userForm.controls.localFirstName.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.localFirstName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="localLastName">Last Name (Local)<span class="text-danger">*</span></label>
              <input
                id="localLastName"
                name="localLastName"
                class="form-control"
                formControlName="localLastName"
                maxlength="100"
                placeholder="Last Name (Local)"
                tabindex="4"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.localLastName.errors
                }"
              />

              <div *ngIf="submitted && userForm.controls.localLastName.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.localLastName.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="mobileNo">Mobile Phone<span class="text-danger">*</span></label>
              <ngx-intl-tel-input
                [cssClass]="'form-control d-block ' + (isPhoneError(userForm.controls.mobileNo) ? 'is-invalid' : '')"
                [preferredCountries]="['th']"
                [enableAutoCountrySelect]="true"
                [maxlength]="'25'"
                id="mobileNo"
                formControlName="mobileNo"
                tabindex="7"
              >
              </ngx-intl-tel-input>
              <div *ngIf="submitted && userForm.controls.mobileNo.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.mobileNo.errors?.required; else validatePhoneNumber">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <ng-template #validatePhoneNumber>
                  <div *ngIf="userForm.controls.mobileNo.errors?.validatePhoneNumber">
                    {{ 'ERRORS.INVALID_PHONE' | translate }}
                  </div>
                </ng-template>

                <div *ngIf="userForm.controls.mobileNo.errors?.isDuplicatedMobile">
                  {{ 'ERRORS.DUPLICATED_FIELD' | translate: { value: 'Mobile Phone' } }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                class="form-control"
                maxlength="100"
                formControlName="email"
                placeholder="Email"
                tabindex="8"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.email.errors
                }"
              />
              <div *ngIf="submitted && userForm.controls.email.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.email.errors?.email">
                  {{ 'ERRORS.INVALID_EMAIL' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="merchant">Merchant<span class="text-danger">*</span></label>
              <ng-select
                id="merchant"
                [items]="merchantList | async"
                placeholder="Please select..."
                notFoundText="No Data"
                [virtualScroll]="true"
                formControlName="merchant"
                bindLabel="merchantNameDisplay"
                dropdownPosition="auto"
                tabindex="9"
                [loading]="merchantSearchLoading"
                [typeahead]="merchantSearchInput$"
                [ngClass]="{ 'is-invalid': submitted && userForm.controls.merchant.errors }"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <strong>Merchant Name:</strong>
                    <span [ngOptionHighlight]="search" class="ml-1">
                      {{ item.merchantName }}
                    </span>
                  </div>
                  <small>
                    <strong>Tax ID/ID Card:</strong
                    ><span [ngOptionHighlight]="search" class="ml-1">{{ item.taxId }}</span
                    >{{ ' | ' }} <strong>Store Type:</strong
                    ><span [ngOptionHighlight]="search" class="ml-1">{{
                      'STORE_TYPE.' + item.merchantType | translate
                    }}</span>
                  </small>
                </ng-template>
              </ng-select>
              <div *ngIf="submitted && userForm.controls.merchant.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.merchant.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="employeeCode">TD Employee ID</label>
              <input
                id="employeeCode"
                name="employeeCode"
                class="form-control"
                formControlName="employeeCode"
                maxlength="100"
                tabindex="10"
                placeholder="TD Employee ID"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.employeeCode.errors
                }"
              />

              <div *ngIf="submitted && userForm.controls.employeeCode.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.employeeCode.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <label for="department">Department</label>
              <ng-select
                id="department"
                placeholder="Please select..."
                [items]="departmentList"
                [searchable]="false"
                [clearable]="false"
                bindLabel="nameTh"
                tabindex="11"
                formControlName="department"
                [ngClass]="{
                  'is-invalid': submitted && userForm.controls.department.errors
                }"
              >
              </ng-select>

              <div *ngIf="submitted && userForm.controls.department.errors" class="invalid-feedback">
                <div *ngIf="userForm.controls.department.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isViewMode && hasManagePermission" class="col-md-6">
          <button
            [disabled]="userView && !userView.active"
            type="button"
            (click)="handleResetPassword()"
            class="btn btn-standard mr-2"
          >
            <em class="icon-reset-pass"></em> <span>Reset Password</span>
          </button>
          <button
            [disabled]="userView && !userView.active"
            type="button"
            class="btn btn-standard mr-2"
            (click)="handleResetPinCode()"
          >
            <em class="icon-reset-pin"></em><span>Reset PIN CODE</span>
          </button>
          <button type="button" class="btn btn-standard" (click)="handleChangeStatusUser()">
            <span *ngIf="userView && userView.active; else isDeactivate">
              <em class="icon-deactivate"></em> <span>Deactivate</span>
            </span>
            <ng-template #isDeactivate>
              <span> <em class="icon-activate"></em> <span>Activate</span> </span>
            </ng-template>
          </button>
        </div>
      </div>

      <!--userProfileDetail-->

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Roles<span class="text-danger">*</span></span>
      </div>

      <div class="row">
        <div class="col-md-6" formArrayName="roles">
          <div *ngFor="let roleControl of rolesFormArray.controls; let i = index" class="py-1" [formGroupName]="i">
            <ng-select
              id="role"
              placeholder="Please select..."
              [items]="roleList"
              [searchable]="true"
              [clearable]="true"
              bindLabel="name"
              formControlName="role"
              (change)="onChangeRole()"
              [ngClass]="{
                'is-invalid': submitted && roleControl.controls.role.errors
              }"
            >
            </ng-select>
            <div *ngIf="submitted && roleControl.controls.role.errors" class="invalid-feedback">
              <div *ngIf="roleControl.controls.role.errors.required">
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
              <div *ngIf="roleControl.controls.role.errors.duplicated">
                {{ 'ERRORS.DUPLICATED_ROLE' | translate }}
              </div>
            </div>
          </div>

          <a
            class="btn-text-link d-inline-block ml-1 float-right"
            *ngIf="!isViewMode && !isStoreOwner"
            (click)="addRole()"
            >+Add more
          </a>
        </div>
      </div>

      <hr class="section-divider" />

      <div class="row section-header">
        <span>Store Authority</span>
      </div>

      <div class="row">
        <div class="col-md-12 mb-3 pb-5">
          <ng-container *ngIf="hasStoreAuthority && listStore$ | async as list; else storeGroupNotFound">
            <div class="row alert-dark my-2 box-store-merchant">
              <label class="m-2 pl-2 bd-highlight ">
                <input
                  type="checkbox"
                  [disabled]="isViewMode"
                  [checked]="selectedAllStoreInMerchant"
                  name="allStoreInMerchant"
                  (change)="onChangeAll($event, list)"
                />
                <span>All store in this merchant</span>
              </label>
            </div>
            <div class="row">
              <div
                class="col-md-6 mt-2 px-2"
                *ngFor="let storeGroup of list; index as i"
                [style.order]="storeGroup.defaultGroup ? -1 : 1"
              >
                <label class="p-1 pl-2 bd-highlight ">
                  <input
                    type="checkbox"
                    [disabled]="isViewMode || storeGroup.disabled || selectedAllStoreInMerchant"
                    [checked]="storeGroup.selected"
                    (change)="onCheckboxChangeGroup($event, storeGroup)"
                    name="{{ storeGroup.no }}"
                  />
                  <span class="font-weight-bold">
                    {{ defaultGroupName(storeGroup.name, storeGroup.defaultGroup) }}</span
                  >
                </label>
                <hr class="my-1" />
                <div class="row pl-3">
                  <div class="col-12" *ngFor="let store of storeGroup.stores">
                    <label class="p-2  bd-highlight ">
                      <input
                        type="checkbox"
                        [disabled]="isViewMode || store.disabled || storeGroup.selected || selectedAllStoreInMerchant"
                        [checked]="store.selected"
                        (change)="onCheckboxChange($event, store)"
                        name="{{ store.code }}"
                      />
                      <span>{{ store.code }}-{{ store.name }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #storeGroupNotFound>
            <div class="no-result-container text-center">
              <div class="d-flex justify-content-center">
                <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <!--footer-->
      <div class="fixed-row-bottom form-row">
        <div class="float-left"></div>

        <div class="float-right">
          <button
            *ngIf="isViewMode && hasManagePermission"
            type="button"
            id="btn-request-edit"
            class="btn btn-primary"
            (click)="toggleToEditMode()"
          >
            Edit
          </button>
          <button
            *ngIf="!isViewMode && hasManagePermission"
            type="button"
            id="btn-cancel"
            class="btn btn-standard mr-2"
            (click)="onExit()"
          >
            Cancel
          </button>
          <button
            *ngIf="!isViewMode && hasManagePermission"
            type="button"
            id="btn-submit"
            (click)="onSubmit()"
            class="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <!--End footer-->
    </div>
  </div>
</div>
