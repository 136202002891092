import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { LegalDocumentComponent } from '../../../shared/components/td-store/legal-document/legal-document.component';
import { MerchantProfileComponent } from '../../../shared/components/td-store/merchant-profile/merchant-profile.component';
import { OwnerProfileComponent } from '../../../shared/components/td-store/owner-profile/owner-profile.component';
import { TDStoreValidatorTypeEnum } from '../../../shared/enum/merchant-validator-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestSectionEnum } from '../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../shared/enum/td-store-page.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  ErrorResponse,
  LegalDocument,
  MerchantInfo,
  MerchantRequestTemplate,
  MerchantViewResponse,
  Wallet
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { MerchantRequestService } from '../../../shared/services/merchant-request.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../../../shared/store/actions/layout.action';
import {
  MerchantByIdRequestAction,
  MerchantCreateResetAction,
  MerchantCreateSubmitRequestAction,
  ResetMerchantSelected
} from '../../../shared/store/actions/merchant.actions';
import { UserPasswordResetAction } from '../../../shared/store/actions/user-info.action';
import { MerchantCreateResponseState } from '../../../shared/store/reducers/merchant-create.reducers';
import { selectMerchant } from '../../../shared/store/selectors/merchant-create.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { getFileId } from '../../../shared/utils/get-fileId-util';
import { getSelectByPage } from '../../../shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../shared/utils/td-store-workflow-util';

@Component({
  selector: 'app-view-merchant',
  templateUrl: './view-merchant.component.html',
  styleUrls: ['./view-merchant.component.scss']
})
export class ViewMerchantComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('merchantProfile', { static: false }) merchantProfile: MerchantProfileComponent;
  @ViewChild('ownerProfile', { static: false }) ownerProfile: OwnerProfileComponent;
  @ViewChild('legalDocument', { static: false }) legalDocument: LegalDocumentComponent;
  @Input() data: { title: string; mode: RequestPageModesEnum; merchant?: string };
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public merchantView$: Observable<MerchantViewResponse>;
  public merchantForm: FormGroup;
  public ownerUsername: string;
  public status: RequestStatusEnum;
  public submitted: boolean;

  private localStore: Observable<any>;
  private readonly type: RequestTypeEnum = RequestTypeEnum.EDIT;
  private readonly step: RequestStepEnum = RequestStepEnum.EDIT_PROFILE;
  private readonly page: TDStorePage = TDStorePage.MERCHANT_EDIT;

  constructor(
    protected authGuardService: AuthGuardService,
    protected fb: FormBuilder,
    protected merchantService: MerchantService,
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected tdStoreWorkflowUtil: TDStoreWorkflowUtil,
    protected merchantRequestService: MerchantRequestService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, true);
  }

  ngOnDestroy() {
    this.store.dispatch(new ResetMerchantSelected());
    this.store.dispatch(new MerchantCreateResetAction());
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new MerchantByIdRequestAction({ merchant: this.data.merchant }));

    this.merchantView$ = this.localStore.pipe(select(getSelectByPage(TDStorePage.MERCHANT_EDIT)));
    this.merchantView$.pipe(filter(data => Boolean(data))).subscribe(data => {
      this.ownerUsername = data.merchantInfo.ownerProfile.userName;
    });

    this.initControl();

    if (this.data.mode === RequestPageModesEnum.REQUEST_EDIT) {
      this.editMerchant();
    }
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  get merchantPage() {
    return TDStorePage.MERCHANT_EDIT;
  }

  get merchantRequestPageMode() {
    return RequestPageModesEnum;
  }

  hasUserPermission() {
    return (
      this.authGuardService.checkPermission(['merchant_key']) && this.data.mode === RequestPageModesEnum.REQUEST_VIEW
    );
  }

  hasEditPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasEditPermission(this.type, this.page, this.step, RequestStatusEnum.DRAFT);
  }

  hasSubmitPermission(): boolean {
    return this.tdStoreWorkflowUtil.hasSubmitPermission(this.type, this.page, this.step, this.status);
  }

  hasAtLeastOnePermission() {
    return this.hasEditPermission() || this.hasSubmitPermission();
  }

  initControl() {
    this.merchantForm = this.fb.group({});
  }

  onCancel() {
    if (this.merchantForm.touched) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.merchantForm.invalid) {
      return;
    }

    this.handleConfirm();
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.prepareMerchantRequestData();
          this.store.dispatch(new MerchantCreateSubmitRequestAction({ merchantCreatRequest: reqData }));
        }
      });
  }

  prepareMerchantRequestData(): MerchantRequestTemplate {
    const formData = this.merchantForm.getRawValue();
    let mobilePhone = null;
    let countryCode = null;
    const merchantRequestTemplate = new MerchantRequestTemplate();

    this.merchantView$.subscribe(value => {
      if (value) {
        merchantRequestTemplate.step = RequestStepEnum.EDIT_PROFILE;
        merchantRequestTemplate.status = this.status;
        merchantRequestTemplate.type = this.type;
        mobilePhone = value.merchantInfo.ownerProfile.mobilePhone;
        countryCode = value.merchantInfo.ownerProfile.countryCode;
      }
    });

    merchantRequestTemplate.merchantInfo = {
      merchantNo: this.data.merchant,
      merchantProfile: formData.merchantProfile,
      ownerProfile: {
        ...formData.ownerProfile,
        countryCode:
          formData.ownerProfile.mobilePhone && formData.ownerProfile.mobilePhone.internationalNumber
            ? formData.ownerProfile.mobilePhone.internationalNumber.split(' ')[0]
            : countryCode,
        mobilePhone:
          formData.ownerProfile.mobilePhone && formData.ownerProfile.mobilePhone.nationalNumber
            ? formData.ownerProfile.mobilePhone.nationalNumber.replace(/ /g, '')
            : mobilePhone,
        birthDate:
          formData.ownerProfile.birthDate &&
          moment(formData.ownerProfile.birthDate, env.dateFormat).format(env.dateFormat),
        attitudeTest: getFileId(formData.ownerProfile.attitudeTest),
        ownerPicture: getFileId(formData.ownerProfile.ownerPicture)
      }
    } as MerchantInfo;

    merchantRequestTemplate.legalDocument = {
      idCardFront: getFileId(formData.legalDocument.idCardFront),
      idCardBack: getFileId(formData.legalDocument.idCardBack),
      houseRegistration: getFileId(formData.legalDocument.houseRegistration),
      letterOfIntent: getFileId(formData.legalDocument.letterOfIntent),
      contractSignOff: getFileId(formData.legalDocument.contractSignOff),
      creditBureauSignOff: getFileId(formData.legalDocument.creditBureauSignOff),
      criminalCheck: getFileId(formData.legalDocument.criminalCheck)
    } as LegalDocument;

    merchantRequestTemplate.wallet = {
      bankName: formData.legalDocument.bankName,
      accountNo: formData.legalDocument.accountNo,
      accountName: formData.legalDocument.accountName,
      bookBank: getFileId(formData.legalDocument.bookBank),
      dipChip: getFileId(formData.legalDocument.dipChip),
      jcNumber: formData.legalDocument.jcNumber
    } as Wallet;

    return merchantRequestTemplate;
  }

  onGenerateUser() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Generate Username & Password"?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.merchantService.generateUser({ merchant: this.data.merchant }).subscribe(
            () => {
              this.store.dispatch(
                new LayoutActionSaveSuccess({
                  isSuccess: true,
                  title: 'Success',
                  message: 'Username & Password has been created and sent to the owner.'
                })
              );
            },
            error => {
              this.alertErrorModal(error.error);
            }
          );
        }
      });
  }

  onResetPassword() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: "Are you sure you want to reset owner's " + '"Password"?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new UserPasswordResetAction({ userName: this.ownerUsername, merchant: this.data.merchant })
          );
        }
      });
  }

  onTriggerEdit() {
    this.merchantRequestService
      .getMerchantValidate(TDStoreValidatorTypeEnum.REQUESTED, this.data.merchant)
      .pipe(untilComponentDestroyed(this))
      .subscribe(
        res => {
          if (res.body.allowToEdit) {
            this.data.mode = RequestPageModesEnum.REQUEST_EDIT;

            if (
              this.tdStoreWorkflowUtil.canEditSection(this.type, this.page, this.step, RequestSectionEnum.PROFILE)
            ) {
              this.data.title = 'Edit Merchant';
              this.editMerchant();

              this.merchantProfile.toggleEditMerchantProfile();
              this.ownerProfile.toggleEditOwnerProfile();
              this.legalDocument.toggleEditLegalDocument();
            }
          } else {
            const initialState = {
              title: 'Alert',
              message: 'Another request is awaiting approval.'
            };

            this.modalService.show(AlertModalComponent, {
              initialState
            });
          }
        },
        error => {
          this.store.dispatch(new LayoutActionLoadError(error));
        }
      );
  }

  editMerchant() {
    this.status = RequestStatusEnum.DRAFT;

    this.localStore
      .pipe(
        select(selectMerchant),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: MerchantCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          this.alertSuccessModal('The request has been sent to approver.');
        } else {
          this.alertErrorModal(value.result.errorResponse);
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }
}
