import { UserActionTypes, UserInfoActions } from '../actions/user-info.action';
import { TenantInfoState } from '../state/tenant-info.state';

export function tenantInfoReducers(state = null, action: UserInfoActions): TenantInfoState {
  if (action.type === UserActionTypes.USER_TENANT) {
    return {
      ...state,
      merchant: action.payload.merchant,
      sites: action.payload.sites,
      groups: action.payload.groups,
      stores: action.payload.stores
    };
  } else {
    return state;
  }
}
