import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { SettingService } from '../../services/setting.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { PriceSettingSuccess, SettingActionTypes } from '../actions/setting.actions';
import { selectPriceSetting } from '../selectors/setting.selectors';
export class SettingEffects {
    constructor(actions$, settingService, logger, store) {
        this.actions$ = actions$;
        this.settingService = settingService;
        this.logger = logger;
        this.store = store;
        this.getPriceSetting$ = this.actions$.pipe(ofType(SettingActionTypes.PRICE_SETTING_REQUESTED), tap(action => this.logger.debug(`@Effect ${SettingActionTypes.PRICE_SETTING_REQUESTED}: ` + JSON.stringify(action))), withLatestFrom(this.store.select(selectPriceSetting)), 
        // Check data store is empty  before calling api
        filter(([, selector]) => selector === null), switchMap(() => this.settingService.getPriceSetting().pipe(map(response => new PriceSettingSuccess({ priceSetting: response })), catchError(error => of(new LayoutActionLoadError(error))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], SettingEffects.prototype, "getPriceSetting$", void 0);
