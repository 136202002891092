import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { UiPaginationComponent } from '../../../../shared/layouts/ui-pagination/ui-pagination.component';
import {
  StoreAssortmentRequestItem,
  StoreAssortmentRequestStoreItem,
  StoreAssortmentRequestViewResponse
} from '../../../../shared/models/store-assortment-request.model';
import { selectStoreAssortmentRequestById } from '../../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-request-table',
  templateUrl: './store-assortment-request-table.component.html',
  styleUrls: ['./store-assortment-request-table.component.scss']
})
export class StoreAssortmentRequestTableComponent implements OnInit, OnDestroy {
  @ViewChild('paging', { static: false }) paging: UiPaginationComponent;
  @Input() mode: RequestPageModesEnum;
  @Output() alert: EventEmitter<boolean> = new EventEmitter<boolean>();
  private localStore: Observable<any>;

  public storeAssortmentRequestView$: Observable<StoreAssortmentRequestViewResponse>;
  public requestPageModes = RequestPageModesEnum;

  public currentPage = 1;
  public pageSize = 20;
  public data: Array<StoreAssortmentRequestStoreItem>;

  constructor(private readonly store: Store<AppStates>, private readonly cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.storeAssortmentRequestView$ = this.localStore.pipe(
      select(selectStoreAssortmentRequestById),
      filter(data => Boolean(data))
    );

    this.storeAssortmentRequestView$.subscribe(data => {
      this.data = [...this.mappingData(data.items)];
      this.cdRef.detectChanges();
    });
  }

  ngOnDestroy() {}

  getNoteColor(mode: RequestPageModesEnum): string {
    return mode.toLocaleLowerCase();
  }

  mappingData(data: StoreAssortmentRequestItem[]): Array<StoreAssortmentRequestStoreItem> {
    const array: Array<StoreAssortmentRequestStoreItem> = [];
    for (const item of data) {
      for (const storeItem of item.storeItems) {
        const mergeItem = {
          ...StoreAssortmentRequestItem.mappingDataToStoreAssortmentRequestItem(item),
          ...storeItem
        };

        array.push(mergeItem);
      }
    }
    return array;
  }

  getStoreData(storeNo, data: Array<StoreAssortmentRequestStoreItem>) {
    return data.filter(v => v.storeNo === storeNo);
  }

  deleteItem(index: number) {
    const i = this.pageSize * (this.currentPage - 1) + index;
    this.data.splice(i, 1);

    if (!this.data.length) {
      this.alert.emit(true);
    }
    this.cdRef.detectChanges();
  }

  getItemNo(item: StoreAssortmentRequestStoreItem) {
    return this.data.filter(v => v.storeNo === item.storeNo).findIndex(v => v.barcode === item.barcode) + 1;
  }
}
