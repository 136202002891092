<ng-container *ngIf="(listWarehouseState$ | async).totalElements == 0">
  <div class="no-result-container text-center">
    <div class="d-flex justify-content-center">
      <img width="240" height="240" src="assets/images/not-found.svg" alt="not found" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="(listWarehouseState$ | async).totalElements > 0">
  <div class="mt-3 table-responsive">
    <table id="transactions" class="table table-striped table-bordered table-hover border-none">
      <thead>
        <tr>
          <th class="text-center w-10">Warehouse</th>
          <th class="text-center w-20">Warehouse available</th>
          <th class="text-center  w-20">Hold</th>
          <th class="text-center  w-10">Reserved</th>
          <th class="text-center  w-10">Total</th>
          <th class="text-center  w-10">On Delivery</th>
          <th class="text-center  w-10">Last Updated</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let result of resultWarehouseList$ | async; index as i">
          <td>{{ result.wmsCode + '-' + result.warehouseName }}</td>
          <td class="text-center">
            {{ result.warehouse | numberFormatDisplay: 0 }}
          </td>
          <td class="text-center">{{ result.hold | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.reserved | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.total | numberFormatDisplay: 0 }}</td>
          <td class="text-center">{{ result.inTransit | numberFormatDisplay: 0 }}</td>
          <td class="text-center">
            {{ result.lastModifiedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
          </td>
        </tr>
      </tbody>
    </table>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listWarehouseState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </div>
</ng-container>
