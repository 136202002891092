import { createEntityAdapter } from '@ngrx/entity';
import { MasterAssortmentActionType } from '../actions/master-assortment.actions';
const ɵ0 = (masterAssortment) => `${masterAssortment.shelfNo}-${masterAssortment.barcode}`;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialMasterAssortmentState = adapter.getInitialState({
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 20
});
export function masterAssortmentReducers(state = initialMasterAssortmentState, action) {
    switch (action.type) {
        case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload.criteria });
        case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case MasterAssortmentActionType.MASTER_ASSORTMENT_LIST_RESET:
            return initialMasterAssortmentState;
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
