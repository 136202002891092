import { Action } from '@ngrx/store';
import { ShelfDetailRequest } from '../../models';

export enum ShelfDetailsActionType {
  SHELF_DETAILS_REQUEST = '[Shelf Details] Shelf Details Request',
  SHELF_DETAILS_RESPONSE = '[Shelf Details] Shelf Details Response',
  SHELF_DETAILS_RESET = '[Shelf Details] Shelf Details Reset'
}

export class ShelfDetailsRequestAction implements Action {
  readonly type = ShelfDetailsActionType.SHELF_DETAILS_REQUEST;

  constructor(public payload: string) {}
}

export class ShelfDetailsResponseAction implements Action {
  readonly type = ShelfDetailsActionType.SHELF_DETAILS_RESPONSE;

  constructor(public payload: ShelfDetailRequest[]) {}
}

export class ShelfDetailsResetAction implements Action {
  readonly type = ShelfDetailsActionType.SHELF_DETAILS_RESET;
}

export type ShelfDetailsActions = ShelfDetailsRequestAction | ShelfDetailsResponseAction | ShelfDetailsResetAction;
