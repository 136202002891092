import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-search-pagination',
  templateUrl: './search-pagination.component.html',
  styleUrls: ['./search-pagination.component.scss']
})
export class SearchPaginationComponent implements OnInit {
  @Input() currentPage: number;
  @Input() pageSize: number;
  @Input() pageSizeList: number[];
  @Input() listState$: Observable<any>;

  @Output()
  public changeRowPerPage: EventEmitter<any> = new EventEmitter();

  @Output()
  public changePage: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onChangePageSize(value) {
    this.changeRowPerPage.emit(value);
  }

  public onChangePage(value) {
    this.changePage.emit(value);
  }
}
