import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { VoucherStatusEnum } from '../../../shared/enum/voucher.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  ErrorResponse,
  VoucherListSearchCriteria,
  VoucherRequestPageModes,
  VoucherViewResponse
} from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { VoucherService } from '../../../shared/services/voucher.service';
import {
  ResetVoucherByVoucherCodeRequestSelected,
  VoucherByVoucherCodeRequestAction,
  VoucherCancelAction,
  VoucherListRequestAction
} from '../../../shared/store/actions/voucher.actions';
import {
  selectVoucherByVoucherCode,
  selectVoucherListCriteria
} from '../../../shared/store/selectors/voucher.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { formatDate } from '../../../shared/utils/date-util';

@Component({
  selector: 'app-voucher-view',
  templateUrl: './voucher-view.component.html',
  styleUrls: ['./voucher-view.component.scss']
})
export class VoucherViewComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: VoucherRequestPageModes;
    voucherCode: string;
  };

  public listSearchCriteria: VoucherListSearchCriteria;
  private localStore: Observable<any>;
  public voucherView$: Observable<VoucherViewResponse>;
  public voucher: VoucherViewResponse;
  public voucherForm: FormGroup;
  public status: string;
  public environment: any;
  public criteriaObject;
  public hasViewVoucherPermission = false;
  public hasEditVoucherPermission = false;
  public hasApproveVoucherPermission = false;
  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService,
    private readonly voucherService: VoucherService
  ) {
    super(store, modalService, false);

    this.hasViewVoucherPermission = this.authGuardService.checkPermission(['voucher_v']);
    this.hasEditVoucherPermission = this.authGuardService.checkPermission(['voucher_m']);
    this.hasApproveVoucherPermission = this.authGuardService.checkPermission(['voucher_app']);
    this.environment = environment;
  }

  ngOnInit() {
    this.initControl();
    this.initState();
  }

  initControl() {
    const initialNullDisabled = [{ value: null, disabled: true }];
    this.voucherForm = this.fb.group({
      campaign: initialNullDisabled,
      store: initialNullDisabled,
      effectiveDate: initialNullDisabled,
      expireDate: initialNullDisabled,
      minimumBuyPerBill: initialNullDisabled,
      voucherValue: initialNullDisabled,
      voucherAmount: initialNullDisabled,
      limitPerBill: initialNullDisabled
    });
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectVoucherListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    if (this.data.voucherCode) {
      this.store.dispatch(new VoucherByVoucherCodeRequestAction({ voucherCode: this.data.voucherCode }));
    }

    this.voucherView$ = this.store.pipe(select(selectVoucherByVoucherCode));

    this.voucherView$.pipe(filter(response => response !== null)).subscribe(voucher => {
      this.status = voucher.status as VoucherStatusEnum;
      this.voucher = voucher;
      this.setVoucherFormValue(voucher);
    });
  }

  setVoucherFormValue(voucherView: VoucherViewResponse) {
    const effectiveDate = formatDate(voucherView.details.effectiveDate, this.environment.dateFormat) || null;
    const expireDate = formatDate(voucherView.details.expireDate, this.environment.dateFormat) || null;
    this.voucherForm.controls['campaign'].setValue(voucherView.details.campaign);
    this.voucherForm.controls['store'].setValue(
      `${voucherView.details.store.code}-${voucherView.details.store.name}`
    );
    this.voucherForm.controls['effectiveDate'].setValue(effectiveDate);
    this.voucherForm.controls['expireDate'].setValue(expireDate);
    this.voucherForm.controls['minimumBuyPerBill'].setValue(voucherView.details.minimumBuyPerBill.amount);
    this.voucherForm.controls['voucherValue'].setValue(voucherView.details.voucherValue.amount);
    this.voucherForm.controls['voucherAmount'].setValue(voucherView.details.voucherAmount);

    this.voucherForm.controls['limitPerBill'].setValue(voucherView.details.limitPerBill);
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  onExit() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  refreshVoucherList() {
    this.store.dispatch(new VoucherListRequestAction(this.listSearchCriteria));
  }

  showExportButton(status: string): boolean {
    return !(status === null || status === 'CANCELLED');
  }

  showCancelButton(status: string): boolean {
    return status === 'AWAITING_SCHEDULE';
  }

  goToCancel() {
    const item = this.voucher;
    if (item.status !== 'AWAITING_SCHEDULE') {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Voucher Code <strong>&quot;${item.voucherCode}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherCancelAction({
              id: item.voucherCode,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onExportVoucher() {
    const voucherCode = this.data.voucherCode;

    if (voucherCode === null || voucherCode.length === 0) {
      return;
    }

    const date = new Date();
    const dateExport = moment(date).format(environment.fileName.exportVoucher.timeFormat);

    this.voucherService.exportVoucher(voucherCode).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${voucherCode}_${dateExport}.xlsx`);
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    }
  }

  ngOnDestroy(): void {
    this.refreshVoucherList();
    this.store.dispatch(new ResetVoucherByVoucherCodeRequestSelected());

    super.unsubscribeBase();
  }
}
