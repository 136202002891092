import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DeliveryOrder, DeliveryOrderContent, DeliveryOrderSearchCriteria } from '../../models/delivery-order.model';
import { DeliveryOrderActions, DeliveryOrderActionTypes } from '../actions/delivery-order.actions';

export interface DeliveryOrderState extends EntityState<DeliveryOrderContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  selected: DeliveryOrder[];
  deliveryOrder: DeliveryOrder;
  criteriaObject: DeliveryOrderSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<DeliveryOrderContent> = createEntityAdapter<DeliveryOrderContent>();

export const initialDeliveryOrderState: DeliveryOrderState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  selected: null,
  deliveryOrder: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function deliveryOrderReducers(
  state = initialDeliveryOrderState,
  action: DeliveryOrderActions
): DeliveryOrderState {
  switch (action.type) {
    case DeliveryOrderActionTypes.DELIVERY_ORDER_VIEW_LOADED: {
      return {
        ...state,
        deliveryOrder: action.payload
      };
    }
    case DeliveryOrderActionTypes.DELIVERY_ORDER_SAVE_SUCCESS: {
      return {
        ...state,
        saveSuccess: action.payload
      };
    }
    case DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case DeliveryOrderActionTypes.DELIVERY_ORDER_RESET: {
      return {
        ...state,
        deliveryOrder: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
