import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductActions, ProductActionTypes } from '../actions/product.action';

import { ProductsContent, ProductSelectStatusResponse, ProductsSearchCriteria } from '../../models';

export interface ProductState extends EntityState<ProductsContent> {
  isLoaded: boolean;
  productSelectStatusResponse: ProductSelectStatusResponse;
  selected: object;
  criteriaObject: ProductsSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProductsContent> = createEntityAdapter<ProductsContent>();

export const initialProductState: ProductState = adapter.getInitialState({
  isLoaded: false,
  productSelectStatusResponse: null,
  selected: {},
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function productReducers(state = initialProductState, action: ProductActions): ProductState {
  switch (action.type) {
    case ProductActionTypes.PRODUCTS_REQUEST: {
      return {
        ...state,
        criteriaObject: action.payload
      };
    }
    case ProductActionTypes.PRODUCTS_SUCCESS: {
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    }
    case ProductActionTypes.PRODUCTS_SELECT_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        productSelectStatusResponse: action.payload,
        selected: {}
      };
    }
    case ProductActionTypes.PRODUCTS_SELECT_RESET: {
      return {
        ...state,
        isLoaded: true,
        productSelectStatusResponse: null
      };
    }
    case ProductActionTypes.PRODUCTS_CHECK_ALL: {
      const { entities } = state;
      const selectedNew = {};
      Object.keys(entities).forEach(key => {
        if (entities[key].selectStatus === 'S') {
          return key;
        }
        selectedNew[entities[key].articleNo] = entities[key];
      });
      return {
        ...state,
        selected: {
          ...state.selected,
          ...selectedNew
        }
      };
    }
    case ProductActionTypes.PRODUCTS_UNCHECK_ALL: {
      const { selected, entities } = state;
      Object.keys(entities).forEach(key => {
        delete selected[entities[key].articleNo];
      });
      return {
        ...state,
        selected: {
          ...selected
        }
      };
    }
    case ProductActionTypes.PRODUCTS_CHECK: {
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.articleNo]: action.payload
        }
      };
    }
    case ProductActionTypes.PRODUCTS_UNCHECK: {
      const { selected } = state;
      delete selected[action.payload.articleNo];
      return {
        ...state,
        selected: {
          ...selected
        }
      };
    }
    case ProductActionTypes.PRODUCTS_CHECK_RESET: {
      return {
        ...state,
        selected: {}
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
