import { ApplicationRef } from '@angular/core';
// tslint:disable-next-line:no-implicit-dependencies
import { createInputTransfer, createNewHosts, getInputValues, removeNgStyles } from '@angularclass/hmr';
import { Store } from '@ngrx/store';

export class HmrModule {
  constructor(public appRef: ApplicationRef, public store: Store<any>) {}

  hmrOnInit(store) {
    if (!store || !store.state) {
      return;
    }

    if ('restoreInputValues' in store) {
      store.restoreInputValues();
    }

    this.appRef.tick();
    delete store.state;
    delete store.restoreInputValues;
  }

  hmrOnDestroy(store) {
    const cmpLocation = this.appRef.components.map(cmp => cmp.location.nativeElement);

    store.disposeOldHosts = createNewHosts(cmpLocation);
    store.state = { data: getInputValues() };
    store.restoreInputValues = createInputTransfer();

    removeNgStyles();
  }

  hmrAfterDestroy(store) {
    store.disposeOldHosts();
    delete store.disposeOldHosts;
  }
}
