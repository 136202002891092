import { createSelector } from '@ngrx/store';
import * as fromProfitSharing from '../reducers/profit-sharing.reducers';
import { AppStates } from '../state/app.states';

const selectProfitSharingState = (state: AppStates) => state.profitSharing;

export const selectAllProfitSharingList = createSelector(selectProfitSharingState, fromProfitSharing.selectAll);

export const selectProfitSharingList = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state
);

export const selectProfitSharingData = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.profitSharing
);

export const selectProfitSharingSubmitError = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.submitError
);

export const selectProfitSharingSearchCriteria = createSelector(
  selectProfitSharingState,
  (state: fromProfitSharing.ProfitSharingState) => state.criteriaObject
);
