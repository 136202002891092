import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse, MerchantRequestResponse } from '../../models';
import { MerchantActions, MerchantActionType } from '../actions/merchant.actions';
import { BaseState } from '../state/base.state';

export interface MerchantCreateResponseState extends EntityState<MerchantRequestResponse>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean;
  result?: {
    response: MerchantRequestResponse | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<MerchantRequestResponse> = createEntityAdapter<MerchantRequestResponse>();

export const initialMerchantCreateResponseState: MerchantCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function merchantCreateReducers(
  state = initialMerchantCreateResponseState,
  action: MerchantActions
): MerchantCreateResponseState {
  switch (action.type) {
    case MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE:
    case MerchantActionType.MERCHANT_CREATE_SUBMIT_RESPONSE:
      return {
        ...state,
        isSave: MerchantActionType.MERCHANT_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case MerchantActionType.MERCHANT_CREATE_SUBMIT_ERROR:
    case MerchantActionType.MERCHANT_CREATE_SAVE_ERROR:
      return {
        ...state,
        isSave: MerchantActionType.MERCHANT_CREATE_SAVE_ERROR === action.type,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case MerchantActionType.MERCHANT_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case MerchantActionType.MERCHANT_REQUEST_APPROVE_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case MerchantActionType.MERCHANT_CREATE_RESET:
      return initialMerchantCreateResponseState;
    default: {
      return state;
    }
  }
}
