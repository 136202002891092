export enum DeliveryOrderStatusEnum {
  DRAFT = 'DRAFT',
  NEW = 'NEW',
  PICKING = 'PICKING',
  PICKED = 'PICKED',
  LOADED = 'LOADED',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum DeliveryOrderModeEnum {
  EDIT_MODE = 'edit',
  VIEW_MODE = 'view'
}
