import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ArticlesContent, ArticlesSearchCriteria } from '../../models/articles.model';
import { ArticleService } from '../../services/article.service';
import { AppStates } from '../../store/state/app.states';

@Component({
  selector: 'app-search-article-modal',
  templateUrl: './search-article-modal.component.html',
  styleUrls: ['./search-article-modal.component.scss']
})
export class SearchArticleModalComponent implements OnInit {
  @ViewChild('searchArticleModal', { static: false }) searchArticleModal: ModalDirective;
  @ViewChild('articleSelect', { static: false }) articleSelect: NgSelectComponent;
  @Output() addItem = new EventEmitter<ArticlesContent[]>();
  @Input() location?: string;
  @Input() locationType?: string;
  @Input() productType?: string[];

  searchForm: FormGroup;
  submittedSelectArticles: boolean;
  articlesList: Observable<ArticlesContent[]>;
  articlesSearchLoading = false;
  articlesSearchInput$ = new Subject<string>();

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    protected readonly modalService: BsModalService,
    private readonly articleService: ArticleService
  ) {}

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      articleName: [null, [Validators.required]]
    });
  }

  ngOnDestroy(): void {}

  openSelectModal() {
    this.loadArticle('');
    this.searchArticleModal.show();
  }

  closeSelectModal() {
    this.searchForm.get('articleName').reset();
    this.submittedSelectArticles = false;
    this.articleSelect.itemsList.unmarkItem();
    this.searchArticleModal.hide();
  }

  get form() {
    return this.searchForm.controls;
  }

  submit() {
    this.submittedSelectArticles = true;
    if (this.searchForm.invalid) {
      return;
    }
    const selectedItem = this.searchForm.get('articleName').value as ArticlesContent;
    this.addItem.emit([selectedItem]);
    this.closeSelectModal();
  }

  onBlurArticleName() {
    if (!this.searchForm.controls.articleName.value) {
      this.loadArticle('');
    }
  }

  loadArticle(initialTerm: string) {
    this.articlesList = concat(
      of([]),
      this.articlesSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.articlesSearchLoading = true)),
        switchMap(term => {
          return this.articleService
            .searchArticleByCriteria({
              searchCriteria: term,
              location: this.location,
              locationType: this.locationType,
              productType: this.productType
            } as ArticlesSearchCriteria)
            .pipe(
              map(res => {
                return res.content;
              }),
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.articlesSearchLoading = false;
              })
            );
        })
      )
    );
  }
}
