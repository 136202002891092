import { Action } from '@ngrx/store';
import { GenerateMemberCard, MemberCardPagination, MemberCardSearchCriteria } from '../../models';

export enum MemberCardTypeActions {
  GENERATE_MEMBER_CARD_REQUEST = '[GENERATE_MEMBER_CARD] Request',
  GENERATE_MEMBER_CARD_RESPONSE = '[GENERATE_MEMBER_CARD] Response',
  GENERATE_MEMBER_CARD_RESET = '[GENERATE_MEMBER_CARD] Reset',

  GENERATE_MEMBER_CARD_LIST_REQUEST = '[GENERATE_MEMBER_CARD_LIST] Request',
  GENERATE_MEMBER_CARD_LIST_RESPONSE = '[GENERATE_MEMBER_CARD_LIST] Response'
}

export class GenerateMemberCardRequestAction implements Action {
  readonly type = MemberCardTypeActions.GENERATE_MEMBER_CARD_REQUEST;
  constructor(public payload: GenerateMemberCard) {}
}

export class GenerateMemberCardResponseAction implements Action {
  readonly type = MemberCardTypeActions.GENERATE_MEMBER_CARD_RESPONSE;
}

export class GenerateMemberCardResetAction implements Action {
  readonly type = MemberCardTypeActions.GENERATE_MEMBER_CARD_RESET;
}

export class GenerateMemberCardListRequestAction implements Action {
  readonly type = MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_REQUEST;
  constructor(public payload: MemberCardSearchCriteria) {}
}

export class GenerateMemberCardListResponseAction implements Action {
  readonly type = MemberCardTypeActions.GENERATE_MEMBER_CARD_LIST_RESPONSE;
  constructor(public payload: MemberCardPagination) {}
}

export type MemberCardAction =
  | GenerateMemberCardRequestAction
  | GenerateMemberCardResponseAction
  | GenerateMemberCardResetAction
  | GenerateMemberCardListRequestAction
  | GenerateMemberCardListResponseAction;
