import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ErrorResponse } from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  StoreAssortmentPrintCriteria,
  StoreAssortmentTypeEnum,
  StoreAssortmentViewResponse
} from '../../../shared/models/store-assortment.model';
import { StoreService } from '../../../shared/services/store.service';
import {
  ShelfDetailsResetAction,
  ShelfDetailsResponseAction
} from '../../../shared/store/actions/shelf-details.actions';
import {
  ResetStoreAssortmentAction,
  StoreAssortmentByIdRequestAction
} from '../../../shared/store/actions/store-assortment.actions';
import { selectStoreAssortmentById } from '../../../shared/store/selectors/store-assortment.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-view-store-assortment',
  templateUrl: './view-store-assortment.component.html',
  styleUrls: ['./view-store-assortment.component.scss']
})
export class ViewStoreAssortmentComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { storeNo: string; title: string };

  private localStore: Observable<any>;

  public dateTimeFormat = environment.dateTimeDisplay;
  public storeAssortmentView$: Observable<StoreAssortmentViewResponse>;

  public selectedTab: StoreAssortmentTypeEnum;
  public storeAssortmentTypeEnum = StoreAssortmentTypeEnum;
  public storeCodeName: string;

  constructor(
    protected readonly modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly storeService: StoreService,
    protected readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.selectedTab = StoreAssortmentTypeEnum.MASTER_ASSORTMENT;
    this.store.dispatch(new StoreAssortmentByIdRequestAction({ storeNo: this.data.storeNo }));
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.storeAssortmentView$ = this.localStore.pipe(
      select(selectStoreAssortmentById),
      filter(data => Boolean(data))
    );
    this.storeAssortmentView$.subscribe((data: StoreAssortmentViewResponse) => {
      this.storeCodeName = data.storeCodeName;
      this.store.dispatch(new ShelfDetailsResponseAction(data.shelfDetails));
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ResetStoreAssortmentAction());
    this.store.dispatch(new ShelfDetailsResetAction());
  }

  onSelectTabs(tab: StoreAssortmentTypeEnum) {
    this.selectedTab = tab;
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  onPrintPdf() {
    const param: StoreAssortmentPrintCriteria = {
      storeNo: this.data.storeNo,
      format: 'pdf',
      locale: 'th'
    };

    this.storeService.printStoreAssortmentPdf(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, this.generateFileName());
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  generateFileName() {
    const fileConfig = environment.fileName.printStoreAssortmentPdf;
    const dateExport = moment().format(fileConfig.timeFormat);

    return `${this.storeCodeName} ${fileConfig.prefix} ${dateExport}.pdf`;
  }
}
