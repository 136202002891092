import { createSelector } from '@ngrx/store';
import * as fromProductAssortment from '../reducers/product-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectProductAssortmentState = (state: AppStates) => state.productAssortment;

export const selectProductAssortmentList = createSelector(
  selectProductAssortmentState,
  fromProductAssortment.selectAll
);

export const selectProductAssortment = createSelector(
  selectProductAssortmentState,
  (state: fromProductAssortment.ProductAssortmentState) => state
);
