import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { PosRegisterModes } from '../../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { Device, ErrorResponse, POSStatus } from '../../../../shared/models';
import { StoreService } from '../../../../shared/services/store.service';
import { StoreByIdRequestAction } from '../../../../shared/store/actions/store.actions';
import { selectDevices } from '../../../../shared/store/selectors/store.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';
import { RegisterPOSComponent } from '../register-pos/register-pos.component';

@Component({
  selector: 'app-pos-terminal',
  templateUrl: './pos-terminal.component.html',
  styleUrls: ['./pos-terminal.component.scss']
})
export class POSTerminalComponent implements OnInit, OnDestroy {
  @Input() data: { storeNo?: string; merchant?: string };

  private localStore: Observable<any>;
  public devices$: Observable<Device[]>;
  public waitingDevice: Device;
  public activeDevice: Device;

  constructor(
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly storeService: StoreService,
    private readonly translate: TranslateService
  ) {}

  ngOnDestroy(): void {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.devices$ = this.localStore.pipe(
      select(selectDevices),
      tap(devices => {
        this.waitingDevice = devices.find(device => device.status === POSStatus.WAITING);
        this.activeDevice = devices.find(device => device.status === POSStatus.ACTIVATED);
      })
    );
  }

  onRegisterPOS() {
    const initialState = {
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      mode: PosRegisterModes.CREATE
    };

    this.modalService.show(RegisterPOSComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  onUpdateRegisterPOS(device: Device) {
    const initialState = {
      storeNo: this.data.storeNo,
      merchant: this.data.merchant,
      mode: PosRegisterModes.EDIT,
      data: device
    };

    this.modalService.show(RegisterPOSComponent, {
      initialState,
      backdrop: 'static'
    });
  }

  onDeletePOS() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, delete',
        cancelText: 'Cancel',
        message: 'Are you sure you want to delete this device?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeService.deletePOS({ storeNo: this.data.storeNo }).subscribe(
            () => {
              this.afterSuccess('deleted');
            },
            error => {
              this.alertErrorModal(error);
            }
          );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  onDeactivatePOS() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Deactivate" this device?',
        label: 'Comment',
        okText: 'Yes, deactivate',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.storeService
            .deactivatePOS({ storeNo: this.data.storeNo }, confirmModalRef.content.confirmMessage)
            .subscribe(
              () => {
                this.afterSuccess('deactivated');
              },
              error => {
                this.alertErrorModal(error);
              }
            );
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  afterSuccess(alertMessage: string) {
    this.store.dispatch(
      new StoreByIdRequestAction({
        merchant: { merchant: this.data.merchant },
        storeNo: { storeNo: this.data.storeNo }
      })
    );

    this.alertSuccessModal(alertMessage);
  }

  alertSuccessModal(type: string) {
    const initialState = {
      title: 'Success',
      message: `This device has been ${type}.`
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(error.translateKey, { context: error.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  canUpdateRegisterPOS(status: string): boolean {
    return status === POSStatus.ACTIVATED;
  }
}
