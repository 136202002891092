import { OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModalButtonResponseEnum } from '../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../shared/layouts';
import { LayoutActionSaveSuccess, LayoutActionVersionError } from '../shared/store/actions/layout.action';
import { selectSaveSuccess, selectVersionError } from '../shared/store/selectors/layout-selector';
import { AppStates } from '../shared/store/state/app.states';

export abstract class BaseComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription = new Subscription();
  public bsDateConfig: BsDatepickerConfig;

  protected constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected isChildComponent = false
  ) {
    if (this.isChildComponent) {
      this.subscribeForSaveSuccess();
    } else {
      this.subscribeForVersionError();
    }

    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: 'DD/MM/YYYY',
      showWeekNumbers: false,
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.unsubscribeBase();
  }

  subscribeForVersionError() {
    // Listen for Version Error
    this.subscriptions.add(
      this.store
        .pipe(
          select(selectVersionError),
          filter(isError => isError),
          untilComponentDestroyed(this)
        )
        .subscribe(isError => {
          if (isError) {
            const initialState = {
              title: 'Failed',
              message: 'The current session is no longer because data has been changed.',
              isRefresh: false
            };
            const alertModal = this.modalService.show(AlertModalComponent, {
              initialState
            });
            alertModal.content.action
              .pipe(untilComponentDestroyed(this))
              .subscribe((result: ModalButtonResponseEnum) => {
                if (result === ModalButtonResponseEnum.OK) {
                  alertModal.hide();
                  this.doAfterVersionAlertModal();
                }
              });
            this.store.dispatch(new LayoutActionVersionError(false));
          }
        })
    );
  }

  subscribeForSaveSuccess(isShowSuccess = true) {
    // Listen for Save Success
    this.subscriptions.add(
      this.store
        .pipe(
          select(selectSaveSuccess),
          filter(saveSuccess => saveSuccess),
          untilComponentDestroyed(this)
        )
        .subscribe(success => {
          if (success && success.isSuccess) {
            if (isShowSuccess) {
              const initialState = {
                title: success.title,
                message: success.message,
                isRefresh: false,
                routerLink: success.routerLink
              };
              const alertModal = this.modalService.show(AlertModalComponent, {
                initialState,
                backdrop: 'static'
              });
              alertModal.content.action
                .pipe(untilComponentDestroyed(this))
                .subscribe((result: ModalButtonResponseEnum) => {
                  if (result === ModalButtonResponseEnum.OK) {
                    alertModal.hide();
                    this.doAfterSuccessModal();
                  }
                });
            } else {
              this.doAfterSuccessModal();
            }
            this.store.dispatch(new LayoutActionSaveSuccess(null));
          }
        })
    );
  }

  getValue(value: any) {
    return this.isEmpty(value) ? null : value;
  }

  isEmpty(value: string) {
    return value === undefined || value === null || value === '';
  }

  // All component inherit from this base component
  // must call this function in its ngOnDestroy
  unsubscribeBase() {
    this.subscriptions.unsubscribe();
  }

  abstract doAfterVersionAlertModal();

  doAfterSuccessModal() {}
}
