import { Action } from '@ngrx/store';
import {
  StoreAssortmentRequestItem,
  StoreAssortmentRequestListResponse,
  StoreAssortmentRequestListSearchCriteria,
  StoreAssortmentRequestViewResponse
} from '../../models/store-assortment-request.model';

export enum StoreAssortmentRequestActionType {
  STORE_ASSORTMENT_REQUEST_LIST_REQUEST = '[StoreAssortmentRequest] Store Assortment Request List Request',
  STORE_ASSORTMENT_REQUEST_LIST_RESPONSE = '[StoreAssortmentRequest] Store Assortment List Response',
  STORE_ASSORTMENT_REQUEST_GET_BY_ID_REQUEST = '[StoreAssortmentRequest] Get Store Assortment List by Id Request',
  STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESPONSE = '[StoreAssortmentRequest] Get Store Assortment List by Id Response',
  STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESET = '[StoreAssortmentRequest] Get Store Assortment List by Id Reset',
  STORE_ASSORTMENT_CREATE_SUBMIT_REQUEST = '[StoreAssortmentRequest] Create StoreAssortment Submit Request'
}

export class StoreAssortmentRequestListRequestAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_REQUEST;

  constructor(public payload: StoreAssortmentRequestListSearchCriteria) {}
}

export class StoreAssortmentRequestListResponseAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_LIST_RESPONSE;

  constructor(public payload: StoreAssortmentRequestListResponse) {}
}

export class StoreAssortmentRequestByIdRequestAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class StoreAssortmentRequestByIdResponseAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: StoreAssortmentRequestViewResponse) {}
}

export class StoreAssortmentRequestResetAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_REQUEST_GET_BY_ID_RESET;
}

export class StoreAssortmentCreateSubmitRequestAction implements Action {
  readonly type = StoreAssortmentRequestActionType.STORE_ASSORTMENT_CREATE_SUBMIT_REQUEST;

  constructor(public payload: Array<StoreAssortmentRequestItem>) {}
}

export type StoreAssortmentRequestActions =
  | StoreAssortmentRequestListRequestAction
  | StoreAssortmentRequestListResponseAction
  | StoreAssortmentRequestByIdRequestAction
  | StoreAssortmentRequestByIdResponseAction
  | StoreAssortmentRequestResetAction
  | StoreAssortmentCreateSubmitRequestAction;
