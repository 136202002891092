/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/ngx-spinner/ngx-spinner.ngfactory";
import * as i4 from "ngx-spinner";
import * as i5 from "./app.component";
import * as i6 from "ngx-logger";
import * as i7 from "@ngx-translate/core";
import * as i8 from "@angular/platform-browser";
import * as i9 from "@angular/service-worker";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ngx-spinner", [["bdColor", "#000"], ["bdOpacity", "0.8"], ["color", "#fff"], ["size", "medium"], ["type", "pacman"]], null, null, null, i3.View_NgxSpinnerComponent_0, i3.RenderType_NgxSpinnerComponent)), i1.ɵdid(3, 770048, null, 0, i4.NgxSpinnerComponent, [i4.NgxSpinnerService, i1.ChangeDetectorRef], { bdColor: [0, "bdColor"], size: [1, "size"], color: [2, "color"], type: [3, "type"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "p", [["id", "system-loading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["System is in progress"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_0 = "#000"; var currVal_1 = "medium"; var currVal_2 = "#fff"; var currVal_3 = "pacman"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], [[2, "tier-alpha", null], [2, "tier-beta", null], [2, "tier-staging", null], [2, "tier-develop", null]], null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i5.AppComponent, [i6.NGXLogger, i7.TranslateService, i8.Title, [2, i9.SwUpdate]], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).isAlpha; var currVal_1 = i1.ɵnov(_v, 1).isBeta; var currVal_2 = i1.ɵnov(_v, 1).isStaging; var currVal_3 = i1.ɵnov(_v, 1).isDevelop; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var AppComponentNgFactory = i1.ɵccf("app-root", i5.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
