import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import {
  ApproveStoreRequest,
  StoreId,
  StoreRequestListResponse,
  StoreRequestListSearchCriteria,
  StoreRequestTemplate,
  StoreRequestViewResponse,
  ValidateOrderRequest
} from '../models';
import { VersionModel } from '../models/version.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreRequestService extends BaseService {
  headers: HttpHeaders;

  constructor(private readonly http: HttpClient, private readonly translate: TranslateService) {
    super();
    this.envService = this.env.services.storeRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchStoreRequestByCriteria(
    criteria: StoreRequestListSearchCriteria
  ): Observable<StoreRequestListResponse> {
    const params = this.getParams(criteria);

    return this.http.get<StoreRequestListResponse>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getStoreById(storeId: StoreId): Observable<StoreRequestTemplate> {
    const url = this.getFullUrl(this.envService.view, {
      storeId: storeId.storeId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getStoreRequestVersionById(storeId: StoreId): Observable<VersionModel> {
    const url = this.getFullUrl(this.envService.getStoreVersion, {
      storeId: storeId.storeId
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchMerchantByName(term, size = 20) {
    const params = this.getParams({
      ...term,
      size,
      sortBy: 'name',
      sortOrder: 'asc',
      status: 'ACTIVE',
      include: 'name,type,taxId'
    });
    const url = this.env.serverUrl + this.env.services.merchant.url;

    return this.http
      .get<any>(url, {
        headers: this.headers,
        observe: 'body',
        params
      })
      .pipe(
        map(data => data.content),
        tap(merchants => {
          if (merchants) {
            merchants.forEach(merchant => {
              this.translate.get('STORE_TYPE.' + merchant.merchantType).subscribe(merchantType => {
                merchant.merchantNameDisplay = `${merchant.merchantName} (${merchant.taxId}) - ${merchantType}`;
              });
            });
          }
        })
      );
  }

  public saveRequest(storeRequest: StoreRequestTemplate): Observable<StoreRequestTemplate> {
    const url = this.getFullUrl(this.envService.save);

    return this.http.put<any>(url, storeRequest, { headers: this.loaderHeaders() });
  }

  public submitRequest(storeRequest: StoreRequestTemplate): Observable<StoreRequestTemplate> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, storeRequest, { headers: this.loaderHeaders() });
  }

  public approveRequest(request: ApproveStoreRequest): Observable<StoreRequestViewResponse> {
    const url = this.getFullUrl(this.envService.approve);

    return this.http.put<StoreRequestViewResponse>(url, request, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public validateSubmitFirstLotOrder(payload: ValidateOrderRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.validateSubmitFirstLot);

    return this.http.post<any>(url, payload, { headers: this.headers });
  }

  public deleteByRequestId(storeId: StoreId): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.delete, {
      storeId: storeId.storeId
    });
    return this.http.delete<any>(url, { headers: this.loaderHeaders(), observe: 'body' });
  }

  public getHistoryLogs(storeId: StoreId): Observable<any> {
    const url = this.getFullUrl(this.envService.history, {
      storeId: storeId.storeId
    });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public getStoreValidate(
    type: TDStoreValidatorTypeEnum,
    value: string,
    urlParams: { [key: string]: string } = null
  ): Observable<HttpResponse<any>> {
    const url = this.getFullUrl(this.envService.validate, { type, value });
    const params = urlParams ? this.getParams(urlParams) : null;

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'response',
      params
    });
  }
}
