import { createSelector } from '@ngrx/store';
import * as fromMemberCardState from '../reducers/member-card.reducers';
import { AppStates } from '../state/app.states';

const memberCardState = (state: AppStates) => state.memberCard;

export const selectAllMemberCardList = createSelector(memberCardState, fromMemberCardState.selectAll);

export const selectMemberCardList = createSelector(
  memberCardState,
  (state: fromMemberCardState.MemberCardState) => state
);

export const selectMemberCardListCriteria = createSelector(memberCardState, state => {
  return state.criteriaObject;
});

export const selectMemberCardSubmitResponse = createSelector(
  memberCardState,
  (state: fromMemberCardState.MemberCardState) => state.submitSuccess
);
