import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GroupSupplierStoresService } from '../../services/group-supplier-stores.service';
import { SupplierGroupRequestService } from '../../services/supplier-group-request.service';
import { SupplierGroupService } from '../../services/supplier-group.service';
import { SupplierStoreService } from '../../services/supplier-store.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { StoreSupplierListResponse, StoreSupplierRequestListResponse, SupplierStoreActionTypes, SupplierStoreRequestListResponse } from '../actions/supplier-store.actions';
export class SupplierStoreEffects {
    constructor(actions$, supplierStoreService, supplierGroupRequestService, supplierGroupService, groupSupplierStoresService, logger) {
        this.actions$ = actions$;
        this.supplierStoreService = supplierStoreService;
        this.supplierGroupRequestService = supplierGroupRequestService;
        this.supplierGroupService = supplierGroupService;
        this.groupSupplierStoresService = groupSupplierStoresService;
        this.logger = logger;
        this.searchSupplierStore$ = this.actions$.pipe(ofType(SupplierStoreActionTypes.SUPPLIER_STORE_REQUEST_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Store Request List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.supplierStoreService.searchByCriteria(action.payload).pipe(map(response => new SupplierStoreRequestListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.searchStoreSupplierRequest$ = this.actions$.pipe(ofType(SupplierStoreActionTypes.REQUEST_STORE_SUPPLIER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Store Group Request List Requested:` + JSON.stringify(action.payload))), switchMap(action => {
            if (action.payload.isImported) {
                return this.groupSupplierStoresService.getStoresSupplierBySupplierGroupId(action.payload).pipe(map(response => new StoreSupplierRequestListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))));
            }
            else {
                return this.supplierGroupRequestService.getStoresSupplierBySupplierGroupId(action.payload).pipe(map(response => new StoreSupplierRequestListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))));
            }
        }));
        this.searchStoreSupplier$ = this.actions$.pipe(ofType(SupplierStoreActionTypes.STORE_SUPPLIER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Supplier Store Group List Requested:` + JSON.stringify(action.payload))), switchMap(action => {
            // if (action.payload.isImported) {
            //   // TODO: implement after import
            //   // return this.groupSupplierStoresService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
            //   //   map(response => new StoreSupplierRequestListResponse(response)),
            //   //   catchError(error => of(new LayoutActionLoadError(error)))
            //   // );
            // } else {
            //   return this.supplierGroupService.getStoresSupplierBySupplierGroupId(action.payload).pipe(
            //     map(response => new StoreSupplierListResponse(response)),
            //     catchError(error => of(new LayoutActionLoadError(error)))
            //   );
            // }
            if (!action.payload.isImported) {
                return this.supplierGroupService.getStoresSupplierBySupplierGroupId(action.payload).pipe(map(response => new StoreSupplierListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))));
            }
            return of([]);
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierStoreEffects.prototype, "searchSupplierStore$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierStoreEffects.prototype, "searchStoreSupplierRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], SupplierStoreEffects.prototype, "searchStoreSupplier$", void 0);
