import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class PermissionsService extends BaseService {
    constructor(http) {
        super();
        this.http = http;
        this.envService = this.env.services.permission;
        this.headers = new HttpHeaders(this.envService.headers);
    }
    getPermission() {
        return this.http.get(this.getUrl(), {
            headers: this.headers,
            observe: 'body'
        });
    }
}
PermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(i0.ɵɵinject(i1.HttpClient)); }, token: PermissionsService, providedIn: "root" });
