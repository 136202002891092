import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  ApproveShelfRequest,
  RequestId,
  ShelfCreateRequest,
  ShelfRequestListResponse,
  ShelfRequestListSearchCriteria,
  ShelfRequestViewResponse
} from '../../models/shelf-request.model';

export enum ShelfRequestActionType {
  SHELF_REQUEST_LIST_REQUEST = '[ShelfRequest] Shelf Request List Request',
  SHELF_REQUEST_LIST_RESPONSE = '[ShelfRequest] Shelf Request List Response',
  SHELF_CREATE_SAVE_REQUEST = '[ShelfRequest] Create Shelf Save Request',
  SHELF_CREATE_SAVE_RESPONSE = '[ShelfRequest] Create Shelf Save Response',
  SHELF_CREATE_SAVE_ERROR = '[ShelfRequest] Create Shelf Save Error',
  SHELF_CREATE_SUBMIT_REQUEST = '[ShelfRequest] Create Shelf Submit Request',
  SHELF_CREATE_SUBMIT_RESPONSE = '[ShelfRequest] Create Shelf Submit Response',
  SHELF_CREATE_SUBMIT_ERROR = '[ShelfRequest] Create Shelf Submit Error',
  SHELF_REQUEST_GET_BY_ID_REQUEST = '[ShelfRequest] Get Shelf By Id Request',
  SHELF_REQUEST_GET_BY_ID_RESPONSE = '[ShelfRequest] Get Shelf By Id Response',
  SHELF_REQUEST_GET_BY_ID_RESET = '[ShelfRequest] Get Shelf By Id Reset',
  SHELF_REQUEST_LIST_HISTORY_REQUEST = '[ShelfRequest] Shelf Request List History Request',
  SHELF_REQUEST_LIST_HISTORY_RESPONSE = '[ShelfRequest] Shelf Request List History Response',
  SHELF_REQUEST_DELETE_REQUEST = '[ShelfRequest] Request Delete Shelf Request',
  SHELF_REQUEST_DELETE_RESPONSE = '[ShelfRequest] Request Delete Shelf Response',
  SHELF_REQUEST_DELETE_RESET = '[ShelfRequest] Request Delete Shelf Reset',
  SHELF_REQUEST_APPROVE_REQUEST = '[ShelfRequest] Request Approve Shelf Request',
  SHELF_REQUEST_APPROVE_RESPONSE = '[ShelfRequest] Request Approve Shelf Response',
  SHELF_TYPE_LIST_REQUEST = '[ShelfRequest] Shelf Type List Request',
  SHELF_TYPE_LIST_RESPONSE = '[ShelfRequest] Shelf Type List Response',
  SHELF_CREATE_RESET = '[ShelfRequest] Create Shelf Reset'
}

export class ShelfRequestListRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST;

  constructor(public payload: ShelfRequestListSearchCriteria) {}
}

export class ShelfRequestListResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_RESPONSE;

  constructor(public payload: ShelfRequestListResponse) {}
}

export class ShelfCreateSaveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_REQUEST;

  constructor(public payload: ShelfCreateRequest) {}
}

export class ShelfCreateSaveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfCreateSaveErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SAVE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfCreateSubmitRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_REQUEST;

  constructor(public payload: ShelfCreateRequest) {}
}

export class ShelfCreateSubmitResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfCreateSubmitErrorAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_SUBMIT_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShelfRequestListHistoryRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfRequestListHistoryResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_RESPONSE;

  constructor(public payload: { auditLogs }) {}
}

export class ShelfRequestByIdRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfRequestByIdResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESPONSE;

  constructor(public payload: ShelfRequestViewResponse) {}
}

export class ShelfRequestByIdResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESET;
}

export class ShelfDeleteRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_REQUEST;

  constructor(public payload: RequestId) {}
}

export class ShelfDeleteResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfDeleteResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_DELETE_RESET;
}

export class ShelfApproveRequestAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_REQUEST;

  constructor(public payload: ApproveShelfRequest) {}
}

export class ShelfApproveResponseAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_REQUEST_APPROVE_RESPONSE;

  constructor(public payload: { isSuccess: boolean }) {}
}

export class ShelfCreateResetAction implements Action {
  readonly type = ShelfRequestActionType.SHELF_CREATE_RESET;
}

export type ShelfRequestActions =
  | ShelfRequestListRequestAction
  | ShelfRequestListResponseAction
  | ShelfCreateSaveRequestAction
  | ShelfCreateSaveResponseAction
  | ShelfCreateSaveErrorAction
  | ShelfCreateSubmitRequestAction
  | ShelfCreateSubmitResponseAction
  | ShelfCreateSubmitErrorAction
  | ShelfRequestByIdRequestAction
  | ShelfRequestByIdResponseAction
  | ShelfRequestByIdResetAction
  | ShelfRequestListHistoryRequestAction
  | ShelfRequestListHistoryResponseAction
  | ShelfDeleteRequestAction
  | ShelfDeleteResponseAction
  | ShelfDeleteResetAction
  | ShelfApproveRequestAction
  | ShelfApproveResponseAction
  | ShelfCreateResetAction;
