import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { UploadStatusEnum } from '../../../shared/enum/upload-status.enum';
import { AppStates } from '../../../shared/store/state/app.states';
import { DeliveryOrderFileUploadComponent } from '../delivery-order-file-upload/delivery-order-file-upload.component';

@Component({
  selector: 'app-delivery-order-import-modal',
  templateUrl: './delivery-order-import-modal.component.html',
  styleUrls: ['./delivery-order-import-modal.component.scss']
})
export class DeliveryOrderImportModalComponent implements OnInit, OnDestroy {
  @ViewChild(DeliveryOrderFileUploadComponent, { static: false })
  fileUpload: DeliveryOrderFileUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  public uploadUrl: string;
  public warehouseList: NgOption;

  public uploadStatus = UploadStatusEnum;
  public form: FormGroup;
  public errors = [];
  public errorMessage: string;

  private localStore: Observable<any>;

  constructor(
    public bsModalRef: BsModalRef,
    public fb: FormBuilder,
    protected modalService: BsModalService,
    private readonly store: Store<AppStates>
  ) {}
  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  createForm() {
    this.form = this.fb.group({
      deliveryOrderImport: [null],
      warehouseCode: null
    });
  }

  onSubmit() {
    if (!this.form.controls.deliveryOrderImport.value && !this.form.controls.deliveryOrderImport.value[0].length) {
      return;
    }
    this.submitUpload.emit({ file: this.form.controls.deliveryOrderImport.value[0] });
    this.bsModalRef.hide();
  }
}
