import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ProductAssortmentList, ProductAssortmentSearchCriteria } from '../../models';
import { ProductAssortmentActions, ProductAssortmentActionTypes } from '../actions/product-assortment.actions';

export interface ProductAssortmentState extends EntityState<ProductAssortmentList> {
  isLoading: boolean;
  productAssortment: any;
  criteriaObject: ProductAssortmentSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ProductAssortmentList> = createEntityAdapter<ProductAssortmentList>();

export const initialProductAssortmentState: ProductAssortmentState = adapter.getInitialState({
  isLoading: false,
  productAssortment: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function productAssortmentReducers(
  state = initialProductAssortmentState,
  action: ProductAssortmentActions
): ProductAssortmentState {
  if (action.type === ProductAssortmentActionTypes.ProductAssortmentSearchLoaded) {
    return adapter.addAll(action.payload.productAssortments.content, {
      ...state,
      page: action.payload.productAssortments.page,
      size: action.payload.productAssortments.size,
      totalElements: action.payload.productAssortments.totalElements,
      totalPages: action.payload.productAssortments.totalPages
    });
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
