import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { PermissionsService } from '../../services/permissions.service';
import { RoleService } from '../../services/role.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { RoleActionTypes, RoleAllResponseAction, RoleByIdResponseAction, RoleListResponseAction, RoleMasterListResponse, RoleResponseErrorAction } from '../actions/role.actions';
export class RoleEffects {
    constructor(actions$, roleService, permissionsService, logger, store, spinner) {
        this.actions$ = actions$;
        this.roleService = roleService;
        this.permissionsService = permissionsService;
        this.logger = logger;
        this.store = store;
        this.spinner = spinner;
        this.searchRoleList$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_LIST_REQUEST_ACTION), map(action => {
            this.logger.debug(`@Effect ${RoleActionTypes.ROLE_LIST_REQUEST_ACTION}: ` + this.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.roleService.searchByCriteriaRequest(payload).pipe(map(requests => {
                let i = 1;
                requests.content.forEach(e => {
                    e.id = i.toString();
                    i++;
                });
                return new RoleListResponseAction(requests);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.getRoleMasterList$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_MASTER_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_MASTER_LIST_REQUEST}: ` + JSON.stringify(action))), switchMap(() => this.permissionsService.getPermission().pipe(map(items => new RoleMasterListResponse({ permissions: items })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.createRole$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_CREATED_REQUEST), tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_CREATED_REQUEST}: ` + JSON.stringify(action))), switchMap(action => {
            return this.roleService.save(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The role has been created.'
            })), catchError(err => this.responseError(err, action)));
        }));
        this.editRole$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_EDIT_REQUEST), tap(action => this.logger.debug(`@Effect ${RoleActionTypes.ROLE_EDIT_REQUEST}: ` + JSON.stringify(action))), switchMap(action => {
            return this.roleService.edit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The role has been updated.'
            })), catchError(err => this.responseError(err, action)));
        }));
        this.getRoleById$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_GET_BY_ID_REQUEST), tap(action => {
            this.logger.debug('@Effect Role By Id Load: ' + JSON.stringify(action.payload));
        }), mergeMap(action => this.roleService.getRoleById(action.payload).pipe(map(roleView => {
            return new RoleByIdResponseAction(roleView);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
        this.getRoleAll$ = this.actions$.pipe(ofType(RoleActionTypes.ROLE_ALL_REQUEST_ACTION), tap(action => {
            this.logger.debug('@Effect Role All Load: ' + JSON.stringify(action.type));
        }), mergeMap(() => this.roleService.getRoleAll().pipe(map(roleView => {
            return new RoleAllResponseAction(roleView);
        }), catchError(err => of(new LayoutActionLoadError(err))))));
    }
    responseError(err, action) {
        return err && err.error && ['02011', '02012'].includes(err.error.code)
            ? of(new RoleResponseErrorAction(Object.assign({}, err.error, { duplicateName: action.payload.name })))
            : err && err.error && err.error.code === '00001'
                ? of(new LayoutActionVersionError(true))
                : of(new LayoutActionLoadError(err));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RoleEffects.prototype, "searchRoleList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], RoleEffects.prototype, "getRoleMasterList$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], RoleEffects.prototype, "createRole$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], RoleEffects.prototype, "editRole$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RoleEffects.prototype, "getRoleById$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], RoleEffects.prototype, "getRoleAll$", void 0);
