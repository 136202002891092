import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { AuthRequest } from '../../shared/models';
import { LogInAction, LoginReset, LogOutAction } from '../../shared/store/actions/auth.actions';
import { ForgotPasswordReset } from '../../shared/store/actions/forgot-password.action';
import { ForgotUsernameReset } from '../../shared/store/actions/forgot-username.action';
import { BaseForceChangePasswordResponseState } from '../../shared/store/reducers/force-change-password.reducer';
import { BaseForgotUsernameState } from '../../shared/store/reducers/forgot-username.reducer';
import { selectAuthResult } from '../../shared/store/selectors/auth.selector';
import { selectForgotPasswordResult } from '../../shared/store/selectors/forgot-password.selector';
import { selectForgotUsernameResult } from '../../shared/store/selectors/forgot-username.selector';
import { selectForceChangePasswordResult } from '../../shared/store/selectors/user-selector';
import { AppStates } from '../../shared/store/state/app.states';
import { BaseAuthState } from '../../shared/store/state/auth.state';
import { BaseForgotPasswordState } from '../../shared/store/state/forgot-password.state';

@Component({
  selector: 'app-login-right',
  templateUrl: './login-right.component.html',
  styleUrls: ['./login-right.component.scss']
})
export class LoginRightComponent implements OnInit, OnDestroy {
  public bsModalRef: BsModalRef;
  public loginForm: FormGroup;
  public submitted = false;
  public result$: Observable<BaseAuthState>;
  public forgotUsernameResult$: Observable<BaseForgotUsernameState>;
  public forgotPasswordResult$: Observable<BaseForgotPasswordState>;
  public forceChangePasswordResult$: Observable<BaseForceChangePasswordResponseState>;
  public isShowPassword: boolean;
  public forceChangePasswordSuccess: boolean;

  private localStore: Observable<any>;

  constructor(private readonly store: Store<AppStates>, private readonly formBuilder: FormBuilder) {}

  ngOnDestroy(): void {
    this.store.dispatch(new LoginReset());
    this.resetAlertMessage();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.result$ = this.localStore.pipe(select(selectAuthResult));
    this.forgotUsernameResult$ = this.localStore.pipe(select(selectForgotUsernameResult));
    this.forgotPasswordResult$ = this.localStore.pipe(select(selectForgotPasswordResult));
    this.forceChangePasswordResult$ = this.localStore.pipe(select(selectForceChangePasswordResult));

    this.forceChangePasswordResult$.subscribe(data => {
      if (data.result.isSuccess) {
        this.store.dispatch(new LogOutAction());
        this.forceChangePasswordSuccess = true;
      }
    });

    this.isShowPassword = false;
  }

  toggleInputType() {
    this.isShowPassword = !this.isShowPassword;
  }

  onSubmit() {
    this.submitted = true;
    this.isShowPassword = false;

    if (this.loginForm.invalid) {
      return;
    }

    const payload: AuthRequest = {
      ...this.loginForm.value,
      grant_type: 'password'
    };

    this.resetAlertMessage();
    this.store.dispatch(new LogInAction(payload as AuthRequest));
  }

  get f(): {
    [key: string]: AbstractControl;
  } {
    return this.loginForm.controls;
  }

  resetAlertMessage() {
    this.store.dispatch(new ForgotUsernameReset()); // remove alert success from forgotUsernameResult
    this.store.dispatch(new ForgotPasswordReset()); // remove alert success from forgotPasswordResult
    this.forceChangePasswordSuccess = false;
  }
}
