import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as _ from 'lodash';
import { BsModalService } from 'ngx-bootstrap';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../../../environments/base.environment';
import { BaseComponent } from '../../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../../shared/enum/request-step.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ConfirmModalComponent } from '../../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ButtonType, ImportExportButton } from '../../../../shared/models/import-export-button.model';
import { Z8ParameterContent, Z8ParameterList } from '../../../../shared/models/z8-parameter.model';
import { Families, ProductAssortmentService, RequestAssortmentService } from '../../../../shared/services';
import { MerchantService } from '../../../../shared/services/merchant.service';
import { Z8ParameterDataService } from '../../../../shared/services/z8-parameter-data.service';
import { selectZ8ParameterById } from '../../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { dateStringToTagCriteria } from '../../../../shared/utils/date-util';
import { AddConditionComponent } from '../../components/add-condition/add-condition.component';
import { ImportDataComponent } from '../../components/import-data/import-data.component';

@Component({
  selector: 'app-edit-parameter',
  templateUrl: './edit-parameter.component.html',
  styleUrls: ['./edit-parameter.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditParameterComponent extends BaseComponent implements OnInit, OnDestroy {
  private localStore: Observable<any>;
  public data: Array<Z8ParameterContent> = [];
  public searchForm: FormGroup;

  public isShowAdvanceSearch: boolean;
  public dateFormat = environment.dateFormat;
  public dateTag: string;
  public dateStringTag: string;
  public minDate: Date;
  public maxDate: Date;

  public currentPage: number;
  public pageSize: number;
  public listOfValue = {};
  public storeList;
  public articleList;
  public validateObj = {};

  public isLoading = true;

  private listOfValue$: Observable<any>;
  private storeList$: Observable<any>;
  private articleList$: Observable<any>;

  public parameterInfo: Z8ParameterList;
  public parameterFactor = [
    {
      parameterName: 'Product Factor',
      decimals: 2
    },
    {
      parameterName: 'Weekend',
      decimals: 2
    },
    {
      parameterName: 'Beginning Month',
      decimals: 2
    },
    {
      parameterName: 'Promotion',
      decimals: 0
    },
    {
      parameterName: 'Max Target',
      decimals: 0
    }
  ];

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.IMPORT,
      name: 'Import'
    }
  ];

  constructor(
    private readonly fb: FormBuilder,
    public readonly merchantService: MerchantService,
    public modalService: BsModalService,
    private readonly productAssortmentService: ProductAssortmentService,
    private readonly requestAssortmentService: RequestAssortmentService,
    public store: Store<AppStates>,
    private readonly z8ParameterDataService: Z8ParameterDataService,
    private readonly translate: TranslateService,
    private readonly cdRef: ChangeDetectorRef
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.createForm();
    this.setInitialValue();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore.pipe(select(selectZ8ParameterById)).subscribe(data => {
      this.parameterInfo = data;
    });

    this.z8ParameterDataService.getData.subscribe(data => {
      this.data = [...data];
      this.cdRef.detectChanges();
    });

    this.z8ParameterDataService.updateData.subscribe(isUpdate => {
      if (isUpdate) {
        this.search();
      }
    });

    this.listOfValue$ = this.productAssortmentService.getSegmentListData(null).pipe(filter(v => Boolean(v)));
    this.storeList$ = this.merchantService
      .searchStoreByCriteria({
        page: 0,
        size: 10000
      })
      .pipe(filter(v => Boolean(v)));
    this.articleList$ = this.requestAssortmentService
      .getZ8ParameterAssortment({
        page: 0,
        size: 50000
      })
      .pipe(filter(v => Boolean(v)));
    this.loadMasterList();
  }

  ngOnDestroy() {
    this.z8ParameterDataService.reset();
  }

  setInitialValue() {
    this.currentPage = 1;
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = null;
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      startDate: [null],
      endDate: [null]
    });
  }

  openAddConditionModal() {
    const modal = this.modalService.show(AddConditionComponent, {
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        validateObj: this.validateObj,
        listOfValue$: this.listOfValue$,
        storeList$: this.storeList$,
        articleList$: this.articleList$,
        mode: RequestPageModesEnum.REQUEST_CREATE
      }
    });

    const validateObjChange = modal.content.validateObjChange.subscribe(data => {
      this.validateObj = { ...data };
      this.searchForm.reset();
      this.clearAdvanceFilter();
      if (validateObjChange) {
        validateObjChange.unsubscribe();
      }
    });
  }

  openImportModal() {
    const modal = this.modalService.show(ImportDataComponent, {
      backdrop: 'static',
      initialState: {
        validateObj: this.validateObj,
        listOfValue: this.listOfValue,
        storeList: this.storeList,
        articleList: this.articleList
      }
    });

    const validateObjChange = modal.content.validateObjChange.subscribe(data => {
      this.alertMessage({ title: 'Success', message: 'The data have been imported.' });
      this.validateObj = { ...data };
      if (validateObjChange) {
        validateObjChange.unsubscribe();
      }
    });
  }

  deleteCondition(itemNo: string, item: Z8ParameterContent) {
    const initialState = {
      title: 'Confirm',
      okText: 'Yes, delete',
      cancelText: 'Cancel',
      message: 'Are you sure you want to delete?'
    };

    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.deleteConditionItem(itemNo, item);
        }

        if (confirmModalRef.content.actions) {
          confirmModalRef.content.actions.unsubscribe();
        }
      });
  }

  editCondition(item: Z8ParameterContent) {
    const modal = this.modalService.show(AddConditionComponent, {
      class: 'modal-lg',
      backdrop: 'static',
      initialState: {
        validateObj: this.validateObj,
        listOfValue$: this.listOfValue$,
        storeList$: this.storeList$,
        articleList$: this.articleList$,
        mode: RequestPageModesEnum.REQUEST_EDIT,
        editItem: item
      }
    });

    const validateObjChange = modal.content.validateObjChange.subscribe(data => {
      this.validateObj = { ...data };

      if (validateObjChange) {
        validateObjChange.unsubscribe();
      }
    });
  }

  deleteConditionItem(itemNo: string, item: Z8ParameterContent) {
    const key = this.getItemKey(item);

    if (this.validateObj[key]) {
      if (this.validateObj[key].length > 1) {
        const keyItemIndex = this.validateObj[key].findIndex(
          x => x.startDate === item.startDate && x.endDate === item.endDate
        );

        if (keyItemIndex > -1) {
          this.validateObj[key].splice(keyItemIndex, 1);
        }
      } else {
        delete this.validateObj[key];
      }
    }

    this.z8ParameterDataService.deleteItem(itemNo);
    this.searchForm.reset();
    this.clearAdvanceFilter();
  }

  getItemKey(item: Z8ParameterContent) {
    return `${item.storeCode === 'ALL' ? 'All Stores' : item.storeCode}_${this.translate.instant(
      'Z8_PARAMETER_REQUEST.PRODUCT_LEVEL.' + item.productLevel
    )}_${item.productValue.code}`;
  }

  search() {
    this.z8ParameterDataService.searchData(
      this.searchForm.value.searchCriteria,
      this.searchForm.value.startDate,
      this.searchForm.value.endDate
    );
  }

  onSearchSubmit() {
    this.search();
  }

  clearLastKeyUpSearchText(event) {
    if (!event.target.value) {
      this.search();
    }
  }

  clearSearchText() {
    this.searchForm.controls['searchCriteria'].reset();
    this.search();
  }

  clearFilterDate() {
    this.searchForm.controls['startDate'].reset();
    this.searchForm.controls['endDate'].reset();

    this.dateTag = null;
    this.dateStringTag = null;

    this.search();
  }

  clearAdvanceFilter() {
    this.clearFilterDate();
  }

  getParameterFactor() {
    const defaultParameter = {
      parameterName: 'Default',
      decimals: 0
    };

    const paramFactor = this.parameterFactor
      ? this.parameterFactor.find(item => item.parameterName === this.parameterInfo.name)
      : null;

    return paramFactor ? paramFactor : defaultParameter;
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = null;
    }
  }

  isApplySearchFilter() {
    return Object.values(this.searchForm.value).some(x => x !== null && x !== '');
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;

    const data = this.searchForm.getRawValue();

    const startDate = dateStringToTagCriteria(data.startDate);
    const endDate = dateStringToTagCriteria(data.endDate);

    if (startDate || endDate) {
      if (startDate && endDate) {
        this.dateStringTag = `Date`;
        this.dateTag = `"${startDate} - ${endDate}"`;
      } else if (startDate) {
        this.dateStringTag = `Start Date`;
        this.dateTag = `"${startDate}"`;
      } else if (endDate) {
        this.dateStringTag = `End Date`;
        this.dateTag = `"${endDate}"`;
      }
    }
  }

  onAdvanceSubmit() {
    this.prepareSearchCriteriaTags();
    this.search();

    this.isShowAdvanceSearch = false;
  }

  onSubmit() {}

  doAfterVersionAlertModal() {}

  loadMasterList() {
    combineLatest([
      this.listOfValue$,
      this.storeList$.pipe(
        map(v => {
          const arr = v.content.map(item => {
            return {
              storeNo: item.no,
              storeCode: item.code,
              storeCodeName: item.storeCodeName
            };
          });
          // [TODO]: @Pansakorn Refactor
          return _.keyBy(arr, 'storeCode');
        })
      ),
      this.articleList$.pipe(
        map(v => {
          const arr = v.map(item => {
            return {
              code: item.articleNo,
              name: item.articleNoProductName
            };
          });
          // [TODO]: @Pansakorn Refactor
          return _.keyBy(arr, 'code');
        })
      )
    ]).subscribe(([segment, storeList, articleList]) => {
      const families: Array<Families> = this.flatArray(segment.data.segments.map(v => v.families).filter(Boolean));
      this.listOfValue['familyCodes'] = this.getObjectWithKeys(families);

      const classCodes = this.flatArray(families.map(v => v.classCodes).filter(Boolean));
      this.listOfValue['classCodes'] = this.getObjectWithKeys(classCodes);

      const subclasses = this.flatArray(classCodes.map(v => v.subClasses).filter(Boolean));
      this.listOfValue['subclasses'] = this.getObjectWithKeys(subclasses);

      this.storeList = storeList;

      this.articleList = articleList;

      this.isLoading = false;
    });
  }

  getObjectWithKeys(array) {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item['code']]: {
          code: item.code,
          name: `${item.code}-${item.nameEn}`
        }
      };
    }, initialValue);
  }

  flatArray(array) {
    return array.reduce((a, b) => a.concat(b), []);
  }

  alertMessage(data: { title: string; message: string }) {
    this.modalService.show(AlertModalComponent, {
      keyboard: false,
      backdrop: 'static',
      initialState: {
        title: data.title,
        message: data.message
      }
    });
  }
}
