import { RequestAdjustItemErrorEnum } from '../enum/manage-stock.emun';
import { ArticlesContent } from './articles.model';
import { BaseReport } from './base-report.model';

export class StockAdjustSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  locationType?: string[];
  adjustType?: string[];
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class StockAdjustPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: StockAdjustContent[];
}

export class StockAdjustContent {
  id: string;
  requestNo: string;
  locationCode: string;
  locationName: string;
  requester: string;
  status: string;
  locationType: string;
  requestedDate: string;
  createdByName: string;
  createdDate: string;
}

export class AdjustStockRequest {
  adjustType: string;
  refNo: string;
  locationType: string;
  location: string;
  items: AdjustItem[];
}

export class AdjustStockResponse {
  id: string;
  version: number;
  docNo: string;
  items?: AdjustItem[];
  location: string;
  locationType?: string;
  adjustType: string;
  status: string;
  refNo: string;
  createdDate: Date | string;
  createdBy: string;
  createdByName: string;
  lastModifiedBy: string;
  lastModifiedByName: string;
  lastModifiedDate: Date | string;
}

export class AdjustStockItemCriteria {
  page: number;
  size: number;
  sortBy?: string;
  sortOrder?: string;
}

export class AdjustStockItemPagination {
  page: number;
  size: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
  totalPages: number;
  hasContent: boolean;
  last: boolean;
  empty: boolean;
  content: AdjustStockItemContent[];
}

export class AdjustStockItemContent {
  id?: string;
  articleNo: string;
  productName: string;
  adjustStock: number;
  unit: string;
  unitFactor: string;
  reason: string;
}

export class AdjustStockResponseError {
  code: string;
  message: string;
  items: ItemsErrorList[];
}

export class ItemsErrorList {
  articleNo: string;
  errorMessage: RequestAdjustItemErrorEnum;
}

export class AdjustItem {
  id?: string;
  articleNo: string;
  productName: string;
  adjustStock: number;
  unit: string;
  unitFactor: string;
  reason: string;
  barcode?: string;
  productStatus?: string;
  productType?: string;
  errorMessage?: RequestAdjustItemErrorEnum;

  public static mappingArticleResponseToProductItem(response: ArticlesContent): AdjustItem {
    return {
      ...response,
      adjustStock: null,
      reason: null,
      errorMessage: null
    } as AdjustItem;
  }
}

export class ExportAdjustCriteria extends BaseReport {
  createdDateFrom: string;
  createdDateTo: string;

  constructor(init?: Partial<ExportAdjustCriteria>) {
    super();
    Object.assign(this, init);
  }
}

export interface ImportAdjustItem {
  articleNo: string;
  barcode: string;
  productName: string;
  adjustStock: number;
  reason: string;
  unit: string;
  unitFactor: number;
}

export class RequestImportAdjustStock {
  items: ImportAdjustItem[];
  location: string;
  locationType: string;
  adjustType: string;
  refNo: string;
}

export class ResponseImportAdjustStock {
  items: ImportAdjustItem[];
  location: string;
  locationType: string;
  adjustType: string;
  refNo: string;
}
