import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../base/base.component';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import {
  Box,
  ProductStatusEnum,
  StockInformationDto,
  StockInformationSearchCriteria,
  StockInformationTabsEnum,
  StockInfoType,
  StockLocation
} from '../../../shared/models';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import {
  StockInformationRequestAction,
  StockInformationResetAction
} from '../../../shared/store/actions/stock-information.actions';
import {
  selectStockInformation,
  selectStockInformationListCriteria
} from '../../../shared/store/selectors/stock-information.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-stock-information',
  templateUrl: './stock-information.component.html',
  styleUrls: ['./stock-information.component.scss']
})
export class StockInformationComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Input() data: {
    title: string;
    warehouseCode: string;
    articleNo: string;
    articleName: string;
    productType: string;
    stockLocation: StockLocation;
  };

  public stockInformationTabsEnum = StockInformationTabsEnum;
  public currentTab: StockInformationTabsEnum;
  private localStore: Observable<any>;
  public stockInformation: StockInformationDto;
  public listBox: any[];
  public criteriaObject: StockInformationSearchCriteria;
  public stockLocation = StockLocation;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.currentTab =
      this.data.stockLocation === StockLocation.ALL_LOCATIONS
        ? StockInformationTabsEnum.WAREHOUSE
        : StockInformationTabsEnum.MOVEMENT;
    this.initData();
    this.initState();

    this.localStore
      .pipe(select(selectStockInformationListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  initData() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.listBox = [];
  }

  initState() {
    this.store.dispatch(
      new StockInformationRequestAction({ warehouse: this.data.warehouseCode, articleNo: this.data.articleNo })
    );

    this.localStore.pipe(select(selectStockInformation)).subscribe(data => {
      if (data) {
        this.stockInformation = data;

        this.mappingStockDetail();
      }
    });
  }

  mappingStockDetail() {
    const stockInfo = this.getStockInfo();
    const infoList = this.getBoxOrdering();
    this.listBox = infoList.map(list => {
      return list.map(v => stockInfo.find(val => val.title === v) || []);
    });
  }

  getStockInfo(): Box[] {
    return [
      {
        title: 'Warehouse-Available',
        value: this.stockInformation.warehouse,
        className: 'blue',
        icon: 'icon-warehouse'
      },
      {
        title: 'Hold',
        value: this.stockInformation.hold,
        icon: 'icon-deactivate'
      },
      {
        title: 'Reserved',
        value: this.stockInformation.reserved,
        icon: 'icon-reserved'
      },
      {
        title: 'On Delivery',
        value: this.stockInformation.inTransit,
        className: 'orange',
        icon: 'icon-shipping'
      },
      {
        title: 'Store-Available',
        value: this.stockInformation.store,
        icon: 'icon-td-store'
      },
      {
        title: 'To be adjusted',
        value: this.stockInformation.toBeAdjusted,
        icon: 'icon-adjust'
      },
      {
        title: 'Total Stock',
        value: this.stockInformation.quantity,
        className: 'green',
        icon: 'icon-stock'
      },
      {
        title: 'Warehouse Stock',
        value: this.stockInformation.warehouseStock,
        icon: 'icon-warehouse'
      },
      {
        title: 'Store Stock',
        value: this.stockInformation.storeStock,
        icon: 'icon-td-store'
      }
    ] as Box[];
  }

  getBoxOrdering(): Array<Array<string>> {
    let list = [];
    switch (this.data.stockLocation) {
      case StockLocation.DC:
        list = [
          [
            StockInfoType.WAREHOUSE_AVAILABLE,
            StockInfoType.HOLD,
            StockInfoType.RESERVED,
            StockInfoType.TOTAL_STOCK,
            StockInfoType.ON_DELIVERY
          ]
        ];
        break;
      case StockLocation.ALL_STORES:
        list = [[StockInfoType.STORE_AVAILABLE, StockInfoType.TO_BE_ADJUSTED, StockInfoType.TOTAL_STOCK]];
        break;
      case StockLocation.ALL_LOCATIONS:
        const inventory = [
          [
            StockInfoType.WAREHOUSE_AVAILABLE,
            StockInfoType.HOLD,
            StockInfoType.RESERVED,
            StockInfoType.WAREHOUSE_STOCK,
            StockInfoType.STORE_AVAILABLE,
            StockInfoType.TO_BE_ADJUSTED,
            StockInfoType.STORE_STOCK
          ],
          [StockInfoType.ON_DELIVERY, StockInfoType.TOTAL_STOCK]
        ];
        const fixedAssetStoreUsed = [
          [
            StockInfoType.WAREHOUSE_AVAILABLE,
            StockInfoType.HOLD,
            StockInfoType.RESERVED,
            StockInfoType.WAREHOUSE_STOCK,
            StockInfoType.ON_DELIVERY,
            StockInfoType.TOTAL_STOCK
          ]
        ];

        list = this.data.productType === ProductTypeEnum.INVENTORY ? inventory : fixedAssetStoreUsed;
        break;
      default:
    }
    return list;
  }

  doAfterVersionAlertModal() {}

  onExit() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  getColorStatus(status: any) {
    return ProductStatusEnum[status].toLowerCase();
  }

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  onSelectTabs(tabName: StockInformationTabsEnum) {
    this.currentTab = tabName;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StockInformationResetAction());
    super.unsubscribeBase();
  }

  getStockStorePermission() {
    return (
      this.data.stockLocation === StockLocation.ALL_STORES ||
      (this.data.stockLocation === StockLocation.ALL_LOCATIONS && this.data.productType === ProductTypeEnum.INVENTORY)
    );
  }
}
