import { createSelector } from '@ngrx/store';
import * as fromSupplierGroup from '../reducers/supplier-group.reducers';
import { AppStates } from '../state/app.states';

const selectSupplierGroupFromState = (state: AppStates) => state.supplierGroup;

export const selectSupplierGroupList = createSelector(selectSupplierGroupFromState, fromSupplierGroup.selectAll);

export const selectSupplierGroupState = createSelector(
  selectSupplierGroupFromState,
  (state: fromSupplierGroup.SupplierGroupState) => state
);

export const selectSupplierGroup = createSelector(selectSupplierGroupFromState, state => {
  return state.supplierGroup;
});
