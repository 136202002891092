import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AssortmentAllType,
  AssortmentPageModes,
  ErrorResponse,
  ProductAssortmentList,
  ProductAssortmentSearchCriteria,
  ProductStatusEnum,
  RouteLinkTab
} from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService, ProductAssortmentService, RequestAssortmentService } from '../../../shared/services';
import { ProductAssortmentSearchRequested } from '../../../shared/store/actions/product-assortment.actions';
import { PriceSettingRequested } from '../../../shared/store/actions/setting.actions';
import { ProductAssortmentState } from '../../../shared/store/reducers/product-assortment.reducers';
import {
  selectProductAssortment,
  selectProductAssortmentList
} from '../../../shared/store/selectors/product-assortment.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { AssortmentRequestComponent } from '../../assortment-request/assortment-request/assortment-request.component';

enum AdvanceFilterFormNameEnum {
  SEGMENT = 'segment',
  FAMILY = 'family',
  CLASS_CODE = 'classCode',
  SUB_CLASS = 'subClass',
  PRODUCT_TYPE = 'productType',
  ORDERING_METHOD = 'orderingMethods',
  DATE_FORM = 'lastUpdatedDateFrom',
  DATE_TO = 'lastUpdatedDateTo'
}

@Component({
  selector: 'app-assortment-product',
  templateUrl: './assortment-product-list.component.html',
  styleUrls: ['./assortment-product-list.component.scss']
})
export class AssortmentProductListComponent
  extends BaseSearchComponent<ProductAssortmentSearchCriteria, ProductAssortmentList, ProductAssortmentState>
  implements OnInit, OnDestroy {
  assortmentAllType = AssortmentAllType;
  advanceFilterFormName = AdvanceFilterFormNameEnum;

  segmentTag: string;
  segmentStringTag: string;
  familyTag: string;
  familyStringTag: string;
  classCodeTag: string;
  classCodeStringTag: string;
  subClassTag: string;
  subClassStringTag: string;
  orderingMethodTag: string;
  orderingMethodStringTag: string;
  productTypeTag: string;
  productTypeStringTag: string;
  submittedExport: boolean;

  dateTag: string;
  dateStringTag: string;
  isShowAdvanceSearch: boolean;

  searchForm: FormGroup;
  exportForm: FormGroup;
  criteriaObject: ProductAssortmentSearchCriteria;

  hasViewPermission = false;
  hasEditPermission = false;
  hasApprovePermission = false;
  hasMerchandisePermission = ['tdassort_inv_v', 'tdassort_inv_app', 'tdassort_inv_m'];
  hasNonMerchandisePermission = [
    'tdassort_asset_v',
    'tdassort_asset_app',
    'tdassort_asset_m',
    'tdassort_sto_v',
    'tdassort_sto_app',
    'tdassort_sto_m'
  ];

  listOfValue = {};
  productTypeSelect: NgOption[];
  orderingMethodSelect: NgOption[];
  productAssortmentStatus: NgOption[];
  exportProductAssortmentStatus: NgOption[];

  bsModalRef: BsModalRef;

  listRoute: Array<RouteLinkTab>;
  errorExport: string;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  private readonly types = [
    this.assortmentAllType.SEGMENT,
    this.assortmentAllType.FAMILY,
    this.assortmentAllType.CLASS,
    this.assortmentAllType.SUBCLASS
  ];

  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;
  deliveryOrderService: any;
  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected router: Router,
    private readonly translate: TranslateService,
    private productAssortmentService: ProductAssortmentService,
    private requestAssortmentService: RequestAssortmentService
  ) {
    super(store, modalService, selectProductAssortmentList, selectProductAssortment);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    // Check permission

    this.hasViewPermission = this.authGuardService.checkPermission([
      'tdassort_inv_v',
      'tdassort_asset_v',
      'tdassort_sto_v'
    ]);
    this.hasEditPermission = this.authGuardService.checkPermission([
      'tdassort_inv_m',
      'tdassort_asset_m',
      'tdassort_sto_m'
    ]);
    this.hasApprovePermission = this.authGuardService.checkPermission([
      'tdassort_inv_app',
      'tdassort_asset_app',
      'tdassort_sto_app'
    ]);

    this.loadSegmentList();
    this.setDropdownDefault();
    this.createForm();
    this.createExportForm();
    this.setInitialCriteriaObject();
    this.setFirstPage();
    this.setRouteTab();

    // Load for child component
    this.store.dispatch(new PriceSettingRequested());
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
  }

  doDestroy() {}

  loadSegmentList() {
    let productType = null;
    if (
      this.authGuardService.checkPermission(this.hasNonMerchandisePermission) &&
      this.authGuardService.checkPermission(this.hasMerchandisePermission)
    ) {
      productType = null;
    } else if (this.authGuardService.checkPermission(this.hasNonMerchandisePermission)) {
      productType = 'N';
    } else if (this.authGuardService.checkPermission(this.hasMerchandisePermission)) {
      productType = 'M';
    }

    this.productAssortmentService
      .getSegmentListData(productType)
      .pipe(untilComponentDestroyed(this))
      .subscribe(data => {
        this.listOfValue[this.assortmentAllType.SEGMENT] = data.data.segments;
      });
  }

  setDropdownDefault() {
    this.productTypeSelect = filterDropdown.productTypeLOV;
    this.orderingMethodSelect = filterDropdown.orderingMethodFilter;
    this.productAssortmentStatus = filterDropdown.productAssortmentStatus;
    this.exportProductAssortmentStatus = filterDropdown.productAssortmentStatus.filter(status => status.value);
  }

  onChangeSelectDropdown($event, assortmentType: AssortmentAllType, isAdvanceSearch: boolean) {
    if (assortmentType) {
      this.clearFormSelectDropdown(assortmentType, isAdvanceSearch);
      if (this.assortmentAllType.SEGMENT === assortmentType) {
        this.listOfValue[this.assortmentAllType.FAMILY] = $event.families;
      } else if (this.assortmentAllType.FAMILY === assortmentType) {
        this.listOfValue[this.assortmentAllType.CLASS] = $event.classCodes;
      } else if (this.assortmentAllType.CLASS === assortmentType) {
        this.listOfValue[this.assortmentAllType.SUBCLASS] = $event.subClasses;
      }
    }
  }

  clearFormSelectDropdown(selectedType, isAdvanceSearch: boolean) {
    const associateLv = this.types.indexOf(selectedType) + 1;
    this.types
      .filter(val => associateLv <= val.length)
      .slice(associateLv)
      .forEach(typeName => {
        if (isAdvanceSearch) {
          this.searchForm.controls[typeName].reset();
        } else {
          this.exportForm.controls[typeName].reset();
        }
        this.listOfValue[typeName] = [];
      });
  }

  createForm() {
    this.submittedExport = false;
    this.searchForm = this.fb.group({
      searchCriteria: [null, Validators.required],
      productStatus: [''],
      lastUpdatedDateFrom: [null],
      lastUpdatedDateTo: [null],
      segment: [null],
      family: [null],
      classCode: [null],
      subClass: [null],
      productType: [null],
      orderingMethods: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      segment: [null],
      family: [null],
      classCode: [null],
      subClass: [null],
      productType: [null],
      orderingMethods: [null],
      productStatuses: [null]
    });
  }

  openExportModal() {
    this.exportModal.show();
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      productStatus: formValue.productStatus,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.lastUpdatedDateFrom &&
      !formValue.lastUpdatedDateTo &&
      !formValue.segment &&
      !formValue.family &&
      !formValue.classCode &&
      !formValue.subClass &&
      !formValue.orderingMethods &&
      !formValue.productType
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
    this.setFirstPage();
    let beginLastUpdate = formValue.lastUpdatedDateFrom;
    let endLastUpdate = formValue.lastUpdatedDateTo;
    if (beginLastUpdate && !isNaN(beginLastUpdate.getTime())) {
      beginLastUpdate = dateToStringCriteria(beginLastUpdate, true);
    } else {
      beginLastUpdate = null;
    }

    if (endLastUpdate && !isNaN(endLastUpdate.getTime())) {
      endLastUpdate = dateToStringCriteria(endLastUpdate, false);
    } else {
      endLastUpdate = null;
    }

    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      segment: formValue.segment,
      family: formValue.family,
      classCode: formValue.classCode,
      subClass: formValue.subClass,
      productType: formValue.productType,
      orderingMethods: formValue.orderingMethods,
      lastUpdatedDateFrom: beginLastUpdate,
      lastUpdatedDateTo: endLastUpdate
    };

    this.doSearch(this.criteriaObject);
  }

  clearDate() {
    this.setFirstPage();
    this.searchForm.controls[AdvanceFilterFormNameEnum.DATE_FORM].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.DATE_TO].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null
    };

    this.doSearch(this.criteriaObject);
  }

  clearAdvanceSearch(formName: string) {
    this.setFirstPage();
    this.searchForm.controls[formName].reset();

    if (
      formName === AdvanceFilterFormNameEnum.SEGMENT ||
      formName === AdvanceFilterFormNameEnum.FAMILY ||
      formName === AdvanceFilterFormNameEnum.CLASS_CODE ||
      formName === AdvanceFilterFormNameEnum.SUB_CLASS
    ) {
      this.clearFormSelectDropdown(formName, true);
    }

    const formValue = this.searchForm.getRawValue();
    this.criteriaObject = {
      ...this.criteriaObject,
      [formName]: null,
      segment: formValue.segment,
      family: formValue.family,
      classCode: formValue.classCode,
      subClass: formValue.subClass,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  clearAdvanceSearchAll() {
    this.setFirstPage();
    this.searchForm.controls[AdvanceFilterFormNameEnum.DATE_FORM].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.DATE_TO].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.SEGMENT].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.FAMILY].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.CLASS_CODE].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.SUB_CLASS].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.ORDERING_METHOD].reset();
    this.searchForm.controls[AdvanceFilterFormNameEnum.PRODUCT_TYPE].reset();

    this.criteriaObject = {
      ...this.criteriaObject,
      segment: null,
      family: null,
      classCode: null,
      subClass: null,
      orderingMethods: null,
      productType: null,
      lastUpdatedDateFrom: null,
      lastUpdatedDateTo: null,
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      productStatus: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangePage(event: any) {
    this.currentPage = event.page;
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.segmentTag = null;
    this.segmentStringTag = null;
    this.familyTag = null;
    this.familyStringTag = null;
    this.classCodeTag = null;
    this.classCodeStringTag = null;
    this.subClassTag = null;
    this.subClassStringTag = null;
    this.productTypeTag = null;
    this.productTypeStringTag = null;
    this.orderingMethodTag = null;
    this.orderingMethodStringTag = null;
    this.dateTag = null;
    this.dateStringTag = null;

    const lastUpdatedDateFrom = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateFrom);
    const lastUpdatedDateTo = dateStringToTagCriteria(this.criteriaObject.lastUpdatedDateTo);
    const lastUpdatedDate = generateDateStringTag({
      dateName: 'Last Updated Date',
      dateFrom: lastUpdatedDateFrom,
      dateTo: lastUpdatedDateTo
    });

    this.dateStringTag = lastUpdatedDate.dateStringTag;
    this.dateTag = lastUpdatedDate.dateTag;

    if (this.criteriaObject.segment) {
      this.segmentStringTag = 'Segment';
      this.segmentTag = `"${
        this.listOfValue[this.assortmentAllType.SEGMENT].find(obj => obj.code === this.criteriaObject.segment).nameEn
      }"`;
      if (this.criteriaObject.family) {
        this.familyStringTag = 'Family';
        this.familyTag = `"${
          this.listOfValue[this.assortmentAllType.FAMILY].find(obj => obj.code === this.criteriaObject.family).nameEn
        }"`;
        if (this.criteriaObject.classCode) {
          this.classCodeStringTag = 'Class';
          this.classCodeTag = `"${
            this.listOfValue[this.assortmentAllType.CLASS].find(obj => obj.code === this.criteriaObject.classCode)
              .nameEn
          }"`;
          if (this.criteriaObject.subClass) {
            this.subClassStringTag = 'Sub Class';
            this.subClassTag = `"${
              this.listOfValue[this.assortmentAllType.SUBCLASS].find(obj => obj.code === this.criteriaObject.subClass)
                .nameEn
            }"`;
          }
        }
      }
    }

    if (this.criteriaObject.orderingMethods && this.criteriaObject.orderingMethods.length > 0) {
      this.orderingMethodStringTag = 'Ordering Method';
      const orderingMethodList = filterDropdown.orderingMethodFilter
        .filter(data => this.criteriaObject.orderingMethods.indexOf(data.value) > -1)
        .map(data => data.label)
        .join(', ');
      this.orderingMethodTag = `"${orderingMethodList}"`;
    }

    if (this.criteriaObject.productType && this.criteriaObject.productType.length > 0) {
      this.productTypeStringTag = 'Product Type';
      const productTypeList = filterDropdown.productTypeLOV
        .filter(data => this.criteriaObject.productType.indexOf(data.value) > -1)
        .map(data => data.label)
        .join(', ');
      this.productTypeTag = `"${productTypeList}"`;
    }
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  getColorStatus(status: ProductStatusEnum): string {
    return ProductStatusEnum[status].toLowerCase();
  }

  goToView(assortment) {
    const initialState = {
      childItem: new ChildItem(
        AssortmentRequestComponent,
        {
          title: 'View Product',
          articleNo: assortment.articleNo,
          articleType: assortment.articleType,
          mode: AssortmentPageModes.ASSORTMENT_VIEW,
          productType: assortment.productType
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState
    });
    return;
  }

  goToEdit(assortment) {
    this.requestAssortmentService
      .checkProductRequestExists(assortment.articleNo)
      .pipe(
        untilComponentDestroyed(this),
        map(() => {
          this.modalService.show(AlertModalComponent, {
            initialState: {
              title: 'Failed',
              message: 'This product has been edited and waiting for approval.'
            }
          });
          return false;
        }),
        catchError(() => {
          return of(true);
        })
      )
      .subscribe(isAllowToggleEdit => {
        if (isAllowToggleEdit) {
          const initialState = {
            childItem: new ChildItem(
              AssortmentRequestComponent,
              {
                title: 'Edit Product',
                articleNo: assortment.articleNo,
                articleType: assortment.articleType,
                mode: AssortmentPageModes.ASSORTMENT_EDIT,
                productType: assortment.productType
              },
              true
            )
          };

          this.bsModalRef = this.modalService.show(FullModalComponent, {
            animated: false,
            backdrop: false,
            keyboard: false,
            initialState
          });
          return;
        }
      });
  }

  setRouteTab() {
    this.listRoute = [];

    if (this.hasViewPermission || this.hasEditPermission) {
      this.listRoute.push({ tabName: 'Product List', url: '/products/product-list' });
    }

    // Add request list tabs in case it has permission to edit
    if (this.hasEditPermission || this.hasApprovePermission) {
      this.listRoute.push({ tabName: 'Product Request', url: '/products/request-list' });
    }
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new ProductAssortmentSearchRequested(criteriaObj));
  }

  onExport() {
    this.submittedExport = true;
    const formData = this.exportForm.getRawValue();
    this.productAssortmentService.exportAssortment(formData).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, this.getGeneratedFileName());
        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  getGeneratedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportAssortment.timeFormat);
    return `${environment.fileName.exportAssortment.prefix}${formattedDate}.xlsx`;
  }

  resetExportForm() {
    this.exportForm.reset();
    this.errorExport = null;
  }

  clearErrorText() {
    this.errorExport = null;
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code === '00001') {
        this.errorExport = initialState.message;
      } else {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }
}
