<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    View Z8 Parameter Request
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ data.parameterInfo.requestNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus(data.parameterInfo.status)" class="request-status">
          {{ 'Z8_PARAMETER_REQUEST.STATUS.' + data.parameterInfo.status | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="information-container">
    <div class="row merchant-info">
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Parameter Name</span>
          <span class="info-header-value">{{ data.parameterInfo.parameterName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Parameter Type</span>
          <span class="info-header-value">{{ data.parameterInfo.parameterType | titlecase }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Requested By</span>
          <span class="info-header-value">{{ data.parameterInfo.requestedByName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Requested Date</span>
          <span class="info-header-value">{{
            data.parameterInfo.requestedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <app-view-parameter-list
    [guid]="data.parameterInfo.guid"
    [parameterName]="data.parameterInfo.parameterName"
    [viewOnly]="true"
  ></app-view-parameter-list>
</div>
