export class DropdownMasterRequest {
  type: string[];
  articleType?: string;
}

export class MasterData {
  id?: string;
  code: string;
  name?: string;
  nameEn?: string;
  nameTh?: string;
  type?: any; // TODO:: Update this type again after Backend team finish the refactoring. Type are AssortmentAllType and MerchantSelectValueEnum.
  associate: Array<Associate>;
}

export class Associate {
  type: AssortmentAllType;
  codes: Array<string>;
}

export enum AssortmentGroup {
  CATALOG = 'catalog',
  TYPE = 'type'
}

export enum AssortmentAllType {
  SEGMENT = 'segment',
  FAMILY = 'family',
  CLASS = 'classCode',
  SUBCLASS = 'subClass',
  GRADINGGROUP = 'gradingGroup',
  CATLV1 = 'catlv1',
  CATLV2 = 'catlv2',
  UNIT = 'unit',
  UOM = 'uom'
}
