import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ConditionsService } from '../../services/z8-conditions.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import { Z8ConditionsActionType, Z8ConditionsListResponseAction } from '../actions/z8-conditions.actions';
export class Z8ConditionsEffects {
    constructor(actions$, z8ConditionsService, logger) {
        this.actions$ = actions$;
        this.z8ConditionsService = z8ConditionsService;
        this.logger = logger;
        this.searchZ8Conditions$ = this.actions$.pipe(ofType(Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST), map(action => {
            this.logger.debug(`@Effect ${Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST}: ` +
                Z8ConditionsEffects.stringify(action.payload));
            return action.payload;
        }), switchMap(payload => {
            return this.z8ConditionsService.searchByCriteria(payload).pipe(map(response => {
                return new Z8ConditionsListResponseAction(response);
            }), catchError(error => {
                return of(new LayoutActionLoadError(error));
            }));
        }));
    }
    static stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], Z8ConditionsEffects.prototype, "searchZ8Conditions$", void 0);
