import { Action } from '@ngrx/store';
import { ErrorResponse, MasterData, MerchantSelectValueRequest } from '../../models';

export enum MerchantSelectValueActionType {
  MERCHANT_SELECT_VALUE_REQUEST = '[Merchant Select Value] Merchant Select Value Request',
  MERCHANT_SELECT_VALUE_RESPONSE = '[Merchant Select Value] Merchant Select Value Response',
  MERCHANT_SELECT_VALUE_ERROR = '[Merchant Select Value] Merchant Select Value Error'
}

export class MerchantSelectValueRequestAction implements Action {
  readonly type = MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_REQUEST;

  constructor(public payload: MerchantSelectValueRequest) {}
}

export class MerchantSelectValueResponseAction implements Action {
  readonly type = MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_RESPONSE;
  constructor(public payload: MasterData[]) {}
}

export class MerchantSelectValueErrorAction implements Action {
  readonly type = MerchantSelectValueActionType.MERCHANT_SELECT_VALUE_ERROR;
  constructor(public payload: ErrorResponse) {}
}

export type MerchantSelectValueActions =
  | MerchantSelectValueRequestAction
  | MerchantSelectValueResponseAction
  | MerchantSelectValueErrorAction;
