<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-history-header">
    <span>{{ historyHeader }}</span>
  </div>
  <div class="modal-body history-modal-body">
    <div class="row mt-3">
      <div class="col-4 history-list-header">
        Edit Date
      </div>
      <div class="col-4 history-list-header">
        Activity
      </div>
      <div class="col-4 history-list-header">
        Edit By
      </div>
    </div>
    <div
      class="row"
      *ngFor="let auditLog of auditLogs ? auditLogs : (auditLogs$ | async); index as i"
      [ngClass]="{ 'mt-2': i !== 0 }"
    >
      <div class="col-4 history-list-text">
        {{ auditLog.editDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay }}
      </div>

      <div class="col-4 history-list-text" *ngIf="auditLog.activity; else activities">
        <div *ngIf="action === 'REQUEST'; else notRequestType">
          {{ historyType + '.HISTORY_ACTIVITY.' + auditLog.activity | translate }}
        </div>
        <ng-template #notRequestType>
          <div>
            {{ auditLog.activity }}
          </div>
        </ng-template>
        <div class="history-list-desc" *ngIf="auditLog.description">- {{ auditLog.description }}</div>
        <div class="history-list-desc-container" *ngIf="auditLog.descriptions">
          <div *ngFor="let description of auditLog.descriptions; index as indexOfDescription">
            <ng-container *ngIf="description"
              ><span class="history-list-desc"> - {{ description }}</span></ng-container
            >
          </div>
        </div>
      </div>

      <!--Template for list activity-->
      <ng-template #activities>
        <div class="col-4 history-list-text">
          <div *ngFor="let activity of auditLog.activities; index as indexOfActivity">
            {{ activity.activity }}
            <div class="history-list-desc-container" *ngIf="activity.descriptions">
              <div *ngFor="let description of activity.descriptions; index as indexOfDescription">
                <ng-container *ngIf="description"
                  ><span class="history-list-desc"> - {{ description }}</span></ng-container
                >
              </div>
            </div>
          </div>
        </div>
      </ng-template>
      <!--End Template for list activity-->

      <div class="col-4 history-list-text">
        <span>{{ auditLog.editByName }}</span>
      </div>
    </div>
  </div>
</div>
