import gql from 'graphql-tag';

export class ClassCodesBaseMasterData {
  id?: string;
  code: string;
  nameEn?: string;
  nameTh?: string;
}

export class ClassCodesSegments extends ClassCodesBaseMasterData {
  families: ClassCodesFamilies[];
}

export class ClassCodesFamilies extends ClassCodesBaseMasterData {
  classCodes: ClassCodesBaseMasterData[];
}

export class ClassCodesProductMasterData {
  segments: ClassCodesSegments[];
}

export const classCodesProductsMasterQuery = gql`
  query masterData($articleType: String!) {
    segments(articleType: $articleType) {
      families {
        classCodes {
          id
          code
          nameEn
          nameTh
        }
      }
    }
  }
`;
