import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { ProductTypeEnum } from '../../../../shared/enum/product-type.enum';
import { BarcodeNameSearchCriteria, BarcodeResponse } from '../../../../shared/models/barcode.model';
import { BarcodeService } from '../../../../shared/services';
import { StoreAssortmentService } from '../../../../shared/services/store-assortment.service';

@Component({
  selector: 'app-export-by-product',
  templateUrl: './export-by-product.component.html',
  styleUrls: ['./export-by-product.component.scss']
})
export class ExportByProductComponent implements OnInit {
  public submitted: boolean;
  public form: FormGroup;
  public productList: Observable<BarcodeResponse[]>;
  public productSearchLoading = false;
  public productSearchInput$ = new Subject<string>();
  public errorExport: string;
  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly barcodeService: BarcodeService,
    private readonly storeAssortmentService: StoreAssortmentService
  ) {}

  ngOnInit() {
    this.loadProduct('');
    this.form = this.fb.group({
      product: [null, [Validators.required]]
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const fileConfig = environment.fileName.exportStoreAssortment;
    const dateExport = moment().format(fileConfig.timeFormat);
    const formData = this.form.getRawValue();

    this.storeAssortmentService.exportByProduct({ barcode: formData.product }).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      error => {
        this.errorExport = error.error.message;
      },
      () => this.closeModal()
    );
  }

  onBlurProduct() {
    if (!this.form.controls.product.value) {
      this.loadProduct('');
    }
  }

  loadProduct(initialTerm: string) {
    this.productList = concat(
      of([]),
      this.productSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.productSearchLoading = true)),
        switchMap(term => {
          return this.barcodeService
            .searchBarcodeByName(
              new BarcodeNameSearchCriteria({
                searchCriteria: term,
                allowRestrictItem: true,
                productType: this.productType
              })
            )
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.productSearchLoading = false;
              })
            );
        })
      )
    );
  }
}
