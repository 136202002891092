<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="info-header-container" *ngIf="adjustStockView$ | async">
    <div class="info-header-item" *ngIf="(adjustStockView$ | async)?.docNo">
      <div class="info-header-label">
        <span>Stock Adjustment No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (adjustStockView$ | async)?.docNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Reference No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (adjustStockView$ | async)?.refNo }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="(adjustStockView$ | async)?.status">
      <div class="info-header-label">
        <span>Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((adjustStockView$ | async)?.status)" class="request-status">
          {{ 'STOCK.STATUS.' + (adjustStockView$ | async)?.status | translate | titlecase }}</span
        >
      </div>
    </div>
  </div>

  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Location Code</span>
          </div>
          <span class="info-header-value">
            {{ data.location.code }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Location Name</span>
          </div>
          <span class="info-header-value">
            {{ data.location.name }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Adjust Type</span>
          </div>
          <span class="info-header-value">
            {{ 'ADJUST_TYPE.' + (adjustStockView$ | async)?.adjustType | translate }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created By</span>
          </div>
          <span class="info-header-value">
            {{ (adjustStockView$ | async)?.createdByName ? (adjustStockView$ | async)?.createdByName : '(None)' }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created Date</span>
          </div>
          <span class="info-header-value">
            {{
              (adjustStockView$ | async)?.createdDate
                ? ((adjustStockView$ | async)?.createdDate | amFromUtc | amLocal | dateDisplay: dateFormatDisplay)
                : '(None)'
            }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-6"></div>
    <div class="col-md-6 d-flex justify-content-end align-items-end">
      <div class="row">
        <div class="col-md-12 mb-3"><span>UOM: Base Unit </span></div>
      </div>
    </div>

    <div class="col-md-12 mb-5">
      <ng-container *ngIf="(adjustStockItemState$ | async)?.totalElements > 0">
        <table id="inventoryTable" class="table table-striped table-bordered w-100">
          <thead>
            <tr>
              <th class="text-center w-2">{{ headerRow[0] }}</th>
              <th class="text-center w-5">{{ headerRow[1] }}</th>
              <th class="text-center w-10">{{ headerRow[2] }}</th>
              <th class="text-center w-10">{{ headerRow[3] }}</th>
              <th class="text-center w-10">{{ headerRow[4] }}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of resultAdjustStockItemList$ | async; index as i">
              <td class="text-center align-top">
                {{ (adjustStockItemState$ | async)?.criteriaObject.page * pageSize + (i + 1) }}
              </td>
              <td class="text-left align-top">
                {{ item.articleNo }}
              </td>
              <td class="text-left align-top">
                {{ item.productName }}
              </td>
              <td class="text-center align-top">
                {{ item.adjustStock }}
              </td>
              <td class="text-center align-top">
                {{ item.reason }}
              </td>
            </tr>
          </tbody>
        </table>

        <!--pagination-->
        <app-search-pagination
          [currentPage]="currentPage"
          [pageSize]="pageSize"
          [listState$]="adjustStockItemState$"
          (changePage)="onChangePage($event)"
          (changeRowPerPage)="onChangeRowPerPage($event)"
        >
        </app-search-pagination>
      </ng-container>
      <ng-container *ngIf="(adjustStockItemState$ | async).totalElements == 0">
        <div class="no-result-container text-center">
          <div class="d-flex justify-content-center no-data">
            <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
