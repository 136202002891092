import { Attribute, Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { environment as env } from '../../../environments/environment';

@Directive({
  selector: 'img[appSrc]'
})
export class AppSrcDirective implements OnInit, OnDestroy {
  @Attribute('img-error') public imgError: string;
  @Input() appSrc: string;

  private readonly nativeElement: HTMLElement;
  private fullSrcPath: string;

  constructor(private readonly renderer: Renderer2, private readonly el: ElementRef) {
    this.nativeElement = this.el.nativeElement;
  }

  ngOnInit() {
    this.initAttribute();
  }

  ngOnDestroy() {}

  private initAttribute() {
    this.nativeElement.onerror = () => this.onError();
    this.nativeElement.setAttribute('loading', 'auto');

    this.fullSrcPath = this.appSrc ? `${env.imgUrl}/${this.appSrc}` : './assets/images/no-image.png';
    this.nativeElement.setAttribute('src', this.fullSrcPath);
  }

  private onError() {
    const fullImagePath = this.imgError || './assets/images/no-image.png';

    this.nativeElement.setAttribute('src', fullImagePath);
  }

  private onLoad() {
    if (this.nativeElement.getAttribute('src') !== this.appSrc) {
      this.nativeElement.setAttribute('src', this.fullSrcPath);
    }
  }
}
