<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Select Store</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="parentForm" (ngSubmit)="submit()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Store<span class="text-danger">*</span></label>
            <app-dropdown-multiple-select
              [parentForm]="form"
              controlName="stores"
              [items]="storeList"
              [showSelectAll]="false"
              bindLabel="storeName"
              [showTotalItem]="false"
            >
            </app-dropdown-multiple-select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="closeModal()" id="product-modal-cancelBtn">
      Cancel
    </button>
    <button type="submit" class="btn btn-primary" id="product-modal-addBtn" (click)="submit()" appPreventDoubleClick>
      OK
    </button>
  </div>
</div>
