<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-store"></em>
    <h4>Merchant</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="filter-dropdown flex-item form-group ml-md-2">
        <ng-select
          [items]="merchantStatusList"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="status"
          (change)="onchangeStatus($event)"
        >
        </ng-select>
      </div>
      <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
        <button
          class="btn advance-filter-button btn-advance-filter"
          [ngClass]="{ active: isShowAdvanceSearch }"
          type="button"
          (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
        >
          <em class="icon-filter"></em>
          {{ 'ADVANCED_FILTERS' | translate }}
        </button>
        <div
          class="box-dropdown"
          [ngClass]="{ open: isShowAdvanceSearch }"
          (appClickOutside)="onClickedOutside($event)"
          [exclude]="['.btn-advance-filter', '.is-highlighted']"
        >
          <div class="box-dropdown-list advance-filter-dropdown">
            <div class="box-dropdown-body px-4">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="mb-2">
                      Created Date
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'FROM' | translate }}</label>
                      <input
                        id="startCreatedDate"
                        formControlName="startCreatedDate"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: dateFormat,
                          showWeekNumbers: false
                        }"
                        [maxDate]="maxDate"
                        (bsValueChange)="onChangeDateFrom($event)"
                        readonly
                      />
                      <label for="startCreatedDate" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>{{ 'TO' | translate }}</label>
                      <input
                        id="endCreatedDate"
                        formControlName="endCreatedDate"
                        type="text"
                        placeholder="{{ dateFormat }}"
                        autocomplete="off"
                        class="form-control calendar"
                        bsDatepicker
                        [bsConfig]="{
                          containerClass: 'theme-dark-blue',
                          dateInputFormat: dateFormat,
                          showWeekNumbers: false
                        }"
                        [minDate]="minDate"
                        (bsValueChange)="onChangeDateTo($event)"
                        readonly
                      />
                      <label for="endCreatedDate" class="icon-calendar btn-calendar"></label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="advance-filter-footer text-center">
              <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                Cancel
              </button>
              <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                {{ 'APPLY' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(pageMode.REQUEST_VIEW, result$.merchant)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ result$.merchantName }}
              </label>
              <span [ngClass]="getColorStatus(result$.status)" class="card-status merchant-status ml-auto">{{
                result$.status | titlecase
              }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Merchant ID:</span>
              <span class="result-value">{{ result$.merchant }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Tax ID/ ID card:</span>
              <span class="result-value">{{ result$.taxId }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Created By:</span>
              <span class="result-value">{{ result$.createdByName }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ result$.createdDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button
              type="button"
              class="btn btn-link"
              *ngIf="hasMerchantEditPermission()"
              (click)="handleEdit(result$.merchant)"
            >
              <em class="icon-edit"></em>Edit
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(result$.merchant)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
