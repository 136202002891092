import { createSelector } from '@ngrx/store';
import * as fromWarehouseState from '../reducers/warehouse.reducers';
import { AppStates } from '../state/app.states';

const selectWarehouseState = (state: AppStates) => state.warehouse;

export const selectAllWarehouses = createSelector(selectWarehouseState, fromWarehouseState.selectAll);

export const selectAllWarehouse = (type: string = null) =>
  createSelector(selectAllWarehouses, warehouses => {
    if (!type) {
      return warehouses;
    } else {
      return warehouses.filter(warehouse => warehouse.type === type);
    }
  });

export const selectWarehouseList = createSelector(
  selectWarehouseState,
  (state: fromWarehouseState.WarehouseState) => state.selectWarehouseList
);
