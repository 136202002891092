<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-member"></em>
    <h4>Membership</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap d-none d-md-block">
        <button
          type="button"
          class="btn btn-primary mr-2"
          *ngIf="hasPermission()"
          (click)="showModalGenerateMemberCard()"
        >
          <em class="icon-gen-user-pass"></em>
          Generate Member Card
        </button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let item of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(item)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">{{ item.docNo }} </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">No. of Member:</span>
              <span class="result-value">{{ item.memberCardAmount | number }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Card No.:</span>
              <span class="result-value">{{ item.cardNosDisplay }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created Date:</span>
              <span class="result-value">{{ item.createdDate | dateDisplay }}</span>
            </div>
            <div class="col-lg-5 col-md-6 text-break">
              <span class="result-property">Created By:</span>
              <span class="result-value">{{ item.createdByName }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box text-center">
          <button
            type="button"
            class="btn advance-filter-button btn-advance-filter"
            *ngIf="hasPermission()"
            (click)="onDownLoadMemberCard(item)"
          >
            <em class="icon-download"></em>
            Download
          </button>
        </div>
      </div>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>

<div class="modal fade" bsModal #modalGenerateMemberCard="bs-modal" [config]="{ backdrop: 'static' }">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">Generate Member Card</h6>
        <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
          <em class="icon-close"></em>
        </button>
      </div>
      <div class="modal-body admin-form">
        <div class="container-fluid">
          <div [formGroup]="generateMemberCardForm">
            <div class="form-group">
              <div class="mb-2">No. of Member Card<span class="text-danger">*</span></div>
              <app-numeric-textbox
                id="noOfCard"
                [decimals]="0"
                [maxValue]="99999"
                [minValue]="1"
                [min]="1"
                maxlength="5"
                formControlName="noOfCard"
                [format]="'0,0'"
                [negativeSign]="false"
                placeholder="No. of Member Card"
                [ngClass]="{
                  'is-invalid': generateMemberCardSubmitted && generateMemberCardForm.controls.noOfCard?.errors
                }"
              ></app-numeric-textbox>
              <div
                *ngIf="generateMemberCardSubmitted && generateMemberCardForm.controls.noOfCard.errors"
                class="invalid-feedback"
              >
                <div *ngIf="generateMemberCardForm.controls.noOfCard.errors.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="generateMemberCardForm.controls.noOfCard.errors.minError">
                  {{ 'ERRORS.NOT_ALLOW' | translate: '{value:0}' }}
                </div>
              </div>
              <div class="info-description">Maximum No. of Card 99,999</div>
            </div>
            <div class="form-group">
              <label>
                <input
                  type="checkbox"
                  class="form-check-input-label checkbox-modal"
                  [id]="'hasConfirmed'"
                  formControlName="hasConfirmed"
                />
                Confirm to generate member card number.
              </label>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-standard" (click)="hideModal()" appPreventDoubleClick>
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          appPreventDoubleClick
          [disabled]="disableGenerateMemberCard"
          (click)="onGenerateMemberCardSubmit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
