import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { StoreGroupPageModes } from '../../../shared/enum/store-group.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  MerchantSearch,
  StoreGroup,
  StoreGroupDto,
  StoreGroupErrorDetails,
  StoreGroupList,
  StoreGroupSearchCriteria,
  StoreList,
  StoreListSearchCriteria
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { MerchantService } from '../../../shared/services/merchant.service';
import { StoreRequestService } from '../../../shared/services/store-request.service';
import {
  StoreGroupGetRequestAction,
  StoreGroupListRequestAction,
  StoreGroupSubmitRequestAction,
  StoreGroupSubmitResetAction,
  StoreGroupUpdateRequestAction
} from '../../../shared/store/actions/store-group.actions';
import {
  selectStoreGroupErrorResponse,
  selectStoreGroupGetResponse,
  selectStoreGroupListCriteria
} from '../../../shared/store/selectors/store-group.selector';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-group-create',
  templateUrl: './store-group-create.component.html',
  styleUrls: ['./store-group-create.component.scss']
})
export class StoreGroupCreateComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('modalSelectStore', { static: false }) modalSelectStore: ModalDirective;
  @ViewChild('modalSelectProvince', { static: false }) modalSelectProvince: ModalDirective;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    merchantNo: string;
    groupNo: string;
    mode: StoreGroupPageModes;
  };

  public storeForm: FormGroup;
  public dialogForm: FormGroup;

  private localStore: Observable<any>;
  public merchantList: Observable<MerchantSearch>;
  public storeList: Observable<StoreList[]>;

  public merchantSearchInput$ = new Subject<string>();
  public merchantSearchLoading: boolean;
  public storeSearchLoading = false;
  public storeSearchInput$ = new Subject<string>();

  public isFormDirty: boolean;
  public provinces: Array<any>;
  public regions: Array<any>;
  public selectProvinces: Array<any>;

  public selectStoreOk: boolean;
  public selectProvinceOk: boolean;

  public currentMerchant: string;
  public submitted: boolean;
  public isSendToSubmit: boolean;
  public isRequestViewMode: boolean;
  public storeGroup: StoreGroup;

  public criteriaObject: StoreGroupSearchCriteria;
  private duplicatedNameValue: string;
  public isRequestEditMode: boolean;
  public hasViewPermission = false;
  public hasManagePermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected storeRequestService: StoreRequestService,
    protected merchantService: MerchantService,
    protected authGuardService: AuthGuardService,
    protected masterService: MasterService
  ) {
    super(store, modalService, false);
    this.hasViewPermission = this.authGuardService.checkPermission(['store_group_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['store_group_m']);
  }

  subscribeForVersionError() {
    // Override since list page already subscribe
  }

  ngOnInit() {
    this.initAction();
    this.initControl();
    this.initData();
    this.initState();
  }

  initControl() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];
    this.storeForm = this.fb.group({
      storeGroupName: initialNullRequired,
      merchant: initialNullRequired,
      storeList: this.fb.array([])
    });

    this.dialogForm = this.fb.group({
      storeName: initialNullRequired,
      province: initialNullRequired
    });

    this.initRequestMode();
  }

  initRequestMode() {
    if (this.isRequestViewMode) {
      this.storeForm.disable();
    } else if (this.isRequestEditMode) {
      this.storeForm.get('merchant').disable();
    }
  }

  initAction() {
    this.isRequestViewMode = [StoreGroupPageModes.VIEW].includes(this.data.mode);
    this.isRequestEditMode = [StoreGroupPageModes.EDIT].includes(this.data.mode);
  }

  initData() {
    this.loadMerchant('');

    this.masterService.getMasterDataByNames(['states']).subscribe(result => {
      if (result.data) {
        this.provinces = result.data.states;
        this.selectProvinces = [...this.provinces];
      }
    });

    this.masterService.getMasterDataByNames(['regions']).subscribe(result => {
      if (result.data) {
        this.regions = result.data.regions;
      }
    });

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
  }

  initState() {
    if (this.data.groupNo && this.data.merchantNo) {
      this.store.dispatch(
        new StoreGroupGetRequestAction({ merchantNo: this.data.merchantNo, groupNo: this.data.groupNo })
      );
    }

    this.localStore.pipe(select(selectStoreGroupErrorResponse)).subscribe(error => {
      if (error && error.code) {
        if (error.code === '04015') {
          this.duplicatedNameValue = this.storeForm.get('storeGroupName').value;
          this.storeForm
            .get('storeGroupName')
            .setValidators([Validators.required, this.isGroupNameDuplicatedValidator]);
          this.storeForm.get('storeGroupName').updateValueAndValidity();
        }

        if (error.details) {
          this.handleErrorStoreList(error.details);
        }
      }
    });

    this.localStore.pipe(select(selectStoreGroupGetResponse)).subscribe(data => {
      if (data) {
        this.storeGroup = { ...data };
        const merchantType = this.translate.instant('STORE_TYPE.' + this.storeGroup.merchantType);

        this.storeForm
          .get('storeGroupName')
          .setValue(
            this.storeGroup.defaultGroup
              ? this.translate.instant('STORE.STORE_GROUP.DEFAULT_GROUP')
              : this.storeGroup.name
          );

        this.storeForm.get('merchant').setValue({
          merchantName: this.storeGroup.merchantName,
          taxId: this.storeGroup.merchantTaxId,
          merchantType: this.storeGroup.merchantType,
          merchant: this.storeGroup.merchant,
          merchantNameDisplay: `${this.storeGroup.merchantName} (${this.storeGroup.merchantTaxId}) - ${merchantType}`
        });
        this.handleGetStoreList(data.stores);
      }
    });

    this.localStore
      .pipe(select(selectStoreGroupListCriteria))
      .subscribe(criteriaObject => (this.criteriaObject = criteriaObject));
  }

  handleErrorStoreList(error: StoreGroupErrorDetails[]) {
    if (error && error.length > 0) {
      error.forEach(err => {
        for (let i = 0; i < this.formStoreList.controls.length; i++) {
          const ctr = this.formStoreList.at(i);

          if (ctr.get('code').value === err.storeCode) {
            if (err.code === '04017') {
              ctr.get('code').setValidators(this.isInvalidAnotherGroupValidator);
              ctr.get('code').updateValueAndValidity();
            }
            break;
          }
        }
      });
    }
  }

  toggleToEditMode() {
    if (this.storeGroup.defaultGroup) {
      this.showAlert('Alert', 'Not allow to edit default group.');
      return;
    }

    this.isRequestViewMode = false;
    this.isRequestEditMode = true;
    this.data.title = 'Edit Store Group';
    this.data.mode = StoreGroupPageModes.EDIT;
    this.storeForm.get('storeGroupName').enable();
  }

  onSelectStore() {
    if (this.checkMerchant()) {
      this.dialogForm.controls['storeName'].setValue(null);
      this.loadStoreName('');
      this.modalSelectStore.show();
    }
  }

  onStoreOk() {
    this.selectStoreOk = true;
    if (this.dialogForm.controls['storeName'].invalid) {
      return;
    }

    this.onExitStore();
    const store = this.dialogForm.controls['storeName'].value;

    if (!this.checkHasDulicated(store)) {
      const formItem = this.initCreateFromStoreItem(store);

      this.formStoreList.push(formItem);
    } else {
      this.showAlert('Failed', 'Not allow to add duplicated store.');
    }
  }

  onProvinceOk() {
    this.selectProvinceOk = true;
    if (this.dialogForm.controls['province'].invalid) {
      return;
    }
    const merchantNo = this.storeForm.controls['merchant'].value.merchant;

    const criteria = {
      active: true,
      defaultGroup: true,
      state: this.dialogForm.controls['province'].value
    } as StoreListSearchCriteria;

    this.merchantService
      .searchStoreByMerchant(merchantNo, criteria)
      .pipe(
        tap(value => {
          if (value && value.length > 0) {
            this.handleGetStoreList(value);
          } else {
            this.showAlert('Failed', 'Selected province does not have any store.');
          }
        })
      )
      .subscribe();

    this.onExitProvince();
  }

  handleGetStoreList(stores: StoreGroupList[] | StoreList[]) {
    if (stores && stores.length > 0) {
      for (const store of stores) {
        const formItem = this.initCreateFromStoreItem(store);
        this.formStoreList.push(formItem);
      }

      this.storeForm.updateValueAndValidity();
    }
  }

  initCreateFromStoreItem(store: any) {
    const formItem = this.fb.group({
      region: [{ value: null, disabled: false }],
      state: [{ value: null, disabled: false }],
      code: [
        { value: null, disabled: false },
        {
          validators: [this.isUniqueValidator('code')]
        }
      ],
      name: [{ value: null, disabled: false }],
      status: [{ value: null, disabled: false }],
      data: [{ value: null }]
    });

    formItem.patchValue({
      region: store.region,
      code: store.code,
      state: store.state,
      name: store.name,
      status: store.status,
      data: store
    });
    return formItem;
  }

  deleteStoreItem(index: number) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        okText: 'Yes, Delete',
        message: 'Are you sure you want to delete this item?'
      },
      keyboard: false
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const codeno = this.formStoreList.at(index).get('code').value;
          this.formStoreList.removeAt(index);
          this.formStoreList.controls.forEach(control => {
            if (control.get('code').value === codeno) {
              control.get('code').updateValueAndValidity();
            }
          });
        }
      });
  }

  onSelectProvince() {
    if (this.checkMerchant()) {
      this.dialogForm.controls['province'].setValue(null);
      this.modalSelectProvince.show();
    }
  }

  checkMerchant() {
    if (this.storeForm.get('merchant').value !== null) {
      return true;
    }

    this.showAlert('Alert', 'Please select merchant before add store.');

    return false;
  }

  checkHasDulicated(stores: any) {
    if (stores && this.formStoreList.length > 0) {
      const store = this.formStoreList.controls.filter(x => x.get('code').value === stores.code);
      if (store && store.length > 0) {
        return true;
      }
    }

    return false;
  }

  loadMerchant(initialTerm: string) {
    this.merchantList = concat(
      of([]),
      this.merchantSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.merchantSearchLoading = true)),
        switchMap(term =>
          this.storeRequestService.searchMerchantByName({ searchCriteria: term }, 20).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.merchantSearchLoading = false;
            })
          )
        )
      )
    );
  }

  loadStoreName(initialTerm: string) {
    const merchantNo = this.storeForm.controls['merchant'].value.merchant;
    this.storeList = concat(
      of([]),
      this.storeSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.storeSearchLoading = true)),
        switchMap(term => {
          const criteria = {
            text: term,
            active: true,
            defaultGroup: true
          } as StoreListSearchCriteria;

          return this.merchantService.searchStoreByMerchant(merchantNo, criteria).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.storeSearchLoading = false;
            })
          );
        })
      )
    );
  }

  onSubmit() {
    this.submitted = true;

    if (this.storeForm.invalid || this.formStoreList.controls.length === 0) {
      if (this.formStoreList.controls.length === 0) {
        this.showAlert('Failed', 'Please select at least 1 store.');
      }
      return;
    }

    this.duplicatedNameValue = '';
    const data = this.prepareData();

    this.sendToSubmit(data);
  }

  sendToSubmit(data: StoreGroupDto) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.isSendToSubmit = true;
          if (this.data.groupNo && this.data.merchantNo) {
            this.store.dispatch(
              new StoreGroupUpdateRequestAction({
                merchantNo: this.data.merchantNo,
                groupNo: this.data.groupNo,
                data
              })
            );
          } else {
            this.store.dispatch(new StoreGroupSubmitRequestAction(data));
          }
        }
      });
  }

  prepareData(): StoreGroupDto {
    const rawData = this.storeForm.getRawValue();
    return {
      merchant: rawData.merchant.merchant,
      name: rawData.storeGroupName,
      stores: this.formStoreList.getRawValue().map(item => ({ no: item.data.no })),
      version: this.storeGroup && this.data.groupNo ? this.storeGroup.version : 0,
      no: this.storeGroup && this.data.groupNo ? this.storeGroup.no : null
    } as StoreGroupDto;
  }

  onExitStore() {
    this.selectStoreOk = false;
    this.modalSelectStore.hide();
  }

  onExitProvince() {
    this.selectProvinceOk = false;
    this.modalSelectProvince.hide();
  }

  onExit() {
    this.isFormDirty = this.storeForm.dirty || this.isFormDirty;

    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  onChangeMerchant() {
    if (this.formStoreList.controls.length > 0) {
      const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: 'Confirm',
          message: 'Are you sure you want to change Merchant? Selected store will be deleted.'
        },
        backdrop: 'static',
        keyboard: false
      });
      const tempValue = this.storeForm.controls['merchant'].value;
      this.storeForm.controls['merchant'].setValue(this.currentMerchant);
      confirmModalRef.content.action
        .pipe(untilComponentDestroyed(this))
        .subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            this.formStoreList.clear();
            this.storeForm.controls['merchant'].setValue(tempValue);
            this.currentMerchant = tempValue;
          }
        });
    } else {
      this.currentMerchant = this.storeForm.controls['merchant'].value;
    }
  }

  showAlert(title: string, message: string) {
    const initialState = {
      title,
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  isUniqueValidator(controlName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null && this.formStoreList.length > 0) {
        const isDuplicated = this.formStoreList.controls
          .map((formGroup: AbstractControl | any) => formGroup.controls[controlName])
          .filter(formControl => formControl !== control)
          .find(formControl => formControl.value === control.value);
        return isDuplicated ? { duplicated: true } : null;
      }
      return null;
    };
  }

  get isInvalidAnotherGroupValidator(): ValidatorFn {
    return (): { [key: string]: boolean } | null => {
      return { isAnotherGroup: true };
    };
  }

  get isGroupNameDuplicatedValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value !== null) {
        return control.value === this.duplicatedNameValue ? { isDuplicatedName: true } : null;
      }
      return null;
    };
  }

  get formStoreList(): FormArray {
    return this.storeForm.get('storeList') as FormArray;
  }

  get pageMode() {
    return StoreGroupPageModes;
  }

  doAfterVersionAlertModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new StoreGroupSubmitResetAction());

    if (this.data.mode === StoreGroupPageModes.CREATE && this.isSendToSubmit) {
      this.criteriaObject.page = 0;
    }

    this.store.dispatch(new StoreGroupListRequestAction(this.criteriaObject));

    super.unsubscribeBase();
  }
}
