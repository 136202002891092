import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { MasterDataEnum } from '../../../shared/enum/master-data.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import {
  SupplierGroupModeEnum,
  SupplierGroupRequestStatusEnum,
  SupplierGroupRequestTypeEnum,
  SupplierGroupStatusEnum
} from '../../../shared/enum/supplier-group.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ErrorResponse, TaskModuleUrl } from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import {
  productTypeLOV,
  supplierNameFilter,
  supplierStoreRequestStatus
} from '../../../shared/models/list-value/list-key-value.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import {
  StoreSupplierRequestSearchCriteria,
  SupplierGroupRequest,
  SupplierGroupRequestObject,
  SupplierStoreContent
} from '../../../shared/models/supplier-group-request.model';
import { StoreSupplierSearchCriteria, SupplierGroup } from '../../../shared/models/supplier-group.model';
import { SupplierSearch } from '../../../shared/models/supplier.model';
import { AuthGuardService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import { SupplierGroupRequestService } from '../../../shared/services/supplier-group-request.service';
import { SupplierGroupService } from '../../../shared/services/supplier-group.service';
import { SupplierService } from '../../../shared/services/supplier.service';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import { LayoutActionVersionError } from '../../../shared/store/actions/layout.action';
import {
  SupplierGroupRequestApproveRequest,
  SupplierGroupRequestCancelRequest,
  SupplierGroupRequestCheckExistingRequest,
  SupplierGroupRequestDeleteRequest,
  SupplierGroupRequestEditSubmitRequest,
  SupplierGroupRequestExistingReset,
  SupplierGroupRequestRejectRequest,
  SupplierGroupRequestReset,
  SupplierGroupRequestSaveRequest,
  SupplierGroupRequestSubmitRequest,
  SupplierGroupRequestUpdateRequest,
  SupplierGroupRequestViewRequest
} from '../../../shared/store/actions/supplier-group-request.actions';
import { SupplierGroupViewRequest } from '../../../shared/store/actions/supplier-group.actions';
import {
  StoreSupplierListRequest,
  StoreSupplierRequestListRequest,
  SupplierStoreRequestListRequest,
  SupplierStoreReset
} from '../../../shared/store/actions/supplier-store.actions';
import { SupplierStoreState } from '../../../shared/store/reducers/supplier-store.reducers';
import {
  selectSupplierGroupRequest,
  selectSupplierGroupRequestErrorResponse,
  selectSupplierGroupRequestExisting
} from '../../../shared/store/selectors/supplier-group-request.selectors';
import { selectSupplierGroup } from '../../../shared/store/selectors/supplier-group.selectors';
import {
  selectSupplierStore,
  selectSupplierStoreList
} from '../../../shared/store/selectors/supplier-store.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-supplier-group-request-view',
  templateUrl: './supplier-group-request-view.component.html',
  styleUrls: ['./supplier-group-request-view.component.scss']
})
export class SupplierGroupRequestViewComponent extends BaseComponent implements OnInit, OnDestroy {
  supplierRequest: SupplierGroupRequest | SupplierGroup;
  supplierGroupRequestStatusEnum = SupplierGroupRequestStatusEnum;
  supplierGroupRequestModeEnum = SupplierGroupModeEnum;
  supplierGroupRequestTypeEnum = SupplierGroupRequestTypeEnum;
  productTypeList: NgOption[];
  supplierRequestForm: FormGroup;
  isSubmit: boolean;
  isShowAdvanceSearch: boolean;

  criteriaObject: any;
  currentPage: number;
  pageSize: number;
  searchForm: FormGroup;

  resultList$: Observable<SupplierStoreContent[]>;
  listState$: Observable<SupplierStoreState>;
  supplierList: Observable<SupplierSearch[]>;
  submitSelectSupplier: boolean;
  supplierNameForm: FormGroup;

  supplierSearchLoading = false;
  supplierSearchInput$ = new Subject<string>();
  private localStore: Observable<any>;

  currentSupplier: { supplierCode: string; supplierName: string; branchNo: string };
  tempSupplier: { supplierCode: string; supplierName: string; branchNo: string };
  newSupplier: { supplierCode: string; supplierName: string; branchNo: string };
  previousSupplier: { supplierCode: string; supplierName: string; branchNo: string };

  statusTypeTag: string;
  statusStringTag: string;
  regionTag: string;
  regionStringTag: string;
  stateTag: string;
  stateStringTag: string;
  supplierNameTag: string;
  supplierNameStringTag;
  isImported: boolean;
  importedGroupSupplierId: string;

  public allowManageSupplierGroup = ['suppliergroup_inv_m', 'suppliergroup_asset_m', 'suppliergroup_sto_m'];
  public allowApproveSupplierGroup = ['suppliergroup_inv_app', 'suppliergroup_asset_app', 'suppliergroup_sto_app'];
  public allowViewSupplierGroup = ['suppliergroup_inv_v', 'suppliergroup_asset_v', 'suppliergroup_sto_v'];
  public hasPermissionManageSupplierGroup = false;
  public hasPermissionApproveSupplierGroup = false;
  public hasPermissionViewSupplierGroup = false;
  public hasPermissionManageInventorySupplierGroup = false;
  public hasPermissionManageFixAssetSupplierGroup = false;
  public hasPermissionManageStoreUseSupplierGroup = false;
  public listRegion: any[];
  public listState: any[];
  importResult: { title: string; table: any[]; message: string };
  @ViewChild('importResultModel', { static: false }) importResultModel: ModalDirective;

  public supplierGroupRequestStatusFilter: NgOption[] = supplierStoreRequestStatus;
  public supplierNameFilter: NgOption[] = supplierNameFilter;

  @Output() data: {
    id: string;
    groupNo?: string;
    mode: SupplierGroupModeEnum;
    title: string;
    originPage?: string;
    type?;
  };

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  @ViewChild('modalSelectSupplier', { static: false }) modalSelectSupplier: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    protected supplierService: SupplierService,
    protected spinner: NgxSpinnerService,
    protected supplierGroupRequestService: SupplierGroupRequestService,
    protected supplierGroupService: SupplierGroupService,
    private readonly masterService: MasterService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.hasPermissionManageSupplierGroup = this.authGuardService.checkPermission(this.allowManageSupplierGroup);
    this.hasPermissionApproveSupplierGroup = this.authGuardService.checkPermission(this.allowApproveSupplierGroup);
    this.hasPermissionViewSupplierGroup = this.authGuardService.checkPermission(this.allowViewSupplierGroup);
    this.hasPermissionManageInventorySupplierGroup = this.authGuardService.checkPermission([
      'suppliergroup_inv_m',
      'suppliergroup_inv_app',
      'suppliergroup_inv_v'
    ]);
    this.hasPermissionManageStoreUseSupplierGroup = this.authGuardService.checkPermission([
      'suppliergroup_sto_m',
      'suppliergroup_sto_app',
      'suppliergroup_sto_v'
    ]);
    this.hasPermissionManageFixAssetSupplierGroup = this.authGuardService.checkPermission([
      'suppliergroup_asset_m',
      'suppliergroup_asset_app',
      'suppliergroup_asset_v'
    ]);

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.setInitialCriteriaObject();
    this.initialSubscription();
    this.initialData();

    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.subscribeForSaveSuccess(false);
    }
  }

  ngOnDestroy() {
    super.unsubscribeBase();
    this.store.dispatch(new SupplierStoreReset());
    this.store.dispatch(new SupplierGroupRequestReset());
    this.store.dispatch(new SupplierGroupRequestExistingReset());
  }

  // Override since list page already subscribe
  subscribeForVersionError() {}

  initialData() {
    this.productTypeList = productTypeLOV;
    this.filterProductTypeList();

    this.resultList$ = this.store.select(selectSupplierStoreList);
    this.listState$ = this.store.select(selectSupplierStore);

    this.supplierRequest = {
      id: null,
      requestNo: null,
      status: SupplierGroupRequestStatusEnum.DRAFT,
      name: null,
      productType: null,
      type: SupplierGroupRequestTypeEnum.NEW,
      supplier: {
        supplierCode: null,
        supplierName: null,
        branchNo: null
      }
    } as SupplierGroupRequest;

    this.tempSupplier = {
      supplierCode: null,
      supplierName: null,
      branchNo: null
    };

    this.currentSupplier = {
      supplierCode: null,
      supplierName: null,
      branchNo: null
    };
    this.createForm(this.supplierRequest);
    this.createSupplierNameForm();
    this.createSearchForm();

    if (this.data.mode === SupplierGroupModeEnum.REQUEST_CREATE) {
      this.store.dispatch(new SupplierStoreRequestListRequest(this.criteriaObject));
    } else if ([SupplierGroupModeEnum.REQUEST_VIEW, SupplierGroupModeEnum.REQUEST_EDIT].includes(this.data.mode)) {
      this.initialForRequestView();
      this.subscribeForView();
    } else if ([SupplierGroupModeEnum.VIEW, SupplierGroupModeEnum.EDIT].includes(this.data.mode)) {
      this.initialForView();
      this.subscribeForView();
    }
  }

  setInitialCriteriaObject() {
    this.pageSize = 20;
    this.criteriaObject = {
      ...({} as any),
      page: 0,
      size: 20
    };
  }

  filterProductTypeList() {
    if (
      this.hasPermissionManageInventorySupplierGroup &&
      this.hasPermissionManageFixAssetSupplierGroup &&
      this.hasPermissionManageStoreUseSupplierGroup
    ) {
      return;
    }

    if (!this.hasPermissionManageInventorySupplierGroup) {
      this.productTypeList = this.productTypeList.filter(data => {
        return data.value !== ProductTypeEnum.INVENTORY;
      });
    }

    if (!this.hasPermissionManageFixAssetSupplierGroup) {
      this.productTypeList = this.productTypeList.filter(data => {
        return data.value !== ProductTypeEnum.FIX_ASSET;
      });
    }

    if (!this.hasPermissionManageStoreUseSupplierGroup) {
      this.productTypeList = this.productTypeList.filter(data => {
        return data.value !== ProductTypeEnum.STORE_USE;
      });
    }
  }

  initialForRequestView() {
    this.store.dispatch(new SupplierGroupRequestViewRequest(this.data.id));
    this.store.dispatch(
      new StoreSupplierRequestListRequest({
        id: this.data.id,
        searchCriteria: this.criteriaObject,
        isImported: this.isImported
      } as StoreSupplierRequestSearchCriteria)
    );

    // Subscribe for supplier group request
    this.localStore
      .pipe(
        select(selectSupplierGroupRequest),
        filter(suppGroup => suppGroup !== null)
      )
      .subscribe(supplierGroupRequest => {
        this.supplierRequest = {
          id: this.data.id,
          requestNo: this.getValue(supplierGroupRequest.requestNo),
          status: this.getValue(supplierGroupRequest.status),
          name: this.getValue(supplierGroupRequest.name),
          productType: this.getValue(supplierGroupRequest.productType),
          type: this.getValue(supplierGroupRequest.type),
          groupNo: this.getValue(supplierGroupRequest.groupNo),
          totalSuppliers: this.getValue(supplierGroupRequest.totalSuppliers),
          numberOfArticles: this.getValue(supplierGroupRequest.numberOfArticles),
          pendingSelectSupplier: this.getValue(supplierGroupRequest.pendingSelectSupplier),
          completed: this.getValue(supplierGroupRequest.completed),
          lastModifiedDate: this.getValue(supplierGroupRequest.lastModifiedDate)
        } as SupplierGroupRequest;
        this.createForm(this.supplierRequest);
        this.createSupplierNameForm();
      });
  }

  initialForView() {
    this.store.dispatch(new SupplierGroupViewRequest(this.data.groupNo));
    this.store.dispatch(
      new StoreSupplierListRequest({
        id: this.data.id,
        searchCriteria: this.criteriaObject,
        isImported: this.isImported
      } as StoreSupplierSearchCriteria)
    );

    // Subscribe for supplier group
    this.localStore
      .pipe(
        select(selectSupplierGroup),
        filter(suppGroup => suppGroup !== null)
      )
      .subscribe(supplierGroupRequest => {
        this.supplierRequest = {
          id: this.data.id,
          status:
            SupplierGroupModeEnum.EDIT === this.data.mode
              ? SupplierGroupRequestStatusEnum.DRAFT
              : this.getValue(supplierGroupRequest.status),
          name: this.getValue(supplierGroupRequest.name),
          productType: this.getValue(supplierGroupRequest.productType),
          type: this.getValue(supplierGroupRequest.type),
          groupNo: this.getValue(supplierGroupRequest.groupNo),
          totalSuppliers: this.getValue(supplierGroupRequest.totalSuppliers),
          numberOfArticles: this.getValue(supplierGroupRequest.numberOfArticles),
          pendingSelectSupplier: this.getValue(supplierGroupRequest.pendingSelectSupplier),
          completed: this.getValue(supplierGroupRequest.completed),
          lastModifiedDate: this.getValue(supplierGroupRequest.lastModifiedDate)
        } as SupplierGroup;
        this.createForm(this.supplierRequest);
        this.createSupplierNameForm();
      });
  }

  subscribeForView() {
    // Subscribe for store supplier list table
    this.localStore
      .pipe(
        select(selectSupplierStoreList),
        filter(resultList => resultList !== null && resultList.length > 0)
      )
      .subscribe(resultList => {
        this.supplierRequest = {
          ...this.supplierRequest,
          supplier: {
            id: resultList[0].id,
            supplierCode: resultList[0].supplier ? resultList[0].supplier.supplierCode : null,
            supplierName: resultList[0].supplier ? resultList[0].supplier.supplierName : null,
            branchNo: resultList[0].supplier ? resultList[0].supplier.branchNo : null
          }
        };

        if (this.newSupplier) {
          this.currentSupplier = this.newSupplier;
        } else {
          if (!this.previousSupplier) {
            this.previousSupplier = this.currentSupplier;
          }
        }
      });

    this.checkExistingSupplierGroup();
  }

  checkExistingSupplierGroup() {
    if (
      [
        SupplierGroupModeEnum.REQUEST_VIEW,
        SupplierGroupModeEnum.VIEW,
        SupplierGroupModeEnum.REQUEST_EDIT,
        SupplierGroupModeEnum.EDIT
      ].includes(this.data.mode)
    ) {
      this.localStore
        .pipe(
          select(selectSupplierGroupRequestExisting),
          filter(isExisting => isExisting !== null)
        )
        .subscribe(isExisting => {
          if (isExisting) {
            this.showErrorModal('Alert', 'This supplier group has been edited and awaiting for approval.');

            this.store.dispatch(new SupplierGroupRequestExistingReset());
          } else {
            if (SupplierGroupModeEnum.VIEW === this.data.mode) {
              this.doEditAction();
            } else {
              this.doSubmitAction();
            }
            this.store.dispatch(new SupplierGroupRequestExistingReset());
          }
        });
    }
  }

  initialSubscription() {
    this.localStore.pipe(select(selectSupplierGroupRequestErrorResponse)).subscribe(error => {
      this.handleSupplierGroupError(error);
    });

    this.store
      .pipe(select(selectSupplierStoreList))
      .subscribe(() => (this.currentPage = this.criteriaObject.page + 1));

    this.masterService
      .getMasterDataByNames([MasterDataEnum.REGION, MasterDataEnum.STATE])
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data) {
          if (result.data.regions) {
            this.listRegion = result.data.regions;
          }
          if (result.data.states) {
            this.listState = result.data.states;
          }
        }
      });
  }

  handleSupplierGroupError(error: ErrorResponse) {
    if (error && error.code === '00001') {
      if (error.message.includes('Supplier group') && error.message && error.message.includes('already exists')) {
        this.showErrorModal('Failed', 'Supplier Group Name is duplicated in the system.');
      } else if (error.message && error.message.includes('Please select at least 1 supplier')) {
        this.showErrorModal('Failed', 'Please select at least 1 supplier.');
      } else {
        this.store.dispatch(new LayoutActionVersionError(true));
      }
    } else if (
      error &&
      error.code === '08002' &&
      error.message &&
      error.message.includes('Please select at least 1 supplier')
    ) {
      this.showErrorModal('Failed', 'Please select at least 1 supplier.');
    } else if (
      error &&
      error.code === '08003' &&
      error.message &&
      error.message.includes('Please change at least 1 store')
    ) {
      this.showErrorModal('Failed', 'Please change at least 1 store.');
    }
  }

  showErrorModal(title: string, message: string) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title,
        message
      }
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }
    });
  }

  createForm(supplierGroupRequest: SupplierGroupRequest | SupplierGroup) {
    this.supplierRequestForm = this.fb.group({
      name: [
        {
          value: supplierGroupRequest.name,
          disabled: [SupplierGroupModeEnum.REQUEST_VIEW, SupplierGroupModeEnum.VIEW].includes(this.data.mode)
        },
        [Validators.required]
      ],
      productType: [
        {
          value: supplierGroupRequest.productType ? supplierGroupRequest.productType : null,
          disabled:
            [SupplierGroupModeEnum.REQUEST_VIEW, SupplierGroupModeEnum.VIEW, SupplierGroupModeEnum.EDIT].includes(
              this.data.mode
            ) ||
            // &&
            //   [
            //     SupplierGroupRequestStatusEnum.AWAITING_APPROVAL,
            //     SupplierGroupRequestStatusEnum.APPROVED,
            //     SupplierGroupRequestStatusEnum.CANCELLED,
            //     SupplierGroupRequestStatusEnum.REJECTED
            //   ].includes(this.data)
            this.supplierRequest.type !== SupplierGroupRequestTypeEnum.NEW ||
            this.supplierRequest.status !== SupplierGroupRequestStatusEnum.DRAFT
        },
        [Validators.required]
      ]
    });
  }

  createSupplierNameForm() {
    this.supplierNameForm = this.fb.group({
      supplierName: [{ value: null, disabled: false }, [Validators.required]]
    });
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      region: [null],
      state: [null],
      supplierExist: [null],
      status: [null]
    });
  }

  onChangePage(event: any) {
    this.criteriaObject = {
      ...this.criteriaObject,
      page: event.page - 1
    };
    this.search();
  }

  onChangeRowPerPage(value: string) {
    this.setFirstPage();
    this.pageSize = Number(value);
    this.criteriaObject = {
      ...this.criteriaObject,
      size: Number(value),
      page: 0
    };
    this.search();
  }

  search() {
    if ([SupplierGroupModeEnum.REQUEST_CREATE].includes(this.data.mode)) {
      if (this.isImported) {
        this.store.dispatch(
          new StoreSupplierRequestListRequest({
            id: this.importedGroupSupplierId,
            searchCriteria: this.criteriaObject,
            isImported: this.isImported
          } as StoreSupplierRequestSearchCriteria)
        );
      } else {
        this.store.dispatch(new SupplierStoreRequestListRequest(this.criteriaObject));
      }
    } else if (this.data.mode === SupplierGroupModeEnum.VIEW) {
      this.store.dispatch(
        new StoreSupplierListRequest({
          id: this.data.id,
          searchCriteria: this.criteriaObject,
          isImported: this.isImported
        } as StoreSupplierSearchCriteria)
      );
    } else if (this.data.mode === SupplierGroupModeEnum.EDIT) {
      if (this.isImported) {
        this.store.dispatch(
          new StoreSupplierRequestListRequest({
            id: this.importedGroupSupplierId,
            searchCriteria: this.criteriaObject,
            isImported: this.isImported
          } as StoreSupplierRequestSearchCriteria)
        );
      } else {
        this.store.dispatch(
          new StoreSupplierListRequest({
            id: this.data.id,
            searchCriteria: this.criteriaObject,
            isImported: this.isImported
          } as StoreSupplierSearchCriteria)
        );
      }
    } else if (this.data.mode === SupplierGroupModeEnum.REQUEST_EDIT && this.isImported) {
      this.store.dispatch(
        new StoreSupplierRequestListRequest({
          id: this.importedGroupSupplierId,
          searchCriteria: this.criteriaObject,
          isImported: this.isImported
        } as StoreSupplierRequestSearchCriteria)
      );
    } else {
      this.store.dispatch(
        new StoreSupplierRequestListRequest({
          id: this.data.id,
          searchCriteria: this.criteriaObject,
          isImported: this.isImported
        } as StoreSupplierRequestSearchCriteria)
      );
    }
  }

  hideModalSelectSupplier() {
    this.modalSelectSupplier.hide();
  }

  showModalSelectSupplier() {
    this.submitSelectSupplier = false;
    this.modalSelectSupplier.show();

    this.loadSupplier('');

    if (this.currentSupplier && this.currentSupplier.supplierName) {
      this.supplierNameForm.patchValue({
        supplierName: null
      });
    }
  }

  onChangeSupplierName(value) {
    this.supplierNameForm.patchValue({
      supplierCode: value ? value.supplierCode : null
    });
    if (value) {
      this.tempSupplier = {
        supplierCode: value.supplierCode,
        supplierName: value.supplierName,
        branchNo: value.branchNo
      };
      this.loadSupplier(value);
    } else {
      this.tempSupplier = {
        supplierCode: null,
        supplierName: null,
        branchNo: null
      };
      this.loadSupplier('');
    }
  }

  onBlurSupplierName() {
    if (!this.supplierNameForm.controls.supplierName.value) {
      this.loadSupplier('');
    }
  }

  confirmSelectSupplierToAllStores() {
    this.submitSelectSupplier = true;
    if (this.supplierNameForm.invalid) {
      return;
    }
    this.importedGroupSupplierId = null;
    this.isImported = false;

    this.currentSupplier = { ...this.tempSupplier };
    this.newSupplier = { ...this.tempSupplier };
    this.modalSelectSupplier.hide();
  }

  toggleToEdit() {
    if ([SupplierGroupModeEnum.REQUEST_VIEW].includes(this.data.mode)) {
      this.doEditAction();
    } else if ([SupplierGroupModeEnum.VIEW].includes(this.data.mode)) {
      this.store.dispatch(new SupplierGroupRequestCheckExistingRequest(this.supplierRequest.groupNo));
    }
  }

  doEditAction() {
    if ([SupplierGroupModeEnum.REQUEST_VIEW].includes(this.data.mode)) {
      this.data.mode = SupplierGroupModeEnum.REQUEST_EDIT;
      this.data.title = 'Edit Supplier Group Request';
    } else if ([SupplierGroupModeEnum.VIEW].includes(this.data.mode)) {
      this.data.mode = SupplierGroupModeEnum.EDIT;
      this.data.title = 'Edit Supplier Group';
    }

    if (
      [SupplierGroupModeEnum.REQUEST_EDIT].includes(this.data.mode) &&
      this.supplierRequest.type === SupplierGroupRequestTypeEnum.NEW &&
      this.supplierRequest.status === SupplierGroupRequestStatusEnum.DRAFT
    ) {
      this.supplierRequestForm.get('productType').enable();
    }

    this.supplierRequest.type = SupplierGroupRequestTypeEnum.EDIT;
    this.supplierRequest.status = SupplierGroupRequestStatusEnum.DRAFT;
    this.supplierRequestForm.get('name').enable();
    this.isImported = false;

    this.clearAdvanceFilter();
  }

  onSave() {
    this.isSubmit = true;

    if (this.supplierRequestForm.invalid) {
      return;
    }
    const supplierRequest = this.prepareData();

    if (supplierRequest.id) {
      this.store.dispatch(new SupplierGroupRequestUpdateRequest(supplierRequest));
    } else {
      this.store.dispatch(new SupplierGroupRequestSaveRequest(supplierRequest));
    }
  }

  onSubmit() {
    this.isSubmit = true;

    if (this.supplierRequestForm.invalid) {
      return;
    } else {
      this.handleConfirm();
    }
  }

  onApprove() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to "Approve"?`,
        label: 'Comment',
        isRequiredConfirmMessage: false,
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new SupplierGroupRequestApproveRequest({
              id: this.data.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onCancel() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel request number "${this.supplierRequest.requestNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new SupplierGroupRequestCancelRequest({
              id: this.supplierRequest.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onDelete() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this request?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new SupplierGroupRequestDeleteRequest(this.supplierRequest.id));
        }
      });
  }

  onReject() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to "Reject"?`,
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new SupplierGroupRequestRejectRequest({
              id: this.data.id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          if (
            [
              SupplierGroupModeEnum.REQUEST_VIEW,
              SupplierGroupModeEnum.VIEW,
              SupplierGroupModeEnum.REQUEST_EDIT,
              SupplierGroupModeEnum.EDIT
            ].includes(this.data.mode)
          ) {
            this.store.dispatch(new SupplierGroupRequestCheckExistingRequest(this.supplierRequest.groupNo));
          } else {
            this.doSubmitAction();
          }
        }
      });
  }

  doSubmitAction() {
    const supplierRequest = this.prepareData();
    if (this.data.mode === SupplierGroupModeEnum.EDIT) {
      this.store.dispatch(new SupplierGroupRequestEditSubmitRequest(supplierRequest));
    } else {
      this.store.dispatch(new SupplierGroupRequestSubmitRequest(supplierRequest));
    }
  }

  onCloseFullModal() {
    if (
      (this.form && (this.form.touched || this.form.dirty)) ||
      (this.supplierNameModalForm && (this.supplierNameModalForm.touched || this.supplierNameModalForm.dirty))
    ) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  prepareData() {
    const supplierRequestRaw = this.supplierRequestForm.getRawValue();

    /** BO-3483 [Supplier Group] Suppliers in store list has disappeared after update supplier group name
     *
     * use newSupplier instead of currentSupplier and if no selected supplier group, will send request supplier object as null
     *
     * SuteeC. 1/Apr/2020
     * **/
    const supplierNameRaw = this.newSupplier && this.newSupplier.supplierName ? this.newSupplier : null;
    if (this.isImported) {
      return {
        id: this.data.mode === SupplierGroupModeEnum.EDIT ? null : this.supplierRequest.id,
        name: supplierRequestRaw.name,
        productType: supplierRequestRaw.productType,
        supplier: null,
        type: this.getSupplierGroupRequestType(),
        groupImportId: this.isImported ? this.importedGroupSupplierId : null,
        groupNo: this.getValue(this.supplierRequest.groupNo),
        version: this.getValue(this.supplierRequest.version)
      } as SupplierGroupRequestObject;
    } else {
      return {
        id: this.data.mode === SupplierGroupModeEnum.EDIT ? null : this.supplierRequest.id,
        name: supplierRequestRaw.name,
        productType: supplierRequestRaw.productType,
        supplier:
          supplierNameRaw && supplierNameRaw.supplierName && !this.isImported
            ? {
                supplierName: supplierNameRaw.supplierName,
                supplierCode: supplierNameRaw.supplierCode,
                branchNo: supplierNameRaw.branchNo
              }
            : null,
        type: this.getSupplierGroupRequestType(),
        groupImportId: this.isImported ? this.importedGroupSupplierId : null,
        groupNo: this.getValue(this.supplierRequest.groupNo),
        version: this.getValue(this.supplierRequest.version)
      } as SupplierGroupRequestObject;
    }
  }

  getSupplierGroupRequestType() {
    return this.data.mode === SupplierGroupModeEnum.REQUEST_EDIT
      ? SupplierGroupRequestTypeEnum.NEW
      : this.data.mode === SupplierGroupModeEnum.EDIT
      ? SupplierGroupRequestTypeEnum.EDIT
      : this.supplierRequest.type
      ? this.supplierRequest.type
      : SupplierGroupRequestTypeEnum.NEW;
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.state && !formValue.region && !formValue.status && !formValue.supplierExist) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      state: formValue.state,
      supplierExist: formValue.supplierExist,
      region: formValue.region,
      status: formValue.status,
      page: 0
    };

    this.prepareSearchCriteriaTags();
    this.search();
  }

  prepareSearchCriteriaTags() {
    this.statusTypeTag = null;
    this.statusStringTag = null;
    this.regionTag = null;
    this.regionStringTag = null;
    this.stateTag = null;
    this.stateStringTag = null;
    this.supplierNameTag = null;
    this.supplierNameStringTag = null;

    if (this.criteriaObject.region && this.criteriaObject.region.length > 0) {
      this.regionTag = 'Region';
      const regions = this.listRegion
        .filter(data => {
          return this.criteriaObject.region.indexOf(data.code) > -1;
        })
        .map(region => region.nameTh);

      const regionsText = regions.join(', ');
      this.regionStringTag = `"${regionsText}"`;
    }

    if (this.criteriaObject.status && this.criteriaObject.status.length > 0) {
      this.statusTypeTag = 'Item Status';
      const statuses = this.supplierGroupRequestStatusFilter
        .filter(data => {
          return this.criteriaObject.status.indexOf(data.value) > -1;
        })
        .map(sta => sta.label);

      const statusesText = statuses.join(', ');
      this.statusStringTag = `"${statusesText}"`;
    }

    if (this.criteriaObject.state && this.criteriaObject.state.length > 0) {
      this.stateTag = 'Province';
      const states = this.listState
        .filter(data => {
          return this.criteriaObject.state.indexOf(data.code) > -1;
        })
        .map(st => st.nameTh);

      const statesText = states.join(', ');
      this.stateStringTag = `"${statesText}"`;
    }

    if (this.criteriaObject.supplierExist && this.criteriaObject.supplierExist.length > 0) {
      this.supplierNameTag = 'Supplier Name';
      const supplierNames = this.supplierNameFilter
        .filter(data => {
          return this.criteriaObject.supplierExist.indexOf(data.value) > -1;
        })
        .map(st => st.label);

      const statesText = supplierNames.join(', ');
      this.supplierNameStringTag = `"${statesText}"`;
    }
  }

  clearRegionFilter() {
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      region: null,
      page: 0
    };
    this.searchForm.controls['region'].reset();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearStateFilter() {
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      state: null,
      page: 0
    };
    this.searchForm.controls['state'].reset();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearStatusFilter() {
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      status: null,
      page: 0
    };
    this.searchForm.controls['status'].reset();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearSupplierNameFilter() {
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      supplierExist: null,
      page: 0
    };
    this.searchForm.controls['supplierExist'].reset();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  clearAdvanceFilter() {
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      state: null,
      region: null,
      status: null,
      supplierExist: null,
      page: 0
    };
    this.searchForm.controls['region'].reset();
    this.searchForm.controls['state'].reset();
    this.searchForm.controls['status'].reset();
    this.searchForm.controls['supplierExist'].reset();
    this.prepareSearchCriteriaTags();
    this.search();
  }

  // ======== Supplier Modal =======
  loadSupplier(initialTerm: string) {
    this.supplierList = concat(
      of([]),
      this.supplierSearchInput$.pipe(
        startWith(initialTerm),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => (this.supplierSearchLoading = true)),
        switchMap(term =>
          this.supplierService.searchSupplierByName({ searchCriteria: term }).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.supplierSearchLoading = false;
            })
          )
        )
      )
    );
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  exportStoreSupplier() {
    this.supplierGroupService.export(this.data.groupNo).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName(this.supplierRequestForm.get('name').value));
      },
      () => {}
    );
  }

  exportStoreSupplierTemplate() {
    this.supplierGroupRequestService.exportTemplate().subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
      },
      () => {}
    );
  }

  generatedFileName(supplierGroupName?: string) {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportPr.timeFormat);

    if (supplierGroupName) {
      return `${this.supplierRequestForm.get('name').value}_${formattedDate}.xlsx`;
    } else {
      return `Template_Supplier_Group_${formattedDate}.xlsx`;
    }
  }

  onLoadItemDetail(value: any) {
    if (Array.isArray(value.validations) && value.validations.length > 0) {
      this.importResult = {
        title: `${value.validations.length} Errors Found.`,
        table: value.validations,
        message: null
      };
    } else if (value.groupImportStoreSupplierId) {
      this.isImported = true;
      this.importedGroupSupplierId = value.groupImportStoreSupplierId;
      this.store.dispatch(
        new StoreSupplierRequestListRequest({
          id: this.importedGroupSupplierId,
          searchCriteria: this.criteriaObject,
          isImported: this.isImported
        } as StoreSupplierRequestSearchCriteria)
      );
      this.importResult = {
        title: 'Success',
        table: null,
        message: 'The data have been imported.'
      };
    }
    this.importResultModel.show();
  }

  get form(): FormGroup {
    return this.supplierRequestForm;
  }

  get supplierNameModalForm(): FormGroup {
    return this.supplierNameForm;
  }

  getColorStatus(status: SupplierGroupRequestStatusEnum | SupplierGroupStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  getItemStatus(status: string, previousSupplier: any) {
    if (this.newSupplier && this.newSupplier.supplierName) {
      if (previousSupplier && previousSupplier.supplierName) {
        if (previousSupplier.supplierName !== this.newSupplier.supplierName) {
          return this.translate.instant('SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.EDIT');
        } else {
          return this.translate.instant('SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.NONE');
        }
      } else {
        return this.translate.instant('SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.ADD');
      }
    } else {
      return status
        ? this.translate.instant('SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.' + status)
        : this.translate.instant('SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.NONE');
    }
  }

  doAfterVersionAlertModal() {}

  doAfterSuccessModal() {
    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    }
  }

  getNameByCode(type: string, code: string): string | undefined {
    if (type === 'state') {
      const stateProvince = this.listState.find(state => state.code === code);
      return stateProvince ? stateProvince.nameTh : code;
    } else if (type === 'region') {
      const regionName = this.listRegion.find(region => region.code === code);
      return regionName ? regionName.nameTh : code;
    }
  }

  onClickedOutside(e) {
    if (
      e.target &&
      (e.target.classList.contains('is-highlighted') ||
        e.target.classList.contains('ng-option') ||
        e.target.classList.contains('ng-value-icon') ||
        e.target.classList.contains('ng-option-label'))
    ) {
      return;
    }
    this.isShowAdvanceSearch = false;
  }
}
