import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ModalButtonResponseEnum } from '../../../../enum/modal-button-response.enum';
import { RequestPageModesEnum } from '../../../../enum/request-step.enum';
import { TDStorePage } from '../../../../enum/td-store-page.enum';
import { AlertModalComponent } from '../../../../layouts';
import { ErrorResponse, UpdateOrderScheduleTemplate } from '../../../../models';
import { StoreService } from '../../../../services/store.service';
import { StoreByIdRequestAction } from '../../../../store/actions/store.actions';
import { AppStates } from '../../../../store/state/app.states';
import { removeObjectArray } from '../../../../utils/remove-object-array.util';
import { OrderPolicyComponent } from '../order-policy.component';

@Component({
  selector: 'app-update-order-policy',
  templateUrl: './update-order-policy.component.html',
  styleUrls: ['./update-order-policy.component.scss']
})
export class UpdateOrderPolicyComponent implements OnInit, AfterViewInit {
  @ViewChild('orderPolicy', { static: false }) orderPolicy: OrderPolicyComponent;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() mode: RequestPageModesEnum;
  @Input() page: TDStorePage;
  @Input() storeNo: string;
  @Input() merchant: string;
  @Input() version: string;

  public form: FormGroup;
  private localStore: Observable<any>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly storeService: StoreService
  ) {}

  ngOnInit() {
    this.form = this.createForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
  }

  ngOnDestroy() {}

  ngAfterViewInit() {
    this.orderPolicy.toggleEditOrderPolicy();
  }

  createForm() {
    return this.fb.group({});
  }

  onCancel() {
    this.bsModalRef.hide();
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const data = this.prepareOrderSchedule();

    this.storeService.updateOrderSchedule(data).subscribe(
      () => {
        this.store.dispatch(
          new StoreByIdRequestAction({
            merchant: { merchant: this.merchant },
            storeNo: { storeNo: this.storeNo }
          })
        );

        this.alertSuccessModal();
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  prepareOrderSchedule() {
    const template: UpdateOrderScheduleTemplate = new UpdateOrderScheduleTemplate();
    const formData = this.form.getRawValue();
    const schedules = formData.orderSchedule.orderScheduleList[0].schedules;

    template.no = this.storeNo;
    template.warehouseCode = formData.orderSchedule.orderScheduleList[0].warehouseCode;
    template.minOrder = formData.orderSchedule.orderScheduleList[0].minOrder;
    template.version = this.version;
    template.schedules = removeObjectArray(schedules);

    return template;
  }

  alertSuccessModal() {
    const initialState = {
      title: 'Success',
      message: `Order Schedule has been updated`
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.bsModalRef.hide();
      }
    });
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(error.translateKey, { context: error.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }
}
