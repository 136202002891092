import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { BaseComponent } from '../../../../base/base.component';
import { NewRequestStatusEnum } from '../../../../shared/enum/request-status.enum';
import * as filterDropdown from '../../../../shared/models/list-value/list-key-value.model';
import { Z8ExportCriteria, Z8File, Z8ParameterName } from '../../../../shared/models/z8-parameter.model';
import { MerchantService } from '../../../../shared/services/merchant.service';
import { Z8ParameterService } from '../../../../shared/services/z8-parameter.service';
import { selectZ8ParameterById } from '../../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { dateTimeToDateOnlyString } from '../../../../shared/utils/date-util';

@Component({
  selector: 'app-export-store-stock-planning',
  templateUrl: './export-store-stock-planning.component.html',
  styleUrls: ['./export-store-stock-planning.component.scss']
})
export class ExportStoreStockPlanningComponent extends BaseComponent implements OnInit, OnDestroy {
  private localStore: Observable<any>;
  private parameter: string;

  public storeList: NgOption[];
  public statusList = filterDropdown.z8ParameterStatusFilter.filter(v => Boolean(v.value));
  public dateFormat = environment.dateFormat;
  public maxDate: Date;
  public minDate: Date;

  public exportForm: FormGroup;
  public errorExport: string;
  public exportSuccess = false;
  public files: Array<Z8File>;

  constructor(
    protected fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    protected modalService: BsModalService,
    protected readonly translate: TranslateService,
    private readonly bsModalRef: BsModalRef,
    private readonly merchantService: MerchantService,
    private readonly z8ParameterService: Z8ParameterService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.getStoreList();
    this.initControl();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectZ8ParameterById))
      .pipe(filter(v => Boolean(v)))
      .subscribe(data => {
        this.parameter = data.name;
      });

    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);
  }

  ngOnDestroy() {}

  initControl() {
    this.exportForm = this.fb.group({
      storeCode: [null],
      startDate: [null],
      endDate: [null],
      status: [null]
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  getStoreList() {
    const allStore = {
      code: 'ALL',
      name: 'All Stores',
      storeCodeName: 'All Stores'
    };

    this.merchantService
      .searchStoreByCriteria({
        status: NewRequestStatusEnum.ACTIVE,
        page: 0,
        size: 10000
      })
      .pipe(filter(v => Boolean(v)))
      .subscribe(data => {
        const storeList = data.content.sort((a, b) => a.code.localeCompare(b.code));
        storeList.splice(0, 0, allStore);

        this.storeList = storeList;
      });
  }

  onChangeStartDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeEndDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onExport() {
    const formData = this.exportForm.getRawValue();

    const data = new Z8ExportCriteria({
      startDate: dateTimeToDateOnlyString(formData.startDate),
      endDate: dateTimeToDateOnlyString(formData.endDate),
      parameter: Z8ParameterName[this.getEnumKey()],
      storeCode: formData.storeCode ? formData.storeCode.join(',') : null,
      status: formData.status ? formData.status.join(',') : null
    });

    this.z8ParameterService.exportZ8(data).subscribe(
      response => {
        if (response.length === 1) {
          this.saveFile(response[0].signedUrl);
          this.closeModal();
        } else {
          this.files = response;
        }
      },
      error => {
        this.errorExport = error.error.message;
      }
    );
  }

  doAfterVersionAlertModal() {}

  saveFile(url: string) {
    const link = document.createElement('a');
    link.target = '_self';
    link.href = url;
    link.click();
  }

  onDownloadAll() {
    for (const file of this.files) {
      saveAs(file.signedUrl);
    }
  }

  getEnumKey() {
    return this.parameter.replace(' ', '_').toUpperCase();
  }
}
