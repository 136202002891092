import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProductAssortmentService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import { ProductAssortmentActionTypes, ProductAssortmentSearchLoaded } from '../actions/product-assortment.actions';
export class ProductAssortmentEffects {
    constructor(actions$, productAssortmentService, logger) {
        this.actions$ = actions$;
        this.productAssortmentService = productAssortmentService;
        this.logger = logger;
        this.searchProductAssortments$ = this.actions$.pipe(ofType(ProductAssortmentActionTypes.ProductAssortmentSearchRequested), tap(action => this.logger.debug(`@Effect ${ProductAssortmentActionTypes.ProductAssortmentSearchRequested}: ` + JSON.stringify(action.payload))), switchMap(action => {
            return this.productAssortmentService.searchByCriteria(action.payload).pipe(map(productAssortments => new ProductAssortmentSearchLoaded({ productAssortments })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], ProductAssortmentEffects.prototype, "searchProductAssortments$", void 0);
