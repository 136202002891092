import { createSelector } from '@ngrx/store';
import * as fromShipment from '../reducers/shipment.reducers';
import { AppStates } from '../state/app.states';

const selectShipmentState = (state: AppStates) => state.shipment;

export const selectShipmentContent = createSelector(selectShipmentState, state => {
  return state.shipment;
});

export const selectShipmentOrderSelect = createSelector(selectShipmentState, state => {
  return state.shipment ? state.shipment.deliveryOrders : [];
});

export const selectShipmentDeliveryOrdersCreate = createSelector(selectShipmentState, state => {
  return state.deliveryOrdersCreate;
});

export const selectShipment = createSelector(selectShipmentState, state => {
  return state;
});

export const selectShipmentSaveStatus = createSelector(selectShipmentState, state => {
  return state.saveSuccess;
});

export const selectShipmentList = createSelector(selectShipmentState, fromShipment.selectAll);

export const selectShipmentCriteria = createSelector(selectShipmentState, state => state.criteriaObject);

export const selectShipmentCheckList = createSelector(selectShipmentState, state => state.selected);

export const selectShipmentOrderByIds = createSelector(fromShipment.selectEntities, (entities, props) =>
  props.ids.map(id => entities[id])
);
