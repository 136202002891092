import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { NoSpaceOnlyValidation } from '../../custom-validators/no-space-only-validation';
import { AlertModalComponent } from '../../layouts';
import { ArticlesContent, ArticlesSearchCriteria } from '../../models/articles.model';
import { ArticleService } from '../../services/article.service';
import { AppStates } from '../../store/state/app.states';

@Component({
  selector: 'app-search-multiple-article-modal',
  templateUrl: './search-multiple-article-modal.component.html',
  styleUrls: ['./search-multiple-article-modal.component.scss']
})
export class SearchMultipleArticleModalComponent implements OnInit {
  @ViewChild('searchMultipleArticleModal', { static: false }) searchMultipleArticleModal: ModalDirective;
  @ViewChild('articleInput', { static: false }) articleInput: ElementRef;
  @Output() addItem = new EventEmitter<ArticlesContent[]>();
  @Input() location?: string;
  @Input() locationType?: string;
  @Input() productType?: string[];

  articleForm: FormGroup;
  submittedSearchArticle: boolean;
  hasDuplicatedArticle$: Observable<number>;
  duplicateError$: Observable<string>;
  maxLength = 200;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    private readonly articleService: ArticleService
  ) {
    this.articleForm = this.formBuilder.group({
      article: ['', [Validators.required, NoSpaceOnlyValidation]]
    });
  }

  ngOnInit() {
    this.initCheckDuplicatedBarcode();
  }

  get form() {
    return this.articleForm.controls;
  }

  ngOnDestroy(): void {}

  closeSelectArticleModal() {
    this.articleForm.get('article').reset();
    this.submittedSearchArticle = false;
    this.searchMultipleArticleModal.hide();
  }

  submit() {
    this.submittedSearchArticle = true;
    if (this.articleForm.invalid) {
      return;
    }
    if (this.form.article.value) {
      const result = this.replaceEmptyString(this.form.article.value)
        .split(/\n/g)
        .filter(value => value !== '')
        .filter((value, index, a) => a.indexOf(value) === index);

      if (result.length > this.maxLength) {
        this.alertErrorModal();
      } else if (result.length) {
        this.loadArticleData(result.join('\n'));
      } else {
        this.form.article.setValue('');
      }
    }
  }

  initCheckDuplicatedBarcode() {
    this.hasDuplicatedArticle$ = this.form.article.valueChanges.pipe(
      switchMap(articleValue => {
        const duplicatedAmount = this.duplicateList(articleValue).length;

        this.duplicateError$ = this.translate.get('ERRORS.DUPLICATED_ARTICLE_MESSAGE', {
          value: duplicatedAmount
        });
        return of(duplicatedAmount);
      })
    ) as Observable<number>;
  }

  replaceEmptyString(input: string) {
    return input === null ? '' : input.replace(/^\n+|\n+$/g, '');
  }

  duplicateList(input: string) {
    const duplicatedList = [];
    this.replaceEmptyString(input)
      .split(/\n/g)
      .forEach((item, index, data) => {
        const isDuplicated = data.lastIndexOf(item) !== index;

        if (isDuplicated && !duplicatedList.includes(item)) {
          duplicatedList.push(item);
        }
      });

    return duplicatedList.filter(value => value !== '');
  }

  loadArticleData(articleString: string) {
    const searchCriteria = {
      productType: this.productType,
      location: this.location,
      locationType: this.locationType,
      size: this.maxLength
    } as ArticlesSearchCriteria;
    this.articleService
      .searchArticleListByCriteria(articleString.split(/\n/), searchCriteria)
      .pipe(filter(data => Boolean(data) && Boolean(data.length)))
      .subscribe(response => {
        this.addItem.emit(response);
        this.closeSelectArticleModal();
      });
  }

  alertErrorModal() {
    this.articleForm.get('article').reset();
    this.searchMultipleArticleModal.hide();
    const initialState = {
      title: 'Failed',
      message: `File size limit exceeded (maximum size ${this.maxLength} records).`
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }
}
