<div class="tab-menu d-flex d-inline-flex">
  <a
    class="list-tab-menu px-3 px-md-5 py-2"
    *ngFor="let route of routeLinkTabs"
    [routerLink]="[route.url]"
    [queryParams]="route.queryParams"
    [ngClass]="{ active: handleActiveUrl(route.url, route.tabName), disable: !route.url }"
    (click)="onClickTab(route)"
    >{{ route.tabName }}</a
  >
</div>
