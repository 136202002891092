/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./step-progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./step-progress.component";
var styles_StepProgressComponent = [i0.styles];
var RenderType_StepProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StepProgressComponent, data: {} });
export { RenderType_StepProgressComponent as RenderType_StepProgressComponent };
function View_StepProgressComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "is-active": 0 }), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_v.context.$implicit.value === _co.selectedStepValue)); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.label; _ck(_v, 4, 0, currVal_1); }); }
export function View_StepProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [["class", "list-unstyled multi-steps"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StepProgressComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_StepProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-step-progress", [], null, null, null, View_StepProgressComponent_0, RenderType_StepProgressComponent)), i1.ɵdid(1, 245760, null, 0, i3.StepProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepProgressComponentNgFactory = i1.ɵccf("app-step-progress", i3.StepProgressComponent, View_StepProgressComponent_Host_0, { items: "items", selectedStepValue: "selectedStepValue" }, {}, []);
export { StepProgressComponentNgFactory as StepProgressComponentNgFactory };
