import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from 'apollo-client';
import { Observable } from 'rxjs';
import { billToMasterQuery } from '../gql/billTo.gql';
import { warehouseMasterQuery, warehouseQuery } from '../gql/warehouse.gql';
import {
  BillingMasterData,
  SelectWarehouseList,
  WarehouseListContent,
  WarehouseMasterData,
  WarehousesStoresSearchCriteria
} from '../models/warehouse.model';
import { BaseService } from './base.service';
import { MasterService } from './master.service';

@Injectable()
export class WarehouseService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly masterService: MasterService, private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.warehousesStores;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public listWarehouse(): Observable<ApolloQueryResult<WarehouseListContent>> {
    return this.masterService.watchQuery<WarehouseListContent>({
      query: warehouseQuery
    });
  }

  public getWarehouseMasterData(): Observable<ApolloQueryResult<WarehouseMasterData>> {
    return this.masterService.watchQuery<WarehouseMasterData>({
      query: warehouseMasterQuery
    });
  }

  public getBillingMasterData(): Observable<ApolloQueryResult<BillingMasterData>> {
    return this.masterService.watchQuery<BillingMasterData>({
      query: billToMasterQuery
    });
  }

  public selectWarehouseList(criteria: WarehousesStoresSearchCriteria): Observable<SelectWarehouseList[]> {
    const params = this.getParams(criteria);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
