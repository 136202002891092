import { Action } from '@ngrx/store';
import { PurchaseRequestItem } from '../../models/purchase-request.model';

export enum TdAssortmentOrderActionTypes {
  TD_ASSORTMENT_ORDER_ADD_ITEM = '[TD Assortment Order] Add Item',
  TD_ASSORTMENT_ORDER_UPDATE_ITEM = '[TD Assortment Order] Update Item',
  TD_ASSORTMENT_ORDER_REMOVE_ITEM = '[TD Assortment Order] Remove Item',
  TD_ASSORTMENT_ORDER_RESET = '[TD Assortment Order] Reset',
  PURCHASE_REQUEST_ITEM_SELECTED_LIST = '[TD Assortment Order] Purchase Request Item Selected List',
  TD_ASSORTMENT_ORDER_ADD_ALL_ITEM = '[TD Assortment Order] Add All Item',
  TD_ASSORTMENT_ORDER_INSERT_ALL_ITEM = '[TD Assortment Order] Insert All Item'
}

export class TdAssortmentOrderAddItem implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ITEM;

  constructor(public payload: PurchaseRequestItem) {}
}

export class TdAssortmentOrderUpdateItem implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_UPDATE_ITEM;

  constructor(public payload: PurchaseRequestItem) {}
}

export class TdAssortmentOrderAddAllItem implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_ADD_ALL_ITEM;

  constructor(public payload: PurchaseRequestItem[]) {}
}

export class TdAssortmentOrderInsertAllItem implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_INSERT_ALL_ITEM;

  constructor(public payload: PurchaseRequestItem[]) {}
}

export class TdAssortmentOrderRemoveItem implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class TdAssortmentOrderReset implements Action {
  readonly type = TdAssortmentOrderActionTypes.TD_ASSORTMENT_ORDER_RESET;

  constructor() {}
}

export class PurchaseRequestItemSelectedList implements Action {
  readonly type = TdAssortmentOrderActionTypes.PURCHASE_REQUEST_ITEM_SELECTED_LIST;

  constructor(public payload: PurchaseRequestItem[]) {}
}

export type TdAssortmentOrderActions =
  | TdAssortmentOrderAddItem
  | TdAssortmentOrderRemoveItem
  | TdAssortmentOrderReset
  | PurchaseRequestItemSelectedList
  | TdAssortmentOrderAddAllItem
  | TdAssortmentOrderUpdateItem
  | TdAssortmentOrderInsertAllItem;
