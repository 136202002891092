<div class="modal-content">
  <div class="modal-header pb-4">
    <div class="td-modal-title">
      <h6 class="modal-title pull-left">Z8 Factor</h6>
      <span class="sub-header">{{ data.articleNo }}: {{ data.productName }}</span>
    </div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-2 td-info-modal-header">
      <div class="col-9">
        Factor
      </div>
      <div class="col-3 text-right">
        Value
      </div>
    </div>
    <div class="row mt-2 td-info-modal-data" *ngFor="let detail of z8FactorDetails">
      <div class="col-9">
        <span>{{ detail.label }}</span>
      </div>
      <div class="col-3 text-right">
        <span>{{ data.z8Factor[detail.fieldValue] | numberFormatDisplay: 0:'N/A' }}</span>
      </div>
    </div>
  </div>
</div>
