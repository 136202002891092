import { Action } from '@ngrx/store';
import { ImportOrderRequest, ProductOrderItem } from '../../models/order-request.model';

export enum OrderSelectItemOrderActionTypes {
  ORDER_SELECT_ITEM_ORDER_ADD_ITEM = '[Order Select Item Order] Add Item',
  ORDER_SELECT_ITEM_ORDER_UPDATE_ITEM = '[Order Select Item Order] Update Item',
  ORDER_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM = '[Order Select Item Order] Update ALL Item',
  ORDER_SELECT_ITEM_ORDER_REMOVE_ITEM = '[Order Select Item Order] Remove Item',
  ORDER_SELECT_ITEM_ORDER_RESET = '[Order Select Item Order] Reset',
  ORDER_SELECT_ITEM_ORDER_ADD_ALL_ITEM = '[Order Select Item Order] Add All Item'
}

export class OrderSelectItemOrderAddItem implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_ADD_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderSelectItemOrderUpdateItem implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_UPDATE_ITEM;

  constructor(public payload: ProductOrderItem) {}
}

export class OrderSelectItemOrderUpdateAllItem implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_UPDATE_ALL_ITEM;

  constructor(public payload: Array<ImportOrderRequest>) {}
}

export class OrderSelectItemOrderAddAllItem implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_ADD_ALL_ITEM;

  constructor(public payload: { itemList: ProductOrderItem[]; skipUpdated?: boolean }) {}
}

export class OrderSelectItemOrderRemoveItem implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_REMOVE_ITEM;

  constructor(public payload: string) {}
}

export class OrderSelectItemOrderReset implements Action {
  readonly type = OrderSelectItemOrderActionTypes.ORDER_SELECT_ITEM_ORDER_RESET;

  constructor() {}
}

export type OrderSelectItemOrderActions =
  | OrderSelectItemOrderAddItem
  | OrderSelectItemOrderRemoveItem
  | OrderSelectItemOrderReset
  | OrderSelectItemOrderAddAllItem
  | OrderSelectItemOrderUpdateItem
  | OrderSelectItemOrderUpdateAllItem;
