import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { PortalModule, TaskModuleUrl } from 'src/app/shared/models';
import { ModuleUtil } from 'src/app/shared/utils/module-util';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { ShipToType } from '../../../shared/enum/purchase-order.enum';
import { PurchaseRequestStatusEnum, PurchaseRequestTypeEnum } from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  PurchaseRequestContent,
  PurchaseRequestExportCriteria,
  PurchaseRequestSearchCriteria
} from '../../../shared/models/purchase-request.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import {
  grManagePermissions,
  grViewPermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prDcManagePermissions,
  prManagePermissions,
  prViewPermissions
} from '../../../shared/permissions/permissions';
import { AuthGuardService } from '../../../shared/services';
import { PurchaseRequestService } from '../../../shared/services/purchase-request.service';
import {
  PurchaseRequestCancelRequest,
  PurchaseRequestDeleteRequest,
  PurchaseRequestListRequest
} from '../../../shared/store/actions/purchase-request.actions';
import { WarehouseMasterListRequestAction } from '../../../shared/store/actions/warehouse-master.actions';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { PurchaseRequestState } from '../../../shared/store/reducers/purchase-request.reducers';
import {
  selectPurchaseRequest,
  selectPurchaseRequestList
} from '../../../shared/store/selectors/purchaser-request.selectors';
import { selectWarehouseMaster } from '../../../shared/store/selectors/warehouse-master.selectors';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { PurchaseRequestViewComponent } from '../purchase-request-view/purchase-request-view.component';
@Component({
  selector: 'app-purchase-request-list',
  templateUrl: './purchase-request-list.component.html',
  styleUrls: ['./purchase-request-list.component.scss']
})
export class PurchaseRequestListComponent
  extends BaseSearchComponent<PurchaseRequestSearchCriteria, PurchaseRequestContent, PurchaseRequestState>
  implements OnInit, OnDestroy, AfterViewInit {
  public bsModalRef: BsModalRef;
  public localStore: Observable<any>;

  purchaseRequestTypes: NgOption[];
  warehouses: Array<any>;
  createdPrType: string;

  createPurchaseForm: FormGroup;
  isCreated = false;

  @ViewChild('modalCreatePr', { static: false }) modalCreatePr: ModalDirective;

  public prStatusFilter: NgOption[] = filterDropdown.prStatusFilter;
  public prStatusExportFilter: NgOption[];
  public prTypeFilter = filterDropdown.prTypeFilter;
  public purchaseRequestTypeEnum = PurchaseRequestTypeEnum;
  public purchaseRequestStatusEnum = PurchaseRequestStatusEnum;
  public shipToType = ShipToType;
  public permissionAction = PermissionAction;

  public dateTag: string;
  public dateStringTag: string;
  public prTypeTag: string;
  public prTypeStringTag: string;
  public allowCreateEditPr = false;

  public createdMaxDate: Date;
  public createdMinDate: Date;
  public exportForm: FormGroup;
  public submittedExport: boolean;
  public errorExport: string;
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store.select(selectWarehouseList);

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected spinner: NgxSpinnerService,
    protected authGuardService: AuthGuardService,
    protected purchaseRequestService: PurchaseRequestService,
    protected permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, selectPurchaseRequestList, selectPurchaseRequest);
    super.subscribeForSaveSuccess();
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
    if (this.modalCreatePr) {
      this.modalCreatePr.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetCreatePurchaseForm();
      });
    }
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.prStatusFilter[0].value],
      purchaseType: [null],
      createdDateFrom: [null],
      createdDateTo: [null],
      location: []
    });
    this.createPurchaseForm = this.fb.group({
      purchaseType: [{ value: null, disabled: true }, Validators.required],
      warehouse: [{ value: null, disabled: false }, Validators.required]
    });
  }

  setInitialValue() {
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.createdMaxDate = new Date();
    this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: false, module: 'pr' }));
    this.store.dispatch(new WarehouseMasterListRequestAction());
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectPurchaseRequestList))
      .subscribe(() => (this.currentPage = this.criteriaObject.page + 1));

    this.determineCreatePermission();
    this.createExportForm();
    this.loadMasterData();
    // Clone without all and draft status
    this.prStatusExportFilter = filterDropdown.prStatusFilter.filter(
      status => status.value !== '' && status.value !== 'DRAFT'
    );
  }

  determineCreatePermission() {
    this.allowCreateEditPr = this.authGuardService.checkPermission([...prDcManagePermissions], true);
  }

  loadMasterData() {
    this.localStore
      .pipe(select(selectWarehouseMaster))
      .subscribe(
        whs =>
          (this.warehouses = whs.filter(wh =>
            this.permissionsUtil.checkPermissionByLocation(['pr_inv_m_$1', 'pr_ast_m_$1', 'pr_sto_m_$1'], wh.code)
          ))
      );
  }

  onchangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.createdDateFrom && !formValue.createdDateTo && !formValue.purchaseType) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      purchaseType: formValue.purchaseType,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeWarehouse($event) {
    if ($event) {
      this.createPurchaseForm.controls['purchaseType'].enable();
      this.generatePurchaseTypeList($event);
    }
  }

  generatePurchaseTypeList($event) {
    if (!this.permissionsUtil.checkPermissionByLocation(['pr_inv_m_$1'], $event.code)) {
      this.purchaseRequestTypes = filterDropdown.createPrTypeFilter.filter(
        value => value.value === this.purchaseRequestTypeEnum.SUPPLIER
      );
    } else {
      this.purchaseRequestTypes = filterDropdown.createPrTypeFilter;
    }

    this.createPurchaseForm.controls['purchaseType'].reset();
  }

  clearDateFilter() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearTypeFilter() {
    this.setFirstPage();
    this.searchForm.controls['purchaseType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      purchaseType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['purchaseType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      purchaseType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.prTypeTag = null;
    this.prTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'PR Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.purchaseType && this.criteriaObject.purchaseType.length > 0) {
      this.prTypeStringTag = 'Purchase Type';
      const prType = this.prTypeFilter
        .filter(data => {
          return this.criteriaObject.purchaseType.indexOf(data.value) > -1;
        })
        .map(status => status.label);

      const prTypes = prType.join(', ');
      this.prTypeTag = `"${prTypes}"`;
    }
  }

  search(criteriaObj) {
    this.store.dispatch(new PurchaseRequestListRequest(criteriaObj));
  }

  setRouteTab() {
    const hasPrPermission = this.authGuardService.checkPermission(
      [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions],
      true
    );
    const hasPoPermission = this.authGuardService.checkPermission(
      [...poViewPermissions, ...poManagePermissions, ...grManagePermissions],
      true
    );
    const hasGrPermission = this.authGuardService.checkPermission(
      [...grViewPermissions, ...grManagePermissions],
      true
    );
    if (hasPrPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_REQUEST.PURCHASE_REQUEST',
        url: '/purchase/purchase-request-list'
      });
    }

    if (hasPoPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_ORDER.PURCHASE_ORDER',
        url: '/purchase/purchase-order-list'
      });
    }

    if (hasGrPermission) {
      this.listRoute.push({
        tabName: 'GOODS_RECEIVE.GOODS_RECEIVE',
        url: '/purchase/goods-receive-list'
      });
    }
  }

  hideModalCreateNewPr() {
    this.createdPrType = null;
    this.modalCreatePr.hide();
  }

  showModalCreatePr() {
    this.createdPrType = null;
    this.modalCreatePr.show();
  }

  goToCreate() {
    this.isCreated = true;
    if (this.createPurchaseForm.invalid) {
      return;
    }

    const title = 'Create PR';
    const formValue = this.createPurchaseForm.getRawValue();
    this.modalCreatePr.hide();

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          PurchaseRequestViewComponent,
          {
            mode: 'CREATE',
            type: formValue.purchaseType,
            shipTo: formValue.warehouse,
            shipToCode: formValue.warehouse.code,
            shipToType: ShipToType.WAREHOUSE,
            title
          },
          true
        )
      }
    });
    this.createPurchaseForm.reset();
    this.isCreated = false;
  }

  getColorStatus(status: PurchaseRequestStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  getPurchaseType(purchaseRequest: PurchaseRequestContent) {
    if (purchaseRequest.type === this.purchaseRequestTypeEnum.Z9) {
      return purchaseRequest.advance
        ? `Z9(${this.translate.instant('DAY_INDEX.' + purchaseRequest.orderSchedule)}) - Advance`
        : `Z9(${this.translate.instant('DAY_INDEX.' + purchaseRequest.orderSchedule)})`;
    } else if (purchaseRequest.type === this.purchaseRequestTypeEnum.Z9_EDIT) {
      return `Z9(${this.translate.instant('DAY_INDEX.' + purchaseRequest.orderSchedule)}) - Edit`;
    } else if (purchaseRequest.type === this.purchaseRequestTypeEnum.HOT_PRICE) {
      return 'Hot Price';
    } else if (purchaseRequest.type === this.purchaseRequestTypeEnum.SUPPLIER) {
      return 'Supplier';
    } else if (purchaseRequest.type === this.purchaseRequestTypeEnum.WAREHOUSE) {
      return 'Warehouse';
    }
  }

  goToView(purchaseRequest: PurchaseRequestContent) {
    this.displayPurchaseRequestViewModal(purchaseRequest, 'VIEW');
  }

  goToEdit(purchaseRequest: PurchaseRequestContent) {
    this.displayPurchaseRequestViewModal(purchaseRequest, 'EDIT');
  }

  displayPurchaseRequestViewModal(purchaseRequest: PurchaseRequestContent, action: string) {
    let title = 'Create PR';
    if ('VIEW' === action) {
      title = 'View PR';
    } else if ('EDIT' === action) {
      title = 'Edit PR';
    }

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: ModuleUtil.InitialState(
        purchaseRequest,
        PurchaseRequestViewComponent,
        action,
        title,
        TaskModuleUrl.PURCHASE_REQUEST,
        PortalModule.PURCHASE_REQUEST
      )
    });
  }

  onDeletePr(prId: string) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete this PR?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(new PurchaseRequestDeleteRequest(prId));
        }
      });
  }

  onCancelPr(id: string, docNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel PR Number "${docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseRequestCancelRequest({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showHistory(purchaseRequest: PurchaseRequestContent) {
    const initialState = {
      title: 'History',
      historyHeader: `PR Number: ${purchaseRequest.docNo}`,
      auditLogs: purchaseRequest.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      purchaseType: [null],
      status: [null],
      prCreatedDateFrom: [null, Validators.required],
      prCreatedDateTo: [null, Validators.required],
      code: [null]
    });
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();
    this.purchaseRequestService.exportPr(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
        this.doSearch(this.criteriaObject);
        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  prepareExportRequestData(formData): PurchaseRequestExportCriteria {
    const createdDateFrom = dateToStringCriteria(formData.prCreatedDateFrom);
    const createdDateTo = dateToStringCriteria(formData.prCreatedDateTo, false);
    const statuses = this.getValue(formData.status);
    const purchaseTypes = this.getValue(formData.purchaseType);
    const locations = this.getValue(formData.code);

    return new PurchaseRequestExportCriteria({
      createdDateFrom,
      createdDateTo,
      statuses,
      purchaseTypes,
      locations
    });
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportPr.timeFormat);

    return `${environment.fileName.exportPr.prefix}${formattedDate}.xlsx`;
  }

  resetExportForm() {
    this.exportForm.controls['prCreatedDateFrom'].reset();
    this.exportForm.controls['prCreatedDateTo'].reset();
    this.exportForm.controls['status'].reset();
    this.exportForm.controls['purchaseType'].reset();
    this.exportForm.controls['code'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }

  resetCreatePurchaseForm() {
    this.isCreated = false;
    this.createPurchaseForm.reset();
    this.createPurchaseForm.controls['purchaseType'].disable();
  }

  clearError() {
    this.exportForm.controls['prCreatedDateFrom'].setErrors(null);
    this.exportForm.controls['prCreatedDateTo'].setErrors(null);
    this.errorExport = null;
  }

  alertErrorModal(errorResponse) {
    if (errorResponse && errorResponse.error) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.error.messsage
      };
      if (errorResponse.status === 404) {
        this.errorExport = this.translate.instant('ERRORS.NO_TRANSACTION');
      } else if (['00008', '08023'].includes(errorResponse.error.code)) {
        this.errorExport = errorResponse.error.message;
      } else {
        /** any unhandle error goes modal here **/
        initialState.message = errorResponse.error.message;

        this.modalService.show(AlertModalComponent, {
          initialState
        });
      }
    }
  }

  onChangePRCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangePRCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
    }
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !(formValues.prCreatedDateFrom || formValues.prCreatedDateTo);
  }
}
