<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="(storeView$ | async)?.status === requestStatus.INACTIVE && hasEditPermission()"
    (click)="onActivateStore(data.storeNo)"
  >
    <em class="icon-activate"></em>
    Activate
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      (storeView$ | async)?.status === requestStatus.ACTIVE &&
      hasEditPermission() &&
      data.mode === requestPageModesEnum.REQUEST_VIEW
    "
    (click)="onDeactivateStore(data.storeNo)"
  >
    <em class="icon-deactivate"></em>
    Deactivate
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Store ID:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (storeView$ | async)?.no | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="status !== 'D'; else edit">
      <div class="info-header-label">
        <span>Store Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((storeView$ | async)?.status)" class="store-status">
          {{ 'STORE.STATUS.' + (storeView$ | async)?.status | translate }}
        </span>
      </div>
    </div>
    <ng-template #edit>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="getColorStatus('draft')" class="store-status">
            {{ 'STORE_REQUEST.STATUS.' + status | translate }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="information-container">
    <div class="row merchant-info">
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant ID</span>
          <span class="info-header-value">{{ (storeView$ | async)?.merchantId | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Tax ID/ID card</span>
          <span class="info-header-value">{{ (storeView$ | async)?.taxId | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant Name</span>
          <span class="info-header-value">{{ (storeView$ | async)?.merchantName | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Merchant Type</span>
          <span class="info-header-value">{{ 'STORE_TYPE.' + (storeView$ | async)?.merchantType | translate }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Contact Name</span>
          <span class="info-header-value">{{ (storeView$ | async)?.contactName | dashDisplay }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Mobile Phone</span>
          <span class="info-header-value">{{ (storeView$ | async)?.mobilePhone | dashDisplay }}</span>
        </div>
      </div>
    </div>
  </div>

  <div [formGroup]="storeForm">
    <!-- Store Profile -->
    <div class="row section-header justify-content-between">
      <span>Store Profile</span>
    </div>
    <app-store-profile
      #storeProfile
      [parentForm]="storeForm"
      [submitted]="submitted"
      [mode]="data.mode"
      [page]="merchantPage"
    ></app-store-profile>

    <hr class="section-divider" />

    <div class="row mt-4 mx-0">
      <!-- Order Policy -->
      <div class="col-lg-7 pl-0">
        <div class="row mb-3 mr-md-0 section-header">
          <span id="ORDER_POLICY">Order Schedule(for supply chain)</span>
        </div>

        <div class="d-flex mb-3">
          <button
            type="button"
            class="btn btn-standard d-flex align-items-center"
            *appHasPermission="['merchant_order_m']"
            (click)="onUpdateOrderSchedule()"
          >
            <em class="d-inline-block icon-calendar mr-1"></em>
            Update Order Schedule
          </button>
        </div>

        <div class="container-fluid">
          <app-order-policy
            #orderPolicy
            [parentForm]="storeForm"
            [submitted]="submitted"
            [mode]="data.mode"
            [page]="merchantPage"
          ></app-order-policy>
        </div>
      </div>

      <!-- POS Terminal -->
      <div class="pos-container col-lg-5 px-0 pl-lg-3">
        <div class="row mb-3 section-header">
          <span id="POS_TERMINAL">POS Terminal</span>
        </div>

        <app-pos-terminal #posTerminal [data]="data"> </app-pos-terminal>
      </div>
    </div>
  </div>

  <!--footer-->
  <ng-container *ngIf="hasAtLeastOnePermission()">
    <ng-container *ngIf="requestPageModesEnum.REQUEST_EDIT === data.mode; else otherMode">
      <div class="fixed-row-bottom form-row">
        <div class="float-right" *ngIf="hasSubmitPermission()">
          <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
          <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
            Submit
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #otherMode>
      <ng-container>
        <div class="fixed-row-bottom form-row">
          <div class="float-left"></div>
          <div class="float-right">
            <span *ngIf="hasEditPermission() && (storeView$ | async)?.status !== requestStatus.INACTIVE" class="mr-2">
              <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
            </span>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </ng-container>
  <!--End footer-->
</div>
