import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ClaimRequestStatusEnum } from '../../../shared/enum/claim-status.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { PortalModule, TaskModuleUrl } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import {
  ClaimRequestContent,
  ClaimRequestExportCriteria,
  ClaimRequestSearchCriteria
} from '../../../shared/models/claim-request.model';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { ClaimService } from '../../../shared/services/claim.service';
import { ClaimRequestListRequest } from '../../../shared/store/actions/claim-request.actions';
import { ClaimRequestState } from '../../../shared/store/reducers/claim-request.reducers';
import { selectClaimRequest, selectClaimRequestList } from '../../../shared/store/selectors/claim-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { ClaimRequestViewComponent } from '../claim-request-view/claim-request-view.component';

@Component({
  selector: 'app-claim-request-list',
  templateUrl: './claim-request-list.component.html',
  styleUrls: ['./claim-request-list.component.scss']
})
export class ClaimRequestListComponent
  extends BaseSearchComponent<ClaimRequestSearchCriteria, ClaimRequestContent, ClaimRequestState>
  implements OnInit {
  public bsModalRef: BsModalRef;
  public localStore: Observable<any>;
  public claimRequestStatusList: Array<{ value: string; label: string }>;
  public claimStatusExportList: NgOption[];

  public createdMaxDate: Date;
  public createdMinDate: Date;
  public requestedDateTag: string;
  public requestedDateStringTag: string;

  public exportForm: FormGroup;
  public submittedExport: boolean;
  public errorExport: string;
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    private authGuardService: AuthGuardService,
    private claimService: ClaimService,
    private spinner: NgxSpinnerService,
    private readonly translate: TranslateService
  ) {
    super(store, modalService, selectClaimRequestList, selectClaimRequest);
    super.subscribeForSaveSuccess();
    this.claimRequestStatusList = filterDropdown.claimRequestStatusFilter;
  }

  ngAfterViewInit(): void {
    if (this.exportModal) {
      this.exportModal.onHidden.pipe(untilComponentDestroyed(this)).subscribe(() => {
        this.resetExportForm();
      });
    }
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.claimRequestStatusList[0].value],
      requestedDateFrom: [null],
      requestedDateTo: [null]
    });
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      status: [null],
      createdDateFrom: [null, Validators.required],
      createdDateTo: [null, Validators.required]
    });
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doDestroy() {}

  doInit() {
    // Check permission
    this.createExportForm();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    // Clone without all
    this.claimStatusExportList = filterDropdown.claimRequestStatusFilter.filter(status => status.value !== '');
  }

  goToView(claimRequest: ClaimRequestContent) {
    const title =
      ClaimRequestStatusEnum.WAITING.includes(claimRequest.claimRequestStatus) &&
      this.authGuardService.checkPermission(['cr_app'])
        ? 'Approve Claim Request'
        : 'View Claim Request';

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: ModuleUtil.InitialState(
        claimRequest,
        ClaimRequestViewComponent,
        '',
        title,
        TaskModuleUrl.CLAIM_REQUEST,
        PortalModule.CLAIM
      )
    });
  }

  clearFilterRequestedDate() {
    this.setFirstPage();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: null,
      requestedDateTo: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.requestedDateFrom && !formValue.requestedDateTo) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: dateToStringCriteria(formValue.requestedDateFrom),
      requestedDateTo: dateToStringCriteria(formValue.requestedDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.requestedDateTag = null;
    this.requestedDateStringTag = null;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.requestedDateStringTag = requestedDate.dateStringTag;
    this.requestedDateTag = requestedDate.dateTag;
  }

  search(criteriaObj) {
    this.store.dispatch(new ClaimRequestListRequest(criteriaObj));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.pageSize = 20;
    this.currentPage = 1;
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.createdMaxDate = new Date();
    this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
  }

  getColorStatus(status: ClaimRequestStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  setRouteTab() {
    this.listRoute.push({
      tabName: 'CLAIM_REQUEST.CLAIM_REQUEST_LIST',
      url: '/order/claim-request-list'
    });
  }

  showHistory(claimRequest: ClaimRequestContent) {
    const initialState = {
      title: 'History',
      historyHeader: `Claim Request No.: ${claimRequest.claimRequestNo}`,
      historyType: HistoryType.CLAIM_REQUEST,
      auditLogs: claimRequest.histories
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  get exportFormInValid() {
    const formValues = this.exportForm.value;
    return !(formValues.createdDateFrom || formValues.createdDateTo);
  }

  onExport() {
    this.submittedExport = true;

    if (this.exportFormInValid) {
      return;
    }

    const formData = this.exportForm.getRawValue();
    const request = this.prepareExportRequestData(formData);

    this.clearError();
    this.claimService.exportClaimRequest(request).subscribe(
      response => {
        const blob = new Blob([response]);

        saveAs(blob, this.generatedFileName());
        this.doSearch(this.criteriaObject);
        this.resetExportForm();
        this.exportModal.hide();
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  generatedFileName() {
    const date = new Date();
    const formattedDate = moment(date).format(environment.fileName.exportClaim.timeFormat);

    return `${environment.fileName.exportClaim.prefix}${formattedDate}.xlsx`;
  }

  clearError() {
    this.exportForm.controls['createdDateFrom'].setErrors(null);
    this.exportForm.controls['createdDateTo'].setErrors(null);
    this.errorExport = null;
  }

  alertErrorModal(errorResponse) {
    if (errorResponse.code && errorResponse.message) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };
      if (['00001', '00004', '00006', '08023'].includes(errorResponse.code)) {
        this.errorExport = initialState.message;
      } else {
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });

        const modal = alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
          if (modal) {
            modal.unsubscribe();
          }
        });
      }
    }
  }

  onChangeRequestedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.createdMinDate = new Date(value);
    } else {
      this.createdMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeRequestedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.createdMaxDate = new Date(value);
    } else {
      this.createdMaxDate = new Date();
      this.createdMaxDate.setDate(this.createdMaxDate.getDate() + 365);
    }
  }

  prepareExportRequestData(formData): ClaimRequestExportCriteria {
    const createdDateFrom = dateToStringCriteria(formData.createdDateFrom);
    const createdDateTo = dateToStringCriteria(formData.createdDateTo, false);
    const status = this.getValue(formData.status);

    const exportCriteria = new ClaimRequestExportCriteria({
      createdDateFrom,
      createdDateTo,
      status
    });

    return exportCriteria;
  }

  resetExportForm() {
    this.exportForm.controls['createdDateFrom'].reset();
    this.exportForm.controls['createdDateTo'].reset();
    this.exportForm.controls['status'].reset();
    this.submittedExport = false;
    this.errorExport = null;
  }
}
