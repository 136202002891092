<div class="form-group mb-1 admin-form">
  <label>Deliver To<span class="text-danger">*</span></label>
</div>
<div class="dual-list">
  <div
    class="listbox"
    *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) && !useCJProduct"
  >
    <form class="filter">
      <input
        class="form-control filter"
        name="filterSource"
        placeholder="&#xe018; Search Location"
        [(ngModel)]="available.picker"
        (ngModelChange)="onFilter(available)"
        [disabled]="disabled"
        [ngClass]="{ 'bg-h-change': isChange }"
      />
    </form>
    <div class="record-picker">
      <ul
        [ngStyle]="{ 'max-height': height, 'min-height': height }"
        [ngClass]="{ over: available.dragOver, disabled: disabled }"
        (drop)="drop($event, confirmed)"
        (dragover)="allowDrop($event, available)"
        (dragleave)="dragLeave()"
      >
        <li
          *ngFor="let item of available.sift; let idx = index"
          (click)="selectItem(available.pick, item); shiftClick($event, idx, available, item)"
          [ngClass]="{ selected: isItemSelected(available.pick, item), disabled: disabled }"
          [draggable]="!disabled"
          (dragstart)="drag($event, item, available)"
          (dragend)="dragEnd(available)"
        >
          <label>{{ item._name }}</label>
        </li>
      </ul>
    </div>
  </div>

  <div
    class="buttonbox"
    *ngIf="![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) && !useCJProduct"
  >
    <button
      type="button"
      class="btn btn-standard btn-block"
      (click)="moveItem(available, confirmed)"
      [disabled]="available.pick.length === 0 || disabled"
    >
      <em class="icon-next"></em>
    </button>
    <button
      type="button"
      class="btn btn-standard btn-block"
      (click)="moveAll()"
      [disabled]="isAllSelected(available) || disabled"
    >
      <em class="icon-next-to-end"></em>
    </button>
    <button
      type="button"
      class="btn btn-standard btn-block"
      (click)="moveItem(confirmed, available)"
      [disabled]="confirmed.pick.length === 0 || disabled"
    >
      <em class="icon-prev"></em>
    </button>
    <button
      type="button"
      class="btn btn-standard btn-block"
      (click)="removeAll()"
      [disabled]="isAllSelected(confirmed) || disabled"
    >
      <em class="icon-prev-to-first"></em>
    </button>
  </div>
  <div class="listbox">
    <div
      class="form-control py-2"
      [ngClass]="{
        'is-invalid': isSubmitAddSupplierPrice && confirmed.list.length === 0,
        'text-left':
          [assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) || useCJProduct,
        'text-center':
          ![assortmentPageMode.ASSORTMENT_VIEW, assortmentPageMode.REQUEST_VIEW].includes(mode) && !useCJProduct,
        'bg-h-change': isChange
      }"
    >
      Selected Location: {{ confirmed.list.length }}
    </div>
    <div class="record-picker" [ngClass]="{ 'is-invalid': isSubmitAddSupplierPrice && confirmed.list.length === 0 }">
      <ul
        [ngStyle]="{ 'max-height': height, 'min-height': height }"
        [ngClass]="{ over: confirmed.dragOver, disabled: disabled, 'bg-h-change': isChange }"
        (drop)="drop($event, available)"
        (dragover)="allowDrop($event, confirmed)"
        (dragleave)="dragLeave()"
      >
        <li
          *ngFor="let item of confirmed.sift; let idx = index"
          (click)="selectItem(confirmed.pick, item); shiftClick($event, idx, confirmed, item)"
          [ngClass]="{ selected: isItemSelected(confirmed.pick, item), disabled: disabled }"
          [draggable]="!disabled"
          (dragstart)="drag($event, item, confirmed)"
          (dragend)="dragEnd(confirmed)"
        >
          <label>{{ item._name }}</label>
        </li>
      </ul>
    </div>
    <div *ngIf="isSubmitAddSupplierPrice && confirmed.list.length === 0" class="invalid-feedback">
      <div *ngIf="isSubmitAddSupplierPrice && confirmed.list.length === 0">
        {{ 'ERRORS.REQUIRED_AT_LEAST_ONE_WAREHOUSE' | translate }}
      </div>
    </div>
  </div>
</div>
