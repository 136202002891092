import gql from 'graphql-tag';

export class ProductGradings {
  public code: string;
  public beautyPct: number;
  public firstLotPct: number;
}

export const productGradingQuery = gql`
  query {
    productGradings(sort: { orderBy: CODE }) {
      code
      beautyPct
      firstLotPct
    }
  }
`;
