<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-home"></em>
    <h4>Home</h4>
  </div>
  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-end">
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
      <div class="filter-dropdown flex-item form-group ml-md-2">
        <ng-select
          [items]="portalModuleList"
          [searchable]="false"
          [clearable]="false"
          (change)="onChangeModule($event)"
          class="select-status"
          bindLabel="label"
          bindValue="value"
          formControlName="module"
        >
        </ng-select>
      </div>
    </div>
  </form>
  <ng-container *ngIf="(listState$ | async).totalElements == 0 && !this.hasSearchCriteria">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="no item" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements == 0 && this.hasSearchCriteria">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-10">Document Number</th>
            <th class="text-center w-10">Module</th>
            <th class="text-center w-20">Subject</th>
            <th class="text-center w-15">Request By</th>
            <th class="text-center w-10">Received Date</th>
            <th class="text-center w-10">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.requestNo }}
            </td>
            <td (click)="goToView(item)">
              {{ 'MY_TASK.' + item.module | translate }}
            </td>
            <td (click)="goToView(item)">
              {{ item.subject }}
            </td>
            <td (click)="goToView(item)">
              {{ item.requesterName }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item.requestedDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(item)" class="tasks-status" [ngClass]="item.requestStatus | lowercase">
              {{ this.getDisplayStatus(item) | translate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
