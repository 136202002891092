import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PromotionExportCriteria,
  PromotionListResponse,
  PromotionSearchCriteria,
  PromotionTheme,
  RequestWithComment
} from '../models';
import { BaseService } from './base.service';

@Injectable()
export class PromotionService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.promotions;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: PromotionSearchCriteria): Observable<PromotionListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  searchTheme(criteria: any): Observable<PromotionTheme[]> {
    const url = this.getFullUrl(this.envService.themesList);
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  addTheme(theme: PromotionTheme): Observable<any> {
    const url = this.getFullUrl(this.envService.themes);
    return this.http.post<any>(url, theme, {
      headers: this.headers,
      observe: 'body'
    });
  }

  getPromotionById(code: string): Observable<any> {
    const url = this.getFullUrl(this.envService.get, { id: code });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  /**
   * @description For cancel promotion
   */
  cancel(data: RequestWithComment) {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<any>(url, data, {
      headers: this.loaderHeaders()
    });
  }

  exportPromotion(exportCriteria: PromotionExportCriteria): Observable<any> {
    const params = this.getParams(exportCriteria, true);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
