import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter, withLatestFrom } from 'rxjs/operators';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { shelfTypeStatusEnum } from '../../../../shared/enum/request-status.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { ShelfDetailRequest, ShelfItems } from '../../../../shared/models';
import { ConfirmModal } from '../../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import {
  OrderRequestGenerateShelfItemsRequestAction,
  OrderRequestShelfSelectionRequestAction,
  OrderRequestShelfSelectionResetAction
} from '../../../../shared/store/actions/order-request.actions';
import { ShelfDetailsResponseAction } from '../../../../shared/store/actions/shelf-details.actions';
import { selectOrderRequestShelfList } from '../../../../shared/store/selectors/order-request.selector';
import { selectAllShelfDetails } from '../../../../shared/store/selectors/shelf-details.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-select-shelf-modal',
  templateUrl: './select-shelf-modal.component.html',
  styleUrls: ['./select-shelf-modal.component.scss']
})
export class SelectShelfModalComponent implements OnInit, OnDestroy {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    storeNo: string;
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly translate: TranslateService,
    private readonly element: ElementRef
  ) {}

  get form(): FormGroup {
    return this.shelfListForm;
  }

  get formShelfItems(): FormArray {
    return this.shelfListForm.get('shelfItems') as FormArray;
  }

  public localStore: Observable<any>;
  public shelfListForm: FormGroup;
  public shelfListDetails: ShelfDetailRequest[];
  public submitted = false;

  public shelfList$: Observable<ShelfItems[]>;
  public shelfDetails$: Observable<ShelfDetailRequest[]>;

  ngOnDestroy(): void {
    this.store.dispatch(new OrderRequestShelfSelectionResetAction());
  }

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.shelfList$ = this.localStore.pipe(
      select(selectOrderRequestShelfList),
      filter(response => Boolean(response)),
      untilComponentDestroyed(this)
    );
    this.shelfDetails$ = this.localStore.pipe(
      select(selectAllShelfDetails),
      filter(response => Boolean(response)),
      untilComponentDestroyed(this)
    );

    this.store.dispatch(new OrderRequestShelfSelectionRequestAction(shelfTypeStatusEnum.ACTIVE));

    this.createForm();
    this.initialShelfData();
  }

  createForm() {
    this.shelfListForm = this.fb.group({
      shelfItems: this.fb.array([])
    });
  }

  initialShelfData() {
    this.shelfList$.pipe(withLatestFrom(this.shelfDetails$)).subscribe(([shelfList, shelfListDetails]) => {
      if (shelfList) {
        shelfList.forEach(item => {
          if (item.options && item.options.length > 0) {
            this.formShelfItems.push(this.createShelfForm(item));
          }
        });
      }

      if (shelfListDetails) {
        this.shelfListDetails = shelfListDetails;
        this.shelfListDetails.forEach(selected => {
          this.formShelfItems.controls.forEach(listItem => {
            const selectedShelfType = listItem.get('shelfTypeCode').value === selected.shelfTypeCode;
            const shelfOptions: [] = listItem.get('shelfOptions').value;
            const checkMappingOptions = element => element.name === selected.shelfOption;
            const hasSelectedOption = shelfOptions.some(checkMappingOptions);
            if (selectedShelfType && hasSelectedOption) {
              listItem.get('selected').setValue(true);
              listItem.get('selected').updateValueAndValidity();

              listItem.get('shelfCode').setValue(selected.shelfCode);
              listItem.get('shelfCode').updateValueAndValidity();

              listItem.get('shelfOption').setValue(selected.shelfOption);
              listItem.get('shelfOption').updateValueAndValidity();
            }
          });
        });
      }
    });
  }

  createShelfForm(shelfListItem: ShelfItems): FormGroup {
    const initialNullDisabled = [{ value: null, disabled: true }];

    if (shelfListItem) {
      return this.fb.group({
        shelfTypeCode: shelfListItem.code,
        shelfType: shelfListItem.name,
        shelfOptions: [shelfListItem.options],
        shelfCode: initialNullDisabled,
        shelfOption: initialNullDisabled,
        selected: false
      });
    }

    return null;
  }

  getShelfOption(index: number) {
    return this.formShelfItems.at(index).get('shelfOptions').value;
  }

  isSelectedShelf(item: AbstractControl) {
    return item.get('selected').value ? item.get('selected').value : false;
  }

  getSelectedItem(): number {
    const selectedItems = this.formShelfItems.controls.filter(item => {
      return item.get('selected').value;
    });

    return selectedItems ? selectedItems.length : 0;
  }

  selectShelf(index: number) {
    const selected = this.formShelfItems.at(index).get('selected').value || false;
    const isSelectedPreviously = this.shelfListDetails.find(
      x => x.shelfCode === this.formShelfItems.at(index).get('shelfCode').value
    );

    if (select && isSelectedPreviously) {
      this.alertModal('Items in this shelf have been generated. Please delete all items first.');

      return;
    }

    this.formShelfItems
      .at(index)
      .get('selected')
      .setValue(!selected);

    this.formShelfItems
      .at(index)
      .get('selected')
      .updateValueAndValidity();

    if (!selected) {
      if (this.getShelfOption(index) && this.getShelfOption(index).length === 1) {
        this.formShelfItems
          .at(index)
          .get('shelfCode')
          .setValue(this.getShelfOption(index)[0].code);

        this.formShelfItems
          .at(index)
          .get('shelfOption')
          .setValue(this.getShelfOption(index)[0].name);
      } else {
        this.formShelfItems
          .at(index)
          .get('shelfCode')
          .enable();

        this.formShelfItems
          .at(index)
          .get('shelfCode')
          .setValidators(Validators.required);
      }
    } else {
      this.formShelfItems
        .at(index)
        .get('shelfCode')
        .setValue(null);

      this.formShelfItems
        .at(index)
        .get('shelfOption')
        .setValue(null);

      this.formShelfItems
        .at(index)
        .get('shelfCode')
        .disable();

      this.formShelfItems
        .at(index)
        .get('shelfCode')
        .clearValidators();
    }

    this.formShelfItems
      .at(index)
      .get('shelfCode')
      .updateValueAndValidity();

    this.formShelfItems
      .at(index)
      .get('shelfOption')
      .updateValueAndValidity();
  }

  onBack() {
    if (this.shelfListDetails.length !== this.getSelectedItem()) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const selectedItem: ShelfDetailRequest[] = this.prepareShelfRequestData(this.form.getRawValue());

    if (selectedItem.length > 0) {
      const currentShelfNo = this.shelfListDetails.map(item => item.shelfNo);
      const selectedShelfNo = selectedItem.map(item => item.shelfNo);

      // Get only adding array value from the current
      const differenceShelfNo = selectedShelfNo.filter(x => !currentShelfNo.includes(x));

      const selectedItems = [...selectedItem, ...this.shelfListDetails].filter(
        (item, i, array) => array.findIndex(temp => temp.shelfNo === item.shelfNo) === i
      );

      this.store.dispatch(new ShelfDetailsResponseAction(selectedItems));

      if (differenceShelfNo.length > 0) {
        const result = { response: true, errorResponse: null };
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.NEXT, result: result });

        this.store.dispatch(
          new OrderRequestGenerateShelfItemsRequestAction({
            shelfNo: differenceShelfNo,
            storeNo: this.data.storeNo
          })
        );
      } else {
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
      }
    }
  }

  prepareShelfRequestData(formData): ShelfDetailRequest[] {
    return formData.shelfItems
      .filter(item => item.selected)
      .map(item => {
        const shelfOptionSelected = item.shelfCode ? item.shelfOptions.find(x => x.code === item.shelfCode) : '';

        return {
          shelfNo: shelfOptionSelected.no,
          shelfTypeCode: item.shelfTypeCode,
          shelfType: item.shelfType,
          shelfCode: item.shelfCode,
          shelfOption: shelfOptionSelected.name
        } as ShelfDetailRequest;
      });
  }

  alertModal(message: string) {
    const initialState = {
      title: 'Alert',
      message
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  disableTableScroll(toDisable: boolean) {
    const el: HTMLInputElement = this.element.nativeElement.querySelector('.table-scroll');
    if (toDisable) {
      el.setAttribute('style', 'overflow-y: hidden');
    } else {
      el.removeAttribute('style');
    }
  }
}
