import { Action } from '@ngrx/store';
import { OrderContent, OrderListResponse, OrderSearchCriteria } from '../../models/order.model';

export enum OrderActionTypes {
  OrderListSearchRequested = '[Order List Page] Order List Search Requested',
  OrderListSearchLoaded = '[Order List API] Order List Search Loaded',
  OrderViewRequested = '[Order View Page] Order View Requested',
  OrderViewLoaded = '[Order API] Order View Loaded'
}

export class OrderListSearchRequested implements Action {
  readonly type = OrderActionTypes.OrderListSearchRequested;
  constructor(public payload: OrderSearchCriteria) {}
}

export class OrderListSearchLoaded implements Action {
  readonly type = OrderActionTypes.OrderListSearchLoaded;
  constructor(public payload: { orders: OrderListResponse }) {}
}

export class OrderViewRequested implements Action {
  readonly type = OrderActionTypes.OrderViewRequested;
  constructor(public payload: string) {}
}

export class OrderViewLoaded implements Action {
  readonly type = OrderActionTypes.OrderViewLoaded;
  constructor(public payload: OrderContent) {}
}

export type OrderActions = OrderListSearchRequested | OrderListSearchLoaded | OrderViewRequested | OrderViewLoaded;
