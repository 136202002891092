import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Menu } from '../../models/menu.model';
import { MenuActions, MenuActionTypes } from '../actions/menu.action';

export interface MenuState extends EntityState<Menu> {
  menus: Menu[];
}

export const adapter: EntityAdapter<Menu> = createEntityAdapter<Menu>();

export const initialMenuState: MenuState = adapter.getInitialState({
  menus: []
});

export function menuReducers(state = initialMenuState, action: MenuActions): MenuState {
  if (action.type === MenuActionTypes.MenuLoaded) {
    return {
      ...state,
      menus: action.payload
    };
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
