import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { filter as lodashFilter, find } from 'lodash';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { BaseComponent } from '../../../base/base.component';
import { DeliveryOrderModeEnum, DeliveryOrderStatusEnum } from '../../../shared/enum/delivery-order-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ErrorResponse } from '../../../shared/models';
import {
  DeliveryOrder,
  DeliveryOrderItem,
  DeliveryOrderPrintCriteria,
  DeliveryOrderSearchCriteria
} from '../../../shared/models/delivery-order.model';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { DeliveryOrderService } from '../../../shared/services/delivery-order.service';
import {
  DeliveryOrderListRequestAction,
  DeliveryOrderReset,
  DeliveryOrderSaveRequested,
  DeliveryOrderSaveSuccess,
  DeliveryOrderSubmitRequested,
  DeliveryOrderViewRequested
} from '../../../shared/store/actions/delivery-order.actions';
import {
  selectDeliveryOrderCriteria,
  selectDeliveryOrderSaveStatus,
  selectEditedDeliveryOrder
} from '../../../shared/store/selectors/delivery-order.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';

@Component({
  selector: 'app-delivery-order-edit',
  templateUrl: './delivery-order-edit.component.html',
  styleUrls: ['./delivery-order-edit.component.scss']
})
export class DeliveryOrderEditComponent extends BaseComponent implements OnInit, OnDestroy {
  deliveryOrder: DeliveryOrder;
  draftList: NgOption[];
  selectedDraftNo: number;
  maxDraftNo: number;
  storeFullName: string;
  selectedItemNoList: {};
  isDisableSplitDo: boolean;
  noSelectedDraft: boolean;
  isEditMode: boolean;
  isSubmit: boolean;
  deliveryOrderCriteriaList: DeliveryOrderSearchCriteria;
  public localStore: Observable<any>;
  public currentPage = 1;
  public pageSize = 20;

  public deliveryOrder$: Observable<DeliveryOrder>;
  public permissionAction = PermissionAction;
  private allowManageLogisticPermissions: boolean;
  private allowManageDOPermissions: boolean;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();
  @Output() data: any;
  @ViewChild('modalSplitDo', { static: false }) modalSplitDo: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    private readonly logger: NGXLogger,
    private readonly translate: TranslateService,
    private readonly deliveryOrderService: DeliveryOrderService,
    private readonly spinner: NgxSpinnerService,
    private readonly authGuardService: AuthGuardService,
    public permissionUtil: PermissionsUtil
  ) {
    super(store, modalService, true);
  }

  ngOnInit() {
    this.isEditMode = this.data.mode === DeliveryOrderModeEnum.EDIT_MODE || false;
    this.selectedItemNoList = {};
    this.isDisableSplitDo = true;

    // Load DO details
    this.store.dispatch(new DeliveryOrderViewRequested(this.data.id));

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.deliveryOrder$ = this.localStore.pipe(
      select(selectEditedDeliveryOrder),
      distinctUntilChanged(),
      filter(deliveryOrder => deliveryOrder !== null)
    );

    this.localStore
      .pipe(
        select(selectDeliveryOrderSaveStatus),
        distinctUntilChanged(),
        tap(isSuccess => {
          if (isSuccess) {
            let message = null;
            if (this.isSubmit) {
              message =
                this.deliveryOrder.doStatus === DeliveryOrderStatusEnum.DRAFT
                  ? 'The request has been created.'
                  : 'The request has been submitted.';
            } else {
              message = 'The request has been saved.';
            }
            const alertModal = this.modalService.show(AlertModalComponent, {
              initialState: {
                title: 'Success',
                message,
                routerLink: '/order/delivery-order-list'
              },
              backdrop: 'static'
            });

            alertModal.content.action
              .pipe(untilComponentDestroyed(this))
              .subscribe((result: ModalButtonResponseEnum) => {
                if (result === ModalButtonResponseEnum.OK) {
                  this.store.dispatch(new DeliveryOrderSaveSuccess(false));
                  this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
                }
              });
          }
        })
      )
      .subscribe();

    this.localStore.pipe(select(selectDeliveryOrderCriteria), distinctUntilChanged()).subscribe(criteria => {
      this.deliveryOrderCriteriaList = criteria;
    });

    this.deliveryOrder$.subscribe(deliveryOrder => {
      this.deliveryOrder = deliveryOrder;
      this.storeFullName = this.setStoreFullName(this.deliveryOrder);
      this.maxDraftNo = this.findMaxDraftNo(this.deliveryOrder.items);
      this.generateDraftList(this.maxDraftNo);
    });

    this.deliveryOrder$.subscribe(deliveryOrder => {
      this.allowManageDOPermissions =
        this.permissionUtil.determineDoPermission(deliveryOrder.warehouseCode, [this.permissionAction.MANAGE]) &&
        ['NEW', 'PICKING', 'PICKED'].includes(deliveryOrder.doStatus);
      this.allowManageLogisticPermissions =
        this.permissionUtil.determineLogisticPermission(deliveryOrder.warehouseCode, [
          this.permissionAction.MANAGE
        ]) && ['DRAFT'].includes(deliveryOrder.doStatus);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.deliveryOrder = null;
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }

    // Reset node to free memory
    this.store.dispatch(new DeliveryOrderReset());
    // Refresh delivery order list after close edit page
    this.store.dispatch(new DeliveryOrderListRequestAction(this.deliveryOrderCriteriaList));
  }

  showModalSplitDo() {
    this.noSelectedDraft = false;
    this.modalSplitDo.show();
    this.maxDraftNo = this.findMaxDraftNo(this.deliveryOrder.items);
    this.generateDraftList(this.maxDraftNo);
  }

  onChangeDraftNo(event: any) {
    if (event.value) {
      this.noSelectedDraft = false;
    }
  }

  hideModalSplitDo() {
    this.selectedDraftNo = null;
    this.modalSplitDo.hide();
  }

  cancelSplit() {
    this.hideModalSplitDo();
  }

  confirmSplit() {
    if (!this.selectedDraftNo) {
      this.noSelectedDraft = true;
      return;
    }
    this.modalSplitDo.hide();

    this.updateSplitNo(this.selectedDraftNo, this.selectedItemNoList, this.deliveryOrder.items);
    this.selectedDraftNo = null;
    this.isDisableSplitDo = true;
    this.selectedItemNoList = {};

    const currentSplitNoItems = lodashFilter(this.deliveryOrder.items, { splitNo: 1 });
    if (currentSplitNoItems && currentSplitNoItems.length === 0) {
      this.rearrangeSplitNo();
    }
  }

  updateSplitNo(selectedDraftNo, selectedDoItems: any, doItems: DeliveryOrderItem[]) {
    if (selectedDraftNo && selectedDraftNo.value && doItems.length) {
      doItems.forEach(doItem => {
        if (selectedDoItems[doItem.itemNo]) {
          doItem.splitNo = selectedDraftNo.value;
        }
      });
    }
  }

  rearrangeSplitNo() {
    const emptySplitNo = this.findEmptySplitNo();
    if (emptySplitNo) {
      this.deliveryOrder.items.forEach(doItem => {
        if (doItem.splitNo > emptySplitNo) {
          doItem.splitNo = doItem.splitNo - 1;
        }
      });
    }
  }

  findEmptySplitNo() {
    let emptySplitNo = null;
    const maxNo = this.findMaxDraftNo(this.deliveryOrder.items);

    for (let i = 1; i <= maxNo; i++) {
      const currentSplitNoItems = find(this.deliveryOrder.items, { splitNo: i });
      if (currentSplitNoItems === undefined) {
        emptySplitNo = i;
      }
    }
    return emptySplitNo;
  }

  generateDraftList(maxNo: number) {
    let newMax: number;

    if (maxNo >= 4) {
      newMax = 5;
    } else {
      newMax = maxNo + 1;
    }
    this.draftList = [];
    for (let i = 2; i <= newMax; i++) {
      this.draftList.push({ value: i, label: `New delivery order (draft ${i})` });
    }
  }

  findMaxDraftNo(items: DeliveryOrderItem[]): number {
    return items.reduce((max, p) => (p.splitNo > max ? p.splitNo : max), items[0].splitNo);
  }

  onClickCheckBox(event: any, doItem: DeliveryOrderItem) {
    this.notifyLeaveFormOutput.emit(true);
    if (event.target.checked) {
      this.selectedItemNoList[doItem.itemNo] = true;
      this.isDisableSplitDo = false;
    } else {
      delete this.selectedItemNoList[doItem.itemNo];
      this.determineDisableSplitDo();
    }
  }

  deleteDoItem(_index: number, deliveryOrderItem: DeliveryOrderItem) {
    deliveryOrderItem.splitNo = 1;
    this.rearrangeSplitNo();
  }

  determineDisableSplitDo() {
    let disable = true;
    for (const key in this.selectedItemNoList) {
      if (this.selectedItemNoList.hasOwnProperty(key)) {
        disable = false;
        break;
      }
    }
    this.isDisableSplitDo = disable;
  }

  toggleToEdit() {
    this.isEditMode = true;
    this.data.title = 'Edit Delivery Order';
  }

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  onSave() {
    this.isSubmit = false;
    this.store.dispatch(new DeliveryOrderSaveRequested(this.deliveryOrder));
  }

  onSubmit() {
    this.isSubmit = true;
    if (this.validatePickedQuantity()) {
      return;
    }
    this.handleConfirm();
  }

  validatePickedQuantity() {
    const isNotValid = find(this.deliveryOrder.items, item => item.pickedQty > item.assignedQty);

    let pickedNotNull = true;
    if (this.deliveryOrder.doStatus !== DeliveryOrderStatusEnum.DRAFT) {
      pickedNotNull = this.deliveryOrder.items.some(item => Boolean(item.pickedQty));
      if (!pickedNotNull) {
        this.modalService.show(AlertModalComponent, {
          initialState: {
            title: 'Failed',
            message: 'Please enter at least 1 picked quantity before submit.'
          }
        });
      }
    }

    return isNotValid || !pickedNotNull;
  }

  handleConfirm() {
    const draftNumber = this.findMaxDraftNo(this.deliveryOrder.items);
    let message;
    if (this.deliveryOrder.doStatus === DeliveryOrderStatusEnum.DRAFT) {
      message =
        draftNumber > 1
          ? `Are you sure you want to submit?<br/><br/>Separate order to ${draftNumber} deliveries.`
          : 'Are you sure you want to submit?';
    } else {
      message = 'Are you sure you want to submit?';
    }
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (ModalButtonResponseEnum.OK === result) {
          this.store.dispatch(new DeliveryOrderSubmitRequested(this.deliveryOrder));
        }
      });
  }

  getDraftColor(draftNo: number): string {
    switch (draftNo) {
      case 1:
        return 'draft-1';
      case 2:
        return 'draft-2';
      case 3:
        return 'draft-3';
      case 4:
        return 'draft-4';
      case 5:
        return 'draft-5';
      default:
        return '';
    }
  }

  getColorStatus(status: DeliveryOrderStatusEnum): string {
    return status ? (DeliveryOrderStatusEnum[status] as string).toLowerCase() : '';
  }

  doAfterVersionAlertModal() {}

  setStoreFullName(deliveryOrder: DeliveryOrder) {
    return (deliveryOrder.storeCode ? deliveryOrder.storeCode + '-' : '') + deliveryOrder.storeName;
  }

  onBlurPickedQty(deliveryOrderItem: DeliveryOrderItem) {
    this.notifyLeaveFormOutput.emit(true);
    deliveryOrderItem.blur = true;
  }

  onPrintPdf(deliveryOrder: DeliveryOrder) {
    if (!deliveryOrder) {
      return;
    }
    const param: DeliveryOrderPrintCriteria = {
      id: this.deliveryOrder.id,
      format: 'pdf',
      locale: 'th'
    };

    this.deliveryOrderService.printPdfDeliveryOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, generatedFilenamePdf(this.deliveryOrder.doNo));
      },
      error => {
        this.alertErrorModal(error.error);
      }
    );
  }

  cancelDeliveryOrder() {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel delivery order number <strong>&quot;${this.deliveryOrder.doNo}&quot;</strong>?`,
        label: 'Reason',
        okText: 'Yes, cancel',
        cancelText: 'Cancel',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.deliveryOrderService
            .cancelDeliveryOrder({
              doNo: this.deliveryOrder.doNo,
              comment: confirmModalRef.content.confirmMessage
            })
            .pipe(untilComponentDestroyed(this))
            .subscribe(
              () => {
                this.alertSuccessModal('The delivery order has been cancelled.');
              },
              error => {
                this.alertErrorModal(error.error);
              }
            );

          if (confirmModalRef.content.actions) {
            confirmModalRef.content.actions.unsubscribe();
          }
        }
      });
  }

  alertSuccessModal(message: string) {
    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState,
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
      }
    });
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    const initialState = {
      title: 'Failed',
      message: this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }

  isCanPrintPDF(status): boolean {
    return ['PICKED', 'LOADED', 'DISPATCHED', 'DELIVERED'].includes(status);
  }

  onClose() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  showSaveButton(doStatus: DeliveryOrderStatusEnum) {
    return (
      this.isEditMode &&
      ['DRAFT'].includes(doStatus) &&
      (this.allowManageLogisticPermissions || this.allowManageDOPermissions)
    );
  }

  hasCancelPermission() {
    if (this.deliveryOrder) {
      return (
        [
          DeliveryOrderStatusEnum.DRAFT,
          DeliveryOrderStatusEnum.NEW,
          DeliveryOrderStatusEnum.PICKING,
          DeliveryOrderStatusEnum.PICKED
        ].includes(this.deliveryOrder.doStatus) &&
        this.authGuardService.checkPermission('^do_m_[a-zA-Z]{2}[0-9]{1,3}$', true) &&
        !this.isEditMode
      );
    }
  }

  get showEditButton() {
    return !this.isEditMode && (this.allowManageLogisticPermissions || this.allowManageDOPermissions);
  }

  get showCancelButton() {
    return this.isEditMode && (this.allowManageLogisticPermissions || this.allowManageDOPermissions);
  }

  get showSubmitButton() {
    return this.isEditMode && (this.allowManageLogisticPermissions || this.allowManageDOPermissions);
  }

  get allowSplitDeliveryOrder() {
    return this.isEditMode && this.allowManageLogisticPermissions && this.deliveryOrder.items.length > 1;
  }
}
