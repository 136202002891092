import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { StoreAssortmentRequestViewResponse } from '../../../shared/models/store-assortment-request.model';
import { StoreAssortmentRequestByIdRequestAction } from '../../../shared/store/actions/store-assortment-request.actions';
import { selectStoreAssortmentRequestById } from '../../../shared/store/selectors/store-assortment-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';

@Component({
  selector: 'app-store-assortment-request-view',
  templateUrl: './store-assortment-request-view.component.html',
  styleUrls: ['./store-assortment-request-view.component.scss']
})
export class StoreAssortmentRequestViewComponent implements OnInit {
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: { requestId: string; title: string };

  private localStore: Observable<any>;

  public dateTimeFormat = environment.dateTimeDisplay;
  public storeAssortmentRequestView$: Observable<StoreAssortmentRequestViewResponse>;
  public mode = RequestPageModesEnum.REQUEST_VIEW;

  constructor(protected readonly store: Store<AppStates>, protected readonly translate: TranslateService) {}

  ngOnInit() {
    this.store.dispatch(new StoreAssortmentRequestByIdRequestAction(this.data.requestId));
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.storeAssortmentRequestView$ = this.localStore.pipe(
      select(selectStoreAssortmentRequestById),
      filter(data => Boolean(data))
    );
  }

  ngOnDestroy(): void {}

  onCancel() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }
}
