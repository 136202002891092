<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Alert</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body">
    <table id="prItemErrorDetails" class="table table-striped table-bordered table-hover border-none">
      <thead>
        <tr>
          <th class="text-center">Subject</th>
          <th class="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isChange">
          <td>Data Changed.</td>
          <td>This data has been updated. Please verify data before submit.</td>
        </tr>
        <tr *ngIf="isInvalidData">
          <td>Invalid Data.</td>
          <td>Some items has invalid data. Please remove item from list.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="decline()" id="okBtn">
      OK
    </button>
  </div>
</div>
