import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OfficeWarehouse } from '../../models/warehouse.model';
import { WarehouseMasterAction, WarehouseMasterActionTypes } from '../actions/warehouse-master.actions';

export interface WarehouseMasterState extends EntityState<OfficeWarehouse> {}

export const adapter: EntityAdapter<OfficeWarehouse> = createEntityAdapter<OfficeWarehouse>({
  selectId: (officeWarehouse: OfficeWarehouse) => officeWarehouse.code
});

export const initialWarehouseMasterState: WarehouseMasterState = adapter.getInitialState();

export function warehouseMasterReducers(
  state = initialWarehouseMasterState,
  action: WarehouseMasterAction
): WarehouseMasterState {
  if (action.type === WarehouseMasterActionTypes.WAREHOUSE_MASTER_LIST_RESPONSE) {
    return adapter.addAll(action.payload, { ...state });
  } else {
    return state;
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
