<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-marketing"></em>
    <h4>Voucher</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>
  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap">
        <button
          *ngIf="hasEditVoucherPermission"
          type="button"
          class="btn btn-primary col-sm-12"
          (click)="gotoCreateVoucherRequest()"
        >
          <em class="icon-plus"></em>
          Create Voucher
        </button>
      </div>

      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              aria-hidden="true"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="statusList"
            [searchable]="false"
            [clearable]="false"
            (change)="onChangeStatus($event)"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter" aria-hidden="true"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">{{ 'VOUCHER.CAMPAIGN' | translate }}</div>
                        <ng-select
                          bindLabel="name"
                          bindValue="name"
                          [searchable]="false"
                          [multiple]="true"
                          [items]="campaignList"
                          placeholder="Please select..."
                          formControlName="campaign"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'VOUCHER.EFFECTIVE_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="effectiveDateFrom"
                          formControlName="effectiveDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="effectiveDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="effectiveDateTo"
                          formControlName="effectiveDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="effectiveDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'VOUCHER.EXPIRE_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="expireDateFrom"
                          formControlName="expireDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="expireMaxDate"
                          (bsValueChange)="onChangeExpireDateFrom($event)"
                          readonly
                        />
                        <label for="expireDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="expireDateTo"
                          formControlName="expireDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control w-100 calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="expireMinDate"
                          (bsValueChange)="onChangeExpireDateTo($event)"
                          readonly
                        />
                        <label for="expireDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        {{ 'VOUCHER.REQUEST_DATE' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="requestedDateFrom"
                          formControlName="requestedDateFrom"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="requestMaxDate"
                          (bsValueChange)="onChangeRequestedDateFrom($event)"
                          readonly
                        />
                        <label for="requestedDateFrom" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="requestedDateTo"
                          formControlName="requestedDateTo"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="requestMinDate"
                          (bsValueChange)="onChangeRequestedDateTo($event)"
                          readonly
                        />
                        <label for="requestedDateTo" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="requestedDateStringTag || effectiveDateStringTag || expireDateStringTag || campaignStringTag"
  >
    <div class="search-criteria" *ngIf="campaignStringTag">
      <a class="remove-criteria" (click)="clearFilterCampaign()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ campaignStringTag }}: {{ campaignTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="effectiveDateStringTag">
      <a class="remove-criteria" (click)="clearFilterEffectiveDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ effectiveDateStringTag }}: {{ effectiveDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="expireDateStringTag">
      <a class="remove-criteria" (click)="clearFilterExpireDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ expireDateStringTag }}: {{ expireDateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="requestedDateStringTag">
      <a class="remove-criteria" (click)="clearFilterRequestedDate()">
        <em class="icon-close" aria-hidden="true"></em>
      </a>
      <span>{{ requestedDateStringTag }}: {{ requestedDateTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-10">Request No.</th>
            <th class="text-center w-10">Campaign</th>
            <th class="text-center w-15">Store</th>
            <th class="text-center w-10">Value(THB)</th>
            <th class="text-center w-10">Effective Date</th>
            <th class="text-center w-10">Expire Date</th>
            <th class="text-center w-10">Requested By</th>
            <th class="text-center w-10">Requested Date</th>
            <th class="text-center w-15">Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.requestNo | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.campaign | dashDisplay }}
              </div>
            </td>
            <td (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.store | dashDisplay }}
              </div>
            </td>
            <td class="text-right" (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.voucherValue.amount | numberFormatDisplay }}
              </div>
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item?.effectiveDate | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item?.expireDate | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(item)">
              {{ item.createdBy | dashDisplay }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item?.createdDate | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(item)" [ngClass]="getColorStatus(item.status)" class="request-status">
              {{ 'VOUCHER.STATUS.' + item.status | translate }}
            </td>
            <td class="text-center">
              <a
                href="javascript:void(0)"
                class="btn-more "
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasEditVoucherPermission && showCancelButton(item.status)"
                  (click)="cancelVoucherRequest(item)"
                >
                  <em class="icon-forbidden"></em>Cancel Request
                </button>
                <button type="button" class="btn btn-link d-block" (click)="showHistory(item)">
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
