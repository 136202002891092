import { Action } from '@ngrx/store';
import { ProductMaster } from '../../models/product-master.model';

export enum ProductMasterActionTypes {
  ProductMasterViewRequested = '[View Product Master Page] Product Master Requested',
  ProductMasterViewLoaded = '[Product Master API] Product Master Loaded'
}

export class ProductMasterViewRequested implements Action {
  readonly type = ProductMasterActionTypes.ProductMasterViewRequested;
  constructor(public payload: { articleNo: string }) {}
}

export class ProductMasterViewLoaded implements Action {
  readonly type = ProductMasterActionTypes.ProductMasterViewLoaded;
  constructor(public payload: { productMaster: ProductMaster }) {}
}

export type ProductMasterActions = ProductMasterViewRequested | ProductMasterViewLoaded;
