import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { VoucherRequestResponse } from '../../models';
import { VoucherRequestAction, VoucherRequestActionTypes } from '../actions/voucher-request.actions';

export interface VoucherCreateState extends EntityState<VoucherRequestResponse> {
  isLoading: boolean;
  isSubmitSuccess: boolean;
  requestVoucherData: VoucherRequestResponse;
}

export const adapter: EntityAdapter<VoucherRequestResponse> = createEntityAdapter<VoucherRequestResponse>();

export const initialVoucherCreateState: VoucherCreateState = adapter.getInitialState({
  isLoading: false,
  isSubmitSuccess: false,
  requestVoucherData: null
});

export function VoucherCreateReducers(
  state = initialVoucherCreateState,
  action: VoucherRequestAction
): VoucherCreateState {
  switch (action.type) {
    case VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_RESPONSE:
      return {
        ...state,
        isSubmitSuccess: true,
        requestVoucherData: action.payload
      };
    case VoucherRequestActionTypes.VOUCHER_REQUEST_SUBMIT_RESET:
      return initialVoucherCreateState;
    default: {
      return state;
    }
  }
}
