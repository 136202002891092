import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  DeliveryOrder,
  DeliveryOrderPagination,
  DeliveryOrderSearchCriteria
} from '../../models/delivery-order.model';

export enum DeliveryOrderActionTypes {
  DELIVERY_ORDER_VIEW_REQUESTED = '[Delivery Order View Page] Delivery Order View Requested',
  DELIVERY_ORDER_VIEW_LOADED = '[Delivery Order API] Delivery Order View Loaded',
  DELIVERY_ORDER_SAVE_REQUESTED = '[Delivery Order Edit Page] Delivery Order Save Requested',
  DELIVERY_ORDER_SUBMIT_REQUESTED = '[Delivery Order Edit Page] Delivery Order Submit Requested',
  DELIVERY_ORDER_SAVE_SUCCESS = '[Delivery Order API] Delivery Order Save Success',
  DELIVERY_ORDER_RESET = '[Delivery Order View Page] Delivery Order Reset',
  DELIVERY_REQUEST_LIST_REQUEST = '[Delivery] Request List Request',
  DELIVERY_REQUEST_LIST_RESPONSE = '[Delivery] Request List Response',
  DELIVERY_REQUEST_LIST_ERROR = '[Delivery] Request List Error'
}

export class DeliveryOrderViewRequested implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class DeliveryOrderViewLoaded implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_VIEW_LOADED;

  constructor(public payload: DeliveryOrder) {}
}

export class DeliveryOrderSaveRequested implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_SAVE_REQUESTED;

  constructor(public payload: DeliveryOrder) {}
}

export class DeliveryOrderSubmitRequested implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_SUBMIT_REQUESTED;

  constructor(public payload: DeliveryOrder) {}
}

export class DeliveryOrderSaveSuccess implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_SAVE_SUCCESS;

  constructor(public payload: boolean) {}
}

export class DeliveryOrderListRequestAction implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST;

  constructor(public payload: DeliveryOrderSearchCriteria) {}
}

export class DeliveryOrderListResponseAction implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_RESPONSE;

  constructor(public payload: DeliveryOrderPagination) {}
}

export class DeliveryOrderListErrorAction implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class DeliveryOrderReset implements Action {
  readonly type = DeliveryOrderActionTypes.DELIVERY_ORDER_RESET;

  constructor() {}
}

export type DeliveryOrderActions =
  | DeliveryOrderViewRequested
  | DeliveryOrderViewLoaded
  | DeliveryOrderSaveRequested
  | DeliveryOrderSaveSuccess
  | DeliveryOrderSubmitRequested
  | DeliveryOrderListRequestAction
  | DeliveryOrderListResponseAction
  | DeliveryOrderListErrorAction
  | DeliveryOrderReset;
