import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ReceiveOrderService } from '../../services/receive-order.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  ReceiveOrderActionType,
  ReceiveOrderByIdRequestAction,
  ReceiveOrderByIdResponseAction,
  ReceiveOrderListRequestAction,
  ReceiveOrderListResponseAction
} from '../actions/receive-order.actions';

@Injectable()
export class ReceiveOrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly receiveOrderService: ReceiveOrderService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchReceiveOrder$ = this.actions$.pipe(
    ofType<ReceiveOrderListRequestAction>(ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST}: ` + this.stringify(action.payload)
      )
    ),
    switchMap(action => {
      return this.receiveOrderService.searchByCriteria(action.payload).pipe(
        map(response => new ReceiveOrderListResponseAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  getReceiveOrderByNo$ = this.actions$.pipe(
    ofType<ReceiveOrderByIdRequestAction>(ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_REQUEST),
    tap(action =>
      this.logger.debug(
        `@Effect ${ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_REQUEST}: ` + this.stringify(action.payload)
      )
    ),
    switchMap(action => {
      return this.receiveOrderService.getReceiveOrderByNo(action.payload.receiveOrderNo).pipe(
        map(response => new ReceiveOrderByIdResponseAction(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
