import { createSelector } from '@ngrx/store';
import * as fromClaimRequest from '../reducers/claim-request.reducers';
import { AppStates } from '../state/app.states';

const selectClaimRequestState = (state: AppStates) => state.claimRequest;

export const selectEditedClaimRequest = createSelector(selectClaimRequestState, state => {
  return state.claimRequest;
});

export const selectClaimRequest = createSelector(
  selectClaimRequestState,
  (state: fromClaimRequest.ClaimRequestState) => state
);

export const selectClaimRequestSaveStatus = createSelector(
  selectClaimRequestState,
  (state: fromClaimRequest.ClaimRequestState) => {
    return state.saveSuccess;
  }
);

export const selectClaimRequestList = createSelector(selectClaimRequestState, fromClaimRequest.selectAll);

export const selectClaimRequestCriteria = createSelector(selectClaimRequestState, state => {
  return state.criteriaObject;
});
