import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

class ICustomFile extends File {
  errors?: { [key: string]: any };
  id?: number;
}

@Component({
  selector: 'app-get-excel-data',
  templateUrl: './get-excel-data.component.html',
  styleUrls: ['./get-excel-data.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GetExcelDataComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => GetExcelDataComponent),
      multi: true
    }
  ],
  exportAs: 'GetExcelDataComponent'
})
export class GetExcelDataComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  constructor(
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly logger: NGXLogger
  ) {
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;
  @Output() clearInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() showErrorMessage: EventEmitter<string> = new EventEmitter<string>();
  @Input() size: number;
  @Input() controlName: string;
  @Input() disabled: boolean;

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = [];
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    this.ngChange(this.value);
    this.ngTouched();

    return (files: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const f of files) {
        fileArr.push(f);
      }

      fn(fileArr);
    };
  }

  validate(c: AbstractControl): ValidationErrors | undefined {
    if (!c.value || !c.value.length || c.disabled) {
      return null;
    }

    let errors: ValidationErrors = {};

    for (const f of c.value) {
      if (this.size && this.size < f.size) {
        f.errors = { ...f.errors, fileSize: true };
        errors = { ...errors, fileSize: true };
      }

      const extP = new RegExp('.(xlsx)', 'ig');
      const typeP = new RegExp('^application/vnd.openxmlformats-.*$', 'ig');

      const fileErrors = {
        ...(!extP.test(f.name) && {
          fileExt: true
        }),
        ...(f.type &&
          !typeP.test(f.type) && {
            fileType: true
          })
      };

      errors = {
        ...errors,
        ...fileErrors
      };

      f.errors = {
        ...f.errors,
        ...fileErrors
      };
    }

    if (!this.isLoad) {
      this.fileList = c.value;

      if (Object.keys(errors).length) {
        const isFileTypeError = errors['fileType'] || errors['fileExt'];
        const isFileSizeError = errors['fileSize'];
        this.onShowErrorMessage(isFileTypeError, isFileSizeError);
        return errors;
      }
    }
  }

  onClickDelete() {
    this.ngChange(this.value);
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue(null);
    this.clearInput.emit();
  }

  onShowErrorMessage(isFileTypeError, isFileSizeError) {
    this.showErrorMessage.emit(
      isFileTypeError ? 'Incorrect Format (allow only format file .xlsx).' : `File size limit exceeded. <br/><br/>`
    );
    this.logger.debug('alertFailModal->isFileSizeError', isFileSizeError);
  }
}
