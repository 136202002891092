import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ClaimService } from '../../services/claim.service';
import {
  ClaimRequestActionTypes,
  ClaimRequestApprove,
  ClaimRequestListRequest,
  ClaimRequestListResponse,
  ClaimRequestReject,
  ClaimRequestReleaseCN,
  ClaimRequestSubmitRequested,
  ClaimRequestViewLoaded,
  ClaimRequestViewRequested
} from '../actions/claim-request.actions';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';

@Injectable()
export class ClaimEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly claimService: ClaimService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  loadClaimRequest$ = this.actions$.pipe(
    ofType<ClaimRequestViewRequested>(ClaimRequestActionTypes.CLAIM_REQUEST_VIEW_REQUESTED),
    tap(action => this.logger.debug('@Effect Claim Get Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.getClaimRequestByClaimNumber(action.payload).pipe(
        map(result => new ClaimRequestViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  draftClaimRequest$ = this.actions$.pipe(
    ofType<ClaimRequestViewRequested>(ClaimRequestActionTypes.CLAIM_REQUEST_DRAFT_REQUESTED),
    tap(action => this.logger.debug('@Effect Claim Draft Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.getClaimDraftByReceiveOrderNumber(action.payload).pipe(
        map(result => new ClaimRequestViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  claimRequestSubmit$ = this.actions$.pipe(
    ofType<ClaimRequestSubmitRequested>(ClaimRequestActionTypes.CLAIM_REQUEST_SUBMIT_REQUESTED),
    tap(action => this.logger.debug('@Effect Claim Submit Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.submit(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been created.',
              routerLink: '/order/claim-request-list'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  approveClaimRequest$ = this.actions$.pipe(
    ofType<ClaimRequestApprove>(ClaimRequestActionTypes.CLAIM_REQUEST_APPROVE),
    tap(action => this.logger.debug('@Effect Claim Approve Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.approve(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been approved.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  rejectClaimRequest$ = this.actions$.pipe(
    ofType<ClaimRequestReject>(ClaimRequestActionTypes.CLAIM_REQUEST_REJECT),
    tap(action => this.logger.debug('@Effect Claim Reject Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.reject(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been rejected.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  releaseCN$ = this.actions$.pipe(
    ofType<ClaimRequestReleaseCN>(ClaimRequestActionTypes.CLAIM_REQUEST_RELEASE_CN),
    tap(action => this.logger.debug('@Effect Claim Release CN Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.releaseCN(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'Credit Note has been created.'
            })
        ),
        catchError(err => {
          return this.errorHandling(err);
        })
      )
    )
  );

  @Effect()
  searchClaimRequest$ = this.actions$.pipe(
    ofType<ClaimRequestListRequest>(ClaimRequestActionTypes.CLAIM_REQUEST_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Claim List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.claimService.searchByCriteria(action.payload).pipe(
        map(response => new ClaimRequestListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  errorHandling(err: any) {
    return err.error && err.error.code === '01003'
      ? of(new LayoutActionVersionError(true))
      : of(new LayoutActionLoadError(err));
  }
}
