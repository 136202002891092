import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuardService } from '../../services';
import { MenuActionTypes, MenuLoadedAction } from '../actions/menu.action';
export class MenuEffects {
    constructor(actions, logger, authGuardService) {
        this.actions = actions;
        this.logger = logger;
        this.authGuardService = authGuardService;
        this.MenuRequested = this.actions.pipe(ofType(MenuActionTypes.MenuRequested), map(action => {
            this.logger.debug(`@Effect ${MenuActionTypes.MenuRequested}: ` + this.stringify(action.payload));
            return new MenuLoadedAction(this.authGuardService.getMenuByPermission(action.payload));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MenuEffects.prototype, "MenuRequested", void 0);
