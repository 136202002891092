import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { CJProduct } from '../../../shared/models';
import { ProductMaster } from '../../../shared/models/product-master.model';

@Component({
  selector: 'app-product-view-header',
  templateUrl: './product-view-header.component.html',
  styleUrls: ['./product-view-header.component.scss']
})
export class ProductViewHeaderComponent implements OnInit {
  @Input() productMaster$: Observable<ProductMaster>;
  @Input() headerStatus: string;
  @Input() productStatus: string;
  @Input() articleNo: string;
  @Input() cjProduct: CJProduct;
  @Input() listOfChange: {};
  @Input() isRequestViewMode: boolean;

  constructor() {}

  ngOnInit() {
    this.cjProduct = {} as CJProduct;
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }
}
