import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Pipe({
  name: 'nameThDisplay'
})
export class NameThDisplayPipe implements PipeTransform, OnDestroy {
  constructor(private readonly logger: NGXLogger) {}

  transform(code: string, arr: Array<any>): string {
    const selected = arr.filter(v => v.code === code);
    return selected != null && selected.length > 0 ? selected[0].nameTh : '';
  }

  ngOnDestroy(): void {}
}
