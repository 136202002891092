<div class="modal-content">
  <div class="modal-header pb-4">
    <h6 class="modal-title pull-left">Shelf Details</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body history-modal-body">
    <div class="row mt-2 td-info-modal-header">
      <div class="col-3">
        Shelf Code
      </div>
      <div class="col-3">
        Shelf Type
      </div>
      <div class="col-6">
        Shelf Option
      </div>
    </div>
    <div class="row mt-2 td-info-modal-data" *ngFor="let shelf of shelfDetails$ | async; index as i">
      <div class="col-3">
        <span>{{ shelf.shelfCode }}</span>
      </div>
      <div class="col-3">
        <span>{{ shelf.shelfType }}</span>
      </div>
      <div class="col-6">
        <span>{{ shelf.shelfOption }}</span>
      </div>
    </div>
  </div>
</div>
