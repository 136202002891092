import { BsDatepickerConfig } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';

export abstract class BaseReportModalComponent {
  protected subscriptions: Subscription = new Subscription();
  public bsDateConfig: BsDatepickerConfig;
  public dateFormat = environment.dateFormat;
  public exportFormInValid: boolean;

  protected constructor() {
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: this.dateFormat,
      showWeekNumbers: false,
      adaptivePosition: true
    } as BsDatepickerConfig;
  }

  abstract onExport();

  abstract openExportModal();

  abstract closeExportModal();
}
