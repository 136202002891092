import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { RoleErrorResponse, RoleMasterResponse, RoleViewResponse } from '../../models';
import { RoleActions, RoleActionTypes } from '../actions/role.actions';

export interface RoleState extends EntityState<RoleMasterResponse> {
  isLoading: boolean;
  selected: any;
  permissions: RoleMasterResponse[];
  roleErrorResponse: RoleErrorResponse;
  roleList: RoleViewResponse[];
}

export const adapter: EntityAdapter<RoleMasterResponse> = createEntityAdapter<RoleMasterResponse>();

export const initialRoleState: RoleState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  permissions: null,
  roleErrorResponse: null,
  roleList: null
});

export function roleReducers(state = initialRoleState, action: RoleActions): RoleState {
  switch (action.type) {
    case RoleActionTypes.ROLE_MASTER_LIST_RESPONSE:
      return {
        ...state,
        permissions: action.payload.permissions
      };
    case RoleActionTypes.ROLE_MASTER_LIST_RESET:
      return {
        ...state,
        permissions: null
      };
    case RoleActionTypes.ROLE_RESPONSE_ERROR_RESET: {
      return {
        ...state,
        roleErrorResponse: null
      };
    }
    case RoleActionTypes.ROLE_RESPONSE_ERROR: {
      return {
        ...state,
        roleErrorResponse: action.payload
      };
    }

    case RoleActionTypes.ROLE_ALL_RESPONSE_ACTION: {
      return {
        ...state,
        roleList: action.payload
      };
    }
    case RoleActionTypes.ROLE_ALL_RESET_ACTION: {
      return {
        ...state,
        roleList: null
      };
    }

    case RoleActionTypes.ROLE_GET_BY_ID_RESPONSE: {
      return {
        ...state,
        selected: action.payload
      };
    }
    case RoleActionTypes.ROLE_GET_BY_ID_RESET: {
      return {
        ...state,
        selected: null
      };
    }
    default: {
      return state;
    }
  }
}
