import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse, ShelfItems, ShelfOptionType, UploadedFirstLotOrder } from '../../models';
import { FirstLotOrderActions, FirstLotOrderActionType } from '../actions/first-lot-order.action';
import { BaseState } from '../state/base.state';

export interface FirstLotOrderResponseState extends EntityState<UploadedFirstLotOrder>, BaseState {
  isLoading: boolean;
  isSave: boolean | null;
  data: { [key: string]: UploadedFirstLotOrder };
  shelfItems?: {
    response: ShelfItems[];
    error: ErrorResponse | null;
    isSuccess: boolean;
  };
  shelfDetails: ShelfOptionType[];
}

export const adapter: EntityAdapter<UploadedFirstLotOrder> = createEntityAdapter<UploadedFirstLotOrder>();

export const initialFirstLotOrderResponseState: FirstLotOrderResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  data: {},
  shelfItems: null,
  shelfDetails: []
});

export function firstLotOrderReducers(
  state = initialFirstLotOrderResponseState,
  action: FirstLotOrderActions
): FirstLotOrderResponseState {
  switch (action.type) {
    case FirstLotOrderActionType.FIRST_LOT_ORDER_SAVE_REQUEST:
      const data = { ...state.data };
      data[action.payload.storeCode] = action.payload;

      return {
        ...state,
        data
      };
    case FirstLotOrderActionType.FIRST_LOT_ORDER_SAVE_RESPONSE:
      return {
        ...state,
        data: action.payload
      };

    case FirstLotOrderActionType.FIRST_LOT_ORDER_RESET:
      return initialFirstLotOrderResponseState;
    case FirstLotOrderActionType.SHELF_ITEMS_RESPONSE:
      return {
        ...state,
        shelfItems: {
          response: action.payload,
          error: null,
          isSuccess: false
        }
      };
    case FirstLotOrderActionType.ADD_SHELF_TYPE_RESPONSE:
      return {
        ...state,
        shelfItems: {
          response: action.payload,
          error: null,
          isSuccess: true
        }
      };
    case FirstLotOrderActionType.ADD_SHELF_TYPE_ERROR:
      return {
        ...state,
        shelfItems: {
          ...state.shelfItems,
          error: action.payload,
          isSuccess: false
        }
      };
    case FirstLotOrderActionType.SHELF_ITEMS_RESET:
      return {
        ...state,
        shelfItems: null
      };
    case FirstLotOrderActionType.SHELF_DETAILS_RESPONSE:
      return {
        ...state,
        shelfDetails: action.payload
      };
    case FirstLotOrderActionType.SHELF_DETAILS_RESET:
      return {
        ...state,
        shelfDetails: null
      };
    default: {
      return state;
    }
  }
}
