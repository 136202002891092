<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-supplier"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      supplierRequest.status === supplierGroupRequestStatusEnum.DRAFT &&
      data.mode === supplierGroupRequestModeEnum.REQUEST_VIEW &&
      hasPermissionManageSupplierGroup
    "
    (click)="onDelete()"
  >
    <em class="icon-delete"></em>
    Delete
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="
      supplierRequest.status === supplierGroupRequestStatusEnum.AWAITING_APPROVAL && hasPermissionManageSupplierGroup
    "
    (click)="onCancel()"
  >
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="supplier-group-request" class="admin-form">
  <div class="info-header-container">
    <div
      class="info-header-item"
      *ngIf="[supplierGroupRequestModeEnum.VIEW, supplierGroupRequestModeEnum.EDIT].includes(data.mode)"
    >
      <div class="info-header-label">
        <span>Supplier Group No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ supplierRequest.groupNo }}</span>
      </div>
    </div>
    <div *ngIf="supplierRequest?.requestNo" class="info-header-item">
      <div class="info-header-label">
        <span>Request No.:</span>
      </div>
      <div class="info-header-value">
        <span>
          {{ supplierRequest?.requestNo }}
        </span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>{{ supplierGroupRequestModeEnum.VIEW === data.mode ? 'Status' : 'Request Status' }}:</span>
      </div>
      <div class="info-header-value">
        <span class="supplier-group-status" [ngClass]="getColorStatus(supplierRequest?.status)">
          {{
            ([
              supplierGroupRequestModeEnum.REQUEST_VIEW,
              supplierGroupRequestModeEnum.REQUEST_CREATE,
              supplierGroupRequestModeEnum.REQUEST_EDIT,
              supplierGroupRequestModeEnum.EDIT
            ].includes(data.mode)
              ? 'SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_STATUS.'
              : 'SUPPLIER_GROUP.SUPPLIER_GROUP_STATUS.') + supplierRequest?.status | translate
          }}
        </span>
      </div>
    </div>
  </div>

  <div class="information-container mb-3" *ngIf="data.mode === supplierGroupRequestModeEnum.VIEW">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>No. of article</span>
          </div>
          <div class="info-header-value">
            {{ supplierRequest.numberOfArticles }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Total Supplier</span>
          </div>
          <div class="info-header-value">
            {{ supplierRequest.totalSuppliers }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Selected Supplier</span>
          </div>
          <div class="info-header-value">
            {{ supplierRequest.completed ? 'Completed' : 'Incompleted' }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Pending Select Supplier</span>
          </div>
          <div class="info-header-value">
            {{ supplierRequest.pendingSelectSupplier }}
          </div>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Last Updated</span>
          </div>
          <div class="info-header-value">
            {{ supplierRequest.lastModifiedDate | amFromUtc | amLocal | dateDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row section-header">
    <span>Supplier Group Details</span>
  </div>
  <div *ngIf="supplierRequest" [formGroup]="supplierRequestForm">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <label for="supplierGroupName">Supplier Group Name<span class="text-danger">*</span></label>
        <input
          type="text"
          class="form-control"
          id="supplierGroupName"
          maxlength="50"
          formControlName="name"
          trim="blur"
          placeholder="Supplier Group Name"
          [ngClass]="{
            'is-invalid': isSubmit && supplierRequestForm.controls.name.errors
          }"
        />
        <div *ngIf="isSubmit && supplierRequestForm.controls.name.errors" class="invalid-display">
          <div *ngIf="supplierRequestForm.controls.name.errors">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <label for="productType">Product Type<span class="text-danger">*</span></label>
        <ng-select
          id="productType"
          placeholder="Please select..."
          [items]="productTypeList"
          [searchable]="false"
          [clearable]="false"
          bindLabel="label"
          bindValue="value"
          formControlName="productType"
          [ngClass]="{
            'is-invalid': isSubmit && supplierRequestForm.controls.productType.errors
          }"
        >
        </ng-select>
        <div *ngIf="isSubmit && supplierRequestForm.controls.productType.errors" class="invalid-display">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
  </div>

  <hr class="section-divider" />

  <div class="row section-header mt-3 mb-2 mx-0">
    <span>Store List</span>
  </div>

  <div class="mt-3">
    <div class="row">
      <div class="col-12">
        <div class="float-left">
          <button
            *ngIf="
              ![supplierGroupRequestModeEnum.REQUEST_VIEW, supplierGroupRequestModeEnum.VIEW].includes(data.mode)
            "
            class="btn btn-secondary mr-2"
            type="button"
            (click)="showModalSelectSupplier()"
          >
            Select Supplier to All Stores
          </button>
          <app-supplier-group-import
            *ngIf="
              hasPermissionManageSupplierGroup &&
              [
                supplierGroupRequestModeEnum.EDIT,
                supplierGroupRequestModeEnum.REQUEST_EDIT,
                supplierGroupRequestModeEnum.REQUEST_CREATE
              ].includes(data.mode)
            "
            [allowedTypes]="'sheet'"
            [withMeta]="false"
            [multiple]="false"
            [size]="2700000"
            [allowedExt]="'xlsx'"
            (itemDetail)="onLoadItemDetail($event)"
            [mode]="data.mode"
            [supplierGroupNo]="data.groupNo"
            [fileSizeExceedMessage]="'File size limit exceeded. Up to 50,000 rows.'"
          ></app-supplier-group-import>
          <button
            class="btn btn-standard d-none d-lg-block"
            type="button"
            *ngIf="
              (hasPermissionManageSupplierGroup ||
                hasPermissionViewSupplierGroup ||
                hasPermissionApproveSupplierGroup) &&
              supplierGroupRequestModeEnum.VIEW === data.mode
            "
            (click)="exportStoreSupplier()"
          >
            <em class="icon-export"></em>
            Export All Store
          </button>
        </div>

        <div class="float-right">
          <button
            class="btn btn-standard d-none d-lg-block"
            type="button"
            *ngIf="
              hasPermissionManageSupplierGroup &&
              [supplierGroupRequestModeEnum.REQUEST_CREATE, supplierGroupRequestModeEnum.REQUEST_EDIT].includes(
                data.mode
              )
            "
            (click)="exportStoreSupplierTemplate()"
          >
            <em class="icon-download"></em>
            Download Template
          </button>
          <button
            class="btn advance-filter-button btn-advance-filter d-none d-lg-block"
            [ngClass]="{ active: isShowAdvanceSearch }"
            *ngIf="[supplierGroupRequestModeEnum.REQUEST_VIEW, supplierGroupRequestModeEnum.VIEW].includes(data.mode)"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>

          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container" [formGroup]="searchForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Region
                        </div>
                        <ng-select
                          id="filterRegion"
                          name="filterRegion"
                          placeholder="Please select..."
                          [items]="listRegion"
                          [searchable]="false"
                          [clearable]="false"
                          bindLabel="nameTh"
                          bindValue="code"
                          [multiple]="true"
                          formControlName="region"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Province
                        </div>
                        <ng-select
                          id="filterState"
                          name="filterState"
                          placeholder="Please select..."
                          [items]="listState"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="nameTh"
                          bindValue="code"
                          formControlName="state"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div
                    class="row"
                    *ngIf="
                      [supplierGroupRequestModeEnum.REQUEST_VIEW, supplierGroupRequestModeEnum.VIEW].includes(
                        data.mode
                      )
                    "
                  >
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Supplier Name
                        </div>
                        <ng-select
                          id="filterSupplierName"
                          name="filterSupplierName"
                          placeholder="Please select..."
                          [items]="supplierNameFilter"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="supplierExist"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="supplierGroupRequestTypeEnum.EDIT === supplierRequest.type">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          Change Status
                        </div>
                        <ng-select
                          id="filterStatus"
                          name="filterStatus"
                          placeholder="Please select..."
                          [items]="supplierGroupRequestStatusFilter"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          class="ng-select-width"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="status"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="top-search-criteria space-search-criteria"
    *ngIf="statusTypeTag || regionTag || stateTag || supplierNameTag"
  >
    <div class="search-criteria" *ngIf="regionStringTag">
      <a class="remove-criteria" (click)="clearRegionFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ regionTag }}: {{ regionStringTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="stateStringTag">
      <a class="remove-criteria" (click)="clearStateFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ stateTag }}: {{ stateStringTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="supplierNameStringTag">
      <a class="remove-criteria" (click)="clearSupplierNameFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ supplierNameTag }}: {{ supplierNameStringTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="statusStringTag">
      <a class="remove-criteria" (click)="clearStatusFilter()">
        <em class="icon-close"></em>
      </a>
      <span>{{ statusTypeTag }}: {{ statusStringTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="not found" />
      </div>
    </div>
  </ng-container>
  <div *ngIf="supplierRequest && (listState$ | async).totalElements > 0">
    <div class="mt-3 table-responsive">
      <table id="storeListTable" class="table table-striped table-bordered table-hover border-none">
        <thead>
          <tr>
            <th class="text-center">Region</th>
            <th class="text-center">Province</th>
            <th class="text-center">Store Code</th>
            <th class="text-center">Store Name</th>
            <th class="text-center">Vendor Number</th>
            <th class="text-center">Supplier Name</th>
            <th class="text-center">Branch No.</th>
            <th
              class="text-center"
              *ngIf="
                [supplierGroupRequestModeEnum.REQUEST_VIEW, supplierGroupRequestModeEnum.EDIT].includes(data.mode) &&
                (supplierGroupRequestTypeEnum.EDIT === supplierRequest.type ||
                  supplierGroupRequestTypeEnum.EDIT === data.type)
              "
            >
              Change Status
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-left">
              {{ getNameByCode('region', result.store?.region) }}
            </td>
            <td class="text-left">
              {{ getNameByCode('state', result.store?.state) }}
            </td>
            <td class="text-center">
              {{ result.store?.code | dashDisplay }}
            </td>
            <td class="text-left">
              {{ result.store?.name | dashDisplay }}
            </td>
            <td class="text-left">
              <span *ngIf="!isImported">
                {{
                  (data.mode === supplierGroupRequestModeEnum.REQUEST_CREATE || currentSupplier.supplierName
                    ? currentSupplier?.supplierCode
                    : result.supplier?.supplierCode
                  ) | dashDisplay
                }}
              </span>
              <span *ngIf="isImported">
                {{ result.supplier?.supplierCode | dashDisplay }}
              </span>
            </td>
            <td class="text-left">
              <span *ngIf="!isImported">
                {{
                  (data.mode === supplierGroupRequestModeEnum.REQUEST_CREATE || currentSupplier.supplierName
                    ? currentSupplier?.supplierName
                    : result.supplier?.supplierName
                  ) | dashDisplay
                }}
              </span>
              <span *ngIf="isImported">
                {{ result.supplier?.supplierName | dashDisplay }}
              </span>
            </td>
            <td class="text-left">
              <span *ngIf="!isImported">
                {{
                  (data.mode === supplierGroupRequestModeEnum.REQUEST_CREATE || currentSupplier.supplierName
                    ? currentSupplier?.branchNo
                    : result.supplier?.branchNo
                  ) | dashDisplay
                }}
              </span>
              <span *ngIf="isImported">
                {{ result.supplier?.branchNo | dashDisplay }}
              </span>
            </td>
            <td
              class="text-center"
              *ngIf="
                [supplierGroupRequestModeEnum.REQUEST_VIEW, supplierGroupRequestModeEnum.EDIT].includes(data.mode) &&
                (supplierGroupRequestTypeEnum.EDIT === supplierRequest.type ||
                  supplierGroupRequestTypeEnum.EDIT === data.type)
              "
            >
              <div *ngIf="!isImported">
                {{ getItemStatus(result.status, result.supplier) }}
              </div>
              <span *ngIf="isImported">
                {{ 'SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_ITEM_STATUS.' + result.status | translate }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <!--pagination-->
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </div>

  <div class="fixed-row-bottom form-row">
    <div
      class="float-left"
      *ngIf="
        [supplierGroupRequestModeEnum.REQUEST_EDIT, supplierGroupRequestModeEnum.REQUEST_CREATE].includes(data.mode)
      "
    >
      <button
        type="button"
        id="btn-save"
        class="btn btn-secondary"
        appPreventDoubleClick
        (click)="onSave()"
        [disabled]="form?.get('name') === null"
      >
        Save
      </button>
    </div>

    <div class="float-right">
      <button
        type="button"
        id="btn-edit"
        class="btn btn-primary"
        (click)="toggleToEdit()"
        *ngIf="
          (hasPermissionManageSupplierGroup &&
            supplierGroupRequestModeEnum.REQUEST_VIEW === data.mode &&
            supplierRequest?.status === supplierGroupRequestStatusEnum.DRAFT) ||
          (supplierGroupRequestModeEnum.VIEW === data.mode && hasPermissionManageSupplierGroup)
        "
      >
        Edit
      </button>
      <button
        type="button"
        id="btn-cancel"
        class="btn btn-standard mr-2"
        *ngIf="
          hasPermissionManageSupplierGroup &&
          [
            supplierGroupRequestModeEnum.REQUEST_EDIT,
            supplierGroupRequestModeEnum.REQUEST_CREATE,
            supplierGroupRequestModeEnum.EDIT
          ].includes(data.mode)
        "
        (click)="onCloseFullModal()"
      >
        Cancel
      </button>
      <button
        type="button"
        id="btn-submit"
        class="btn btn-primary"
        appPreventDoubleClick
        *ngIf="
          hasPermissionManageSupplierGroup &&
          [
            supplierGroupRequestModeEnum.REQUEST_EDIT,
            supplierGroupRequestModeEnum.REQUEST_CREATE,
            supplierGroupRequestModeEnum.EDIT
          ].includes(data.mode)
        "
        (click)="onSubmit()"
      >
        Submit
      </button>
      <button
        type="button"
        id="btn-reject"
        class="btn btn-special-reject mr-2"
        appPreventDoubleClick
        *ngIf="
          hasPermissionApproveSupplierGroup &&
          [supplierGroupRequestModeEnum.REQUEST_VIEW].includes(data.mode) &&
          supplierRequest?.status === supplierGroupRequestStatusEnum.AWAITING_APPROVAL
        "
        (click)="onReject()"
      >
        Reject
      </button>
      <button
        type="button"
        id="btn-approve"
        class="btn btn-special-approve"
        appPreventDoubleClick
        *ngIf="
          hasPermissionApproveSupplierGroup &&
          [supplierGroupRequestModeEnum.REQUEST_VIEW].includes(data.mode) &&
          supplierRequest?.status === supplierGroupRequestStatusEnum.AWAITING_APPROVAL
        "
        (click)="onApprove()"
      >
        Approve
      </button>
    </div>
  </div>

  <!--modal select supplier-->
  <div
    class="modal fade"
    bsModal
    #modalSelectSupplier="bs-modal"
    [config]="{ backdrop: 'static' }"
    *ngIf="supplierRequest"
    [formGroup]="supplierNameForm"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Supplier to All Stores</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="hideModalSelectSupplier()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="mb-2">Search by: Supplier Name, Vendor Number</div>
              <ng-select
                id="supplierName"
                [items]="supplierList | async"
                bindLabel="supplierName"
                dropdownPosition="auto"
                labelForId="album"
                placeholder="Please select..."
                notFoundText="No Data"
                [loading]="supplierSearchLoading"
                [typeahead]="supplierSearchInput$"
                [virtualScroll]="true"
                (change)="onChangeSupplierName($event)"
                formControlName="supplierName"
                (blur)="onBlurSupplierName()"
                [ngClass]="{
                  'is-invalid': submitSelectSupplier && supplierNameForm.controls.supplierName.errors
                }"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <strong>Supplier Name:</strong>
                    <span [ngOptionHighlight]="search" class="ml-1">
                      {{ item.supplierName }}
                    </span>
                  </div>
                  <small>
                    <strong>Vendor No:</strong>
                    <span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span>
                    {{ ' | ' }}
                    <strong>Branch No:</strong>
                    <span class="ml-1">{{ item.branchNo }}</span>
                  </small>
                </ng-template>
                <ng-template ng-label-tmp let-item="item">
                  {{ item.supplierCode + ' ' + item.supplierName + ' (' + item.branchNo + ')' }}
                </ng-template>
              </ng-select>
              <div
                *ngIf="submitSelectSupplier && supplierNameForm.controls.supplierName.errors"
                class="invalid-display"
              >
                {{ 'ERRORS.REQUIRED' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="btn-supplier-name-modal-cancel"
            class="btn btn-standard"
            (click)="hideModalSelectSupplier()"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-primary"
            appPreventDoubleClick
            (click)="confirmSelectSupplierToAllStores()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" bsModal #importResultModel="bs-modal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title pull-left">{{ importResult?.title }}</h6>
      </div>
      <div class="modal-body">
        <div
          *ngIf="importResult && importResult.table && importResult.table.length > 0"
          class="max-height-table table-responsive"
        >
          <table class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr class="error-background text-center">
                <th scope="col">Row</th>
                <th scope="col">Column</th>
                <th scope="col">Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of importResult.table; index as i">
                <td class="text-center">{{ item.row }}</td>
                <td>{{ item.column }}</td>
                <td>{{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="importResult && importResult.message">
          {{ importResult.message }}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="importResultModel.hide()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
