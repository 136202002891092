import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseComponent } from '../../../base/base.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../shared/enum/notification-type.enum';
import {
  PromotionModeEnum,
  PromotionRequestStatusEnum,
  SelectedPromotionStoreEnum
} from '../../../shared/enum/promotion.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import {
  DataPromotionItem,
  PromotionItem,
  PromotionItemErrorEnum,
  PromotionRequestError,
  PromotionRequestPageModes,
  PromotionRequestResponse,
  PromotionRequestTemplate,
  PromotionSearchCriteria,
  PromotionTheme,
  PromotionTransFrom,
  PromotionTypeEnum,
  StockTypeEnum,
  TaskModuleUrl
} from '../../../shared/models';
import { ConfirmModal } from '../../../shared/models/confirm-modal.mode';
import { NotificationEmit } from '../../../shared/models/notification-emit.model';
import { AuthGuardService } from '../../../shared/services';
import { ClassMarkupReset } from '../../../shared/store/actions/class-markup.actions';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import { DropdownMasterDataReset } from '../../../shared/store/actions/master-data.actions';
import {
  PromotionApproveRequested,
  PromotionCreateSaveRequestAction,
  PromotionCreateSubmitRequestAction,
  PromotionEditSaveRequestAction,
  PromotionRejectRequested,
  PromotionRequestCancelRequested,
  PromotionRequestGetByIdRequestAction,
  PromotionRequestListRequestAction,
  PromotionResetAction,
  ResetPromotionRequestSelected
} from '../../../shared/store/actions/promotion.action';
import { PromotionCreateResponseState } from '../../../shared/store/reducers/promotion-create.reducers';
import {
  selectCoPromotionItems,
  selectPromotionCreated,
  selectPromotionItems,
  selectPromotionRequestListCriteria,
  selectPromotionThemeComing,
  selectRequestPromotionData
} from '../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { PromotionConditionComponent } from './promotion-condition/promotion-condition.component';
import { PromotionDetailsComponent } from './promotion-details/promotion-details.component';
import { PromotionItemComponent } from './promotion-item/promotion-item.component';
import { PromotionStoreComponent } from './promotion-store/promotion-store.component';

@Component({
  selector: 'app-promotion-request',
  templateUrl: './promotion-request.component.html',
  styleUrls: ['./promotion-request.component.scss']
})
export class PromotionRequestComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('promotionItem', { static: false }) promotionItem: PromotionItemComponent;
  @ViewChild('coPromotionItem', { static: false }) coPromotionItem: PromotionItemComponent;
  @ViewChild('promotionDetails', { static: false }) promotionDetails: PromotionDetailsComponent;
  @ViewChild('promotionCondition', { static: false }) promotionCondition: PromotionConditionComponent;
  @ViewChild('promotionStore', { static: false }) promotionStore: PromotionStoreComponent;

  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();
  @Output() data: {
    title: string;
    mode: PromotionRequestPageModes;
    promotionRequestId?: string;
    promotionId?: string;
    originPage?: string;
  };
  @Output() notifyLeaveFormOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() titleOutput: EventEmitter<string> = new EventEmitter<string>();

  private localStore: Observable<any>;
  public saveDraft: boolean;
  public submitted: boolean;
  public promotionForm: FormGroup;
  public status: PromotionRequestStatusEnum;
  private listSearchCriteria: PromotionSearchCriteria;
  public pageMode: PromotionModeEnum;
  isFormDirty: boolean;
  promotionTypeSubscription: Subscription;
  promotionItems$: Observable<PromotionItem[]>;
  coPromotionItems$: Observable<PromotionItem[]>;
  promotionRequestView$: Observable<PromotionRequestResponse>;
  promotion: PromotionRequestResponse;
  version: number;
  isRequestCreateMode: boolean;
  isRequestEditMode: boolean;
  isRequestViewMode: boolean;
  isApprove: boolean;
  hasViewPromotionPermission: boolean;
  hasEditPromotionPermission: boolean;
  hasApprovePromotionPermission: boolean;
  listTheme: PromotionTheme[];

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    protected readonly fb: FormBuilder,
    protected readonly logger: NGXLogger,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected spinner: NgxSpinnerService
  ) {
    super(store, modalService, false);
  }

  ngOnInit() {
    this.initControl();
    this.initData();
    this.initState();
    this.isRequestCreateMode = this.data.mode === PromotionRequestPageModes.REQUEST_CREATE;
    this.isRequestEditMode = this.data.mode === PromotionRequestPageModes.REQUEST_EDIT;
    this.isRequestViewMode = this.data.mode === PromotionRequestPageModes.REQUEST_VIEW;
    this.hasViewPromotionPermission = this.authGuardService.checkPermission(['promotion_v']);
    this.hasEditPromotionPermission = this.authGuardService.checkPermission(['promotion_m']);
    this.hasApprovePromotionPermission = this.authGuardService.checkPermission(['promotion_app']);
  }

  ngAfterViewInit() {}

  get form(): FormGroup {
    return this.promotionForm;
  }

  get details(): FormGroup {
    return this.form.get('promotionDetails') as FormGroup;
  }

  get formCondition(): FormGroup {
    return this.form.get('promotionCondition') as FormGroup;
  }

  get formPromotionItem(): FormArray {
    return this.form.get('promotionItem') as FormArray;
  }

  get formCoPromotionItem(): FormArray {
    return this.form.get('coPromotionItem') as FormArray;
  }

  get formStore(): FormGroup {
    return this.form.get('promotionStores') as FormGroup;
  }

  initControl() {
    const controlsConfig = {
      promotionDetails: this.fb.group({}),
      promotionCondition: this.fb.group({}),
      promotionItem: this.fb.array([], this.barcodeValidator()),
      coPromotionItem: this.fb.array([], this.barcodeValidator()),
      promotionStores: this.fb.group({})
    };

    this.promotionForm = this.fb.group(controlsConfig);
  }

  initState() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.store.dispatch(new PromotionResetAction());
    this.promotionItems$ = this.store.pipe(select(selectPromotionItems));
    this.coPromotionItems$ = this.store.pipe(select(selectCoPromotionItems));

    this.localStore
      .pipe(
        select(selectPromotionCreated),
        filter(value => Boolean(value.result))
      )
      .subscribe((value: PromotionCreateResponseState) => {
        const result = value.result;

        if (result.response) {
          const message = this.getSubmitMessage(value.isSave);
          this.alertModalSuccessAndExit(message);
        } else {
          this.alertErrorModal(value.result.errorResponse);
        }
      });

    // Current searchCriteria
    this.localStore
      .pipe(select(selectPromotionRequestListCriteria))
      .subscribe(criteriaObject => (this.listSearchCriteria = criteriaObject));

    this.localStore.pipe(select(selectPromotionThemeComing)).subscribe(themes => (this.listTheme = themes));

    if (this.data.promotionRequestId && this.canEditView) {
      this.store.dispatch(new PromotionRequestGetByIdRequestAction({ requestId: this.data.promotionRequestId }));
    }
    this.promotionRequestView$ = this.localStore.pipe(select(selectRequestPromotionData));
    this.promotionRequestView$.subscribe(promotionValue => {
      if (promotionValue) {
        this.promotion = promotionValue;
        this.version = promotionValue.version;
        this.status = promotionValue.status as PromotionRequestStatusEnum;
        if (this.data.mode === PromotionRequestPageModes.REQUEST_EDIT) {
          this.toggleToEditMode();
        } else {
          this.form.disable();
        }
      }
    });
  }

  initData() {
    this.status = PromotionRequestStatusEnum.DRAFT;
    this.pageMode = PromotionModeEnum.NEW;
    this.version = 0;
  }

  onSubmit() {
    this.submitted = true;
    this.validatePromotionItem();
    if (this.form.invalid) {
      return;
    }

    this.handleConfirm();
  }

  validatePromotionItem() {
    let messageError: string = null;
    const promotionType = this.details.controls['promotionType'].value;

    const promotionItem = this.formPromotionItem.controls.length;
    const coPromotionItem = this.formPromotionItem.controls.length;

    if (promotionItem === 0 || (coPromotionItem === 0 && promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE)) {
      messageError = 'Please select at least one item before submit.';
      this.form.setErrors({ invalidItem: true });
    }

    if (messageError == null && promotionItem > 0) {
      messageError = this.validateInvalidItem(this.formPromotionItem.controls);
    }

    if (messageError == null && coPromotionItem > 0 && promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE) {
      messageError = this.validateInvalidItem(this.formCoPromotionItem.controls);
    }

    if (messageError) {
      this.showModalError(messageError);
    } else {
      this.formPromotionItem.setErrors(null);
      this.validateSpecialPrice();
    }
  }

  validateInvalidItem(formItems: AbstractControl[]): string {
    let message = null;
    for (const formItem of formItems) {
      const hasItemError =
        formItem.errors ||
        formItem.get('barcode').errors ||
        formItem.get('productName').errors ||
        formItem.get('retailPrice').errors;
      if (hasItemError) {
        message = 'Please delete invalid data before submit.';
        break;
      }
    }

    return message;
  }

  validateSpecialPrice() {
    let isInvalidTotalPrice = false;
    const promotionType = this.details.controls['promotionType'].value;
    if (![PromotionTypeEnum.SPECIAL_PRICE, PromotionTypeEnum.MORE_SPECIAL_PRICE].includes(promotionType)) {
      this.validateInvalidSelectedByStore();
      return;
    }
    const averageUnitPriceValue = this.formCondition.get('averageUnitPrice').value;
    for (const formItem of this.formPromotionItem.controls) {
      if (averageUnitPriceValue >= formItem.get('retailPrice').value) {
        isInvalidTotalPrice = true;
        break;
      }
    }
    if (isInvalidTotalPrice) {
      this.formCondition.get('totalPrice').setErrors({ invalidPrice: true });
      this.showModalError('Average new price should be less than retail price of all items.');
    } else {
      this.formCondition.get('totalPrice').setErrors(null);
      this.validateInvalidSelectedByStore();
    }
  }

  validateInvalidSelectedByStore() {
    const stores = this.formStore.get('stores') as FormArray;
    if (
      this.formStore.controls['selectStore'].value === SelectedPromotionStoreEnum.SELECT_BY_STORE &&
      stores.controls.length === 0
    ) {
      this.showModalError('Please select at least one store before submit.');
      this.formStore.get('stores').setErrors({ invalidStore: true });
    } else {
      this.formStore.get('stores').setErrors(null);
    }
  }

  showModalError(message: string) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  checkDateTime(curr: string, value: string): boolean {
    const date1 = moment(curr);
    const date2 = moment(value);

    return date2.diff(date1) <= 0;
  }

  onSave() {
    this.saveDraft = true;
    const formValue = this.details.getRawValue();
    if (!formValue.promotionName || this.details.controls['promotionName'].invalid) {
      return;
    }

    const req = this.preparePromotionData();

    if (this.data.mode === PromotionRequestPageModes.REQUEST_EDIT && this.data.promotionRequestId !== null) {
      this.store.dispatch(new PromotionEditSaveRequestAction({ promotionCreatRequest: req }));
    } else {
      this.store.dispatch(new PromotionCreateSaveRequestAction({ promotionCreatRequest: req }));
    }
  }

  onExit() {
    this.isFormDirty = this.form.dirty || this.isFormDirty;
    if (this.isFormDirty) {
      const initialState: ConfirmModal = {
        title: this.translate.instant('LEAVE_WITHOUT_SAVING'),
        okText: this.translate.instant('STAY_ON_PAGE'),
        cancelText: this.translate.instant('LEAVE'),
        message: this.translate.instant('CONFIRM_LEAVE_WITHOUT_SAVING')
      };

      this.notifyParent.emit({
        initialState,
        notificationType: NotificationTypeEnum.CONFIRM
      });
    } else {
      this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
    }
  }

  onCancelPromotion() {
    if (!this.hasEditPromotionPermission) {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel promotion request number <strong>&quot;${this.promotion.requestNo}&quot;</strong>?`,
        okText: 'Yes, cancel',
        label: 'Reason',
        isRequiredConfirmMessage: true
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionRequestCancelRequested({
              id: this.promotion.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showCancelButton(status: string): boolean {
    return (
      status !== null &&
      (status.toLocaleLowerCase() === 'awaiting_schedule' || status.toLocaleLowerCase() === 'active')
    );
  }

  handleConfirm() {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to submit?'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          const reqData = this.preparePromotionData();
          this.store.dispatch(new PromotionCreateSubmitRequestAction({ promotionCreatRequest: reqData }));
        }
      });
  }

  preparePromotionData(): PromotionRequestTemplate {
    const rawValue = this.form.getRawValue();
    const temp: PromotionRequestTemplate = new PromotionRequestTemplate();

    temp.id = this.data.promotionRequestId || null;
    temp.type = this.pageMode;
    temp.status = this.status;
    temp.version = this.version;
    temp.details = { ...rawValue.promotionDetails };
    temp.condition = {
      ...rawValue.promotionCondition,
      totalPrice: PromotionTransFrom.getMoney(rawValue.promotionCondition.totalPrice),
      averageUnitPrice: PromotionTransFrom.getMoney(rawValue.promotionCondition.averageUnitPrice),
      selectedRetailPrice: PromotionTransFrom.getMoney(rawValue.promotionCondition.selectedRetailPrice)
    };

    if (rawValue.promotionDetails.effectiveDate) {
      const eff = moment(rawValue.promotionDetails.effectiveDate);
      const getTime = rawValue.promotionDetails.effectiveTime.split(':');
      eff.set('hour', Number(getTime[0]));
      eff.set('minute', Number(getTime[1]));
      temp.details.effectiveDate = eff.format(environment.dateTimeFormat);
    } else {
      temp.details.effectiveDate = '';
    }

    if (temp.details.supplier) {
      temp.details.supplier = {
        id: temp.details.supplier.id,
        supplierCode: temp.details.supplier.supplierCode,
        supplierName: temp.details.supplier.supplierName
      };
    }

    temp.details.stockType = StockTypeEnum.NORMAL_PRICE;

    if (rawValue.promotionDetails.theme) {
      const themeSelect = this.listTheme.filter(item => item.id === rawValue.promotionDetails.theme.id)[0];

      if (themeSelect) {
        temp.details.theme = {
          id: themeSelect.id,
          name: themeSelect.name
        };
      }
    }

    temp.details.expireDate = '';
    if (rawValue.promotionDetails.expireDate) {
      const exp = moment(rawValue.promotionDetails.expireDate);
      const getTime = rawValue.promotionDetails.expireTime.split(':');
      exp.set('hour', Number(getTime[0]));
      exp.set('minute', Number(getTime[1]));
      temp.details.expireDate = exp.format(environment.dateTimeFormat);
    }

    temp.promotionItems = this.generatePromotionItem(rawValue.promotionItem);
    if (rawValue.promotionDetails.promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE) {
      temp.coPromotionItems = this.generatePromotionItem(rawValue.coPromotionItem);
    }

    temp.selectStore = rawValue.promotionStores.selectStore;
    temp.stores = [];
    if (rawValue.promotionStores.selectStore === SelectedPromotionStoreEnum.SELECT_BY_STORE) {
      temp.stores = rawValue.promotionStores.stores;
    }

    return temp;
  }

  generatePromotionItem(promotionItemData): PromotionItem[] {
    const promotionItems = new Array<PromotionItem>();
    promotionItemData.forEach(value => {
      let tempDiscountCode = null;
      if (value.discountCode) {
        tempDiscountCode = value.discountCode.code ? value.discountCode.code : value.discountCode;
      }
      const setPromotionItem = {
        barcode: value.barcode,
        articleNo: value.articleNo,
        productName: value.productName,
        unit: value.unit,
        unitFactor: value.unitFactor,
        movingAverage: PromotionTransFrom.getMoney(value.movingAverage),
        retailPrice: PromotionTransFrom.getMoney(value.retailPrice),
        discountCode: tempDiscountCode,
        supplierCompensate: PromotionTransFrom.getMoney(value.supplierCompensate),
        partnerCompensate: PromotionTransFrom.getMoney(value.partnerCompensate)
      } as PromotionItem;
      promotionItems.push(setPromotionItem);
    });
    return promotionItems;
  }

  alertErrorModal(errorResponse: PromotionRequestError) {
    const initialState = {
      title: 'Failed',
      message: errorResponse.data
        ? this.translate.instant(errorResponse.translateKey, { context: errorResponse.message })
        : 'Please delete invalid data before submit.'
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    if (errorResponse.code === '00001' && errorResponse.data !== null) {
      this.isErrorResponse(errorResponse.data);
    }

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
      if (alertModal.content.actions) {
        alertModal.content.actions.unsubscribe();
      }

      if (errorResponse.code === '04005' || errorResponse.code === '00003') {
        window.location.reload();
      }
    });
  }

  isErrorResponse(data: DataPromotionItem) {
    if (data.promotionItems && data.promotionItems.length > 0) {
      this.formPromotionItem.controls.forEach(formControls => {
        data.promotionItems
          .filter(value => value.barcode === formControls.get('barcode').value && value.errorMessage)
          .forEach(errorMessage => this.setMessageError(formControls, errorMessage.errorMessage));
      });
    }

    if (data.coPromotionItems && data.coPromotionItems.length > 0) {
      this.formCoPromotionItem.controls.forEach(formControls => {
        data.coPromotionItems
          .filter(value => value.barcode === formControls.get('barcode').value && value.errorMessage)
          .forEach(errorMessage => this.setMessageError(formControls, errorMessage.errorMessage));
      });
    }
  }

  setMessageError(controlItem: AbstractControl, errorMessage: PromotionItemErrorEnum) {
    switch (errorMessage) {
      case PromotionItemErrorEnum.INACTIVE_BARCODE:
      case PromotionItemErrorEnum.INVALID_BARCODE:
        controlItem.get('barcode').setErrors(this.setError(errorMessage));
        controlItem.updateValueAndValidity({ onlySelf: false });
        break;
      case PromotionItemErrorEnum.RESTRICT_ITEM:
      case PromotionItemErrorEnum.STATUS_IS_DELISTED:
      case PromotionItemErrorEnum.NOT_ALLOW_STORE_USE:
      case PromotionItemErrorEnum.NOT_ALLOW_FIX_ASSET:
        controlItem.get('productName').setErrors(this.setError(errorMessage));
        controlItem.updateValueAndValidity({ onlySelf: false });
        break;
      default:
    }
  }

  setError(errorMessage: PromotionItemErrorEnum) {
    switch (errorMessage) {
      case PromotionItemErrorEnum.INVALID_BARCODE:
        return { invalidBarcode: true };
      case PromotionItemErrorEnum.INACTIVE_BARCODE:
        return { isInactive: true };
      case PromotionItemErrorEnum.STATUS_IS_DELISTED:
        return { isStatusDelisted: true };
      case PromotionItemErrorEnum.NOT_ALLOW_FIX_ASSET:
        return { isFixAssetItem: true };
      case PromotionItemErrorEnum.NOT_ALLOW_STORE_USE:
        return { isStoreUseItem: true };
      case PromotionItemErrorEnum.RESTRICT_ITEM:
        return { isRestrictItem: true };
      default:
        return null;
    }
  }

  isCoPromotion(): boolean {
    const promotionType = this.details.controls['promotionType'] && this.details.controls['promotionType'].value;
    return promotionType === PromotionTypeEnum.BUY_TWO_SAVE_MORE;
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }

  getSubmitMessage(isSave: boolean) {
    if (isSave) {
      return 'The request has been saved.';
    } else {
      return 'The request has been sent to approver.';
    }
  }

  toggleToEditMode() {
    this.isRequestViewMode = false;
    this.isRequestEditMode =
      this.data.mode === PromotionRequestPageModes.REQUEST_VIEW ||
      this.data.mode === PromotionRequestPageModes.REQUEST_EDIT;
    this.data.mode = PromotionRequestPageModes.REQUEST_EDIT;
    this.data.title = 'Edit Promotion Request';

    this.promotionDetails.toggleEditPromotionDetail();
    this.promotionCondition.toggleEditPromotionCondition();
    this.promotionItem.toggleEditPromotionItems();
    this.coPromotionItem.toggleEditPromotionItems();
    this.promotionStore.toggleEditPromotionStore();
  }

  refreshPromotionList(isResetToFirstPage = false) {
    if (isResetToFirstPage && (this.saveDraft || this.submitted)) {
      this.listSearchCriteria.page = 0;
    }
    if (this.data.originPage && this.data.originPage === TaskModuleUrl.MY_TASKS) {
      this.store.dispatch(new TasksByRoleListRequestAction());
    } else {
      this.store.dispatch(new PromotionRequestListRequestAction(this.listSearchCriteria));
    }
  }

  reject() {
    this.isApprove = false;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Reject"?',
        label: 'Comment',
        isRequiredConfirmMessage: true,
        okText: 'Reject',
        okClass: 'btn btn-special-reject'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionRejectRequested({
              id: this.promotion.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  approve() {
    this.isApprove = true;
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to "Approve"?',
        isRequiredConfirmMessage: false,
        label: 'Comment',
        okText: 'Approve',
        okClass: 'btn btn-special-approve'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PromotionApproveRequested({
              id: this.promotion.requestNo,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  alertModalSuccessAndExit(message: string) {
    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Success',
        message
      },
      keyboard: false,
      backdrop: 'static'
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
        this.modalService.hide(1);
      }
    });
  }

  barcodeValidator(): ValidatorFn {
    return (fa: FormArray) => {
      const array = fa.getRawValue();
      const availableBarcodes = [];
      for (let i = 0; i < array.length; i++) {
        const isDuplicated = availableBarcodes.indexOf(array[i].barcode) > -1;
        if (!isDuplicated) {
          availableBarcodes.push(array[i].barcode);
        }

        if (!fa.controls[i].errors || fa.controls[i].getError('duplicated')) {
          fa.controls[i].setErrors(isDuplicated && { duplicated: true });
        }
      }
      return null;
    };
  }

  get promotionRequestPageMode() {
    return PromotionRequestPageModes;
  }

  get canEdit(): boolean {
    return [PromotionRequestPageModes.REQUEST_CREATE, PromotionRequestPageModes.REQUEST_EDIT].includes(
      this.data.mode
    );
  }

  get canEditView(): boolean {
    return [PromotionRequestPageModes.REQUEST_EDIT, PromotionRequestPageModes.REQUEST_VIEW].includes(this.data.mode);
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.notifyParent.emit({ notificationType: NotificationTypeEnum.FORCE_CLOSE });
  }

  ngOnDestroy(): void {
    this.refreshPromotionList(this.status === PromotionRequestStatusEnum.DRAFT);
    if (this.promotionTypeSubscription) {
      this.promotionTypeSubscription.unsubscribe();
    }
    if (this.titleOutput) {
      this.titleOutput.unsubscribe();
    }
    if (this.notifyLeaveFormOutput) {
      this.notifyLeaveFormOutput.unsubscribe();
    }
    if (this.notifyParent) {
      this.notifyParent.unsubscribe();
    }
    this.store.dispatch(new PromotionResetAction());
    this.store.dispatch(new ResetPromotionRequestSelected());
    this.store.dispatch(new DropdownMasterDataReset());
    this.store.dispatch(new ClassMarkupReset());
    super.unsubscribeBase();
  }
}
