import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { UserPageModes } from '../../../shared/enum/user.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab, UserListViewDtoUserListViewDto, UserSearchCriteria } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { UserListRequestAction } from '../../../shared/store/actions/user-info.action';
import { UsersState } from '../../../shared/store/reducers/users.reducers';
import {
  selectAllUsersList,
  selectUsersList,
  selectUsersListCriteria
} from '../../../shared/store/selectors/user-selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { UserCreateComponent } from '../user-create/user-create.component';
import { UserEditComponent } from '../user-edit/user-edit.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent extends BaseSearchComponent<
  UserSearchCriteria,
  UserListViewDtoUserListViewDto,
  UsersState
> {
  private localStore: Observable<any>;
  public statusList: any[];

  public listRoute: Array<RouteLinkTab>;
  private bsModalRef: BsModalRef;
  public dateFormat = environment.dateFormat;
  public pageMode = UserPageModes;

  public hasViewPermission = false;
  public hasManagePermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllUsersList, selectUsersList);
    super.subscribeForSaveSuccess();

    this.hasViewPermission = this.authGuardService.checkPermission(['user_bo_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['user_bo_m']);
  }
  doInit() {
    this.setRouteTab();
    this.statusList = filterDropdown.selectedActive;

    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectUsersListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: ['']
    });
  }

  onAdvanceSubmit() {}
  prepareSearchCriteriaTags() {}
  search(criteriaObj: any) {
    this.store.dispatch(new UserListRequestAction(criteriaObj));
  }

  goToView(mode: UserPageModes, viewParams?: UserListViewDtoUserListViewDto) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        UserEditComponent,
        {
          title: mode === UserPageModes.EDIT ? 'Edit User' : 'View User',
          mode: mode,
          userNo: viewParams.no
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  gotoCreate() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        UserCreateComponent,
        {
          mode: UserPageModes.CREATE,
          title: 'Create User'
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      active: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  setFirstPage() {
    this.currentPage = 1;
  }
  setInitialValue() {
    this.pageSize = 20;
  }

  getStatus(status: boolean): string {
    return status ? 'ACTIVE' : 'INACTIVE';
  }

  setRouteTab(): void {
    this.listRoute = [];
    this.listRoute.push({ tabName: 'User List', url: '/user-management/user-list' });
  }

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }
  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  doDestroy() {}
}
