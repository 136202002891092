import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { SupplierGroup, SupplierGroupContent, SupplierGroupSearchCriteria } from '../../models/supplier-group.model';
import { SupplierGroupActions, SupplierGroupActionTypes } from '../actions/supplier-group.actions';

export interface SupplierGroupState extends EntityState<SupplierGroupContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  supplierGroup: SupplierGroup;
  criteriaObject: SupplierGroupSearchCriteria;
  totalElements: number;
  totalPages: number;
  errorResponse: ErrorResponse;
}

export const adapter: EntityAdapter<SupplierGroupContent> = createEntityAdapter<SupplierGroupContent>();

export const initialSupplierGroupState: SupplierGroupState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  supplierGroup: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  errorResponse: null
});

export function supplierGroupReducers(
  state = initialSupplierGroupState,
  action: SupplierGroupActions
): SupplierGroupState {
  switch (action.type) {
    case SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case SupplierGroupActionTypes.SUPPLIER_GROUP_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SupplierGroupActionTypes.SUPPLIER_GROUP_VIEW_LOADED: {
      return {
        ...state,
        supplierGroup: action.payload
      };
    }
    case SupplierGroupActionTypes.SUPPLIER_GROUP_RESET: {
      return {
        ...state,
        supplierGroup: null,
        errorResponse: null
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
