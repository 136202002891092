<div
  class="information-container container-fluid"
  [ngClass]="{
    'bg-h-change':
      isRequestViewMode && listOfChange['supplierArticleNo'] && listOfChange['supplierArticleNo']['after']
  }"
>
  <div class="row">
    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Article No.</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.articleNo | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Product Name</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.productName | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3"></div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Product Status</span>
        <span class="info-header-value">
          {{
            (productMaster$ | async)?.statusCJ
              ? ('CJ_PRODUCT.STATUS_DISPLAY.' + (productMaster$ | async)?.statusCJ | translate)
              : ((productMaster$ | async)?.statusCJ | dashDisplay)
          }}</span
        >
      </div>
    </div>
  </div>

  <div class="row mx-auto line"></div>

  <div class="row mt-3">
    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Brand</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.brand | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Source Supplier</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.supplierName | dashDisplay }}</span>
      </div>
    </div>

    <!--    <div class="col-md-3">-->
    <!--      <div class="d-flex flex-md-column">-->
    <!--        <span class="info-header-label">CJ Picking Unit</span>-->
    <!--        <span class="info-header-value">{{ (productMaster$ | async)?.pickingUnit | dashDisplay }}</span>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">VAT</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.vat | boolDisplay }}</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Restricted Item</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.restrictedItem | boolDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Shelf Life (Day)</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.shelfLife | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">Minimum Shelf Life (Day)</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.minimumShelfLife | dashDisplay }}</span>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Segment</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.segmentName | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Family</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.familyName | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Class</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.className | dashDisplay }}</span>
      </div>
    </div>

    <div class="col-md-3">
      <div class="d-flex flex-md-column">
        <span class="info-header-label">CJ Subclass</span>
        <span class="info-header-value">{{ (productMaster$ | async)?.subClassName | dashDisplay }}</span>
      </div>
    </div>
  </div>
</div>
