import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { PermissionAction } from '../../../shared/enum/permission-action';
import { PurchaseOrderStatusEnum, ShipToType } from '../../../shared/enum/purchase-order.enum';
import { PurchaseRequestTypeEnum } from '../../../shared/enum/purchase-request.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { ErrorResponse } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  PurchaseOrder,
  PurchaseOrderContent,
  PurchaseOrderPrintCriteria,
  PurchaseOrderSearchCriteria
} from '../../../shared/models/purchase-order.model';
import {
  grManagePermissions,
  grViewPermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prManagePermissions,
  prViewPermissions
} from '../../../shared/permissions/permissions';
import { AuthGuardService } from '../../../shared/services';
import { PurchaseOrderService } from '../../../shared/services/purchase-order.service';
import {
  PurchaseOrderCancelRequest,
  PurchaseOrderCloseRequest,
  PurchaseOrderListRequest,
  PurchaseOrderReset
} from '../../../shared/store/actions/purchase-order.actions';
import { PurchaseOrderState } from '../../../shared/store/reducers/purchase-order.reducers';
import {
  selectPurchaseOrder,
  selectPurchaseOrderCancelError,
  selectPurchaseOrderList
} from '../../../shared/store/selectors/purchaser-order.selectors';
import { selectPurchaseRequestList } from '../../../shared/store/selectors/purchaser-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { generatedFilenamePdf } from '../../../shared/utils/generate-filename-util';
import { PermissionsUtil } from '../../../shared/utils/permissions-util';
import { GoodsReceiveViewComponent } from '../goods-receive-view/goods-receive-view.component';
import { PurchaseOrderViewComponent } from '../purchase-order-view/purchase-order-view.component';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent
  extends BaseSearchComponent<PurchaseOrderSearchCriteria, PurchaseOrderContent, PurchaseOrderState>
  implements OnInit, OnDestroy {
  public bsModalRef: BsModalRef;
  public bsGrModalRef: BsModalRef;
  public localStore: Observable<any>;

  public dateTag: string;
  public dateStringTag: string;
  public poTypeTag: string;
  public poTypeStringTag: string;

  public poStatusFilter = filterDropdown.poStatusFilter;
  public poTypeFilter = filterDropdown.prTypeFilter;
  public purchaseRequestTypeEnum = PurchaseRequestTypeEnum;
  public purchaseOrderStatusEnum = PurchaseOrderStatusEnum;
  public shipToType = ShipToType;
  public permissionAction = PermissionAction;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected readonly translate: TranslateService,
    protected authGuardService: AuthGuardService,
    private purchaseOrderService: PurchaseOrderService,
    private spinner: NgxSpinnerService,
    protected permissionsUtil: PermissionsUtil
  ) {
    super(store, modalService, selectPurchaseOrderList, selectPurchaseOrder);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectPurchaseRequestList))
      .subscribe(() => (this.currentPage = this.criteriaObject.page + 1));
    this.localStore.pipe(select(selectPurchaseOrderCancelError)).subscribe(error => {
      if (error) {
        const initialState = {
          title: 'Error',
          message: error.message,
          isRefresh: true
        };
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });
        alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
          if (result === ModalButtonResponseEnum.OK) {
            this.store.dispatch(new PurchaseOrderReset());
          }
        });
      }
    });
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.poStatusFilter[0].value],
      purchaseType: [null],
      createdDateFrom: [null],
      createdDateTo: [null]
    });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    this.doSearch(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
    if (this.bsGrModalRef) {
      this.bsGrModalRef.hide();
    }
  }

  onchangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  getColorStatus(status: PurchaseOrderStatusEnum) {
    return status ? status.toLowerCase() : '';
  }

  getPurchaseType(purchaseOrder: PurchaseOrderContent) {
    if (purchaseOrder.type === this.purchaseRequestTypeEnum.Z9) {
      return purchaseOrder.advance
        ? `Z9(${this.translate.instant('DAY_INDEX.' + purchaseOrder.orderSchedule)}) - Advance`
        : `Z9(${this.translate.instant('DAY_INDEX.' + purchaseOrder.orderSchedule)})`;
    } else if (purchaseOrder.type === this.purchaseRequestTypeEnum.Z9_EDIT) {
      return `Z9(${this.translate.instant('DAY_INDEX.' + purchaseOrder.orderSchedule)}) - Edit`;
    } else if (purchaseOrder.type === this.purchaseRequestTypeEnum.HOT_PRICE) {
      return 'Hot Price';
    } else if (purchaseOrder.type === this.purchaseRequestTypeEnum.SUPPLIER) {
      return 'Supplier';
    } else if (purchaseOrder.type === this.purchaseRequestTypeEnum.WAREHOUSE) {
      return 'Warehouse';
    }
  }

  goToView(po: PurchaseOrder) {
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          PurchaseOrderViewComponent,
          {
            poNo: po.docNo,
            id: po.id,
            title: 'View PO'
          },
          true
        )
      }
    });
  }

  goToGr(poNo: string) {
    this.bsGrModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: true,
      initialState: {
        childItem: new ChildItem(
          GoodsReceiveViewComponent,
          {
            poNo,
            mode: 'CREATE',
            title: 'Create GR'
          },
          true
        )
      }
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.createdDateFrom && !formValue.createdDateTo && !formValue.purchaseType) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      purchaseType: formValue.purchaseType,
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  clearDateFilter() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearTypeFilter() {
    this.setFirstPage();
    this.searchForm.controls['purchaseType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      purchaseType: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['purchaseType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: null,
      createdDateTo: null,
      purchaseType: null,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.poTypeTag = null;
    this.poTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'PO Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.purchaseType && this.criteriaObject.purchaseType.length > 0) {
      this.poTypeStringTag = 'Purchase Type';
      const poType = this.poTypeFilter
        .filter(data => {
          return this.criteriaObject.purchaseType.indexOf(data.value) > -1;
        })
        .map(status => status.label);

      const poTypes = poType.join(', ');
      this.poTypeTag = `"${poTypes}"`;
    }
  }
  search(criteriaObj) {
    this.store.dispatch(new PurchaseOrderListRequest(criteriaObj));
  }

  setRouteTab() {
    const hasPrPermission = this.authGuardService.checkPermission(
      [...prViewPermissions, ...prManagePermissions, ...prApprovePermissions],
      true
    );
    const hasPoPermission = this.authGuardService.checkPermission(
      [...poViewPermissions, ...poManagePermissions, ...grManagePermissions],
      true
    );
    const hasGrPermission = this.authGuardService.checkPermission(
      [...grViewPermissions, ...grManagePermissions],
      true
    );
    if (hasPrPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_REQUEST.PURCHASE_REQUEST',
        url: '/purchase/purchase-request-list'
      });
    }

    if (hasPoPermission) {
      this.listRoute.push({
        tabName: 'PURCHASE_ORDER.PURCHASE_ORDER',
        url: '/purchase/purchase-order-list'
      });
    }

    if (hasGrPermission) {
      this.listRoute.push({
        tabName: 'GOODS_RECEIVE.GOODS_RECEIVE',
        url: '/purchase/goods-receive-list'
      });
    }
  }

  onCancelPo(id: string, docNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel PO Number "${docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseOrderCancelRequest({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  onPrintPdf(purchaseOrder: PurchaseOrderContent) {
    if (!purchaseOrder) {
      return;
    }
    const param: PurchaseOrderPrintCriteria = {
      id: purchaseOrder.id,
      format: 'pdf',
      locale: 'th'
    };

    this.purchaseOrderService.printPdfOrder(param).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf;charset=utf-8' });
        saveAs(blob, generatedFilenamePdf(purchaseOrder.docNo));
      },
      error => {
        this.alertErrorModal(error);
      }
    );
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      if (errorResponse.code !== '00001') {
        initialState.message = this.translate.instant(errorResponse.translateKey);
        const alertModal = this.modalService.show(AlertModalComponent, {
          initialState
        });

        alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe(() => {
          if (alertModal.content.actions) {
            alertModal.content.actions.unsubscribe();
          }
        });
      }
    }
  }

  isCanPrintPDF(status: PurchaseOrderStatusEnum) {
    return [
      this.purchaseOrderStatusEnum.AWAITING_SEND_PO,
      this.purchaseOrderStatusEnum.AWAITING_SUPPLIER_RESPONSE,
      this.purchaseOrderStatusEnum.AWAITING_GR,
      this.purchaseOrderStatusEnum.CLOSED,
      this.purchaseOrderStatusEnum.GR_COMPLETED
    ].includes(status);
  }

  onClosePo(id: string, docNo: string) {
    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to Close PO Number "${docNo}"?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, close PO'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new PurchaseOrderCloseRequest({
              id,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  showHistory(po: PurchaseOrderContent) {
    const initialState = {
      title: 'History',
      historyHeader: `PO Number: ${po.docNo}`,
      auditLogs: po.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }
}
