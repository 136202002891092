import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WarehouseService } from '../../services/warehouse.service';
import { BillToMasterActionTypes, BillToMasterListResponseAction } from '../actions/bill-to-master.actions';
import { LayoutActionLoadError } from '../actions/layout.action';
export class BillToMasterEffects {
    constructor(actions$, logger, warehouseService) {
        this.actions$ = actions$;
        this.logger = logger;
        this.warehouseService = warehouseService;
        this.billToMasterList$ = this.actions$.pipe(ofType(BillToMasterActionTypes.BILL_TO_MASTER_LIST_REQUEST), map(() => {
            this.logger.debug(`@Effect ${BillToMasterActionTypes.BILL_TO_MASTER_LIST_REQUEST}`);
        }), switchMap(() => {
            return this.warehouseService.getBillingMasterData().pipe(map(response => {
                return new BillToMasterListResponseAction(response.data.billings);
            }), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], BillToMasterEffects.prototype, "billToMasterList$", void 0);
