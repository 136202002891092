import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { RequestSectionEnum } from '../../../../../shared/enum/request-section.enum';
import { RequestStatusEnum } from '../../../../../shared/enum/request-status.enum';
import { RequestPageModesEnum, RequestStepEnum, RequestTypeEnum } from '../../../../../shared/enum/request-step.enum';
import { TDStorePage } from '../../../../../shared/enum/td-store-page.enum';
import { MerchantRequestViewResponse, StoreRequestViewResponse } from '../../../../../shared/models';
import { AppStates } from '../../../../../shared/store/state/app.states';
import { getSelectByPage } from '../../../../../shared/utils/get-select-by-page-util';
import { TDStoreWorkflowUtil } from '../../../../../shared/utils/td-store-workflow-util';
import { NewMasterData } from '../../../../gql/common.gql';

@Component({
  selector: 'app-delivery-times',
  templateUrl: './delivery-times.component.html',
  styleUrls: ['./delivery-times.component.scss']
})
export class DeliveryTimesComponent implements OnInit, OnDestroy {
  public merchantRequestView$: Observable<MerchantRequestViewResponse>;
  @Input() parentForm: FormGroup;
  @Input() submitted: boolean;
  @Input() page: TDStorePage;
  @Input() index: number;
  @Input() mode: RequestPageModesEnum;
  @Input() restrictDeliveryTimeSelectValue: Array<NewMasterData>;

  private localStore: Observable<any>;

  private type: RequestTypeEnum;
  private status: RequestStatusEnum;
  private step: RequestStepEnum;

  public restrictedDeliveryTimeFrom: Array<NewMasterData>;
  public restrictedDeliveryTimeTo: Array<NewMasterData>;

  constructor(
    public fb: FormBuilder,
    public store: Store<AppStates>,
    private readonly tdStoreWorkflowUtil: TDStoreWorkflowUtil
  ) {}

  ngOnInit() {
    this.type = RequestTypeEnum.NEW;
    this.step = RequestStepEnum.PROFILE;
    this.status = RequestStatusEnum.DRAFT;

    this.parentForm.addControl('deliveryTimes', this.fb.array([]));
    this.addForm();

    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.setRestrictedDeliveryTimes(this.restrictDeliveryTimeSelectValue);

    this.merchantRequestView$ = this.localStore.pipe(select(getSelectByPage(this.page)));
    this.merchantRequestView$.pipe(untilComponentDestroyed(this)).subscribe(results => {
      if (results && results.merchantInfo && results.merchantInfo.storeProfile) {
        this.setDeliveryTimes(results);
      }
    });
  }

  ngOnDestroy() {}

  get deliveryTimes() {
    return this.parentForm.get('deliveryTimes') as FormArray;
  }

  addForm() {
    this.deliveryTimes.push(this.createForm());
  }

  createForm() {
    const initialNull = [{ value: null, disabled: false }];

    return this.fb.group(
      {
        from: initialNull,
        to: initialNull
      },
      { validator: this.timeValidator() }
    );
  }

  timeValidator(): ValidatorFn {
    return (fg: FormGroup) => {
      const from = fg.get('from').value;
      const to = fg.get('to').value;
      if (from && to && from >= to) {
        return { invalid: true };
      } else if (fg.errors && !fg.hasError('invalid')) {
        return fg.errors;
      } else {
        return null;
      }
    };
  }

  overlapValidator() {
    const data = this.deliveryTimes.value;
    const availableTimes = [];
    if (data.length > 1) {
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          availableTimes.push(data[i]);
        } else {
          const control = this.deliveryTimes.controls[i];
          const isOverlapped = this.isOverlapped(availableTimes, data[i]);

          control.setErrors(
            (control.getError('invalid') || isOverlapped) && {
              ...control.errors,
              overlapped: !!isOverlapped
            }
          );

          if (!control.errors) {
            availableTimes.push(data[i]);
          }
        }
      }
    }
  }

  isOverlapped(arr, b) {
    return arr.find(a => {
      return (
        a.from &&
        a.to &&
        b.from &&
        b.to &&
        ((a.from === b.from && a.to === b.to) ||
          (a.from <= b.from && b.from < a.to) ||
          (a.from < b.to && b.to <= a.to) ||
          (b.from < a.from && a.from < b.to))
      );
    });
  }

  onSelectedDeliveryTime(rowIndex) {
    const fieldUpdates = ['from', 'to'];
    for (const field of fieldUpdates) {
      this.deliveryTimes
        .at(rowIndex)
        .get(field)
        .setValidators([Validators.required]);
      this.deliveryTimes
        .at(rowIndex)
        .get(field)
        .updateValueAndValidity();
    }

    this.overlapValidator();
  }

  checkEditPermission() {
    return this.deliveryTimes.disabled;
  }

  deleteDeliveryTime(index) {
    this.deliveryTimes.removeAt(index);
    this.overlapValidator();

    if (index === 0 && this.deliveryTimes.length > 0) {
      // remove if there is overlapped on the first row
      const newFirstRow = this.deliveryTimes.controls[0];
      const isOverlapped = newFirstRow.getError('overlapped');
      const isInvalid = newFirstRow.getError('invalid');
      if (isOverlapped) {
        this.deliveryTimes.controls[0].setErrors(
          isInvalid && {
            ...this.deliveryTimes.controls[0].errors,
            overlapped: null
          }
        );
      }
    }

    if (this.deliveryTimes.length === 0) {
      this.addForm();
    }
  }

  setRestrictedDeliveryTimes(deliveryTimes: Array<NewMasterData>) {
    this.restrictedDeliveryTimeTo = deliveryTimes.slice(1);
    this.restrictedDeliveryTimeFrom = deliveryTimes.slice(0, deliveryTimes.length - 1);
  }

  setDeliveryTimes(merchantRequestView: MerchantRequestViewResponse | StoreRequestViewResponse) {
    this.type = merchantRequestView.type || RequestTypeEnum.EDIT;
    this.step = merchantRequestView.step || RequestStepEnum.EDIT_PROFILE;
    this.status = merchantRequestView.status || RequestStatusEnum.DRAFT;

    const currentData = merchantRequestView.merchantInfo && merchantRequestView.merchantInfo.storeProfile[this.index];

    if (currentData && currentData.deliveryTimes && currentData.deliveryTimes.length > 0) {
      currentData.deliveryTimes.forEach((field, i) => {
        if (!this.deliveryTimes.at(i)) {
          this.addForm();
        }

        this.deliveryTimes.at(i).patchValue({
          from: field.from,
          to: field.to
        });
      });
    }

    this.setDeliveryTimesCtrl(this.type, this.page, this.step, this.mode);
  }

  setDeliveryTimesCtrl(
    localType: RequestTypeEnum,
    localPage: TDStorePage,
    localStep: RequestStepEnum,
    localMode: RequestPageModesEnum
  ) {
    const editSection = [TDStorePage.MERCHANT_EDIT, TDStorePage.MERCHANT_REQUEST].includes(localPage)
      ? RequestSectionEnum.PROFILE
      : RequestSectionEnum.STORE_PROFILE;

    const canEditByWorkflow = this.tdStoreWorkflowUtil.canEditSection(localType, localPage, localStep, editSection);

    if (localMode === RequestPageModesEnum.REQUEST_EDIT && canEditByWorkflow) {
      this.deliveryTimes.enable();
      this.overlapValidator();
    } else {
      this.deliveryTimes.disable();
    }
  }
}
