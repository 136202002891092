import { createEntityAdapter } from '@ngrx/entity';
import * as uuid from 'uuid';
import { Z8ConditionsActionType } from '../actions/z8-conditions.actions';
const ɵ0 = (z8Parameter) => `${uuid.v4()}_${z8Parameter.store}_${z8Parameter.productLevel}_${z8Parameter.productValue.code}_${z8Parameter.startDate}_${z8Parameter.endDate}`;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialZ8ConditionsResponseState = adapter.getInitialState({
    criteriaObject: {
        page: 0,
        size: 20,
        requestNo: null
    },
    totalElements: 0,
    totalPages: 20,
    auditLogs: null
});
export function z8ConditionsReducers(state = initialZ8ConditionsResponseState, action) {
    switch (action.type) {
        case Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESET:
            return initialZ8ConditionsResponseState;
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
