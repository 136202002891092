import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Z8ExportCriteria,
  Z8File,
  Z8ParameterListResponse,
  Z8ParameterListSearchCriteria,
  Z8ParameterRequest,
  Z8ParameterRequestUpdate
} from '../models/z8-parameter.model';
import { BaseService } from './base.service';

@Injectable()
export class Z8ParameterService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.z8Parameter;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: Z8ParameterListSearchCriteria): Observable<Z8ParameterListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public submit(data: Z8ParameterRequest): Observable<any> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<any>(url, data, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public updateSubmit(data: Z8ParameterRequestUpdate): Observable<any> {
    const url = this.getUrl();
    return this.http.put<any>(url, data, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getHistoryLogs(id: string): Observable<any> {
    const url = this.getFullUrl(this.envService.history, { id });
    return this.http.get<any>(url, { headers: this.headers, observe: 'body' });
  }

  public exportZ8(criteria: Z8ExportCriteria): Observable<Array<Z8File>> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params
    });
  }
}
