<div [formGroup]="form">
  <div
    [formArrayName]="arrayName"
    *ngFor="let arr of formArray.controls; let i = index; last as LastIndex"
    [ngClass]="{ 'mb-3': !LastIndex }"
  >
    <div [formGroupName]="i">
      <app-files-upload
        id="{{ arrayName + i }}"
        formControlName="image"
        [hasError]="submitted && arr.controls.image.errors"
        [allowedTypes]="allowedTypes"
        [withMeta]="true"
        [multiple]="false"
        [controlName]="'image'"
        [allowedExt]="allowedExt"
        [descriptionTxt]="descriptionTxt"
        [fileSizeErrorTxt]="fileSizeErrorTxt"
        [fileTypeErrorTxt]="fileTypeErrorTxt"
        [size]="size"
        isAddable="true"
        [maxImages]="maxImages"
        [index]="i"
        [lastIndex]="LastIndex"
        (addImage)="addMoreImage()"
        [fileModule]="fileModule"
      ></app-files-upload>
    </div>
  </div>
</div>
