import { ErrorResponse } from '../../models';
import { All, AuthActionTypes } from '../actions/auth.actions';
import { BaseAuthState, initialAuthState } from '../state/auth.state';

export function authReducers(state = initialAuthState, action: All): BaseAuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        result: {
          isAuthenticated: true,
          response: action.payload,
          errorResponse: new ErrorResponse()
        }
      };
    }
    case AuthActionTypes.LOGIN_FAILURE: {
      return {
        ...state,
        result: {
          isAuthenticated: false,
          response: null,
          errorResponse: action.payload
        }
      };
    }
    // TODO Comment default log out for test meta reducer
    // case AuthActionTypes.LOGOUT: {
    //   return initialAuthState;
    // }
    case AuthActionTypes.LOGIN_RESET: {
      return initialAuthState;
    }
    default: {
      return state;
    }
  }
}
