<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-store"></em>
    <span>{{ data.title }}</span>
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="!ownerUsername && hasUserPermission()"
    (click)="onGenerateUser()"
  >
    <em class="icon-gen-user-pass"></em>
    Generate Owner Username/Password
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="ownerUsername && hasUserPermission()"
    (click)="onResetPassword()"
  >
    <em class="icon-gen-user-pass"></em>
    Reset Owner Password
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div>
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Merchant ID:</span>
      </div>
      <div class="info-header-value">
        <span>{{ (merchantView$ | async)?.no | dashDisplay }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="status !== 'D'; else edit">
      <div class="info-header-label">
        <span>Merchant Status:</span>
      </div>
      <div class="info-header-value">
        <span [ngClass]="getColorStatus((merchantView$ | async)?.status)" class="merchant-status">
          {{ 'MERCHANT.STATUS.' + (merchantView$ | async)?.status | translate }}
        </span>
      </div>
    </div>
    <ng-template #edit>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span [ngClass]="getColorStatus('draft')" class="merchant-status">
            {{ 'MERCHANT_REQUEST.STATUS.' + status | translate }}
          </span>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="admin-form">
    <div [formGroup]="merchantForm" (ngSubmit)="onSubmit()">
      <!-- Merchant Profile -->
      <div class="row section-header">
        <span id="PROFILE">Merchant Profile</span>
      </div>
      <app-merchant-profile
        #merchantProfile
        [parentForm]="merchantForm"
        [submitted]="submitted"
        [mode]="data.mode"
        [page]="merchantPage"
      ></app-merchant-profile>

      <hr class="section-divider" />

      <!-- Owner Profile -->
      <div class="row section-header">
        <span>Owner Profile</span>
      </div>
      <app-owner-profile
        #ownerProfile
        [parentForm]="merchantForm"
        [submitted]="submitted"
        [mode]="data.mode"
        [page]="merchantPage"
      ></app-owner-profile>

      <hr class="section-divider" />

      <!-- Legal Document -->
      <div class="row section-header">
        <span id="LEGAL_DOCUMENT">Legal Document</span>
      </div>
      <app-legal-document
        #legalDocument
        [parentForm]="merchantForm"
        [submitted]="submitted"
        [mode]="data.mode"
        [page]="merchantPage"
      ></app-legal-document>
    </div>

    <!--footer-->
    <ng-container *ngIf="hasAtLeastOnePermission()">
      <ng-container *ngIf="merchantRequestPageMode.REQUEST_EDIT === data.mode; else otherMode">
        <div class="fixed-row-bottom form-row">
          <div class="float-right" *ngIf="hasSubmitPermission()">
            <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
            <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
              Submit
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #otherMode>
        <ng-container>
          <div class="fixed-row-bottom form-row">
            <div class="float-left"></div>
            <div class="float-right">
              <span *ngIf="hasEditPermission()" class="mr-2">
                <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>
    <!--End footer-->
  </div>
</div>
