import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DeliveryOrderShipment, Shipment, ShipmentSearchCriteria } from '../../models/shipment.model';
import { ShipmentActions, ShipmentActionTypes } from '../actions/shipment.actions';

export interface ShipmentState extends EntityState<Shipment> {
  isLoading: boolean;
  saveSuccess: boolean;
  isSuccess: boolean;
  selected: { [key: string]: boolean };
  deliveryOrdersCreate: DeliveryOrderShipment[];
  shipment: Shipment;
  criteriaObject: ShipmentSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<Shipment> = createEntityAdapter<Shipment>();

export const initialShipmentState: ShipmentState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  isSuccess: false,
  selected: null,
  shipment: new Shipment(),
  deliveryOrdersCreate: [],
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function shipmentReducers(state = initialShipmentState, action: ShipmentActions): ShipmentState {
  switch (action.type) {
    case ShipmentActionTypes.SHIPMENT_GET_BY_ID_RESPONSE: {
      return {
        ...state,
        shipment: {
          ...action.payload
        }
      };
    }
    case ShipmentActionTypes.SHIPMENT_CHECK_DELIVERY_ORDER: {
      return {
        ...state,
        deliveryOrdersCreate: state.deliveryOrdersCreate.concat(action.payload),
        selected: {
          ...state.selected,
          [action.payload.id]: true
        }
      };
    }
    case ShipmentActionTypes.SHIPMENT_RESET_CHECK_DELIVERY_ORDER: {
      return {
        ...state,
        selected: null,
        deliveryOrdersCreate: []
      };
    }
    case ShipmentActionTypes.SHIPMENT_UNCHECK_DELIVERY_ORDER: {
      const { selected } = state;
      delete selected[action.payload];
      return {
        ...state,
        deliveryOrdersCreate: state.deliveryOrdersCreate.filter(item => item.id !== action.payload),
        selected: {
          ...selected
        }
      };
    }
    case ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShipmentActionTypes.SHIPMENT_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShipmentActionTypes.SHIPMENT_RESET: {
      return {
        ...state,
        shipment: null,
        selected: null,
        deliveryOrdersCreate: []
      };
    }
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
