import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GoodsReceive,
  GoodsReceiveCancelRequestData,
  GoodsReceiveExportCriteria,
  GoodsReceivePagination,
  GoodsReceiveSearchCriteria
} from '../models/goods-receive.model';
import { BaseService } from './base.service';
@Injectable()
export class GoodsReceiveService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.goodsReceive;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: GoodsReceiveSearchCriteria): Observable<GoodsReceivePagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<GoodsReceivePagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public submit(gr: GoodsReceive): Observable<GoodsReceive> {
    const url = this.getUrl(); // OR  this.getFullUrl(this.envService.submit);
    return this.http.post<GoodsReceive>(url, gr, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public getGrByPoNo(poNo: string): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.getByPoNo, {
      poNo
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public getGrById(id: string): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.getById, {
      id
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public cancel(grCancelRequest: GoodsReceiveCancelRequestData): Observable<GoodsReceive> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<GoodsReceive>(url, grCancelRequest, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  public exportGr(criteria: GoodsReceiveExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportGr);

    return this.http.post<GoodsReceive>(url, criteria, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
