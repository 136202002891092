import { createSelector } from '@ngrx/store';
import * as fromVoucherRequestState from '../reducers/voucher-request.reducers';
import { AppStates } from '../state/app.states';

const selectVoucherRequestState = (state: AppStates) => state.voucherRequest;

export const selectAllVoucherRequestList = createSelector(
  selectVoucherRequestState,
  fromVoucherRequestState.selectAll
);

export const selectVoucherRequestList = createSelector(
  selectVoucherRequestState,
  (state: fromVoucherRequestState.VoucherRequestState) => state
);

export const selectVoucherRequest = createSelector(
  selectVoucherRequestState,
  (state: fromVoucherRequestState.VoucherRequestState) => state.selected
);

export const selectVouchernRequestListCriteria = createSelector(selectVoucherRequestState, state => {
  return state.criteriaObject;
});
