import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  StoreAssortmentRequestItem,
  StoreAssortmentRequestListResponse,
  StoreAssortmentRequestListSearchCriteria,
  StoreAssortmentRequestViewResponse
} from '../models/store-assortment-request.model';
import { BaseService } from './base.service';

@Injectable()
export class StoreAssortmentRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.storeAssortmentRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public getStoreAssortmentRequestById(id: string): Observable<StoreAssortmentRequestViewResponse> {
    const url = this.getFullUrl(this.envService.view, {
      requestId: id
    });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchByCriteria(
    criteria: StoreAssortmentRequestListSearchCriteria
  ): Observable<StoreAssortmentRequestListResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public submitRequest(request: Array<StoreAssortmentRequestItem>): Observable<StoreAssortmentRequestViewResponse> {
    const url = this.getFullUrl(this.envService.submit);

    return this.http.post<any>(url, request, { headers: this.loaderHeaders() });
  }

  public validateStoreAssortment(
    articleList: Array<StoreAssortmentRequestItem>
  ): Observable<Array<StoreAssortmentRequestItem>> {
    const url = this.getFullUrl(this.envService.validateStoreAssortment);

    return this.http.post<Array<StoreAssortmentRequestItem>>(url, articleList, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public downloadTemplate(): Observable<any> {
    const url = `${this.env.serverUrl}${this.env.services.storeAssortment.downloadTemplate}`;

    return this.http.get<any>(url, { headers: this.loaderHeaders(), responseType: 'blob' as 'json' });
  }
}
