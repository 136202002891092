import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ArticlesContent, ArticlesMasterResponse, ArticlesSearchCriteria } from '../models/articles.model';
import { BaseService } from './base.service';

@Injectable()
export class ArticleService extends BaseService {
  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.article;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public headers: HttpHeaders;
  public env = environment;
  public envService: any;

  searchArticleByCriteria(criteria: ArticlesSearchCriteria): Observable<ArticlesMasterResponse> {
    const url = this.getUrl();
    const params = this.getParams(criteria, true);

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  searchArticleListByCriteria(article: string[], criteria: ArticlesSearchCriteria): Observable<ArticlesContent[]> {
    const url = this.getFullUrl(this.envService.getList);
    const params = this.getParams(criteria, true);
    return this.http.post<any>(url, article, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
