import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MasterDataService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  DropdownMasterDataActionsType,
  DropdownMasterDataLoaded,
  DropdownMasterDataRequested
} from '../actions/master-data.actions';

@Injectable()
export class MasterDataEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly masterDataService: MasterDataService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  dropdownList$: Observable<Action> = this.actions$.pipe(
    ofType<DropdownMasterDataRequested>(DropdownMasterDataActionsType.DropdownMasterDataRequested),
    tap(action => this.logger.debug('@Effect DropdownMasterDataRequested: ' + this.stringify(action.payload))),
    switchMap(action => {
      return this.masterDataService.getDropdownMasterData(action.payload).pipe(
        map(res => new DropdownMasterDataLoaded(res)),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
