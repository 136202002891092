import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { MonthlyPartnerProfitSharingStatusEnum } from '../../../shared/enum/monthly-partner-profit-sharing.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { AuditLog } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { Z8Result, Z8ResultSearchCriteria } from '../../../shared/models/z8-result.model';
import { AuthGuardService } from '../../../shared/services';
import { Z8ResultService } from '../../../shared/services/z8-result.service';
import {
  Z8ResultHistoryRequestAction,
  Z8ResultHistoryResponseAction,
  Z8ResultRequestAction
} from '../../../shared/store/actions/z8-result.actions';
import { Z8ResultState } from '../../../shared/store/reducers/z8-result.reducers';
import {
  selectAllZ8Result,
  selectZ8Result,
  selectZ8ResultHistory
} from '../../../shared/store/selectors/z8-result.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { TargetStockImportModalComponent } from '../components/target-stock-import-modal/target-stock-import-modal.component';

@Component({
  selector: 'app-z8-result',
  templateUrl: './z8-result.component.html',
  styleUrls: ['./z8-result.component.scss']
})
export class Z8ResultComponent extends BaseSearchComponent<Z8ResultSearchCriteria, Z8Result, Z8ResultState> {
  public dateFormat = environment.dateFormat;
  public dateTimeDisplay = environment.dateTimeDisplay;
  monthlyPartnerProfitSharingEnum = MonthlyPartnerProfitSharingStatusEnum;
  targetStockImportModal: BsModalRef;

  public auditLogs$: Observable<AuditLog[]>;
  private localStore: Observable<any>;

  constructor(
    protected modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly authGuardService: AuthGuardService,
    protected readonly z8ResultService: Z8ResultService
  ) {
    super(store, modalService, selectAllZ8Result, selectZ8Result);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.auditLogs$ = this.localStore.pipe(select(selectZ8ResultHistory));
  }

  doDestroy() {}

  createForm() {}

  search() {
    this.store.dispatch(new Z8ResultRequestAction(this.criteriaObject));
  }

  setRouteTab() {
    const hasZ8ResultPermission = this.authGuardService.checkPermission(
      ['^z8_m_[a-zA-Z]{2}[0-9]{1,3}$', '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );
    const hasListPagePermission = this.authGuardService.checkPermission(['stk_plan_v_sto', 'stk_plan_m_sto']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['stk_plan_m_sto']);

    this.listRoute = [];

    if (hasZ8ResultPermission) {
      this.listRoute.push({ tabName: 'Z8 Result', url: '/stock/z8-result' });
    }

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter', url: '/stock/z8-parameter-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter Request', url: '/stock/z8-parameter-request-list' });
    }
  }

  hasManagePermission(warehouse: string) {
    return this.authGuardService.checkPermission([`z8_m_${warehouse}`], true);
  }

  getColorStatus(status: string): string {
    return status && status.toLowerCase();
  }

  onExport(fileUrl: string) {
    if (!fileUrl) {
      this.showModalMessage({
        title: 'Error',
        message: 'There is something went wrong!!! Please try again later.'
      });

      return;
    }

    this.z8ResultService.exportZ8Result(fileUrl).subscribe(response => {
      const link = document.createElement('a');
      link.target = '_self';
      link.href = response.signedUrl;
      link.click();
    });
  }

  onImport(item: Z8Result) {
    this.targetStockImportModal = this.modalService.show(TargetStockImportModalComponent, {
      backdrop: 'static',
      keyboard: false,
      initialState: {
        orderDate: item.orderDate,
        warehouseCode: item.warehouse.code
      }
    });
  }

  showHistory(item: Z8Result) {
    const orderDate = moment(item.orderDate).format(environment.dateFormat);
    this.store.dispatch(new Z8ResultHistoryRequestAction(item.id));
    const initialState = {
      title: 'History',
      historyHeader: `Order Date: ${orderDate}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.Z8_RESULT,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new Z8ResultHistoryResponseAction({ auditLogs: null }));
    });
  }

  showModalMessage(data: { title: string; message: string }) {
    this.modalService.show(AlertModalComponent, {
      initialState: data
    });
  }

  goToView() {}
  onAdvanceSubmit() {}
  prepareSearchCriteriaTags() {}

  doAfterVersionAlertModal() {}
}
