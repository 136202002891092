import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { Menu } from '../models/menu.model';
import {
  grManagePermissions,
  poManagePermissions,
  poViewPermissions,
  prApprovePermissions,
  prManagePermissions,
  prViewPermissions
} from '../permissions/permissions';
import { selectAuthoritiesResult, selectUserInfoResult } from '../store/selectors/auth-user-info.selector';
import { selectClientIdResult } from '../store/selectors/client-id.selector';
import { AppStates } from '../store/state/app.states';
import { AuthUserInfoState } from '../store/state/auth-user-info.state';
import { CookiesUtil } from '../utils/cookies-util';

type AuthenticationType = Observable<boolean> | Promise<boolean> | boolean | UrlTree;

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanActivateChild {
  protected authUserInfo: AuthUserInfoState;
  private authorities: string[];

  constructor(
    public router: Router,
    private readonly store: Store<AppStates>,
    private readonly cookieService: CookieService,
    private readonly logger: NGXLogger
  ) {
    this.cookiesUtil = new CookiesUtil(cookieService);
    this.store
      .pipe(
        select(selectClientIdResult),
        tap(clientId => (this.clientId = clientId))
      )
      .subscribe();

    this.store.pipe(select(selectUserInfoResult)).subscribe(userInfo => {
      this.authUserInfo = userInfo;
    });

    this.store
      .pipe(
        select(selectAuthoritiesResult),
        filter(value => Boolean(value))
      )
      .subscribe(authorities => (this.authorities = authorities));
  }
  private clientId: ClientIdTypeEnum | null;
  private readonly cookiesUtil: CookiesUtil;
  private readonly MAXSIZE = env.cookies.maxSizePerCookies;

  public canActivate(route: ActivatedRouteSnapshot): AuthenticationType {
    return this.doAuthenticated(route.data.permissions, route.routeConfig.path);
  }

  public canActivateChild(route: ActivatedRouteSnapshot): AuthenticationType {
    return this.doAuthenticated(route.data.permissions, route.routeConfig.path);
  }

  public checkPermission(permission: Array<string> | string, isRegExPattern = false): boolean {
    const permissions: Array<string> = Array.isArray(permission) ? permission : [permission];
    let hasPermission = false;

    this.store
      .select(state => (state.userInfo && state.userInfo.authorities ? state.userInfo.authorities : null))
      .subscribe(authorities => {
        if (permissions && authorities) {
          hasPermission = isRegExPattern
            ? permissions.some(val => this.checkAuthoritiesRegex(val, authorities))
            : permissions.some(val => authorities.indexOf(val) > -1);
        }
      });

    return hasPermission;
  }

  generateMenu(config: any[], userAuthorities: string[]) {
    const menus: Menu[] = [];
    const subLinks = {};

    // Extract routing menu from routing table
    const configFiltered = config
      .map(route =>
        route.path === '' && route.children && route.children.length > 0 ? route.children.shift() : route
      )
      .filter(route => {
        if (route.children) {
          route.children = route.children.filter(child => {
            let hasPermission = true;

            if (child.data && child.data.permissions && child.data.isRegex) {
              hasPermission = child.data.permissions.some(val => this.checkAuthoritiesRegex(val, userAuthorities));
            } else if (child.data && child.data.permissions) {
              hasPermission = child.data.permissions.some(val => userAuthorities.indexOf(val) > -1);
            }

            // In case user doesn't have permission on the first tab so it will automatically redirect to the secondary routing
            if (
              child.path === 'product-list' &&
              !this.checkPermission([
                'tdassort_inv_v',
                'tdassort_asset_v',
                'tdassort_sto_v',
                'tdassort_inv_m',
                'tdassort_asset_m',
                'tdassort_sto_m',
                'ADMIN'
              ])
            ) {
              child.path = 'request-list';
            }

            if (
              child.path === 'merchant-list' &&
              !this.checkPermission(['merchant_v', 'merchant_vs', 'merchant_key', 'merchant_edit_m', 'ADMIN'])
            ) {
              child.path = 'merchant-request-list';
            }

            if (
              child.path === 'store-list' &&
              !this.checkPermission(['store_v', 'store_eq', 'merchant_edit_m', 'merchant_order_m', 'ADMIN'])
            ) {
              child.path = 'store-request-list';
            }

            if (
              child.path === 'delivery-order-list' &&
              !this.checkPermission(
                [
                  '^do_logis_m_[a-zA-Z]{2}[0-9]{1,3}$',
                  '^do_v_[a-zA-Z]{2}[0-9]{1,3}$',
                  '^do_m_[a-zA-Z]{2}[0-9]{1,3}$'
                ],
                true
              )
            ) {
              child.path = 'shipment-list';
            }

            if (
              child.path === 'purchase-request-list' &&
              !this.checkPermission([...prViewPermissions, ...prManagePermissions, ...prApprovePermissions], true)
            ) {
              child.path = 'purchase-order-list';
            }

            if (
              child.path === 'purchase-order-list' &&
              !this.checkPermission([...poViewPermissions, ...poManagePermissions, ...grManagePermissions], true)
            ) {
              child.path = 'goods-receive-list';
            }

            if (
              child.path === 'supplier-group-list' &&
              !this.checkPermission([
                'suppliergroup_inv_v',
                'suppliergroup_asset_v',
                'suppliergroup_sto_v',
                // 'suppliergroup_inv_app',
                // 'suppliergroup_asset_app',
                // 'suppliergroup_sto_app',
                'suppliergroup_inv_m',
                'suppliergroup_asset_m',
                'suppliergroup_sto_m',
                'ADMIN'
              ])
            ) {
              child.path = 'supplier-group-request-list';
            }

            if (child.path === 'order-list' && !this.checkPermission(['so_v', 'ADMIN'])) {
              child.path = 'order-request-list';
            }

            if (
              child.path === 'shelf-list' &&
              !this.checkPermission(['shelf_v', 'shelf_m', 'shelf_firstlot_m', 'ADMIN'])
            ) {
              child.path = 'shelf-request-list';
            }

            if (child.path === 'receive-order-list' && !this.checkPermission(['cr_m', 'ro_v'])) {
              child.path = 'receive-order-list';
            }

            if (child.path === 'claim-request-list' && !this.checkPermission(['cr_m', 'cr_v', 'cr_app'])) {
              child.path = 'claim-request-list';
            }

            if (
              child.path === 'z8-result' &&
              !this.checkPermission(['^z8_m_[a-zA-Z]{2}[0-9]{1,3}$', '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'], true)
            ) {
              child.path = 'z8-parameter-list';
            }

            if (child.data && child.data.subgroup) {
              subLinks[child.data.subgroup] = [...(subLinks[child.data.subgroup] || []), child.path];
            }

            return hasPermission && child.data && child.data.isShowOnMenu;
          });
        } else {
          let hasPermission = true;

          if (route && route.data && route.data.permissions) {
            hasPermission = route.data.permissions.some(val => userAuthorities.indexOf(val) > -1);
          }

          if (
            route.path === 'store-assortment/store-assortment-list' &&
            !this.checkPermission(['store_assortment_v', 'store_assortment_m', 'ADMIN'])
          ) {
            route.path = 'store-assortment/store-assortment-request-list';
          }

          return hasPermission && route.data && route.data.isShowOnMenu;
        }

        return (route.data && route.data.isShowOnMenu) || (route.children && route.children.length > 0);
      })
      .filter(route => (route.children && route.children.length > 0) || !route.children)
      .sort((a, b) => a.data.order - b.data.order);

    configFiltered.forEach(parent => {
      const menu = new Menu();
      menu.title = parent.data.title;
      menu.link = parent.path ? '/' + parent.path : '';

      if (parent.data.group) {
        menu.group = parent.data.group;
      }
      if (parent.data.icon) {
        menu.icon = parent.data.icon;
      }
      if (parent.data.badge) {
        menu.badge = parent.data.badge;
      }
      if (parent.data.active) {
        menu.active = parent.data.active;
      }

      if (parent.children) {
        menu.type = 'dropdown';
        menu.submenus = [];

        parent.children.forEach(child => {
          const subMenu = new Menu();

          subMenu.title = child.data.title ? child.data.title : '';
          subMenu.link = child.path;
          subMenu.sublinks = subLinks[child.data.subgroup];

          menu.submenus.push(subMenu);
        });
      } else {
        menu.type = 'simple';
      }

      menus.push(menu);
    });

    return menus;
  }

  public getMenuByPermission(userAuthorities: string[]): Menu[] {
    return this.generateMenu(JSON.parse(JSON.stringify(this.router.config)), userAuthorities);
  }

  private checkAuthoritiesRegex(strReg: string, authorities: string[]): boolean {
    for (const value of authorities) {
      const regex = new RegExp(strReg, 'gi');
      if (regex.exec(value) !== null) {
        return true;
      }
    }
    return false;
  }

  private doAuthenticated(permissions: string[], path: string): boolean {
    const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
    let canActivate = this.cookieService.check(accessTokenCookiesName[0]);

    if (canActivate && this.authUserInfo.forceChangePassword) {
      canActivate = path === 'force-change-password';
      if (!canActivate) {
        this.router.navigateByUrl('/force-change-password', {
          replaceUrl: true
        });
      }
    } else if (canActivate && !this.authUserInfo.forceChangePassword) {
      // authorities => {
      if (path === 'force-change-password') {
        canActivate = false;
        this.router.navigateByUrl('/dashboard/my-task');
      } else if (!permissions) {
        canActivate = true;
      } else {
        if (permissions.some(val => this.checkAuthoritiesRegex(val, this.authorities))) {
          return canActivate;
        }
        canActivate = false;
        this.router.navigateByUrl('/dashboard/my-task');
      }
    } else {
      canActivate = false;
      this.router.navigateByUrl('/');
    }
    return canActivate;
  }
}
