/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-export-button.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-bootstrap/popover";
import * as i4 from "ngx-bootstrap/component-loader";
import * as i5 from "ngx-bootstrap/positioning";
import * as i6 from "./import-export-button.component";
var styles_ImportExportButtonComponent = [i0.styles];
var RenderType_ImportExportButtonComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ImportExportButtonComponent, data: {} });
export { RenderType_ImportExportButtonComponent as RenderType_ImportExportButtonComponent };
function View_ImportExportButtonComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "btn btn-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onSubmit(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "em", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "icon-", _v.parent.context.$implicit.type, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_ImportExportButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportExportButtonComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.hidden; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ImportExportButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportExportButtonComponent_3)), i1.ɵdid(1, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ImportExportButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "import-export-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 2, "button", [["class", "btn btn-standard min-width-auto"], ["containerClass", "bottomLeft"], ["placement", "bottom"], ["type", "button"]], [[8, "disabled", 0]], null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i3.PopoverDirective, [i3.PopoverConfig, i1.ElementRef, i1.Renderer2, i1.ViewContainerRef, i4.ComponentLoaderFactory, i5.PositioningService], { popover: [0, "popover"], placement: [1, "placement"], outsideClick: [2, "outsideClick"], containerClass: [3, "containerClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "em", [["class", "icon-excel"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["imExTemplate", 2]], null, 0, null, View_ImportExportButtonComponent_2))], function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4); var currVal_2 = "bottom"; var currVal_3 = true; var currVal_4 = "bottomLeft"; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabled; _ck(_v, 1, 0, currVal_0); }); }
export function View_ImportExportButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportExportButtonComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isShowing(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ImportExportButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-import-export-button", [], null, null, null, View_ImportExportButtonComponent_0, RenderType_ImportExportButtonComponent)), i1.ɵdid(1, 114688, null, 0, i6.ImportExportButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportExportButtonComponentNgFactory = i1.ɵccf("app-import-export-button", i6.ImportExportButtonComponent, View_ImportExportButtonComponent_Host_0, { buttons: "buttons", disabled: "disabled" }, { import: "import", export: "export" }, []);
export { ImportExportButtonComponentNgFactory as ImportExportButtonComponentNgFactory };
