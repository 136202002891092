<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div class="admin-form">
  <div class="information-container">
    <div class="container-fluid px-0">
      <div class="row">
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Location Code</span>
          </div>
          <span class="info-header-value">
            {{ data.location.wmsCode ? data.location.wmsCode : data.location.code }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Location Name</span>
          </div>
          <span class="info-header-value">
            {{ data.location.name }}
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created By</span>
          </div>
          <span class="info-header-value">
            (None)
          </span>
        </div>
        <div class="col-lg col-md-4 flex-column">
          <div class="info-header-label">
            <span>Created Date</span>
          </div>
          <span class="info-header-value">
            (None)
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4" [formGroup]="adjustStockForm">
    <div class="col-md-6">
      <div class="row" *ngIf="!isViewMode">
        <div class="col-md-6 mb-3">
          <label for="adjustType">Adjust Type<span class="text-danger">*</span></label>
          <ng-select
            id="adjustType"
            bindLabel="label"
            bindValue="value"
            [searchable]="false"
            [clearable]="false"
            [items]="adjustTypeList"
            placeholder="Please select..."
            formControlName="adjustType"
            (change)="onChangeAdjustType($event)"
            class="text-left"
            [ngClass]="{
              'is-invalid': submitted && adjustStockForm.controls.adjustType.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && adjustStockForm.controls.adjustType.errors" class="invalid-feedback">
            <div *ngIf="adjustStockForm.controls.adjustType.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="refNo">Reference No.<span class="text-danger">*</span></label>
          <input
            id="refNo"
            type="text"
            placeholder="Reference No."
            class="form-control"
            formControlName="refNo"
            maxlength="30"
            [ngClass]="{
              'is-invalid': submitted && adjustStockForm.controls.refNo.errors
            }"
          />
          <div *ngIf="submitted && adjustStockForm.controls.refNo.errors" class="invalid-feedback">
            <div *ngIf="adjustStockForm.controls.refNo.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 d-flex justify-content-end align-items-end">
      <div class="row">
        <div class="col-md-12 mb-3"><span>UOM: Base Unit </span></div>
      </div>
    </div>

    <div class="col-md-12 mb-5">
      <table
        id="inventoryTable"
        class="table table-striped table-bordered w-100"
        *ngIf="formAdjustItemControls.length > 0"
      >
        <thead>
          <tr>
            <th class="text-center w-2" *ngIf="!isViewMode">{{ headerRow[0] }}</th>
            <th class="text-center w-2">{{ headerRow[1] }}</th>
            <th class="text-center w-5">{{ headerRow[2] }}</th>
            <th class="text-center w-10">{{ headerRow[3] }}</th>
            <th class="text-center w-10">{{ headerRow[4] }}<span class="text-danger">*</span></th>
            <th class="text-center w-10">{{ headerRow[5] }}<span class="text-danger">*</span></th>
          </tr>
        </thead>
        <tbody formArrayName="items">
          <tr
            *ngFor="let item of formAdjustItemControls; let i = index; first as firstIndex"
            [formGroupName]="i"
            [className]="
              item.errors?.isNotAllowFixAsset ||
              item.errors?.isNotAllowStoreUse ||
              item.errors?.invalidArticle ||
              item.errors?.isNotAllowFreshLiteItem ||
              item.errors?.isNotAllowFreshLiteExpenseItem ||
              item.errors?.duplicated
                ? 'error-background'
                : ''
            "
          >
            <td class="text-center align-top" *ngIf="!isViewMode">
              <a
                class="is-link text-muted"
                (click)="deleteItem(i)"
                title="Delete"
                appTippy
                [tippyOptions]="{ arrow: true }"
              >
                <em class="icon-delete"></em>
              </a>
            </td>
            <td class="text-center align-top">{{ i + 1 }}</td>
            <td class="text-left align-top">
              {{ item.get('articleNo').value }}
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidArticle">
                {{ 'ERRORS.INVALID_FIELD_ITEM' | translate: { value: 'article' } }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.duplicated">
                {{ 'ERRORS.DUPLICATED_FIELD_ITEM' | translate: { value: 'article' } }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isNotAllowFixAsset">
                {{ 'ERRORS.NOT_ALLOW_FIX_ASSET' | translate }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isNotAllowFreshLiteItem">
                {{ 'ERRORS.FRESH_LITE_ITEM' | translate }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isNotAllowFreshLiteExpenseItem">
                {{ 'ERRORS.FRESH_LITE_EXPENSE_ITEM' | translate }}
              </small>
              <small class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isNotAllowStoreUse">
                {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
              </small>
            </td>
            <td class="text-left align-top">
              {{ item.get('productName').value }}
            </td>

            <td class="text-center align-top">
              <app-numeric-textbox
                class="td-numeric-textbox-center"
                [ngClass]="{
                  'is-invalid': submitted && validatorFormControls('adjustStock', i),
                  'd-none': item.errors && !(item.errors?.required && item.errors?.isZero)
                }"
                formControlName="adjustStock"
                (keyup)="onChangeAdjustStock($event, i)"
                [format]="'0'"
                [minValue]="-99999"
                [maxValue]="999999"
                [negativeSign]="true"
                id="adjustStock"
                maxlength="6"
              >
              </app-numeric-textbox>
              <div
                class="invalid-display text-danger "
                *ngIf="
                  submitted &&
                  validatorFormControls('adjustStock', i) &&
                  !item.errors?.invalidArticle &&
                  !item.errors?.isNotAllowFixAsset &&
                  !item.errors?.isNotAllowStoreUse
                "
              >
                <div *ngIf="validatorFormControls('adjustStock', i)?.required">
                  <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
                </div>

                <div *ngIf="validatorFormControls('adjustStock', i)?.isZero">
                  <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
                </div>
                <div *ngIf="validatorFormControls('adjustStock', i)?.notInsufficientStock">
                  <span>{{ 'ERRORS.NOT_INSUFFICIENT_STOCK' | translate }}</span>
                </div>
              </div>
            </td>
            <td class="text-center align-top">
              <ng-select
                bindLabel="label"
                [searchable]="false"
                [clearable]="false"
                [items]="reasonList"
                placeholder="Please select..."
                formControlName="reason"
                (change)="onChangeReason($event, i)"
                class="text-left"
                [ngClass]="{
                  'is-invalid': submitted && validatorFormControls('reason', i),
                  'd-none': item.errors && !(item.errors?.required && item.errors?.isZero)
                }"
              >
              </ng-select>
              <a
                *ngIf="
                  firstIndex &&
                  !item.errors?.invalidArticle &&
                  !isViewMode &&
                  !item.errors?.isNotAllowFixAsset &&
                  !item.errors?.isNotAllowStoreUse
                "
                (click)="ApplyToAllReason(i)"
                class="btn-text-link d-block"
              >
                Apply to All
              </a>
              <div
                *ngIf="
                  submitted &&
                  validatorFormControls('reason', i) &&
                  !item.errors?.invalidArticle &&
                  !item.errors?.isNotAllowFixAsset &&
                  !item.errors?.isNotAllowStoreUse
                "
                class="invalid-feedback "
              >
                <div *ngIf="validatorFormControls('reason', i)?.required">
                  <span> {{ 'ERRORS.REQUIRED' | translate }} </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="!isViewMode">
        <a class="btn-text-link d-inline-block mr-1 " (click)="addSelectItem()">+Select Item</a> or
        <a class="btn-text-link d-inline-block ml-1 " (click)="addArticleItem()">+Add Article</a>
      </div>
    </div>
  </div>

  <!--footer-->
  <ng-container *ngIf="!isViewMode">
    <div class="fixed-row-bottom form-row">
      <div class="float-right">
        <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
        <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
  <!--End footer-->
</div>

<app-search-article-modal
  #searchArticleModal
  [location]="data.location.code"
  [productType]="getProductType()"
  [locationType]="data.locationType"
  (addItem)="onAddSelectedItem($event)"
></app-search-article-modal>
<app-search-multiple-article-modal
  #searchMultipleArticleModal
  [location]="data.location.code"
  [locationType]="data.locationType"
  [productType]="getProductType()"
  (addItem)="onAddArticleItem($event)"
>
</app-search-multiple-article-modal>
