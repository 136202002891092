import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MerchantList, MerchantListSearchCriteria, MerchantViewResponse } from '../../models';
import { MerchantActions, MerchantActionType } from '../actions/merchant.actions';
import { BaseState } from '../state/base.state';

export interface MerchantState extends EntityState<MerchantList>, BaseState {
  selected: MerchantViewResponse;
  criteriaObject: MerchantListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<MerchantList> = createEntityAdapter<MerchantList>();

export const initialMerchantResponseState: MerchantState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function merchantReducers(state = initialMerchantResponseState, action: MerchantActions): MerchantState {
  switch (action.type) {
    case MerchantActionType.MERCHANT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MerchantActionType.MERCHANT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case MerchantActionType.MERCHANT_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload.merchantView
      };
    case MerchantActionType.MERCHANT_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case MerchantActionType.MERCHANT_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
