import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../../services';
import { UsersService } from '../../services/users.service';
import { ForgotPasswordActionTypes, ForgotPasswordChangePasswordFailed, ForgotPasswordChangePasswordSuccess, ForgotPasswordOTPFailed, ForgotPasswordOTPSuccess, ForgotPasswordVerifyOTPSuccess } from '../actions/forgot-password.action';
export class ForgotPasswordEffects {
    constructor(actions$, authService, userService, router, logger) {
        this.actions$ = actions$;
        this.authService = authService;
        this.userService = userService;
        this.router = router;
        this.logger = logger;
        this.forgotPasswordCreateOTP$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_OTP_REQUEST), map((action) => action.payload), switchMap(payload => {
            return this.authService.bypassAuthenToken().pipe(map(result => result['access_token']), switchMap(accessToken => {
                return this.userService.forgotPasswordCreateOTP(payload, accessToken);
            }), map(response => {
                return new ForgotPasswordOTPSuccess({ data: response.body });
            }), catchError(err => {
                return of(new ForgotPasswordOTPFailed(err));
            }));
        }));
        this.forgotPasswordVerifyOTP$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_VERIFY_OTP_REQUEST), tap((action) => this.logger.debug('@Effect Forgot Password Verify OTP Request Action : ' + JSON.stringify(action.payload))), map((action) => action.payload), switchMap(payload => {
            return this.authService.bypassAuthenToken().pipe(map(result => result['access_token']), switchMap(accessToken => {
                return this.userService.forgotPasswordVerifyOTP(payload.userName, payload.otp, accessToken);
            }), map(response => {
                return new ForgotPasswordVerifyOTPSuccess({ data: response.body });
            }), catchError(err => {
                return of(new ForgotPasswordOTPFailed(err));
            }));
        }));
        this.forgotPasswordChangePassword$ = this.actions$.pipe(ofType(ForgotPasswordActionTypes.FORGOT_PASSWORD_CHANGE_PASSWORD_REQUEST), tap((action) => this.logger.debug('@Effect Forgot Password Change Password Request Action : ' + JSON.stringify(action.payload))), map((action) => action.payload), switchMap(payload => {
            return this.authService.bypassAuthenToken().pipe(map(result => result['access_token']), switchMap(accessToken => {
                return this.userService.forgotPassword(payload.userName, payload.newPassword, payload.otp, accessToken);
            }), map(response => {
                this.router.navigateByUrl('/');
                return new ForgotPasswordChangePasswordSuccess({ data: response.body });
            }), catchError(err => {
                return of(new ForgotPasswordChangePasswordFailed(err));
            }));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ForgotPasswordEffects.prototype, "forgotPasswordCreateOTP$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ForgotPasswordEffects.prototype, "forgotPasswordVerifyOTP$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], ForgotPasswordEffects.prototype, "forgotPasswordChangePassword$", void 0);
