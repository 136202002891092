import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HmrModule } from './hmr.module';
import { AppComponentService } from './shared/services/app-component.service';
import { AppInitService } from './shared/services/app-init.service';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG = {
    suppressScrollX: true
};
export function InitializeAppFactory(appInitService) {
    return appInitService.load();
}
const ɵ0 = AppComponentService, ɵ1 = DEFAULT_PERFECT_SCROLLBAR_CONFIG;
// TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF
export class AppModule extends HmrModule {
}
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1 };
