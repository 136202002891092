<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCancel()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>
<div class="admin-form">
  <div class="information-container">
    <div class="row merchant-info">
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Parameter Name</span>
          <span class="info-header-value">{{ parameterInfo?.name }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4" *ngIf="mode === requestPageMode.REQUEST_VIEW">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Z8 Request No.</span>
          <span class="info-header-value">{{ parameterInfo?.requestNo }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Parameter Type</span>
          <span class="info-header-value">{{ parameterInfo?.type | titlecase }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Updated By</span>
          <span class="info-header-value">{{ parameterInfo?.lastUpdatedByName }}</span>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="d-flex flex-md-column">
          <span class="info-header-label">Last Updated</span>
          <span class="info-header-value">{{
            parameterInfo?.lastUpdatedDate | amFromUtc | amLocal | dateDisplay: dateTimeDisplay
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <app-view-parameter-list
    *ngIf="mode === requestPageMode.REQUEST_VIEW"
    [guid]="data.guid"
    [parameterName]="parameterInfo?.name"
    [viewOnly]="false"
  ></app-view-parameter-list>

  <app-edit-parameter #editParameter *ngIf="mode === requestPageMode.REQUEST_EDIT"></app-edit-parameter>

  <!--footer-->
  <ng-container *ngIf="mode === requestPageMode.REQUEST_EDIT; else otherMode">
    <div class="fixed-row-bottom form-row">
      <div class="float-right">
        <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">Cancel</button>
        <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()">
          Submit
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #otherMode>
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right">
        <span *ngIf="hasEditPermission()" class="mr-2">
          <button type="button" id="btn-edit" class="btn btn-primary" (click)="onTriggerEdit()">Edit</button>
        </span>
      </div>
    </div>
  </ng-template>
  <!--End footer-->

  <!-- Progress Modal-->
  <div class="modal-progress modal fade" bsModal #modalProgress="bs-modal" [config]="{ backdrop: 'static' }">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body admin-form">
          <div class="container-fluid px-0">
            <div class="row">
              <div class="col-2">
                <em [ngClass]="getProgressIcon()"></em>
              </div>
              <div class="col-10">
                <div class="progress-text">
                  <span [innerHTML]="getProgressText()"></span>
                  <button
                    type="button"
                    class="close pull-right"
                    aria-label="Close"
                    *ngIf="progressMode === 'NEW'"
                    (click)="cancelProgressSubmit()"
                  >
                    <em class="icon-close"></em>
                  </button>
                </div>
                <div class="meter">
                  <span [ngStyle]="{ width: progressPercentage() }"><span class="progress"></span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
