import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap';
import { environment } from '../../../../../environments/environment';
import { ShelfList } from '../../../../shared/models/shelf.model';
import { ShelfRequestService } from '../../../../shared/services/shelf-request.service';
import { ShelfService } from '../../../../shared/services/shelf.service';

@Component({
  selector: 'app-export-shelf',
  templateUrl: './export-shelf.component.html',
  styleUrls: ['./export-shelf.component.scss']
})
export class ExportShelfComponent implements OnInit {
  public submitted: boolean;
  public form: FormGroup;
  public shelfList: ShelfList[];
  public errorExport: string;
  private readonly MAX_SHELF = 100;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly shelfService: ShelfService,
    private readonly shelfRequestService: ShelfRequestService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      no: [[], [Validators.required]]
    });

    this.initData();
  }

  initData() {
    this.shelfService
      .searchByCriteria({
        page: 0,
        size: 10000,
        sortBy: 'shelfName',
        sortOrder: 'asc'
      })
      .subscribe(data => {
        this.shelfList = data.content;
      });

    this.form.valueChanges.subscribe(() => {
      if (this.form.get('no').value.length <= this.MAX_SHELF) {
        this.errorExport = null;
      }
    });
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  onExport() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    const fileConfig = environment.fileName.exportShelf;
    const dateExport = moment().format(fileConfig.timeFormat);
    const selected = this.form.get('no').value;

    if (selected.length > this.MAX_SHELF) {
      this.errorExport = `Export limit exceeded (up to ${this.MAX_SHELF} shelves).`;
      return;
    }

    this.shelfRequestService.exportShelf(selected).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${fileConfig.prefix} ${dateExport}.xlsx`);
      },
      errorResponse => {
        this.errorExport = errorResponse.error.message || this.translate.instant(errorResponse.error.translateKey);
      },
      () => this.closeModal()
    );
  }
}
