import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[appDecimalPlaces]' })
export class DecimalPlacesDirective {
  @Input('appDecimalPlaces') decimalPlaces: number;

  private readonly navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  private readonly extraKey = [',', '.'];

  inputElement: HTMLElement;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.ctrlKey === true) ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.metaKey === true)
    ) {
      return;
    }

    if (this.el.nativeElement.value.lastIndexOf('.') !== -1 && this.extraKey.includes(e.key)) {
      e.preventDefault();
    }
    this.filterKeyFormat(e);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/\D/g, ''); // get a digit-only string
    event.preventDefault();
    this.filterInsertFormat(pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    const textData = event.dataTransfer.getData('text').replace(/\D/g, '');
    event.preventDefault();
    this.filterInsertFormat(textData);
  }

  filterKeyFormat(e: KeyboardEvent) {
    const newValue = `${this.el.nativeElement.value}${e.key}`;
    const newDecimalPlaces = newValue.search(/\./) === -1 ? '' : newValue.split('.')[1];

    if (
      this.el.nativeElement.value.search(/(.*)\.[0-9][0-9]/) === 0 &&
      newDecimalPlaces.length > this.decimalPlaces
    ) {
      e.preventDefault();
    }
  }

  filterInsertFormat(data: string) {
    const newValue = `${this.el.nativeElement.value}${data}`;
    const newDecimalPlaces = newValue.search(/\./) === -1 ? '' : newValue.split('.')[1];

    if (
      this.el.nativeElement.value.search(/(.*)\.[0-9][0-9]/) === 0 &&
      newDecimalPlaces.length <= this.decimalPlaces
    ) {
      document.execCommand('insertText', false, data);
    }
  }
}
