import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ErrorResponse } from '../../models';
import { ShelfCreateRequest } from '../../models/shelf-request.model';
import { ShelfRequestActions, ShelfRequestActionType } from '../actions/shelf-request.actions';
import { ShelfActions, ShelfActionType } from '../actions/shelf.actions';
import { BaseState } from '../state/base.state';

export interface ShelfCreateResponseState extends EntityState<ShelfCreateRequest>, BaseState {
  isLoading: boolean;
  isSave?: boolean | null;
  isApproveRejectSuccess?: boolean | null;
  result?: {
    response: ShelfCreateRequest | null;
    errorResponse: ErrorResponse | null;
  };
}

export const adapter: EntityAdapter<ShelfCreateRequest> = createEntityAdapter<ShelfCreateRequest>();

export const initialShelfCreateResponseState: ShelfCreateResponseState = adapter.getInitialState({
  isLoading: false,
  isSave: null,
  isApproveRejectSuccess: null
});

export function shelfCreateReducers(
  state = initialShelfCreateResponseState,
  action: ShelfRequestActions | ShelfActions
): ShelfCreateResponseState {
  switch (action.type) {
    case ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE:
    case ShelfRequestActionType.SHELF_CREATE_SUBMIT_RESPONSE:
    case ShelfActionType.SUBMIT_SHELF_FIRST_LOT_RESPONSE:
      return {
        ...state,
        isSave: ShelfRequestActionType.SHELF_CREATE_SAVE_RESPONSE === action.type,
        result: {
          response: action.payload,
          errorResponse: null
        }
      };
    case ShelfRequestActionType.SHELF_CREATE_SAVE_ERROR:
    case ShelfRequestActionType.SHELF_CREATE_SUBMIT_ERROR:
    case ShelfActionType.SUBMIT_SHELF_FIRST_LOT_ERROR:
      return {
        ...state,
        result: {
          response: null,
          errorResponse: action.payload
        }
      };
    case ShelfRequestActionType.SHELF_REQUEST_APPROVE_RESPONSE:
      return {
        ...state,
        isApproveRejectSuccess: action.payload.isSuccess
      };
    case ShelfRequestActionType.SHELF_CREATE_RESET:
      return initialShelfCreateResponseState;
    default: {
      return state;
    }
  }
}
