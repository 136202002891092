<div class="admin-form mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-store"></em>
    <h4>Store Stock Planning</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="td-card" *ngFor="let result$ of resultList$ | async; index as i">
      <div class="row">
        <div class="col-12 col-md-10 cursor-pointer" (click)="goToView(result$, false)">
          <div class="row">
            <div class="col-12 d-flex">
              <label class="chk-container">
                {{ result$.name }}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Z8 Request No.:</span>
              <span class="result-value">{{ result$.requestNo }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Parameter Type:</span>
              <span class="result-value">{{ result$.type | titlecase }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Updated By:</span>
              <span class="result-value">{{ result$.lastUpdatedByName | dashDisplay }}</span>
            </div>
            <div class="col-lg-4 col-md-6 text-break">
              <span class="result-property">Last Updated:</span>
              <span class="result-value">{{ result$.lastUpdatedDate | amFromUtc | amLocal | dateDisplay }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-2 action-box">
          <a
            href="javascript:void(0)"
            class="btn-more float-right"
            [popover]="moreTemplate"
            placement="bottom"
            containerClass="fl"
            [outsideClick]="true"
          >
            <em class="icon-more"></em>
          </a>
          <ng-template #moreTemplate>
            <button type="button" class="btn btn-link" *ngIf="hasEditPermission()" (click)="goToView(result$, true)">
              <em class="icon-edit"></em>Edit
            </button>
            <button type="button" class="btn btn-link" (click)="showHistory(result$)">
              <em class="icon-history"></em>History
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
