import { Action } from '@ngrx/store';
import { FreshLiteCategory, FreshLiteUpdateResponse } from '../../models';

export enum FreshLiteActionTypes {
  FRESH_LITE_LIST_REQUEST = '[Fresh Lite List] Get Fresh Lite List Request',
  FRESH_LITE_LIST_LOADED = '[Fresh Lite List] Fresh Lite List Loaded',
  ADD_FRESH_LITE = '[Fresh Lite List] Add One Item Fresh Lite',
  UPDATE_FRESH_LITE_REQUEST = '[Fresh Lite List] Update Fresh Lite List Request',
  UPDATE_FRESH_LITE_RESPONSE = '[Fresh Lite List] Update Fresh Lite List Response'
}

export class FreshLiteListRequest implements Action {
  readonly type = FreshLiteActionTypes.FRESH_LITE_LIST_REQUEST;
  constructor() {}
}

export class FreshLiteListLoaded implements Action {
  readonly type = FreshLiteActionTypes.FRESH_LITE_LIST_LOADED;
  constructor(public payload: FreshLiteCategory[]) {}
}

export class AddFreshLite implements Action {
  readonly type = FreshLiteActionTypes.ADD_FRESH_LITE;
  constructor(public payload: FreshLiteCategory) {}
}

export class UpdateFreshLiteRequest implements Action {
  readonly type = FreshLiteActionTypes.UPDATE_FRESH_LITE_REQUEST;
  constructor(public payload: FreshLiteCategory[]) {}
}

export class UpdateFreshLiteResponse implements Action {
  readonly type = FreshLiteActionTypes.UPDATE_FRESH_LITE_RESPONSE;
  constructor(public payload: FreshLiteUpdateResponse) {}
}

export type FreshLiteActions =
  | FreshLiteListRequest
  | FreshLiteListLoaded
  | AddFreshLite
  | UpdateFreshLiteRequest
  | UpdateFreshLiteResponse;
