import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ConditionsService } from '../../services/z8-conditions.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  Z8ConditionsActionType,
  Z8ConditionsListRequestAction,
  Z8ConditionsListResponseAction
} from '../actions/z8-conditions.actions';

@Injectable()
export class Z8ConditionsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly z8ConditionsService: Z8ConditionsService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchZ8Conditions$ = this.actions$.pipe(
    ofType<Z8ConditionsListRequestAction>(Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST}: ` +
          Z8ConditionsEffects.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ConditionsService.searchByCriteria(payload).pipe(
        map(response => {
          return new Z8ConditionsListResponseAction(response);
        }),
        catchError(error => {
          return of(new LayoutActionLoadError(error));
        })
      );
    })
  );

  private static stringify(data: any) {
    return JSON.stringify(data);
  }
}
