import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { ClaimRequestStatusEnum } from '../../../shared/enum/claim-status.enum';
import { OrderingMethodEnum } from '../../../shared/enum/ordering-method.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { PortalModule, TaskModuleUrl } from '../../../shared/models';
import { ButtonType, ImportExportButton } from '../../../shared/models/import-export-button.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  ReceiveOrderExportCriteria,
  ReceiveOrderList,
  ReceiveOrderListSearchCriteria
} from '../../../shared/models/receive-order.model';
import { AuthGuardService } from '../../../shared/services';
import { ReceiveOrderService } from '../../../shared/services/receive-order.service';
import { ReceiveOrderListRequestAction } from '../../../shared/store/actions/receive-order.actions';
import { ReceiveOrderState } from '../../../shared/store/reducers/receive-order.reducers';
import {
  selectAllReceiveOrderList,
  selectReceiveOrderList,
  selectReceiveOrderListCriteria
} from '../../../shared/store/selectors/receive-order.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  convertUtcToBkk,
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag,
  getDateFromString
} from '../../../shared/utils/date-util';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { ClaimRequestViewComponent } from '../../claim-request/claim-request-view/claim-request-view.component';
import { ReceiveOrderViewComponent } from '../receive-order-view/receive-order-view.component';

@Component({
  selector: 'app-receive-order-list',
  templateUrl: './receive-order-list.component.html',
  styleUrls: ['./receive-order-list.component.scss']
})
export class ReceiveOrderListComponent extends BaseSearchComponent<
  ReceiveOrderListSearchCriteria,
  ReceiveOrderList,
  ReceiveOrderState
> {
  @ViewChild('exportModal', { static: false }) exportModal: ModalDirective;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected receiveOrderService: ReceiveOrderService
  ) {
    super(store, modalService, selectAllReceiveOrderList, selectReceiveOrderList);
    super.subscribeForSaveSuccess();
  }

  public dateStringTag: string;
  public deliveryByStringTag: string;
  public orderTypeStringTag: string;
  public dateTag: string;
  public deliveryByTag: string;
  public orderTypeTag: string;
  public deliveryByFilter: Array<{ value: string; label: string }> = filterDropdown.orderDeliveryFilter;
  public orderTypeFilter: Array<{ value: string; label: string }> = filterDropdown.orderingMethodFilter;

  public exportForm: FormGroup;
  public exportFormInValid: boolean;
  public exportMaxDate: Date;
  public exportMinDate: Date;
  public responseExportError: string;

  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;

  public buttons: Array<ImportExportButton> = [
    {
      type: ButtonType.EXPORT,
      name: 'Export'
    }
  ];

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectReceiveOrderListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
    this.createExportForm();
  }

  setInitialValue() {
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);

    this.exportMaxDate = new Date();
    this.exportMaxDate.setDate(this.exportMaxDate.getDate() + 365);
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      createdDateTo: [null],
      createdDateFrom: [null],
      deliveryBy: [null],
      orderType: [null]
    });
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;

    if (!formValue.createdDateFrom && !formValue.createdDateTo && !formValue.deliveryBy && !formValue.orderType) {
      return;
    }

    let dateFrom = this.searchForm.value.createdDateFrom;
    let dateTo = this.searchForm.value.createdDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      createdDateFrom: dateFrom,
      createdDateTo: dateTo,
      deliveryBy: formValue.deliveryBy && formValue.deliveryBy.length > 0 ? formValue.deliveryBy.toString() : null,
      orderType: formValue.orderType && formValue.orderType.length > 0 ? formValue.orderType.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.deliveryByTag = null;
    this.deliveryByStringTag = null;
    this.orderTypeTag = null;
    this.orderTypeStringTag = null;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.dateStringTag = createdDate.dateStringTag;
    this.dateTag = createdDate.dateTag;

    if (this.criteriaObject.deliveryBy && this.criteriaObject.deliveryBy.length) {
      this.deliveryByStringTag = 'Delivery By';
      const types = this.deliveryByFilter
        .filter(data => this.criteriaObject.deliveryBy.includes(data.value))
        .map(data => data.label)
        .join(', ');
      this.deliveryByTag = `"${types}"`;
    }

    if (this.criteriaObject.orderType && this.criteriaObject.orderType.length) {
      this.orderTypeStringTag = 'Order Type';
      const types = this.orderTypeFilter
        .filter(data => this.criteriaObject.orderType.includes(data.value))
        .map(data => data.label)
        .join(', ');
      this.orderTypeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new ReceiveOrderListRequestAction(criteriaObj));
  }

  goToView(viewParams: ReceiveOrderList) {
    if (viewParams === null) {
      return;
    }

    const title = 'View Receive Order';
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: {
        childItem: new ChildItem(
          ReceiveOrderViewComponent,
          {
            receiveOrderNo: viewParams.receiveOrderNo,
            deliveryBy: viewParams.deliveryBy,
            crNo: viewParams.crNo,
            title
          },
          true
        )
      }
    });
  }

  setRouteTab() {
    const hasReceiveOrderListPagePermission = this.authGuardService.checkPermission(['ro_v', 'cr_m']);

    this.listRoute = [];
    if (hasReceiveOrderListPagePermission) {
      this.listRoute.push({
        tabName: 'Receive Order List',
        url: '/order/receive-order-list'
      });
    }
  }

  doAfterVersionAlertModal() {
    this.doSearch(this.criteriaObject);
  }

  doAfterSuccessModal() {
    this.modalService.hide(1);
  }

  clearFilterDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterDeliveryBy() {
    this.setFirstPage();
    this.searchForm.controls['deliveryBy'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      deliveryBy: null
    };
    this.search(this.criteriaObject);
  }

  clearFilterOrderType() {
    this.setFirstPage();
    this.searchForm.controls['orderType'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      orderType: null
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['deliveryBy'].reset();
    this.searchForm.controls['orderType'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null,
      deliveryBy: null,
      orderType: null
    };
    this.search(this.criteriaObject);
  }

  closeExportModal() {
    this.exportFormInValid = false;
    this.exportModal.hide();
  }

  createExportForm() {
    this.exportForm = this.fb.group({
      rcCreatedDateFrom: [],
      rcCreatedDateTo: []
    });
  }

  onExport() {
    if (this.exportFormInValid) {
      return;
    }

    const dateExport = moment().format(environment.fileName.exportReOrder.timeFormat);
    let dateFrom = this.exportForm.getRawValue().rcCreatedDateFrom;
    let dateTo = this.exportForm.getRawValue().rcCreatedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    const receiveOrderExportCriteria = new ReceiveOrderExportCriteria({
      createdDateFrom: dateFrom,
      createdDateTo: dateTo
    });

    this.receiveOrderService.export(receiveOrderExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportReOrder.prefix}${dateExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message;
      },
      () => this.closeExportModal()
    );
  }

  openExportModal() {
    this.responseExportError = null;
    this.exportForm.controls['rcCreatedDateFrom'].reset();
    this.exportForm.controls['rcCreatedDateTo'].reset();
    this.exportFormInValid = true;

    this.exportModal.show();
  }

  onChangeExportDateFrom(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.exportMinDate = new Date(value);
    } else {
      this.exportMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExportDateTo(value: Date): void {
    this.exportFormInValid = false;
    if (value && !isNaN(value.getTime())) {
      this.exportMaxDate = new Date(value);
    } else {
      this.exportMaxDate = new Date();
      this.exportMaxDate.setDate(this.exportMaxDate.getDate() + 365);
    }
  }

  createClaimRO(viewParams: ReceiveOrderList) {
    const cutOffDate = moment('2020-09-11').toDate();
    const createdDate = getDateFromString(convertUtcToBkk(viewParams.createdDate), 'YYYY-MM-DDTHH:mm:ss.SSS');

    if (viewParams.orderType !== OrderingMethodEnum.FRESH_LITE) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Allow to create claim for receive order type fresh lite only.'
        }
      });
      return;
    } else if (viewParams.crNo) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'This receive order already created claim.'
        }
      });
      return;
    } else if (createdDate && cutOffDate && createdDate < cutOffDate) {
      this.modalService.show(AlertModalComponent, {
        initialState: {
          title: 'Failed',
          message: 'Not allow to claim Receive Order created before 11 September 2020.'
        }
      });
      return;
    }

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      keyboard: false,
      initialState: ModuleUtil.InitialState(
        { receiveOrderNo: viewParams.receiveOrderNo, claimRequestStatus: ClaimRequestStatusEnum.DRAFT },
        ClaimRequestViewComponent,
        '',
        'Create Claim Request',
        TaskModuleUrl.RECEIVE_ORDER_REQUEST,
        PortalModule.CLAIM
      )
    });
  }

  hasManageClaimPermission() {
    return this.authGuardService.checkPermission(['cr_m']);
  }

  subscribeForVersionError() {}
}
