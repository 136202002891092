import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseRequestService } from '../../services/purchase-request.service';
import { StackPricingService } from '../../services/stack-pricing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import { PurchaseRequestActionTypes, PurchaseRequestDeliveryDetailResponse, PurchaseRequestListResponse, PurchaseRequestSubmitErrorResponse, PurchaseRequestViewLoaded } from '../actions/purchase-request.actions';
export class PurchaseRequestEffects {
    constructor(actions$, purchaseRequestService, stackPricingService, logger) {
        this.actions$ = actions$;
        this.purchaseRequestService = purchaseRequestService;
        this.stackPricingService = stackPricingService;
        this.logger = logger;
        this.loadPurchaseRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_VIEW_REQUESTED), tap(action => this.logger.debug('@Effect Purchase Request Get Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.getPrByPrNo(action.payload).pipe(map(result => new PurchaseRequestViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.searchClaimRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_LIST_REQUEST), tap(action => this.logger.debug(`@Effect Purchase Request List Requested:` + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.searchByCriteria(action.payload).pipe(map(response => new PurchaseRequestListResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
        // @Effect()
        // loadPurchaseCJProductListRequest$ = this.actions$.pipe(
        //   ofType<PurchaseRequestLoadProductListRequest>(
        //     PurchaseRequestActionTypes.PURCHASE_REQUEST_LOAD_PRODUCT_LIST_REQUEST
        //   ),
        //   tap(action =>
        //     this.logger.debug('@Effect Purchase Request Load Product List Requested: ' + JSON.stringify(action.payload))
        //   ),
        //   switchMap(action =>
        //     this.stackPricingService.getCJProductByOrderingCycle(action.payload).pipe(
        //       withLatestFrom(this.store.select(selectAllTdAssortmentOrder)),
        //       map(([result, tdAssortmentOrder]) => {
        //         tdAssortmentOrder.forEach(order => {
        //           const foundObjectIndex = result.content.findIndex(content => {
        //             return content.articleNo === order.articleNo;
        //           });
        //           if (result.content[foundObjectIndex]) {
        //             result.content[foundObjectIndex].quantity = order.quantity;
        //           }
        //         });
        //         return new TdAssortmentListResponse(result);
        //       }),
        //       catchError(err => of(new LayoutActionLoadError(err)))
        //     )
        //   )
        // );
        this.savePrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_SAVE), tap(action => this.logger.debug('@Effect PR Save Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.save(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been saved.'
        })), catchError(err => this.versionErrorHandling(err, '01003')))));
        this.submitPrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_SUBMIT_REQUEST), tap(action => this.logger.debug('@Effect PR Submit Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'The request has been created.'
        })), catchError(err => {
            return err && err.error && (err.error.code === '08009' || err.error.code === '08010')
                ? of(new PurchaseRequestSubmitErrorResponse(err.error))
                : err && err.error && err.error.code === '00001'
                    ? of(new LayoutActionVersionError(true))
                    : of(new LayoutActionLoadError(err));
        }))));
        this.loadDefaultDeliveryDetail = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_DELIVERY_DETAIL_REQUEST), tap(action => this.logger.debug('@Effect PR Submit Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.getDeliveryDetailByType(action.payload).pipe(map(response => new PurchaseRequestDeliveryDetailResponse(response)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.deletePrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_DELETE_REQUEST), tap(action => this.logger.debug('@Effect PR Delete Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.delete(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'PR has been deleted.'
        })), catchError(err => this.versionErrorHandling(err, '00004')))));
        this.cancelPrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_CANCEL_REQUEST), tap(action => this.logger.debug('@Effect PR Delete Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.cancel(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'PR has been cancelled.'
        })), catchError(err => {
            return this.errorHandling(err);
        }))));
        this.approvePrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_APPROVE_REQUEST), tap(action => this.logger.debug('@Effect PR Approve Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.approve(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'PR has been approved.'
        })), catchError(err => {
            return this.errorHandling(err);
        }))));
        this.rejectPrRequest$ = this.actions$.pipe(ofType(PurchaseRequestActionTypes.PURCHASE_REQUEST_REJECT_REQUEST), tap(action => this.logger.debug('@Effect PR Reject Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.purchaseRequestService.reject(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
            isSuccess: true,
            title: 'Success',
            message: 'PR has been rejected.'
        })), catchError(err => {
            return this.errorHandling(err);
        }))));
    }
    errorHandling(err) {
        return err.error && err.error.code !== '00001'
            ? of(new LayoutActionLoadError(err))
            : of(new LayoutActionLoadError(err.error));
    }
    versionErrorHandling(err, errorCode) {
        return err.error && err.error.code === errorCode
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "loadPurchaseRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "searchClaimRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "savePrRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "submitPrRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "loadDefaultDeliveryDetail", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "deletePrRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "cancelPrRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "approvePrRequest$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], PurchaseRequestEffects.prototype, "rejectPrRequest$", void 0);
