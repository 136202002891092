import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
export class DisableButtonService {
    constructor() {
        this.loaded = new Subject();
    }
    enable() {
        this.loaded.next(true);
    }
}
DisableButtonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DisableButtonService_Factory() { return new DisableButtonService(); }, token: DisableButtonService, providedIn: "root" });
