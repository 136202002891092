<nav aria-label="Page navigation" class="border-0" *ngIf="items.length > 0">
  <div class="form-group float-md-left d-none d-md-flex">
    <label for="recordPerPage" class="col-form-label">Rows per page:</label>
    <div class="col-sm-6">
      <select
        class="custom-select custom-select-sm col-sm-3"
        id="recordPerPage"
        [ngModel]="pageSize"
        #recordPerPage
        (change)="onChangeRowPerPage(recordPerPage.value)"
      >
        <ng-container *ngIf="pageSizeList; else defaultPageSize">
          <ng-container *ngFor="let pageSize of pageSizeList; index as i">
            <option [value]="pageSize" [selected]="i === 0">{{ pageSize }}</option>
          </ng-container>
        </ng-container>
        <ng-template #defaultPageSize>
          <option value="20" selected>20</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </ng-template>
      </select>
    </div>
  </div>
  <div class="float-right d-md-flex text-center">
    <div class="page-container">
      <label
        >{{ (currentPage - 1) * pageSize + 1 }} to
        {{ currentPage * pageSize > items.length ? items.length : currentPage * pageSize }}
        of {{ items.length }} Entries
      </label>
    </div>

    <div class="d-inline-block">
      <pagination
        class="justify-content-end pagination-sm text-success"
        [boundaryLinks]="true"
        [totalItems]="items.length"
        [itemsPerPage]="pageSize"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
        [maxSize]="5"
        (pageChanged)="pageChanged($event)"
        [ngModel]="currentPage"
      >
      </pagination>
    </div>
  </div>
</nav>
