import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ButtonType, ImportExportButton } from '../../models/import-export-button.model';

@Component({
  selector: 'app-import-export-button',
  templateUrl: './import-export-button.component.html',
  styleUrls: ['./import-export-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportExportButtonComponent implements OnInit {
  @Output() import: EventEmitter<string> = new EventEmitter<string>();
  @Output() export: EventEmitter<string> = new EventEmitter<string>();
  @Input() buttons: Array<ImportExportButton> = [];
  @Input() disabled: boolean;

  ngOnInit() {}

  onSubmit(button: ImportExportButton) {
    if (button.type === ButtonType.IMPORT) {
      this.import.emit(button.by);
    } else if (button.type === ButtonType.EXPORT) {
      this.export.emit(button.by);
    }
  }

  isShowing() {
    return this.buttons.filter(v => !v.hidden).length > 0;
  }
}
