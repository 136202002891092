import { Action } from '@ngrx/store';
import {
  Z8ParameterRequestListResponse,
  Z8ParameterRequestListSearchCriteria
} from '../../models/z8-parameter-request.model';

export enum Z8ParameterRequestActionType {
  Z8_PARAMETER_REQUEST_LIST_REQUEST = '[Z8ParameterRequest] Z8 ParameterRequest List Request',
  Z8_PARAMETER_REQUEST_LIST_RESPONSE = '[Z8ParameterRequest] Z8 ParameterRequest List Response'
}

export class Z8ParameterRequestListRequestAction implements Action {
  readonly type = Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_REQUEST;

  constructor(public payload: Z8ParameterRequestListSearchCriteria) {}
}

export class Z8ParameterRequestListResponseAction implements Action {
  readonly type = Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_RESPONSE;

  constructor(public payload: Z8ParameterRequestListResponse) {}
}

export type Z8ParameterRequestActions = Z8ParameterRequestListRequestAction | Z8ParameterRequestListResponseAction;
