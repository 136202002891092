import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  PurchaseOrder,
  PurchaseOrderCancelCloseRequestData,
  PurchaseOrderPagination,
  PurchaseOrderSearchCriteria
} from '../../models/purchase-order.model';

export enum PurchaseOrderActionTypes {
  PURCHASE_ORDER_VIEW_REQUESTED = '[Purchase Order View Page] Purchase Order View Request',
  PURCHASE_ORDER_VIEW_LOADED = '[Purchase Order API] Purchase Order View Loaded',
  PURCHASE_ORDER_RESET = '[Purchase Order View Page] Purchase Order Reset',
  PURCHASE_ORDER_LIST_REQUEST = '[Purchase Order List Page] Purchase Order List Request',
  PURCHASE_ORDER_LIST_RESPONSE = '[Purchase Order List API] Purchase Order List Response',
  PURCHASE_ORDER_CANCEL_REQUEST = '[Purchase Order Cancel Page] Purchase Order Cancel Request',
  PURCHASE_ORDER_CLOSE_REQUEST = '[Purchase Order Close Page] Purchase Order Close Request',
  PURCHASE_ORDER_CANCEL_ERROR_RESPONSE = '[Purchase Order Cancel API] Purchase Order Cancel Error Response'
}

export class PurchaseOrderViewRequest implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class PurchaseOrderViewLoaded implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_VIEW_LOADED;

  constructor(public payload: PurchaseOrder) {}
}

export class PurchaseOrderReset implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_RESET;

  constructor() {}
}

export class PurchaseOrderListRequest implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_REQUEST;

  constructor(public payload: PurchaseOrderSearchCriteria) {}
}

export class PurchaseOrderListResponse implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_RESPONSE;

  constructor(public payload: PurchaseOrderPagination) {}
}

export class PurchaseOrderCancelRequest implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_CANCEL_REQUEST;

  constructor(public payload: PurchaseOrderCancelCloseRequestData) {}
}

export class PurchaseOrderCloseRequest implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_CLOSE_REQUEST;

  constructor(public payload: PurchaseOrderCancelCloseRequestData) {}
}

export class PurchaseOrderCancelErrorResponse implements Action {
  readonly type = PurchaseOrderActionTypes.PURCHASE_ORDER_CANCEL_ERROR_RESPONSE;

  constructor(public payload: ErrorResponse) {}
}
export type PurchaseOrderActions =
  | PurchaseOrderViewRequest
  | PurchaseOrderViewLoaded
  | PurchaseOrderReset
  | PurchaseOrderListRequest
  | PurchaseOrderListResponse
  | PurchaseOrderCancelRequest
  | PurchaseOrderCloseRequest
  | PurchaseOrderCancelErrorResponse;
