<div class="row mt-3">
  <div class="col-md-12">
    <table
      id="fixAssetTable"
      class="table table-striped table-bordered w-100"
      [formGroup]="parentForm"
      *ngIf="form.controls.length"
    >
      <thead>
        <tr>
          <th class="text-center w-5">{{ headerRow[0] }}</th>
          <th class="text-center w-10">{{ headerRow[1] }}</th>
          <th class="text-center w-55">{{ headerRow[2] }}</th>
          <th class="text-center w-5">{{ headerRow[3] }}</th>
          <th class="text-center w-5">{{ headerRow[4] }}</th>
          <th class="text-center w-10">{{ headerRow[5] }}<span class="text-danger">*</span></th>
          <th class="text-center w-5" *ngIf="hasEditPermission">{{ headerRow[6] }}</th>
          <th class="text-center w-5" *ngIf="isShowChangesMode">{{ headerRow[7] }}</th>
        </tr>
      </thead>
      <tbody formArrayName="fixAssetItem">
        <tr
          *ngFor="let item of paging.currentPageData; index as i"
          [formGroupName]="i + (currentPage - 1) * pageSize"
          [ngClass]="{
            'error-background':
              item.errors?.invalidBarcode ||
              item.errors?.isInactive ||
              item.errors?.isStatusDelisted ||
              item.errors?.isInventoryItem ||
              item.errors?.isStoreUseItem ||
              (item.errors?.duplicated && mode !== requestPageModesEnum.REQUEST_VIEW)
          }"
        >
          <td class="text-center align-top">{{ paging.getItemNo(i) }}</td>
          <td class="text-left align-top">
            {{ item.get('barcode').value }}

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.invalidBarcode">
              {{ 'ERRORS.INVALID_BARCODE' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isInactive">
              {{ 'ERRORS.INACTIVE_BARCODE' | translate }}
            </span>
            <span
              class="d-block invalid-feedback text-danger"
              *ngIf="item.errors?.duplicated && mode !== requestPageModesEnum.REQUEST_VIEW"
            >
              {{ 'ERRORS.DUPLICATED_BARCODE_FIELD' | translate }}
            </span>
          </td>
          <td class="text-left align-top">
            {{ item.get('productName').value }}
            <span class="d-block">{{ item.get('articleNo').value }}</span>

            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStatusDelisted">
              {{ 'ERRORS.STATUS_IS_DELISTED' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isInventoryItem">
              {{ 'ERRORS.NOT_ALLOW_INVENTORY' | translate }}
            </span>
            <span class="d-block invalid-feedback text-danger" *ngIf="item.errors?.isStoreUseItem">
              {{ 'ERRORS.NOT_ALLOW_STORE_USE' | translate }}
            </span>
          </td>
          <td class="text-center align-top">
            {{ item.get('unit').value }}
          </td>
          <td class="text-center align-top">
            {{ item.get('unitFactor').value }}
          </td>
          <td class="text-center align-top">
            <app-numeric-textbox
              class="td-numeric-textbox-center"
              [ngClass]="{
                'is-invalid': submitted && validatorFormControls('quantity', i),
                'd-none':
                  item.errors &&
                  !(item.errors?.required && item.errors?.isZero) &&
                  !(item.errors?.duplicated && mode === requestPageModesEnum.REQUEST_VIEW),
                'bg-h-change': hasChanges(item.get('barcode').value, 'quantity')
              }"
              formControlName="quantity"
              [format]="'0,0.[00]'"
              [minValue]="1"
              [maxValue]="999"
              maxlength="3"
            >
            </app-numeric-textbox>
            <div
              class="invalid-display text-danger"
              *ngIf="submitted && !item.errors && validatorFormControls('quantity', i)"
            >
              <div *ngIf="validatorFormControls('quantity', i)?.required">
                <span>{{ 'ERRORS.REQUIRED' | translate }}</span>
              </div>

              <div *ngIf="validatorFormControls('quantity', i).isZero">
                <span>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</span>
              </div>
            </div>
          </td>
          <td class="text-center align-middle" *ngIf="hasEditPermission">
            <a
              id="delete-store"
              class="is-link text-muted"
              (click)="deleteItem(i)"
              title="Delete"
              appTippy
              [tippyOptions]="{ arrow: true }"
            >
              <em class="icon-delete"></em>
            </a>
          </td>
          <td class="text-center align-top" *ngIf="isShowChangesMode">
            {{ 'CHANGE_STATUS.' + changeStatus(item.get('barcode').value) | translate }}
          </td>
        </tr>
      </tbody>
    </table>

    <app-ui-pagination
      #paging
      [(currentPage)]="currentPage"
      [(pageSize)]="pageSize"
      [items]="form.controls"
      [valueChangeDetector]="form.valueChanges | async"
    >
    </app-ui-pagination>

    <div class="clearfix"></div>
    <div class="mt-2" *ngIf="isShowDeletedItemsButton">
      <span>
        Remark: <a class="btn-text-link d-inline-block mr-1 " (click)="showDeletedItems()">Deleted Items List</a>
      </span>
    </div>
  </div>
</div>

<div *ngIf="hasEditPermission">
  <a class="btn-text-link d-inline-block mr-1 " (click)="addNewItem()">+Select Item</a> or
  <a class="btn-text-link d-inline-block ml-1 " (click)="addNewBarcode()">+Add Barcode</a>
</div>
<app-search-product-modal #searchProductModal [productType]="productType" (addItem)="onAddItem($event)">
</app-search-product-modal>
<app-search-barcode-modal
  #searchBarcodeModal
  [allowProductType]="productType"
  (addItem)="onAddBarcodeItem($event)"
></app-search-barcode-modal>
