<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-marketing"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="showCancelButton((voucherView$ | async)?.status) && hasEditVoucherPermission"
    (click)="goToCancel()"
  >
    <em class="icon-forbidden"></em>
    Cancel Voucher
  </button>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="showExportButton((voucherView$ | async)?.status) && hasEditVoucherPermission"
    (click)="onExportVoucher()"
  >
    <em class="icon-download"></em>
    Download Voucher
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="voucherForm">
    <div class="info-header-container">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Voucher Code.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ data.voucherCode }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Status:</span>
        </div>
        <div class="info-header-value">
          <span *ngIf="status" [ngClass]="getColorStatus(status)" class="voucher-status">
            {{ 'PROMOTION.STATUS.' + status | translate | titlecase }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Voucher Details</span>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="campaign">Campaign<span class="text-danger">*</span></label>
              <input id="campaign" name="campaign" class="form-control" formControlName="campaign" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="effectiveDate">Effective Date<span class="text-danger">*</span></label>
              <input
                id="effectiveDate"
                formControlName="effectiveDate"
                type="text"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                readonly
              />
              <label for="effectiveDate" class="icon-calendar btn-calendar"></label>
            </div>
            <div class="col-md-6 mb-3">
              <label for="expireDate">Expire Date<span class="text-danger">*</span></label>
              <input
                id="expireDate"
                formControlName="expireDate"
                type="text"
                autocomplete="off"
                class="form-control calendar"
                bsDatepicker
                [bsConfig]="bsDateConfig"
                readonly
              />
              <label for="expireDate" class="icon-calendar btn-calendar"></label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="minimumBuyPerBill">Minimum Per Bill (THB)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [minValue]="0"
                [format]="'0,0.00'"
                [decimals]="2"
                [maxValue]="9999.99"
                ngClass="numeric-format"
                id="minimumBuyPerBill"
                formControlName="minimumBuyPerBill"
                maxlength="7"
              >
              </app-numeric-textbox>
            </div>
            <div class="col-md-6 mb-3">
              <label for="limitPerBill">Limit Per Bill<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [minValue]="0"
                [format]="'0'"
                [decimals]="0"
                [maxValue]="999"
                ngClass="numeric-format"
                id="limitPerBill"
                formControlName="limitPerBill"
                maxlength="3"
              >
              </app-numeric-textbox>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="store">Store<span class="text-danger">*</span></label>
              <input id="store" name="store" class="form-control" formControlName="store" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="voucherValue">Value (THB)<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [minValue]="1"
                [format]="'0,0.00'"
                [decimals]="2"
                [maxValue]="9999.99"
                ngClass="numeric-format"
                id="voucherValue"
                placeholder="Value (THB)"
                formControlName="voucherValue"
                maxlength="7"
              >
              </app-numeric-textbox>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="voucherAmount">No.of Voucher<span class="text-danger">*</span></label>
              <app-numeric-textbox
                [minValue]="1"
                [format]="'0,0'"
                [decimals]="0"
                ngClass="numeric-format"
                id="voucherAmount"
                placeholder="No.of Voucher"
                formControlName="voucherAmount"
                maxlength="4"
              >
              </app-numeric-textbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->
    <!--End footer-->
  </div>
</div>
