import { ForgotPasswordStepEnum } from '../../enum/forgot-password.enum';
import { ErrorResponse } from '../../models';
import { BaseState } from './base.state';

export interface BaseForgotPasswordState extends BaseState {
  result: {
    isSuccess: boolean;
    isSentOTP: boolean;
    isVerfiedOTP: boolean;
    response: any | null;
    errorResponse: ErrorResponse | null;
    phoneNo: string | null;
    refNo: string | null;
    userName: string | null;
    step: ForgotPasswordStepEnum;
  };
}

export const initialForgotPasswordState: BaseForgotPasswordState = {
  result: {
    isSuccess: false,
    isSentOTP: false,
    isVerfiedOTP: false,
    response: null,
    errorResponse: new ErrorResponse(),
    phoneNo: null,
    refNo: null,
    userName: null,
    step: ForgotPasswordStepEnum.REQUEST_OTP
  }
};
