import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  PurchaseRequest,
  PurchaseRequestApproveRequestData,
  PurchaseRequestCancelRequestData,
  PurchaseRequestDeliveryDetail,
  PurchaseRequestDeliveryDetailCriteria,
  PurchaseRequestExportCriteria,
  PurchaseRequestPagination,
  PurchaseRequestRejectRequestData,
  PurchaseRequestSearchCriteria
} from '../models/purchase-request.model';
import { BaseService } from './base.service';

@Injectable()
export class PurchaseRequestService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.purchaseRequest;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  save(pr: PurchaseRequest): Observable<PurchaseRequest> {
    const url = this.getUrl();
    if (pr.id) {
      return this.http.put<PurchaseRequest>(url, pr, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    } else {
      return this.http.post<PurchaseRequest>(url, pr, {
        headers: this.loaderHeaders(),
        observe: 'body'
      });
    }
  }

  public getPrByPrNo(id: string): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.get, {
      id
    });

    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public searchByCriteria(criteria: PurchaseRequestSearchCriteria): Observable<PurchaseRequestPagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<PurchaseRequestPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getDeliveryDetailByType(
    criteria: PurchaseRequestDeliveryDetailCriteria
  ): Observable<PurchaseRequestDeliveryDetail> {
    const params = this.getParams(criteria, true);
    const url = this.getFullUrl(this.envService.deliveryDetail);
    return this.http.get<PurchaseRequestDeliveryDetail>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public submit(pr: PurchaseRequest): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.submit);
    return this.http.post<PurchaseRequest>(url, pr, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  delete(id: string): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.deleted, {
      id
    });
    return this.http.delete<PurchaseRequest>(url, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  cancel(prCancelRequest: PurchaseRequestCancelRequestData): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.cancel);
    return this.http.post<PurchaseRequest>(url, prCancelRequest, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  approve(prApprove: PurchaseRequestApproveRequestData): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.approve);
    return this.http.post<PurchaseRequest>(url, prApprove, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  reject(prReject: PurchaseRequestRejectRequestData): Observable<PurchaseRequest> {
    const url = this.getFullUrl(this.envService.reject);
    return this.http.post<PurchaseRequest>(url, prReject, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }

  searchFreeItems(term) {
    const params = this.getParams(term, true);
    const url = this.getFullUrl(this.envService.supplierPromotions);
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public exportPr(criteria: PurchaseRequestExportCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.exportPr);
    return this.http.post<any>(url, criteria, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
