import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { environment as env } from '../../../environments/environment';
import { ClientIdTypeEnum } from '../enum/client-id.enum';
import { AuthUser } from '../models';
import { LogOutAction } from '../store/actions/auth.actions';
import { MenuRequestedAction } from '../store/actions/menu.action';
import { ClientIdAction, TenantInformationAction, UserInformationAction } from '../store/actions/user-info.action';
import { AppStates } from '../store/state/app.states';
import { CookiesUtil } from '../utils/cookies-util';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private readonly cookiesUtil: CookiesUtil;

  constructor(
    private readonly cookieService: CookieService,
    private readonly store: Store<AppStates>,
    protected readonly logger: NGXLogger
  ) {
    this.cookiesUtil = new CookiesUtil(cookieService);
  }

  public load() {
    return () =>
      new Promise(resolve => {
        try {
          const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);
          const accessToken = this.cookiesUtil.concatCookie(accessTokenCookiesName);

          if (accessToken) {
            const userInfo: AuthUser = JwtDecode(accessToken);

            this.dispatchInfo(userInfo);
            this.store.dispatch(new ClientIdAction(userInfo.client_id));
          } else {
            this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
          }

          resolve(true);
        } catch (err) {
          this.logger.error(err);
          this.store.dispatch(new LogOutAction());
          window.location.href = window.location.origin;
        }
      });
  }

  private dispatchInfo(userInfo: AuthUser) {
    userInfo.user_info.authorities = userInfo.authorities;

    this.store.dispatch(new UserInformationAction(userInfo.user_info));
    this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));
    this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
  }
}
