import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Z8ParameterRequestService } from '../../services/z8-parameter-request-service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  Z8ParameterRequestActionType,
  Z8ParameterRequestListRequestAction,
  Z8ParameterRequestListResponseAction
} from '../actions/z8-parameter-request.actions';

@Injectable()
export class Z8ParameterRequestEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly z8ParameterRequestService: Z8ParameterRequestService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchZ8ParameterRequest$ = this.actions$.pipe(
    ofType<Z8ParameterRequestListRequestAction>(Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.z8ParameterRequestService.searchByCriteria(payload).pipe(
        map(response => {
          return new Z8ParameterRequestListResponseAction(response);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
