import { createSelector } from '@ngrx/store';
import * as fromStoreListResponseState from '../reducers/store.reducers';
import { AppStates } from '../state/app.states';

const selectStoreListState = (state: AppStates) => state.store;

export const selectAllStoreList = createSelector(selectStoreListState, fromStoreListResponseState.selectAll);

export const selectStoreList = createSelector(
  selectStoreListState,
  (state: fromStoreListResponseState.StoreState) => state
);

export const selectStoreListCriteria = createSelector(selectStoreListState, state => {
  return state.criteriaObject;
});

export const selectStoreListHistory = createSelector(
  selectStoreListState,
  (state: fromStoreListResponseState.StoreState) => {
    return state.auditLogs;
  }
);

export const selectStoreById = createSelector(
  selectStoreListState,
  (state: fromStoreListResponseState.StoreState) => state.selected
);

export const selectDevices = createSelector(selectStoreListState, (state: fromStoreListResponseState.StoreState) =>
  state.selected && state.selected.devices ? state.selected.devices : []
);
