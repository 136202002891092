import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { FreshLiteService } from '../../services/fresh-lite.service';
import { FreshLiteActionTypes, FreshLiteListLoaded, UpdateFreshLiteResponse } from '../actions/fresh-lite.actions';
import { LayoutActionLoadError } from '../actions/layout.action';
export class FreshLiteEffects {
    constructor(actions$, freshLiteService, logger) {
        this.actions$ = actions$;
        this.freshLiteService = freshLiteService;
        this.logger = logger;
        this.searchFreshLite$ = this.actions$.pipe(ofType(FreshLiteActionTypes.FRESH_LITE_LIST_REQUEST), tap(() => this.logger.debug('@Effect Fresh Lite List Requested: ')), switchMap(() => this.freshLiteService.searchFreshLite().pipe(map(response => {
            response.forEach((res, index) => (res.index = index.toString()));
            return new FreshLiteListLoaded(response);
        }), catchError(error => of(new LayoutActionLoadError(error))))));
        this.updateFreshLite$ = this.actions$.pipe(ofType(FreshLiteActionTypes.UPDATE_FRESH_LITE_REQUEST), tap(action => this.logger.debug('@Effect Fresh Lite Update Requested: ' + JSON.stringify(action.payload))), switchMap(action => this.freshLiteService.addFreshLiteList(action.payload).pipe(map(response => new UpdateFreshLiteResponse(response)), catchError(error => of(new LayoutActionLoadError(error))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FreshLiteEffects.prototype, "searchFreshLite$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], FreshLiteEffects.prototype, "updateFreshLite$", void 0);
