import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({ selector: '[appAlphanumericOnly]' })
export class AlphanumericOnlyDirective {
  private readonly navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;

  @Input() additionalCharacters: RegExp = null;
  @Input() toUpperCase: boolean;

  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.ctrlKey === true) ||
      ((e.key === 'a' || e.key === 'c' || e.key === 'v' || e.key === 'x' || e.key === 'a') && e.metaKey === true)
    ) {
      return;
    }
    // Ensure that it is not alphanumeric and stop the keypress
    if (this.additionalCharacters) {
      if (!/[A-Za-z0-9]/gi.test(e.key) && !this.additionalCharacters.test(e.key)) {
        e.preventDefault();
      }
    } else {
      if (!/[A-Za-z0-9]/gi.test(e.key)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(_e: KeyboardEvent) {
    if (this.toUpperCase && this.el.nativeElement.value) {
      const selectionStart = this.el.nativeElement.selectionStart;
      const selectionEnd = this.el.nativeElement.selectionEnd;
      this.el.nativeElement.value = this.el.nativeElement.value.toUpperCase();
      this.el.nativeElement.setSelectionRange(selectionStart, selectionEnd);
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text/plain').replace(/[\W_]+/gi, '');
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/[\W_]+/gi, '');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}
