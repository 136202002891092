import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RequestAssortmentSearchCriteria } from '../../models';
import { RequestAssortmentService } from '../../services';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  AssortmentFreshLifeSearchRequested,
  ProductAssortmentLoaded,
  ProductAssortmentLoadRequested,
  RequestAssortmentActionTypes,
  RequestAssortmentApproveRejectSuccess,
  RequestAssortmentApproveRequested,
  RequestAssortmentCancelRequested,
  RequestAssortmentCheckCJDuplicateBarcodeRequest,
  RequestAssortmentCheckCJDuplicateBarcodeResponse,
  RequestAssortmentCopyRequested,
  RequestAssortmentCopySuccess,
  RequestAssortmentCreateRequested,
  RequestAssortmentDeleteRequested,
  RequestAssortmentDuplicateBarcode,
  RequestAssortmentLoaded,
  RequestAssortmentLoadRequested,
  RequestAssortmentRejectRequested,
  RequestAssortmentSearchLoaded,
  RequestAssortmentSearchRequested,
  RequestAssortmentSubmitError,
  RequestAssortmentSubmitRequested,
  RequestAssortmentUpdateRequested,
  RequestAssortmentUpdateSuccess
} from '../actions/request-assortment.actions';
import { SupplierPriceIsPriceUpdated } from '../actions/supplier-price.actions';
import { selectRequestAssortmentCriteria } from '../selectors/request-assortment.selectors';
import { AppStates } from '../state/app.states';

@Injectable()
export class RequestAssortmentEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly requestAssortmentService: RequestAssortmentService,
    private readonly store: Store<AppStates>,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentSearchRequested>(RequestAssortmentActionTypes.RequestAssortmentSearchRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Search: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.searchByCriteria(action.payload).pipe(
        map(requestAssortment => new RequestAssortmentSearchLoaded({ requestAssortment })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  deleteRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentDeleteRequested>(RequestAssortmentActionTypes.RequestAssortmentDeleteRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Delete: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.requestAssortmentService.deleteByRequestId(action.payload.requestId).pipe(
        // map(() =>  new RequestAssortmentDeleteSuccess()),
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been deleted.'
            })
        ),
        catchError(err =>
          err.error && err.error.code === '00004'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err))
        )
      )
    )
  );

  @Effect()
  cancelRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentCancelRequested>(RequestAssortmentActionTypes.RequestAssortmentCancelRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Cancel: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.cancel(action.payload).pipe(
        // map(() =>  new RequestAssortmentDeleteSuccess()),
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'The request has been cancelled.'
            })
        ),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  loadRequestAssortment$ = this.actions$.pipe(
    ofType<RequestAssortmentLoadRequested>(RequestAssortmentActionTypes.RequestAssortmentLoadRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.requestAssortmentService.getByRequestId(action.payload.requestId).pipe(
        map(requestAssortment => new RequestAssortmentLoaded({ requestAssortment })),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  saveRequestAssortment$ = this.actions$.pipe(
    ofType<RequestAssortmentUpdateRequested>(RequestAssortmentActionTypes.RequestAssortmentUpdateRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Save: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.updateByRequestId(action.payload.assortment).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => this.searchWithCriteria(criteria)),
        map(() => new RequestAssortmentUpdateSuccess({ status: true })),
        catchError(error => {
          if ((error.error && error.error.code === '05013') || error.error.code === '05015') {
            return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
          } else {
            of(new LayoutActionLoadError(error));
          }
        })
      )
    )
  );

  @Effect()
  createRequestAssortment$ = this.actions$.pipe(
    ofType<RequestAssortmentCreateRequested>(RequestAssortmentActionTypes.RequestAssortmentCreateRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Create: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.create(action.payload.assortment).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => this.searchWithCriteria(criteria)),
        map(() => new RequestAssortmentUpdateSuccess({ status: true })),
        catchError(error => {
          if (error.error.code === '05013') {
            return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
          } else {
            of(new LayoutActionLoadError(error));
          }
        })
      )
    )
  );

  @Effect()
  submitRequestAssortment$ = this.actions$.pipe(
    ofType<RequestAssortmentSubmitRequested>(RequestAssortmentActionTypes.RequestAssortmentSubmitRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Submit: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.submit(action.payload.assortment).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => this.searchWithCriteria(criteria)),
        map(() => new RequestAssortmentUpdateSuccess({ status: true })),
        catchError(error => {
          if (error.error.code === '05014') {
            return of(new RequestAssortmentDuplicateBarcode(error.error.message));
          } else if (error.error.code === '05013' || error.error.code === '05015') {
            return of(new RequestAssortmentSubmitError({ code: error.error.code, message: error.error.message }));
          }
          if (error.error.code === '00004') {
            return of(new LayoutActionVersionError(true));
          } else {
            return of(new LayoutActionLoadError(error));
          }
        })
      )
    )
  );

  @Effect()
  loadProductAssortment$ = this.actions$.pipe(
    ofType<ProductAssortmentLoadRequested>(RequestAssortmentActionTypes.ProductAssortmentLoadRequested),
    tap(action => this.logger.debug('@Effect Product Assortment Load: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.requestAssortmentService.getById(action.payload.data, action.payload.isProduct).pipe(
        switchMap(requestAssortment => [
          new ProductAssortmentLoaded({ requestAssortment }),
          new SupplierPriceIsPriceUpdated(requestAssortment.isPriceUpdated ? requestAssortment.isPriceUpdated : false)
        ]),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  approveRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentApproveRequested>(RequestAssortmentActionTypes.RequestAssortmentApproveRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Approve: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.requestAssortmentService.approve(action.payload).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => {
          this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }),
        map(() => new RequestAssortmentApproveRejectSuccess({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  rejectRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentRejectRequested>(RequestAssortmentActionTypes.RequestAssortmentRejectRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Reject: ' + JSON.stringify(action.payload))),
    mergeMap(action =>
      this.requestAssortmentService.reject(action.payload).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => {
          this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }),
        map(() => new RequestAssortmentApproveRejectSuccess({ isSuccess: true })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  copyRequestAssortments$ = this.actions$.pipe(
    ofType<RequestAssortmentCopyRequested>(RequestAssortmentActionTypes.RequestAssortmentCopyRequested),
    tap(action => this.logger.debug('@Effect Request Assortment Copy: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.requestAssortmentService.copy(action.payload.requestId).pipe(
        withLatestFrom(this.store.select(selectRequestAssortmentCriteria)),
        tap(([, criteria]) => {
          this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
        }),
        map(([response]: any) => new RequestAssortmentCopySuccess({ ...response })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  searchAssortmentFreshLife = this.actions$.pipe(
    ofType<AssortmentFreshLifeSearchRequested>(RequestAssortmentActionTypes.AssortmentFreshLifeListRequest),
    tap(() => this.logger.debug('@Effect Assortment Fresh Life Search: ')),
    switchMap(() =>
      this.requestAssortmentService.searchFreshLife().pipe(
        map(requestAssortment => new RequestAssortmentSearchLoaded({ requestAssortment })),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  checkDuplicateCJBarcode$ = this.actions$.pipe(
    ofType<RequestAssortmentCheckCJDuplicateBarcodeRequest>(
      RequestAssortmentActionTypes.RequestAssortmentCheckCJDuplicateBarcodeRequest
    ),
    tap(action =>
      this.logger.debug('@Effect Request Assortment Check Duplicate CJ Barcode: ' + JSON.stringify(action.payload))
    ),
    switchMap(action =>
      this.requestAssortmentService.checkCJDuplicateBarcode(action.payload).pipe(
        map(result => {
          return new RequestAssortmentCheckCJDuplicateBarcodeResponse({ cjBarcodeList: result });
        }),
        catchError(error => {
          if (error.error.code === '00004') {
            return of(new RequestAssortmentCheckCJDuplicateBarcodeResponse({ cjBarcodeList: [] }));
          } else {
            return of(new LayoutActionLoadError(error));
          }
        })
      )
    )
  );

  searchWithCriteria(criteria: RequestAssortmentSearchCriteria) {
    if (criteria) {
      this.store.dispatch(new RequestAssortmentSearchRequested(criteria));
    }
  }
}
