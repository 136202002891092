<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-store"></em>
    <h4>Merchant</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>
  <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap">
        <button
          type="button"
          class="btn btn-primary col-sm-12"
          *appHasPermission="['merchant_new_m']"
          (click)="goToCreate()"
        >
          <em class="icon-plus"></em>
          Create New Merchant
        </button>
      </div>
      <div class="d-md-flex flex-fill justify-content-end">
        <div class="filter-search-box flex-item form-group ml-md-2">
          <div class="search-box position-relative">
            <input
              type="text"
              formControlName="searchCriteria"
              class="searchCriteria form-control"
              placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
              required
              maxlength="50"
              (keyup.backspace)="clearLastKeyUpSearchText($event)"
            />
            <em
              class="icon-close-mini"
              [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
              (click)="clearSearchText()"
            ></em>
            <button class="btn-search" type="submit">
              <em class="icon-search"></em>
            </button>
          </div>
        </div>
        <div class="filter-dropdown flex-item form-group ml-md-2">
          <ng-select
            [items]="merchantStatusList"
            [searchable]="false"
            [clearable]="false"
            class="select-status"
            bindLabel="label"
            bindValue="value"
            formControlName="status"
            (change)="onchangeStatus($event)"
          >
          </ng-select>
        </div>
        <div class="text-nowrap form-group ml-md-2 d-none d-lg-block">
          <button
            class="btn advance-filter-button btn-advance-filter"
            [ngClass]="{ active: isShowAdvanceSearch }"
            type="button"
            (click)="isShowAdvanceSearch = !isShowAdvanceSearch"
          >
            <em class="icon-filter"></em>
            {{ 'ADVANCED_FILTERS' | translate }}
          </button>
          <div
            class="box-dropdown"
            [ngClass]="{ open: isShowAdvanceSearch }"
            (appClickOutside)="onClickedOutside($event)"
            [exclude]="['.btn-advance-filter', '.is-highlighted']"
          >
            <div class="box-dropdown-list advance-filter-dropdown">
              <div class="box-dropdown-body px-4">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-2">
                        Requested Date
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'FROM' | translate }}</label>
                        <input
                          id="startRequestDate"
                          formControlName="startRequestDate"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [maxDate]="maxDate"
                          (bsValueChange)="onChangeDateFrom($event)"
                          readonly
                        />
                        <label for="startRequestDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group">
                        <label>{{ 'TO' | translate }}</label>
                        <input
                          id="endRequestDate"
                          formControlName="endRequestDate"
                          type="text"
                          placeholder="{{ dateFormat }}"
                          autocomplete="off"
                          class="form-control calendar"
                          bsDatepicker
                          [bsConfig]="bsDateConfig"
                          [minDate]="minDate"
                          (bsValueChange)="onChangeDateTo($event)"
                          readonly
                        />
                        <label for="endRequestDate" class="icon-calendar btn-calendar"></label>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <div class="mb-2">
                          {{ 'CJ_PRODUCT.REQUEST_TYPE' | translate }}
                        </div>
                        <ng-select
                          placeholder="Please select..."
                          [items]="merchantRequestTypeList"
                          [searchable]="false"
                          [clearable]="true"
                          [multiple]="true"
                          bindLabel="label"
                          bindValue="value"
                          formControlName="type"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="advance-filter-footer text-center">
                <button class="btn btn-standard mr-2" type="button" (click)="onClickedOutside($event)">
                  Cancel
                </button>
                <button class="btn btn-primary" type="button" (click)="onAdvanceSubmit()">
                  {{ 'APPLY' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="top-search-criteria space-search-criteria" *ngIf="dateStringTag || typeStringTag">
    <div class="search-criteria" *ngIf="dateStringTag">
      <a class="remove-criteria" (click)="clearFilterDate()">
        <em class="icon-close"></em>
      </a>
      <span>{{ dateStringTag }}: {{ dateTag }}</span>
    </div>

    <div class="search-criteria" *ngIf="typeStringTag">
      <a class="remove-criteria" (click)="clearFilterType()">
        <em class="icon-close"></em>
      </a>
      <span>{{ typeStringTag }}: {{ typeTag }}</span>
    </div>

    <div class="d-inline-block">
      <a class="btn clear-btn" (click)="clearAdvanceFilter()">Clear All</a>
    </div>
  </div>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-10">Request No.</th>
            <th class="text-center w-10">Merchant ID</th>
            <th class="text-center w-30">Merchant Name</th>
            <th class="text-center w-10">Request Type</th>
            <th class="text-center w-15">Requested By</th>
            <th class="text-center w-10">Requested Date</th>
            <th class="text-center w-10">Status</th>
            <th
              class="text-center w-5"
              *appHasPermission="['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let merchantRequest of resultList$ | async; index as i">
            <td (click)="goToView(merchantRequest)">
              {{ merchantRequest.requestNo | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(merchantRequest)">
              {{ merchantRequest.merchantId | dashDisplay: '(None)' }}
            </td>
            <td (click)="goToView(merchantRequest)">
              {{ merchantRequest.merchantName | dashDisplay }}
            </td>
            <td (click)="goToView(merchantRequest)">
              {{ 'MERCHANT_REQUEST.TYPE.' + (merchantRequest.type || 'N') | translate }}
            </td>
            <td (click)="goToView(merchantRequest)">
              {{ merchantRequest.requestBy | dashDisplay }}
            </td>
            <td class="text-center" (click)="goToView(merchantRequest)">
              {{ merchantRequest.requestDate | amFromUtc | amLocal | dateDisplay }}
            </td>
            <td
              [ngClass]="getColorStatus(merchantRequest.requestStatus)"
              class="request-status"
              (click)="goToView(merchantRequest)"
            >
              {{ 'MERCHANT_REQUEST.STATUS.' + merchantRequest.requestStatus | translate }}
            </td>

            <td
              class="text-center position-relative"
              *appHasPermission="['merchant_new_m', 'merchant_edit_m', 'merchant_order_m', 'merchant_app']"
            >
              <a
                href="javascript:void(0)"
                class="btn-more mx-auto"
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
                *ngIf="
                  hasEditPermission(merchantRequest.type, merchantRequest.step, merchantRequest.requestStatus) ||
                  (merchantRequest.requestStatus === 'D' &&
                    hasDeletePermission(merchantRequest.type, merchantRequest.step, merchantRequest.requestStatus)) ||
                  hasCancelPermission(merchantRequest.requestStatus) ||
                  (merchantRequest.requestStatus !== 'D' && hasViewHistoryPermission(merchantRequest.requestStatus))
                "
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasEditPermission(merchantRequest.type, merchantRequest.step, merchantRequest.requestStatus)"
                  (click)="goToEdit(merchantRequest)"
                >
                  <em class="icon-edit"></em>Edit
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="
                    merchantRequest.requestStatus === 'D' &&
                    hasDeletePermission(merchantRequest.type, merchantRequest.step, merchantRequest.requestStatus)
                  "
                  (click)="deleteMerchantRequest(merchantRequest.id)"
                >
                  <em class="icon-delete"></em>Delete
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="hasCancelPermission(merchantRequest.requestStatus)"
                  (click)="cancelMerchantRequest(merchantRequest.step, merchantRequest.requestNo)"
                >
                  <em class="icon-forbidden"></em>Cancel Request
                </button>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="
                    merchantRequest.requestStatus !== 'D' && hasViewHistoryPermission(merchantRequest.requestStatus)
                  "
                  (click)="showHistory(merchantRequest.id, merchantRequest.requestNo)"
                >
                  <em class="icon-history"></em>History
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
