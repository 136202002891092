import { Action } from '@ngrx/store';
import { ErrorResponse } from '../../models';
import {
  CreateShipmentRequest,
  DeliveryOrderShipment,
  Shipment,
  ShipmentPagination,
  ShipmentSearchCriteria
} from '../../models/shipment.model';

export enum ShipmentActionTypes {
  SHIPMENT_GET_BY_ID_REQUEST = '[Shipment] Get Shipment Request by Id',
  SHIPMENT_GET_BY_ID_RESPONSE = '[Shipment] Get Shipment Response by Id',
  SHIPMENT_CREATE_REQUEST = '[Shipment] Create Shipment Request',
  SHIPMENT_CREATE_RESPONSE = '[Shipment] Create Shipment Response',
  SHIPMENT_DELETE_BY_SHIPMENT_NO_REQUEST = '[Shipment] Delete Shipment Request by Shipment No',
  SHIPMENT_DELETE_SUCCESS = '[Shipment] Delete Shipment Success',
  SHIPMENT_DISPATCH_BY_SHIPMENT_NO_REQUEST = '[Shipment] Dispatch Shipment Request by Shipment No',
  SHIPMENT_DISPATCH_SUCCESS = '[Shipment] Dispatch Shipment Success',
  SHIPMENT_CHECK_DELIVERY_ORDER = '[Shipment] Select Delivery Order',
  SHIPMENT_RESET_CHECK_DELIVERY_ORDER = '[Shipment] Reset Select Delivery Order',
  SHIPMENT_UNCHECK_DELIVERY_ORDER = '[Shipment] Unselect Delivery Order',
  SHIPMENT_REQUEST_LIST_REQUEST = '[Shipment] Request List Request',
  SHIPMENT_REQUEST_LIST_RESPONSE = '[Shipment]Request List Request',
  SHIPMENT_REQUEST_LIST_ERROR = '[Shipment] Request List Error',
  SHIPMENT_RESET = '[Shipment] Shipment Reset',
  SHIPMENT_CONFIRM_LOAD_REQUEST = '[Shipment] Confirm Load Request',
  SHIPMENT_CONFIRM_DISPATCH_REQUEST = '[Shipment] Confirm Dispatch Request',
  SHIPMENT_UPDATE_REQUEST = '[Shipment] Update Shipment Request',
  SHIPMENT_DELETE_REQUEST = '[Shipment] Delete Shipment Request'
}

export class ShipmentGetByIdRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_GET_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class ShipmentGetByIdResponse implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_GET_BY_ID_RESPONSE;

  constructor(public payload: Shipment) {}
}

export class ShipmentCreateRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_CREATE_REQUEST;

  constructor(public payload: { shipment: CreateShipmentRequest }) {}
}

export class ShipmentConfirmLoadRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_CONFIRM_LOAD_REQUEST;

  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentConfirmDispatchRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_CONFIRM_DISPATCH_REQUEST;

  constructor(public payload: string) {}
}

export class ShipmentUpdateRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_UPDATE_REQUEST;

  constructor(public payload: { shipment: Shipment }) {}
}

export class ShipmentDeleteRequest implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_DELETE_REQUEST;

  constructor(public payload: string) {}
}

export class ShipmentCreateResponse implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_CREATE_RESPONSE;

  constructor(public payload: Shipment) {}
}

export class ShipmentDispatchSuccess implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_DISPATCH_SUCCESS;

  constructor(public payload: boolean) {}
}

export class ShipmentSelectDeliveryOrder implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_CHECK_DELIVERY_ORDER;

  constructor(public payload: DeliveryOrderShipment) {}
}

export class ShipmentResetSelectDeliveryOrder implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_RESET_CHECK_DELIVERY_ORDER;

  constructor() {}
}

export class ShipmentUnselectDeliveryOrder implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_UNCHECK_DELIVERY_ORDER;

  constructor(public payload) {}
}

export class ShipmentListRequestAction implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_REQUEST_LIST_REQUEST;

  constructor(public payload: ShipmentSearchCriteria) {}
}

export class ShipmentListResponseAction implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_REQUEST_LIST_RESPONSE;

  constructor(public payload: ShipmentPagination) {}
}

export class ShipmentListErrorAction implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_REQUEST_LIST_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export class ShipmentReset implements Action {
  readonly type = ShipmentActionTypes.SHIPMENT_RESET;

  constructor() {}
}

export type ShipmentActions =
  | ShipmentGetByIdRequest
  | ShipmentGetByIdResponse
  | ShipmentSelectDeliveryOrder
  | ShipmentResetSelectDeliveryOrder
  | ShipmentUnselectDeliveryOrder
  | ShipmentCreateRequest
  | ShipmentCreateResponse
  | ShipmentListRequestAction
  | ShipmentListResponseAction
  | ShipmentListErrorAction
  | ShipmentReset
  | ShipmentConfirmLoadRequest
  | ShipmentConfirmDispatchRequest
  | ShipmentUpdateRequest
  | ShipmentDeleteRequest;
