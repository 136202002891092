import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as i0 from "@angular/core";
export class BaseService {
    constructor() {
        this.env = environment;
    }
    get timezone() {
        return moment().format('Z');
    }
    loaderHeaders(ms = 0) {
        return new HttpHeaders(this.envService.headers).set('x-frontend-spinner-loader', ms.toString());
    }
    /**
     * Encode an object to HttpParams
     * eg. this.getParams({key:value})
     * @param query  Object for encoding to HttpParams.
     * @param noBracket  Object for no include bracket.
     * @returns HttpParams with a query.
     */
    getParams(query, noBracket = false) {
        let params = new HttpParams({ encoder: new CustomEncoder() });
        for (const key of Object.keys(query)) {
            if (query[key]) {
                if (query[key] instanceof Array) {
                    query[key].forEach(item => {
                        params = params.append(noBracket ? `${key.toString()}` : `${key.toString()}[]`, item);
                    });
                }
                else {
                    params = params.append(key.toString(), query[key]);
                }
            }
            else if (query[key] === 0 || query[key] === false) {
                params = params.append(key.toString(), query[key]);
            }
        }
        return params;
    }
    /**
     * Returns the full url of the service.
     * eg. this.getUrl('serviceAppend',{'param1':'Param1','param2':'Param2'}
     * @param serviceUrl  serviceUrl.
     * @param urlParams  Object for replacing all matches in serviceAppend.
     * @returns Full service url
     */
    getFullUrl(serviceUrl, urlParams = null) {
        for (const urlParamsKey in urlParams) {
            if (urlParams[urlParamsKey]) {
                serviceUrl = serviceUrl.replace(new RegExp(`{${urlParamsKey}}`, 'g'), urlParams[urlParamsKey]);
            }
        }
        return this.env.serverUrl + this.envService.url + serviceUrl;
    }
    getUrl() {
        return this.env.serverUrl + this.envService.url;
    }
    getFileUrl(refId) {
        return of({ refId, signedUrl: null });
    }
}
BaseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BaseService_Factory() { return new BaseService(); }, token: BaseService, providedIn: "root" });
export class CustomEncoder {
    encodeKey(key) {
        return encodeURIComponent(key);
    }
    encodeValue(value) {
        return encodeURIComponent(value);
    }
    decodeKey(key) {
        return decodeURIComponent(key);
    }
    decodeValue(value) {
        return decodeURIComponent(value);
    }
}
