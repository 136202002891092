import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { MasterDataService } from '../../services';
import { LayoutActionLoadError } from '../actions/layout.action';
import { DropdownMasterDataActionsType, DropdownMasterDataLoaded } from '../actions/master-data.actions';
export class MasterDataEffects {
    constructor(actions$, masterDataService, logger) {
        this.actions$ = actions$;
        this.masterDataService = masterDataService;
        this.logger = logger;
        this.dropdownList$ = this.actions$.pipe(ofType(DropdownMasterDataActionsType.DropdownMasterDataRequested), tap(action => this.logger.debug('@Effect DropdownMasterDataRequested: ' + this.stringify(action.payload))), switchMap(action => {
            return this.masterDataService.getDropdownMasterData(action.payload).pipe(map(res => new DropdownMasterDataLoaded(res)), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
    stringify(data) {
        return JSON.stringify(data);
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Observable)
], MasterDataEffects.prototype, "dropdownList$", void 0);
