<div class="admin-form popover-container mt-2">
  <div class="page-title">
    <em class="page-title-icon icon-td-user"></em>
    <h4>Store Group</h4>
  </div>

  <app-tab [routeLinkTabs]="listRoute"></app-tab>
  <form id="search-form" [formGroup]="searchForm" (ngSubmit)="onSubmit()">
    <div class="flex-item d-md-flex justify-content-between align-items-center">
      <div class="form-group text-nowrap">
        <button type="button" *ngIf="hasManagePermission" class="btn btn-primary col-sm-12" (click)="gotoCreate()">
          <em class="icon-plus"></em>
          Create Store Group
        </button>
      </div>
      <div class="filter-search-box flex-item form-group ml-md-2">
        <div class="search-box position-relative">
          <input
            type="text"
            formControlName="searchCriteria"
            class="searchCriteria form-control"
            placeholder="{{ 'CJ_PRODUCT.ENTER_KEY_PLACEHOLDER' | translate }}"
            required
            maxlength="50"
            (keyup.backspace)="clearLastKeyUpSearchText($event)"
          />
          <em
            class="icon-close-mini"
            aria-hidden="true"
            [ngClass]="{ 'd-none': !searchForm.controls['searchCriteria'].value }"
            (click)="clearSearchText()"
          ></em>
          <button class="btn-search" type="submit">
            <em class="icon-search"></em>
          </button>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/not-found.svg" alt="Data not found" />
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="table-responsive">
      <table id="data-table" class="table table-striped table-bordered table-hover w-100" aria-hidden="true">
        <thead>
          <tr>
            <th class="text-center w-10">Store Group ID</th>
            <th class="text-center w-20">Store Group</th>
            <th class="text-center w-20">Merchant</th>
            <th class="text-center w-10">No. of Store</th>
            <th class="text-center w-15">Updated By</th>
            <th class="text-center w-10">Last Updated</th>
            <th class="text-center w-5">Status</th>
            <th class="text-center w-5">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of resultList$ | async; index as i">
            <td (click)="goToView(item)">
              {{ item.no }}
            </td>
            <td (click)="goToView(item)">
              <div class="fixedWidth">
                {{ defaultGroupName(item.name, item.defaultGroup) }}
              </div>
            </td>
            <td (click)="goToView(item)">
              {{ item.merchant }}
            </td>
            <td class="text-center" (click)="goToView(item)">
              <div class="fixedWidth">
                {{ item.numOfStore | numberFormatDisplay: 0 }}
              </div>
            </td>
            <td (click)="goToView(item)">
              {{ item.updatedBy }}
            </td>
            <td (click)="goToView(item)" class="text-center">
              {{ item.lastUpdated | amFromUtc | amLocal | dateDisplay: dateFormat }}
            </td>
            <td (click)="goToView(item)" [ngClass]="item.status | lowercase" class="request-status">
              {{ item.status | titlecase }}
            </td>
            <td class="text-center position-relative">
              <a
                href="javascript:void(0)"
                class="btn-more  mx-auto "
                [popover]="moreTemplate"
                placement="bottom"
                containerClass="fl"
                [outsideClick]="true"
                container=".popover-container"
                *ngIf="!item.defaultGroup && hasManagePermission"
              >
                <em class="icon-more"></em>
              </a>
              <ng-template #moreTemplate>
                <button
                  type="button"
                  class="btn btn-link"
                  *ngIf="!item.defaultGroup && hasManagePermission"
                  (click)="editStoreGroup(item)"
                >
                  <em class="icon-edit"></em>Edit
                </button>
              </ng-template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--pagination-->
    <app-search-pagination
      [currentPage]="currentPage"
      [pageSize]="pageSize"
      [listState$]="listState$"
      (changePage)="onChangePage($event)"
      (changeRowPerPage)="onChangeRowPerPage($event)"
    >
    </app-search-pagination>
  </ng-container>
</div>
