import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { selectUserInfoResult } from '../store/selectors/auth-user-info.selector';
import { BaseService } from './base.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-logger";
import * as i3 from "@ngrx/store";
export class UsersService extends BaseService {
    constructor(http, logger, store) {
        super();
        this.http = http;
        this.logger = logger;
        this.store = store;
        this.envService = this.env.services.users;
        this.headers = new HttpHeaders(this.envService.headers);
        this.store.select(selectUserInfoResult).subscribe(val => {
            if (val) {
                this.userName = val.userName;
            }
        });
    }
    uploadUserFiles(files) {
        return files.map(file => this.uploadUserFile(file));
    }
    uploadUserFile(file) {
        const url = this.getFullUrl(this.envService.uploadUserFileAppend, { userName: this.userName });
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.headers.append('Content-Type', 'multipart/form-data');
        return this.http
            .post(url, formData, {
            reportProgress: true,
            observe: 'events',
            headers: this.headers
        })
            .pipe(map(event => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    const progress = Math.round((100 * event.loaded) / event.total);
                    return { status: 'progress', message: progress };
                case HttpEventType.Response:
                    return event.body;
                default:
                    const msg = `Unhandled event: ${HttpEventType[event.type]}`;
                    this.logger.info(msg);
                    return null;
            }
        }));
    }
    resetPassword(userName) {
        const url = this.getFullUrl(this.env.services.users.resetPassword, { userName });
        return this.http.post(url, null, { headers: this.headers, observe: 'response' });
    }
    resetPinCode(userName) {
        const url = this.getFullUrl(this.env.services.users.resetPinCode, {
            userName
        });
        return this.http.post(url, null, { headers: this.headers, observe: 'response' });
    }
    forgotUsername(mobileNumber, accessToken) {
        const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
        const url = this.getFullUrl(this.env.services.users.forgotUsername, {
            mobileNumber: mobileNumber.mobileNumber
        });
        return this.http.post(url, null, { headers, observe: 'response' });
    }
    forgotPasswordCreateOTP(userName, accessToken) {
        const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
        const url = this.getFullUrl(this.envService.createOTP, { userName });
        return this.http.post(url, null, { headers, observe: 'response' });
    }
    forgotPasswordVerifyOTP(userName, otp, accessToken) {
        const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
        const url = this.getFullUrl(this.envService.verifyOTP, { userName });
        const data = { otp };
        return this.http.post(url, data, { headers, observe: 'response' });
    }
    forgotPassword(userName, newPassword, otp, accessToken) {
        const headers = this.headers.append('Authorization', `Bearer ${accessToken}`);
        const url = this.getFullUrl(this.envService.forgotPassword, { userName });
        const data = { newPassword, otp };
        return this.http.post(url, data, { headers, observe: 'response' });
    }
    searchByCriteriaRequest(criteria) {
        const url = this.getFullUrl(this.envService.list);
        const params = this.getParams(criteria);
        return this.http.get(url, {
            headers: this.headers,
            observe: 'body',
            params
        });
    }
    crateUser(data) {
        const url = this.getUrl();
        return this.http.post(url, data, { headers: this.loaderHeaders(), observe: 'body' });
    }
    updateUser(data) {
        const url = this.getUrl();
        return this.http.put(url, data, { headers: this.loaderHeaders(), observe: 'body' });
    }
    getUserByNo(userNo) {
        const url = this.getFullUrl(this.envService.get, { no: userNo });
        return this.http.get(url, { headers: this.headers, observe: 'body' });
    }
    changeStatusUserByUserName(user) {
        const url = this.getFullUrl(this.envService.activateUser, { userName: user.userName, status: `${user.status}` });
        return this.http.put(url, {}, { headers: this.headers, observe: 'response' });
    }
    changePassword(request) {
        const url = this.getFullUrl(this.envService.forceChangePassword, {
            userName: request.userName
        });
        return this.http.post(url, { newPassword: request.newPassword }, {
            headers: this.headers,
            observe: 'response'
        });
    }
}
UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.NGXLogger), i0.ɵɵinject(i3.Store)); }, token: UsersService, providedIn: "root" });
