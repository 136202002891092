import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab } from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import {
  Z8ParameterRequestList,
  Z8ParameterRequestListSearchCriteria
} from '../../../shared/models/z8-parameter-request.model';
import { AuthGuardService } from '../../../shared/services';
import { Z8ParameterRequestListRequestAction } from '../../../shared/store/actions/z8-parameter-request.actions';
import { Z8ParameterRequestState } from '../../../shared/store/reducers/z8-parameter-request.reducers';
import {
  selectAllZ8ParameterRequestList,
  selectZ8ParameterRequestList,
  selectZ8ParameterRequestListCriteria
} from '../../../shared/store/selectors/z8-parameter-request.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ViewParameterRequestComponent } from '../view-parameter-request/view-parameter-request.component';

@Component({
  selector: 'app-z8-parameter-request-list',
  templateUrl: './z8-parameter-request-list.component.html',
  styleUrls: ['./z8-parameter-request-list.component.scss']
})
export class Z8ParameterRequestListComponent extends BaseSearchComponent<
  Z8ParameterRequestListSearchCriteria,
  Z8ParameterRequestList,
  Z8ParameterRequestState
> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;

  public listRoute: Array<RouteLinkTab>;

  public minDate: Date;
  public maxDate: Date;
  public dateTag: string;
  public dateStringTag: string;
  public parameterTypeTag: string;
  public parameterTypeStringTag: string;

  public patameterType: string;

  public parameterTypeFilter = filterDropdown.z8ParameterType;
  public statusList: Array<{ value: string; label: string }> = filterDropdown.z8ParameterRequestStatusFilter;
  public dateFormat = environment.dateFormat;

  constructor(
    protected fb: FormBuilder,
    protected modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllZ8ParameterRequestList, selectZ8ParameterRequestList);
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectZ8ParameterRequestListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  doDestroy() {}

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      requestedDateFrom: [null],
      requestedDateTo: [null],
      parameterType: [null]
    });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      page: 0,
      size: 20
    };
  }

  clearFilterDate() {
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      requestedDateFrom: null,
      requestedDateTo: null
    };
    this.search(this.criteriaObject);
  }

  clearFilter(controlName) {
    this.searchForm.controls[controlName].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      [controlName]: this.searchForm.controls[controlName].value
    };
    this.search(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.searchForm.controls['parameterType'].reset();
    this.searchForm.controls['requestedDateFrom'].reset();
    this.searchForm.controls['requestedDateTo'].reset();

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      parameterType: null,
      requestedDateFrom: null,
      requestedDateTo: null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = value;
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = value;
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (!formValue.requestedDateFrom && !formValue.requestedDateTo && !formValue.parameterType) {
      return;
    }

    let dateFrom = formValue.requestedDateFrom;
    let dateTo = formValue.requestedDateTo;

    if (dateFrom && !isNaN(dateFrom.getTime())) {
      dateFrom = dateToStringCriteria(dateFrom);
    } else {
      dateFrom = null;
    }

    if (dateTo && !isNaN(dateTo.getTime())) {
      dateTo = dateToStringCriteria(dateTo, false);
    } else {
      dateTo = null;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      requestedDateFrom: dateFrom,
      requestedDateTo: dateTo,
      parameterType:
        formValue.parameterType && formValue.parameterType.length > 0 ? formValue.parameterType.toString() : null,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.dateTag = null;
    this.dateStringTag = null;
    this.parameterTypeTag = null;
    this.parameterTypeStringTag = null;

    const requestedDateFrom = dateStringToTagCriteria(this.criteriaObject.requestedDateFrom);
    const requestedDateTo = dateStringToTagCriteria(this.criteriaObject.requestedDateTo);
    const requestedDate = generateDateStringTag({
      dateName: 'Requested Date',
      dateFrom: requestedDateFrom,
      dateTo: requestedDateTo
    });

    this.dateStringTag = requestedDate.dateStringTag;
    this.dateTag = requestedDate.dateTag;

    if (this.criteriaObject.parameterType && this.criteriaObject.parameterType.length > 0) {
      this.parameterTypeStringTag = 'Parameter Type';

      const types = this.parameterTypeFilter
        .filter(data => {
          return this.criteriaObject.parameterType.indexOf(data.value) > -1;
        })
        .map(status => status.label)
        .join(', ');

      this.parameterTypeTag = `"${types}"`;
    }
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new Z8ParameterRequestListRequestAction(criteriaObj));
  }

  goToView(item: Z8ParameterRequestList) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewParameterRequestComponent,
        {
          parameterInfo: item
        },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    return;
  }

  getColorStatus(status: string): string {
    return status ? status.toLowerCase() : '';
  }

  setRouteTab() {
    const hasZ8ResultPermission = this.authGuardService.checkPermission(
      ['^z8_m_[a-zA-Z]{2}[0-9]{1,3}$', '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );
    const hasListPagePermission = this.authGuardService.checkPermission(['stk_plan_v_sto', 'stk_plan_m_sto']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['stk_plan_m_sto']);

    this.listRoute = [];

    if (hasZ8ResultPermission) {
      this.listRoute.push({ tabName: 'Z8 Result', url: '/stock/z8-result' });
    }

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter', url: '/stock/z8-parameter-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter Request', url: '/stock/z8-parameter-request-list' });
    }
  }

  doAfterVersionAlertModal() {}
}
