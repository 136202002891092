import gql from 'graphql-tag';
import { Segments } from '../services';

export class SegmentList {
  segments: Segments[];
}

export const segmentListByArticleTypeQuery = gql`
  query masterData($articleType: String) {
    segments(articleType: $articleType) {
      code
      nameEn
      families {
        code
        nameEn
        classCodes {
          code
          nameEn
          subClasses {
            code
            nameEn
          }
        }
      }
    }
  }
`;
