import { createSelector } from '@ngrx/store';
import * as fromSupplierGroupRequest from '../reducers/supplier-group-request.reducers';
import { AppStates } from '../state/app.states';

const selectSupplierGroupRequestState = (state: AppStates) => state.supplierGroupRequest;

export const selectSupplierGroupRequestList = createSelector(
  selectSupplierGroupRequestState,
  fromSupplierGroupRequest.selectAll
);

export const selectSupplierGroupState = createSelector(
  selectSupplierGroupRequestState,
  (state: fromSupplierGroupRequest.SupplierGroupRequestState) => state
);

export const selectSupplierGroupRequestErrorResponse = createSelector(selectSupplierGroupRequestState, state => {
  return state.errorResponse;
});

export const selectSupplierGroupRequest = createSelector(selectSupplierGroupRequestState, state => {
  return state.supplierGroupRequest;
});

export const selectSupplierGroupRequestExisting = createSelector(selectSupplierGroupRequestState, state => {
  return state.isExisting;
});

export const selectSupplierGroupRequestExistingForList = createSelector(selectSupplierGroupRequestState, state => {
  return state.isExistingForList;
});
