import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { NoSpaceOnlyValidation } from '../../custom-validators/no-space-only-validation';
import { ProductTypeEnum } from '../../enum/product-type.enum';
import { PromotionItemTypeEnum } from '../../enum/promotion.enum';
import { AlertModalComponent } from '../../layouts';
import { PromotionItem, PromotionTypeEnum } from '../../models';
import { BarcodeListSearchCriteria, BarcodeResponse } from '../../models/barcode.model';
import { BarcodeService } from '../../services';
import { AppStates } from '../../store/state/app.states';

@Component({
  selector: 'app-search-barcode-modal',
  templateUrl: './search-barcode-modal.component.html',
  styleUrls: ['./search-barcode-modal.component.scss']
})
export class SearchBarcodeModalComponent implements OnInit, OnDestroy {
  @ViewChild('selectBarcodeModal', { static: false }) selectBarcodeModal: ModalDirective;
  @ViewChild('barcodeInput', { static: false }) barcodeInput: ElementRef;
  @Output() addItem = new EventEmitter<PromotionItem[] | BarcodeResponse[]>(); // TODO Fixing type of any to specified type
  @Input() allowProductType?: ProductTypeEnum = ProductTypeEnum.ALL;
  @Input() promotion?: {
    promotionConRetailPrice: string;
    promotionType: PromotionTypeEnum;
    promotionItemType: PromotionItemTypeEnum;
  };

  public productType: ProductTypeEnum = ProductTypeEnum.INVENTORY;
  barcodeForm: FormGroup;
  submittedSelectBarcode: boolean;
  hasDuplicatedBarcode$: Observable<number>;
  duplicateError$: Observable<string>;
  maxLength = 200;

  constructor(
    protected readonly store: Store<AppStates>,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    protected readonly modalService: BsModalService,
    private readonly barcodeService: BarcodeService
  ) {
    this.barcodeForm = this.formBuilder.group({
      barcode: ['', [Validators.required, NoSpaceOnlyValidation]]
    });
  }

  ngOnInit() {
    this.initCheckDuplicatedBarcode();
    // this.barcodeForm.patchValue({
    //   barcode: ''
    // });
  }

  get form() {
    return this.barcodeForm.controls;
  }

  ngOnDestroy(): void {}

  closeSelectBarcodeModal() {
    this.barcodeForm.get('barcode').reset();
    this.submittedSelectBarcode = false;
    this.selectBarcodeModal.hide();
  }

  saveBarcode() {
    this.submittedSelectBarcode = true;
    if (this.barcodeForm.invalid) {
      return;
    }
    if (this.form.barcode.value) {
      const result = this.replaceEmptyString(this.form.barcode.value)
        .split(/\n/g)
        .filter(value => value !== '')
        .filter((value, index, a) => a.indexOf(value) === index);

      if (result.length > this.maxLength) {
        this.alertErrorModal();
      } else if (result.length) {
        this.loadBarcodeData(result.join('\n'));
      } else {
        this.form.barcode.setValue('');
      }
    }
  }

  initCheckDuplicatedBarcode() {
    this.hasDuplicatedBarcode$ = this.form.barcode.valueChanges.pipe(
      switchMap(barcodeValue => {
        const duplicatedAmount = this.duplicateList(barcodeValue).length;

        this.duplicateError$ = this.translate.get('ERRORS.DUPLICATED_BARCODE_MESSAGE', {
          value: duplicatedAmount
        });
        return of(duplicatedAmount);
      })
    ) as Observable<number>;
  }

  replaceEmptyString(input: string) {
    return input === null ? '' : input.replace(/^\n+|\n+$/g, '');
  }

  duplicateList(input: string) {
    const duplicatedList = [];
    this.replaceEmptyString(input)
      .split(/\n/g)
      .forEach((item, index, data) => {
        const isDuplicated = data.lastIndexOf(item) !== index;

        if (isDuplicated && !duplicatedList.includes(item)) {
          duplicatedList.push(item);
        }
      });

    return duplicatedList.filter(value => value !== '');
  }

  loadBarcodeData(barcodeString: string) {
    let searchCriteria: BarcodeListSearchCriteria;

    if (this.promotion) {
      searchCriteria = new BarcodeListSearchCriteria({ allowProductType: this.allowProductType });
    } else {
      searchCriteria = new BarcodeListSearchCriteria({
        allowRestrictItem: true,
        allowProductType: this.allowProductType,
        size: this.maxLength
      });
    }
    this.barcodeService
      .searchBarcodeByCriteria(barcodeString.split(/\n/), searchCriteria)
      .pipe(filter(data => Boolean(data) && Boolean(data.length)))
      .subscribe(response => {
        if (this.promotion) {
          const items: any[] = response.map(selectedItem => {
            return {
              barcode: selectedItem.barcode,
              articleNo: selectedItem.articleNo,
              productName: selectedItem.productName,
              unit: selectedItem.unit,
              unitFactor: selectedItem.unitFactor,
              movingAverage: selectedItem.movingAverage,
              retailPrice: selectedItem.retailPrice,
              discountCode: null,
              supplierCompensate: null,
              partnerCompensate: null,
              supplier: null,
              errorMessage: selectedItem.errorMessage
            };
          });

          this.addItem.emit(items);
        } else {
          this.addItem.emit(response);
        }

        this.closeSelectBarcodeModal();
      });
  }

  alertErrorModal() {
    this.barcodeForm.get('barcode').reset();
    this.selectBarcodeModal.hide();
    const initialState = {
      title: 'Failed',
      message: `File size limit exceeded (maximum size ${this.maxLength} records).`
    };

    this.modalService.show(AlertModalComponent, {
      initialState
    });
  }
}
