import { Action } from '@ngrx/store';

export enum FullModalActionTypes {
  UPDATE_LEAVE_FORM_DIRTY = '[Full Modal] Leave Form Dirty Updated'
}

export class FullModalActionLeaveFormUpdate implements Action {
  readonly type = FullModalActionTypes.UPDATE_LEAVE_FORM_DIRTY;
  constructor(public payload: boolean) {}
}

export type FullModalActions = FullModalActionLeaveFormUpdate;
