import { AbstractControl, ValidatorFn } from '@angular/forms';

export function isZeroValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value !== null) {
      return control.value === 0 ? { isZero: true } : null;
    }
    return null;
  };
}
