import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ClassMarkup, ClassMarkupSearchCriteria } from '../../models';
import { ClassMarkupActions, ClassMarkupActionTypes } from '../actions/class-markup.actions';

export interface ClassMarkupState extends EntityState<ClassMarkup> {
  isLoading: boolean;
  criteriaObject: ClassMarkupSearchCriteria;
  totalElements: number;
  currentClassMarkup: ClassMarkup;
}

export const adapter: EntityAdapter<ClassMarkup> = createEntityAdapter<ClassMarkup>();

export const initialClassMarkupState: ClassMarkupState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  currentClassMarkup: null
});

export function classMarkupReducers(state = initialClassMarkupState, action: ClassMarkupActions): ClassMarkupState {
  switch (action.type) {
    case ClassMarkupActionTypes.ClassMarkupSearchLoaded:
      return adapter.addAll(action.payload.classMarkup.content, {
        ...state,
        page: action.payload.classMarkup.page,
        size: action.payload.classMarkup.size,
        totalElements: action.payload.classMarkup.totalElements,
        totalPages: action.payload.classMarkup.totalPages
      });
    case ClassMarkupActionTypes.ClassMarkupUpdateSuccess:
      return adapter.updateOne(
        {
          id: action.payload.id,
          changes: action.payload.classMarkup
        },
        state
      );
    case ClassMarkupActionTypes.ClassMarkupLoaded:
      return {
        ...state,
        currentClassMarkup: action.payload.classMarkup
      };
    case ClassMarkupActionTypes.ClassMarkupReset:
      return {
        ...state,
        currentClassMarkup: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
