import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/base.environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { AuditLog, RouteLinkTab } from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import { Z8ParameterList, Z8ParameterListSearchCriteria } from '../../../shared/models/z8-parameter.model';
import { AuthGuardService } from '../../../shared/services';
import {
  Z8ParameterByIdResponseAction,
  Z8ParameterListHistoryRequestAction,
  Z8ParameterListHistoryResponseAction,
  Z8ParameterListRequestAction
} from '../../../shared/store/actions/z8-parameter.actions';
import { Z8ParameterState } from '../../../shared/store/reducers/z8-parameter.reducers';
import {
  selectAllZ8ParameterList,
  selectZ8ParameterList,
  selectZ8ParameterListCriteria,
  selectZ8ParameterListHistory
} from '../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { ViewParameterComponent } from '../view-parameter/view-parameter.component';

@Component({
  selector: 'app-z8-parameter-list',
  templateUrl: './z8-parameter-list.component.html',
  styleUrls: ['./z8-parameter-list.component.scss']
})
export class Z8ParameterListComponent extends BaseSearchComponent<
  Z8ParameterListSearchCriteria,
  Z8ParameterList,
  Z8ParameterState
> {
  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;

  public listRoute: Array<RouteLinkTab>;
  public auditLogs$: Observable<AuditLog[]>;

  public exportForm: FormGroup;
  public errorExport: string;
  public dateFormat = environment.dateFormat;

  constructor(
    protected modalService: BsModalService,
    protected readonly store: Store<AppStates>,
    protected readonly translate: TranslateService,
    protected readonly authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllZ8ParameterList, selectZ8ParameterList);
  }

  createForm() {}

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectZ8ParameterListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));

    this.auditLogs$ = this.localStore.pipe(select(selectZ8ParameterListHistory));
  }

  doDestroy() {}

  search() {
    this.store.dispatch(new Z8ParameterListRequestAction(this.criteriaObject));
  }

  goToView(item: Z8ParameterList, editing?) {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        ViewParameterComponent,
        {
          title: `View ${item.name} Parameter`,
          mode: editing ? RequestPageModesEnum.REQUEST_EDIT : RequestPageModesEnum.REQUEST_VIEW,
          guid: item.guid
        },
        false
      )
    };
    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });

    this.store.dispatch(new Z8ParameterByIdResponseAction(item));
    return;
  }

  onExport() {}

  hasEditPermission() {
    return this.authGuardService.checkPermission(['stk_plan_m_sto']);
  }

  showHistory(item: Z8ParameterList) {
    this.store.dispatch(new Z8ParameterListHistoryRequestAction(item.id));
    const initialState = {
      title: 'History',
      historyHeader: `${item.name}`,
      action: HistoryType.REQUEST,
      historyType: HistoryType.Z8_PARAMETER,
      auditLogs$: this.auditLogs$
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });

    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.store.dispatch(new Z8ParameterListHistoryResponseAction({ auditLogs: null }));
    });
  }

  onChangeCreatedDate(_event) {}

  setRouteTab() {
    const hasZ8ResultPermission = this.authGuardService.checkPermission(
      ['^z8_m_[a-zA-Z]{2}[0-9]{1,3}$', '^z8_v_[a-zA-Z]{2}[0-9]{1,3}$'],
      true
    );
    const hasListPagePermission = this.authGuardService.checkPermission(['stk_plan_v_sto', 'stk_plan_m_sto']);
    const hasRequestPagePermission = this.authGuardService.checkPermission(['stk_plan_m_sto']);

    this.listRoute = [];

    if (hasZ8ResultPermission) {
      this.listRoute.push({ tabName: 'Z8 Result', url: '/stock/z8-result' });
    }

    if (hasListPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter', url: '/stock/z8-parameter-list' });
    }

    if (hasRequestPagePermission) {
      this.listRoute.push({ tabName: 'Z8 Parameter Request', url: '/stock/z8-parameter-request-list' });
    }
  }

  doAfterVersionAlertModal() {}
  onAdvanceSubmit() {}
  prepareSearchCriteriaTags() {}
}
