<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-marketing"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button"
    *ngIf="isRequestViewMode && (voucherRequestView$ | async)?.status === 'W' && hasEditVoucherPermission"
    (click)="onCancelVoucherRequest()"
  >
    <em class="icon-forbidden"></em>
    Cancel Request
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="voucherForm">
    <div class="info-header-container">
      <div class="info-header-item" *ngIf="(voucherRequestView$ | async)?.requestNo">
        <div class="info-header-label">
          <span>Request No.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ (voucherRequestView$ | async)?.requestNo }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Request Status:</span>
        </div>
        <div class="info-header-value">
          <span
            *ngIf="pageMode.REQUEST_CREATE === data.mode; else getStatus"
            [ngClass]="getColorStatus('D')"
            class="request-status"
          >
            {{ 'PROMOTION.STATUS.D' | translate }}</span
          >
          <ng-template #getStatus>
            <span [ngClass]="getColorStatus(status)" class="request-status">
              {{ 'PROMOTION.STATUS.' + status | translate | titlecase }}</span
            >
          </ng-template>
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Voucher Details</span>
      </div>

      <div class="row">
        <div class="col-md-6 mb-3">
          <label for="campaign">Campaign<span class="text-danger">*</span></label>
          <ng-select
            id="campaign"
            class="d-block"
            formControlName="campaign"
            placeholder="Please select..."
            [items]="campaignList"
            bindLabel="name"
            bindValue="name"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.campaign.errors
            }"
          >
          </ng-select>
          <div *ngIf="submitted && voucherForm.controls.campaign.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.campaign.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="store">Store<span class="text-danger">*</span></label>
          <ng-select
            id="store"
            [items]="storeList | async"
            placeholder="Please select..."
            notFoundText="No Data"
            [virtualScroll]="true"
            formControlName="store"
            bindLabel="display"
            dropdownPosition="auto"
            [loading]="storeSearchLoading"
            [typeahead]="storeSearchInput$"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.store.errors
            }"
          >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <strong>Store Name:</strong>
                <span [ngOptionHighlight]="search" class="ml-1">
                  {{ item.name }}
                </span>
              </div>
              <small>
                <strong>Store Code:</strong><span [ngOptionHighlight]="search" class="ml-1">{{ item.code }}</span
                >{{ ' | ' }} <strong>Store ID:</strong
                ><span [ngOptionHighlight]="search" class="ml-1">{{ item.no }}</span>
              </small>
            </ng-template>
          </ng-select>
          <div *ngIf="submitted && voucherForm.controls.store.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.store.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <label for="effectiveDate">Effective Date<span class="text-danger">*</span></label>
          <input
            id="effectiveDate"
            formControlName="effectiveDate"
            type="text"
            placeholder="{{ environment.dateFormat }}"
            autocomplete="off"
            class="form-control calendar"
            bsDatepicker
            [bsConfig]="bsDateConfig"
            readonly
            [minDate]="minEffectiveDate"
            [maxDate]="maxDate"
            (bsValueChange)="onChangeEffectiveDate($event)"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.effectiveDate.errors
            }"
          />
          <label for="effectiveDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="submitted && voucherForm.controls.effectiveDate.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.effectiveDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="voucherForm.controls.effectiveDate.errors.isCurrent">
              {{ 'ERRORS.CURRENT_DATE' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="expireDate">Expire Date<span class="text-danger">*</span></label>
          <input
            id="expireDate"
            formControlName="expireDate"
            type="text"
            placeholder="{{ environment.dateFormat }}"
            autocomplete="off"
            class="form-control calendar"
            bsDatepicker
            [bsConfig]="bsDateConfig"
            readonly
            [minDate]="minDate"
            (bsValueChange)="onChangeExpireDate($event)"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.expireDate.errors
            }"
          />
          <label for="expireDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="submitted && voucherForm.controls.expireDate.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.expireDate.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="voucherValue">Value (THB)<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="1"
            [format]="'0,0.00'"
            [decimals]="2"
            [maxValue]="9999.99"
            [negativeSign]="false"
            ngClass="numeric-format"
            id="voucherValue"
            placeholder="Value (THB)"
            formControlName="voucherValue"
            maxlength="7"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.voucherValue.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && voucherForm.controls.voucherValue.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.voucherValue.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="voucherForm.controls.voucherValue.errors.isZero">
              <small>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</small>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <label for="minimumBuyPerBill">Minimum Per Bill (THB)<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="0"
            [format]="'0,0.00'"
            [decimals]="2"
            [maxValue]="9999.99"
            [negativeSign]="false"
            ngClass="numeric-format"
            id="minimumBuyPerBill"
            placeholder="Minimum Per Bill (THB)"
            formControlName="minimumBuyPerBill"
            maxlength="7"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.minimumBuyPerBill.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && voucherForm.controls.minimumBuyPerBill.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.minimumBuyPerBill.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <label for="limitPerBill">Limit Per Bill<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="0"
            [format]="'0'"
            [decimals]="0"
            [maxValue]="999"
            [negativeSign]="false"
            ngClass="numeric-format"
            id="limitPerBill"
            placeholder="Limit Per Bill"
            formControlName="limitPerBill"
            maxlength="3"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.limitPerBill.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && voucherForm.controls.limitPerBill.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.limitPerBill.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div class="col-md-6 mb-3">
          <label for="voucherAmount">No.of Voucher<span class="text-danger">*</span></label>
          <app-numeric-textbox
            [minValue]="1"
            [format]="'0,0'"
            [decimals]="0"
            [negativeSign]="false"
            ngClass="numeric-format"
            id="voucherAmount"
            placeholder="No.of Voucher"
            formControlName="voucherAmount"
            maxlength="4"
            [ngClass]="{
              'is-invalid': submitted && voucherForm.controls.voucherAmount.errors
            }"
          ></app-numeric-textbox>
          <div *ngIf="submitted && voucherForm.controls.voucherAmount.errors" class="invalid-feedback">
            <div *ngIf="voucherForm.controls.voucherAmount.errors.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="voucherForm.controls.voucherAmount.errors.isZero">
              <small>{{ 'ERRORS.NOT_ALLOW' | translate: '{ value: 0 }' }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right" *ngIf="isCreatedRequestMode && hasEditVoucherPermission">
        <button type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onExit()">
          Cancel
        </button>
        <button type="button" id="btn-submit" class="btn btn-primary" (click)="onSubmit()" appPreventDoubleClick>
          Submit
        </button>
      </div>

      <div
        class="float-right"
        *ngIf="isRequestViewMode && (voucherRequestView$ | async)?.status === 'W' && hasApproveVoucherPermission"
      >
        <button
          type="button"
          class="btn btn-special-reject mr-3"
          id="btn-reject"
          (click)="reject()"
          appPreventDoubleClick
        >
          Reject
        </button>
        <button
          type="button"
          class="btn btn-special-approve mr-3"
          id="btn-approve"
          (click)="approve()"
          appPreventDoubleClick
        >
          Approve
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>
</div>
