import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

export enum ModeEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  FORCE_VIEW = 'FORCE_VIEW'
}

@Component({
  selector: 'app-edit-in-place',
  templateUrl: './edit-in-place.component.html',
  styleUrls: ['./edit-in-place.component.scss']
})
export class EditInPlaceComponent implements OnInit, OnChanges {
  @Input() control: AbstractControl;
  @Input() viewData: string;
  @Input() mode? = ModeEnum.VIEW;

  @Output() modeChange: EventEmitter<ModeEnum> = new EventEmitter<ModeEnum>();

  private previousValue: any;
  private actionOwner = false;
  public localMode: ModeEnum;

  constructor() {}

  ngOnInit() {
    this.localMode = this.mode;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['mode']) {
      if (!this.actionOwner) {
        if (changes['mode'].currentValue === ModeEnum.EDIT) {
          this.previousValue = this.control.value;
        }
        if (changes['mode'].currentValue === ModeEnum.FORCE_VIEW) {
          this.localMode = ModeEnum.VIEW;
        }
      } else {
        this.actionOwner = false;
      }
    }
  }

  doOK() {
    this.doToggleMode();
  }

  doCancel() {
    this.control.setValue(this.previousValue);
    this.doToggleMode();
  }

  doToggleMode() {
    if (this.localMode === ModeEnum.VIEW) {
      this.modeChange.emit(ModeEnum.FORCE_VIEW);
      this.actionOwner = true;
    }
    if (this.localMode === ModeEnum.EDIT || this.localMode === ModeEnum.FORCE_VIEW) {
      this.localMode = ModeEnum.VIEW;
    } else {
      this.localMode = ModeEnum.EDIT;
    }

    if (this.localMode === ModeEnum.EDIT) {
      this.previousValue = this.control.value;
    }
  }

  get ModeEnum() {
    return ModeEnum;
  }
}
