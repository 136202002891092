import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { HistoryComponent } from '../../../shared/components/history/history.component';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ConfirmWithMessageModalComponent } from '../../../shared/layouts/modals/confirm-with-message-modal/confirm-with-message-modal.component';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  ErrorResponse,
  RouteLinkTab,
  VoucherListContent,
  VoucherListSearchCriteria,
  VoucherRequestPageModes
} from '../../../shared/models';
import { HistoryType } from '../../../shared/models/audit-log.model';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { VoucherService } from '../../../shared/services/voucher.service';
import { VoucherCancelAction, VoucherListRequestAction } from '../../../shared/store/actions/voucher.actions';
import { VoucherState } from '../../../shared/store/reducers/voucher.reducers';
import {
  selectAllVoucherList,
  selectVoucherList,
  selectVoucherListCriteria
} from '../../../shared/store/selectors/voucher.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  formatDate,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { VoucherViewComponent } from '../voucher-view/voucher-view.component';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss']
})
export class VoucherListComponent extends BaseSearchComponent<
  VoucherListSearchCriteria,
  VoucherListContent,
  VoucherState
> {
  private localStore: Observable<any>;
  listRoute: Array<RouteLinkTab>;
  private bsModalRef: BsModalRef;

  public minDate: Date;
  public maxDate: Date;
  public expireMaxDate: Date;
  public expireMinDate: Date;
  public requestMaxDate: Date;
  public requestMinDate: Date;

  public campaignTag: string;
  public campaignStringTag: string;
  public createdDateTag: string;
  public createdDateStringTag: string;
  public effectiveDateTag: string;
  public effectiveDateStringTag: string;
  public expireDateTag: string;
  public expireDateStringTag: string;
  public statusList: any[];
  public campaignList: any[];
  public dateFormat = environment.dateFormat;
  public hasViewVoucherPermission = false;
  public hasEditVoucherPermission = false;
  public hasApproveVoucherPermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    private readonly router: Router,
    protected readonly translate: TranslateService,
    private readonly voucherService: VoucherService,
    private readonly spinner: NgxSpinnerService
  ) {
    super(store, modalService, selectAllVoucherList, selectVoucherList);
    super.subscribeForSaveSuccess();
    this.campaignList = filterDropdown.campaignList;
    this.statusList = filterDropdown.voucherStatusFilter;
    this.hasViewVoucherPermission = this.authGuardService.checkPermission(['voucher_v']);
    this.hasEditVoucherPermission = this.authGuardService.checkPermission(['voucher_m']);
    this.hasApproveVoucherPermission = this.authGuardService.checkPermission(['voucher_app']);
    if (this.hasApproveVoucherPermission && !this.hasEditVoucherPermission) {
      this.router.navigateByUrl('campaign/voucher-request-list');
    }
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectVoucherListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  showHistory(request) {
    const initialState = {
      title: 'History',
      action: HistoryType.REQUEST,
      historyHeader: `Voucher Code: ${request.voucherCode}`,
      historyType: HistoryType.VOUCHER,
      auditLogs: request.auditLogs
    };
    this.modalService.show(HistoryComponent, {
      initialState
    });
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  setInitialValue() {
    this.pageSize = 20;
    const todayDate = new Date();

    this.maxDate = todayDate;
    this.expireMaxDate = todayDate;
    this.requestMaxDate = todayDate;

    this.maxDate.setDate(todayDate.getDate() + 365);
    this.expireMaxDate.setDate(todayDate.getDate() + 365);
    this.requestMaxDate.setDate(todayDate.getDate() + 365);
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new VoucherListRequestAction(criteriaObj));
  }

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    if (
      !formValue.effectiveDateFrom &&
      !formValue.effectiveDateTo &&
      !formValue.expireDateFrom &&
      !formValue.expireDateTo &&
      !formValue.createdDateFrom &&
      !formValue.createdDateTo &&
      !formValue.campaign
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
    this.setFirstPage();

    this.criteriaObject = {
      ...this.criteriaObject,
      campaign: [formValue.campaign.join(',')],
      effectiveDateFrom: formatDate(formValue.effectiveDateFrom, this.dateFormat),
      effectiveDateTo: formatDate(formValue.effectiveDateTo, this.dateFormat),
      expireDateFrom: formatDate(formValue.expireDateFrom, this.dateFormat),
      expireDateTo: formatDate(formValue.expireDateTo, this.dateFormat),
      createdDateFrom: dateToStringCriteria(formValue.createdDateFrom),
      createdDateTo: dateToStringCriteria(formValue.createdDateTo, false),
      page: 0
    };

    this.doSearch(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.effectiveDateTag = null;
    this.effectiveDateStringTag = null;
    this.expireDateTag = null;
    this.expireDateStringTag = null;
    this.createdDateTag = null;
    this.createdDateStringTag = null;
    this.campaignTag = null;
    this.campaignStringTag = null;

    const effectiveDateFrom = dateStringToTagCriteria(this.criteriaObject.effectiveDateFrom);
    const effectiveDateTo = dateStringToTagCriteria(this.criteriaObject.effectiveDateTo);
    const effectiveDate = generateDateStringTag({
      dateName: 'Effective Date',
      dateFrom: effectiveDateFrom,
      dateTo: effectiveDateTo
    });

    this.effectiveDateStringTag = effectiveDate.dateStringTag;
    this.effectiveDateTag = effectiveDate.dateTag;

    const expireDateFrom = dateStringToTagCriteria(this.criteriaObject.expireDateFrom);
    const expireDateTo = dateStringToTagCriteria(this.criteriaObject.expireDateTo);
    const expireDate = generateDateStringTag({
      dateName: 'Expire Date',
      dateFrom: expireDateFrom,
      dateTo: expireDateTo
    });

    this.expireDateStringTag = expireDate.dateStringTag;
    this.expireDateTag = expireDate.dateTag;

    const createdDateFrom = dateStringToTagCriteria(this.criteriaObject.createdDateFrom);
    const createdDateTo = dateStringToTagCriteria(this.criteriaObject.createdDateTo);
    const createdDate = generateDateStringTag({
      dateName: 'Created Date',
      dateFrom: createdDateFrom,
      dateTo: createdDateTo
    });

    this.createdDateStringTag = createdDate.dateStringTag;
    this.createdDateTag = createdDate.dateTag;

    if (this.criteriaObject.campaign && this.criteriaObject.campaign.length > 0) {
      this.campaignStringTag = this.translate.instant('VOUCHER.CAMPAIGN');
      const requests = this.criteriaObject.campaign.join(',');
      this.campaignTag = `"${requests}"`;
    }
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      status: [this.statusList[0].value],
      campaign: [],
      effectiveDateFrom: [null],
      effectiveDateTo: [null],
      expireDateFrom: [null],
      expireDateTo: [null],
      createdDateFrom: [null],
      createdDateTo: [null]
    });
  }

  onChangeExpireDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.expireMinDate = new Date(value);
    } else {
      this.expireMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeExpireDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.expireMaxDate = new Date(value);
    } else {
      this.expireMaxDate = new Date();
      this.expireMaxDate.setDate(this.expireMaxDate.getDate() + 365);
    }
  }

  onChangeCreatedDateFrom(value: Date) {
    if (value && !isNaN(value.getTime())) {
      this.requestMinDate = new Date(value);
    } else {
      this.requestMinDate = new Date(2019, 0, 1);
    }
  }

  onChangeCreatedDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.requestMaxDate = new Date(value);
    } else {
      this.requestMaxDate = new Date();
      this.requestMaxDate.setDate(this.requestMaxDate.getDate() + 365);
    }
  }

  onChangeStatus(event: any) {
    this.currentPage = 1;
    this.criteriaObject = {
      ...this.criteriaObject,
      status: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  clearFilterCampaign() {
    this.setFirstPage();
    this.searchForm.controls['campaign'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      campaign: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterEffectiveDate() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterExpireDate() {
    this.setFirstPage();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      expireDateFrom: null,
      expireDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearFilterCreatedDate() {
    this.setFirstPage();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      createdDateFrom: null,
      createdDateTo: null
    };
    this.doSearch(this.criteriaObject);
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['effectiveDateFrom'].reset();
    this.searchForm.controls['effectiveDateTo'].reset();
    this.searchForm.controls['expireDateFrom'].reset();
    this.searchForm.controls['expireDateTo'].reset();
    this.searchForm.controls['createdDateFrom'].reset();
    this.searchForm.controls['createdDateTo'].reset();
    this.searchForm.controls['campaign'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      effectiveDateFrom: null,
      effectiveDateTo: null,
      expireDateFrom: null,
      expireDateTo: null,
      createdDateFrom: null,
      createdDateTo: null,
      campaign: null
    };
    this.doSearch(this.criteriaObject);
  }

  setRouteTab(): void {
    this.listRoute = [];
    this.listRoute.push({ tabName: 'Voucher List', url: '/campaign/voucher-list' });
    if (this.hasEditVoucherPermission) {
      this.listRoute.push({ tabName: 'Voucher Request', url: '/campaign/voucher-request-list' });
    }
  }

  showExportButton(status: string): boolean {
    return status.toLocaleLowerCase() !== 'cancelled';
  }

  showCancelButton(status: string): boolean {
    return status.toLocaleLowerCase() === 'awaiting_schedule';
  }

  onExportVoucher(item: any) {
    this.spinner.show();

    const date = new Date();
    const dateExpoert = moment(date).format(environment.fileName.exportVoucher.timeFormat);

    this.voucherService.exportVoucher(item.voucherCode).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${item.voucherCode}_${dateExpoert}.xlsx`);
      },
      error => {
        this.spinner.hide();
        this.alertErrorModal(error.error);
      },
      () => this.spinner.hide()
    );
  }

  goToCancel(item: any) {
    if (item.status.toLocaleLowerCase() !== 'awaiting_schedule') {
      return;
    }

    const confirmModalRef = this.modalService.show(ConfirmWithMessageModalComponent, {
      initialState: {
        title: 'Confirm',
        message: `Are you sure you want to cancel Voucher Code <strong>&quot;${item.voucherCode}&quot;</strong>?`,
        label: 'Reason',
        isRequiredConfirmMessage: true,
        okText: 'Yes, cancel'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.store.dispatch(
            new VoucherCancelAction({
              id: item.voucherCode,
              comment: confirmModalRef.content.confirmMessage
            })
          );
        }
      });
  }

  goToView(viewParams: VoucherListContent) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      title: null,
      childItem: new ChildItem(
        VoucherViewComponent,
        {
          title: 'View Voucher',
          mode: VoucherRequestPageModes.VIEW,
          voucherCode: viewParams.voucherCode
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  getColorStatus(status: string): string {
    return status ? status.toLocaleLowerCase() : '';
  }

  alertErrorModal(errorResponse: ErrorResponse) {
    if (errorResponse.translateKey) {
      const initialState = {
        title: 'Failed',
        message: errorResponse.message
      };

      this.modalService.show(AlertModalComponent, {
        initialState
      });
    }
  }

  doDestroy() {}
}
