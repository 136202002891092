import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  AuditLog,
  StoreAssortmentList,
  StoreAssortmentListSearchCriteria,
  StoreAssortmentViewResponse
} from '../../models/store-assortment.model';
import { StoreAssortmentActions, StoreAssortmentActionType } from '../actions/store-assortment.actions';
import { BaseState } from '../state/base.state';

export interface StoreAssortmentState extends EntityState<StoreAssortmentList>, BaseState {
  selected: StoreAssortmentViewResponse;
  criteriaObject: StoreAssortmentListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: AuditLog[];
}

export const adapter: EntityAdapter<StoreAssortmentList> = createEntityAdapter<StoreAssortmentList>({
  selectId: list => list.storeNo
});

export const initialStoreAssortmentResponseState: StoreAssortmentState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function storeAssortmentReducers(
  state = initialStoreAssortmentResponseState,
  action: StoreAssortmentActions
): StoreAssortmentState {
  switch (action.type) {
    case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
