export enum PurchaseRequestTypeEnum {
  Z9 = 'Z9',
  Z9_EDIT = 'Z9_EDIT',
  WAREHOUSE = 'WAREHOUSE',
  HOT_PRICE = 'HOT_PRICE',
  SUPPLIER = 'SUPPLIER'
}

export enum PurchaseRequestStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  AWAITING_CREATE_PO = 'AWAITING_CREATE_PO',
  COMPLETED = 'COMPLETED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}

export enum PurchaseRequestModeEnum {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  PARTIAL_APPROVE = 'PARTIAL_APPROVE'
}
