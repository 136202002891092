export const prViewPermissions = [
  '^pr_inv_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_ast_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_sto_v_[a-zA-Z]{2}[0-9]{1,3}$',
  'pr_inv_v_sto',
  'pr_ast_v_sto',
  'pr_sto_v_sto'
];

export const prManagePermissions = [
  '^pr_inv_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_ast_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_sto_m_[a-zA-Z]{2}[0-9]{1,3}$',
  'pr_inv_m_sto',
  'pr_ast_m_sto',
  'pr_sto_m_sto'
];

export const prDcManagePermissions = [
  '^pr_inv_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_ast_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_sto_m_[a-zA-Z]{2}[0-9]{1,3}$'
];

export const prApprovePermissions = [
  '^pr_inv_app_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_ast_app_[a-zA-Z]{2}[0-9]{1,3}$',
  '^pr_sto_app_[a-zA-Z]{2}[0-9]{1,3}$',
  'pr_inv_app_sto',
  'pr_ast_app_sto',
  'pr_sto_app_sto'
];

export const poViewPermissions = [
  '^po_inv_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^po_ast_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^po_sto_v_[a-zA-Z]{2}[0-9]{1,3}$',
  'po_inv_v_sto',
  'po_ast_v_sto',
  'po_sto_v_sto'
];

export const poManagePermissions = [
  '^po_inv_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^po_ast_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^po_sto_m_[a-zA-Z]{2}[0-9]{1,3}$',
  'po_inv_m_sto',
  'po_ast_m_sto',
  'po_sto_m_sto'
];

export const grViewPermissions = [
  '^gr_inv_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^gr_ast_v_[a-zA-Z]{2}[0-9]{1,3}$',
  '^gr_sto_v_[a-zA-Z]{2}[0-9]{1,3}$',
  'gr_inv_v_sto',
  'gr_ast_v_sto',
  'gr_sto_v_sto'
];

export const grManagePermissions = [
  '^gr_inv_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^gr_ast_m_[a-zA-Z]{2}[0-9]{1,3}$',
  '^gr_sto_m_[a-zA-Z]{2}[0-9]{1,3}$',
  'gr_inv_m_sto',
  'gr_ast_m_sto',
  'gr_sto_m_sto'
];
