import { Action } from '@ngrx/store';
import {
  AdjustStockItemCriteria,
  AdjustStockItemPagination,
  AdjustStockRequest,
  AdjustStockResponse,
  AdjustStockResponseError,
  RequestImportAdjustStock,
  StockAdjustPagination,
  StockAdjustSearchCriteria
} from '../../models/adjust-stock.model';

export enum AdjustStockTypeActions {
  ADJUST_STOCK_LIST_REQUEST = '[Adjust Stock] List Request',
  ADJUST_STOCK_LIST_RESPONSE = '[Adjust Stock] List Response',

  ADJUST_STOCK_ITEM_LIST_REQUEST = '[Adjust Stock] Adjust Stock Item List Request',
  ADJUST_STOCK_ITEM_LIST_RESPONSE = '[Adjust Stock] Adjust Stock Item List Response',
  ADJUST_STOCK_ITEM_LIST_RESET = '[Adjust Stock] Adjust Stock Item List Reset',

  ADJUST_STOCK_REQUEST_VALIDATE_REQUEST = '[Adjust Stock] Adjust Stock Validate Request',
  ADJUST_STOCK_REQUEST_VALIDATE_RESPONSE = '[Adjust Stock] Adjust Stock Validate Response',
  ADJUST_STOCK_REQUEST_VALIDATE_RESET = '[Adjust Stock] Adjust Stock Validate Reset',

  ADJUST_STOCK_REQUEST_SUBMIT_REQUEST = '[Adjust Stock] Adjust Stock Submit Request',
  ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE = '[Adjust Stock] Adjust Stock Submit Response',
  ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE_ERROR = '[Adjust Stock] Adjust Stock Submit Error',
  ADJUST_STOCK_REQUEST_SUBMIT_RESET = '[Adjust Stock] Adjust Stock Submit Reset',

  ADJUST_STOCK_GET_BY_ID_REQUEST = '[Adjust Stock] Get Adjust Stock by Id Request',
  ADJUST_STOCK_GET_BY_ID_RESPONSE = '[Adjust Stock] Get Adjust Stock by Id Response',

  ADJUST_STOCK_IMPORT_SUBMIT_REQUEST = '[Adjust Stock] Adjust Stock Import Submit  Request',
  ADJUST_STOCK_IMPORT_SUBMIT_RESPONSE = '[Adjust Stock] Adjust Stock Import Submit Response',
  ADJUST_STOCK_IMPORT_SUBMIT_RESET = '[Adjust Stock] Adjust Stock Import Submit Reset',
  ADJUST_STOCK_GET_BY_ID_RESET = '[Adjust Stock] Adjust Stock Submit Reset'
}

export class AdjustStockListRequestAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST;
  constructor(public payload: StockAdjustSearchCriteria) {}
}

export class AdjustStockListResponseAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_LIST_RESPONSE;
  constructor(public payload: StockAdjustPagination) {}
}

export class AdjustStockItemListRequestAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_REQUEST;
  constructor(public payload: { docNo: string; adjustStockItemCriteria: AdjustStockItemCriteria }) {}
}

export class AdjustStockItemListResponseAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_RESPONSE;
  constructor(public payload: AdjustStockItemPagination) {}
}

export class AdjustStockItemListResetAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_ITEM_LIST_RESET;
}

export class AdjustStockRequestValidateAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_REQUEST;
  constructor(public payload: AdjustStockRequest) {}
}

export class AdjustStockResponseValidateAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_RESPONSE;
  constructor(public payload: { validatePass: boolean }) {}
}

export class AdjustStockResetValidateAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_RESET;
}

export class AdjustStockRequestAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_REQUEST;
  constructor(public payload: AdjustStockRequest) {}
}

export class AdjustStockResponseAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE;
  constructor(public payload: AdjustStockResponse) {}
}

export class AdjustStockResponseErrorAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE_ERROR;
  constructor(public payload: AdjustStockResponseError) {}
}

export class AdjustStockResetAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESET;
}

export class AdjustStockByIdRequestAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_REQUEST;

  constructor(public payload: string) {}
}

export class AdjustStockByIdResponseAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_RESPONSE;

  constructor(public payload: AdjustStockResponse) {}
}

export class AdjustStockImportSubmitRequestAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_REQUEST;

  constructor(public payload: RequestImportAdjustStock[]) {}
}

export class AdjustStockImportSubmitResponseAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_RESPONSE;

  constructor(public payload: any) {}
}

export class AdjustStockImportSubmitResetAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_RESET;
}

export class AdjustStockByIdResetAction implements Action {
  readonly type = AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_RESET;
}

export type AdjustStockAction =
  | AdjustStockItemListRequestAction
  | AdjustStockItemListResponseAction
  | AdjustStockItemListResetAction
  | AdjustStockListRequestAction
  | AdjustStockListResponseAction
  | AdjustStockByIdRequestAction
  | AdjustStockByIdResponseAction
  | AdjustStockByIdResetAction
  | AdjustStockRequestValidateAction
  | AdjustStockResponseValidateAction
  | AdjustStockResetValidateAction
  | AdjustStockRequestAction
  | AdjustStockResponseAction
  | AdjustStockResponseErrorAction
  | AdjustStockResetAction
  | AdjustStockImportSubmitRequestAction
  | AdjustStockImportSubmitResponseAction
  | AdjustStockImportSubmitResetAction;
