import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ShelfRequestList,
  ShelfRequestListSearchCriteria,
  ShelfRequestViewResponse
} from '../../models/shelf-request.model';
import { ShelfRequestActions, ShelfRequestActionType } from '../actions/shelf-request.actions';
import { BaseState } from '../state/base.state';

export interface ShelfRequestState extends EntityState<ShelfRequestList>, BaseState {
  selected: ShelfRequestViewResponse;
  criteriaObject: ShelfRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
  isDeleteSuccess: boolean;
}

export const adapter: EntityAdapter<ShelfRequestList> = createEntityAdapter<ShelfRequestList>();

export const initialShelfResponseState: ShelfRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function shelfRequestReducers(
  state = initialShelfResponseState,
  action: ShelfRequestActions
): ShelfRequestState {
  switch (action.type) {
    case ShelfRequestActionType.SHELF_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ShelfRequestActionType.SHELF_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ShelfRequestActionType.SHELF_REQUEST_LIST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    case ShelfRequestActionType.SHELF_REQUEST_DELETE_RESPONSE:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case ShelfRequestActionType.SHELF_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case ShelfRequestActionType.SHELF_REQUEST_DELETE_RESET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
