import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DeliveryOrderService } from '../../services/delivery-order.service';
import { DeliveryOrderActionTypes, DeliveryOrderListResponseAction, DeliveryOrderSaveSuccess, DeliveryOrderViewLoaded } from '../actions/delivery-order.actions';
import { LayoutActionLoadError } from '../actions/layout.action';
export class DeliveryOrderEffects {
    constructor(actions$, deliveryOrderService, logger) {
        this.actions$ = actions$;
        this.deliveryOrderService = deliveryOrderService;
        this.logger = logger;
        this.loadDeliveryOrder$ = this.actions$.pipe(ofType(DeliveryOrderActionTypes.DELIVERY_ORDER_VIEW_REQUESTED), tap(action => this.logger.debug('@Effect Delivery Order Requested: ' + JSON.stringify(action.payload))), mergeMap(action => this.deliveryOrderService.getDeliveryOrderById(action.payload).pipe(map(result => new DeliveryOrderViewLoaded(result)), catchError(err => of(new LayoutActionLoadError(err))))));
        this.saveDeliveryOrder$ = this.actions$.pipe(ofType(DeliveryOrderActionTypes.DELIVERY_ORDER_SAVE_REQUESTED), tap(action => this.logger.debug('@Effect Delivery Order Save: ' + JSON.stringify(action.payload))), switchMap(action => this.deliveryOrderService.updateDeliveryOrder(action.payload).pipe(map(() => new DeliveryOrderSaveSuccess(true)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.submitDeliveryOrder$ = this.actions$.pipe(ofType(DeliveryOrderActionTypes.DELIVERY_ORDER_SUBMIT_REQUESTED), tap(action => this.logger.debug('@Effect Delivery Order Submit: ' + JSON.stringify(action.payload))), switchMap(action => this.deliveryOrderService.submitDeliveryOrder(action.payload).pipe(map(() => new DeliveryOrderSaveSuccess(true)), catchError(error => of(new LayoutActionLoadError(error))))));
        this.deliveryOrderListRequestAction$ = this.actions$.pipe(ofType(DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST), tap(action => this.logger.debug(`@Effect ${DeliveryOrderActionTypes.DELIVERY_REQUEST_LIST_REQUEST}:` + JSON.stringify(action.payload))), switchMap(action => this.deliveryOrderService.searchByCriteria(action.payload).pipe(map(response => new DeliveryOrderListResponseAction(response)), catchError(error => of(new LayoutActionLoadError(error))))));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], DeliveryOrderEffects.prototype, "loadDeliveryOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], DeliveryOrderEffects.prototype, "saveDeliveryOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], DeliveryOrderEffects.prototype, "submitDeliveryOrder$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], DeliveryOrderEffects.prototype, "deliveryOrderListRequestAction$", void 0);
