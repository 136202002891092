import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  StoreAssortmentRequestStoreContent,
  StoreAssortmentRequestStoreListSearchCriteria
} from '../../models/store-assortment-request-store.model';
import {
  StoreAssortmentRequestStoreAction,
  StoreAssortmentRequestStoreActionTypes
} from '../actions/store-assortment-request-store.actions';

export interface StoreAssortmentRequestStoreState extends EntityState<StoreAssortmentRequestStoreContent> {
  selected: object;
  selectedAllPage: object;
  criteriaObject: StoreAssortmentRequestStoreListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StoreAssortmentRequestStoreContent> = createEntityAdapter<
  StoreAssortmentRequestStoreContent
>({
  selectId: list => list.storeNo
});

export const initialStoreAssortmentRequestStoreState: StoreAssortmentRequestStoreState = adapter.getInitialState({
  selected: {},
  selectedAllPage: {},
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function storeAssortmentRequestStoreReducers(
  state = initialStoreAssortmentRequestStoreState,
  action: StoreAssortmentRequestStoreAction
): StoreAssortmentRequestStoreState {
  switch (action.type) {
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL: {
      const { entities } = state;
      const selectedNew = {};
      Object.keys(entities).forEach(key => {
        selectedNew[entities[key].storeNo] = entities[key];
      });
      return {
        ...state,
        selected: {
          ...state.selected,
          ...selectedNew
        },
        selectedAllPage: {
          ...state.selectedAllPage
        }
      };
    }
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT_ALL: {
      const { selected, entities } = state;
      Object.keys(entities).forEach(key => {
        delete selected[entities[key].storeNo];
      });
      return {
        ...state,
        selected: {
          ...selected
        },
        selectedAllPage: {
          ...state.selectedAllPage
        }
      };
    }
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT: {
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.storeNo]: action.payload
        }
      };
    }
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_UNSELECT: {
      const { selected } = state;
      delete selected[action.payload.storeNo];
      return {
        ...state,
        selected: {
          ...selected
        }
      };
    }
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_RESET: {
      return {
        ...state,
        selected: {},
        selectedAllPage: {}
      };
    }
    case StoreAssortmentRequestStoreActionTypes.STORE_ASSORTMENT_REQUEST_STORE_SELECT_ALL_RESET: {
      return {
        ...state,
        selectedAllPage: { 1: false }
      };
    }
    default: {
      return { ...state };
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
