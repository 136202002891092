import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  AdjustStockResponse,
  AdjustStockResponseError,
  ResponseImportAdjustStock,
  StockAdjustContent,
  StockAdjustSearchCriteria
} from '../../models/adjust-stock.model';
import { AdjustStockAction, AdjustStockTypeActions } from '../actions/adjust-stock.actions';

export interface AdjustStockState extends EntityState<StockAdjustContent> {
  isLoading: boolean;
  saveSuccess: boolean;
  validatePass: boolean;
  adjustStockResponse: AdjustStockResponse;
  adjustStockResponseError: AdjustStockResponseError;
  selected: AdjustStockResponse;
  criteriaObject: StockAdjustSearchCriteria;
  totalElements: number;
  totalPages: number;
  importSuccess: boolean;
  importAdjustStockResponse: ResponseImportAdjustStock;
}

export const adapter: EntityAdapter<StockAdjustContent> = createEntityAdapter<StockAdjustContent>();

export const initialAdjustStockState: AdjustStockState = adapter.getInitialState({
  isLoading: false,
  saveSuccess: false,
  validatePass: false,
  adjustStockResponse: null,
  adjustStockResponseError: null,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  importSuccess: false,
  importAdjustStockResponse: null
});

export function AdjustStockReducers(state = initialAdjustStockState, action: AdjustStockAction): AdjustStockState {
  switch (action.type) {
    case AdjustStockTypeActions.ADJUST_STOCK_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case AdjustStockTypeActions.ADJUST_STOCK_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_RESPONSE:
      return {
        ...state,
        validatePass: action.payload.validatePass
      };
    case AdjustStockTypeActions.ADJUST_STOCK_REQUEST_VALIDATE_RESET:
      return {
        ...state,
        validatePass: false
      };
    case AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE:
      return {
        ...state,
        saveSuccess: true,
        adjustStockResponse: action.payload
      };
    case AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESPONSE_ERROR:
      return {
        ...state,
        saveSuccess: true,
        adjustStockResponseError: action.payload
      };
    case AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case AdjustStockTypeActions.ADJUST_STOCK_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case AdjustStockTypeActions.ADJUST_STOCK_REQUEST_SUBMIT_RESET:
      return {
        ...state,
        selected: null,
        adjustStockResponseError: null,
        adjustStockResponse: null,
        saveSuccess: false,
        validatePass: false
      };

    case AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_RESPONSE:
      return {
        ...state,
        importSuccess: true,
        importAdjustStockResponse: action.payload
      };

    case AdjustStockTypeActions.ADJUST_STOCK_IMPORT_SUBMIT_RESET:
      return {
        ...state,
        importSuccess: false,
        importAdjustStockResponse: null
      };

    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
