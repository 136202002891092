import { Action } from '@ngrx/store';
import {
  GoodsReceive,
  GoodsReceiveCancelRequestData,
  GoodsReceiveErrorResponse,
  GoodsReceivePagination,
  GoodsReceiveSearchCriteria
} from '../../models/goods-receive.model';

export enum GoodsReceiveActionTypes {
  GOODS_RECEIVE_VIEW_REQUESTED = '[Goods Receive View Page] Goods Receive View Request',
  GOODS_RECEIVE_VIEW_BY_ID_REQUESTED = '[Goods Receive View Page By Id] Goods Receive View By Id Request',
  GOODS_RECEIVE_VIEW_LOADED = '[Goods Receive API] Goods Receive View Loaded',
  GOODS_RECEIVE_RESET = '[Goods Receive View Page] Goods Receive Reset',
  GOODS_RECEIVE_LIST_REQUEST = '[Goods Receive List Page] Goods Receive List Request',
  GOODS_RECEIVE_LIST_RESPONSE = '[Goods Receive List API] Goods Receive List Response',
  GOODS_RECEIVE_SUBMIT_REQUEST = '[Goods Receive Submit API] Goods Receive Submit Request',
  GOODS_RECEIVE_SUBMIT_RESPONSE_SUCCESS = '[Goods Receive Submit API] Goods Receive Submit Response Success',
  GOODS_RECEIVE_SUBMIT_RESPONSE_ERROR = '[Goods Receive Submit API] Goods Receive Submit Response Error',
  GOODS_RECEIVE_RESET_SAVE_SUCCESS_STATUS = '[Goods Receive Submit API] Goods Receive Submit Reset Status',
  GOODS_RECEIVE_CANCEL_REQUEST = '[Goods Receive Cancel API] Goods Receive Cancel Request'
}

export class GoodsReceiveViewRequest implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_REQUESTED;

  constructor(public payload: string) {}
}

export class GoodsReceiveViewLoaded implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_LOADED;

  constructor(public payload: GoodsReceive) {}
}

export class GoodsReceiveViewByIdRequest implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_VIEW_BY_ID_REQUESTED;

  constructor(public payload: string) {}
}

export class GoodsReceiveReset implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_RESET;

  constructor() {}
}

export class GoodsReceiveListRequest implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_LIST_REQUEST;

  constructor(public payload: GoodsReceiveSearchCriteria) {}
}

export class GoodsReceiveListResponse implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_LIST_RESPONSE;

  constructor(public payload: GoodsReceivePagination) {}
}

export class GoodsReceiveSubmitRequest implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_REQUEST;

  constructor(public payload: GoodsReceive) {}
}

export class GoodsReceiveSubmitResponseSuccess implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_RESPONSE_SUCCESS;

  constructor() {}
}

export class GoodsReceiveResetSaveSuccessStatus implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_RESET_SAVE_SUCCESS_STATUS;

  constructor() {}
}

export class GoodsReceiveCancelRequest implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_CANCEL_REQUEST;

  constructor(public payload: GoodsReceiveCancelRequestData) {}
}

export class GoodsReceiveSubmitResponseError implements Action {
  readonly type = GoodsReceiveActionTypes.GOODS_RECEIVE_SUBMIT_RESPONSE_ERROR;

  constructor(public payload: GoodsReceiveErrorResponse) {}
}

export type GoodsReceiveActions =
  | GoodsReceiveViewRequest
  | GoodsReceiveViewByIdRequest
  | GoodsReceiveViewLoaded
  | GoodsReceiveReset
  | GoodsReceiveListRequest
  | GoodsReceiveListResponse
  | GoodsReceiveSubmitRequest
  | GoodsReceiveSubmitResponseSuccess
  | GoodsReceiveResetSaveSuccessStatus
  | GoodsReceiveCancelRequest
  | GoodsReceiveSubmitResponseError;
