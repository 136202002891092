import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PriceSetting } from '../models';
import { BaseService } from './base.service';

@Injectable()
export class SettingService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.priceSetting;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  getPriceSetting(): Observable<PriceSetting> {
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body'
    });
  }
}
