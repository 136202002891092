<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-user"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close" aria-hidden="true"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div>
  <div class="admin-form" [formGroup]="storeForm">
    <div class="info-header-container" *ngIf="storeGroup">
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Store Group ID.:</span>
        </div>
        <div class="info-header-value">
          <span>{{ storeGroup.no }}</span>
        </div>
      </div>
      <div class="info-header-item">
        <div class="info-header-label">
          <span>Status:</span>
        </div>
        <div class="info-header-value">
          <span *ngIf="pageMode.CREATE !== data.mode" [ngClass]="storeGroup?.status | lowercase" class="request-status">
            {{ storeGroup?.status | titlecase }}</span
          >
        </div>
      </div>
    </div>

    <div>
      <div class="row section-header">
        <span>Store Group</span>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="storeGroupName">Store Group Name<span class="text-danger">*</span></label>
              <input
                id="storeGroupName"
                name="storeGroupName"
                class="form-control"
                formControlName="storeGroupName"
                maxlength="100"
                placeholder="Store Group Name"
                trim="blur"
                [ngClass]="{
                  'is-invalid': submitted && storeForm.controls.storeGroupName.errors
                }"
              />
              <div *ngIf="submitted && storeForm.controls.storeGroupName.errors" class="invalid-feedback">
                <div *ngIf="storeForm.controls.storeGroupName.errors?.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
                <div *ngIf="storeForm.controls.storeGroupName.errors?.isDuplicatedName">
                  {{ 'ERROR_CODE.04015' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-3">
              <label for="merchant">Merchant<span class="text-danger">*</span></label>
              <ng-select
                id="merchant"
                [items]="merchantList | async"
                placeholder="Please select..."
                notFoundText="No Data"
                formControlName="merchant"
                bindLabel="merchantNameDisplay"
                dropdownPosition="auto"
                [loading]="merchantSearchLoading"
                (change)="onChangeMerchant()"
                [typeahead]="merchantSearchInput$"
                [ngClass]="{
                  'is-invalid': submitted && storeForm.controls.merchant.errors
                }"
              >
                <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                  <div>
                    <strong>Merchant Name:</strong>
                    <span [ngOptionHighlight]="search" class="ml-1">
                      {{ item.merchantName }}
                    </span>
                  </div>
                  <small>
                    <strong>Tax ID/ID Card:</strong
                    ><span [ngOptionHighlight]="search" class="ml-1">{{ item.taxId }}</span
                    >{{ ' | ' }} <strong>Store Type:</strong
                    ><span [ngOptionHighlight]="search" class="ml-1">{{
                      'STORE_TYPE.' + item.merchantType | translate
                    }}</span>
                  </small>
                </ng-template>
              </ng-select>

              <div *ngIf="submitted && storeForm.controls.merchant.errors" class="invalid-feedback">
                <div *ngIf="storeForm.controls.merchant.errors?.required">
                  {{ 'ERRORS.REQUIRED' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr class="section-divider" />

      <div class="row section-header">
        <span>Store</span>
      </div>

      <div class="row" *ngIf="formStoreList.length > 0">
        <div class="col-md-12 mb-3">
          <table class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr>
                <th class="text-center w-15">Region</th>
                <th class="text-center w-15">Province</th>
                <th class="text-center w-10">Store Code</th>
                <th class="text-center w-35">Store Name</th>
                <th class="text-center w-10">Status</th>
                <th class="text-center w-5" *ngIf="!isRequestViewMode">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let item of formStoreList.controls; let i = index"
                [ngClass]="{
                  'error-background': submitted && item.get('code').errors?.duplicated
                }"
              >
                <td>{{ item.get('region').value | nameThDisplay: regions }}</td>
                <td>{{ item.get('state').value | nameThDisplay: provinces }}</td>
                <td>
                  {{ item.get('code').value }}
                </td>
                <td>
                  {{ item.get('name').value }}
                  <small
                    class="d-block invalid-feedback text-danger "
                    *ngIf="submitted && item.get('code').errors?.duplicated"
                  >
                    {{ 'ERRORS.DUPLICATED_STORE' | translate }}</small
                  >
                  <small
                    class="d-block invalid-feedback text-danger "
                    *ngIf="submitted && item.get('code').errors?.isAnotherGroup"
                  >
                    {{ 'ERROR_CODE.04017' | translate }}</small
                  >
                </td>
                <td>{{ item.get('status').value | titlecase }}</td>
                <td class="text-center align-middle" *ngIf="!isRequestViewMode">
                  <a
                    id="delete-store-{{ i }}"
                    class="is-link text-muted"
                    (click)="deleteStoreItem(i)"
                    title="Delete"
                    appTippy
                    [tippyOptions]="{ arrow: true }"
                    *ngIf="!isRequestViewMode"
                  >
                    <em class="icon-delete"></em>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row" *ngIf="!isRequestViewMode">
        <div class="col-md-12 mb-3">
          <a class="btn-text-link d-inline-block mr-1 " (click)="onSelectStore()">+Select Store</a> or
          <a class="btn-text-link d-inline-block ml-1" (click)="onSelectProvince()">+Select Province</a>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="fixed-row-bottom form-row">
      <div class="float-left"></div>
      <div class="float-right">
        <button
          type="button"
          id="btn-cancel"
          class="btn btn-standard mr-2"
          (click)="onExit()"
          *ngIf="!isRequestViewMode && hasManagePermission"
        >
          Cancel
        </button>
        <button
          type="button"
          id="btn-submit"
          class="btn btn-primary"
          appPreventDoubleClick
          (click)="onSubmit()"
          *ngIf="!isRequestViewMode && hasManagePermission"
        >
          Submit
        </button>
        <button
          *ngIf="isRequestViewMode && (storeGroup?.status | lowercase) === 'active' && hasManagePermission"
          type="button"
          id="btn-request-edit"
          class="btn btn-primary"
          (click)="toggleToEditMode()"
        >
          Edit
        </button>
      </div>
    </div>
    <!--End footer-->
  </div>

  <!-- Start Dialog show Store-->
  <div
    class="modal fade"
    bsModal
    #modalSelectStore="bs-modal"
    [config]="{ backdrop: 'static' }"
    [formGroup]="dialogForm"
    name="modalStore"
    id="modalStore"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Store</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="onExitStore()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label>Search by: Store Name, Store Code, Store ID<span class="text-danger">*</span></label>
                  <ng-select
                    id="storeName"
                    [items]="storeList | async"
                    placeholder="Please select..."
                    notFoundText="No Data"
                    [virtualScroll]="true"
                    formControlName="storeName"
                    bindLabel="display"
                    dropdownPosition="auto"
                    [loading]="storeSearchLoading"
                    [typeahead]="storeSearchInput$"
                    [ngClass]="{
                      'is-invalid': selectStoreOk && dialogForm.controls.storeName.errors
                    }"
                  >
                    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                      <div>
                        <strong>Store Name:</strong>
                        <span [ngOptionHighlight]="search" class="ml-1">
                          {{ item.name }}
                        </span>
                      </div>
                      <small>
                        <strong>Store Code:</strong
                        ><span [ngOptionHighlight]="search" class="ml-1">{{ item.code }}</span
                        >{{ ' | ' }} <strong>Store ID:</strong
                        ><span [ngOptionHighlight]="search" class="ml-1">{{ item.no }}</span>
                      </small>
                    </ng-template>
                  </ng-select>
                  <div *ngIf="selectStoreOk && dialogForm.controls.storeName.errors" class="invalid-feedback">
                    <div *ngIf="dialogForm.controls.storeName.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="btn-select-store-cancel"
            name="btn-select-store-cancel"
            class="btn btn-standard mr-2"
            (click)="onExitStore()"
          >
            Cancel
          </button>
          <button
            type="button"
            id="btn-select-store-ok"
            name="btn-select-store-ok"
            class="btn btn-primary"
            appPreventDoubleClick
            (click)="onStoreOk()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Dialog-->

  <!-- Start Dialog show province-->
  <div
    class="modal fade"
    bsModal
    #modalSelectProvince="bs-modal"
    [config]="{ backdrop: 'static' }"
    [formGroup]="dialogForm"
    name="modalProvince"
    id="modalProvince"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title pull-left">Select Province</h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="onExitProvince()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body admin-form">
          <div class="container-fluid">
            <div class="form-group">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <label>Select Province<span class="text-danger">*</span></label>
                  <ng-select
                    id="province"
                    formControlName="province"
                    placeholder="Please select..."
                    [items]="selectProvinces"
                    bindLabel="nameTh"
                    bindValue="code"
                    [ngClass]="{
                      'is-invalid': selectProvinceOk && dialogForm.controls.province.errors
                    }"
                  >
                  </ng-select>
                  <div *ngIf="selectProvinceOk && dialogForm.controls.province.errors" class="invalid-feedback">
                    <div *ngIf="dialogForm.controls.province.errors.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            id="btn-select-province-cancel"
            name="btn-select-province-cancel"
            class="btn btn-standard mr-2"
            (click)="onExitProvince()"
          >
            Cancel
          </button>
          <button
            type="button"
            id="btn-select-province-ok"
            name="btn-select-province-ok"
            class="btn btn-primary"
            (click)="onProvinceOk()"
            appPreventDoubleClick
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Dialog-->
</div>
