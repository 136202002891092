import { Action } from '@ngrx/store';
import { StoreNo } from '../../models';
import {
  StoreAssortmentListResponse,
  StoreAssortmentListSearchCriteria,
  StoreAssortmentViewResponse
} from '../../models/store-assortment.model';

export enum StoreAssortmentActionType {
  STORE_ASSORTMENT_LIST_REQUEST = '[StoreAssortment] StoreAssortment List Request',
  STORE_ASSORTMENT_LIST_RESPONSE = '[StoreAssortment] StoreAssortment List Response',
  STORE_ASSORTMENT_LIST_HISTORY_REQUEST = '[StoreAssortment] List History Request',
  STORE_ASSORTMENT_LIST_HISTORY_RESPONSE = '[StoreAssortment] List History Response',
  STORE_ASSORTMENT_GET_BY_ID_REQUEST = '[StoreAssortment] Get StoreAssortment by Id Request',
  STORE_ASSORTMENT_GET_BY_ID_RESPONSE = '[StoreAssortment] Get StoreAssortment by Id Response',
  STORE_ASSORTMENT_GET_BY_ID_RESET = '[StoreAssortment] Get StoreAssortment by Id Reset'
}

export class StoreAssortmentListRequestAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST;

  constructor(public payload: StoreAssortmentListSearchCriteria) {}
}

export class StoreAssortmentListResponseAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_LIST_RESPONSE;

  constructor(public payload: StoreAssortmentListResponse) {}
}

export class StoreAssortmentListHistoryRequestAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_REQUEST;
  constructor(public payload: StoreNo) {}
}

export class StoreAssortmentListHistoryResponseAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_RESPONSE;
  constructor(public payload: { auditLogs }) {}
}

export class StoreAssortmentByIdRequestAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_REQUEST;

  constructor(public payload: StoreNo) {}
}

export class StoreAssortmentByIdResponseAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESPONSE;

  constructor(public payload: StoreAssortmentViewResponse) {}
}

export class ResetStoreAssortmentAction implements Action {
  readonly type = StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESET;
}

export type StoreAssortmentActions =
  | StoreAssortmentListRequestAction
  | StoreAssortmentListResponseAction
  | StoreAssortmentListHistoryRequestAction
  | StoreAssortmentListHistoryResponseAction
  | StoreAssortmentByIdRequestAction
  | StoreAssortmentByIdResponseAction
  | ResetStoreAssortmentAction;
