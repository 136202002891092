import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';
import { PromotionTheme } from '../../../../shared/models';
import { timeRangeTwentyFour } from '../../../../shared/models/list-value/list-key-value.model';
import {
  PromotionThemeAddRequestAction,
  PromotionThemeAddReset,
  PromotionThemeRequestAction
} from '../../../../shared/store/actions/promotion.action';
import {
  selectAddPromotionTheme,
  selectAddPromotionThemeError,
  selectPromotionThemeActive
} from '../../../../shared/store/selectors/promotion-request.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-handle-theme',
  templateUrl: './handle-theme.component.html',
  styleUrls: ['./handle-theme.component.scss']
})
export class HandleThemeComponent implements OnInit, OnDestroy {
  @ViewChild('handleTheme', { static: false }) handleTheme: ModalDirective;
  private readonly localStore: Observable<any>;

  themeForm: FormGroup;
  isAddTheme: boolean;
  listTheme: PromotionTheme[];
  listThemeSubscribe: Subscription;
  addThemeSubscribe: Subscription;
  addThemeErrorSubscribe: Subscription;
  timeRangeExpire: NgOption[];
  timeRangeEffective: NgOption[];
  minDate: Date = new Date();
  minEffectiveDate: Date = new Date();
  maxDate: Date;
  isDuplicateTheme: boolean;
  dateFormat = environment.dateFormat;
  dateTimeDisplay = environment.dateTimeDisplay;

  constructor(protected readonly store: Store<AppStates>, protected fb: FormBuilder) {}

  ngOnInit() {
    this.isAddTheme = false;
    this.timeRangeEffective = timeRangeTwentyFour().slice(0, -1);
    this.timeRangeExpire = timeRangeTwentyFour().slice(1);

    this.listThemeSubscribe = this.store.pipe(select(selectPromotionThemeActive)).subscribe(data => {
      this.listTheme = data;
    });

    this.addThemeSubscribe = this.store.pipe(select(selectAddPromotionTheme)).subscribe(success => {
      if (success) {
        this.store.dispatch(new PromotionThemeRequestAction({ type: 'expiredate' }));

        this.isDuplicateTheme = false;
        this.clearValue();
      }
    });

    this.addThemeErrorSubscribe = this.store.pipe(select(selectAddPromotionThemeError)).subscribe(result => {
      if (result) {
        this.isDuplicateTheme = true;
      } else {
        this.isDuplicateTheme = false;
      }
    });

    this.createForm();
  }

  createForm() {
    const initialNullRequired = [{ value: null, disabled: false }, Validators.required];

    this.themeForm = this.fb.group({
      theme: initialNullRequired,
      effectiveDate: initialNullRequired,
      effectiveTime: [{ value: this.timeRangeEffective[0].value, disabled: false }],
      expireDate: initialNullRequired,
      expireTime: [{ value: this.timeRangeExpire[this.timeRangeExpire.length - 1].value, disabled: false }]
    });
  }

  onChangeEffectiveDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
      this.effectiveChangeDate(value);
    }
  }

  onChangeEffectiveTime(event: any) {
    const effDate = this.themeForm.controls['effectiveDate'].value;
    const expDate = this.themeForm.controls['expireDate'].value;
    const expTime = this.themeForm.controls['expireTime'].value;
    let timeList = timeRangeTwentyFour().slice(1);
    this.timeRangeExpire = [];
    const effTime = event;
    if (moment(effDate).isSame(expDate, 'day')) {
      timeList = this.fillOutIsToday(expDate, timeList, 0);
      const hour = Number(effTime.value.split(':')[0]);
      timeList = timeList.filter(item => hour < Number(item.value.split(':')[0]) || item.value === '23:59');
    }
    this.timeRangeExpire = timeList;
    if (!timeList.find(e => e.value === expTime)) {
      this.themeForm.controls['expireTime'].setValue(timeList[0].value);
    }
  }

  onChangeExpireTime(event: any) {
    const effDate = this.themeForm.controls['effectiveDate'].value;
    const expDate = this.themeForm.controls['expireDate'].value;
    const effTime = this.themeForm.controls['effectiveTime'].value;
    let timeList = timeRangeTwentyFour().slice(0, -1);
    this.timeRangeEffective = [];
    const expTime = event;
    if (moment(effDate).isSame(expDate, 'day')) {
      timeList = this.fillOutIsToday(effDate, timeList, 0);
      const hour = Number(expTime.value.split(':')[0]);
      timeList = timeList.filter(item => hour > Number(item.value.split(':')[0]));
      if (expTime === '23:59') {
        timeList.push({ value: '23:00', label: '23:00' });
      }
    }
    this.timeRangeEffective = timeList;
    if (!timeList.find(e => e.value === effTime)) {
      this.themeForm.controls['effectiveTime'].setValue(timeList[0].value);
    }
  }

  effectiveChangeDate(dateValue: Date) {
    let timeList = timeRangeTwentyFour().slice(0, -1);
    const today = new Date();
    this.timeRangeEffective = [];

    if (moment(dateValue).isSame(today, 'day')) {
      timeList = this.fillOutIsToday(dateValue, timeList, 0);
    }
    this.timeRangeEffective = timeList;
    const selected = this.timeRangeEffective[0];
    this.themeForm.controls['effectiveTime'].setValue(selected.value);
    const expDate = this.themeForm.controls['expireDate'].value;

    if (moment(dateValue).isSame(expDate, 'day')) {
      this.timeRangeExpire = [];
      const effTime = this.themeForm.controls['effectiveTime'].value;
      const hour = Number(effTime.split(':')[0]);
      const timeListExpire = timeList.filter(
        item => hour < Number(item.value.split(':')[0]) || item.value === '23:59'
      );

      timeListExpire.push({ value: '23:59', label: '23:59' });
      this.timeRangeExpire = timeListExpire;
      const setTime = this.timeRangeExpire[this.timeRangeEffective.length - 1];
      this.themeForm.controls['expireTime'].setValue(setTime.value);
    } else {
      this.timeRangeExpire = timeRangeTwentyFour().splice(1);
    }
  }

  expireChangeDate(dateValue: Date) {
    let timeList = timeRangeTwentyFour().slice(1);
    const today = new Date();
    this.timeRangeExpire = [];

    if (moment(dateValue).isSame(today, 'day')) {
      timeList = this.fillOutIsToday(dateValue, timeList, 1);
    }

    const effDate = this.themeForm.controls['effectiveDate'].value;

    if (moment(dateValue).isSame(effDate, 'day')) {
      const effTime = this.themeForm.controls['effectiveTime'].value;
      const hour = Number(effTime.split(':')[0]);
      timeList = timeList.filter(item => hour < Number(item.value.split(':')[0]) || item.value === '23:59');
    }

    this.timeRangeExpire = timeList;
    const selected = this.timeRangeExpire.find(e => e.value === '23:59');
    this.themeForm.controls['expireTime'].setValue(selected.value);
  }

  onChangeExpireDate(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
      this.expireChangeDate(value);
    } else {
      this.maxDate = null;
    }
  }

  fillOutIsToday(selectedDate: Date, timeList: any[], increase = 0): any[] {
    let output = [];
    const today = new Date();
    const getTime = today.getHours() + increase;

    if (moment(selectedDate).isSame(today, 'day')) {
      timeList.forEach(getValue => {
        const timeStr = getValue.value.split(':');
        const hour = Number(timeStr[0]);

        if (hour > getTime) {
          output.push(getValue);
        }
      });
    } else {
      output = timeList;
    }
    return output;
  }

  clearValue(): void {
    this.isAddTheme = false;
    const controlName = ['theme', 'effectiveDate', 'expireDate', 'effectiveTime', 'expireTime'];

    controlName.forEach(control => {
      if (control === 'effectiveTime') {
        this.themeForm.get(control).setValue(this.timeRangeEffective[0].value);
      } else if (control === 'expireTime') {
        this.themeForm.get(control).setValue(this.timeRangeExpire[this.timeRangeExpire.length - 1].value);
      } else {
        this.themeForm.get(control).setValue(null);
      }
      this.themeForm.get(control).updateValueAndValidity({ onlySelf: true });
    });
  }

  closeModel() {
    this.clearValue();
    this.store.dispatch(new PromotionThemeAddReset());
    this.store.dispatch(new PromotionThemeRequestAction({ type: 'effectiveDate' }));
    this.handleTheme.hide();
  }

  addTheme() {
    this.isAddTheme = true;

    if (this.themeForm.invalid) {
      this.isDuplicateTheme = false;
      return;
    }

    const data = this.prepareData();

    this.store.dispatch(new PromotionThemeAddRequestAction(data));
  }

  prepareData(): PromotionTheme {
    const rawData = this.themeForm.getRawValue();

    return {
      name: rawData.theme,
      effectiveDate: this.mergeDateTime(rawData.effectiveDate, rawData.effectiveTime),
      expireDate: this.mergeDateTime(rawData.expireDate, rawData.expireTime)
    } as PromotionTheme;
  }

  mergeDateTime(date: any, time: string): string {
    date = moment(date);
    const arrTime = time.split(':');
    date.set('hour', Number(arrTime[0]));
    date.set('minute', Number(arrTime[1]));

    return date.format(environment.dateTimeFormat);
  }

  ngOnDestroy(): void {
    if (this.listThemeSubscribe) {
      this.listThemeSubscribe.unsubscribe();
    }

    if (this.addThemeSubscribe) {
      this.addThemeSubscribe.unsubscribe();
    }

    if (this.addThemeErrorSubscribe) {
      this.addThemeErrorSubscribe.unsubscribe();
    }
  }
}
