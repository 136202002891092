import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseService } from '../../../services/base.service';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit, OnDestroy {
  public refId: string;
  public url: string;
  public service: BaseService;

  constructor() {}

  ngOnInit() {
    if (this.service) {
      this.service.getFileUrl(this.refId).subscribe(result => {
        this.url = result.signedUrl;
      });
    }
  }

  ngOnDestroy(): void {}
}
