import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class StoresService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.stores;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchStores(term): Observable<any> {
    const criteria = { ...term, size: 100 };
    const params = this.getParams(criteria);
    console.log('params ', params);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }
}
