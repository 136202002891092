import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as uuid from 'uuid';
import { Z8ConditionsListSearchCriteria } from '../../models/z8-conditions.model';
import { Z8ParameterContent } from '../../models/z8-parameter.model';
import { Z8ConditionsActions, Z8ConditionsActionType } from '../actions/z8-conditions.actions';
import { BaseState } from '../state/base.state';

export interface Z8ConditionsState extends EntityState<Z8ParameterContent>, BaseState {
  criteriaObject: Z8ConditionsListSearchCriteria;
  totalElements: number;
  totalPages: number;
  auditLogs: any;
}

export const adapter: EntityAdapter<Z8ParameterContent> = createEntityAdapter<Z8ParameterContent>({
  selectId: (z8Parameter: any) =>
    `${uuid.v4()}_${z8Parameter.store}_${z8Parameter.productLevel}_${z8Parameter.productValue.code}_${
      z8Parameter.startDate
    }_${z8Parameter.endDate}`
});

export const initialZ8ConditionsResponseState: Z8ConditionsState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20,
    requestNo: null
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null
});

export function z8ConditionsReducers(
  state = initialZ8ConditionsResponseState,
  action: Z8ConditionsActions
): Z8ConditionsState {
  switch (action.type) {
    case Z8ConditionsActionType.Z8_CONDITIONS_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case Z8ConditionsActionType.Z8_CONDITIONS_LIST_RESET:
      return initialZ8ConditionsResponseState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
