import { Pipe, PipeTransform } from '@angular/core';
import { round } from 'lodash';

@Pipe({ name: 'numberFormatDisplay' })
export class NumberFormatDisplayPipe implements PipeTransform {
  transform(num: any, precision = 2, dash: string = '-') {
    if (!num && num !== 0) {
      return dash;
    }
    if (typeof num === 'string' || num instanceof String) {
      return num;
    }

    let isNegative = false;
    if (num < 0) {
      isNegative = true;
      num = Math.abs(num);
    }

    // for IE8+
    if (precision === 0) {
      const realNumber = String(Number(num).toLocaleString()).match(/^([0-9,]+)/);
      return `${isNegative ? '-' : ''}${realNumber[1]}`;
    }

    const floatNumber = Number(round(num, precision)).toFixed(precision);
    const groupNumber = String(floatNumber).match(/^(\d+).(\d+)$/);
    const leadingNumber = String(Number(groupNumber[1]).toLocaleString()).match(/^([0-9,]+)/);
    return `${isNegative ? '-' : ''}${leadingNumber[0]}.${groupNumber[2]}`;
  }
}
