import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import * as moment from 'moment';
import { BsDatepickerConfig, BsModalService } from 'ngx-bootstrap';
import { environment } from '../../../../environments/environment';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { ModalButtonResponseEnum } from '../../../shared/enum/modal-button-response.enum';
import { ConfirmModalComponent } from '../../../shared/layouts/modals/confirm-modal/confirm-modal.component';
import {
  AssortmentAllType,
  AssortmentPageModes,
  AssortmentRequestTypeEnum,
  BarcodeStatusEnum
} from '../../../shared/models';
import {
  barcodeSizeLOV,
  barcodeStatusLOV,
  barcodeStatusWithoutNoneValueLOV
} from '../../../shared/models/list-value/list-key-value.model';

@Component({
  selector: 'app-assortment-barcode',
  templateUrl: './assortment-barcode.component.html',
  styleUrls: ['./assortment-barcode.component.scss']
})
export class AssortmentBarcodeComponent implements OnInit, OnDestroy {
  @Input() parentForm: FormGroup;
  @Input() isSubmitted: boolean;
  @Input() mode: AssortmentPageModes;
  @Input() articleType: ArticleTypeEnum;
  @Input() listOfValue: {};
  @Input() listOfChange: {};
  @Input() isRequestViewMode: {};
  @Input() editBarcodeList: string[];
  @Input() type: AssortmentRequestTypeEnum;

  articleTypeEnum = ArticleTypeEnum;
  barcodeStatusEnum = BarcodeStatusEnum;
  assortmentPageModes = AssortmentPageModes;
  assortmentRequestTypeEnum = AssortmentRequestTypeEnum;
  public assortmentAllType = AssortmentAllType;

  barcodeSizeList: NgOption[];
  barcodeStatusList: NgOption[];
  barcodeStatusListWithoutNoneValue: NgOption[];

  bsDateConfig: BsDatepickerConfig;
  minDate: Date;
  minNewRetailPriceEffectiveDate: Date;
  additionalCharacters: RegExp = new RegExp('[-]', 'gi');
  dateFormat = environment.dateFormat;

  constructor(protected readonly modalService: BsModalService) {}

  ngOnInit() {
    this.barcodeSizeList = barcodeSizeLOV;
    this.barcodeStatusList = barcodeStatusLOV;
    this.barcodeStatusListWithoutNoneValue = barcodeStatusWithoutNoneValueLOV;

    this.minDate = new Date(2019, 0, 1);
    this.minNewRetailPriceEffectiveDate = moment()
      .add(1, 'days')
      .startOf('day')
      .toDate();
    this.bsDateConfig = {
      containerClass: 'theme-dark-blue',
      dateInputFormat: this.dateFormat,
      showWeekNumbers: false,
      adaptivePosition: true,
      isAnimated: true
    } as BsDatepickerConfig;
  }

  ngOnDestroy(): void {}

  onChangeTDPickingUnit(index: number) {
    this.getBarcodeForm.controls.forEach((barcodeForm, i) => {
      if (index !== i) {
        barcodeForm.patchValue({
          tdPickingUnit: false
        });
      }
    });
  }

  onDeleteBarcode(index: number) {
    const confirmModalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        okText: 'Yes, delete'
      }
    });

    confirmModalRef.content.action
      .pipe(untilComponentDestroyed(this))
      .subscribe((result: ModalButtonResponseEnum) => {
        if (result === ModalButtonResponseEnum.OK) {
          this.getBarcodeForm.removeAt(index);
        }
      });
  }

  get getBarcodeForm(): any {
    return this.parentForm.get('barcodes') as FormArray;
  }
}
