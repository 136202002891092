import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DcAssortmentExportCriteria } from '../../../shared/models/report.model';
import { SelectWarehouseList } from '../../../shared/models/warehouse.model';
import { ProductAssortmentService } from '../../../shared/services';
import { SelectWarehouseListRequestAction } from '../../../shared/store/actions/warehouse.actions';
import { selectWarehouseList } from '../../../shared/store/selectors/warehouse.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { BaseReportModalComponent } from '../base-report-modal.component';

@Component({
  selector: 'app-dc-assortment-modal',
  templateUrl: './dc-assortment-modal.component.html',
  styleUrls: ['./dc-assortment-modal.component.scss']
})
export class DcAssortmentModalComponent extends BaseReportModalComponent implements OnInit {
  @ViewChild('dcAssortmentModal', { static: false }) dcAssortmentModal: ModalDirective;
  public exportForm: FormGroup;
  public submitted: boolean;
  public responseExportError: string;
  public warehouseList$: Observable<SelectWarehouseList[]> = this.store.select(selectWarehouseList);
  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly formBuilder: FormBuilder,
    protected readonly translate: TranslateService,
    protected readonly productAssortmentService: ProductAssortmentService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new SelectWarehouseListRequestAction({ isWarehouse: true }));
    this.exportFormInValid = true;
    this.responseExportError = null;

    this.initControl();
  }

  initControl() {
    this.exportForm = this.formBuilder.group({
      code: [null]
    });
  }

  get form() {
    return this.exportForm.controls;
  }

  get isExportFormValid() {
    return !!this.exportForm.controls.code.value;
  }

  closeExportModal() {
    this.exportForm.reset();
    this.dcAssortmentModal.hide();
  }

  onExport() {
    this.submitted = true;
    if (!this.isExportFormValid) {
      return;
    }

    this.responseExportError = null;
    const dcAssortmentExportCriteria = new DcAssortmentExportCriteria({
      code: this.exportForm.controls.code.value
    });

    this.productAssortmentService.exportDcAssortment(dcAssortmentExportCriteria).subscribe(
      response => {
        const blob = new Blob([response]);
        saveAs(blob, `${environment.fileName.exportDcAssortmentConfigs.prefix} ${this.timeToExport}.xlsx`);
      },
      error => {
        this.responseExportError = error.error.message || this.translate.instant(error.error.translateKey);
      },
      () => {
        this.submitted = false;
        this.closeExportModal();
      }
    );
  }

  get timeToExport(): string {
    return moment().format(environment.fileName.exportDcAssortmentConfigs.timeFormat);
  }

  openExportModal() {
    this.exportForm.reset();
    this.responseExportError = null;
    this.exportFormInValid = true;
    this.dcAssortmentModal.show();
  }
}
