import { createSelector } from '@ngrx/store';
import * as fromStoreAssortmentListResponseState from '../reducers/store-assortment.reducers';
import { AppStates } from '../state/app.states';

const selectStoreAssortmentListState = (state: AppStates) => state.storeAssortment;

export const selectAllStoreAssortmentList = createSelector(
  selectStoreAssortmentListState,
  fromStoreAssortmentListResponseState.selectAll
);

export const selectStoreAssortmentList = createSelector(
  selectStoreAssortmentListState,
  (state: fromStoreAssortmentListResponseState.StoreAssortmentState) => state
);

export const selectStoreAssortmentListCriteria = createSelector(selectStoreAssortmentListState, state => {
  return state.criteriaObject;
});

export const selectStoreAssortmentListHistory = createSelector(
  selectStoreAssortmentListState,
  (state: fromStoreAssortmentListResponseState.StoreAssortmentState) => {
    return state.auditLogs;
  }
);

export const selectStoreAssortmentById = createSelector(
  selectStoreAssortmentListState,
  (state: fromStoreAssortmentListResponseState.StoreAssortmentState) => state.selected
);
