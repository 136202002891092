import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CommonUploadService {
  constructor(private readonly http: HttpClient, private readonly logger: NGXLogger) {}

  public upload(files: File[], uploadUrl: string, uploadHeaders: any): Array<CommonUploadRequest> {
    const headers: HttpHeaders = new HttpHeaders(uploadHeaders);

    // this will be our resulting map
    const status: Array<CommonUploadRequest> = new Array<CommonUploadRequest>();

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', uploadUrl, formData, {
        reportProgress: true,
        headers
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();
      const response = new Subject<any>();

      // send the http-request and subscribe for progress-updates
      this.http
        .request(req)
        .pipe(
          map(event => {
            if (event.type === HttpEventType.UploadProgress) {
              // calculate the progress percentage
              const percentDone = Math.round((100 * event.loaded) / event.total);

              // pass the percentage into the progress-stream
              progress.next(percentDone);
            } else if (event instanceof HttpResponse) {
              if (event.status && event.status === 200) {
                response.next(event.body);
                response.complete();
              } else {
                response.complete();
              }
              // Close the progress-stream if we get an answer-form the API
              // The upload is complete
              progress.complete();
            }
          }),
          catchError(err => {
            this.logger.debug('error upload service', err);
            response.error(err);
            return err;
          })
        )
        .subscribe(
          next => this.logger.debug('Next upload service', next),
          error1 => this.logger.debug('Error upload service ', error1),
          () => this.logger.debug('Complete upload service')
        );

      // Save every progress-observable in a map of all observables
      status.push({
        progress: progress.asObservable(),
        response: response.asObservable()
      } as CommonUploadRequest);
    });

    // return the map of progress.observables
    return status;
  }
}

export class CommonUploadRequest {
  progress: Observable<number>;
  response: Observable<any>;
}
