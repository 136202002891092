import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PromotionCommonError, PromotionTheme } from '../../models';
import { PromotionAction, PromotionActionTypes } from '../actions/promotion.action';
import { BaseState } from '../state/base.state';

export interface PromotionThemeState extends EntityState<PromotionTheme>, BaseState {
  isLoading: boolean;
  themesActive: PromotionTheme[];
  themesComing: PromotionTheme[];
  isAddSuccess: boolean;
  error: PromotionCommonError;
}

export const adapter: EntityAdapter<PromotionTheme> = createEntityAdapter<PromotionTheme>();

export const initialPromotionThemeState: PromotionThemeState = adapter.getInitialState({
  isLoading: false,
  themesActive: null,
  themesComing: null,
  isAddSuccess: false,
  error: null
});

export function promotionThemeReducers(
  state = initialPromotionThemeState,
  action: PromotionAction
): PromotionThemeState {
  switch (action.type) {
    case PromotionActionTypes.PROMOTION_THEME_LIST_COMING_RESPONSE:
      return {
        ...state,
        themesComing: action.payload
      };
    case PromotionActionTypes.PROMOTION_THEME_LIST_ACTIVE_RESPONSE:
      return {
        ...state,
        themesActive: action.payload
      };
    case PromotionActionTypes.PROMOTION_THEME_ADD_SUCCESS:
      return {
        ...state,
        isAddSuccess: !!action.payload.id
      };
    case PromotionActionTypes.PROMOTION_THEME_ADD_ERROR:
      return {
        ...state,
        error: action.payload,
        isAddSuccess: false
      };
    case PromotionActionTypes.PROMOTION_THEME_ADD_RESET:
      return initialPromotionThemeState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
