import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { flatten, merge } from 'lodash';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { ArticleTypeEnum } from '../../../shared/enum/article-type.enum';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { AlertModalComponent } from '../../../shared/layouts';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AssortmentAllType,
  ProductsContent,
  ProductsSearchCriteria,
  ProductsSelectStatusRequest
} from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService, ProductService } from '../../../shared/services';
import { MasterService } from '../../../shared/services/master.service';
import {
  ProductListCheckAction,
  ProductListCheckAllAction,
  ProductListCheckResetAction,
  ProductListRequestAction,
  ProductListSelectStatusRequestAction,
  ProductListSelectStatusResetDelete,
  ProductListUnCheckAction,
  ProductListUnCheckAllAction
} from '../../../shared/store/actions/product.action';
import { ProductState } from '../../../shared/store/reducers/product.reducers';
import {
  selectProducts,
  selectProductsCheckList,
  selectProductsResult,
  selectStatusUpdate
} from '../../../shared/store/selectors/product-selector';
import { AppStates } from '../../../shared/store/state/app.states';
import {
  dateStringToTagCriteria,
  dateToStringCriteria,
  generateDateStringTag
} from '../../../shared/utils/date-util';
import { ProductViewComponent } from '../product-view/product-view.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent extends BaseSearchComponent<ProductsSearchCriteria, ProductsContent, ProductState>
  implements OnInit, OnDestroy {
  headerRow: string[];
  selectedAllPage: any;
  totalSelectedCount: number;
  submitFromFilter: boolean;
  checkAll: boolean;
  checkedList: any;
  selectedProductType: ProductTypeEnum;
  currentActionStatus: string;
  requireSelectProductTypeError = false;
  assortmentAllType = AssortmentAllType;

  dateTag: string;
  dateStringTag: string;
  requestClassCodeTag: string;

  productsMasterStatus = filterDropdown.productsMasterStatus;
  productTypes: NgOption[] = filterDropdown.productTypeNonMerLOV;
  productsMasterClassCode: Array<{ [key: string]: string }>;
  productsResult: ProductsContent[];

  productsChangeStatusRequest: ProductsSelectStatusRequest;

  allowEditPermissions = ['tdassort_inv_m'];

  @ViewChild('successModal', { static: false }) successModal: ModalDirective;
  @ViewChild('confirmChangeStatusModal', { static: false }) confirmChangeStatusModal: ModalDirective;
  @ViewChild('modalSelectProductType', { static: false }) modalSelectProductType: ModalDirective;

  public bsModalRef: BsModalRef;

  private localStore: Observable<any>;

  constructor(
    protected readonly store: Store<AppStates>,
    protected fb: FormBuilder,
    protected readonly modalService: BsModalService,
    protected authGuardService: AuthGuardService,
    protected masterService: MasterService,
    protected productService: ProductService
  ) {
    super(store, modalService, selectProductsResult, selectProducts);
  }

  ngOnDestroy() {
    this.store.dispatch(new ProductListCheckResetAction());
    this.unsubscribeBase();
  }

  onSubmit() {
    if (this.searchForm.invalid) {
      return;
    }

    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: this.searchForm.controls['searchCriteria'].value,
      selectStatus: this.searchForm.controls['selectStatus'].value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {
    this.requestClassCodeTag = null;
    this.dateTag = null;
    this.dateStringTag = null;

    const beginImportDate = dateStringToTagCriteria(this.criteriaObject.beginImportDate);
    const endImportDate = dateStringToTagCriteria(this.criteriaObject.endImportDate);
    const importDate = generateDateStringTag({
      dateName: 'Imported Date',
      dateFrom: beginImportDate,
      dateTo: endImportDate
    });

    this.dateStringTag = importDate.dateStringTag;
    this.dateTag = importDate.dateTag;

    if (this.criteriaObject.classCode && this.criteriaObject.classCode.length) {
      const classCodeList = this.criteriaObject.classCode
        .map(classCode => this.productsMasterClassCode.find(obj => obj.code === classCode).nameEn)
        .join(', ');

      this.requestClassCodeTag = `"${classCodeList}"`;
    }
  }

  onAdvanceSubmit() {
    const formValue = this.searchForm.value;
    let beginImportDate = this.searchForm.value.beginImportDate;
    let endImportDate = this.searchForm.value.endImportDate;

    if (!formValue.beginImportDate && !formValue.endImportDate && !formValue.type && !formValue.classCode) {
      return;
    }

    this.setFirstPage();

    if (beginImportDate && !isNaN(beginImportDate.getTime())) {
      beginImportDate = dateToStringCriteria(beginImportDate);
    } else {
      beginImportDate = null;
    }

    if (endImportDate && !isNaN(endImportDate.getTime())) {
      endImportDate = dateToStringCriteria(endImportDate, false);
    } else {
      endImportDate = null;
    }

    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      beginImportDate,
      endImportDate,
      classCode: formValue.classCode
    };
    this.search(this.criteriaObject);
    this.isShowAdvanceSearch = false;
  }

  clearAdvanceFilter() {
    this.setFirstPage();
    this.searchForm.controls['beginImportDate'].reset();
    this.searchForm.controls['endImportDate'].reset();
    this.searchForm.controls['classCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      beginImportDate: null,
      endImportDate: null,
      classCode: null
    };
    this.search(this.criteriaObject);
  }

  clearRequestClass() {
    this.setFirstPage();
    this.searchForm.controls['classCode'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      classCode: null
    };
    this.search(this.criteriaObject);
  }

  clearDate() {
    this.setFirstPage();
    this.searchForm.controls['beginImportDate'].reset();
    this.searchForm.controls['endImportDate'].reset();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0,
      beginImportDate: null,
      endImportDate: null
    };
    this.search(this.criteriaObject);
  }

  onChangeStatus(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      selectStatus: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onClickedOutside(e) {
    const target = e.target;

    if (!target) {
      return;
    } else if (
      target.classList.contains('is-highlighted') ||
      target.classList.contains('ng-option') ||
      target.classList.contains('ng-value-icon') ||
      target.classList.contains('ng-option-label')
    ) {
      return;
    }

    this.isShowAdvanceSearch = false;
  }

  onChangeDateFrom(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.minDate = new Date(value);
    } else {
      this.minDate = new Date(2019, 0, 1);
    }
  }

  onChangeDateTo(value: Date): void {
    if (value && !isNaN(value.getTime())) {
      this.maxDate = new Date(value);
    } else {
      this.maxDate = new Date();
      this.maxDate.setDate(this.maxDate.getDate() + 365);
    }
  }

  onClickCheckBox(event: any, product) {
    if (event.target.checked) {
      this.store.dispatch(new ProductListCheckAction(product));
    } else {
      this.store.dispatch(new ProductListUnCheckAction(product));
      this.selectedAllPage[this.currentPage] = false;
    }
  }

  onClickCheckAllBox(event) {
    if (event.target.checked) {
      this.store.dispatch(new ProductListCheckAllAction());
      this.selectedAllPage[this.currentPage] = true;
    } else {
      this.store.dispatch(new ProductListUnCheckAllAction());
      this.selectedAllPage[this.currentPage] = false;
    }
  }

  onChangeProductType() {
    this.requireSelectProductTypeError = false;
  }

  checkAtLeastOneProduct(): boolean {
    if (!Object.keys(this.checkedList).length) {
      const msg = 'Please select at least 1 product.';
      this.showAlertModal(msg);
      return false;
    }
    return true;
  }

  checkValidSelectedProduct() {
    if (!this.checkAtLeastOneProduct()) {
      return;
    }

    const isNonMerchandiseCheck = Object.values(this.checkedList).some((item: any) => item.merchandise === false);
    const isManageAssetPermission = this.authGuardService.checkPermission(['tdassort_asset_m']);
    const isManageStoreUsePermission = this.authGuardService.checkPermission(['tdassort_sto_m']);

    if (isNonMerchandiseCheck) {
      if (isManageAssetPermission && !isManageStoreUsePermission) {
        this.selectedProductType = ProductTypeEnum.FIX_ASSET;
        this.showConfirmChangeStatusModal('SELECTED');
      } else if (isManageStoreUsePermission && !isManageAssetPermission) {
        this.selectedProductType = ProductTypeEnum.STORE_USE;
        this.showConfirmChangeStatusModal('SELECTED');
      } else if (isManageAssetPermission && isManageStoreUsePermission) {
        this.modalSelectProductType.show();
      }
    } else {
      this.showConfirmChangeStatusModal('SELECTED');
    }
  }

  maskAsTdUnused() {
    if (!this.checkAtLeastOneProduct()) {
      return;
    }
    this.showConfirmChangeStatusModal('UNUSED');
  }

  showConfirmChangeStatusModal(action: string) {
    if (this.modalSelectProductType.isShown && this.selectedProductType) {
      this.modalSelectProductType.hide();
      this.requireSelectProductTypeError = false;
    } else if (this.modalSelectProductType.isShown && !this.selectedProductType) {
      this.requireSelectProductTypeError = true;
      return;
    }
    this.currentActionStatus = action;
    const validateLst = [];
    Object.values(this.checkedList).forEach((checked: any) => {
      if (!checked.readyToImport) {
        validateLst.push(checked.articleNo);
      }
    });

    if (!(Boolean(validateLst) && validateLst.length > 0 && this.currentActionStatus === 'SELECTED')) {
      this.confirmChangeStatusModal.show();
    } else {
      const articleNoMsg = validateLst.join(', ');
      const msg = `Incomplete data. <br/> These articles: ${articleNoMsg}`;

      this.showAlertModal(msg);
      return;
    }
  }

  confirmChangeStatus() {
    this.productsChangeStatusRequest = {
      selectStatus: this.currentActionStatus.toLocaleUpperCase(),
      articleNos: Object.keys(this.checkedList),
      productType: this.selectedProductType
    };
    this.store.dispatch(new ProductListSelectStatusRequestAction(this.productsChangeStatusRequest));
  }

  showAlertModal(message) {
    this.modalService.show(AlertModalComponent, {
      initialState: {
        title: 'Failed',
        message
      }
    });
  }

  getColorStatus(status: string): string {
    switch (status) {
      case 'NEW':
        return 'new';
      case 'SELECTED':
        return 'used';
      case 'UNUSED':
        return 'not-used';
      default:
        return '';
    }
  }

  goToView(product) {
    const initialState = {
      childItem: new ChildItem(
        ProductViewComponent,
        { title: 'View CJ Product Master', id: product.id, articleNo: product.articleNo },
        false
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState
    });
  }

  doAfterVersionAlertModal() {
    this.store.dispatch(new ProductListRequestAction(this.criteriaObject));
  }

  search(criteriaObj) {
    this.prepareSearchCriteriaTags();
    this.store.dispatch(new ProductListRequestAction(criteriaObj));
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null, Validators.required],
      selectStatus: [this.productsMasterStatus[0].value],
      beginImportDate: [null],
      endImportDate: [null],
      classCode: null
    });
  }

  setInitialValue() {
    this.headerRow = [
      'CJ_PRODUCT.ARTICLE_NO',
      'CJ_PRODUCT.PRODUCT_NAME',
      'CJ_PRODUCT.CLASS',
      'CJ_PRODUCT.SUPPLIER',
      'CJ_PRODUCT.STATUS_CJ',
      'CJ_PRODUCT.IMPORTED_DATE',
      'CJ_PRODUCT.STATUS'
    ];
    this.productsResult = null;
    this.checkAll = false;
    this.submitFromFilter = false;
    this.isShowAdvanceSearch = false;
    this.checkedList = {};
    this.selectedAllPage = { 1: false };
    this.totalSelectedCount = 0;
    this.pageSize = 20;
    this.isShowAdvanceSearch = false;
    this.minDate = new Date(2019, 0, 1);
    this.maxDate = new Date();
    this.maxDate.setDate(this.maxDate.getDate() + 365);
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: '',
      beginImportDate: '',
      endImportDate: '',
      selectStatus: '',
      page: 0,
      size: 20,
      sortOrder: 'desc',
      sortBy: 'lastModifiedDate',
      classCode: null
    };
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));

    this.productService
      .getClassCodeData(ArticleTypeEnum.MERCHANDISE)
      .pipe(untilComponentDestroyed(this))
      .subscribe(result => {
        if (result.data) {
          this.productsMasterClassCode = flatten(
            flatten(
              merge(
                result.data.segments.map(segment =>
                  segment.families.map(family => family.classCodes.map(classCodes => classCodes))
                )
              )
            )
          );
        }
      });

    this.localStore.pipe(select(selectProductsCheckList)).subscribe(selectedState => {
      this.checkedList = {
        ...(Object.keys(selectedState).length && selectedState)
      };
      this.totalSelectedCount = Object.keys(selectedState).length;
    });

    this.localStore.pipe(select(selectProductsResult)).subscribe(result => {
      this.productsResult = result;
    });

    this.localStore
      .pipe(
        select(selectStatusUpdate),
        filter(data => Boolean(data))
      )
      .subscribe(data => {
        let msg;
        let articleNoMsg;
        if ((data.duplicate && data.duplicate.length) || (data.failure && data.failure.length)) {
          articleNoMsg =
            data.duplicate && data.duplicate.length ? data.duplicate.join(', ') : data.failure.join(', ');
          msg = `Duplicate with existing TD Product. <br/> These articles: ${articleNoMsg}`;
          this.showAlertModal(msg);
        } else if (data.incomplete && data.incomplete.length) {
          articleNoMsg = data.incomplete.join(', ');
          msg = `Incomplete Data. <br/> These articles: ${articleNoMsg}`;
          this.showAlertModal(msg);
        } else {
          this.successModal.show();
        }

        this.confirmChangeStatusModal.hide();
        this.selectedAllPage = {};
        this.store.dispatch(new ProductListSelectStatusResetDelete());
      });
  }

  doSearch() {
    this.search(this.criteriaObject);
  }

  setRouteTab() {}

  hideModalSelectProductType() {
    this.selectedProductType = null;
    this.requireSelectProductTypeError = false;
    this.modalSelectProductType.hide();
  }

  doDestroy() {
    this.store.dispatch(new ProductListCheckResetAction());
  }
}
