import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ShelfDetailRequest } from '../../models';
import { ShelfDetailsActions, ShelfDetailsActionType } from '../actions/shelf-details.actions';
import { BaseState } from '../state/base.state';

export interface ShelfDetailsState extends EntityState<ShelfDetailRequest>, BaseState {
  isLoading: boolean;
}

export const adapter: EntityAdapter<ShelfDetailRequest> = createEntityAdapter<ShelfDetailRequest>({
  selectId: (shelfDetail: ShelfDetailRequest) => shelfDetail.shelfNo
});

export const initialShelfDetailsResponseState: ShelfDetailsState = adapter.getInitialState({
  isLoading: false
});

export function ShelfDetailsReducers(
  state = initialShelfDetailsResponseState,
  action: ShelfDetailsActions
): ShelfDetailsState {
  switch (action.type) {
    case ShelfDetailsActionType.SHELF_DETAILS_RESPONSE:
      return adapter.upsertMany(action.payload, {
        ...state
      });
    case ShelfDetailsActionType.SHELF_DETAILS_RESET:
      return adapter.removeAll({
        ...state
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
