import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { MerchantRequestService } from './merchant-request.service';
import { ShelfTypesService } from './shelf-types.service';

@Injectable({
  providedIn: 'root'
})
export class FileUrlService {
  public env = environment;

  constructor(
    private readonly merchantRequestService: MerchantRequestService,
    private readonly shelfTypeService: ShelfTypesService
  ) {}

  public merchantRequest = refId => this.merchantRequestService.getFileUrl(refId);
  public shelfType = refId => this.shelfTypeService.getFileUrl(refId);
}
