import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgressStep } from '../../models/progress-step.model';

@Component({
  selector: 'app-step-progress',
  templateUrl: './step-progress.component.html',
  styleUrls: ['./step-progress.component.scss']
})
export class StepProgressComponent implements OnInit, OnDestroy {
  @Input() items: ProgressStep[];
  @Input() selectedStepValue: string;

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit() {}
}
