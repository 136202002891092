export enum TDStorePage {
  MERCHANT_REQUEST = 'Merchant Request',
  MERCHANT_EDIT = 'Merchant List',
  STORE_REQUEST = 'Store Request',
  STORE_EDIT = 'Store List',
  STORE_EDIT_ORDER_SCHEDULE = 'Store Edit Order Schedule'
}

export enum ShelfPages {
  SHELF_REQUEST = 'Shelf Request',
  SHELF_EDIT = 'Shelf List'
}
