import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { RequestPageModesEnum } from '../../../shared/enum/request-step.enum';
import { SupplierGroupModeEnum } from '../../../shared/enum/supplier-group.enum';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import {
  AssortmentPageModes,
  MyTaskContent,
  MyTaskSearchCriteria,
  PortalModule,
  PromotionRequestPageModes,
  RouteLinkTab,
  TaskModuleUrl,
  VoucherRequestPageModes
} from '../../../shared/models';
import * as filterDropdown from '../../../shared/models/list-value/list-key-value.model';
import { AuthGuardService } from '../../../shared/services';
import { TasksByRoleListRequestAction } from '../../../shared/store/actions/dashboard.actions';
import { MyTaskState } from '../../../shared/store/reducers/my-task.reducers';
import {
  selectAllMyTaskList,
  selectMyTaskList,
  selectMyTaskListCriteria
} from '../../../shared/store/selectors/my-task.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { ModuleUtil } from '../../../shared/utils/module-util';
import { AssortmentRequestComponent } from '../../assortment-request/assortment-request/assortment-request.component';
import { ClaimRequestViewComponent } from '../../claim-request/claim-request-view/claim-request-view.component';
import { MerchantRequestComponent } from '../../merchant/merchant-request/merchant-request.component';
import { OrderRequestListComponent } from '../../order/order-request-list/order-request-list.component';
import { OrderRequestComponent } from '../../order/order-request/order-request.component';
import { PromotionRequestComponent } from '../../promotion/promotion-request/promotion-request.component';
import { PurchaseRequestViewComponent } from '../../purchase/purchase-request-view/purchase-request-view.component';
import { ShelfRequestListComponent } from '../../shelf/shelf-request-list/shelf-request-list.component';
import { ShelfRequestComponent } from '../../shelf/shelf-request/shelf-request.component';
import { StoreRequestComponent } from '../../store/store-request/store-request.component';
import { SupplierGroupRequestViewComponent } from '../../supplier/supplier-group-request-view/supplier-group-request-view.component';
import { VoucherRequestComponent } from '../../voucher/voucher-request/voucher-request.component';

@Component({
  selector: 'app-my-task',
  templateUrl: './my-task.component.html',
  styleUrls: ['./my-task.component.scss']
})
export class MyTaskComponent extends BaseSearchComponent<MyTaskSearchCriteria, MyTaskContent, MyTaskState>
  implements OnInit {
  listRoute: Array<RouteLinkTab>;
  dateFormat = environment.dateFormat;

  public currentPage: number;
  public portalModuleList = filterDropdown.portalModuleList;

  private localStore: Observable<any>;
  private bsModalRef: BsModalRef;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService,
    protected readonly logger: NGXLogger
  ) {
    super(store, modalService, selectAllMyTaskList, selectMyTaskList);
    super.subscribeForSaveSuccess();
  }

  doInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectMyTaskListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      searchCriteria: null,
      page: 0,
      size: 20
    };
  }

  setInitialValue() {
    this.pageSize = 20;
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null],
      module: [this.portalModuleList[0].value]
    });
  }

  onAdvanceSubmit() {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  onChangeModule(event: any) {
    this.setFirstPage();
    this.criteriaObject = {
      ...this.criteriaObject,
      module: event.value,
      page: 0
    };
    this.search(this.criteriaObject);
  }

  prepareSearchCriteriaTags() {}

  search(criteriaObj: any) {
    this.store.dispatch(new TasksByRoleListRequestAction(criteriaObj));
    return;
  }

  goToView(viewParams: MyTaskContent) {
    if (!viewParams) {
      return null;
    }

    const valueItem = { ...viewParams, ...(viewParams.requestInfo || { requestInfo: null }) };
    let initialState = null;
    switch (viewParams.module) {
      case PortalModule.PURCHASE_REQUEST:
        initialState = ModuleUtil.InitialState(
          valueItem,
          PurchaseRequestViewComponent,
          'VIEW',
          'View PR',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.PROMOTION:
        initialState = ModuleUtil.InitialState(
          valueItem,
          PromotionRequestComponent,
          PromotionRequestPageModes.REQUEST_VIEW,
          'View Promotion Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.CLAIM:
        initialState = ModuleUtil.InitialState(
          valueItem,
          ClaimRequestViewComponent,
          '',
          'Approve Claim Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.VOUCHER:
        initialState = ModuleUtil.InitialState(
          valueItem,
          VoucherRequestComponent,
          VoucherRequestPageModes.REQUEST_VIEW,
          'View Voucher Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.MERCHANT:
        initialState = ModuleUtil.InitialState(
          valueItem,
          MerchantRequestComponent,
          RequestPageModesEnum.REQUEST_VIEW,
          'View Merchant Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.STORE:
        initialState = ModuleUtil.InitialState(
          valueItem,
          StoreRequestComponent,
          RequestPageModesEnum.REQUEST_VIEW,
          'View Store Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.SUPPLIER_GROUP:
        initialState = ModuleUtil.InitialState(
          valueItem,
          SupplierGroupRequestViewComponent,
          SupplierGroupModeEnum.REQUEST_VIEW,
          'View Supplier Group Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.SHELF_REQUEST:
        initialState = ModuleUtil.InitialState(
          valueItem,
          ShelfRequestComponent,
          RequestPageModesEnum.REQUEST_VIEW,
          ShelfRequestListComponent.getShelfRequestTitle(RequestPageModesEnum.REQUEST_VIEW),
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.PRODUCT:
        initialState = ModuleUtil.InitialState(
          valueItem,
          AssortmentRequestComponent,
          AssortmentPageModes.REQUEST_VIEW,
          'View Product Request',
          TaskModuleUrl.MY_TASKS
        );
        break;
      case PortalModule.ORDER:
        initialState = ModuleUtil.InitialState(
          valueItem,
          OrderRequestComponent,
          RequestPageModesEnum.REQUEST_VIEW,
          OrderRequestListComponent.getOrderRequestTitle(RequestPageModesEnum.REQUEST_VIEW),
          TaskModuleUrl.MY_TASKS
        );
        break;
      default:
        this.logger.error(`fnGoToView: MyTaskContent don't support this ${JSON.stringify(viewParams)}.`);
        break;
    }
    if (initialState) {
      this.bsModalRef = this.modalService.show(FullModalComponent, {
        animated: false,
        backdrop: false,
        initialState,
        keyboard: false
      });
    }
  }

  doAfterVersionAlertModal() {
    this.doAfterSuccessModal();
  }

  doAfterSuccessModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  setRouteTab() {
    this.listRoute.push({ tabName: 'My Task', url: '/dashboard/my-task' });
  }

  getDisplayStatus(item: MyTaskContent) {
    let preRequestStatus;

    switch (item.module) {
      case PortalModule.PURCHASE_REQUEST:
        preRequestStatus = 'PURCHASE_REQUEST.PURCHASE_REQUEST_STATUS.';
        break;
      case PortalModule.PRODUCT:
        preRequestStatus = 'PRODUCT_ASSORTMENT.STATUS.';
        break;
      case PortalModule.PROMOTION:
        preRequestStatus = item.module + '.STATUS.';
        break;
      case PortalModule.CLAIM:
        preRequestStatus = 'CLAIM_REQUEST.CLAIM_REQUEST_STATUS.';
        break;
      case PortalModule.VOUCHER:
        preRequestStatus = item.module + '.STATUS.';
        break;
      case PortalModule.MERCHANT:
        preRequestStatus = 'MERCHANT_REQUEST.STATUS.';
        break;
      case PortalModule.STORE:
        preRequestStatus = 'STORE_REQUEST.STATUS.';
        break;
      case PortalModule.SUPPLIER_GROUP:
        preRequestStatus = 'SUPPLIER_GROUP_REQUEST.SUPPLIER_GROUP_REQUEST_STATUS.';
        break;
      case PortalModule.SHELF_REQUEST:
        preRequestStatus = item.module + '.STATUS.';
        break;
      case PortalModule.ORDER:
        preRequestStatus = 'ORDER_REQUEST.STATUS.';
        break;
      default:
        this.logger.error(`fnGetDisplayStatus: MyTaskContent don't support this ${JSON.stringify(item)}.`);
        break;
    }

    return preRequestStatus ? `${preRequestStatus}${item.requestStatus}` : '';
  }

  get hasSearchCriteria(): boolean {
    return !!(this.criteriaObject && (this.criteriaObject.searchCriteria || this.criteriaObject.module));
  }

  doDestroy() {}
}
