<!-- Modal Product Section -->
<div
  id="promotionCompensateModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #promotionCompensateModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Promotion Compensate Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="form-group col-md-12">
                      <div class="form-row">
                        <div class="col-12">Effective Date<span class="text-danger">*</span></div>
                        <div class="col-md-6">
                          <label>{{ 'FROM' | translate }}</label>
                          <input
                            id="effectiveDateFrom"
                            formControlName="effectiveDateFrom"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [bsConfig]="bsDateConfig"
                            [maxDate]="maxDate"
                            (bsValueChange)="onChangeExportEffectiveDateFrom($event)"
                            readonly
                          />
                          <label for="effectiveDateFrom" class="icon-calendar btn-calendar"></label>
                        </div>
                        <div class="col-md-6">
                          <label>{{ 'TO' | translate }}</label>
                          <input
                            id="effectiveDateTo"
                            formControlName="effectiveDateTo"
                            type="text"
                            placeholder="{{ dateFormat }}"
                            autocomplete="off"
                            class="form-control calendar"
                            bsDatepicker
                            [minDate]="minDate"
                            [bsConfig]="bsDateConfig"
                            (bsValueChange)="onChangeExportEffectiveDateTo($event)"
                            readonly
                          />
                          <label for="effectiveDateTo" class="icon-calendar btn-calendar"></label>
                        </div>
                      </div>
                    </div>

                    <div class="form-group col-md-12">
                      <div class="mb-2">
                        Store Type
                      </div>
                      <ng-select
                        [items]="storeTypeList"
                        placeholder="Please select..."
                        [searchable]="false"
                        [clearable]="false"
                        bindLabel="nameTh"
                        bindValue="code"
                        formControlName="storeType"
                      >
                      </ng-select>
                    </div>

                    <div class="form-group  col-md-12">
                      <div class="mb-2">
                        Supplier
                      </div>
                      <ng-select
                        [items]="supplierList | async"
                        placeholder="Please select..."
                        notFoundText="No Data"
                        formControlName="supplier"
                        dropdownPosition="auto"
                        [virtualScroll]="true"
                        [loading]="supplierSearchLoading"
                        [typeahead]="supplierSearchInput$"
                      >
                        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
                          <div>
                            <strong>Supplier Name:</strong>
                            <span [ngOptionHighlight]="search" class="ml-1">
                              {{ item.supplierName }}
                            </span>
                          </div>
                          <small>
                            <strong>Vendor No:</strong
                            ><span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span
                            >{{ ' | ' }} <strong>Branch No:</strong
                            ><span [ngOptionHighlight]="search" class="ml-1">{{ item.branchNo }}</span>
                          </small>
                        </ng-template>

                        <ng-template ng-label-tmp let-item="item">
                          {{ item.supplierNameDisplay }}
                        </ng-template>
                      </ng-select>
                    </div>

                    <div class="form-group  col-md-12">
                      <div class="mb-2">
                        Product Class
                      </div>
                      <ng-select
                        [items]="productClassList"
                        placeholder="Please select..."
                        [searchable]="false"
                        [clearable]="false"
                        [multiple]="true"
                        bindLabel="nameTh"
                        bindValue="code"
                        formControlName="productClass"
                      >
                      </ng-select>
                    </div>

                    <div *ngIf="responseExportError" class="alert-danger form-group mb-0 w-100">
                      {{ responseExportError }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="export_sales_transaction_report_btn"
            [disabled]="exportFormInValid || this.exportForm.invalid"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Modal Product Section -->
