import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { StackPricingService } from '../../services/stack-pricing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess } from '../actions/layout.action';
import { StackPricingActionTypes, StackPricingHistoryLoaded, StackPricingSearchLoaded, StackPricingViewLoaded } from '../actions/stack-pricing.actions';
export class StackPricingEffects {
    constructor(actions$, stackPricingService, logger) {
        this.actions$ = actions$;
        this.stackPricingService = stackPricingService;
        this.logger = logger;
        this.searchStackPricing$ = this.actions$.pipe(ofType(StackPricingActionTypes.StackPricingSearchRequested), tap(action => this.logger.debug('@Effect Stack Pricing Search: ' + JSON.stringify(action.payload))), switchMap(action => this.stackPricingService.searchByCriteria(action.payload).pipe(map(stackPricings => new StackPricingSearchLoaded({ stackPricings })), catchError(error => of(new LayoutActionLoadError(error))))));
        this.viewStackPricing$ = this.actions$.pipe(ofType(StackPricingActionTypes.StackPricingViewRequested), tap(action => this.logger.debug('@Effect StackPricingViewRequested: ' + JSON.stringify(action.payload))), switchMap(action => this.stackPricingService.getStackPricingByID(action.payload.articleNo).pipe(map(stackPricingView => new StackPricingViewLoaded({ stackPricingView })), catchError(err => of(new LayoutActionLoadError(err))))));
        this.submitStackPricing$ = this.actions$.pipe(ofType(StackPricingActionTypes.StackPricingViewSubmitRequested), tap(action => this.logger.debug('@Effect StackPricingView Submit: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stackPricingService.submit(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The request has been saved.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.deleteStackPricing$ = this.actions$.pipe(ofType(StackPricingActionTypes.StackPricingDeleteRequested), tap(action => this.logger.debug('@Effect StackPricingView Delete: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stackPricingService.deleteStackPricingByID(action.payload).pipe(map(() => new LayoutActionSaveSuccess({
                isSuccess: true,
                title: 'Success',
                message: 'The new stack pricing has been deleted.'
            })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
        this.historyStackPricing$ = this.actions$.pipe(ofType(StackPricingActionTypes.StackPricingHistoryRequested), tap(action => this.logger.debug('@Effect StackPricingView Delete: ' + JSON.stringify(action.payload))), switchMap(action => {
            return this.stackPricingService.getHistoryByID(action.payload.articleNo).pipe(map(response => new StackPricingHistoryLoaded({ auditLogs: response.auditLogs })), catchError(error => of(new LayoutActionLoadError(error))));
        }));
    }
}
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StackPricingEffects.prototype, "searchStackPricing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StackPricingEffects.prototype, "viewStackPricing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StackPricingEffects.prototype, "submitStackPricing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StackPricingEffects.prototype, "deleteStackPricing$", void 0);
tslib_1.__decorate([
    Effect(),
    tslib_1.__metadata("design:type", Object)
], StackPricingEffects.prototype, "historyStackPricing$", void 0);
