import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  ReceiveOrderList,
  ReceiveOrderListSearchCriteria,
  ReceiveOrderViewResponse
} from '../../models/receive-order.model';
import { ReceiveOrderActions, ReceiveOrderActionType } from '../actions/receive-order.actions';
import { BaseState, initialCriteriaObject } from '../state/base.state';

export interface ReceiveOrderState extends EntityState<ReceiveOrderList>, BaseState {
  selected: ReceiveOrderViewResponse;
  criteriaObject: ReceiveOrderListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<ReceiveOrderList> = createEntityAdapter<ReceiveOrderList>({
  selectId: (payload: ReceiveOrderList) => payload.receiveOrderNo
});

export const initialOrderResponseState: ReceiveOrderState = adapter.getInitialState({
  selected: null,
  criteriaObject: initialCriteriaObject,
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  shelfList: null,
  shelfDetails: []
});

export function receiveOrderReducers(
  state = initialOrderResponseState,
  action: ReceiveOrderActions
): ReceiveOrderState {
  switch (action.type) {
    case ReceiveOrderActionType.RECEIVE_ORDER_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case ReceiveOrderActionType.RECEIVE_ORDER_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESPONSE:
      return {
        ...state,
        selected: action.payload
      };
    case ReceiveOrderActionType.RECEIVE_ORDER_GET_BY_NO_RESET:
      return {
        ...state,
        selected: null
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
