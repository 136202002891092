import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsDatepickerConfig, BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { ModalButtonResponseEnum } from '../../../../shared/enum/modal-button-response.enum';
import { NotificationTypeEnum } from '../../../../shared/enum/notification-type.enum';
import { PosRegisterModes } from '../../../../shared/enum/store.enum';
import { AlertModalComponent } from '../../../../shared/layouts';
import { Device, ErrorResponse } from '../../../../shared/models';
import { NotificationEmit } from '../../../../shared/models/notification-emit.model';
import { StoreService } from '../../../../shared/services/store.service';
import { StoreByIdRequestAction } from '../../../../shared/store/actions/store.actions';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-register-pos',
  templateUrl: './register-pos.component.html',
  styleUrls: ['./register-pos.component.scss']
})
export class RegisterPOSComponent implements OnInit, OnDestroy {
  @Input() storeNo: string;
  @Input() merchant: string;
  @Input() mode: PosRegisterModes;
  @Input() data: Device;
  @Output() notifyParent: EventEmitter<NotificationEmit> = new EventEmitter<NotificationEmit>();

  public isSavedDeliveryDetails = false;
  public bsConfig: BsDatepickerConfig;
  public form: FormGroup;
  public scheduleConfig: BsDatepickerConfig;
  public submitted: boolean;
  public viewMode: boolean;
  public title: string;

  private readonly localStore: Observable<any>;

  constructor(
    public bsModalRef: BsModalRef,
    protected readonly modalService: BsModalService,
    public fb: FormBuilder,
    protected readonly store: Store<AppStates>,
    private readonly storeService: StoreService,
    private readonly translate: TranslateService
  ) {}

  ngOnInit() {
    this.title =
      this.mode === PosRegisterModes.EDIT ? `Update POS Registration Code` : `Generate POS Registration Code`;
    this.createForm();
    this.setInitialValue();
  }

  ngOnDestroy() {}

  createForm() {
    const hexaDemicalRegex = '^(0x|0X)?[a-fA-F0-9]+$';
    this.form = this.fb.group({
      deviceId: [
        { value: null, disabled: false },
        [Validators.required, Validators.maxLength(16), Validators.pattern(hexaDemicalRegex)]
      ],
      registeredNo: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(20)]],
      serialNo: [{ value: null, disabled: false }, [Validators.required, Validators.maxLength(50)]]
    });
  }

  setInitialValue() {
    if (this.mode !== PosRegisterModes.EDIT && !this.data) {
      return;
    }

    this.form.get('deviceId').setValue(this.data.deviceId);
    this.form.get('serialNo').setValue(this.data.serialNo);
    this.form.get('registeredNo').setValue(this.data.registeredNo);
    this.form.get('deviceId').disable();
    this.form.get('serialNo').disable();
  }

  decline(): void {
    this.bsModalRef.hide();
  }

  confirm() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    const POSRegisteredData = this.form.getRawValue();

    if (this.mode === PosRegisterModes.EDIT) {
      this.storeService.updatePOSRegistrationCode({ storeNo: this.storeNo }, POSRegisteredData).subscribe(
        response => this.alertSuccessModal(response),
        error => {
          this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
          this.alertErrorModal(error);
        }
      );
    } else {
      this.storeService.generatePOSRegistrationCode({ storeNo: this.storeNo }, POSRegisteredData).subscribe(
        response => this.alertSuccessModal(response),
        error => {
          this.notifyParent.emit({ notificationType: NotificationTypeEnum.CANCEL, result: null });
          this.alertErrorModal(error);
        }
      );
    }
  }

  alertSuccessModal(registrationCode: string) {
    let message = `Your Registration Code is <strong>${registrationCode}</strong>`;

    if (this.mode === PosRegisterModes.EDIT) {
      message = `POS Registration Code has been updated`;
    }

    const initialState = {
      title: 'Success',
      message
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      backdrop: 'static',
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();

        this.store.dispatch(
          new StoreByIdRequestAction({
            merchant: { merchant: this.merchant },
            storeNo: { storeNo: this.storeNo }
          })
        );

        this.bsModalRef.hide();
      }
    });
  }

  alertErrorModal(errorResponse: any) {
    const error: ErrorResponse = errorResponse.error;
    const errorMessage =
      error.code === '07001'
        ? this.getDuplicatedErrorMessage(error)
        : this.translate.instant(error.translateKey, { context: error.message });

    const initialState = {
      title: 'Failed',
      message: errorMessage
    };

    const alertModal = this.modalService.show(AlertModalComponent, {
      initialState
    });

    alertModal.content.action.pipe(untilComponentDestroyed(this)).subscribe((result: ModalButtonResponseEnum) => {
      if (result === ModalButtonResponseEnum.OK) {
        alertModal.hide();
      }
    });
  }

  getDuplicatedErrorMessage(error: ErrorResponse): string {
    const regex = /^\[(.*)\]/g;
    const matched = regex.exec(error.message);

    if (matched && matched[1]) {
      const fieldsMessage = matched[1].replace(/\w+/g, field => {
        return this.translate.instant('STORE.POS_REGISTERED.' + field);
      });

      return this.translate.instant('ERRORS.DUPLICATED_FIELD', { value: fieldsMessage });
    }
    return null;
  }
}
