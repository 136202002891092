import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgOption } from '@ng-select/ng-select';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ProductTypeEnum } from '../../../shared/enum/product-type.enum';
import { UploadStatusEnum } from '../../../shared/enum/upload-status.enum';
import { PurchaseRequestFileUploadComponent } from '../purchase-request-file-upload/purchase-request-file-upload.component';

@Component({
  selector: 'app-purchase-request-modal-upload',
  templateUrl: './purchase-request-modal-upload.component.html',
  styleUrls: ['./purchase-request-modal-upload.component.scss']
})
export class PurchaseRequestModalUploadComponent implements OnInit, OnDestroy {
  @ViewChild(PurchaseRequestFileUploadComponent, { static: false })
  fileUpload: PurchaseRequestFileUploadComponent;

  @Output() submitUpload: EventEmitter<any> = new EventEmitter();

  public uploadUrl: string;
  public productType: ProductTypeEnum;
  public productTypeList: NgOption[];

  public uploadStatus = UploadStatusEnum;
  public form: FormGroup;
  public errors = [];
  public errorMessage: string;

  constructor(public bsModalRef: BsModalRef, public fb: FormBuilder, protected modalService: BsModalService) {}

  ngOnInit() {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.form.reset();
  }

  createForm() {
    const disableProductType = !!this.productType;
    const url = `${this.uploadUrl}&productType=${this.productType}`;
    this.form = this.fb.group({
      purchaseImport: [null],
      productType: [{ value: this.productType, disabled: disableProductType }],
      uploadUrl: [url]
    });

    this.subscriptionValueChange();
  }

  subscriptionValueChange() {
    this.form.controls['productType'].valueChanges.pipe(untilComponentDestroyed(this)).subscribe(value => {
      this.form.patchValue({
        uploadUrl: `${this.uploadUrl}&productType=${value}`
      });
    });
  }

  onSubmit() {
    if (!this.form.controls['purchaseImport'].value && !this.form.controls['purchaseImport'].value[0].length) {
      return;
    }
    this.submitUpload.emit({
      file: this.form.controls['purchaseImport'].value[0],
      productType: this.form.controls['productType'].value
    });
    this.bsModalRef.hide();
  }
}
