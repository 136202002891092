<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-td-bill"></em>
    {{ data.title }}
  </div>
  <button
    type="button"
    class="btn btn-header-button d-none d-md-block"
    (click)="onPrintProfitSharing()"
    *ngIf="data.mode === profitSharingModeEnum.VIEW"
  >
    <em class="icon-print"></em>Print
  </button>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onCloseFullModal()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="profit-sharing" class="admin-form mb-5 mb-sm-0" *ngIf="profitSharing && profitSharingForm">
  <div class="info-header-container" *ngIf="profitSharing?.profitStatus">
    <div class="info-header-item" *ngIf="profitSharing?.docNo">
      <div class="info-header-label">
        <span>Profit Sharing No.</span>
      </div>
      <div class="info-header-value">
        <span>{{ profitSharing?.docNo }}</span>
      </div>
    </div>
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Status</span>
      </div>
      <div class="info-header-value">
        <span class="profit-sharing-status" [ngClass]="getColorStatus(profitSharing?.profitStatus)">
          {{ 'PROFIT_SHARING.STATUS.' + profitSharing?.profitStatus | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="row justify-content-around px-3 px-md-0">
    <div class="information-container col-md-7 mr-3 mx-md-0">
      <div class="container-fluid px-0">
        <div class="row">
          <div class="col-lg-4 col-md-3 flex-column">
            <div class="info-header-label">
              <span>Store</span>
            </div>
            <div class="info-header-value">{{ profitSharing?.store.code }} - {{ profitSharing?.store.name }}</div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>As of Month</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.scheduleDisplay }}
            </div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>Updated By</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.lastModifiedByName }}
            </div>
          </div>
          <div class="col-lg col-md-3 flex-column">
            <div class="info-header-label">
              <span>Last Updated</span>
            </div>
            <div class="info-header-value">
              {{ profitSharing?.lastModifiedDate | dateDisplay }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="information-container col-md-2 mx-md-4 mr-3">
      <div class="container-fluid row pr-0">
        <em class="header-grey-icon icon-td-bill"></em>
        <div class="flex-column">
          <div class="info-header-label w-100">
            <span>Remaining Balance</span>
          </div>
          <div class="info-header-value-amount">
            {{ profitSharing?.remainingBalanceAmount | numberFormatDisplay }}
          </div>
        </div>
      </div>
    </div>

    <div class="information-container col-md-2 mx-md-0 mr-3">
      <div class="container-fluid row pr-0">
        <em class="header-red-icon icon-td-bill"></em>
        <div class="float-left">
          <div class="info-header-label w-100">
            <span>Profit Sharing</span>
          </div>
          <div class="info-total-amount">
            {{ profitSharing?.profitSharingAmount | numberFormatDisplay }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row flex-wrap" [formGroup]="profitSharingForm">
    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="section-header w-100">
        <span id="gp">Gross Profit</span>
      </div>
      <div class="w-100">
        <table id="gp-table" class="table table-bordered table-hover w-100">
          <thead>
            <tr>
              <th>
                <div class="py-2">
                  <span class="float-left table-header-text">Gross Profit</span>
                  <span class="float-right table-header-value">{{
                    profitSharing.grossProfitIncVatAmount | numberFormatDisplay
                  }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid row w-100">
                    <em class="table-grey-icon icon-td-purchase"></em>
                    <div class="text-left">Sales<br />(Inc. VAT)</div>
                  </div>
                </div>
                <div class="float-right gross-profit-value">
                  {{ profitSharing.salesIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid row w-100">
                    <em class="table-grey-icon icon-td-bill"></em>
                    <div class="text-left">COGS<br />(WSP Inc. VAT)</div>
                  </div>
                </div>
                <div class="float-right gross-profit-value negative">
                  {{ profitSharing.cogsIncVatAmount > 0 ? '-' : ''
                  }}{{ profitSharing.cogsIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid row w-100">
                    <em class="table-grey-icon icon-td-marketing"></em>
                    <div class="text-left">Compensate</div>
                  </div>
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="compensateAmount"
                    placeholder="0.00"
                    formControlName="compensateAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.compensateAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.compensateAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.compensateAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left w-60">
                  <div class="container-fluid row w-100">
                    <em class="table-grey-icon icon-td-marketing"></em>
                    <div class="text-left w-60">Cigarette VAT Adjustment (-)</div>
                  </div>
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="cigaretteVatAdjustmentAmount"
                    placeholder="0.00"
                    formControlName="cigaretteVatAdjustmentAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div
                      *ngIf="isSubmitted && profitSharingForm.controls.cigaretteVatAdjustmentAmount.errors?.required"
                    >
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="section-header row">
        <span id="profit-sharing-table">Profit Sharing</span>
      </div>
      <div class="w-100">
        <table id="profit-sharing-detail" class="table table-bordered table-hover w-100">
          <thead>
            <tr>
              <th class="header-text">
                <div class="py-2">
                  <div class="float-left">
                    <span class="table-header-text">Gross Profit</span><br /><span class="sub-header-text">
                      (After share to TD)</span
                    >
                  </div>
                  <span class="float-right table-header-value pt-2">
                    {{ profitSharing.grossProfitAfterShareAmount | numberFormatDisplay }}
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="float-left text-left pt-1 w-30">
                  Gross Profit
                </div>
                <div class="float-right profit-sharing-value">
                  {{ profitSharing.grossProfitIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-1 w-30">
                  Share to TD
                </div>
                <div class="float-right profit-sharing-value negative">
                  {{ profitSharing.shareToTDIncVatAmount > 0 ? '-' : ''
                  }}{{ profitSharing.shareToTDIncVatAmount | numberFormatDisplay }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="row blank-space"></div>
      <div class="w-100">
        <table id="administrative-table" class="table table-bordered table-hover w-100">
          <thead>
            <tr>
              <th>
                <div class="py-2">
                  <div class="float-left table-header-text">Administrative Expense</div>
                  <span
                    class="float-right table-header-value"
                    [ngClass]="{
                      negative: profitSharing.totalOtherExpense > 0
                    }"
                    >{{ profitSharing.totalOtherExpense > 0 ? '-' : ''
                    }}{{ profitSharing.totalOtherExpense || 0 | numberFormatDisplay }}</span
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Withholding Tax
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="withHoldingTaxAmount"
                    placeholder="0.00"
                    formControlName="withHoldingTaxAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.withHoldingTaxAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Less Cash Transfer
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="lessCashTransferAmount"
                    placeholder="0.00"
                    formControlName="lessCashTransferAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.lessCashTransferAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.lessCashTransferAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.lessCashTransferAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Store Use
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="storeUseAmount"
                    placeholder="0.00"
                    formControlName="storeUseAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.storeUseAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.storeUseAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.storeUseAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Stock Loss Adjustment
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="stockLossAdjustmentAmount"
                    placeholder="0.00"
                    formControlName="stockLossAdjustmentAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.stockLossAdjustmentAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.stockLossAdjustmentAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.stockLossAdjustmentAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Destroy
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="destroyAmount"
                    placeholder="0.00"
                    formControlName="destroyAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.destroyAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.destroyAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.destroyAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Net Penalty
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="netPenaltyAmount"
                    placeholder="0.00"
                    formControlName="netPenaltyAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.netPenaltyAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.netPenaltyAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.netPenaltyAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Other Expense
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="otherExpenseAmount"
                    placeholder="0.00"
                    formControlName="otherExpenseAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.otherExpenseAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.otherExpenseAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.otherExpenseAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-lg-3 pl-0">
      <div class="row blank-space"></div>
      <div class="w-100">
        <table id="other-income-table" class="table table-bordered table-hover w-100">
          <thead>
            <tr>
              <th>
                <div class="py-2">
                  <div class="float-left table-header-text">Other Income</div>
                  <span
                    class="float-right table-header-value"
                    [ngClass]="{
                      positive: profitSharing.totalOtherIncome > 0,
                      negative: profitSharing.totalOtherIncome < 0
                    }"
                    >{{ profitSharing.totalOtherIncome > 0 ? '+' : ''
                    }}{{ profitSharing.totalOtherIncome || 0 | numberFormatDisplay }}</span
                  >
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Over Cash Transfer
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="overCashTransferAmount"
                    placeholder="0.00"
                    formControlName="overCashTransferAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.overCashTransferAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.overCashTransferAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.overCashTransferAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Incentive
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="incentiveAmount"
                    placeholder="0.00"
                    formControlName="incentiveAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.incentiveAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.incentiveAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.incentiveAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="float-left text-left pt-md-2 w-60">
                  Other Income
                </div>
                <div class="float-right w-40">
                  <app-numeric-textbox
                    [format]="'0,0.00'"
                    [negativeSign]="false"
                    id="otherIncomeAmount"
                    placeholder="0.00"
                    formControlName="otherIncomeAmount"
                    class="td-numeric-textbox"
                    [ngClass]="{
                      'is-invalid': isSubmitted && profitSharingForm.controls.otherIncomeAmount.errors
                    }"
                  ></app-numeric-textbox>
                  <div
                    *ngIf="isSubmitted && profitSharingForm.controls.otherIncomeAmount.errors"
                    class="invalid-display text-left"
                  >
                    <div *ngIf="isSubmitted && profitSharingForm.controls.otherIncomeAmount.errors?.required">
                      {{ 'ERRORS.REQUIRED' | translate }}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="fixed-row-bottom form-row">
  <div class="float-left"></div>

  <div class="float-right">
    <button
      *ngIf="isViewMode && hasPermissionManage"
      type="button"
      id="btn-request-edit"
      class="btn btn-primary"
      (click)="toggleToEditMode()"
    >
      Edit
    </button>
    <button *ngIf="!isViewMode" type="button" id="btn-cancel" class="btn btn-standard mr-2" (click)="onCancel()">
      Cancel
    </button>
    <button *ngIf="!isViewMode" type="button" id="btn-submit" (click)="onSubmit()" class="btn btn-primary">
      Submit
    </button>
  </div>
</div>
