import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { FileModuleEnum } from '../../../shared/enum/file-url.enum';
import { ImportAdjustUploadStatusEnum } from '../../../shared/enum/manage-stock.emun';
import { StockInformationService } from '../../../shared/services/stock-information.service';

export class ICustomFile extends File {
  errors?: { [key: string]: any };
  imgSrc?: string;
  filePath?: string | ArrayBuffer | null;
  id?: number;
  imgHeight?: number;
  imgWidth?: number;
  isImg?: boolean;
  imgLoadReplay?: ReplaySubject<[Event, ProgressEvent]>;
  textContent?: string;
  textLoadReplay?: ReplaySubject<ProgressEvent>;
}

type allowedType = RegExp | string | string[];

@Component({
  selector: 'app-stock-adjustment-file-upload',
  templateUrl: './stock-adjustment-file-upload.component.html',
  styleUrls: ['./stock-adjustment-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StockAdjustmentFileUploadComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => StockAdjustmentFileUploadComponent),
      multi: true
    }
  ]
})
export class StockAdjustmentFileUploadComponent implements OnChanges, OnInit, OnDestroy, ControlValueAccessor {
  @HostBinding('class.is-invalid') get invalid() {
    return this.hasError;
  }

  constructor(
    @Optional() @Host() @SkipSelf() private readonly controlContainer: ControlContainer,
    private readonly modalService: BsModalService,
    protected readonly translate: TranslateService,
    private readonly stockInformationService: StockInformationService,
    private readonly logger: NGXLogger
  ) {
    this.disabled = false;
    this.hasError = false;
    this.hasChange = false;
    this.ngChange = () => {};
    this.ngTouched = () => {};
  }

  @Input()
  set allowedExt(value: allowedType) {
    if (typeof value === 'string') {
      value = value + '$';
    }
    if (value instanceof Array) {
      value = value.join('|') + '$';
    }
    this._allowedExt = value;
  }

  get allowedExt(): allowedType {
    return this._allowedExt;
  }

  private _allowedExt: allowedType;
  url = '';
  inputValue;
  isLoad = true;
  ngChange;
  ngTouched;
  value;
  validator: AsyncValidatorFn;
  fileList: ICustomFile[] = [];
  progress;
  fileService;

  @ViewChild('uploadInput', { static: false }) uploadInput: ElementRef;

  @Input() disabled: boolean;
  @Input() multiple: boolean;
  @Input() allowedTypes: allowedType;
  @Input() size: number;
  @Input() hasError: boolean;
  @Input() hasChange: boolean;
  @Input() withMeta: boolean;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() controlName: string;
  @Input() initialFileList: ICustomFile[];
  @Input() isNew: boolean;
  @Input() fileTypeErrorTxt: string;
  @Input() fileSizeErrorTxt: string;
  @Input() descriptionTxt: string | null;
  @Input() isAddable: boolean;
  @Input() index: number;
  @Input() maxImages: number;
  @Input() lastIndex: number;
  @Input() fileModule: FileModuleEnum;
  @Input() runDate: string;

  @Output() addImage = new EventEmitter<string>();

  @HostListener('change', ['$event.target.files']) onChange = (_value: any) => {};
  @HostListener('blur') onTouched = () => {};

  ngOnInit(): void {
    this.fileList = this.initialFileList || null;
  }

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    this.ngChange(this.value);
  }

  propagateChange: any = () => {};

  writeValue(fileList): void {
    if (fileList && fileList.length) {
      fileList
        .filter(file => !(file.filePath instanceof ArrayBuffer))
        .forEach(file => {
          this.fileService(file.filePath as string)
            .pipe(filter(data => Boolean(data)))
            .subscribe(signedUrlObject => (file.filePath = signedUrlObject.signedUrl));
        });
    }

    this.value = this.fileList = this.inputValue = fileList;
  }

  registerOnChange(fn: any): void {
    this.onChange = this.onChangeGenerator(fn);
  }

  registerOnTouched(fn: any): void {
    this.ngTouched = fn;
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onChangeGenerator(fn: (_: any) => {}): (_: ICustomFile[]) => void {
    if (this.value) {
      this.value.forEach(imgObj => {
        const regexpImageType = new RegExp(env.regexp.imageType, 'ig');
        imgObj.isImg = regexpImageType.test(imgObj.name);
      });
    }
    this.ngChange(this.value);
    this.ngTouched();

    return (customFiles: ICustomFile[]) => {
      const fileArr: File[] = [];
      this.isLoad = false;

      for (const file of customFiles) {
        file.errors = {};
        fileArr.push(file);
      }

      fn(fileArr);
    };
  }

  private generateRegExp(pattern: allowedType): RegExp | null {
    if (!pattern) {
      return null;
    }

    if (pattern instanceof RegExp) {
      return new RegExp(pattern);
    } else if (typeof pattern === 'string') {
      return new RegExp(pattern, 'ig');
    } else if (pattern instanceof Array) {
      return new RegExp(`(${pattern.join('|')})`, 'ig');
    }

    return null;
  }

  validate(files: AbstractControl) {
    if (!files.value || !files.value.length || files.disabled) {
      return null;
    }

    const errors: ValidationErrors = {};

    for (const file of files.value) {
      if (this.size && this.size < file.size) {
        file.errors['fileSize'] = true;
        errors['fileSize'] = true;
      }

      if (!this.allowedExt && !this.allowedTypes) {
        continue;
      }

      const extP = this.generateRegExp(this.allowedExt);
      const typeP = this.generateRegExp(this.allowedTypes);

      if (extP && !extP.test(file.name)) {
        file.errors['fileExt'] = true;
        errors['fileExt'] = true;
      }

      if (typeP && file.type && !typeP.test(file.type)) {
        file.errors['fileType'] = true;
        errors['fileType'] = true;
      }
    }

    if (this.isLoad) {
      return;
    }

    this.fileList = files.value;
    if (Object.keys(errors).length) {
      return errors;
    }

    this.stockInformationService.validateImportAdjustStockFiles(files.value).forEach((allRes, index: number) =>
      allRes.pipe(filter(Boolean)).subscribe(
        res => {
          if (!this.fileList[index].filePath) {
            const reader = new FileReader();

            reader.readAsDataURL(files.value[index]);
            reader.onload = () => (this.fileList[index].filePath = reader.result);
          }

          if (res['status'] === 'progress') {
            return JSON.stringify(res);
          } else if (res['stockAdjustItems'] && res['validations'] && !res['validations'].length) {
            files.value[index].stockAdjustItems = res['stockAdjustItems'];
            files.value[index].status = ImportAdjustUploadStatusEnum.COMPLETED;
            files.markAsTouched();
            this.isLoad = true;
          } else if (res['validations'] && res['validations'].length) {
            files.value[index].errorMessage = `${res['validations'].length} Error Found.`;
            files.value[index].validations = res['validations'];
            files.value[index].status = ImportAdjustUploadStatusEnum.FAILED;
          } else {
            this.logger.info(`File-Unhandled`, res);
            files.value[index].errorMessage = res['error'] && res['error'].message;
            files.value[index].status = ImportAdjustUploadStatusEnum.FAILED;
          }
        },
        err => {
          files.value[index].errorMessage = this.translate.instant(err.error.translateKey);
          this.logger.error(`File-Error`, err);
        }
      )
    );
  }

  onClickDelete() {
    this.fileList = [];
    this.controlContainer.control.get(this.controlName).setValue([]);
  }
}
