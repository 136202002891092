import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ProfitSharingData,
  ProfitSharingExportCriteria,
  ProfitSharingListPagination,
  ProfitSharingPrintCriteria,
  ProfitSharingSearchCriteria
} from '../models/profit-sharing.model';
import { BaseService } from './base.service';

@Injectable()
export class ProfitSharingService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.profitSharing;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public searchByCriteria(criteria: ProfitSharingSearchCriteria): Observable<ProfitSharingListPagination> {
    const params = this.getParams(criteria, true);
    return this.http.get<ProfitSharingListPagination>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  public getProfitSharingById(id: string): Observable<ProfitSharingData> {
    const url = this.getFullUrl(this.envService.view, {
      id
    });
    return this.http.get<ProfitSharingData>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  public printProfitSharingPdf(criteria: ProfitSharingPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.print, { ...criteria });
    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public export(criteria: ProfitSharingExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }

  public submit(profitSharing: ProfitSharingData): Observable<ProfitSharingData> {
    const url = this.getUrl();
    return this.http.post<ProfitSharingData>(url, profitSharing, {
      headers: this.loaderHeaders(),
      observe: 'body'
    });
  }
}
