import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { DefaultStatusEnum } from '../../../shared/enum/default-status.enum';
import { StoreGroupPageModes } from '../../../shared/enum/store-group.enum';
import { ChildItem } from '../../../shared/layouts/modals/full-modal/child-item';
import { FullModalComponent } from '../../../shared/layouts/modals/full-modal/full-modal.component';
import { RouteLinkTab, StoreGroupContent, StoreGroupSearchCriteria } from '../../../shared/models';
import { AuthGuardService } from '../../../shared/services';
import { StoreGroupListRequestAction } from '../../../shared/store/actions/store-group.actions';
import { StoreGroupState } from '../../../shared/store/reducers/store-group.reducers';
import {
  selectAllStoreGroupList,
  selectStoreGroupList,
  selectStoreGroupListCriteria
} from '../../../shared/store/selectors/store-group.selector';
import { AppStates } from '../../../shared/store/state/app.states';
import { StoreGroupCreateComponent } from '../store-group-create/store-group-create.component';

@Component({
  selector: 'app-store-group-list',
  templateUrl: './store-group-list.component.html',
  styleUrls: ['./store-group-list.component.scss']
})
export class StoreGroupListComponent extends BaseSearchComponent<
  StoreGroupSearchCriteria,
  StoreGroupContent,
  StoreGroupState
> {
  private localStore: Observable<any>;
  public listRoute: Array<RouteLinkTab>;
  private bsModalRef: BsModalRef;
  public dateFormat = environment.dateFormat;
  public hasViewPermission = false;
  public hasManagePermission = false;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected fb: FormBuilder,
    protected authGuardService: AuthGuardService,
    protected readonly translate: TranslateService
  ) {
    super(store, modalService, selectAllStoreGroupList, selectStoreGroupList);
    super.subscribeForSaveSuccess();

    this.hasViewPermission = this.authGuardService.checkPermission(['store_group_v']);
    this.hasManagePermission = this.authGuardService.checkPermission(['store_group_m']);
  }

  doInit() {
    this.setRouteTab();
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.localStore
      .pipe(select(selectStoreGroupListCriteria))
      .subscribe(criteriaObject => (this.currentPage = criteriaObject.page + 1));
  }

  createForm() {
    this.searchForm = this.fb.group({
      searchCriteria: [null]
    });
  }

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj: any) {
    this.store.dispatch(new StoreGroupListRequestAction(criteriaObj));
  }

  goToView(viewParams?: any) {
    if (viewParams === null) {
      return;
    }

    const initialState = {
      childItem: new ChildItem(
        StoreGroupCreateComponent,
        {
          merchantNo: viewParams.merchantNo,
          groupNo: viewParams.no,
          title: 'View Store Group',
          mode: StoreGroupPageModes.VIEW
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }
  setFirstPage() {
    this.currentPage = 1;
  }
  setInitialValue() {
    this.pageSize = 20;
  }

  setRouteTab(): void {
    this.listRoute = [];
    this.listRoute.push({ tabName: 'Store Group List', url: '/user-management/store-group-list' });
  }

  onSubmit() {
    this.setFirstPage();
    const formValue = this.searchForm.value;
    this.criteriaObject = {
      ...this.criteriaObject,
      searchCriteria: formValue.searchCriteria,
      page: 0
    };
    this.doSearch(this.criteriaObject);
  }

  gotoCreate() {
    const initialState = {
      title: null,
      childItem: new ChildItem(
        StoreGroupCreateComponent,
        {
          mode: StoreGroupPageModes.CREATE,
          title: 'Create Store Group'
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  editStoreGroup(item: any) {
    if (item === null) {
      return;
    }

    const initialState = {
      childItem: new ChildItem(
        StoreGroupCreateComponent,
        {
          merchantNo: item.merchantNo,
          groupNo: item.no,
          title: 'Edit Store Group',
          mode: StoreGroupPageModes.EDIT
        },
        true
      )
    };

    this.bsModalRef = this.modalService.show(FullModalComponent, {
      animated: false,
      backdrop: false,
      initialState,
      keyboard: false
    });
  }

  getColorStatus(status: string): string {
    return DefaultStatusEnum[status];
  }

  defaultGroupName(name: string, defaultGroup: boolean) {
    return defaultGroup ? this.translate.instant('STORE.STORE_GROUP.DEFAULT_GROUP') : name;
  }

  doAfterVersionAlertModal() {
    this.search(this.criteriaObject);
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  doAfterSuccessModal() {
    this.doAfterVersionAlertModal();
  }

  doDestroy() {}
}
