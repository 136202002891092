import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  OrderContent,
  OrderExportCriteria,
  OrderListResponse,
  OrderPrintCriteria,
  OrderSearchCriteria
} from '../models/order.model';
import { BaseService, CustomEncoder } from './base.service';

@Injectable()
export class OrderService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.order;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  searchByCriteria(criteria: OrderSearchCriteria): Observable<OrderListResponse> {
    const params = this.getParams(criteria);
    return this.http.get<any>(this.getUrl(), {
      headers: this.headers,
      observe: 'body',
      params
    });
  }

  getOrderRequest(orderNo: string): Observable<OrderContent> {
    const encoder = new CustomEncoder();
    const url = this.getFullUrl(this.envService.view, { orderNo: encoder.encodeKey(orderNo) });
    return this.http.get<any>(url, {
      headers: this.headers,
      observe: 'body'
    });
  }

  printPdfOrder(criteria: OrderPrintCriteria): Observable<any> {
    const url = this.getFullUrl(this.envService.print, { ...criteria });
    const body = new HttpParams();

    return this.http.post<any>(url, body, {
      headers: this.loaderHeaders(),
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }

  public exportOrder(criteria: OrderExportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.export);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
