import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { SpecialAssortmentContent, SpecialAssortmentListSearchCriteria } from '../../models/store-assortment.model';
import { SpecialAssortmentActions, SpecialAssortmentActionType } from '../actions/special-assortment.actions';
import { BaseState } from '../state/base.state';

export interface SpecialAssortmentState extends EntityState<SpecialAssortmentContent>, BaseState {
  criteriaObject: SpecialAssortmentListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<SpecialAssortmentContent> = createEntityAdapter<SpecialAssortmentContent>({
  selectId: (specialAssortment: SpecialAssortmentContent) => specialAssortment.barcode
});

export const initialSpecialAssortmentState: SpecialAssortmentState = adapter.getInitialState({
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20
});

export function specialAssortmentReducers(
  state = initialSpecialAssortmentState,
  action: SpecialAssortmentActions
): SpecialAssortmentState {
  switch (action.type) {
    case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload.criteria
      };
    case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    case SpecialAssortmentActionType.SPECIAL_ASSORTMENT_LIST_RESET:
      return initialSpecialAssortmentState;
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
