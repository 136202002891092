export class Menu {
  title: string;
  icon: string;
  active: boolean;
  link: string;
  type: string;
  group: string;
  badge?: Badge;
  permissions?: string[] | null;
  submenus?: SubmenusEntity[] | null;
  sublinks?: string[];
}

export class Badge {
  text: string;
  class: string;
}

export class SubmenusEntity {
  title: string;
  link: string;
  permissions?: string[] | null;
  sublinks?: string[];
}
