<div
  id="toteAssetLocationModal"
  class="modal fade barcodeModal admin-form"
  bsModal
  #toteAssetLocationModal="bs-modal"
  [config]="{ backdrop: 'static' }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <form>
        <div class="modal-header">
          <h6 class="modal-title pull-left">
            Export Tote Asset Location Report
          </h6>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeExportModal()">
            <em class="icon-close"></em>
          </button>
        </div>
        <div class="modal-body my-2 pt-0">
          <div class="container" [formGroup]="exportForm">
            <div class="container-fluid">
              <div class="row">
                <div class=" col-md-12">
                  <div class="form-group col-md-12">
                    <div class="form-row">
                      <div class="col-12 mb-2">Tote Name</div>
                      <div class="col-md-12">
                        <ng-select
                          placeholder="Please select..."
                          [items]="listOfValue"
                          [searchable]="false"
                          [clearable]="false"
                          [multiple]="true"
                          bindLabel="nameEn"
                          bindValue="code"
                          formControlName="toteCode"
                        >
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="responseExportError" class="alert-danger form-group mb-0">
                    {{ responseExportError }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            id="exportToteAssetLocation"
            (click)="onExport()"
            appPreventDoubleClick
          >
            Export
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
