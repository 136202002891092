import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { BsModalRef } from 'ngx-bootstrap';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { Z8ParameterList } from '../../../../shared/models/z8-parameter.model';
import { Z8ParameterDataService } from '../../../../shared/services/z8-parameter-data.service';
import { selectZ8ParameterById } from '../../../../shared/store/selectors/z8-parameter.selector';
import { AppStates } from '../../../../shared/store/state/app.states';
import { ExcelUtil } from '../../../../shared/utils/excel-util';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss']
})
export class ImportDataComponent implements OnInit, OnDestroy {
  @Output() validateObjChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() validateObj;
  @Input() listOfValue;
  @Input() storeList = [];
  @Input() articleList = [];

  public form: FormGroup;
  public loading: boolean;
  public worker: Worker;
  public errorData: any;
  public noOfErrors: number;
  public errorMessage: string;

  private localStore: Observable<any>;
  private parameter$: Observable<Z8ParameterList>;

  constructor(
    private readonly fb: FormBuilder,
    private readonly bsModalRef: BsModalRef,
    private readonly store: Store<AppStates>,
    private readonly z8ParameterDataService: Z8ParameterDataService,
    protected readonly logger: NGXLogger
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.parameter$ = this.localStore.pipe(select(selectZ8ParameterById));
    this.createForm();
    this.startWorker(this.processWorkbook.bind(this));
  }

  ngOnDestroy() {}

  createForm() {
    this.form = this.fb.group({
      importData: [null, Validators.required]
    });
  }

  closeModal() {
    if (this.worker) {
      this.worker.terminate();
    }

    this.bsModalRef.hide();
  }

  onSubmit() {
    this.loading = true;
    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      /* create workbook */
      const result = new Uint8Array(e.target.result);
      this.readImportData(result);
    };
    reader.readAsArrayBuffer(this.form.value.importData[0]);
  }

  processWorkbook(data) {
    this.z8ParameterDataService.addData(data);
    this.loading = false;
    this.closeModal();
  }

  startWorker(callback) {
    this.worker = new Worker('./import-data.worker', { type: 'module' });
    this.worker.onmessage = e => {
      switch (e.data.t) {
        case 'ready':
          break;
        case 'e':
          this.errorData = JSON.parse(e.data.d);
          this.noOfErrors = e.data.n;
          this.setErrorMessage(
            `${this.noOfErrors} Errors Found. Download this file to edit or delete invalid row then re-import.`
          );
          this.loading = false;
          break;
        case 'alert':
          this.setErrorMessage(e.data.d);
          this.loading = false;
          break;
        case 'xlsx':
          this.validateObj = JSON.parse(e.data.v);
          this.validateObjChange.emit(this.validateObj);
          callback(JSON.parse(e.data.d));
          break;
        case 'log':
          this.logger.log(JSON.parse(e.data.d));
          break;
        default:
      }
    };
  }

  readImportData(data) {
    this.parameter$.subscribe(item => {
      this.worker.postMessage({
        v: this.validateObj,
        d: data,
        n: item.name,
        storeList: this.storeList,
        listOfValue: this.listOfValue,
        articleList: this.articleList
      });
    });
  }

  downloadErrorFile() {
    ExcelUtil.WriteFile(this.errorData, this.form.value.importData[0].name);
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  reset() {
    this.errorMessage = null;
    this.errorData = null;
    this.noOfErrors = null;
  }
}
