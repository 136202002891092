<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Export Store Stock Planning</h4>
    <span *ngIf="files">Total export {{ files.length }} files</span>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ng-container *ngIf="!files; else exportSuccess">
    <div class="modal-body admin-form" [formGroup]="exportForm" (ngSubmit)="onExport()">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label>Store Condition</label>
              <ng-select
                placeholder="Please select..."
                [items]="storeList"
                [multiple]="true"
                bindLabel="storeCodeName"
                bindValue="code"
                [searchable]="true"
                formControlName="storeCode"
              ></ng-select>
            </div>
            <div class="form-group">
              <div class="form-row">
                <div class="col-md-6">
                  <label>Start Date</label>
                  <input
                    id="exportStartDate"
                    formControlName="startDate"
                    type="text"
                    placeholder="{{ dateFormat }}"
                    autocomplete="off"
                    class="form-control calendar"
                    bsDatepicker
                    [bsConfig]="bsDateConfig"
                    [maxDate]="maxDate"
                    (bsValueChange)="onChangeStartDate($event)"
                    readonly
                  />
                  <label for="exportStartDate" class="icon-calendar btn-calendar"></label>
                </div>
                <div class="col-md-6">
                  <label>End Date</label>
                  <input
                    id="exportEndDate"
                    formControlName="endDate"
                    type="text"
                    placeholder="{{ dateFormat }}"
                    autocomplete="off"
                    class="form-control calendar"
                    bsDatepicker
                    [minDate]="minDate"
                    [bsConfig]="bsDateConfig"
                    (bsValueChange)="onChangeEndDate($event)"
                    readonly
                  />
                  <label for="exportEndDate" class="icon-calendar btn-calendar"></label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Status</label>
              <ng-select
                [items]="statusList"
                [searchable]="false"
                [clearable]="false"
                [multiple]="true"
                class="select-status w-100"
                bindLabel="label"
                bindValue="value"
                formControlName="status"
                placeholder="Please select..."
                dropdownPosition="auto"
                notFoundText="No Data"
              ></ng-select>
            </div>
            <div *ngIf="errorExport" class="alert-danger form-group mb-0 " role="alert">
              {{ errorExport }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onExport()" id="btnExport">
        Export
      </button>
    </div>
  </ng-container>

  <ng-template #exportSuccess>
    <div class="modal-body">
      <table class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-10">No.</th>
            <th class="text-center w-30">File Name</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let file of files; index as i">
            <td class="text-center">{{ i + 1 }}</td>
            <td class="d-flex flex-row justify-content-between">
              <span>{{ file.refId }}</span>
              <a class="d-flex align-items-center" target="_self" [href]="file.signedUrl"
                ><em class="icon-download"></em
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onDownloadAll()" id="btnDownloadAll">
        Download All
      </button>
    </div>
  </ng-template>
</div>
