export enum PromotionRequestStatusEnum {
  AWAITING_APPROVAL = 'W',
  DRAFT = 'D',
  REJECTED = 'R',
  APPROVED = 'A',
  // TODO: temp fixed
  REQUEST_CREATE = 'X'
}

export enum PromotionStatusEnum {
  AWAITING_SCHEDULE = 'AS',
  ACTIVE = 'A',
  EXPIRED = 'E',
  CANCELLED = 'C'
}

export enum PromotionModeEnum {
  NEW = 'N',
  EDIT = 'E'
}

export enum PromotionItemTypeEnum {
  PROMOTION = 'promotionItem',
  CO_PROMOTION = 'coPromotionItem'
}

export enum SelectedPromotionStoreEnum {
  ALL_STORE = 'ALL_STORE',
  SELECT_BY_STORE = 'SELECT_BY_STORE'
}
