/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./image-modal.component";
var styles_ImageModalComponent = [i0.styles];
var RenderType_ImageModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageModalComponent, data: {} });
export { RenderType_ImageModalComponent as RenderType_ImageModalComponent };
function View_ImageModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "TD Tawandang"], ["class", "img-fluid"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
export function View_ImageModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body mx-auto h-auto text-center"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageModalComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ImageModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-modal", [], null, null, null, View_ImageModalComponent_0, RenderType_ImageModalComponent)), i1.ɵdid(1, 245760, null, 0, i3.ImageModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageModalComponentNgFactory = i1.ɵccf("app-image-modal", i3.ImageModalComponent, View_ImageModalComponent_Host_0, {}, {}, []);
export { ImageModalComponentNgFactory as ImageModalComponentNgFactory };
