import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as JwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { ClientIdTypeEnum } from '../../enum/client-id.enum';
import { AuthResponse, AuthUser, ErrorResponse } from '../../models';
import { AuthService } from '../../services';
import { CookiesUtil } from '../../utils/cookies-util';
import {
  AuthActionTypes,
  LogInAction,
  LogInFailureAction,
  LogInSuccessAction,
  LogOutAction,
  RefreshAction
} from '../actions/auth.actions';
import { MenuRequestedAction } from '../actions/menu.action';
import {
  ClientIdAction,
  TenantInformationAction,
  UserInformationAction,
  UserInformationResetAction
} from '../actions/user-info.action';
import { AppStates } from '../state/app.states';

@Injectable()
export class AuthEffects {
  private readonly cookiesUtil: CookiesUtil;

  constructor(
    private readonly actions: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly cookieService: CookieService,
    private readonly logger: NGXLogger,
    private readonly store: Store<AppStates>
  ) {
    this.cookiesUtil = new CookiesUtil(cookieService);
  }

  @Effect()
  LogIn: Observable<Action> = this.actions.pipe(
    ofType<LogInAction>(AuthActionTypes.LOGIN),
    map(action => action.payload),
    switchMap(payload => {
      return this.authService.logIn(payload).pipe(
        switchMap((resp: AuthResponse) => {
          return of(new UserInformationResetAction(), new LogInSuccessAction(resp));
        }),
        catchError(err => {
          return of(new LogInFailureAction(err.error));
        })
      );
    })
  );

  @Effect()
  Refresh: Observable<Action> = this.actions.pipe(
    ofType<RefreshAction>(AuthActionTypes.REFRESH),
    map(action => action.payload),
    switchMap(payload => {
      return this.authService.refreshToken(payload).pipe(
        switchMap((resp: AuthResponse) => of(new LogInSuccessAction(resp))),
        catchError(err => of(new LogInFailureAction(err.error)))
      );
    })
  );

  @Effect({ dispatch: false })
  LogInSuccess: Observable<Action> = this.actions.pipe(
    ofType<LogInSuccessAction>(AuthActionTypes.LOGIN_SUCCESS),
    map(action => action.payload),
    tap((payload: any) => {
      try {
        const userInfo: AuthUser = JwtDecode(payload.access_token);

        const mSec = payload.expires_in * 1000;
        const currentDate = new Date();
        const expiryDate = new Date(currentDate.getTime() + mSec + env.cookies.bufferTimePeriod);
        const tokenExpiryDate = new Date(
          moment(expiryDate)
            .add(1, 'week')
            .toDate()
        );

        const cookieOptions = {
          expires: expiryDate,
          path: env.cookies.path,
          domain: env.cookies.domain,
          secure: window.location.protocol === 'https:'
        };

        if (payload.access_token) {
          const accessToken = this.cookiesUtil.splitByLength(
            payload.access_token,
            env.cookies.accessToken,
            cookieOptions
          );
          const accessTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.accessToken);

          accessTokenCookiesName.forEach((value, index) => {
            this.cookieService.set(
              value,
              accessToken[index],
              tokenExpiryDate,
              cookieOptions.path,
              cookieOptions.domain,
              cookieOptions.secure
            );
          });
        }

        if (payload.refresh_token) {
          const refreshToken = this.cookiesUtil.splitByLength(
            payload.refresh_token,
            env.cookies.refreshToken,
            cookieOptions
          );
          const refreshTokenCookiesName = this.cookiesUtil.generateNameByAmount(env.cookies.refreshToken);

          refreshTokenCookiesName.forEach((value, index) => {
            this.cookieService.set(
              value,
              refreshToken[index],
              tokenExpiryDate,
              cookieOptions.path,
              cookieOptions.domain,
              cookieOptions.secure
            );
          });
        }

        this.dispatchInfo(userInfo);
        if (userInfo.user_info.forceChangePassword) {
          this.router.navigate(['/force-change-password']);
        } else if (this.router.url === '/' || this.router.url.toLocaleLowerCase() === '/') {
          this.router.navigateByUrl('/dashboard/my-task');
        }
      } catch (err) {
        this.logger.error(err);
        this.store.dispatch(new LogOutAction());
        window.location.href = window.location.origin;
      }
    })
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<Action | ErrorResponse> = this.actions.pipe(
    ofType<LogInFailureAction>(AuthActionTypes.LOGIN_FAILURE),
    tap(() => {
      this.clearAuth();
      this.router.navigateByUrl('/');
    })
  );

  @Effect({ dispatch: false })
  LogOut: Observable<Action> = this.actions.pipe(
    ofType<LogOutAction>(AuthActionTypes.LOGOUT),
    tap(() => {
      this.clearAuth();
      this.router.navigateByUrl('/');
    })
  );

  private dispatchInfo(userInfo: AuthUser) {
    userInfo.user_info.authorities = userInfo.authorities;

    this.store.dispatch(new UserInformationAction(userInfo.user_info));
    this.store.dispatch(new TenantInformationAction(userInfo.tenant_info));
    this.store.dispatch(new ClientIdAction(userInfo.client_id));
    this.store.dispatch(new MenuRequestedAction(userInfo.authorities));
  }

  private clearAuth() {
    this.cookieService.deleteAll(env.cookies.path, env.cookies.domain);
    this.logger.info('delete all ', env.cookies.domain, 'cookies ', this.cookieService.getAll());

    this.store.dispatch(new ClientIdAction(env.defaultClientId as ClientIdTypeEnum));
    this.store.dispatch(new UserInformationResetAction());
  }
}
