import { createSelector } from '@ngrx/store';
import * as fromStoreCreateResponseState from '../reducers/store-create.reducers';
import { AppStates } from '../state/app.states';

const selectStoreCreateState = (state: AppStates) => state.storeCreate;

export const selectStore = createSelector(
  selectStoreCreateState,
  (state: fromStoreCreateResponseState.StoreCreateResponseState | null) => state
);

export const selectStoreApproveRejectStatus = createSelector(
  selectStoreCreateState,
  (state: fromStoreCreateResponseState.StoreCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
