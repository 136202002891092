<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Import</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body admin-form" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label>Store Stock Planning</label>
            <div class="d-flex justify-content-between">
              <app-get-excel-data
                id="importData"
                formControlName="importData"
                [size]="50000000"
                [controlName]="'importData'"
                [disabled]="loading"
                (clearInput)="reset()"
                (showErrorMessage)="setErrorMessage($event)"
                class="flex-item"
              ></app-get-excel-data>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="errorMessage" class="alert-danger form-group upload-error mt-3">
        <span [ngClass]="{ 'flex-70': errorData }">{{ errorMessage }}</span>
        <a *ngIf="errorData" class="btn btn-link upload-error-file" (click)="downloadErrorFile()">download</a>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard mr-2" (click)="closeModal()" id="btnCancel">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary btn-loader"
      (click)="onSubmit()"
      id="btnSumit"
      [disabled]="!form.value.importData || loading || errorMessage"
    >
      OK
      <div class="loader" *ngIf="loading"></div>
    </button>
  </div>
</div>
