<div class="modal-content">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="decline()" id="confirm-modal-closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body text-center">
    <div class="mb-4">
      <span [innerHTML]="message"></span>
    </div>
    <div>
      <div class="form-check form-check-inline mr-4" *ngFor="let option of options; index as i">
        <input
          class="form-check-input"
          type="radio"
          name="{{ 'radio_' + (i + 1) }}"
          id="{{ 'radio_' + (i + 1) }}"
          [value]="option.value"
          [(ngModel)]="selectedValue"
          [checked]="selectedValue === option.value"
        />
        <label class="form-check-label" for="{{ 'radio_' + (i + 1) }}">
          {{ option.label }}
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="decline()" id="confirm-modal-cancelBtn">
      {{ cancelText }}
    </button>
    <button
      type="button"
      [ngClass]="okClass"
      (click)="confirm()"
      id="confirm-modal-okBtn"
      [disabled]="!selectedValue"
    >
      {{ okText }}
    </button>
  </div>
</div>
