export enum GoodsReceiveStatusEnum {
  DRAFT = 'DRAFT',
  RECEIVED = 'RECEIVED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED'
}

export enum GoodsReceiveModeEnum {
  VIEW = 'VIEW',
  CREATE = 'CREATE'
}
