import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { StoreGroupContent, StoreGroupSearchCriteria } from '../../models';
import { StoreGroupAction, StoreGroupActionTypes } from '../actions/store-group.actions';

export interface StoreGroupState extends EntityState<StoreGroupContent> {
  isLoading: boolean;
  criteriaObject: StoreGroupSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<StoreGroupContent> = createEntityAdapter<StoreGroupContent>();

export const initialVoucherRequestListState: StoreGroupState = adapter.getInitialState({
  isLoading: false,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0
});

export function StoreGroupReducers(
  state = initialVoucherRequestListState,
  action: StoreGroupAction
): StoreGroupState {
  switch (action.type) {
    case StoreGroupActionTypes.STORE_GROUP_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case StoreGroupActionTypes.STORE_GROUP_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
