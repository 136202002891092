<div class="full-popup-head">
  <div class="full-popup-title flex">
    <em class="modal-title-icon icon-stock"></em>
    {{ data.title }}
  </div>
  <div class="full-popup-close">
    <a aria-label="Close" class="close" (click)="onExit()">
      <em class="icon-close"></em>
    </a>
  </div>
  <div class="clearfix"></div>
</div>

<div id="stock-information" class="admin-form">
  <div class="info-header-container">
    <div class="info-header-item">
      <div class="info-header-label">
        <span>Article No.:</span>
      </div>
      <div class="info-header-value">
        <span>{{ data.articleName }}</span>
      </div>
    </div>
    <div class="info-header-item" *ngIf="stockInformation">
      <div class="info-header-label">
        <span>Status:</span>
      </div>
      <div class="info-header-value">
        <span class="product-assortment-status" [ngClass]="getColorStatus(stockInformation?.productStatus)">{{
          'PRODUCT_ASSORTMENT.PRODUCT_STATUS.' + stockInformation.productStatus | translate
        }}</span>
      </div>
    </div>
  </div>

  <div
    class="list-box d-flex full-width"
    [ngClass]="{
      warehouse: data.stockLocation === stockLocation.ALL_LOCATIONS && data.productType === productTypeEnum.INVENTORY
    }"
  >
    <app-boxs-display
      *ngFor="let list of listBox; index as i"
      [boxs]="list"
      [ngClass]="{ 'left-box': i === 0, 'right-box': i === 1 }"
      class="info-box"
    ></app-boxs-display>
  </div>

  <div class="tab-menu d-flex d-inline-flex">
    <a
      *ngIf="data.stockLocation !== stockLocation.ALL_LOCATIONS"
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === stockInformationTabsEnum.MOVEMENT }"
      (click)="onSelectTabs(stockInformationTabsEnum.MOVEMENT)"
    >
      Stock Movement
    </a>
    <a
      *ngIf="data.stockLocation === stockLocation.ALL_LOCATIONS"
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === stockInformationTabsEnum.WAREHOUSE }"
      (click)="onSelectTabs(stockInformationTabsEnum.WAREHOUSE)"
    >
      Stock by Warehouse
    </a>
    <a
      *ngIf="getStockStorePermission()"
      class="list-tab-menu px-3 px-md-5 py-2"
      [ngClass]="{ active: currentTab === stockInformationTabsEnum.STORE }"
      (click)="onSelectTabs(stockInformationTabsEnum.STORE)"
    >
      Stock by Store
    </a>
  </div>
  <ng-container *ngIf="currentTab === 'MOVEMENT' && data.warehouseCode && data.articleNo && data.productType">
    <app-stock-movement
      [warehouse]="data.warehouseCode"
      [articleNo]="data.articleNo"
      [productType]="data.productType"
      [stockLocation]="data.stockLocation"
    >
    </app-stock-movement>
  </ng-container>

  <ng-container *ngIf="currentTab === 'STORE' && data.warehouseCode && data.articleNo">
    <app-stock-store [warehouse]="data.warehouseCode" [articleNo]="data.articleNo"> </app-stock-store>
  </ng-container>

  <ng-container *ngIf="currentTab === 'WAREHOUSE' && data.articleNo">
    <app-stock-warehouse [warehouse]="data.warehouseCode" [articleNo]="data.articleNo"> </app-stock-warehouse>
  </ng-container>
</div>
