import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StockInformationService } from '../../services/stock-information.service';
import { LayoutActionLoadError } from '../actions/layout.action';
import {
  StockInformationActionTypes,
  StockInformationListRequestAction,
  StockInformationListResponseAction,
  StockInformationMovementRequestAction,
  StockInformationMovementResponseAction,
  StockInformationRequestAction,
  StockInformationResponseAction,
  StockInformationStoreRequestAction,
  StockInformationStoreResponseAction,
  StockInformationWarehouseRequestAction,
  StockInformationWarehouseResponseAction
} from '../actions/stock-information.actions';

@Injectable()
export class StockInformationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly logger: NGXLogger,
    private readonly stockInformationService: StockInformationService
  ) {}

  @Effect()
  searchStockInformationList$ = this.actions$.pipe(
    ofType<StockInformationListRequestAction>(StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockInformationActionTypes.STOCK_INFORMATION_LIST_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService.searchByCriteria(payload).pipe(
        map(requests => {
          return new StockInformationListResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  stockInformation$ = this.actions$.pipe(
    ofType<StockInformationRequestAction>(StockInformationActionTypes.STOCK_INFORMATION_GET_REQUEST),
    map(action => {
      this.logger.debug(`@Effect ${StockInformationActionTypes.STOCK_INFORMATION_GET_REQUEST}`);
      return action;
    }),
    switchMap(action => {
      return this.stockInformationService.warehouseArticleNo(action.payload.warehouse, action.payload.articleNo).pipe(
        map(requests => {
          return new StockInformationResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  searchStockMovement$ = this.actions$.pipe(
    ofType<StockInformationMovementRequestAction>(StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockInformationActionTypes.STOCK_INFORMATION_MOVEMENT_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService.movement(payload.warehouse, payload.articleNo, payload.criteria).pipe(
        map(requests => {
          return new StockInformationMovementResponseAction(requests);
        }),
        catchError(error => of(new LayoutActionLoadError(error)))
      );
    })
  );

  @Effect()
  searchStockStore$ = this.actions$.pipe(
    ofType<StockInformationStoreRequestAction>(StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockInformationActionTypes.STOCK_INFORMATION_STORE_REQUEST}: ` + this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService
        .stockByLocation(payload.warehouse, payload.articleNo, payload.criteria)
        .pipe(
          map(requests => {
            return new StockInformationStoreResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
    })
  );

  @Effect()
  searchStockWarehouse$ = this.actions$.pipe(
    ofType<StockInformationWarehouseRequestAction>(StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST),
    map(action => {
      this.logger.debug(
        `@Effect ${StockInformationActionTypes.STOCK_INFORMATION_WAREHOUSE_REQUEST}: ` +
          this.stringify(action.payload)
      );
      return action.payload;
    }),
    switchMap(payload => {
      return this.stockInformationService
        .stockByLocation(payload.warehouse, payload.articleNo, payload.criteria)
        .pipe(
          map(requests => {
            return new StockInformationWarehouseResponseAction(requests);
          }),
          catchError(error => of(new LayoutActionLoadError(error)))
        );
    })
  );

  private stringify(data: any) {
    return JSON.stringify(data);
  }
}
