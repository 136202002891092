import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { Observable, of, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ErrorCodeEnum } from '../enum/error-code.enum';
import { TDStoreValidatorTypeEnum } from '../enum/merchant-validator-type.enum';
import { TDStorePage } from '../enum/td-store-page.enum';
import { MerchantRequestService } from '../services/merchant-request.service';

export function merchantDuplicatedValidator(
  type: TDStoreValidatorTypeEnum,
  merchantService: MerchantRequestService,
  requestId?: string,
  currentPage?: TDStorePage
): AsyncValidatorFn {
  const getErrorCode = (merchantValidator: TDStoreValidatorTypeEnum): ErrorCodeEnum => {
    switch (merchantValidator) {
      case TDStoreValidatorTypeEnum.MOBILE_PHONE:
        return ErrorCodeEnum.DUPLICATE_MOBILE_ERROR_CODE;
      case TDStoreValidatorTypeEnum.STORE_NAME:
        return ErrorCodeEnum.DUPLICATE_STORE_NAME_ERROR_CODE;
      case TDStoreValidatorTypeEnum.STORE_CODE:
        return ErrorCodeEnum.DUPLICATE_STORE_CODE_ERROR_CODE;
      case TDStoreValidatorTypeEnum.TAX_ID:
        return ErrorCodeEnum.DUPLICATE_TAXID_ERROR_CODE;
      case TDStoreValidatorTypeEnum.ID_CARD:
        return ErrorCodeEnum.DUPLICATE_IDCARD_ERROR_CODE;
      default:
        return null;
    }
  };

  const getRequestId = (page: TDStorePage, id: string) => {
    switch (page) {
      case TDStorePage.MERCHANT_REQUEST:
        return { merchantRequestId: id };
      case TDStorePage.STORE_REQUEST:
        return { storeRequestId: id };
      default:
        return {};
    }
  };

  return (control: AbstractControl): Observable<ValidationErrors | null> | Promise<ValidationErrors | null> => {
    return timer().pipe(
      switchMap(() => {
        if (control.value) {
          let input;

          switch (type) {
            case TDStoreValidatorTypeEnum.MOBILE_PHONE:
              input = control.value && control.value.nationalNumber.replace(/ /g, '');
              break;
            case TDStoreValidatorTypeEnum.STORE_CODE:
            case TDStoreValidatorTypeEnum.STORE_NAME:
              input = encodeURIComponent(control.value);
              break;
            default:
              input = control.value;
          }

          return merchantService.getMerchantValidate(type, input, getRequestId(currentPage, requestId)).pipe(
            switchMap(() => of(null)),
            catchError(errResponse => {
              const isInvalid = errResponse.status === 400 && errResponse.error.code === getErrorCode(type);
              const result = isInvalid ? { duplicated: isInvalid } : null;
              return of(result);
            })
          );
        }
      })
    );
  };
}
