import { Action } from '@ngrx/store';
import { TdAssortmentSearchCriteria } from '../../models/purchase-request.model';

export enum TdAssortmentActionTypes {
  TD_ASSORTMENT_LIST_REQUEST = '[TD Assortment] TD Assortment List Request',
  TD_ASSORTMENT_LIST_RESPONSE = '[TD Assortment] TD Assortment List Response',
  TD_ASSORTMENT_LIST_RESET = '[TD Assortment] TD Assortment List Reset'
}

export class TdAssortmentListRequest implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST;

  constructor(public payload: TdAssortmentSearchCriteria) {}
}

export class TdAssortmentListResponse implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESPONSE;

  // constructor(public payload: TdAssortmentListPagination) {}
  constructor(public payload: any) {}
}

export class TdAssortmentListReset implements Action {
  readonly type = TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESET;

  constructor() {}
}

export type TdAssortmentActions = TdAssortmentListRequest | TdAssortmentListResponse | TdAssortmentListReset;
