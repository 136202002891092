export enum SupplierGroupRequestStatusEnum {
  DRAFT = 'DRAFT',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED'
}

export enum SupplierGroupRequestItemStatusEnum {
  PENDING = 'PENDING',
  AWAITING = 'AWAITING'
}

export enum SupplierGroupModeEnum {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT',
  REQUEST_CREATE = 'REQUEST_CREATE',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export enum SupplierGroupRequestTypeEnum {
  NEW = 'N',
  EDIT = 'E'
}

export enum SupplierGroupStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}
