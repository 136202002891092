import { Action } from '@ngrx/store';
import { ErrorResponse, ShelfItems, ShelfOptionType, UploadedFirstLotOrder } from '../../models';

export enum FirstLotOrderActionType {
  FIRST_LOT_ORDER_SAVE_REQUEST = '[Merchant] First Lot Order Save Request',
  FIRST_LOT_ORDER_SAVE_RESPONSE = '[Merchant] First Lot Order Save Response',
  FIRST_LOT_ORDER_RESET = '[Merchant] First Lot Order Reset',
  SHELF_ITEMS_REQUEST = '[Shelf] Shelf items Request',
  SHELF_ITEMS_RESPONSE = '[Shelf] Shelf items Response',
  SHELF_ITEMS_RESET = '[Shelf] Shelf items Reset',
  SHELF_DETAILS_REQUEST = '[Shelf] Shelf Details Request',
  SHELF_DETAILS_RESPONSE = '[Shelf] Shelf Details Response',
  SHELF_DETAILS_RESET = '[Shelf] Shelf Details Reset',
  ADD_SHELF_TYPE_REQUEST = '[Shelf] Add Shelf Type Request',
  ADD_SHELF_TYPE_RESPONSE = '[Shelf] Add Shelf Type Response',
  ADD_SHELF_TYPE_ERROR = '[Shelf] Add Shelf Type Error'
}

export class FirstLotOrderSaveRequestAction implements Action {
  readonly type = FirstLotOrderActionType.FIRST_LOT_ORDER_SAVE_REQUEST;

  constructor(public payload: UploadedFirstLotOrder) {}
}

export class FirstLotOrderSaveResponseAction implements Action {
  readonly type = FirstLotOrderActionType.FIRST_LOT_ORDER_SAVE_RESPONSE;

  constructor(public payload: { [key: string]: UploadedFirstLotOrder }) {}
}

export class FirstLotOrderResetAction implements Action {
  readonly type = FirstLotOrderActionType.FIRST_LOT_ORDER_RESET;
}

export class ShelfItemsRequestAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_ITEMS_REQUEST;
}

export class ShelfItemsResponseAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_ITEMS_RESPONSE;

  constructor(public payload: ShelfItems[]) {}
}

export class ShelfItemsResetAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_ITEMS_RESET;
}

export class ShelfDetailsRequestAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_DETAILS_REQUEST;

  constructor(public payload: string) {}
}

export class ShelfDetailsResponseAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_DETAILS_RESPONSE;

  constructor(public payload: ShelfOptionType[]) {}
}

export class ShelfDetailsResetAction implements Action {
  readonly type = FirstLotOrderActionType.SHELF_DETAILS_RESET;
}

export class AddShelfTypeRequestAction implements Action {
  readonly type = FirstLotOrderActionType.ADD_SHELF_TYPE_REQUEST;

  constructor(public payload: ShelfItems[]) {}
}

export class AddShelfTypeResponseAction implements Action {
  readonly type = FirstLotOrderActionType.ADD_SHELF_TYPE_RESPONSE;

  constructor(public payload: ShelfItems[]) {}
}

export class AddShelfTypeErrorAction implements Action {
  readonly type = FirstLotOrderActionType.ADD_SHELF_TYPE_ERROR;

  constructor(public payload: ErrorResponse) {}
}

export type FirstLotOrderActions =
  | FirstLotOrderSaveRequestAction
  | FirstLotOrderSaveResponseAction
  | FirstLotOrderResetAction
  | ShelfItemsRequestAction
  | ShelfItemsResponseAction
  | ShelfItemsResetAction
  | ShelfDetailsRequestAction
  | ShelfDetailsResponseAction
  | ShelfDetailsResetAction
  | AddShelfTypeRequestAction
  | AddShelfTypeResponseAction
  | AddShelfTypeErrorAction;
