import * as numeral from 'numeral';
import { BillPaymentStatusEnum } from '../../enum/bill-payment-status.enum';
import { AdjustTypeEnum, LocationTypeEnum } from '../../enum/manage-stock.emun';
import { SelectedPromotionStoreEnum } from '../../enum/promotion.enum';
import { PruductMasterSelectStatusEnum } from '../product-master.model';
import {
  AssortmentDeliveryMethodEnum,
  AssortmentFreshLiteTypeEnum,
  ProductStatusEnum
} from '../request-assortment.model';

export const productsMasterStatus = [
  { value: '', label: 'All Status' },
  { value: PruductMasterSelectStatusEnum.NEW, label: 'New Product' },
  { value: PruductMasterSelectStatusEnum.SELECTED, label: 'Copy Completed' },
  { value: PruductMasterSelectStatusEnum.UNUSED, label: 'TD Unused' }
];

export const productsMasterType = [
  { value: 'CJ', label: 'CJ' },
  { value: 'TD', label: 'TD' }
];

export const barcodeSizeLOV = [
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' }
];
export const barcodeStatusLOV = [
  { value: '', label: '(None)' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const barcodeStatusWithoutNoneValueLOV = [
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' },
  { value: 'MARK_DELETE', label: 'Mark Delete' }
];

export const supplierLOV = [{ value: 'CJ', label: 'CJ Express Group Co., Ltd.' }];

export const productTierLOV = [
  { value: 'Core', label: 'Core' },
  { value: 'Non-Core', label: 'Non-Core' }
];

export const productStandardLOV = [
  { value: 'TISI', label: 'TISI' },
  { value: 'FDA', label: 'FDA' },
  { value: 'N/A', label: 'N/A' }
];

export const productLevelList = [
  { value: 'Family', label: 'Family' },
  { value: 'Class', label: 'Class' },
  { value: 'Sub Class', label: 'Sub Class' },
  { value: 'Article', label: 'Article' }
];

export const exclusiveProductLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const cbgProductLOV = [
  { value: 'CBG', label: 'CBG' },
  { value: 'OEM', label: 'OEM' },
  { value: 'Other', label: 'Other' }
];

export const stockStatusLOV = [
  { value: 'In stock', label: 'In stock' },
  { value: 'Out of stock', label: 'Out of stock' }
];

export const yesNoLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const vatLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const applyLOV = [
  { value: false, label: 'Not Apply' },
  { value: true, label: 'Apply' }
];

export const productRecommendLOV = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

export const shippingMethodLOV = [
  { value: 'Flow thru', label: 'Flow thru' },
  { value: 'Direct to Store', label: 'Direct to Store' }
];

export const productAssortmentStatus = [
  { value: '', label: 'All Status' },
  { value: ProductStatusEnum.SELECT, label: 'Select' },
  { value: ProductStatusEnum.NEW, label: 'New' },
  { value: ProductStatusEnum.SEASONAL, label: 'Seasonal' },
  { value: ProductStatusEnum.ACTIVE, label: 'Active' },
  { value: ProductStatusEnum.OUT_OF_STOCK, label: 'Out of Stock' },
  { value: ProductStatusEnum.HOLD_BUY, label: 'Hold (buy)' },
  { value: ProductStatusEnum.HOLD_BUY_SELL, label: 'Hold (buy,sell)' },
  { value: ProductStatusEnum.CLEAR, label: 'Clear' },
  { value: ProductStatusEnum.CLEAR_PROMOTION, label: 'Clear Promotion' },
  { value: ProductStatusEnum.RECALL, label: 'Recall' },
  { value: ProductStatusEnum.DELETE, label: 'Delist' }
];

export const catalogStatus = [
  { value: 'I', label: 'Inactive' },
  { value: 'A', label: 'Active' }
];

export const productGradingLOV = [
  { value: 5, label: 'A' },
  { value: 4, label: 'B' },
  { value: 3, label: 'C' },
  { value: 2, label: 'D' }
];

export const articleTypeLOV = [
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const productTypeLOV = [
  { value: 'INVENTORY', label: 'Inventory' },
  { value: 'FIX_ASSET', label: 'Fix Asset' },
  { value: 'STORE_USE', label: 'Store Use' }
];

export const productTypeNonMerLOV = [
  { value: 'FIX_ASSET', label: 'Fix Asset' },
  { value: 'STORE_USE', label: 'Store Use' }
];

export const productStatusLOV = [
  { value: '', label: '(None)' },
  { value: ProductStatusEnum.SELECT, label: 'Select' },
  { value: ProductStatusEnum.NEW, label: 'New' },
  { value: ProductStatusEnum.SEASONAL, label: 'Seasonal' },
  { value: ProductStatusEnum.ACTIVE, label: 'Active' },
  { value: ProductStatusEnum.OUT_OF_STOCK, label: 'Out of Stock' },
  { value: ProductStatusEnum.HOLD_BUY, label: 'Hold (buy)' },
  { value: ProductStatusEnum.HOLD_BUY_SELL, label: 'Hold (buy,sell)' },
  { value: ProductStatusEnum.CLEAR, label: 'Clear' },
  { value: ProductStatusEnum.CLEAR_PROMOTION, label: 'Clear Promotion' },
  { value: ProductStatusEnum.RECALL, label: 'Recall' },
  { value: ProductStatusEnum.DELETE, label: 'Delist' }
];

export const assortmentRequestStatusLOV = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const pickingUnitLOV = [
  { value: 'CAR', label: 'CAR' },
  { value: 'PAC', label: 'PAC' },
  { value: 'BOX', label: 'BOX' },
  { value: 'BAG', label: 'BAG' },
  { value: 'PC', label: 'PC' }
];

export const classMarkupFilter = [
  { value: '', label: 'All Status' },
  { value: 'Y', label: 'Price Markup (yes)' },
  { value: 'N', label: 'Price Markup (no)' }
];

export const scheduleFilter = [
  { value: '', label: 'All Schedule' },
  { value: 'Y', label: 'Yes' },
  { value: 'N', label: 'No' }
];

export const merchantStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' }
];

export const merchantRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'WA', label: 'Awaiting Approval' },
  { value: 'WOS', label: 'Awaiting Order Schedule' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const requestTypeFilter = [
  { value: 'N', label: 'New' },
  { value: 'E', label: 'Edit' }
];
export const newRequestTypeFilter = [
  { value: 'NEW', label: 'New' },
  { value: 'EDIT', label: 'Edit' }
];

export const baseCurrency = [{ value: 'THB', label: 'THB - Thai Baht' }];
export const deliveryBy4Wheels = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];
export const gender = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' }
];
export const deliveryMethod = [
  { value: AssortmentDeliveryMethodEnum.SUPPLIER, label: 'Supplier (Direct to Store)' },
  { value: AssortmentDeliveryMethodEnum.TD, label: 'TD Warehouse' }
];

export const storeAssortmentEditType = [
  { value: 'ADD', label: 'Add' },
  { value: 'DELETE', label: 'Delete' }
];

export const storeAssortmentRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const keywordList = [
  { value: 'STORE_CODE', label: 'Store Code' },
  { value: 'STORE_NAME', label: 'Store Name' },
  { value: 'ARTICLE_NO', label: 'Article No.' },
  { value: 'BARCODE', label: 'Barcode' },
  { value: 'PRODUCT_NAME', label: 'Product Name' },
  { value: 'ALL', label: 'All' }
];

// Order List Page
export const orderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'NEW_ORDER', label: 'New' },
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'PARTIAL_DELIVERED', label: 'Partial Delivered' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELED_BY_SUPPLIER', label: 'Cancelled by Supplier' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'REJECTED_BY_ERP', label: 'Rejected by ERP' }
];

export const orderRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const orderingMethodLOV = [
  {
    value: '["FIRST_LOT_ORDER","SPECIAL_REQUEST"]',
    label: 'First Lot, Special Request'
  },
  {
    value: '["FIRST_LOT_ORDER","SPECIAL_REQUEST","STORE_REPLENISHMENT"]',
    label: 'First Lot, Special Request, Store Replenishment'
  },
  { value: '["FRESH_LITE"]', label: 'Fresh Lite' }
];

export const orderingMethodFilter = [
  { value: 'FIRST_LOT_ORDER', label: 'First Lot' },
  { value: 'SPECIAL_REQUEST', label: 'Special Request' },
  { value: 'STORE_REPLENISHMENT', label: 'Store Replenishment' },
  { value: 'FRESH_LITE', label: 'Fresh Lite' }
];

export const orderDeliveryFilter = [
  { value: 'TD', label: 'Warehouse' },
  { value: 'SUPPLIER', label: 'Supplier' }
];
// End Order List Page

export const deliveryOrderStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'NEW', label: 'New' },
  { value: 'PICKING', label: 'Picking' },
  { value: 'PICKED', label: 'Picked' },
  { value: 'LOADED', label: 'Loaded' },
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'DELIVERED', label: 'Delivered' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const shipmentStatusList = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING', label: 'Awaiting' },
  { value: 'AWAITING_DELETED', label: 'Awaiting Deleted' },
  { value: 'DISPATCHED', label: 'Dispatched' },
  { value: 'DELIVERED', label: 'Delivered' }
];

export const listStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const storeStepFilter = [
  { value: 'P', label: 'Profile' },
  { value: 'F', label: 'First Lot Order' },
  { value: 'C', label: 'Created Store' }
];

export const storeRequestTypeFilter = [
  { value: 'N', label: 'New' },
  { value: 'E', label: 'Edit' }
];

export const claimFromList = [
  { value: 'TD_WAREHOUSE', label: 'TD Warehouse' },
  { value: 'SUPPLIER', label: 'Supplier' },
  { value: 'LOGISTIC_PROVIDER', label: 'Logistic Provider' }
];

export const claimRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'WAITING', label: 'Awaiting Approval' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' }
];

export const promotionRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const promotionStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const storeRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'D', label: 'Draft' },
  { value: 'WA', label: 'Awaiting Approval' },
  { value: 'WOS', label: 'Awaiting Order Schedule' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const promotionBy = [
  { value: 'SUPPLIER', label: 'Supplier' },
  { value: 'TD', label: 'TD Tawandang' }
];

export const promotionType = [
  { value: '1', label: 'แถม 1' },
  { value: '2', label: 'แถม 1 (บังคับซื้อหลายชิ้น)' },
  { value: '3', label: 'ราคาพิเศษ' },
  { value: '4', label: 'ราคาพิเศษ (บังคับซื้อหลายชิ้น)' } // Disabled { value: '5', label: 'ซื้อคู่ถูกกว่า' }
];

//  remove Jira : BO-1443
export const prStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'AWAITING_CREATE_PO', label: 'Awaiting Create PO' },
  { value: 'COMPLETED', label: 'PR Completed' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const poStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AWAITING_SEND_PO', label: 'Awaiting Send PO' },
  { value: 'AWAITING_SUPPLIER_RESPONSE', label: 'Awaiting Supplier Response' },
  { value: 'AWAITING_GR', label: 'Awaiting GR' },
  { value: 'GR_COMPLETED', label: 'GR Completed' },
  { value: 'CLOSED', label: 'Closed PO' },
  { value: 'AWAITING_CLOSED', label: 'Awaiting Closed PO' },
  { value: 'CANCELLED', label: 'Cancelled' },
  { value: 'AWAITING_CANCELLED', label: 'Awaiting Cancelled' }
];

export const grStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'RECEIVED', label: 'Received' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const prTypeFilter = [
  { value: 'Z9', label: 'Z9' },
  { value: 'Z9_EDIT', label: 'Z9-Edit' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const createPrTypeFilter = [
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const dayIndexList = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 7, label: 'Sunday' }
];

export const timeRangeTwentyFour = () => {
  const t: any[] = [];

  for (let i = 0; i < 24; i++) {
    const s = numeral(i).format('00') + ':00';
    t.push({ value: s, label: s });
  }

  t.push({ value: '23:59', label: '23:59' });

  return t;
};

export const articleTypeList = [
  { value: '', label: 'All ArticleType' },
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const prArticleTypeList = [
  { value: 'M', label: 'Merchandise' },
  { value: 'N', label: 'Non Merchandise' }
];

export const purchaseRequestTypeLOV = [
  { value: 'Z9', label: 'Z9' },
  { value: 'Z9_EDIT', label: 'Z9-Edit' },
  { value: 'WAREHOUSE', label: 'Warehouse' },
  { value: 'HOT_PRICE', label: 'Hot Price' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const portalModuleList = [
  { value: '', label: 'All Task' },
  { value: 'CLAIM', label: 'Claim' },
  { value: 'MERCHANT', label: 'Merchant' },
  { value: 'ORDER', label: 'Order' },
  { value: 'PRODUCT', label: 'Product' },
  { value: 'PROMOTION', label: 'Promotion' },
  { value: 'PURCHASE_REQUEST', label: 'Purchase Request' },
  { value: 'SHELF_REQUEST', label: 'Shelf' },
  { value: 'STORE', label: 'Store' },
  { value: 'VOUCHER', label: 'Voucher' }
];

export const campaignList = [
  { code: 'XX-1234', name: 'New Store' },
  { code: 'MIN_PURCHASE', name: 'Minimum Purchase' }
];

export const supplierGroupRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const supplierStoreRequestStatus = [
  { value: 'ADD', label: 'Add' },
  { value: 'EDIT', label: 'Edit' },
  { value: 'DELETE', label: 'Delete' }
];

export const supplierNameFilter = [
  { value: true, label: 'Completed' },
  { value: false, label: 'Blank' }
];

export const voucherRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'W', label: 'Awaiting Approval' },
  { value: 'A', label: 'Approved' },
  { value: 'R', label: 'Rejected' },
  { value: 'C', label: 'Cancelled' }
];

export const voucherStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'AS', label: 'Awaiting Schedule' },
  { value: 'A', label: 'Active' },
  { value: 'E', label: 'Expired' },
  { value: 'C', label: 'Cancelled' }
];

export const supplierGroupListStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'INACTIVE', label: 'Inactive' }
];

export const selectedSupplierFilter = [
  { value: true, label: 'Completed' },
  { value: false, label: 'Incompleted' }
];

export const shelfRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'DRAFT', label: 'Draft' },
  { value: 'AWAITING_APPROVAL', label: 'Awaiting Approval' },
  { value: 'AWAITING_FIRST_LOT', label: 'Awaiting First Lot Quantity' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'CANCELLED', label: 'Cancelled' }
];

export const selectedActive = [
  { value: '', label: 'All Status' },
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
];

export const selectedPromotionStore = [
  { value: SelectedPromotionStoreEnum.ALL_STORE, label: 'All Stores' },
  { value: SelectedPromotionStoreEnum.SELECT_BY_STORE, label: 'Select by Store' }
];

export const freshLiteTypeLOV = [
  { value: AssortmentFreshLiteTypeEnum.KEEP_STOCK, label: 'Keep Stock' },
  { value: AssortmentFreshLiteTypeEnum.EXPENSE, label: 'Expense' }
];

export const locationType = [
  { value: LocationTypeEnum.WAREHOUSE, label: 'Warehouse', regex: /stkadj_m_([a-zA-Z]{2}[0-9]{1,3})$/ },
  { value: LocationTypeEnum.STORE, label: 'Store', regex: /^stkadj_m_sto$/ }
];

export const stockType = [
  { value: '1', label: 'Normal Price' },
  { value: '2', label: 'Special Price' }
];

export const compensateType = [
  { value: 'PROMOTION', label: 'Promotion' },
  { value: 'VOUCHER', label: 'Voucher' }
];

export const adjustTypeList = [
  { value: AdjustTypeEnum.RECEIVE_ORDER, label: 'Receive Order', allowedLocationType: [LocationTypeEnum.STORE] },
  {
    value: AdjustTypeEnum.INVENTORY_COUNT,
    label: 'Inventory Count',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE, LocationTypeEnum.STORE]
  },
  { value: AdjustTypeEnum.PURCHASE, label: 'Purchase', allowedLocationType: [LocationTypeEnum.WAREHOUSE] },
  {
    value: AdjustTypeEnum.DESTROY,
    label: 'Destroy',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE, LocationTypeEnum.STORE]
  },
  { value: AdjustTypeEnum.CLAIM, label: 'Claim', allowedLocationType: [] },
  { value: AdjustTypeEnum.TRANSFER, label: 'Transfer', allowedLocationType: [LocationTypeEnum.STORE] },
  { value: AdjustTypeEnum.VOID_RECEIPT, label: 'Void Receipt', allowedLocationType: [LocationTypeEnum.STORE] },
  {
    value: AdjustTypeEnum.RETURN_TO_SUPPLIER,
    label: 'Return to Supplier',
    allowedLocationType: [LocationTypeEnum.STORE]
  },
  { value: AdjustTypeEnum.INTERNAL_USE, label: 'Internal Use', allowedLocationType: [LocationTypeEnum.WAREHOUSE] },
  {
    value: AdjustTypeEnum.RETURN_TO_WAREHOUSE,
    label: 'Return to Warehouse',
    allowedLocationType: [LocationTypeEnum.WAREHOUSE]
  }
];

export const adjustStockStatusList = [
  { value: '', label: 'All Status' },
  { value: 'NEW', label: 'New' },
  { value: 'COMPLETED', label: 'Completed' },
  { value: 'FAILED', label: 'Failed' }
];

export const salesType = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'PROMOTION', label: 'Promotion' }
];

export const profitSharingStatus = [
  { value: '', label: 'All Status' },
  { value: 'POSITIVE', label: 'Positive' },
  { value: 'NEGATIVE', label: 'Negative' }
];

export const z8ParameterStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'ACTIVE', label: 'Active' },
  { value: 'AWAITING_SCHEDULE', label: 'Awaiting Schedule' }
];

export const z8ParameterRequestStatusFilter = [
  { value: '', label: 'All Status' },
  { value: 'COMPLETED', label: 'Completed' }
];

export const billPaymentStatusFilter = [
  { value: BillPaymentStatusEnum.AWAITING_PAYMENT, label: 'Awaiting Payment' },
  { value: BillPaymentStatusEnum.PAID, label: 'Paid' },
  { value: BillPaymentStatusEnum.NO_OUTSTANDING_BALANCE, label: 'No Outstanding Balance' },
  { value: BillPaymentStatusEnum.AWAITING_CONFIRM, label: 'Awaiting Confirmation' }
];

export const dateThirtyOne = () => {
  const t: any[] = [];

  for (let i = 1; i <= 31; i++) {
    t.push({ value: i, label: i });
  }

  return t;
};

export const z8ParameterType = [
  { value: 'PERCENT', label: 'Percent' },
  { value: 'FIXED', label: 'Fixed' }
];

export const z8ParameterFactor = [
  {
    parameterName: 'Product Factor',
    format: '0,0.[00]',
    minValue: -999.99,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: true
  },
  {
    parameterName: 'Weekend',
    format: '0,0.[00]',
    minValue: 1,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: false
  },
  {
    parameterName: 'Beginning Month',
    format: '0,0.[00]',
    minValue: 1,
    maxValue: 999.99,
    decimals: 2,
    maxLength: 6,
    allowNegative: false
  },
  {
    parameterName: 'Promotion',
    format: '0,0',
    minValue: 1,
    maxValue: 99999,
    decimals: 0,
    maxLength: 5,
    allowNegative: false
  },
  {
    parameterName: 'Max Target',
    format: '0,0',
    minValue: 1,
    maxValue: 99999,
    decimals: 0,
    maxLength: 5,
    allowNegative: false
  }
];

export const locationLevelWarehouseLOV = [
  { value: 'ALL_DCS', label: 'All DCs' },
  { value: 'BY_DC', label: 'By DC' }
];

export const locationAllWarehouseLOV = [{ value: 'All DCs', label: 'All DCs' }];

export const locationLevelSupplierLOV = [
  { value: 'ALL_STORES', label: 'All Stores' },
  { value: 'BY_PROVINCE', label: 'By Province' },
  { value: 'BY_STORE', label: 'By Store' }
];

export const locationAllStoresLOV = [{ value: 'All Stores', label: 'All Stores' }];
