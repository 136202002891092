import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { untilComponentDestroyed } from '@w11k/ngx-componentdestroyed';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { OrderContent } from '../../../../shared/models/order.model';
import { selectViewOrder } from '../../../../shared/store/selectors/order.selectors';
import { AppStates } from '../../../../shared/store/state/app.states';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit, OnDestroy, AfterContentChecked {
  private localStore: Observable<any>;
  viewOrder$: Observable<OrderContent>;

  currentPage = 1;
  pageSize = 20;

  constructor(
    public readonly store: Store<AppStates>,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.localStore = this.store.pipe(untilComponentDestroyed(this));
    this.viewOrder$ = this.localStore.pipe(
      select(selectViewOrder),
      distinctUntilChanged(),
      filter(viewOrder => viewOrder !== null)
    );
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getItemStatus(status) {
    return status ? this.translate.instant('ORDER.ITEM_STATUS.' + status) : null;
  }

  ngOnDestroy(): void {}
}
