import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MerchantRequestList, MerchantRequestListSearchCriteria, MerchantRequestViewResponse } from '../../models';
import { MerchantActions, MerchantActionType } from '../actions/merchant.actions';

export interface MerchantRequestState extends EntityState<MerchantRequestList> {
  isLoading: boolean;
  selected: MerchantRequestViewResponse;
  criteriaObject: MerchantRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
  isDeleteSuccess: boolean;
  auditLogs: any;
}

export const adapter: EntityAdapter<MerchantRequestList> = createEntityAdapter<MerchantRequestList>();

export const initialMerchantRequestListState: MerchantRequestState = adapter.getInitialState({
  isLoading: false,
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 0,
  isDeleteSuccess: false,
  auditLogs: null
});

export function merchantRequestReducers(
  state = initialMerchantRequestListState,
  action: MerchantActions
): MerchantRequestState {
  switch (action.type) {
    case MerchantActionType.MERCHANT_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case MerchantActionType.MERCHANT_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.merchantRequests.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.merchantRequests.page,
          size: action.payload.merchantRequests.size
        },
        totalElements: action.payload.merchantRequests.totalElements,
        totalPages: action.payload.merchantRequests.totalPages
      });
    case MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_RESPONSE:
      return {
        ...state,
        selected: { ...action.payload.merchantRequestView }
      };
    case MerchantActionType.MERCHANT_REQUEST_DELETE_RESPONSE:
      return {
        ...state,
        isDeleteSuccess: action.payload.isSuccess
      };
    case MerchantActionType.MERCHANT_REQUEST_DELETE_RESET:
      return {
        ...state,
        isDeleteSuccess: false
      };
    case MerchantActionType.MERCHANT_REQUEST_GET_BY_ID_RESET:
      return {
        ...state,
        selected: null
      };
    case MerchantActionType.MERCHANT_GET_VERSION_RESPONSE:
      return {
        ...state,
        selected: {
          ...state.selected,
          version: action.payload.version
        }
      };
    case MerchantActionType.MERCHANT_REQUEST_RESET:
      return initialMerchantRequestListState;
    case MerchantActionType.MERCHANT_REQUEST_HISTORY_RESPONSE:
      return {
        ...state,
        auditLogs: action.payload.auditLogs
      };
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
