<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">{{ title }}</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close()" id="closeBtn">
      <span aria-hidden="true"><em class="icon-close"></em></span>
    </button>
  </div>
  <div class="modal-body" [formGroup]="shipmentForm">
    <div class="row mb-3" *ngIf="showNo">
      <div class="col-md-6 col-sm-12 col-12">
        <h6 class="pr-2">Shipment No.: {{ shipmentNo }}</h6>
      </div>
      <div class="col-md-6 col-sm-12 col-12 shipment-status-align">
        <h6 class="d-inline pr-2">Shipment Status:</h6>
        <span class="shipment-status" [ngClass]="getColorStatusShipment(form.controls.status?.value)">{{
          'SHIPMENT.SHIPMENT_STATUS.' + form.controls.status?.value | translate
        }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryBy">Delivery By<span class="text-danger">*</span></label>
          <ng-select
            id="deliveryBy"
            [items]="supplierList | async"
            bindLabel="supplierName"
            dropdownPosition="auto"
            labelForId="album"
            placeholder="Please select..."
            notFoundText="No Data"
            [loading]="supplierSearchLoading"
            [typeahead]="supplierSearchInput$"
            [virtualScroll]="true"
            formControlName="deliveryBy"
            (blur)="onBlurSupplierName()"
            [ngClass]="{ 'is-invalid': submitted && form.controls.deliveryBy.errors }"
          >
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
              <div>
                <strong>Supplier Name:</strong>
                <span [ngOptionHighlight]="search" class="ml-1">
                  {{ item.supplierName }}
                </span>
              </div>
              <small>
                <strong>Vendor No:</strong
                ><span [ngOptionHighlight]="search" class="ml-1">{{ item.supplierCode }}</span
                >{{ ' | ' }} <strong>Branch No:</strong
                ><span [ngOptionHighlight]="search" class="ml-1">{{ item.branchNo }}</span>
              </small>
            </ng-template>
          </ng-select>
          <div *ngIf="submitted && form.controls.deliveryBy.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="shipCharge">Ship Charge<span class="text-danger">*</span></label>
          <app-numeric-textbox
            formControlName="shipCharge"
            id="shipCharge"
            [format]="'0,0.[00]'"
            [maxValue]="99999.99"
            maxlength="8"
            placeholder="Ship Charge Amount"
            [ngClass]="{
              'is-invalid': submitted && form.controls.shipCharge.errors
            }"
          >
          </app-numeric-textbox>
          <div *ngIf="submitted && form.controls.shipCharge.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="plateNo"
            >Plate No.<span
              *ngIf="![shipmentTypeEnum.CREATE, shipmentTypeEnum.VIEW].includes(mode)"
              class="text-danger"
              >*</span
            ></label
          >
          <input
            type="text"
            class="form-control"
            maxlength="10"
            id="plateNo"
            placeholder="Plate No."
            formControlName="plateNo"
            [ngClass]="{
              'is-invalid': submitted && form.controls.plateNo.errors
            }"
          />
          <div *ngIf="submitted && form.controls.plateNo.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="driverName"
            >Driver Name<span
              *ngIf="![shipmentTypeEnum.CREATE, shipmentTypeEnum.VIEW].includes(mode)"
              class="text-danger"
              >*</span
            ></label
          >
          <input
            type="text"
            class="form-control"
            maxlength="25"
            id="driverName"
            placeholder="Driver Name"
            formControlName="driverName"
            [ngClass]="{
              'is-invalid': submitted && form.controls.driverName.errors
            }"
          />
          <div *ngIf="submitted && form.controls.driverName.errors?.required" class="invalid-display">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label for="deliveryDate">Delivery Date<span class="text-danger">*</span></label>
          <input
            #deliveryDate
            id="deliveryDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': submitted && form.controls.deliveryDate.errors
            }"
            bsDatepicker
            [bsConfig]="bsConfig"
            (bsValueChange)="onChangeDate($event, deliveryDate, true)"
            formControlName="deliveryDate"
            readonly
          />
          <label for="deliveryDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="form.controls.isWarningAfterDeliveryDate.value" class="warning-display">
            Delivery Date is after Req.Delivery Date.
          </div>
          <div *ngIf="submitted && form.controls.deliveryDate.errors" class="invalid-feedback">
            <div *ngIf="form.controls.deliveryDate.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label for="pickUpDate">Pick Up Date<span class="text-danger">*</span></label>
          <input
            #pickUpDate
            id="pickUpDate"
            name="pickUpDate"
            type="text"
            placeholder="{{ dateFormat }}"
            class="form-control calendar"
            autocomplete="off"
            [ngClass]="{
              'is-invalid': submitted && form.controls.pickUpDate.errors
            }"
            bsDatepicker
            [bsConfig]="bsConfig"
            (bsValueChange)="onChangeDate($event, pickUpDate)"
            formControlName="pickUpDate"
            readonly
          />
          <label for="pickUpDate" class="icon-calendar btn-calendar"></label>
          <div *ngIf="submitted && form.controls.pickUpDate.errors" class="invalid-feedback">
            <div *ngIf="form.controls.pickUpDate.errors?.required">
              {{ 'ERRORS.REQUIRED' | translate }}
            </div>
            <div *ngIf="form.controls.pickUpDate.errors?.afterDate">
              Pick Up Date is after Delivery Date.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label for="datatable">Delivery Order List</label>
        <div class="table-responsive">
          <table id="datatable" class="table table-striped table-bordered table-hover w-100">
            <thead>
              <tr>
                <th class="text-center">No.</th>
                <th class="text-center">Deliver Order No.</th>
                <th class="text-center">Req. Delivery Date</th>
                <th class="text-center">Store Name</th>
                <th class="text-center" *ngIf="mode !== 'create'">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let deliveryOrder of doList; index as i">
                <td class="text-center">
                  <!--Need to change late: M updated this and related with BO-999 [Delivery Order] Green flag is overlapped with Delivery Order List item number-->
                  <em *ngIf="deliveryOrder.isCurrentDO" class="fa nicon flag-green"></em>
                  {{ i + 1 }}
                </td>
                <td>{{ deliveryOrder.doNo }}</td>
                <td class="text-center">{{ deliveryOrder.requestedDeliveryDate | amDateFormat | dateDisplay }}</td>
                <td>{{ deliveryOrder.storeCode + '-' + deliveryOrder.storeName }}</td>
                <td
                  class="text-center do-status"
                  *ngIf="mode !== 'create'"
                  [ngClass]="getColorStatusDeliveryOrder(deliveryOrder?.doStatus)"
                >
                  {{ 'DELIVERY_ORDER.DELIVERY_ORDER_STATUS.' + deliveryOrder?.doStatus | translate }}
                </td>
              </tr>
            </tbody>
          </table>
          <div *ngIf="submitted && itemIsLoading" class="invalid-display">
            Not allow to dispatch because some delivery order is waiting for loading.
          </div>

          <div
            class="form-check"
            *ngIf="mode === shipmentTypeEnum.LOAD"
            [ngClass]="{ 'bg-confirm-required': submitted && form.controls.confirmLoad.errors }"
          >
            <input
              type="checkbox"
              id="confirmLoad"
              name="confirmLoad"
              class="form-check-input"
              formControlName="confirmLoad"
              (click)="onSelectConfirm($event)"
            />
            <label class="form-check-label" for="confirmLoad">
              Confirm to load Delivery into this shipment.
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!itemIsLoading && mode !== shipmentTypeEnum.VIEW">
    <button type="button" class="btn btn-standard" (click)="close()" id="cancelBtn">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="validateSubmit()"
      [isFormInvalid]="form.invalid"
      id="okBtn"
      appDisableButton
    >
      {{ mode === 'delete' ? 'Yes, delete' : 'OK' }}
    </button>
  </div>
</div>
