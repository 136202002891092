import { round } from 'lodash';

export class ItemPrice<T> {
  private readonly item: T;
  private readonly qty: number;

  constructor(item: T, qtyField: string) {
    this.item = item;
    this.qty = this.item[qtyField];
  }

  getTotalFrom(field: string) {
    return getPriceFormat(round(this.item[field].amount, 2) * this.qty, this.item[field].currency);
  }
}

export const getPriceFormat = (amount = 0, currency = 'THB') => {
  return {
    amount,
    currency
  };
};
