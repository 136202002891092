import { createEntityAdapter } from '@ngrx/entity';
import { StoreAssortmentActionType } from '../actions/store-assortment.actions';
const ɵ0 = list => list.storeNo;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialStoreAssortmentResponseState = adapter.getInitialState({
    selected: null,
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 20,
    auditLogs: null
});
export function storeAssortmentReducers(state = initialStoreAssortmentResponseState, action) {
    switch (action.type) {
        case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { criteriaObject: Object.assign({}, state.criteriaObject, { page: action.payload.page, size: action.payload.size }), totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESPONSE:
            return Object.assign({}, state, { selected: action.payload });
        case StoreAssortmentActionType.STORE_ASSORTMENT_GET_BY_ID_RESET:
            return Object.assign({}, state, { selected: null });
        case StoreAssortmentActionType.STORE_ASSORTMENT_LIST_HISTORY_RESPONSE:
            return Object.assign({}, state, { auditLogs: action.payload.auditLogs });
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
