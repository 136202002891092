import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToteTransactionDCReportCriteria, ToteTransactionFromStoreReportCriteria } from '../models/report.model';
import { BaseService } from './base.service';

@Injectable()
export class ToteTransactionService extends BaseService {
  public headers: HttpHeaders;

  constructor(private readonly http: HttpClient) {
    super();
    this.envService = this.env.services.toteTransaction;
    this.headers = new HttpHeaders(this.envService.headers);
  }

  public exportDC(criteria: ToteTransactionDCReportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportDC);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
  public exportStore(criteria: ToteTransactionFromStoreReportCriteria): Observable<any> {
    const params = this.getParams(criteria);
    const url = this.getFullUrl(this.envService.exportStore);

    return this.http.get<any>(url, {
      headers: this.loaderHeaders(),
      observe: 'body',
      params,
      responseType: 'blob' as 'json'
    });
  }
}
