import { createSelector } from '@ngrx/store';
import { AppStates } from '../state/app.states';
import { LayoutState } from '../state/layout.state';

const selectLayout = (state: AppStates) => state.layout;

export const selectLayoutError = createSelector(selectLayout, (state: LayoutState) => {
  return state.isApiError;
});

export const selectVersionError = createSelector(selectLayout, (state: LayoutState) => {
  return state.isVersionError;
});

export const selectSaveSuccess = createSelector(selectLayout, (state: LayoutState) => {
  return state.saveSuccess;
});
