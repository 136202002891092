import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AuditLog } from '../../models/audit-log.model';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
  public title: string;
  public historyHeader: string;
  public historyType: string;
  public action: string;
  public auditLogs: AuditLog[];
  public auditLogs$: Observable<AuditLog[]>;
  public dateTimeDisplay = environment.dateTimeDisplay;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {}

  ngOnDestroy() {}

  decline(): void {
    this.bsModalRef.hide();
  }
}
