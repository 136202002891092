import { createSelector } from '@ngrx/store';
import * as fromMerchantCreateResponseState from '../reducers/merchant-create.reducers';
import { AppStates } from '../state/app.states';

const selectMerchantCreateState = (state: AppStates) => state.merchantCreate;

export const selectMerchant = createSelector(
  selectMerchantCreateState,
  (state: fromMerchantCreateResponseState.MerchantCreateResponseState | null) => state
);

export const selectMerchantApproveRejectStatus = createSelector(
  selectMerchantCreateState,
  (state: fromMerchantCreateResponseState.MerchantCreateResponseState) => {
    return state.isApproveRejectSuccess;
  }
);
