/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./forgot-username-left.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./forgot-username-left.component";
import * as i4 from "@angular/router";
var styles_ForgotUsernameLeftComponent = [i0.styles];
var RenderType_ForgotUsernameLeftComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ForgotUsernameLeftComponent, data: {} });
export { RenderType_ForgotUsernameLeftComponent as RenderType_ForgotUsernameLeftComponent };
export function View_ForgotUsernameLeftComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "left-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["class", "forgot-section-header"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["class", "btn btn-standard text-capitalize"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("TD_NEST")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("FORGOT_USERNAME.FORGOT_USERNAME")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform("FORGOT_USERNAME.PLEASE_ENTER_YOUR_MOBILE_NUMBER")); _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform("CANCEL")); _ck(_v, 12, 0, currVal_3); }); }
export function View_ForgotUsernameLeftComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-forgot-username-left", [], null, null, null, View_ForgotUsernameLeftComponent_0, RenderType_ForgotUsernameLeftComponent)), i1.ɵdid(1, 114688, null, 0, i3.ForgotUsernameLeftComponent, [i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ForgotUsernameLeftComponentNgFactory = i1.ɵccf("app-forgot-username-left", i3.ForgotUsernameLeftComponent, View_ForgotUsernameLeftComponent_Host_0, {}, {}, []);
export { ForgotUsernameLeftComponentNgFactory as ForgotUsernameLeftComponentNgFactory };
