import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import {
  Z8ParameterRequestList,
  Z8ParameterRequestListSearchCriteria
} from '../../models/z8-parameter-request.model';
import { Z8ParameterRequestActions, Z8ParameterRequestActionType } from '../actions/z8-parameter-request.actions';
import { BaseState } from '../state/base.state';

export interface Z8ParameterRequestState extends EntityState<Z8ParameterRequestList>, BaseState {
  selected: Z8ParameterRequestList;
  criteriaObject: Z8ParameterRequestListSearchCriteria;
  totalElements: number;
  totalPages: number;
}

export const adapter: EntityAdapter<Z8ParameterRequestList> = createEntityAdapter<Z8ParameterRequestList>();

export const initialZ8ParameterRequestResponseState: Z8ParameterRequestState = adapter.getInitialState({
  selected: null,
  criteriaObject: {
    page: 0,
    size: 20
  },
  totalElements: 0,
  totalPages: 20,
  auditLogs: null,
  isDeleteSuccess: null
});

export function Z8ParameterRequestReducers(
  state = initialZ8ParameterRequestResponseState,
  action: Z8ParameterRequestActions
): Z8ParameterRequestState {
  switch (action.type) {
    case Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_REQUEST:
      return {
        ...state,
        criteriaObject: action.payload
      };
    case Z8ParameterRequestActionType.Z8_PARAMETER_REQUEST_LIST_RESPONSE:
      return adapter.addAll(action.payload.content, {
        ...state,
        criteriaObject: {
          ...state.criteriaObject,
          page: action.payload.page,
          size: action.payload.size
        },
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages
      });
    default: {
      return state;
    }
  }
}

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
