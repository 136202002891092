<div class="modal-content admin-form">
  <div class="modal-header">
    <h6 class="modal-title pull-left">Update Order Schedule</h6>
    <button type="button" class="close pull-right" aria-label="Close" (click)="onCancel()" id="closeBtn">
      <em class="icon-close"></em>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <app-order-policy #orderPolicy [parentForm]="form" [submitted]="submitted" [mode]="mode" [page]="page">
      </app-order-policy>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-standard" (click)="onCancel()" id="cancelBtn">
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmit()"
      [isFormInvalid]="form.invalid"
      id="okBtn"
      appDisableButton
    >
      Submit
    </button>
  </div>
</div>
