import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { SelectedPromotionStoreEnum } from '../enum/promotion.enum';
import { Sort } from './pagination.model';

export class PromotionCreateRequest {
  promotionCreatRequest: PromotionRequestTemplate;
}

export class PromotionRequestTemplate {
  id: string;
  version: number;
  requestNo: string;
  type: string;
  status: string;
  details: PromotionDetails;
  condition: Condition;
  promotionItems?: PromotionItem[];
  coPromotionItems?: PromotionItem[];
  selectStore?: SelectedPromotionStoreEnum;
  stores?: Stores[];
}

export interface PromotionRequestResponse {
  id: string;
  version: number;
  requestNo: string;
  promotionCode?: string;
  type: string;
  status: string;
  details: PromotionDetails;
  condition: Condition;
  promotionItems?: PromotionItem[];
  coPromotionItems?: PromotionItem[];
  selectStore?: SelectedPromotionStoreEnum;
  stores?: Stores[];
}

export interface Stores {
  code: string;
  name: string;
  merchantType: string;
  region: string;
  state: string;
}

export interface RequestWithComment {
  id: string;
  comment: string;
}

export interface Condition {
  buyQty: number;
  totalPrice: Money;
  averageUnitPrice: Money;
  buyCoItemQty: number;
  selectedRetailPrice: Money;
}

export class PromotionDetails {
  promotionName: string;
  promotionType: PromotionTypeEnum;
  promotionBy: string;
  refPromotionCode: string;
  forceBuyQty: number;
  limitPerBill: number;
  effectiveDate: string;
  expireDate: string;
  supplier: Supplier;
  theme: ThemeDetail;
  promotionTypeDisplayName?: string;
  stockType: StockTypeEnum;
}

export interface ThemeDetail {
  id: string;
  name: string;
  effectiveDate?: string;
  expireDate?: string;
}

export class PromotionItem {
  barcode: string;
  articleNo: string;
  productName: string;
  unit: string;
  unitFactor: number;
  movingAverage: Money;
  retailPrice: Money;
  discountCode: string;
  supplierCompensate: Money;
  partnerCompensate: Money;
  errorMessage: any;
}

export class PromotionTheme {
  id?: string;
  name: string;
  effectiveDate: string;
  expireDate: string;
  value?: string;
  label?: string;
  selected?: boolean;
}

export class PromotionSearchCriteria {
  searchCriteria?: string;
  status?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
  validDateFrom?: string;
  validDateTo?: string;
  expireDateFrom?: string;
  promotionType?: PromotionTypeEnum[];
  expireDateTo?: string;
  page: number;
  size: number;
}

export class PromotionExportCriteria {
  status?: string;
  validDateFrom?: string;
  validDateTo?: string;
  effectiveDateFrom?: string;
  effectiveDateTo?: string;
}

export interface PromotionContent {
  id: string;
  promotionCode: string;
  theme: string;
  promotionName: string;
  promotionTypeDisplayName?: string;
  effectiveDate: string;
  expireDate: string;
  status: string;
  promotionType: PromotionTypeEnum;
  createdDate: string;
  forceBuyQty: number;
  auditLogs: AuditLog[];
}

export class PromotionListResponse {
  id: number;
  content: PromotionContent[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface PromotionRequest {
  id: string;
  requestNo: string;
  theme: string;
  promotionTypeDisplayName?: string;
  promotionName: string;
  effectiveDate: string;
  expireDate: string;
  status: string;
  createdBy: string;
  createdDate: string;
  auditLogs: AuditLog[];
}

export class PromotionRequestListResponse {
  id: number;
  content: PromotionRequest[];
  totalElements: number;
  totalPages: number;
  last: boolean;
  page: number;
  size: number;
  number: number;
  sort: Sort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

export interface AuditLog {
  activity: string;
  description: string;
  editBy: string;
  editByName: string;
  editDate: string;
}

export interface Supplier {
  id: string;
  supplierCode: string;
  supplierName: string;
}

export interface PromotionRequestError {
  code: string;
  message?: string;
  translateKey?: string;
  data?: DataPromotionItem;
}

export interface DataPromotionItem {
  promotionItems: PromotionItemError[];
  coPromotionItems: PromotionItemError[];
}

export interface PromotionItemError {
  barcode: string;
  errorMessage: PromotionItemErrorEnum;
}

export interface PromotionCommonError {
  code: string;
  message: string;
}

export interface Money {
  amount: number;
  currency?: string;
}

// export interface RootObject {
//   code: string;
//   data: Data;
// }

export enum PromotionByEnum {
  SUPPLIER = 'SUPPLIER',
  TD = 'TD'
}

export enum StockTypeEnum {
  NORMAL_PRICE = '1',
  SPECIAL_PRICE = '2'
}

export enum PromotionTypeEnum {
  /** @description แถม 1 */
  BUY_GET_ONE_FREE = '1',
  /** @description แถม 1 บังคับซื้อหลายชิ้น */
  BUY_MORE_GET_ONE_FREE = '2',
  /** @description ราคาพิเศษ */
  SPECIAL_PRICE = '3',
  /** @description ราคาพิเศษ(บังคับซื้อหลายชิ้น) */
  MORE_SPECIAL_PRICE = '4',
  /** @description ซื้อคู่ถูกกว่า 'not implemented' */
  BUY_TWO_SAVE_MORE = '5'
}

export enum PromotionRequestPageModes {
  REQUEST_VIEW = 'REQUEST_VIEW',
  REQUEST_EDIT = 'REQUEST_EDIT',
  REQUEST_CREATE = 'REQUEST_CREATE',
  VIEW = 'VIEW'
}

export enum PromotionItemErrorEnum {
  INVALID_BARCODE = 'INVALID_BARCODE',
  INACTIVE_BARCODE = 'INACTIVE_BARCODE',
  STATUS_IS_DELISTED = 'STATUS_IS_DELISTED',
  RESTRICT_ITEM = 'RESTRICT_ITEM',
  DIFFERENT_PRICE = 'DIFFERENT_PRICE',
  NOT_ALLOW_INVENTORY = 'NOT_ALLOW_INVENTORY',
  NOT_ALLOW_FIX_ASSET = 'NOT_ALLOW_FIX_ASSET',
  NOT_ALLOW_STORE_USE = 'NOT_ALLOW_STORE_USE',
  NOT_ALLOW_ZERO = 'NOT_ALLOW_ZERO'
}

export class PromotionTransFrom {
  public static getAmount(value: any): number {
    if (value === null || typeof value === 'undefined') {
      return null;
    }

    if (typeof value.amount === 'number') {
      return value.amount;
    }

    return value as number;
  }

  public static getMoney(value: any): Money {
    if (value === null) {
      return null;
    }

    if (typeof value === 'number') {
      return { amount: value, currency: 'THB' };
    }

    return value as Money;
  }

  public static mappingPromotionTimeBkktoUtc(detail: PromotionDetails) {
    let effective = null;
    let expire = null;

    if (detail.effectiveDate) {
      effective = this.convertBkkToUtc(detail.effectiveDate);
    }

    if (detail.expireDate) {
      expire = this.convertBkkToUtc(detail.expireDate);
    }

    return {
      ...detail,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionDetails;
  }

  public static mappingPromotionTimeUtcToBkk(detail: PromotionDetails) {
    let effective = null;
    let expire = null;

    if (detail.effectiveDate) {
      effective = this.convertUtcToBkk(detail.effectiveDate);
    }

    if (detail.expireDate) {
      expire = this.convertUtcToBkk(detail.expireDate);
    }

    const objDetail = {
      ...detail,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionDetails;

    return objDetail;
  }

  public static mappingPromotionThemeTimeBkktoUtc(theme: PromotionTheme) {
    let effective = null;
    let expire = null;

    if (theme.effectiveDate) {
      effective = this.convertBkkToUtc(theme.effectiveDate);
    }

    if (theme.expireDate) {
      expire = this.convertBkkToUtc(theme.expireDate);
    }

    return {
      ...theme,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionTheme;
  }

  public static mappingPrmotionThemeTimeUtcToBkk(theme: PromotionTheme) {
    let effective = null;
    let expire = null;

    if (theme.effectiveDate) {
      effective = this.convertUtcToBkk(theme.effectiveDate);
    }

    if (theme.expireDate) {
      expire = this.convertUtcToBkk(theme.expireDate);
    }

    return {
      ...theme,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionTheme;
  }

  public static mappingPromotionContentTimeBkktoUtc(content: PromotionContent) {
    let effective = null;
    let expire = null;

    if (content.effectiveDate) {
      effective = this.convertBkkToUtc(content.effectiveDate);
    }

    if (content.expireDate) {
      expire = this.convertBkkToUtc(content.expireDate);
    }

    return {
      ...content,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionContent;
  }

  public static mappingPromotionContentTimeUtcToBkk(content: PromotionContent) {
    let effective = null;
    let expire = null;

    if (content.effectiveDate) {
      effective = this.convertUtcToBkk(content.effectiveDate);
    }

    if (content.expireDate) {
      expire = this.convertUtcToBkk(content.expireDate);
    }

    return {
      ...content,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionContent;
  }

  public static mappingPromotionRequestTimeBkktoUtc(request: PromotionRequest) {
    let effective = null;
    let expire = null;

    if (request.effectiveDate) {
      effective = this.convertBkkToUtc(request.effectiveDate);
    }

    if (request.expireDate) {
      expire = this.convertBkkToUtc(request.expireDate);
    }

    return {
      ...request,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionRequest;
  }

  public static mappingPromotionRequestTimeUtcToBkk(request: PromotionRequest) {
    let effective = null;
    let expire = null;

    if (request.effectiveDate) {
      effective = this.convertUtcToBkk(request.effectiveDate);
    }

    if (request.expireDate) {
      expire = this.convertUtcToBkk(request.expireDate);
    }

    return {
      ...request,
      effectiveDate: effective,
      expireDate: expire
    } as PromotionRequest;
  }

  public static convertBkkToUtc(bkkDate: string) {
    const timeZone = 7;
    let utcDate = null;

    if (bkkDate && bkkDate !== '') {
      const datetime = moment(bkkDate);
      utcDate = datetime.add(-timeZone, 'hours').format(environment.dateTimeFormat);
    }

    return utcDate;
  }

  public static convertUtcToBkk(utcDate: string) {
    let bkkDate = null;

    if (utcDate && utcDate !== '') {
      if (!utcDate.endsWith('Z')) {
        utcDate = `${utcDate}Z`;
      }
      const datetime = moment(utcDate);
      bkkDate = datetime.utcOffset(7).format(environment.dateTimeFormat);
    }

    return bkkDate;
  }
}
