import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { PurchaseOrderService } from '../../services/purchase-order.service';
import { StackPricingService } from '../../services/stack-pricing.service';
import { LayoutActionLoadError, LayoutActionSaveSuccess, LayoutActionVersionError } from '../actions/layout.action';
import {
  PurchaseOrderActionTypes,
  PurchaseOrderCancelErrorResponse,
  PurchaseOrderCancelRequest,
  PurchaseOrderCloseRequest,
  PurchaseOrderListRequest,
  PurchaseOrderListResponse,
  PurchaseOrderViewLoaded,
  PurchaseOrderViewRequest
} from '../actions/purchase-order.actions';

@Injectable()
export class PurchaseOrderEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly purchaseOrderService: PurchaseOrderService,
    private readonly stackPricingService: StackPricingService,
    private readonly logger: NGXLogger
  ) {}

  @Effect()
  searchPurchaseOrder$ = this.actions$.pipe(
    ofType<PurchaseOrderListRequest>(PurchaseOrderActionTypes.PURCHASE_ORDER_LIST_REQUEST),
    tap(action => this.logger.debug(`@Effect Purchase Order List Requested:` + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseOrderService.searchByCriteria(action.payload).pipe(
        map(response => new PurchaseOrderListResponse(response)),
        catchError(error => of(new LayoutActionLoadError(error)))
      )
    )
  );

  @Effect()
  loadPurchaseOrder$ = this.actions$.pipe(
    ofType<PurchaseOrderViewRequest>(PurchaseOrderActionTypes.PURCHASE_ORDER_VIEW_REQUESTED),
    tap(action => this.logger.debug('@Effect Purchase Order Get Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseOrderService.getPoById(action.payload).pipe(
        map(result => new PurchaseOrderViewLoaded(result)),
        catchError(err => of(new LayoutActionLoadError(err)))
      )
    )
  );

  @Effect()
  cancelPoRequest$ = this.actions$.pipe(
    ofType<PurchaseOrderCancelRequest>(PurchaseOrderActionTypes.PURCHASE_ORDER_CANCEL_REQUEST),
    tap(action => this.logger.debug('@Effect PO Cancel Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseOrderService.cancel(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This PO has been cancelled.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : err.error && err.error.code === '08019'
            ? of(new PurchaseOrderCancelErrorResponse(err.error))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );

  @Effect()
  closePoRequest$ = this.actions$.pipe(
    ofType<PurchaseOrderCloseRequest>(PurchaseOrderActionTypes.PURCHASE_ORDER_CLOSE_REQUEST),
    tap(action => this.logger.debug('@Effect PO Close Requested: ' + JSON.stringify(action.payload))),
    switchMap(action =>
      this.purchaseOrderService.close(action.payload).pipe(
        map(
          () =>
            new LayoutActionSaveSuccess({
              isSuccess: true,
              title: 'Success',
              message: 'This PO has been closed.'
            })
        ),
        catchError(err => {
          return err.error && err.error.code === '00001'
            ? of(new LayoutActionVersionError(true))
            : of(new LayoutActionLoadError(err));
        })
      )
    )
  );
}
