import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { take } from 'rxjs/operators';
import { BaseSearchComponent } from '../../../base/base-search.component';
import { MonthlyPartnerProfitSharingStatusEnum } from '../../../shared/enum/monthly-partner-profit-sharing.enum';
import { RouteLinkTab } from '../../../shared/models';
import {
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingSearchCriteria
} from '../../../shared/models/monthly-partner-profit-sharing.model';
import { AuthGuardService } from '../../../shared/services';
import {
  MonthlyPartnerProfitSharingListRequestAction,
  MonthlyPartnerProfitSharingResetAction
} from '../../../shared/store/actions/monthly-partner-profit-sharing.actions';
import { MonthlyPartnerProfitSharingState } from '../../../shared/store/reducers/monthly-partner-profit-sharing-reducers';
import {
  selectAllMonthlyPartnerProfitSharingList,
  selectMonthlyPartnerProfitSharingList
} from '../../../shared/store/selectors/monthly-partner-profit-sharing.selectors';
import { AppStates } from '../../../shared/store/state/app.states';
import { MonthlyPartnerProfitSharingDownloadModalComponent } from '../monthly-partner-profit-sharing-download-modal/monthly-partner-profit-sharing-download-modal.component';

@Component({
  selector: 'app-monthly-profit-sharing-list',
  templateUrl: './monthly-partner-profit-sharing-list.component.html',
  styleUrls: ['./monthly-partner-profit-sharing-list.component.scss']
})
export class MonthlyPartnerProfitSharingListComponent extends BaseSearchComponent<
  MonthlyPartnerProfitSharingSearchCriteria,
  MonthlyPartnerProfitSharingContent,
  MonthlyPartnerProfitSharingState
> {
  listRoute: Array<RouteLinkTab>;
  monthlyPartnerProfitSharingEnum = MonthlyPartnerProfitSharingStatusEnum;
  monthlyPartnerProfitSharingDownloadModal: BsModalRef;
  hasPermissionManage: boolean;
  hasPermissionView: boolean;

  url: string;

  constructor(
    protected readonly store: Store<AppStates>,
    protected readonly modalService: BsModalService,
    protected router: Router,
    protected authGuardService: AuthGuardService
  ) {
    super(store, modalService, selectAllMonthlyPartnerProfitSharingList, selectMonthlyPartnerProfitSharingList);
    this.hasPermissionManage = this.authGuardService.checkPermission(['profitsharing_m']);
    this.hasPermissionView = this.authGuardService.checkPermission(['profitsharing_v']);
  }

  createForm() {}

  doAfterVersionAlertModal() {
    this.modalService.hide(1);
  }

  doDestroy() {}

  doInit() {
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      this.search(this.criteriaObject);
      this.store.dispatch(new MonthlyPartnerProfitSharingResetAction());
    });
  }

  setInitialCriteriaObject() {
    this.criteriaObject = {
      page: 0,
      size: 20,
      sortOrder: 'desc',
      sortBy: 'lastModifiedDate'
    };
  }

  goToView(_viewParams?: any) {}

  onAdvanceSubmit() {}

  prepareSearchCriteriaTags() {}

  search(criteriaObj) {
    this.store.dispatch(new MonthlyPartnerProfitSharingListRequestAction(criteriaObj));
  }

  setRouteTab() {
    this.listRoute = [];

    if (this.hasPermissionView || this.hasPermissionManage) {
      this.listRoute.push({ tabName: 'Profit Sharing List', url: '/billing/profit-sharing-list' });
    }

    if (this.hasPermissionManage) {
      this.listRoute.push({ tabName: 'Monthly Profit Sharing', url: '/billing/monthly-partner-profit-sharing-list' });
    }
  }

  setFirstPage() {
    this.currentPage = 1;
  }

  getColorStatus(status): string {
    return status.toLowerCase();
  }

  showMonthlyPartnerProfitSharingDownloadModal(monthlyProfitSharing: MonthlyPartnerProfitSharingContent) {
    if (!monthlyProfitSharing) {
      return;
    }
    this.monthlyPartnerProfitSharingDownloadModal = this.modalService.show(
      MonthlyPartnerProfitSharingDownloadModalComponent,
      {
        backdrop: 'static',
        keyboard: false,
        initialState: {
          id: monthlyProfitSharing.id,
          refId: `/${monthlyProfitSharing.directory}/${monthlyProfitSharing.fileName}`,
          runDate: monthlyProfitSharing.runDate
        }
      }
    );
  }
}
