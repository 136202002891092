import { createEntityAdapter } from '@ngrx/entity';
import { TdAssortmentActionTypes } from '../actions/td-assortment.actions';
const ɵ0 = (tdAssortmentContent) => `${tdAssortmentContent.supplierCode}_${tdAssortmentContent.barcode}`;
export const adapter = createEntityAdapter({
    selectId: ɵ0
});
export const initialTdAssortmentState = adapter.getInitialState({
    isLoading: false,
    saveSuccess: false,
    criteriaObject: {
        page: 0,
        size: 20
    },
    totalElements: 0,
    totalPages: 0
});
export function tdAssortmentReducers(state = initialTdAssortmentState, action) {
    switch (action.type) {
        case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_REQUEST:
            return Object.assign({}, state, { criteriaObject: action.payload });
        case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESPONSE:
            return adapter.addAll(action.payload.content, Object.assign({}, state, { totalElements: action.payload.totalElements, totalPages: action.payload.totalPages }));
        case TdAssortmentActionTypes.TD_ASSORTMENT_LIST_RESET:
            return adapter.removeAll(Object.assign({}, state, { totalElements: 0, totalPages: 0 }));
        default: {
            return state;
        }
    }
}
export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();
export { ɵ0 };
