<div class="search-result">
  <ng-container *ngIf="(listState$ | async).totalElements == 0">
    <div class="no-result-container text-center">
      <div class="d-flex justify-content-center">
        <img width="240" height="240" src="/assets/images/no-item.svg" alt="No item" />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(listState$ | async).totalElements > 0">
    <div class="mt-2 table-responsive table-scroll max-height-table">
      <table id="searchResultTable" class="table table-striped table-bordered table-hover w-100">
        <thead>
          <tr>
            <th class="text-center w-5">
              <input
                id="checkAllSearchjResultId"
                type="checkbox"
                (change)="onClickCheckAllBox($event)"
                [(ngModel)]="selectedAllPage[currentPage]"
              />
            </th>
            <th class="text-center w-10">Store Code</th>
            <th class="text-center w-20">Store Name</th>
            <th class="text-center w-10">Store Type</th>
            <th class="text-center w-20">Region</th>
            <th class="text-center w-15">Province</th>
            <th class="text-center poi-col">POI Type</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let result of resultList$ | async; index as i">
            <td class="text-center">
              <input
                type="checkbox"
                (change)="onClickCheckBox($event, result)"
                [(ngModel)]="selectedList[result.storeNo]"
              />
            </td>
            <td class="text-center">
              {{ result.storeCode }}
            </td>
            <td>{{ result.storeName }}</td>
            <td class="text-center">{{ 'STORE_TYPE.' + result.storeType | translate }}</td>
            <td class="text-center">{{ result.region }}</td>
            <td class="text-center">{{ result.province }}</td>
            <td>
              <ng-container *ngIf="result.poiType">
                <span>{{ wordLimiter(result.poiType) }} </span>
                <span
                  popover="{{ result.poiType }}"
                  [adaptivePosition]="false"
                  [outsideClick]="true"
                  containerClass="customPopover"
                  placement="top end"
                  triggers="mouseenter:mouseleave"
                  *ngIf="wordLimiter(result.poiType).length !== wordSpaceCompare(result.poiType).length"
                  >, ...</span
                >
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="result-pagination">
      <app-search-pagination
        [currentPage]="currentPage"
        [pageSize]="pageSize"
        [listState$]="listState$"
        (changePage)="onChangePage($event)"
        (changeRowPerPage)="onChangeRowPerPage($event)"
      >
      </app-search-pagination>
    </div>
  </ng-container>
</div>
