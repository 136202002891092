import { createSelector } from '@ngrx/store';
import * as fromVoucherState from '../reducers/voucher.reducers';
import { AppStates } from '../state/app.states';

const selectVoucherState = (state: AppStates) => state.voucher;

export const selectAllVoucherList = createSelector(selectVoucherState, fromVoucherState.selectAll);

export const selectVoucherList = createSelector(selectVoucherState, (state: fromVoucherState.VoucherState) => state);

export const selectVoucherListCriteria = createSelector(selectVoucherState, state => {
  return state.criteriaObject;
});

export const selectVoucherByVoucherCode = createSelector(
  selectVoucherState,
  (state: fromVoucherState.VoucherState) => state.selected
);
