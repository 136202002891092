<div [formGroup]="parentForm">
  <div formGroupName="shelfInfo">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="shelfName">Shelf Name<span class="text-danger">*</span></label>
        <input
          id="shelfName"
          name="shelfName"
          type="text"
          class="form-control"
          maxlength="100"
          formControlName="shelfName"
          placeholder="Shelf Name"
          [ngClass]="{
            'is-invalid': (saved || submitted) && form.controls.shelfName.errors,
            'bg-h-change': hasChanges('shelfName')
          }"
        />
        <div *ngIf="(saved || submitted) && form.controls.shelfName.errors" class="invalid-feedback">
          <div *ngIf="form.controls.shelfName.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
      </div>
      <div class="col-md-6 mb-3">
        <label for="shelfType">Shelf Type<span class="text-danger">*</span></label>
        <ng-select
          formControlName="shelfType"
          placeholder="Please select..."
          id="shelfType"
          [items]="shelfTypeList$ | async"
          [typeahead]="shelfTypeSearchInput$"
          [loading]="shelfTypeSearchLoading"
          bindLabel="name"
          bindValue="code"
          notFoundText="No Data"
          [ngClass]="{
            'is-invalid': submitted && form.controls.shelfType.errors,
            'bg-h-change': hasChanges('shelfType')
          }"
        >
          <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
            <div [ngOptionHighlight]="search" class="ml-1">
              {{ item.name }}
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="submitted && form.controls.shelfType.errors" class="invalid-feedback">
          <div *ngIf="form.controls.shelfType.errors.required">
            {{ 'ERRORS.REQUIRED' | translate }}
          </div>
        </div>
        <div class="input-label" *ngIf="isShowButton">
          <a class="btn-text-link" (click)="goToShelfType()">+Add Shelf Type</a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <label for="shelfImage">Shelf Image<span class="text-danger">*</span></label>
        <app-files-upload
          id="shelfImage"
          formControlName="shelfImage"
          class="form-control"
          [hasError]="submitted && form.controls.shelfImage.errors"
          [hasChange]="hasChanges('shelfImage')"
          [allowedTypes]="'(pdf|png|jpe?g)'"
          [withMeta]="true"
          [size]="500000"
          [controlName]="'shelfImage'"
          [allowedExt]="'(.pdf|.png|.jpe?g)'"
          descriptionTxt="Format file .pdf, .jpg, jpeg, .png (Size up to 500KB)"
          fileSizeErrorTxt="Size up to 500 KB."
          fileTypeErrorTxt="Incorrect Format (allow only format file .pdf, .jpg, .jpeg, .png)."
          [fileModule]="fileModule.SHELF_TYPE"
        ></app-files-upload>
      </div>
    </div>
  </div>
</div>
