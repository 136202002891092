import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LayoutActionErrorUpdate, LayoutActionTypes, LayoutActionVersionError } from '../actions/layout.action';

@Injectable()
export class LayoutEffects {
  constructor(private readonly actions$: Actions, private readonly spinner: NgxSpinnerService) {}

  // The Error handler ...
  @Effect() public onLoadError$ = this.actions$.pipe(
    ofType(LayoutActionTypes.LOAD_ERROR),
    switchMap((response: any) => {
      // Hide loading spinner for every error
      this.spinner.hide();

      if (
        response.payload &&
        response.payload.error &&
        response.payload.error.code &&
        ['00001', '00003'].includes(response.payload.error.code)
      ) {
        return of(new LayoutActionVersionError(true));
      }

      // TODO: handle for 401 and forward to login
      // ... you can check the payload here to show different messages
      // like if error.statusCode === 501 etc.

      // remap to noop Action if no state needs to be updated.
      // or for example on 401 Errors dispatch a re-login action etc.
      if (response.payload.status !== 401) {
        return of(new LayoutActionErrorUpdate(true));
      }

      return of(new LayoutActionErrorUpdate(false));
    })
  );
}
