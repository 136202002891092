<div [formGroup]="parentForm">
  <div class="row" formGroupName="promotionStores">
    <div class="col-md-3">
      <label for="selectStore">Store<span class="text-danger">*</span></label>
      <ng-select
        id="selectStore"
        placeholder="Please select..."
        [items]="storeList"
        [searchable]="false"
        [clearable]="false"
        [readonly]="!this.canEdit"
        class=" mr-sm-3"
        bindLabel="label"
        bindValue="value"
        [formControlName]="getFormParams.selectStore"
        (change)="onChangeStoreList($event)"
        [ngClass]="{
          'is-invalid': submitted && form.controls.selectStore.errors
        }"
      >
      </ng-select>
      <div *ngIf="submitted && form.controls.selectStore.errors" class="invalid-feedback">
        <div *ngIf="form.controls.selectStore.errors.required">
          {{ 'ERRORS.REQUIRED' | translate }}
        </div>
      </div>
    </div>
    <div class="col-md-3 box-select-store">
      <a *ngIf="isSelectedByStores && canEdit" class="btn-text-link " (click)="openSelectByStore()">+Select Store</a>
    </div>

    <div class="col-md-12 mt-3">
      <table *ngIf="formStores.controls.length" class="table table-striped table-bordered w-100">
        <thead>
          <tr>
            <th class="text-center w-5">{{ headerRow[0] }}</th>
            <th class="text-center w-20">{{ headerRow[1] }}</th>
            <th class="text-center w-20">{{ headerRow[2] }}</th>
            <th class="text-center w-10">{{ headerRow[3] }}</th>
            <th class="text-center w-10">{{ headerRow[4] }}</th>
            <th class="text-center w-10">{{ headerRow[5] }}</th>
          </tr>
        </thead>
        <tbody [formArrayName]="getFormParams.stores">
          <tr *ngFor="let store of formStores.controls; let i = index" [formGroupName]="i">
            <td class="text-center align-top">{{ i + 1 }}</td>
            <td class="align-top">
              {{ store.get('code').value }}
            </td>
            <td class="align-top">
              {{ store.get('name').value }}
            </td>
            <td class="align-top">
              {{ 'STORE_TYPE.' + store.get('merchantType').value | translate }}
            </td>
            <td class="align-top">
              {{ store.get('region').value | masterDataDisplay: masterDataEnum.REGION | async }}
            </td>
            <td class="align-top">
              {{ store.get('state').value | masterDataDisplay: masterDataEnum.STATE | async }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
