import { createSelector } from '@ngrx/store';
import * as fromZ8ParameterListResponseState from '../reducers/z8-parameter.reducers';
import { AppStates } from '../state/app.states';

const selectZ8ParameterListState = (state: AppStates) => state.z8Parameter;

export const selectAllZ8ParameterList = createSelector(
  selectZ8ParameterListState,
  fromZ8ParameterListResponseState.selectAll
);

export const selectZ8ParameterList = createSelector(
  selectZ8ParameterListState,
  (state: fromZ8ParameterListResponseState.Z8ParameterState) => state
);

export const selectZ8ParameterListCriteria = createSelector(
  selectZ8ParameterListState,
  (state: fromZ8ParameterListResponseState.Z8ParameterState) => state.criteriaObject
);

export const selectZ8ParameterListHistory = createSelector(
  selectZ8ParameterListState,
  (state: fromZ8ParameterListResponseState.Z8ParameterState) => {
    return state.auditLogs;
  }
);

export const selectZ8ParameterById = createSelector(
  selectZ8ParameterListState,
  (state: fromZ8ParameterListResponseState.Z8ParameterState) => {
    return state.selected;
  }
);
